import { AddDevice } from "../screens/Device/AddDevice/AddDevice";
import { ArchiveDevice } from "../screens/Device/ArchiveDevice/ArchiveDevice";
import { ArchiveDeviceStatic } from "../screens/Device/ArchiveDevice/ArchiveDeviceStatic";
import { DetailedDevice } from "../screens/Device/DetailedDevice/DetailedDevice";
import { EditDevice } from "../screens/Device/EditDevice/EditDevice";

export const deviceRoutes = [
  {
    path: "/add-device",
    element: <AddDevice />,
  },
  {
    path: "/edit-device/:id",
    element: <EditDevice />,
  },
  {
    path: "/devices",
    element: <ArchiveDevice />,
  },
  {
    path: "/devices-static",
    element: <ArchiveDeviceStatic />,
  },
  {
    path: "/device/:id/*",
    element: <DetailedDevice />,
  }

];
