import React, { useEffect, useRef, useState } from 'react';

import { Link, useNavigate, useParams } from 'react-router-dom';

import "./AdminBusinessFormView.css";


import divider2 from '../../../../icons/Gerator/profile_settings/divider2.svg';
import lock04 from '../../../../icons/Gerator/archive_page/lock04.svg';
import featured_icon from '../../../../icons/Gerator/profile_settings/featured-icon.svg';

import divider from '../../../../icons/Gerator/profile_settings/divider.svg';

import placeholder1 from '../../../../icons/Gerator/profile_settings/placeholder1.svg';
import page from '../../../../icons/Gerator/add_form/page.svg';
import { auth, onAuthStateChanged } from '../../../../screens/Login/FirebaseClient';

import searchlg from '../../../../icons/Gerator/profile_settings/searchlg.svg';
import chevronright from '../../../../icons/Gerator/profile_settings/chevronright.svg';
import placeholder1 from '../../../../icons/Gerator/profile_settings/placeholder1.svg';
import { NoListingFound } from '../../../NoListing/NoListingFound';
import { useLazyFindGetBusinessQuery, useLazyFindGetMyBusinessQuery } from '../../../../api/BusinessAPI';
import { Helmet } from 'react-helmet-async';
import { NoBusinessFound } from '../../../NoBusiness/NoBusiness';
import arrowsquareupright from "../../../../icons/Gerator/dashboard/arrowsquareupright.svg";
import BusinessOwnerSvg from "../../../../icons/Gerator/dashboard/BusinessOwnerSvg.svg";
import TeamMemberSvg from "../../../../icons/Gerator/dashboard/TeamMemberSvg.svg"
import CoOwnerSvg from "../../../../icons/Gerator/dashboard/CoOwnerSvg.svg"
import dotsvertical from '../../../../icons/Gerator/dashboard/dotsvertical.svg';
import BusinessDropdown from '../../../BusinessDropdown/BusinessDropdown';
import { encryptData } from '../../../../helper/AESHelper';
import { NoAccessBusiness } from '../../../NoAccessBusiness/NoAccessBusiness';
import verified from "../../../../icons/Gerator/profile_settings/bookmarkcheck.svg";
import unverified from "../../../../icons/Gerator/profile_settings/bookmark-x.svg";
import awaiting from "../../../../icons/Gerator/profile_settings/bookmark-minus.svg";

import { ErrorShowComponent } from '../../../ErrorShowComponent/ErrorShowComponent';
import { useAddRequestMemberMutation, useLazyGetRequestMemberStatusQuery } from '../../../../api/MemberAPI';
import page from '../../../../icons/Gerator/detailed_listing/page.svg';
import progress_fill from '../../../../icons/Gerator/detailed_listing/progress-fill.svg';
import { useLazyFindGetAdminBusinessWithoutTaxQuery, useSetBusinessPublishedMutation, useSetBusinessUnpublishedMutation, useSetBusinessUnverifiedMutation, useSetBusinessVerifiedMutation } from '../../../../api/admin/AdminBusinessAPI';
import { NothingFoundMessage } from '../../../NothingFoundMessage/NothingFoundMessage';
import { NoBusinessEmptyMessage } from '../NoBusinessEmptyMessage/NoBusinessEmptyMessage';
import { PostStatusBusinessComponent } from '../../../PostStatusLIstingComponent/PostStatusBusinessComponent';

export const AdminBusinessFormView = ({ currentUser, currentUsermeta, id }) => {

  const navigate = useNavigate();
  const [isNewDropdownVisible, setNewDropdownVisible] = useState(false);
  const [isMemberRequested, setMemberRequested] = useState(null);
  const newdropdownRef = useRef(null);
  const [showSuccessMessage, setShowSuccessMessage] = useState({
    message: "",
    color: "",
  });

  const toggleNewDropdown = () => {
    setNewDropdownVisible(!isNewDropdownVisible);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {

      const isLink = event.target.closest('a');

      if (isLink) {
        return; // Allow navigation to happen
      }

      if (newdropdownRef.current && !newdropdownRef.current.contains(event.target)) {
        setNewDropdownVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Get Business Full detail to show on page
  const [triggerGetBusiness, { data, isSuccess, error, isLoading, isError }] = useLazyFindGetAdminBusinessWithoutTaxQuery();
  // Get Status of member if it requested or not
  // const [triggerGetMemberRequestStatus, { data: memberRequestStatusData, isSuccess: isMemberRequestStatusSuccess }] = useLazyGetRequestMemberStatusQuery();
  // // Add request of member to db 
  const [setBusinessPublished, { isSuccess: isBusinessPublishedSuccess, isLoading: isBusinessPublishedLoading }] = useSetBusinessPublishedMutation();

  const [setBusinessUnpublished, { isSuccess: isBusinessUnpublishedSuccess, isLoading: isBusinessUnpublishedLoading }] = useSetBusinessUnpublishedMutation();

  const [setBusinessVerified, { isSuccess: isBusinessVerifiedSuccess, isLoading: isBusinessVerifiedLoading }] = useSetBusinessVerifiedMutation();

  const [setBusinessUnverified, { isSuccess: isBusinessUnverifiedSuccess, isLoading: isBusinessUnverifiedLoading }] = useSetBusinessUnverifiedMutation();
  // const [currentUser, setCurrentUser] = useState("");
  // const [formLoadData, setFormLoadData] = useState("");
  // const navigate = useNavigate();

  useEffect(() => {
    if (currentUser && id) {
      //limiting query run because when domain is private then this query will run
      triggerGetBusiness(id);
      // triggerGetMemberRequestStatus();
    }
  }, [currentUser]);


  useEffect(() => {
    if (isSuccess) {
      console.log(data);

    }
  }, [isSuccess])

  const handlePublishBusiness = async (e) => {
    // e.preventDefault();
    // e.stopPropagation();

    try {
      if (!id) {
        console.error('No Business id provided');
        return;
      }

      const businessPublishedStatus = await setBusinessPublished(id).unwrap();
      console.log(businessPublishedStatus);

      if (businessPublishedStatus.status) {
        navigate('/admin/dashboard');
      } else {
        setShowSuccessMessage({
          message: businessPublishedStatus.message || "There was an error while publishing the business",
          color: "red"
        });
      }

      // navigate(`/admin/dashboard/settings/business/${businessId}`);

      // console.log('Updating status for user:', businessId);
      // const response = await setToInactive({ id: businessId }).unwrap();
      // console.log('Update response:', response);

      // if (response.status) {
      //   onClose();
      //   window.location.reload();
      // }
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  const handleUnpublishBusiness = async (e) => {
    // e.preventDefault();
    // e.stopPropagation();

    try {
      if (!id) {
        console.error('No Business id provided');
        return;
      }

      const businessPublishedStatus = await setBusinessUnpublished(id).unwrap();
      console.log(businessPublishedStatus);

      if (businessPublishedStatus.status) {
        navigate('/admin/dashboard');
      } else {
        setShowSuccessMessage({
          message: businessPublishedStatus.message || "There was an error while unpublishing the business",
          color: "red"
        });
      }

      // navigate(`/admin/dashboard/settings/business/${businessId}`);

      // console.log('Updating status for user:', businessId);
      // const response = await setToInactive({ id: businessId }).unwrap();
      // console.log('Update response:', response);

      // if (response.status) {
      //   onClose();
      //   window.location.reload();
      // }
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  const handleVerifyBusiness = async (e) => {
    // e.preventDefault();
    // e.stopPropagation();

    try {
      if (!id) {
        console.error('No Business id provided');
        return;
      }

      const businessVerifiedStatus = await setBusinessVerified(id).unwrap();
      console.log(businessVerifiedStatus);

      if (businessVerifiedStatus.status) {
        navigate('/admin/dashboard');
      } else {
        setShowSuccessMessage({
          message: businessVerifiedStatus.message || "There was an error while verifying the business",
          color: "red"
        });
      }

      // navigate(`/admin/dashboard/settings/business/${businessId}`);

      // console.log('Updating status for user:', businessId);
      // const response = await setToInactive({ id: businessId }).unwrap();
      // console.log('Update response:', response);

      // if (response.status) {
      //   onClose();
      //   window.location.reload();
      // }
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  const handleUnverifyBusiness = async (e) => {
    // e.preventDefault();
    // e.stopPropagation();

    try {
      if (!id) {
        console.error('No Business id provided');
        return;
      }

      const businessVerifiedStatus = await setBusinessUnverified(id).unwrap();
      console.log(businessVerifiedStatus);

      if (businessVerifiedStatus.status) {
        navigate('/admin/dashboard');
      } else {
        setShowSuccessMessage({
          message: businessVerifiedStatus.message || "There was an error while unverifying the business",
          color: "red"
        });
      }

      // navigate(`/admin/dashboard/settings/business/${businessId}`);

      // console.log('Updating status for user:', businessId);
      // const response = await setToInactive({ id: businessId }).unwrap();
      // console.log('Update response:', response);

      // if (response.status) {
      //   onClose();
      //   window.location.reload();
      // }
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  const handleCancel = async (e) => {
    // e.preventDefault();
    // e.stopPropagation();
    navigate('/admin/dashboard');

  };

  // useEffect(() => {
  //   if (isMemberRequestStatusSuccess) {
  //     console.log(memberRequestStatusData);
  //     setMemberRequested(memberRequestStatusData.status);
  //   }
  // }, [isMemberRequestStatusSuccess])


  // //Ensuring both user and meta is loaded then second if condition will work
  // if (currentUser && currentUsermeta) {
  //   if (currentUsermeta?.isUserEmailDomainPrivate === false) {
  //     return <NoAccessBusiness path={currentUser.email} />
  //   }
  // }



  if (isError) {
    console.log(error);

    if (error.status === 404) {
      //Add this when team member is added. this is useful when user has private email domain
      return <ErrorShowComponent message1={"Invalid Data."} message2={'Try again later.'} />
    } else {
      return <ErrorShowComponent message1={"Internal server error"} />
    }
  }


  if (isSuccess) {
    // console.log(data);
    if (data.status) {
      return (
        <>
          <Helmet>
            <title>Admin Settings - Business</title>
          </Helmet>
          <div className="admin-business-display-form-content-wrapper">
            <div className="business-form-form-content-wrapper">
              <div className="business-form-form-content">
                <div className="business-form-toggle-header-parent">
                  <div className="business-form-toggle-header">
                    <div className="business-form-toggle-title">Review Business</div>
                    <div className="business-form-supporting-text2">
                      Your success story starts right here
                    </div>
                  </div>
                  <div className="business-form-toggle-controls">
                    {/* <div >
                      {
                        currentUsermeta.userType === 2
                          ? <img
                            className="admin-business-display-badge-icon1"
                            alt=""
                            src={BusinessOwnerSvg}
                          />
                          : currentUsermeta.userType === 3
                            ? <img
                              className="admin-business-display-badge-icon1"
                              alt=""
                              src={TeamMemberSvg}
                            />
                            : currentUsermeta.userType === 4
                              ? <img
                                className="admin-business-display-badge-icon1"
                                alt=""
                                src={CoOwnerSvg}
                              />
                              : null // Default value or error handling
                      }
                    </div> */}
                      <div className="admin-business-form-business-status" style={{paddingTop:"8px"}}> 
                    <PostStatusBusinessComponent postStatus={data.tax_post_status.name} postSlug={data.tax_post_status.slug} />
                     </div>

                    {/* <div className="business-form-rectangle-group">
                      <div className="business-form-frame-item"></div>
                      <div className="business-form-toggle-base">
                        <div className="business-form-button6"></div>
                      </div>
                      <div className="business-form-switch-label">Explorer mode</div>
                      <div className="business-form-supporting-text3">
                        Save my login details for next time.
                      </div>
                    </div> */}
                    {/* <button className="business-form-button7">
                      <img
                      className="business-form-placeholder-icon"
                      alt=""
                      src="/profile_settings/placeholder2.svg"
                      />
                      
                      <div className="business-form-button-labels">Business Owner</div>
                      <img
                        className="business-form-placeholder-icon"
                        alt=""
                        src="/profile_settings/placeholder2.svg"
                        />
                    </button> */}
                    {/* <div className="team-display-toggle-supporting-text">
                      {currentUsermeta.userType === 1 && (
                        <>
                          {(currentUsermeta.userPlan === 1 || currentUsermeta.userPlan === 14) ? (
                            <button
                              className="business-form-button8"
                              onClick={() => window.location.href = '/dashboard/settings/plan'}>
                              <img
                                className="business-form-placeholder-icon"
                                alt=""
                                src="/profile_settings/placeholder3.svg"
                              />
                              <div className="business-form-text49">Upgrade</div>
                              <img
                                className="business-form-placeholder-icon"
                                alt=""
                                src="/profile_settings/placeholder3.svg"
                              />
                            </button>
                          ) :
                            (isMemberRequested ?

                              <div className="business-form-button7" >
                                <img
                                  className="business-form-placeholder-icon"
                                  alt=""
                                  src="/profile_settings/placeholder3.svg"
                                />
                                <div className="profile-setting-button-labels">Requested Access</div>
                                <img
                                  className="business-form-placeholder-icon"
                                  alt=""
                                  src="/profile_settings/placeholder3.svg"
                                />
                              </div>

                              :
                              <button className="business-form-button8" onClick={handleAddRequestMember}>
                                <img
                                  className="business-form-placeholder-icon"
                                  alt=""
                                  src="/profile_settings/placeholder3.svg"
                                />
                                <div className="business-form-text49">Request Access</div>
                                <img
                                  className="business-form-placeholder-icon"
                                  alt=""
                                  src="/profile_settings/placeholder3.svg"
                                />
                              </button>
                            )
                          }
                          <div className="business-form-view-supporting-text3">
                            For team member access
                          </div>
                        </>
                      )}
                    </div> */}


                    <div >
                      <img
                        className="admin-business-display-dots-icon1"
                        alt=""
                        src={dotsvertical}
                        onClick={toggleNewDropdown}
                        style={{ cursor: 'pointer' }}
                        ref={newdropdownRef}
                      />
                    </div>
                    {/* {isNewDropdownVisible && (
                      <div className="admin-business-display-dropdown-wrapper" >
                        <BusinessDropdown currentUsermeta={currentUsermeta} />
                      </div>
                    )} */}
                  </div>
                </div>






                <div className="business-form-divider-container">
                  <img
                    className="business-form-divider-icon3"
                    alt=""
                    src={divider2}
                  />
                </div>
                <div className="business-form-content18">
                  <div className="business-form-section-label1">
                    <div className="business-form-title">Business Name
                    </div>
                    <div className="business-form-supporting-text2">
                      Cannot be edited
                    </div>
                  </div>
                  <div className="business-form-input-field1 ">
                    <div className="business-form-textarea-input-field">
                      <div className="business-form-input4 input-disabled">
                        <div className="business-form-content19 ">

                          <input
                            className="business-form-extra-input-info"
                            disabled value={data.businessName}
                            type="text"
                          />
                          <img
                            className="account-update-profile-home-line-icon"
                            alt=""
                            src={lock04}
                          />
                        </div>

                      </div>
                    </div>
                    <div className="business-form-contact-button">
                      <div className="business-form-message-button">
                        {data.tax_verification.id === 1 &&
                          <>
                            <div className="business-form-navigation">
                              <img
                                className="business-form-bookmark-check-icon"
                                alt=""
                                src={verified}
                              />
                            </div>
                            <div className="business-form-supporting-text10">
                              Verified by Gerätor
                            </div>
                          </>
                        }
                        {data.tax_verification.id === 2 &&
                          <>
                            <div className="business-form-navigation">
                              <img
                                className="business-form-bookmark-check-icon"
                                alt=""
                                src={unverified}
                              />
                            </div>
                            <div className="business-form-supporting-text10">
                              Unverified by Gerätor
                            </div>
                          </>
                        }
                        {data.tax_verification.id === 3 &&
                          <>
                            <div className="business-form-navigation">
                              <img
                                className="business-form-bookmark-check-icon"
                                alt=""
                                src={awaiting}
                              />
                            </div>
                            <div className="business-form-supporting-text10">
                              Awaiting verification by Gerätor
                            </div>
                          </>
                        }
                      </div>
                    </div>
                  </div>

                  <div className="admin-business-display-dashboard-arrow-square-up-right-wrapper1">
                    <Link to={`/business/${data.id}`}>

                      <img
                        className="admin-business-display-dashboard-arrow-square-up-right-icon1"
                        alt=""
                        src={arrowsquareupright}
                      />
                    </Link>

                  </div>


                </div>
                <div className="business-form-divider"></div>
                <div className="business-form-content18">
                  <div className="business-form-section-label1">
                    <div className="business-form-title">Website </div>
                    <div className="business-form-supporting-text2">
                      Cannot be edited
                    </div>
                  </div>
                  <div className="business-form-input-field1">
                    <div className="business-form-textarea-input-field">

                      <div className="business-form-input4 input-disabled">
                        <div className="business-form-content19 ">


                          <input
                            className="business-form-extra-input-info"
                            disabled value={data.businessWebsite}
                          />
                          <img
                            className="account-update-profile-home-line-icon"
                            alt=""
                            src={lock04}
                          />
                        </div>



                      </div>
                    </div>
                  </div>
                </div>
                <div className="business-form-divider"></div>
                <div className="business-form-content18">
                  <div className="business-form-section-label1">
                    <div className="business-form-title">Business Genre </div>
                  </div>
                  <div className="business-form-input-dropdown1">
                    <div className="business-form-textarea-input-field">

                      <div className="business-form-input8">
                        <div className="business-form-content29 ">


                          <input className="business-form-extra-input-info" disabled value={data.tax_business_genre.name} />

                        </div>

                      </div>
                    </div>
                  </div>
                </div>

                <div className="business-form-divider"></div>

                <div className="business-form-content18">
                  <div className="business-form-section-label1">
                    <div className="business-form-title">Ownership  </div>
                  </div>
                  <div className="business-form-input-dropdown1">
                    <div className="business-form-textarea-input-field">

                      <div className="business-form-input8">
                        <div className="business-form-content29 ">
                          <input className="business-form-extra-input-info" disabled value={data.tax_ownership.name} />
                        </div>

                      </div>
                    </div>
                  </div>
                </div>

                <div className="business-form-divider"></div>

                <div className="business-form-content18">
                  <div className="business-form-section-label1">
                    <div className="business-form-title">Size </div>
                  </div>
                  <div className="business-form-input-dropdown1">
                    <div className="business-form-textarea-input-field">

                      <div className="business-form-input8">
                        <div className="business-form-content29 ">


                          <input className="business-form-extra-input-info" disabled value={data.tax_business_size.name} />

                        </div>

                      </div>
                    </div>
                  </div>
                </div>

                <div className="business-form-divider"></div>
                <div className="business-form-content18">
                  <div className="business-form-section-label1">
                    <div className="business-form-title">Expertise Highlights

                    </div>
                  </div>
                  <div className="business-form-input-field1">
                    <div className="business-form-textarea-input-field">
                      <div className="business-form-input4">
                        <div className="business-form-content19 ">
                          <textarea
                            className="add-service-step3-text82 business-form-extra-input-info-custom "
                            disabled value={data.businessExpertiseHighlights}
                          />

                          {/* <input
                                      className="business-form-extra-input-info"
                                      type="text"
                                      disabled value={data.businessExpertiseHighlights}
                                    /> */}
                        </div>

                      </div>
                    </div>

                  </div>
                </div>

                <div className="business-form-divider"></div>

                <div className="business-form-content18">
                  <div className="business-form-section-label1">
                    <div className="business-form-title">Expertise Description</div>
                  </div>
                  <div className="business-form-toolbar-and-text-input">
                    <div className="business-form-textarea-input-field">
                      <div className="business-form-textarea-input-field">
                        <div className="business-form-input16 ">
                          <textarea
                            className="add-service-step3-text82 business-form-extra-input-info-custom "
                            disabled value={data.businessExpertiseDescription}
                          />
                        </div>
                      </div>
                      <div className="business-form-supporting-text4">
                      </div>
                    </div>
                  </div>
                </div>

                <div className="business-form-toggle-header-parent">
                  <div className="business-form-toggle-header">
                    <div className="business-form-toggle-title1"> Business Location</div>

                  </div>

                </div>

                <div className="business-form-divider"></div>
                <div className="business-form-content18">
                  <div className="business-form-section-label1">
                    <div className="business-form-title">Country </div>
                  </div>
                  <div className="business-form-input-dropdown1">
                    <div className="business-form-textarea-input-field">

                      <div className="business-form-input8">
                        <div className="business-form-content29 ">


                          <input className="business-form-extra-input-info" disabled value={data.tax_country.name} />

                        </div>

                      </div>
                    </div>
                  </div>
                </div>


                <div className="business-form-divider"></div>
                <div className="business-form-content18">
                  <div className="business-form-section-label1">
                    <div className="business-form-title">Building </div>
                  </div>
                  <div className="business-form-input-dropdown1">
                    <div className="business-form-textarea-input-field">
                      <div className="business-form-input9 ">
                        <div className="business-form-content31 ">
                          <input className="business-form-extra-input-info" disabled value={data.businessBuilding} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="business-form-divider"></div>
                <div className="business-form-content18">
                  <div className="business-form-section-label1">
                    <div className="business-form-title">Street </div>
                  </div>
                  <div className="business-form-input-dropdown1">
                    <div className="business-form-textarea-input-field">
                      <div className="business-form-input9 ">
                        <div className="business-form-content31 ">
                          <input className="business-form-extra-input-info" disabled value={data.businessStreet} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="business-form-divider"></div>

                <div className="business-form-content18">
                  <div className="business-form-section-label1">
                    <div className="business-form-title">State </div>
                  </div>
                  <div className="business-form-input-dropdown1">
                    <div className="business-form-textarea-input-field">

                      <div className="business-form-input10 ">
                        <input
                          className="business-form-extra-input-info"

                          type="text"
                          disabled value={data.businessState}
                        />
                      </div>
                    </div>

                  </div>
                </div>

                <div className="business-form-divider"></div>
                <div className="business-form-content18">
                  <div className="business-form-section-label1">
                    <div className="business-form-title">Town / City</div>
                  </div>
                  <div className="business-form-input-dropdown1">
                    <div className="business-form-textarea-input-field">

                      <div className="business-form-input10 ">
                        <input
                          className="business-form-extra-input-info"

                          type="text"
                          disabled value={data.businessTownCity}
                        />
                      </div>
                    </div>

                  </div>
                </div>


                <div className="business-form-divider"></div>

                <div className="business-form-content18">
                  <div className="business-form-section-label1">
                    <div className="business-form-title">District </div>
                  </div>
                  <div className="business-form-input-dropdown1">
                    <div className="business-form-textarea-input-field">

                      <div className="business-form-input10 ">
                        <input
                          className="business-form-extra-input-info"

                          type="text"
                          disabled value={data.businessDistrict}
                        />
                      </div>
                    </div>

                  </div>
                </div>

                <div className="business-form-divider"></div>
                <div className="business-form-content18">
                  <div className="business-form-section-label1">
                    <div className="business-form-title">Postcode / ZIP</div>
                  </div>
                  <div className="business-form-input-dropdown1">
                    <div className="business-form-textarea-input-field">

                      <div className="business-form-input10 ">
                        <input
                          className="business-form-extra-input-info"

                          type="text"
                          disabled value={data.businessPostcodeZip}
                        />
                      </div>
                    </div>

                  </div>
                </div>


                <div className="business-form-toggle-header-parent">
                  <div className="business-form-toggle-header">
                    <div className="business-form-toggle-title1"> Business Profile </div>

                  </div>

                </div>
                <div className="business-form-content44 business-form-content44-custom">
                  <div className="admin-business-display-toggle-title1 custom-banner">Banner
                    <div className="admin-business-display-rectangle-group custom-banner-width custom-padding">
                      <div className="admin-business-display-rectangle-container custom-banner-width">
                        {/* <div className="admin-business-display-frame-inner"></div> */}
                        <img
                          className="admin-business-display-f4b711e503bc72fe396e5043e0c2-7-icon"
                          loading="lazy"
                          alt=""
                          src={data.bannerImageLink}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="business-form-file-upload">
                    {/* <div className="business-form-file-upload-base1">
                      <div className="business-form-content45">
                        <img
                          className="business-form-featured-icon"
                          alt=""
                          src={featured_icon}
                        />
  
                        <div className="business-form-text-and-supporting-text1">
                          <div className="business-form-action">
                            <div className="business-form-buttonsbutton1">
                              <img
                                className="business-form-placeholder-icon"
                                alt=""
                                src={placeholder1}
                              />
  
                              <div className="business-form-text54">Click to upload</div>
                              <img
                                className="business-form-placeholder-icon"
                                alt=""
                                src={placeholder1}
                              />
                            </div>
                            <div className="business-form-text55">or drag and drop</div>
                          </div>
                          <div className="business-form-supporting-text5">
                            SVG, PNG, JPG or GIF (max. 800x400px)
                          </div>
                        </div>
                      </div>
                    </div> */}



                    <div className="add-service-step-file-upload-item-base">
                      <div className="add-service-step-first-upload-item-content">
                        <div className="add-service-step-file-type-icon">
                          <img
                            className="add-service-step-page-icon"
                            loading="lazy"
                            alt=""
                            src={page}
                          />

                          <div className="add-service-step-file-type-wrap">
                            <b className="add-service-step-file-type">IMG</b>
                          </div>
                        </div>
                        <div className="add-service-step-first-upload-item-info">
                          <div className="add-service-step-first-upload-item-inner">
                            <div className="add-service-step-first-upload-item">
                              {data.banner_image.file_name}
                            </div>
                            <div className="add-service-step3-checkbox3">
                              <input type="checkbox" name="imageCheckbox" checked className="checkbox-instance checkbox-base size-30-sm state-8-default checked-true type-checkbox checkbox-2" />
                            </div>
                          </div>
                          <div className="add-service-step3-supporting-text14">{(data.banner_image.file_size / 1000).toFixed(1) + " KB"}</div>
                        </div>
                      </div>
                      <div className="add-service-step3-progress-bar8">
                        <div className="add-service-step3-progress-bar-wrapper">
                          <div className="add-service-step3-progress-bar9">
                            <div className="add-service-step3-background3"></div>
                            <div className="add-service-step3-progress3"></div>
                          </div>
                        </div>
                        <div className="add-service-step3-percentage3">100%</div>
                      </div>
                    </div>
                  </div>


                </div>
                <div className="business-form-divider-container">
                  <img
                    className="business-form-divider-icon3"
                    alt=""
                    src={divider2}
                  />
                </div>
                <div className="business-form-content44 business-form-content44-custom">
                  {/* <div className="business-form-section-label1">
                              <div className="business-form-title">Logo <span className="business-form-span">*</span></div>
                              <div className="business-form-supporting-text4">
  
                              </div>
                            </div> */}
                  <div className="admin-business-display-toggle-title1 custom-logo" >Logo

                    <div className="admin-business-display-rectangle-group custom-logo-width custom-padding">
                      <div className="admin-business-display-rectangle-container custom-logo-width">
                        {/* <div className="admin-business-display-frame-inner"></div> */}
                        <img
                          className="admin-business-display-f4b711e503bc72fe396e5043e0c2-7-icon"
                          loading="lazy"
                          alt=""
                          src={data.logoImageLink}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="business-form-file-upload">
                    {/* <div className="business-form-file-upload-base1">
                      <div className="business-form-content45">
                        <img
                          className="business-form-featured-icon"
                          alt=""
                          src={featured_icon}
                        />
  
                        <div className="business-form-text-and-supporting-text1">
                          <div className="business-form-action">
                            <div className="business-form-buttonsbutton1">
                              <img
                                className="business-form-placeholder-icon"
                                alt=""
                                src={placeholder1}
                              />
  
                              <div className="business-form-text54">Click to upload</div>
                              <img
                                className="business-form-placeholder-icon"
                                alt=""
                                src={placeholder1}
                              />
                            </div>
                            <div className="business-form-text55">or drag and drop</div>
                          </div>
                          <div className="business-form-supporting-text5">
                            SVG, PNG, JPG or GIF (max. 800x400px)
                          </div>
                        </div>
                      </div>
                    </div> */}



                    <div className="add-service-step-file-upload-item-base">
                      <div className="add-service-step-first-upload-item-content">
                        <div className="add-service-step-file-type-icon">
                          <img
                            className="add-service-step-page-icon"
                            loading="lazy"
                            alt=""
                            src={page}
                          />

                          <div className="add-service-step-file-type-wrap">
                            <b className="add-service-step-file-type">IMG</b>
                          </div>
                        </div>
                        <div className="add-service-step-first-upload-item-info">
                          <div className="add-service-step-first-upload-item-inner">
                            <div className="add-service-step-first-upload-item">
                              {data.logo_image.file_name}
                            </div>
                            <div className="add-service-step3-checkbox3">
                              <input type="checkbox" name="imageCheckbox" checked className="checkbox-instance checkbox-base size-30-sm state-8-default checked-true type-checkbox checkbox-2" />
                            </div>
                          </div>
                          <div className="add-service-step3-supporting-text14">{(data.logo_image.file_size / 1000).toFixed(1) + " KB"}</div>
                        </div>
                      </div>
                      <div className="add-service-step3-progress-bar8">
                        <div className="add-service-step3-progress-bar-wrapper">
                          <div className="add-service-step3-progress-bar9">
                            <div className="add-service-step3-background3"></div>
                            <div className="add-service-step3-progress3"></div>
                          </div>
                        </div>
                        <div className="add-service-step3-percentage3">100%</div>
                      </div>
                    </div>
                  </div>
                </div>

                {(data?.documentFiles?.length > 0 && (data.tax_verification.id === 3 || data.tax_verification.id === 1)) && (
                  <div className="admin-business-display-heading-parent">
                    <div className="admin-business-display-toggle-title1 ">Documents</div>
                    <div className="detailed-device-download-items-parent detailed-device-download-items-parent-custom">
                      {data.documentFiles.map((link, index) => (
                        <a
                          key={index}
                          href={data.documentFilesLink[index]} // Use the appropriate property for the file URL
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <div className="detailed-device-download-items">
                            <div className="detailed-device-file-upload-item-base">
                              <img
                                className="detailed-device-progress-fill-icon"
                                alt=""
                                src={progress_fill}
                              />
                              <div className="detailed-device-file-type-icon">
                                <img
                                  className="detailed-device-page-icon"
                                  alt=""
                                  src={page}
                                />
                                <b className="detailed-device-file-type">PDF</b>
                              </div>
                              <div className="detailed-device-download-info">
                                <div className="detailed-device-text12">
                                  {data.documentFiles[index].file_name} {/* Access file name from the current file object */}
                                </div>
                                <div className="detailed-device-supporting-text3">
                                  {(data.documentFiles[index].file_size / 1000).toFixed(1) + " KB"} {/* Access file size */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </a>
                      ))}
                    </div>
                  </div>
                )}
                <div className="admin-business-display-upload-footer">
                  <div className="admin-business-display-section-footer">
                    <img
                      className="admin-business-display-divider-icon4"
                      alt=""
                      src="/profile_settings/divider2.svg"
                    />

                    <div className="admin-business-display-content46">

                      <div className="admin-business-display-actions1">

                        {/* When business status is pending and verification is unverified */}
                        {/* When business status is pending and verification is verified */}
                        {((data.tax_verification.id === 2 && data.tax_post_status.id === 2) || (data.tax_verification.id === 1 && data.tax_post_status.id === 2)) &&
                          <>
                            <button className="admin-business-display-button11" type="button" onClick={handlePublishBusiness} >
                              {/* <img
                                                  className="admin-business-display-placeholder-icon"
                                                  alt=""
                                                  src="/profile_settings/placeholder3.svg"
                                                /> */}

                              <div className="admin-business-display-text-padding8">
                                <div className="admin-business-display-text76">{isBusinessPublishedLoading ? 'Publishing...' : 'Publish'}</div>
                              </div>
                              {/* <img
                                                  className="admin-business-display-placeholder-icon"
                                                  alt=""
                                                  src="/profile_settings/placeholder3.svg"
                                                /> */}
                            </button>
                            <button className="admin-business-display-button11" type="button" onClick={handleCancel} >
                              {/* <img
                                                  className="admin-business-display-placeholder-icon"
                                                  alt=""
                                                  src="/profile_settings/placeholder3.svg"
                                                /> */}

                              <div className="admin-business-display-text-padding8">
                                <div className="admin-business-display-text76">Back</div>
                              </div>
                              {/* <img
                                                  className="admin-business-display-placeholder-icon"
                                                  alt=""
                                                  src="/profile_settings/placeholder3.svg"
                                                /> */}
                            </button>
                          </>
                        }



                        {/* When business status is publish and verification is awaiting */}
                        {data.tax_verification.id === 3 &&
                          <>
                            <button className="admin-business-display-button12" type="button" onClick={handleVerifyBusiness}>
                              {/* <img
                                                  className="admin-business-display-placeholder-icon"
                                                  alt=""
                                                  src="/profile_settings/placeholder3.svg"
                                                /> */}

                              <div className="admin-business-display-text-padding8">
                                <div className="admin-business-display-text76">{isBusinessVerifiedLoading ? 'Verifying...' : 'Verify'}</div>
                              </div>
                              {/* <img
                                                  className="admin-business-display-placeholder-icon"
                                                  alt=""
                                                  src="/profile_settings/placeholder3.svg"
                                                /> */}
                            </button>
                            <button className="admin-business-display-button12" type="button" onClick={handleUnverifyBusiness}>
                              {/* <img
                                                  className="admin-business-display-placeholder-icon"
                                                  alt=""
                                                  src="/profile_settings/placeholder3.svg"
                                                /> */}

                              <div className="admin-business-display-text-padding8">
                                <div className="admin-business-display-text76">{isBusinessUnverifiedLoading ? 'Unverifying...' : 'Unverify'}</div>
                              </div>
                              {/* <img
                                                  className="admin-business-display-placeholder-icon"
                                                  alt=""
                                                  src="/profile_settings/placeholder3.svg"
                                                /> */}
                            </button>
                          </>
                        }

                        {/* When business status is publish and verification is verified */}
                        {/* When business status is publish and verification is unverified */}
                        {((data.tax_verification.id === 1 && data.tax_post_status.id === 1) || (data.tax_verification.id === 2 && data.tax_post_status.id === 1)) &&
                          <>
                            <button className="admin-business-display-button12" type="button" onClick={handleUnpublishBusiness}>
                              {/* <img
                                                  className="admin-business-display-placeholder-icon"
                                                  alt=""
                                                  src="/profile_settings/placeholder3.svg"
                                                /> */}

                              <div className="admin-business-display-text-padding8">
                                <div className="admin-business-display-text76">{isBusinessUnpublishedLoading ? 'Unpublishing...' : 'Unpublish'}</div>
                              </div>
                              {/* <img
                                                  className="admin-business-display-placeholder-icon"
                                                  alt=""
                                                  src="/profile_settings/placeholder3.svg"
                                                /> */}
                            </button>
                            <button className="admin-business-display-button11" type="button" onClick={handleCancel} >
                              {/* <img
                                                  className="admin-business-display-placeholder-icon"
                                                  alt=""
                                                  src="/profile_settings/placeholder3.svg"
                                                /> */}

                              <div className="admin-business-display-text-padding8">
                                <div className="admin-business-display-text76">Back</div>
                              </div>
                              {/* <img
                                                  className="admin-business-display-placeholder-icon"
                                                  alt=""
                                                  src="/profile_settings/placeholder3.svg"
                                                /> */}
                            </button>
                          </>
                        }


                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="business-form-divider"></div> */}
                {showSuccessMessage.message && (
                  <div
                    style={{
                      fontSize: "14px",
                      color: showSuccessMessage.color,
                      padding: "15px 10px",
                      border: "1px solid " + showSuccessMessage.color,
                      borderRadius: "10px",
                      margin: "0 auto",
                    }}
                  >
                    {showSuccessMessage.message}
                  </div>
                )}





              </div>
            </div>
          </div>
        </>

      )
    }
    else {
      return <NoBusinessEmptyMessage />
    }
  }




}

