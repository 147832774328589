import React, { useEffect, useRef, useState } from 'react';
import "./DetailedDevice.css"
import { Navbar1 } from '../../../components/Navbar1/Navbar1';
import FooterDetailPage from '../../../components/Footer/FooterDetailPage/FooterDetailPage';
import SlideShowDetailPageComponent from '../../../components/SlideShowDetailPageComponent/SlideShowDetailPageComponent';
import target_04 from "../../../icons/Gerator/dashboard/target_04.png"
import divider from '../../../icons/Gerator/detailed_listing/divider.svg';
import divider2 from '../../../icons/Gerator/detailed_listing/divider2.svg';
import divider3 from '../../../icons/Gerator/detailed_listing/divider3.svg';
import divider4 from '../../../icons/Gerator/detailed_listing/divider4.svg';

import chevronright from '../../../icons/Gerator/detailed_listing/chevronright.svg';

import rectangle_9_2x from '../../../icons/Gerator/detailed_listing/rectangle-9@2x.png';
import rectangle_5_2x from '../../../icons/Gerator/detailed_listing/rectangle-5@2x.png';
import rectangle_43_2x from '../../../icons/Gerator/detailed_listing/rectangle-43@2x.png';
import rectangle_95_2x from '../../../icons/Gerator/detailed_listing/rectangle-95@2x.png';
import rectangle_431_2x from '../../../icons/Gerator/detailed_listing/rectangle-431@2x.png';
import rectangle_196_2x from '../../../icons/Gerator/detailed_listing/rectangle-196@2x.png';

import arrowleft from '../../../icons/Gerator/detailed_listing/arrowleft.svg';
import arrowright from '../../../icons/Gerator/detailed_listing/arrowright.svg';

import breadcrumb_controls_2x from '../../../icons/Gerator/detailed_listing/breadcrumb-controls@2x.png';
import breadcrumb_controls1_2x from '../../../icons/Gerator/detailed_listing/breadcrumb-controls1@2x.png';
import breadcrumb_controls2_2x from '../../../icons/Gerator/detailed_listing/breadcrumb-controls2@2x.png';

import placeholder from '../../../icons/Gerator/detailed_listing/placeholder.svg';
import placeholder1 from '../../../icons/Gerator/detailed_listing/placeholder1.svg';
import placeholder2 from '../../../icons/Gerator/detailed_listing/placeholder2.svg';

import markerpin02 from '../../../icons/Gerator/detailed_listing/markerpin02.svg';

import calendar from '../../../icons/Gerator/detailed_listing/calendar.svg';
import calendar1 from '../../../icons/Gerator/detailed_listing/calendar1.svg';

import facebook_1_1_2x from '../../../icons/Gerator/detailed_listing/facebook-1-1@2x.png';

import instagram_1_1_2x from '../../../icons/Gerator/detailed_listing/instagram-1-1@2x.png';
import whatsapp_1_2x from '../../../icons/Gerator/detailed_listing/whatsapp-1@2x.png';
import twitter_1_2x from '../../../icons/Gerator/detailed_listing/twitter-1@2x.png';
import linkedinlogo_1_2x from '../../../icons/Gerator/detailed_listing/linkedinlogo-1@2x.png';


import radar_1_1_2x from '../../../icons/Gerator/detailed_listing/radar-1-1@2x.png';
import deal_2_1_2x from '../../../icons/Gerator/detailed_listing/deal-2-1@2x.png';
import magnifier_1_2x from '../../../icons/Gerator/detailed_listing/magnifier-1@2x.png';
import share_1_2x from '../../../icons/Gerator/detailed_listing/share-1@2x.png';

import group_1010 from '../../../icons/Gerator/detailed_listing/group-1010.svg';
import group_78_2x from '../../../icons/Gerator/detailed_listing/group-78@2x.png';
import group_58_2x from '../../../icons/Gerator/detailed_listing/group-58@2x.png';
import group_781_2x from '../../../icons/Gerator/detailed_listing/group-781@2x.png';

import dotsvertical from '../../../icons/Gerator/detailed_listing/dotsvertical.svg';
import divider1 from '../../../icons/Gerator/detailed_listing/divider1.svg';
import messagechatcircle from '../../../icons/Gerator/detailed_listing/messagechatcircle.svg';
import page from '../../../icons/Gerator/detailed_listing/page.svg';
import progress_fill from '../../../icons/Gerator/detailed_listing/progress-fill.svg';
import searchlg from '../../../icons/Gerator/detailed_listing/searchlg.svg';
import filterlines from '../../../icons/Gerator/detailed_listing/filterlines.svg';
import star_background from '../../../icons/Gerator/detailed_listing/star-background.svg';
import star_icon from '../../../icons/Gerator/detailed_listing/star-icon.svg';
import dot1 from '../../../icons/Gerator/detailed_listing/-dot1.svg';
import bookmarkcheck from '../../../icons/Gerator/detailed_listing/bookmarkcheck.svg';
import usercheck02 from '../../../icons/Gerator/detailed_listing/usercheck02.svg';
import users01 from '../../../icons/Gerator/detailed_listing/users01.svg';
import f8595f4b711e503bc72fe396e5043e0c2_7_2x from '../../../icons/Gerator/detailed_listing/8595f4b711e503bc72fe396e5043e0c2-7@2x.png';
import messagedotscircle from '../../../icons/Gerator/detailed_listing/messagedotscircle.svg';

import add_contact from "../../../icons/Gerator/detailed_listing/add_contact.svg"


import markerpin021 from '../../../icons/Gerator/detailed_listing/markerpin021.svg';
import icon1 from '../../../icons/Gerator/detailed_listing/icon1.svg';
import login04 from '../../../icons/Gerator/detailed_listing/login04.svg';
import { Loader } from "../../../components/Loader";
// import { useDispatch } from "react-redux";
import { useLazyFindGetDeviceQuery, useLazyGetDevicesQuery } from "../../../api/DeviceAPI";
import { Link, useNavigate, useParams } from "react-router-dom";
import { formatNumber, getCustomizedDate } from "../../../helper/DeviceHelper";
import { decryptErrorData } from '../../../helper/AESHelper';
import { useLazyGetProfileImageLinkQuery, useLazyGetUserQuery } from '../../../api/Login&RegisterAPI';
import { firestore, doc, getDoc, auth, onAuthStateChanged } from '../../Login/FirebaseClient';
import { useAddContactMutation, useDeleteContactMutation } from '../../../api/ContactAPI';
import { useAddRadarDeviceMutation, useDeleteRadarDeviceMutation } from '../../../api/Radar/RadarDeviceAPI';
import VideoSection from '../../../components/VideoSection/VideoSection';
import MapLocationViewComponent from '../../../components/MapLocationViewComponent/MapLocationViewComponent';
import { TransactionTypeComponent } from '../../../components/TransactionTypeComponent/TransactionTypeComponent';
import { Helmet } from 'react-helmet-async';
import { useAddViewDeviceMutation } from '../../../api/View/ViewDeviceAPI';

import follow from "../../../icons/Gerator/detailed_listing/follow.svg";

import ShareThisMetaData from '../../../components/ShareThisMetaData/ShareThisMetaData';

import { InlineReactionButtons } from 'sharethis-reactjs';
import { InlineShareButtons } from 'sharethis-reactjs';
import { StickyShareButtons } from 'sharethis-reactjs';
import { InlineFollowButtons } from 'sharethis-reactjs';

import empty_star_icon from "../../../icons/Gerator/detailed_listing/empty_star_icon.svg";
import { RatingStarComponent } from '../../../components/review/RatingStarComponent/RatingStarComponent';
import { ReviewDescriptionComponent } from '../../../components/review/ReviewDescriptionComponent/ReviewDescriptionComponent';
import AddReviewForm from '../../../components/review/ReviewFormComponent/AddReviewDeviceForm';
import ReviewView from '../../../components/review/ReviewViewComponent/ReviewDeviceView';
import ReviewDeviceView from '../../../components/review/ReviewViewComponent/ReviewDeviceView';
import AddReviewDeviceForm from '../../../components/review/ReviewFormComponent/AddReviewDeviceForm';
import { ThreeDotsDetailPageComponent } from '../../../components/ThreeDotsListingComponent/ThreeDotsDetailPageComponent';
import {ThreeDotsDetailPageUserComponent} from "../../../components/ThreeDotsListingComponent/ThreeDotsDetailPageUserComponent";



export const DetailedDevice = () => {


  const [isThreeDotOpen, setIsThreeDotOpen] = useState(false);
const threeDotsRef = useRef(null);
const [isClickBlocked, setIsClickBlocked] = useState(false);

// Toggle function for the three dots dropdown
const toggleThreeDotsDropdown = () => {
  if (isClickBlocked) return; // Prevent rapid toggle

  setIsThreeDotOpen(prev => !prev); // Toggle the dropdown
  setIsClickBlocked(true);
  setTimeout(() => setIsClickBlocked(false), 400); // Small delay to avoid reopening
};

// Handle clicks outside the three dots dropdown
useEffect(() => {
  const handleThreeDotClickOutside = (event) => {
    const isLink = event.target.closest('a');
    if (isLink) return; // Allow navigation to happen

    if (threeDotsRef.current && !threeDotsRef.current.contains(event.target)) {
      setIsThreeDotOpen(false); // Close the dropdown when clicking outside
    }
  };

  document.addEventListener("mousedown", handleThreeDotClickOutside);
  return () => {
    document.removeEventListener("mousedown", handleThreeDotClickOutside);
  };
}, []);


// For second dropdown (for user or other context)
const [isThreeDotUserOpen, setIsThreeDotUserOpen] = useState(false);
const threeDotsRefUser = useRef(null);
const [isClickBlockedUser, setIsClickBlockedUser] = useState(false);

// Toggle function for the second three dots dropdown
const toggleThreeDotsDropdownUser = () => {
  if (isClickBlockedUser) return; // Prevent rapid toggle

  setIsThreeDotUserOpen(prev => !prev); // Toggle the dropdown
  setIsClickBlockedUser(true);
  setTimeout(() => setIsClickBlockedUser(false), 400); // Small delay to avoid reopening
};

// Handle clicks outside the second three dots dropdown
useEffect(() => {
  const handleThreeDotClickOutsideUser = (event) => {
    const isLink = event.target.closest('a');
    if (isLink) return; // Allow navigation to happen

    if (threeDotsRefUser.current && !threeDotsRefUser.current.contains(event.target)) {
      setIsThreeDotUserOpen(false); // Close the dropdown when clicking outside
    }
  };

  document.addEventListener("mousedown", handleThreeDotClickOutsideUser);
  return () => {
    document.removeEventListener("mousedown", handleThreeDotClickOutsideUser);
  };
}, []);


  const formikRef = useRef(null);

  // const [showReply, setShowReply] = useState(false);
  // const [replyText, setReplyText] = useState('');

  // const maxLength = 500; // Maximum character count

  // const handleReplyClick = () => {
  //   setShowReply(!showReply); // Toggle text area visibility
  // };

  // const handleChange = (event) => {
  //   if (event.target.value.length <= maxLength) {
  //     setReplyText(event.target.value); // Update reply text
  //   }
  // };

  // const handleSubmit = () => {
  //   // Handle reply submission here
  //   console.log('Reply submitted:', replyText);
  //   setShowReply(false); // Hide the text area after submission
  // };

  // const remainingChars = maxLength - replyText.length;
  // const warningClass = remainingChars <= 50 ? 'warning' : '';


  const [activeSection, setActiveSection] = useState(null); // "share", "follow", or null

  const handleShareButtonClick = () => {
    setActiveSection((prevState) => (prevState === 'share' ? null : 'share')); // Toggle or close
  };

  const handleFollowButtonClick = () => {
    setActiveSection((prevState) => (prevState === 'follow' ? null : 'follow')); // Toggle or close
  };


  const { id } = useParams();
  let content;
  const [currentUser, setCurrentUser] = useState("");
  const navigate = useNavigate();
  const [profileImageLink, setProfileImageLink] = useState("");
  const [currentUsermeta, setCurrentUsermeta] = useState("");


  const [triggerNewProfileImageLink] = useLazyGetProfileImageLinkQuery();

  // const [triggerGetUser, { data, isSuccess, error, isLoading, isError }] = useLazyGetUserQuery();
  const [triggerGetDevice, { data: deviceData,
    isLoading: isDeviceLoading,
    isSuccess: isDeviceSuccess,
    isError: isDeviceError,
    error: deviceError }] = useLazyFindGetDeviceQuery(id);

  //Add View Query
  const [addViewDevice, { data: deviceViewData,
    isLoading: isViewDeviceLoading,
    isSuccess: isViewDeviceSuccess,
    isError: isViewDeviceError,
    error: deviceViewError }] = useAddViewDeviceMutation();

  //Add/Remove Query
  const [addToContact, {
    isLoading: isContactLoading,
    isSuccess: isContactSuccess,
    isError: isContactError,
    error: contactError }] = useAddContactMutation();

  const [deleteFromContact] = useDeleteContactMutation();

  // Radar query delete
  const [deleteFromRadar] = useDeleteRadarDeviceMutation();

  // Radar query delete
  const [addToRadar] = useAddRadarDeviceMutation();

  // const [addToRadar] = ();
  // const [editDeviceSavedData, setEditDeviceSavedData] = useState(null);

  // const [showSuccessMessage, setShowSuccessMessage] = useState({
  //   message: "",
  //   color: "",
  // });


  useEffect(() => {
    // const auth = getAuth();

    // Listen for authentication state change
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {

        if (user) {
          // Get user to check wizardStep
          const userDocRef = doc(firestore, "users", user.uid);
          const userDocSnapshot = await getDoc(userDocRef);
          setCurrentUser(user);

          if (userDocSnapshot.exists()) {
            const usermeta = userDocSnapshot.data(); // Default to 1 if not set

            if (usermeta.userStatus == 2) {
              navigate('/account-disabled');
              return;
            }

            if (usermeta.userType == 5) {
              navigate('/access-denied');
              return;
            }

            if (usermeta.wizardStep !== 0) {
              navigate('/account-setup-check-email');
              return;
            }

            // await triggerGetCurrentPlan().unwrap();
            setCurrentUsermeta(usermeta);
            // User is authenticated
          } else {
            content = <ErrorShowComponent message1={"Internal server error"} />
          }
        }
      } else {
        // User is not authenticated, redirect to login
        navigate('/sign-in', { state: { message: 'Please log in to continue.' } });
      }
    });


    return () => unsubscribe(); // Cleanup listener on component unmount
  }, [navigate]);

  //C
  useEffect(() => {
    if (currentUsermeta) {
      triggerGetDevice(id);
    }
  }, [currentUsermeta]);

  //Profile image load useEffect
  useEffect(() => {
    const refreshProfileImage = async () => {
      if (currentUsermeta && currentUsermeta.profileImage.expiresAt < Date.now() / 1000) {
        // Pre-signed URL has expired, trigger a new request to the backend
        const newImageData = await triggerNewProfileImageLink().unwrap();
        // Update the profile image link directly
        setProfileImageLink(newImageData.url);
      } else if (currentUsermeta && currentUsermeta.profileImage.url) {
        // Use the existing pre-signed URL to display the profile image
        setProfileImageLink(currentUsermeta.profileImage.url);
      }
    };

    refreshProfileImage();
  }, [currentUsermeta]);

  // // Trigger the get wanted device query only after user data is successfully fetched
  useEffect(() => {
    if (isDeviceSuccess && deviceData) {
      addViewDevice(id);
      console.log('added view data');
    }
  }, [isDeviceSuccess, deviceData]);

  useEffect(() => {
    if (deviceViewData) {
      console.log(deviceViewData);
    }
  }, [deviceViewData]);



  // const handleAddToRadar = async (deviceId) => {
  //   setToggledImages(prev => ({
  //     ...prev,
  //     [deviceId]: !prev[deviceId]
  //   }));
  //   await addToRadar({ id: deviceId });
  // };

  const handleRemoveRadar = async (deviceId) => {
    await deleteFromRadar(deviceId);
    window.location.reload();
  };

  const handleAddRadar = async (deviceId) => {
    console.log(`clicked ${deviceId}`);

    await addToRadar(deviceId);
    window.location.reload();
  };

  const handleRemoveContact = async (deviceId) => {
    const result = await deleteFromContact(deviceId);
    if (result.data) {
      // If successful, reload the page
      window.location.reload();
    } else if (result.error) {
      console.log(result.error);
    }
  };

  const handleAddContact = async (deviceId) => {
    const result = await addToContact(deviceId);
    if (result.data) {
      // If successful, reload the page
      window.location.reload();
    } else if (result.error) {
      console.log(result.error);
    }
  };

  // To show error when adding contact
  useEffect(() => {
    // const { status } = contactError;
    // console.log(contactError);

    if (contactError?.status === 405)
      console.log(contactError.data.message);
    else
      console.log("Internal server error");
  }, [isContactError])




  // if (error) {
  //   console.log(error);

  //   if (isError) {
  //     const decryptedData = decryptErrorData(error.data);
  //     console.log(decryptedData);
  //     // navigate("/sign-in", { state: decryptedData.message });
  //     navigate("/sign-in", { state: { message: decryptedData.message } });
  //   }
  //   else {
  //     navigate("/sign-in", { state: { message: "Internal server error" } });
  //   }
  //   return; // Optionally handle error differently
  // }

  // CHAT


  useEffect(() => {
    if (isDeviceSuccess && deviceData) {
      console.log("SPARE DATA: ", deviceData);
    }
  }, [isDeviceSuccess , deviceData]);


const handlePrivateMessage = () => {
  const deviceId = deviceData.data.id; // Use the appropriate ID for the chat
  handleAddRadar(deviceId);
  navigate(`/chat/offered/Device/${deviceId}`); // Replace `/chat/:id` with the correct route for your chat page
};


  if (currentUser && isDeviceSuccess && profileImageLink) {
    return (
      <>
        <Helmet>
          <title>Device</title>
        </Helmet>
        <ShareThisMetaData
          title="Service - Detailed Page"
          description="Explore detailed services and listings tailored to your needs."
          image={profileImageLink}
          url={"https://gerator.io/"}
        />
        <div className="detailed-device-listings-detailed-page-devic">
          <div className="detailed-device-rectangle-parent">
            <div className="detailed-device-frame-child"></div>

            <Navbar1 userData={currentUser} navigate={navigate} profileImageLink={profileImageLink} />

            <img className="detailed-device-divider-icon" alt="" src={divider} />

            <div className="detailed-device-breadcrumb-button-base">
              <div className="detailed-device-text6">...</div>
            </div>
            <img
              className="detailed-device-chevron-right-icon"
              alt=""
              src={chevronright}
            />

            <div className="detailed-device-breadcrumb-button-base1">
              <div className="detailed-device-text7">Another link</div>
            </div>
            <img
              className="detailed-device-chevron-right-icon1"
              alt=""
              src={chevronright}
            />

            <div className="detailed-device-frame-parent">
              <div className="detailed-device-breadcrumb-container-parent">
                <SlideShowDetailPageComponent slides={deviceData.data.galleryImages} />
                <div className="detailed-device-breadcrumb">
                  <div className="detailed-device-breadcrumb-items">
                    <div className="detailed-device-breadcrumb-button-base2">
                      <div className="detailed-device-text9">Dashboard</div>
                    </div>
                    <div className="detailed-device-chevron-container">
                      <img
                        className="detailed-device-chevron-right-icon2"
                        alt=""
                        src={chevronright}
                      />
                    </div>
                    <div className="detailed-device-breadcrumb-button-base3">
                      <div className="detailed-device-text10">Device</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="detailed-device-listing-content-wrapper">
                <div className="detailed-device-listing-content">
                  <div className="detailed-device-description-block-parent">
                    <div className="detailed-device-description-block">
                      <div className="detailed-device-description-heading">
                        {deviceData.data.title}
                      </div>
                      <div className="detailed-device-listing-details">
                        <div className="detailed-device-detail-container">
                          <div className="detailed-device-details-content">
                            <div className="detailed-device-details-row">
                              <div className="detailed-device-detail-items">
                                <img
                                  className="detailed-device-marker-pin-02-icon"
                                  alt=""
                                  src={markerpin02}
                                />

                                <div className="detailed-device-supporting-text">{deviceData.data.device_address.addressTownCity},  {deviceData.data.device_address.tax_country.name}</div>
                              </div>
                              <div className="detailed-device-detail-items">
                                <img
                                  className="detailed-device-marker-pin-02-icon"
                                  alt=""
                                  src={calendar}
                                />

                                <div className="detailed-device-supporting-text">{getCustomizedDate(deviceData.data.createdAt)}</div>
                              </div>
                            </div>
                            <div className="detailed-device-contact-details">
                              <div className="detailed-device-contact-name-wrapper">
                                <div className="detailed-device-contact-name">4.9/5</div>
                              </div>
                              <div className="detailed-device-contact-role">
                                <div className="detailed-device-supporting-text2">99,999 reviews</div>
                              </div>
                              {/* <div className="detailed-device-social-links">
                                <div className="detailed-device-rectangle-container">
                                  <div className="detailed-device-frame-inner"></div>
                                  <div className="detailed-device-icon-backgrounds-parent">
                                    <div className="detailed-device-icon-backgrounds"></div>
                                    <img
                                      className="detailed-device-whatsapp-1-icon"
                                      alt=""
                                      src={facebook_1_1_2x}
                                    />
                                  </div>
                                  <div className="detailed-device-icon-backgrounds-parent">
                                    <div className="detailed-device-ellipse-div"></div>
                                    <img
                                      className="detailed-device-whatsapp-1-icon"
                                      alt=""
                                      src={instagram_1_1_2x}
                                    />
                                  </div>
                                  <div className="detailed-device-icon-backgrounds-parent">
                                    <div className="detailed-device-frame-child1"></div>
                                    <img
                                      className="detailed-device-whatsapp-1-icon"
                                      alt=""
                                      src={whatsapp_1_2x}
                                    />
                                  </div>
                                  <div className="detailed-device-icon-backgrounds-parent">
                                    <div className="detailed-device-icon-backgrounds"></div>
                                    <img
                                      className="detailed-device-whatsapp-1-icon"
                                      alt=""
                                      src={twitter_1_2x}
                                    />
                                  </div>
                                  <div className="detailed-device-icon-backgrounds-parent">
                                    <div className="detailed-device-ellipse-div"></div>
                                    <img
                                      className="detailed-device-whatsapp-1-icon"
                                      alt=""
                                      src={linkedinlogo_1_2x}
                                    />
                                  </div>
                                </div>
                              </div> */}
                            </div>
                          </div>
                          <div className="detailed-device-button-row">
                            {!deviceData.data.isAuthor && (
                              deviceData.data.addedToRadar ? (

                                <div className="detailed-device-buttonsbutton1" onClick={() => handleRemoveRadar(deviceData.data.id)} style={{ cursor: "pointer" }}>
                                  <img
                                    className="detailed-device-magnifier-1-icon"
                                    alt=""
                                    src={target_04}
                                  />
                                  <div className="detailed-device-text-padding">
                                    <div className="detailed-device-text11">Added To Radar</div>
                                  </div>

                                </div>
                              ) : (
                                <div className="detailed-device-buttonsbutton1" onClick={() => handleAddRadar(deviceData.data.id)
                                } style={{ cursor: "pointer" }}>
                                  <img
                                    className="detailed-device-magnifier-1-icon"
                                    alt=""
                                    src={radar_1_1_2x}
                                  />

                                  <div className="detailed-device-text-padding">
                                    <div className="detailed-device-text11">Add To Radar</div>
                                  </div>
                                </div>
                              )
                            )}
                            {/* <div className="detailed-device-buttonsbutton2">
                              <img
                                className="detailed-device-placeholder-icon4"
                                alt=""
                                src={placeholder1}
                              />

                              <img
                                className="detailed-device-magnifier-1-icon"
                                alt=""
                                src={deal_2_1_2x}
                              />

                              <img
                                className="detailed-device-placeholder-icon4"
                                alt=""
                                src={placeholder1}
                              />
                            </div>
                            <div className="detailed-device-buttonsbutton2">
                              <img
                                className="detailed-device-placeholder-icon4"
                                alt=""
                                src={placeholder1}
                              />

                              <img
                                className="detailed-device-magnifier-1-icon"
                                alt=""
                                src={magnifier_1_2x}
                              />

                              <img
                                className="detailed-device-placeholder-icon4"
                                alt=""
                                src={placeholder1}
                              />
                            </div> */}
                            <div className='detailed-device-sharethis-parent'>

                              <div className="detailed-device-buttonsbutton2" onClick={handleShareButtonClick} style={{ cursor: 'pointer' }}>
                                <img
                                  className="detailed-device-placeholder-icon4"
                                  alt=""
                                  src={placeholder1}
                                />

                                <img
                                  className="detailed-device-magnifier-1-icon"
                                  alt=""
                                  src={share_1_2x}
                                />

                                <img
                                  className="detailed-device-placeholder-icon4"
                                  alt=""
                                  src={placeholder1}
                                />



                              </div>

                              {activeSection === 'share' && (
                                <div className='detailed-device-sharethis'>
                                  <InlineShareButtons
                                    config={{
                                      alignment: 'center',
                                      color: 'black',
                                      enabled: true,
                                      font_size: 14,
                                      labels: 'cta',
                                      language: 'en',
                                      networks: ["twitter", "whatsapp", "linkedin", "email", "facebook"
                                        , "flipboard"
                                        , "google"
                                        , "digg"
                                        , "livejournal"
                                        , "mailru"
                                        , "meneame"
                                        , "messenger"
                                        , "oknoklassniki"
                                        , "pinterest"
                                        , "print"
                                        , "reddit"
                                        , "sharethis"
                                        , "sms"
                                        , "stumbleupon"
                                        , "tumblr"
                                        , "blogger", "vk", "wechat", "weibo", "delicious", "xing"],
                                      padding: 12,
                                      radius: 4,
                                      show_total: false,
                                      size: 34,
                                      url: "https://gerator.io/", // Use the current page URL dynamically
                                      image: 'https://bit.ly/2CMhCMC',
                                      description: 'Check out this amazing content!',
                                      title: 'Custom Share Title',
                                    }}
                                  />
                                </div>
                              )}

                            </div >


                            {/* <img
                                                          className="detailed-device-button-row-child"
                                                          alt=""
                                                          src={group_1010}
                                                        /> */}

                            <div className='detailed-device-sharethis-follow-parent'>

                              <div className="detailed-device-buttonsbutton2" onClick={handleFollowButtonClick} style={{ cursor: 'pointer' }}>
                                <img
                                  className="detailed-device-placeholder-icon4"
                                  alt=""
                                  src={placeholder1}
                                />

                                <img
                                  className="detailed-device-magnifier-1-icon"
                                  alt=""
                                  src={follow}

                                />

                                <img
                                  className="detailed-device-placeholder-icon4"
                                  alt=""
                                  src={placeholder1}
                                />

                                Follow us

                              </div>

                              {activeSection === 'follow' && (
                                <div className='detailed-device-sharethis-follow'>
                                  <InlineFollowButtons
                                    config={{
                                      action: 'Follow us:', // call to action (STRING)
                                      action_enable: false,
                                      action_pos: 'bottom', // position of call to action (left, top, right)
                                      alignment: 'center',  // alignment of buttons (left, center, right)
                                      color: 'white',       // set the color of buttons (social, white)
                                      enabled: true,        // show/hide buttons (true, false)
                                      networks: [
                                        'linkedin',         // which networks to include (see FOLLOW NETWORKS)
                                        'twitter',
                                        'facebook',
                                        'instagram',

                                      ],
                                      padding: 8,           // padding within buttons (INTEGER)
                                      profiles: {           // social profile links for buttons
                                        twitter: 'ger8or',
                                        facebook: 'Ger8orMarketplace ',
                                        instagram: 'ger8or',
                                        linkedin: 'company/gerator',
                                      },
                                      radius: 9,            // the corner radius on each button (INTEGER)
                                      size: 32,             // the size of each button (INTEGER)
                                      spacing: 8            // the spacing between buttons (INTEGER)
                                    }}
                                  />
                                </div>
                              )}

                            </div>
                          </div>
                        </div>
                        <div className="detailed-device-dropdown-container" style={{ cursor: "pointer" }} onClick={toggleThreeDotsDropdown} ref={threeDotsRef}>
                          <div className="detailed-device-dropdown">
                            <img
                              className="detailed-device-maximize-01-icon"
                              alt=""
                              src={dotsvertical}
                            />
                          </div>
                          {isThreeDotOpen && <ThreeDotsDetailPageComponent  />}
                        </div>
                      </div>
                    </div>
                    <img className="detailed-device-divider-icon" alt="" src={divider1} />

                    <div className="detailed-device-heading-parent">
                      <div className="detailed-device-heading">Highlights</div>
                      <div className="detailed-device-description-content">
                        <div className="detailed-device-description-columns-first">
                          <div className="detailed-device-first-columns-paragraphs">
                            <div className="detailed-device-paragraph">
                              {deviceData.data.tax_device_category.name}
                            </div>
                            <div className="detailed-device-paragraph1">Device Category</div>
                          </div>
                          <div className="detailed-device-first-columns-paragraphs">
                            <div className="detailed-device-paragraph">{deviceData.data.tax_availability.name}</div>
                            <div className="detailed-device-paragraph1">Available From</div>
                          </div>
                          <div className="detailed-device-first-columns-paragraphs">
                            <div className="detailed-device-paragraph">{deviceData.data.tax_clinical_application.name}</div>
                            <div className="detailed-device-paragraph1">Clinical Applications</div>
                          </div>
                        </div>
                        <div className="detailed-device-description-columns-second">
                          <div className="detailed-device-second-columns-paragraphs">
                            <div className="detailed-device-paragraph">
                              {deviceData.data.tax_oem.name}
                            </div>
                            <div className="detailed-device-paragraph1">OEM Brand</div>
                          </div>
                          <div className="detailed-device-second-columns-paragraphs">
                            <div className="detailed-device-paragraph">{deviceData.data.modelNumber}</div>
                            <div className="detailed-device-paragraph1">Model Number</div>
                          </div>
                          <div className="detailed-device-second-columns-paragraphs">
                            <div className="detailed-device-paragraph">
                              {deviceData.data.tax_purpose_use.name}
                            </div>
                            <div className="detailed-device-paragraph1">Device Use</div>
                          </div>
                          <div className="detailed-device-second-columns-paragraphs">
                            <div className="detailed-device-paragraph">{deviceData.data.modelName}</div>
                            <div className="detailed-device-paragraph1">Model Name</div>
                          </div>
                          <div className="detailed-device-second-columns-paragraphs">
                            <div className="detailed-device-paragraph">{deviceData.data.serialNumber}</div>
                            <div className="detailed-device-paragraph1">Serial Number</div>
                          </div>
                          <div className="detailed-device-second-columns-paragraphs">
                            <div className="detailed-device-paragraph">
                              {deviceData.data.tax_physical_location.name}
                            </div>
                            <div className="detailed-device-paragraph1">Physical Location</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="detailed-device-third-columns-paragraphs-parent">
                      <div className="detailed-device-first-columns-paragraphs">
                        <div className="detailed-device-paragraph18">{deviceData.data.tax_status_condition.name}</div>
                        <div className="detailed-device-paragraph19">Status</div>
                      </div>
                      <div className="detailed-device-first-columns-paragraphs">
                        <div className="detailed-device-paragraph18">
                          {deviceData.data.tax_warranty.name}
                        </div>
                        <div className="detailed-device-paragraph19">Warranty</div>
                      </div>
                      <div className="detailed-device-first-columns-paragraphs">
                        <div className="detailed-device-paragraph18">{deviceData.data.tax_year_of_manufacture.name}</div>
                        <div className="detailed-device-paragraph19">Year of Manufacture</div>
                      </div>
                    </div>
                    <div className="detailed-device-description-paragraphs">
                      <div className="detailed-device-paragraph">{deviceData.data.tax_shipping.name}</div>
                      <div className="detailed-device-paragraph1">Shipping</div>
                    </div>
                    <div className="detailed-device-content-blocks">
                      <div className="detailed-device-heading1">Hardware</div>
                      <div className="detailed-device-paragraph26">
                        {deviceData.data.hardwareHighlights}
                      </div>
                    </div>
                    <div className="detailed-device-content-blocks">
                      <div className="detailed-device-heading1">
                        Software / Upgrade / OS / Applications / Worklist -
                        Highlights
                      </div>
                      <div className="detailed-device-paragraph26">
                        {deviceData.data.softwareUpgradesOsApplicationsworklistHighlights}
                      </div>
                    </div>
                    <div className="detailed-device-content-blocks">
                      <div className="detailed-device-heading1">Accessories - Highlights</div>
                      <div className="detailed-device-paragraph26">
                        {deviceData.data.accessoriesHighlights}
                      </div>
                    </div>
                    <div className="detailed-device-content-blocks">
                      <div className="detailed-device-heading1">Location</div>
                      <div className="detailed-device-paragraph26">
                        {deviceData.data.device_address?.fullAddress}
                      </div>
                      <MapLocationViewComponent
                        key={deviceData.data.id}
                        width={"100%"}
                        height={"400px"}
                        initialCoordinates={deviceData.data.device_address.addressLocation}
                      />

                      {/* <img
                      className="detailed-device-rectangle-icon"
                      alt=""
                      src={rectangle_43_2x}
                    /> */}
                    </div>
                    {(deviceData.data.videoType && deviceData.data.linkVideo) &&
                      // (deviceData.data.videoType === 'Youtube') ?
                      <>
                        <VideoSection videoUrl={deviceData.data.linkVideo} />
                        <br />
                      </>
                      // :
                      // <>
                      //   <VideoSection videoUrl={deviceData.data.linkVideo} />
                      //   <br />
                      // </>
                    }
                    {/* <div className="detailed-device-image-gallery">
                    <div className="detailed-device-heading1">Video</div>
                    <div className="detailed-device-gallery-images">
                      <div className="detailed-device-gallery-images-container">
                        <img
                          className="detailed-device-gallery-images-container-child"
                          alt=""
                          src={rectangle_431_2x}
                        />

                        <img
                          className="detailed-device-gallery-images-container-item"
                          alt=""
                          src={group_351}
                        />
                      </div>
                      <div className="detailed-device-gallery-images-container1">
                        <img
                          className="detailed-device-gallery-images-container-child"
                          alt=""
                          src={rectangle_196_2x}
                        />

                        <img
                          className="detailed-device-gallery-images-container-item"
                          alt=""
                          src={group_351}
                        />
                      </div>
                    </div>
                  </div> */}
                    <img className="detailed-device-divider-icon" alt="" src={divider1} />

                    <div className="detailed-device-section">
                      <div className="detailed-device-benefits-container">
                        <div className="detailed-device-benefits-content">
                          {(deviceData.data?.hardware?.trim() ||
                            deviceData.data?.softwareUpgraadesOsApplicationsWorklist?.trim() ||
                            deviceData.data?.warrantyInput?.trim() ||
                            deviceData.data?.packingList?.trim() ||
                            deviceData.data?.warrantyInput?.trim() ||
                            deviceData.data?.serviceHistory?.trim() ||
                            deviceData.data?.additionalInformation?.trim() ||
                            deviceData.data?.accessories?.trim() ||
                            deviceData.data?.packingList?.trim()) &&
                            <div className="detailed-device-heading6">Description</div>}
                          {deviceData.data?.hardware?.trim() !== "" && (
                            <div className="detailed-device-benefits-subtitle">
                              <div className="detailed-device-heading7">Hardware</div>
                              <div className="detailed-device-paragraph30">
                                {deviceData.data.hardware}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    {deviceData.data?.softwareUpgraadesOsApplicationsWorklist?.trim() !== "" && (
                      <div className="detailed-device-content-blocks">
                        <div className="detailed-device-heading1">
                          Software / Upgrade / OS / Applications / Worklist
                        </div>
                        <div className="detailed-device-paragraph26">
                          {deviceData.data.softwareUpgraadesOsApplicationsWorklist}
                        </div>
                      </div>
                    )}
                    {deviceData.data?.accessories?.trim() !== "" && (
                      <div className="detailed-device-content-blocks">
                        <div className="detailed-device-heading1">Accessories</div>
                        <div className="detailed-device-paragraph26">
                          {deviceData.data.accessories}
                        </div>
                      </div>
                    )}
                    {deviceData.data?.warrantyInput?.trim() !== "" && (
                      <div className="detailed-device-content-blocks">
                        <div className="detailed-device-heading1">Warranty Inclusions & Exclusions</div>
                        <div className="detailed-device-paragraph26">
                          {deviceData.data.warrantyInput}
                        </div>
                      </div>
                    )}
                    {deviceData.data?.serviceHistory?.trim() !== "" && (
                      <div className="detailed-device-content-blocks">
                        <div className="detailed-device-heading1">Service History</div>
                        <div className="detailed-device-paragraph26">
                          {deviceData.data.serviceHistory}
                        </div>
                      </div>
                    )}
                    <div className="detailed-device-heading-parent">
                      {deviceData.data?.packingList?.trim() !== "" && (
                        <div className="detailed-device-content-blocks">
                          <div className="detailed-device-heading1">Packing List</div>
                          <div className="detailed-device-second-paragraph">
                            <div className="detailed-device-paragraph35">
                              {deviceData.data.packingList}
                            </div>
                          </div>
                        </div>
                      )}

                      {deviceData.data?.additionalInformation?.trim() !== "" && (
                        <div className="detailed-device-content-blocks">
                          <div className="detailed-device-heading1">Additional Information</div>
                          <div className="detailed-device-paragraph26">
                            {deviceData.data.additionalInformation}
                          </div>
                        </div>
                      )}
                    </div>
                    {deviceData.data?.documentFiles?.length > 0 && (
                      <div className="detailed-device-heading-parent">
                        <div className="detailed-device-heading1">Documents</div>
                        <div className="detailed-device-download-items-parent detailed-device-download-items-parent-custom">
                          {deviceData.data.documentFiles.map((link, index) => (
                            <a
                              key={index}
                              href={link} // Use the appropriate property for the file URL
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <div className="detailed-device-download-items">
                                <div className="detailed-device-file-upload-item-base">
                                  <img
                                    className="detailed-device-progress-fill-icon"
                                    alt=""
                                    src={progress_fill}
                                  />
                                  <div className="detailed-device-file-type-icon">
                                    <img
                                      className="detailed-device-page-icon"
                                      alt=""
                                      src={page}
                                    />
                                    <b className="detailed-device-file-type">PDF</b>
                                  </div>
                                  <div className="detailed-device-download-info">
                                    <div className="detailed-device-text12">
                                      {deviceData.data.devices_files[index].file_name} {/* Access file name from the current file object */}
                                    </div>
                                    <div className="detailed-device-supporting-text3">
                                      {(deviceData.data.devices_files[index].file_size / 1000).toFixed(1) + " KB"} {/* Access file size */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </a>
                          ))}
                        </div>
                      </div>
                    )}

                    {/* <div className="detailed-device-heading15">Reviews (2)</div>
                    <div className="detailed-device-filters-bar">
                      <div className="detailed-device-content4">
                        <div className="detailed-device-button-group">
                          <div className="detailed-device-button-group-base">
                            <div className="detailed-device-text18">Newest</div>
                          </div>
                          <div className="detailed-device-button-group-base1">
                            <div className="detailed-device-text19">Highest</div>
                          </div>
                          <div className="detailed-device-button-group-base2">
                            <div className="detailed-device-text20">Text</div>
                          </div>
                          <div className="detailed-device-button-group-base3">
                            <div className="detailed-device-text20">Text</div>
                          </div>
                          <div className="detailed-device-button-group-base4">
                            <div className="detailed-device-text20">Text</div>
                          </div>
                          <div className="detailed-device-button-group-base5">
                            <div className="detailed-device-text20">Text</div>
                          </div>
                          <div className="detailed-device-button-group-base6">
                            <div className="detailed-device-text20">Text</div>
                          </div>
                          <div className="detailed-device-button-group-base7">
                            <div className="detailed-device-text20">Text</div>
                          </div>
                          <div className="detailed-device-button-group-base8">
                            <div className="detailed-device-text26">Lowest</div>
                          </div>
                        </div>
                        <div className="detailed-device-actions">
                          <div className="detailed-device-input-dropdown">
                            <div className="detailed-device-input-with-label1">
                              <div className="detailed-device-label">Search</div>
                              <div className="detailed-device-input1">
                                <div className="detailed-device-content5">
                                  <img
                                    className="detailed-device-search-lg-icon"
                                    alt=""
                                    src={searchlg}
                                  />

                                  <input
                                    className="detailed-device-text27"
                                    placeholder="Thomson Medical"
                                    type="text"
                                  />

                                </div>
                              </div>
                            </div>
                            <div className="detailed-device-hint-text">
                              This is a hint text to help user.
                            </div>
                          </div>
                          <div className="detailed-device-button">
                            <img
                              className="detailed-device-filter-lines-icon"
                              alt=""
                              src={filterlines}
                            />

                            <div className="detailed-device-text-padding">
                              <div className="detailed-device-text28">Filters</div>
                            </div>
                            <img
                              className="detailed-device-placeholder-icon4"
                              alt=""
                              src={placeholder1}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="detailed-device-review-container">
                      <div className="detailed-device-review-content">
                        <div className="detailed-device-first-review">
                          <b className="detailed-device-empty-review">5.0</b>
                          <div className="detailed-device-reviewer">
                            <div className="detailed-device-heading16">/5</div>
                          </div>
                        </div>
                        <div className="detailed-device-review-stars-background">
                          <img
                            className="detailed-device-star-background-icon"
                            alt=""
                            src={star_background}
                          />

                          <img
                            className="detailed-device-star-background-icon"
                            alt=""
                            src={star_background}
                          />

                          <img
                            className="detailed-device-star-background-icon"
                            alt=""
                            src={star_background}
                          />

                          <img
                            className="detailed-device-star-background-icon"
                            alt=""
                            src={star_background}
                          />

                          <img
                            className="detailed-device-star-background-icon"
                            alt=""
                            src={star_background}
                          />
                        </div>
                      </div>
                      <div className="detailed-device-review-stars">
                        <div className="detailed-device-rectangle-parent1">
                          <div className="detailed-device-rectangle-div"></div>
                          <div className="detailed-device-stars">
                            <img
                              className="detailed-device-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />

                            <img
                              className="detailed-device-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />

                            <img
                              className="detailed-device-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />

                            <img
                              className="detailed-device-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />

                            <img
                              className="detailed-device-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />
                          </div>
                          <div className="detailed-device-first-review-fifth-star">
                            <div className="detailed-device-heading17">Accuracy (5.0)</div>
                          </div>
                        </div>
                        <div className="detailed-device-rectangle-parent1">
                          <div className="detailed-device-rectangle-div"></div>
                          <div className="detailed-device-stars">
                            <img
                              className="detailed-device-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />

                            <img
                              className="detailed-device-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />

                            <img
                              className="detailed-device-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />

                            <img
                              className="detailed-device-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />

                            <img
                              className="detailed-device-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />
                          </div>
                          <div className="detailed-device-heading-wrapper">
                            <div className="detailed-device-heading18">Relevance (5.0)</div>
                          </div>
                        </div>
                        <div className="detailed-device-rectangle-parent3">
                          <div className="detailed-device-rectangle-div"></div>
                          <div className="detailed-device-stars-wrapper">
                            <div className="detailed-device-stars2">
                              <img
                                className="detailed-device-filter-lines-icon"
                                alt=""
                                src={star_icon}
                              />

                              <img
                                className="detailed-device-filter-lines-icon"
                                alt=""
                                src={star_icon}
                              />

                              <img
                                className="detailed-device-filter-lines-icon"
                                alt=""
                                src={star_icon}
                              />

                              <img
                                className="detailed-device-filter-lines-icon"
                                alt=""
                                src={star_icon}
                              />

                              <img
                                className="detailed-device-filter-lines-icon"
                                alt=""
                                src={star_icon}
                              />
                            </div>
                          </div>
                          <div className="detailed-device-heading19">Value for Money (5.0)</div>
                        </div>
                      </div>
                    </div> */}
                    <div className="detailed-device-divider-wrapper">
                      <img
                        className="detailed-device-divider-icon3"
                        alt=""
                        src={divider1}
                      />
                    </div>
                    {/* <div className="detailed-device-client-review">
                      <img
                        className="detailed-device-client-review-child"
                        alt=""
                        src={group_78_2x}
                      />

                      <div className="detailed-device-client-review-header">
                        <div className="detailed-device-heading20">Chloe Tammy</div>
                        <div className="detailed-device-heading21">
                          <span>Marketing Communications at</span>
                          <span className="detailed-device-alvo-medical"> Alvo Medical</span>
                        </div>
                      </div>
                      <div className="detailed-device-client-review-content">
                        <div className="detailed-device-client-rating">
                          <div className="detailed-device-stars3">
                            <img
                              className="detailed-device-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />

                            <img
                              className="detailed-device-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />

                            <img
                              className="detailed-device-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />

                            <img
                              className="detailed-device-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />

                            <img
                              className="detailed-device-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />
                          </div>
                          <div className="detailed-device-rating-value">
                            <div className="detailed-device-heading22">1 month ago</div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                  <div className="detailed-device-service-card">
                    <div className="detailed-device-card-content">
                      <div className="detailed-device-card-content-child"></div>
                      <div className="detailed-device-card-header">
                        <div className="detailed-device-price-tag">
                          <div className="detailed-device-badge-wrapper">
                            <TransactionTypeComponent transactionType={deviceData.data.tax_transaction_type.name} slug={deviceData.data.tax_transaction_type.slug} />
                            {/* <div className="detailed-device-badge3">
                            <img
                              className="detailed-device-dot-icon3"
                              alt=""
                              src={dot1}
                            /> */}

                            {/* <div className="detailed-device-text29">{deviceData.data.tax_transaction_type.name}</div> */}
                            {/* </div> */}
                          </div>
                          <div className="detailed-device-price-parent">
                            <div className="detailed-device-price">${formatNumber(deviceData.data.price)}</div>
                            <div className="detailed-device-original-price">
                              <div className="detailed-device-discount">{deviceData.data.tax_unit_measure_device_spare.name}</div>
                            </div>
                          </div>
                          <div className="detailed-device-service-title-container">
                            <div className="detailed-device-service-title">Posted By {deviceData.data.tax_your_role.name}</div>
                          </div>
                        </div>
                      </div>
                      <div className="detailed-device-card-footer">
                        {deviceData.data.profileImage ?

                          <img
                            className="detailed-device-card-footer-child"
                            alt=""
                            src={deviceData.data.profileImage}
                          />
                          :
                          <img
                            className="detailed-device-card-footer-child"
                            alt=""
                            src={group_781_2x}
                          />

                        }


                        <div className="detailed-device-footer-divider">
                          <img
                            className="detailed-device-divider-icon4"
                            alt=""
                            src={divider2}
                          />
                        </div>
                      </div>
                      <div className="detailed-device-professional-info">
                        <div className="detailed-device-professional-details">
                          <div className="detailed-device-professional-actions">
                            <div className="detailed-device-benefits-container">
                              <div className="detailed-device-amlie-laurent">{deviceData.data.device_user.displayName}</div>
                              <Link className="detailed-device-role" to={`/business/${deviceData.data.device_business.id}/${deviceData.data.device_business.businessName}`}>
                                {deviceData.data.device_business.businessName}
                              </Link>
                            </div>
                            <div className="detailed-device-action-buttons">
                              <div className="detailed-device-contact-button">
                                <div className="detailed-device-message-button">
                                  <div className="detailed-device-navigation">
                                    <img
                                      className="detailed-device-bookmark-check-icon"
                                      alt=""
                                      src={bookmarkcheck}
                                    />
                                  </div>
                                  <div className="detailed-device-supporting-text10">
                                    Verified by Gerätor
                                  </div>
                                </div>
                              </div>
                              <div className="detailed-device-supporting-text11">
                                Adani Health conforms to Gerätor's requirements for
                                verification
                              </div>
                            </div>
                            <div className="detailed-device-favorite-button">
                              <div className="detailed-device-button-container2">
                                {!deviceData.data.isAuthor && (
                                        <div className="detailed-device-buttonsbutton5" onClick={handlePrivateMessage}>
                                          <img
                                              className="detailed-device-filter-lines-icon"
                                              alt=""
                                              src={messagechatcircle}
                                          />
                                          <div className="detailed-device-text30">Private Message</div>
                                        </div>
                                )}
                                {/* Add To Contact */}
                                {!deviceData.data.isAuthor && (
                                  (deviceData.data.addedToContact) ?
                                    <div className="detailed-device-buttonsbutton6" onClick={() => handleRemoveContact(deviceData.data.device_user.id)} style={{ cursor: 'pointer' }}>
                                      <img
                                        className="detailed-device-search-lg-icon"
                                        alt=""
                                        src={usercheck02}
                                      />
                                    </div>
                                    :
                                    <div className="detailed-device-buttonsbutton6-add" onClick={() => handleAddContact(deviceData.data.device_user.id)} style={{ cursor: 'pointer' }}>
                                      <img
                                        className="detailed-device-search-lg-icon"
                                        alt=""
                                        src={add_contact}
                                      />
                                    </div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="detailed-device-dropdown-menu" style={{ cursor: "pointer" }} onClick={toggleThreeDotsDropdownUser} ref={threeDotsRefUser}>
                            <div className="detailed-device-dropdown1">
                              <img
                                className="detailed-device-maximize-01-icon"
                                alt=""
                                src={dotsvertical}
                              />
                            </div>
                            {isThreeDotUserOpen && <ThreeDotsDetailPageUserComponent />}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="detailed-device-rectangle-parent4">
                      <div className="detailed-device-frame-child6"></div>
                      <div className="detailed-device-testimonial-header">
                        <div className="detailed-device-header-content1">
                          <div className="detailed-device-heading23">Jobs</div>
                          <div className="detailed-device-testimonial-description">
                            <div className="detailed-device-description">View All</div>
                          </div>
                        </div>
                      </div>
                      <div className="detailed-device-testimonial-list">
                        <img
                          className="detailed-device-divider-icon"
                          alt=""
                          src={divider2}
                        />

                        <div className="detailed-device-testimonial-divider">
                          <div className="detailed-device-testimonial-item">
                            <div className="detailed-device-rectangle-parent5">
                              <div className="detailed-device-frame-child7"></div>
                              <img
                                className="detailed-device-f4b711e503bc72fe396e5043e0c2-7-icon"
                                alt=""
                                src={f8595f4b711e503bc72fe396e5043e0c2_7_2x}
                              />
                            </div>
                            <div className="detailed-device-testimonial-details">
                              <div className="detailed-device-client-name">
                                WHI Program assistant / Undergraduate researcher
                              </div>
                              <div className="detailed-device-heading24">Marketing Fusion SARL</div>
                            </div>
                          </div>
                        </div>
                        <img
                          className="detailed-device-divider-icon"
                          alt=""
                          src={divider2}
                        />

                        <div className="detailed-device-testimonial-divider">
                          <div className="detailed-device-testimonial-item">
                            <div className="detailed-device-rectangle-parent5">
                              <div className="detailed-device-frame-child7"></div>
                              <img
                                className="detailed-device-f4b711e503bc72fe396e5043e0c2-7-icon"
                                alt=""
                                src={f8595f4b711e503bc72fe396e5043e0c2_7_2x}
                              />
                            </div>
                            <div className="detailed-device-testimonial-details">
                              <div className="detailed-device-client-name">
                                WHI Program assistant / Undergraduate researcher
                              </div>
                              <div className="detailed-device-heading24">Marketing Fusion SARL</div>
                            </div>
                          </div>
                        </div>
                        <img
                          className="detailed-device-divider-icon"
                          alt=""
                          src={divider2}
                        />

                        <div className="detailed-device-testimonial-divider">
                          <div className="detailed-device-testimonial-item">
                            <div className="detailed-device-rectangle-parent5">
                              <div className="detailed-device-frame-child7"></div>
                              <img
                                className="detailed-device-f4b711e503bc72fe396e5043e0c2-7-icon"
                                alt=""
                                src={f8595f4b711e503bc72fe396e5043e0c2_7_2x}
                              />
                            </div>
                            <div className="detailed-device-testimonial-details">
                              <div className="detailed-device-client-name">
                                WHI Program assistant / Undergraduate researcher
                              </div>
                              <div className="detailed-device-heading24">Marketing Fusion SARL</div>
                            </div>
                          </div>
                        </div>
                        <img
                          className="detailed-device-divider-icon"
                          alt=""
                          src={divider2}
                        />

                        <div className="detailed-device-testimonial-divider">
                          <div className="detailed-device-testimonial-item">
                            <div className="detailed-device-rectangle-parent5">
                              <div className="detailed-device-frame-child7"></div>
                              <img
                                className="detailed-device-f4b711e503bc72fe396e5043e0c2-7-icon"
                                alt=""
                                src={f8595f4b711e503bc72fe396e5043e0c2_7_2x}
                              />
                            </div>
                            <div className="detailed-device-testimonial-details">
                              <div className="detailed-device-client-name">
                                WHI Program assistant / Undergraduate researcher
                              </div>
                              <div className="detailed-device-heading24">Marketing Fusion SARL</div>
                            </div>
                          </div>
                        </div>
                        <img
                          className="detailed-device-divider-icon"
                          alt=""
                          src={divider2}
                        />

                        <div className="detailed-device-testimonial-divider">
                          <div className="detailed-device-testimonial-item">
                            <div className="detailed-device-rectangle-parent5">
                              <div className="detailed-device-frame-child7"></div>
                              <img
                                className="detailed-device-f4b711e503bc72fe396e5043e0c2-7-icon"
                                alt=""
                                src={f8595f4b711e503bc72fe396e5043e0c2_7_2x}
                              />
                            </div>
                            <div className="detailed-device-testimonial-details">
                              <div className="detailed-device-client-name">
                                WHI Program assistant / Undergraduate researcher
                              </div>
                              <div className="detailed-device-heading24">Marketing Fusion SARL</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="detailed-device-listing-image-grid">
              <div className="detailed-device-paragraph37">
                One of the best offers I found in the market
              </div>
            </div>
            <div className="detailed-device-listing-image-grid1">
              <div className="detailed-device-paragraph35">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy text
                ever since the 1500s, when an unknown printer took a galley of type
                and scrambled it to make a type specimen book.
              </div>
            </div> */}




            {/* <div className="detailed-device-buttonsbutton7">
                    <img
                      className="detailed-device-filter-lines-icon"
                      alt=""
                      src={messagedotscircle}
                    />

                    <div className="detailed-device-text35">Reply</div>
                  </div> */}


            {/* Review View static part */}
            {/* <div className="detailed-device-client-feedback-parent">

              <div className="detailed-device-client-feedback">
                <div className="detailed-device-feedback-content">
                  <img
                    className="detailed-device-feedback-content-child"
                    alt=""
                    src={group_78_2x}
                  />

                  <div className="detailed-device-feedback-header">
                    <div className="detailed-device-header-info">
                      <div className="detailed-device-heading20">Chloe Tammy</div>
                      <div className="detailed-device-heading21">
                        <span>Marketing Communications at</span>
                        <span className="detailed-device-alvo-medical"> Alvo Medical</span>
                      </div>
                    </div>
                  </div>
                  <div className="detailed-device-feedback-rating">
                   
                    <div className="detailed-device-feedback-score">
                      <div className="detailed-device-stars-parent">
                        <div className="detailed-device-stars">
                          <img
                            className="detailed-device-filter-lines-icon"
                            alt=""
                            src={star_icon}
                          />

                          <img
                            className="detailed-device-filter-lines-icon"
                            alt=""
                            src={star_icon}
                          />

                          <img
                            className="detailed-device-filter-lines-icon"
                            alt=""
                            src={star_icon}
                          />

                          <img
                            className="detailed-device-filter-lines-icon"
                            alt=""
                            src={star_icon}
                          />

                          <img
                            className="detailed-device-filter-lines-icon"
                            alt=""
                            src={star_icon}
                          />
                        </div>
                        <div className="detailed-device-rating-value">
                          <div className="detailed-device-heading22">1 month ago</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="detailed-device-client-message">
                <div className="detailed-device-message-content">
                  <div className="detailed-device-paragraph39">
                    One of the best offers I found in the market
                  </div>
                  <div className="detailed-device-paragraph26">
                    Lorem Ipsum is simply dummy text of the printing and typesetting
                    industry. Lorem Ipsum has been the industry's standard dummy text
                    ever since the 1500s, when an unknown printer took a gallery of
                    type and scrambled it to make a type specimen book.
                  </div>
                </div>
              </div>
              <div className="detailed-device-view-replies">
                <div className="detailed-device-buttonsbutton8" onClick={handleReplyClick}>
                  <img
                    className="detailed-device-filter-lines-icon"
                    alt=""
                    src={messagedotscircle}
                  />

                  <div className="detailed-device-text35" >Reply</div>
                </div>
              </div>

              {showReply && (
                <div className="detailed-device-reply-section">
                  <textarea
                    className="detailed-device-reply-textarea"
                    value={replyText}
                    onChange={handleChange}
                    placeholder="Type your reply..."
                  />

                  <div className={`detailed-device-char-counter ${warningClass}`}>
                    {maxLength - replyText.length} characters left
                  </div>

                  <button className="detailed-device-reply-submit-btn" onClick={handleSubmit}>
                    Submit Reply
                  </button>
                </div>
              )}

            </div>
            <div className="detailed-device-pagination-wrapper">
              <div className="detailed-device-pagination">
                <div className="detailed-device-button-wrap">
                  <div className="detailed-device-buttonsbutton9">
                    <img
                      className="detailed-device-placeholder-icon4"
                      alt=""
                      src={placeholder1}
                    />

                    <div className="detailed-device-text-padding">
                      <div className="detailed-device-page-number">Previous</div>
                    </div>
                    <img
                      className="detailed-device-placeholder-icon4"
                      alt=""
                      src={placeholder1}
                    />
                  </div>
                </div>
                <div className="detailed-device-details">1 of 10</div>
                <div className="detailed-device-button-wrap1">
                  <div className="detailed-device-buttonsbutton10">
                    <img
                      className="detailed-device-placeholder-icon4"
                      alt=""
                      src={placeholder1}
                    />

                    <div className="detailed-device-text-padding">
                      <div className="detailed-device-text37">Next</div>
                    </div>
                    <img
                      className="detailed-device-placeholder-icon4"
                      alt=""
                      src={placeholder1}
                    />
                  </div>
                </div>
              </div>
            </div> */}



            <ReviewDeviceView isAuthor={deviceData.data.isAuthor} listingId={id} currentUsermeta={currentUsermeta} />


            <div className="detailed-device-listing-image-grid1">
              <img className="detailed-device-divider-icon3" alt="" src={divider1} />
            </div>

            {/* Review Add Form component */}
            {(!deviceData.data.isAuthor && (!deviceData.data.isReviewed) && <AddReviewDeviceForm listingId={id} currentUsermeta={currentUsermeta} formikRef={formikRef} />)
            }

            <div className="detailed-device-listing-image-grid3">
              <img className="detailed-device-divider-icon11" alt="" src={divider3} />
            </div>
            <div className="detailed-device-listing-image-grid4">
              <div className="detailed-device-heading-parent3">
                <div className="detailed-device-heading36">Related Devices</div>
                <div className="detailed-device-text-wrapper">
                  <div className="detailed-device-description">View All</div>
                </div>
              </div>
            </div>
            <div className="detailed-device-listing-image-grid5">
              <div className="detailed-device-frame-parent4">
                <div className="detailed-device-frame-parent5">
                  <div className="detailed-device-rectangle-parent10">
                    <div className="detailed-device-frame-child12"></div>
                    <div className="detailed-device-listing-card-badge-overlay">
                      <img
                        className="detailed-device-listing-card-badge-overlay-child"
                        alt=""
                        src={rectangle_95_2x}
                      />

                      <div className="detailed-device-image-overlay">
                        <div className="detailed-device-badge4">
                          <div className="detailed-device-listing-card-price">Assured</div>
                        </div>
                        <div className="detailed-device-short-listing-team">
                          <div className="detailed-device-badge5">
                            <div className="detailed-device-listing-card-radar">ID# AXJYN</div>
                          </div>
                          <div className="detailed-device-buttonsbutton11">
                            <img
                              className="detailed-device-magnifier-1-icon"
                              alt=""
                              src={radar_1_1_2x}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="detailed-device-listing-detail-item">
                      <div className="detailed-device-message-content">
                        <div className="detailed-device-text-frame">
                          <div className="detailed-device-text42">
                            New / Surplus 2021 Philips Health-care HD11 XE
                            (P/N:23A...
                          </div>
                        </div>
                        <div className="detailed-device-listing-detail-item-value">
                          <div className="detailed-device-listing-detail-item-pin-wrapper">
                            <div className="detailed-device-heading-container">
                              <div className="detailed-device-listing-detail-item-location">
                                <div className="detailed-device-listing-detail-item-date-parent">
                                  <div className="detailed-device-listing-detail-item-date">
                                    <img
                                      className="detailed-device-chevron-right-icon2"
                                      alt=""
                                      src={markerpin021}
                                    />
                                  </div>
                                  <div className="detailed-device-location">India, New Delhi</div>
                                </div>
                                <div className="detailed-device-listing-detail-item-date-parent">
                                  <div className="detailed-device-listing-detail-item-date">
                                    <img
                                      className="detailed-device-chevron-right-icon2"
                                      alt=""
                                      src={calendar1}
                                    />
                                  </div>
                                  <div className="detailed-device-type-of-work">April 09, 2023</div>
                                </div>
                              </div>
                              <div className="detailed-device-listing-team">
                                <div className="detailed-device-listing-team-member-wrapper">
                                  <div className="detailed-device-listing-team-member">4.9/5</div>
                                </div>
                                <div className="detailed-device-contact-role">
                                  <div className="detailed-device-supporting-text12">
                                    99,999 reviews
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="detailed-device-listing">
                            <div className="detailed-device-breadcrumb-items">
                              <div className="detailed-device-listing-short-info">$9,999,999.99</div>
                              <div className="detailed-device-supporting-text-wrapper">
                                <div className="detailed-device-supporting-text13">USD-Per Unit</div>
                              </div>
                            </div>
                          </div>
                          <img
                            className="detailed-device-divider-icon"
                            alt=""
                            src={divider4}
                          />

                          <div className="detailed-device-listing-detail-item-value-inner">
                            <div className="detailed-device-footer-social-parent">
                              <div className="detailed-device-footer-social">
                                <img
                                  className="detailed-device-footer-social-child"
                                  alt=""
                                  src={group_58_2x}
                                />

                                <div className="detailed-device-listing-short-detail-item-pin-parent">
                                  <div className="detailed-device-listing-short-detail-item-pin">
                                    <div className="detailed-device-name">Olivia Rhye</div>
                                  </div>
                                  <div className="detailed-device-role1">Business Name</div>
                                </div>
                              </div>
                              <div className="detailed-device-listing-short-detail-item-date">
                                <img
                                  className="detailed-device-icon1"
                                  alt=""
                                  src={icon1}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="detailed-device-rectangle-parent10">
                    <div className="detailed-device-frame-child12"></div>
                    <div className="detailed-device-listing-card-badge-overlay">
                      <img
                        className="detailed-device-listing-card-badge-overlay-child"
                        alt=""
                        src={rectangle_95_2x}
                      />

                      <div className="detailed-device-image-overlay">
                        <div className="detailed-device-badge4">
                          <div className="detailed-device-listing-card-price">Assured</div>
                        </div>
                        <div className="detailed-device-short-listing-team">
                          <div className="detailed-device-badge5">
                            <div className="detailed-device-listing-card-radar">ID# AXJYN</div>
                          </div>
                          <div className="detailed-device-buttonsbutton11">
                            <img
                              className="detailed-device-magnifier-1-icon"
                              alt=""
                              src={radar_1_1_2x}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="detailed-device-listing-detail-item">
                      <div className="detailed-device-message-content">
                        <div className="detailed-device-text-frame">
                          <div className="detailed-device-text42">
                            New / Surplus 2021 Philips Health-care HD11 XE
                            (P/N:23A...
                          </div>
                        </div>
                        <div className="detailed-device-listing-detail-item-value">
                          <div className="detailed-device-listing-detail-item-pin-wrapper">
                            <div className="detailed-device-heading-container">
                              <div className="detailed-device-listing-detail-item-location">
                                <div className="detailed-device-listing-detail-item-date-parent">
                                  <div className="detailed-device-listing-detail-item-date">
                                    <img
                                      className="detailed-device-chevron-right-icon2"
                                      alt=""
                                      src={markerpin021}
                                    />
                                  </div>
                                  <div className="detailed-device-location">India, New Delhi</div>
                                </div>
                                <div className="detailed-device-listing-detail-item-date-parent">
                                  <div className="detailed-device-listing-detail-item-date">
                                    <img
                                      className="detailed-device-chevron-right-icon2"
                                      alt=""
                                      src={calendar1}
                                    />
                                  </div>
                                  <div className="detailed-device-type-of-work">April 09, 2023</div>
                                </div>
                              </div>
                              <div className="detailed-device-listing-team">
                                <div className="detailed-device-listing-team-member-wrapper">
                                  <div className="detailed-device-listing-team-member">4.9/5</div>
                                </div>
                                <div className="detailed-device-contact-role">
                                  <div className="detailed-device-supporting-text12">
                                    99,999 reviews
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="detailed-device-listing">
                            <div className="detailed-device-breadcrumb-items">
                              <div className="detailed-device-listing-short-info">$9,999,999.99</div>
                              <div className="detailed-device-supporting-text-wrapper">
                                <div className="detailed-device-supporting-text13">USD-Per Unit</div>
                              </div>
                            </div>
                          </div>
                          <img
                            className="detailed-device-divider-icon"
                            alt=""
                            src={divider4}
                          />

                          <div className="detailed-device-listing-detail-item-value-inner">
                            <div className="detailed-device-footer-social-parent">
                              <div className="detailed-device-footer-social">
                                <img
                                  className="detailed-device-footer-social-child"
                                  alt=""
                                  src={group_58_2x}
                                />

                                <div className="detailed-device-listing-short-detail-item-pin-parent">
                                  <div className="detailed-device-listing-short-detail-item-pin">
                                    <div className="detailed-device-name">Olivia Rhye</div>
                                  </div>
                                  <div className="detailed-device-role1">Business Name</div>
                                </div>
                              </div>
                              <div className="detailed-device-listing-short-detail-item-date">
                                <img
                                  className="detailed-device-icon1"
                                  alt=""
                                  src={icon1}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="detailed-device-rectangle-parent10">
                    <div className="detailed-device-frame-child12"></div>
                    <div className="detailed-device-listing-card-badge-overlay">
                      <img
                        className="detailed-device-listing-card-badge-overlay-child"
                        alt=""
                        src={rectangle_95_2x}
                      />

                      <div className="detailed-device-image-overlay">
                        <div className="detailed-device-badge4">
                          <div className="detailed-device-listing-card-price">Assured</div>
                        </div>
                        <div className="detailed-device-short-listing-team">
                          <div className="detailed-device-badge5">
                            <div className="detailed-device-listing-card-radar">ID# AXJYN</div>
                          </div>
                          <div className="detailed-device-buttonsbutton11">
                            <img
                              className="detailed-device-magnifier-1-icon"
                              alt=""
                              src={radar_1_1_2x}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="detailed-device-listing-detail-item">
                      <div className="detailed-device-message-content">
                        <div className="detailed-device-text-frame">
                          <div className="detailed-device-text42">
                            New / Surplus 2021 Philips Health-care HD11 XE
                            (P/N:23A...
                          </div>
                        </div>
                        <div className="detailed-device-listing-detail-item-value">
                          <div className="detailed-device-listing-detail-item-pin-wrapper">
                            <div className="detailed-device-heading-container">
                              <div className="detailed-device-listing-detail-item-location">
                                <div className="detailed-device-listing-detail-item-date-parent">
                                  <div className="detailed-device-listing-detail-item-date">
                                    <img
                                      className="detailed-device-chevron-right-icon2"
                                      alt=""
                                      src={markerpin021}
                                    />
                                  </div>
                                  <div className="detailed-device-location">India, New Delhi</div>
                                </div>
                                <div className="detailed-device-listing-detail-item-date-parent">
                                  <div className="detailed-device-listing-detail-item-date">
                                    <img
                                      className="detailed-device-chevron-right-icon2"
                                      alt=""
                                      src={calendar1}
                                    />
                                  </div>
                                  <div className="detailed-device-type-of-work">April 09, 2023</div>
                                </div>
                              </div>
                              <div className="detailed-device-listing-team">
                                <div className="detailed-device-listing-team-member-wrapper">
                                  <div className="detailed-device-listing-team-member">4.9/5</div>
                                </div>
                                <div className="detailed-device-contact-role">
                                  <div className="detailed-device-supporting-text12">
                                    99,999 reviews
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="detailed-device-listing">
                            <div className="detailed-device-breadcrumb-items">
                              <div className="detailed-device-listing-short-info">$9,999,999.99</div>
                              <div className="detailed-device-supporting-text-wrapper">
                                <div className="detailed-device-supporting-text13">USD-Per Unit</div>
                              </div>
                            </div>
                          </div>
                          <img
                            className="detailed-device-divider-icon"
                            alt=""
                            src={divider4}
                          />

                          <div className="detailed-device-listing-detail-item-value-inner">
                            <div className="detailed-device-footer-social-parent">
                              <div className="detailed-device-footer-social">
                                <img
                                  className="detailed-device-footer-social-child"
                                  alt=""
                                  src={group_58_2x}
                                />

                                <div className="detailed-device-listing-short-detail-item-pin-parent">
                                  <div className="detailed-device-listing-short-detail-item-pin">
                                    <div className="detailed-device-name">Olivia Rhye</div>
                                  </div>
                                  <div className="detailed-device-role1">Business Name</div>
                                </div>
                              </div>
                              <div className="detailed-device-listing-short-detail-item-date">
                                <img
                                  className="detailed-device-icon1"
                                  alt=""
                                  src={icon1}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="detailed-device-rectangle-parent10">
                    <div className="detailed-device-frame-child12"></div>
                    <div className="detailed-device-listing-card-badge-overlay">
                      <img
                        className="detailed-device-listing-card-badge-overlay-child"
                        alt=""
                        src={rectangle_95_2x}
                      />

                      <div className="detailed-device-image-overlay3">
                        <div className="detailed-device-badge4">
                          <div className="detailed-device-listing-card-price">Assured</div>
                        </div>
                        <div className="detailed-device-badge-group">
                          <div className="detailed-device-badge5">
                            <div className="detailed-device-listing-card-radar">ID# AXJYN</div>
                          </div>
                          <div className="detailed-device-buttonsbutton14">
                            <img
                              className="detailed-device-magnifier-1-icon"
                              alt=""
                              src={radar_1_1_2x}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="detailed-device-listing-detail-item">
                      <div className="detailed-device-message-content">
                        <div className="detailed-device-text-frame">
                          <div className="detailed-device-text42">
                            New / Surplus 2021 Philips Health-care HD11 XE
                            (P/N:23A...
                          </div>
                        </div>
                        <div className="detailed-device-work-four-wrapper">
                          <div className="detailed-device-heading-container">
                            <div className="detailed-device-listing-detail-item-location">
                              <div className="detailed-device-listing-detail-item-date-parent">
                                <div className="detailed-device-listing-detail-item-date">
                                  <img
                                    className="detailed-device-chevron-right-icon2"
                                    alt=""
                                    src={markerpin021}
                                  />
                                </div>
                                <div className="detailed-device-location">India, New Delhi</div>
                              </div>
                              <div className="detailed-device-listing-detail-item-date-parent">
                                <div className="detailed-device-listing-detail-item-date">
                                  <img
                                    className="detailed-device-chevron-right-icon2"
                                    alt=""
                                    src={calendar1}
                                  />
                                </div>
                                <div className="detailed-device-type-of-work">April 09, 2023</div>
                              </div>
                            </div>
                            <div className="detailed-device-listing-team">
                              <div className="detailed-device-listing-team-member-wrapper">
                                <div className="detailed-device-listing-team-member">4.9/5</div>
                              </div>
                              <div className="detailed-device-contact-role">
                                <div className="detailed-device-supporting-text12">99,999 reviews</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="detailed-device-agent-details-icon">
                          <img className="detailed-device-icon4" alt="" src={icon1} />

                          <div className="detailed-device-agent-extra-info">
                            <div className="detailed-device-listing-detail-item-pin-wrapper">
                              <div className="detailed-device-breadcrumb-items">
                                <div className="detailed-device-listing-short-info">$9,999,999.99</div>
                                <div className="detailed-device-supporting-text-wrapper">
                                  <div className="detailed-device-supporting-text13">USD-Per Unit</div>
                                </div>
                              </div>
                            </div>
                            <img
                              className="detailed-device-divider-icon"
                              alt=""
                              src={divider4}
                            />

                            <div className="detailed-device-another-agent-info">
                              <div className="detailed-device-detail-items">
                                <img
                                  className="detailed-device-footer-social-child"
                                  alt=""
                                  src={group_58_2x}
                                />

                                <div className="detailed-device-listing-short-detail-item-pin-parent">
                                  <div className="detailed-device-listing-short-detail-item-pin">
                                    <div className="detailed-device-name">Olivia Rhye</div>
                                  </div>
                                  <div className="detailed-device-role1">Business Name</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="detailed-device-testiomonial-carousel-arrow2">
                    <img
                      className="detailed-device-arrow-left-icon"
                      alt=""
                      src={arrowleft}
                    />
                  </div>
                </div>
                <div className="detailed-device-testiomonial-carousel-arrow3">
                  <img
                    className="detailed-device-arrow-left-icon"
                    alt=""
                    src={arrowright}
                  />
                </div>
              </div>
            </div>
            <div className="detailed-device-listing-image-grid4">
              <div className="detailed-device-heading-parent3">
                <div className="detailed-device-heading36">Related Spares</div>
                <div className="detailed-device-testimonial-description">
                  <div className="detailed-device-description">View All</div>
                </div>
              </div>
            </div>
            <div className="detailed-device-listing-image-grid5">
              <div className="detailed-device-frame-parent4">
                <div className="detailed-device-frame-parent5">
                  <div className="detailed-device-rectangle-parent10">
                    <div className="detailed-device-frame-child12"></div>
                    <div className="detailed-device-listing-card-badge-overlay">
                      <img
                        className="detailed-device-listing-card-badge-overlay-child"
                        alt=""
                        src={rectangle_95_2x}
                      />

                      <div className="detailed-device-image-overlay">
                        <div className="detailed-device-badge4">
                          <div className="detailed-device-listing-card-price">Assured</div>
                        </div>
                        <div className="detailed-device-short-listing-team">
                          <div className="detailed-device-badge5">
                            <div className="detailed-device-listing-card-radar">ID# AXJYN</div>
                          </div>
                          <div className="detailed-device-buttonsbutton11">
                            <img
                              className="detailed-device-magnifier-1-icon"
                              alt=""
                              src={radar_1_1_2x}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="detailed-device-listing-detail-item">
                      <div className="detailed-device-message-content">
                        <div className="detailed-device-text-frame">
                          <div className="detailed-device-text42">
                            New / Surplus 2021 Philips Health-care HD11 XE
                            (P/N:23A...
                          </div>
                        </div>
                        <div className="detailed-device-listing-detail-item-value">
                          <div className="detailed-device-listing-detail-item-pin-wrapper">
                            <div className="detailed-device-heading-container">
                              <div className="detailed-device-listing-detail-item-location">
                                <div className="detailed-device-listing-detail-item-date-parent">
                                  <div className="detailed-device-listing-detail-item-date">
                                    <img
                                      className="detailed-device-chevron-right-icon2"
                                      alt=""
                                      src={markerpin021}
                                    />
                                  </div>
                                  <div className="detailed-device-location">India, New Delhi</div>
                                </div>
                                <div className="detailed-device-listing-detail-item-date-parent">
                                  <div className="detailed-device-listing-detail-item-date">
                                    <img
                                      className="detailed-device-chevron-right-icon2"
                                      alt=""
                                      src={calendar1}
                                    />
                                  </div>
                                  <div className="detailed-device-type-of-work">April 09, 2023</div>
                                </div>
                              </div>
                              <div className="detailed-device-listing-team">
                                <div className="detailed-device-listing-team-member-wrapper">
                                  <div className="detailed-device-listing-team-member">4.9/5</div>
                                </div>
                                <div className="detailed-device-contact-role">
                                  <div className="detailed-device-supporting-text12">
                                    99,999 reviews
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="detailed-device-listing">
                            <div className="detailed-device-breadcrumb-items">
                              <div className="detailed-device-listing-short-info">$9,999,999.99</div>
                              <div className="detailed-device-supporting-text-wrapper">
                                <div className="detailed-device-supporting-text13">USD-Per Unit</div>
                              </div>
                            </div>
                          </div>
                          <img
                            className="detailed-device-divider-icon"
                            alt=""
                            src={divider4}
                          />

                          <div className="detailed-device-listing-detail-item-value-inner">
                            <div className="detailed-device-footer-social-parent">
                              <div className="detailed-device-footer-social">
                                <img
                                  className="detailed-device-footer-social-child"
                                  alt=""
                                  src={group_58_2x}
                                />

                                <div className="detailed-device-listing-short-detail-item-pin-parent">
                                  <div className="detailed-device-listing-short-detail-item-pin">
                                    <div className="detailed-device-name">Olivia Rhye</div>
                                  </div>
                                  <div className="detailed-device-role1">Business Name</div>
                                </div>
                              </div>
                              <div className="detailed-device-listing-short-detail-item-date">
                                <img
                                  className="detailed-device-icon1"
                                  alt=""
                                  src={icon1}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="detailed-device-rectangle-parent10">
                    <div className="detailed-device-frame-child12"></div>
                    <div className="detailed-device-listing-card-badge-overlay">
                      <img
                        className="detailed-device-listing-card-badge-overlay-child"
                        alt=""
                        src={rectangle_95_2x}
                      />

                      <div className="detailed-device-image-overlay">
                        <div className="detailed-device-badge4">
                          <div className="detailed-device-listing-card-price">Assured</div>
                        </div>
                        <div className="detailed-device-short-listing-team">
                          <div className="detailed-device-badge5">
                            <div className="detailed-device-listing-card-radar">ID# AXJYN</div>
                          </div>
                          <div className="detailed-device-buttonsbutton11">
                            <img
                              className="detailed-device-magnifier-1-icon"
                              alt=""
                              src={radar_1_1_2x}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="detailed-device-listing-detail-item">
                      <div className="detailed-device-message-content">
                        <div className="detailed-device-text-frame">
                          <div className="detailed-device-text42">
                            New / Surplus 2021 Philips Health-care HD11 XE
                            (P/N:23A...
                          </div>
                        </div>
                        <div className="detailed-device-listing-detail-item-value">
                          <div className="detailed-device-listing-detail-item-pin-wrapper">
                            <div className="detailed-device-heading-container">
                              <div className="detailed-device-listing-detail-item-location">
                                <div className="detailed-device-listing-detail-item-date-parent">
                                  <div className="detailed-device-listing-detail-item-date">
                                    <img
                                      className="detailed-device-chevron-right-icon2"
                                      alt=""
                                      src={markerpin021}
                                    />
                                  </div>
                                  <div className="detailed-device-location">India, New Delhi</div>
                                </div>
                                <div className="detailed-device-listing-detail-item-date-parent">
                                  <div className="detailed-device-listing-detail-item-date">
                                    <img
                                      className="detailed-device-chevron-right-icon2"
                                      alt=""
                                      src={calendar1}
                                    />
                                  </div>
                                  <div className="detailed-device-type-of-work">April 09, 2023</div>
                                </div>
                              </div>
                              <div className="detailed-device-listing-team">
                                <div className="detailed-device-listing-team-member-wrapper">
                                  <div className="detailed-device-listing-team-member">4.9/5</div>
                                </div>
                                <div className="detailed-device-contact-role">
                                  <div className="detailed-device-supporting-text12">
                                    99,999 reviews
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="detailed-device-listing">
                            <div className="detailed-device-breadcrumb-items">
                              <div className="detailed-device-listing-short-info">$9,999,999.99</div>
                              <div className="detailed-device-supporting-text-wrapper">
                                <div className="detailed-device-supporting-text13">USD-Per Unit</div>
                              </div>
                            </div>
                          </div>
                          <img
                            className="detailed-device-divider-icon"
                            alt=""
                            src={divider4}
                          />

                          <div className="detailed-device-listing-detail-item-value-inner">
                            <div className="detailed-device-footer-social-parent">
                              <div className="detailed-device-footer-social">
                                <img
                                  className="detailed-device-footer-social-child"
                                  alt=""
                                  src={group_58_2x}
                                />

                                <div className="detailed-device-listing-short-detail-item-pin-parent">
                                  <div className="detailed-device-listing-short-detail-item-pin">
                                    <div className="detailed-device-name">Olivia Rhye</div>
                                  </div>
                                  <div className="detailed-device-role1">Business Name</div>
                                </div>
                              </div>
                              <div className="detailed-device-listing-short-detail-item-date">
                                <img
                                  className="detailed-device-icon1"
                                  alt=""
                                  src={icon1}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="detailed-device-rectangle-parent10">
                    <div className="detailed-device-frame-child12"></div>
                    <div className="detailed-device-listing-card-badge-overlay">
                      <img
                        className="detailed-device-listing-card-badge-overlay-child"
                        alt=""
                        src={rectangle_95_2x}
                      />

                      <div className="detailed-device-image-overlay">
                        <div className="detailed-device-badge4">
                          <div className="detailed-device-listing-card-price">Assured</div>
                        </div>
                        <div className="detailed-device-short-listing-team">
                          <div className="detailed-device-badge5">
                            <div className="detailed-device-listing-card-radar">ID# AXJYN</div>
                          </div>
                          <div className="detailed-device-buttonsbutton11">
                            <img
                              className="detailed-device-magnifier-1-icon"
                              alt=""
                              src={radar_1_1_2x}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="detailed-device-listing-detail-item">
                      <div className="detailed-device-message-content">
                        <div className="detailed-device-text-frame">
                          <div className="detailed-device-text42">
                            New / Surplus 2021 Philips Health-care HD11 XE
                            (P/N:23A...
                          </div>
                        </div>
                        <div className="detailed-device-listing-detail-item-value">
                          <div className="detailed-device-listing-detail-item-pin-wrapper">
                            <div className="detailed-device-heading-container">
                              <div className="detailed-device-listing-detail-item-location">
                                <div className="detailed-device-listing-detail-item-date-parent">
                                  <div className="detailed-device-listing-detail-item-date">
                                    <img
                                      className="detailed-device-chevron-right-icon2"
                                      alt=""
                                      src={markerpin021}
                                    />
                                  </div>
                                  <div className="detailed-device-location">India, New Delhi</div>
                                </div>
                                <div className="detailed-device-listing-detail-item-date-parent">
                                  <div className="detailed-device-listing-detail-item-date">
                                    <img
                                      className="detailed-device-chevron-right-icon2"
                                      alt=""
                                      src={calendar1}
                                    />
                                  </div>
                                  <div className="detailed-device-type-of-work">April 09, 2023</div>
                                </div>
                              </div>
                              <div className="detailed-device-listing-team">
                                <div className="detailed-device-listing-team-member-wrapper">
                                  <div className="detailed-device-listing-team-member">4.9/5</div>
                                </div>
                                <div className="detailed-device-contact-role">
                                  <div className="detailed-device-supporting-text12">
                                    99,999 reviews
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="detailed-device-listing">
                            <div className="detailed-device-breadcrumb-items">
                              <div className="detailed-device-listing-short-info">$9,999,999.99</div>
                              <div className="detailed-device-supporting-text-wrapper">
                                <div className="detailed-device-supporting-text13">USD-Per Unit</div>
                              </div>
                            </div>
                          </div>
                          <img
                            className="detailed-device-divider-icon"
                            alt=""
                            src={divider4}
                          />

                          <div className="detailed-device-listing-detail-item-value-inner">
                            <div className="detailed-device-footer-social-parent">
                              <div className="detailed-device-footer-social">
                                <img
                                  className="detailed-device-footer-social-child"
                                  alt=""
                                  src={group_58_2x}
                                />

                                <div className="detailed-device-listing-short-detail-item-pin-parent">
                                  <div className="detailed-device-listing-short-detail-item-pin">
                                    <div className="detailed-device-name">Olivia Rhye</div>
                                  </div>
                                  <div className="detailed-device-role1">Business Name</div>
                                </div>
                              </div>
                              <div className="detailed-device-listing-short-detail-item-date">
                                <img
                                  className="detailed-device-icon1"
                                  alt=""
                                  src={icon1}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="detailed-device-rectangle-parent10">
                    <div className="detailed-device-frame-child12"></div>
                    <div className="detailed-device-listing-card-badge-overlay">
                      <img
                        className="detailed-device-listing-card-badge-overlay-child"
                        alt=""
                        src={rectangle_95_2x}
                      />

                      <div className="detailed-device-image-overlay3">
                        <div className="detailed-device-badge4">
                          <div className="detailed-device-listing-card-price">Assured</div>
                        </div>
                        <div className="detailed-device-badge-group">
                          <div className="detailed-device-badge5">
                            <div className="detailed-device-listing-card-radar">ID# AXJYN</div>
                          </div>
                          <div className="detailed-device-buttonsbutton14">
                            <img
                              className="detailed-device-magnifier-1-icon"
                              alt=""
                              src={radar_1_1_2x}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="detailed-device-listing-detail-item">
                      <div className="detailed-device-message-content">
                        <div className="detailed-device-text-frame">
                          <div className="detailed-device-text42">
                            New / Surplus 2021 Philips Health-care HD11 XE
                            (P/N:23A...
                          </div>
                        </div>
                        <div className="detailed-device-work-four-wrapper">
                          <div className="detailed-device-heading-container">
                            <div className="detailed-device-listing-detail-item-location">
                              <div className="detailed-device-listing-detail-item-date-parent">
                                <div className="detailed-device-listing-detail-item-date">
                                  <img
                                    className="detailed-device-chevron-right-icon2"
                                    alt=""
                                    src={markerpin021}
                                  />
                                </div>
                                <div className="detailed-device-location">India, New Delhi</div>
                              </div>
                              <div className="detailed-device-listing-detail-item-date-parent">
                                <div className="detailed-device-listing-detail-item-date">
                                  <img
                                    className="detailed-device-chevron-right-icon2"
                                    alt=""
                                    src={calendar1}
                                  />
                                </div>
                                <div className="detailed-device-type-of-work">April 09, 2023</div>
                              </div>
                            </div>
                            <div className="detailed-device-listing-team">
                              <div className="detailed-device-listing-team-member-wrapper">
                                <div className="detailed-device-listing-team-member">4.9/5</div>
                              </div>
                              <div className="detailed-device-contact-role">
                                <div className="detailed-device-supporting-text12">99,999 reviews</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="detailed-device-agent-details-icon">
                          <img className="detailed-device-icon4" alt="" src={icon1} />

                          <div className="detailed-device-agent-extra-info">
                            <div className="detailed-device-listing-detail-item-pin-wrapper">
                              <div className="detailed-device-breadcrumb-items">
                                <div className="detailed-device-listing-short-info">$9,999,999.99</div>
                                <div className="detailed-device-supporting-text-wrapper">
                                  <div className="detailed-device-supporting-text13">USD-Per Unit</div>
                                </div>
                              </div>
                            </div>
                            <img
                              className="detailed-device-divider-icon"
                              alt=""
                              src={divider4}
                            />

                            <div className="detailed-device-another-agent-info">
                              <div className="detailed-device-detail-items">
                                <img
                                  className="detailed-device-footer-social-child"
                                  alt=""
                                  src={group_58_2x}
                                />

                                <div className="detailed-device-listing-short-detail-item-pin-parent">
                                  <div className="detailed-device-listing-short-detail-item-pin">
                                    <div className="detailed-device-name">Olivia Rhye</div>
                                  </div>
                                  <div className="detailed-device-role1">Business Name</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="detailed-device-testiomonial-carousel-arrow4">
                    <img
                      className="detailed-device-arrow-left-icon"
                      alt=""
                      src={arrowleft}
                    />
                  </div>
                </div>
                <div className="detailed-device-testiomonial-carousel-arrow5">
                  <img
                    className="detailed-device-arrow-left-icon"
                    alt=""
                    src={arrowright}
                  />
                </div>
              </div>
            </div>
            <div className="detailed-device-listing-image-grid4">
              <div className="detailed-device-heading-parent3">
                <div className="detailed-device-heading38">Related Services</div>
                <div className="detailed-device-testimonial-description">
                  <div className="detailed-device-description">View All</div>
                </div>
              </div>
            </div>
            <div className="detailed-device-listing-image-grid5">
              <div className="detailed-device-frame-parent4">
                <div className="detailed-device-frame-parent5">
                  <div className="detailed-device-rectangle-parent10">
                    <div className="detailed-device-frame-child12"></div>
                    <div className="detailed-device-listing-card-badge-overlay">
                      <img
                        className="detailed-device-listing-card-badge-overlay-child"
                        alt=""
                        src={rectangle_95_2x}
                      />

                      <div className="detailed-device-image-overlay">
                        <div className="detailed-device-badge4">
                          <div className="detailed-device-listing-card-price">Assured</div>
                        </div>
                        <div className="detailed-device-short-listing-team">
                          <div className="detailed-device-badge5">
                            <div className="detailed-device-listing-card-radar">ID# AXJYN</div>
                          </div>
                          <div className="detailed-device-buttonsbutton11">
                            <img
                              className="detailed-device-magnifier-1-icon"
                              alt=""
                              src={radar_1_1_2x}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="detailed-device-listing-detail-item">
                      <div className="detailed-device-message-content">
                        <div className="detailed-device-text-frame">
                          <div className="detailed-device-text42">
                            New / Surplus 2021 Philips Health-care HD11 XE
                            (P/N:23A...
                          </div>
                        </div>
                        <div className="detailed-device-listing-detail-item-value">
                          <div className="detailed-device-listing-detail-item-pin-wrapper">
                            <div className="detailed-device-heading-container">
                              <div className="detailed-device-listing-detail-item-location">
                                <div className="detailed-device-listing-detail-item-date-parent">
                                  <div className="detailed-device-listing-detail-item-date">
                                    <img
                                      className="detailed-device-chevron-right-icon2"
                                      alt=""
                                      src={markerpin021}
                                    />
                                  </div>
                                  <div className="detailed-device-location">India, New Delhi</div>
                                </div>
                                <div className="detailed-device-listing-detail-item-date-parent">
                                  <div className="detailed-device-listing-detail-item-date">
                                    <img
                                      className="detailed-device-chevron-right-icon2"
                                      alt=""
                                      src={calendar1}
                                    />
                                  </div>
                                  <div className="detailed-device-type-of-work">April 09, 2023</div>
                                </div>
                              </div>
                              <div className="detailed-device-listing-team">
                                <div className="detailed-device-listing-team-member-wrapper">
                                  <div className="detailed-device-listing-team-member">4.9/5</div>
                                </div>
                                <div className="detailed-device-contact-role">
                                  <div className="detailed-device-supporting-text12">
                                    99,999 reviews
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="detailed-device-listing">
                            <div className="detailed-device-breadcrumb-items">
                              <div className="detailed-device-listing-short-info">$9,999,999.99</div>
                              <div className="detailed-device-supporting-text-wrapper">
                                <div className="detailed-device-supporting-text13">USD-Per Unit</div>
                              </div>
                            </div>
                          </div>
                          <img
                            className="detailed-device-divider-icon"
                            alt=""
                            src={divider4}
                          />

                          <div className="detailed-device-listing-detail-item-value-inner">
                            <div className="detailed-device-footer-social-parent">
                              <div className="detailed-device-footer-social">
                                <img
                                  className="detailed-device-footer-social-child"
                                  alt=""
                                  src={group_58_2x}
                                />

                                <div className="detailed-device-listing-short-detail-item-pin-parent">
                                  <div className="detailed-device-listing-short-detail-item-pin">
                                    <div className="detailed-device-name">Olivia Rhye</div>
                                  </div>
                                  <div className="detailed-device-role1">Business Name</div>
                                </div>
                              </div>
                              <div className="detailed-device-listing-short-detail-item-date">
                                <img
                                  className="detailed-device-icon1"
                                  alt=""
                                  src={icon1}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="detailed-device-rectangle-parent10">
                    <div className="detailed-device-frame-child12"></div>
                    <div className="detailed-device-listing-card-badge-overlay">
                      <img
                        className="detailed-device-listing-card-badge-overlay-child"
                        alt=""
                        src={rectangle_95_2x}
                      />

                      <div className="detailed-device-image-overlay">
                        <div className="detailed-device-badge4">
                          <div className="detailed-device-listing-card-price">Assured</div>
                        </div>
                        <div className="detailed-device-short-listing-team">
                          <div className="detailed-device-badge5">
                            <div className="detailed-device-listing-card-radar">ID# AXJYN</div>
                          </div>
                          <div className="detailed-device-buttonsbutton11">
                            <img
                              className="detailed-device-magnifier-1-icon"
                              alt=""
                              src={radar_1_1_2x}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="detailed-device-listing-detail-item">
                      <div className="detailed-device-message-content">
                        <div className="detailed-device-text-frame">
                          <div className="detailed-device-text42">
                            New / Surplus 2021 Philips Health-care HD11 XE
                            (P/N:23A...
                          </div>
                        </div>
                        <div className="detailed-device-listing-detail-item-value">
                          <div className="detailed-device-listing-detail-item-pin-wrapper">
                            <div className="detailed-device-heading-container">
                              <div className="detailed-device-listing-detail-item-location">
                                <div className="detailed-device-listing-detail-item-date-parent">
                                  <div className="detailed-device-listing-detail-item-date">
                                    <img
                                      className="detailed-device-chevron-right-icon2"
                                      alt=""
                                      src={markerpin021}
                                    />
                                  </div>
                                  <div className="detailed-device-location">India, New Delhi</div>
                                </div>
                                <div className="detailed-device-listing-detail-item-date-parent">
                                  <div className="detailed-device-listing-detail-item-date">
                                    <img
                                      className="detailed-device-chevron-right-icon2"
                                      alt=""
                                      src={calendar1}
                                    />
                                  </div>
                                  <div className="detailed-device-type-of-work">April 09, 2023</div>
                                </div>
                              </div>
                              <div className="detailed-device-listing-team">
                                <div className="detailed-device-listing-team-member-wrapper">
                                  <div className="detailed-device-listing-team-member">4.9/5</div>
                                </div>
                                <div className="detailed-device-contact-role">
                                  <div className="detailed-device-supporting-text12">
                                    99,999 reviews
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="detailed-device-listing">
                            <div className="detailed-device-breadcrumb-items">
                              <div className="detailed-device-listing-short-info">$9,999,999.99</div>
                              <div className="detailed-device-supporting-text-wrapper">
                                <div className="detailed-device-supporting-text13">USD-Per Unit</div>
                              </div>
                            </div>
                          </div>
                          <img
                            className="detailed-device-divider-icon"
                            alt=""
                            src={divider4}
                          />

                          <div className="detailed-device-listing-detail-item-value-inner">
                            <div className="detailed-device-footer-social-parent">
                              <div className="detailed-device-footer-social">
                                <img
                                  className="detailed-device-footer-social-child"
                                  alt=""
                                  src={group_58_2x}
                                />

                                <div className="detailed-device-listing-short-detail-item-pin-parent">
                                  <div className="detailed-device-listing-short-detail-item-pin">
                                    <div className="detailed-device-name">Olivia Rhye</div>
                                  </div>
                                  <div className="detailed-device-role1">Business Name</div>
                                </div>
                              </div>
                              <div className="detailed-device-listing-short-detail-item-date">
                                <img
                                  className="detailed-device-icon1"
                                  alt=""
                                  src={icon1}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="detailed-device-rectangle-parent10">
                    <div className="detailed-device-frame-child12"></div>
                    <div className="detailed-device-listing-card-badge-overlay">
                      <img
                        className="detailed-device-listing-card-badge-overlay-child"
                        alt=""
                        src={rectangle_95_2x}
                      />

                      <div className="detailed-device-image-overlay">
                        <div className="detailed-device-badge4">
                          <div className="detailed-device-listing-card-price">Assured</div>
                        </div>
                        <div className="detailed-device-short-listing-team">
                          <div className="detailed-device-badge5">
                            <div className="detailed-device-listing-card-radar">ID# AXJYN</div>
                          </div>
                          <div className="detailed-device-buttonsbutton11">
                            <img
                              className="detailed-device-magnifier-1-icon"
                              alt=""
                              src={radar_1_1_2x}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="detailed-device-listing-detail-item">
                      <div className="detailed-device-message-content">
                        <div className="detailed-device-text-frame">
                          <div className="detailed-device-text42">
                            New / Surplus 2021 Philips Health-care HD11 XE
                            (P/N:23A...
                          </div>
                        </div>
                        <div className="detailed-device-listing-detail-item-value">
                          <div className="detailed-device-listing-detail-item-pin-wrapper">
                            <div className="detailed-device-heading-container">
                              <div className="detailed-device-listing-detail-item-location">
                                <div className="detailed-device-listing-detail-item-date-parent">
                                  <div className="detailed-device-listing-detail-item-date">
                                    <img
                                      className="detailed-device-chevron-right-icon2"
                                      alt=""
                                      src={markerpin021}
                                    />
                                  </div>
                                  <div className="detailed-device-location">India, New Delhi</div>
                                </div>
                                <div className="detailed-device-listing-detail-item-date-parent">
                                  <div className="detailed-device-listing-detail-item-date">
                                    <img
                                      className="detailed-device-chevron-right-icon2"
                                      alt=""
                                      src={calendar1}
                                    />
                                  </div>
                                  <div className="detailed-device-type-of-work">April 09, 2023</div>
                                </div>
                              </div>
                              <div className="detailed-device-listing-team">
                                <div className="detailed-device-listing-team-member-wrapper">
                                  <div className="detailed-device-listing-team-member">4.9/5</div>
                                </div>
                                <div className="detailed-device-contact-role">
                                  <div className="detailed-device-supporting-text12">
                                    99,999 reviews
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="detailed-device-listing">
                            <div className="detailed-device-breadcrumb-items">
                              <div className="detailed-device-listing-short-info">$9,999,999.99</div>
                              <div className="detailed-device-supporting-text-wrapper">
                                <div className="detailed-device-supporting-text13">USD-Per Unit</div>
                              </div>
                            </div>
                          </div>
                          <img
                            className="detailed-device-divider-icon"
                            alt=""
                            src={divider4}
                          />

                          <div className="detailed-device-listing-detail-item-value-inner">
                            <div className="detailed-device-footer-social-parent">
                              <div className="detailed-device-footer-social">
                                <img
                                  className="detailed-device-footer-social-child"
                                  alt=""
                                  src={group_58_2x}
                                />

                                <div className="detailed-device-listing-short-detail-item-pin-parent">
                                  <div className="detailed-device-listing-short-detail-item-pin">
                                    <div className="detailed-device-name">Olivia Rhye</div>
                                  </div>
                                  <div className="detailed-device-role1">Business Name</div>
                                </div>
                              </div>
                              <div className="detailed-device-listing-short-detail-item-date">
                                <img
                                  className="detailed-device-icon1"
                                  alt=""
                                  src={icon1}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="detailed-device-rectangle-parent10">
                    <div className="detailed-device-frame-child12"></div>
                    <div className="detailed-device-rectangle-parent30">
                      <img
                        className="detailed-device-listing-card-badge-overlay-child"
                        alt=""
                        src={rectangle_95_2x}
                      />

                      <div className="detailed-device-image-overlay3">
                        <div className="detailed-device-badge4">
                          <div className="detailed-device-listing-card-price">Assured</div>
                        </div>
                        <div className="detailed-device-badge-group">
                          <div className="detailed-device-badge5">
                            <div className="detailed-device-listing-card-radar">ID# AXJYN</div>
                          </div>
                          <div className="detailed-device-buttonsbutton14">
                            <img
                              className="detailed-device-magnifier-1-icon"
                              alt=""
                              src={radar_1_1_2x}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="detailed-device-listing-detail-item">
                      <div className="detailed-device-message-content">
                        <div className="detailed-device-text-frame">
                          <div className="detailed-device-text42">
                            New / Surplus 2021 Philips Health-care HD11 XE
                            (P/N:23A...
                          </div>
                        </div>
                        <div className="detailed-device-icon-group">
                          <img className="detailed-device-icon4" alt="" src={icon1} />

                          <div className="detailed-device-message-content">
                            <div className="detailed-device-listing-detail-item-pin-wrapper">
                              <div className="detailed-device-listing-detail-item-location">
                                <div className="detailed-device-listing-detail-item-date-parent">
                                  <div className="detailed-device-listing-detail-item-date">
                                    <img
                                      className="detailed-device-chevron-right-icon2"
                                      alt=""
                                      src={markerpin021}
                                    />
                                  </div>
                                  <div className="detailed-device-location">India, New Delhi</div>
                                </div>
                                <div className="detailed-device-listing-detail-item-date-parent">
                                  <div className="detailed-device-listing-detail-item-date">
                                    <img
                                      className="detailed-device-chevron-right-icon2"
                                      alt=""
                                      src={calendar1}
                                    />
                                  </div>
                                  <div className="detailed-device-type-of-work">April 09, 2023</div>
                                </div>
                              </div>
                            </div>
                            <div className="detailed-device-supporting-work-five">
                              <div className="detailed-device-breadcrumb-items">
                                <div className="detailed-device-listing-team-member-wrapper">
                                  <div className="detailed-device-listing-team-member">4.9/5</div>
                                </div>
                                <div className="detailed-device-contact-role">
                                  <div className="detailed-device-supporting-text12">
                                    99,999 reviews
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="detailed-device-supporting-six-wrapper">
                              <div className="detailed-device-breadcrumb-items">
                                <div className="detailed-device-listing-short-info">$9,999,999.99</div>
                                <div className="detailed-device-supporting-text-wrapper">
                                  <div className="detailed-device-supporting-text13">USD-Per Unit</div>
                                </div>
                              </div>
                            </div>
                            <div className="detailed-device-divider-container">
                              <img
                                className="detailed-device-divider-icon3"
                                alt=""
                                src={divider4}
                              />
                            </div>
                            <div className="detailed-device-team-info-four">
                              <div className="detailed-device-detail-items">
                                <img
                                  className="detailed-device-footer-social-child"
                                  alt=""
                                  src={group_58_2x}
                                />

                                <div className="detailed-device-listing-short-detail-item-pin-parent">
                                  <div className="detailed-device-listing-short-detail-item-pin">
                                    <div className="detailed-device-name">Olivia Rhye</div>
                                  </div>
                                  <div className="detailed-device-role1">Business Name</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="detailed-device-testiomonial-carousel-arrow6">
                    <img
                      className="detailed-device-arrow-left-icon"
                      alt=""
                      src={arrowleft}
                    />
                  </div>
                </div>
                <div className="detailed-device-testiomonial-carousel-arrow7">
                  <img
                    className="detailed-device-arrow-left-icon"
                    alt=""
                    src={arrowright}
                  />
                </div>
              </div>
            </div>
            {/* <div className="detailed-device-breadcrumb-button-base4">
              <div className="detailed-device-text6">...</div>
            </div>
            <img
              className="detailed-device-chevron-right-icon3"
              alt=""
              src={chevronright}
            />

            <div className="detailed-device-breadcrumb-button-base5">
              <div className="detailed-device-text97">Another link</div>
            </div>
            <img
              className="detailed-device-chevron-right-icon4"
              alt=""
              src={chevronright}
            /> */}

            <img className="detailed-device-divider-icon24" alt="" src="/detailed_listing/divider5.svg" />

            {/* <div className="detailed-device-actions1">
        <div className="detailed-device-button2">
          <img
            className="detailed-device-placeholder-icon17"
            alt=""
            src="/detailed_listing/placeholder3.svg"
          />

          <div className="detailed-device-text-padding4">
            <div className="detailed-device-text98">Tertiary</div>
          </div>
          <img
            className="detailed-device-placeholder-icon17"
            alt=""
            src="/detailed_listing/placeholder3.svg"
          />
        </div>
        <div className="detailed-device-button3">
          <img
            className="detailed-device-placeholder-icon17"
            alt=""
            src={placeholder4}
          />

          <div className="detailed-device-text-padding5">
            <div className="detailed-device-text98">Secondary</div>
          </div>
          <img
            className="detailed-device-placeholder-icon17"
            alt=""
            src={placeholder4}
          />
        </div>
        <div className="detailed-device-button4">
          <img
            className="detailed-device-placeholder-icon17"
            alt=""
            src={placeholder1}
          />

          <div className="detailed-device-text-padding5">
            <div className="detailed-device-text98">Secondary</div>
          </div>
          <img
            className="detailed-device-placeholder-icon17"
            alt=""
            src={placeholder1}
          />
        </div>
        <div className="detailed-device-button5">
          <img
            className="detailed-device-placeholder-icon17"
            alt=""
            src={placeholder2}
          />

          <div className="detailed-device-text-padding7">
            <div className="detailed-device-text98">Primary</div>
          </div>
          <img
            className="detailed-device-placeholder-icon17"
            alt=""
            src={placeholder2}
          />
        </div>
      </div> */}
            <div className="detailed-device-listing-image-grid4">
              <div className="detailed-device-heading-parent3">
                <div className="detailed-device-heading36">Related Software</div>
                <div className="detailed-device-testimonial-description">
                  <div className="detailed-device-description">View All</div>
                </div>
              </div>
            </div>
            <div className="detailed-device-listing-image-grid11">
              <div className="detailed-device-frame-parent4">
                <div className="detailed-device-frame-parent5">
                  <div className="detailed-device-rectangle-parent10">
                    <div className="detailed-device-frame-child12"></div>
                    <div className="detailed-device-rectangle-parent30">
                      <img
                        className="detailed-device-listing-card-badge-overlay-child"
                        alt=""
                        src={rectangle_95_2x}
                      />

                      <div className="detailed-device-image-overlay">
                        <div className="detailed-device-badge4">
                          <div className="detailed-device-listing-card-price">Assured</div>
                        </div>
                        <div className="detailed-device-short-listing-team">
                          <div className="detailed-device-badge5">
                            <div className="detailed-device-listing-card-radar">ID# AXJYN</div>
                          </div>
                          <div className="detailed-device-buttonsbutton11">
                            <img
                              className="detailed-device-magnifier-1-icon"
                              alt=""
                              src={radar_1_1_2x}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="detailed-device-listing-detail-item">
                      <div className="detailed-device-message-content">
                        <div className="detailed-device-text-frame">
                          <div className="detailed-device-text42">
                            New / Surplus 2021 Philips Health-care HD11 XE
                            (P/N:23A...
                          </div>
                        </div>
                        <div className="detailed-device-listing-detail-item-value">
                          <div className="detailed-device-listing-detail-item-pin-wrapper">
                            <div className="detailed-device-heading-container">
                              <div className="detailed-device-listing-detail-item-location">
                                <div className="detailed-device-listing-detail-item-date-parent">
                                  <div className="detailed-device-listing-detail-item-date">
                                    <img
                                      className="detailed-device-chevron-right-icon2"
                                      alt=""
                                      src={markerpin021}
                                    />
                                  </div>
                                  <div className="detailed-device-location">India, New Delhi</div>
                                </div>
                                <div className="detailed-device-listing-detail-item-date-parent">
                                  <div className="detailed-device-listing-detail-item-date">
                                    <img
                                      className="detailed-device-chevron-right-icon2"
                                      alt=""
                                      src={calendar1}
                                    />
                                  </div>
                                  <div className="detailed-device-type-of-work">April 09, 2023</div>
                                </div>
                              </div>
                              <div className="detailed-device-listing-team">
                                <div className="detailed-device-listing-team-member-wrapper">
                                  <div className="detailed-device-listing-team-member">4.9/5</div>
                                </div>
                                <div className="detailed-device-contact-role">
                                  <div className="detailed-device-supporting-text12">
                                    99,999 reviews
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="detailed-device-listing">
                            <div className="detailed-device-breadcrumb-items">
                              <div className="detailed-device-listing-short-info">$9,999,999.99</div>
                              <div className="detailed-device-supporting-text-wrapper">
                                <div className="detailed-device-supporting-text13">USD-Per Unit</div>
                              </div>
                            </div>
                          </div>
                          <img
                            className="detailed-device-divider-icon"
                            alt=""
                            src={divider4}
                          />

                          <div className="detailed-device-listing-detail-item-value-inner">
                            <div className="detailed-device-footer-social-parent">
                              <div className="detailed-device-footer-social">
                                <img
                                  className="detailed-device-footer-social-child"
                                  alt=""
                                  src={group_58_2x}
                                />

                                <div className="detailed-device-listing-short-detail-item-pin-parent">
                                  <div className="detailed-device-listing-short-detail-item-pin">
                                    <div className="detailed-device-name">Olivia Rhye</div>
                                  </div>
                                  <div className="detailed-device-role1">Business Name</div>
                                </div>
                              </div>
                              <div className="detailed-device-listing-short-detail-item-date">
                                <img
                                  className="detailed-device-icon1"
                                  alt=""
                                  src={icon1}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="detailed-device-rectangle-parent10">
                    <div className="detailed-device-frame-child12"></div>
                    <div className="detailed-device-rectangle-parent30">
                      <img
                        className="detailed-device-listing-card-badge-overlay-child"
                        alt=""
                        src={rectangle_95_2x}
                      />

                      <div className="detailed-device-image-overlay">
                        <div className="detailed-device-badge4">
                          <div className="detailed-device-listing-card-price">Assured</div>
                        </div>
                        <div className="detailed-device-short-listing-team">
                          <div className="detailed-device-badge5">
                            <div className="detailed-device-listing-card-radar">ID# AXJYN</div>
                          </div>
                          <div className="detailed-device-buttonsbutton11">
                            <img
                              className="detailed-device-magnifier-1-icon"
                              alt=""
                              src={radar_1_1_2x}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="detailed-device-listing-detail-item">
                      <div className="detailed-device-message-content">
                        <div className="detailed-device-text-frame">
                          <div className="detailed-device-text42">
                            New / Surplus 2021 Philips Health-care HD11 XE
                            (P/N:23A...
                          </div>
                        </div>
                        <div className="detailed-device-listing-detail-item-value">
                          <div className="detailed-device-listing-detail-item-pin-wrapper">
                            <div className="detailed-device-heading-container">
                              <div className="detailed-device-listing-detail-item-location">
                                <div className="detailed-device-listing-detail-item-date-parent">
                                  <div className="detailed-device-listing-detail-item-date">
                                    <img
                                      className="detailed-device-chevron-right-icon2"
                                      alt=""
                                      src={markerpin021}
                                    />
                                  </div>
                                  <div className="detailed-device-location">India, New Delhi</div>
                                </div>
                                <div className="detailed-device-listing-detail-item-date-parent">
                                  <div className="detailed-device-listing-detail-item-date">
                                    <img
                                      className="detailed-device-chevron-right-icon2"
                                      alt=""
                                      src={calendar1}
                                    />
                                  </div>
                                  <div className="detailed-device-type-of-work">April 09, 2023</div>
                                </div>
                              </div>
                              <div className="detailed-device-listing-team">
                                <div className="detailed-device-listing-team-member-wrapper">
                                  <div className="detailed-device-listing-team-member">4.9/5</div>
                                </div>
                                <div className="detailed-device-contact-role">
                                  <div className="detailed-device-supporting-text12">
                                    99,999 reviews
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="detailed-device-listing">
                            <div className="detailed-device-breadcrumb-items">
                              <div className="detailed-device-listing-short-info">$9,999,999.99</div>
                              <div className="detailed-device-supporting-text-wrapper">
                                <div className="detailed-device-supporting-text13">USD-Per Unit</div>
                              </div>
                            </div>
                          </div>
                          <img
                            className="detailed-device-divider-icon"
                            alt=""
                            src={divider4}
                          />

                          <div className="detailed-device-listing-detail-item-value-inner">
                            <div className="detailed-device-footer-social-parent">
                              <div className="detailed-device-footer-social">
                                <img
                                  className="detailed-device-footer-social-child"
                                  alt=""
                                  src={group_58_2x}
                                />

                                <div className="detailed-device-listing-short-detail-item-pin-parent">
                                  <div className="detailed-device-listing-short-detail-item-pin">
                                    <div className="detailed-device-name">Olivia Rhye</div>
                                  </div>
                                  <div className="detailed-device-role1">Business Name</div>
                                </div>
                              </div>
                              <div className="detailed-device-listing-short-detail-item-date">
                                <img
                                  className="detailed-device-icon1"
                                  alt=""
                                  src={icon1}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="detailed-device-rectangle-parent10">
                    <div className="detailed-device-frame-child12"></div>
                    <div className="detailed-device-rectangle-parent30">
                      <img
                        className="detailed-device-listing-card-badge-overlay-child"
                        alt=""
                        src={rectangle_95_2x}
                      />

                      <div className="detailed-device-image-overlay">
                        <div className="detailed-device-badge4">
                          <div className="detailed-device-listing-card-price">Assured</div>
                        </div>
                        <div className="detailed-device-short-listing-team">
                          <div className="detailed-device-badge5">
                            <div className="detailed-device-listing-card-radar">ID# AXJYN</div>
                          </div>
                          <div className="detailed-device-buttonsbutton11">
                            <img
                              className="detailed-device-magnifier-1-icon"
                              alt=""
                              src={radar_1_1_2x}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="detailed-device-listing-detail-item">
                      <div className="detailed-device-message-content">
                        <div className="detailed-device-text-frame">
                          <div className="detailed-device-text42">
                            New / Surplus 2021 Philips Health-care HD11 XE
                            (P/N:23A...
                          </div>
                        </div>
                        <div className="detailed-device-listing-detail-item-value">
                          <div className="detailed-device-listing-detail-item-pin-wrapper">
                            <div className="detailed-device-heading-container">
                              <div className="detailed-device-listing-detail-item-location">
                                <div className="detailed-device-listing-detail-item-date-parent">
                                  <div className="detailed-device-listing-detail-item-date">
                                    <img
                                      className="detailed-device-chevron-right-icon2"
                                      alt=""
                                      src={markerpin021}
                                    />
                                  </div>
                                  <div className="detailed-device-location">India, New Delhi</div>
                                </div>
                                <div className="detailed-device-listing-detail-item-date-parent">
                                  <div className="detailed-device-listing-detail-item-date">
                                    <img
                                      className="detailed-device-chevron-right-icon2"
                                      alt=""
                                      src={calendar1}
                                    />
                                  </div>
                                  <div className="detailed-device-type-of-work">April 09, 2023</div>
                                </div>
                              </div>
                              <div className="detailed-device-listing-team">
                                <div className="detailed-device-listing-team-member-wrapper">
                                  <div className="detailed-device-listing-team-member">4.9/5</div>
                                </div>
                                <div className="detailed-device-contact-role">
                                  <div className="detailed-device-supporting-text12">
                                    99,999 reviews
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="detailed-device-listing">
                            <div className="detailed-device-breadcrumb-items">
                              <div className="detailed-device-listing-short-info">$9,999,999.99</div>
                              <div className="detailed-device-supporting-text-wrapper">
                                <div className="detailed-device-supporting-text13">USD-Per Unit</div>
                              </div>
                            </div>
                          </div>
                          <img
                            className="detailed-device-divider-icon"
                            alt=""
                            src={divider4}
                          />

                          <div className="detailed-device-listing-detail-item-value-inner">
                            <div className="detailed-device-footer-social-parent">
                              <div className="detailed-device-footer-social">
                                <img
                                  className="detailed-device-footer-social-child"
                                  alt=""
                                  src={group_58_2x}
                                />

                                <div className="detailed-device-listing-short-detail-item-pin-parent">
                                  <div className="detailed-device-listing-short-detail-item-pin">
                                    <div className="detailed-device-name">Olivia Rhye</div>
                                  </div>
                                  <div className="detailed-device-role1">Business Name</div>
                                </div>
                              </div>
                              <div className="detailed-device-listing-short-detail-item-date">
                                <img
                                  className="detailed-device-icon1"
                                  alt=""
                                  src={icon1}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="detailed-device-rectangle-parent10">
                    <div className="detailed-device-frame-child12"></div>
                    <div className="detailed-device-rectangle-parent30">
                      <img
                        className="detailed-device-listing-card-badge-overlay-child"
                        alt=""
                        src={rectangle_95_2x}
                      />

                      <div className="detailed-device-image-overlay3">
                        <div className="detailed-device-badge4">
                          <div className="detailed-device-listing-card-price">Assured</div>
                        </div>
                        <div className="detailed-device-badge-group">
                          <div className="detailed-device-badge5">
                            <div className="detailed-device-listing-card-radar">ID# AXJYN</div>
                          </div>
                          <div className="detailed-device-buttonsbutton14">
                            <img
                              className="detailed-device-magnifier-1-icon"
                              alt=""
                              src={radar_1_1_2x}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="detailed-device-listing-detail-item">
                      <div className="detailed-device-message-content">
                        <div className="detailed-device-text-frame">
                          <div className="detailed-device-text42">
                            New / Surplus 2021 Philips Health-care HD11 XE
                            (P/N:23A...
                          </div>
                        </div>
                        <div className="detailed-device-icon-group">
                          <img className="detailed-device-icon4" alt="" src={icon1} />

                          <div className="detailed-device-message-content">
                            <div className="detailed-device-listing-detail-item-pin-wrapper">
                              <div className="detailed-device-listing-detail-item-location">
                                <div className="detailed-device-listing-detail-item-date-parent">
                                  <div className="detailed-device-listing-detail-item-date">
                                    <img
                                      className="detailed-device-chevron-right-icon2"
                                      alt=""
                                      src={markerpin021}
                                    />
                                  </div>
                                  <div className="detailed-device-location">India, New Delhi</div>
                                </div>
                                <div className="detailed-device-listing-detail-item-date-parent">
                                  <div className="detailed-device-listing-detail-item-date">
                                    <img
                                      className="detailed-device-chevron-right-icon2"
                                      alt=""
                                      src={calendar1}
                                    />
                                  </div>
                                  <div className="detailed-device-type-of-work">April 09, 2023</div>
                                </div>
                              </div>
                            </div>
                            <div className="detailed-device-supporting-work-five">
                              <div className="detailed-device-breadcrumb-items">
                                <div className="detailed-device-listing-team-member-wrapper">
                                  <div className="detailed-device-listing-team-member">4.9/5</div>
                                </div>
                                <div className="detailed-device-contact-role">
                                  <div className="detailed-device-supporting-text12">
                                    99,999 reviews
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="detailed-device-supporting-six-wrapper">
                              <div className="detailed-device-breadcrumb-items">
                                <div className="detailed-device-listing-short-info">$9,999,999.99</div>
                                <div className="detailed-device-supporting-text-wrapper">
                                  <div className="detailed-device-supporting-text13">USD-Per Unit</div>
                                </div>
                              </div>
                            </div>
                            <div className="detailed-device-divider-container">
                              <img
                                className="detailed-device-divider-icon3"
                                alt=""
                                src={divider4}
                              />
                            </div>
                            <div className="detailed-device-team-info-four">
                              <div className="detailed-device-detail-items">
                                <img
                                  className="detailed-device-footer-social-child"
                                  alt=""
                                  src={group_58_2x}
                                />

                                <div className="detailed-device-listing-short-detail-item-pin-parent">
                                  <div className="detailed-device-listing-short-detail-item-pin">
                                    <div className="detailed-device-name">Olivia Rhye</div>
                                  </div>
                                  <div className="detailed-device-role1">Business Name</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="detailed-device-testiomonial-carousel-arrow8">
                    <img
                      className="detailed-device-arrow-left-icon"
                      alt=""
                      src={arrowleft}
                    />
                  </div>
                </div>
                <div className="detailed-device-testiomonial-carousel-arrow9">
                  <img
                    className="detailed-device-arrow-left-icon"
                    alt=""
                    src={arrowright}
                  />
                </div>
              </div>
            </div>
            <FooterDetailPage />
          </div>
        </div>
      </>
    )
  } else {
    return <Loader />;
  }


}

