
// import React, { useState, useEffect, useRef } from 'react';
// import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';

// const libraries = ['marker'];

// const MapComponent = ({ width, height, zoom, setSelectedMapCoordinates }) => {
//     const mapStyles = {
//         height: height,
//         width: width,
//     };

//     const defaultCenter = {
//         lat: 0,
//         lng: 0,
//     };

//     const [selectedLocation, setSelectedLocation] = useState(null);
//     const mapRef = useRef(null);
//     const markerRef = useRef(null);

//     const { isLoaded, loadError } = useJsApiLoader({
//         googleMapsApiKey: "AIzaSyDW_qBPEy1VCPIiP9b6yvJXL0vg6iLlgTQ",
//         libraries: libraries,
//     });

//     useEffect(() => {
//         if (selectedLocation && mapRef.current) {
//             const { google } = window;

//             if (google && google.maps && google.maps.marker) {
//                 if (!markerRef.current) {
//                     markerRef.current = new google.maps.marker.AdvancedMarkerElement({
//                         position: selectedLocation,
//                         map: mapRef.current,
//                     });
//                 } else {
//                     markerRef.current.position = selectedLocation;
//                 }
//             }
//         }
//     }, [selectedLocation]);

//     const handleMapClick = (e) => {
//         const lat = e.latLng.lat();
//         const lng = e.latLng.lng();
//         setSelectedLocation({ lat, lng });
//         if (setSelectedMapCoordinates) {
//             setSelectedMapCoordinates({ lat, lng });
//         }
//         console.log('Latitude:', lat, 'Longitude:', lng);
//     };

//     if (loadError) {
//         return <div>Error loading maps</div>;
//     }

//     if (!isLoaded) {
//         return <div>Loading maps</div>;
//     }

//     return (
//         <GoogleMap
//             mapContainerStyle={mapStyles}
//             zoom={zoom || 2}
//             center={selectedLocation || defaultCenter}
//             onLoad={(map) => (mapRef.current = map)}
//             onClick={handleMapClick}
//             options={{
//                 mapId: "fea14720c452d711"
//             }}
//         />
//     );
// };

// export default MapComponent;


// import React, { useState, useEffect, useRef } from 'react';
// import { GoogleMap, LoadScript } from '@react-google-maps/api';

// // Define the libraries array outside of the component
// const libraries = ['marker'];

// const MapComponent = ({ width, height, zoom, setSelectedMapCoordinates, initialCoordinates }) => {
//     const mapStyles = {
//         height: height,
//         width: width,
//     };

//     const defaultCenter = {
//         lat: 0,
//         lng: 0,
//     };

//     const [selectedLocation, setSelectedLocation] = useState(null);
//     const mapRef = useRef(null);
//     const markerRef = useRef(null);

//     useEffect(() => {
//         // Set the initial location if coordinates are provided
//         if (initialCoordinates && initialCoordinates.type === 'Point' && initialCoordinates.coordinates) {
//             const [lng, lat] = initialCoordinates.coordinates;
//             setSelectedLocation({ lat, lng });
//         }
//     }, [initialCoordinates]);

//     useEffect(() => {
//         if (selectedLocation && mapRef.current) {
//             const { google } = window;

//             if (google && google.maps && google.maps.Marker) {
//                 if (!markerRef.current) {
//                     markerRef.current = new google.maps.Marker({
//                         position: selectedLocation,
//                         map: mapRef.current,
//                     });
//                 } else {
//                     markerRef.current.setPosition(selectedLocation);
//                 }
//             }
//         }
//     }, [selectedLocation]);

//     const handleMapClick = (e) => {
//         const lat = e.latLng.lat();
//         const lng = e.latLng.lng();
//         setSelectedLocation({ lat, lng });
//         setSelectedMapCoordinates({ lat, lng });
//         console.log('Latitude:', lat, 'Longitude:', lng);
//     };

//     return (
//         <LoadScript
//             googleMapsApiKey="AIzaSyDW_qBPEy1VCPIiP9b6yvJXL0vg6iLlgTQ"
//             libraries={libraries} // Use the constant libraries array
//         >
//             <GoogleMap
//                 mapContainerStyle={mapStyles}
//                 zoom={selectedLocation ? zoom : 2}
//                 center={selectedLocation ? selectedLocation : defaultCenter}
//                 onLoad={(map) => (mapRef.current = map)}
//                 onClick={handleMapClick}
//                 mapId="fea14720c452d711"
//             />
//         </LoadScript>
//     );
// };

// export default MapComponent;

// import React, { useState, useEffect, useRef, useCallback } from 'react';
// import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';

// const libraries = ['marker'];

// const MapComponent = ({ width, height, zoom, setSelectedMapCoordinates, initialCoordinates }) => {
//     const mapStyles = {
//         height: height,
//         width: width,
//     };

//     const defaultCenter = {
//         lat: 0,
//         lng: 0,
//     };

//     const [selectedLocation, setSelectedLocation] = useState(null);
//     const mapRef = useRef(null);
//     const markerRef = useRef(null);

//     const { isLoaded, loadError } = useJsApiLoader({
//         googleMapsApiKey: "AIzaSyDW_qBPEy1VCPIiP9b6yvJXL0vg6iLlgTQ",
//         libraries: libraries,
//     });

//     const onMapLoad = useCallback((map) => {
//         mapRef.current = map;
//     }, []);

//     useEffect(() => {
//         // Set the initial location if coordinates are provided
//         if (initialCoordinates && initialCoordinates.type === 'Point' && initialCoordinates.coordinates) {
//             const [lng, lat] = initialCoordinates.coordinates;
//             setSelectedLocation({ lat, lng });
//         }
//     }, [initialCoordinates]);

//     const handleMapClick = useCallback((e) => {
//         const lat = e.latLng.lat();
//         const lng = e.latLng.lng();
//         setSelectedLocation({ lat, lng });
//         if (setSelectedMapCoordinates) {
//             setSelectedMapCoordinates({ lat, lng });
//         }
//         console.log('Latitude:', lat, 'Longitude:', lng);
//     }, [setSelectedMapCoordinates]);

//     useEffect(() => {
//         if (selectedLocation && mapRef.current) {
//             const { google } = window;
//             if (google && google.maps && google.maps.marker) {
//                 if (markerRef.current) {
//                     markerRef.current.map = null;
//                 }
//                 markerRef.current = new google.maps.marker.AdvancedMarkerElement({
//                     position: selectedLocation,
//                     map: mapRef.current,
//                 });
//             }
//         }
//     }, [selectedLocation]);

//     if (loadError) {
//         return <div>Error loading maps</div>;
//     }

//     if (!isLoaded) {
//         return <div>Loading maps</div>;
//     }

//     return (
//         <GoogleMap
//             mapContainerStyle={mapStyles}
//             zoom={zoom || 2}
//             center={selectedLocation || defaultCenter}
//             onLoad={onMapLoad}
//             onClick={handleMapClick}
//             options={{
//                 mapId: "fea14720c452d711",
//                 streetViewControl: false,
//                 mapTypeControl: false,
//             }}
//         />
//     );
// };

// export default MapComponent;

// import React, { useState, useEffect, useRef, useCallback } from 'react';
// import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';

// const libraries = ['marker'];

// const MapComponent = ({ width, height, zoom, setSelectedMapCoordinates, initialCoordinates }) => {
//     const mapStyles = {
//         height: height,
//         width: width,
//     };

//     const defaultCenter = {
//         lat: 0,
//         lng: 0,
//     };

//     const [selectedLocation, setSelectedLocation] = useState(null);
//     const mapRef = useRef(null);
//     const markerRef = useRef(null);

//     const { isLoaded, loadError } = useJsApiLoader({
//         googleMapsApiKey: "AIzaSyDW_qBPEy1VCPIiP9b6yvJXL0vg6iLlgTQ",
//         libraries: libraries,
//     });

//     const onMapLoad = useCallback((map) => {
//         mapRef.current = map;
//     }, []);

//     useEffect(() => {
//         // Set the initial location if coordinates are provided
//         if (initialCoordinates) {
//             // const [lng, lat] = initialCoordinates.coordinates;
//             setSelectedLocation(initialCoordinates);
//             if (setSelectedMapCoordinates) {
//                 setSelectedMapCoordinates(initialCoordinates);
//             }
//         }
//     }, [initialCoordinates, setSelectedMapCoordinates]);

//     const handleMapClick = useCallback((e) => {
//         const lat = e.latLng.lat();
//         const lng = e.latLng.lng();
//         setSelectedLocation({ lat, lng });
//         if (setSelectedMapCoordinates) {
//             setSelectedMapCoordinates({ lat, lng });
//         }
//         console.log('Latitude:', lat, 'Longitude:', lng);
//     }, [setSelectedMapCoordinates]);

//     useEffect(() => {
//         if (selectedLocation && mapRef.current) {
//             const { google } = window;
//             if (google && google.maps && google.maps.marker) {
//                 if (markerRef.current) {
//                     markerRef.current.map = null;
//                 }
//                 markerRef.current = new google.maps.marker.AdvancedMarkerElement({
//                     position: selectedLocation,
//                     map: mapRef.current,
//                 });
//                 // Center the map on the selected location
//                 mapRef.current.panTo(selectedLocation);
//             }
//         }
//     }, [selectedLocation]);

//     if (loadError) {
//         return <div>Error loading maps</div>;
//     }

//     if (!isLoaded) {
//         return <div>Loading maps</div>;
//     }

//     return (
//         <GoogleMap
//             mapContainerStyle={mapStyles}
//             zoom={zoom || 2}
//             center={selectedLocation || defaultCenter}
//             onLoad={onMapLoad}
//             onClick={handleMapClick}
//             options={{
//                 mapId: "fea14720c452d711",
//                 streetViewControl: false,
//                 mapTypeControl: false,
//             }}
//         />
//     );
// };

// export default MapComponent;

import React, { useState, useEffect, useRef, useCallback } from 'react';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';

const libraries = ['marker'];

const MapComponent = ({ width, height, zoom, setSelectedMapCoordinates, initialCoordinates }) => {
    const mapStyles = {
        height: height,
        width: width,
    };

    const defaultCenter = {
        lat: 0,
        lng: 0,
    };

    const [selectedLocation, setSelectedLocation] = useState(null);
    const mapRef = useRef(null);
    const markerRef = useRef(null);

    const { isLoaded, loadError } = useJsApiLoader({
        googleMapsApiKey: "AIzaSyDW_qBPEy1VCPIiP9b6yvJXL0vg6iLlgTQ",
        libraries: libraries,
    });

    const onMapLoad = useCallback((map) => {
        mapRef.current = map;
        // If we have initial coordinates, create the marker here
        if (initialCoordinates && initialCoordinates.lat && initialCoordinates.lng) {
            createOrUpdateMarker(initialCoordinates);
        }
    }, [initialCoordinates]);

    const createOrUpdateMarker = useCallback((location) => {
        const { google } = window;
        if (google && google.maps && google.maps.marker && mapRef.current) {
            if (markerRef.current) {
                markerRef.current.map = null;
            }
            markerRef.current = new google.maps.marker.AdvancedMarkerElement({
                position: location,
                map: mapRef.current,
            });
            mapRef.current.panTo(location);
        }
    }, []);

    useEffect(() => {
        if (initialCoordinates && initialCoordinates.lat && initialCoordinates.lng) {
            setSelectedLocation(initialCoordinates);
            if (setSelectedMapCoordinates) {
                setSelectedMapCoordinates(initialCoordinates);
            }
            if (mapRef.current) {
                createOrUpdateMarker(initialCoordinates);
            }
        }
    }, [initialCoordinates, setSelectedMapCoordinates, createOrUpdateMarker]);

    const handleMapClick = useCallback((e) => {
        const lat = e.latLng.lat();
        const lng = e.latLng.lng();
        const newLocation = { lat, lng };
        setSelectedLocation(newLocation);
        createOrUpdateMarker(newLocation);
        if (setSelectedMapCoordinates) {
            setSelectedMapCoordinates(newLocation);
        }
        console.log('Latitude:', lat, 'Longitude:', lng);
    }, [setSelectedMapCoordinates, createOrUpdateMarker]);

    if (loadError) {
        return <div>Error loading maps</div>;
    }

    if (!isLoaded) {
        return <div>Loading maps</div>;
    }

    return (
        <GoogleMap
            mapContainerStyle={mapStyles}
            zoom={zoom || 12}
            center={selectedLocation || defaultCenter}
            onLoad={onMapLoad}
            onClick={handleMapClick}
            options={{
                mapId: "fea14720c452d711",
                streetViewControl: false,
                mapTypeControl: false,
            }}
        />
    );
};

export default MapComponent;
