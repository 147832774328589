import React, { useState } from 'react';
import "./Team.css";
// import { AddressEditForm } from '../EditAddress_copy/EditAddress';
// import divider1 from '../../icons/Gerator/profile_settings/divider1.svg';
import divider2 from '../../icons/Gerator/profile_settings/divider2.svg';
import divider3 from "../../icons/Gerator/dashboard/divider3.svg"
import placeholder2 from '../../icons/Gerator/profile_settings/placeholder2.svg';
import placeholder3 from '../../icons/Gerator/profile_settings/placeholder3.svg';

import trend_down_02 from "../../icons/Gerator/profile_settings/trend_down_02.svg";
import trend_up_02 from "../../icons/Gerator/profile_settings/trend_up_02.svg"
import thumbs_up from "../../icons/Gerator/profile_settings/thumbs_up.svg";
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { useNavigate, useParams } from 'react-router-dom';
import { auth, onAuthStateChanged } from '../Login/FirebaseClient';
import SkeletonListingLoaderComponent from '../../components/SkeletonListingLoaderComponent/SkeletonListingLoaderComponent';
import { NoListingFound } from '../../components/NoListing/NoListingFound';
import { useRef } from 'react';
import { useDeleteAddressMutation, useGetUserDashboardAddressesQuery, useLazyGetAddressesQuery } from '../../api/AddressAPI';
import dotsvertical from "../../icons/Gerator/contacts/dotsvertical.svg";
import arrowdown from "../../icons/Gerator/contacts/arrowdown.svg";
import markerpin02 from "../../icons/Gerator/contacts/markerpin02.svg";
import help_icon from "../../icons/Gerator/contacts/help_icon.svg";
import arrowdown1 from '../../icons/Gerator/dashboard/arrowdown1.svg';
import badge_paid from "../../icons/Gerator/dashboard/Badge_Paid.svg"


import { useEffect } from 'react';
import { NoAddress } from '../../components/NoAddress/NoAddress';
import { NothingFoundMessage } from '../../components/NothingFoundMessage/NothingFoundMessage';
import plus from '../../icons/Gerator/no_listing/plus.svg';
import { NoAccessTeam } from '../../components/NoAccessTeam/NoAccessTeam';

export const Team = () => {


  useEffect(() => {
    // Dynamically load the Razorpay checkout script when the component mounts
    const script = document.createElement('script');
    script.src = 'https://checkout.razorpay.com/v1/checkout.js';
    script.async = true;
    document.body.appendChild(script);

    // Cleanup script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  function payNow() {
    // const amount = document.getElementById('amount').value;

    // Create order by calling the server endpoint
    // const response = await fetch('/create-order', {
    //   method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify({ amount, currency: 'INR', receipt: 'receipt#1', notes: {} })
    // });

    // const order = await response.json();


    const amount = 5000;
    // Open Razorpay Checkout
    const options = {
      key: 'rzp_test_DtZHIIH1CUQ69J', // Replace with your Razorpay key_id
      // amount: order.amount,
      // currency: order.currency,
      amount: amount,
      currency: "INR",
      name: 'Gerator',
      description: 'Test Transaction',
      // order_id: order.id, // This is the order_id created in the backend

      callback_url: `${process.env.REACT_APP_URL}/dashboard/success`, // Your success URL
      prefill: {
        name: 'Your Name',
        email: 'your.email@example.com',
        contact: '9999999999'
      },
      theme: {
        color: '#7e56d9'
      },
      // handler: function (response) {
      //   fetch('/verify-payment', {
      //     method: 'POST',
      //     headers: {
      //       'Content-Type': 'application/json'
      //     },
      //     body: JSON.stringify({
      //       razorpay_order_id: response.razorpay_order_id,
      //       razorpay_payment_id: response.razorpay_payment_id,
      //       razorpay_signature: response.razorpay_signature
      //     })
      //   }).then(res => res.json())
      //     .then(data => {
      //       if (data.status === 'ok') {
      //         window.location.href = 'dashboard/success';
      //       } else {
      //         alert('Payment verification failed');
      //       }
      //     }).catch(error => {
      //       console.error('Error:', error);
      //       alert('Error verifying payment');
      //     });
      // }

      handler: function (response) {
        // Handle success/failure after payment is completed
        // alert(`Payment successful! Payment ID: ${response.razorpay_payment_id}`);
        // console.log(response);
        window.location.href = '/dashboard/settings/success';
      },
      // modal: {
      //   ondismiss: function () {
      //     alert('Payment popup closed!');
      //   }
      // }
    };

    const rzp = new Razorpay(options);
    rzp.open();
  }






  // Retrieve the initial state from localStorage, or default to "Monthly" (true)
  const initialToggleState = localStorage.getItem('toggleState') === 'false' ? false : true;

  // State to manage the toggle
  const [isMonthly, setIsMonthly] = useState(initialToggleState);

  // Toggle the state when button is clicked
  const handleToggle = () => {
    const newToggleState = !isMonthly;
    setIsMonthly(newToggleState); // Toggle between monthly and yearly
    localStorage.setItem('toggleState', newToggleState); // Save state to localStorage
  };

  useEffect(() => {
    // On component mount, check localStorage and set the state accordingly
    const savedToggleState = localStorage.getItem('toggleState');
    if (savedToggleState !== null) {
      setIsMonthly(savedToggleState === 'true');
    }
  }, []);

  // Pricing examples for monthly and yearly plans
  const monthlyPrices = {
    plan1: 200,
    plan2: 450
  };

  const yearlyPrices = {
    plan1: 2000,
    plan2: 5000
  };

  //Offset to load data when click load more
  const navigate = useNavigate();
  const [offset, setOffset] = useState(0);
  // const [sortBy, setSortBy] = useState('date_desc');
  const [searchTerm, setSearchTerm] = useState('');
  const [currentUser, setCurrentUser] = useState("");
  const [totalCount, setTotalCount] = useState("");
  // const [totalCountWantedSpare, settotalCountWantedSpare] = useState("");
  // const [totalCountWantedService, settotalCountWantedService] = useState("");
  // const [totalCountWantedSoftware, settotalCountWantedSoftware] = useState("");
  // const [activePostStatus, setActivePostStatusFilter] = useState(null);
  //Query to get data
  const { data, isSuccess, isError, refetch, isLoading, isFetching } = useGetUserDashboardAddressesQuery(
    { offset, search: searchTerm },
    {
      // Refetch when component mounts or tab becomes active
      refetchOnMountOrArgChange: true
    }
  );

  // Delete query for address
  const [deleteAddress] = useDeleteAddressMutation();

  //flag var to show listing when loading complete when sekelteon layout is going to stop
  const [loadingComplete, setLoadingComplete] = useState(false);

  //Var is reponsible to show listings and other thing as well
  let content;

  //Sort by text show on button
  // const [selectedSortOption, setSelectedSortOption] = useState('');

  //For SortBy Dropdown
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);

  //For Threedots in listing dropdown
  const [activeThreeDotId, setActiveThreeDotId] = useState(null);
  const threeDotsRef = useRef(null);
  const [isClickBlocked, setIsClickBlocked] = useState(false);

  const handleDelete = async (id) => {
    try {

      const response = await deleteAddress(id).unwrap();

      if (response.status) {
        // setShowSuccessMessage(response.message)
        // await new Promise(resolve => setTimeout(resolve, 1500));
        // Reseting form
        window.location.reload();
      }
    } catch (error) {
      console.error(`Failed to delete Address `, error);
    }
  };



  // const toggleDropdown = () => {
  //   setDropdownVisible(!isDropdownVisible);
  // }

  // const toggleThreeDotsDropdown = (id) => {
  //   if (isClickBlocked) return; // Prevent rapid toggle

  //   if (activeThreeDotId === id) {
  //     setActiveThreeDotId(null); // Close if the same one is clicked again
  //     setIsClickBlocked(true);
  //     setTimeout(() => setIsClickBlocked(false), 400); // Small delay to avoid reopening
  //   } else {
  //     setActiveThreeDotId(id); // Open the clicked one
  //   }
  // };

  // const handleSearch = (term) => {
  //   setSearchTerm(term);
  // };


  //Set value for sortby selected option on button text
  // const handleOptionSelect = (option) => {
  //   if (option.value !== sortBy) {
  //     // setSortBy(option.value);
  //     setOffset(0); // Reset offset when sort option changes
  //   }
  //   setSelectedSortOption(option);
  //   setDropdownVisible(false);
  // };

  //DashbaordFilterButtonGroup component to get data
  // const handleFilterClick = (filterLabel) => {
  //   if (activePostStatus === filterLabel) {
  //     setSortBy('date_desc')
  //     setSelectedSortOption('')
  //     setActivePostStatusFilter(null);
  //   } else {
  //     setSortBy('date_desc')
  //     setSelectedSortOption('')
  //     setActivePostStatusFilter(filterLabel);
  //   }
  //   setOffset(0); // Reset offset when changing filters
  // };

  //Setting offset for loadmore when user clicked on load more button(this trigger query to fetch new data)
  // Reset offset when component mounts or remounts
  // useEffect(() => {
  //   setOffset(0);
  // }, []);
  // // Reset offset when sort option changes
  // useEffect(() => {
  //   setOffset(0);
  // }, [sortBy]);

  const handleLoadMore = () => {
    if (data?.hasMore && !isFetching) {
      setOffset(currentOffset => currentOffset + 5);
    }
  };

  const showLoadMore = data?.hasMore &&
    data.addresses.length < data.totalCount &&
    !isFetching;





  //Onclick listener for sortby dropdown
  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
  //       setDropdownVisible(false);
  //     }
  //   };

  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, []);


  //Onclick listener for threedots dropdown
  // useEffect(() => {
  //   const handleThreeDotClickOutside = (event) => {
  //     if (threeDotsRef.current && !threeDotsRef.current.contains(event.target)) {
  //       setActiveThreeDotId(null); // Close any open dropdown when clicking outside
  //     }
  //   };

  //   document.addEventListener("mousedown", handleThreeDotClickOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", handleThreeDotClickOutside);
  //   };
  // }, []);

  // Listen for tab visibility changes
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        refetch();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [refetch]);


  //Success true then pause to show sekelton layout
  useEffect(() => {
    if (isSuccess) {
      // Set totalcount in listing counter layout
      setTotalCount(data.totalCount);
      // settotalCountExpired(data.totalCountExpired);
      // Set timeout to delay the loading completion
      const timer = setTimeout(() => {
        setLoadingComplete(true);
      }, 1000); // 1 second

      // Cleanup timer on component unmount
      return () => clearTimeout(timer);
    }
  }, [isSuccess]);

  useEffect(() => {
    // const auth = getAuth();

    // Listen for authentication state change
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is authenticated
        setCurrentUser(user);
      } else {
        // User is not authenticated, redirect to login
        navigate('/sign-in', { state: { message: 'Please log in to continue.' } });
      }
    });

    return () => unsubscribe(); // Cleanup listener on component unmount
  }, [navigate]);

  if (isError) {
    content = <NoListingFound path={"/add-device"} />
  }

  if (isLoading) {
    content = <SkeletonListingLoaderComponent />;
  } else if (loadingComplete && isSuccess) {

    if (data.addresses.length > 0) {
      // dataLength = data.addresses.length;
      content = data.addresses.map((address, index) =>
      (

        <>

        </>
      ))
    }
    else {
      //   content = (

      //     <NothingFoundMessage text="Your company doesn't have an address yet ?!" text1="Let's start the process ?" text2="You need to create an address for your business,or be related one." />
      //     // <NoAddress />
      //   )
    }

  } else if (!loadingComplete && isSuccess) {
    content = <SkeletonListingLoaderComponent />; // Show loader until loadingComplete is true
  }



  return (
    <>
      <Helmet>
        <title>Settings - Plan & Billing</title>
      </Helmet>
      <div className="team-display-form-content-wrapper">
        <div className="team-display-form-content">
       <div className="team-display-toggle-header-parent">
            <div className="team-display-toggle-header">
              <div className="team-display-toggle-title">My Team</div>
             
              <div className="team-display-supporting-text2">
              Unleash your team's powers to drive growth.
              </div>
              
                
            </div>
            <div className="team-display-toggle-controls">
              {/* <div className="team-display-rectangle-group">
                <div className="team-display-frame-item"></div>
                <div className="team-display-toggle-base">
                  <div className="team-display-button6"></div>
                </div>
                <div className="team-display-switch-label">Explorer mode</div>
                <div className="team-display-supporting-text3">
                  Save my login details for next time.
                </div>
              </div> */}
                <div className="team-display-toggle-supporting-text">
                <button className="team-display-button7">
                    <img
                      className="team-display-placeholder-icon"
                      alt=""
                      src="/profile_settings/placeholder2.svg"
                    />

                    <div className="team-display-button-labels">Upgrade</div>
                    <img
                      className="team-display-placeholder-icon"
                      alt=""
                      src="/profile_settings/placeholder2.svg"
                    />
                  </button>
                  <div className="team-display-supporting-text4">
                  To manage team members
                </div>
                 </div>
                  <div className="team-display-toggle-supporting-text">
              
                  <Link to={`/dashboard/settings/address/add-address`} className="team-display-button8">
                <img
                  className="team-display-plus-icon1"
                  alt=""
                  src={plus}
                />

                <div className="team-display-text49"> Invite</div>
                <img
                  className="team-display-placeholder-icon"
                  alt=""
                  src={placeholder3}
                />
              </Link>
              
              <div className="team-display-supporting-text4">
                    5 seats left
                </div>
                </div>
             
             
            </div>
          </div>
          <div className="team-display-divider-container">
            <img
              className="team-display-divider-icon3"
              alt=""
              src={divider3}
            />
          </div>

         
         <NoAccessTeam/>


        </div>
      </div >

      

     
    </>

  )

}