import React, {useState} from 'react';
import "./EditSpare.css"
import { Navbar1 } from '../../../components/Navbar1/Navbar1'
import { FooterAddForms } from '../../../components/Footer/FooterAddForms/FooterAddForms';
import { HeaderForms } from '../../../components/Header/HeaderForms/HeaderForms';
import divider from '../../../icons/Gerator/add_form/navbar_divider/divider.svg';
import divider_light from '../../../icons/Gerator/divider.svg';
import { Sidebar } from '../../../components/Sidebar/Sidebar';
import { SideBarSubProgressStep1 } from '../../../components/SideBars-Sub/SideBarSubAddForm/SideBarSubProgressStep1';
import { SideBarSubProgressStep2 } from '../../../components/SideBars-Sub/SideBarSubAddForm/SideBarSubProgressStep2';
import { SideBarSubProgressStep3 } from '../../../components/SideBars-Sub/SideBarSubAddForm/SideBarSubProgressStep3';
import EditSpareParent from '../../../components/EditSpareFormComponent/EditSpareParent';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { auth, onAuthStateChanged } from '../../Login/FirebaseClient';
import { Loader } from '../../../components/Loader';
import { useLazyGetUserProfileQuery, useLazyGetUserQuery } from '../../../api/Login&RegisterAPI';
import { useEffect } from 'react';
import { useRef } from 'react';
import { decryptErrorData } from '../../../helper/AESHelper';


const renderProgressStep = (step) => {
  switch (step) {
    case 0:
      return <SideBarSubProgressStep1 />;
    case 1:
      return <SideBarSubProgressStep2 />;
    case 2:
      return <SideBarSubProgressStep3 />;
    default:
      return null;
  }
};

export const EditSpare = () =>  {

  const appData = useSelector((state) => state.appDataEditSpare);
  // Progress var when this is true means all taxonomy loaded
  const [isTaxonomySet, setIsTaxonomySet] = useState(false);
  const [triggerGetUser, { data, isSuccess, error, isLoading, isError }] = useLazyGetUserQuery();
  const [currentUser, setCurrentUser] = useState("");
  const navigate = useNavigate();

  const [showSuccessMessage, setShowSuccessMessage] = useState({
    message: "",
    color: "",
  });

  useEffect(() => {
    // const auth = getAuth();

    // Listen for authentication state change
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is authenticated
        triggerGetUser();
        setCurrentUser(user);
      } else {
        // User is not authenticated, redirect to login
        navigate('/sign-in', { state: { message: 'Please log in to continue.' } });
      }
    });

    return () => unsubscribe(); // Cleanup listener on component unmount
  }, [navigate, triggerGetUser]);


  if (error) {
    console.log(error);
    if (isError) {
      const decryptedData = decryptErrorData(error.data);
      console.log(decryptedData);
      // navigate("/sign-in", { state: decryptedData.message });
      navigate("/sign-in", { state: { message: decryptedData.message } });
    }
    else {
      navigate("/sign-in", { state: { message: "Internal server error" } });
    }
    return; // Optionally handle error differently
  }

if (isSuccess) {
  
  return (
   

    <div className="add-spare-forms-add-spare add-spare-forms-add-spare-custom-margin">
    {/* <div className="add-spare-forms-add-spare-child"></div> */}
   

    <Navbar1 userData={currentUser} navigate={navigate} />

     <img className="divider-icon" alt="" src={divider} />
    
   
    <main className="add-spare-content-parent">

    <Sidebar>{renderProgressStep(appData.currentStep)}</Sidebar>


      





      
      <section className="add-spare-bottom-actions bottom-actions-custom-padding">
        <div className="add-spare-progress-container-parent">
          <div className="add-spare-progress-container">
            {/* <div className="add-spare-progress-steps">
              <div className="add-spare-form-parent">
               



              </div>
            </div> */}
                {/* <ProgressStep1/> */}
                {/* <ProgressStep2/> */}
                {/* <ProgressStep3/> */}
            <div className="add-spare-content5">
              <div className="add-spare-main">


              <HeaderForms text="Edit Spare " text1="Edit Spare Listing" text2="Your first step to realizing asset value." />




                <div className="add-spare-main-child"></div>
                <div className="add-spare-main-item"></div>
              </div>
              <div className="add-spare-body">
              <EditSpareParent setShowSuccessMessage={setShowSuccessMessage} setIsTaxonomySet={setIsTaxonomySet} />



                
              </div>
              {showSuccessMessage.message && (
                    <div
                      style={{
                        fontSize: "14px",
                        color: showSuccessMessage.color,
                        padding: "15px 10px",
                        border: "1px solid " + showSuccessMessage.color,
                        borderRadius: "10px",
                        margin: "0 auto",
                      }}
                    >
                      {showSuccessMessage.message}
                    </div>
                  )}
            </div>
          </div>
        </div>
      </section>
    </main>
    <img
        className="add-spare-divider-icon10"
        loading="lazy"
        alt=""
        src={divider_light}
      />

    <FooterAddForms/>
  </div>
  )
} else {
  return <Loader />
}

}

