import React, {useState} from 'react';
import "./StartCampaignSlideOut.css";

import { createPortal } from 'react-dom';

import xclose from "../../icons/Gerator/slide_out/xclose.svg";


import searchlg from "../../icons/Gerator/contacts/searchlg.svg";
import campaign from "../../icons/Gerator/sales_iq/campaign.svg";


export const StartCampaignSlideOut = ({onClose}) => {

  

  

 
  

  return  createPortal (
    <div className="start-campaign-slide-out-menu">
      <main className="start-campaign-panel">
        <section className="start-campaign-slide-out-menu-header-parent">
          <header className="start-campaign-slide-out-menu-header">
            <div className="start-campaign-content71">
              <img
                className="start-campaign-featured-icon2"
                loading="lazy"
                alt=""
                src={campaign}
              />

              <div className="start-campaign-text-and-supporting-text26">
                <a className="start-campaign-title9">Create Campaign</a>
                <div className="start-campaign-supporting-text91">
                  Add a line here
                </div>
              </div>
            </div>
            <div className="start-campaign-button-close-x6" onClick={onClose} >
              <img
                className="start-campaign-mail-01-icon"
                loading="lazy"
                alt=""
                src={xclose}
              />
            </div>
          </header>

          <div className="start-campaign-searchbox-container">
              <div className="start-campaign-searchbox">
                <div className="start-campaign-searchbox-input-field" >
                  <div  >
                    <img
                    className="start-campaign-searchbox-search-icon" 
                    src={searchlg} 
                    alt="" 
                    />
                    
                  </div>
                </div>
                <input 
                className="start-campaign-searchbox-placeholder" 
                type='text'
                placeholder='Search'
                />
              </div>
            </div>

          <div className="start-campaign-section-container">
            <div className="start-campaign-section2">
            
                <div className="start-campaign-contact-header">My Contacts</div>
                <div className= " start-campaign-contact-container">
                    <div className="start-campaign-contact-container-menu">
                        <div className="start-campaign-contact-main" >
                        <div className="start-campaign-contact-avatar-menu">
                            <div className='start-campaign-contact-avatar'  />
                            <div className='start-campaign-contact-avatar-online-indicator' />
                        </div>
                        <div className='start-campaign-contact-name-and-work-title' >
                            <div  className='start-campaign-contact-name' >Olivia Rhye</div>
                            <div className='start-campaign-contact-work-title'>Tutanis</div>
                        </div>
                        </div>
                        <div className="start-campaign-contact-checkbox-container" >
                        {/* <div style={{}}>
                            <div style={{width: 16.67, height: 13.33, border: '1.67px #475467 solid'}}></div>
                        </div> */}
                        <input  className="start-campaign-contact-checkbox" type="checkbox" />
                        </div>
                    </div>
            
            </div>


              

            </div>
          </div>

          <div className="start-campaign-input-field-container">
                <div className="start-campaign-input-field">
                  <div className="start-campaign-label12">Message</div>
                  <div className="start-campaign-input6">
                      

                  

                    <textarea
                      className="start-campaign-content72"
                      placeholder="Max 300 characters"
                      type="text"
                       maxlength="300"
                    />

                  </div>
                </div>
                
              </div>


          <div className="start-campaign-divider-wrapper">
            <div className="start-campaign-divider12"></div>
          </div>
        </section>
        <footer className="start-campaign-footer2">
          <div className="start-campaign-content76">
            <div className="start-campaign-actions19">
              <button className="start-campaign-buttonsbutton49">
                <img
                  className="start-campaign-placeholder-icon91"
                  alt=""
                  src="./public/placeholder2.svg"
                />

                <div className="start-campaign-text-padding51">
                  <div className="start-campaign-text275">Cancel</div>
                </div>
                <img
                  className="start-campaign-placeholder-icon91"
                  alt=""
                  src="./public/placeholder2.svg"
                />
              </button>
              <button className="start-campaign-buttonsbutton50">
              

                <div className="start-campaign-text-padding51">
                  <div className="start-campaign-text276">Initiate Campaign</div>
                </div>
               
              </button>
            </div>
          </div>
        </footer>
      </main>
    </div>,
     document.body
  )
}

