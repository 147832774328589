import React, { useEffect, useState } from 'react';
import "./Error.css";
// import '../../components/Navbar1/Navbar1.css'
import { Link, useNavigate } from 'react-router-dom';
import { auth, onAuthStateChanged } from '../Login/FirebaseClient';
import searchlg from '../../icons/Gerator/error_page/searchlg.svg';
import help_icon from '../../icons/Gerator/error_page/help-icon.svg';
import placeholder2 from '../../icons/Gerator/error_page/placeholder2.svg';
import placeholder from '../../icons/Gerator/error_page/placeholder.svg';
import placeholder1 from '../../icons/Gerator/error_page/placeholder1.svg';
import gerator_logo from '../../icons/Gerator/error_page/gerator04-1@2x.png';
import divider from '../../icons/Gerator/profile_settings/divider.svg';
import arrowleft from '../../icons/Gerator/error_page/arrowleft.svg';
import placeholder3 from '../../icons/Gerator/error_page/placeholder3.svg';
import placeholder4 from '../../icons/Gerator/error_page/placeholder4.svg';
import codesquare02 from '../../icons/Gerator/error_page/codesquare02.svg';
import placeholder5 from '../../icons/Gerator/error_page/placeholder5.svg';
import arrowright from '../../icons/Gerator/error_page/arrowright.svg';
import bookopen01 from '../../icons/Gerator/error_page/bookopen01.svg';
import placeholder5 from '../../icons/Gerator/error_page/placeholder5.svg';
import arrowright from '../../icons/Gerator/error_page/arrowright.svg';
import messagechatcircle from '../../icons/Gerator/error_page/messagechatcircle.svg';
import placeholder5 from '../../icons/Gerator/error_page/placeholder5.svg';
import mail01 from '../../icons/Gerator/error_page/mail01.svg';
import { Navbar1 } from '../../components/Navbar1/Navbar1';
import { Loader } from '../../components/Loader';

export const Error = () => {

  const [currentUser, setCurrentUser] = useState("");
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    // const auth = getAuth();

    // Listen for authentication state change
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is authenticated
        setCurrentUser(user);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });

    return () => unsubscribe(); // Cleanup listener on component unmount
  }, [navigate]);


  if (loading) {
    return <Loader />
  }


  if (currentUser) {
    return (
      <div className="error-page-error-404-page-desktop error-page-error-404-page-desktop-custom">
        {/* <header className="error-page-error-404-page-desktop-inner">
        <div className="error-page-frame-parent">
          <div className="error-page-logo-wrapper">
            <div className="error-page-logo">
              <img
                className="error-page-gerator-04-1-icon"
                alt=""
                src={gerator_logo}
              />
            </div>
          </div>
          <div className="error-page-navigation-actions">
            <button className="error-page-buttonsbutton">
              <img
                className="error-page-placeholder-icon"
                alt=""
                src={placeholder}
              />
             
             <Link to={"/dashboard"} style={{ textDecoration: 'none' }}>
              <div className="error-page-text-padding">
                <a className="error-page-text">Log in</a>
              </div>
              </Link>
              <img
                className="error-page-placeholder-icon"
                alt=""
                src={placeholder}
              />
            </button>
            <button className="error-page-buttonsbutton1">
              <img
                className="error-page-placeholder-icon"
                alt=""
                src={placeholder1}
              />
             
             <Link to={"/dashboard"} style={{ textDecoration: 'none' }}>
              <div className="error-page-text-padding">
                <a className="error-page-text1">Sign up</a>
              </div>
              </Link>
              <img
                className="error-page-placeholder-icon"
                alt=""
                src={placeholder1}
              />
            </button>
          </div>
        </div>
      </header> */}
        <Navbar1 userData={currentUser} navigate={navigate} />
        <img
              className="business-form-divider-icon"
              loading="lazy"
              alt=""
              src={divider}
            />
        <main className="error-page-log-in">
          <section className="error-page-container">
            <div className="error-page-content">
              <div className="error-page-container1">
                <div className="error-page-content1">
                  <div className="error-page-heading-and-supporting-text">
                    <div className="error-page-heading-and-subheading">
                      <div className="error-page-subheading">404 error</div>
                      <h1 className="error-page-heading">Oops, we lost this page!</h1>
                    </div>
                    <div className="error-page-supporting-text">
                      <p className="error-page-we-searched-high">
                        We searched high and low, but couldn’t find what you’re
                        looking for.
                      </p>
                      <p className="error-page-lets-find-a">
                        Let’s find a better place for you to go.
                      </p>
                    </div>
                  </div>
                  <div className="error-page-search-input">
                    <div className="error-page-input-field">
                      <div className="error-page-input-with-label">
                        <div className="error-page-label">Email</div>
                        <div className="error-page-input">
                          <div className="error-page-content2">
                            <img
                              className="error-page-search-lg-icon"
                              loading="lazy"
                              alt=""
                              src={searchlg}
                            />

                            <div className="error-page-text2">Search our site</div>
                          </div>
                          <img
                            className="error-page-help-icon"
                            alt=""
                            src={help_icon}
                          />
                        </div>
                      </div>
                      <div className="error-page-hint-text">
                        This is a hint text to help user.
                      </div>
                    </div>
                    <button className="error-page-buttonsbutton2">
                      <img
                        className="error-page-placeholder-icon"
                        alt=""
                        src={placeholder2}
                      />

                      <div className="error-page-text-padding">
                        <div className="error-page-text3">Search</div>
                      </div>
                      <img
                        className="error-page-placeholder-icon"
                        alt=""
                        src={placeholder2}
                      />
                    </button>
                  </div>
                  <div className="error-page-actions">
                    <button className="error-page-buttonsbutton3">
                      <img
                        className="error-page-arrow-left-icon"
                        alt=""
                        src={arrowleft}
                      />

                      <div className="error-page-text-padding">
                        <div className="error-page-text4">Go back</div>
                      </div>
                      <img
                        className="error-page-placeholder-icon6"
                        alt=""
                        src={placeholder3}
                      />
                    </button>
                    <button className="error-page-buttonsbutton4">
                      <img
                        className="error-page-placeholder-icon6"
                        alt=""
                        src={placeholder4}
                      />

                      <Link to={"/dashboard"} style={{ textDecoration: 'none' }}>
                        <div className="error-page-text-padding">
                          <div className="error-page-text5">Go home</div>
                        </div>
                      </Link>
                      <img
                        className="error-page-placeholder-icon6"
                        alt=""
                        src={placeholder4}
                      />
                    </button>
                  </div>
                </div>
              </div>
              <div className="error-page-container2">
                <div className="error-page-content3">
                  <div className="error-page-feature-text">
                    <img
                      className="error-page-code-square-02-icon"
                      loading="lazy"
                      alt=""
                      src={codesquare02}
                    />

                    <div className="error-page-content4">
                      <div className="error-page-text-and-supporting-text">
                        <div className="error-page-feature-title">Documentation</div>
                        <div className="error-page-supporting-text1">
                          Dive in to learn all about our product.
                        </div>
                      </div>
                      <div className="error-page-buttonsbutton5">
                        <img
                          className="error-page-placeholder-icon"
                          alt=""
                          src={placeholder5}
                        />

                        <div className="error-page-text6">Start learning</div>
                        <img
                          className="error-page-search-lg-icon"
                          loading="lazy"
                          alt=""
                          src={arrowright}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="error-page-feature-text">
                    <img
                      className="error-page-code-square-02-icon"
                      loading="lazy"
                      alt=""
                      src={bookopen01}
                    />

                    <div className="error-page-content4">
                      <div className="error-page-text-and-supporting-text">
                        <div className="error-page-feature-title">Our blog</div>
                        <div className="error-page-supporting-text1">
                          Read the latest posts on our blog.
                        </div>
                      </div>
                      <div className="error-page-buttonsbutton5">
                        <img
                          className="error-page-placeholder-icon"
                          alt=""
                          src={placeholder5}
                        />

                        <div className="error-page-text8">View lastest posts</div>
                        <img
                          className="error-page-search-lg-icon"
                          loading="lazy"
                          alt=""
                          src={arrowright}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="error-page-feature-text">
                    <img
                      className="error-page-code-square-02-icon"
                      loading="lazy"
                      alt=""
                      src={messagechatcircle}
                    />

                    <div className="error-page-content4">
                      <div className="error-page-text-and-supporting-text">
                        <div className="error-page-feature-title">Chat to us</div>
                        <div className="error-page-supporting-text1">
                          Can’t find what you’re looking for?
                        </div>
                      </div>
                      <div className="error-page-buttonsbutton5">
                        <img
                          className="error-page-placeholder-icon"
                          alt=""
                          src={placeholder5}
                        />

                        <div className="error-page-text8">Chat to our team</div>
                        <img
                          className="error-page-search-lg-icon"
                          loading="lazy"
                          alt=""
                          src={arrowright}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="error-page-row">
                <div className="error-page-text11">Don’t have an account?</div>
                <div className="error-page-buttonsbutton8">
                  <img
                    className="error-page-placeholder-icon12"
                    alt=""
                    src={placeholder5}
                  />

                  <Link to={"/sign-up"} style={{ textDecoration: 'none' }}>
                    <div className="error-page-text12">Sign up</div>
                  </Link>
                  <img
                    className="error-page-placeholder-icon12"
                    alt=""
                    src={placeholder5}
                  />
                </div>
              </div> */}
            </div>
          </section>
          <div className="error-page-text13">© Gerator Asia LLP. All rights reserved.</div>
          <img
            className="error-page-mail-01-icon"
            loading="lazy"
            alt=""
            src={mail01}
          />

          <div className="error-page-helpcentergeratorcom">helpcenter@gerator.com</div>
        </main>
      </div>
    )
  } else {
    return (
      <div className="error-page-error-404-page-desktop">
        <header className="error-page-error-404-page-desktop-inner">
          <div className="error-page-frame-parent">
            <div className="error-page-logo-wrapper">
              <div className="error-page-logo">
                <img
                  className="error-page-gerator-04-1-icon"
                  alt=""
                  src={gerator_logo}
                />
              </div>
            </div>
            <div className="error-page-navigation-actions">
              <button className="error-page-buttonsbutton">
                <img
                  className="error-page-placeholder-icon"
                  alt=""
                  src={placeholder}
                />

                <Link to={"/sign-in"} style={{ textDecoration: 'none' }}>
                  <div className="error-page-text-padding">
                    <div className="error-page-text">Log in</div>
                  </div>
                </Link>
                <img
                  className="error-page-placeholder-icon"
                  alt=""
                  src={placeholder}
                />
              </button>
              <button className="error-page-buttonsbutton1">
                <img
                  className="error-page-placeholder-icon"
                  alt=""
                  src={placeholder1}
                />

                <Link to={"/sign-up"} style={{ textDecoration: 'none' }}>
                  <div className="error-page-text-padding">
                    <div className="error-page-text1">Sign up</div>
                  </div>
                </Link>
                <img
                  className="error-page-placeholder-icon"
                  alt=""
                  src={placeholder1}
                />
              </button>
            </div>
          </div>
        </header>
        <main className="error-page-log-in">
          <section className="error-page-container">
            <div className="error-page-content">
              <div className="error-page-container1">
                <div className="error-page-content1">
                  <div className="error-page-heading-and-supporting-text">
                    <div className="error-page-heading-and-subheading">
                      <div className="error-page-subheading">404 error</div>
                      <h1 className="error-page-heading">Oops, we lost this page!</h1>
                    </div>
                    <div className="error-page-supporting-text">
                      <p className="error-page-we-searched-high">
                        We searched high and low, but couldn’t find what you’re
                        looking for.
                      </p>
                      <p className="error-page-lets-find-a">
                        Let’s find a better place for you to go.
                      </p>
                    </div>
                  </div>
                  <div className="error-page-search-input">
                    <div className="error-page-input-field">
                      <div className="error-page-input-with-label">
                        <div className="error-page-label">Email</div>
                        <div className="error-page-input">
                          <div className="error-page-content2">
                            <img
                              className="error-page-search-lg-icon"
                              loading="lazy"
                              alt=""
                              src={searchlg}
                            />

                            <div className="error-page-text2">Search our site</div>
                          </div>
                          <img
                            className="error-page-help-icon"
                            alt=""
                            src={help_icon}
                          />
                        </div>
                      </div>
                      <div className="error-page-hint-text">
                        This is a hint text to help user.
                      </div>
                    </div>
                    <button className="error-page-buttonsbutton2">
                      <img
                        className="error-page-placeholder-icon"
                        alt=""
                        src={placeholder2}
                      />

                      <div className="error-page-text-padding">
                        <div className="error-page-text3">Search</div>
                      </div>
                      <img
                        className="error-page-placeholder-icon"
                        alt=""
                        src={placeholder2}
                      />
                    </button>
                  </div>
                  <div className="error-page-actions">
                    <button className="error-page-buttonsbutton3">
                      <img
                        className="error-page-arrow-left-icon"
                        alt=""
                        src={arrowleft}
                      />

                      <div className="error-page-text-padding">
                        <div className="error-page-text4">Go back</div>
                      </div>
                      <img
                        className="error-page-placeholder-icon6"
                        alt=""
                        src={placeholder3}
                      />
                    </button>
                    <button className="error-page-buttonsbutton4">
                      <img
                        className="error-page-placeholder-icon6"
                        alt=""
                        src={placeholder4}
                      />

                      <Link to={"/dashboard"} style={{ textDecoration: 'none' }}>
                        <div className="error-page-text-padding">
                          <div className="error-page-text5">Go home</div>
                        </div>
                      </Link>
                      <img
                        className="error-page-placeholder-icon6"
                        alt=""
                        src={placeholder4}
                      />
                    </button>
                  </div>
                </div>
              </div>
              <div className="error-page-container2">
                <div className="error-page-content3">
                  <div className="error-page-feature-text">
                    <img
                      className="error-page-code-square-02-icon"
                      loading="lazy"
                      alt=""
                      src={codesquare02}
                    />

                    <div className="error-page-content4">
                      <div className="error-page-text-and-supporting-text">
                        <div className="error-page-feature-title">Documentation</div>
                        <div className="error-page-supporting-text1">
                          Dive in to learn all about our product.
                        </div>
                      </div>
                      <div className="error-page-buttonsbutton5">
                        <img
                          className="error-page-placeholder-icon"
                          alt=""
                          src={placeholder5}
                        />

                        <div className="error-page-text6">Start learning</div>
                        <img
                          className="error-page-search-lg-icon"
                          loading="lazy"
                          alt=""
                          src={arrowright}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="error-page-feature-text">
                    <img
                      className="error-page-code-square-02-icon"
                      loading="lazy"
                      alt=""
                      src={bookopen01}
                    />

                    <div className="error-page-content4">
                      <div className="error-page-text-and-supporting-text">
                        <div className="error-page-feature-title">Our blog</div>
                        <div className="error-page-supporting-text1">
                          Read the latest posts on our blog.
                        </div>
                      </div>
                      <div className="error-page-buttonsbutton5">
                        <img
                          className="error-page-placeholder-icon"
                          alt=""
                          src={placeholder5}
                        />

                        <div className="error-page-text8">View lastest posts</div>
                        <img
                          className="error-page-search-lg-icon"
                          loading="lazy"
                          alt=""
                          src={arrowright}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="error-page-feature-text">
                    <img
                      className="error-page-code-square-02-icon"
                      loading="lazy"
                      alt=""
                      src={messagechatcircle}
                    />

                    <div className="error-page-content4">
                      <div className="error-page-text-and-supporting-text">
                        <div className="error-page-feature-title">Chat to us</div>
                        <div className="error-page-supporting-text1">
                          Can’t find what you’re looking for?
                        </div>
                      </div>
                      <div className="error-page-buttonsbutton5">
                        <img
                          className="error-page-placeholder-icon"
                          alt=""
                          src={placeholder5}
                        />

                        <div className="error-page-text8">Chat to our team</div>
                        <img
                          className="error-page-search-lg-icon"
                          loading="lazy"
                          alt=""
                          src={arrowright}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="error-page-row">
                <div className="error-page-text11">Don’t have an account?</div>
                <div className="error-page-buttonsbutton8">
                  <img
                    className="error-page-placeholder-icon12"
                    alt=""
                    src={placeholder5}
                  />

                  <Link to={"/sign-up"} style={{ textDecoration: 'none' }}>
                    <div className="error-page-text12">Sign up</div>
                  </Link>
                  <img
                    className="error-page-placeholder-icon12"
                    alt=""
                    src={placeholder5}
                  />
                </div>
              </div>
            </div>
          </section>
          <div className="error-page-text13">© Gerator Asia LLP. All rights reserved.</div>
          <img
            className="error-page-mail-01-icon"
            loading="lazy"
            alt=""
            src={mail01}
          />

          <div className="error-page-helpcentergeratorcom">helpcenter@gerator.com</div>
        </main>
      </div>
    )
  }

}

