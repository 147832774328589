import React from 'react';
import "./AddDeviceStep.css"
import divider1 from '../../icons/Gerator/add_form/divider1.svg';
import chevrondown_2 from '../../icons/Gerator/add_form/chevrondown-2.svg';
import chevronleft from '../../icons/Gerator/add_form/chevronleft.svg';
import placeholder3 from '../../icons/Gerator/add_form/placeholder3.svg';
import save02 from '../../icons/Gerator/add_form/save02.svg';
import featured_icon from '../../icons/Gerator/add_form/featured-icon.svg';
import placeholder1 from '../../icons/Gerator/add_form/placeholder1.svg';
import placeholder2 from '../../icons/Gerator/add_form/placeholder2.svg';
import placeholder3 from '../../icons/Gerator/add_form/placeholder3.svg';
import placeholder from '../../icons/Gerator/add_form/placeholder.svg';
import page from '../../icons/Gerator/add_form/page.svg';
import checkbox_base from '../../icons/Gerator/add_form/-checkbox-base.svg';
import page from '../../icons/Gerator/add_form/page.svg';
import { Field, Formik, useField, useFormikContext, getIn } from "formik";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateField } from "./AddDeviceSlice";
import { updateAppDataDevice } from "./AppDataDeviceSlice";
import AddDeviceImageCrop from "../ImageCropComponent/AddDeviceImageCrop";
import AddDeviceFormContext from '../../screens/Device/AddDevice/AddDeviceContext';

const MySelect = ({ validate, label, ...props }) => {
  const [field, meta] = useField(props);

  const inputStyle = meta.touched && meta.error ? { borderColor: 'red' } : {};   //New code for border colour

  let fieldValidationData = null;
  // console.log("MySelect inside validation result");
  // console.log(validate);

  if (validate?.length > 0) {
    validate.some(field => { // Use 'some' for early termination
      if (field.path === props.name) {
        fieldValidationData = field;
        return true; // This will stop the loop early
      }
      return false; // Continue if no match
    });
  }


  return (
    <>
      <div className="add-job-step-first-upload-inner">
        <div className="add-job-step-label3">
          <label className="add-job-step-transaction-type" htmlFor={props.name}>
            {label} <span className="add-job-step-span">*</span>
          </label>
        </div>
        <div className="add-job-step-input1">
          <div className="add-job-step-content8">
            <div className="add-job-step-text-input">
              <select
                className="add-job-step-first-nested-input account-update-profile-content-custom-23"
                style={inputStyle}   //New code for border colour
                {...field}
                {...props}
              />
            </div>
          </div>

        </div>
      </div>






      {(meta.touched && meta.error) ? (
        <div className="error-for-all-field">{meta.error}</div>
      ) : null}

      {fieldValidationData !== null ? (
        <div className="error-for-all-field">{fieldValidationData.msg}</div>
      ) : null}
    </>
  );
};

const MyTextInput = ({ validate, label, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input>. We can use field meta to show an error
  // message if the field is invalid and it has been touched (i.e. visited)
  const [field, meta] = useField(props);
  let fieldValidationData = null;
  if (validate?.length > 0) {
    validate.some(field => { // Use 'some' for early termination
      if (field.path === props.name) {
        fieldValidationData = field;
        return true; // This will stop the loop early
      }
      return false; // Continue if no match
    });
  }

  if (fieldValidationData != null) {
    // console.log(fieldValidationData);
  }
  // {
  //   fieldValidationData !== null ? (
  //     <div className="error">{fieldValidationData.msg}</div>
  //   ) : null
  // }

  return (
    <>
      {/* <label className="label">Model Number</label> */}
      <div className="add-service-step-first-upload-inner">
        <div className="add-service-step-label5">
          <label className="add-service-step-transaction-type" htmlFor={props.id || props.name}>
            {label} <span className="add-service-step-span">*</span>
          </label>
        </div>
        <div className="add-service-step-input5">
          <input
            className="add-service-step-content12"
            // style={inputStyle}  //New code for border colour
            {...field}
            {...props}

          />
        </div>
      </div>


      {(meta.touched && meta.error) ? (
        <div className="error-for-all-field">{meta.error}</div>
      ) : null}

      {fieldValidationData !== null ? (
        <div className="error-for-all-field">{fieldValidationData.msg}</div>
      ) : null}

    </>
  );
};

const FileUpload = ({ validate, ...props }) => {
  const [field, meta] = useField(props);
  let fieldValidationData = "";
  if (validate?.length > 0) {
    validate.some(field => { // Use 'some' for early termination
      if (field.path === "featureImageObject") {
        fieldValidationData = field;
        return true; // This will stop the loop early
      }
      return false; // Continue if no match
    });
  }

  return (

    <div className='document-field-component-width'>
      <div className="add-service-step-input-with-label19">
        <a className="add-service-step-label22">
          <span className="add-service-step-your-role-in">Feature Image  </span>
          <span className="add-service-step-span">*</span>
        </a>
      </div>

      <div className="add-service-step3-file-upload-base5">
        <label htmlFor="featureImage">

          <div className="add-service-step3-content69">
            <img

              className="add-service-step3-featured-icon5"
              loading="lazy"
              alt=""
              src={featured_icon}
            />

            <div className="add-service-step3-text-and-supporting-text13">
              <div className="add-service-step3-action5">
                <div className="add-service-step3-buttonsbutton16">
                  {/* <img 
                  className="add-service-step3-placeholder-icon60"
                  alt=""
                  src={placeholder1}
                /> */}
                  <div className="add-service-step3-text88 documentLabel">Click to upload</div>
                  <input type="file" accept="image/*" {...field} {...props} className='hide-input-field' />
                </div>
              </div>
              <div className="action custom-action">

                <div className="add-service-step3-text89">or drag and drop</div>
              </div>
              <div className="add-service-step3-supporting-text28">
                <span>Please upload files having extension </span>
                <span className="add-service-step3-pdf1">PNG, JPEG & WEBP</span>
                <span> only (Maximum file size. 1 MB)</span>
              </div>
            </div>
          </div>
        </label>
        {(meta.touched && meta.error) ? (
          <div className="error-for-all-field error-for-document">{meta.error}</div>
        ) : null}

        {fieldValidationData !== "" ? (
          <div className="error-for-all-field error-for-document">{fieldValidationData.msg}</div>
        ) : null}
      </div>


    </div>
  );
};

const MultiFileUpload = ({ validate, maxfilewarning, minfilewarning, ...props }) => {
  const [field, meta] = useField(props);
  let fieldValidationData = "";
  if (validate?.length > 0) {
    validate.some(field => { // Use 'some' for early termination
      if (field.path === "galleryImageObject") {
        fieldValidationData = field;
        return true; // This will stop the loop early
      }
      return false; // Continue if no match
    });
  }

  return (

    <div className='document-field-component-width'>
      <div className="add-service-step-input-with-label19">
        <a className="add-service-step-label22">
          <span className="add-service-step-your-role-in">Gallery  </span>
          <span className="add-service-step-span">*</span>
        </a>
      </div>

      <div className="add-service-step3-file-upload-base5">
        <label htmlFor="gallery">

          <div className="add-service-step3-content69">
            <img

              className="add-service-step3-featured-icon5"
              loading="lazy"
              alt=""
              src={featured_icon}
            />

            <div className="add-service-step3-text-and-supporting-text13">
              <div className="add-service-step3-action5">
                <div className="add-service-step3-buttonsbutton16">
                  {/* <img 
                  className="add-service-step3-placeholder-icon60"
                  alt=""
                  src={placeholder1}
                /> */}
                  <div className="add-service-step3-text88 documentLabel">Click to upload</div>
                  <input type="file" multiple accept="image/*" {...field} {...props} className='hide-input-field' />
                </div>
              </div>
              <div className="action custom-action">

                <div className="add-service-step3-text89">or drag and drop</div>
              </div>
              <div className="add-service-step3-supporting-text28">
                <span>Please upload files having extension </span>
                <span className="add-service-step3-pdf1">PNG, JPEG & WEBP</span>
                <span> only (Maximum file size. 1 MB)</span>
              </div>
            </div>
          </div>
        </label>

        {maxfilewarning.isMaxImage && (
          <div>
            <div style={{ color: "red" }}>
              {maxfilewarning.message}
            </div>
          </div>
        )}
        {minfilewarning.isMinImage && (
          <div>
            <div style={{ color: "red" }}>
              {minfilewarning.message}
            </div>
          </div>
        )}
        {(meta.touched && meta.error) ? (
          <div className="error-for-all-field error-for-document">{meta.error}</div>
        ) : null}

        {fieldValidationData !== "" ? (
          <div className="error-for-all-field error-for-document">{fieldValidationData.msg}</div>
        ) : null}
      </div>


    </div>
  );
};

export const AddDeviceStep2 = ({ setStep }) => {

  const dispatch = useDispatch();
  const formData = useSelector((state) => state.addDevice);
  const appData = useSelector((state) => state.appDataAddDevice);

  // ------------ Feature Image Hooks ---------------------

  //This hook will store error message of featureImage 
  const [imageSingleCheckboxError, setImageSingleCheckboxError] = useState("");
  //This hook will store image file if uploaded image have some validation error on FeatureImage 
  const [featureImageDemoFile, setFeatureImageDemoFile] = useState("");

  // ------------ Gallery Image Hooks ---------------------

  //This hook will store boolean value if uploaded image has total 10 image validation exceeding
  const [maxFileValidation, setMaxFileValidation] = useState({ message: "Maximum of 5 files are allowed to upload", isMaxImage: false });

  const [minFileValidation, setMinFileValidation] = useState({ message: "At least 3 files are required to upload", isMinImage: false })

  //This hook will store all error message of all uploaded images. using when showing error message on image card.
  const [imageMultiCheckboxError, setImageMultiCheckboxError] = useState([]);

  // ------------ Formik Methods to manage form flow ---------------------
  const { values, validateForm, setFieldValue, setFieldError, setErrors, setFieldTouched, validateField, errors, isValid, dirty, touched } = useFormikContext();

  // ------------ Form Context getting from Adddeviceparent.jsx ---------------------
  const { backendValidation, allTaxonomy } = useContext(AddDeviceFormContext);
  const { isImageSelected, setIsImageSelected, selectedMultiImageFile, setSelectedMultiImageFile, selectedImageFile, setSelectedImageFile, cropedImageFile, setCropedImageFile } = useContext(AddDeviceFormContext);

  // ------------ These refs used to manage radio field state ---------------------
  const inputYoutubeRef = useRef(null);
  const inputVimeoRef = useRef(null);

  useEffect(() => {
    console.log(allTaxonomy.relatedAddress.data.data)
    // allTaxonomy.relatedAddress.data.map(tax => (

    // ))
  }, [allTaxonomy.relatedAddress])


  /* 
      This useEffect will provide updated values from formik and run some conditions Used on FeatureImage
  */
  useEffect(() => {
    console.log("Values useEffect Open");

    if (touched.featureImage) {
      if (errors.featureImage && values.featureImage !== "") {
        console.log("Values Error UseEffect");
        handleSelectedImageFile("error");
        return; // Exit useEffect to avoid further actions if there are errors
      }

      // 2. Proceed with Updates if No Errors:
      if (touched.featureImage && values.featureImage != "") {
        console.log("Values Touch UseEffect");
        // setGalleryImageFile([...values.gallery]);
        handleSelectedImageFile("no-error");
      }
    }
    // 1. Check for Errors FIRST:

    console.log("Values useEffect Closed ");
    // }

  }, [values.featureImage, errors.featureImage]);


  /* 
    This useEffect will provide updated values from formik and run some conditions Used on Gallery Image
*/
  useEffect(() => {
    console.log("Values useEffect Open");

    //BELOWE CODE IS FOR GALLERY IMAGES
    if (touched.gallery) {
      if (errors.gallery && values.gallery.length > 0) {
        console.log("Values Error UseEffect");
        handleSelectedMultiImageFile("error");
        return; // Exit useEffect to avoid further actions if there are errors
      }

      // 2. Proceed with Updates if No Errors:
      if (touched.gallery && values.gallery[0] != null) {
        console.log("Values Touch UseEffect");
        // setGalleryImageFile([...values.gallery]);
        handleSelectedMultiImageFile("no-error");
      }
    }
    // 1. Check for Errors FIRST:

    console.log("Values useEffect Closed ");


  }, [values, errors.gallery]);

  //Added For loggin purposes
  // useEffect(() => {
  //   console.log("Errors in image checkbox");
  //   console.log(imageMultiCheckboxError);
  // }, [imageMultiCheckboxError])




  //onChange handle method for featureImage where populating image
  const handleSelectedImageFile = async (type) => {

    console.log("HandleSelectedImageFile Called " + type);

    setFieldTouched("featureImage", true); // Always touch the field for validation

    console.log(errors);

    // 3. Additional Error Check:
    if (errors.featureImage) {
      console.log("Inside errors.gallery is true means error found ");
      if (cropedImageFile !== '') setCropedImageFile('')
      setSelectedImageFile('');
      setFeatureImageDemoFile(values.featureImage);
      setImageSingleCheckboxError(errors.featureImage);

      return; // Don't proceed with upload if there's an error
    }

    // 4. Proceed with Upload if No Errors:
    console.log("Inside !errors.gallery is true means no error found");
    setFeatureImageDemoFile('');
    setImageSingleCheckboxError('');
    if (cropedImageFile === '') setSelectedImageFile(values.featureImage);
    // else if (cropedImageFile !== '') {
    //   await setCropedImageFile('');
    //   setSelectedImageFile(values.featureImage);
    // }
    console.log("File added into select multi image hook");

  }



  //onClick handle method For featureImage remove
  const handleImageCheckbox = async (e) => {

    //If croped Image hook has some data then clean up 
    if (cropedImageFile !== '') setCropedImageFile('');
    //If errors.featureImage has some errors then filter then set featureImage to "", clear other field as well
    if (errors.featureImage) {
      console.log(errors.featureImage);
      const filteredErrors = Object.keys(errors).reduce((acc, fieldName) => {
        // Filter based on your condition (replace with your actual logic)
        if (fieldName !== 'featureImage') {
          acc[fieldName] = errors[fieldName];
        }
        return acc;
      }, {});
      console.log(filteredErrors);
      await setFieldValue("featureImage", '');
      setErrors(filteredErrors)
      setFeatureImageDemoFile('');
      setImageSingleCheckboxError('');
    } else {

      await setFieldValue("featureImage", '');
      setFeatureImageDemoFile('');
      setImageSingleCheckboxError('');
    }
  }

  const handleSelectedMultiImageFile = async (type) => {
    console.log("HandleSelectedMultiImageFile Called " + type);

    setFieldTouched("gallery", true); // Always touch the field for validation

    console.log(errors);

    // 3. Additional Error Check:
    if (errors.gallery) {
      console.log("Inside errors.gallery is true means error found 435");
      setSelectedMultiImageFile(values.gallery);
      setImageMultiCheckboxError(errors.gallery);
      return; // Don't proceed with upload if there's an error
    }

    // 4. Proceed with Upload if No Errors:
    console.log("Inside !errors.gallery is true means no error found 435");
    setSelectedMultiImageFile(values.gallery);
    console.log("File added into select multi image hook");
  };


  //onClick handle method For gallery image remove one by one
  const handleImageMultiCheckbox = async (indexToRemove) => {
    await setSelectedMultiImageFile(selectedMultiImageFile => selectedMultiImageFile.filter((_, index) => index !== indexToRemove));

    const gallery = values.gallery;
    gallery.splice(indexToRemove, 1)

    if (imageMultiCheckboxError.length !== 0) {
      const errorMessages = imageMultiCheckboxError;
      errorMessages.splice(indexToRemove, 1);
      setImageMultiCheckboxError(errorMessages);
    }

    console.log("handleImageMultiCheckbox index removed :" + indexToRemove);
    console.log(gallery);

    if (gallery.length !== 0) {
      const filteredErrors = Object.keys(errors).reduce((acc, fieldName) => {
        // Filter based on your condition (replace with your actual logic)
        if (fieldName !== 'gallery') {
          acc[fieldName] = errors[fieldName];
        }
        return acc;
      }, {});
      setErrors(filteredErrors)
      await setFieldValue('gallery', gallery);

      //Custom Max Min file check validation.
      customValidation();
      console.log("handleImageMultiCheckbox working.....");
      return;
    } else {
      setFieldValue('gallery', []);
    }

    console.log("handleImageMultiCheckbox closed.....");

  }

  //This handle method used to capture input entered text and save them into redux state
  const handleChange = async (event) => {
    const { name, value } = event.target;
    console.log("handle change called");

    if (name === 'featureImage') {
      const file = event.target.files[0];
      console.log('\x1b[36m%s\x1b[0m', "inside handle change called");
      setFieldValue(name, file);
      setFieldTouched("featureImage", true);
      return;
    }

    if (name === 'gallery') {

      console.log("Gallery new File uploading.....................");
      console.log(event.target.files);
      const filesObject = event.target.files;
      const fileObjects = Array.from(filesObject).map(file => ({
        file: file, // Store the original file object
      }));
      let fileLength = values.gallery.length + fileObjects.length;

      (fileLength < 3) ? setMinFileValidation(prev => ({ ...prev, isMinImage: true }))
        : setMinFileValidation(prev => ({ ...prev, isMinImage: false }))
      // if (fileLength < 3) {
      //   setMinFileValidation((prevState) => ({
      //     ...prevState,    // Copy all existing properties
      //     isMinImage: true // Update isMaxImage
      //   }));

      // } else {
      //   setMinFileValidation((prevState) => ({
      //     ...prevState,    // Copy all existing properties
      //     isMinImage: false // Update isMaxImage
      //   }));
      // }
      if (fileLength <= 5) {
        maxFileValidation.isMaxImage && setMaxFileValidation(prev => ({ ...prev, isMaxImage: false }));
        setFieldTouched("gallery", true);
        await setFieldValue(name, [...values.gallery, ...fileObjects]);
      } else {
        setMaxFileValidation(prev => ({ ...prev, isMaxImage: true }));
      };
      console.log("Values updated........");
      return;
    }

    console.log("handle change closed");

    setFieldValue(name, value);
    dispatch(updateField({ field: name, value }));

  };

  //onClick Listener on input radio tag(video/youtube)
  useEffect(() => {
    if (inputYoutubeRef.current) {
      inputYoutubeRef.current.addEventListener('click', handleClickYoutube);
    }
    return () => { // Add a cleanup function
      if (inputYoutubeRef.current) {
        inputYoutubeRef.current.removeEventListener('click', handleClickYoutube);
      }
    }
  }, []);

  //onClick Listener on input radio tag(video/vimeo)
  useEffect(() => {
    if (inputVimeoRef.current) {
      inputVimeoRef.current.addEventListener('click', handleClickVimeo);
    }
    return () => { // Add a cleanup function
      if (inputVimeoRef.current) {
        inputVimeoRef.current.removeEventListener('click', handleClickVimeo);
      }
    }
  }, []);


  //Click method for Youtube radio
  const handleClickYoutube = (e) => {
    // Reset Vimeo when Youtube is clicked
    setFieldValue("videoType", "Youtube")
    dispatch(updateAppDataDevice({ case: "VIDEO", field: "isVimeoChecked", value: false }))
    dispatch(updateAppDataDevice({ case: "VIDEO", field: "isYoutubeChecked", value: true }))
    dispatch(updateAppDataDevice({ case: "VIDEO", field: "myfieldinsideradio", value: true }))
  };

  //Click method for Vimeo radio
  const handleClickVimeo = (e) => {
    // Reset Youtube when Vimeo is clicked
    setFieldValue("videoType", "Vimeo")
    dispatch(updateAppDataDevice({ case: "VIDEO", field: "isYoutubeChecked", value: false }))
    dispatch(updateAppDataDevice({ case: "VIDEO", field: "isVimeoChecked", value: true }))
    dispatch(updateAppDataDevice({ case: "VIDEO", field: "myfieldinsideradio", value: true }))
  };

  //This method used to decrement current step of form and update state in appData reducer
  const prev = () => {
    // dispatch(updateField({transactionType:"Nothing"}));
    dispatch(updateAppDataDevice({ case: "CURRENTSTEP", value: (appData.currentStep - 1) }))
  }

  //This method used to increment current step of form and update state in appData reducer
  const next = async () => {

    // dispatch(updateAppDataDevice({ case: "CURRENTSTEP", value: appData.currentStep + 1 }));


    const errors = await validateForm();
    // dispatch(updateAppDataDevice({ case: "CURRENTSTEP", value: appData.currentStep + 1 }));

    // Check all condition regarding validation and errors then go to next step
    if (values.gallery.length >= 3 && values.gallery.length <= 5 && (!Object.keys(errors).length)) {
      dispatch(updateAppDataDevice({ case: "CURRENTSTEP", value: appData.currentStep + 1 }));
    }

    // Below code make field touch so error will be visible on form 
    for (const key in errors) {
      setFieldTouched(key, true);
    }

    // This will check if images are uploaded. if yes then how much if that is less than 3 then show validation message
    setMinFileValidation(prev => ({ ...prev, isMinImage: values.gallery.length < 3 }));

  };


  // if (cropedImageFile !== '') {
  //   console.log("Croped image has something " + (cropedImageFile !== ''));
  //   const filteredErrors = Object.keys(errors).reduce((acc, fieldName) => {
  //     if (fieldName !== 'featureImage') {
  //       acc[fieldName] = errors[fieldName];
  //     }
  //     return acc;
  //   }, {});
  //   setErrors(filteredErrors)
  // }


  const customValidation = () => {
    //gallery length is less than 3 then save true
    setMinFileValidation(prev => ({ ...prev, isMinImage: values.gallery.length < 3 }));

    //If isMaxImage is true then save value to false
    maxFileValidation.isMaxImage && setMaxFileValidation(prev => ({ ...prev, isMaxImage: false }));

  }

  return (

    //  < form className="add-device-step-section-header-parent">


    <>
      <div className="add-device-step-section-header">

        <div className="add-device-step-content7">Highlights</div>



        <img
          className="add-device-step-divider-icon2"
          alt=""
          src={divider1}
        />
      </div>
      <div className="add-device-step-tagged-inputs">
        <div className="add-device-step-input-field12">
          <MyTextInput
            label="Hardware - Highlights"
            name="hardwareHighlights"
            id="hardwareHighlights"                                                          //New Code
            type="text"
            validate={backendValidation}
            onChange={handleChange}
            value={formData.hardwareHighlights}
            placeholder="190 characters"
          />

        </div>
      </div>
      <div className="add-device-step-tagged-inputs">
        <div className="add-device-step-input-field12">
          <MyTextInput
            label="Software / Upgrades / OS / Applications / Worklist - Highlights"
            name="softwareUpgradesOsApplicationsworklistHighlights"                                                          //New Code
            id="softwareUpgradesOsApplicationsworklistHighlights"                                                          //New Code
            type="text"
            validate={backendValidation}
            onChange={handleChange}
            value={formData.softwareUpgradesOsApplicationsworklistHighlights}
            placeholder="190 characters"
          />
        </div>
      </div>
      <div className="add-device-step-tagged-inputs">
        <div className="add-device-step-input-field12">
          <MyTextInput
            label="Accessories - Highlights"
            name="accessoriesHighlights"                                                          //New Code
            id="accessoriesHighlights"                                                          //New Code
            type="text"
            onChange={handleChange}
            validate={backendValidation}
            value={formData.accessoriesHighlights}
            placeholder="190 characters"
          />
        </div>
      </div>
      <div className="add-software-step-single-input-parent">
        <div className="add-service-step-first-upload-container">
          <div className="add-service-step-first-upload-inner">
            <FileUpload key={selectedImageFile ? selectedImageFile.name : Date.now()} name="featureImage" id="featureImage" validate={backendValidation} onChange={handleChange} value={undefined} />
            {(selectedImageFile instanceof File) && (
              <AddDeviceImageCrop />
            )}



            {
              (typeof cropedImageFile === 'object' && cropedImageFile !== "") &&

              (
                <div className="add-service-step-file-upload-item-base">
                  <div className="add-service-step-first-upload-item-content">
                    <div className="add-service-step-file-type-icon">
                      <img
                        className="add-service-step-page-icon"
                        loading="lazy"
                        alt=""
                        src={page}
                      />

                      <div className="add-service-step-file-type-wrap">
                        <b className="add-service-step-file-type">IMG</b>
                      </div>
                    </div>
                    <div className="add-service-step-first-upload-item-info">
                      <div className="add-service-step-first-upload-item-inner">
                        <div className="add-service-step-first-upload-item">
                          {cropedImageFile.name}
                        </div>
                        <div className="add-service-step3-checkbox3">
                          <Field type="checkbox" onClick={handleImageCheckbox} name="imageCheckbox" checked className="checkbox-instance checkbox-base size-30-sm state-8-default checked-true type-checkbox checkbox-2"></Field>
                        </div>
                      </div>
                      <div className="add-service-step3-supporting-text14">{(cropedImageFile.size / 1000).toFixed(1) + " KB"}</div>
                    </div>
                  </div>
                  <div className="add-service-step3-progress-bar8">
                    <div className="add-service-step3-progress-bar-wrapper">
                      <div className="add-service-step3-progress-bar9">
                        <div className="add-service-step3-background3"></div>
                        <div className="add-service-step3-progress3"></div>
                      </div>
                    </div>
                    {(imageSingleCheckboxError !== "") ?
                      <div style={{ color: "red" }}>
                        {imageSingleCheckboxError}
                      </div> :
                      <div className="add-service-step3-percentage3">100%</div>
                    }
                  </div>

                </div>
              )
            }
            {
              (featureImageDemoFile instanceof File) &&

              (
                <div className="add-service-step-file-upload-item-base">
                  <div className="add-service-step-first-upload-item-content">
                    <div className="add-service-step-file-type-icon">
                      <img
                        className="add-service-step-page-icon"
                        loading="lazy"
                        alt=""
                        src={page}
                      />

                      <div className="add-service-step-file-type-wrap">
                        <b className="add-service-step-file-type">IMG</b>
                      </div>
                    </div>
                    <div className="add-service-step-first-upload-item-info">
                      <div className="add-service-step-first-upload-item-inner">
                        <div className="add-service-step-first-upload-item">
                          {featureImageDemoFile.name}
                        </div>
                        <div className="add-service-step3-checkbox3">
                          <Field type="checkbox" onClick={handleImageCheckbox} name="imageCheckbox" checked className="checkbox-instance checkbox-base size-30-sm state-8-default checked-true type-checkbox checkbox-2"></Field>
                        </div>
                      </div>
                      <div className="add-service-step3-supporting-text14">{(featureImageDemoFile.size / 1000).toFixed(1) + " KB"}</div>
                    </div>
                  </div>
                  <div className="add-service-step3-progress-bar8">
                    <div className="add-service-step3-progress-bar-wrapper">
                      <div className="add-service-step3-progress-bar9">
                        <div className="add-service-step3-background3"></div>
                        <div className="add-service-step3-progress3"></div>
                      </div>
                    </div>
                    {(imageSingleCheckboxError !== "") ?
                      <div style={{ color: "red" }}>
                        {imageSingleCheckboxError}
                      </div> :
                      <div className="add-service-step3-percentage3">100%</div>
                    }
                  </div>

                </div>
              )
            }
          </div>
          <div className="add-service-step-tagged-inputs">
            <div className="add-service-step-input-field12">
              <div className="add-service-step-input-with-label19">
                <div className="add-service-step-label21">
                  <span className="add-service-step-transaction-type">Video </span>

                </div>
              </div>
              {/* <div className="add-service-step-hint-text1">
            This is a hint text to help user.
          </div> */}
              <div className="add-service-step-file-button-container">
                <div className="add-service-step-buttonsbutton4">
                  {/* <img
                    className="add-service-step-search-lg-icon"
                    alt=""
                    src={placeholder}
                  /> */}

                  <div className="add-service-step-text-padding6">
                    <div className="add-service-step-file-button-labels">YouTube
                      <Field type="radio" id="youtube" name="video" value="Youtube" innerRef={inputYoutubeRef} checked={appData.isYoutubeChecked} />

                    </div>
                  </div>
                </div>
                <div className="add-service-step-buttonsbutton4">
                  {/* <img
                    className="add-service-step-search-lg-icon"
                    alt=""
                    src={placeholder}
                  /> */}

                  <div className="add-service-step-text-padding6">
                    <div className="add-service-step-text48">Vimeo
                      <Field type="radio" id='vimeo' name="video" value="Vimeo" innerRef={inputVimeoRef} checked={appData.isVimeoChecked} />

                    </div>
                  </div>
                </div>
                {appData.myfieldinsideradio && (
                  <Field type="text" name="linkVideo" className="text-input text-25 content-15 myinputfield" onChange={handleChange}
                    value={formData.linkVideo} />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="add-service-step-first-upload-container">
          <div className="add-service-step-first-upload-inner">
            <MultiFileUpload name="gallery" id="gallery" onChange={handleChange} validate={backendValidation} maxfilewarning={maxFileValidation} minfilewarning={minFileValidation} value={[undefined]} />
            {
              (typeof selectedMultiImageFile === 'object' && selectedMultiImageFile.length !== 0) && (
                selectedMultiImageFile?.map((imageObject, index) => (
                  <div className="add-service-step-file-upload-item-base" key={index}>
                    <div className="add-service-step-first-upload-item-content">
                      <div className="add-service-step-file-type-icon">
                        <img
                          className="add-service-step-page-icon"
                          loading="lazy"
                          alt=""
                          src={page}
                        />

                        <div className="add-service-step-file-type-wrap">
                          <b className="add-service-step-file-type">IMG</b>
                        </div>
                      </div>
                      <div className="add-service-step-first-upload-item-info">
                        <div className="add-service-step-first-upload-item-inner">
                          <div className="add-service-step-first-upload-item">
                            {imageObject.file.name}
                          </div>
                          <div className="add-service-step3-checkbox3">
                            {(imageMultiCheckboxError[index]) ?
                              <Field type="checkbox" onClick={() => handleImageMultiCheckbox(index)} name="imageMultiCheckbox" checked className="checkbox-instance custom-checkbox"></Field> : <Field type="checkbox" onClick={() => handleImageMultiCheckbox(index)} name="imageMultiCheckbox" checked className="checkbox-instance checkbox-base size-30-sm state-8-default checked-false type-checkbox checkbox-2"></Field>}
                          </div>
                        </div>
                        <div className="add-service-step3-supporting-text14">{(imageObject.file.size / 1000).toFixed(1) + " KB"}</div>
                      </div>
                    </div>
                    <div className="add-service-step3-progress-bar8">
                      <div className="add-service-step3-progress-bar-wrapper">
                        <div className="add-service-step3-progress-bar9">
                          <div className="add-service-step3-background3"></div>
                          <div className="add-service-step3-progress3"></div>
                        </div>
                      </div>
                      {(imageMultiCheckboxError[index]) ?
                        <div key={index} style={{ color: "red" }}>
                          {imageMultiCheckboxError[index]}
                        </div> :
                        <div className="add-service-step3-percentage3">100%</div>
                      }
                    </div>

                  </div>


                ))

              )
            }


          </div>
        </div>
      </div>
      <div className="add-device-step-input-tags">
        <div className="add-device-step-divider"></div>
        <div className="add-device-step-section-header">

          <div className="add-device-step-content7">Current Location Of the Device ?</div>


          <img
            className="add-device-step-divider-icon2"
            alt=""
            src={divider1}
          />
        </div>
        <div className="add-device-step-input-field13">
          <MySelect label="Location" name="location" id="location" onChange={handleChange} validate={backendValidation} value={formData.location}>
            <option value="0">Select From Dropdown List </option>
            {(allTaxonomy.relatedAddress.isLoading || allTaxonomy.relatedAddress.isError) && <option value="0">Loading...</option>}
            {
              (allTaxonomy.relatedAddress.isSuccess) && (allTaxonomy.relatedAddress.data.data.map(tax => (
                <option key={tax.id} value={tax.id}>{tax.addressTitle}{tax.fullAddress ? ` : ${tax.fullAddress}` : ""}</option>
              )))
            }
          </MySelect>
          <div className="add-device-step-hint-text21">
            <span className="add-device-step-cant-find-an"
            >Cant find an address?
            </span>
            <span className="add-device-step-add-it">Add it.</span>
          </div>
        </div>
      </div>
      <div className="add-device-step-section-footer1">
        <div className="add-device-step-section-footer2">
          <img
            className="add-device-step-divider-icon2"
            loading="lazy"
            alt=""
            src={divider1}
          />

          <div className="add-device-step-content31">

            <div className="add-device-step-actions2">

              <button className="add-device-step-button11" type='button' onClick={prev}>
                <img
                  className="add-device-step-chevron-down-icon"
                  alt=""
                  src={placeholder2}
                />

                <img
                  className="add-device-step-home-line-icon"
                  alt=""
                  src={chevronleft}
                />

                <div className="add-device-step-text-padding6">
                  <div className="add-device-step-text53">Back</div>
                </div>
                <img
                  className="add-device-step-chevron-down-icon"
                  alt=""
                  src={placeholder2}
                />
              </button>
              <button className="add-device-step-button9" type='button' onClick={next}>
                <img
                  className="add-device-step-chevron-down-icon"
                  alt=""
                  src={placeholder3}
                />

                <img
                  className="add-device-step-home-line-icon"
                  alt=""
                  src={save02}
                />

                <div className="add-device-step-text-padding6">
                  <div className="add-device-step-text44" >Save & Proceed</div>
                </div>
                <img
                  className="add-device-step-chevron-down-icon"
                  alt=""
                  src={placeholder3}
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>

    //  </form>


  )
}

