import React, { useEffect, useRef, useState } from "react";
import { RatingStarComponent } from "../RatingStarComponent/RatingStarComponent";
import { ReviewDescriptionComponent } from "../ReviewDescriptionComponent/ReviewDescriptionComponent";
import placeholder2 from "../../../icons/Gerator/detailed_listing/placeholder2.svg";
import arrowdown1 from '../../../icons/Gerator/dashboard/arrowdown1.svg';
import star_icon from "../../../icons/Gerator/detailed_listing/star-icon.svg";
import empty_star_icon_new from "../../../icons/Gerator/detailed_listing/empty_star_icon_new.svg";
import { useAddReviewDeviceMutation, useLazyGetAllReviewOfSinglePostDevicesQuery } from "../../../api/Review/ReviewDeviceAPI";
import { decryptErrorData, encryptData } from "../../../helper/AESHelper";
import { Form, Formik, useField } from "formik";
import * as Yup from "yup";
import { objectToReviewFormData } from "../../../helper/ReviewHelper";
import group_78_2x from '../../../icons/Gerator/detailed_listing/group-78@2x.png';
import star_icon from '../../../icons/Gerator/detailed_listing/star-icon.svg';
import messagedotscircle from '../../../icons/Gerator/detailed_listing/messagedotscircle.svg';
import placeholder1 from '../../../icons/Gerator/detailed_listing/placeholder1.svg';
import { ErrorShowComponent } from "../../ErrorShowComponent/ErrorShowComponent";
import SkeletonTableListingLoaderComponent from "../../SkeletonTableListingLoaderComponent/SkeletonTableListingLoaderComponent";
import { NoTableDataDisplay } from "../../NoTableDataDisplay/NoTableDataDisplay";
import { getCustomizedDate, getLastSeen } from "../../../helper/DeviceHelper";
import { useAddReplyDeviceMutation } from "../../../api/Review/Reply/ReplyDeviceAPI";
import searchlg from '../../../icons/Gerator/detailed_listing/searchlg.svg';
import filterlines from '../../../icons/Gerator/detailed_listing/filterlines.svg';
import star_background from '../../../icons/Gerator/detailed_listing/star-background.svg';
import star_icon from '../../../icons/Gerator/detailed_listing/star-icon.svg';
import { SortByFilterComponent } from "../../SortByFilterComponent/SortByFilterComponent";
import { FilterBySinglePostPageComponent } from "../../FilterBySinglePostPageComponent/FilterBySinglePostPageComponent";
import SearchInputListingComponent from "../../SearchInputListingComponent/SearchInputListingComponent";
import { NoReviews } from "../NoReviewComponent/NoReviews";
import { useLazyGetAllReviewOfSinglePostSoftwaresQuery } from "../../../api/Review/ReviewSoftwareAPI";
import { useAddReplySoftwareMutation } from "../../../api/Review/Reply/ReplySoftwareAPI";



const ReviewSoftwareView = ({ isAuthor, listingId, currentUsermeta }) => {

  const [loadingComplete, setLoadingComplete] = useState(false);
  let content;
  //Offset to load data when click load more
  const [offset, setOffset] = useState(0);
  const [sortBy, setSortBy] = useState('date_desc');
  const [searchTerm, setSearchTerm] = useState('');
  // const [totalCount, setTotalCount] = useState("");
  const [showReply, setShowReply] = useState(false);
  const [replyStates, setReplyStates] = useState({});
  const [replyText, setReplyText] = useState('');
  const maxLength = 500; // Maximum character count

  const remainingChars = maxLength - replyText.length;
  const warningClass = remainingChars <= 50 ? 'warning' : '';

  //Sort by text show on button
  const [selectedSortOption, setSelectedSortOption] = useState('');

  //For SortBy Dropdown
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);
  // const [activePostStatus, setActivePostStatusFilter] = useState(null);
  const toggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible);
  }

  const [showSuccessMessage, setShowSuccessMessage] = useState({
    message: "",
    color: "",
  });

  //Get Review query
  const [triggerGetAllReview, { data, isLoading, isError, error, isSuccess, isFetching }] = useLazyGetAllReviewOfSinglePostSoftwaresQuery();

  //Add reply query
  const [addReply, { data: addReplyData, isLoading: isAddReplyLoading, isError: isAddReplyError, isSuccess: isAddReplySuccess, error: errorAddReply }] = useAddReplySoftwareMutation();

  // const handleReplyClick = () => {
  //   setShowReply(!showReply); // Toggle text area visibility
  // };

  // const handleChange = (event) => {
  //   if (event.target.value.length <= maxLength) {
  //     setReplyText(event.target.value); // Update reply text
  //   }
  // };

  // //Responsible to hid reply add api to save into db
  // const handleSubmit = async (reviewId) => {
  //   // Handle reply submission here
  //   console.log('Reply submitted:', replyText);
  //   const reply = { ownerId: currentUsermeta.userId, reviewId, replyText }
  //   const encryptedValue = encryptData(reply);
  //   const replyStatus = await addReply(encryptedValue).unwrap();
  //   console.log(replyStatus);

  //   if (replyStatus.status === true) {
  //     window.location.reload();
  //   }
  //   // setShowReply(false); // Hide the text area after submission
  // };

  const handleReplyClick = (reviewId) => {
    setReplyStates((prevState) => ({
      ...prevState,
      [reviewId]: {
        ...prevState[reviewId],
        showReply: !prevState[reviewId]?.showReply, // Toggle the showReply state for this review
      },
    }));
  };

  const handleChange = (event, reviewId) => {
    const { value } = event.target;
    if (value.length <= maxLength) {
      setReplyStates((prevState) => ({
        ...prevState,
        [reviewId]: {
          ...prevState[reviewId],
          replyText: value, // Update the reply text for this review
        },
      }));
    }
  };

  const handleSubmit = async (reviewId) => {
    const replyText = replyStates[reviewId]?.replyText || '';
    console.log('Reply submitted:', replyText);

    const reply = { ownerId: currentUsermeta.userId, reviewId, replyText };
    const encryptedValue = encryptData(reply);
    const replyStatus = await addReply(encryptedValue).unwrap();

    if (replyStatus.status === true) {
      window.location.reload();
    }
  };



  //Search related method to pass search text into state
  const handleSearch = (term) => {
    setSearchTerm(term);
  };


  //Set value for sortby selected option on button text
  const handleOptionSelect = (option) => {
    if (option.value !== sortBy) {
      setSortBy(option.value);
      setOffset(0); // Reset offset when sort option changes
    }
    setSelectedSortOption(option);
    setDropdownVisible(false);
  };

  //Onclick listener for sortby dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);



  // //Show message if error when adding reply
  useEffect(() => {

    console.log(errorAddReply);

    if (errorAddReply?.status === 422) {
      setShowSuccessMessage({ message: errorAddReply.data.errors[0].msg, color: "red" });
    }
    if (errorAddReply?.status === 500) {
      setShowSuccessMessage({ message: "internal server error", color: "red" });
    }
  }, [errorAddReply]);

  // { id: listingId, offset, sortBy, search: searchTerm },
  // {
  //   // Refetch when component mounts or tab becomes active
  //   refetchOnMountOrArgChange: true
  // }

  useEffect(() => {
    if (currentUsermeta && listingId) {
      triggerGetAllReview({ id: listingId, offset, sortBy, search: searchTerm },
        {
          // Refetch when component mounts or tab becomes active
          refetchOnMountOrArgChange: true
        });
    }
  }, [currentUsermeta, listingId, offset, sortBy, searchTerm]);

  //Success true then pause to show sekelton layout
  useEffect(() => {
    console.log(data);

    if (isSuccess) {

      const timer = setTimeout(() => {
        setLoadingComplete(true);
      }, 1000); // 1 second

      // Cleanup timer on component unmount
      return () => clearTimeout(timer);
    }
  }, [isSuccess]);


  const handleLoadMore = () => {
    if (data?.hasMore && !isFetching) {
      setOffset(currentOffset => currentOffset + 5);
    }
  };

  const showLoadMore = data?.hasMore &&
    data.review_software.length < data.totalCountReviewSoftware &&
    !isFetching;


  if (isError) {
    content = <ErrorShowComponent message1={'Internal server error'} />
  }

  if (isLoading) {
    content = <SkeletonTableListingLoaderComponent />;
  } else if (loadingComplete && isSuccess) {

    if (data?.review_software.length > 0) {
      // currentActiveMemberData.activeMembersLength = currentActiveMemberData.activeMembers.length;
      content = data.review_software.map((review, index) =>
      (
        <div className="detailed-software-client-feedback-parent" key={index}>
          <div className="detailed-software-client-feedback">
            <div className="detailed-software-feedback-content">
              <img
                className="detailed-software-feedback-content-child"
                alt=""
                src={review.reviewProfileImage || group_78_2x}
              />
              <div className="detailed-software-feedback-header">
                <div className="detailed-software-header-info">
                  <div className="detailed-software-heading20">{review.review_user?.displayName}</div>
                  <div className="detailed-software-heading21">
                    <span>{review.review_user?.user_usermeta?.userWorkTitle} at </span>
                    <span className="detailed-software-alvo-medical">{review.review_user?.user_usermeta?.userBusinessName}</span>
                  </div>
                </div>
              </div>
              <div className="detailed-software-feedback-rating">
                {/* <div className="detailed-software-buttonsbutton7">
                          <img
                            className="detailed-software-filter-lines-icon"
                            alt=""
                            src={messagedotscircle}
                          />
      
                          <div className="detailed-software-text35">Reply</div>
                        </div> */}
                <div className="detailed-software-feedback-score">
                  <div className="detailed-software-stars-parent">
                    <div className="detailed-software-stars">
                      {/* <img
                          className="detailed-software-filter-lines-icon"
                          alt=""
                          src={star_icon}
                        />

                        <img
                          className="detailed-software-filter-lines-icon"
                          alt=""
                          src={star_icon}
                        />

                        <img
                          className="detailed-software-filter-lines-icon"
                          alt=""
                          src={star_icon}
                        />

                        <img
                          className="detailed-software-filter-lines-icon"
                          alt=""
                          src={star_icon}
                        />

                        <img
                          className="detailed-software-filter-lines-icon"
                          alt=""
                          src={star_icon}
                        /> */}
                      {Array.from({ length: 5 }, (_, index) => (
                        <img
                          key={index}
                          className="detailed-software-filter-lines-icon"
                          alt={`${index + 1} star`}
                          src={index < review.overallRating ? star_icon : empty_star_icon_new}
                        />
                      ))}
                    </div>
                    <div className="detailed-software-rating-value">
                      <div className="detailed-software-heading22">{getLastSeen(review.updatedAt)}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="detailed-software-client-message">
            <div className="detailed-software-message-content">
              <div className="detailed-software-paragraph39">
                {review.reviewTitle}
              </div>
              <div className="detailed-software-paragraph26">
                {review.reviewText}
              </div>
            </div>
          </div>
          {/* Reply section to show when reply is found */}
          {review.review_reply && (
            <div className="detailed-software-review-reply-main">

              <div className="detailed-software-review-reply-header">
                <div className="detailed-software-review-reply-heading2"> Reply</div>
              </div>

              <div className="detailed-software-review-reply-feedback-header">
                <img
                  className="detailed-software-review-reply-feedback-content-child"
                  alt=""
                  src={review.replyProfileImage || group_78_2x}
                />
                <div className="detailed-software-review-reply-header-info">


                  <div className="detailed-software-review-reply-heading20">{review.review_reply?.reply_user?.displayName}</div>
                  <div className="detailed-software-review-reply-heading21">
                    <span>{review.review_reply.reply_user?.user_usermeta?.userWorkTitle} at </span>
                    <span className="detailed-software-review-reply-alvo-medical">{review.review_reply?.reply_user?.user_usermeta?.userBusinessName}</span>
                  </div>
                </div>
              </div>
              <div className="detailed-software-review-reply">
                <div className="detailed-software-review-reply-feedback-content">

                  <div className="detailed-software-review-reply-content">
                    {review.review_reply?.replyText}
                  </div>

                </div>
              </div>
            </div>
          )}

          {/* If current user author and there is not reply then show add reply button */}
          {(isAuthor && !review.review_reply) &&
            <>
              <div className="detailed-software-view-replies">
                <div className="detailed-software-buttonsbutton8" onClick={() => handleReplyClick(review.id)}>
                  <img
                    className="detailed-software-filter-lines-icon"
                    alt=""
                    src={messagedotscircle}
                  />

                  <div className="detailed-software-text35" >Reply</div>
                </div>
              </div>
              {replyStates[review.id]?.showReply && (
                <div className="detailed-software-reply-section">
                  <textarea
                    className="detailed-software-reply-textarea"
                    value={replyStates[review.id]?.replyText || ''}
                    onChange={(event) => handleChange(event, review.id)}
                    placeholder="Type your reply..."
                  />

                  <div
                    className={`detailed-software-char-counter ${replyStates[review.id]?.replyText?.length >= maxLength
                      ? 'warningClass'
                      : ''
                      }`}
                  >
                    {maxLength - (replyStates[review.id]?.replyText?.length || 0)}{' '}
                    characters left
                  </div>

                  {/* <div className={`detailed-software-char-counter ${warningClass}`}>
                      {maxLength - replyText.length} characters left
                    </div> */}

                  <button className="detailed-software-reply-submit-btn" onClick={() => handleSubmit(review.id)}>
                    Submit Reply
                  </button>

                </div>
              )}
            </>
          }
        </div>
      ))
    }
    else {
      content = (
        <NoReviews />
      )
    }

  } else if (!loadingComplete && isSuccess) {
    content = <SkeletonTableListingLoaderComponent />; // Show loader until loadingComplete is true
  }

  // const finalSubmit = async (formData, setSubmitting) => {
  //   try {
  //     const response = await addReview(formData).unwrap();
  //     //If form was submitted successfully then status return with true, then reseting form
  //     console.log(response);
  //     if (response.status) {
  //       console.log(response);

  //       // setShowSuccessMessage(response.message)
  //       setShowSuccessMessage({
  //         message: "Review submitted successfully",
  //         color: "green",
  //       });
  //       await new Promise((resolve) => setTimeout(resolve, 1500));
  //       // Reseting form
  //       // dispatch(clearWantedDeviceForm());
  //       formikRef.current.resetForm();
  //       window.location.reload();
  //     }
  //     // if (!response.status) {
  //     //   setShowSuccessMessage({ message: response.message, color: "red" })
  //     // }
  //   } catch (error) {
  //     if (error.status === 422) {
  //       console.log(error);
  //       const decryptedData = decryptErrorData(error);
  //       console.log(decryptedData);
  //       setBackendValidation(decryptedData.errors);
  //       // setShowSuccessMessage({ message: decryptedData.message, color: "red" });
  //     } else {
  //       console.log(error);
  //       // const decryptedData = decryptErrorData(error.data);
  //       // console.log(decryptedData);
  //       setShowSuccessMessage({
  //         message: "Internal server error",
  //         color: "red",
  //       });
  //     }
  //     setSubmitting(false);
  //   }
  // };

  return (
    <div className ="review-section-main-container" >
      <div className="detailed-software-heading15">Reviews ({data?.totalCountReviewSoftware || 0})</div>
      <div className="detailed-software-filters-bar">
        <div className="detailed-software-content4">
          <div className="detailed-software-button-group">
            <div className="detailed-software-button-group-base">
              <div className="detailed-software-text18">Newest</div>
            </div>
            <div className="detailed-software-button-group-base1">
              <div className="detailed-software-text19">Highest</div>
            </div>
            <div className="detailed-software-button-group-base2">
              <div className="detailed-software-text20">Text</div>
            </div>
            <div className="detailed-software-button-group-base3">
              <div className="detailed-software-text20">Text</div>
            </div>
            <div className="detailed-software-button-group-base4">
              <div className="detailed-software-text20">Text</div>
            </div>
            <div className="detailed-software-button-group-base5">
              <div className="detailed-software-text20">Text</div>
            </div>
            <div className="detailed-software-button-group-base6">
              <div className="detailed-software-text20">Text</div>
            </div>
            <div className="detailed-software-button-group-base7">
              <div className="detailed-software-text20">Text</div>
            </div>
            <div className="detailed-software-button-group-base8">
              <div className="detailed-software-text26">Lowest</div>
            </div>
          </div>
          <div className="detailed-software-actions">
            {/* <div className="detailed-software-input-dropdown">
              <div className="detailed-software-input-with-label1">
                <div className="detailed-software-label">Search</div>
                <div className="detailed-software-input1">
                  <div className="detailed-software-content5">
                    <img
                      className="detailed-software-search-lg-icon"
                      alt=""
                      src={searchlg}
                    />

                    <input
                      className="detailed-software-text27"
                      placeholder="Thomson Medical"
                      type="text"
                    />

                  </div>
                </div>
              </div>
              <div className="detailed-software-hint-text">
                This is a hint text to help user.
              </div>
            </div> */}
            <SearchInputListingComponent onSearch={handleSearch} />
            <div className="detailed-software-hint-text">
              Search Business name
            </div>
            <div className="detailed-software-button" onClick={toggleDropdown}>
              <img
                className="detailed-software-filter-lines-icon"
                alt=""
                src={filterlines}
              />

              <div className="detailed-software-text-padding" ref={dropdownRef}>
                <div className="detailed-software-text28">Filter By{selectedSortOption && `: ${selectedSortOption.label}`}
                </div>
                {isDropdownVisible && <FilterBySinglePostPageComponent onOptionSelect={handleOptionSelect} />}

              </div>
              <img
                className="detailed-software-placeholder-icon4"
                alt=""
                src={placeholder1}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="detailed-software-review-container">
        <div className="detailed-software-review-content">
          <div className="detailed-software-first-review">
            <b className="detailed-software-empty-review">{data?.ratingTotalAvg || 0}</b>
            <div className="detailed-software-reviewer">
              <div className="detailed-software-heading16">/5</div>
            </div>
          </div>
          <div className="detailed-software-review-stars-background">
            {Array.from({ length: 5 }, (_, index) => (
              <img
                key={index}
                className="detailed-software-filter-lines-icon"
                alt={`${index + 1} star`}
                src={index < data?.ratingTotalAvg ? star_icon : empty_star_icon_new}
              />
            ))}
            {/* <img
              className="detailed-software-star-background-icon"
              alt=""
              src={star_background}
            />

            <img
              className="detailed-software-star-background-icon"
              alt=""
              src={star_background}
            />

            <img
              className="detailed-software-star-background-icon"
              alt=""
              src={star_background}
            />

            <img
              className="detailed-software-star-background-icon"
              alt=""
              src={star_background}
            />

            <img
              className="detailed-software-star-background-icon"
              alt=""
              src={star_background}
            /> */}
          </div>
        </div>
        <div className="detailed-software-review-stars">
          <div className="detailed-software-rectangle-parent1">
            <div className="detailed-software-rectangle-div"></div>
            <div className="detailed-software-stars">
              {Array.from({ length: 5 }, (_, index) => (
                <img
                  key={index}
                  className="detailed-software-filter-lines-icon"
                  alt={`${index + 1} star`}
                  src={index < data?.ratingAccuracyAvg ? star_icon : empty_star_icon_new}
                />
              ))}
              {/* <img
                className="detailed-software-filter-lines-icon"
                alt=""
                src={star_icon}
              />

              <img
                className="detailed-software-filter-lines-icon"
                alt=""
                src={star_icon}
              />

              <img
                className="detailed-software-filter-lines-icon"
                alt=""
                src={star_icon}
              />

              <img
                className="detailed-software-filter-lines-icon"
                alt=""
                src={star_icon}
              />

              <img
                className="detailed-software-filter-lines-icon"
                alt=""
                src={star_icon}
              /> */}
            </div>
            <div className="detailed-software-first-review-fifth-star">
              <div className="detailed-software-heading17">Accuracy ({data?.ratingAccuracyAvg || 0})</div>
            </div>
          </div>
          <div className="detailed-software-rectangle-parent1">
            <div className="detailed-software-rectangle-div"></div>
            <div className="detailed-software-stars">
              {Array.from({ length: 5 }, (_, index) => (
                <img
                  key={index}
                  className="detailed-software-filter-lines-icon"
                  alt={`${index + 1} star`}
                  src={index < data?.ratingRelevanceAvg ? star_icon : empty_star_icon_new}
                />
              ))}
              {/* <img
                className="detailed-software-filter-lines-icon"
                alt=""
                src={star_icon}
              />

              <img
                className="detailed-software-filter-lines-icon"
                alt=""
                src={star_icon}
              />

              <img
                className="detailed-software-filter-lines-icon"
                alt=""
                src={star_icon}
              />

              <img
                className="detailed-software-filter-lines-icon"
                alt=""
                src={star_icon}
              />

              <img
                className="detailed-software-filter-lines-icon"
                alt=""
                src={star_icon}
              /> */}
            </div>
            <div className="detailed-software-heading-wrapper">
              <div className="detailed-software-heading18">Relevance ({data?.ratingRelevanceAvg || 0})</div>
            </div>
          </div>
          <div className="detailed-software-rectangle-parent3">
            <div className="detailed-software-rectangle-div"></div>
            <div className="detailed-software-stars-wrapper">
              <div className="detailed-software-stars2">
                {Array.from({ length: 5 }, (_, index) => (
                  <img
                    key={index}
                    className="detailed-software-filter-lines-icon"
                    alt={`${index + 1} star`}
                    src={index < data?.ratingValueForMoneyAvg ? star_icon : empty_star_icon_new}
                  />
                ))}
                {/* <img
                  className="detailed-software-filter-lines-icon"
                  alt=""
                  src={star_icon}
                />

                <img
                  className="detailed-software-filter-lines-icon"
                  alt=""
                  src={star_icon}
                />

                <img
                  className="detailed-software-filter-lines-icon"
                  alt=""
                  src={star_icon}
                />

                <img
                  className="detailed-software-filter-lines-icon"
                  alt=""
                  src={star_icon}
                />

                <img
                  className="detailed-software-filter-lines-icon"
                  alt=""
                  src={star_icon}
                /> */}
              </div>
            </div>
            <div className="detailed-software-heading19">Value for Money ({data?.ratingValueForMoneyAvg || 0})</div>
          </div>
        </div>
      </div>

      {content}
      {/* Reply Success Message */}
      {showSuccessMessage.message && (
        <div
          style={{
            fontSize: "14px",
            color: showSuccessMessage.color,
            padding: "15px 10px",
            border: "1px solid " + showSuccessMessage.color,
            borderRadius: "10px",
            margin: "0 auto",
          }}
        >
          {showSuccessMessage.message}
        </div>
      )}
      {showLoadMore && (
        <div className="detailed-software-pagination-wrapper">
          <div className="detailed-software-pagination">
            {/* <div className="detailed-software-button-wrap">
            <div className="detailed-software-buttonsbutton9">
              <img
                className="detailed-software-placeholder-icon4"
                alt=""
                src={placeholder1}
              />

              <div className="detailed-software-text-padding">
                <div className="detailed-software-page-number">Previous</div>
              </div>
              <img
                className="detailed-software-placeholder-icon4"
                alt=""
                src={placeholder1}
              />
            </div>
          </div> */}
            {/* <div className="detailed-software-details">1 of 10</div> */}
            <button className='detailed-software-details dashboard-buttonsbutton-wrapper1-child'
              onClick={handleLoadMore}
              disabled={isFetching}
            >
              <div className="dashboard-buttonsbutton28">
                <img
                  className="dashboard-arrow-narrow-left-icon"
                  alt=""
                  src={arrowdown1}
                />
                <div className="dashboard-search-input">
                  <div className="dashboard-text146">{isFetching ? 'Loading...' : 'Load More'}</div>
                </div>
              </div>
            </button>
            {/* <div className="detailed-software-button-wrap1">
            <div className="detailed-software-buttonsbutton10">
            <img
            className="detailed-software-placeholder-icon4"
            alt=""
            src={placeholder1}
            />

              <div className="detailed-software-text-padding">
                <div className="detailed-software-text37">Next</div>
              </div>
              <img
              className="detailed-software-placeholder-icon4"
                alt=""
                src={placeholder1}
                />
                </div>
                </div> */}
          </div>
          {/* {showSuccessMessage.message && (
            <div
              style={{
                fontSize: "14px",
                color: showSuccessMessage.color,
                padding: "15px 10px",
                border: "1px solid " + showSuccessMessage.color,
                borderRadius: "10px",
                margin: "0 auto",
              }}
            >
              {showSuccessMessage.message}
            </div>
          )} */}
        </div>
      )}

    </div>
  );
};

export default ReviewSoftwareView;

{
  /* <div className="detailed-software-frame-parent3">
    
                     
                        <div className="detailed-software-heading-container">
                          <div className="detailed-software-heading33">Accuracy</div>
                          <div className="detailed-software-listing-detail-item-date-parent">
                            <img
                              className="detailed-software-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />
    
                            <img
                              className="detailed-software-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />
    
                            <img
                              className="detailed-software-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />
    
                            <img
                              className="detailed-software-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />
    
                            <img
                              className="detailed-software-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />
                          </div>
                        </div>
                        <div className="detailed-software-heading-container">
                          <div className="detailed-software-heading33">Relevance</div>
                          <div className="detailed-software-listing-detail-item-date-parent">
                            <img
                              className="detailed-software-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />
    
                            <img
                              className="detailed-software-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />
    
                            <img
                              className="detailed-software-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />
    
                            <img
                              className="detailed-software-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />
    
                            <img
                              className="detailed-software-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />
                          </div>
                        </div>
                        <div className="detailed-software-heading-container">
                          <div className="detailed-software-heading35">Value for Money</div>
                          <div className="detailed-software-listing-detail-item-date-parent">
                            <img
                              className="detailed-software-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />
    
                            <img
                              className="detailed-software-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />
    
                            <img
                              className="detailed-software-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />
    
                            <img
                              className="detailed-software-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />
    
                            <img
                              className="detailed-software-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />
                          </div>
                        </div>
                      </div> */
}
{
  /* <div className="detailed-software-input-field">
                        <div className="detailed-software-input-with-label1">
                          <div className="detailed-software-label2">Title</div>
                          <div className="detailed-software-content6">
                            <div className="detailed-software-textarea-input-field">
                              <div className="detailed-software-input-with-label3">
                                <div className="detailed-software-label3">Description</div>
                                <div className="detailed-software-input2">
                                  <div className="detailed-software-text38">70 characters</div>
                                </div>
                              </div>
                              <div className="detailed-software-hint-text1">10 characters left</div>
                            </div>
                          </div>
                        </div>
                        <div className="detailed-software-hint-text">This is a hint text to help user.</div>
                      </div>
                      <div className="detailed-software-input-field1">
                        <div className="detailed-software-input-with-label1">
                          <div className="detailed-software-label4">Review</div>
                          <div className="detailed-software-content6">
                            <div className="detailed-software-textarea-input-field1">
                              <div className="detailed-software-input-with-label3">
                                <div className="detailed-software-label3">Description</div>
                                <div className="detailed-software-input3">
                                  <div className="detailed-software-text39">1000 characters</div>
                                </div>
                              </div>
                              <div className="detailed-software-hint-text3">40 characters left</div>
                            </div>
                          </div>
                        </div>
                        <div className="detailed-software-hint-text">This is a hint text to help user.</div>
                      </div> */
}
