import React, { useState, useEffect, useRef } from 'react';
import "./AddBusiness.css"
// import { Navbar } from '../../components/Navbar/Navbar';
// import { Footer } from '../../components/Footer/Footer';
// import { Sidebar } from '../../components/Sidebar/Sidebar';
import { Navbar1 } from '../../../components/Navbar1/Navbar1'
import divider from '../../../icons/Gerator/profile_settings/divider.svg';
import homeline from '../../../icons/Gerator/account_setup_profile_subscription/homeline.svg';
import searchlg from '../../../icons/Gerator/profile_settings/searchlg.svg';
import chevronright from '../../../icons/Gerator/profile_settings/chevronright.svg';
import placeholder1 from '../../../icons/Gerator/profile_settings/placeholder1.svg';
import placeholder from '../../../icons/Gerator/profile_settings/placeholder.svg';
import placeholder3 from '../../../icons/Gerator/profile_settings/placeholder3.svg';
import placeholder2 from '../../../icons/Gerator/profile_settings/placeholder2.svg';

import { FooterAddForms } from '../../../components/Footer/FooterAddForms/FooterAddForms';
import { HeaderForms } from '../../../components/Header/HeaderForms/HeaderForms';
import { SideBarSubNormal } from '../../../components/SideBars-Sub/SideBarSubNormal/SideBarSubNormal';
import { Sidebar } from '../../../components/Sidebar/Sidebar';
import { Footer } from '../../../components/Footer/Footer';
import { useNavigate } from 'react-router-dom';
import { decryptErrorData } from '../../../helper/AESHelper';
import { useLazyGetUserQuery } from '../../../api/Login&RegisterAPI';

import { Loader } from '../../../components/Loader';
import { auth, onAuthStateChanged } from '../../Login/FirebaseClient';
import { useNavigate } from 'react-router-dom';
import { AddBusinessForm } from '../../../components/AddBusinessFormComponent/AddBusinessForm';
import { Provider } from "./AddBusinessContext";
import AddBusinessParent from '../../../components/AddBusinessFormComponent/AddBusinessParent';

export const AddBusiness = () => {
  const [triggerGetUser, { data, isSuccess, error, isLoading, isError }] = useLazyGetUserQuery();
  const [currentUser, setCurrentUser] = useState("");
  const navigate = useNavigate();

  const [showSuccessMessage, setShowSuccessMessage] = useState({
    message: "",
    color: "",
  });


  useEffect(() => {
    // const auth = getAuth();

    // Listen for authentication state change
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is authenticated
        setCurrentUser(user);
        triggerGetUser();
      } else {
        // User is not authenticated, redirect to login
        navigate('/sign-in', { state: { message: 'Please log in to continue.' } });
      }
    });

    return () => unsubscribe(); // Cleanup listener on component unmount
  }, [navigate, triggerGetUser]);



  //
  if (error) {
    console.log(error);
    if (isError) {
      const decryptedData = decryptErrorData(error.data);
      console.log(decryptedData);
      // navigate("/sign-in", { state: decryptedData.message });
      navigate("/sign-in", { state: { message: decryptedData.message } });
    }
    else {
      navigate("/sign-in", { state: { message: "Internal server error" } });
    }
    return; // Optionally handle error differently
  }


  if (isSuccess) {

    return (
      <div className="business-form-dashboard-frame-layout-setti">
        <main className="business-form-dashboard-frame-layout-setti1">
          <div className="business-form-dashboard-frame-layout-setti-child"></div>
          
              <div className="business-form-frame-child"></div>

              <Navbar1 userData={currentUser} navigate={navigate} />


          <section className="business-form-divider-wrapper">
            <img
              className="business-form-divider-icon"
              loading="lazy"
              alt=""
              src={divider}
            />
          </section>
          <section className="business-form-sidebar-navigation-parent">
            <div className="business-form-sidebar-navigation">

              <Sidebar><SideBarSubNormal userData={currentUser} /></Sidebar>

            </div>
            <div className="business-form-main-parent">
              <div className="business-form-main">
                <div className="business-form-header-section">
                  <div className="business-form-container">
                    <div className="business-form-page-header">
                      <div className="business-form-breadcrumbs">
                        <div className="business-form-tabs">
                          <div className="business-form-breadcrumb-button-base">
                            <img
                              className="business-form-search-lg-icon"
                              alt=""
                              src={homeline}
                            />
                          </div>
                          <img
                            className="business-form-chevron-right-icon"
                            alt=""
                            src={chevronright}
                          />

                          <div className="business-form-breadcrumb-button-base1">
                            <a className="business-form-text22">Dashboard</a>
                          </div>
                          <img
                            className="business-form-chevron-right-icon"
                            alt=""
                            src={chevronright}
                          />

                          <div className="business-form-breadcrumb-button-base2">
                            <div className="business-form-text23">...</div>
                          </div>
                          <img
                            className="business-form-chevron-right-icon2"
                            alt=""
                            src={chevronright}
                          />

                          <div className="business-form-breadcrumb-button-base3">
                            <div className="business-form-text24">Another link</div>
                          </div>
                          <img
                            className="business-form-chevron-right-icon2"
                            alt=""
                            src={chevronright}
                          />



                          <div className="business-form-breadcrumb-button-base1">
                            <a className="business-form-text22">Settings</a>
                          </div>
                          <img
                            className="business-form-chevron-right-icon"
                            alt=""
                            src={chevronright}
                          />

                          <div className="business-form-breadcrumb-button-base1">
                            <a href='/dashboard/settings/business' className="business-form-text22">Business</a>
                          </div>
                          <img
                            className="business-form-chevron-right-icon"
                            alt=""
                            src={chevronright}
                          />

                          <div className="business-form-breadcrumb-button-base4">
                            <a className="business-form-text25">Add Business</a>
                          </div>
                        </div>
                      </div>
                      <img
                        className="business-form-divider-icon1"
                        alt=""
                        src={divider}
                      />
                    </div>
                  </div>
                </div>
                <div className="business-form-main-child"></div>
                <div className="business-form-main-item"></div>
              </div>
              <div className="business-form-main-wrapper">
                <div className="business-form-main1">
                  {/* <div className="business-form-header-content">
                    <div className="business-form-header-section1">
                      <div className="business-form-container1">
                        <div className="business-form-page-header1">
                          <div className="business-form-breadcrumbs1">
                            <div className="business-form-tabs1">
                              <div className="business-form-breadcrumb-button-base">
                                <img
                                  className="business-form-search-lg-icon"
                                  alt=""
                                  src={homeline}
                                />
                              </div>
                              <img
                                className="business-form-chevron-right-icon"
                                alt=""
                                src={chevronright}
                              />

                              <div className="business-form-breadcrumb-button-base6">
                                <div className="business-form-text26">Add Business</div>
                              </div>
                              <img
                                className="business-form-chevron-right-icon"
                                alt=""
                                src={chevronright}
                              />

                              <div className="business-form-breadcrumb-button-base7">
                                <div className="business-form-text23">...</div>
                              </div>
                              <img
                                className="business-form-chevron-right-icon"
                                alt=""
                                src={chevronright}
                              />

                              <div className="business-form-breadcrumb-button-base3">
                                <div className="business-form-text24">Another link</div>
                              </div>
                              <img
                                className="business-form-chevron-right-icon2"
                                alt=""
                                src={chevronright}
                              />

                              <div className="business-form-breadcrumb-button-base4">
                                <div className="business-form-text29">Team</div>
                              </div>
                            </div>
                          </div>
                          <div className="business-form-content13">
                            <div className="business-form-text-and-supporting-text">
                              <h1 className="business-form-page-title">Settings</h1>
                              <div className="business-form-supporting-text1">
                                Manage your team members and their account
                                permissions here.
                              </div>
                            </div>
                            <div className="business-form-actions">
                              <div className="business-form-button">
                                <img
                                  className="business-form-placeholder-icon"
                                  alt=""
                                  src={placeholder}
                                />

                                <div className="business-form-text-padding">
                                  <div className="business-form-title">Tertiary</div>
                                </div>
                                <img
                                  className="business-form-placeholder-icon"
                                  alt=""
                                  src={placeholder}
                                />
                              </div>
                              <div className="business-form-button1">
                                <img
                                  className="business-form-placeholder-icon"
                                  alt=""
                                  src={placeholder1}
                                />

                                <div className="business-form-text-padding">
                                  <div className="business-form-title">Secondary</div>
                                </div>
                                <img
                                  className="business-form-placeholder-icon"
                                  alt=""
                                  src={placeholder1}
                                />
                              </div>
                              <div className="business-form-button2">
                                <img
                                  className="business-form-placeholder-icon"
                                  alt=""
                                  src={placeholder2}
                                />

                                <div className="business-form-text-padding">
                                  <div className="business-form-title">Secondary</div>
                                </div>
                                <img
                                  className="business-form-placeholder-icon"
                                  alt=""
                                  src={placeholder2}
                                />
                              </div>
                              <div className="business-form-button3">
                                <img
                                  className="business-form-placeholder-icon"
                                  alt=""
                                  src={placeholder3}
                                />

                                <div className="business-form-text-padding">
                                  <div className="business-form-title">Primary</div>
                                </div>
                                <img
                                  className="business-form-placeholder-icon"
                                  alt=""
                                  src={placeholder3}
                                />
                              </div>
                            </div>
                            <div className="business-form-input-dropdown">
                              <div className="business-form-input-with-label1">
                                <div className="business-form-label">Search</div>
                                <div className="business-form-input1">
                                  <div className="business-form-content3">
                                    <img
                                      className="business-form-search-lg-icon"
                                      alt=""
                                      src={searchlg}
                                    />

                                    <input
                                      className="business-form-text34"
                                      placeholder="Search"
                                      type="text"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="business-form-hint-text">
                                This is a hint text to help user.
                              </div>
                            </div>
                          </div>
                          <img
                            className="business-form-divider-icon2"
                            alt=""
                            src="/profile_settings/divider2.svg"
                          />
                        </div>
                        <div className="business-form-horizontal-tabs">
                          <div className="business-form-tabs2">
                            <div className="business-form-tab-button-base1">
                              <a className="business-form-text35">Profile</a>
                            </div>
                            <div className="business-form-tab-button-base">
                              <a className="business-form-text36">Business</a>
                            </div>
                            <div className="business-form-tab-button-base1">
                              <a className="business-form-text25">Address</a>
                            </div>
                            <div className="business-form-tab-button-base3">
                              <div className="business-form-text38">Team</div>
                              <div className="business-form-badge10">
                                <div className="business-form-badge-count">4</div>
                              </div>
                            </div>
                            <div className="business-form-tab-button-base1">
                              <div className="business-form-text39">Plan</div>
                            </div>
                            <div className="business-form-tab-button-base3">
                              <a className="business-form-text40">Billing</a>
                              <div className="business-form-badge10">
                                <div className="business-form-badge-count">2</div>
                              </div>
                            </div>
                            <div className="business-form-tab-button-base1">
                              <a className="business-form-text40">Social</a>
                            </div>
                            <div className="business-form-tab-button-base7">
                              <a className="business-form-text43">Preferences</a>
                              <div className="business-form-badge10">
                                <div className="business-form-badge-count">2</div>
                              </div>
                            </div>
                            <div className="business-form-tab-button-base1">
                              <div className="business-form-text45">Integrations</div>
                            </div>
                            <div className="business-form-tab-button-base9">
                              <div className="business-form-text46">API</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}

                  <AddBusinessParent setShowSuccessMessage={setShowSuccessMessage} />


                </div>
                {showSuccessMessage.message && (
                  <div
                    style={{
                      fontSize: "14px",
                      color: showSuccessMessage.color,
                      padding: "15px 10px",
                      border: "1px solid " + showSuccessMessage.color,
                      borderRadius: "10px",
                      margin: "0 auto",
                    }}
                  >
                    {showSuccessMessage.message}
                  </div>
                )}
              </div>
            </div>
          </section>
          <Footer />

          <img className="business-form-divider-icon5" alt="" src={divider} />
        </main>
      </div>
    )
  }
  else {
    return <Loader />
  }


}

