import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  softwareCategory:'',
  softwareValueProposition:'',
  softwareType:'',
  installationLocation:'',
  clinicalApplications:'',
  purposeUse:'',
  deviceCategory:'',
  physicalLocation:'',
}

const AddWantedSoftwareSlice = createSlice({
  name: "addWantedSoftware",
  initialState,
  reducers: {
    updateWantedSoftwareField(state, action) {
      const {field, value} = action.payload
      if (value !== undefined) { 
        state[field] = value; 
      }
    },
    clearWantedSoftwareForm() {
      return initialState;
    }
  },
});

export const { updateWantedSoftwareField,clearWantedSoftwareForm } = AddWantedSoftwareSlice.actions

export default AddWantedSoftwareSlice.reducer

