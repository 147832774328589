import React from 'react';
import "./ThreeDotsListingComponent.css";



export const ThreeDotsMessageComponent = ({ url }) => {
  
  return (
    <div className="three-dots-admin-filter-dropdown-three-dots-admin-filter-all-options-fixed">
      <section className="three-dots-admin-filter-dropdown-menu-items">
        <div className="three-dots-admin-filter-dropdown-dropdown-list-item" >
          <div className="three-dots-admin-filter-dropdown-content48">
            <div className="three-dots-admin-filter-dropdown-icon-and-text">
              <div className="three-dots-admin-filter-dropdown-text247" to={`/admin/edit-${url}`}>Message</div>
            </div>
          </div>
        </div>
      
      </section>
    </div>
  )
}

