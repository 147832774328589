import React, { useEffect, useState,useRef } from 'react';
import divider3 from '../../icons/Gerator/sales_iq/divider3.svg';
import dotsvertical from '../../icons/Gerator/sales_iq/dotsvertical.svg';
import arrowdown1 from '../../icons/Gerator/dashboard/arrowdown1.svg';

import SkeletonTableListingLoaderComponent from '../SkeletonTableListingLoaderComponent/SkeletonTableListingLoaderComponent';
import { ErrorShowComponent } from '../ErrorShowComponent/ErrorShowComponent';
import { NoTableDataDisplay } from '../NoTableDataDisplay/NoTableDataDisplay';
import { getLastSeen } from '../../helper/DeviceHelper';
import { ThreeDotsMessageComponent } from '../ThreeDotsListingComponent/ThreeDotsMessageComponent';


const SalesIqViewTable = ({ data, isLoading, isSuccess, isError, isFetching, showLoadMore, handleLoadMore, }) => {

     const [activeThreeDotId, setActiveThreeDotId] = useState(null);
      const threeDotsRef = useRef(null);
      const [isClickBlocked, setIsClickBlocked] = useState(false);
    
    
     
    
      const toggleThreeDotsDropdown = (id) => {
        if (isClickBlocked) return; // Prevent rapid toggle
    
        if (activeThreeDotId === id) {
          setActiveThreeDotId(null); // Close if the same one is clicked again
          setIsClickBlocked(true);
          setTimeout(() => setIsClickBlocked(false), 400); // Small delay to avoid reopening
        } else {
          setActiveThreeDotId(id); // Open the clicked one
        }
      };
  
      
        //Onclick listener for threedots dropdown
        
        useEffect(() => {
      
      
          const handleThreeDotClickOutside = (event) => {
      
            const isLink = event.target.closest('a');
      
            if (isLink) {
              return; // Allow navigation to happen
            }
      
      
            if (threeDotsRef.current && !threeDotsRef.current.contains(event.target)) {
              setActiveThreeDotId(null); // Close any open dropdown when clicking outside
            }
          };
      
          document.addEventListener("mousedown", handleThreeDotClickOutside);
          return () => {
            document.removeEventListener("mousedown", handleThreeDotClickOutside);
          };
        }, []);


  console.log('table data : ' + data);
  const [loadingComplete, setLoadingComplete] = useState(false);

  let content;



  //Success true then pause to show sekelton layout
  useEffect(() => {
    if (isSuccess) {

      const timer = setTimeout(() => {
        setLoadingComplete(true);
      }, 1000); // 1 second

      // Cleanup timer on component unmount
      return () => clearTimeout(timer);
    }
  }, [isSuccess]);

  if (isError) {
    content = <ErrorShowComponent message1={'Internal server error'} />
  }

  if (isLoading) {
    content = <SkeletonTableListingLoaderComponent />;
  } else if (loadingComplete && isSuccess) {

    if (data?.length > 0) {
      // dataLength = data.length;
      content = data.map((data, index) =>
      (
        <div className="sales-iq-detail-device-table-header-cell-parent" key={index}>

          <div className="sales-iq-detail-device-table-cell10">


                    {/* <div className="sales-iq-detail-device-table-cell-content">
                                  <input className="sales-iq-detail-device-table-checkbox-wrapper" type="checkbox" />
                                </div> */}

                             <div className="sales-iq-detail-device-table-avatar" style={{
                                    backgroundImage: data.profileImage
                                      && `url(${data.profileImage})`
                                  }}>
                                    {/* <div className="sales-iq-detail-device-table-contrast-border">
                                        <div className="sales-iq-detail-device-table-avatar-online-indicator"></div>
                                    </div> */}
                                </div>


            <div className="sales-iq-detail-device-dropdown1">
              <div className="sales-iq-detail-device-cell-title">{data.user_usermeta.firstName} {data.user_usermeta.lastName}</div>
            </div>
          </div>

          <div className="sales-iq-detail-device-table-cell20">
            <div className="sales-iq-detail-device-location-content">


              <div className="sales-iq-detail-device-text36">{data.user_usermeta.userBusinessName}</div>
            </div>
          </div>

          <div className="sales-iq-detail-device-table-cell30">
            <div className="sales-iq-detail-device-location-content">
              <div className="sales-iq-detail-device-text36">{data.user_usermeta.tax_country.name}</div>
            </div>
          </div>

          <div className="sales-iq-detail-device-table-cell30">
            <div className="sales-iq-detail-device-location-content">


              <div className="sales-iq-detail-device-text36">{getLastSeen(data.viewUpdatedAt)}</div>
            </div>
          </div>



          <div className="sales-iq-detail-device-table-cell40"  style={{cursor:"pointer"}} onClick={() => toggleThreeDotsDropdown(index)} ref={threeDotsRef}>
            <div className="sales-iq-detail-device-location-content">
              <div className="sales-iq-detail-device-text36">
                <img
                  className="sales-iq-detail-device-dots-vertical-icon"
                  alt=""
                  src={dotsvertical}
                />
              </div>
                                          {activeThreeDotId === index && <ThreeDotsMessageComponent/>}
              
            </div>

          </div>

        </div>
      ))
    }
    else {
      content = (

     <NoTableDataDisplay/>

      )
    }

  } else if (!loadingComplete && isSuccess) {
    content = <SkeletonTableListingLoaderComponent />; // Show loader until loadingComplete is true
  }


  return (
    <div className="sales-iq-detail-device-display-form-content-wrapper">
      <div className="sales-iq-detail-device-display-form-content">
        <div className="sales-iq-detail-device-display-toggle-header-parent">
          <div className="sales-iq-detail-device-display-toggle-header">
            <div className="sales-iq-detail-device-display-toggle-title">Viewed</div>
            {/* <PlanExpiryStatus expiryDate={currentPlanExpiry} planName={currentPlanName} /> */}
          </div>

        </div>
        <div className="sales-iq-detail-device-display-divider-container">
          <img
            className="sales-iq-detail-device-display-divider-icon3"
            alt=""
            src={divider3}
          />
        </div>

        <form className="sales-iq-detail-device-table">

          <div className="sales-iq-detail-device-table-content">

            <div className="sales-iq-detail-device-table-row">

              {/* Invoice */}



              <div className="sales-iq-detail-device-table-header-cell1">
                {/* <input className="sales-iq-detail-device-checkbox-wrapper" type="checkbox" /> */}
                <div className="sales-iq-detail-device-table-header">
                  <a className="sales-iq-detail-device-column-title">Name</a>
                  {/* <img
                                className="sales-iq-detail-device-arrow-down-icon1"
                                alt=""
                                src={arrowdown}
                                /> */}
                </div>
              </div>


              {/* Business Name */}

              <div className="sales-iq-detail-device-table-header-cell2">
                <div className="sales-iq-detail-device-table-header">
                  <a className="sales-iq-detail-device-column-title">Business Name</a>

                </div>
              </div>


              {/* Location  */}

              <div className="sales-iq-detail-device-table-header-cell3">
                <div className="sales-iq-detail-device-table-header">
                  <a className="sales-iq-detail-device-column-title">Location</a>


                </div>
              </div>

              {/* Last seen  */}

              <div className="sales-iq-detail-device-table-header-cell3">
                <div className="sales-iq-detail-device-table-header">
                  <a className="sales-iq-detail-device-column-title">Visited</a>


                </div>
              </div>

              {/* Price  */}

              <div className="sales-iq-detail-device-table-header-cell4">
                <div className="sales-iq-detail-device-table-header">
                  <a className="sales-iq-detail-device-column-title">Action</a>


                </div>
              </div>

              {/* Action  */}

              {/* <div className="sales-iq-detail-device-table-header-cell4">
                            <div className="sales-iq-detail-device-table-header2">
                                <div className="sales-iq-detail-device-action-column-title">Action</div>
                                <img
                                className="sales-iq-detail-device-arrow-down-icon2"
                                alt=""
                                src={arrowdown}
                                />
                            </div>
                            </div> */}

            </div>



          </div>

          <div className='sales-iq-detail-device-table-data-custom-main-container' style={{ width: "100%" }}>
            {content}
          </div>

          {/* Load more button  */}
          <div className="sales-iq-frame-wrapper44">
            <div className="sales-iq-frame-parent72">
              <div className="sales-iq-buttonsbutton-wrapper1">
                {showLoadMore && (
                  <button className='sales-iq-buttonsbutton-wrapper1-child'
                    onClick={handleLoadMore}
                    disabled={isFetching}
                  >
                    <div className="sales-iq-buttonsbutton28">
                      <img
                        className="sales-iq-arrow-narrow-left-icon"
                        alt=""
                        src={arrowdown1}
                      />
                      <div className="sales-iq-search-input">
                        <div className="sales-iq-text146">{isFetching ? 'Loading...' : 'Load More'}</div>
                      </div>
                    </div>
                  </button>
                )}
              </div>
            </div>
          </div>

          {/* Load More  */}
          {showLoadMore || undefined && (
            <div className="sales-iq-detail-device-frame-wrapper44">
              <div className="sales-iq-detail-device-frame-parent72">
                <div className="sales-iq-detail-device-buttonsbutton-wrapper1">

                  <button className='sales-iq-detail-device-buttonsbutton-wrapper1-child'
                    onClick={handleLoadMore}
                    disabled={isFetching}
                  >
                    <div className="sales-iq-detail-device-buttonsbutton28">
                      <img
                        className="sales-iq-detail-device-arrow-narrow-left-icon"
                        alt=""
                        src={arrowdown1}
                      />
                      <div className="sales-iq-detail-device-search-input">
                        <div className="sales-iq-detail-device-text146">{isFetching ? 'Loading...' : 'Load More'}</div>
                      </div>
                    </div>
                  </button>

                </div>
              </div>
            </div>
          )
          }

        </form>


      </div>


    </div >
  )
}

export default SalesIqViewTable