import React from "react";
import "./AccountSetupButton.css";
import { Link } from "react-router-dom";
import placeholder from '../../icons/Gerator/account_setup/placeholder.svg';
export const AccountSetupButton = ({ text, next }) => {

  // if(next === "/")
  // {

  // }


  return (
    <Link to={next} style={{ textDecoration: 'none' }} >
      <button className="account-setup-button-buttonsbutton">
        <img
          className="account-setup-button-placeholder-icon"
          alt=""
          src={placeholder}
        />

        <div className="account-setup-button-text-padding">
          <div className="account-setup-button-action-label">{text}</div>
        </div>
        <img
          className="account-setup-button-placeholder-icon"
          alt=""
          src={placeholder}
        />
      </button>
    </Link>
  )
}