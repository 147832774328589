import { AddSoftware } from "../screens/Software/AddSoftware/AddSoftware";
import { ArchiveSoftware } from "../screens/Software/ArchiveSoftware/ArchiveSoftware";
import { DetailedSoftware } from "../screens/Software/DetailedSoftware/DetailedSoftware";
import { EditSoftware } from "../screens/Software/EditSoftware/EditSoftware";

export const softwareRoutes = [
  {
    path: "/add-software",
    element: <AddSoftware />,
  },
  {
    path: "/edit-software/:id/*",
    element: <EditSoftware />,
  },
  {
    path: "/softwares",
    element: <ArchiveSoftware />,
  },
  {
    path: "/software/:id/*",
    element: <DetailedSoftware />,
  }
];
