// import React from "react";
// import "../ChatPageComponent.css";

// import avatar2 from "../../../icons/Gerator/chat_page/avatar2.svg"; // Replace with correct paths
// import dotsvertical from "../../../icons/Gerator/chat_page/dotsvertical.svg";
// import searchlg from "../../../icons/Gerator/chat_page/searchlg.svg";

// const SidePanel = ({ LatestSenderProfiles = [], onSelectChat }) => {
  
//   // Handle chat selection and notify the parent component
//   const handleSelectChat = (message) => {
//     onSelectChat(message); // Notify the parent component
//   };

//   return (
//     <div className="chatB-side-panel">
//       {/* Header Section */}
//       <div className="chatB-card-header">
//         <div className="chatB-content5">
//           <div className="chatB-text-and-supporting-text">
//             <div className="chatB-text-and-badge">
//               <div className="chatB-chat-name-label">Chats</div>
//               <div className="chatB-badge10">
//                 <div className="chatB-text17">{LatestSenderProfiles.length}</div>
//               </div>
//             </div>
//             <div className="chatB-supporting-text2">
//               Select a conversation to view messages.
//             </div>
//           </div>
//         </div>
//         <div className="chatB-dropdown5">
//           <img className="chatB-dots-vertical-icon" alt="" src={dotsvertical} />
//         </div>
//         <hr className="chatB-divider-icon4" />
//       </div>

//       {/* Search Section */}
//       <div className="chatB-search-wrap">
//         <div className="chatB-input-dropdown">
//           <div className="chatB-input-with-label1">
//             <div className="chatB-label1">Search</div>
//             <div className="chatB-input1">
//               <div className="chatB-tabs">
//                 <img className="chatB-search-lg-icon" alt="" src={searchlg} />
//                 <input
//                   className="chatB-search-placeholder1"
//                   placeholder="Search chats"
//                   type="text"
//                 />
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>

//       {/* Chat List Section */}
//       <div className="chatB-message-list">
//         {LatestSenderProfiles.length > 0 ? (
//           LatestSenderProfiles.map((profile, index) => (
//             <div
//             key={profile.messageId}
//             onClick={() => handleSelectChat(profile)} // Call onSelectChat with the selected message
//             className="chat-item"
//           >
//               <div className="chatB-avatar-and-time">
//                 <div className="chatB-avatar-label-group">
//                   <img
//                     className="chatB-avatar2"
//                     src={profile?.senderDetails?.profileImageLink || avatar2}
//                     alt="Avatar"
//                   />
//                   <div className="chatB-text-and-supporting-text1">
//                     <div className="chatB-message-sender-name">
//                       {profile?.senderDetails?.display_name || "Unknown User"}
//                     </div>
//                     <div className="chatB-supporting-text3">
//                       {profile?.timestamp
//                         ? new Date(profile.timestamp).toLocaleTimeString()
//                         : "Unknown Time"}
//                     </div>
//                   </div>
//                 </div>
//               </div>
//               <div className="chatB-message-preview-wrap">
//                 <div className="chatB-message-preview">
//                   {profile?.content || "No message"}
//                 </div>
//               </div>
//             </div>
//           ))
//         ) : (
//           <div className="chatB-no-messages">No messages to display</div>
//         )}
//       </div>
//     </div>
//   );
// };

// export { SidePanel };



import React, { useState } from "react";
import "../ChatPageComponent.css";

import avatar2 from "../../../icons/Gerator/chat_page/avatar2.svg"; // Replace with correct paths
import dotsvertical from "../../../icons/Gerator/chat_page/dotsvertical.svg";
import searchlg from "../../../icons/Gerator/chat_page/searchlg.svg";

const SidePanel = ({ LatestSenderProfiles = [], onSelectChat }) => {
  // State for the search query
  const [searchQuery, setSearchQuery] = useState("");

  // Filter the chat profiles based on the search query
  const filteredProfiles = LatestSenderProfiles.filter((profile) =>
    (profile?.senderDetails?.display_name || "Unknown User")
      .toLowerCase()
      .includes(searchQuery.toLowerCase())
  );

  // Handle chat selection and notify the parent component
  const handleSelectChat = (message) => {
    onSelectChat(message); // Notify the parent component
  };

  return (
    <div className="chatB-side-panel">
      {/* Header Section */}
      <div className="chatB-card-header">
        <div className="chatB-content5">
          <div className="chatB-text-and-supporting-text">
            <div className="chatB-text-and-badge">
              <div className="chatB-chat-name-label">Chats</div>
              <div className="chatB-badge10">
                <div className="chatB-text17">{LatestSenderProfiles.length}</div>
              </div>
            </div>
            <div className="chatB-supporting-text2">
              Select a conversation to view messages.
            </div>
          </div>
        </div>
        <div className="chatB-dropdown5">
          <img className="chatB-dots-vertical-icon" alt="" src={dotsvertical} />
        </div>
        <hr className="chatB-divider-icon4" />
      </div>

      {/* Search Section */}
      <div className="chatB-search-wrap">
        <div className="chatB-input-dropdown">
          <div className="chatB-input-with-label1">
            <div className="chatB-label1">Search</div>
            <div className="chatB-input1">
              <div className="chatB-tabs">
                <img className="chatB-search-lg-icon" alt="" src={searchlg} />
                <input
                  className="chatB-search-placeholder1"
                  placeholder="Search chats"
                  type="text"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Chat List Section */}
      <div className="chatB-message-list">
        {filteredProfiles.length > 0 ? (
          filteredProfiles.map((profile) => (
            <div
              key={profile.messageId}
              onClick={() => handleSelectChat(profile)} // Call onSelectChat with the selected message
              className="chat-item"
            >
              <div className="chatB-avatar-and-time">
                <div className="chatB-avatar-label-group">
                  <img
                    className="chatB-avatar2"
                    src={profile?.senderDetails?.profileImageLink || avatar2}
                    alt="Avatar"
                  />
                  <div className="chatB-text-and-supporting-text1">
                    <div className="chatB-message-sender-name">
                      {profile?.senderDetails?.display_name || "Unknown User"}
                    </div>
                    <div className="chatB-supporting-text3">
                      {profile?.timestamp
                        ? new Date(profile.timestamp).toLocaleTimeString()
                        : "Unknown Time"}
                    </div>
                  </div>
                </div>
              </div>
              <div className="chatB-message-preview-wrap">
                <div className="chatB-message-preview">
                  {profile?.content || "No message"}
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="chatB-no-messages">No chats found</div>
        )}
      </div>
    </div>
  );
};

export { SidePanel };

