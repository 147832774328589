import React from 'react';
import "./Dropdown.css";
import { Link } from 'react-router-dom';

export const ResetPasswordDropdown = () => {

    

  return (
    <div className="reset-password-dropdown-user-menu-all-options-fixed">
    
    <section className="reset-password-dropdown-menu-items">
      <div className="reset-password-dropdown-dropdown-list-item">
        <div className="reset-password-dropdown-content48">
          <div className="reset-password-dropdown-icon-and-text">
            {/* <img className="reset-password-dropdown-user-01-icon" alt="" src="/dashboard/user01.svg" /> */}
            
            <Link to={"/sign-in"} style={{ textDecoration: 'none' , color:"black"  }}>
            <div className="reset-password-dropdown-text247">Log in</div>
           </Link>
          </div>
        </div>
      </div>
      <div className="reset-password-dropdown-dropdown-list-item">
        <div className="reset-password-dropdown-content48">
          <div className="reset-password-dropdown-icon-and-text">
            {/* <img className="reset-password-dropdown-user-01-icon" alt="" src="/dashboard/settings011.svg" /> */}
           
            <Link to={"/sign-up"} style={{ textDecoration: 'none' , color:"black" }}>
            <div className="reset-password-dropdown-text247">Sign up</div>
            </Link>
          </div>
        </div>
      </div>
    
    </section>
  </div>
  )
}

