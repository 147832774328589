import React from 'react';
import "./NothingFoundMessage.css";
import featured_icon from '../../icons/Gerator/no_address/featured-icon.svg';
import plus from '../../icons/Gerator/no_address/plus.svg';
import placeholder1 from '../../icons/Gerator/no_address/placeholder1.svg';
import { Link } from 'react-router-dom';


export const NothingFoundMessage = ({ text, text1, text2 }) => {
  return (
    <div className="nothing-found-message-search-results">

      <section className="nothing-found-message-empty-state2">
        <div className="nothing-found-message-empty-state3">
          <div className="nothing-found-message-content15">
            <img
              className="nothing-found-message-featured-icon"
              loading="lazy"
              alt=""
              src={featured_icon}
            />

            <div className="nothing-found-message-text-and-supporting-text4">
              <div className="nothing-found-message-supporting-text16">
                {text}
              </div>
              <br />
              <div className="nothing-found-message-empty-title">{text1}</div>
              <br />
              <div className="nothing-found-message-supporting-text16">
                {text2}
              </div>
            </div>
          </div>
          <div className="nothing-found-message-actions6">

            <Link to={'/dashboard/settings/address/add-address'} className="nothing-found-message-buttonsbutton5">
              <img className="nothing-found-message-search-lg-icon1" alt="" src={plus} />

              <div className="nothing-found-message-text-padding15">
                <div className="nothing-found-message-text74">Create Address</div>
              </div>
              <img
                className="nothing-found-message-placeholder-icon27"
                alt=""
                src={placeholder1}
              />
            </Link>
          </div>
        </div>
      </section>
    </div>
  )
}

