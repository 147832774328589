import React, { useEffect, useRef, useState } from 'react';
import "./DetailedService.css"
import { Navbar1 } from '../../../components/Navbar1/Navbar1';
import FooterDetailPage from '../../../components/Footer/FooterDetailPage/FooterDetailPage';
import SlideShowDetailPageComponent from '../../../components/SlideShowDetailPageComponent/SlideShowDetailPageComponent';
import target_04 from "../../../icons/Gerator/dashboard/target_04.png"

import divider from '../../../icons/Gerator/detailed_listing/divider.svg';
import divider2 from '../../../icons/Gerator/detailed_listing/divider2.svg';
import divider3 from '../../../icons/Gerator/detailed_listing/divider3.svg';
import divider4 from '../../../icons/Gerator/detailed_listing/divider4.svg';

import chevronright from '../../../icons/Gerator/detailed_listing/chevronright.svg';
import users01 from '../../../icons/Gerator/detailed_listing/users01.svg';
import add_contact from "../../../icons/Gerator/detailed_listing/add_contact.svg"

import rectangle_9_2x from '../../../icons/Gerator/detailed_listing/rectangle-9@2x.png';
import rectangle_5_2x from '../../../icons/Gerator/detailed_listing/rectangle-5@2x.png';
import rectangle_43_2x from '../../../icons/Gerator/detailed_listing/rectangle-43@2x.png';
import rectangle_95_2x from '../../../icons/Gerator/detailed_listing/rectangle-95@2x.png';
import rectangle_431_2x from '../../../icons/Gerator/detailed_listing/rectangle-431@2x.png';
import rectangle_196_2x from '../../../icons/Gerator/detailed_listing/rectangle-196@2x.png';

import arrowleft from '../../../icons/Gerator/detailed_listing/arrowleft.svg';
import arrowright from '../../../icons/Gerator/detailed_listing/arrowright.svg';

import breadcrumb_controls_2x from '../../../icons/Gerator/detailed_listing/breadcrumb-controls@2x.png';
import breadcrumb_controls1_2x from '../../../icons/Gerator/detailed_listing/breadcrumb-controls1@2x.png';
import breadcrumb_controls2_2x from '../../../icons/Gerator/detailed_listing/breadcrumb-controls2@2x.png';

import placeholder from '../../../icons/Gerator/detailed_listing/placeholder.svg';
import placeholder1 from '../../../icons/Gerator/detailed_listing/placeholder1.svg';
import placeholder2 from '../../../icons/Gerator/detailed_listing/placeholder2.svg';

import maximize01 from '../../../icons/Gerator/detailed_listing/maximize01.svg';
import markerpin02 from '../../../icons/Gerator/detailed_listing/markerpin02.svg';

import calendar from '../../../icons/Gerator/detailed_listing/calendar.svg';
import calendar1 from '../../../icons/Gerator/detailed_listing/calendar1.svg';

import facebook_1_1_2x from '../../../icons/Gerator/detailed_listing/facebook-1-1@2x.png';

import instagram_1_1_2x from '../../../icons/Gerator/detailed_listing/instagram-1-1@2x.png';
import whatsapp_1_2x from '../../../icons/Gerator/detailed_listing/whatsapp-1@2x.png';
import twitter_1_2x from '../../../icons/Gerator/detailed_listing/twitter-1@2x.png';
import linkedinlogo_1_2x from '../../../icons/Gerator/detailed_listing/linkedinlogo-1@2x.png';


import radar_1_1_2x from '../../../icons/Gerator/detailed_listing/radar-1-1@2x.png';
import deal_2_1_2x from '../../../icons/Gerator/detailed_listing/deal-2-1@2x.png';
import magnifier_1_2x from '../../../icons/Gerator/detailed_listing/magnifier-1@2x.png';
import share_1_2x from '../../../icons/Gerator/detailed_listing/share-1@2x.png';

import group_1010 from '../../../icons/Gerator/detailed_listing/group-1010.svg';
import group_78_2x from '../../../icons/Gerator/detailed_listing/group-78@2x.png';
import group_58_2x from '../../../icons/Gerator/detailed_listing/group-58@2x.png';
import group_781_2x from '../../../icons/Gerator/detailed_listing/group-781@2x.png';

import dotsvertical from '../../../icons/Gerator/detailed_listing/dotsvertical.svg';
import divider1 from '../../../icons/Gerator/detailed_listing/divider1.svg';
import group_351 from '../../../icons/Gerator/detailed_listing/group-351.svg';
import page from '../../../icons/Gerator/detailed_listing/page.svg';
import progress_fill from '../../../icons/Gerator/detailed_listing/progress-fill.svg';
import searchlg from '../../../icons/Gerator/detailed_listing/searchlg.svg';
import filterlines from '../../../icons/Gerator/detailed_listing/filterlines.svg';
import star_background from '../../../icons/Gerator/detailed_listing/star-background.svg';
import star_icon from '../../../icons/Gerator/detailed_listing/star-icon.svg';
import dot1 from '../../../icons/Gerator/detailed_listing/-dot1.svg';
import bookmarkcheck from '../../../icons/Gerator/detailed_listing/bookmarkcheck.svg';
import usercheck02 from '../../../icons/Gerator/detailed_listing/usercheck02.svg';
import f8595f4b711e503bc72fe396e5043e0c2_7_2x from '../../../icons/Gerator/detailed_listing/8595f4b711e503bc72fe396e5043e0c2-7@2x.png';
import messagedotscircle from '../../../icons/Gerator/detailed_listing/messagedotscircle.svg';
import messagechatcircle from '../../../icons/Gerator/detailed_listing/messagechatcircle.svg';

import markerpin021 from '../../../icons/Gerator/detailed_listing/markerpin021.svg';
import icon1 from '../../../icons/Gerator/detailed_listing/icon1.svg';
import login04 from '../../../icons/Gerator/detailed_listing/login04.svg';
import { Loader } from "../../../components/Loader";
// import { useDispatch } from "react-redux";
import { useLazyFindGetServiceQuery, useLazyGetServicesQuery } from "../../../api/ServiceAPI";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getCustomizedDate } from "../../../helper/ServiceHelper";
import { decryptErrorData } from '../../../helper/AESHelper';
import { useLazyGetProfileImageLinkQuery, useLazyGetUserQuery } from '../../../api/Login&RegisterAPI';
import { firestore, doc, getDoc, auth, onAuthStateChanged } from '../../Login/FirebaseClient';
import MapLocationViewComponent from '../../../components/MapLocationViewComponent/MapLocationViewComponent';
import VideoSection from '../../../components/VideoSection/VideoSection';
import { useAddContactMutation, useDeleteContactMutation } from '../../../api/ContactAPI';
import { useAddRadarServiceMutation, useDeleteRadarServiceMutation } from '../../../api/Radar/RadarServiceAPI';
import { Helmet } from 'react-helmet-async';
import { formatNumber } from '../../../helper/DeviceHelper';
import { useAddViewServiceMutation } from '../../../api/View/ViewServiceAPI';


import follow from "../../../icons/Gerator/detailed_listing/follow.svg";
import ShareThisMetaData from '../../../components/ShareThisMetaData/ShareThisMetaData';
import { InlineShareButtons } from 'sharethis-reactjs';
import { InlineFollowButtons } from 'sharethis-reactjs';
import { RatingStarComponent } from '../../../components/review/RatingStarComponent/RatingStarComponent';
import { ReviewDescriptionComponent } from '../../../components/review/ReviewDescriptionComponent/ReviewDescriptionComponent';
import ReviewServiceView from '../../../components/review/ReviewViewComponent/ReviewServiceView';
import AddReviewServiceForm from '../../../components/review/ReviewFormComponent/AddReviewServiceForm';

import { ThreeDotsDetailPageComponent } from '../../../components/ThreeDotsListingComponent/ThreeDotsDetailPageComponent';
import { ThreeDotsDetailPageUserComponent } from '../../../components/ThreeDotsListingComponent/ThreeDotsDetailPageUserComponent';

export const DetailedService = () => {



     const [isThreeDotOpen, setIsThreeDotOpen] = useState(false);
   const threeDotsRef = useRef(null);
   const [isClickBlocked, setIsClickBlocked] = useState(false);

   // Toggle function for the three dots dropdown
   const toggleThreeDotsDropdown = () => {
     if (isClickBlocked) return; // Prevent rapid toggle

     setIsThreeDotOpen(prev => !prev); // Toggle the dropdown
     setIsClickBlocked(true);
     setTimeout(() => setIsClickBlocked(false), 400); // Small delay to avoid reopening
   };

   // Handle clicks outside the three dots dropdown
   useEffect(() => {
     const handleThreeDotClickOutside = (event) => {
       const isLink = event.target.closest('a');
       if (isLink) return; // Allow navigation to happen

       if (threeDotsRef.current && !threeDotsRef.current.contains(event.target)) {
         setIsThreeDotOpen(false); // Close the dropdown when clicking outside
       }
     };

     document.addEventListener("mousedown", handleThreeDotClickOutside);
     return () => {
       document.removeEventListener("mousedown", handleThreeDotClickOutside);
     };
   }, []);


   // For second dropdown (for user or other context)
   const [isThreeDotUserOpen, setIsThreeDotUserOpen] = useState(false);
   const threeDotsRefUser = useRef(null);
   const [isClickBlockedUser, setIsClickBlockedUser] = useState(false);

   // Toggle function for the second three dots dropdown
   const toggleThreeDotsDropdownUser = () => {
     if (isClickBlockedUser) return; // Prevent rapid toggle

     setIsThreeDotUserOpen(prev => !prev); // Toggle the dropdown
     setIsClickBlockedUser(true);
     setTimeout(() => setIsClickBlockedUser(false), 400); // Small delay to avoid reopening
   };

   // Handle clicks outside the second three dots dropdown
   useEffect(() => {
     const handleThreeDotClickOutsideUser = (event) => {
       const isLink = event.target.closest('a');
       if (isLink) return; // Allow navigation to happen

       if (threeDotsRefUser.current && !threeDotsRefUser.current.contains(event.target)) {
         setIsThreeDotUserOpen(false); // Close the dropdown when clicking outside
       }
     };

     document.addEventListener("mousedown", handleThreeDotClickOutsideUser);
     return () => {
       document.removeEventListener("mousedown", handleThreeDotClickOutsideUser);
     };
   }, []);


  const formikRef = useRef(null);


  const [showReply, setShowReply] = useState(false);
  const [replyText, setReplyText] = useState('');

  const maxLength = 500; // Maximum character count

  const handleReplyClick = () => {
    setShowReply(!showReply); // Toggle text area visibility
  };

  const handleChange = (event) => {
    if (event.target.value.length <= maxLength) {
      setReplyText(event.target.value); // Update reply text
    }
  };

  const handleSubmit = () => {
    // Handle reply submission here
    console.log('Reply submitted:', replyText);
    setShowReply(false); // Hide the text area after submission
  };

  const remainingChars = maxLength - replyText.length;
  const warningClass = remainingChars <= 50 ? 'warning' : '';

  const [activeSection, setActiveSection] = useState(null); // "share", "follow", or null

  const handleShareButtonClick = () => {
    setActiveSection((prevState) => (prevState === 'share' ? null : 'share')); // Toggle or close
  };

  const handleFollowButtonClick = () => {
    setActiveSection((prevState) => (prevState === 'follow' ? null : 'follow')); // Toggle or close
  };

  // const [showShareButtons, setShowShareButtons] = useState(false);
  // const [showfollowButtons, setShowfollowButtons] = useState(false);

  // const handleShareButtonClick = () => {
  //   setShowShareButtons((prevState) => !prevState); // Toggle visibility
  // };

  // const handleFollowButtonClick = () => {
  //   setShowfollowButtons((prevState) => !prevState); // Toggle visibility
  // };


  const { id } = useParams();
  let content;
  const [currentUser, setCurrentUser] = useState("");
  const navigate = useNavigate();
  const [profileImageLink, setProfileImageLink] = useState("");
  const [currentUsermeta, setCurrentUsermeta] = useState("");

  const [triggerNewProfileImageLink] = useLazyGetProfileImageLinkQuery();


  // const [triggerGetUser, { data, isSuccess, error, isLoading, isError }] = useLazyGetUserQuery();
  const [triggerGetService, { data: serviceData,
    isLoading: isServiceLoading,
    isSuccess: isServiceSuccess,
    isError: isServiceError,
    error: serviceError }] = useLazyFindGetServiceQuery(id);


  const [addViewService, { data: serviceViewData,
    isLoading: isViewServiceLoading,
    isSuccess: isViewServiceSuccess,
    isError: isViewServiceError,
    error: serviceViewError }] = useAddViewServiceMutation();


  const [addToContact, {
    isLoading: isContactLoading,
    isSuccess: isContactSuccess,
    isError: isContactError,
    error: contactError }] = useAddContactMutation();

  const [deleteFromContact] = useDeleteContactMutation();

  // Radar query delete
  const [deleteFromRadar] = useDeleteRadarServiceMutation();

  // Radar query delete
  const [addToRadar] = useAddRadarServiceMutation();
  // const [showSuccessMessage, setShowSuccessMessage] = useState({
  //   message: "",
  //   color: "",
  // });


  useEffect(() => {
    // const auth = getAuth();

    // Listen for authentication state change
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {

        if (user) {
          // Get user to check wizardStep
          const userDocRef = doc(firestore, "users", user.uid);
          const userDocSnapshot = await getDoc(userDocRef);
          setCurrentUser(user);

          if (userDocSnapshot.exists()) {
            const usermeta = userDocSnapshot.data(); // Default to 1 if not set

            if (usermeta.userStatus == 2) {
              navigate('/account-disabled');
              return;
            }

            if (usermeta.userType == 5) {
              navigate('/access-denied');
              return;
            }

            if (usermeta.wizardStep !== 0) {
              navigate('/account-setup-check-email');
              return;
            }

            // await triggerGetCurrentPlan().unwrap();
            setCurrentUsermeta(usermeta);
            triggerGetService(id);
            // User is authenticated
          } else {
            content = <ErrorShowComponent message1={"Internal server error"} />
          }
        }
      } else {
        // User is not authenticated, redirect to login
        navigate('/sign-in', { state: { message: 'Please log in to continue.' } });
      }
    });


    return () => unsubscribe(); // Cleanup listener on component unmount
  }, [navigate]);


  //Profile image load useEffect
  useEffect(() => {
    const refreshProfileImage = async () => {
      if (currentUsermeta && currentUsermeta.profileImage.expiresAt < Date.now() / 1000) {
        // Pre-signed URL has expired, trigger a new request to the backend
        const newImageData = await triggerNewProfileImageLink().unwrap();
        // Update the profile image link directly
        setProfileImageLink(newImageData.url);
      } else if (currentUsermeta && currentUsermeta.profileImage.url) {
        // Use the existing pre-signed URL to display the profile image
        setProfileImageLink(currentUsermeta.profileImage.url);
      }
    };

    refreshProfileImage();
  }, [currentUsermeta]);

  // // Trigger the get wanted service query only after user data is successfully fetched
  useEffect(() => {
    if (isServiceSuccess && serviceData) {
      addViewService(id);
      console.log('added view data');
    }
  }, [isServiceSuccess, serviceData]);

  useEffect(() => {
    if (serviceViewData) {
      console.log(serviceViewData);
    }
  }, [serviceViewData]);

  const handleRemoveRadar = async (serviceId) => {
    await deleteFromRadar(serviceId);
    window.location.reload();
  };

  const handleAddRadar = async (serviceId) => {
    await addToRadar(serviceId);
    window.location.reload();
  };

  const handleRemoveContact = async (serviceId) => {
    const result = await deleteFromContact(serviceId);
    if (result.data) {
      // If successful, reload the page
      window.location.reload();
    } else if (result.error) {
      console.log(result.error);
    }
  };

  const handleAddContact = async (serviceId) => {
    const result = await addToContact(serviceId);
    if (result.data) {
      // If successful, reload the page
      window.location.reload();
    } else if (result.error) {
      console.log(result.error);
    }
  };

  // To show error when adding contact
  useEffect(() => {
    // const { status } = contactError;
    // console.log(contactError);

    if (contactError?.status === 405)
      console.log(contactError.data.message);
    else
      console.log("Internal server error");
  }, [isContactError])

  // CHAT

  const handlePrivateMessage = () => {
    const serviceId = serviceData.data.id; // Use the appropriate ID for the chat
    handleAddRadar(serviceId);
    navigate(`/chat/offered/Service/${serviceId}`); // Replace `/chat/:id` with the correct route for your chat page
  };



  // Trigger the get wanted device query only after user data is successfully fetched

  if (currentUser && isServiceSuccess && profileImageLink) {
    return (
      <>
        <Helmet>
          <title>Service</title>
        </Helmet>
        <ShareThisMetaData
          title="Service - Detailed Page"
          description="Explore detailed services and listings tailored to your needs."
          image={profileImageLink}
          url={"https://gerator.io/"}
        />
        <div className="detailed-service-listings-detailed-page-devic">
          <div className="detailed-service-rectangle-parent">
            <div className="detailed-service-frame-child"></div>
            <Navbar1 userData={currentUser} navigate={navigate} profileImageLink={profileImageLink} />

            <img className="detailed-service-divider-icon" alt="" src={divider} />

            {/* <div className="detailed-service-breadcrumb-button-base">
              <div className="detailed-service-text6">...</div>
            </div>
            <img
              className="detailed-service-chevron-right-icon"
              alt=""
              src={chevronright}
            />

            <div className="detailed-service-breadcrumb-button-base1">
              <div className="detailed-service-text7">Another link</div>
            </div>
            <img
              className="detailed-service-chevron-right-icon1"
              alt=""
              src={chevronright}
            /> */}

            <div className="detailed-service-frame-parent">
              <div className="detailed-service-breadcrumb-container-parent">
                <div className="detailed-service-breadcrumb-container">

                  <img
                    className="detailed-service-breadcrumb-controls-icon"
                    alt=""
                    src={serviceData.data.bannerImage}
                  />

                </div>
                <div className="detailed-service-breadcrumb">
                  <div className="detailed-service-breadcrumb-items">
                    <div className="detailed-service-breadcrumb-button-base2">
                      <div className="detailed-service-text9">Dashboard</div>
                    </div>
                    <div className="detailed-service-chevron-container">
                      <img
                        className="detailed-service-chevron-right-icon2"
                        alt=""
                        src={chevronright}
                      />
                    </div>
                    <div className="detailed-service-breadcrumb-button-base3">
                      <div className="detailed-service-text10">Service</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="detailed-service-listing-content-wrapper">
                <div className="detailed-service-listing-content">
                  <div className="detailed-service-description-block-parent">
                    <div className="detailed-service-description-block">
                      <div className="detailed-service-description-heading">
                        {serviceData.data.title}
                      </div>
                      <div className="detailed-service-listing-details">
                        <div className="detailed-service-detail-container">
                          <div className="detailed-service-details-content">
                            <div className="detailed-service-details-row">
                              <div className="detailed-service-detail-items">
                                <img
                                  className="detailed-service-marker-pin-02-icon"
                                  alt=""
                                  src={markerpin02}
                                />
                                {serviceData.data.service_address.tax_address_type
                                  .slug === "address" ?
                                  <div className="detailed-service-supporting-text">{serviceData.data.service_address.addressTownCity},  {serviceData.data.service_address.tax_country.name}</div>
                                  :
                                  <div className="detailed-service-supporting-text">{serviceData.data.service_address.tax_address_type
                                    .name}</div>
                                }

                              </div>
                              <div className="detailed-service-detail-items">
                                <img
                                  className="detailed-service-marker-pin-02-icon"
                                  alt=""
                                  src={calendar}
                                />

                                <div className="detailed-service-supporting-text">{getCustomizedDate(serviceData.data.createdAt)}</div>
                              </div>
                            </div>
                            <div className="detailed-service-contact-details">
                              <div className="detailed-service-contact-name-wrapper">
                                <div className="detailed-service-contact-name">4.9/5</div>
                              </div>
                              <div className="detailed-service-contact-role">
                                <div className="detailed-service-supporting-text2">99,999 reviews</div>
                              </div>
                              {/* <div className="detailed-service-social-links">
                                <div className="detailed-service-rectangle-container">
                                  <div className="detailed-service-frame-inner"></div>
                                  <div className="detailed-service-icon-backgrounds-parent">
                                    <div className="detailed-service-icon-backgrounds"></div>
                                    <img
                                      className="detailed-service-whatsapp-1-icon"
                                      alt=""
                                      src={facebook_1_1_2x}
                                    />
                                  </div>
                                  <div className="detailed-service-icon-backgrounds-parent">
                                    <div className="detailed-service-ellipse-div"></div>
                                    <img
                                      className="detailed-service-whatsapp-1-icon"
                                      alt=""
                                      src={instagram_1_1_2x}
                                    />
                                  </div>
                                  <div className="detailed-service-icon-backgrounds-parent">
                                    <div className="detailed-service-frame-child1"></div>
                                    <img
                                      className="detailed-service-whatsapp-1-icon"
                                      alt=""
                                      src={whatsapp_1_2x}
                                    />
                                  </div>
                                  <div className="detailed-service-icon-backgrounds-parent">
                                    <div className="detailed-service-icon-backgrounds"></div>
                                    <img
                                      className="detailed-service-whatsapp-1-icon"
                                      alt=""
                                      src={twitter_1_2x}
                                    />
                                  </div>
                                  <div className="detailed-service-icon-backgrounds-parent">
                                    <div className="detailed-service-ellipse-div"></div>
                                    <img
                                      className="detailed-service-whatsapp-1-icon"
                                      alt=""
                                      src={linkedinlogo_1_2x}
                                    />
                                  </div>
                                </div>
                              </div> */}
                            </div>
                          </div>
                          <div className="detailed-service-button-row">
                            {!serviceData.data.isAuthor && (
                              serviceData.data.addedToRadar ? (

                                <div className="detailed-service-buttonsbutton1" onClick={() => handleRemoveRadar(serviceData.data.id)} style={{ cursor: "pointer" }}>
                                  <img
                                    className="detailed-service-magnifier-1-icon"
                                    alt=""
                                    src={target_04}
                                  />
                                  <div className="detailed-service-text-padding">
                                    <div className="detailed-service-text11">Added To Radar</div>
                                  </div>

                                </div>
                              ) : (
                                <div className="detailed-service-buttonsbutton1" onClick={() => handleAddRadar(serviceData.data.id)} style={{ cursor: "pointer" }}>
                                  <img
                                    className="detailed-service-magnifier-1-icon"
                                    alt=""
                                    src={radar_1_1_2x}
                                  />

                                  <div className="detailed-service-text-padding">
                                    <div className="detailed-service-text11">Add To Radar</div>
                                  </div>
                                </div>
                              )
                            )}
                            {/* <div className="detailed-service-buttonsbutton2">
                              <img
                                className="detailed-service-placeholder-icon4"
                                alt=""
                                src={placeholder1}
                              />

                              <img
                                className="detailed-service-magnifier-1-icon"
                                alt=""
                                src={deal_2_1_2x}
                              />

                              <img
                                className="detailed-service-placeholder-icon4"
                                alt=""
                                src={placeholder1}
                              />
                            </div>
                            <div className="detailed-service-buttonsbutton2">
                              <img
                                className="detailed-service-placeholder-icon4"
                                alt=""
                                src={placeholder1}
                              />

                              <img
                                className="detailed-service-magnifier-1-icon"
                                alt=""
                                src={magnifier_1_2x}
                              />

                              <img
                                className="detailed-service-placeholder-icon4"
                                alt=""
                                src={placeholder1}
                              />
                            </div> */}

                            <div className='detailed-service-sharethis-parent'>

                              <div className="detailed-service-buttonsbutton2" onClick={handleShareButtonClick} style={{ cursor: 'pointer' }}>
                                <img
                                  className="detailed-service-placeholder-icon4"
                                  alt=""
                                  src={placeholder1}
                                />

                                <img
                                  className="detailed-service-magnifier-1-icon"
                                  alt=""
                                  src={share_1_2x}
                                />

                                <img
                                  className="detailed-service-placeholder-icon4"
                                  alt=""
                                  src={placeholder1}
                                />



                              </div>

                              {activeSection === 'share' && (
                                <div className='detailed-service-sharethis'>
                                  <InlineShareButtons
                                    config={{
                                      alignment: 'center',
                                      color: 'black',
                                      enabled: true,
                                      font_size: 14,
                                      labels: 'cta',
                                      language: 'en',
                                      networks: ["twitter", "whatsapp", "linkedin", "email", "facebook"
                                        , "flipboard"
                                        , "google"
                                        , "digg"
                                        , "livejournal"
                                        , "mailru"
                                        , "meneame"
                                        , "messenger"
                                        , "oknoklassniki"
                                        , "pinterest"
                                        , "print"
                                        , "reddit"
                                        , "sharethis"
                                        , "sms"
                                        , "stumbleupon"
                                        , "tumblr"
                                        , "blogger", "vk", "wechat", "weibo", "delicious", "xing"],
                                      padding: 12,
                                      radius: 4,
                                      show_total: false,
                                      size: 34,
                                      url: "https://gerator.io/", // Use the current page URL dynamically
                                      image: 'https://bit.ly/2CMhCMC',
                                      description: 'Check out this amazing content!',
                                      title: 'Custom Share Title',
                                    }}
                                  />
                                </div>
                              )}

                            </div >


                            {/* <img
                              className="detailed-service-button-row-child"
                              alt=""
                              src={group_1010}
                            /> */}

                            <div className='detailed-service-sharethis-follow-parent'>

                              <div className="detailed-service-buttonsbutton2" onClick={handleFollowButtonClick} style={{ cursor: 'pointer' }}>
                                <img
                                  className="detailed-service-placeholder-icon4"
                                  alt=""
                                  src={placeholder1}
                                />

                                <img
                                  className="detailed-service-magnifier-1-icon"
                                  alt=""
                                  src={follow}

                                />

                                <img
                                  className="detailed-service-placeholder-icon4"
                                  alt=""
                                  src={placeholder1}
                                />

                                Follow us

                              </div>

                              {activeSection === 'follow' && (
                                <div className='detailed-service-sharethis-follow'>
                                  <InlineFollowButtons
                                    config={{
                                      action: 'Follow us:', // call to action (STRING)
                                      action_enable: false,
                                      action_pos: 'bottom', // position of call to action (left, top, right)
                                      alignment: 'center',  // alignment of buttons (left, center, right)
                                      color: 'white',       // set the color of buttons (social, white)
                                      enabled: true,        // show/hide buttons (true, false)
                                      networks: [
                                        'linkedin',         // which networks to include (see FOLLOW NETWORKS)
                                        'twitter',
                                        'facebook',
                                        'instagram',

                                      ],
                                      padding: 8,           // padding within buttons (INTEGER)
                                      profiles: {           // social profile links for buttons
                                        twitter: 'ger8or',
                                        facebook: 'Ger8orMarketplace ',
                                        instagram: 'ger8or',
                                        linkedin: 'company/gerator',
                                      },
                                      radius: 9,            // the corner radius on each button (INTEGER)
                                      size: 32,             // the size of each button (INTEGER)
                                      spacing: 8            // the spacing between buttons (INTEGER)
                                    }}
                                  />
                                </div>
                              )}

                            </div>


                          </div>
                        </div>
                        <div className="detailed-service-dropdown-container"  style={{ cursor: "pointer" }} onClick={toggleThreeDotsDropdown} ref={threeDotsRef}>
                          <div className="detailed-service-dropdown">
                            <img
                              className="detailed-service-maximize-01-icon"
                              alt=""
                              src={dotsvertical}
                            />
                          </div>
                          {isThreeDotOpen && <ThreeDotsDetailPageComponent  />}

                        </div>
                      </div>
                    </div>
                    <img className="detailed-service-divider-icon" alt="" src={divider1} />

                    <div className="detailed-service-heading-parent">
                      <div className="detailed-service-heading">Highlights</div>
                      <div className="detailed-service-description-content">
                        <div className="detailed-service-description-columns-first">
                          <div className="detailed-service-first-columns-paragraphs">
                            <div className="detailed-service-paragraph">
                              {serviceData.data.tax_service_category.name}
                            </div>
                            <div className="detailed-service-paragraph1">Service Category</div>
                          </div>
                          <div className="detailed-service-first-columns-paragraphs">
                            <div className="detailed-service-paragraph">{serviceData.data.tax_purpose_use.name}</div>
                            <div className="detailed-service-paragraph1">Device Use</div>
                          </div>
                          <div className="detailed-service-first-columns-paragraphs">
                            <div className="detailed-service-paragraph">Anatomy, Physiology</div>
                            <div className="detailed-service-paragraph1">Service Brand / Company</div>
                          </div>
                        </div>
                        <div className="detailed-service-description-columns-second">
                          <div className="detailed-service-second-columns-paragraphs">
                            <div className="detailed-service-paragraph">
                              {serviceData.data.tax_physical_location.name}
                            </div>
                            <div className="detailed-service-paragraph1">Physical Location</div>
                          </div>

                          <div className="detailed-service-second-columns-paragraphs">
                            <div className="detailed-service-paragraph">{serviceData.data.tax_clinical_application.name}</div>
                            <div className="detailed-service-paragraph1">Clinical Applications</div>
                          </div>


                          <div className="detailed-service-second-columns-paragraphs">
                            <div className="detailed-service-paragraph">
                              {serviceData.data.tax_service_and_support_reach.name}
                            </div>
                            <div className="detailed-service-paragraph1">Service & Support Reach</div>
                          </div>

                          <div className="detailed-service-second-columns-paragraphs">
                            <div className="detailed-service-paragraph">{serviceData.data.tax_service_category.name}</div>
                            <div className="detailed-service-paragraph1">Device Category</div>
                          </div>

                        </div>
                      </div>
                    </div>

                    <div className="detailed-service-content-blocks">
                      <div className="detailed-service-heading1">Service Feature Highlights</div>
                      <div className="detailed-service-paragraph26">
                        {serviceData.data.serviceFeatureHighlights}
                      </div>
                    </div>
                    <div className="detailed-service-content-blocks">
                      <div className="detailed-service-heading1">
                        Service Benefits Highlights
                      </div>
                      <div className="detailed-service-paragraph26">
                        {serviceData.data.serviceBenefitsHighlights}
                      </div>
                    </div>
                    <div className="detailed-service-content-blocks">
                      <div className="detailed-service-heading1">Parent Devices for which this service is available</div>
                      <div className="detailed-service-paragraph26">
                        {serviceData.data.parentDevices}
                      </div>
                    </div>
                    <div className="detailed-service-content-blocks">
                      <div className="detailed-service-heading1">Location</div>
                      {serviceData.data.service_address.tax_address_type
                        .slug === "address" ?
                        <div className="detailed-service-paragraph26">
                          {serviceData.data.service_address?.fullAddress}
                        </div>
                        :
                        <div className="detailed-service-paragraph26">
                          {serviceData.data.service_address.tax_address_type
                            .name}
                        </div>
                      }

                      {serviceData.data.service_address.tax_address_type
                        .slug === "address" && <MapLocationViewComponent
                          key={serviceData.data.id}
                          width={"100%"}
                          height={"400px"}
                          initialCoordinates={serviceData.data.service_address.addressLocation}
                        />}

                    </div>
                    {(serviceData.data.videoType && serviceData.data.linkVideo) &&
                      // (serviceData.data.videoType === 'Youtube') ?
                      <>
                        <VideoSection videoUrl={serviceData.data.linkVideo} />
                        <br />
                      </>
                      // :
                      // <>
                      //   <VideoSection videoUrl={serviceData.data.linkVideo} />
                      //   <br />
                      // </>
                    }
                    <img className="detailed-service-divider-icon" alt="" src={divider1} />

                    <div className="detailed-service-section">
                      <div className="detailed-service-benefits-container">
                        <div className="detailed-service-benefits-content">
                          {(serviceData.data?.serviceFeatures?.trim() ||
                            serviceData.data?.serviceBenefits?.trim() ||
                            serviceData.data?.scopeOfWork?.trim() ||
                            serviceData.data?.serviceLevelAgreement?.trim() ||
                            serviceData.data?.additionalInformation?.trim()) &&
                            <div className="detailed-service-heading6">Description</div>}

                          {serviceData.data?.serviceFeatures?.trim() !== "" && (
                            <div className="detailed-service-benefits-subtitle">
                              <div className="detailed-service-heading7">Service Features</div>
                              <div className="detailed-service-paragraph30">
                                {serviceData.data.serviceFeatures}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    {serviceData.data?.serviceBenefits?.trim() !== "" && (
                      <div className="detailed-service-content-blocks">
                        <div className="detailed-service-heading1">
                          Service Benefits
                        </div>
                        <div className="detailed-service-paragraph26">
                          {serviceData.data.serviceBenefits}
                        </div>
                      </div>
                    )}
                    {serviceData.data?.scopeOfWork?.trim() !== "" && (
                      <div className="detailed-service-content-blocks">
                        <div className="detailed-service-heading1">
                          Scope Of Work
                        </div>
                        <div className="detailed-service-paragraph26">
                          {serviceData.data.scopeOfWork}
                        </div>
                      </div>
                    )}



                    <div className="detailed-service-heading-parent">
                      {serviceData.data?.serviceLevelAgreement?.trim() !== "" && (
                        <div className="detailed-service-content-blocks">
                          <div className="detailed-service-heading1">Service Level Agreement</div>
                          <div className="detailed-service-second-paragraph">
                            <div className="detailed-service-paragraph35">
                              {serviceData.data.serviceLevelAgreement}
                            </div>
                          </div>
                        </div>
                      )}

                      {serviceData.data?.additionalInformation?.trim() !== "" && (
                        <div className="detailed-service-content-blocks">
                          <div className="detailed-service-heading1">Additional Information</div>
                          <div className="detailed-service-paragraph26">
                            {serviceData.data.additionalInformation}
                          </div>
                        </div>
                      )}
                    </div>
                    {serviceData.data?.documentFiles?.length > 0 && (
                      <div className="detailed-device-heading-parent">
                        <div className="detailed-device-heading1">Documents</div>
                        <div className="detailed-device-download-items-parent detailed-device-download-items-parent-custom">
                          {serviceData.data.documentFiles.map((link, index) => (
                            <a
                              key={index}
                              href={link} // Use the appropriate property for the file URL
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <div className="detailed-device-download-items">
                                <div className="detailed-device-file-upload-item-base">
                                  <img
                                    className="detailed-device-progress-fill-icon"
                                    alt=""
                                    src={progress_fill}
                                  />
                                  <div className="detailed-device-file-type-icon">
                                    <img
                                      className="detailed-device-page-icon"
                                      alt=""
                                      src={page}
                                    />
                                    <b className="detailed-device-file-type">PDF</b>
                                  </div>
                                  <div className="detailed-device-download-info">
                                    <div className="detailed-device-text12">
                                      {serviceData.data.services_files[index].file_name} {/* Access file name from the current file object */}
                                    </div>
                                    <div className="detailed-device-supporting-text3">
                                      {(serviceData.data.services_files[index].file_size / 1000).toFixed(1) + " KB"} {/* Access file size */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </a>
                          ))}
                        </div>
                      </div>
                    )}
                    {/* <div className="detailed-service-heading15">Reviews (2)</div>
                    <div className="detailed-service-filters-bar">
                      <div className="detailed-service-content4">
                        <div className="detailed-service-button-group">
                          <div className="detailed-service-button-group-base">
                            <div className="detailed-service-text18">Newest</div>
                          </div>
                          <div className="detailed-service-button-group-base1">
                            <div className="detailed-service-text19">Highest</div>
                          </div>
                          <div className="detailed-service-button-group-base2">
                            <div className="detailed-service-text20">Text</div>
                          </div>
                          <div className="detailed-service-button-group-base3">
                            <div className="detailed-service-text20">Text</div>
                          </div>
                          <div className="detailed-service-button-group-base4">
                            <div className="detailed-service-text20">Text</div>
                          </div>
                          <div className="detailed-service-button-group-base5">
                            <div className="detailed-service-text20">Text</div>
                          </div>
                          <div className="detailed-service-button-group-base6">
                            <div className="detailed-service-text20">Text</div>
                          </div>
                          <div className="detailed-service-button-group-base7">
                            <div className="detailed-service-text20">Text</div>
                          </div>
                          <div className="detailed-service-button-group-base8">
                            <div className="detailed-service-text26">Lowest</div>
                          </div>
                        </div>
                        <div className="detailed-service-actions">
                          <div className="detailed-service-input-dropdown">
                            <div className="detailed-service-input-with-label1">
                              <div className="detailed-service-label">Search</div>
                              <div className="detailed-service-input1">
                                <div className="detailed-service-content5">
                                  <img
                                    className="detailed-service-search-lg-icon"
                                    alt=""
                                    src={searchlg}
                                  />

                                  <input
                                    className="detailed-service-text27"
                                    placeholder="Thomson Medical"
                                    type="text"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="detailed-service-hint-text">
                              This is a hint text to help user.
                            </div>
                          </div>
                          <div className="detailed-service-button">
                            <img
                              className="detailed-service-filter-lines-icon"
                              alt=""
                              src={filterlines}
                            />

                            <div className="detailed-service-text-padding">
                              <div className="detailed-service-text28">Filters</div>
                            </div>
                            <img
                              className="detailed-service-placeholder-icon4"
                              alt=""
                              src={placeholder1}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="detailed-service-review-container">
                      <div className="detailed-service-review-content">
                        <div className="detailed-service-first-review">
                          <b className="detailed-service-empty-review">5.0</b>
                          <div className="detailed-service-reviewer">
                            <div className="detailed-service-heading16">/5</div>
                          </div>
                        </div>
                        <div className="detailed-service-review-stars-background">
                          <img
                            className="detailed-service-star-background-icon"
                            alt=""
                            src={star_background}
                          />

                          <img
                            className="detailed-service-star-background-icon"
                            alt=""
                            src={star_background}
                          />

                          <img
                            className="detailed-service-star-background-icon"
                            alt=""
                            src={star_background}
                          />

                          <img
                            className="detailed-service-star-background-icon"
                            alt=""
                            src={star_background}
                          />

                          <img
                            className="detailed-service-star-background-icon"
                            alt=""
                            src={star_background}
                          />
                        </div>
                      </div>
                      <div className="detailed-service-review-stars">
                        <div className="detailed-service-rectangle-parent1">
                          <div className="detailed-service-rectangle-div"></div>
                          <div className="detailed-service-stars">
                            <img
                              className="detailed-service-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />

                            <img
                              className="detailed-service-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />

                            <img
                              className="detailed-service-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />

                            <img
                              className="detailed-service-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />

                            <img
                              className="detailed-service-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />
                          </div>
                          <div className="detailed-service-first-review-fifth-star">
                            <div className="detailed-service-heading17">Accuracy (5.0)</div>
                          </div>
                        </div>
                        <div className="detailed-service-rectangle-parent1">
                          <div className="detailed-service-rectangle-div"></div>
                          <div className="detailed-service-stars">
                            <img
                              className="detailed-service-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />

                            <img
                              className="detailed-service-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />

                            <img
                              className="detailed-service-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />

                            <img
                              className="detailed-service-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />

                            <img
                              className="detailed-service-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />
                          </div>
                          <div className="detailed-service-heading-wrapper">
                            <div className="detailed-service-heading18">Relevance (5.0)</div>
                          </div>
                        </div>
                        <div className="detailed-service-rectangle-parent3">
                          <div className="detailed-service-rectangle-div"></div>
                          <div className="detailed-service-stars-wrapper">
                            <div className="detailed-service-stars2">
                              <img
                                className="detailed-service-filter-lines-icon"
                                alt=""
                                src={star_icon}
                              />

                              <img
                                className="detailed-service-filter-lines-icon"
                                alt=""
                                src={star_icon}
                              />

                              <img
                                className="detailed-service-filter-lines-icon"
                                alt=""
                                src={star_icon}
                              />

                              <img
                                className="detailed-service-filter-lines-icon"
                                alt=""
                                src={star_icon}
                              />

                              <img
                                className="detailed-service-filter-lines-icon"
                                alt=""
                                src={star_icon}
                              />
                            </div>
                          </div>
                          <div className="detailed-service-heading19">Value for Money (5.0)</div>
                        </div>
                      </div>
                    </div> */}
                    <div className="detailed-service-divider-wrapper">
                      <img
                        className="detailed-service-divider-icon3"
                        alt=""
                        src={divider1}
                      />
                    </div>
                    {/* <div className="detailed-service-client-review">
                      <img
                        className="detailed-service-client-review-child"
                        alt=""
                        src={group_78_2x}
                      />

                      <div className="detailed-service-client-review-header">
                        <div className="detailed-service-heading20">Chloe Tammy</div>
                        <div className="detailed-service-heading21">
                          <span>Marketing Communications at</span>
                          <span className="detailed-service-alvo-medical"> Alvo Medical</span>
                        </div>
                      </div>
                      <div className="detailed-service-client-review-content">
                        <div className="detailed-service-client-rating">
                          <div className="detailed-service-stars3">
                            <img
                              className="detailed-service-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />

                            <img
                              className="detailed-service-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />

                            <img
                              className="detailed-service-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />

                            <img
                              className="detailed-service-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />

                            <img
                              className="detailed-service-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />
                          </div>
                          <div className="detailed-service-rating-value">
                            <div className="detailed-service-heading22">1 month ago</div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                  <div className="detailed-service-service-card">
                    <div className="detailed-service-card-content">
                      <div className="detailed-service-card-content-child"></div>
                      <div className="detailed-service-card-header">
                        <div className="detailed-service-price-tag">
                          {/* <div className="detailed-service-badge-wrapper">
                          <div className="detailed-service-badge3">
                            <img
                              className="detailed-service-dot-icon3"
                              alt=""
                              src={dot1}
                            />

                            <div className="detailed-service-text29">Sale</div>
                          </div>
                        </div> */}
                          <div className="detailed-service-price-parent">
                            <div className="detailed-service-price">${formatNumber(serviceData.data.rangeMin)}</div>
                            <div className="detailed-service-original-price">
                              <div className="detailed-service-discount">{serviceData.data.tax_unit_measure_service.name}</div>
                            </div>
                          </div>
                          {/* <div className="detailed-service-service-title-container">
                          <div className="detailed-service-service-title">Posted By {serviceData.data.tax_your_role.name}</div>
                        </div> */}
                        </div>
                      </div>
                      <div className="detailed-service-card-footer">
                        {serviceData.data.profileImage ?
                          <img
                            className="detailed-service-card-footer-child"
                            alt=""
                            src={serviceData.data.profileImage}
                          />
                          :
                          <img
                            className="detailed-service-card-footer-child"
                            alt=""
                            src={group_781_2x}
                          />
                        }
                        <div className="detailed-service-footer-divider">
                          <img
                            className="detailed-service-divider-icon4"
                            alt=""
                            src={divider2}
                          />
                        </div>
                      </div>
                      <div className="detailed-service-professional-info">
                        <div className="detailed-service-professional-details">
                          <div className="detailed-service-professional-actions">
                            <div className="detailed-service-benefits-container">
                              <div className="detailed-service-amlie-laurent">{serviceData.data.service_user.displayName}</div>
                              <Link className="detailed-service-role" to={`/business/${serviceData.data.service_business.id}/${serviceData.data.service_business.businessName}`}>
                                {serviceData.data.service_business.businessName}
                              </Link>
                              {/* <div className="detailed-service-role">{serviceData.data.service_business.businessName}</div> */}
                            </div>
                            <div className="detailed-service-action-buttons">
                              <div className="detailed-service-contact-button">
                                <div className="detailed-service-message-button">
                                  <div className="detailed-service-navigation">
                                    <img
                                      className="detailed-service-bookmark-check-icon"
                                      alt=""
                                      src={bookmarkcheck}
                                    />
                                  </div>
                                  <div className="detailed-service-supporting-text10">
                                    Verified by Gerätor
                                  </div>
                                </div>
                              </div>
                              <div className="detailed-service-supporting-text11">
                                Adani Health conforms to Gerätor's requirements for
                                verification
                              </div>
                            </div>
                            <div className="detailed-service-favorite-button">
                              <div className="detailed-service-button-container2">
                                {!serviceData.data.isAuthor && (
                                    <div className="detailed-device-buttonsbutton5" onClick={handlePrivateMessage}>
                                      <img
                                          className="detailed-device-filter-lines-icon"
                                          alt=""
                                          src={messagechatcircle}
                                      />
                                      <div className="detailed-device-text30">Private Message</div>
                                    </div>
                                )}
                                {/* Add To Contact */}
                                {!serviceData.data.isAuthor && (
                                  (serviceData.data.addedToContact) ?
                                    <div className="detailed-service-buttonsbutton6" onClick={() => handleRemoveContact(serviceData.data.service_user.id)} style={{ cursor: 'pointer' }}>
                                      <img
                                        className="detailed-service-search-lg-icon"
                                        alt=""
                                        src={usercheck02}
                                      />
                                    </div>
                                    :
                                    <div className="detailed-service-buttonsbutton6-add" onClick={() => handleAddContact(serviceData.data.service_user.id)} style={{ cursor: 'pointer' }}>
                                      <img
                                        className="detailed-service-search-lg-icon"
                                        alt=""
                                        src={add_contact}
                                      />
                                    </div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="detailed-service-dropdown-menu" style={{ cursor: "pointer" }} onClick={toggleThreeDotsDropdownUser} ref={threeDotsRefUser}>
                            <div className="detailed-service-dropdown1">
                              <img
                                className="detailed-service-maximize-01-icon"
                                alt=""
                                src={dotsvertical}
                              />
                            </div>
                            {isThreeDotUserOpen && <ThreeDotsDetailPageUserComponent />}

                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="detailed-service-rectangle-parent4">
                      <div className="detailed-service-frame-child6"></div>
                      <div className="detailed-service-testimonial-header">
                        <div className="detailed-service-header-content1">
                          <div className="detailed-service-heading23">Jobs</div>
                          <div className="detailed-service-testimonial-description">
                            <div className="detailed-service-description">View All</div>
                          </div>
                        </div>
                      </div>
                      <div className="detailed-service-testimonial-list">
                        <img
                          className="detailed-service-divider-icon"
                          alt=""
                          src={divider2}
                        />

                        <div className="detailed-service-testimonial-divider">
                          <div className="detailed-service-testimonial-item">
                            <div className="detailed-service-rectangle-parent5">
                              <div className="detailed-service-frame-child7"></div>
                              <img
                                className="detailed-service-f4b711e503bc72fe396e5043e0c2-7-icon"
                                alt=""
                                src={f8595f4b711e503bc72fe396e5043e0c2_7_2x}
                              />
                            </div>
                            <div className="detailed-service-testimonial-details">
                              <div className="detailed-service-client-name">
                                WHI Program assistant / Undergraduate researcher
                              </div>
                              <div className="detailed-service-heading24">Marketing Fusion SARL</div>
                            </div>
                          </div>
                        </div>
                        <img
                          className="detailed-service-divider-icon"
                          alt=""
                          src={divider2}
                        />

                        <div className="detailed-service-testimonial-divider">
                          <div className="detailed-service-testimonial-item">
                            <div className="detailed-service-rectangle-parent5">
                              <div className="detailed-service-frame-child7"></div>
                              <img
                                className="detailed-service-f4b711e503bc72fe396e5043e0c2-7-icon"
                                alt=""
                                src={f8595f4b711e503bc72fe396e5043e0c2_7_2x}
                              />
                            </div>
                            <div className="detailed-service-testimonial-details">
                              <div className="detailed-service-client-name">
                                WHI Program assistant / Undergraduate researcher
                              </div>
                              <div className="detailed-service-heading24">Marketing Fusion SARL</div>
                            </div>
                          </div>
                        </div>
                        <img
                          className="detailed-service-divider-icon"
                          alt=""
                          src={divider2}
                        />

                        <div className="detailed-service-testimonial-divider">
                          <div className="detailed-service-testimonial-item">
                            <div className="detailed-service-rectangle-parent5">
                              <div className="detailed-service-frame-child7"></div>
                              <img
                                className="detailed-service-f4b711e503bc72fe396e5043e0c2-7-icon"
                                alt=""
                                src={f8595f4b711e503bc72fe396e5043e0c2_7_2x}
                              />
                            </div>
                            <div className="detailed-service-testimonial-details">
                              <div className="detailed-service-client-name">
                                WHI Program assistant / Undergraduate researcher
                              </div>
                              <div className="detailed-service-heading24">Marketing Fusion SARL</div>
                            </div>
                          </div>
                        </div>
                        <img
                          className="detailed-service-divider-icon"
                          alt=""
                          src={divider2}
                        />

                        <div className="detailed-service-testimonial-divider">
                          <div className="detailed-service-testimonial-item">
                            <div className="detailed-service-rectangle-parent5">
                              <div className="detailed-service-frame-child7"></div>
                              <img
                                className="detailed-service-f4b711e503bc72fe396e5043e0c2-7-icon"
                                alt=""
                                src={f8595f4b711e503bc72fe396e5043e0c2_7_2x}
                              />
                            </div>
                            <div className="detailed-service-testimonial-details">
                              <div className="detailed-service-client-name">
                                WHI Program assistant / Undergraduate researcher
                              </div>
                              <div className="detailed-service-heading24">Marketing Fusion SARL</div>
                            </div>
                          </div>
                        </div>
                        <img
                          className="detailed-service-divider-icon"
                          alt=""
                          src={divider2}
                        />

                        <div className="detailed-service-testimonial-divider">
                          <div className="detailed-service-testimonial-item">
                            <div className="detailed-service-rectangle-parent5">
                              <div className="detailed-service-frame-child7"></div>
                              <img
                                className="detailed-service-f4b711e503bc72fe396e5043e0c2-7-icon"
                                alt=""
                                src={f8595f4b711e503bc72fe396e5043e0c2_7_2x}
                              />
                            </div>
                            <div className="detailed-service-testimonial-details">
                              <div className="detailed-service-client-name">
                                WHI Program assistant / Undergraduate researcher
                              </div>
                              <div className="detailed-service-heading24">Marketing Fusion SARL</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="detailed-service-listing-image-grid">
              <div className="detailed-service-paragraph37">
                One of the best offers I found in the market
              </div>
            </div>
            <div className="detailed-service-listing-image-grid1">
              <div className="detailed-service-paragraph35">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy text
                ever since the 1500s, when an unknown printer took a galley of type
                and scrambled it to make a type specimen book.
              </div>
            </div> */}

            {/* <div className="detailed-service-client-feedback-parent">
              <div className="detailed-service-client-feedback">
                <div className="detailed-service-feedback-content">
                  <img
                    className="detailed-service-feedback-content-child"
                    alt=""
                    src={group_78_2x}
                  />

                  <div className="detailed-service-feedback-header">
                    <div className="detailed-service-header-info">
                      <div className="detailed-service-heading20">Chloe Tammy</div>
                      <div className="detailed-service-heading21">
                        <span>Marketing Communications at</span>
                        <span className="detailed-service-alvo-medical"> Alvo Medical</span>
                      </div>
                    </div>
                  </div>
                  <div className="detailed-service-feedback-rating">
                    <div className="detailed-service-buttonsbutton7">
                        <img
                          className="detailed-service-filter-lines-icon"
                          alt=""
                          src={messagedotscircle}
                        />

                        <div className="detailed-service-text35">Reply</div>
                      </div>
                    <div className="detailed-service-feedback-score">
                      <div className="detailed-service-stars-parent">
                        <div className="detailed-service-stars">
                          <img
                            className="detailed-service-filter-lines-icon"
                            alt=""
                            src={star_icon}
                          />

                          <img
                            className="detailed-service-filter-lines-icon"
                            alt=""
                            src={star_icon}
                          />

                          <img
                            className="detailed-service-filter-lines-icon"
                            alt=""
                            src={star_icon}
                          />

                          <img
                            className="detailed-service-filter-lines-icon"
                            alt=""
                            src={star_icon}
                          />

                          <img
                            className="detailed-service-filter-lines-icon"
                            alt=""
                            src={star_icon}
                          />
                        </div>
                        <div className="detailed-service-rating-value">
                          <div className="detailed-service-heading22">1 month ago</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="detailed-service-client-message">
                <div className="detailed-service-message-content">
                  <div className="detailed-service-paragraph39">
                    One of the best offers I found in the market
                  </div>
                  <div className="detailed-service-paragraph26">
                    Lorem Ipsum is simply dummy text of the printing and typesetting
                    industry. Lorem Ipsum has been the industry's standard dummy text
                    ever since the 1500s, when an unknown printer took a galley of
                    type and scrambled it to make a type specimen book.
                  </div>
                </div>
              </div>
              <div className="detailed-service-view-replies">
                <div className="detailed-service-buttonsbutton8" onClick={handleReplyClick}>
                  <img
                    className="detailed-service-filter-lines-icon"
                    alt=""
                    src={messagedotscircle}
                  />

                  <div className="detailed-service-text35">Reply</div>
                </div>
              </div>

              {showReply && (
                <div className="detailed-service-reply-section">
                  <textarea
                    className="detailed-service-reply-textarea"
                    value={replyText}
                    onChange={handleChange}
                    placeholder="Type your reply..."
                  />

                  <div className={`detailed-service-char-counter ${warningClass}`}>
                    {maxLength - replyText.length} characters left
                  </div>

                  <button className="detailed-service-reply-submit-btn" onClick={handleSubmit}>
                    Submit Reply
                  </button>
                </div>
              )}

            </div> */}
            {/* <div className="detailed-service-pagination-wrapper">
              <div className="detailed-service-pagination">
                <div className="detailed-service-button-wrap">
                  <div className="detailed-service-buttonsbutton9">
                    <img
                      className="detailed-service-placeholder-icon4"
                      alt=""
                      src={placeholder1}
                    />

                    <div className="detailed-service-text-padding">
                      <div className="detailed-service-page-number">Previous</div>
                    </div>
                    <img
                      className="detailed-service-placeholder-icon4"
                      alt=""
                      src={placeholder1}
                    />
                  </div>
                </div>
                <div className="detailed-service-details">1 of 10</div>
                <div className="detailed-service-button-wrap1">
                  <div className="detailed-service-buttonsbutton10">
                    <img
                      className="detailed-service-placeholder-icon4"
                      alt=""
                      src={placeholder1}
                    />

                    <div className="detailed-service-text-padding">
                      <div className="detailed-service-text37">Next</div>
                    </div>
                    <img
                      className="detailed-service-placeholder-icon4"
                      alt=""
                      src={placeholder1}
                    />
                  </div>
                </div>
              </div>
            </div> */}


            <ReviewServiceView isAuthor={serviceData.data.isAuthor} listingId={id} currentUsermeta={currentUsermeta} />

            <div className="detailed-service-listing-image-grid1">
              <img className="detailed-service-divider-icon3" alt="" src={divider1} />
            </div>

            {/* Review Add Form component */}
            {(!serviceData.data.isAuthor && (!serviceData.data.isReviewed) && <AddReviewServiceForm listingId={id} currentUsermeta={currentUsermeta} formikRef={formikRef} />)
            }


            {/* <div className="detailed-service-review-form-wrapper">
              <div className="detailed-service-review-form">
                <div className="detailed-service-review-form-child"></div>
                <div className="detailed-service-breadcrumb-container-parent">
                  <div className="detailed-service-heading32">Write Review</div>

                  <RatingStarComponent />
                  <ReviewDescriptionComponent />

                  <div className="detailed-service-frame-parent3">
                    <div className="detailed-service-heading-container">
                      <div className="detailed-service-heading33">Accuracy</div>
                      <div className="detailed-service-listing-detail-item-date-parent">
                        <img
                          className="detailed-service-filter-lines-icon"
                          alt=""
                          src={star_icon}
                        />

                        <img
                          className="detailed-service-filter-lines-icon"
                          alt=""
                          src={star_icon}
                        />

                        <img
                          className="detailed-service-filter-lines-icon"
                          alt=""
                          src={star_icon}
                        />

                        <img
                          className="detailed-service-filter-lines-icon"
                          alt=""
                          src={star_icon}
                        />

                        <img
                          className="detailed-service-filter-lines-icon"
                          alt=""
                          src={star_icon}
                        />
                      </div>
                    </div>
                    <div className="detailed-service-heading-container">
                      <div className="detailed-service-heading33">Relevance</div>
                      <div className="detailed-service-listing-detail-item-date-parent">
                        <img
                          className="detailed-service-filter-lines-icon"
                          alt=""
                          src={star_icon}
                        />

                        <img
                          className="detailed-service-filter-lines-icon"
                          alt=""
                          src={star_icon}
                        />

                        <img
                          className="detailed-service-filter-lines-icon"
                          alt=""
                          src={star_icon}
                        />

                        <img
                          className="detailed-service-filter-lines-icon"
                          alt=""
                          src={star_icon}
                        />

                        <img
                          className="detailed-service-filter-lines-icon"
                          alt=""
                          src={star_icon}
                        />
                      </div>
                    </div>
                    <div className="detailed-service-heading-container">
                      <div className="detailed-service-heading35">Value for Money</div>
                      <div className="detailed-service-listing-detail-item-date-parent">
                        <img
                          className="detailed-service-filter-lines-icon"
                          alt=""
                          src={star_icon}
                        />

                        <img
                          className="detailed-service-filter-lines-icon"
                          alt=""
                          src={star_icon}
                        />

                        <img
                          className="detailed-service-filter-lines-icon"
                          alt=""
                          src={star_icon}
                        />

                        <img
                          className="detailed-service-filter-lines-icon"
                          alt=""
                          src={star_icon}
                        />

                        <img
                          className="detailed-service-filter-lines-icon"
                          alt=""
                          src={star_icon}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="detailed-service-input-field">
                    <div className="detailed-service-input-with-label1">
                      <div className="detailed-service-label2">Title</div>
                      <div className="detailed-service-content6">
                        <div className="detailed-service-textarea-input-field">
                          <div className="detailed-service-input-with-label3">
                            <div className="detailed-service-label3">Description</div>
                            <div className="detailed-service-input2">
                              <div className="detailed-service-text38">70 characters</div>
                            </div>
                          </div>
                          <div className="detailed-service-hint-text1">10 characters left</div>
                        </div>
                      </div>
                    </div>
                    <div className="detailed-service-hint-text">This is a hint text to help user.</div>
                  </div>
                  <div className="detailed-service-input-field1">
                    <div className="detailed-service-input-with-label1">
                      <div className="detailed-service-label4">Review</div>
                      <div className="detailed-service-content6">
                        <div className="detailed-service-textarea-input-field1">
                          <div className="detailed-service-input-with-label3">
                            <div className="detailed-service-label3">Description</div>
                            <div className="detailed-service-input3">
                              <div className="detailed-service-text39">1000 characters</div>
                            </div>
                          </div>
                          <div className="detailed-service-hint-text3">40 characters left</div>
                        </div>
                      </div>
                    </div>
                    <div className="detailed-service-hint-text">This is a hint text to help user.</div>
                  </div>
                </div>
                <div className="detailed-service-submit-button-wrapper">
                  <div className="detailed-service-button1-submit">
                    <img
                      className="detailed-service-chevron-down-icon"
                      alt=""
                      src={placeholder2}
                    />

                    <img className="detailed-service-log-in-04-icon" alt="" src={login04} />

                    <div className="detailed-service-navigation">
                      <div className="detailed-service-text40">Submit</div>
                    </div>
                    <img
                      className="detailed-service-chevron-down-icon"
                      alt=""
                      src={placeholder2}
                    />
                  </div>
                </div>
              </div>
            </div> */}
            <div className="detailed-service-listing-image-grid3">
              <img className="detailed-service-divider-icon11" alt="" src={divider3} />
            </div>
            <div className="detailed-service-listing-image-grid4">
              <div className="detailed-service-heading-parent3">
                <div className="detailed-service-heading36">Related Devices</div>
                <div className="detailed-service-text-wrapper">
                  <div className="detailed-service-description">View All</div>
                </div>
              </div>
            </div>
            <div className="detailed-service-listing-image-grid5">
              <div className="detailed-service-frame-parent4">
                <div className="detailed-service-frame-parent5">
                  <div className="detailed-service-rectangle-parent10">
                    <div className="detailed-service-frame-child12"></div>
                    <div className="detailed-service-listing-card-badge-overlay">
                      <img
                        className="detailed-service-listing-card-badge-overlay-child"
                        alt=""
                        src={rectangle_95_2x}
                      />

                      <div className="detailed-service-image-overlay">
                        <div className="detailed-service-badge4">
                          <div className="detailed-service-listing-card-price">Assured</div>
                        </div>
                        <div className="detailed-service-short-listing-team">
                          <div className="detailed-service-badge5">
                            <div className="detailed-service-listing-card-radar">ID# AXJYN</div>
                          </div>
                          <div className="detailed-service-buttonsbutton11">
                            <img
                              className="detailed-service-magnifier-1-icon"
                              alt=""
                              src={radar_1_1_2x}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="detailed-service-listing-detail-item">
                      <div className="detailed-service-message-content">
                        <div className="detailed-service-text-frame">
                          <div className="detailed-service-text42">
                            New / Surplus 2021 Philips Health-care HD11 XE
                            (P/N:23A...
                          </div>
                        </div>
                        <div className="detailed-service-listing-detail-item-value">
                          <div className="detailed-service-listing-detail-item-pin-wrapper">
                            <div className="detailed-service-heading-container">
                              <div className="detailed-service-listing-detail-item-location">
                                <div className="detailed-service-listing-detail-item-date-parent">
                                  <div className="detailed-service-listing-detail-item-date">
                                    <img
                                      className="detailed-service-chevron-right-icon2"
                                      alt=""
                                      src={markerpin021}
                                    />
                                  </div>
                                  <div className="detailed-service-location">India, New Delhi</div>
                                </div>
                                <div className="detailed-service-listing-detail-item-date-parent">
                                  <div className="detailed-service-listing-detail-item-date">
                                    <img
                                      className="detailed-service-chevron-right-icon2"
                                      alt=""
                                      src={calendar1}
                                    />
                                  </div>
                                  <div className="detailed-service-type-of-work">April 09, 2023</div>
                                </div>
                              </div>
                              <div className="detailed-service-listing-team">
                                <div className="detailed-service-listing-team-member-wrapper">
                                  <div className="detailed-service-listing-team-member">4.9/5</div>
                                </div>
                                <div className="detailed-service-contact-role">
                                  <div className="detailed-service-supporting-text12">
                                    99,999 reviews
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="detailed-service-listing">
                            <div className="detailed-service-breadcrumb-items">
                              <div className="detailed-service-listing-short-info">$9,999,999.99</div>
                              <div className="detailed-service-supporting-text-wrapper">
                                <div className="detailed-service-supporting-text13">USD-Per Unit</div>
                              </div>
                            </div>
                          </div>
                          <img
                            className="detailed-service-divider-icon"
                            alt=""
                            src={divider4}
                          />

                          <div className="detailed-service-listing-detail-item-value-inner">
                            <div className="detailed-service-footer-social-parent">
                              <div className="detailed-service-footer-social">
                                <img
                                  className="detailed-service-footer-social-child"
                                  alt=""
                                  src={group_58_2x}
                                />

                                <div className="detailed-service-listing-short-detail-item-pin-parent">
                                  <div className="detailed-service-listing-short-detail-item-pin">
                                    <div className="detailed-service-name">Olivia Rhye</div>
                                  </div>
                                  <div className="detailed-service-role1">Business Name</div>
                                </div>
                              </div>
                              <div className="detailed-service-listing-short-detail-item-date">
                                <img
                                  className="detailed-service-icon1"
                                  alt=""
                                  src={icon1}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="detailed-service-rectangle-parent10">
                    <div className="detailed-service-frame-child12"></div>
                    <div className="detailed-service-listing-card-badge-overlay">
                      <img
                        className="detailed-service-listing-card-badge-overlay-child"
                        alt=""
                        src={rectangle_95_2x}
                      />

                      <div className="detailed-service-image-overlay">
                        <div className="detailed-service-badge4">
                          <div className="detailed-service-listing-card-price">Assured</div>
                        </div>
                        <div className="detailed-service-short-listing-team">
                          <div className="detailed-service-badge5">
                            <div className="detailed-service-listing-card-radar">ID# AXJYN</div>
                          </div>
                          <div className="detailed-service-buttonsbutton11">
                            <img
                              className="detailed-service-magnifier-1-icon"
                              alt=""
                              src={radar_1_1_2x}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="detailed-service-listing-detail-item">
                      <div className="detailed-service-message-content">
                        <div className="detailed-service-text-frame">
                          <div className="detailed-service-text42">
                            New / Surplus 2021 Philips Health-care HD11 XE
                            (P/N:23A...
                          </div>
                        </div>
                        <div className="detailed-service-listing-detail-item-value">
                          <div className="detailed-service-listing-detail-item-pin-wrapper">
                            <div className="detailed-service-heading-container">
                              <div className="detailed-service-listing-detail-item-location">
                                <div className="detailed-service-listing-detail-item-date-parent">
                                  <div className="detailed-service-listing-detail-item-date">
                                    <img
                                      className="detailed-service-chevron-right-icon2"
                                      alt=""
                                      src={markerpin021}
                                    />
                                  </div>
                                  <div className="detailed-service-location">India, New Delhi</div>
                                </div>
                                <div className="detailed-service-listing-detail-item-date-parent">
                                  <div className="detailed-service-listing-detail-item-date">
                                    <img
                                      className="detailed-service-chevron-right-icon2"
                                      alt=""
                                      src={calendar1}
                                    />
                                  </div>
                                  <div className="detailed-service-type-of-work">April 09, 2023</div>
                                </div>
                              </div>
                              <div className="detailed-service-listing-team">
                                <div className="detailed-service-listing-team-member-wrapper">
                                  <div className="detailed-service-listing-team-member">4.9/5</div>
                                </div>
                                <div className="detailed-service-contact-role">
                                  <div className="detailed-service-supporting-text12">
                                    99,999 reviews
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="detailed-service-listing">
                            <div className="detailed-service-breadcrumb-items">
                              <div className="detailed-service-listing-short-info">$9,999,999.99</div>
                              <div className="detailed-service-supporting-text-wrapper">
                                <div className="detailed-service-supporting-text13">USD-Per Unit</div>
                              </div>
                            </div>
                          </div>
                          <img
                            className="detailed-service-divider-icon"
                            alt=""
                            src={divider4}
                          />

                          <div className="detailed-service-listing-detail-item-value-inner">
                            <div className="detailed-service-footer-social-parent">
                              <div className="detailed-service-footer-social">
                                <img
                                  className="detailed-service-footer-social-child"
                                  alt=""
                                  src={group_58_2x}
                                />

                                <div className="detailed-service-listing-short-detail-item-pin-parent">
                                  <div className="detailed-service-listing-short-detail-item-pin">
                                    <div className="detailed-service-name">Olivia Rhye</div>
                                  </div>
                                  <div className="detailed-service-role1">Business Name</div>
                                </div>
                              </div>
                              <div className="detailed-service-listing-short-detail-item-date">
                                <img
                                  className="detailed-service-icon1"
                                  alt=""
                                  src={icon1}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="detailed-service-rectangle-parent10">
                    <div className="detailed-service-frame-child12"></div>
                    <div className="detailed-service-listing-card-badge-overlay">
                      <img
                        className="detailed-service-listing-card-badge-overlay-child"
                        alt=""
                        src={rectangle_95_2x}
                      />

                      <div className="detailed-service-image-overlay">
                        <div className="detailed-service-badge4">
                          <div className="detailed-service-listing-card-price">Assured</div>
                        </div>
                        <div className="detailed-service-short-listing-team">
                          <div className="detailed-service-badge5">
                            <div className="detailed-service-listing-card-radar">ID# AXJYN</div>
                          </div>
                          <div className="detailed-service-buttonsbutton11">
                            <img
                              className="detailed-service-magnifier-1-icon"
                              alt=""
                              src={radar_1_1_2x}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="detailed-service-listing-detail-item">
                      <div className="detailed-service-message-content">
                        <div className="detailed-service-text-frame">
                          <div className="detailed-service-text42">
                            New / Surplus 2021 Philips Health-care HD11 XE
                            (P/N:23A...
                          </div>
                        </div>
                        <div className="detailed-service-listing-detail-item-value">
                          <div className="detailed-service-listing-detail-item-pin-wrapper">
                            <div className="detailed-service-heading-container">
                              <div className="detailed-service-listing-detail-item-location">
                                <div className="detailed-service-listing-detail-item-date-parent">
                                  <div className="detailed-service-listing-detail-item-date">
                                    <img
                                      className="detailed-service-chevron-right-icon2"
                                      alt=""
                                      src={markerpin021}
                                    />
                                  </div>
                                  <div className="detailed-service-location">India, New Delhi</div>
                                </div>
                                <div className="detailed-service-listing-detail-item-date-parent">
                                  <div className="detailed-service-listing-detail-item-date">
                                    <img
                                      className="detailed-service-chevron-right-icon2"
                                      alt=""
                                      src={calendar1}
                                    />
                                  </div>
                                  <div className="detailed-service-type-of-work">April 09, 2023</div>
                                </div>
                              </div>
                              <div className="detailed-service-listing-team">
                                <div className="detailed-service-listing-team-member-wrapper">
                                  <div className="detailed-service-listing-team-member">4.9/5</div>
                                </div>
                                <div className="detailed-service-contact-role">
                                  <div className="detailed-service-supporting-text12">
                                    99,999 reviews
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="detailed-service-listing">
                            <div className="detailed-service-breadcrumb-items">
                              <div className="detailed-service-listing-short-info">$9,999,999.99</div>
                              <div className="detailed-service-supporting-text-wrapper">
                                <div className="detailed-service-supporting-text13">USD-Per Unit</div>
                              </div>
                            </div>
                          </div>
                          <img
                            className="detailed-service-divider-icon"
                            alt=""
                            src={divider4}
                          />

                          <div className="detailed-service-listing-detail-item-value-inner">
                            <div className="detailed-service-footer-social-parent">
                              <div className="detailed-service-footer-social">
                                <img
                                  className="detailed-service-footer-social-child"
                                  alt=""
                                  src={group_58_2x}
                                />

                                <div className="detailed-service-listing-short-detail-item-pin-parent">
                                  <div className="detailed-service-listing-short-detail-item-pin">
                                    <div className="detailed-service-name">Olivia Rhye</div>
                                  </div>
                                  <div className="detailed-service-role1">Business Name</div>
                                </div>
                              </div>
                              <div className="detailed-service-listing-short-detail-item-date">
                                <img
                                  className="detailed-service-icon1"
                                  alt=""
                                  src={icon1}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="detailed-service-rectangle-parent10">
                    <div className="detailed-service-frame-child12"></div>
                    <div className="detailed-service-listing-card-badge-overlay">
                      <img
                        className="detailed-service-listing-card-badge-overlay-child"
                        alt=""
                        src={rectangle_95_2x}
                      />

                      <div className="detailed-service-image-overlay3">
                        <div className="detailed-service-badge4">
                          <div className="detailed-service-listing-card-price">Assured</div>
                        </div>
                        <div className="detailed-service-badge-group">
                          <div className="detailed-service-badge5">
                            <div className="detailed-service-listing-card-radar">ID# AXJYN</div>
                          </div>
                          <div className="detailed-service-buttonsbutton14">
                            <img
                              className="detailed-service-magnifier-1-icon"
                              alt=""
                              src={radar_1_1_2x}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="detailed-service-listing-detail-item">
                      <div className="detailed-service-message-content">
                        <div className="detailed-service-text-frame">
                          <div className="detailed-service-text42">
                            New / Surplus 2021 Philips Health-care HD11 XE
                            (P/N:23A...
                          </div>
                        </div>
                        <div className="detailed-service-work-four-wrapper">
                          <div className="detailed-service-heading-container">
                            <div className="detailed-service-listing-detail-item-location">
                              <div className="detailed-service-listing-detail-item-date-parent">
                                <div className="detailed-service-listing-detail-item-date">
                                  <img
                                    className="detailed-service-chevron-right-icon2"
                                    alt=""
                                    src={markerpin021}
                                  />
                                </div>
                                <div className="detailed-service-location">India, New Delhi</div>
                              </div>
                              <div className="detailed-service-listing-detail-item-date-parent">
                                <div className="detailed-service-listing-detail-item-date">
                                  <img
                                    className="detailed-service-chevron-right-icon2"
                                    alt=""
                                    src={calendar1}
                                  />
                                </div>
                                <div className="detailed-service-type-of-work">April 09, 2023</div>
                              </div>
                            </div>
                            <div className="detailed-service-listing-team">
                              <div className="detailed-service-listing-team-member-wrapper">
                                <div className="detailed-service-listing-team-member">4.9/5</div>
                              </div>
                              <div className="detailed-service-contact-role">
                                <div className="detailed-service-supporting-text12">99,999 reviews</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="detailed-service-agent-details-icon">
                          <img className="detailed-service-icon4" alt="" src={icon1} />

                          <div className="detailed-service-agent-extra-info">
                            <div className="detailed-service-listing-detail-item-pin-wrapper">
                              <div className="detailed-service-breadcrumb-items">
                                <div className="detailed-service-listing-short-info">$9,999,999.99</div>
                                <div className="detailed-service-supporting-text-wrapper">
                                  <div className="detailed-service-supporting-text13">USD-Per Unit</div>
                                </div>
                              </div>
                            </div>
                            <img
                              className="detailed-service-divider-icon"
                              alt=""
                              src={divider4}
                            />

                            <div className="detailed-service-another-agent-info">
                              <div className="detailed-service-detail-items">
                                <img
                                  className="detailed-service-footer-social-child"
                                  alt=""
                                  src={group_58_2x}
                                />

                                <div className="detailed-service-listing-short-detail-item-pin-parent">
                                  <div className="detailed-service-listing-short-detail-item-pin">
                                    <div className="detailed-service-name">Olivia Rhye</div>
                                  </div>
                                  <div className="detailed-service-role1">Business Name</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="detailed-service-testiomonial-carousel-arrow2">
                    <img
                      className="detailed-service-arrow-left-icon"
                      alt=""
                      src={arrowleft}
                    />
                  </div>
                </div>
                <div className="detailed-service-testiomonial-carousel-arrow3">
                  <img
                    className="detailed-service-arrow-left-icon"
                    alt=""
                    src={arrowright}
                  />
                </div>
              </div>
            </div>
            <div className="detailed-service-listing-image-grid4">
              <div className="detailed-service-heading-parent3">
                <div className="detailed-service-heading36">Related Spares</div>
                <div className="detailed-service-testimonial-description">
                  <div className="detailed-service-description">View All</div>
                </div>
              </div>
            </div>
            <div className="detailed-service-listing-image-grid5">
              <div className="detailed-service-frame-parent4">
                <div className="detailed-service-frame-parent5">
                  <div className="detailed-service-rectangle-parent10">
                    <div className="detailed-service-frame-child12"></div>
                    <div className="detailed-service-listing-card-badge-overlay">
                      <img
                        className="detailed-service-listing-card-badge-overlay-child"
                        alt=""
                        src={rectangle_95_2x}
                      />

                      <div className="detailed-service-image-overlay">
                        <div className="detailed-service-badge4">
                          <div className="detailed-service-listing-card-price">Assured</div>
                        </div>
                        <div className="detailed-service-short-listing-team">
                          <div className="detailed-service-badge5">
                            <div className="detailed-service-listing-card-radar">ID# AXJYN</div>
                          </div>
                          <div className="detailed-service-buttonsbutton11">
                            <img
                              className="detailed-service-magnifier-1-icon"
                              alt=""
                              src={radar_1_1_2x}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="detailed-service-listing-detail-item">
                      <div className="detailed-service-message-content">
                        <div className="detailed-service-text-frame">
                          <div className="detailed-service-text42">
                            New / Surplus 2021 Philips Health-care HD11 XE
                            (P/N:23A...
                          </div>
                        </div>
                        <div className="detailed-service-listing-detail-item-value">
                          <div className="detailed-service-listing-detail-item-pin-wrapper">
                            <div className="detailed-service-heading-container">
                              <div className="detailed-service-listing-detail-item-location">
                                <div className="detailed-service-listing-detail-item-date-parent">
                                  <div className="detailed-service-listing-detail-item-date">
                                    <img
                                      className="detailed-service-chevron-right-icon2"
                                      alt=""
                                      src={markerpin021}
                                    />
                                  </div>
                                  <div className="detailed-service-location">India, New Delhi</div>
                                </div>
                                <div className="detailed-service-listing-detail-item-date-parent">
                                  <div className="detailed-service-listing-detail-item-date">
                                    <img
                                      className="detailed-service-chevron-right-icon2"
                                      alt=""
                                      src={calendar1}
                                    />
                                  </div>
                                  <div className="detailed-service-type-of-work">April 09, 2023</div>
                                </div>
                              </div>
                              <div className="detailed-service-listing-team">
                                <div className="detailed-service-listing-team-member-wrapper">
                                  <div className="detailed-service-listing-team-member">4.9/5</div>
                                </div>
                                <div className="detailed-service-contact-role">
                                  <div className="detailed-service-supporting-text12">
                                    99,999 reviews
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="detailed-service-listing">
                            <div className="detailed-service-breadcrumb-items">
                              <div className="detailed-service-listing-short-info">$9,999,999.99</div>
                              <div className="detailed-service-supporting-text-wrapper">
                                <div className="detailed-service-supporting-text13">USD-Per Unit</div>
                              </div>
                            </div>
                          </div>
                          <img
                            className="detailed-service-divider-icon"
                            alt=""
                            src={divider4}
                          />

                          <div className="detailed-service-listing-detail-item-value-inner">
                            <div className="detailed-service-footer-social-parent">
                              <div className="detailed-service-footer-social">
                                <img
                                  className="detailed-service-footer-social-child"
                                  alt=""
                                  src={group_58_2x}
                                />

                                <div className="detailed-service-listing-short-detail-item-pin-parent">
                                  <div className="detailed-service-listing-short-detail-item-pin">
                                    <div className="detailed-service-name">Olivia Rhye</div>
                                  </div>
                                  <div className="detailed-service-role1">Business Name</div>
                                </div>
                              </div>
                              <div className="detailed-service-listing-short-detail-item-date">
                                <img
                                  className="detailed-service-icon1"
                                  alt=""
                                  src={icon1}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="detailed-service-rectangle-parent10">
                    <div className="detailed-service-frame-child12"></div>
                    <div className="detailed-service-listing-card-badge-overlay">
                      <img
                        className="detailed-service-listing-card-badge-overlay-child"
                        alt=""
                        src={rectangle_95_2x}
                      />

                      <div className="detailed-service-image-overlay">
                        <div className="detailed-service-badge4">
                          <div className="detailed-service-listing-card-price">Assured</div>
                        </div>
                        <div className="detailed-service-short-listing-team">
                          <div className="detailed-service-badge5">
                            <div className="detailed-service-listing-card-radar">ID# AXJYN</div>
                          </div>
                          <div className="detailed-service-buttonsbutton11">
                            <img
                              className="detailed-service-magnifier-1-icon"
                              alt=""
                              src={radar_1_1_2x}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="detailed-service-listing-detail-item">
                      <div className="detailed-service-message-content">
                        <div className="detailed-service-text-frame">
                          <div className="detailed-service-text42">
                            New / Surplus 2021 Philips Health-care HD11 XE
                            (P/N:23A...
                          </div>
                        </div>
                        <div className="detailed-service-listing-detail-item-value">
                          <div className="detailed-service-listing-detail-item-pin-wrapper">
                            <div className="detailed-service-heading-container">
                              <div className="detailed-service-listing-detail-item-location">
                                <div className="detailed-service-listing-detail-item-date-parent">
                                  <div className="detailed-service-listing-detail-item-date">
                                    <img
                                      className="detailed-service-chevron-right-icon2"
                                      alt=""
                                      src={markerpin021}
                                    />
                                  </div>
                                  <div className="detailed-service-location">India, New Delhi</div>
                                </div>
                                <div className="detailed-service-listing-detail-item-date-parent">
                                  <div className="detailed-service-listing-detail-item-date">
                                    <img
                                      className="detailed-service-chevron-right-icon2"
                                      alt=""
                                      src={calendar1}
                                    />
                                  </div>
                                  <div className="detailed-service-type-of-work">April 09, 2023</div>
                                </div>
                              </div>
                              <div className="detailed-service-listing-team">
                                <div className="detailed-service-listing-team-member-wrapper">
                                  <div className="detailed-service-listing-team-member">4.9/5</div>
                                </div>
                                <div className="detailed-service-contact-role">
                                  <div className="detailed-service-supporting-text12">
                                    99,999 reviews
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="detailed-service-listing">
                            <div className="detailed-service-breadcrumb-items">
                              <div className="detailed-service-listing-short-info">$9,999,999.99</div>
                              <div className="detailed-service-supporting-text-wrapper">
                                <div className="detailed-service-supporting-text13">USD-Per Unit</div>
                              </div>
                            </div>
                          </div>
                          <img
                            className="detailed-service-divider-icon"
                            alt=""
                            src={divider4}
                          />

                          <div className="detailed-service-listing-detail-item-value-inner">
                            <div className="detailed-service-footer-social-parent">
                              <div className="detailed-service-footer-social">
                                <img
                                  className="detailed-service-footer-social-child"
                                  alt=""
                                  src={group_58_2x}
                                />

                                <div className="detailed-service-listing-short-detail-item-pin-parent">
                                  <div className="detailed-service-listing-short-detail-item-pin">
                                    <div className="detailed-service-name">Olivia Rhye</div>
                                  </div>
                                  <div className="detailed-service-role1">Business Name</div>
                                </div>
                              </div>
                              <div className="detailed-service-listing-short-detail-item-date">
                                <img
                                  className="detailed-service-icon1"
                                  alt=""
                                  src={icon1}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="detailed-service-rectangle-parent10">
                    <div className="detailed-service-frame-child12"></div>
                    <div className="detailed-service-listing-card-badge-overlay">
                      <img
                        className="detailed-service-listing-card-badge-overlay-child"
                        alt=""
                        src={rectangle_95_2x}
                      />

                      <div className="detailed-service-image-overlay">
                        <div className="detailed-service-badge4">
                          <div className="detailed-service-listing-card-price">Assured</div>
                        </div>
                        <div className="detailed-service-short-listing-team">
                          <div className="detailed-service-badge5">
                            <div className="detailed-service-listing-card-radar">ID# AXJYN</div>
                          </div>
                          <div className="detailed-service-buttonsbutton11">
                            <img
                              className="detailed-service-magnifier-1-icon"
                              alt=""
                              src={radar_1_1_2x}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="detailed-service-listing-detail-item">
                      <div className="detailed-service-message-content">
                        <div className="detailed-service-text-frame">
                          <div className="detailed-service-text42">
                            New / Surplus 2021 Philips Health-care HD11 XE
                            (P/N:23A...
                          </div>
                        </div>
                        <div className="detailed-service-listing-detail-item-value">
                          <div className="detailed-service-listing-detail-item-pin-wrapper">
                            <div className="detailed-service-heading-container">
                              <div className="detailed-service-listing-detail-item-location">
                                <div className="detailed-service-listing-detail-item-date-parent">
                                  <div className="detailed-service-listing-detail-item-date">
                                    <img
                                      className="detailed-service-chevron-right-icon2"
                                      alt=""
                                      src={markerpin021}
                                    />
                                  </div>
                                  <div className="detailed-service-location">India, New Delhi</div>
                                </div>
                                <div className="detailed-service-listing-detail-item-date-parent">
                                  <div className="detailed-service-listing-detail-item-date">
                                    <img
                                      className="detailed-service-chevron-right-icon2"
                                      alt=""
                                      src={calendar1}
                                    />
                                  </div>
                                  <div className="detailed-service-type-of-work">April 09, 2023</div>
                                </div>
                              </div>
                              <div className="detailed-service-listing-team">
                                <div className="detailed-service-listing-team-member-wrapper">
                                  <div className="detailed-service-listing-team-member">4.9/5</div>
                                </div>
                                <div className="detailed-service-contact-role">
                                  <div className="detailed-service-supporting-text12">
                                    99,999 reviews
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="detailed-service-listing">
                            <div className="detailed-service-breadcrumb-items">
                              <div className="detailed-service-listing-short-info">$9,999,999.99</div>
                              <div className="detailed-service-supporting-text-wrapper">
                                <div className="detailed-service-supporting-text13">USD-Per Unit</div>
                              </div>
                            </div>
                          </div>
                          <img
                            className="detailed-service-divider-icon"
                            alt=""
                            src={divider4}
                          />

                          <div className="detailed-service-listing-detail-item-value-inner">
                            <div className="detailed-service-footer-social-parent">
                              <div className="detailed-service-footer-social">
                                <img
                                  className="detailed-service-footer-social-child"
                                  alt=""
                                  src={group_58_2x}
                                />

                                <div className="detailed-service-listing-short-detail-item-pin-parent">
                                  <div className="detailed-service-listing-short-detail-item-pin">
                                    <div className="detailed-service-name">Olivia Rhye</div>
                                  </div>
                                  <div className="detailed-service-role1">Business Name</div>
                                </div>
                              </div>
                              <div className="detailed-service-listing-short-detail-item-date">
                                <img
                                  className="detailed-service-icon1"
                                  alt=""
                                  src={icon1}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="detailed-service-rectangle-parent10">
                    <div className="detailed-service-frame-child12"></div>
                    <div className="detailed-service-listing-card-badge-overlay">
                      <img
                        className="detailed-service-listing-card-badge-overlay-child"
                        alt=""
                        src={rectangle_95_2x}
                      />

                      <div className="detailed-service-image-overlay3">
                        <div className="detailed-service-badge4">
                          <div className="detailed-service-listing-card-price">Assured</div>
                        </div>
                        <div className="detailed-service-badge-group">
                          <div className="detailed-service-badge5">
                            <div className="detailed-service-listing-card-radar">ID# AXJYN</div>
                          </div>
                          <div className="detailed-service-buttonsbutton14">
                            <img
                              className="detailed-service-magnifier-1-icon"
                              alt=""
                              src={radar_1_1_2x}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="detailed-service-listing-detail-item">
                      <div className="detailed-service-message-content">
                        <div className="detailed-service-text-frame">
                          <div className="detailed-service-text42">
                            New / Surplus 2021 Philips Health-care HD11 XE
                            (P/N:23A...
                          </div>
                        </div>
                        <div className="detailed-service-work-four-wrapper">
                          <div className="detailed-service-heading-container">
                            <div className="detailed-service-listing-detail-item-location">
                              <div className="detailed-service-listing-detail-item-date-parent">
                                <div className="detailed-service-listing-detail-item-date">
                                  <img
                                    className="detailed-service-chevron-right-icon2"
                                    alt=""
                                    src={markerpin021}
                                  />
                                </div>
                                <div className="detailed-service-location">India, New Delhi</div>
                              </div>
                              <div className="detailed-service-listing-detail-item-date-parent">
                                <div className="detailed-service-listing-detail-item-date">
                                  <img
                                    className="detailed-service-chevron-right-icon2"
                                    alt=""
                                    src={calendar1}
                                  />
                                </div>
                                <div className="detailed-service-type-of-work">April 09, 2023</div>
                              </div>
                            </div>
                            <div className="detailed-service-listing-team">
                              <div className="detailed-service-listing-team-member-wrapper">
                                <div className="detailed-service-listing-team-member">4.9/5</div>
                              </div>
                              <div className="detailed-service-contact-role">
                                <div className="detailed-service-supporting-text12">99,999 reviews</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="detailed-service-agent-details-icon">
                          <img className="detailed-service-icon4" alt="" src={icon1} />

                          <div className="detailed-service-agent-extra-info">
                            <div className="detailed-service-listing-detail-item-pin-wrapper">
                              <div className="detailed-service-breadcrumb-items">
                                <div className="detailed-service-listing-short-info">$9,999,999.99</div>
                                <div className="detailed-service-supporting-text-wrapper">
                                  <div className="detailed-service-supporting-text13">USD-Per Unit</div>
                                </div>
                              </div>
                            </div>
                            <img
                              className="detailed-service-divider-icon"
                              alt=""
                              src={divider4}
                            />

                            <div className="detailed-service-another-agent-info">
                              <div className="detailed-service-detail-items">
                                <img
                                  className="detailed-service-footer-social-child"
                                  alt=""
                                  src={group_58_2x}
                                />

                                <div className="detailed-service-listing-short-detail-item-pin-parent">
                                  <div className="detailed-service-listing-short-detail-item-pin">
                                    <div className="detailed-service-name">Olivia Rhye</div>
                                  </div>
                                  <div className="detailed-service-role1">Business Name</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="detailed-service-testiomonial-carousel-arrow4">
                    <img
                      className="detailed-service-arrow-left-icon"
                      alt=""
                      src={arrowleft}
                    />
                  </div>
                </div>
                <div className="detailed-service-testiomonial-carousel-arrow5">
                  <img
                    className="detailed-service-arrow-left-icon"
                    alt=""
                    src={arrowright}
                  />
                </div>
              </div>
            </div>
            <div className="detailed-service-listing-image-grid4">
              <div className="detailed-service-heading-parent3">
                <div className="detailed-service-heading38">Related Services</div>
                <div className="detailed-service-testimonial-description">
                  <div className="detailed-service-description">View All</div>
                </div>
              </div>
            </div>
            <div className="detailed-service-listing-image-grid5">
              <div className="detailed-service-frame-parent4">
                <div className="detailed-service-frame-parent5">
                  <div className="detailed-service-rectangle-parent10">
                    <div className="detailed-service-frame-child12"></div>
                    <div className="detailed-service-listing-card-badge-overlay">
                      <img
                        className="detailed-service-listing-card-badge-overlay-child"
                        alt=""
                        src={rectangle_95_2x}
                      />

                      <div className="detailed-service-image-overlay">
                        <div className="detailed-service-badge4">
                          <div className="detailed-service-listing-card-price">Assured</div>
                        </div>
                        <div className="detailed-service-short-listing-team">
                          <div className="detailed-service-badge5">
                            <div className="detailed-service-listing-card-radar">ID# AXJYN</div>
                          </div>
                          <div className="detailed-service-buttonsbutton11">
                            <img
                              className="detailed-service-magnifier-1-icon"
                              alt=""
                              src={radar_1_1_2x}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="detailed-service-listing-detail-item">
                      <div className="detailed-service-message-content">
                        <div className="detailed-service-text-frame">
                          <div className="detailed-service-text42">
                            New / Surplus 2021 Philips Health-care HD11 XE
                            (P/N:23A...
                          </div>
                        </div>
                        <div className="detailed-service-listing-detail-item-value">
                          <div className="detailed-service-listing-detail-item-pin-wrapper">
                            <div className="detailed-service-heading-container">
                              <div className="detailed-service-listing-detail-item-location">
                                <div className="detailed-service-listing-detail-item-date-parent">
                                  <div className="detailed-service-listing-detail-item-date">
                                    <img
                                      className="detailed-service-chevron-right-icon2"
                                      alt=""
                                      src={markerpin021}
                                    />
                                  </div>
                                  <div className="detailed-service-location">India, New Delhi</div>
                                </div>
                                <div className="detailed-service-listing-detail-item-date-parent">
                                  <div className="detailed-service-listing-detail-item-date">
                                    <img
                                      className="detailed-service-chevron-right-icon2"
                                      alt=""
                                      src={calendar1}
                                    />
                                  </div>
                                  <div className="detailed-service-type-of-work">April 09, 2023</div>
                                </div>
                              </div>
                              <div className="detailed-service-listing-team">
                                <div className="detailed-service-listing-team-member-wrapper">
                                  <div className="detailed-service-listing-team-member">4.9/5</div>
                                </div>
                                <div className="detailed-service-contact-role">
                                  <div className="detailed-service-supporting-text12">
                                    99,999 reviews
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="detailed-service-listing">
                            <div className="detailed-service-breadcrumb-items">
                              <div className="detailed-service-listing-short-info">$9,999,999.99</div>
                              <div className="detailed-service-supporting-text-wrapper">
                                <div className="detailed-service-supporting-text13">USD-Per Unit</div>
                              </div>
                            </div>
                          </div>
                          <img
                            className="detailed-service-divider-icon"
                            alt=""
                            src={divider4}
                          />

                          <div className="detailed-service-listing-detail-item-value-inner">
                            <div className="detailed-service-footer-social-parent">
                              <div className="detailed-service-footer-social">
                                <img
                                  className="detailed-service-footer-social-child"
                                  alt=""
                                  src={group_58_2x}
                                />

                                <div className="detailed-service-listing-short-detail-item-pin-parent">
                                  <div className="detailed-service-listing-short-detail-item-pin">
                                    <div className="detailed-service-name">Olivia Rhye</div>
                                  </div>
                                  <div className="detailed-service-role1">Business Name</div>
                                </div>
                              </div>
                              <div className="detailed-service-listing-short-detail-item-date">
                                <img
                                  className="detailed-service-icon1"
                                  alt=""
                                  src={icon1}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="detailed-service-rectangle-parent10">
                    <div className="detailed-service-frame-child12"></div>
                    <div className="detailed-service-listing-card-badge-overlay">
                      <img
                        className="detailed-service-listing-card-badge-overlay-child"
                        alt=""
                        src={rectangle_95_2x}
                      />

                      <div className="detailed-service-image-overlay">
                        <div className="detailed-service-badge4">
                          <div className="detailed-service-listing-card-price">Assured</div>
                        </div>
                        <div className="detailed-service-short-listing-team">
                          <div className="detailed-service-badge5">
                            <div className="detailed-service-listing-card-radar">ID# AXJYN</div>
                          </div>
                          <div className="detailed-service-buttonsbutton11">
                            <img
                              className="detailed-service-magnifier-1-icon"
                              alt=""
                              src={radar_1_1_2x}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="detailed-service-listing-detail-item">
                      <div className="detailed-service-message-content">
                        <div className="detailed-service-text-frame">
                          <div className="detailed-service-text42">
                            New / Surplus 2021 Philips Health-care HD11 XE
                            (P/N:23A...
                          </div>
                        </div>
                        <div className="detailed-service-listing-detail-item-value">
                          <div className="detailed-service-listing-detail-item-pin-wrapper">
                            <div className="detailed-service-heading-container">
                              <div className="detailed-service-listing-detail-item-location">
                                <div className="detailed-service-listing-detail-item-date-parent">
                                  <div className="detailed-service-listing-detail-item-date">
                                    <img
                                      className="detailed-service-chevron-right-icon2"
                                      alt=""
                                      src={markerpin021}
                                    />
                                  </div>
                                  <div className="detailed-service-location">India, New Delhi</div>
                                </div>
                                <div className="detailed-service-listing-detail-item-date-parent">
                                  <div className="detailed-service-listing-detail-item-date">
                                    <img
                                      className="detailed-service-chevron-right-icon2"
                                      alt=""
                                      src={calendar1}
                                    />
                                  </div>
                                  <div className="detailed-service-type-of-work">April 09, 2023</div>
                                </div>
                              </div>
                              <div className="detailed-service-listing-team">
                                <div className="detailed-service-listing-team-member-wrapper">
                                  <div className="detailed-service-listing-team-member">4.9/5</div>
                                </div>
                                <div className="detailed-service-contact-role">
                                  <div className="detailed-service-supporting-text12">
                                    99,999 reviews
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="detailed-service-listing">
                            <div className="detailed-service-breadcrumb-items">
                              <div className="detailed-service-listing-short-info">$9,999,999.99</div>
                              <div className="detailed-service-supporting-text-wrapper">
                                <div className="detailed-service-supporting-text13">USD-Per Unit</div>
                              </div>
                            </div>
                          </div>
                          <img
                            className="detailed-service-divider-icon"
                            alt=""
                            src={divider4}
                          />

                          <div className="detailed-service-listing-detail-item-value-inner">
                            <div className="detailed-service-footer-social-parent">
                              <div className="detailed-service-footer-social">
                                <img
                                  className="detailed-service-footer-social-child"
                                  alt=""
                                  src={group_58_2x}
                                />

                                <div className="detailed-service-listing-short-detail-item-pin-parent">
                                  <div className="detailed-service-listing-short-detail-item-pin">
                                    <div className="detailed-service-name">Olivia Rhye</div>
                                  </div>
                                  <div className="detailed-service-role1">Business Name</div>
                                </div>
                              </div>
                              <div className="detailed-service-listing-short-detail-item-date">
                                <img
                                  className="detailed-service-icon1"
                                  alt=""
                                  src={icon1}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="detailed-service-rectangle-parent10">
                    <div className="detailed-service-frame-child12"></div>
                    <div className="detailed-service-listing-card-badge-overlay">
                      <img
                        className="detailed-service-listing-card-badge-overlay-child"
                        alt=""
                        src={rectangle_95_2x}
                      />

                      <div className="detailed-service-image-overlay">
                        <div className="detailed-service-badge4">
                          <div className="detailed-service-listing-card-price">Assured</div>
                        </div>
                        <div className="detailed-service-short-listing-team">
                          <div className="detailed-service-badge5">
                            <div className="detailed-service-listing-card-radar">ID# AXJYN</div>
                          </div>
                          <div className="detailed-service-buttonsbutton11">
                            <img
                              className="detailed-service-magnifier-1-icon"
                              alt=""
                              src={radar_1_1_2x}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="detailed-service-listing-detail-item">
                      <div className="detailed-service-message-content">
                        <div className="detailed-service-text-frame">
                          <div className="detailed-service-text42">
                            New / Surplus 2021 Philips Health-care HD11 XE
                            (P/N:23A...
                          </div>
                        </div>
                        <div className="detailed-service-listing-detail-item-value">
                          <div className="detailed-service-listing-detail-item-pin-wrapper">
                            <div className="detailed-service-heading-container">
                              <div className="detailed-service-listing-detail-item-location">
                                <div className="detailed-service-listing-detail-item-date-parent">
                                  <div className="detailed-service-listing-detail-item-date">
                                    <img
                                      className="detailed-service-chevron-right-icon2"
                                      alt=""
                                      src={markerpin021}
                                    />
                                  </div>
                                  <div className="detailed-service-location">India, New Delhi</div>
                                </div>
                                <div className="detailed-service-listing-detail-item-date-parent">
                                  <div className="detailed-service-listing-detail-item-date">
                                    <img
                                      className="detailed-service-chevron-right-icon2"
                                      alt=""
                                      src={calendar1}
                                    />
                                  </div>
                                  <div className="detailed-service-type-of-work">April 09, 2023</div>
                                </div>
                              </div>
                              <div className="detailed-service-listing-team">
                                <div className="detailed-service-listing-team-member-wrapper">
                                  <div className="detailed-service-listing-team-member">4.9/5</div>
                                </div>
                                <div className="detailed-service-contact-role">
                                  <div className="detailed-service-supporting-text12">
                                    99,999 reviews
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="detailed-service-listing">
                            <div className="detailed-service-breadcrumb-items">
                              <div className="detailed-service-listing-short-info">$9,999,999.99</div>
                              <div className="detailed-service-supporting-text-wrapper">
                                <div className="detailed-service-supporting-text13">USD-Per Unit</div>
                              </div>
                            </div>
                          </div>
                          <img
                            className="detailed-service-divider-icon"
                            alt=""
                            src={divider4}
                          />

                          <div className="detailed-service-listing-detail-item-value-inner">
                            <div className="detailed-service-footer-social-parent">
                              <div className="detailed-service-footer-social">
                                <img
                                  className="detailed-service-footer-social-child"
                                  alt=""
                                  src={group_58_2x}
                                />

                                <div className="detailed-service-listing-short-detail-item-pin-parent">
                                  <div className="detailed-service-listing-short-detail-item-pin">
                                    <div className="detailed-service-name">Olivia Rhye</div>
                                  </div>
                                  <div className="detailed-service-role1">Business Name</div>
                                </div>
                              </div>
                              <div className="detailed-service-listing-short-detail-item-date">
                                <img
                                  className="detailed-service-icon1"
                                  alt=""
                                  src={icon1}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="detailed-service-rectangle-parent10">
                    <div className="detailed-service-frame-child12"></div>
                    <div className="detailed-service-rectangle-parent30">
                      <img
                        className="detailed-service-listing-card-badge-overlay-child"
                        alt=""
                        src={rectangle_95_2x}
                      />

                      <div className="detailed-service-image-overlay3">
                        <div className="detailed-service-badge4">
                          <div className="detailed-service-listing-card-price">Assured</div>
                        </div>
                        <div className="detailed-service-badge-group">
                          <div className="detailed-service-badge5">
                            <div className="detailed-service-listing-card-radar">ID# AXJYN</div>
                          </div>
                          <div className="detailed-service-buttonsbutton14">
                            <img
                              className="detailed-service-magnifier-1-icon"
                              alt=""
                              src={radar_1_1_2x}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="detailed-service-listing-detail-item">
                      <div className="detailed-service-message-content">
                        <div className="detailed-service-text-frame">
                          <div className="detailed-service-text42">
                            New / Surplus 2021 Philips Health-care HD11 XE
                            (P/N:23A...
                          </div>
                        </div>
                        <div className="detailed-service-icon-group">
                          <img className="detailed-service-icon4" alt="" src={icon1} />

                          <div className="detailed-service-message-content">
                            <div className="detailed-service-listing-detail-item-pin-wrapper">
                              <div className="detailed-service-listing-detail-item-location">
                                <div className="detailed-service-listing-detail-item-date-parent">
                                  <div className="detailed-service-listing-detail-item-date">
                                    <img
                                      className="detailed-service-chevron-right-icon2"
                                      alt=""
                                      src={markerpin021}
                                    />
                                  </div>
                                  <div className="detailed-service-location">India, New Delhi</div>
                                </div>
                                <div className="detailed-service-listing-detail-item-date-parent">
                                  <div className="detailed-service-listing-detail-item-date">
                                    <img
                                      className="detailed-service-chevron-right-icon2"
                                      alt=""
                                      src={calendar1}
                                    />
                                  </div>
                                  <div className="detailed-service-type-of-work">April 09, 2023</div>
                                </div>
                              </div>
                            </div>
                            <div className="detailed-service-supporting-work-five">
                              <div className="detailed-service-breadcrumb-items">
                                <div className="detailed-service-listing-team-member-wrapper">
                                  <div className="detailed-service-listing-team-member">4.9/5</div>
                                </div>
                                <div className="detailed-service-contact-role">
                                  <div className="detailed-service-supporting-text12">
                                    99,999 reviews
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="detailed-service-supporting-six-wrapper">
                              <div className="detailed-service-breadcrumb-items">
                                <div className="detailed-service-listing-short-info">$9,999,999.99</div>
                                <div className="detailed-service-supporting-text-wrapper">
                                  <div className="detailed-service-supporting-text13">USD-Per Unit</div>
                                </div>
                              </div>
                            </div>
                            <div className="detailed-service-divider-container">
                              <img
                                className="detailed-service-divider-icon3"
                                alt=""
                                src={divider4}
                              />
                            </div>
                            <div className="detailed-service-team-info-four">
                              <div className="detailed-service-detail-items">
                                <img
                                  className="detailed-service-footer-social-child"
                                  alt=""
                                  src={group_58_2x}
                                />

                                <div className="detailed-service-listing-short-detail-item-pin-parent">
                                  <div className="detailed-service-listing-short-detail-item-pin">
                                    <div className="detailed-service-name">Olivia Rhye</div>
                                  </div>
                                  <div className="detailed-service-role1">Business Name</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="detailed-service-testiomonial-carousel-arrow6">
                    <img
                      className="detailed-service-arrow-left-icon"
                      alt=""
                      src={arrowleft}
                    />
                  </div>
                </div>
                <div className="detailed-service-testiomonial-carousel-arrow7">
                  <img
                    className="detailed-service-arrow-left-icon"
                    alt=""
                    src={arrowright}
                  />
                </div>
              </div>
            </div>


            <img className="detailed-service-divider-icon24" alt="" src="/detailed_listing/divider5.svg" />

            {/* <div className="detailed-service-actions1">
          <div className="detailed-service-button2">
            <img
              className="detailed-service-placeholder-icon17"
              alt=""
              src="/detailed_listing/placeholder3.svg"
            />
  
            <div className="detailed-service-text-padding4">
              <div className="detailed-service-text98">Tertiary</div>
            </div>
            <img
              className="detailed-service-placeholder-icon17"
              alt=""
              src="/detailed_listing/placeholder3.svg"
            />
          </div>
          <div className="detailed-service-button3">
            <img
              className="detailed-service-placeholder-icon17"
              alt=""
              src="/detailed_listing/placeholder4.svg"
            />
  
            <div className="detailed-service-text-padding5">
              <div className="detailed-service-text98">Secondary</div>
            </div>
            <img
              className="detailed-service-placeholder-icon17"
              alt=""
              src="/detailed_listing/placeholder4.svg"
            />
          </div>
          <div className="detailed-service-button4">
            <img
              className="detailed-service-placeholder-icon17"
              alt=""
              src={placeholder1}
            />
  
            <div className="detailed-service-text-padding5">
              <div className="detailed-service-text98">Secondary</div>
            </div>
            <img
              className="detailed-service-placeholder-icon17"
              alt=""
              src={placeholder1}
            />
          </div>
          <div className="detailed-service-button5">
            <img
              className="detailed-service-placeholder-icon17"
              alt=""
              src={placeholder2}
            />
  
            <div className="detailed-service-text-padding7">
              <div className="detailed-service-text98">Primary</div>
            </div>
            <img
              className="detailed-service-placeholder-icon17"
              alt=""
              src={placeholder2}
            />
          </div>
        </div> */}
            <div className="detailed-service-listing-image-grid4">
              <div className="detailed-service-heading-parent3">
                <div className="detailed-service-heading36">Related Software</div>
                <div className="detailed-service-testimonial-description">
                  <div className="detailed-service-description">View All</div>
                </div>
              </div>
            </div>
            <div className="detailed-service-listing-image-grid11">
              <div className="detailed-service-frame-parent4">
                <div className="detailed-service-frame-parent5">
                  <div className="detailed-service-rectangle-parent10">
                    <div className="detailed-service-frame-child12"></div>
                    <div className="detailed-service-rectangle-parent30">
                      <img
                        className="detailed-service-listing-card-badge-overlay-child"
                        alt=""
                        src={rectangle_95_2x}
                      />

                      <div className="detailed-service-image-overlay">
                        <div className="detailed-service-badge4">
                          <div className="detailed-service-listing-card-price">Assured</div>
                        </div>
                        <div className="detailed-service-short-listing-team">
                          <div className="detailed-service-badge5">
                            <div className="detailed-service-listing-card-radar">ID# AXJYN</div>
                          </div>
                          <div className="detailed-service-buttonsbutton11">
                            <img
                              className="detailed-service-magnifier-1-icon"
                              alt=""
                              src={radar_1_1_2x}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="detailed-service-listing-detail-item">
                      <div className="detailed-service-message-content">
                        <div className="detailed-service-text-frame">
                          <div className="detailed-service-text42">
                            New / Surplus 2021 Philips Health-care HD11 XE
                            (P/N:23A...
                          </div>
                        </div>
                        <div className="detailed-service-listing-detail-item-value">
                          <div className="detailed-service-listing-detail-item-pin-wrapper">
                            <div className="detailed-service-heading-container">
                              <div className="detailed-service-listing-detail-item-location">
                                <div className="detailed-service-listing-detail-item-date-parent">
                                  <div className="detailed-service-listing-detail-item-date">
                                    <img
                                      className="detailed-service-chevron-right-icon2"
                                      alt=""
                                      src={markerpin021}
                                    />
                                  </div>
                                  <div className="detailed-service-location">India, New Delhi</div>
                                </div>
                                <div className="detailed-service-listing-detail-item-date-parent">
                                  <div className="detailed-service-listing-detail-item-date">
                                    <img
                                      className="detailed-service-chevron-right-icon2"
                                      alt=""
                                      src={calendar1}
                                    />
                                  </div>
                                  <div className="detailed-service-type-of-work">April 09, 2023</div>
                                </div>
                              </div>
                              <div className="detailed-service-listing-team">
                                <div className="detailed-service-listing-team-member-wrapper">
                                  <div className="detailed-service-listing-team-member">4.9/5</div>
                                </div>
                                <div className="detailed-service-contact-role">
                                  <div className="detailed-service-supporting-text12">
                                    99,999 reviews
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="detailed-service-listing">
                            <div className="detailed-service-breadcrumb-items">
                              <div className="detailed-service-listing-short-info">$9,999,999.99</div>
                              <div className="detailed-service-supporting-text-wrapper">
                                <div className="detailed-service-supporting-text13">USD-Per Unit</div>
                              </div>
                            </div>
                          </div>
                          <img
                            className="detailed-service-divider-icon"
                            alt=""
                            src={divider4}
                          />

                          <div className="detailed-service-listing-detail-item-value-inner">
                            <div className="detailed-service-footer-social-parent">
                              <div className="detailed-service-footer-social">
                                <img
                                  className="detailed-service-footer-social-child"
                                  alt=""
                                  src={group_58_2x}
                                />

                                <div className="detailed-service-listing-short-detail-item-pin-parent">
                                  <div className="detailed-service-listing-short-detail-item-pin">
                                    <div className="detailed-service-name">Olivia Rhye</div>
                                  </div>
                                  <div className="detailed-service-role1">Business Name</div>
                                </div>
                              </div>
                              <div className="detailed-service-listing-short-detail-item-date">
                                <img
                                  className="detailed-service-icon1"
                                  alt=""
                                  src={icon1}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="detailed-service-rectangle-parent10">
                    <div className="detailed-service-frame-child12"></div>
                    <div className="detailed-service-rectangle-parent30">
                      <img
                        className="detailed-service-listing-card-badge-overlay-child"
                        alt=""
                        src={rectangle_95_2x}
                      />

                      <div className="detailed-service-image-overlay">
                        <div className="detailed-service-badge4">
                          <div className="detailed-service-listing-card-price">Assured</div>
                        </div>
                        <div className="detailed-service-short-listing-team">
                          <div className="detailed-service-badge5">
                            <div className="detailed-service-listing-card-radar">ID# AXJYN</div>
                          </div>
                          <div className="detailed-service-buttonsbutton11">
                            <img
                              className="detailed-service-magnifier-1-icon"
                              alt=""
                              src={radar_1_1_2x}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="detailed-service-listing-detail-item">
                      <div className="detailed-service-message-content">
                        <div className="detailed-service-text-frame">
                          <div className="detailed-service-text42">
                            New / Surplus 2021 Philips Health-care HD11 XE
                            (P/N:23A...
                          </div>
                        </div>
                        <div className="detailed-service-listing-detail-item-value">
                          <div className="detailed-service-listing-detail-item-pin-wrapper">
                            <div className="detailed-service-heading-container">
                              <div className="detailed-service-listing-detail-item-location">
                                <div className="detailed-service-listing-detail-item-date-parent">
                                  <div className="detailed-service-listing-detail-item-date">
                                    <img
                                      className="detailed-service-chevron-right-icon2"
                                      alt=""
                                      src={markerpin021}
                                    />
                                  </div>
                                  <div className="detailed-service-location">India, New Delhi</div>
                                </div>
                                <div className="detailed-service-listing-detail-item-date-parent">
                                  <div className="detailed-service-listing-detail-item-date">
                                    <img
                                      className="detailed-service-chevron-right-icon2"
                                      alt=""
                                      src={calendar1}
                                    />
                                  </div>
                                  <div className="detailed-service-type-of-work">April 09, 2023</div>
                                </div>
                              </div>
                              <div className="detailed-service-listing-team">
                                <div className="detailed-service-listing-team-member-wrapper">
                                  <div className="detailed-service-listing-team-member">4.9/5</div>
                                </div>
                                <div className="detailed-service-contact-role">
                                  <div className="detailed-service-supporting-text12">
                                    99,999 reviews
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="detailed-service-listing">
                            <div className="detailed-service-breadcrumb-items">
                              <div className="detailed-service-listing-short-info">$9,999,999.99</div>
                              <div className="detailed-service-supporting-text-wrapper">
                                <div className="detailed-service-supporting-text13">USD-Per Unit</div>
                              </div>
                            </div>
                          </div>
                          <img
                            className="detailed-service-divider-icon"
                            alt=""
                            src={divider4}
                          />

                          <div className="detailed-service-listing-detail-item-value-inner">
                            <div className="detailed-service-footer-social-parent">
                              <div className="detailed-service-footer-social">
                                <img
                                  className="detailed-service-footer-social-child"
                                  alt=""
                                  src={group_58_2x}
                                />

                                <div className="detailed-service-listing-short-detail-item-pin-parent">
                                  <div className="detailed-service-listing-short-detail-item-pin">
                                    <div className="detailed-service-name">Olivia Rhye</div>
                                  </div>
                                  <div className="detailed-service-role1">Business Name</div>
                                </div>
                              </div>
                              <div className="detailed-service-listing-short-detail-item-date">
                                <img
                                  className="detailed-service-icon1"
                                  alt=""
                                  src={icon1}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="detailed-service-rectangle-parent10">
                    <div className="detailed-service-frame-child12"></div>
                    <div className="detailed-service-rectangle-parent30">
                      <img
                        className="detailed-service-listing-card-badge-overlay-child"
                        alt=""
                        src={rectangle_95_2x}
                      />

                      <div className="detailed-service-image-overlay">
                        <div className="detailed-service-badge4">
                          <div className="detailed-service-listing-card-price">Assured</div>
                        </div>
                        <div className="detailed-service-short-listing-team">
                          <div className="detailed-service-badge5">
                            <div className="detailed-service-listing-card-radar">ID# AXJYN</div>
                          </div>
                          <div className="detailed-service-buttonsbutton11">
                            <img
                              className="detailed-service-magnifier-1-icon"
                              alt=""
                              src={radar_1_1_2x}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="detailed-service-listing-detail-item">
                      <div className="detailed-service-message-content">
                        <div className="detailed-service-text-frame">
                          <div className="detailed-service-text42">
                            New / Surplus 2021 Philips Health-care HD11 XE
                            (P/N:23A...
                          </div>
                        </div>
                        <div className="detailed-service-listing-detail-item-value">
                          <div className="detailed-service-listing-detail-item-pin-wrapper">
                            <div className="detailed-service-heading-container">
                              <div className="detailed-service-listing-detail-item-location">
                                <div className="detailed-service-listing-detail-item-date-parent">
                                  <div className="detailed-service-listing-detail-item-date">
                                    <img
                                      className="detailed-service-chevron-right-icon2"
                                      alt=""
                                      src={markerpin021}
                                    />
                                  </div>
                                  <div className="detailed-service-location">India, New Delhi</div>
                                </div>
                                <div className="detailed-service-listing-detail-item-date-parent">
                                  <div className="detailed-service-listing-detail-item-date">
                                    <img
                                      className="detailed-service-chevron-right-icon2"
                                      alt=""
                                      src={calendar1}
                                    />
                                  </div>
                                  <div className="detailed-service-type-of-work">April 09, 2023</div>
                                </div>
                              </div>
                              <div className="detailed-service-listing-team">
                                <div className="detailed-service-listing-team-member-wrapper">
                                  <div className="detailed-service-listing-team-member">4.9/5</div>
                                </div>
                                <div className="detailed-service-contact-role">
                                  <div className="detailed-service-supporting-text12">
                                    99,999 reviews
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="detailed-service-listing">
                            <div className="detailed-service-breadcrumb-items">
                              <div className="detailed-service-listing-short-info">$9,999,999.99</div>
                              <div className="detailed-service-supporting-text-wrapper">
                                <div className="detailed-service-supporting-text13">USD-Per Unit</div>
                              </div>
                            </div>
                          </div>
                          <img
                            className="detailed-service-divider-icon"
                            alt=""
                            src={divider4}
                          />

                          <div className="detailed-service-listing-detail-item-value-inner">
                            <div className="detailed-service-footer-social-parent">
                              <div className="detailed-service-footer-social">
                                <img
                                  className="detailed-service-footer-social-child"
                                  alt=""
                                  src={group_58_2x}
                                />

                                <div className="detailed-service-listing-short-detail-item-pin-parent">
                                  <div className="detailed-service-listing-short-detail-item-pin">
                                    <div className="detailed-service-name">Olivia Rhye</div>
                                  </div>
                                  <div className="detailed-service-role1">Business Name</div>
                                </div>
                              </div>
                              <div className="detailed-service-listing-short-detail-item-date">
                                <img
                                  className="detailed-service-icon1"
                                  alt=""
                                  src={icon1}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="detailed-service-rectangle-parent10">
                    <div className="detailed-service-frame-child12"></div>
                    <div className="detailed-service-rectangle-parent30">
                      <img
                        className="detailed-service-listing-card-badge-overlay-child"
                        alt=""
                        src={rectangle_95_2x}
                      />

                      <div className="detailed-service-image-overlay3">
                        <div className="detailed-service-badge4">
                          <div className="detailed-service-listing-card-price">Assured</div>
                        </div>
                        <div className="detailed-service-badge-group">
                          <div className="detailed-service-badge5">
                            <div className="detailed-service-listing-card-radar">ID# AXJYN</div>
                          </div>
                          <div className="detailed-service-buttonsbutton14">
                            <img
                              className="detailed-service-magnifier-1-icon"
                              alt=""
                              src={radar_1_1_2x}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="detailed-service-listing-detail-item">
                      <div className="detailed-service-message-content">
                        <div className="detailed-service-text-frame">
                          <div className="detailed-service-text42">
                            New / Surplus 2021 Philips Health-care HD11 XE
                            (P/N:23A...
                          </div>
                        </div>
                        <div className="detailed-service-icon-group">
                          <img className="detailed-service-icon4" alt="" src={icon1} />

                          <div className="detailed-service-message-content">
                            <div className="detailed-service-listing-detail-item-pin-wrapper">
                              <div className="detailed-service-listing-detail-item-location">
                                <div className="detailed-service-listing-detail-item-date-parent">
                                  <div className="detailed-service-listing-detail-item-date">
                                    <img
                                      className="detailed-service-chevron-right-icon2"
                                      alt=""
                                      src={markerpin021}
                                    />
                                  </div>
                                  <div className="detailed-service-location">India, New Delhi</div>
                                </div>
                                <div className="detailed-service-listing-detail-item-date-parent">
                                  <div className="detailed-service-listing-detail-item-date">
                                    <img
                                      className="detailed-service-chevron-right-icon2"
                                      alt=""
                                      src={calendar1}
                                    />
                                  </div>
                                  <div className="detailed-service-type-of-work">April 09, 2023</div>
                                </div>
                              </div>
                            </div>
                            <div className="detailed-service-supporting-work-five">
                              <div className="detailed-service-breadcrumb-items">
                                <div className="detailed-service-listing-team-member-wrapper">
                                  <div className="detailed-service-listing-team-member">4.9/5</div>
                                </div>
                                <div className="detailed-service-contact-role">
                                  <div className="detailed-service-supporting-text12">
                                    99,999 reviews
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="detailed-service-supporting-six-wrapper">
                              <div className="detailed-service-breadcrumb-items">
                                <div className="detailed-service-listing-short-info">$9,999,999.99</div>
                                <div className="detailed-service-supporting-text-wrapper">
                                  <div className="detailed-service-supporting-text13">USD-Per Unit</div>
                                </div>
                              </div>
                            </div>
                            <div className="detailed-service-divider-container">
                              <img
                                className="detailed-service-divider-icon3"
                                alt=""
                                src={divider4}
                              />
                            </div>
                            <div className="detailed-service-team-info-four">
                              <div className="detailed-service-detail-items">
                                <img
                                  className="detailed-service-footer-social-child"
                                  alt=""
                                  src={group_58_2x}
                                />

                                <div className="detailed-service-listing-short-detail-item-pin-parent">
                                  <div className="detailed-service-listing-short-detail-item-pin">
                                    <div className="detailed-service-name">Olivia Rhye</div>
                                  </div>
                                  <div className="detailed-service-role1">Business Name</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="detailed-service-testiomonial-carousel-arrow8">
                    <img
                      className="detailed-service-arrow-left-icon"
                      alt=""
                      src={arrowleft}
                    />
                  </div>
                </div>
                <div className="detailed-service-testiomonial-carousel-arrow9">
                  <img
                    className="detailed-service-arrow-left-icon"
                    alt=""
                    src={arrowright}
                  />
                </div>
              </div>
            </div>
            <FooterDetailPage />
          </div>
        </div>
      </>
    )
  }
  else {
    return <Loader />;
  }

}

