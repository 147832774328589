import React, { useEffect, useRef, useState } from 'react';
import "./DashboardJobComponent.css"
import dotsvertical from '../../../icons/Gerator/dashboard/dotsvertical.svg';
import calendar1 from '../../../icons/Gerator/dashboard/calendar1.svg';
import arrowsquareupright1 from '../../../icons/Gerator/dashboard/arrowsquareupright1.svg';
import radar_1_1_2x from '../../../icons/Gerator/dashboard/radar-1-1@2x.png';
import deal_2_1_2x from '../../../icons/Gerator/dashboard/deal-2-1@2x.png';
import magnifier_1_2x from '../../../icons/Gerator/dashboard/magnifier-1@2x.png';
import markerpin02 from '../../../icons/Gerator/dashboard/markerpin02.svg';
import arrowup1 from '../../../icons/Gerator/dashboard/arrowup1.svg';
import arrowdown1 from '../../../icons/Gerator/dashboard/arrowdown1.svg';
import placeholder2 from '../../../icons/Gerator/dashboard/placeholder2.svg';
import xclose from '../../../icons/Gerator/dashboard/xclose.svg';
import filterlines from '../../../icons/Gerator/dashboard/filterlines.svg';
import sliders02 from '../../../icons/Gerator/dashboard/sliders02.svg';
import searchlg from '../../../icons/Gerator/dashboard/searchlg.svg';
import d8595f4b711e503bc72fe396e5043e0c2 from '../../../icons/Gerator/dashboard/8595f4b711e503bc72fe396e5043e0c2-1@2x.png';
import group_58_2x from '../../../icons/Gerator/dashboard/group-58@2x.png';
import { SortByFilterComponent } from '../../SortByFilterComponent/SortByFilterComponent';
import DashboardFilterButtonGroup from '../../DashboardFilterButtonGroup/DashboardFilterButtonGroup';
import { ThreeDotsListingComponent } from '../../ThreeDotsListingComponent/ThreeDotsListingComponent';
import { Link } from 'react-router-dom';
import { PostStatusLIstingComponent } from '../../PostStatusLIstingComponent/PostStatusLIstingComponent';
import { NoListingFound } from '../../NoListing/NoListingFound';
import SkeletonListingLoaderComponent from '../../SkeletonListingLoaderComponent/SkeletonListingLoaderComponent';
import { getCustomizedDate } from '../../../helper/JobHelper';
import SearchInputListingComponent from '../../SearchInputListingComponent/SearchInputListingComponent';
import { useGetUserDashboardJobsQuery } from '../../../api/JobAPI';
import { formatNumber } from '../../../helper/DeviceHelper';

import { ThreeDotsNoActionComponent } from '../../ThreeDotsListingComponent/ThreeDotsNoActionComponent';
import TruncatedTextTooltip from '../../TruncatedTextTooltip/TruncatedTextTooltip';



export const DashboardJobComponent = () => {

    const [activeThreeDotIdNoAction, setActiveThreeDotIdNoAction] = useState(null);
      const threeDotsRefNoAction = useRef([]);
      const [isClickBlockedNoAction, setIsClickBlockedNoAction] = useState(false);
    
      const toggleThreeDotsDropdownNoAction = (index) => {
        if (isClickBlockedNoAction) return; // Prevent rapid toggle
    
       
  
          if (activeThreeDotIdNoAction === index) {
            setActiveThreeDotIdNoAction(null); // Close if the same dropdown is clicked again
          } else {
            setActiveThreeDotIdNoAction(index); // Open the dropdown
          }
  
          setIsClickBlockedNoAction(true);
          setTimeout(() => setIsClickBlockedNoAction(false), 400);
      };
    
      
      useEffect(() => {
        const handleThreeDotClickOutsideNoAction = (event) => {
          if (activeThreeDotIdNoAction !== null) {
            const ref = threeDotsRefNoAction.current[activeThreeDotIdNoAction];
      
            if (ref && !ref.contains(event.target)) {
              setActiveThreeDotIdNoAction(null); // Close when clicking outside
            }
          }
        };
      
        document.addEventListener("mousedown", handleThreeDotClickOutsideNoAction);
        return () => {
          document.removeEventListener("mousedown", handleThreeDotClickOutsideNoAction);
        };
      }, [activeThreeDotIdNoAction]);

  //Offset to load data when click load more
  const [offset, setOffset] = useState(0);
  const [sortBy, setSortBy] = useState('date_desc');
  const [searchTerm, setSearchTerm] = useState('');
  const [totalCount, setTotalCount] = useState("");
  const [totalCountPublished, settotalCountPublished] = useState("");
  const [totalCountPending, settotalCountPending] = useState("");
  const [totalCountArchived, settotalCountArchived] = useState("");
  const [totalCountExpired, settotalCountExpired] = useState("");

  const [activePostStatus, setActivePostStatusFilter] = useState(null);
  //Query to get data
  const { data, isSuccess, isError, refetch, isLoading, isFetching } = useGetUserDashboardJobsQuery(
    { offset, sortBy, postStatus: activePostStatus, search: searchTerm },
    {
      // Refetch when component mounts or tab becomes active
      refetchOnMountOrArgChange: true
    }
  );

  //flag var to show listing when loading complete when sekelteon layout is going to stop
  const [loadingComplete, setLoadingComplete] = useState(false);

  //Var is reponsible to show listings and other thing as well
  let content;

  //Sort by text show on button
  const [selectedSortOption, setSelectedSortOption] = useState('');

  //For SortBy Dropdown
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);

  //For Threedots in listing dropdown
  const [activeThreeDotId, setActiveThreeDotId] = useState(null);
  const threeDotsRef = useRef(null);
  const [isClickBlocked, setIsClickBlocked] = useState(false);



  const toggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible);
  }

  const toggleThreeDotsDropdown = (id) => {
    if (isClickBlocked) return; // Prevent rapid toggle

    if (activeThreeDotId === id) {
      setActiveThreeDotId(null); // Close if the same one is clicked again
      setIsClickBlocked(true);
      setTimeout(() => setIsClickBlocked(false), 400); // Small delay to avoid reopening
    } else {
      setActiveThreeDotId(id); // Open the clicked one
    }
  };

  const handleSearch = (term) => {
    setSearchTerm(term);
  };


  //Set value for sortby selected option on button text
  const handleOptionSelect = (option) => {
    if (option.value !== sortBy) {
      setSortBy(option.value);
      setOffset(0); // Reset offset when sort option changes
    }
    setSelectedSortOption(option);
    setDropdownVisible(false);
  };

  //DashbaordFilterButtonGroup component to get data
  const handleFilterClick = (filterLabel) => {
    if (activePostStatus === filterLabel) {
      setSortBy('date_desc')
      setSelectedSortOption('')
      setActivePostStatusFilter(null);
    } else {
      setSortBy('date_desc')
      setSelectedSortOption('')
      setActivePostStatusFilter(filterLabel);
    }
    setOffset(0); // Reset offset when changing filters
  };

  //Setting offset for loadmore when user clicked on load more button(this trigger query to fetch new data)
  // Reset offset when component mounts or remounts
  // useEffect(() => {
  //   setOffset(0);
  // }, []);
  // // Reset offset when sort option changes
  // useEffect(() => {
  //   setOffset(0);
  // }, [sortBy]);

  const handleLoadMore = () => {
    if (data?.hasMore && !isFetching) {
      setOffset(currentOffset => currentOffset + 5);
    }
  };

  const showLoadMore = data?.hasMore &&
    data.jobs.length < data.totalCount &&
    !isFetching;





  //Onclick listener for sortby dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


  //Onclick listener for threedots dropdown
  useEffect(() => {
    const handleThreeDotClickOutside = (event) => {

      const isLink = event.target.closest('a');

      if (isLink) {
        return; // Allow navigation to happen
      }

      if (threeDotsRef.current && !threeDotsRef.current.contains(event.target)) {
        setActiveThreeDotId(null); // Close any open dropdown when clicking outside
      }
    };

    document.addEventListener("mousedown", handleThreeDotClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleThreeDotClickOutside);
    };
  }, []);

  // Listen for tab visibility changes
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        refetch();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [refetch]);


  //Success true then pause to show sekelton layout
  useEffect(() => {
    if (isSuccess) {
      // Set totalcount in listing counter layout
      setTotalCount(data.totalCount);
      settotalCountPublished(data.totalCountPublished);
      settotalCountArchived(data.totalCountArchived);
      settotalCountPending(data.totalCountPending);
      settotalCountExpired(data.totalCountExpired);
      // Set timeout to delay the loading completion
      const timer = setTimeout(() => {
        setLoadingComplete(true);
      }, 1000); // 1 second

      // Cleanup timer on component unmount
      return () => clearTimeout(timer);
    }
  }, [isSuccess]);


  if (isError) {
    content = <NoListingFound path={"/add-job"} pathName={"Job"} />
  }

  if (isLoading) {
    content = <SkeletonListingLoaderComponent />;
  } else if (loadingComplete && isSuccess) {

    if (data.jobs.length > 0) {
      // dataLength = data.jobs.length;
      content = data.jobs.map((job, index) =>
      (
        <div className="dashboard-job-component-dashboard-content-items" key={index}>
        <div className="dashboard-job-component-dashboard-rectangle-parent10">
          <div className="dashboard-job-component-dashboard-frame-child12"></div>
          <div className="dashboard-job-component-dashboard-frame-wrapper25">
            <div className="dashboard-job-component-dashboard-element-details-parent">
              <div className="dashboard-job-component-dashboard-element-details">
                <div className="dashboard-job-component-dashboard-element-details-child"></div>
                <img
                  className="dashboard-job-component-dashboard-f4b711e503bc72fe396e5043e0c2-1-icon"
                  alt=""
                  src={job.logoImage}
                />
              </div>
              <div className="dashboard-job-component-dashboard-element-actions">
                <Link to={`/job/${job.id}/${job.title}`} className="dashboard-job-component-dashboard-text78">
                  {job.title}
                </Link>
                <div className="dashboard-job-component-dashboard-action-icons">
              
                    <div className="dashboard-job-component-dashboard-date-icon">
                      <div className="dashboard-job-component-dashboard-badge-parent">
                        <div className="dashboard-job-component-dashboard-supporting-text12">
                          {job.job_business.businessName}
                        </div>
                        
                      </div>
                      <div className="dashboard-job-component-dashboard-type-badge-elements">
                        <div className="dashboard-job-component-dashboard-end-label">
                          <div className="dashboard-job-component-dashboard-search-icon-input">4.9/5</div>
                        </div>
                        <div className="dashboard-job-component-dashboard-type-support">
                          <div className="dashboard-job-component-dashboard-supporting-text9">
                            99,999 reviews
                          </div>
                        </div>
                      </div>
                     
                    </div>
                    <div className="dashboard-job-component-dashboard-work-type-icon">

                    <div className="dashboard-job-component-dashboard-search-input">
                          <img
                            className="dashboard-job-component-dashboard-marker-pin-02-icon2"
                            alt=""
                            src={markerpin02}
                          />
                        </div>

                      <div className="dashboard-job-component-dashboard-location3">
                        {
                          (job.job_address.tax_address_type.slug === 'remote') && job.job_address.tax_address_type.name
                        }

                        {
                          (job.job_address.tax_address_type.slug === 'address' && `${job.job_address.addressTownCity}, ${job.job_address.tax_country.name}`)
                        }

                            {/* {
                             job.job_address.tax_address_type.slug === 'address' && (<TruncatedTextTooltip 
                                text={`${job.job_address.addressTownCity}, ${job.job_address.tax_country.name}`} 
                              />)
                            } */}
                      </div>
                    
                    </div>
                
                  <div className="dashboard-job-component-dashboard-work-type-label">
                    <div className="dashboard-job-component-dashboard-search-input">
                      <img
                        className="dashboard-job-component-dashboard-marker-pin-02-icon2"
                        alt=""
                        src={calendar1}
                      />
                    </div>
                    <div className="dashboard-job-component-dashboard-type-of-work2">{getCustomizedDate(job.createdAt)}</div>
                  </div>
                </div>

                <div className="dashboard-job-component-dashboard-small-card">

                <div className="dashboard-job-component-dashboard-work-type-value">
                    <div className="dashboard-job-component-dashboard-price-parent">
                        <div className="dashboard-job-component-dashboard-text100">{job.tax_engagement_type.name}</div>
                        <div className="dashboard-job-component-dashboard-supporting-text15">
                          Engagement Type
                        </div>
                     </div>
                 </div>

                      <div className="dashboard-job-component-dashboard-work-type-value">
                        <div className="dashboard-job-component-dashboard-price-parent">
                          <div className="dashboard-job-component-dashboard-text100">{job.tax_experience_level.name}</div>
                          <div className="dashboard-job-component-dashboard-supporting-text15">Experience</div>
                        </div>
                      </div>

                </div>
              </div>
              <div className="dashboard-job-component-dashboard-dropdown-parent1">
            {/* {(job.tax_post_status.slug !== 'pending') ? */}
            <div className="dashboard-job-component-dashboard-dropdown10" onClick={() => toggleThreeDotsDropdown(index)} ref={threeDotsRef}>
              <img
                className="dashboard-job-component-dashboard-dots-vertical-icon"
                alt=""
                src={dotsvertical}
              />
              {activeThreeDotId === index && <ThreeDotsListingComponent url={`job/${job.id}`} />}
              {/* {isThreeDotsVisible && <ThreeDotsListingComponent url={`job/${job.id}`} />} */}
            </div>
            {/* : */}
            {/* <div className="dashboard-job-component-dashboard-dropdown10" >
                <img
                  className="dashboard-job-component-dashboard-dots-vertical-icon"
                  alt=""
                  src=""
                />
              </div> */}
            {/* } */}
            <div className="dashboard-job-component-dashboard-filter-elements-inner">
              <div className="dashboard-job-component-dashboard-frame-parent31">
                <div className="dashboard-job-component-dashboard-text-wrapper10">
                  <div className="dashboard-job-component-dashboard-text101">From</div>
                </div>
                <div className="dashboard-job-component-dashboard-price4">${formatNumber(job.budget)}</div>
                <div className="dashboard-job-component-dashboard-trend-chart">
                  <div className="dashboard-job-component-dashboard-text83">{job.tax_unit_measure_service.name}</div>
                  <div className="dashboard-job-component-dashboard-trend-chart-inner">
                    <div className="dashboard-job-component-dashboard-frame-parent32">
                      <div className="dashboard-job-component-dashboard-frame-wrapper27">
                        <div className="dashboard-job-component-dashboard-radar-1-1-container">
                          <img
                            className="dashboard-job-component-dashboard-magnifier-1-icon"
                            alt=""
                            src={radar_1_1_2x}
                          />

                          <img
                            className="dashboard-job-component-dashboard-magnifier-1-icon"
                            alt=""
                            src={deal_2_1_2x}
                          />

                          <img
                            className="dashboard-job-component-dashboard-magnifier-1-icon"
                            alt=""
                            src={magnifier_1_2x}
                          />
                        </div>
                      </div>
                      <a 
                        href={`/job/${job.id}/${job.title}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        
                      >
                      <img
                        className="dashboard-job-component-dashboard-arrow-square-up-right-icon1"
                        alt=""
                        src={arrowsquareupright1}
                      />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
            </div>
          </div>
        
        </div>
      </div>
      ))
    }
    else {
      content = (

        <NoListingFound path={"/add-job"} pathName={"Job"} />

      )
    }

  } else if (!loadingComplete && isSuccess) {
    content = <SkeletonListingLoaderComponent />; // Show loader until loadingComplete is true
  }




  return (
    <>
      <div className="dashboard-frame-wrapper">
        <div className="dashboard-frame-group">
          <div className="dashboard-rectangle-group">
            <div className="dashboard-frame-item"></div>
            <div className="dashboard-metric-card-row">
              <div className="dashboard-metric-card">
                <div className="dashboard-metric-card-title">
                  <div className="dashboard-heading4">Total</div>
                </div>
                <div className="dashboard-dropdown3" style={{cursor:"pointer"}}  onClick={() => toggleThreeDotsDropdownNoAction(0)} ref={(el) => threeDotsRefNoAction.current[0] = el}>
                  <img
                    className="dashboard-dots-vertical-icon"
                    alt=""
                    src={dotsvertical}
                  />
                                                        {activeThreeDotIdNoAction === 0  && <ThreeDotsNoActionComponent  />}

                </div>
              </div>
              <div className="dashboard-number3">{totalCount || 0}</div>
            </div>
            <div className="dashboard-badge11">
              <div className="dashboard-options-icon">
                <img
                  className="dashboard-arrow-up-icon2"
                  alt=""
                  src={arrowup1}
                />
              </div>
              <div className="dashboard-text58">20 slots available</div>
            </div>
          </div>
          <div className="dashboard-rectangle-container">
            <div className="dashboard-frame-inner"></div>
            <div className="dashboard-frame-container">
              <div className="dashboard-heading-parent">
                <div className="dashboard-heading5">Published</div>
                <div className="dashboard-number4">{totalCountPublished || 0}</div>
                <div className="dashboard-badge12">
                  <div className="dashboard-options-icon">
                    <img
                      className="dashboard-arrow-up-icon2"
                      alt=""
                      src={arrowup1}
                    />
                  </div>
                  <div className="dashboard-change-amount">20%</div>
                </div>
              </div>
            </div>
            <div className="dashboard-dropdown3" style={{cursor:"pointer"}}   onClick={() => toggleThreeDotsDropdownNoAction(1)} ref={(el) => threeDotsRefNoAction.current[1] = el}>
              <img
                className="dashboard-dots-vertical-icon"
                alt=""
                src={dotsvertical}
              />
                                                        {activeThreeDotIdNoAction === 1  && <ThreeDotsNoActionComponent  />}

            </div>
          </div>
          <div className="dashboard-rectangle-container">
            <div className="dashboard-frame-inner"></div>
            <div className="dashboard-options-icon">
              <div className="dashboard-heading-group">
                <div className="dashboard-heading5">Pending</div>
                <div className="dashboard-number4">{totalCountPending || 0}</div>
                <div className="dashboard-badge12">
                  <div className="dashboard-options-icon">
                    <img
                      className="dashboard-arrow-up-icon2"
                      alt=""
                      src={arrowup1}
                    />
                  </div>
                  <div className="dashboard-change-amount">20%</div>
                </div>
              </div>
            </div>
            <div className="dashboard-dropdown3" style={{cursor:"pointer"}}  onClick={() => toggleThreeDotsDropdownNoAction(2)} ref={(el) => threeDotsRefNoAction.current[2] = el}>
              <img
                className="dashboard-dots-vertical-icon"
                alt=""
                src={dotsvertical}
              />
                                                        {activeThreeDotIdNoAction === 2  && <ThreeDotsNoActionComponent  />}

            </div>
          </div>
          <div className="dashboard-rectangle-container">
            <div className="dashboard-frame-inner"></div>
            <div className="dashboard-frame-container">
              <div className="dashboard-heading-container">
                <div className="dashboard-heading5">Archived</div>
                <div className="dashboard-number4">{totalCountArchived || 0}</div>
                <div className="dashboard-badge12">
                  <div className="dashboard-options-icon">
                    <img
                      className="dashboard-arrow-up-icon2"
                      alt=""
                      src={arrowup1}
                    />
                  </div>
                  <div className="dashboard-change-amount">20%</div>
                </div>
              </div>
            </div>
            <div className="dashboard-dropdown3" style={{cursor:"pointer"}}   onClick={() => toggleThreeDotsDropdownNoAction(3)} ref={(el) => threeDotsRefNoAction.current[3] = el}>
              <img
                className="dashboard-dots-vertical-icon"
                alt=""
                src={dotsvertical}
              />
                                                        {activeThreeDotIdNoAction === 3  && <ThreeDotsNoActionComponent  />}

            </div>
          </div>
          <div className="dashboard-rectangle-parent2">
            <div className="dashboard-frame-inner"></div>
            <div className="dashboard-frame-container">
              <div className="dashboard-heading-parent1">
                <div className="dashboard-heading5">Expired</div>
                <div className="dashboard-number4">{totalCountExpired || 0}</div>
                <div className="dashboard-badge12">
                  <div className="dashboard-options-icon">
                    <img
                      className="dashboard-arrow-up-icon2"
                      alt=""
                      src={arrowup1}
                    />
                  </div>
                  <div className="dashboard-change-amount">20%</div>
                </div>
              </div>
            </div>
            <div className="dashboard-dropdown7"  style={{cursor:"pointer"}}  onClick={() => toggleThreeDotsDropdownNoAction(4)} ref={(el) => threeDotsRefNoAction.current[4] = el}>
              <img
                className="dashboard-dots-vertical-icon"
                alt=""
                src={dotsvertical}
              />
                                                                      {activeThreeDotIdNoAction === 3 && <ThreeDotsNoActionComponent  />}
              
            </div>
          </div>
        </div>
      </div>
      <div className="dashboard-section-wrapper">
        <div className="dashboard-section1">
          <div className="dashboard-container1">
            <div className="dashboard-filters-bar">
              <div className="dashboard-content17">
                <div className="dashboard-dropdowns">

                  <DashboardFilterButtonGroup
                    handleFilterClick={handleFilterClick}
                    activePostStatus={activePostStatus}
                  />

                </div>
                <div className="dashboard-input-dropdown2">
                  {/* <div className="dashboard-input-with-label2">
                    <div className="dashboard-label">Search</div>
                    <div className="dashboard-input2">
                      <div className="dashboard-content3">
                        <img
                          className="dashboard-search-lg-icon dashboard-search-lg-icon-custom"
                          alt=""
                          src={searchlg}
                        />
                        <input type="text" className="dashboard-search-placeholder" placeholder='Search' />
                      </div>
                    </div>
                  </div> */}

                  <SearchInputListingComponent onSearch={handleSearch} />

                  <div className="dashboard-buttonsbutton1" onClick={toggleDropdown}>
                    <img
                      className="dashboard-upload-04-icon"
                      alt=""
                      src={sliders02}
                    />
                    <div className="dashboard-text-padding6" ref={dropdownRef}>
                      <div className="dashboard-text65">
                        Sort By {selectedSortOption && `: ${selectedSortOption.label}`}
                      </div>
                      {isDropdownVisible && <SortByFilterComponent onOptionSelect={handleOptionSelect} />}
                    </div>
                    <img
                      className="dashboard-placeholder-icon"
                      alt=""
                      src={placeholder2}
                    />

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Listing Container */}
      <div className="dashboard-job-component-listing-container">

        {/* Listing layout start    */}
        {content}

      </div>

      {/* Load more button  */}
      <div className="dashboard-frame-wrapper44">
        <div className="dashboard-frame-parent72">
          <div className="dashboard-buttonsbutton-wrapper1">
            {showLoadMore && (
              <button className='dashboard-buttonsbutton-wrapper1-child'
                onClick={handleLoadMore}
                disabled={isFetching}
              >
                <div className="dashboard-buttonsbutton28">
                  <img
                    className="dashboard-arrow-narrow-left-icon"
                    alt=""
                    src={arrowdown1}
                  />
                  <div className="dashboard-search-input">
                    <div className="dashboard-text146">{isFetching ? 'Loading...' : 'Load More'}</div>
                  </div>
                </div>
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

