// import { element } from "prop-types";
// import { Dashboard } from "../screens/Dashboard/Dashboard";
// import { SignIn } from "../screens/Sign_In/SignIn";
// import { SignUp } from "../screens/Sign_Up/SignUp";
// import { UserProfile } from "../screens/UserProfile/UserProfile";
// import { Radar } from "../screens/Radar/Radar";
// import { Beacon } from "../screens/Beacon/Beacon";
import { PlanAndBilling, PlanAndBillingSuccess } from "../screens/PlanAndBillingSuccess/PlanAndBillingSuccess";
import { Settings } from "../screens/Settings/Settings";



export const SettingsRoutes = [
  {
    path: "/dashboard/settings/*",
    element: <Settings />,
  },
 
  // {
  //   path: "/dashboard/settings/profile",
  //   element: <Settings />,
  // },
  // {
  //   path: "/dashboard/settings/business",
  //   element: <Settings />,
  // },
  // {
  //   path: "/dashboard/settings/address",
  //   element: <Settings />,
  // },
  // {
  //   path: "/dashboard/settings/team",
  //   element: <Settings />,
  // },
];
