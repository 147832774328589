import React from "react";
import "./BackToLoginButton.css"
import placeholder1 from '../../icons/Gerator/account_setup/placeholder1.svg';
import arrow_left from '../../icons/Gerator/account_setup/arrowleft.svg';
import { getAuth, signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";


export const BackToLoginButton = () =>{
  const navigate = useNavigate();

  const logout = async () => {
    navigate('/sign-in');

     // const navigate = useNavigate();
     const auth = getAuth();
     try {
       //Signout from firebase
       await signOut(auth);
 
       // Handle successful logout, like redirecting to the login page
       console.log("User logged out successfully");
       navigate("/sign-in");
     } catch (error) {
       console.log("Error during logout:", error);
       navigate("/sign-in", { state: { message: error.message } });
     }
  }
    return(
      <div className="login-buttonsbutton1">
      <img
        className="login-arrow-left-icon"
        loading="lazy"
        alt=""
        src={arrow_left}
      />
    
     <div className="login-back-label" onClick={logout}>Back to log in</div>
    
      <img
        className="login-placeholder-icon2"
        alt=""
        src={placeholder1}
      />
      
    </div>
    )
}