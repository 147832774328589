import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import Pusher from "pusher-js";
import { MessageBox } from "./MessageBox";
import { useLazyGetUserProfileQuery } from "../../../api/UsermetaAPI";
import {
  useSaveMetadataMutation,
  useSaveConversationMutation,
  useSendMessageMutation,
  useLazyCheckChatMetadataQuery,
  useSaveEachMessageMutation,
  useLazyFetchMessagesQuery,
  useLazyFetchInitialMessagesForDisplayQuery,
} from "../../../api/ChatAPI";
import { useLazyGetPostTypesQuery } from "../../../api/TaxonomyFormAPI";

import { ChatAPI } from "../../../api/ChatAPI"; // Import your ChatAPI instance
import { updateChatCache } from "../../../helper/ChatHelper";

const ChatPanel = ({ currentUser, deviceData }) => {
  const {listing, id, entity } = useParams();
  const pusherInstance = useRef(null); // Persist Pusher instance
  const [triggerGetUserProfile, { data: decryptedData, error, isLoading }] =
    useLazyGetUserProfileQuery();
  const [sendMessage] = useSendMessageMutation();
  const [saveMetadata] = useSaveMetadataMutation();
  const [saveConversation] = useSaveConversationMutation();
  const [messages, setMessages] = useState([]);
  const [postTypeMap, setPostTypeMap] = useState(new Map());
  const [triggerCheckChatMetadata] = useLazyCheckChatMetadataQuery();
  const [saveEachMessage] = useSaveEachMessageMutation();
  const [metadataId, setMetadataId] = useState(null);
  const [page, setPage] = useState(1); // Pagination state
  //const [triggerfetchMessages, { data: fetchedMessages, isLoading: isFetchLoading, isError: isFetchError }] = useLazyFetchMessagesQuery();
  const [triggerFetchMessages] = useLazyFetchMessagesQuery();
  const [triggerFetchInitialMessagesForDisplay, { data: initialMessagesForDisplay , isLoading:isInitialMessagesForDisplayLoading, isError : isInitialMessagesForDisplayError}] = useLazyFetchInitialMessagesForDisplayQuery();
  const isInitialMessagesFetched = useRef(false);

  const [triggerFetchPostTypes, { data: postTypeQueryData }] =
    useLazyGetPostTypesQuery();

  useEffect(() => {
    if (currentUser) {
      triggerGetUserProfile();
      triggerFetchPostTypes();
    }
  }, [currentUser, triggerGetUserProfile, triggerFetchPostTypes]);

  useEffect(() => {
    if (postTypeQueryData) {
      const map = new Map(postTypeQueryData.map((item) => [item.name, item.id]));
      setPostTypeMap(map);
    }
  }, [postTypeQueryData]);

  //PUSHER SUBSCRIPTION LOGIC START

useEffect(() => {
  if (!pusherInstance.current) {
    pusherInstance.current = new Pusher("032ab85ab72373f5f617", {
      cluster: "us3",
      authEndpoint: `${process.env.REACT_APP_API_URL}/pusher/auth`,
    });
  }

  if (metadataId && decryptedData && deviceData) {
    const userId = decryptedData?.user?.id;
    const recipientId = deviceData?.data?.device_user?.id;
    const postId = postTypeMap.get(entity);
    const channelName = `private-chat-${listing}-${postId}-${Math.min(userId, recipientId)}-${Math.max(userId, recipientId)}`;

    console.log('Subscribing to channel,  postId, recipientId ,userId: ', channelName,postId,recipientId,userId);
console.log("decryptedData ",decryptedData)
console.log("deviceData ",deviceData)
    const channel = pusherInstance.current.subscribe(channelName);

    channel.bind("new-message", (newMessage) => {
      if (newMessage.sender === decryptedData.user.id) {
        console.log("This is a message sent by me, ignoring as received.");
        return; // Ignore messages sent by the same user
      }
      console.log("New message received in Pusher:");
  console.log("Message Data:", newMessage);


  // Mark message as real-time
  newMessage.real_time = true;

      // Use the helper function to update the cache
      updateChatCache({
        channelName: channelName,
        newMessage,
        page: 1,
      });


      setMessages((prevMessages) => [...prevMessages, newMessage]);
    });

    // Cleanup
    return () => {
      channel.unbind_all();
      channel.unsubscribe();
    };
  }
}, [metadataId, decryptedData, deviceData, postTypeMap, entity]);

  //PUSHER SUBSCRIPTION LOGIC END

  useEffect(() => {
    console.log("Updated messages state:", messages);
  }, [messages]); // Runs whenever `messages` changes
  

//   useEffect(() => {
//     if (currentUser && decryptedData) {
//     const userId = decryptedData.user.user_usermeta.user_id;
//     const recipientId = deviceData.data.device_business.business_user.id;
//     const postId = postTypeMap.get(entity);
//     const channel_name = `private-chat-${postId}-${Math.min(
//       userId,
//       recipientId
//     )}-${Math.max(userId, recipientId)}`;


//     triggerfetchMessages({ channel_name, page }); // Fetch messages for the current page
//  } }, [page]);

//   useEffect(() => {
//     if (fetchedMessages?.messages) {
//       setMessages((prevMessages) => {
//         console.log('Previous messages:', prevMessages);
//         console.log('New messages to add:', fetchedMessages.messages);
      
//         return [...prevMessages, ...fetchedMessages.messages];
//       });
      
//     }
//   }, [fetchedMessages]);
  
//HANDLEMORE FUNCTION TO FETCH MESSAGES LOAD MORE
const handleLoadMore = async () => {
  try {
    const userId = decryptedData.user.id;
    const recipientId = deviceData.data.device_user.id;;
    const postId = postTypeMap.get(entity);

    console.log("user and rec id:", userId, recipientId);
    const channel_name = `private-chat-${listing}-${postId}-${Math.min(
      userId,
      recipientId
    )}-${Math.max(userId, recipientId)}`;

    const response = await triggerFetchMessages({ channel_name, page }).unwrap();
    if (response?.messages) {
      setMessages((prevMessages) => [...response.messages, ...prevMessages]);
      setPage((prevPage) => prevPage + 1); // Increment page for the next fetch
    }
  } catch (error) {
    console.error("Error fetching historical messages:", error);
  }
};
  // const handleLoadMore = () => {
  //   setPage((prevPage) => prevPage + 1); // Increment page for older messages
  // };

  // Helper function to save metadata and conversation
 
 
  const handleSaveMetadataAndConversation = async (
    channel_name,
    userId,
    recipientId,
    subject,
    postId
  ) => {
    console.log("printing postid in save: ",postId);
    try {
      const metadataResponse = await saveMetadata({
        channel_name,
        user_id: userId,
        recipient_id: recipientId,
        post_id: postId,
        listing_type:listing,
      }).unwrap();

      const conversationResponse = await saveConversation({
        metadata_id: metadataResponse.metadata_id,
        subject,
      }).unwrap();

      return { metadataResponse, conversationResponse };
    } catch (error) {
      console.error("Error saving metadata or conversation:", error);
      throw error;
    }
  };

  // Helper function to check metadata and optionally create it
  const subscribeWithMetadataCheck = async (
    channel_name,
    userId,
    recipientId,
    postId,
    subject
  ) => {
    try {
      console.log("CHANNEL NAME WHEN CHECKING FOR DATA: ",channel_name);
      const response = await triggerCheckChatMetadata({ channel_name }).unwrap();
      console.log("RESPONSE:",response);

      console.log("HELLO SUBSCRIBING AND CHECKING NOW");
      if (response?.status) {
        console.log("yes, EXIST!", response.metadata_id);
        return response.metadata_id;
      } else {
        console.log("NO, does not exist. SAVING METADATA AND CONVO");
        const result = await handleSaveMetadataAndConversation(
          channel_name,
          userId,
          recipientId,
          subject,
          postId
        );

        if (result?.metadataResponse) {
          console.log("Created result :", result?.metadataResponse);
          return result.metadataResponse.metadata_id;
        } else {
          throw new Error("Failed to create metadata");
        }
      }
    } catch (error) {
      console.error("Error checking or saving metadata:", error);
      throw error;
    }
  };

  // Step 2: Initialize chat
  useEffect(() => {
    const initializeChat = async () => {
      if (
        !currentUser ||
        !decryptedData?.user.id ||
        !deviceData?.data?.device_user?.id ||
        !postTypeMap.get(entity)
      ) {
        return;
      }
      console.log("HELLO INITALIZING NOW", postTypeMap.get(entity));

      const userId = decryptedData.user.id;
      const recipientId = deviceData.data.device_user.id;;
      const postId = postTypeMap.get(entity);

      console.log("HELLO INITALIZING postid NOW", postId);
      console.log("user and rec id:", userId, recipientId);
      const channel_name = `private-chat-${listing}-${postId}-${Math.min(
        userId,
        recipientId
      )}-${Math.max(userId, recipientId)}`;

      try {
        const metadata_id = await subscribeWithMetadataCheck(
          channel_name,
          userId,
          recipientId,
          postId,
          entity
        );
        setMetadataId(metadata_id);

      console.log("1st metaid:", metadataId);
      } catch (error) {
        console.error("Error initializing chat:", error);
      }
    };

    initializeChat();
  }, [currentUser, decryptedData, deviceData, postTypeMap, setMetadataId, metadataId]);

//FETCH THE INITIAL MESSAGES FOR DISPLAY BTU NOT WORKING
useEffect(() => {  //NEW DONE
  const initializeChat = async () => {
    if (!currentUser || !decryptedData?.user || !deviceData?.data || !postTypeMap || isInitialMessagesFetched.current) {
      return;
    }

    try {
      const userId = decryptedData.user.id;
      const recipientId = deviceData.data.device_user.id;;
      const postId = postTypeMap.get(entity);

      console.log("user and rec id:", userId, recipientId);
      const channel_name = `private-chat-${listing}-${postId}-${Math.min(
        userId,
        recipientId
      )}-${Math.max(userId, recipientId)}`;

      const response = await triggerFetchInitialMessagesForDisplay(channel_name).unwrap();
      isInitialMessagesFetched.current = true;
      console.log("TRIGGER FETCH IN CHAT APNEL ",response);
      if (response?.messages) {
      //  console.log("response in CHAT PNAEL : ",response?.messages)
        setMessages(response.messages); // Populate messages with historical data
      }
    } catch (error) {
      console.error("Error fetching initial messages:", error);
    }
  };

  initializeChat();
}, [currentUser, decryptedData, deviceData?.data]);


  const handleSendMessage = (messageText) => {
    if (!messageText.trim()) return;

    const userId = decryptedData.user.id;
    const profileImageLink = decryptedData.user.profileImageLink;
    const displayName = currentUser.displayName;
    const recipientId = deviceData?.data?.device_user?.id;
    const postId = postTypeMap.get(entity);

    const channel_name = `private-chat-${listing}-${postId}-${Math.min(
      userId,
      recipientId
    )}-${Math.max(userId, recipientId)}`;
console.log("printing channel nale: currentUser and deviceData", channel_name,currentUser,deviceData);

const time =Date.now();

const formattedTime = new Date(time).toISOString();
    const newMessage = {
      sender: userId,
      timestamp: formattedTime,
      content: messageText,
    };

    setMessages((prevMessages) => [...prevMessages, newMessage]);
console.log("message timestamp",messageText,formattedTime)
    sendMessage({
      channel: channel_name,
      message: messageText,
      sender: userId,
      timestamp: formattedTime,
      profileImageLink:profileImageLink,
      displayName:displayName,
    })
      .unwrap()
      .then(() => console.log("Message sent"))
      .catch((err) => console.error("Error sending message:", err));

      console.log({
        message_metadata_id: metadataId,
        message: messageText,
        message_type: "text",
        message_status: true,
        sender_id: userId,
      });
     

    saveEachMessage({
      message_metadata_id: metadataId,
      message: messageText,
      message_type: "text",
      message_status: true,
     sender_id: userId, // NEW
    }).unwrap();
  };

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error loading data</div>;

  return (
    <form className="chatB-chat-panel" onSubmit={(e) => e.preventDefault()}>
      <div className="chatB-chat-content">
        {currentUser && decryptedData?.user?.user_usermeta ? (
          <MessageBox
            messages={messages}
            setMessages={setMessages}
            currentUser={decryptedData.user.user_usermeta}
            onSendMessage={handleSendMessage}
            deviceData={deviceData}
            onLoadMore={handleLoadMore} // Pass load more functionality

          />
        ) : (
          <div>Loading user data...</div>
        )}
      </div>
    </form>
  );
};

export { ChatPanel };
