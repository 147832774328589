import { AddWantedSpare } from "../../screens/WantedSpare/AddWantedSpare/AddWantedSpare";
import { ArchiveWantedSpare } from "../../screens/WantedSpare/ArchiveWantedSpare/ArchiveWantedSpare";
import { DetailedWantedSpare } from "../../screens/WantedSpare/DetailedWantedSpare/DetailedWantedSpare";
import { EditWantedSpare } from "../../screens/WantedSpare/EditWantedSpare/EditWantedSpare";

export const wantedSpareRoutes = [
    {
        path: "/wanted-spares",
        element: <ArchiveWantedSpare />,
      },
      {
        path: "/add-wanted-spare",
        element: <AddWantedSpare />,
      },
      {
        path: "/edit-wanted-spare/:id/*",
        element: <EditWantedSpare />,
      },
      {
        path: "/wanted-spare/:id/*",
        element: <DetailedWantedSpare />,
      },
]

