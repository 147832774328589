import React, { useEffect, useState } from "react";
import "../ChatPageComponent.css"; // Correct relative path

import arrowright from "../../../icons/Gerator/chat_page/arrowright.svg";
import dot2 from "../../../icons/Gerator/chat_page/-dot2.svg";
import facesmile from "../../../icons/Gerator/chat_page/facesmile.svg";
import arrowleft from "../../../icons/Gerator/chat_page/arrowleft.svg";
import avatar2 from "../../../icons/Gerator/chat_page/avatar2.svg";
import dotsvertical from "../../../icons/Gerator/chat_page/dotsvertical.svg";
import divider2 from "../../../icons/Gerator/chat_page/divider2.svg";
import divider3 from "../../../icons/Gerator/chat_page/divider-3.svg";
import dotshorizontal from "../../../icons/Gerator/chat_page/dotshorizontal.svg";
import uploadcloud02 from "../../../icons/Gerator/chat_page/uploadcloud02.svg";
import videorecorder from "../../../icons/Gerator/chat_page/videorecorder.svg";
import phonecall01 from "../../../icons/Gerator/chat_page/phonecall01.svg";
import filterlines from "../../../icons/Gerator/chat_page/filterlines.svg";
import placeholder1 from "../../../icons/Gerator/chat_page/placeholder1.svg";
import placeholder2 from "../../../icons/Gerator/chat_page/placeholder2.svg";
import placeholder3 from "../../../icons/Gerator/chat_page/placeholder3.svg";
import placeholder from "../../../icons/Gerator/chat_page/placeholder.svg";
import pageIcon from "../../../icons/Gerator/chat_page/page.svg";

import searchlg from "../../../icons/Gerator/chat_page/searchlg.svg";

// const MessageInput = () => {
//   return (

//         <div className="chatB-message-input">
//           <div className="chatB-textarea-input-field">
//             <div className="chatB-textarea-input-field">
//               <div className="chatB-label2">Description</div>
//               <div className="chatB-input10">
//                 <input className="chatB-message-placeholder" placeholder="Send a message" type="text" />
//               </div>
//             </div>
//             <div className="chatB-hint-text1">
//               This is a hint text to help user.
//             </div>
//           </div>
//           <div className="chatB-input-actions">
//             <div className="chatB-actions2">
//               <div className="chatB-icons">
//                 <div className="chatB-buttonsbutton4">
//                   <img className="chatB-search-lg-icon" loading="lazy" alt="" src={facesmile} />
//                 </div>
//                 <div className="chatB-buttonsbutton4">
//                   <img className="chatB-search-lg-icon" loading="lazy" alt="" src={dotshorizontal} />
//                 </div>
//               </div>
//               <button className="chatB-buttonsbutton6">
//                 <img className="chatB-placeholder-icon" alt="" src={placeholder2}/>
//                 <div className="chatB-text-padding4">
//                   <div className="chatB-send-label">Send</div>
//                 </div>
//                 <img className="chatB-placeholder-icon" alt="" src={placeholder2}/>
//               </button>
//             </div>
//           </div>
//         </div>
//         // CHAT INPUT

//   );
// }

// export {MessageInput};


const MessageInput = ({ onSendMessage }) => {
  const [message, setMessage] = useState("");

  const handleSend = () => {
    if (message.trim()) {
      onSendMessage(message); // Pass the message to the parent component
      setMessage(""); // Clear the input field
    }
  };

  return (
    <div className="chatB-message-input">
      <div className="chatB-textarea-input-field">
        <div className="chatB-textarea-input-field">
          <div className="chatB-label2">Description</div>
          <div className="chatB-input10">
            <input
              className="chatB-message-placeholder"
              placeholder="Send a message"
              type="text"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              onKeyDown={(e) => e.key === "Enter" && handleSend()}
            />
          </div>
        </div>
        <div className="chatB-hint-text1">This is a hint text to help user.</div>
      </div>
      <div className="chatB-input-actions">
        <div className="chatB-actions2">
          <div className="chatB-icons">
            <div className="chatB-buttonsbutton4">
              <img
                className="chatB-search-lg-icon"
                loading="lazy"
                alt="Emoji"
                src={facesmile}
              />
            </div>
            <div className="chatB-buttonsbutton4">
              <img
                className="chatB-search-lg-icon"
                loading="lazy"
                alt="More"
                src={dotshorizontal}
              />
            </div>
          </div>
          <button className="chatB-buttonsbutton6" onClick={handleSend}>
            <img className="chatB-placeholder-icon" alt="Send" src={placeholder2} />
            <div className="chatB-text-padding4">
              <div className="chatB-send-label">Send</div>
            </div>
            <img className="chatB-placeholder-icon" alt="Send" src={placeholder2} />
          </button>
        </div>
      </div>
    </div>
  );
};

export { MessageInput };
