import React from 'react';
import "./DetailedWantedService.css"
import { Navbar1 } from '../../../components/Navbar1/Navbar1';
import FooterDetailPage from '../../../components/Footer/FooterDetailPage/FooterDetailPage';
import SlideShowDetailPageComponent from '../../../components/SlideShowDetailPageComponent/SlideShowDetailPageComponent';

import divider from '../../../icons/Gerator/detailed_listing/divider.svg';
import divider2 from '../../../icons/Gerator/detailed_listing/divider2.svg';
import divider3 from '../../../icons/Gerator/detailed_listing/divider3.svg';
import divider4 from '../../../icons/Gerator/detailed_listing/divider4.svg';
import divider5 from '../../../icons/Gerator/detailed_listing/divider5.svg';

import chevronright from '../../../icons/Gerator/detailed_listing/chevronright.svg';



import rectangle_9_2x from '../../../icons/Gerator/detailed_listing/rectangle-9@2x.png';
import rectangle_5_2x from '../../../icons/Gerator/detailed_listing/rectangle-5@2x.png';
import rectangle_43_2x from '../../../icons/Gerator/detailed_listing/rectangle-43@2x.png';
import rectangle_95_2x from '../../../icons/Gerator/detailed_listing/rectangle-95@2x.png';
import rectangle_431_2x from '../../../icons/Gerator/detailed_listing/rectangle-431@2x.png';
import rectangle_196_2x from '../../../icons/Gerator/detailed_listing/rectangle-196@2x.png';

import arrowleft from '../../../icons/Gerator/detailed_listing/arrowleft.svg';
import arrowright from '../../../icons/Gerator/detailed_listing/arrowright.svg';

import breadcrumb_controls_2x from '../../../icons/Gerator/detailed_listing/breadcrumb-controls@2x.png';
import breadcrumb_controls1_2x from '../../../icons/Gerator/detailed_listing/breadcrumb-controls1@2x.png';
import breadcrumb_controls2_2x from '../../../icons/Gerator/detailed_listing/breadcrumb-controls2@2x.png';

import placeholder from '../../../icons/Gerator/detailed_listing/placeholder.svg';
import placeholder1 from '../../../icons/Gerator/detailed_listing/placeholder1.svg';
import placeholder2 from '../../../icons/Gerator/detailed_listing/placeholder2.svg';

import maximize01 from '../../../icons/Gerator/detailed_listing/maximize01.svg';
import markerpin02 from '../../../icons/Gerator/detailed_listing/markerpin02.svg';

import calendar from '../../../icons/Gerator/detailed_listing/calendar.svg';
import calendar1 from '../../../icons/Gerator/detailed_listing/calendar1.svg';

import facebook_1_1_2x from '../../../icons/Gerator/detailed_listing/facebook-1-1@2x.png';

import instagram_1_1_2x from '../../../icons/Gerator/detailed_listing/instagram-1-1@2x.png';
import whatsapp_1_2x from '../../../icons/Gerator/detailed_listing/whatsapp-1@2x.png';
import twitter_1_2x from '../../../icons/Gerator/detailed_listing/twitter-1@2x.png';
import linkedinlogo_1_2x from '../../../icons/Gerator/detailed_listing/linkedinlogo-1@2x.png';


import radar_1_1_2x from '../../../icons/Gerator/detailed_listing/radar-1-1@2x.png';
import deal_2_1_2x from '../../../icons/Gerator/detailed_listing/deal-2-1@2x.png';
import magnifier_1_2x from '../../../icons/Gerator/detailed_listing/magnifier-1@2x.png';
import share_1_2x from '../../../icons/Gerator/detailed_listing/share-1@2x.png';

import group_1010 from '../../../icons/Gerator/detailed_listing/group-1010.svg';
import group_78_2x from '../../../icons/Gerator/detailed_listing/group-78@2x.png';
import group_58_2x from '../../../icons/Gerator/detailed_listing/group-58@2x.png';
import group_781_2x from '../../../icons/Gerator/detailed_listing/group-781@2x.png';

import dotsvertical from '../../../icons/Gerator/detailed_listing/dotsvertical.svg';
import divider1 from '../../../icons/Gerator/detailed_listing/divider1.svg';
import group_351 from '../../../icons/Gerator/detailed_listing/group-351.svg';
import page from '../../../icons/Gerator/detailed_listing/page.svg';
import progress_fill from '../../../icons/Gerator/detailed_listing/progress-fill.svg';
import searchlg from '../../../icons/Gerator/detailed_listing/searchlg.svg';
import filterlines from '../../../icons/Gerator/detailed_listing/filterlines.svg';
import star_background from '../../../icons/Gerator/detailed_listing/star-background.svg';
import star_icon from '../../../icons/Gerator/detailed_listing/star-icon.svg';
import dot1 from '../../../icons/Gerator/detailed_listing/-dot1.svg';
import bookmarkcheck from '../../../icons/Gerator/detailed_listing/bookmarkcheck.svg';
import usercheck02 from '../../../icons/Gerator/detailed_listing/usercheck02.svg';
import f8595f4b711e503bc72fe396e5043e0c2_7_2x from '../../../icons/Gerator/detailed_listing/8595f4b711e503bc72fe396e5043e0c2-7@2x.png';
import messagedotscircle from '../../../icons/Gerator/detailed_listing/messagedotscircle.svg';
import messagechatcircle from '../../../icons/Gerator/detailed_listing/messagechatcircle.svg';

import markerpin021 from '../../../icons/Gerator/detailed_listing/markerpin021.svg';
import icon1 from '../../../icons/Gerator/detailed_listing/icon1.svg';
import login04 from '../../../icons/Gerator/detailed_listing/login04.svg';

export const DetailedWantedService = () => {
  return (
    <div className="detailed-wanted-service-listings-detailed-page-devic">
    <div className="detailed-wanted-service-rectangle-parent">
      <div className="detailed-wanted-service-frame-child"></div>
     <Navbar1/>
      <img className="detailed-wanted-service-divider-icon" alt="" src={divider} />

      <div className="detailed-wanted-service-breadcrumb-button-base">
        <div className="detailed-wanted-service-text6">...</div>
      </div>
      <img
        className="detailed-wanted-service-chevron-right-icon"
        alt=""
        src={chevronright}
      />

      <div className="detailed-wanted-service-breadcrumb-button-base1">
        <div className="detailed-wanted-service-text7">Another link</div>
      </div>
      <img
        className="detailed-wanted-service-chevron-right-icon1"
        alt=""
        src={chevronright}
      />

      <div className="detailed-wanted-service-frame-parent">
        <div className="detailed-wanted-service-breadcrumb-container-parent">
         
          <div className="detailed-wanted-service-breadcrumb">
            <div className="detailed-wanted-service-breadcrumb-items">
              <div className="detailed-wanted-service-breadcrumb-button-base2">
                <div className="detailed-wanted-service-text9">Dashboard</div>
              </div>
              <div className="detailed-wanted-service-chevron-container">
                <img
                  className="detailed-wanted-service-chevron-right-icon2"
                  alt=""
                  src={chevronright}
                />
              </div>
              <div className="detailed-wanted-service-breadcrumb-button-base3">
                <div className="detailed-wanted-service-text10">Wanted Service</div>
              </div>
            </div>
          </div>
        </div>
        <div className="detailed-wanted-service-listing-content-wrapper">
          <div className="detailed-wanted-service-listing-content">
            <div className="detailed-wanted-service-description-block-parent">
              <div className="detailed-wanted-service-description-block">
                <div className="detailed-wanted-service-description-heading">
                  <span className="detailed-wanted-service-pre-">Pre-</span>
                  <span className="detailed-wanted-service-o">o</span>
                  <span className="detailed-wanted-service-pre-">wned, </span>
                  <span className="detailed-wanted-service-o"
                    >USED, FULLY REFURBISHED 1984 gARAVENTA ACCESSIBILITY
                    WRISTVIEW STREPTOCOCCUS A HYALURONIDASE ANTIBODY
                    DETECTION, IDENTIFICATION REAGENTS FOR RENT / LEASE</span
                  >
                </div>
                <div className="detailed-wanted-service-listing-details">
                  <div className="detailed-wanted-service-detail-container">
                    <div className="detailed-wanted-service-details-content">
                      <div className="detailed-wanted-service-details-row">
                        <div className="detailed-wanted-service-detail-items">
                          <img
                            className="detailed-wanted-service-marker-pin-02-icon"
                            alt=""
                            src={markerpin02}
                          />

                          <div className="detailed-wanted-service-supporting-text">India, New Delhi</div>
                        </div>
                        <div className="detailed-wanted-service-detail-items">
                          <img
                            className="detailed-wanted-service-marker-pin-02-icon"
                            alt=""
                            src={calendar}
                          />

                          <div className="detailed-wanted-service-supporting-text">April 9, 2023</div>
                        </div>
                      </div>
                      <div className="detailed-wanted-service-contact-details">
                        <div className="detailed-wanted-service-contact-name-wrapper">
                          <div className="detailed-wanted-service-contact-name">4.9/5</div>
                        </div>
                        <div className="detailed-wanted-service-contact-role">
                          <div className="detailed-wanted-service-supporting-text2">99,999 reviews</div>
                        </div>
                        <div className="detailed-wanted-service-social-links">
                          <div className="detailed-wanted-service-rectangle-container">
                            <div className="detailed-wanted-service-frame-inner"></div>
                            <div className="detailed-wanted-service-icon-backgrounds-parent">
                              <div className="detailed-wanted-service-icon-backgrounds"></div>
                              <img
                                className="detailed-wanted-service-whatsapp-1-icon"
                                alt=""
                                src={facebook_1_1_2x}
                              />
                            </div>
                            <div className="detailed-wanted-service-icon-backgrounds-parent">
                              <div className="detailed-wanted-service-ellipse-div"></div>
                              <img
                                className="detailed-wanted-service-whatsapp-1-icon"
                                alt=""
                                src={instagram_1_1_2x}
                              />
                            </div>
                            <div className="detailed-wanted-service-icon-backgrounds-parent">
                              <div className="detailed-wanted-service-frame-child1"></div>
                              <img
                                className="detailed-wanted-service-whatsapp-1-icon"
                                alt=""
                                src={whatsapp_1_2x}
                              />
                            </div>
                            <div className="detailed-wanted-service-icon-backgrounds-parent">
                              <div className="detailed-wanted-service-icon-backgrounds"></div>
                              <img
                                className="detailed-wanted-service-whatsapp-1-icon"
                                alt=""
                                src={twitter_1_2x}
                              />
                            </div>
                            <div className="detailed-wanted-service-icon-backgrounds-parent">
                              <div className="detailed-wanted-service-ellipse-div"></div>
                              <img
                                className="detailed-wanted-service-whatsapp-1-icon"
                                alt=""
                                src={linkedinlogo_1_2x}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="detailed-wanted-service-button-row">
                      <div className="detailed-wanted-service-buttonsbutton1">
                        <img
                          className="detailed-wanted-service-chevron-down-icon"
                          alt=""
                          src={placeholder1}
                        />

                        <img
                          className="detailed-wanted-service-magnifier-1-icon"
                          alt=""
                          src={radar_1_1_2x}
                        />

                        <div className="detailed-wanted-service-text-padding">
                          <div className="detailed-wanted-service-text11">Add To Radar</div>
                        </div>
                        <img
                          className="detailed-wanted-service-chevron-down-icon"
                          alt=""
                          src={placeholder1}
                        />
                      </div>
                      <div className="detailed-wanted-service-buttonsbutton2">
                        <img
                          className="detailed-wanted-service-placeholder-icon4"
                          alt=""
                          src={placeholder1}
                        />

                        <img
                          className="detailed-wanted-service-magnifier-1-icon"
                          alt=""
                          src={deal_2_1_2x}
                        />

                        <img
                          className="detailed-wanted-service-placeholder-icon4"
                          alt=""
                          src={placeholder1}
                        />
                      </div>
                      <div className="detailed-wanted-service-buttonsbutton2">
                        <img
                          className="detailed-wanted-service-placeholder-icon4"
                          alt=""
                          src={placeholder1}
                        />

                        <img
                          className="detailed-wanted-service-magnifier-1-icon"
                          alt=""
                          src={magnifier_1_2x}
                        />

                        <img
                          className="detailed-wanted-service-placeholder-icon4"
                          alt=""
                          src={placeholder1}
                        />
                      </div>
                      <div className="detailed-wanted-service-buttonsbutton2">
                        <img
                          className="detailed-wanted-service-placeholder-icon4"
                          alt=""
                          src={placeholder1}
                        />

                        <img
                          className="detailed-wanted-service-magnifier-1-icon"
                          alt=""
                          src={share_1_2x}
                        />

                        <img
                          className="detailed-wanted-service-placeholder-icon4"
                          alt=""
                          src={placeholder1}
                        />
                      </div>
                      <img
                        className="detailed-wanted-service-button-row-child"
                        alt=""
                        src={group_1010}
                      />
                    </div>
                  </div>
                  <div className="detailed-wanted-service-dropdown-container">
                    <div className="detailed-wanted-service-dropdown">
                      <img
                        className="detailed-wanted-service-maximize-01-icon"
                        alt=""
                        src={dotsvertical}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <img className="detailed-wanted-service-divider-icon" alt="" src={divider1} />

              <div className="detailed-wanted-service-heading-parent">
                <div className="detailed-wanted-service-heading">Highlights</div>
                <div className="detailed-wanted-service-description-content">
                  <div className="detailed-wanted-service-description-columns-first">
                    <div className="detailed-wanted-service-first-columns-paragraphs">
                      <div className="detailed-wanted-service-paragraph">
                        Anti-Hyaluronidase (AHT) Streptococcus A Antibody
                        Detection, Identification Reagents
                      </div>
                      <div className="detailed-wanted-service-paragraph1">Service Category</div>
                    </div>
                    <div className="detailed-wanted-service-first-columns-paragraphs">
                      <div className="detailed-wanted-service-paragraph">March 1, 2021</div>
                      <div className="detailed-wanted-service-paragraph1">Device Use</div>
                    </div>
                    <div className="detailed-wanted-service-first-columns-paragraphs">
                      <div className="detailed-wanted-service-paragraph">Anatomy, Physiology</div>
                      <div className="detailed-wanted-service-paragraph1">Clinical Applications</div>
                    </div>
                  </div>
                  <div className="detailed-wanted-service-description-columns-second">
                    <div className="detailed-wanted-service-second-columns-paragraphs">
                      <div className="detailed-wanted-service-paragraph">
                        Fittydent-Altwirth & Schmitt
                      </div>
                      <div className="detailed-wanted-service-paragraph1">Device Category</div>
                    </div>
                    <div className="detailed-wanted-service-second-columns-paragraphs">
                      <div className="detailed-wanted-service-paragraph">AB9887727</div>
                      <div className="detailed-wanted-service-paragraph1">Physical Location</div>
                    </div>
                    <div className="detailed-wanted-service-second-columns-paragraphs">
                      <div className="detailed-wanted-service-paragraph">
                        Patient, Condition, Disease, Diagnosis
                      </div>
                      <div className="detailed-wanted-service-paragraph1">Model Name</div>
                    </div>

                    
                  
                   
                  </div>
                </div>
              </div>
            
              
            
           
            </div>
            <div className="detailed-wanted-service-service-card">
              <div className="detailed-wanted-service-card-content">
                <div className="detailed-wanted-service-card-content-child"></div>
                <div className="detailed-wanted-service-card-header">
                  <div className="detailed-wanted-service-price-tag">
                    <div className="detailed-wanted-service-badge-wrapper">
                      <div className="detailed-wanted-service-badge3">
                        <img
                          className="detailed-wanted-service-dot-icon3"
                          alt=""
                          src={dot1}
                        />

                        <div className="detailed-wanted-service-text29">Sale</div>
                      </div>
                    </div>
                    <div className="detailed-wanted-service-price-parent">
                      <div className="detailed-wanted-service-price">$9,999,999.99</div>
                      <div className="detailed-wanted-service-original-price">
                        <div className="detailed-wanted-service-discount">USD - Per Unit</div>
                      </div>
                    </div>
                    <div className="detailed-wanted-service-service-title-container">
                      <div className="detailed-wanted-service-service-title">Posted By Manufacturer</div>
                    </div>
                  </div>
                </div>
                <div className="detailed-wanted-service-card-footer">
                  <img
                    className="detailed-wanted-service-card-footer-child"
                    alt=""
                    src={group_781_2x}
                  />

                  <div className="detailed-wanted-service-footer-divider">
                    <img
                      className="detailed-wanted-service-divider-icon4"
                      alt=""
                      src={divider2}
                    />
                  </div>
                </div>
                <div className="detailed-wanted-service-professional-info">
                  <div className="detailed-wanted-service-professional-details">
                    <div className="detailed-wanted-service-professional-actions">
                      <div className="detailed-wanted-service-benefits-container">
                        <div className="detailed-wanted-service-amlie-laurent">Olivia Rhye</div>
                        <div className="detailed-wanted-service-role">Adani Health</div>
                      </div>
                      <div className="detailed-wanted-service-action-buttons">
                        <div className="detailed-wanted-service-contact-button">
                          <div className="detailed-wanted-service-message-button">
                            <div className="detailed-wanted-service-navigation">
                              <img
                                className="detailed-wanted-service-bookmark-check-icon"
                                alt=""
                                src={bookmarkcheck}
                              />
                            </div>
                            <div className="detailed-wanted-service-supporting-text10">
                              Verified by Gerätor
                            </div>
                          </div>
                        </div>
                        <div className="detailed-wanted-service-supporting-text11">
                          Adani Health conforms to Gerätor's requirements for
                          verification
                        </div>
                      </div>
                      <div className="detailed-wanted-service-favorite-button">
                        <div className="detailed-wanted-service-button-container2">
                          <div className="detailed-wanted-service-buttonsbutton5">
                            <img
                              className="detailed-wanted-service-filter-lines-icon"
                              alt=""
                              src={messagechatcircle}
                            />

                            <div className="detailed-wanted-service-text30">Private Message</div>
                          </div>
                          <div className="detailed-wanted-service-buttonsbutton6">
                            <img
                              className="detailed-wanted-service-search-lg-icon"
                              alt=""
                              src={usercheck02}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="detailed-wanted-service-dropdown-menu">
                      <div className="detailed-wanted-service-dropdown1">
                        <img
                          className="detailed-wanted-service-maximize-01-icon"
                          alt=""
                          src={dotsvertical}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="detailed-wanted-service-rectangle-parent4">
                <div className="detailed-wanted-service-frame-child6"></div>
                <div className="detailed-wanted-service-testimonial-header">
                  <div className="detailed-wanted-service-header-content1">
                    <div className="detailed-wanted-service-heading23">Jobs</div>
                    <div className="detailed-wanted-service-testimonial-description">
                      <div className="detailed-wanted-service-description">View All</div>
                    </div>
                  </div>
                </div>
                <div className="detailed-wanted-service-testimonial-list">
                  <img
                    className="detailed-wanted-service-divider-icon"
                    alt=""
                    src={divider2}
                  />

                  <div className="detailed-wanted-service-testimonial-divider">
                    <div className="detailed-wanted-service-testimonial-item">
                      <div className="detailed-wanted-service-rectangle-parent5">
                        <div className="detailed-wanted-service-frame-child7"></div>
                        <img
                          className="detailed-wanted-service-f4b711e503bc72fe396e5043e0c2-7-icon"
                          alt=""
                          src="/detailed_listing/8595f4b711e503bc72fe396e5043e0c2-7@2x.png"
                        />
                      </div>
                      <div className="detailed-wanted-service-testimonial-details">
                        <div className="detailed-wanted-service-client-name">
                          WHI Program assistant / Undergraduate researcher
                        </div>
                        <div className="detailed-wanted-service-heading24">Marketing Fusion SARL</div>
                      </div>
                    </div>
                  </div>
                  <img
                    className="detailed-wanted-service-divider-icon"
                    alt=""
                    src={divider2}
                  />

                  <div className="detailed-wanted-service-testimonial-divider">
                    <div className="detailed-wanted-service-testimonial-item">
                      <div className="detailed-wanted-service-rectangle-parent5">
                        <div className="detailed-wanted-service-frame-child7"></div>
                        <img
                          className="detailed-wanted-service-f4b711e503bc72fe396e5043e0c2-7-icon"
                          alt=""
                          src="/detailed_listing/8595f4b711e503bc72fe396e5043e0c2-7@2x.png"
                        />
                      </div>
                      <div className="detailed-wanted-service-testimonial-details">
                        <div className="detailed-wanted-service-client-name">
                          WHI Program assistant / Undergraduate researcher
                        </div>
                        <div className="detailed-wanted-service-heading24">Marketing Fusion SARL</div>
                      </div>
                    </div>
                  </div>
                  <img
                    className="detailed-wanted-service-divider-icon"
                    alt=""
                    src={divider2}
                  />

                  <div className="detailed-wanted-service-testimonial-divider">
                    <div className="detailed-wanted-service-testimonial-item">
                      <div className="detailed-wanted-service-rectangle-parent5">
                        <div className="detailed-wanted-service-frame-child7"></div>
                        <img
                          className="detailed-wanted-service-f4b711e503bc72fe396e5043e0c2-7-icon"
                          alt=""
                          src="/detailed_listing/8595f4b711e503bc72fe396e5043e0c2-7@2x.png"
                        />
                      </div>
                      <div className="detailed-wanted-service-testimonial-details">
                        <div className="detailed-wanted-service-client-name">
                          WHI Program assistant / Undergraduate researcher
                        </div>
                        <div className="detailed-wanted-service-heading24">Marketing Fusion SARL</div>
                      </div>
                    </div>
                  </div>
                  <img
                    className="detailed-wanted-service-divider-icon"
                    alt=""
                    src={divider2}
                  />

                  <div className="detailed-wanted-service-testimonial-divider">
                    <div className="detailed-wanted-service-testimonial-item">
                      <div className="detailed-wanted-service-rectangle-parent5">
                        <div className="detailed-wanted-service-frame-child7"></div>
                        <img
                          className="detailed-wanted-service-f4b711e503bc72fe396e5043e0c2-7-icon"
                          alt=""
                          src="/detailed_listing/8595f4b711e503bc72fe396e5043e0c2-7@2x.png"
                        />
                      </div>
                      <div className="detailed-wanted-service-testimonial-details">
                        <div className="detailed-wanted-service-client-name">
                          WHI Program assistant / Undergraduate researcher
                        </div>
                        <div className="detailed-wanted-service-heading24">Marketing Fusion SARL</div>
                      </div>
                    </div>
                  </div>
                  <img
                    className="detailed-wanted-service-divider-icon"
                    alt=""
                    src={divider2}
                  />

                  <div className="detailed-wanted-service-testimonial-divider">
                    <div className="detailed-wanted-service-testimonial-item">
                      <div className="detailed-wanted-service-rectangle-parent5">
                        <div className="detailed-wanted-service-frame-child7"></div>
                        <img
                          className="detailed-wanted-service-f4b711e503bc72fe396e5043e0c2-7-icon"
                          alt=""
                          src="/detailed_listing/8595f4b711e503bc72fe396e5043e0c2-7@2x.png"
                        />
                      </div>
                      <div className="detailed-wanted-service-testimonial-details">
                        <div className="detailed-wanted-service-client-name">
                          WHI Program assistant / Undergraduate researcher
                        </div>
                        <div className="detailed-wanted-service-heading24">Marketing Fusion SARL</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
   
{/*       
      <div className="detailed-wanted-service-listing-image-grid3">
        <img className="detailed-wanted-service-divider-icon11" alt="" src="/detailed_listing/divider3.svg" />
      </div>
      <div className="detailed-wanted-service-listing-image-grid4">
        <div className="detailed-wanted-service-heading-parent3">
          <div className="detailed-wanted-service-heading36">Related Devices</div>
          <div className="detailed-wanted-service-text-wrapper">
            <div className="detailed-wanted-service-description">View All</div>
          </div>
        </div>
      </div>
      <div className="detailed-wanted-service-listing-image-grid5">
        <div className="detailed-wanted-service-frame-parent4">
          <div className="detailed-wanted-service-frame-parent5">
            <div className="detailed-wanted-service-rectangle-parent10">
              <div className="detailed-wanted-service-frame-child12"></div>
              <div className="detailed-wanted-service-listing-card-badge-overlay">
                <img
                  className="detailed-wanted-service-listing-card-badge-overlay-child"
                  alt=""
                  src="/detailed_listing/rectangle-95@2x.png"
                />

                <div className="detailed-wanted-service-image-overlay">
                  <div className="detailed-wanted-service-badge4">
                    <div className="detailed-wanted-service-listing-card-price">Assured</div>
                  </div>
                  <div className="detailed-wanted-service-short-listing-team">
                    <div className="detailed-wanted-service-badge5">
                      <div className="detailed-wanted-service-listing-card-radar">ID# AXJYN</div>
                    </div>
                    <div className="detailed-wanted-service-buttonsbutton11">
                      <img
                        className="detailed-wanted-service-magnifier-1-icon"
                        alt=""
                        src={radar_1_1_2x}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="detailed-wanted-service-listing-detail-item">
                <div className="detailed-wanted-service-message-content">
                  <div className="detailed-wanted-service-text-frame">
                    <div className="detailed-wanted-service-text42">
                      New / Surplus 2021 Philips Health-care HD11 XE
                      (P/N:23A...
                    </div>
                  </div>
                  <div className="detailed-wanted-service-listing-detail-item-value">
                    <div className="detailed-wanted-service-listing-detail-item-pin-wrapper">
                      <div className="detailed-wanted-service-heading-container">
                        <div className="detailed-wanted-service-listing-detail-item-location">
                          <div className="detailed-wanted-service-listing-detail-item-date-parent">
                            <div className="detailed-wanted-service-listing-detail-item-date">
                              <img
                                className="detailed-wanted-service-chevron-right-icon2"
                                alt=""
                                src="/detailed_listing/markerpin021.svg"
                              />
                            </div>
                            <div className="detailed-wanted-service-location">India, New Delhi</div>
                          </div>
                          <div className="detailed-wanted-service-listing-detail-item-date-parent">
                            <div className="detailed-wanted-service-listing-detail-item-date">
                              <img
                                className="detailed-wanted-service-chevron-right-icon2"
                                alt=""
                                src="/detailed_listing/calendar1.svg"
                              />
                            </div>
                            <div className="detailed-wanted-service-type-of-work">April 09, 2023</div>
                          </div>
                        </div>
                        <div className="detailed-wanted-service-listing-team">
                          <div className="detailed-wanted-service-listing-team-member-wrapper">
                            <div className="detailed-wanted-service-listing-team-member">4.9/5</div>
                          </div>
                          <div className="detailed-wanted-service-contact-role">
                            <div className="detailed-wanted-service-supporting-text12">
                              99,999 reviews
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="detailed-wanted-service-listing">
                      <div className="detailed-wanted-service-breadcrumb-items">
                        <div className="detailed-wanted-service-listing-short-info">$9,999,999.99</div>
                        <div className="detailed-wanted-service-supporting-text-wrapper">
                          <div className="detailed-wanted-service-supporting-text13">USD-Per Unit</div>
                        </div>
                      </div>
                    </div>
                    <img
                      className="detailed-wanted-service-divider-icon"
                      alt=""
                      src="/detailed_listing/divider4.svg"
                    />

                    <div className="detailed-wanted-service-listing-detail-item-value-inner">
                      <div className="detailed-wanted-service-footer-social-parent">
                        <div className="detailed-wanted-service-footer-social">
                          <img
                            className="detailed-wanted-service-footer-social-child"
                            alt=""
                            src="/detailed_listing/group-58@2x.png"
                          />

                          <div className="detailed-wanted-service-listing-short-detail-item-pin-parent">
                            <div className="detailed-wanted-service-listing-short-detail-item-pin">
                              <div className="detailed-wanted-service-name">Olivia Rhye</div>
                            </div>
                            <div className="detailed-wanted-service-role1">Business Name</div>
                          </div>
                        </div>
                        <div className="detailed-wanted-service-listing-short-detail-item-date">
                          <img
                            className="detailed-wanted-service-icon1"
                            alt=""
                            src="/detailed_listing/icon1.svg"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="detailed-wanted-service-rectangle-parent10">
              <div className="detailed-wanted-service-frame-child12"></div>
              <div className="detailed-wanted-service-listing-card-badge-overlay">
                <img
                  className="detailed-wanted-service-listing-card-badge-overlay-child"
                  alt=""
                  src="/detailed_listing/rectangle-95@2x.png"
                />

                <div className="detailed-wanted-service-image-overlay">
                  <div className="detailed-wanted-service-badge4">
                    <div className="detailed-wanted-service-listing-card-price">Assured</div>
                  </div>
                  <div className="detailed-wanted-service-short-listing-team">
                    <div className="detailed-wanted-service-badge5">
                      <div className="detailed-wanted-service-listing-card-radar">ID# AXJYN</div>
                    </div>
                    <div className="detailed-wanted-service-buttonsbutton11">
                      <img
                        className="detailed-wanted-service-magnifier-1-icon"
                        alt=""
                        src={radar_1_1_2x}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="detailed-wanted-service-listing-detail-item">
                <div className="detailed-wanted-service-message-content">
                  <div className="detailed-wanted-service-text-frame">
                    <div className="detailed-wanted-service-text42">
                      New / Surplus 2021 Philips Health-care HD11 XE
                      (P/N:23A...
                    </div>
                  </div>
                  <div className="detailed-wanted-service-listing-detail-item-value">
                    <div className="detailed-wanted-service-listing-detail-item-pin-wrapper">
                      <div className="detailed-wanted-service-heading-container">
                        <div className="detailed-wanted-service-listing-detail-item-location">
                          <div className="detailed-wanted-service-listing-detail-item-date-parent">
                            <div className="detailed-wanted-service-listing-detail-item-date">
                              <img
                                className="detailed-wanted-service-chevron-right-icon2"
                                alt=""
                                src="/detailed_listing/markerpin021.svg"
                              />
                            </div>
                            <div className="detailed-wanted-service-location">India, New Delhi</div>
                          </div>
                          <div className="detailed-wanted-service-listing-detail-item-date-parent">
                            <div className="detailed-wanted-service-listing-detail-item-date">
                              <img
                                className="detailed-wanted-service-chevron-right-icon2"
                                alt=""
                                src="/detailed_listing/calendar1.svg"
                              />
                            </div>
                            <div className="detailed-wanted-service-type-of-work">April 09, 2023</div>
                          </div>
                        </div>
                        <div className="detailed-wanted-service-listing-team">
                          <div className="detailed-wanted-service-listing-team-member-wrapper">
                            <div className="detailed-wanted-service-listing-team-member">4.9/5</div>
                          </div>
                          <div className="detailed-wanted-service-contact-role">
                            <div className="detailed-wanted-service-supporting-text12">
                              99,999 reviews
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="detailed-wanted-service-listing">
                      <div className="detailed-wanted-service-breadcrumb-items">
                        <div className="detailed-wanted-service-listing-short-info">$9,999,999.99</div>
                        <div className="detailed-wanted-service-supporting-text-wrapper">
                          <div className="detailed-wanted-service-supporting-text13">USD-Per Unit</div>
                        </div>
                      </div>
                    </div>
                    <img
                      className="detailed-wanted-service-divider-icon"
                      alt=""
                      src="/detailed_listing/divider4.svg"
                    />

                    <div className="detailed-wanted-service-listing-detail-item-value-inner">
                      <div className="detailed-wanted-service-footer-social-parent">
                        <div className="detailed-wanted-service-footer-social">
                          <img
                            className="detailed-wanted-service-footer-social-child"
                            alt=""
                            src="/detailed_listing/group-58@2x.png"
                          />

                          <div className="detailed-wanted-service-listing-short-detail-item-pin-parent">
                            <div className="detailed-wanted-service-listing-short-detail-item-pin">
                              <div className="detailed-wanted-service-name">Olivia Rhye</div>
                            </div>
                            <div className="detailed-wanted-service-role1">Business Name</div>
                          </div>
                        </div>
                        <div className="detailed-wanted-service-listing-short-detail-item-date">
                          <img
                            className="detailed-wanted-service-icon1"
                            alt=""
                            src="/detailed_listing/icon1.svg"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="detailed-wanted-service-rectangle-parent10">
              <div className="detailed-wanted-service-frame-child12"></div>
              <div className="detailed-wanted-service-listing-card-badge-overlay">
                <img
                  className="detailed-wanted-service-listing-card-badge-overlay-child"
                  alt=""
                  src="/detailed_listing/rectangle-95@2x.png"
                />

                <div className="detailed-wanted-service-image-overlay">
                  <div className="detailed-wanted-service-badge4">
                    <div className="detailed-wanted-service-listing-card-price">Assured</div>
                  </div>
                  <div className="detailed-wanted-service-short-listing-team">
                    <div className="detailed-wanted-service-badge5">
                      <div className="detailed-wanted-service-listing-card-radar">ID# AXJYN</div>
                    </div>
                    <div className="detailed-wanted-service-buttonsbutton11">
                      <img
                        className="detailed-wanted-service-magnifier-1-icon"
                        alt=""
                        src={radar_1_1_2x}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="detailed-wanted-service-listing-detail-item">
                <div className="detailed-wanted-service-message-content">
                  <div className="detailed-wanted-service-text-frame">
                    <div className="detailed-wanted-service-text42">
                      New / Surplus 2021 Philips Health-care HD11 XE
                      (P/N:23A...
                    </div>
                  </div>
                  <div className="detailed-wanted-service-listing-detail-item-value">
                    <div className="detailed-wanted-service-listing-detail-item-pin-wrapper">
                      <div className="detailed-wanted-service-heading-container">
                        <div className="detailed-wanted-service-listing-detail-item-location">
                          <div className="detailed-wanted-service-listing-detail-item-date-parent">
                            <div className="detailed-wanted-service-listing-detail-item-date">
                              <img
                                className="detailed-wanted-service-chevron-right-icon2"
                                alt=""
                                src="/detailed_listing/markerpin021.svg"
                              />
                            </div>
                            <div className="detailed-wanted-service-location">India, New Delhi</div>
                          </div>
                          <div className="detailed-wanted-service-listing-detail-item-date-parent">
                            <div className="detailed-wanted-service-listing-detail-item-date">
                              <img
                                className="detailed-wanted-service-chevron-right-icon2"
                                alt=""
                                src="/detailed_listing/calendar1.svg"
                              />
                            </div>
                            <div className="detailed-wanted-service-type-of-work">April 09, 2023</div>
                          </div>
                        </div>
                        <div className="detailed-wanted-service-listing-team">
                          <div className="detailed-wanted-service-listing-team-member-wrapper">
                            <div className="detailed-wanted-service-listing-team-member">4.9/5</div>
                          </div>
                          <div className="detailed-wanted-service-contact-role">
                            <div className="detailed-wanted-service-supporting-text12">
                              99,999 reviews
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="detailed-wanted-service-listing">
                      <div className="detailed-wanted-service-breadcrumb-items">
                        <div className="detailed-wanted-service-listing-short-info">$9,999,999.99</div>
                        <div className="detailed-wanted-service-supporting-text-wrapper">
                          <div className="detailed-wanted-service-supporting-text13">USD-Per Unit</div>
                        </div>
                      </div>
                    </div>
                    <img
                      className="detailed-wanted-service-divider-icon"
                      alt=""
                      src="/detailed_listing/divider4.svg"
                    />

                    <div className="detailed-wanted-service-listing-detail-item-value-inner">
                      <div className="detailed-wanted-service-footer-social-parent">
                        <div className="detailed-wanted-service-footer-social">
                          <img
                            className="detailed-wanted-service-footer-social-child"
                            alt=""
                            src="/detailed_listing/group-58@2x.png"
                          />

                          <div className="detailed-wanted-service-listing-short-detail-item-pin-parent">
                            <div className="detailed-wanted-service-listing-short-detail-item-pin">
                              <div className="detailed-wanted-service-name">Olivia Rhye</div>
                            </div>
                            <div className="detailed-wanted-service-role1">Business Name</div>
                          </div>
                        </div>
                        <div className="detailed-wanted-service-listing-short-detail-item-date">
                          <img
                            className="detailed-wanted-service-icon1"
                            alt=""
                            src="/detailed_listing/icon1.svg"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="detailed-wanted-service-rectangle-parent10">
              <div className="detailed-wanted-service-frame-child12"></div>
              <div className="detailed-wanted-service-listing-card-badge-overlay">
                <img
                  className="detailed-wanted-service-listing-card-badge-overlay-child"
                  alt=""
                  src="/detailed_listing/rectangle-95@2x.png"
                />

                <div className="detailed-wanted-service-image-overlay3">
                  <div className="detailed-wanted-service-badge4">
                    <div className="detailed-wanted-service-listing-card-price">Assured</div>
                  </div>
                  <div className="detailed-wanted-service-badge-group">
                    <div className="detailed-wanted-service-badge5">
                      <div className="detailed-wanted-service-listing-card-radar">ID# AXJYN</div>
                    </div>
                    <div className="detailed-wanted-service-buttonsbutton14">
                      <img
                        className="detailed-wanted-service-magnifier-1-icon"
                        alt=""
                        src={radar_1_1_2x}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="detailed-wanted-service-listing-detail-item">
                <div className="detailed-wanted-service-message-content">
                  <div className="detailed-wanted-service-text-frame">
                    <div className="detailed-wanted-service-text42">
                      New / Surplus 2021 Philips Health-care HD11 XE
                      (P/N:23A...
                    </div>
                  </div>
                  <div className="detailed-wanted-service-work-four-wrapper">
                    <div className="detailed-wanted-service-heading-container">
                      <div className="detailed-wanted-service-listing-detail-item-location">
                        <div className="detailed-wanted-service-listing-detail-item-date-parent">
                          <div className="detailed-wanted-service-listing-detail-item-date">
                            <img
                              className="detailed-wanted-service-chevron-right-icon2"
                              alt=""
                              src="/detailed_listing/markerpin021.svg"
                            />
                          </div>
                          <div className="detailed-wanted-service-location">India, New Delhi</div>
                        </div>
                        <div className="detailed-wanted-service-listing-detail-item-date-parent">
                          <div className="detailed-wanted-service-listing-detail-item-date">
                            <img
                              className="detailed-wanted-service-chevron-right-icon2"
                              alt=""
                              src="/detailed_listing/calendar1.svg"
                            />
                          </div>
                          <div className="detailed-wanted-service-type-of-work">April 09, 2023</div>
                        </div>
                      </div>
                      <div className="detailed-wanted-service-listing-team">
                        <div className="detailed-wanted-service-listing-team-member-wrapper">
                          <div className="detailed-wanted-service-listing-team-member">4.9/5</div>
                        </div>
                        <div className="detailed-wanted-service-contact-role">
                          <div className="detailed-wanted-service-supporting-text12">99,999 reviews</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="detailed-wanted-service-agent-details-icon">
                    <img className="detailed-wanted-service-icon4" alt="" src="/detailed_listing/icon1.svg" />

                    <div className="detailed-wanted-service-agent-extra-info">
                      <div className="detailed-wanted-service-listing-detail-item-pin-wrapper">
                        <div className="detailed-wanted-service-breadcrumb-items">
                          <div className="detailed-wanted-service-listing-short-info">$9,999,999.99</div>
                          <div className="detailed-wanted-service-supporting-text-wrapper">
                            <div className="detailed-wanted-service-supporting-text13">USD-Per Unit</div>
                          </div>
                        </div>
                      </div>
                      <img
                        className="detailed-wanted-service-divider-icon"
                        alt=""
                        src="/detailed_listing/divider4.svg"
                      />

                      <div className="detailed-wanted-service-another-agent-info">
                        <div className="detailed-wanted-service-detail-items">
                          <img
                            className="detailed-wanted-service-footer-social-child"
                            alt=""
                            src="/detailed_listing/group-58@2x.png"
                          />

                          <div className="detailed-wanted-service-listing-short-detail-item-pin-parent">
                            <div className="detailed-wanted-service-listing-short-detail-item-pin">
                              <div className="detailed-wanted-service-name">Olivia Rhye</div>
                            </div>
                            <div className="detailed-wanted-service-role1">Business Name</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="detailed-wanted-service-testiomonial-carousel-arrow2">
              <img
                className="detailed-wanted-service-arrow-left-icon"
                alt=""
                src="/detailed_listing/arrowleft.svg"
              />
            </div>
          </div>
          <div className="detailed-wanted-service-testiomonial-carousel-arrow3">
            <img
              className="detailed-wanted-service-arrow-left-icon"
              alt=""
              src="/detailed_listing/arrowright.svg"
            />
          </div>
        </div>
      </div>
      <div className="detailed-wanted-service-listing-image-grid4">
        <div className="detailed-wanted-service-heading-parent3">
          <div className="detailed-wanted-service-heading36">Related Spares</div>
          <div className="detailed-wanted-service-testimonial-description">
            <div className="detailed-wanted-service-description">View All</div>
          </div>
        </div>
      </div>
      <div className="detailed-wanted-service-listing-image-grid5">
        <div className="detailed-wanted-service-frame-parent4">
          <div className="detailed-wanted-service-frame-parent5">
            <div className="detailed-wanted-service-rectangle-parent10">
              <div className="detailed-wanted-service-frame-child12"></div>
              <div className="detailed-wanted-service-listing-card-badge-overlay">
                <img
                  className="detailed-wanted-service-listing-card-badge-overlay-child"
                  alt=""
                  src="/detailed_listing/rectangle-95@2x.png"
                />

                <div className="detailed-wanted-service-image-overlay">
                  <div className="detailed-wanted-service-badge4">
                    <div className="detailed-wanted-service-listing-card-price">Assured</div>
                  </div>
                  <div className="detailed-wanted-service-short-listing-team">
                    <div className="detailed-wanted-service-badge5">
                      <div className="detailed-wanted-service-listing-card-radar">ID# AXJYN</div>
                    </div>
                    <div className="detailed-wanted-service-buttonsbutton11">
                      <img
                        className="detailed-wanted-service-magnifier-1-icon"
                        alt=""
                        src={radar_1_1_2x}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="detailed-wanted-service-listing-detail-item">
                <div className="detailed-wanted-service-message-content">
                  <div className="detailed-wanted-service-text-frame">
                    <div className="detailed-wanted-service-text42">
                      New / Surplus 2021 Philips Health-care HD11 XE
                      (P/N:23A...
                    </div>
                  </div>
                  <div className="detailed-wanted-service-listing-detail-item-value">
                    <div className="detailed-wanted-service-listing-detail-item-pin-wrapper">
                      <div className="detailed-wanted-service-heading-container">
                        <div className="detailed-wanted-service-listing-detail-item-location">
                          <div className="detailed-wanted-service-listing-detail-item-date-parent">
                            <div className="detailed-wanted-service-listing-detail-item-date">
                              <img
                                className="detailed-wanted-service-chevron-right-icon2"
                                alt=""
                                src="/detailed_listing/markerpin021.svg"
                              />
                            </div>
                            <div className="detailed-wanted-service-location">India, New Delhi</div>
                          </div>
                          <div className="detailed-wanted-service-listing-detail-item-date-parent">
                            <div className="detailed-wanted-service-listing-detail-item-date">
                              <img
                                className="detailed-wanted-service-chevron-right-icon2"
                                alt=""
                                src="/detailed_listing/calendar1.svg"
                              />
                            </div>
                            <div className="detailed-wanted-service-type-of-work">April 09, 2023</div>
                          </div>
                        </div>
                        <div className="detailed-wanted-service-listing-team">
                          <div className="detailed-wanted-service-listing-team-member-wrapper">
                            <div className="detailed-wanted-service-listing-team-member">4.9/5</div>
                          </div>
                          <div className="detailed-wanted-service-contact-role">
                            <div className="detailed-wanted-service-supporting-text12">
                              99,999 reviews
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="detailed-wanted-service-listing">
                      <div className="detailed-wanted-service-breadcrumb-items">
                        <div className="detailed-wanted-service-listing-short-info">$9,999,999.99</div>
                        <div className="detailed-wanted-service-supporting-text-wrapper">
                          <div className="detailed-wanted-service-supporting-text13">USD-Per Unit</div>
                        </div>
                      </div>
                    </div>
                    <img
                      className="detailed-wanted-service-divider-icon"
                      alt=""
                      src="/detailed_listing/divider4.svg"
                    />

                    <div className="detailed-wanted-service-listing-detail-item-value-inner">
                      <div className="detailed-wanted-service-footer-social-parent">
                        <div className="detailed-wanted-service-footer-social">
                          <img
                            className="detailed-wanted-service-footer-social-child"
                            alt=""
                            src="/detailed_listing/group-58@2x.png"
                          />

                          <div className="detailed-wanted-service-listing-short-detail-item-pin-parent">
                            <div className="detailed-wanted-service-listing-short-detail-item-pin">
                              <div className="detailed-wanted-service-name">Olivia Rhye</div>
                            </div>
                            <div className="detailed-wanted-service-role1">Business Name</div>
                          </div>
                        </div>
                        <div className="detailed-wanted-service-listing-short-detail-item-date">
                          <img
                            className="detailed-wanted-service-icon1"
                            alt=""
                            src="/detailed_listing/icon1.svg"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="detailed-wanted-service-rectangle-parent10">
              <div className="detailed-wanted-service-frame-child12"></div>
              <div className="detailed-wanted-service-listing-card-badge-overlay">
                <img
                  className="detailed-wanted-service-listing-card-badge-overlay-child"
                  alt=""
                  src="/detailed_listing/rectangle-95@2x.png"
                />

                <div className="detailed-wanted-service-image-overlay">
                  <div className="detailed-wanted-service-badge4">
                    <div className="detailed-wanted-service-listing-card-price">Assured</div>
                  </div>
                  <div className="detailed-wanted-service-short-listing-team">
                    <div className="detailed-wanted-service-badge5">
                      <div className="detailed-wanted-service-listing-card-radar">ID# AXJYN</div>
                    </div>
                    <div className="detailed-wanted-service-buttonsbutton11">
                      <img
                        className="detailed-wanted-service-magnifier-1-icon"
                        alt=""
                        src={radar_1_1_2x}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="detailed-wanted-service-listing-detail-item">
                <div className="detailed-wanted-service-message-content">
                  <div className="detailed-wanted-service-text-frame">
                    <div className="detailed-wanted-service-text42">
                      New / Surplus 2021 Philips Health-care HD11 XE
                      (P/N:23A...
                    </div>
                  </div>
                  <div className="detailed-wanted-service-listing-detail-item-value">
                    <div className="detailed-wanted-service-listing-detail-item-pin-wrapper">
                      <div className="detailed-wanted-service-heading-container">
                        <div className="detailed-wanted-service-listing-detail-item-location">
                          <div className="detailed-wanted-service-listing-detail-item-date-parent">
                            <div className="detailed-wanted-service-listing-detail-item-date">
                              <img
                                className="detailed-wanted-service-chevron-right-icon2"
                                alt=""
                                src="/detailed_listing/markerpin021.svg"
                              />
                            </div>
                            <div className="detailed-wanted-service-location">India, New Delhi</div>
                          </div>
                          <div className="detailed-wanted-service-listing-detail-item-date-parent">
                            <div className="detailed-wanted-service-listing-detail-item-date">
                              <img
                                className="detailed-wanted-service-chevron-right-icon2"
                                alt=""
                                src="/detailed_listing/calendar1.svg"
                              />
                            </div>
                            <div className="detailed-wanted-service-type-of-work">April 09, 2023</div>
                          </div>
                        </div>
                        <div className="detailed-wanted-service-listing-team">
                          <div className="detailed-wanted-service-listing-team-member-wrapper">
                            <div className="detailed-wanted-service-listing-team-member">4.9/5</div>
                          </div>
                          <div className="detailed-wanted-service-contact-role">
                            <div className="detailed-wanted-service-supporting-text12">
                              99,999 reviews
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="detailed-wanted-service-listing">
                      <div className="detailed-wanted-service-breadcrumb-items">
                        <div className="detailed-wanted-service-listing-short-info">$9,999,999.99</div>
                        <div className="detailed-wanted-service-supporting-text-wrapper">
                          <div className="detailed-wanted-service-supporting-text13">USD-Per Unit</div>
                        </div>
                      </div>
                    </div>
                    <img
                      className="detailed-wanted-service-divider-icon"
                      alt=""
                      src="/detailed_listing/divider4.svg"
                    />

                    <div className="detailed-wanted-service-listing-detail-item-value-inner">
                      <div className="detailed-wanted-service-footer-social-parent">
                        <div className="detailed-wanted-service-footer-social">
                          <img
                            className="detailed-wanted-service-footer-social-child"
                            alt=""
                            src="/detailed_listing/group-58@2x.png"
                          />

                          <div className="detailed-wanted-service-listing-short-detail-item-pin-parent">
                            <div className="detailed-wanted-service-listing-short-detail-item-pin">
                              <div className="detailed-wanted-service-name">Olivia Rhye</div>
                            </div>
                            <div className="detailed-wanted-service-role1">Business Name</div>
                          </div>
                        </div>
                        <div className="detailed-wanted-service-listing-short-detail-item-date">
                          <img
                            className="detailed-wanted-service-icon1"
                            alt=""
                            src="/detailed_listing/icon1.svg"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="detailed-wanted-service-rectangle-parent10">
              <div className="detailed-wanted-service-frame-child12"></div>
              <div className="detailed-wanted-service-listing-card-badge-overlay">
                <img
                  className="detailed-wanted-service-listing-card-badge-overlay-child"
                  alt=""
                  src="/detailed_listing/rectangle-95@2x.png"
                />

                <div className="detailed-wanted-service-image-overlay">
                  <div className="detailed-wanted-service-badge4">
                    <div className="detailed-wanted-service-listing-card-price">Assured</div>
                  </div>
                  <div className="detailed-wanted-service-short-listing-team">
                    <div className="detailed-wanted-service-badge5">
                      <div className="detailed-wanted-service-listing-card-radar">ID# AXJYN</div>
                    </div>
                    <div className="detailed-wanted-service-buttonsbutton11">
                      <img
                        className="detailed-wanted-service-magnifier-1-icon"
                        alt=""
                        src={radar_1_1_2x}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="detailed-wanted-service-listing-detail-item">
                <div className="detailed-wanted-service-message-content">
                  <div className="detailed-wanted-service-text-frame">
                    <div className="detailed-wanted-service-text42">
                      New / Surplus 2021 Philips Health-care HD11 XE
                      (P/N:23A...
                    </div>
                  </div>
                  <div className="detailed-wanted-service-listing-detail-item-value">
                    <div className="detailed-wanted-service-listing-detail-item-pin-wrapper">
                      <div className="detailed-wanted-service-heading-container">
                        <div className="detailed-wanted-service-listing-detail-item-location">
                          <div className="detailed-wanted-service-listing-detail-item-date-parent">
                            <div className="detailed-wanted-service-listing-detail-item-date">
                              <img
                                className="detailed-wanted-service-chevron-right-icon2"
                                alt=""
                                src="/detailed_listing/markerpin021.svg"
                              />
                            </div>
                            <div className="detailed-wanted-service-location">India, New Delhi</div>
                          </div>
                          <div className="detailed-wanted-service-listing-detail-item-date-parent">
                            <div className="detailed-wanted-service-listing-detail-item-date">
                              <img
                                className="detailed-wanted-service-chevron-right-icon2"
                                alt=""
                                src="/detailed_listing/calendar1.svg"
                              />
                            </div>
                            <div className="detailed-wanted-service-type-of-work">April 09, 2023</div>
                          </div>
                        </div>
                        <div className="detailed-wanted-service-listing-team">
                          <div className="detailed-wanted-service-listing-team-member-wrapper">
                            <div className="detailed-wanted-service-listing-team-member">4.9/5</div>
                          </div>
                          <div className="detailed-wanted-service-contact-role">
                            <div className="detailed-wanted-service-supporting-text12">
                              99,999 reviews
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="detailed-wanted-service-listing">
                      <div className="detailed-wanted-service-breadcrumb-items">
                        <div className="detailed-wanted-service-listing-short-info">$9,999,999.99</div>
                        <div className="detailed-wanted-service-supporting-text-wrapper">
                          <div className="detailed-wanted-service-supporting-text13">USD-Per Unit</div>
                        </div>
                      </div>
                    </div>
                    <img
                      className="detailed-wanted-service-divider-icon"
                      alt=""
                      src="/detailed_listing/divider4.svg"
                    />

                    <div className="detailed-wanted-service-listing-detail-item-value-inner">
                      <div className="detailed-wanted-service-footer-social-parent">
                        <div className="detailed-wanted-service-footer-social">
                          <img
                            className="detailed-wanted-service-footer-social-child"
                            alt=""
                            src="/detailed_listing/group-58@2x.png"
                          />

                          <div className="detailed-wanted-service-listing-short-detail-item-pin-parent">
                            <div className="detailed-wanted-service-listing-short-detail-item-pin">
                              <div className="detailed-wanted-service-name">Olivia Rhye</div>
                            </div>
                            <div className="detailed-wanted-service-role1">Business Name</div>
                          </div>
                        </div>
                        <div className="detailed-wanted-service-listing-short-detail-item-date">
                          <img
                            className="detailed-wanted-service-icon1"
                            alt=""
                            src="/detailed_listing/icon1.svg"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="detailed-wanted-service-rectangle-parent10">
              <div className="detailed-wanted-service-frame-child12"></div>
              <div className="detailed-wanted-service-listing-card-badge-overlay">
                <img
                  className="detailed-wanted-service-listing-card-badge-overlay-child"
                  alt=""
                  src="/detailed_listing/rectangle-95@2x.png"
                />

                <div className="detailed-wanted-service-image-overlay3">
                  <div className="detailed-wanted-service-badge4">
                    <div className="detailed-wanted-service-listing-card-price">Assured</div>
                  </div>
                  <div className="detailed-wanted-service-badge-group">
                    <div className="detailed-wanted-service-badge5">
                      <div className="detailed-wanted-service-listing-card-radar">ID# AXJYN</div>
                    </div>
                    <div className="detailed-wanted-service-buttonsbutton14">
                      <img
                        className="detailed-wanted-service-magnifier-1-icon"
                        alt=""
                        src={radar_1_1_2x}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="detailed-wanted-service-listing-detail-item">
                <div className="detailed-wanted-service-message-content">
                  <div className="detailed-wanted-service-text-frame">
                    <div className="detailed-wanted-service-text42">
                      New / Surplus 2021 Philips Health-care HD11 XE
                      (P/N:23A...
                    </div>
                  </div>
                  <div className="detailed-wanted-service-work-four-wrapper">
                    <div className="detailed-wanted-service-heading-container">
                      <div className="detailed-wanted-service-listing-detail-item-location">
                        <div className="detailed-wanted-service-listing-detail-item-date-parent">
                          <div className="detailed-wanted-service-listing-detail-item-date">
                            <img
                              className="detailed-wanted-service-chevron-right-icon2"
                              alt=""
                              src="/detailed_listing/markerpin021.svg"
                            />
                          </div>
                          <div className="detailed-wanted-service-location">India, New Delhi</div>
                        </div>
                        <div className="detailed-wanted-service-listing-detail-item-date-parent">
                          <div className="detailed-wanted-service-listing-detail-item-date">
                            <img
                              className="detailed-wanted-service-chevron-right-icon2"
                              alt=""
                              src="/detailed_listing/calendar1.svg"
                            />
                          </div>
                          <div className="detailed-wanted-service-type-of-work">April 09, 2023</div>
                        </div>
                      </div>
                      <div className="detailed-wanted-service-listing-team">
                        <div className="detailed-wanted-service-listing-team-member-wrapper">
                          <div className="detailed-wanted-service-listing-team-member">4.9/5</div>
                        </div>
                        <div className="detailed-wanted-service-contact-role">
                          <div className="detailed-wanted-service-supporting-text12">99,999 reviews</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="detailed-wanted-service-agent-details-icon">
                    <img className="detailed-wanted-service-icon4" alt="" src="/detailed_listing/icon1.svg" />

                    <div className="detailed-wanted-service-agent-extra-info">
                      <div className="detailed-wanted-service-listing-detail-item-pin-wrapper">
                        <div className="detailed-wanted-service-breadcrumb-items">
                          <div className="detailed-wanted-service-listing-short-info">$9,999,999.99</div>
                          <div className="detailed-wanted-service-supporting-text-wrapper">
                            <div className="detailed-wanted-service-supporting-text13">USD-Per Unit</div>
                          </div>
                        </div>
                      </div>
                      <img
                        className="detailed-wanted-service-divider-icon"
                        alt=""
                        src="/detailed_listing/divider4.svg"
                      />

                      <div className="detailed-wanted-service-another-agent-info">
                        <div className="detailed-wanted-service-detail-items">
                          <img
                            className="detailed-wanted-service-footer-social-child"
                            alt=""
                            src="/detailed_listing/group-58@2x.png"
                          />

                          <div className="detailed-wanted-service-listing-short-detail-item-pin-parent">
                            <div className="detailed-wanted-service-listing-short-detail-item-pin">
                              <div className="detailed-wanted-service-name">Olivia Rhye</div>
                            </div>
                            <div className="detailed-wanted-service-role1">Business Name</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="detailed-wanted-service-testiomonial-carousel-arrow4">
              <img
                className="detailed-wanted-service-arrow-left-icon"
                alt=""
                src="/detailed_listing/arrowleft.svg"
              />
            </div>
          </div>
          <div className="detailed-wanted-service-testiomonial-carousel-arrow5">
            <img
              className="detailed-wanted-service-arrow-left-icon"
              alt=""
              src="/detailed_listing/arrowright.svg"
            />
          </div>
        </div>
      </div>
      <div className="detailed-wanted-service-listing-image-grid4">
        <div className="detailed-wanted-service-heading-parent3">
          <div className="detailed-wanted-service-heading38">Related Services</div>
          <div className="detailed-wanted-service-testimonial-description">
            <div className="detailed-wanted-service-description">View All</div>
          </div>
        </div>
      </div>
      <div className="detailed-wanted-service-listing-image-grid5">
        <div className="detailed-wanted-service-frame-parent4">
          <div className="detailed-wanted-service-frame-parent5">
            <div className="detailed-wanted-service-rectangle-parent10">
              <div className="detailed-wanted-service-frame-child12"></div>
              <div className="detailed-wanted-service-listing-card-badge-overlay">
                <img
                  className="detailed-wanted-service-listing-card-badge-overlay-child"
                  alt=""
                  src="/detailed_listing/rectangle-95@2x.png"
                />

                <div className="detailed-wanted-service-image-overlay">
                  <div className="detailed-wanted-service-badge4">
                    <div className="detailed-wanted-service-listing-card-price">Assured</div>
                  </div>
                  <div className="detailed-wanted-service-short-listing-team">
                    <div className="detailed-wanted-service-badge5">
                      <div className="detailed-wanted-service-listing-card-radar">ID# AXJYN</div>
                    </div>
                    <div className="detailed-wanted-service-buttonsbutton11">
                      <img
                        className="detailed-wanted-service-magnifier-1-icon"
                        alt=""
                        src={radar_1_1_2x}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="detailed-wanted-service-listing-detail-item">
                <div className="detailed-wanted-service-message-content">
                  <div className="detailed-wanted-service-text-frame">
                    <div className="detailed-wanted-service-text42">
                      New / Surplus 2021 Philips Health-care HD11 XE
                      (P/N:23A...
                    </div>
                  </div>
                  <div className="detailed-wanted-service-listing-detail-item-value">
                    <div className="detailed-wanted-service-listing-detail-item-pin-wrapper">
                      <div className="detailed-wanted-service-heading-container">
                        <div className="detailed-wanted-service-listing-detail-item-location">
                          <div className="detailed-wanted-service-listing-detail-item-date-parent">
                            <div className="detailed-wanted-service-listing-detail-item-date">
                              <img
                                className="detailed-wanted-service-chevron-right-icon2"
                                alt=""
                                src="/detailed_listing/markerpin021.svg"
                              />
                            </div>
                            <div className="detailed-wanted-service-location">India, New Delhi</div>
                          </div>
                          <div className="detailed-wanted-service-listing-detail-item-date-parent">
                            <div className="detailed-wanted-service-listing-detail-item-date">
                              <img
                                className="detailed-wanted-service-chevron-right-icon2"
                                alt=""
                                src="/detailed_listing/calendar1.svg"
                              />
                            </div>
                            <div className="detailed-wanted-service-type-of-work">April 09, 2023</div>
                          </div>
                        </div>
                        <div className="detailed-wanted-service-listing-team">
                          <div className="detailed-wanted-service-listing-team-member-wrapper">
                            <div className="detailed-wanted-service-listing-team-member">4.9/5</div>
                          </div>
                          <div className="detailed-wanted-service-contact-role">
                            <div className="detailed-wanted-service-supporting-text12">
                              99,999 reviews
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="detailed-wanted-service-listing">
                      <div className="detailed-wanted-service-breadcrumb-items">
                        <div className="detailed-wanted-service-listing-short-info">$9,999,999.99</div>
                        <div className="detailed-wanted-service-supporting-text-wrapper">
                          <div className="detailed-wanted-service-supporting-text13">USD-Per Unit</div>
                        </div>
                      </div>
                    </div>
                    <img
                      className="detailed-wanted-service-divider-icon"
                      alt=""
                      src="/detailed_listing/divider4.svg"
                    />

                    <div className="detailed-wanted-service-listing-detail-item-value-inner">
                      <div className="detailed-wanted-service-footer-social-parent">
                        <div className="detailed-wanted-service-footer-social">
                          <img
                            className="detailed-wanted-service-footer-social-child"
                            alt=""
                            src="/detailed_listing/group-58@2x.png"
                          />

                          <div className="detailed-wanted-service-listing-short-detail-item-pin-parent">
                            <div className="detailed-wanted-service-listing-short-detail-item-pin">
                              <div className="detailed-wanted-service-name">Olivia Rhye</div>
                            </div>
                            <div className="detailed-wanted-service-role1">Business Name</div>
                          </div>
                        </div>
                        <div className="detailed-wanted-service-listing-short-detail-item-date">
                          <img
                            className="detailed-wanted-service-icon1"
                            alt=""
                            src="/detailed_listing/icon1.svg"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="detailed-wanted-service-rectangle-parent10">
              <div className="detailed-wanted-service-frame-child12"></div>
              <div className="detailed-wanted-service-listing-card-badge-overlay">
                <img
                  className="detailed-wanted-service-listing-card-badge-overlay-child"
                  alt=""
                  src="/detailed_listing/rectangle-95@2x.png"
                />

                <div className="detailed-wanted-service-image-overlay">
                  <div className="detailed-wanted-service-badge4">
                    <div className="detailed-wanted-service-listing-card-price">Assured</div>
                  </div>
                  <div className="detailed-wanted-service-short-listing-team">
                    <div className="detailed-wanted-service-badge5">
                      <div className="detailed-wanted-service-listing-card-radar">ID# AXJYN</div>
                    </div>
                    <div className="detailed-wanted-service-buttonsbutton11">
                      <img
                        className="detailed-wanted-service-magnifier-1-icon"
                        alt=""
                        src={radar_1_1_2x}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="detailed-wanted-service-listing-detail-item">
                <div className="detailed-wanted-service-message-content">
                  <div className="detailed-wanted-service-text-frame">
                    <div className="detailed-wanted-service-text42">
                      New / Surplus 2021 Philips Health-care HD11 XE
                      (P/N:23A...
                    </div>
                  </div>
                  <div className="detailed-wanted-service-listing-detail-item-value">
                    <div className="detailed-wanted-service-listing-detail-item-pin-wrapper">
                      <div className="detailed-wanted-service-heading-container">
                        <div className="detailed-wanted-service-listing-detail-item-location">
                          <div className="detailed-wanted-service-listing-detail-item-date-parent">
                            <div className="detailed-wanted-service-listing-detail-item-date">
                              <img
                                className="detailed-wanted-service-chevron-right-icon2"
                                alt=""
                                src="/detailed_listing/markerpin021.svg"
                              />
                            </div>
                            <div className="detailed-wanted-service-location">India, New Delhi</div>
                          </div>
                          <div className="detailed-wanted-service-listing-detail-item-date-parent">
                            <div className="detailed-wanted-service-listing-detail-item-date">
                              <img
                                className="detailed-wanted-service-chevron-right-icon2"
                                alt=""
                                src="/detailed_listing/calendar1.svg"
                              />
                            </div>
                            <div className="detailed-wanted-service-type-of-work">April 09, 2023</div>
                          </div>
                        </div>
                        <div className="detailed-wanted-service-listing-team">
                          <div className="detailed-wanted-service-listing-team-member-wrapper">
                            <div className="detailed-wanted-service-listing-team-member">4.9/5</div>
                          </div>
                          <div className="detailed-wanted-service-contact-role">
                            <div className="detailed-wanted-service-supporting-text12">
                              99,999 reviews
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="detailed-wanted-service-listing">
                      <div className="detailed-wanted-service-breadcrumb-items">
                        <div className="detailed-wanted-service-listing-short-info">$9,999,999.99</div>
                        <div className="detailed-wanted-service-supporting-text-wrapper">
                          <div className="detailed-wanted-service-supporting-text13">USD-Per Unit</div>
                        </div>
                      </div>
                    </div>
                    <img
                      className="detailed-wanted-service-divider-icon"
                      alt=""
                      src="/detailed_listing/divider4.svg"
                    />

                    <div className="detailed-wanted-service-listing-detail-item-value-inner">
                      <div className="detailed-wanted-service-footer-social-parent">
                        <div className="detailed-wanted-service-footer-social">
                          <img
                            className="detailed-wanted-service-footer-social-child"
                            alt=""
                            src="/detailed_listing/group-58@2x.png"
                          />

                          <div className="detailed-wanted-service-listing-short-detail-item-pin-parent">
                            <div className="detailed-wanted-service-listing-short-detail-item-pin">
                              <div className="detailed-wanted-service-name">Olivia Rhye</div>
                            </div>
                            <div className="detailed-wanted-service-role1">Business Name</div>
                          </div>
                        </div>
                        <div className="detailed-wanted-service-listing-short-detail-item-date">
                          <img
                            className="detailed-wanted-service-icon1"
                            alt=""
                            src="/detailed_listing/icon1.svg"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="detailed-wanted-service-rectangle-parent10">
              <div className="detailed-wanted-service-frame-child12"></div>
              <div className="detailed-wanted-service-listing-card-badge-overlay">
                <img
                  className="detailed-wanted-service-listing-card-badge-overlay-child"
                  alt=""
                  src="/detailed_listing/rectangle-95@2x.png"
                />

                <div className="detailed-wanted-service-image-overlay">
                  <div className="detailed-wanted-service-badge4">
                    <div className="detailed-wanted-service-listing-card-price">Assured</div>
                  </div>
                  <div className="detailed-wanted-service-short-listing-team">
                    <div className="detailed-wanted-service-badge5">
                      <div className="detailed-wanted-service-listing-card-radar">ID# AXJYN</div>
                    </div>
                    <div className="detailed-wanted-service-buttonsbutton11">
                      <img
                        className="detailed-wanted-service-magnifier-1-icon"
                        alt=""
                        src={radar_1_1_2x}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="detailed-wanted-service-listing-detail-item">
                <div className="detailed-wanted-service-message-content">
                  <div className="detailed-wanted-service-text-frame">
                    <div className="detailed-wanted-service-text42">
                      New / Surplus 2021 Philips Health-care HD11 XE
                      (P/N:23A...
                    </div>
                  </div>
                  <div className="detailed-wanted-service-listing-detail-item-value">
                    <div className="detailed-wanted-service-listing-detail-item-pin-wrapper">
                      <div className="detailed-wanted-service-heading-container">
                        <div className="detailed-wanted-service-listing-detail-item-location">
                          <div className="detailed-wanted-service-listing-detail-item-date-parent">
                            <div className="detailed-wanted-service-listing-detail-item-date">
                              <img
                                className="detailed-wanted-service-chevron-right-icon2"
                                alt=""
                                src="/detailed_listing/markerpin021.svg"
                              />
                            </div>
                            <div className="detailed-wanted-service-location">India, New Delhi</div>
                          </div>
                          <div className="detailed-wanted-service-listing-detail-item-date-parent">
                            <div className="detailed-wanted-service-listing-detail-item-date">
                              <img
                                className="detailed-wanted-service-chevron-right-icon2"
                                alt=""
                                src="/detailed_listing/calendar1.svg"
                              />
                            </div>
                            <div className="detailed-wanted-service-type-of-work">April 09, 2023</div>
                          </div>
                        </div>
                        <div className="detailed-wanted-service-listing-team">
                          <div className="detailed-wanted-service-listing-team-member-wrapper">
                            <div className="detailed-wanted-service-listing-team-member">4.9/5</div>
                          </div>
                          <div className="detailed-wanted-service-contact-role">
                            <div className="detailed-wanted-service-supporting-text12">
                              99,999 reviews
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="detailed-wanted-service-listing">
                      <div className="detailed-wanted-service-breadcrumb-items">
                        <div className="detailed-wanted-service-listing-short-info">$9,999,999.99</div>
                        <div className="detailed-wanted-service-supporting-text-wrapper">
                          <div className="detailed-wanted-service-supporting-text13">USD-Per Unit</div>
                        </div>
                      </div>
                    </div>
                    <img
                      className="detailed-wanted-service-divider-icon"
                      alt=""
                      src="/detailed_listing/divider4.svg"
                    />

                    <div className="detailed-wanted-service-listing-detail-item-value-inner">
                      <div className="detailed-wanted-service-footer-social-parent">
                        <div className="detailed-wanted-service-footer-social">
                          <img
                            className="detailed-wanted-service-footer-social-child"
                            alt=""
                            src="/detailed_listing/group-58@2x.png"
                          />

                          <div className="detailed-wanted-service-listing-short-detail-item-pin-parent">
                            <div className="detailed-wanted-service-listing-short-detail-item-pin">
                              <div className="detailed-wanted-service-name">Olivia Rhye</div>
                            </div>
                            <div className="detailed-wanted-service-role1">Business Name</div>
                          </div>
                        </div>
                        <div className="detailed-wanted-service-listing-short-detail-item-date">
                          <img
                            className="detailed-wanted-service-icon1"
                            alt=""
                            src="/detailed_listing/icon1.svg"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="detailed-wanted-service-rectangle-parent10">
              <div className="detailed-wanted-service-frame-child12"></div>
              <div className="detailed-wanted-service-rectangle-parent30">
                <img
                  className="detailed-wanted-service-listing-card-badge-overlay-child"
                  alt=""
                  src="/detailed_listing/rectangle-95@2x.png"
                />

                <div className="detailed-wanted-service-image-overlay3">
                  <div className="detailed-wanted-service-badge4">
                    <div className="detailed-wanted-service-listing-card-price">Assured</div>
                  </div>
                  <div className="detailed-wanted-service-badge-group">
                    <div className="detailed-wanted-service-badge5">
                      <div className="detailed-wanted-service-listing-card-radar">ID# AXJYN</div>
                    </div>
                    <div className="detailed-wanted-service-buttonsbutton14">
                      <img
                        className="detailed-wanted-service-magnifier-1-icon"
                        alt=""
                        src={radar_1_1_2x}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="detailed-wanted-service-listing-detail-item">
                <div className="detailed-wanted-service-message-content">
                  <div className="detailed-wanted-service-text-frame">
                    <div className="detailed-wanted-service-text42">
                      New / Surplus 2021 Philips Health-care HD11 XE
                      (P/N:23A...
                    </div>
                  </div>
                  <div className="detailed-wanted-service-icon-group">
                    <img className="detailed-wanted-service-icon4" alt="" src="/detailed_listing/icon1.svg" />

                    <div className="detailed-wanted-service-message-content">
                      <div className="detailed-wanted-service-listing-detail-item-pin-wrapper">
                        <div className="detailed-wanted-service-listing-detail-item-location">
                          <div className="detailed-wanted-service-listing-detail-item-date-parent">
                            <div className="detailed-wanted-service-listing-detail-item-date">
                              <img
                                className="detailed-wanted-service-chevron-right-icon2"
                                alt=""
                                src="/detailed_listing/markerpin021.svg"
                              />
                            </div>
                            <div className="detailed-wanted-service-location">India, New Delhi</div>
                          </div>
                          <div className="detailed-wanted-service-listing-detail-item-date-parent">
                            <div className="detailed-wanted-service-listing-detail-item-date">
                              <img
                                className="detailed-wanted-service-chevron-right-icon2"
                                alt=""
                                src="/detailed_listing/calendar1.svg"
                              />
                            </div>
                            <div className="detailed-wanted-service-type-of-work">April 09, 2023</div>
                          </div>
                        </div>
                      </div>
                      <div className="detailed-wanted-service-supporting-work-five">
                        <div className="detailed-wanted-service-breadcrumb-items">
                          <div className="detailed-wanted-service-listing-team-member-wrapper">
                            <div className="detailed-wanted-service-listing-team-member">4.9/5</div>
                          </div>
                          <div className="detailed-wanted-service-contact-role">
                            <div className="detailed-wanted-service-supporting-text12">
                              99,999 reviews
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="detailed-wanted-service-supporting-six-wrapper">
                        <div className="detailed-wanted-service-breadcrumb-items">
                          <div className="detailed-wanted-service-listing-short-info">$9,999,999.99</div>
                          <div className="detailed-wanted-service-supporting-text-wrapper">
                            <div className="detailed-wanted-service-supporting-text13">USD-Per Unit</div>
                          </div>
                        </div>
                      </div>
                      <div className="detailed-wanted-service-divider-container">
                        <img
                          className="detailed-wanted-service-divider-icon3"
                          alt=""
                          src="/detailed_listing/divider4.svg"
                        />
                      </div>
                      <div className="detailed-wanted-service-team-info-four">
                        <div className="detailed-wanted-service-detail-items">
                          <img
                            className="detailed-wanted-service-footer-social-child"
                            alt=""
                            src="/detailed_listing/group-58@2x.png"
                          />

                          <div className="detailed-wanted-service-listing-short-detail-item-pin-parent">
                            <div className="detailed-wanted-service-listing-short-detail-item-pin">
                              <div className="detailed-wanted-service-name">Olivia Rhye</div>
                            </div>
                            <div className="detailed-wanted-service-role1">Business Name</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="detailed-wanted-service-testiomonial-carousel-arrow6">
              <img
                className="detailed-wanted-service-arrow-left-icon"
                alt=""
                src="/detailed_listing/arrowleft.svg"
              />
            </div>
          </div>
          <div className="detailed-wanted-service-testiomonial-carousel-arrow7">
            <img
              className="detailed-wanted-service-arrow-left-icon"
              alt=""
              src="/detailed_listing/arrowright.svg"
            />
          </div>
        </div>
      </div>
      <div className="detailed-wanted-service-breadcrumb-button-base4">
        <div className="detailed-wanted-service-text6">...</div>
      </div>
      <img
        className="detailed-wanted-service-chevron-right-icon3"
        alt=""
        src={chevronright}
      /> */}

      {/* <div className="detailed-wanted-service-breadcrumb-button-base5">
        <div className="detailed-wanted-service-text97">Another link</div>
      </div>
      <img
        className="detailed-wanted-service-chevron-right-icon4"
        alt=""
        src={chevronright}
      /> */}

      <img className="detailed-wanted-service-divider-icon24" alt="" src="/detailed_listing/divider5.svg" />

      {/* <div className="detailed-wanted-service-actions1">
        <div className="detailed-wanted-service-button2">
          <img
            className="detailed-wanted-service-placeholder-icon17"
            alt=""
            src="/detailed_listing/placeholder3.svg"
          />

          <div className="detailed-wanted-service-text-padding4">
            <div className="detailed-wanted-service-text98">Tertiary</div>
          </div>
          <img
            className="detailed-wanted-service-placeholder-icon17"
            alt=""
            src="/detailed_listing/placeholder3.svg"
          />
        </div>
        <div className="detailed-wanted-service-button3">
          <img
            className="detailed-wanted-service-placeholder-icon17"
            alt=""
            src="/detailed_listing/placeholder4.svg"
          />

          <div className="detailed-wanted-service-text-padding5">
            <div className="detailed-wanted-service-text98">Secondary</div>
          </div>
          <img
            className="detailed-wanted-service-placeholder-icon17"
            alt=""
            src="/detailed_listing/placeholder4.svg"
          />
        </div>
        <div className="detailed-wanted-service-button4">
          <img
            className="detailed-wanted-service-placeholder-icon17"
            alt=""
            src={placeholder1}
          />

          <div className="detailed-wanted-service-text-padding5">
            <div className="detailed-wanted-service-text98">Secondary</div>
          </div>
          <img
            className="detailed-wanted-service-placeholder-icon17"
            alt=""
            src={placeholder1}
          />
        </div>
        <div className="detailed-wanted-service-button5">
          <img
            className="detailed-wanted-service-placeholder-icon17"
            alt=""
            src="/detailed_listing/placeholder2.svg"
          />

          <div className="detailed-wanted-service-text-padding7">
            <div className="detailed-wanted-service-text98">Primary</div>
          </div>
          <img
            className="detailed-wanted-service-placeholder-icon17"
            alt=""
            src="/detailed_listing/placeholder2.svg"
          />
        </div>
      </div> */}
      {/* <div className="detailed-wanted-service-listing-image-grid4">
        <div className="detailed-wanted-service-heading-parent3">
          <div className="detailed-wanted-service-heading36">Related Software</div>
          <div className="detailed-wanted-service-testimonial-description">
            <div className="detailed-wanted-service-description">View All</div>
          </div>
        </div>
      </div> */}
      {/* <div className="detailed-wanted-service-listing-image-grid11">
        <div className="detailed-wanted-service-frame-parent4">
          <div className="detailed-wanted-service-frame-parent5">
            <div className="detailed-wanted-service-rectangle-parent10">
              <div className="detailed-wanted-service-frame-child12"></div>
              <div className="detailed-wanted-service-rectangle-parent30">
                <img
                  className="detailed-wanted-service-listing-card-badge-overlay-child"
                  alt=""
                  src="/detailed_listing/rectangle-95@2x.png"
                />

                <div className="detailed-wanted-service-image-overlay">
                  <div className="detailed-wanted-service-badge4">
                    <div className="detailed-wanted-service-listing-card-price">Assured</div>
                  </div>
                  <div className="detailed-wanted-service-short-listing-team">
                    <div className="detailed-wanted-service-badge5">
                      <div className="detailed-wanted-service-listing-card-radar">ID# AXJYN</div>
                    </div>
                    <div className="detailed-wanted-service-buttonsbutton11">
                      <img
                        className="detailed-wanted-service-magnifier-1-icon"
                        alt=""
                        src={radar_1_1_2x}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="detailed-wanted-service-listing-detail-item">
                <div className="detailed-wanted-service-message-content">
                  <div className="detailed-wanted-service-text-frame">
                    <div className="detailed-wanted-service-text42">
                      New / Surplus 2021 Philips Health-care HD11 XE
                      (P/N:23A...
                    </div>
                  </div>
                  <div className="detailed-wanted-service-listing-detail-item-value">
                    <div className="detailed-wanted-service-listing-detail-item-pin-wrapper">
                      <div className="detailed-wanted-service-heading-container">
                        <div className="detailed-wanted-service-listing-detail-item-location">
                          <div className="detailed-wanted-service-listing-detail-item-date-parent">
                            <div className="detailed-wanted-service-listing-detail-item-date">
                              <img
                                className="detailed-wanted-service-chevron-right-icon2"
                                alt=""
                                src="/detailed_listing/markerpin021.svg"
                              />
                            </div>
                            <div className="detailed-wanted-service-location">India, New Delhi</div>
                          </div>
                          <div className="detailed-wanted-service-listing-detail-item-date-parent">
                            <div className="detailed-wanted-service-listing-detail-item-date">
                              <img
                                className="detailed-wanted-service-chevron-right-icon2"
                                alt=""
                                src="/detailed_listing/calendar1.svg"
                              />
                            </div>
                            <div className="detailed-wanted-service-type-of-work">April 09, 2023</div>
                          </div>
                        </div>
                        <div className="detailed-wanted-service-listing-team">
                          <div className="detailed-wanted-service-listing-team-member-wrapper">
                            <div className="detailed-wanted-service-listing-team-member">4.9/5</div>
                          </div>
                          <div className="detailed-wanted-service-contact-role">
                            <div className="detailed-wanted-service-supporting-text12">
                              99,999 reviews
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="detailed-wanted-service-listing">
                      <div className="detailed-wanted-service-breadcrumb-items">
                        <div className="detailed-wanted-service-listing-short-info">$9,999,999.99</div>
                        <div className="detailed-wanted-service-supporting-text-wrapper">
                          <div className="detailed-wanted-service-supporting-text13">USD-Per Unit</div>
                        </div>
                      </div>
                    </div>
                    <img
                      className="detailed-wanted-service-divider-icon"
                      alt=""
                      src="/detailed_listing/divider4.svg"
                    />

                    <div className="detailed-wanted-service-listing-detail-item-value-inner">
                      <div className="detailed-wanted-service-footer-social-parent">
                        <div className="detailed-wanted-service-footer-social">
                          <img
                            className="detailed-wanted-service-footer-social-child"
                            alt=""
                            src="/detailed_listing/group-58@2x.png"
                          />

                          <div className="detailed-wanted-service-listing-short-detail-item-pin-parent">
                            <div className="detailed-wanted-service-listing-short-detail-item-pin">
                              <div className="detailed-wanted-service-name">Olivia Rhye</div>
                            </div>
                            <div className="detailed-wanted-service-role1">Business Name</div>
                          </div>
                        </div>
                        <div className="detailed-wanted-service-listing-short-detail-item-date">
                          <img
                            className="detailed-wanted-service-icon1"
                            alt=""
                            src="/detailed_listing/icon1.svg"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="detailed-wanted-service-rectangle-parent10">
              <div className="detailed-wanted-service-frame-child12"></div>
              <div className="detailed-wanted-service-rectangle-parent30">
                <img
                  className="detailed-wanted-service-listing-card-badge-overlay-child"
                  alt=""
                  src="/detailed_listing/rectangle-95@2x.png"
                />

                <div className="detailed-wanted-service-image-overlay">
                  <div className="detailed-wanted-service-badge4">
                    <div className="detailed-wanted-service-listing-card-price">Assured</div>
                  </div>
                  <div className="detailed-wanted-service-short-listing-team">
                    <div className="detailed-wanted-service-badge5">
                      <div className="detailed-wanted-service-listing-card-radar">ID# AXJYN</div>
                    </div>
                    <div className="detailed-wanted-service-buttonsbutton11">
                      <img
                        className="detailed-wanted-service-magnifier-1-icon"
                        alt=""
                        src={radar_1_1_2x}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="detailed-wanted-service-listing-detail-item">
                <div className="detailed-wanted-service-message-content">
                  <div className="detailed-wanted-service-text-frame">
                    <div className="detailed-wanted-service-text42">
                      New / Surplus 2021 Philips Health-care HD11 XE
                      (P/N:23A...
                    </div>
                  </div>
                  <div className="detailed-wanted-service-listing-detail-item-value">
                    <div className="detailed-wanted-service-listing-detail-item-pin-wrapper">
                      <div className="detailed-wanted-service-heading-container">
                        <div className="detailed-wanted-service-listing-detail-item-location">
                          <div className="detailed-wanted-service-listing-detail-item-date-parent">
                            <div className="detailed-wanted-service-listing-detail-item-date">
                              <img
                                className="detailed-wanted-service-chevron-right-icon2"
                                alt=""
                                src="/detailed_listing/markerpin021.svg"
                              />
                            </div>
                            <div className="detailed-wanted-service-location">India, New Delhi</div>
                          </div>
                          <div className="detailed-wanted-service-listing-detail-item-date-parent">
                            <div className="detailed-wanted-service-listing-detail-item-date">
                              <img
                                className="detailed-wanted-service-chevron-right-icon2"
                                alt=""
                                src="/detailed_listing/calendar1.svg"
                              />
                            </div>
                            <div className="detailed-wanted-service-type-of-work">April 09, 2023</div>
                          </div>
                        </div>
                        <div className="detailed-wanted-service-listing-team">
                          <div className="detailed-wanted-service-listing-team-member-wrapper">
                            <div className="detailed-wanted-service-listing-team-member">4.9/5</div>
                          </div>
                          <div className="detailed-wanted-service-contact-role">
                            <div className="detailed-wanted-service-supporting-text12">
                              99,999 reviews
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="detailed-wanted-service-listing">
                      <div className="detailed-wanted-service-breadcrumb-items">
                        <div className="detailed-wanted-service-listing-short-info">$9,999,999.99</div>
                        <div className="detailed-wanted-service-supporting-text-wrapper">
                          <div className="detailed-wanted-service-supporting-text13">USD-Per Unit</div>
                        </div>
                      </div>
                    </div>
                    <img
                      className="detailed-wanted-service-divider-icon"
                      alt=""
                      src="/detailed_listing/divider4.svg"
                    />

                    <div className="detailed-wanted-service-listing-detail-item-value-inner">
                      <div className="detailed-wanted-service-footer-social-parent">
                        <div className="detailed-wanted-service-footer-social">
                          <img
                            className="detailed-wanted-service-footer-social-child"
                            alt=""
                            src="/detailed_listing/group-58@2x.png"
                          />

                          <div className="detailed-wanted-service-listing-short-detail-item-pin-parent">
                            <div className="detailed-wanted-service-listing-short-detail-item-pin">
                              <div className="detailed-wanted-service-name">Olivia Rhye</div>
                            </div>
                            <div className="detailed-wanted-service-role1">Business Name</div>
                          </div>
                        </div>
                        <div className="detailed-wanted-service-listing-short-detail-item-date">
                          <img
                            className="detailed-wanted-service-icon1"
                            alt=""
                            src="/detailed_listing/icon1.svg"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="detailed-wanted-service-rectangle-parent10">
              <div className="detailed-wanted-service-frame-child12"></div>
              <div className="detailed-wanted-service-rectangle-parent30">
                <img
                  className="detailed-wanted-service-listing-card-badge-overlay-child"
                  alt=""
                  src="/detailed_listing/rectangle-95@2x.png"
                />

                <div className="detailed-wanted-service-image-overlay">
                  <div className="detailed-wanted-service-badge4">
                    <div className="detailed-wanted-service-listing-card-price">Assured</div>
                  </div>
                  <div className="detailed-wanted-service-short-listing-team">
                    <div className="detailed-wanted-service-badge5">
                      <div className="detailed-wanted-service-listing-card-radar">ID# AXJYN</div>
                    </div>
                    <div className="detailed-wanted-service-buttonsbutton11">
                      <img
                        className="detailed-wanted-service-magnifier-1-icon"
                        alt=""
                        src={radar_1_1_2x}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="detailed-wanted-service-listing-detail-item">
                <div className="detailed-wanted-service-message-content">
                  <div className="detailed-wanted-service-text-frame">
                    <div className="detailed-wanted-service-text42">
                      New / Surplus 2021 Philips Health-care HD11 XE
                      (P/N:23A...
                    </div>
                  </div>
                  <div className="detailed-wanted-service-listing-detail-item-value">
                    <div className="detailed-wanted-service-listing-detail-item-pin-wrapper">
                      <div className="detailed-wanted-service-heading-container">
                        <div className="detailed-wanted-service-listing-detail-item-location">
                          <div className="detailed-wanted-service-listing-detail-item-date-parent">
                            <div className="detailed-wanted-service-listing-detail-item-date">
                              <img
                                className="detailed-wanted-service-chevron-right-icon2"
                                alt=""
                                src="/detailed_listing/markerpin021.svg"
                              />
                            </div>
                            <div className="detailed-wanted-service-location">India, New Delhi</div>
                          </div>
                          <div className="detailed-wanted-service-listing-detail-item-date-parent">
                            <div className="detailed-wanted-service-listing-detail-item-date">
                              <img
                                className="detailed-wanted-service-chevron-right-icon2"
                                alt=""
                                src="/detailed_listing/calendar1.svg"
                              />
                            </div>
                            <div className="detailed-wanted-service-type-of-work">April 09, 2023</div>
                          </div>
                        </div>
                        <div className="detailed-wanted-service-listing-team">
                          <div className="detailed-wanted-service-listing-team-member-wrapper">
                            <div className="detailed-wanted-service-listing-team-member">4.9/5</div>
                          </div>
                          <div className="detailed-wanted-service-contact-role">
                            <div className="detailed-wanted-service-supporting-text12">
                              99,999 reviews
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="detailed-wanted-service-listing">
                      <div className="detailed-wanted-service-breadcrumb-items">
                        <div className="detailed-wanted-service-listing-short-info">$9,999,999.99</div>
                        <div className="detailed-wanted-service-supporting-text-wrapper">
                          <div className="detailed-wanted-service-supporting-text13">USD-Per Unit</div>
                        </div>
                      </div>
                    </div>
                    <img
                      className="detailed-wanted-service-divider-icon"
                      alt=""
                      src="/detailed_listing/divider4.svg"
                    />

                    <div className="detailed-wanted-service-listing-detail-item-value-inner">
                      <div className="detailed-wanted-service-footer-social-parent">
                        <div className="detailed-wanted-service-footer-social">
                          <img
                            className="detailed-wanted-service-footer-social-child"
                            alt=""
                            src="/detailed_listing/group-58@2x.png"
                          />

                          <div className="detailed-wanted-service-listing-short-detail-item-pin-parent">
                            <div className="detailed-wanted-service-listing-short-detail-item-pin">
                              <div className="detailed-wanted-service-name">Olivia Rhye</div>
                            </div>
                            <div className="detailed-wanted-service-role1">Business Name</div>
                          </div>
                        </div>
                        <div className="detailed-wanted-service-listing-short-detail-item-date">
                          <img
                            className="detailed-wanted-service-icon1"
                            alt=""
                            src="/detailed_listing/icon1.svg"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="detailed-wanted-service-rectangle-parent10">
              <div className="detailed-wanted-service-frame-child12"></div>
              <div className="detailed-wanted-service-rectangle-parent30">
                <img
                  className="detailed-wanted-service-listing-card-badge-overlay-child"
                  alt=""
                  src="/detailed_listing/rectangle-95@2x.png"
                />

                <div className="detailed-wanted-service-image-overlay3">
                  <div className="detailed-wanted-service-badge4">
                    <div className="detailed-wanted-service-listing-card-price">Assured</div>
                  </div>
                  <div className="detailed-wanted-service-badge-group">
                    <div className="detailed-wanted-service-badge5">
                      <div className="detailed-wanted-service-listing-card-radar">ID# AXJYN</div>
                    </div>
                    <div className="detailed-wanted-service-buttonsbutton14">
                      <img
                        className="detailed-wanted-service-magnifier-1-icon"
                        alt=""
                        src={radar_1_1_2x}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="detailed-wanted-service-listing-detail-item">
                <div className="detailed-wanted-service-message-content">
                  <div className="detailed-wanted-service-text-frame">
                    <div className="detailed-wanted-service-text42">
                      New / Surplus 2021 Philips Health-care HD11 XE
                      (P/N:23A...
                    </div>
                  </div>
                  <div className="detailed-wanted-service-icon-group">
                    <img className="detailed-wanted-service-icon4" alt="" src="/detailed_listing/icon1.svg" />

                    <div className="detailed-wanted-service-message-content">
                      <div className="detailed-wanted-service-listing-detail-item-pin-wrapper">
                        <div className="detailed-wanted-service-listing-detail-item-location">
                          <div className="detailed-wanted-service-listing-detail-item-date-parent">
                            <div className="detailed-wanted-service-listing-detail-item-date">
                              <img
                                className="detailed-wanted-service-chevron-right-icon2"
                                alt=""
                                src="/detailed_listing/markerpin021.svg"
                              />
                            </div>
                            <div className="detailed-wanted-service-location">India, New Delhi</div>
                          </div>
                          <div className="detailed-wanted-service-listing-detail-item-date-parent">
                            <div className="detailed-wanted-service-listing-detail-item-date">
                              <img
                                className="detailed-wanted-service-chevron-right-icon2"
                                alt=""
                                src="/detailed_listing/calendar1.svg"
                              />
                            </div>
                            <div className="detailed-wanted-service-type-of-work">April 09, 2023</div>
                          </div>
                        </div>
                      </div>
                      <div className="detailed-wanted-service-supporting-work-five">
                        <div className="detailed-wanted-service-breadcrumb-items">
                          <div className="detailed-wanted-service-listing-team-member-wrapper">
                            <div className="detailed-wanted-service-listing-team-member">4.9/5</div>
                          </div>
                          <div className="detailed-wanted-service-contact-role">
                            <div className="detailed-wanted-service-supporting-text12">
                              99,999 reviews
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="detailed-wanted-service-supporting-six-wrapper">
                        <div className="detailed-wanted-service-breadcrumb-items">
                          <div className="detailed-wanted-service-listing-short-info">$9,999,999.99</div>
                          <div className="detailed-wanted-service-supporting-text-wrapper">
                            <div className="detailed-wanted-service-supporting-text13">USD-Per Unit</div>
                          </div>
                        </div>
                      </div>
                      <div className="detailed-wanted-service-divider-container">
                        <img
                          className="detailed-wanted-service-divider-icon3"
                          alt=""
                          src="/detailed_listing/divider4.svg"
                        />
                      </div>
                      <div className="detailed-wanted-service-team-info-four">
                        <div className="detailed-wanted-service-detail-items">
                          <img
                            className="detailed-wanted-service-footer-social-child"
                            alt=""
                            src="/detailed_listing/group-58@2x.png"
                          />

                          <div className="detailed-wanted-service-listing-short-detail-item-pin-parent">
                            <div className="detailed-wanted-service-listing-short-detail-item-pin">
                              <div className="detailed-wanted-service-name">Olivia Rhye</div>
                            </div>
                            <div className="detailed-wanted-service-role1">Business Name</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="detailed-wanted-service-testiomonial-carousel-arrow8">
              <img
                className="detailed-wanted-service-arrow-left-icon"
                alt=""
                src="/detailed_listing/arrowleft.svg"
              />
            </div>
          </div>
          <div className="detailed-wanted-service-testiomonial-carousel-arrow9">
            <img
              className="detailed-wanted-service-arrow-left-icon"
              alt=""
              src="/detailed_listing/arrowright.svg"
            />
          </div>
        </div>
      </div> */}
      <FooterDetailPage/>
    </div>
  </div>
  )
}

