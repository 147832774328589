import React, { useContext, useEffect, useRef, useState } from "react";
import gerator_logo from '../../icons/Gerator/gerator04-1@2x.png';
import social_logo_facebook from '../../icons/Gerator/social-icon-1.svg';
import social_logo_google from '../../icons/Gerator/social-icon.svg';
import content_svg from '../../icons/Gerator/content.svg';
import help_icon from '../../icons/Gerator/help-icon.svg';
import chevron_right from '../../icons/Gerator/chevronright.svg';
import avatar_company_icon from '../../icons/Gerator/-avatar-company-icon@2x.png';
import { Formik, Form, useField, useFormikContext } from "formik";
import { auth } from "../../screens/Login/FirebaseClient";
import * as Yup from "yup";
import "./login.css";
import { Link, useNavigate } from "react-router-dom";
import {
  getAuth, app, signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup
} from "../../screens/Login/FirebaseClient";
import { useLazyAddUserGoogleQuery, useLazyGetUserQuery, useLazySetEmailVerifyQuery, useLazySetEmailVerifySSOQuery, useLoginQuery } from "../../api/Login&RegisterAPI";
import Cookies from 'js-cookie';
import { decryptData } from "../../helper/AESHelper";

const MyTextInput = ({ validate, label, ...props }) => {
  
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input>. We can use field meta to show an error
  // message if the field is invalid and it has been touched (i.e. visited)
  const [field, meta] = useField(props);
  let fieldValidationData = null;
  if (validate?.length > 0) {
    validate.some((field) => {
      // Use 'some' for early termination
      if (field.path === props.name) {
        fieldValidationData = field;
        return true; // This will stop the loop early
      }
      return false; // Continue if no match
    });
  }

  if (fieldValidationData != null) {
    // console.log(fieldValidationData);
  }
  // {
  //   fieldValidationData !== null ? (
  //     <div className="error">{fieldValidationData.msg}</div>
  //   ) : null
  // }

  return (
    <>
      {/* <label className="label">Model Number</label> */}

      {/* <label className="label" htmlFor={props.id || props.name}>
        {label} <span className="text-wrapper-3">*</span>
      </label> */}
      <div className="sign-in-input-field3">
        <div className="sign-in-label4">{label}</div>
        <div className="sign-in-input3">
          <input
            {...field}
            {...props}
          />
          <img
            className="sign-in-help-icon3"
            alt=""
            src="/{help_icon}"
          />
        </div>
      </div>


      {meta.touched && meta.error ? (
        <div className="sign-in-hint-text3 error-code">
          {meta.error}
        </div>
      ) : null}

      {fieldValidationData !== null ? (
        <div className="sign-in-hint-text3 error-code">
          {fieldValidationData.msg}
        </div>
      ) : null}
    </>
  );
};

const LoginForm = ({ message, setErrorMessage }) => {

  let errorMessage = null;
  const [backendValidation, setBackendValidation] = useState(null);

  // Lazy query for getting user data
  const [triggerGetUser, { data: dataGetUser, isLoading: isLoadingGetUser, isError: isErrorGetUser, error: errorGetUser }] = useLazyGetUserQuery();

  // Lazy query for getting user data saved in db confirmation
  const [triggerAddUserGoogle, { dataGoogle, isLoading: isGettingUserGoogle, isError: isGetUserGoogleError, error: getUserGooleError }] = useLazyAddUserGoogleQuery();



  // const [triggerSetEmailVerifySSO, { data: dataEmailVerifySSO, isLoading: isGettingEmailVerifySSO, isError: isGetEmailVerifySSOError, error: getEmailVerifySSOError }] = useLazySetEmailVerifySSOQuery();



  const provider = new GoogleAuthProvider();
  const navigate = useNavigate();
  const [showSuccessMessage, setShowSuccessMessage] = useState({
    message: "",
    color: "",
  });


  //Effect use when protected page trying to acces then message come from dashaboard to Signin.jsx to login component
  useEffect(() => {
    if (message) {
      setShowSuccessMessage({ message: message, color: "red" });
      //Cleaning message from Signin.jsx useState
      // setErrorMessage("");
    }

    // return setShowSuccessMessage({ message: "", color: "" });
  }, [message]);








  //SIGN IN METHOD FOR GOOGLE
  const signInWithGoogle = async () => {
    try {

      const userCredential = await signInWithPopup(auth, provider)
      const user = userCredential.user;
      console.log("User Info: ", await user.getIdToken());
      console.log("User uid: ", user.uid);

      // const idToken = await user.getIdToken();
      // const uid = user.uid;

      console.log(user);
      const status = await triggerGetUser();
      console.log(status);

      if (status.isError && (status.error.status == 401)) {
        const status = await triggerAddUserGoogle();
        console.log(status);
        if (status.isSuccess && status.data.status) {
          setShowSuccessMessage({ message: "Signed up successfully", color: "green" });
          await new Promise(resolve => setTimeout(resolve, 1500));
          navigate("/account-setup-update-profile");
        } else {
          setShowSuccessMessage({ message: "Failed to Signin", color: "red" });
          // throw new Error("Failed to Signin");
          return;
        }
      } else if (status.isSuccess) {
        console.log(status.data);
        const userDetails = status.data.user;
        console.log(userDetails);


        if (!userDetails.emailVerified && (userDetails.wizardStep === 1)) {
          try {
            const updatedUser = await triggerAddUserGoogle();
            console.log(updatedUser);
            if (updatedUser.data.status) {
              setShowSuccessMessage({ message: "Sign in successfully", color: "green" });
              await new Promise(resolve => setTimeout(resolve, 1500));
              navigate("/account-setup-update-profile");
            } else {
              throw new Error("Failed to signup");
            }
            return;
          } catch (error) {
            throw error;
          }
        }

        // if (!userDetails.emailVerified && ((userDetails.wizardStep >= 1 && userDetails.wizardStep <= 3)))
        //   switch (userDetails.wizardStep) {
        //     case 1:
        //       navigate("/account-setup-check-email");
        //       break;
        //     case 2:
        //       navigate("/account-setup-update-profile");
        //       break;
        //     default:
        //       navigate("/account-setup-subscription-plan");
        //       break;
        //   }
        // else 
        if (userDetails.emailVerified && ((userDetails.wizardStep >= 2 && userDetails.wizardStep <= 3))) {
          switch (userDetails.wizardStep) {
            case 1:
              navigate("/account-setup-check-email");
              break;
            case 2:
              navigate("/account-setup-update-profile");
              break;
            default:
              navigate("/account-setup-subscription-plan");
              break;
          }
        }
        else {
          setShowSuccessMessage({ message: "Logged in successfully", color: "green" });
          await new Promise(resolve => setTimeout(resolve, 1500));
          navigate("/dashboard");
        }
      }
      // if (!status.isSuccess) {
      //   if (!status.data.status) {
      //     const status = await triggerAddUserGoogle();
      //     console.log(status);
      //     if (status.data.status) {
      //       setShowSuccessMessage({ message: "Signed in successfully", color: "green" });
      //       await new Promise(resolve => setTimeout(resolve, 1500));
      //       console.log(Cookies.get('token'));
      //       navigate("/dashboard"); if (!status.data.status) {
      //         const status = await triggerAddUserGoogle();
      //         console.log(status);
      //         if (status.data.status) {
      //           // console.log("Google Added User Cookies Set");
      //           // Cookies.set('token', idToken, { expires: 1, secure: true, sameSite: 'strict' });
      //           // Cookies.set('uid', uid, { expires: 7, sameSite: 'strict', secure: true });
      //           setShowSuccessMessage({ message: "Signed in successfully", color: "green" });
      //           await new Promise(resolve => setTimeout(resolve, 1500));
      //           console.log(Cookies.get('token'));
      //           navigate("/dashboard");
      //         } else {
      //           throw new Error("Failed to Signin");
      //         }
      //         return;
      //       }
      //     } else {
      //       throw new Error("Failed to Signin");
      //     }
      //     return;
      //   }
      // }

      // When user not registered

      // When user registered but auth type was password
      // if (!status.data.user.emailVerified) {

      //   const status = await triggerSetEmailVerifySSO();
      //   console.log(status);

      //   if (status.data.status) {
      //     // Cookies.set('token', idToken, { expires: 1, secure: true, sameSite: 'strict' });
      //     // Cookies.set('uid', uid, { expires: 7, sameSite: 'strict', secure: true });
      //     setShowSuccessMessage({ message: "Already gerator user, Logged in successfully", color: "green" });
      //     await new Promise(resolve => setTimeout(resolve, 1500));
      //     navigate("/dashboard");
      //     return;
      //   }
      // }
      // else {
      //   // Cookies.set('token', idToken, { expires: 1, secure: true, sameSite: 'strict' });
      //   // Cookies.set('uid', uid, { expires: 7, sameSite: 'strict', secure: true });
      //   setShowSuccessMessage({ message: "Already gerator user, Logged in successfully", color: "green" });
      //   await new Promise(resolve => setTimeout(resolve, 1500));
      //   navigate("/dashboard");
      //   return;
      // }

    } catch (error) {
      if (error.code) {
        console.log(error.code);

        switch (error.code) {
          case 'auth/popup-closed-by-user':
            setShowSuccessMessage({ message: 'Login canceled. Please complete the sign-in process.', color: "red" });
            break;
          case 'auth/cancelled-popup-request':
            setShowSuccessMessage({ message: 'Login canceled. Please complete the sign-in process.', color: "red" });
            break;
          default:
            setShowSuccessMessage({ message: 'An unknown error occurred. Please try again later.', color: "red" });
        }
      } else {
        // console.log(errorGetUser);
        // if (errorGetUser) {
        //   const decryptedData = decryptData(errorGetUser.data);
        // console.log(decryptedData);
        // setShowSuccessMessage({ message: decryptedData.message, color: "red" });
        // return;
        // }
        const decryptedData = decryptData(error.data);
        console.log(decryptedData);
        setShowSuccessMessage({ message: decryptedData.message, color: "red" });
      }
    }
  };


  const finalSubmit = async (multiPartData, setSubmitting) => {
    try {
      console.log("inside finalSubmit method");
      console.log(multiPartData);

      const userCredential = await signInWithEmailAndPassword(
        getAuth(app),
        multiPartData.email,
        multiPartData.password
      );
      console.log(userCredential);

      const idToken = await userCredential.user.getIdToken();
      // const uid = userCredential.user.uid;
      console.log("User token is:", idToken);

      const status = await triggerGetUser();
      console.log(status);

      if (status.isSuccess) {
        console.log(status.data);
        const userDetails = status.data.user;
        console.log(userDetails);

        if (!userDetails.emailVerified && ((userDetails.wizardStep >= 1 && userDetails.wizardStep <= 3)))
          switch (userDetails.wizardStep) {
            case 1:
              navigate("/account-setup-check-email");
              break;
            case 2:
              navigate("/account-setup-update-profile");
              break;
            default:
              navigate("/account-setup-subscription-plan");
              break;
          }
        else if (userDetails.emailVerified && ((userDetails.wizardStep >= 2 && userDetails.wizardStep <= 3))) {
          switch (userDetails.wizardStep) {
            case 1:
              navigate("/account-setup-check-email");
              break;
            case 2:
              navigate("/account-setup-update-profile");
              break;
            default:
              navigate("/account-setup-subscription-plan");
              break;
          }
        }
        else {
          setShowSuccessMessage({ message: "Logged in successfully", color: "green" });
          await new Promise(resolve => setTimeout(resolve, 1500));
          navigate("/dashboard");
        }


        // setShowSuccessMessage({ message: "Logged in successfully", color: "green" });
        // await new Promise(resolve => setTimeout(resolve, 1500));
        // navigate("/dashboard");
      } else {
        throw new Error("Failed to retrieve user");
      }
    } catch (error) {
      console.log(error.code);

      if (error.code) {
        switch (error.code) {
          case 'auth/invalid-credential':
            setShowSuccessMessage({ message: 'Invalid credentials.', color: "red" });
            break;
          // case 'auth/email-already-in-use':
          //   setShowSuccessMessage({ message: 'This email is already in use by another account.', color: "red" });
          //   break;
          case 'auth/invalid-email':
            setShowSuccessMessage({ message: 'Invalid email format. Please enter a valid email address.', color: "red" });
            break;
          case 'auth/user-disabled':
            setShowSuccessMessage({ message: 'This user has been disabled. Please contact support.', color: "red" });
            break;
          // case 'auth/user-not-found':
          //   setShowSuccessMessage({ message: 'No user found with this email. Please sign up first.', color: "red" });
          //   break;
          // case 'auth/wrong-password':
          //   setShowSuccessMessage({ message: 'Incorrect password. Please try again or reset your password.', color: "red" });
          //   break;
          case 'auth/too-many-requests':
            setShowSuccessMessage({ message: 'Too many failed login attempts. Please try again later.', color: "red" });
            break;
          case 'auth/popup-closed-by-user':
            setShowSuccessMessage({ message: 'Login canceled. Please complete the sign-in process.', color: "red" });
            break;
          default:
            setShowSuccessMessage({ message: 'An unknown error occurred. Please try again later.', color: "red" });
        }
      } else if (error.status) {
        const decryptedData = decryptData(error.data);
        console.log(decryptedData);
        setShowSuccessMessage({ message: decryptedData.message, color: "red" });
      }
      else {
        setShowSuccessMessage({ message: error.message, color: "red" });
      }
    }
    setSubmitting(false);
  };
  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
      }}
      validationSchema={Yup.object({
        email: Yup.string()
          .email("Invalid email address")
          .required("Field is required"),
        password: Yup.string()
          .required('Field is required')
        // .min(8, 'Field must be at least 8 characters long')
        // .matches(/\d/, 'Field must contain at least one number')
        // .matches(/[a-zA-Z]/, 'Field must contain at least one letter')
      })}
      onSubmit={(values, { setSubmitting }) => {
        // multiPartData = objectToWantedDeviceFormData(values)
        finalSubmit(values, setSubmitting);
        console.log(values);
        // resetForm();
      }}
    >
      {({ handleSubmit, isSubmitting }) => (
        <>
          <section className="sign-in-login-form-container">
            <div className="sign-in-container1">
              <div className="sign-in-content6">
                <div className="sign-in-login-form-fields">
                  <div className="sign-in-login-credentials">
                    {/* <div className="sign-in-logomark">
                      <img
                        className="sign-in-content-icon"
                        loading="lazy"
                        alt=""
                        src={content_svg}
                      />
                    </div> */}
                  </div>
                  <div className="sign-in-brand-name">
                    <h1 className="sign-in-title">Log in to your account</h1>
                    <div className="sign-in-supporting-text2">
                      Welcome back! Please enter your details.
                    </div>
                  </div>
                </div>
                <form className="sign-in-content7">
                  <div className="sign-in-form1">
                    <div className="sign-in-input-field3">
                      <MyTextInput
                        validate={backendValidation}
                        className="sign-in-content8"
                        id="email"
                        label="Email"
                        name="email"
                        type="email"
                        placeholder="Enter your email"
                        autoComplete="true"
                      />
                    </div>
                    <div className="sign-in-input-field3">
                      <MyTextInput
                        validate={backendValidation}
                        className="sign-in-content8"
                        id="password"
                        label="Password"
                        name="password"
                        type="password"
                        placeholder="Enter your password"
                        autoComplete="true"
                      />
                    </div>
                  </div>
                  <div className="sign-in-remember-me">
                    <div className="sign-in-checkbox">
                      <input className="sign-in-input5" type="checkbox" />

                      <div className="sign-in-text-and-supporting-text1">
                        <div className="sign-in-checkbox-label">
                          Remember for 30 days
                        </div>
                        <div className="sign-in-supporting-text3">
                          Save my login details for next time.
                        </div>
                      </div>
                    </div>
                    <div className="sign-in-buttonsbutton6">
                      <img
                        className="sign-in-placeholder-icon8"
                        alt=""
                        src="/placeholder1.svg"
                      />

                      <div className="sign-in-submit-label">
                        <Link to={"/reset-password-send-email"}>Forgot password</Link>

                      </div>
                      <img
                        className="sign-in-placeholder-icon8"
                        alt=""
                        src="/placeholder1.svg"
                      />
                    </div>
                  </div>
                  <div className="sign-in-actions1">
                    <button
                      className="sign-in-buttonsbutton7"
                      type="submit"
                      onClick={handleSubmit}
                      disabled={isSubmitting}
                    >
                      <img
                        className="sign-in-placeholder-icon4"
                        alt=""
                        src="/placeholder.svg"
                      />
                      <div className="sign-in-text-padding1">
                        <a className="sign-in-icon">{isSubmitting ? "Signing..." : "Sign in"}</a>
                      </div>
                      <img
                        className="sign-in-placeholder-icon4"
                        alt=""
                        src="/placeholder.svg"
                      />
                    </button>
                    <div className="sign-in-social-button-groups1">
                      <div className="sign-in-social-button4" onClick={signInWithGoogle}>
                        <img
                          className="sign-in-social-icon4"
                          loading="lazy"
                          alt=""
                          src={social_logo_google}
                        />

                        <div className="sign-in-social-label">
                          Sign in with Google
                        </div>
                      </div>
                      {/* <div className="sign-in-social-button4" >
                        <img
                          className="sign-in-social-icon5"
                          alt=""
                          src={social_logo_facebook}
                        />

                        <div className="sign-in-social-label">
                          Sign in with Facebook
                        </div>
                      </div> */}

                    </div>
                  </div>
                </form>
                <div className="sign-in-account-help">
                  <div className="sign-in-help-link">
                    <div className="sign-in-question">
                      Don’t have an account?
                    </div>
                    <div className="sign-in-buttonsbutton8">
                      <img
                        className="sign-in-placeholder-icon8"
                        alt=""
                        src="/placeholder1.svg"
                      />
                      <Link to={"/sign-up"}>
                        <div className="sign-in-help-label">Sign up</div>
                      </Link>
                      <img
                        className="sign-in-placeholder-icon8"
                        alt=""
                        src="/placeholder1.svg"
                      />
                    </div>
                  </div>

                </div>
                <div className="sign-in-account-help">
                  {showSuccessMessage.message.length > 0 && (
                    <div
                      style={{
                        color: showSuccessMessage.color,
                        padding: "15px 10px",
                        border: "1px solid " + showSuccessMessage.color,
                        borderRadius: "10px",
                        margin: "0 auto",
                      }}
                    >
                      {showSuccessMessage.message}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </Formik>
  );
};
export default LoginForm;
