import React, { useState } from 'react';
import "./AddWantedDevice.css"
import { clearWantedDeviceForm } from "../../../components/AddWantedDeviceFormComponent/AddWantedDeviceSlice";
// import { purgeStorage } from "../..";
import { useDispatch, useSelector } from "react-redux";
import { useGetAgeOfDeviceTaxQuery, useGetAvailabilityTaxQuery, useGetClinicalApplicationsTaxQuery, useGetDeviceCategoryTaxQuery, useGetOemTaxQuery, useGetPhysicalLocationTaxQuery, useGetPurposeUseTaxQuery, useGetStatusConditionTaxQuery } from "../../../api/TaxonomyFormAPI";
import AddWantedDeviceForm from "../../../components/AddWantedDeviceFormComponent/AddWantedDeviceForm";


import { Navbar1 } from '../../../components/Navbar1/Navbar1'
import { FooterAddForms } from '../../../components/Footer/FooterAddForms/FooterAddForms';
import { AddWantedDeviceForm } from '../../../components/AddWantedDeviceFormComponent/AddWantedDeviceForm';
import divider from '../../../icons/Gerator/add_form/navbar_divider/divider.svg';
import divider_light from '../../../icons/Gerator/divider.svg';
import { Sidebar } from '../../../components/Sidebar/Sidebar';
import { SideBarSubNormal } from '../../../components/SideBars-Sub/SideBarSubNormal/SideBarSubNormal';
import { HeaderForms } from '../../../components/Header/HeaderForms/HeaderForms';
import { useEffect } from 'react';
import { useRef } from 'react';
import { auth, onAuthStateChanged } from '../../Login/FirebaseClient';
import { useNavigate } from 'react-router-dom';
import { useLazyGetUserProfileQuery, useLazyGetUserQuery } from '../../../api/Login&RegisterAPI';
import { Loader } from '../../../components/Loader';
import { decryptData, decryptErrorData } from '../../../helper/AESHelper';
import { Provider } from "./AddWantedDeviceContext";



export const AddWantedDevice = () => {

  const [triggerGetUser, { data, isSuccess, error, isLoading, isError }] = useLazyGetUserQuery();
  const [currentUser, setCurrentUser] = useState("");
  const navigate = useNavigate();

  const [showSuccessMessage, setShowSuccessMessage] = useState({
    message: "",
    color: "",
  });

  
  useEffect(() => {
    // const auth = getAuth();

    // Listen for authentication state change
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is authenticated
        setCurrentUser(user);
        triggerGetUser();
      } else {
        // User is not authenticated, redirect to login
        navigate('/sign-in', { state: { message: 'Please log in to continue.' } });
      }
    });

    return () => unsubscribe(); // Cleanup listener on component unmount
  }, [navigate, triggerGetUser]);

  //Device Category helper select field
  const [newDeviceCategoryLength, setNewDeviceCategoryLength] = useState(50);
  const [deviceCategorySearchField, setDeviceCategorySearchField] = useState("");

  //Oem helper select field
  const [newOemLength, setNewOemLength] = useState(50);
  const [oemSearchField, setOemSearchField] = useState("");

  const availabilityTax = useGetAvailabilityTaxQuery();
  const clinicalApplicationsTax = useGetClinicalApplicationsTaxQuery();
  const deviceCategoryTax = useGetDeviceCategoryTaxQuery({ id: newDeviceCategoryLength, query: deviceCategorySearchField });
  const oemTax = useGetOemTaxQuery({ id: newOemLength, query: oemSearchField });
  const physicalLocationTax = useGetPhysicalLocationTaxQuery();
  const purposeUseTax = useGetPurposeUseTaxQuery();
  const statusConditionTax = useGetStatusConditionTaxQuery();
  const ageOfDeviceTax = useGetAgeOfDeviceTaxQuery()

  // Progress var when this is true means all taxonomy loaded
  const [isTaxonomySet, setIsTaxonomySet] = useState(false);

  //useState var to save tax
  const [allTaxonomy, setAllTaxonomy] = useState({ ageOfDevice: ageOfDeviceTax, statusCondition: statusConditionTax, oem: oemTax, deviceCategory: deviceCategoryTax, physicalLocation: physicalLocationTax, purposeUse: purposeUseTax, clinicalApplications: clinicalApplicationsTax, availability: availabilityTax, })

  useEffect(() => {

    const allSuccess = [ageOfDeviceTax.isSuccess, statusConditionTax.isSuccess, oemTax.isSuccess, deviceCategoryTax.isSuccess, physicalLocationTax.isSuccess, purposeUseTax.isSuccess, clinicalApplicationsTax.isSuccess, availabilityTax.isSuccess,].every(Boolean);

    console.log('all success '+ allSuccess);
    

    if (allSuccess) {
      setAllTaxonomy({
        oem: oemTax, deviceCategory: deviceCategoryTax, physicalLocation: physicalLocationTax, purposeUse: purposeUseTax, clinicalApplications: clinicalApplicationsTax, availability: availabilityTax, statusCondition: statusConditionTax, ageOfDevice: ageOfDeviceTax
      });
    }

    setIsTaxonomySet(true);
  }, [
    oemTax.isSuccess, oemTax.data,
    deviceCategoryTax.isSuccess, deviceCategoryTax.data,
    availabilityTax.isSuccess, availabilityTax.data,
    clinicalApplicationsTax.isSuccess, clinicalApplicationsTax.data,
    purposeUseTax.isSuccess, purposeUseTax.data,
    physicalLocationTax.isSuccess, physicalLocationTax.data,
    statusConditionTax.isSuccess, statusConditionTax.data,
    ageOfDeviceTax.isSuccess, ageOfDeviceTax.data,
  ]);


  const [backendValidation, setBackendValidation] = useState([])
  const formikRef = useRef(null);
 

//
  if (error) {
    console.log(error);
    if (isError) {
      const decryptedData = decryptErrorData(error.data);
      console.log(decryptedData);
      // navigate("/sign-in", { state: decryptedData.message });
      navigate("/sign-in", { state: { message: decryptedData.message } });
    }
    else {
      navigate("/sign-in", { state: { message: "Internal server error" } });
    }
    return; // Optionally handle error differently
  }



if (isSuccess && isTaxonomySet) {
  return (


    <div className="add-wanted-device-forms-add-wanted-device add-wanted-device-forms-add-wanted-device-custom-margin">
      <div className="add-wanted-device-forms-add-wanted-device-child"></div>


      <Navbar1 userData={currentUser} navigate={navigate}/>

      <img className="divider-icon" alt="" src={divider} />


      <main className="add-wanted-device-content-parent">

        <Sidebar><SideBarSubNormal userData={currentUser} /></Sidebar>


        <section className="add-wanted-device-bottom-actions">
          <div className="add-wanted-device-progress-container-parent">
            <div className="add-wanted-device-progress-container">
              {/* <div className="add-wanted-device-progress-steps">
              <div className="add-wanted-device-form-parent">
               



              </div>
            </div> */}
              {/* {renderProgressStep(step)} */}
              {/* <ProgressStep1/> */}
              {/* <ProgressStep2/> */}
              {/* <ProgressStep3/> */}
              <div className="add-wanted-device-content5">
                <div className="add-wanted-device-main">


                  <HeaderForms text="Add Wanted Device " text1=" Add Wanted Device Listing" text2="Your first step to realizing asset value." />



                  <div className="add-wanted-device-main-child"></div>
                  <div className="add-wanted-device-main-item"></div>
                </div>
                <div className="add-wanted-device-body">
                  {/* {renderFormStep(step, setStep)} */}
                  <Provider value={{ oemSearchField, setOemSearchField, newOemLength, setNewOemLength, deviceCategorySearchField, setDeviceCategorySearchField, newDeviceCategoryLength, setNewDeviceCategoryLength, allTaxonomy, backendValidation, setBackendValidation }}>
                    <AddWantedDeviceForm formikRef={formikRef} setShowSuccessMessage={setShowSuccessMessage} />
                  </Provider>
                  {/* <AddWantedDeviceStep2/> */}
                  {/* <AddWantedDeviceStep3/> */}




                </div>
                {showSuccessMessage.message && (
                  <div
                    style={{
                      fontSize: "14px",
                      color: showSuccessMessage.color,
                      padding: "15px 10px",
                      border: "1px solid " + showSuccessMessage.color,
                      borderRadius: "10px",
                      margin: "0 auto",
                    }}
                  >
                    {showSuccessMessage.message}
                  </div>
                )}
              </div>
            </div>

          </div>
        </section>
      </main>
      <img
        className="add-wanted-device-divider-icon10"
        loading="lazy"
        alt=""
        src={divider_light}
      />
      <FooterAddForms />
    </div>
  )
} else{
  return <Loader />;
}
  
}

 //This snippet used to reset form and applied on reset form button
  
  // const appData = useSelector((state) => state.appDataWantedDevice);
  // const dispatch = useDispatch();
  // const handleReset = (errors) => {
  //   if (formikRef.current && backendValidation.length > 0) {
  //     setBackendValidation([])
  //     dispatch(clearWantedDeviceForm())
  //     return;
  //   }
  //   // setBackendValidation([])
  //   // dispatch(clearWantedDeviceForm())
  //   // formikRef.current.resetForm();
  // }

  // const handleReset = (errors) => {
  //   if (formikRef.current && backendValidation.length > 0) {
  //     setBackendValidation([])
  //     dispatch(clearWantedDeviceForm())
  //     setDeviceCategorySearchField("");
  //     setOemSearchField("");
  //     return;
  //   }
  //   dispatch(clearWantedDeviceForm())
  //   setDeviceCategorySearchField("");
  //   setOemSearchField("");
  //   formikRef.current.resetForm();
  //   // setBackendValidation([])
  //   // dispatch(clearWantedDeviceForm())
  // }