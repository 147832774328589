import React from 'react';
import "./ArchiveJob.css"
import "./ArchiveJob2.css"

import { Navbar1 } from '../../../components/Navbar1/Navbar1';
import { FooterAddForms } from '../../../components/Footer/FooterAddForms/FooterAddForms';
import React, { useEffect, useState } from "react";
import { useLazyGetJobsQuery, useDeleteJobMutation } from "../../../api/JobAPI";

import { getCustomizedDate } from "../../../helper/ServiceHelper";
import { Loader } from "../../../components/Loader";
import { Link, Navigate, useNavigate } from "react-router-dom";
import divider1 from '../../../icons/Gerator/archive_page/divider1.svg';

import divider4 from '../../../icons/Gerator/archive_page/divider-4.svg';
import divider12 from '../../../icons/Gerator/archive_page/divider-12.svg';
import divider13 from '../../../icons/Gerator/archive_page/divider-13.svg';
import divider21 from '../../../icons/Gerator/archive_page/divider-21.svg';
import magnifier_1_2x from '../../../icons/Gerator/archive_page/magnifier-1@2x.png';
import J8595f4b711 from '../../../icons/Gerator/archive_page/8595f4b711e503bc72fe396e5043e0c2-1@2x.png';

import dot from '../../../icons/Gerator/archive_page/-dot.svg';
import dotsvertical from '../../../icons/Gerator/archive_page/dotsvertical.svg';

import help_icon2 from '../../../icons/Gerator/archive_page/help-icon2.svg';
import help_icon from '../../../icons/Gerator/archive_page/help-icon.svg';
import radar_1_1_2x from '../../../icons/Gerator/archive_page/radar-1-1@2x.png';
import deal_2_1_2x from '../../../icons/Gerator/archive_page/deal-2-1@2x.png';

import featured_icon1 from '../../../icons/Gerator/archive_page/featured-icon1.svg';


import chevronright from '../../../icons/Gerator/archive_page/chevronright.svg';
import chevrondown from '../../../icons/Gerator/archive_page/chevrondown.svg';

import placeholder from '../../../icons/Gerator/archive_page/placeholder.svg';
import placeholder1 from '../../../icons/Gerator/archive_page/placeholder1.svg';
import placeholder2 from '../../../icons/Gerator/archive_page/placeholder2.svg';
import placeholder3 from '../../../icons/Gerator/archive_page/placeholder3.svg';

import homeline from '../../../icons/Gerator/archive_page/homeline.svg';
import searchlg from '../../../icons/Gerator/archive_page/searchlg.svg';

import calendar from '../../../icons/Gerator/archive_page/calendar.svg';


import filterfunnel01 from '../../../icons/Gerator/archive_page/filterfunnel01.svg';
import searchlg from '../../../icons/Gerator/archive_page/searchlg.svg';
import filterlines from '../../../icons/Gerator/archive_page/filterlines.svg';
import dot1 from '../../../icons/Gerator/archive_page/-dot1.svg';
import xclose from '../../../icons/Gerator/archive_page/xclose.svg';
import xclose10 from '../../../icons/Gerator/archive_page/xclose-10.svg';
import target_04 from "../../../icons/Gerator/dashboard/target_04.png"

import markerpin02 from '../../../icons/Gerator/archive_page/markerpin02.svg';
import sliders02 from '../../../icons/Gerator/archive_page/sliders02.svg';
import { useLazyGetUserQuery } from "../../../api/Login&RegisterAPI";
import { auth, onAuthStateChanged } from '../../Login/FirebaseClient';
import { decryptErrorData } from "../../../helper/AESHelper";
import { useLazyGetJobsQuery } from '../../../api/JobAPI';
import { NoListingFound } from '../../../components/NoListing/NoListingFound';
import { useAddRadarJobMutation, useDeleteRadarJobMutation } from '../../../api/Radar/RadarJobAPI';
import { Helmet } from 'react-helmet-async';

export const ArchiveJob = () => {

  let dataLength = 0;
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState("");
  const [showFilterLayout, setShowFilterLayout] = useState(false)


  const [deleteJob] = useDeleteJobMutation();
  const [triggerGetJob, { data: jobData,
    isLoading,
    isSuccess,
    isError,
    error }] = useLazyGetJobsQuery();

  const [addToRadar] = useAddRadarJobMutation();

  const [deleteFromRadar] = useDeleteRadarJobMutation();

  let content;
  // const [totalListings, setTotalListings] = useState("0");

  const handleRemoveRadar = async (softwareId) => {
    await deleteFromRadar(softwareId);
    window.location.reload();
  };

  const handleAddRadar = async (softwareId) => {
    await addToRadar(softwareId);
    window.location.reload();
  };

  useEffect(() => {
    // const auth = getAuth();

    // Listen for authentication state change
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is authenticated
        setCurrentUser(user);
        triggerGetJob();
      } else {
        // User is not authenticated, redirect to login
        navigate('/sign-in', { state: { message: 'Please log in to continue.' } });
      }
    });

    return () => unsubscribe(); // Cleanup listener on component unmount
    // }, [navigate]);
  }, [Navigate]);

  //Get all Devices
  if (showFilterLayout === false) {
    if (isLoading) {
      content = <Loader />
    } else if (isSuccess) {

      if (jobData.data.length > 0) {
        dataLength = jobData.data.length;
        content = jobData.data.map((job, index) =>
        (
          <div className="archive-device-rectangle-group" key={index}>
            <div className="archive-job-group-div">
              <img
                className="archive-job-dots-vertical-icon5"
                alt=""
                src={dotsvertical}
              />


              <div className="archive-job-business-listing">
                <div className="archive-job-business-image">
                  <img
                    className="archive-job-f4b711e503bc72fe396e5043e0c2-1-icon1"
                    alt=""
                    src={job.logoImage}
                  />

                </div>
                <div className="archive-job-business-details">
                  <div className="archive-job-text-group">
                    <div className="archive-job-frame-parent11">
                      <div className="archive-job-text-parent4">
                        <Link to={`/job/${job.id}/${job.title}`} className="archive-device1-text37 ">
                          {job.title}
                        </Link>

                      </div>


                    </div>

                    <div className="archive-job-business-location">

                      <div className="archive-job-badge-parent">
                        {/* <div className="archive-job-supporting-text15">
                          {job.job_business.businessName}
                        </div> */}
                        <Link to={`/business/${job.job_business.id}/${job.job_business.businessName}`} className="archive-job-supporting-text15 ">
                          {job.job_business.businessName}
                        </Link>
                      </div>

                      <div className="archive-job-navigation1">
                        <img
                          className="archive-job-marker-pin-02-icon"
                          alt=""
                          src={markerpin02}
                        />
                      </div>
                      {job.job_address.tax_address_type
                        .slug === "address" ?
                        <div className="archive-job-location">{job.job_address.addressTownCity},  {job.job_address.tax_country.name}</div>
                        :
                        <div className="archive-job-location">{job.job_address.tax_address_type
                          .name}</div>
                      }
                      {/* <div className="archive-job-location">{job.location}</div> */}


                      <div className="archive-job-pair-container-parent">
                        <div className="archive-job-navigation">
                          <img
                            className="archive-job-marker-pin-02-icon2"
                            alt=""
                            src={calendar}
                          />
                        </div>
                        <div className="archive-job-type-of-work3 archive-device1-text-overflow">
                          {getCustomizedDate(job.createdAt)}
                        </div>
                      </div>

                    </div>


                  </div>
                  <div className="archive-job-application-count">
                    <div className="archive-job-completed">
                      <div className="archive-job-second-value">4.9/5</div>
                    </div>
                    <div className="archive-job-supporting-text-wrapper">
                      <div className="archive-job-supporting-text3">99,999 reviews</div>
                    </div>
                  </div>
                  <div className="archive-job-badges-parent">
                    <div className="archive-job-badges1">
                      <div className="archive-job-badge-one">{job.tax_engagement_type.name}</div>
                      <div className="archive-job-badge-two">{job.tax_experience_level.name}</div>

                    </div>
                    <div className="archive-job-badge-support-parent">
                      <div className="archive-job-badge-support">
                        <div className="archive-job-supporting-text22">Engagement Type
                        </div>
                      </div>
                      <div className="archive-job-supporting-text232">Experience</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="archive-job-frame-parent24">

                <div className="archive-job-pair-container-group">
                  <div className="archive-job-text-wrapper3">
                    <div className="archive-job-text81">From</div>
                  </div>
                  <div className="archive-job-price6">${job.budget}</div>
                  <div className="archive-job-pair-container-container">
                    <div className="archive-job-deal-details">
                      <div className="archive-job-text66">{job.tax_unit_measure_service.name}</div>
                      <div className="archive-job-deal-inner">
                        <div className="archive-job-radar-1-1-container">
                          {/* <img
                            className="archive-job-magnifier-1-icon"
                            alt=""
                            src={radar_1_1_2x}
                          /> */}
                          {!job.isAuthor && (
                            job.addedToRadar ? (
                              <img
                                className="archive-job-magnifier-1-icon"
                                alt=""
                                onClick={() => handleRemoveRadar(job.id)}
                                src={target_04}
                                style={{ cursor: "pointer" }}
                              />
                            ) : (
                              <img
                                className="archive-job-magnifier-1-icon"
                                alt=""
                                onClick={() => handleAddRadar(job.id)}
                                src={radar_1_1_2x}
                                style={{ cursor: "pointer" }}
                              />
                            )
                          )}
                          <img
                            className="archive-job-magnifier-1-icon"
                            alt=""
                            src={deal_2_1_2x}
                          />

                          <img
                            className="archive-job-magnifier-1-icon"
                            alt=""
                            src={magnifier_1_2x}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))
      }
      else {
        content = (

          <NoListingFound path="/add-job" pathName={"Job"} />

        )
      }

    } else if (isError) {
      content = <div style={{ color: "white" }}>{error.originalStatus + " " + error.data}</div>
    }
  }



  return (
    <>
      <Helmet>
        <title>Archive - Jobs</title>
      </Helmet>
      <div className="archive-device-listings-search-results-list">
        <Navbar1 userData={currentUser} navigate={navigate} />
        <div className='archive-device-mid-container'>
          <div className="archive-device-slide-out">
            <img className="archive-device-divider-icon14" alt="" src={divider12} />

            <div className="archive-device-rectangle-parent4">
              <div className="archive-device-frame-child8"></div>
              <div className="archive-device-frame-child9"></div>
              <img className="archive-device-dot-icon7" alt="" src={dot} />

              <img className="archive-device-dot-icon8" alt="" src={dot} />

              <img className="archive-device-dot-icon9" alt="" src={dot} />

              <img className="archive-device-dot-icon10" alt="" src={dot} />

              <img className="archive-device-dot-icon11" alt="" src={dot} />

              <img className="archive-device-dot-icon12" alt="" src={dot} />

              <img className="archive-device-dot-icon13" alt="" src={dot} />

              <div className="archive-device-frame-parent28">
                <div className="archive-device-slide-out-menu-header-parent">
                  <div className="archive-device-slide-out-menu-header">
                    <div className="archive-device-content24">
                      <img
                        className="archive-device-featured-icon1"
                        alt=""
                        src={featured_icon1}
                      />

                      <div className="archive-device-text-and-supporting-text6">
                        <a className="archive-device-text184">Filters</a>
                        <div className="archive-device-supporting-text57">
                          Apply filters to table data.
                        </div>
                      </div>
                    </div>
                    <div className="archive-device-button-close-x">
                      <img
                        className="archive-device-search-lg-icon"
                        alt=""
                        src={xclose10}
                      />
                    </div>
                  </div>
                  <div className="archive-device-frame-wrapper13">
                    <div className="archive-device-slide-out-title-parent">
                      <a className="archive-device-slide-out-title">Price</a>
                      <div className="archive-device-navigation">
                        <img
                          className="archive-device-marker-pin-02-icon2"
                          alt=""
                          src={chevrondown}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="archive-device-frame-parent29">
                  <div className="archive-device-frame-parent30">
                    <div className="archive-device-frame-wrapper14">
                      <div className="archive-device-frame-parent31">
                        <div className="archive-device-background-parent">
                          <div className="archive-device-background"></div>
                          <div className="archive-device-progress">
                            <div className="archive-device-progress-line"></div>
                            <div className="archive-device-control-handle">
                              <div className="archive-device-tooltip1">
                                <div className="archive-device-content25">
                                  <div className="archive-device-text185">0</div>
                                </div>
                              </div>
                              <div className="archive-device-handle"></div>
                            </div>
                            <div className="archive-device-control-handle">
                              <div className="archive-device-tooltip2">
                                <div className="archive-device-content26">
                                  <div className="archive-device-text186">9,999,999,99</div>
                                </div>
                              </div>
                              <div className="archive-device-handle"></div>
                            </div>
                          </div>
                        </div>
                        <div className="archive-device-input-fields-parent">
                          <div className="archive-device-input-fields">
                            <div className="archive-device-input-field">
                              <div className="archive-device-input-with-label4">
                                <div className="archive-device-label5">Email</div>
                                <div className="archive-device-input4">
                                  <div className="archive-device-content27">
                                    <div className="archive-device-text187">Min Value</div>
                                  </div>
                                  <img
                                    className="archive-device-help-icon1"
                                    alt=""
                                    src="/archive_page/help-icon1.svg"
                                  />
                                </div>
                              </div>
                              <div className="archive-device-hint-text">
                                This is a hint text to help user.
                              </div>
                            </div>
                            <div className="archive-device-text188">–</div>
                            <div className="archive-device-input-field">
                              <div className="archive-device-input-with-label4">
                                <div className="archive-device-label5">Email</div>
                                <div className="archive-device-input4">
                                  <div className="archive-device-content27">
                                    <div className="archive-device-text187">Max Value</div>
                                  </div>
                                  <img
                                    className="archive-device-help-icon1"
                                    alt=""
                                    src="/archive_page/help-icon1.svg"
                                  />
                                </div>
                              </div>
                              <div className="archive-device-hint-text">
                                This is a hint text to help user.
                              </div>
                            </div>
                          </div>
                          <div className="archive-device-checkboxes">
                            <div className="archive-device-collapsed-names">
                              <input className="archive-device-input6" type="checkbox" />

                              <div className="archive-device-price-parent">
                                <div className="archive-device-text8">US$ 0 — US$ 1,000</div>
                                <div className="archive-device-hint-text">
                                  Save my login details for next time.
                                </div>
                              </div>
                            </div>
                            <div className="archive-device-collapsed-names">
                              <input className="archive-device-input6" type="checkbox" />

                              <div className="archive-device-price-parent">
                                <div className="archive-device-text8">US$ 1,001 — US$ 5,000</div>
                                <div className="archive-device-hint-text">
                                  Save my login details for next time.
                                </div>
                              </div>
                            </div>
                            <div className="archive-device-collapsed-names">
                              <input className="archive-device-input6" type="checkbox" />

                              <div className="archive-device-price-parent">
                                <div className="archive-device-text8">US$ 5,001 — US$ 15,000</div>
                                <div className="archive-device-hint-text">
                                  Save my login details for next time.
                                </div>
                              </div>
                            </div>
                            <div className="archive-device-collapsed-names">
                              <input className="archive-device-input6" type="checkbox" />

                              <div className="archive-device-price-parent">
                                <div className="archive-device-text8">US$ 15,001 — US$ 9,999,999</div>
                                <div className="archive-device-hint-text">
                                  Save my login details for next time.
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="archive-device-frame-child10"></div>
                  </div>
                  <img
                    className="archive-device-divider-icon15"
                    alt=""
                    src={divider13}
                  />

                  <div className="archive-device-section-wrapper">
                    <div className="archive-device-section-parent">
                      <div className="archive-device-section">
                        <div className="archive-device-content-parent">
                          <div className="archive-device-content29">
                            <img className="archive-device-dot-icon" alt="" src={dot} />

                            <div className="archive-device-text194">Date Posted</div>
                          </div>
                          <img
                            className="archive-device-search-lg-icon"
                            alt=""
                            src={chevrondown}
                          />
                        </div>
                      </div>
                      <div className="archive-device-input-fields">
                        <div className="archive-device-input-field2">
                          <div className="archive-device-input-with-label4">
                            <div className="archive-device-label5">Email</div>
                            <div className="archive-device-input10">
                              <div className="archive-device-content27">
                                <div className="archive-device-text187">Jan 6, 2024</div>
                              </div>
                              <img
                                className="archive-device-help-icon1"
                                alt=""
                                src={help_icon2}
                              />
                            </div>
                          </div>
                          <div className="archive-device-hint-text">
                            This is a hint text to help user.
                          </div>
                        </div>
                        <div className="archive-device-text188">–</div>
                        <div className="archive-device-input-field2">
                          <div className="archive-device-input-with-label4">
                            <div className="archive-device-label5">Email</div>
                            <div className="archive-device-input10">
                              <div className="archive-device-content27">
                                <div className="archive-device-text187">Jan 12, 2024</div>
                              </div>
                              <img
                                className="archive-device-help-icon1"
                                alt=""
                                src={help_icon2}
                              />
                            </div>
                          </div>
                          <div className="archive-device-hint-text">
                            This is a hint text to help user.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <img
                    className="archive-device-divider-icon15"
                    alt=""
                    src={divider13}
                  />

                  <div className="archive-device-section-wrapper">
                    <div className="archive-device-section1">
                      <div className="archive-device-content-parent">
                        <div className="archive-device-content29">
                          <img className="archive-device-dot-icon" alt="" src={dot} />

                          <div className="archive-device-text198">Seller Role</div>
                        </div>
                        <img
                          className="archive-device-search-lg-icon"
                          alt=""
                          src={chevrondown}
                        />
                      </div>
                      <div className="archive-device-checkboxes1">
                        <div className="archive-device-collapsed-names">
                          <input className="archive-device-input6" type="checkbox" />

                          <div className="archive-device-price-parent">
                            <div className="archive-device-text8">Dealer/Distributor</div>
                            <div className="archive-device-hint-text">
                              Save my login details for next time.
                            </div>
                          </div>
                        </div>
                        <div className="archive-device-collapsed-names">
                          <input className="archive-device-input6" type="checkbox" />

                          <div className="archive-device-price-parent">
                            <div className="archive-device-text8">Manufacturer</div>
                            <div className="archive-device-hint-text">
                              Save my login details for next time.
                            </div>
                          </div>
                        </div>
                        <div className="archive-device-collapsed-names">
                          <input className="archive-device-input6" type="checkbox" />

                          <div className="archive-device-price-parent">
                            <div className="archive-device-text8">Owner/Employee</div>
                            <div className="archive-device-hint-text">
                              Save my login details for next time.
                            </div>
                          </div>
                        </div>
                        <div className="archive-device-collapsed-names">
                          <input className="archive-device-input6" type="checkbox" />

                          <div className="archive-device-price-parent">
                            <div className="archive-device-text8">Third Party/Agent</div>
                            <div className="archive-device-hint-text">
                              Save my login details for next time.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <img
                    className="archive-device-divider-icon15"
                    alt=""
                    src={divider13}
                  />

                  <div className="archive-device-section-wrapper">
                    <div className="archive-device-section1">
                      <div className="archive-device-content-parent">
                        <div className="archive-device-content29">
                          <img className="archive-device-dot-icon" alt="" src={dot} />

                          <div className="archive-device-text203">Transaction Type</div>
                        </div>
                        <img
                          className="archive-device-search-lg-icon"
                          alt=""
                          src={chevrondown}
                        />
                      </div>
                      <div className="archive-device-checkboxes1">
                        <div className="archive-device-collapsed-names">
                          <input className="archive-device-input6" type="checkbox" />

                          <div className="archive-device-price-parent">
                            <div className="archive-device-text8">Exchange</div>
                            <div className="archive-device-hint-text">
                              Save my login details for next time.
                            </div>
                          </div>
                        </div>
                        <div className="archive-device-collapsed-names">
                          <input className="archive-device-input6" type="checkbox" />

                          <div className="archive-device-price-parent">
                            <div className="archive-device-text8">Rent/Lease</div>
                            <div className="archive-device-hint-text">
                              Save my login details for next time.
                            </div>
                          </div>
                        </div>
                        <div className="archive-device-collapsed-names">
                          <input className="archive-device-input6" type="checkbox" />

                          <div className="archive-device-price-parent">
                            <div className="archive-device-text8">Sale</div>
                            <div className="archive-device-hint-text">
                              Save my login details for next time.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <img
                    className="archive-device-divider-icon15"
                    alt=""
                    src={divider13}
                  />

                  <div className="archive-device-section-wrapper">
                    <div className="archive-device-section3">
                      <div className="archive-device-content-parent">
                        <div className="archive-device-content29">
                          <img className="archive-device-dot-icon" alt="" src={dot} />

                          <div className="archive-device-text207">Status</div>
                        </div>
                        <img
                          className="archive-device-search-lg-icon"
                          alt=""
                          src={chevrondown}
                        />
                      </div>
                    </div>
                  </div>
                  <img
                    className="archive-device-divider-icon15"
                    alt=""
                    src={divider13}
                  />

                  <div className="archive-device-section-wrapper">
                    <div className="archive-device-section3">
                      <div className="archive-device-content-parent">
                        <div className="archive-device-content29">
                          <img className="archive-device-dot-icon" alt="" src={dot} />

                          <div className="archive-device-text208">Warranty</div>
                        </div>
                        <img
                          className="archive-device-search-lg-icon"
                          alt=""
                          src={chevrondown}
                        />
                      </div>
                    </div>
                  </div>
                  <img
                    className="archive-device-divider-icon15"
                    alt=""
                    src={divider13}
                  />

                  <div className="archive-device-section-wrapper">
                    <div className="archive-device-section3">
                      <div className="archive-device-content-parent">
                        <div className="archive-device-content29">
                          <img className="archive-device-dot-icon" alt="" src={dot} />

                          <div className="archive-device-text209">Shipping</div>
                        </div>
                        <img
                          className="archive-device-search-lg-icon"
                          alt=""
                          src={chevrondown}
                        />
                      </div>
                    </div>
                  </div>
                  <img
                    className="archive-device-divider-icon15"
                    alt=""
                    src={divider13}
                  />

                  <div className="archive-device-section-wrapper">
                    <div className="archive-device-section-parent">
                      <div className="archive-device-actions6">
                        <div className="archive-device-navigation">
                          <input className="archive-device-search-lg" type="radio" />
                        </div>
                        <div className="archive-device-text210">Country</div>
                      </div>
                      <div className="archive-device-input-fields2">
                        <div className="archive-device-input-field2">
                          <div className="archive-device-input-with-label4">
                            <div className="archive-device-label5">Email</div>
                            <div className="archive-device-input19">
                              <input
                                className="archive-device-content37"
                                placeholder="Search"
                                type="text"
                              />

                              <img
                                className="archive-device-help-icon1"
                                alt=""
                                src={help_icon}
                              />
                            </div>
                          </div>
                          <div className="archive-device-hint-text">
                            This is a hint text to help user.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="archive-device-second-badge-divider">
                    <img
                      className="archive-device-divider-icon22"
                      alt=""
                      src={divider13}
                    />

                    <img
                      className="archive-device-divider-icon23"
                      alt=""
                      src={divider21}
                    />
                  </div>
                  <div className="archive-device-frame-wrapper17">
                    <div className="archive-device-actions6">
                      <div className="archive-device-navigation">
                        <input className="archive-device-search-lg" type="radio" />
                      </div>
                      <div className="archive-device-text211">Device Category</div>
                    </div>
                  </div>
                  <div className="archive-device-second-badge-divider">
                    <img
                      className="archive-device-divider-icon22"
                      alt=""
                      src={divider13}
                    />

                    <img
                      className="archive-device-divider-icon23"
                      alt=""
                      src={divider21}
                    />
                  </div>
                  <div className="archive-device-frame-wrapper17">
                    <div className="archive-device-actions6">
                      <div className="archive-device-navigation">
                        <input className="archive-device-search-lg" type="radio" />
                      </div>
                      <div className="archive-device-text212">OEM Brand</div>
                    </div>
                  </div>
                  <div className="archive-device-frame-parent36">
                    <div className="archive-device-second-badge-divider">
                      <img
                        className="archive-device-divider-icon22"
                        alt=""
                        src={divider13}
                      />

                      <img
                        className="archive-device-divider-icon23"
                        alt=""
                        src={divider21}
                      />
                    </div>
                    <div className="archive-device-frame-wrapper19">
                      <div className="archive-device-frame-parent37">
                        <div className="archive-device-navigation">
                          <input className="archive-device-search-lg" type="radio" />
                        </div>
                        <div className="archive-device-text213">Year of Manufacture</div>
                      </div>
                    </div>
                    <div className="archive-device-second-badge-divider">
                      <img
                        className="archive-device-divider-icon22"
                        alt=""
                        src={divider13}
                      />

                      <img
                        className="archive-device-divider-icon23"
                        alt=""
                        src={divider21}
                      />
                    </div>
                    <div className="archive-device-frame-wrapper19">
                      <div className="archive-device-actions6">
                        <div className="archive-device-navigation">
                          <input className="archive-device-search-lg" type="radio" />
                        </div>
                        <div className="archive-device-text213">Clinical Applications</div>
                      </div>
                    </div>
                  </div>
                  <div className="archive-device-card-header-parent">
                    <img
                      className="archive-device-divider-icon15"
                      alt=""
                      src={divider13}
                    />

                    <img
                      className="archive-device-divider-icon31"
                      alt=""
                      src={divider21}
                    />
                  </div>
                  <div className="archive-device-frame-wrapper17">
                    <div className="archive-device-actions6">
                      <div className="archive-device-navigation">
                        <input className="archive-device-search-lg" type="radio" />
                      </div>
                      <div className="archive-device-text212">Device Use</div>
                    </div>
                  </div>
                  <div className="archive-device-second-badge-divider">
                    <img
                      className="archive-device-divider-icon22"
                      alt=""
                      src={divider13}
                    />

                    <img
                      className="archive-device-divider-icon23"
                      alt=""
                      src={divider21}
                    />
                  </div>
                  <div className="archive-device-frame-wrapper17">
                    <div className="archive-device-frame-parent37">
                      <div className="archive-device-navigation">
                        <input className="archive-device-search-lg" type="radio" />
                      </div>
                      <div className="archive-device-text216">Physical Location</div>
                    </div>
                  </div>
                  <img
                    className="archive-device-divider-icon15"
                    alt=""
                    src={divider13}
                  />
                </div>
              </div>
              <img className="archive-device-dot-icon20" alt="" src={dot} />

              <div className="archive-device-frame-parent41">
                <div className="archive-device-divider-parent6">
                  <img
                    className="archive-device-divider-icon35"
                    alt=""
                    src={divider13}
                  />

                  <div className="archive-device-footer1">
                    <div className="archive-device-content38">
                      <div className="archive-device-logo1">
                        <div className="archive-device-buttonsbutton37">
                          <img
                            className="archive-device-placeholder-icon"
                            alt=""
                            src={placeholder2}
                          />

                          <div className="archive-device-text217">Save filter</div>
                          <img
                            className="archive-device-placeholder-icon"
                            alt=""
                            src={placeholder2}
                          />
                        </div>
                      </div>
                      <div className="archive-device-actions9">
                        <button className="archive-device-buttonsbutton38">
                          <img
                            className="archive-device-placeholder-icon"
                            alt=""
                            src={placeholder}
                          />

                          <div className="archive-device-text-padding">
                            <div className="archive-device-text218">Cancel</div>
                          </div>
                          <img
                            className="archive-device-placeholder-icon"
                            alt=""
                            src={placeholder}
                          />
                        </button>
                        <button className="archive-device-buttonsbutton39">
                          <img
                            className="archive-device-placeholder-icon"
                            alt=""
                            src={placeholder3}
                          />

                          <div className="archive-device-text-padding">
                            <div className="archive-device-text219">Apply</div>
                          </div>
                          <img
                            className="archive-device-placeholder-icon"
                            alt=""
                            src={placeholder3}
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="archive-device-dot-item-innermost">
                  <div className="archive-device-collapsed-content">
                    <img
                      className="archive-device-divider-icon22"
                      alt=""
                      src={divider13}
                    />

                    <img
                      className="archive-device-divider-icon37"
                      alt=""
                      src={divider21}
                    />
                  </div>
                </div>
              </div>
            </div>

          </div>

          <div className="archive-device-filters">
            <div className='archive-device-filter-selected-div'>
              <div className="archive-device-main">
                <div className="archive-device-header-section">
                  <div className="archive-device-container">
                    <div className="archive-device-page-header">
                      <div className="archive-device-breadcrumbs">
                        <div className="archive-device-tabs">
                          <div className="archive-device-breadcrumb-button-base">
                            <img
                              className="archive-device-search-lg-icon"
                              alt=""
                              src={homeline}
                            />
                          </div>
                          <img
                            className="archive-device-chevron-right-icon"
                            alt=""
                            src={chevronright}
                          />

                          <div className="archive-device-breadcrumb-button-base1">
                            <a className="archive-device-text7">Dashboard</a>
                          </div>
                          <img
                            className="archive-device-chevron-right-icon"
                            alt=""
                            src={chevronright}
                          />

                          <div className="archive-device-breadcrumb-button-base2">
                            <div className="archive-device-text8">...</div>
                          </div>
                          <img
                            className="archive-device-chevron-right-icon2"
                            alt=""
                            src={chevronright}
                          />

                          <div className="archive-device-breadcrumb-button-base3">
                            <div className="archive-device-text9">Another link</div>
                          </div>
                          <img
                            className="archive-device-chevron-right-icon2"
                            alt=""
                            src={chevronright}
                          />

                          <div className="archive-device-breadcrumb-button-base4">
                            <a className="archive-device-text10">Category</a>
                          </div>
                        </div>
                      </div>
                      <img className="archive-device-divider-icon" alt="" src="/archive_page/divider.svg" />
                    </div>
                  </div>
                </div>
                <div className="archive-device-main-child"></div>
                <div className="archive-device-main-item"></div>
              </div>
              <div className="archive-device-secondary-content">
                <div className="archive-device-page-header1">
                  <div className="archive-device-breadcrumbs1">
                    <div className="archive-device-tabs1">
                      <div className="archive-device-breadcrumb-button-base">
                        <img
                          className="archive-device-search-lg-icon"
                          alt=""
                          src={homeline}
                        />
                      </div>
                      <img
                        className="archive-device-chevron-right-icon"
                        alt=""
                        src={chevronright}
                      />

                      <div className="archive-device-breadcrumb-button-base6">
                        <div className="archive-device-text11">Settings</div>
                      </div>
                      <img
                        className="archive-device-chevron-right-icon"
                        alt=""
                        src={chevronright}
                      />

                      <div className="archive-device-breadcrumb-button-base7">
                        <div className="archive-device-text8">...</div>
                      </div>
                      <img
                        className="archive-device-chevron-right-icon"
                        alt=""
                        src={chevronright}
                      />

                      <div className="archive-device-breadcrumb-button-base3">
                        <div className="archive-device-text9">Another link</div>
                      </div>
                      <img
                        className="archive-device-chevron-right-icon2"
                        alt=""
                        src={chevronright}
                      />

                      <div className="archive-device-breadcrumb-button-base4">
                        <div className="archive-device-menu-label">Team</div>
                      </div>
                    </div>
                  </div>
                  <div className="archive-device-content4">
                    <div className="archive-device-text-and-supporting-text">
                      <div className="archive-device-page-title">{dataLength} Listings Found</div>
                      <div className="archive-device-supporting-text">
                        Manage your team members and their account permissions here.
                      </div>
                    </div>
                    <div className="archive-device-input-dropdown">
                      <div className="archive-device-input-with-label1">
                        <div className="archive-device-label1">Search</div>
                        <div className="archive-device-input1">
                          <div className="archive-device-content5">
                            <img
                              className="archive-device-search-lg-icon"
                              alt=""
                              src={searchlg}
                            />

                            <div className="archive-device-text15">Search</div>
                          </div>
                        </div>
                      </div>
                      <div className="archive-device-hint-text">This is a hint text to help user.</div>
                    </div>
                  </div>
                  <img className="archive-device-divider-icon1" alt="" src={divider1} />
                </div>
              </div>
              <div className="archive-device-button-group">
                <button className="archive-device-button-group-base">
                  <a className="archive-device-text16">View all</a>
                </button>
                <div className="archive-device-button-group-base1">
                  <div className="archive-device-text17">Monitored</div>
                </div>
                <div className="archive-device-button-group-base2">
                  <div className="archive-device-menu-label">Text</div>
                </div>
                <div className="archive-device-button-group-base3">
                  <div className="archive-device-menu-label">Text</div>
                </div>
                <div className="archive-device-button-group-base4">
                  <div className="archive-device-menu-label">Text</div>
                </div>
                <div className="archive-device-button-group-base5">
                  <div className="archive-device-menu-label">Text</div>
                </div>
                <div className="archive-device-button-group-base6">
                  <div className="archive-device-menu-label">Text</div>
                </div>
                <div className="archive-device-button-group-base7">
                  <div className="archive-device-menu-label">Text</div>
                </div>
                <div className="archive-device-button-group-base1">
                  <div className="archive-device-text24">Unmonitored</div>
                </div>

              </div>
              <div className="archive-device-input-dropdown1">
                <div className="archive-device-hint-text1">This is a hint text to help user.</div>
                <div className="archive-device-buttonsbutton">
                  <img className="archive-device-sliders-02-icon" alt="" src={sliders02} />

                  <div className="archive-device-text-padding">
                    <div className="archive-device-menu-label">Sort By : Relevance</div>
                    <img
                      className="archive-device-sliders-02-icon"
                      alt=""
                      src={chevrondown}
                    />
                  </div>
                  <img
                    className="archive-device-placeholder-icon"
                    alt=""
                    src={placeholder}
                  />
                </div>
              </div>
              <img className="archive-device-divider-icon2" alt="" src="/archive_page/divider2.svg" />

              <img className="archive-device-divider-icon3" alt="" src="/archive_page/divider2.svg" />

              <div className="archive-device-supporting-text1">
                You searched based on the following criteria.
              </div>

              <div className="archive-device-buttonsbutton7">
                <img className="archive-device-placeholder-icon" alt="" src={placeholder} />

                <a className="archive-device-text47">All time</a>
                <img className="archive-device-sliders-02-icon" alt="" src={xclose} />
              </div>
              <button className="archive-device-buttonsbutton8">
                <img className="archive-device-placeholder-icon" alt="" src={placeholder} />

                <div className="archive-device-text48">Clinical Applications</div>
                <img className="archive-device-sliders-02-icon" alt="" src={xclose} />
              </button>
              <div className="archive-device-buttonsbutton9">
                <img className="archive-device-placeholder-icon" alt="" src={placeholder} />

                <div className="archive-device-text49">US, AU, +4</div>
                <img className="archive-device-sliders-02-icon" alt="" src={xclose} />
              </div>
              <div className="archive-device-buttonsbutton10">
                <img className="archive-device-placeholder-icon" alt="" src={placeholder} />

                <div className="archive-device-text50">All/Employee</div>
                <img className="archive-device-sliders-02-icon" alt="" src={xclose} />
              </div>
              {/* <button className="archive-device-buttonsbutton11">
          <img className="archive-device-placeholder-icon" alt="" src={placeholder} />

          <div className="archive-device-text48">Clinical Applications</div>
          <img className="archive-device-sliders-02-icon" alt="" src={xclose} />
        </button> */}
              <div className="archive-device-buttonsbutton12">
                <img className="archive-device-placeholder-icon" alt="" src={placeholder} />

                <div className="archive-device-menu-label">Year of Manufacture</div>
                <img className="archive-device-sliders-02-icon" alt="" src={xclose} />
              </div>
              <button className="archive-device-buttonsbutton13">
                <img className="archive-device-placeholder-icon" alt="" src={placeholder} />

                <div className="archive-device-text53">Warrenty</div>
                <img className="archive-device-sliders-02-icon" alt="" src={xclose} />
              </button>
              <button className="archive-device-buttonsbutton14">
                <img className="archive-device-placeholder-icon" alt="" src={placeholder} />

                <div className="archive-device-text54">Manufacturer</div>
                <img className="archive-device-sliders-02-icon" alt="" src={xclose} />
              </button>
              <button className="archive-device-buttonsbutton15">
                <img className="archive-device-placeholder-icon" alt="" src={placeholder} />

                <div className="archive-device-text55">All Applications</div>
                <img className="archive-device-sliders-02-icon" alt="" src={xclose} />
              </button>
              <button className="archive-device-buttonsbutton16">
                <img className="archive-device-placeholder-icon" alt="" src={placeholder} />

                <div className="archive-device-text56">Rent/Lease</div>
                <img className="archive-device-sliders-02-icon" alt="" src={xclose} />
              </button>
              <button className="archive-device-buttonsbutton17">
                <img className="archive-device-sliders-02-icon" alt="" src={filterlines} />

                <div className="archive-device-text57">More filters</div>
                <img className="archive-device-placeholder-icon" alt="" src={placeholder} />
              </button>
              <button className="archive-device-buttonsbutton18">
                <img
                  className="archive-device-sliders-02-icon"
                  alt=""
                  src={filterfunnel01}
                />

                <div className="archive-device-text58">Clear Filter</div>
                <img className="archive-device-placeholder-icon" alt="" src={placeholder} />
              </button>
              <button className="archive-device-actions">
                <div className="archive-device-button">
                  <img
                    className="archive-device-placeholder-icon"
                    alt=""
                    src={placeholder1}
                  />

                  <div className="archive-device-text-padding1">
                    <div className="archive-device-text59">Tertiary</div>
                  </div>
                  <img
                    className="archive-device-placeholder-icon"
                    alt=""
                    src={placeholder1}
                  />
                </div>
                <div className="archive-device-button1">
                  <img
                    className="archive-device-placeholder-icon"
                    alt=""
                    src={placeholder2}
                  />

                  <div className="archive-device-text-padding1">
                    <div className="archive-device-text60">Secondary</div>
                  </div>
                  <img
                    className="archive-device-placeholder-icon"
                    alt=""
                    src={placeholder2}
                  />
                </div>
                <div className="archive-device-button2">
                  <img
                    className="archive-device-sliders-02-icon"
                    alt=""
                    src={filterlines}
                  />

                  <div className="archive-device-text-padding">
                    <div className="archive-device-filter-label">Save Filter</div>
                  </div>
                  <img
                    className="archive-device-placeholder-icon"
                    alt=""
                    src={placeholder3}
                  />
                </div>
              </button>
            </div>
            {/* <div className="archive-device-listing-container 
          archive-device-listing-container-custom"> */}
            <div className="dashboard-device-component-listing-container">
              {content}
            </div>
          </div>
        </div>
        <img className="archive-device-divider-icon6" alt="" src={divider4} />

        <img className="archive-device-divider-icon7 archive-device-divider-icon7-cstm" alt="" src={divider4} />





        <FooterAddForms />




      </div>
    </>


  )
}

