import React, { useEffect, useState } from 'react';
import "./DetailedWantedDevice.css"
import { Navbar1 } from '../../../components/Navbar1/Navbar1';
import FooterDetailPage from '../../../components/Footer/FooterDetailPage/FooterDetailPage';

import divider from '../../../icons/Gerator/detailed_listing/divider.svg';
import divider2 from '../../../icons/Gerator/detailed_listing/divider2.svg';

import chevronright from '../../../icons/Gerator/detailed_listing/chevronright.svg';



import placeholder1 from '../../../icons/Gerator/detailed_listing/placeholder1.svg';

import markerpin02 from '../../../icons/Gerator/detailed_listing/markerpin02.svg';

import calendar from '../../../icons/Gerator/detailed_listing/calendar.svg';

import facebook_1_1_2x from '../../../icons/Gerator/detailed_listing/facebook-1-1@2x.png';

import instagram_1_1_2x from '../../../icons/Gerator/detailed_listing/instagram-1-1@2x.png';
import whatsapp_1_2x from '../../../icons/Gerator/detailed_listing/whatsapp-1@2x.png';
import twitter_1_2x from '../../../icons/Gerator/detailed_listing/twitter-1@2x.png';
import linkedinlogo_1_2x from '../../../icons/Gerator/detailed_listing/linkedinlogo-1@2x.png';


import radar_1_1_2x from '../../../icons/Gerator/detailed_listing/radar-1-1@2x.png';
import deal_2_1_2x from '../../../icons/Gerator/detailed_listing/deal-2-1@2x.png';
import magnifier_1_2x from '../../../icons/Gerator/detailed_listing/magnifier-1@2x.png';
import share_1_2x from '../../../icons/Gerator/detailed_listing/share-1@2x.png';

import group_1010 from '../../../icons/Gerator/detailed_listing/group-1010.svg';
import group_781_2x from '../../../icons/Gerator/detailed_listing/group-781@2x.png';

import dotsvertical from '../../../icons/Gerator/detailed_listing/dotsvertical.svg';
import divider1 from '../../../icons/Gerator/detailed_listing/divider1.svg';
// import maximize01 from '../../../icons/Gerator/detailed_listing/maximize01.svg';
// import placeholder2 from '../../../icons/Gerator/detailed_listing/placeholder2.svg';
// import calendar1 from '../../../icons/Gerator/detailed_listing/calendar1.svg';
// import divider4 from '../../../icons/Gerator/detailed_listing/divider4.svg';
// import divider5 from '../../../icons/Gerator/detailed_listing/divider5.svg';
// import divider3 from '../../../icons/Gerator/detailed_listing/divider3.svg';
// import rectangle_9_2x from '../../../icons/Gerator/detailed_listing/rectangle-9@2x.png';
// import rectangle_5_2x from '../../../icons/Gerator/detailed_listing/rectangle-5@2x.png';
// import rectangle_43_2x from '../../../icons/Gerator/detailed_listing/rectangle-43@2x.png';
// import rectangle_95_2x from '../../../icons/Gerator/detailed_listing/rectangle-95@2x.png';
// import rectangle_431_2x from '../../../icons/Gerator/detailed_listing/rectangle-431@2x.png';
// import rectangle_196_2x from '../../../icons/Gerator/detailed_listing/rectangle-196@2x.png';

// import arrowleft from '../../../icons/Gerator/detailed_listing/arrowleft.svg';
// import arrowright from '../../../icons/Gerator/detailed_listing/arrowright.svg';

// import breadcrumb_controls_2x from '../../../icons/Gerator/detailed_listing/breadcrumb-controls@2x.png';
// import breadcrumb_controls1_2x from '../../../icons/Gerator/detailed_listing/breadcrumb-controls1@2x.png';
// import breadcrumb_controls2_2x from '../../../icons/Gerator/detailed_listing/breadcrumb-controls2@2x.png';

// import placeholder from '../../../icons/Gerator/detailed_listing/placeholder.svg';
// import group_58_2x from '../../../icons/Gerator/detailed_listing/group-58@2x.png';
// import group_78_2x from '../../../icons/Gerator/detailed_listing/group-78@2x.png';
// import group_351 from '../../../icons/Gerator/detailed_listing/group-351.svg';
// import page from '../../../icons/Gerator/detailed_listing/page.svg';
// import progress_fill from '../../../icons/Gerator/detailed_listing/progress-fill.svg';
// import searchlg from '../../../icons/Gerator/detailed_listing/searchlg.svg';
// import filterlines from '../../../icons/Gerator/detailed_listing/filterlines.svg';
// import star_background from '../../../icons/Gerator/detailed_listing/star-background.svg';
// import star_icon from '../../../icons/Gerator/detailed_listing/star-icon.svg';
// import f8595f4b711e503bc72fe396e5043e0c2_7_2x from '../../../icons/Gerator/detailed_listing/8595f4b711e503bc72fe396e5043e0c2-7@2x.png';
// import messagedotscircle from '../../../icons/Gerator/detailed_listing/messagedotscircle.svg';
// import markerpin021 from '../../../icons/Gerator/detailed_listing/markerpin021.svg';
// import icon1 from '../../../icons/Gerator/detailed_listing/icon1.svg';
// import login04 from '../../../icons/Gerator/detailed_listing/login04.svg';
import dot1 from '../../../icons/Gerator/detailed_listing/-dot1.svg';
import bookmarkcheck from '../../../icons/Gerator/detailed_listing/bookmarkcheck.svg';
import usercheck02 from '../../../icons/Gerator/detailed_listing/usercheck02.svg';
import messagechatcircle from '../../../icons/Gerator/detailed_listing/messagechatcircle.svg';



import { Loader } from "../../../components/Loader";
// import { useDispatch } from "react-redux";
import { useLazyFindGetWantedDeviceQuery, useLazyGetWantedDevicesQuery } from "../../../api/WantedDeviceAPI";
import { useNavigate, useParams } from "react-router-dom";
import { getCustomizedDate } from "../../../helper/DeviceHelper";
import { decryptErrorData } from '../../../helper/AESHelper';
import { useLazyGetUserQuery } from '../../../api/Login&RegisterAPI';
import { auth, onAuthStateChanged } from '../../Login/FirebaseClient';


export const DetailedWantedDevice = () => {

  const { id } = useParams();

  const [triggerGetUser, { data, isSuccess, error, isLoading, isError }] = useLazyGetUserQuery();
  const [triggerGetWantedDevice, { data: wantedDeviceData,
    isLoading: isWantedDeviceLoading,
    isSuccess: isWantedDeviceSuccess,
    isError: isWantedDeviceError,
    error: wantedDeviceError }] = useLazyFindGetWantedDeviceQuery(id);

  let content;
  const [currentUser, setCurrentUser] = useState("");
  // const [editWantedDeviceSavedData, setEditWantedDeviceSavedData] = useState(null);
  const navigate = useNavigate();

  // const [showSuccessMessage, setShowSuccessMessage] = useState({
  //   message: "",
  //   color: "",
  // });


  useEffect(() => {
    // const auth = getAuth();

    // Listen for authentication state change
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is authenticated
        setCurrentUser(user);
        triggerGetUser();
      } else {
        // User is not authenticated, redirect to login
        navigate('/sign-in', { state: { message: 'Please log in to continue.' } });
      }
    });

    return () => unsubscribe(); // Cleanup listener on component unmount
  }, [navigate, triggerGetUser]);

  // Trigger the get wanted device query only after user data is successfully fetched
  useEffect(() => {
    if (isSuccess) {
      // Once the user is authenticated and the getUser query is successful, trigger the getWantedDevice query
      triggerGetWantedDevice(id);
    }
  }, [isSuccess, triggerGetWantedDevice]);


  if (error) {
    console.log(error);

    if (isError) {
      const decryptedData = decryptErrorData(error.data);
      console.log(decryptedData);
      // navigate("/sign-in", { state: decryptedData.message });
      navigate("/sign-in", { state: { message: decryptedData.message } });
    }
    else {
      navigate("/sign-in", { state: { message: "Internal server error" } });
    }
    return; // Optionally handle error differently
  }

  if (isWantedDeviceSuccess) {
    return (
      <>
        <div className="detailed-wanted-device-listings-detailed-page-devic">
          <div className="detailed-wanted-device-rectangle-parent">
            <div className="detailed-wanted-device-frame-child"></div>
            <Navbar1 userData={currentUser} navigate={navigate} />
            <img className="detailed-wanted-device-divider-icon" alt="" src={divider} />

            <div className="detailed-wanted-device-breadcrumb-button-base">
              <div className="detailed-wanted-device-text6">...</div>
            </div>
            <img
              className="detailed-wanted-device-chevron-right-icon"
              alt=""
              src={chevronright}
            />

            <div className="detailed-wanted-device-breadcrumb-button-base1">
              <div className="detailed-wanted-device-text7">Another link</div>
            </div>
            <img
              className="detailed-wanted-device-chevron-right-icon1"
              alt=""
              src={chevronright}
            />

            <div className="detailed-wanted-device-frame-parent">
              <div className="detailed-wanted-device-breadcrumb-container-parent">
                <div className="detailed-wanted-device-breadcrumb">
                  <div className="detailed-wanted-device-breadcrumb-items">
                    <div className="detailed-wanted-device-breadcrumb-button-base2">
                      <div className="detailed-wanted-device-text9">Dashboard</div>
                    </div>
                    <div className="detailed-wanted-device-chevron-container">
                      <img
                        className="detailed-wanted-device-chevron-right-icon2"
                        alt=""
                        src={chevronright}
                      />
                    </div>
                    <div className="detailed-wanted-device-breadcrumb-button-base3">
                      <div className="detailed-wanted-device-text10">Wanted Device</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="detailed-wanted-device-listing-content-wrapper">
                <div className="detailed-wanted-device-listing-content">
                  <div className="detailed-wanted-device-description-block-parent">
                    <div className="detailed-wanted-device-description-block">
                      <div className="detailed-wanted-device-description-heading">
                        {/* <span className="detailed-wanted-device-pre-">Pre-</span>
                    <span className="detailed-wanted-device-o">o</span>
                    <span className="detailed-wanted-device-pre-">wned, </span>
                    <span className="detailed-wanted-device-o"
                    >USED, FULLY REFURBISHED 1984 gARAVENTA ACCESSIBILITY
                      WRISTVIEW STREPTOCOCCUS A HYALURONIDASE ANTIBODY
                      DETECTION, IDENTIFICATION REAGENTS FOR RENT / LEASE</span
                    > */}
                        {wantedDeviceData.data.title}
                      </div>
                      <div className="detailed-wanted-device-listing-details">
                        <div className="detailed-wanted-device-detail-container">
                          <div className="detailed-wanted-device-details-content">
                            <div className="detailed-wanted-device-details-row">
                              <div className="detailed-wanted-device-detail-items">
                                <img
                                  className="detailed-wanted-device-marker-pin-02-icon"
                                  alt=""
                                  src={markerpin02}
                                />

                                <div className="detailed-wanted-device-supporting-text">India, New Delhi</div>
                              </div>
                              <div className="detailed-wanted-device-detail-items">
                                <img
                                  className="detailed-wanted-device-marker-pin-02-icon"
                                  alt=""
                                  src={calendar}
                                />

                                <div className="detailed-wanted-device-supporting-text">{getCustomizedDate(wantedDeviceData.data.createdAt)}</div>
                              </div>
                            </div>
                            <div className="detailed-wanted-device-contact-details">
                              <div className="detailed-wanted-device-contact-name-wrapper">
                                <div className="detailed-wanted-device-contact-name">4.9/5</div>
                              </div>
                              <div className="detailed-wanted-device-contact-role">
                                <div className="detailed-wanted-device-supporting-text2">99,999 reviews</div>
                              </div>
                              <div className="detailed-wanted-device-social-links">
                                <div className="detailed-wanted-device-rectangle-container">
                                  <div className="detailed-wanted-device-frame-inner"></div>
                                  <div className="detailed-wanted-device-icon-backgrounds-parent">
                                    <div className="detailed-wanted-device-icon-backgrounds"></div>
                                    <img
                                      className="detailed-wanted-device-whatsapp-1-icon"
                                      alt=""
                                      src={facebook_1_1_2x}
                                    />
                                  </div>
                                  <div className="detailed-wanted-device-icon-backgrounds-parent">
                                    <div className="detailed-wanted-device-ellipse-div"></div>
                                    <img
                                      className="detailed-wanted-device-whatsapp-1-icon"
                                      alt=""
                                      src={instagram_1_1_2x}
                                    />
                                  </div>
                                  <div className="detailed-wanted-device-icon-backgrounds-parent">
                                    <div className="detailed-wanted-device-frame-child1"></div>
                                    <img
                                      className="detailed-wanted-device-whatsapp-1-icon"
                                      alt=""
                                      src={whatsapp_1_2x}
                                    />
                                  </div>
                                  <div className="detailed-wanted-device-icon-backgrounds-parent">
                                    <div className="detailed-wanted-device-icon-backgrounds"></div>
                                    <img
                                      className="detailed-wanted-device-whatsapp-1-icon"
                                      alt=""
                                      src={twitter_1_2x}
                                    />
                                  </div>
                                  <div className="detailed-wanted-device-icon-backgrounds-parent">
                                    <div className="detailed-wanted-device-ellipse-div"></div>
                                    <img
                                      className="detailed-wanted-device-whatsapp-1-icon"
                                      alt=""
                                      src={linkedinlogo_1_2x}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="detailed-wanted-device-button-row">
                            <div className="detailed-wanted-device-buttonsbutton1">
                              <img
                                className="detailed-wanted-device-chevron-down-icon"
                                alt=""
                                src={placeholder1}
                              />

                              <img
                                className="detailed-wanted-device-magnifier-1-icon"
                                alt=""
                                src={radar_1_1_2x}
                              />

                              <div className="detailed-wanted-device-text-padding">
                                <div className="detailed-wanted-device-text11">Add To Radar</div>
                              </div>
                              <img
                                className="detailed-wanted-device-chevron-down-icon"
                                alt=""
                                src={placeholder1}
                              />
                            </div>
                            <div className="detailed-wanted-device-buttonsbutton2">
                              <img
                                className="detailed-wanted-device-placeholder-icon4"
                                alt=""
                                src={placeholder1}
                              />

                              <img
                                className="detailed-wanted-device-magnifier-1-icon"
                                alt=""
                                src={deal_2_1_2x}
                              />

                              <img
                                className="detailed-wanted-device-placeholder-icon4"
                                alt=""
                                src={placeholder1}
                              />
                            </div>
                            <div className="detailed-wanted-device-buttonsbutton2">
                              <img
                                className="detailed-wanted-device-placeholder-icon4"
                                alt=""
                                src={placeholder1}
                              />

                              <img
                                className="detailed-wanted-device-magnifier-1-icon"
                                alt=""
                                src={magnifier_1_2x}
                              />

                              <img
                                className="detailed-wanted-device-placeholder-icon4"
                                alt=""
                                src={placeholder1}
                              />
                            </div>
                            <div className="detailed-wanted-device-buttonsbutton2">
                              <img
                                className="detailed-wanted-device-placeholder-icon4"
                                alt=""
                                src={placeholder1}
                              />

                              <img
                                className="detailed-wanted-device-magnifier-1-icon"
                                alt=""
                                src={share_1_2x}
                              />

                              <img
                                className="detailed-wanted-device-placeholder-icon4"
                                alt=""
                                src={placeholder1}
                              />
                            </div>
                            <img
                              className="detailed-wanted-device-button-row-child"
                              alt=""
                              src={group_1010}
                            />
                          </div>
                        </div>
                        <div className="detailed-wanted-device-dropdown-container">
                          <div className="detailed-wanted-device-dropdown">
                            <img
                              className="detailed-wanted-device-maximize-01-icon"
                              alt=""
                              src={dotsvertical}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <img className="detailed-wanted-device-divider-icon" alt="" src={divider1} />

                    <div className="detailed-wanted-device-heading-parent">
                      <div className="detailed-wanted-device-heading">Highlights</div>
                      <div className="detailed-wanted-device-description-content">
                        <div className="detailed-wanted-device-description-columns-first">
                          <div className="detailed-wanted-device-first-columns-paragraphs">
                            <div className="detailed-wanted-device-paragraph">
                              {wantedDeviceData.data.tax_device_category.name}
                            </div>
                            <div className="detailed-wanted-device-paragraph1">Device Category</div>
                          </div>
                          <div className="detailed-wanted-device-first-columns-paragraphs">
                            <div className="detailed-wanted-device-paragraph">{wantedDeviceData.data.tax_status_condition.name}</div>
                            <div className="detailed-wanted-device-paragraph1">Status</div>
                          </div>
                          <div className="detailed-wanted-device-first-columns-paragraphs">
                            <div className="detailed-wanted-device-paragraph">{wantedDeviceData.data.tax_clinical_application.name}</div>
                            <div className="detailed-wanted-device-paragraph1">Clinical Applications</div>
                          </div>
                        </div>
                        <div className="detailed-wanted-device-description-columns-second">
                          <div className="detailed-wanted-device-second-columns-paragraphs">
                            <div className="detailed-wanted-device-paragraph">
                              {wantedDeviceData.data.tax_oem.name}
                            </div>
                            <div className="detailed-wanted-device-paragraph1">OEM Brand</div>
                          </div>
                          <div className="detailed-wanted-device-second-columns-paragraphs">
                            <div className="detailed-wanted-device-paragraph">{wantedDeviceData.data.tax_age_of_device.name}</div>
                            <div className="detailed-wanted-device-paragraph1">Age of the Device</div>
                          </div>
                          <div className="detailed-wanted-device-second-columns-paragraphs">
                            <div className="detailed-wanted-device-paragraph">
                              {wantedDeviceData.data.tax_purpose_use.name}
                            </div>
                            <div className="detailed-wanted-device-paragraph1">Device Use</div>
                          </div>
                          <div className="detailed-wanted-device-second-columns-paragraphs">
                            <div className="detailed-wanted-device-paragraph">{wantedDeviceData.data.modelName}</div>
                            <div className="detailed-wanted-device-paragraph1">Model Name</div>
                          </div>
                          <div className="detailed-wanted-device-second-columns-paragraphs">
                            <div className="detailed-wanted-device-paragraph">
                              {wantedDeviceData.data.tax_availability.name}
                            </div>
                            <div className="detailed-wanted-device-paragraph1">Availability</div>
                          </div>
                          <div className="detailed-wanted-device-second-columns-paragraphs">
                            <div className="detailed-wanted-device-paragraph">{wantedDeviceData.data.tax_physical_location.name}</div>
                            <div className="detailed-wanted-device-paragraph1">Physical Location</div>
                          </div>

                        </div>
                      </div>
                    </div>




                  </div>
                  <div className="detailed-wanted-device-service-card">
                    <div className="detailed-wanted-device-card-content">
                      <div className="detailed-wanted-device-card-content-child"></div>
                      <div className="detailed-wanted-device-card-header">
                        <div className="detailed-wanted-device-price-tag">
                          <div className="detailed-wanted-device-badge-wrapper">
                            <div className="detailed-wanted-device-badge3">
                              <img
                                className="detailed-wanted-device-dot-icon3"
                                alt=""
                                src={dot1}
                              />

                              <div className="detailed-wanted-device-text29">Sale</div>
                            </div>
                          </div>
                          <div className="detailed-wanted-device-price-parent">
                            <div className="detailed-wanted-device-price">$9,999,999.99</div>
                            <div className="detailed-wanted-device-original-price">
                              <div className="detailed-wanted-device-discount">USD - Per Unit</div>
                            </div>
                          </div>
                          <div className="detailed-wanted-device-service-title-container">
                            <div className="detailed-wanted-device-service-title">Posted By Manufacturer</div>
                          </div>
                        </div>
                      </div>
                      <div className="detailed-wanted-device-card-footer">
                        <img
                          className="detailed-wanted-device-card-footer-child"
                          alt=""
                          src={group_781_2x}
                        />

                        <div className="detailed-wanted-device-footer-divider">
                          <img
                            className="detailed-wanted-device-divider-icon4"
                            alt=""
                            src={divider2}
                          />
                        </div>
                      </div>
                      <div className="detailed-wanted-device-professional-info">
                        <div className="detailed-wanted-device-professional-details">
                          <div className="detailed-wanted-device-professional-actions">
                            <div className="detailed-wanted-device-benefits-container">
                              <div className="detailed-wanted-device-amlie-laurent">Olivia Rhye</div>
                              <div className="detailed-wanted-device-role">Adani Health</div>
                            </div>
                            <div className="detailed-wanted-device-action-buttons">
                              <div className="detailed-wanted-device-contact-button">
                                <div className="detailed-wanted-device-message-button">
                                  <div className="detailed-wanted-device-navigation">
                                    <img
                                      className="detailed-wanted-device-bookmark-check-icon"
                                      alt=""
                                      src={bookmarkcheck}
                                    />
                                  </div>
                                  <div className="detailed-wanted-device-supporting-text10">
                                    Verified by Gerätor
                                  </div>
                                </div>
                              </div>
                              <div className="detailed-wanted-device-supporting-text11">
                                Adani Health conforms to Gerätor's requirements for
                                verification
                              </div>
                            </div>
                            <div className="detailed-wanted-device-favorite-button">
                              <div className="detailed-wanted-device-button-container2">
                                <div className="detailed-wanted-device-buttonsbutton5">
                                  <img
                                    className="detailed-wanted-device-filter-lines-icon"
                                    alt=""
                                    src={messagechatcircle}
                                  />

                                  <div className="detailed-wanted-device-text30">Private Message</div>
                                </div>
                                <div className="detailed-wanted-device-buttonsbutton6">
                                  <img
                                    className="detailed-wanted-device-search-lg-icon"
                                    alt=""
                                    src={usercheck02}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="detailed-wanted-device-dropdown-menu">
                            <div className="detailed-wanted-device-dropdown1">
                              <img
                                className="detailed-wanted-device-maximize-01-icon"
                                alt=""
                                src={dotsvertical}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>

            <FooterDetailPage />
          </div>
        </div></>
    )
  } else {
    return <Loader />;
  }




}

