import React from 'react';
import "./ArchiveSoftware.css"
import { Navbar1 } from '../../../components/Navbar1/Navbar1';
import { FooterAddForms } from '../../../components/Footer/FooterAddForms/FooterAddForms';
import React, { useEffect, useState } from "react";
import { useLazyGetSoftwaresQuery, useDeleteSoftwareMutation } from "../../../api/SoftwareAPI";

import { getCustomizedDate } from "../../../helper/ServiceHelper";
import { Loader } from "../../../components/Loader";
import { Link, useNavigate } from "react-router-dom";
import divider from '../../../icons/Gerator/archive_page/divider.svg';
import divider1 from '../../../icons/Gerator/archive_page/divider1.svg';

import divider2 from '../../../icons/Gerator/archive_page/divider2.svg';
import divider4 from '../../../icons/Gerator/archive_page/divider-4.svg';
import divider12 from '../../../icons/Gerator/archive_page/divider-12.svg';
import divider13 from '../../../icons/Gerator/archive_page/divider-13.svg';
import divider21 from '../../../icons/Gerator/archive_page/divider-21.svg';

import eye from '../../../icons/Gerator/archive_page/eye.svg';

import rectangle_41_2x from '../../../icons/Gerator/archive_page/rectangle-41@2x.png';

import dotsvertical from '../../../icons/Gerator/archive_page/dotsvertical.svg';
import dot1 from '../../../icons/Gerator/archive_page/-dot1.svg';
import dot from '../../../icons/Gerator/archive_page/-dot.svg';

import help_icon2 from '../../../icons/Gerator/archive_page/help-icon2.svg';
import help_icon from '../../../icons/Gerator/archive_page/help-icon.svg';


import featured_icon1 from '../../../icons/Gerator/archive_page/featured-icon1.svg';


import chevronright from '../../../icons/Gerator/archive_page/chevronright.svg';
import chevrondown from '../../../icons/Gerator/archive_page/chevrondown.svg';

import placeholder from '../../../icons/Gerator/archive_page/placeholder.svg';
import placeholder1 from '../../../icons/Gerator/archive_page/placeholder1.svg';
import placeholder2 from '../../../icons/Gerator/archive_page/placeholder2.svg';
import placeholder3 from '../../../icons/Gerator/archive_page/placeholder3.svg';

import homeline from '../../../icons/Gerator/archive_page/homeline.svg';
import searchlg from '../../../icons/Gerator/archive_page/searchlg.svg';

import calendar from '../../../icons/Gerator/archive_page/calendar.svg';

import target_04 from "../../../icons/Gerator/dashboard/target_04.png"

import radar_1_1_2x from '../../../icons/Gerator/archive_page/radar-1-1@2x.png';
import deal_2_1_2x from '../../../icons/Gerator/archive_page/deal-2-1@2x.png';
import magnifier_1_2x from '../../../icons/Gerator/archive_page/magnifier-1@2x.png';

import group_58_2x from '../../../icons/Gerator/archive_page/group-58@2x.png';

import dotsvertical from '../../../icons/Gerator/archive_page/dotsvertical.svg';
import filterfunnel01 from '../../../icons/Gerator/archive_page/filterfunnel01.svg';
import searchlg from '../../../icons/Gerator/archive_page/searchlg.svg';
import filterlines from '../../../icons/Gerator/archive_page/filterlines.svg';
import dot1 from '../../../icons/Gerator/archive_page/-dot1.svg';
import xclose from '../../../icons/Gerator/archive_page/xclose.svg';
import xclose10 from '../../../icons/Gerator/archive_page/xclose-10.svg';

import lock04 from '../../../icons/Gerator/archive_page/lock04.svg';
import lockunlocked04 from '../../../icons/Gerator/archive_page/lockunlocked04.svg';
import arrowsquareupright from '../../../icons/Gerator/archive_page/arrowsquareupright.svg';
import markerpin02 from '../../../icons/Gerator/archive_page/markerpin02.svg';
import icon1 from '../../../icons/Gerator/archive_page/icon-1.svg';
import sliders02 from '../../../icons/Gerator/archive_page/sliders02.svg';
import { useLazyGetUserQuery } from "../../../api/Login&RegisterAPI";
import { auth, onAuthStateChanged } from '../../Login/FirebaseClient';
import { decryptErrorData } from "../../../helper/AESHelper";
import { NoListingFound } from '../../../components/NoListing/NoListingFound';
import { useAddRadarSoftwareMutation, useDeleteRadarSoftwareMutation } from '../../../api/Radar/RadarSoftwareAPI';
import { Helmet } from 'react-helmet-async';


export const ArchiveSoftware = () => {

  let dataLength = 0;
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState("");
  const [showFilterLayout, setShowFilterLayout] = useState(false)


  const [triggerGetSoftware, { data: softwareData,
    isLoading,
    isSuccess,
    isError,
    error }] = useLazyGetSoftwaresQuery();

  const [addToRadar] = useAddRadarSoftwareMutation();

  const [deleteFromRadar] = useDeleteRadarSoftwareMutation();

  let content;
  // const [totalListings, setTotalListings] = useState("0");

  const handleRemoveRadar = async (softwareId) => {
    await deleteFromRadar(softwareId);
    window.location.reload();
  };

  const handleAddRadar = async (softwareId) => {
    await addToRadar(softwareId);
    window.location.reload();
  };


  useEffect(() => {
    // const auth = getAuth();
    // Listen for authentication state change
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is authenticated
        setCurrentUser(user);
        triggerGetSoftware();
      } else {
        // User is not authenticated, redirect to login
        navigate('/sign-in', { state: { message: 'Please log in to continue.' } });
      }
    });

    return () => unsubscribe(); // Cleanup listener on component unmount
  }, [navigate]);



  if (showFilterLayout === false) {
    if (isLoading) {
      content = <Loader />
    } else if (isSuccess) {

      if (softwareData.data.length > 0) {
        dataLength = softwareData.data.length;
        content = softwareData.data.map((software, index) =>
        (
          <div className="archive-software-rectangle-group" key={index}>
            <div className="archive-software-rectangle-container">
              <img
                className="archive-software-frame-inner"
                alt=""
                src={software.featureImage}
              />

              <div className="archive-software-dots-vertical-container">
                <img
                  className="archive-software-dots-vertical-icon1"
                  alt=""
                  src={dotsvertical}
                />

                <div className="archive-software-text-parent">
                  <Link to={`/software/${software.id}/${software.title}`} className="archive-software-text37 ">
                    {software.title}
                  </Link>
                  {/* <div className="archive-software-text37 ">
                    New / Surplus 2021 Philips Healthcare HD11 XE (P/N:23A10) for
                    abalatio...
                  </div> */}
                  <div className="archive-software-frame-group">
                    <div className="archive-software-frame-container">
                      <div className="archive-software-frame-div">
                        <div className="archive-software-frame-parent1">
                          <div className="archive-software-badge-parent">
                            <div className="archive-software-badge9">
                              <img
                                className="archive-software-dot-icon3"
                                alt=""
                                src={dot1}
                              />

                              <div className="archive-software-price-value">Pending</div>
                            </div>
                            <div className="archive-software-supporting-text4">ID# {software.id}</div>
                          </div>
                          <div className="archive-software-deal-type-parent">
                            <div className="archive-software-completed">
                              <div className="archive-software-second-value">4.9/5</div>
                            </div>
                            <div className="archive-software-supporting-text-wrapper">
                              <div className="archive-software-supporting-text3">99,999 reviews</div>
                            </div>
                          </div>
                          <div className="archive-software-badge10">
                            <div className="archive-software-third-badge-count archive-software-text-overflow">
                              {software.tax_software_category.name}
                            </div>
                          </div>
                        </div>
                        <div className="archive-software-deal-location-parent">
                          <div className="archive-software-dot-item-location-parent">
                            <div className="archive-software-navigation">
                              <img
                                className="archive-software-marker-pin-02-icon"
                                alt=""
                                src={markerpin02}
                              />
                            </div>
                            {software.software_address.tax_address_type
                              .slug === "address" ?
                              <div className="archive-software-location">{software.software_address.addressTownCity},  {software.software_address.tax_country.name}</div>
                              :
                              <div className="archive-software-location">{software.software_address.tax_address_type
                                .name}</div>
                            }
                            {/* <div className="archive-software-location">{software.location}</div> */}
                          </div>
                          <div className="archive-software-frame-wrapper1">
                            <div className="archive-software-badge-group">
                              <div className="archive-software-badge11">
                                <div className="archive-software-text33">11 Health</div>
                              </div>
                              <div className="archive-software-badge12">
                                <div className="archive-software-sub-badge archive-software-text-overflow">{software.tax_software_type.name}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="archive-software-card-work">
                          <div className="archive-software-inner-work">
                            <div className="archive-software-dot-item-type-parent">
                              <div className="archive-software-navigation">
                                <img
                                  className="archive-software-marker-pin-02-icon"
                                  alt=""
                                  src={calendar}
                                />
                              </div>
                              <div className="archive-software-type-of-work archive-software-text-overflow">{getCustomizedDate(software.createdAt)}</div>
                            </div>
                          </div>
                          <div className="archive-software-badge13">
                            <div className="archive-software-third-badge-count archive-software-text-overflow">
                              {software.tax_purpose_use.name}
                            </div>
                          </div>
                        </div>
                        <div className="archive-software-badge14">
                          <div className="archive-software-text36 archive-software-text-overflow archive-software-text-overflow">{software.tax_clinical_application.name}</div>
                        </div>
                      </div>
                    </div>
                    <div className="archive-software-second-price">
                      <div className="archive-software-price">${software.rangeMin} - {software.rangeMax}</div>
                      <div className="archive-software-price-value1">
                        <div className="archive-software-deal-title">{software.tax_unit_measure_software.name}</div>
                      </div>
                      <div className="archive-software-inner-icons-wrapper">
                        <div className="archive-software-inner-icons">
                          {/* <img
                            className="archive-software-magnifier-1-icon"
                            alt=""
                            src={radar_1_1_2x}
                          /> */}
                          {!software.isAuthor && (
                            software.addedToRadar ? (
                              <img
                                className="archive-software-magnifier-1-icon"
                                alt=""
                                onClick={() => handleRemoveRadar(software.id)}
                                src={target_04}
                                style={{ cursor: "pointer" }}
                              />
                            ) : (
                              <img
                                className="archive-software-magnifier-1-icon"
                                alt=""
                                onClick={() => handleAddRadar(software.id)}
                                src={radar_1_1_2x}
                                style={{ cursor: "pointer" }}
                              />
                            )
                          )}
                          <img
                            className="archive-software-magnifier-1-icon"
                            alt=""
                            src={deal_2_1_2x}
                          />

                          <img
                            className="archive-software-magnifier-1-icon"
                            alt=""
                            src={magnifier_1_2x}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="archive-software-divider-parent">
              <img className="archive-software-divider-icon4" alt="" src={divider2} />

              <div className="archive-software-user-listings">
                <div className="archive-software-frame-parent3">
                  <div className="archive-software-frame-parent4">
                    <img
                      className="archive-software-user-content-child"
                      alt=""
                      src={group_58_2x}
                    />

                    <div className="archive-software-inner-info-wrapper">
                      <div className="archive-software-inner-info">
                        <div className="archive-software-name">{software.software_business.business_user.displayName}</div>
                        {/* <div className="archive-software-role1">{software.software_business.businessName}</div> */}
                        <Link className="archive-software-role1" to={`/business/${software.software_business.id}/${software.software_business.businessName}`}>
                          {software.software_business.businessName}
                        </Link>
                      </div>
                    </div>
                  </div>
                  {/* <div className="archive-software-buttonsbutton-wrapper">
                    <Link to={"/edit-software/" + software.id}>
                      <button className="archive-software-buttonsbutton1">
                        <img className="archive-software-eye-icon" alt="" src={eye} />

                        <div className="archive-software-navigation">
                          <div className="archive-software-view-label">Edit</div>
                        </div>
                      </button>
                    </Link>

                  </div>
                  <div className="archive-software-logo-wrapper">
                    <div className="archive-software-buttonsbutton2" onClick={() => handleDelete(software.id)} style={{ cursor: 'pointer' }}>
                      <div className="archive-software-icon-wrapper">
                        <img className="archive-software-icon1" alt="" src={icon1} />
                      </div>
                      <div className="archive-software-navigation">
                        <div className="archive-software-lock-label">Delete</div>
                      </div>
                      <img className="archive-software-eye-icon" alt="" src={lock04} />
                    </div>
                  </div>
                  <div className="archive-software-logo-wrapper">
                    <div className="archive-software-buttonsbutton3">
                      <div className="archive-software-icon-wrapper">
                        <img className="archive-software-icon1" alt="" src={icon1} />
                      </div>
                      <div className="archive-software-navigation">
                        <div className="archive-software-lock-label">Sales IQ</div>
                      </div>
                      <img
                        className="archive-software-eye-icon"
                        alt=""
                        src={lockunlocked04}
                      />
                    </div>
                  </div> */}
                </div>
                <div className="archive-software-arrow-square-up-right-wrapper">
                  <img
                    className="archive-software-frame-child1"
                    alt=""
                    src={arrowsquareupright}
                  />
                </div>
              </div>
            </div>
          </div>
        ))
      }
      else {
        content = (

          <NoListingFound path="/add-software" pathName={"Software"} />

        )
      }

    } else if (isError) {
      content = <div style={{ color: "white" }}>{error.originalStatus + " " + error.data}</div>
    }
  }



  return (
    <>
      <Helmet>
        <title>Archive - Softwares</title>
      </Helmet>
      <div className="archive-software-listings-search-results-list">

        <Navbar1 userData={currentUser} navigate={navigate} />
        <div className='archive-software-mid-container'>
          <div className="archive-software-slide-out">
            <img className="archive-software-divider-icon14" alt="" src={divider12} />

            <div className="archive-software-rectangle-parent4">
              <div className="archive-software-frame-child8"></div>
              <div className="archive-software-frame-child9"></div>
              <img className="archive-software-dot-icon7" alt="" src={dot} />

              <img className="archive-software-dot-icon8" alt="" src={dot} />

              <img className="archive-software-dot-icon9" alt="" src={dot} />

              <img className="archive-software-dot-icon10" alt="" src={dot} />

              <img className="archive-software-dot-icon11" alt="" src={dot} />

              <img className="archive-software-dot-icon12" alt="" src={dot} />

              <img className="archive-software-dot-icon13" alt="" src={dot} />

              <div className="archive-software-frame-parent28">
                <div className="archive-software-slide-out-menu-header-parent">
                  <div className="archive-software-slide-out-menu-header">
                    <div className="archive-software-content24">
                      <img
                        className="archive-software-featured-icon1"
                        alt=""
                        src={featured_icon1}
                      />

                      <div className="archive-software-text-and-supporting-text6">
                        <a className="archive-software-text184">Filters</a>
                        <div className="archive-software-supporting-text57">
                          Apply filters to table data.
                        </div>
                      </div>
                    </div>
                    <div className="archive-software-button-close-x">
                      <img
                        className="archive-software-search-lg-icon"
                        alt=""
                        src={xclose10}
                      />
                    </div>
                  </div>
                  <div className="archive-software-frame-wrapper13">
                    <div className="archive-software-slide-out-title-parent">
                      <a className="archive-software-slide-out-title">Price</a>
                      <div className="archive-software-navigation">
                        <img
                          className="archive-software-marker-pin-02-icon2"
                          alt=""
                          src={chevrondown}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="archive-software-frame-parent29">
                  <div className="archive-software-frame-parent30">
                    <div className="archive-software-frame-wrapper14">
                      <div className="archive-software-frame-parent31">
                        <div className="archive-software-background-parent">
                          <div className="archive-software-background"></div>
                          <div className="archive-software-progress">
                            <div className="archive-software-progress-line"></div>
                            <div className="archive-software-control-handle">
                              <div className="archive-software-tooltip1">
                                <div className="archive-software-content25">
                                  <div className="archive-software-text185">0</div>
                                </div>
                              </div>
                              <div className="archive-software-handle"></div>
                            </div>
                            <div className="archive-software-control-handle">
                              <div className="archive-software-tooltip2">
                                <div className="archive-software-content26">
                                  <div className="archive-software-text186">9,999,999,99</div>
                                </div>
                              </div>
                              <div className="archive-software-handle"></div>
                            </div>
                          </div>
                        </div>
                        <div className="archive-software-input-fields-parent">
                          <div className="archive-software-input-fields">
                            <div className="archive-software-input-field">
                              <div className="archive-software-input-with-label4">
                                <div className="archive-software-label5">Email</div>
                                <div className="archive-software-input4">
                                  <div className="archive-software-content27">
                                    <div className="archive-software-text187">Min Value</div>
                                  </div>
                                  <img
                                    className="archive-software-help-icon1"
                                    alt=""
                                    src="/archive_page/help-icon1.svg"
                                  />
                                </div>
                              </div>
                              <div className="archive-software-hint-text">
                                This is a hint text to help user.
                              </div>
                            </div>
                            <div className="archive-software-text188">–</div>
                            <div className="archive-software-input-field">
                              <div className="archive-software-input-with-label4">
                                <div className="archive-software-label5">Email</div>
                                <div className="archive-software-input4">
                                  <div className="archive-software-content27">
                                    <div className="archive-software-text187">Max Value</div>
                                  </div>
                                  <img
                                    className="archive-software-help-icon1"
                                    alt=""
                                    src="/archive_page/help-icon1.svg"
                                  />
                                </div>
                              </div>
                              <div className="archive-software-hint-text">
                                This is a hint text to help user.
                              </div>
                            </div>
                          </div>
                          <div className="archive-software-checkboxes">
                            <div className="archive-software-collapsed-names">
                              <input className="archive-software-input6" type="checkbox" />

                              <div className="archive-software-price-parent">
                                <div className="archive-software-text8">US$ 0 — US$ 1,000</div>
                                <div className="archive-software-hint-text">
                                  Save my login details for next time.
                                </div>
                              </div>
                            </div>
                            <div className="archive-software-collapsed-names">
                              <input className="archive-software-input6" type="checkbox" />

                              <div className="archive-software-price-parent">
                                <div className="archive-software-text8">US$ 1,001 — US$ 5,000</div>
                                <div className="archive-software-hint-text">
                                  Save my login details for next time.
                                </div>
                              </div>
                            </div>
                            <div className="archive-software-collapsed-names">
                              <input className="archive-software-input6" type="checkbox" />

                              <div className="archive-software-price-parent">
                                <div className="archive-software-text8">US$ 5,001 — US$ 15,000</div>
                                <div className="archive-software-hint-text">
                                  Save my login details for next time.
                                </div>
                              </div>
                            </div>
                            <div className="archive-software-collapsed-names">
                              <input className="archive-software-input6" type="checkbox" />

                              <div className="archive-software-price-parent">
                                <div className="archive-software-text8">US$ 15,001 — US$ 9,999,999</div>
                                <div className="archive-software-hint-text">
                                  Save my login details for next time.
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="archive-software-frame-child10"></div>
                  </div>
                  <img
                    className="archive-software-divider-icon15"
                    alt=""
                    src={divider13}
                  />

                  <div className="archive-software-section-wrapper">
                    <div className="archive-software-section-parent">
                      <div className="archive-software-section">
                        <div className="archive-software-content-parent">
                          <div className="archive-software-content29">
                            <img className="archive-software-dot-icon" alt="" src={dot} />

                            <div className="archive-software-text194">Date Posted</div>
                          </div>
                          <img
                            className="archive-software-search-lg-icon"
                            alt=""
                            src={chevrondown}
                          />
                        </div>
                      </div>
                      <div className="archive-software-input-fields">
                        <div className="archive-software-input-field2">
                          <div className="archive-software-input-with-label4">
                            <div className="archive-software-label5">Email</div>
                            <div className="archive-software-input10">
                              <div className="archive-software-content27">
                                <div className="archive-software-text187">Jan 6, 2024</div>
                              </div>
                              <img
                                className="archive-software-help-icon1"
                                alt=""
                                src={help_icon2}
                              />
                            </div>
                          </div>
                          <div className="archive-software-hint-text">
                            This is a hint text to help user.
                          </div>
                        </div>
                        <div className="archive-software-text188">–</div>
                        <div className="archive-software-input-field2">
                          <div className="archive-software-input-with-label4">
                            <div className="archive-software-label5">Email</div>
                            <div className="archive-software-input10">
                              <div className="archive-software-content27">
                                <div className="archive-software-text187">Jan 12, 2024</div>
                              </div>
                              <img
                                className="archive-software-help-icon1"
                                alt=""
                                src={help_icon2}
                              />
                            </div>
                          </div>
                          <div className="archive-software-hint-text">
                            This is a hint text to help user.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <img
                    className="archive-software-divider-icon15"
                    alt=""
                    src={divider13}
                  />

                  <div className="archive-software-section-wrapper">
                    <div className="archive-software-section1">
                      <div className="archive-software-content-parent">
                        <div className="archive-software-content29">
                          <img className="archive-software-dot-icon" alt="" src={dot} />

                          <div className="archive-software-text198">Seller Role</div>
                        </div>
                        <img
                          className="archive-software-search-lg-icon"
                          alt=""
                          src={chevrondown}
                        />
                      </div>
                      <div className="archive-software-checkboxes1">
                        <div className="archive-software-collapsed-names">
                          <input className="archive-software-input6" type="checkbox" />

                          <div className="archive-software-price-parent">
                            <div className="archive-software-text8">Dealer/Distributor</div>
                            <div className="archive-software-hint-text">
                              Save my login details for next time.
                            </div>
                          </div>
                        </div>
                        <div className="archive-software-collapsed-names">
                          <input className="archive-software-input6" type="checkbox" />

                          <div className="archive-software-price-parent">
                            <div className="archive-software-text8">Manufacturer</div>
                            <div className="archive-software-hint-text">
                              Save my login details for next time.
                            </div>
                          </div>
                        </div>
                        <div className="archive-software-collapsed-names">
                          <input className="archive-software-input6" type="checkbox" />

                          <div className="archive-software-price-parent">
                            <div className="archive-software-text8">Owner/Employee</div>
                            <div className="archive-software-hint-text">
                              Save my login details for next time.
                            </div>
                          </div>
                        </div>
                        <div className="archive-software-collapsed-names">
                          <input className="archive-software-input6" type="checkbox" />

                          <div className="archive-software-price-parent">
                            <div className="archive-software-text8">Third Party/Agent</div>
                            <div className="archive-software-hint-text">
                              Save my login details for next time.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <img
                    className="archive-software-divider-icon15"
                    alt=""
                    src={divider13}
                  />

                  <div className="archive-software-section-wrapper">
                    <div className="archive-software-section1">
                      <div className="archive-software-content-parent">
                        <div className="archive-software-content29">
                          <img className="archive-software-dot-icon" alt="" src={dot} />

                          <div className="archive-software-text203">Transaction Type</div>
                        </div>
                        <img
                          className="archive-software-search-lg-icon"
                          alt=""
                          src={chevrondown}
                        />
                      </div>
                      <div className="archive-software-checkboxes1">
                        <div className="archive-software-collapsed-names">
                          <input className="archive-software-input6" type="checkbox" />

                          <div className="archive-software-price-parent">
                            <div className="archive-software-text8">Exchange</div>
                            <div className="archive-software-hint-text">
                              Save my login details for next time.
                            </div>
                          </div>
                        </div>
                        <div className="archive-software-collapsed-names">
                          <input className="archive-software-input6" type="checkbox" />

                          <div className="archive-software-price-parent">
                            <div className="archive-software-text8">Rent/Lease</div>
                            <div className="archive-software-hint-text">
                              Save my login details for next time.
                            </div>
                          </div>
                        </div>
                        <div className="archive-software-collapsed-names">
                          <input className="archive-software-input6" type="checkbox" />

                          <div className="archive-software-price-parent">
                            <div className="archive-software-text8">Sale</div>
                            <div className="archive-software-hint-text">
                              Save my login details for next time.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <img
                    className="archive-software-divider-icon15"
                    alt=""
                    src={divider13}
                  />

                  <div className="archive-software-section-wrapper">
                    <div className="archive-software-section3">
                      <div className="archive-software-content-parent">
                        <div className="archive-software-content29">
                          <img className="archive-software-dot-icon" alt="" src={dot} />

                          <div className="archive-software-text207">Status</div>
                        </div>
                        <img
                          className="archive-software-search-lg-icon"
                          alt=""
                          src={chevrondown}
                        />
                      </div>
                    </div>
                  </div>
                  <img
                    className="archive-software-divider-icon15"
                    alt=""
                    src={divider13}
                  />

                  <div className="archive-software-section-wrapper">
                    <div className="archive-software-section3">
                      <div className="archive-software-content-parent">
                        <div className="archive-software-content29">
                          <img className="archive-software-dot-icon" alt="" src={dot} />

                          <div className="archive-software-text208">Warranty</div>
                        </div>
                        <img
                          className="archive-software-search-lg-icon"
                          alt=""
                          src={chevrondown}
                        />
                      </div>
                    </div>
                  </div>
                  <img
                    className="archive-software-divider-icon15"
                    alt=""
                    src={divider13}
                  />

                  <div className="archive-software-section-wrapper">
                    <div className="archive-software-section3">
                      <div className="archive-software-content-parent">
                        <div className="archive-software-content29">
                          <img className="archive-software-dot-icon" alt="" src={dot} />

                          <div className="archive-software-text209">Shipping</div>
                        </div>
                        <img
                          className="archive-software-search-lg-icon"
                          alt=""
                          src={chevrondown}
                        />
                      </div>
                    </div>
                  </div>
                  <img
                    className="archive-software-divider-icon15"
                    alt=""
                    src={divider13}
                  />

                  <div className="archive-software-section-wrapper">
                    <div className="archive-software-section-parent">
                      <div className="archive-software-actions6">
                        <div className="archive-software-navigation">
                          <input className="archive-software-search-lg" type="radio" />
                        </div>
                        <div className="archive-software-text210">Country</div>
                      </div>
                      <div className="archive-software-input-fields2">
                        <div className="archive-software-input-field2">
                          <div className="archive-software-input-with-label4">
                            <div className="archive-software-label5">Email</div>
                            <div className="archive-software-input19">
                              <input
                                className="archive-software-content37"
                                placeholder="Search"
                                type="text"
                              />

                              <img
                                className="archive-software-help-icon1"
                                alt=""
                                src={help_icon}
                              />
                            </div>
                          </div>
                          <div className="archive-software-hint-text">
                            This is a hint text to help user.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="archive-software-second-badge-divider">
                    <img
                      className="archive-software-divider-icon22"
                      alt=""
                      src={divider13}
                    />

                    <img
                      className="archive-software-divider-icon23"
                      alt=""
                      src={divider21}
                    />
                  </div>
                  <div className="archive-software-frame-wrapper17">
                    <div className="archive-software-actions6">
                      <div className="archive-software-navigation">
                        <input className="archive-software-search-lg" type="radio" />
                      </div>
                      <div className="archive-software-text211">Device Category</div>
                    </div>
                  </div>
                  <div className="archive-software-second-badge-divider">
                    <img
                      className="archive-software-divider-icon22"
                      alt=""
                      src={divider13}
                    />

                    <img
                      className="archive-software-divider-icon23"
                      alt=""
                      src={divider21}
                    />
                  </div>
                  <div className="archive-software-frame-wrapper17">
                    <div className="archive-software-actions6">
                      <div className="archive-software-navigation">
                        <input className="archive-software-search-lg" type="radio" />
                      </div>
                      <div className="archive-software-text212">OEM Brand</div>
                    </div>
                  </div>
                  <div className="archive-software-frame-parent36">
                    <div className="archive-software-second-badge-divider">
                      <img
                        className="archive-software-divider-icon22"
                        alt=""
                        src={divider13}
                      />

                      <img
                        className="archive-software-divider-icon23"
                        alt=""
                        src={divider21}
                      />
                    </div>
                    <div className="archive-software-frame-wrapper19">
                      <div className="archive-software-frame-parent37">
                        <div className="archive-software-navigation">
                          <input className="archive-software-search-lg" type="radio" />
                        </div>
                        <div className="archive-software-text213">Year of Manufacture</div>
                      </div>
                    </div>
                    <div className="archive-software-second-badge-divider">
                      <img
                        className="archive-software-divider-icon22"
                        alt=""
                        src={divider13}
                      />

                      <img
                        className="archive-software-divider-icon23"
                        alt=""
                        src={divider21}
                      />
                    </div>
                    <div className="archive-software-frame-wrapper19">
                      <div className="archive-software-actions6">
                        <div className="archive-software-navigation">
                          <input className="archive-software-search-lg" type="radio" />
                        </div>
                        <div className="archive-software-text213">Clinical Applications</div>
                      </div>
                    </div>
                  </div>
                  <div className="archive-software-card-header-parent">
                    <img
                      className="archive-software-divider-icon15"
                      alt=""
                      src={divider13}
                    />

                    <img
                      className="archive-software-divider-icon31"
                      alt=""
                      src={divider21}
                    />
                  </div>
                  <div className="archive-software-frame-wrapper17">
                    <div className="archive-software-actions6">
                      <div className="archive-software-navigation">
                        <input className="archive-software-search-lg" type="radio" />
                      </div>
                      <div className="archive-software-text212">Device Use</div>
                    </div>
                  </div>
                  <div className="archive-software-second-badge-divider">
                    <img
                      className="archive-software-divider-icon22"
                      alt=""
                      src={divider13}
                    />

                    <img
                      className="archive-software-divider-icon23"
                      alt=""
                      src={divider21}
                    />
                  </div>
                  <div className="archive-software-frame-wrapper17">
                    <div className="archive-software-frame-parent37">
                      <div className="archive-software-navigation">
                        <input className="archive-software-search-lg" type="radio" />
                      </div>
                      <div className="archive-software-text216">Physical Location</div>
                    </div>
                  </div>
                  <img
                    className="archive-software-divider-icon15"
                    alt=""
                    src={divider13}
                  />
                </div>
              </div>
              <img className="archive-software-dot-icon20" alt="" src={dot} />

              <div className="archive-software-frame-parent41">
                <div className="archive-software-divider-parent6">
                  <img
                    className="archive-software-divider-icon35"
                    alt=""
                    src={divider13}
                  />

                  <div className="archive-software-footer1">
                    <div className="archive-software-content38">
                      <div className="archive-software-logo1">
                        <div className="archive-software-buttonsbutton37">
                          <img
                            className="archive-software-placeholder-icon"
                            alt=""
                            src={placeholder2}
                          />

                          <div className="archive-software-text217">Save filter</div>
                          <img
                            className="archive-software-placeholder-icon"
                            alt=""
                            src={placeholder2}
                          />
                        </div>
                      </div>
                      <div className="archive-software-actions9">
                        <button className="archive-software-buttonsbutton38">
                          <img
                            className="archive-software-placeholder-icon"
                            alt=""
                            src={placeholder}
                          />

                          <div className="archive-software-text-padding">
                            <div className="archive-software-text218">Cancel</div>
                          </div>
                          <img
                            className="archive-software-placeholder-icon"
                            alt=""
                            src={placeholder}
                          />
                        </button>
                        <button className="archive-software-buttonsbutton39">
                          <img
                            className="archive-software-placeholder-icon"
                            alt=""
                            src={placeholder3}
                          />

                          <div className="archive-software-text-padding">
                            <div className="archive-software-text219">Apply</div>
                          </div>
                          <img
                            className="archive-software-placeholder-icon"
                            alt=""
                            src={placeholder3}
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="archive-software-dot-item-innermost">
                  <div className="archive-software-collapsed-content">
                    <img
                      className="archive-software-divider-icon22"
                      alt=""
                      src={divider13}
                    />

                    <img
                      className="archive-software-divider-icon37"
                      alt=""
                      src={divider21}
                    />
                  </div>
                </div>
              </div>
            </div>

          </div>

          <div className="archive-software-filters">
            <div className='archive-software-filter-selected-div'>
              <div className="archive-software-main">
                <div className="archive-software-header-section">
                  <div className="archive-software-container">
                    <div className="archive-software-page-header">
                      <div className="archive-software-breadcrumbs">
                        <div className="archive-software-tabs">
                          <div className="archive-software-breadcrumb-button-base">
                            <img
                              className="archive-software-search-lg-icon"
                              alt=""
                              src={homeline}
                            />
                          </div>
                          <img
                            className="archive-software-chevron-right-icon"
                            alt=""
                            src={chevronright}
                          />

                          <div className="archive-software-breadcrumb-button-base1">
                            <a className="archive-software-text7">Dashboard</a>
                          </div>
                          <img
                            className="archive-software-chevron-right-icon"
                            alt=""
                            src={chevronright}
                          />

                          <div className="archive-software-breadcrumb-button-base2">
                            <div className="archive-software-text8">...</div>
                          </div>
                          <img
                            className="archive-software-chevron-right-icon2"
                            alt=""
                            src={chevronright}
                          />

                          <div className="archive-software-breadcrumb-button-base3">
                            <div className="archive-software-text9">Another link</div>
                          </div>
                          <img
                            className="archive-software-chevron-right-icon2"
                            alt=""
                            src={chevronright}
                          />

                          <div className="archive-software-breadcrumb-button-base4">
                            <a className="archive-software-text10">Category</a>
                          </div>
                        </div>
                      </div>
                      <img className="archive-software-divider-icon" alt="" src="/archive_page/divider.svg" />
                    </div>
                  </div>
                </div>
                <div className="archive-software-main-child"></div>
                <div className="archive-software-main-item"></div>
              </div>
              <div className="archive-software-secondary-content">
                <div className="archive-software-page-header1">
                  <div className="archive-software-breadcrumbs1">
                    <div className="archive-software-tabs1">
                      <div className="archive-software-breadcrumb-button-base">
                        <img
                          className="archive-software-search-lg-icon"
                          alt=""
                          src={homeline}
                        />
                      </div>
                      <img
                        className="archive-software-chevron-right-icon"
                        alt=""
                        src={chevronright}
                      />

                      <div className="archive-software-breadcrumb-button-base6">
                        <div className="archive-software-text11">Settings</div>
                      </div>
                      <img
                        className="archive-software-chevron-right-icon"
                        alt=""
                        src={chevronright}
                      />

                      <div className="archive-software-breadcrumb-button-base7">
                        <div className="archive-software-text8">...</div>
                      </div>
                      <img
                        className="archive-software-chevron-right-icon"
                        alt=""
                        src={chevronright}
                      />

                      <div className="archive-software-breadcrumb-button-base3">
                        <div className="archive-software-text9">Another link</div>
                      </div>
                      <img
                        className="archive-software-chevron-right-icon2"
                        alt=""
                        src={chevronright}
                      />

                      <div className="archive-software-breadcrumb-button-base4">
                        <div className="archive-software-menu-label">Team</div>
                      </div>
                    </div>
                  </div>
                  <div className="archive-software-content4">
                    <div className="archive-software-text-and-supporting-text">
                      <div className="archive-software-page-title">{dataLength} Listings Found</div>
                      <div className="archive-software-supporting-text">
                        Manage your team members and their account permissions here.
                      </div>
                    </div>
                    <div className="archive-software-input-dropdown">
                      <div className="archive-software-input-with-label1">
                        <div className="archive-software-label1">Search</div>
                        <div className="archive-software-input1">
                          <div className="archive-software-content5">
                            <img
                              className="archive-software-search-lg-icon"
                              alt=""
                              src={searchlg}
                            />

                            <div className="archive-software-text15">Search</div>
                          </div>
                        </div>
                      </div>
                      <div className="archive-software-hint-text">This is a hint text to help user.</div>
                    </div>
                  </div>
                  <img className="archive-software-divider-icon1" alt="" src={divider1} />
                </div>
              </div>
              <div className="archive-software-button-group">
                <button className="archive-software-button-group-base">
                  <a className="archive-software-text16">View all</a>
                </button>
                <div className="archive-software-button-group-base1">
                  <div className="archive-software-text17">Monitored</div>
                </div>
                <div className="archive-software-button-group-base2">
                  <div className="archive-software-menu-label">Text</div>
                </div>
                <div className="archive-software-button-group-base3">
                  <div className="archive-software-menu-label">Text</div>
                </div>
                <div className="archive-software-button-group-base4">
                  <div className="archive-software-menu-label">Text</div>
                </div>
                <div className="archive-software-button-group-base5">
                  <div className="archive-software-menu-label">Text</div>
                </div>
                <div className="archive-software-button-group-base6">
                  <div className="archive-software-menu-label">Text</div>
                </div>
                <div className="archive-software-button-group-base7">
                  <div className="archive-software-menu-label">Text</div>
                </div>
                <div className="archive-software-button-group-base1">
                  <div className="archive-software-text24">Unmonitored</div>
                </div>

              </div>
              <div className="archive-software-input-dropdown1">
                <div className="archive-software-hint-text1">This is a hint text to help user.</div>
                <div className="archive-software-buttonsbutton">
                  <img className="archive-software-sliders-02-icon" alt="" src={sliders02} />

                  <div className="archive-software-text-padding">
                    <div className="archive-software-menu-label">Sort By : Relevance</div>
                    <img
                      className="archive-software-sliders-02-icon"
                      alt=""
                      src={chevrondown}
                    />
                  </div>
                  <img
                    className="archive-software-placeholder-icon"
                    alt=""
                    src={placeholder}
                  />
                </div>
              </div>
              <img className="archive-software-divider-icon2" alt="" src="/archive_page/divider2.svg" />

              <img className="archive-software-divider-icon3" alt="" src="/archive_page/divider2.svg" />

              <div className="archive-software-supporting-text1">
                You searched based on the following criteria.
              </div>

              <div className="archive-software-buttonsbutton7">
                <img className="archive-software-placeholder-icon" alt="" src={placeholder} />

                <a className="archive-software-text47">All time</a>
                <img className="archive-software-sliders-02-icon" alt="" src={xclose} />
              </div>
              <button className="archive-software-buttonsbutton8">
                <img className="archive-software-placeholder-icon" alt="" src={placeholder} />

                <div className="archive-software-text48">Clinical Applications</div>
                <img className="archive-software-sliders-02-icon" alt="" src={xclose} />
              </button>
              <div className="archive-software-buttonsbutton9">
                <img className="archive-software-placeholder-icon" alt="" src={placeholder} />

                <div className="archive-software-text49">US, AU, +4</div>
                <img className="archive-software-sliders-02-icon" alt="" src={xclose} />
              </div>
              <div className="archive-software-buttonsbutton10">
                <img className="archive-software-placeholder-icon" alt="" src={placeholder} />

                <div className="archive-software-text50">All/Employee</div>
                <img className="archive-software-sliders-02-icon" alt="" src={xclose} />
              </div>
              {/* <button className="archive-software-buttonsbutton11">
          <img className="archive-software-placeholder-icon" alt="" src={placeholder} />

          <div className="archive-software-text48">Clinical Applications</div>
          <img className="archive-software-sliders-02-icon" alt="" src={xclose} />
        </button> */}
              <div className="archive-software-buttonsbutton12">
                <img className="archive-software-placeholder-icon" alt="" src={placeholder} />

                <div className="archive-software-menu-label">Year of Manufacture</div>
                <img className="archive-software-sliders-02-icon" alt="" src={xclose} />
              </div>
              <button className="archive-software-buttonsbutton13">
                <img className="archive-software-placeholder-icon" alt="" src={placeholder} />

                <div className="archive-software-text53">Warrenty</div>
                <img className="archive-software-sliders-02-icon" alt="" src={xclose} />
              </button>
              <button className="archive-software-buttonsbutton14">
                <img className="archive-software-placeholder-icon" alt="" src={placeholder} />

                <div className="archive-software-text54">Manufacturer</div>
                <img className="archive-software-sliders-02-icon" alt="" src={xclose} />
              </button>
              <button className="archive-software-buttonsbutton15">
                <img className="archive-software-placeholder-icon" alt="" src={placeholder} />

                <div className="archive-software-text55">All Applications</div>
                <img className="archive-software-sliders-02-icon" alt="" src={xclose} />
              </button>
              <button className="archive-software-buttonsbutton16">
                <img className="archive-software-placeholder-icon" alt="" src={placeholder} />

                <div className="archive-software-text56">Rent/Lease</div>
                <img className="archive-software-sliders-02-icon" alt="" src={xclose} />
              </button>
              <button className="archive-software-buttonsbutton17">
                <img className="archive-software-sliders-02-icon" alt="" src={filterlines} />

                <div className="archive-software-text57">More filters</div>
                <img className="archive-software-placeholder-icon" alt="" src={placeholder} />
              </button>
              <button className="archive-software-buttonsbutton18">
                <img
                  className="archive-software-sliders-02-icon"
                  alt=""
                  src={filterfunnel01}
                />

                <div className="archive-software-text58">Clear Filter</div>
                <img className="archive-software-placeholder-icon" alt="" src={placeholder} />
              </button>
              <button className="archive-software-actions">
                <div className="archive-software-button">
                  <img
                    className="archive-software-placeholder-icon"
                    alt=""
                    src={placeholder1}
                  />

                  <div className="archive-software-text-padding1">
                    <div className="archive-software-text59">Tertiary</div>
                  </div>
                  <img
                    className="archive-software-placeholder-icon"
                    alt=""
                    src={placeholder1}
                  />
                </div>
                <div className="archive-software-button1">
                  <img
                    className="archive-software-placeholder-icon"
                    alt=""
                    src={placeholder2}
                  />

                  <div className="archive-software-text-padding1">
                    <div className="archive-software-text60">Secondary</div>
                  </div>
                  <img
                    className="archive-software-placeholder-icon"
                    alt=""
                    src={placeholder2}
                  />
                </div>
                <div className="archive-software-button2">
                  <img
                    className="archive-software-sliders-02-icon"
                    alt=""
                    src={filterlines}
                  />

                  <div className="archive-software-text-padding">
                    <div className="archive-software-filter-label">Save Filter</div>
                  </div>
                  <img
                    className="archive-software-placeholder-icon"
                    alt=""
                    src={placeholder3}
                  />
                </div>
              </button>
            </div>
            {/* <div className="archive-software-listing-container archive-device-listing-container-custom"> */}
            <div className="dashboard-device-component-listing-container">

              {content}

            </div>
          </div>
        </div>
        <img className="archive-software-divider-icon6" alt="" src={divider4} />

        <img className="archive-software-divider-icon7 archive-software-divider-icon7-cstm" alt="" src={divider4} />





        <FooterAddForms />




      </div>
    </>


  )
}

