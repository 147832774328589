import { AddService } from "../screens/Service/AddService/AddService";
import { ArchiveService } from "../screens/Service/ArchiveService/ArchiveService";
import { DetailedService } from "../screens/Service/DetailedService/DetailedService";
import { EditService } from "../screens/Service/EditService/EditService";

export const serviceRoutes = [
    {
      path: "/add-service",
      element: <AddService />,
    },
    {
      path: "/edit-service/:id/*",
      element: <EditService />,
    },
    {
      path: "/services",
      element: <ArchiveService />,
    },
    {
      path: "/service/:id/*",
      element: <DetailedService />,
    }
  ];
  