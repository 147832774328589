import { AddBusiness } from "../screens/Business/AddBusiness/AddBusiness";
import { DetailedBusiness } from "../screens/Business/DetailedBusiness/DetailedBusiness";
import { EditBusiness } from "../screens/Business/EditBusiness/EditBusiness";
import { VerificationBusiness } from "../screens/Business/VerificationBusiness/VerificationBusiness";
import { ViewBusiness } from "../screens/Business/ViewBusiness/ViewBusiness";


export const businessRoutes = [
  {
    path: "/dashboard/settings/business/add-business",
    element: <AddBusiness />,
  },
  {
    path: "/edit-business",
    element: <EditBusiness />,
  },
  {
    path: "/my-business",
    element: <ViewBusiness />,
  },
  {
    path: "/verify-business/:id",
    element: <VerificationBusiness />,
  },
  {
    path: "/business/:id/*",
    element: <DetailedBusiness />,
  }

];
