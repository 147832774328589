import React, { useEffect, useState } from 'react';
import "./AccountSubscription.css";
import { Link, useNavigate } from 'react-router-dom';
import gerator_logo from '../../../icons/Gerator/gerator04-1@2x.png';
import feature_icon from '../../../icons/Gerator/account_setup/featured-icon.svg';
import feature_icon1 from '../../../icons/Gerator/account_setup/featured-icon-1.svg';
import feature_icon2 from '../../../icons/Gerator/account_setup/featured-icon-2.svg';
import check_icon from '../../../icons/Gerator/account_setup_profile_subscription/check-icon.svg';
import feature_icon4 from '../../../icons/Gerator/account_setup_profile_subscription/featured-icon-4.svg';
import feature_icon5 from '../../../icons/Gerator/account_setup_profile_subscription/featured-icon-5.svg';
import mail_icon from '../../../icons/Gerator/account_setup_profile_subscription/mail01-1.svg';
import { firestore, doc, getDoc, auth, onAuthStateChanged } from '../../Login/FirebaseClient';
import { useLazyGetUserCountryQuery, useUpdateWizardAndPlanMutation } from '../../../api/UsermetaAPI';
import { decryptData } from '../../../helper/AESHelper';
import { useLazyGetPlansMonthlyInrQuery, useLazyGetPlansMonthlyUsdQuery } from '../../../api/PlanAPI';
import { useAddFreePlanOrderMutation, useAddOrderMutation, useVerifyOrderMutation } from '../../../api/OrderAPI';
import SkeletonListingLoaderComponent from '../../../components/SkeletonListingLoaderComponent/SkeletonListingLoaderComponent';
import { NoListingFound } from '../../../components/NoListing/NoListingFound';
import { NothingFoundMessage } from '../../../components/NothingFoundMessage/NothingFoundMessage';
import { Helmet } from 'react-helmet-async';
import { useLazyGetUserQuery } from '../../../api/Login&RegisterAPI';
import { Loader } from '../../../components/Loader';


export const AccountSubscription = () => {
  const planDescriptions = {
    1: "Free forever. No Credit Card.",
    2: "For small businesses.",
    8: "For small businesses.",
    3: "For growing businesses.",
    9: "For growing businesses.",
    4: "For large businesses.",
    10: "For large businesses."
  };
  let content;
  // const [updateWizardAndPlan, { isLoading, isError, error }] = useUpdateWizardAndPlanMutation();
  const [triggerGetUserCountry, { data: countryData, isSuccess }] = useLazyGetUserCountryQuery();
  // const [triggerGetUser, { data, isLoading }] = useLazyGetUserQuery();

  const [triggerGetPlanMonthlyInr, { data: monthInrPlanData, isError: isMonthInrError, error: monthInrError, isLoading: isMonthInrLoading, isSuccess: isMonthInrSuccess }] = useLazyGetPlansMonthlyInrQuery();
  const [triggerGetPlanMonthlyUsd, { data: monthUsdPlanData, isError: isMonthUsdError, error: monthUsdError, isLoading: isMonthUsdLoading, isSuccess: isMonthUsdSuccess }] = useLazyGetPlansMonthlyUsdQuery();
  const [createOrder] = useAddOrderMutation();
  const [createFreePlanOrder] = useAddFreePlanOrderMutation();
  const [verifyPayment] = useVerifyOrderMutation();

  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState(null);
  const [currentUsermeta, setCurrentUsermeta] = useState(null);
  const [loadingComplete, setLoadingComplete] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState({
    message: "",
    color: "",
  });


  useEffect(() => {
    // const auth = getAuth();

    // Listen for authentication state change
    const unsubscribe = onAuthStateChanged(auth, async (user) => {

      if (user) {

        const userDocRef = doc(firestore, "users", user.uid);
        const userDocSnapshot = await getDoc(userDocRef);

        if (userDocSnapshot.exists()) {
          const usermeta = userDocSnapshot.data(); // Default to 1 if not set
          setCurrentUsermeta(usermeta);
          setCurrentUser(user);
          console.log("Wizard Step:", usermeta.wizardStep);
        } else {
          console.log("User document does not exist in Firestore");
          navigate("/sign-in", { state: { message: "Internal server error" } });
        }
        // Get user to check wizardStep

      }
      else {
        navigate("/sign-in", { state: { message: "User not authorized" } });
      }
    });

    return () => unsubscribe(); // Cleanup listener on component unmount
  }, [navigate]);

  useEffect(() => {
    if (currentUsermeta) {
      switch (currentUsermeta.wizardStep) {
        case 0:
          navigate('/dashboard');
          break;
        case 1:
          navigate('/account-setup-check-email');
          break;
        case 2:
          navigate('/account-setup-update-profile');
          // case 3:
          //   navigate('/account-setup-subscription-plan');
          break;
      }
      triggerGetUserCountry();
    }
  }, [currentUsermeta])


  // const selectPlan = async (planId) => {
  //   try {
  //     const response = await updateWizardAndPlan({ planId }).unwrap();
  //     if (response.status) {
  //       navigate('/dashboard');
  //     }
  //   } catch (error) {
  //     if (error.status) {
  //       const decryptedData = decryptData(error.data);
  //       console.log(decryptedData);
  //       setShowSuccessMessage({ message: decryptedData.message, color: "red" });
  //     }
  //     else {
  //       setShowSuccessMessage({ message: error.message, color: "red" });
  //     }
  //   }
  //   console.log(planId);
  // }

  useEffect(() => {
    if (countryData?.usermeta?.tax_country.id === 98) {
      triggerGetPlanMonthlyInr();
      // triggerGetPlanYearlyInr();
    } else {
      triggerGetPlanMonthlyUsd();
      // triggerGetPlanYearlyUsd();
    }
  }, [countryData]);

  useEffect(() => {
    // Dynamically load the Razorpay checkout script when the component mounts
    const script = document.createElement('script');
    script.src = 'https://checkout.razorpay.com/v1/checkout.js';
    script.async = true;
    document.body.appendChild(script);

    // Cleanup script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handlePayment = async (planId) => {


    try {


      if (planId === 1) {
        // Free plan flow
        try {
          const orderData = await createFreePlanOrder(planId).unwrap();

          if (orderData.status) {
            setShowSuccessMessage({ message: "Plan selected successfully", color: "green" });
            await new Promise(resolve => setTimeout(resolve, 1500));
            navigate('/dashboard');
          } else {
            throw new Error('Invalid response from free plan order creation');
          }
        } catch (error) {
          console.error('Free plan activation failed:', error);
          setShowSuccessMessage({ message: error.message, color: "red" })
        }
        return;
      }

      const orderData = await createOrder(planId).unwrap();
      const isIndia = countryData?.usermeta?.tax_country.id === 98;

      const options = {
        key: orderData.keyId,
        amount: orderData.amount,
        currency: isIndia ? 'INR' : 'USD',
        order_id: orderData.orderId,
        name: 'Gerator',
        description: 'Plan Purchase',
        handler: async function (response) {
          try {
            const verificationData = {
              orderId: response.razorpay_order_id,
              paymentId: response.razorpay_payment_id,
              signature: response.razorpay_signature,
            };

            const result = await verifyPayment(verificationData).unwrap();
            if (result.status) {
              setShowSuccessMessage({ message: "Plan selected successfully", color: "green" });
              await new Promise(resolve => setTimeout(resolve, 1500));
              navigate('/dashboard');
            }
          } catch (error) {
            console.error('Payment verification failed:', error);
            setShowSuccessMessage({ message: error.message, color: "red" })
          }
        },
        prefill: {
          name: currentUser.displayName,
          email: currentUser.email,
          contact: orderData.contact,
        },
        theme: {
          color: '#3399cc',
        },
        modal: {
          confirm_close: true,
          escape: false
        },
        notes: {
          currency: isIndia ? 'INR' : 'USD'
        }
      };

      const razorpayInstance = new window.Razorpay(options);
      razorpayInstance.open();
    } catch (error) {
      console.error('Payment initiation failed:', error);
    }



  };

  //Success true then pause to show sekelton layout for plans
  useEffect(() => {
    const isIndia = countryData?.usermeta?.tax_country.id === 98;
    const successCondition = isIndia ?
      isMonthInrSuccess :
      isMonthUsdSuccess;

    if (successCondition) {
      const timer = setTimeout(() => {
        setLoadingComplete(true);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [isMonthInrSuccess, isMonthUsdSuccess, countryData]);

  // Plan dynamic condition with INR support
  if (isMonthInrError || isMonthUsdError) {
    content = <NothingFoundMessage text="No plans available" text1="Please contact support" text2="No subscription plans are currently available." />;
  }



  if (isMonthInrLoading || isMonthUsdLoading) {
    content = <SkeletonListingLoaderComponent />;
  } else if (loadingComplete) {
    const isIndia = countryData?.usermeta?.tax_country.id === 98;

    const planData = isIndia ? monthInrPlanData : monthUsdPlanData;
    const currencySymbol = isIndia ? '₹' : '$';
    if (planData?.data?.length > 0) {
      content = planData.data.map((plan, index) => (

        <div className="account-subscription-pricing-tier-card" key={index}>
          <div className="account-subscription-header">
            <div className="account-subscription-heading-and-price">
              <div className="account-subscription-heading-and-icon">
                <img
                  className="account-subscription-featured-icon4"
                  loading="lazy"
                  alt=""
                  src={feature_icon4}
                />

                <div className="account-subscription-heading1">{plan.planName}</div>
              </div>
              <h1 className="account-subscription-price">{currencySymbol}{plan.planAmount}/mth</h1>
              <div className="account-subscription-supporting-text5">
                {planDescriptions[plan.id]}
                {/* {plan.id === 1 && "Free forever. No Credit Card."}
                {(plan.id === 2 || plan.id === 8) && "For small businesses."}
                {(plan.id === 3 || plan.id === 9) && "For growing businesses."}
                {(plan.id === 4 || plan.id === 10) && "For large businesses."} */}
              </div>
            </div>
          </div>
          <div className="account-subscription-content2">
            <div className="account-subscription-check-items">
              <div className="account-subscription-check-item-text">
                <img
                  className="account-subscription-check-icon"
                  loading="lazy"
                  alt=""
                  src={check_icon}
                />

                <div className="account-subscription-text-wrap">
                  <div className="account-subscription-item-description">
                    <span className="account-subscription-span">{plan.planAllowedListing}</span>
                    <span> concurrent listing slots</span>
                  </div>
                </div>
              </div>
              <div className="account-subscription-check-item-text">
                <img
                  className="account-subscription-check-icon"
                  loading="lazy"
                  alt=""
                  src={check_icon}
                />

                <div className="account-subscription-text-wrap">
                  <div className="account-subscription-item-description">
                    <span>Listing validity of</span>
                    <span className="account-subscription-span"> {plan.planListingValidity} days</span>
                  </div>
                </div>
              </div>
              <div className="account-subscription-check-item-text">
                <img
                  className="account-subscription-check-icon"
                  loading="lazy"
                  alt=""
                  src={check_icon}
                />

                <div className="account-subscription-text-wrap">
                  <div className="account-subscription-item-description">
                    <span className="account-subscription-span">{plan.planAllowedAddress}</span>
                    <span> business addresses</span>
                  </div>
                </div>
              </div>
              <div className="account-subscription-check-item-text3">
                <img
                  className="account-subscription-check-icon"
                  loading="lazy"
                  alt=""
                  src={check_icon}
                />

                <div className="account-subscription-text-wrap">
                  <div className="account-subscription-item-description">
                    <span className="account-subscription-business-page">Business page</span>

                    <sup>
                      <span>1</span>
                    </sup>
                  </div>
                </div>
              </div>
              <div className="account-subscription-check-item-text">
                <img
                  className="account-subscription-check-icon"
                  loading="lazy"
                  alt=""
                  src={check_icon}
                />
                <div className="account-subscription-text-wrap">
                  <div className="account-subscription-item-description">Upto {plan.planAllowTeamMember} Team Members</div>
                </div>
                {/* {plan.id > 1 ?
                  <div className="account-subscription-text-wrap">
                    <div className="account-subscription-item-description">Upto {plan.planAllowTeamMember} Team Members</div>
                  </div>
                  :
                  <div className="account-subscription-text-wrap">
                    <div className="account-subscription-item-description">Team Members</div>
                  </div>
                } */}
              </div>

              <div className="account-subscription-check-item-text5">
                <img
                  className="account-subscription-check-icon5"
                  alt=""
                  src={check_icon}
                />

                <div className="account-subscription-text-wrap">
                  <div className="account-subscription-item-description">
                    Another feature
                  </div>
                </div>
              </div>
              <div className="account-subscription-check-item-text5">
                <img
                  className="account-subscription-check-icon5"
                  alt=""
                  src={check_icon}
                />

                <div className="account-subscription-text-wrap">
                  <div className="account-subscription-item-description">
                    Another feature
                  </div>
                </div>
              </div>
              <div className="account-subscription-check-item-text5">
                <img
                  className="account-subscription-check-icon5"
                  alt=""
                  src={check_icon}
                />

                <div className="account-subscription-text-wrap">
                  <div className="account-subscription-item-description">
                    Another feature
                  </div>
                </div>
              </div>
              <div className="account-subscription-check-item-text5">
                <img
                  className="account-subscription-check-icon5"
                  alt=""
                  src={check_icon}
                />

                <div className="account-subscription-text-wrap">
                  <div className="account-subscription-item-description">
                    Another feature
                  </div>
                </div>
              </div>
              <div className="account-subscription-check-item-text5">
                <img
                  className="account-subscription-check-icon5"
                  alt=""
                  src={check_icon}
                />

                <div className="account-subscription-text-wrap">
                  <div className="account-subscription-item-description">
                    Another feature
                  </div>
                </div>
              </div>
            </div>
          </div>

          <button className="account-subscription-footer">
            <div className="account-subscription-heading-and-subheading">
              <div className="account-subscription-buttonsbutton">
                <img
                  className="account-subscription-placeholder-icon"
                  alt=""
                  src="/account_setup_profile_subscription/placeholder.svg"
                />
                {plan.id === 1 ?
                  <div className="account-subscription-text-padding" onClick={() => handlePayment(plan.id)}>
                    <div className="account-subscription-button-label">Try for free</div>
                  </div>
                  :
                  <div className="account-subscription-text-padding" onClick={() => handlePayment(plan.id)}>
                    <div className="account-subscription-button-label">Select Plan</div>
                  </div>
                }



                <img
                  className="account-subscription-placeholder-icon"
                  alt=""
                  src="/account_setup_profile_subscription/placeholder.svg"
                />
              </div>

            </div>
          </button>

        </div>
      ));
    } else {
      content = <NothingFoundMessage text="No plans available" text1="Please contact support" text2="No subscription plans are currently available." />;
    }


  }



  if (currentUsermeta && isSuccess) {
    return (
      <>
        <Helmet>
          <title>Step 3 - Choose Plan</title>
        </Helmet>
        <div className="account-subscription-account-subscription">
          <div className="account-subscription-section">
            <div className="account-subscription-progress-content-wrapper">
              <div className="account-subscription-progress-content">
                <div className="account-subscription-logo">
                  <img
                    className="account-subscription-gerator-04-1-icon"
                    alt=""
                    src={gerator_logo}
                  />
                </div>
                <div className="account-subscription-progress-steps-progress-icon">
                  <div className="account-subscription-step-base">
                    <div className="account-subscription-step-wrappers">
                      <img
                        className="account-subscription-featured-icon"
                        loading="lazy"
                        alt=""
                        src={feature_icon}
                      />

                      <div className="account-subscription-step-titles-parent">
                        <div className="account-subscription-step-titles">Quick tap to confirm you're you!</div>
                        <div className="account-subscription-supporting-text">Verify your email address</div>
                      </div>
                    </div>
                    <div className="account-subscription-connector-wrappers">
                      <div className="account-subscription-connector"></div>
                    </div>
                  </div>
                  <div className="account-subscription-step-base1">
                    <div className="account-subscription-step-wrappers">
                      <img
                        className="account-subscription-featured-icon"
                        loading="lazy"
                        alt=""
                        src={feature_icon1}
                      />

                      <div className="account-subscription-step-titles-parent">
                        <div className="account-subscription-step-titles">Update Profile</div>
                        <div className="account-subscription-supporting-text">
                          Tell us a bit about yourself
                        </div>
                      </div>
                    </div>
                    <div className="account-subscription-connector-wrappers">
                      <div className="account-subscription-connector"></div>
                    </div>
                  </div>
                  <div className="account-subscription-step-base2">
                    <div className="account-subscription-connector-wrap">
                      <img
                        className="account-subscription-featured-icon2"
                        loading="lazy"
                        alt=""
                        src={feature_icon2}
                      />


                    </div>
                    <div className="account-subscription-text-and-supporting-text">
                      <div className="account-subscription-step-titles">Choose a Subscription plan</div>
                      <div className="account-subscription-supporting-text2">
                        Choose how you want to roll
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <div className="account-subscription-contact-title-parent">
              {/* <div className="account-subscription-contact-title">© Untitled UI 2077</div>
          <div className="account-subscription-contact-info">
            <div className="account-subscription-mail-01-wrapper">
              <img className="account-subscription-mail-01-icon" loading="lazy" alt="" />
            </div>
            <div className="account-subscription-helpuntitleduicom">helpcenter@gerator.com</div>
          </div> */}
            </div>
          </div>
          <section className="account-subscription-section1">
            <div className="account-subscription-frame-parent">
              <div className="account-subscription-frame-wrapper">
                <div className="account-subscription-container-wrapper">
                  <div className="account-subscription-container">
                    <div className="account-subscription-pricing-section">
                      <div className="account-subscription-container1">
                        <div className="account-subscription-content">
                          <div className="account-subscription-heading-and-supporting-text">
                            <div className="account-subscription-heading-and-subheading">
                              <div className="account-subscription-step-titles">Pricing</div>
                              <h1 className="account-subscription-heading">
                                Choose your subscription plan
                              </h1>
                            </div>
                            <div className="account-subscription-supporting-text4">
                              Goodbye equipment spreadsheet chaos, hello weekend plans! Ready to level up? ⚡
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="account-subscription-container2">
                        <div className="account-subscription-content1">
                          <div className="account-subscription-pricing-tier-card">
                            <div className="account-subscription-header">
                              <div className="account-subscription-heading-and-price">
                                <div className="account-subscription-heading-and-icon">
                                  <img
                                    className="account-subscription-featured-icon4"
                                    loading="lazy"
                                    alt=""
                                    src={feature_icon4}
                                  />

                                  <div className="account-subscription-heading1">Oxygen</div>
                                </div>
                                <h1 className="account-subscription-price">$0/mth</h1>
                                <div className="account-subscription-supporting-text5">
                                  Free forever. No Credit Card.
                                </div>
                              </div>
                            </div>
                            <div className="account-subscription-content2">
                              <div className="account-subscription-check-items">
                                <div className="account-subscription-check-item-text">
                                  <img
                                    className="account-subscription-check-icon"
                                    loading="lazy"
                                    alt=""
                                    src={check_icon}
                                  />

                                  <div className="account-subscription-text-wrap">
                                    <div className="account-subscription-item-description">
                                      <span className="account-subscription-span">10</span>
                                      <span> concurrent listing slots</span>
                                    </div>
                                  </div>
                                </div>
                                <div className="account-subscription-check-item-text">
                                  <img
                                    className="account-subscription-check-icon"
                                    loading="lazy"
                                    alt=""
                                    src={check_icon}
                                  />

                                  <div className="account-subscription-text-wrap">
                                    <div className="account-subscription-item-description">
                                      <span>Listing validity of</span>
                                      <span className="account-subscription-span"> 30 days</span>
                                    </div>
                                  </div>
                                </div>
                                <div className="account-subscription-check-item-text">
                                  <img
                                    className="account-subscription-check-icon"
                                    loading="lazy"
                                    alt=""
                                    src={check_icon}
                                  />

                                  <div className="account-subscription-text-wrap">
                                    <div className="account-subscription-item-description">
                                      <span className="account-subscription-span">5</span>
                                      <span> business addresses</span>
                                    </div>
                                  </div>
                                </div>
                                <div className="account-subscription-check-item-text3">
                                  <img
                                    className="account-subscription-check-icon"
                                    loading="lazy"
                                    alt=""
                                    src={check_icon}
                                  />

                                  <div className="account-subscription-text-wrap">
                                    <div className="account-subscription-item-description">
                                      <span className="account-subscription-business-page"
                                      >Business page </span
                                      >
                                      <sup>
                                        <span>1</span>
                                      </sup>
                                    </div>
                                  </div>
                                </div>
                                <div className="account-subscription-check-item-text">
                                  <img
                                    className="account-subscription-check-icon"
                                    loading="lazy"
                                    alt=""
                                    src={check_icon}
                                  />

                                  <div className="account-subscription-text-wrap">
                                    <div className="account-subscription-item-description">Team Members</div>
                                  </div>
                                </div>
                                <div className="account-subscription-check-item-text5">
                                  <img
                                    className="account-subscription-check-icon5"
                                    alt=""
                                    src={check_icon}
                                  />

                                  <div className="account-subscription-text-wrap">
                                    <div className="account-subscription-item-description">
                                      Another feature
                                    </div>
                                  </div>
                                </div>
                                <div className="account-subscription-check-item-text5">
                                  <img
                                    className="account-subscription-check-icon5"
                                    alt=""
                                    src={check_icon}
                                  />

                                  <div className="account-subscription-text-wrap">
                                    <div className="account-subscription-item-description">
                                      Another feature
                                    </div>
                                  </div>
                                </div>
                                <div className="account-subscription-check-item-text5">
                                  <img
                                    className="account-subscription-check-icon5"
                                    alt=""
                                    src={check_icon}
                                  />

                                  <div className="account-subscription-text-wrap">
                                    <div className="account-subscription-item-description">
                                      Another feature
                                    </div>
                                  </div>
                                </div>
                                <div className="account-subscription-check-item-text5">
                                  <img
                                    className="account-subscription-check-icon5"
                                    alt=""
                                    src={check_icon}
                                  />

                                  <div className="account-subscription-text-wrap">
                                    <div className="account-subscription-item-description">
                                      Another feature
                                    </div>
                                  </div>
                                </div>
                                <div className="account-subscription-check-item-text5">
                                  <img
                                    className="account-subscription-check-icon5"
                                    alt=""
                                    src={check_icon}
                                  />

                                  <div className="account-subscription-text-wrap">
                                    <div className="account-subscription-item-description">
                                      Another feature
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <button className="account-subscription-footer">
                              <div className="account-subscription-heading-and-subheading">
                                <div className="account-subscription-buttonsbutton">
                                  <img
                                    className="account-subscription-placeholder-icon"
                                    alt=""
                                    src="/account_setup_profile_subscription/placeholder.svg"
                                  />

                                  <div className="account-subscription-text-padding" onClick={() => handlePayment(1)}>
                                    {/* <div className="account-subscription-text-padding" onClick={() => selectPlan(1)}> */}

                                    <div className="account-subscription-button-label">Try for free</div>
                                  </div>


                                  <img
                                    className="account-subscription-placeholder-icon"
                                    alt=""
                                    src="/account_setup_profile_subscription/placeholder.svg"
                                  />
                                </div>
                              </div>
                            </button>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    )
  }
  else {
    return <Loader />
  }
}