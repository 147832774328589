import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  deviceCategory:'',
  oem:'',
  partName:'',
  ageOfDevice:'',
  availability:'',
  partNumber:'',
  clinicalApplications:'',
  purposeUse:'',
  statusCondition:'',
  physicalLocation:'',
}

const AddWantedSpareSlice = createSlice({
  name: "addWantedSpare",
  initialState,
  reducers: {
    updateWantedSpareField(state, action) {
      const {field, value} = action.payload
      if (value !== undefined) { 
        state[field] = value; 
      }
    },
    clearWantedSpareForm() {
      return initialState;
    }
  },
});

export const { updateWantedSpareField,clearWantedSpareForm } = AddWantedSpareSlice.actions

export default AddWantedSpareSlice.reducer

