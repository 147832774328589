import React from 'react';
import "./NoBusiness.css";
import bifocallens from '../../icons/Gerator/no_listing/bifocallens.png';
import placeholder from '../../icons/Gerator/no_listing/placeholder.svg';
import placeholder1 from '../../icons/Gerator/no_listing/placeholder1.svg';
import plus from '../../icons/Gerator/no_listing/plus.svg';
import { Link } from "react-router-dom";
import NoBusiness from "../../icons/Gerator/business_page/NoBusiness.png";

export const NoBusinessFound = ({ path, pathName }) => {
  return (
    <div className="no-business-found-no-business-found">
      <section className="no-business-found-empty-state">
        <div className="no-business-found-empty-state1">
          <div className="no-business-found-content">
            <img
              className="no-business-found-bifocal-optometry-measurement-icon"
              loading="lazy"
              alt=""
              src={NoBusiness}
            />

            <div className="no-business-found-text-and-supporting-text">
              <div className="no-business-found-title">No business yet!</div>
              <div className="no-business-found-supporting-text">
              Get ready to grow something amazing. Set up your business profile to start selling.
              </div>
            </div>
          </div>
          <div className="no-business-found-actions">

            <Link to={"/dashboard/settings/plan"}>
            <button className="no-business-found-buttonsbutton">
              <img
                className="no-business-found-placeholder-icon"
                alt=""
                src={placeholder}
              />

              <div className="no-business-found-text-padding">
                <div className="no-business-found-text">Upgrade Plan</div>
              </div>
              <img
                className="no-business-found-placeholder-icon"
                alt=""
                src={placeholder}
              />
            </button>
            </Link>
            
            <button className="no-business-found-buttonsbutton1"  >
              <Link to={path} className='no-business-found-button-link'>
                <img className="no-business-found-plus-icon" alt="" src={plus} />

                <div className="no-business-found-text-padding">
                  <div className="no-business-found-text1">Add {pathName}</div>
                </div>
                <img
                  className="no-business-found-placeholder-icon"
                  alt=""
                  src={placeholder1}
                />
              </Link>
            </button>
          </div>
        </div>
      </section>
    </div>
  )
}
