import React from 'react'
import facebook_1_2_2x from '../../../icons/Gerator/detailed_listing/facebook-1-2@2x.png';
import instagram_1_3_2x from '../../../icons/Gerator/detailed_listing/instagram-1-3@2x.png';
import linkedinlogo_2_2x from '../../../icons/Gerator/detailed_listing/linkedinlogo-2@2x.png';
import youtube_1_1_2x from '../../../icons/Gerator/detailed_listing/youtube-1-1@2x.png';
import vimeo_1_2_2x from '../../../icons/Gerator/detailed_listing/vimeo-1-2@2x.png';
import placeholder4 from '../../../icons/Gerator/detailed_listing/placeholder4.svg';
import placeholder2 from '../../../icons/Gerator/detailed_listing/placeholder2.svg';
import help_icon from '../../../icons/Gerator/detailed_listing/help-icon.svg';
import gerator10_2_2x from '../../../icons/Gerator/detailed_listing/gerator10-2@2x.png';
import divider from '../../../icons/Gerator/detailed_listing/divider.svg';


const FooterDetailPage = () => {
    return (
        <>
            <div className="detailed-device-footer">
                <div className="detailed-device-footer-wrapper">
                    <div className="detailed-device-container">
                        <div className="detailed-device-content8">
                            <div className="detailed-device-brand-info">
                                <div className="detailed-device-logo-and-supporting-text">
                                    <div className="detailed-device-logo1">
                                        <img
                                            className="detailed-device-gerator-04-1-icon"
                                            alt=""
                                            src="/detailed_listing/gerator04-1@2x.png"
                                        />
                                    </div>
                                    <div className="detailed-device-supporting-text44">
                                        Buy, sell, lease or exchange medical devices, spares,
                                        services and software with ease.
                                    </div>
                                </div>
                                <div className="detailed-device-breadcrumb-items">
                                    <img
                                        className="detailed-device-linkedin-logo-2-icon"
                                        alt=""
                                        src={facebook_1_2_2x}
                                    />

                                    <img
                                        className="detailed-device-linkedin-logo-2-icon"
                                        alt=""
                                        src={linkedinlogo_2_2x}
                                    />

                                    <img
                                        className="detailed-device-linkedin-logo-2-icon"
                                        alt=""
                                        src={instagram_1_3_2x}
                                    />

                                    <img
                                        className="detailed-device-linkedin-logo-2-icon"
                                        alt=""
                                        src={youtube_1_1_2x}
                                    />

                                    <img
                                        className="detailed-device-linkedin-logo-2-icon"
                                        alt=""
                                        src={vimeo_1_2_2x}
                                    />
                                </div>
                            </div>
                            <div className="detailed-device-links-and-newsletter">
                                <div className="detailed-device-links">
                                    <div className="detailed-device-footer-links-column">
                                        <div className="detailed-device-heading40">Buy & Sell</div>
                                        <div className="detailed-device-footer-links1">
                                            <div className="detailed-device-footer-link1">
                                                <div className="detailed-device-buttonsbutton27">
                                                    <img
                                                        className="detailed-device-chevron-down-icon"
                                                        alt=""
                                                        src={placeholder4}
                                                    />

                                                    <div className="detailed-device-text123">Devices</div>
                                                    <img
                                                        className="detailed-device-chevron-down-icon"
                                                        alt=""
                                                        src={placeholder4}
                                                    />
                                                </div>
                                            </div>
                                            <div className="detailed-device-footer-link1">
                                                <div className="detailed-device-buttonsbutton27">
                                                    <img
                                                        className="detailed-device-chevron-down-icon"
                                                        alt=""
                                                        src={placeholder4}
                                                    />

                                                    <div className="detailed-device-text124">Spares</div>
                                                    <img
                                                        className="detailed-device-chevron-down-icon"
                                                        alt=""
                                                        src={placeholder4}
                                                    />
                                                </div>
                                            </div>
                                            <div className="detailed-device-footer-link3">
                                                <div className="detailed-device-buttonsbutton27">
                                                    <img
                                                        className="detailed-device-chevron-down-icon"
                                                        alt=""
                                                        src={placeholder4}
                                                    />

                                                    <div className="detailed-device-text125">Services</div>
                                                    <img
                                                        className="detailed-device-chevron-down-icon"
                                                        alt=""
                                                        src={placeholder4}
                                                    />
                                                </div>
                                                <div className="detailed-device-badge36">
                                                    <div className="detailed-device-text126">New</div>
                                                </div>
                                            </div>
                                            <div className="detailed-device-footer-link1">
                                                <div className="detailed-device-buttonsbutton27">
                                                    <img
                                                        className="detailed-device-chevron-down-icon"
                                                        alt=""
                                                        src={placeholder4}
                                                    />

                                                    <div className="detailed-device-text127">Software</div>
                                                    <img
                                                        className="detailed-device-chevron-down-icon"
                                                        alt=""
                                                        src={placeholder4}
                                                    />
                                                </div>
                                            </div>
                                            <div className="detailed-device-footer-link1">
                                                <div className="detailed-device-buttonsbutton27">
                                                    <img
                                                        className="detailed-device-chevron-down-icon"
                                                        alt=""
                                                        src={placeholder4}
                                                    />

                                                    <div className="detailed-device-text128">Add Listings</div>
                                                    <img
                                                        className="detailed-device-chevron-down-icon"
                                                        alt=""
                                                        src={placeholder4}
                                                    />
                                                </div>
                                            </div>
                                            <div className="detailed-device-footer-link1">
                                                <div className="detailed-device-buttonsbutton27">
                                                    <img
                                                        className="detailed-device-chevron-down-icon"
                                                        alt=""
                                                        src={placeholder4}
                                                    />

                                                    <div className="detailed-device-text129">Post a Job</div>
                                                    <img
                                                        className="detailed-device-chevron-down-icon"
                                                        alt=""
                                                        src={placeholder4}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="detailed-device-footer-links-column">
                                        <div className="detailed-device-heading40">Quick Links</div>
                                        <div className="detailed-device-footer-links2">
                                            <div className="detailed-device-footer-link1">
                                                <div className="detailed-device-buttonsbutton27">
                                                    <img
                                                        className="detailed-device-chevron-down-icon"
                                                        alt=""
                                                        src={placeholder4}
                                                    />

                                                    <div className="detailed-device-text130">Terms of use</div>
                                                    <img
                                                        className="detailed-device-chevron-down-icon"
                                                        alt=""
                                                        src={placeholder4}
                                                    />
                                                </div>
                                            </div>
                                            <div className="detailed-device-footer-link1">
                                                <div className="detailed-device-buttonsbutton27">
                                                    <img
                                                        className="detailed-device-chevron-down-icon"
                                                        alt=""
                                                        src={placeholder4}
                                                    />

                                                    <div className="detailed-device-text131">Privacy</div>
                                                    <img
                                                        className="detailed-device-chevron-down-icon"
                                                        alt=""
                                                        src={placeholder4}
                                                    />
                                                </div>
                                            </div>
                                            <div className="detailed-device-footer-link1">
                                                <div className="detailed-device-buttonsbutton27">
                                                    <img
                                                        className="detailed-device-chevron-down-icon"
                                                        alt=""
                                                        src={placeholder4}
                                                    />

                                                    <div className="detailed-device-text132">Security</div>
                                                    <img
                                                        className="detailed-device-chevron-down-icon"
                                                        alt=""
                                                        src={placeholder4}
                                                    />
                                                </div>
                                            </div>
                                            <div className="detailed-device-footer-link1">
                                                <div className="detailed-device-buttonsbutton27">
                                                    <img
                                                        className="detailed-device-chevron-down-icon"
                                                        alt=""
                                                        src={placeholder4}
                                                    />

                                                    <div className="detailed-device-text133">Verification</div>
                                                    <img
                                                        className="detailed-device-chevron-down-icon"
                                                        alt=""
                                                        src={placeholder4}
                                                    />
                                                </div>
                                            </div>
                                            <div className="detailed-device-footer-link1">
                                                <div className="detailed-device-buttonsbutton27">
                                                    <img
                                                        className="detailed-device-chevron-down-icon"
                                                        alt=""
                                                        src={placeholder4}
                                                    />

                                                    <div className="detailed-device-text134">Pricing</div>
                                                    <img
                                                        className="detailed-device-chevron-down-icon"
                                                        alt=""
                                                        src={placeholder4}
                                                    />
                                                </div>
                                            </div>
                                            <div className="detailed-device-footer-link1">
                                                <div className="detailed-device-buttonsbutton27">
                                                    <img
                                                        className="detailed-device-chevron-down-icon"
                                                        alt=""
                                                        src={placeholder4}
                                                    />

                                                    <div className="detailed-device-text135">Support</div>
                                                    <img
                                                        className="detailed-device-chevron-down-icon"
                                                        alt=""
                                                        src={placeholder4}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="detailed-device-newsletter">
                                    <div className="detailed-device-heading40">Stay up to date</div>
                                    <div className="detailed-device-email-capture">
                                        <div className="detailed-device-input-field2">
                                            <div className="detailed-device-input-with-label6">
                                                <div className="detailed-device-label6">Stay up to date</div>
                                                <div className="detailed-device-input4">
                                                    <div className="detailed-device-button-wrap">
                                                        <div className="detailed-device-placeholder">Enter your email</div>
                                                    </div>
                                                    <img
                                                        className="detailed-device-help-icon"
                                                        alt=""
                                                        src={help_icon}
                                                    />
                                                </div>
                                            </div>
                                            <div className="detailed-device-hint-text5">
                                                This is a hint text to help user.
                                            </div>
                                        </div>
                                        <div className="detailed-device-buttonsbutton39">
                                            <img
                                                className="detailed-device-chevron-down-icon"
                                                alt=""
                                                src={placeholder2}
                                            />

                                            <div className="detailed-device-text-padding">
                                                <div className="detailed-device-text137">Subscribe</div>
                                            </div>
                                            <img
                                                className="detailed-device-chevron-down-icon"
                                                alt=""
                                                src={placeholder2}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <img className="detailed-device-divider-icon29" alt="" src={divider} />
            </div>
            <div className="detailed-device-footer1">
                <div className="detailed-device-container1">
                    <div className="detailed-device-content10">
                        <div className="detailed-device-footer-text">
                            © Gerator Asia LLP. All rights reserved.
                        </div>
                        <div className="detailed-device-logo2">
                            <div className="detailed-device-copyright-brand-details">
                                <div className="detailed-device-brand-rights">
                                    <div className="detailed-device-footer-text">All prices are in USD</div>
                                </div>
                                <div className="detailed-device-logomark">
                                    <div className="detailed-device-logomark1">
                                        <img
                                            className="detailed-device-gerator-10-2-icon"
                                            alt=""
                                            src={gerator10_2_2x}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div></>
    )
}

export default FooterDetailPage