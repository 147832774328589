import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  softwareCategory:'',
  softwareValueProposition:'',
  softwareType:'',
  installationLocation:'',
  clinicalApplications:'',
  purposeUse:'',
  deviceCategory:'',
  physicalLocation:'',
}

const editWantedSoftwareSlice = createSlice({
  name: "editWantedSoftware",
  initialState,
  reducers: {
    fillEditWantedSoftwareForm(state, action) {
     return action.payload;
    },
    updateEditWantedSoftwareField(state, action) {
      const {field, value} = action.payload
      if (value !== undefined) { 
        state[field] = value; 
      }
    },
    clearEditWantedSoftwareForm() {
      return initialState;
    }
  },
});

export const {fillEditWantedSoftwareForm, updateEditWantedSoftwareField,clearEditWantedSoftwareForm } = editWantedSoftwareSlice.actions

export default editWantedSoftwareSlice.reducer

