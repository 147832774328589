import { createBrowserRouter } from "react-router-dom";
import { authRoutes } from "./auth.routes";
import { accountSetupRoutes } from "./account_setup.routes";
import { resetPasswordRoutes } from "./reset_password.routes";
import { spareRoutes } from "./spare.routes";
import { serviceRoutes } from "./services.routes";
import { softwareRoutes } from "./software.routes";
import { jobRoutes } from "./job.routes";
import { deviceRoutes } from "./device.routes";
import { wantedDeviceRoutes } from "./wanted/wanted-device.routes";
import { wantedSpareRoutes } from "./wanted/wanted-spare.routes";
import { wantedSoftwareRoutes } from "./wanted/wanted-software.routes";
import { wantedServiceRoutes } from "./wanted/wanted-service.routes";
import { businessRoutes } from "./business.routes";
import { Error } from "../screens/Error/Error";
import { SettingsRoutes } from "./settings.routes";
import { AddressRoutes } from "./address.routes";
import { salesIqRoutes } from "./sales_iq.routes";
import { chatRoutes } from "./chat.routes";
import { adminRoutes } from "./admin.routes";

export const Router = createBrowserRouter([

  // Auth and Common Routes
  ...adminRoutes,
  ...authRoutes,
  ...accountSetupRoutes,
  ...resetPasswordRoutes,

  // Device, Spare, Service, Software, Jobs, and Wanted Routes
  ...deviceRoutes,
  ...spareRoutes,
  ...serviceRoutes,
  ...softwareRoutes,
  ...jobRoutes,

  // Wanted Routes
  ...wantedDeviceRoutes,
  ...wantedSpareRoutes,
  ...wantedSoftwareRoutes,
  ...wantedServiceRoutes,

  // Business Routes
  ...businessRoutes,

  // Settigns Page Routes
  ...SettingsRoutes,

  // Address Page Routes
  ...AddressRoutes,

  // SalesIQ Page Routes
  ...salesIqRoutes,
  // 404 Route (Wildcard to catch all other paths)

  ...chatRoutes,
  {
    path: "*",
    element: <Error />,
  }

]);