import React, { useState,useEffect } from 'react';
import './VideoSection.css'; // Assuming you have a CSS file for styles
import group_351 from '../../icons/Gerator/detailed_listing/group-351.svg';


const VideoSection = ({ videoUrl }) => {
  const [isVideoVisible, setVideoVisible] = useState(false);
  const [thumbnailUrl, setThumbnailUrl] = useState(null);
  const [videoData, setVideoData] = useState(null);

  const playVideo = () => {
    setVideoVisible(true);
  };

  const closeVideo = () => {
    setVideoVisible(false);
  };

  const extractVideoId = (url) => {
    const youtubeRegex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const vimeoRegex = /(?:https?:\/\/)?(?:www\.)?vimeo\.com\/(?:video\/)?([0-9]+)/;
    
    if (youtubeRegex.test(url)) {
      const matches = url.match(youtubeRegex);
      return { type: 'youtube', id: matches ? matches[1] : null };
    } else if (vimeoRegex.test(url)) {
      const matches = url.match(vimeoRegex);
      return { type: 'vimeo', id: matches ? matches[1] : null };
    }

    return null;
  };

  const fetchVimeoThumbnail = async (videoId) => {
    try {
      const response = await fetch(`https://vimeo.com/api/oembed.json?url=https://vimeo.com/${videoId}`);
      const data = await response.json();
      return data.thumbnail_url;
    } catch (error) {
      console.error('Error fetching Vimeo thumbnail:', error);
      return null;
    }
  };

  // const videoId = extractVideoId(videoUrl);
  // const thumbnailUrl =  videoData?.type === 'youtube'
  // ? `https://img.youtube.com/vi/${videoData.id}/0.jpg`
  // : null;

  useEffect(() => {
    const videoData = extractVideoId(videoUrl);
    setVideoData(videoData);
  }, [videoUrl]);



  useEffect(() => {
    const getThumbnail = async () => {
      if (videoData?.type === 'youtube') {
        setThumbnailUrl(`https://img.youtube.com/vi/${videoData.id}/0.jpg`);
      } else if (videoData?.type === 'vimeo') {
        const thumbnail = await fetchVimeoThumbnail(videoData.id);
        setThumbnailUrl(thumbnail);
      }
    };

    if (videoData) {
      getThumbnail();
    }
  }, [videoData]);


  return (
    <div className="video-section-image-gallery">
      <div className="video-section-heading1">Video</div>
      <div className="video-section-gallery-images">
        {!isVideoVisible && (
          <div className="video-thumbnail" onClick={playVideo}>
            {thumbnailUrl && (
              <img
                src={thumbnailUrl}
                className="video-section-gallery-images-container-child"
                alt="Video Thumbnail"
              />
            )}
            <img
              className="video-section-gallery-images-container-item"
              alt="Play Button"
              src={group_351}
            />
          </div>
        )}
        {isVideoVisible && (
          <div className="video-container">

          {videoData?.type === 'youtube' && (
            <iframe
              width="100%"
              height="100%"
              src={`https://www.youtube.com/embed/${videoData.id}`}
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Embedded YouTube Video"
            ></iframe>  
          )}

            {videoData?.type === 'vimeo' && (
              <iframe
                src={`https://player.vimeo.com/video/${videoData.id}`}
                width="100%"
                height="100%"
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
                title="Embedded Vimeo Video"
              ></iframe>
            )}

            <button className="close-button" onClick={closeVideo}>
              Close Video
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default VideoSection;
