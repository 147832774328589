import React, { useRef } from 'react';
import "./ArchiveDeviceStatic.css"
import { Navbar1 } from '../../../components/Navbar1/Navbar1';
import { FooterAddForms } from '../../../components/Footer/FooterAddForms/FooterAddForms';
import React, { useEffect, useState } from "react";

import { getCustomizedDate } from "../../../helper/ServiceHelper";
import { Loader } from "../../../components/Loader";
import { Link, useNavigate } from "react-router-dom";
import divider from '../../../icons/Gerator/archive_page/divider.svg';
import divider1 from '../../../icons/Gerator/archive_page/divider1.svg';

import divider2 from '../../../icons/Gerator/archive_page/divider2.svg';
import divider4 from '../../../icons/Gerator/archive_page/divider-4.svg';
import divider12 from '../../../icons/Gerator/archive_page/divider-12.svg';
import divider13 from '../../../icons/Gerator/archive_page/divider-13.svg';
import divider21 from '../../../icons/Gerator/archive_page/divider-21.svg';

import eye from '../../../icons/Gerator/archive_page/eye.svg';


import dotsvertical from '../../../icons/Gerator/archive_page/dotsvertical.svg';
import dot1 from '../../../icons/Gerator/archive_page/-dot1.svg';
import dot from '../../../icons/Gerator/archive_page/-dot.svg';

import help_icon2 from '../../../icons/Gerator/archive_page/help-icon2.svg';
import help_icon from '../../../icons/Gerator/archive_page/help-icon.svg';


import featured_icon1 from '../../../icons/Gerator/archive_page/featured-icon1.svg';


import chevronright from '../../../icons/Gerator/archive_page/chevronright.svg';
import chevrondown from '../../../icons/Gerator/archive_page/chevrondown.svg';

import placeholder from '../../../icons/Gerator/archive_page/placeholder.svg';
import placeholder1 from '../../../icons/Gerator/archive_page/placeholder1.svg';
import placeholder2 from '../../../icons/Gerator/archive_page/placeholder2.svg';
import placeholder3 from '../../../icons/Gerator/archive_page/placeholder3.svg';

import homeline from '../../../icons/Gerator/archive_page/homeline.svg';
import searchlg from '../../../icons/Gerator/archive_page/searchlg.svg';

import calendar from '../../../icons/Gerator/archive_page/calendar.svg';


import radar_1_1_2x from '../../../icons/Gerator/archive_page/radar-1-1@2x.png';
import deal_2_1_2x from '../../../icons/Gerator/archive_page/deal-2-1@2x.png';
import magnifier_1_2x from '../../../icons/Gerator/archive_page/magnifier-1@2x.png';

import group_58_2x from '../../../icons/Gerator/archive_page/group-58@2x.png';

import dotsvertical from '../../../icons/Gerator/archive_page/dotsvertical.svg';
import filterfunnel01 from '../../../icons/Gerator/archive_page/filterfunnel01.svg';
import searchlg from '../../../icons/Gerator/archive_page/searchlg.svg';
import filterlines from '../../../icons/Gerator/archive_page/filterlines.svg';
import dot1 from '../../../icons/Gerator/archive_page/-dot1.svg';
import xclose from '../../../icons/Gerator/archive_page/xclose.svg';
import xclose10 from '../../../icons/Gerator/archive_page/xclose-10.svg';

import lock04 from '../../../icons/Gerator/archive_page/lock04.svg';
import lockunlocked04 from '../../../icons/Gerator/archive_page/lockunlocked04.svg';
import arrowsquareupright from '../../../icons/Gerator/archive_page/arrowsquareupright.svg';
import markerpin02 from '../../../icons/Gerator/archive_page/markerpin02.svg';
import icon1 from '../../../icons/Gerator/archive_page/icon-1.svg';
import sliders02 from '../../../icons/Gerator/archive_page/sliders02.svg';
import { useLazyGetUserQuery } from "../../../api/Login&RegisterAPI";
import { auth, onAuthStateChanged } from '../../Login/FirebaseClient';
import { decryptErrorData } from "../../../helper/AESHelper";
import { useDeleteDeviceMutation, useLazyGetDevicesQuery } from '../../../api/DeviceAPI';
import { useGetAvailabilityTaxQuery, useGetClinicalApplicationsTaxQuery, useGetDeviceCategoryTaxQuery, useGetOemTaxQuery, useGetPhysicalLocationTaxQuery, useGetPurposeUseTaxQuery, useGetShippingTaxQuery, useGetStatusConditionTaxQuery, useGetTransactionTypeTaxQuery, useGetUnitOfMeasureDeviceSpareTaxQuery, useGetWarrantyTaxQuery, useGetYearOfManufactureTaxQuery, useGetYourRoleTaxQuery, useGetYearOfManufactureFilterTaxQuery, useGetClinicalApplicationsFilterTaxQuery, useGetPhysicalLocationFilterTaxQuery, useGetPurposeUseFilterTaxQuery } from "../../../api/TaxonomyFormAPI";
import { Navbar } from '../../../components/Navbar/Navbar';




export const ArchiveDeviceStatic = () => {

  const [collapsedSections, setCollapsedSections] = useState({
    price: true,
    datePosted: true,
    sellerRole: true,
    transactionType: true,
    status: true,
    warranty: true,
    shipping: true,
    country: true,
    deviceCategory: true,
    oemBrand: true,
    yearOfManufacture: true,
    clinicalApplications: true,
    deviceUse: true,
    physicalLocation: true
  });



  return (
    <div className="archive-device1-listings-search-results-list">

      <Navbar/>
      <div className='archive-device1-mid-container'>


        <div className="archive-device1-slide-out">
          <img className="archive-device1-divider-icon14" alt="" src={divider12} />


          <div className="archive-device-rectangle-parent4">

            <div className="archive-device-frame-parent28">
              <div className="archive-device-slide-out-menu-header-parent">
                <div className="archive-device-slide-out-menu-header">
                  <div className="archive-device-content24">
                    <img
                      className="archive-device-featured-icon1"
                      alt=""
                      src={featured_icon1}
                    />

                    <div className="archive-device-text-and-supporting-text6">
                      <a className="archive-device-text184">Filters</a>
                      <div className="archive-device-supporting-text57">
                        Apply filters to table data.
                      </div>
                    </div>
                  </div>
                  <div className="archive-device-button-close-x">
                    <img
                      className="archive-device-search-lg-icon"
                      alt=""
                      src={xclose10}
                    />
                  </div>
                </div>
                <div className="archive-device-frame-wrapper13">
                  <div className="archive-device-slide-out-title-parent">
                    <a className="archive-device-slide-out-title">Price</a>
                    <div className="archive-device-navigation">
                      <img
                        onClick={() => toggleCollapse('price')}
                        className="archive-device-marker-pin-02-icon2"
                        alt=""
                        src={chevrondown}
                        style={{ cursor: 'pointer' }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="archive-device-frame-parent29">
                <div className="archive-device-frame-parent30"

                  style={{
                    maxHeight: collapsedSections.price ? '0px' : '500px',
                    overflow: 'hidden',
                    transition: 'max-height 0.3s ease-in-out',
                  }}
                >
                  <div className="archive-device-frame-wrapper14">
                    <div className="archive-device-frame-parent31">
                      <div className="archive-device-background-parent">
                        <div className="archive-device-background"></div>
                        <div className="archive-device-progress">
                          <div className="archive-device-progress-line"></div>


                          <div className="archive-device-handle"></div>



                          <div className="archive-device-handle"></div>

                        </div>
                      </div>
                      <div className="archive-device-input-fields-parent">
                        <div className="archive-device-input-fields">
                          <div className="archive-device-input-field">
                            <div className="archive-device-input-with-label4">
                              <div className="archive-device-label5">Email</div>
                              <div className="archive-device-input4">
                                <div className="archive-device-content27">
                                  <div className="archive-device-text187">Min Value</div>
                                </div>
                                <img
                                  className="archive-device-help-icon1"
                                  alt=""
                                  src={help_icon}
                                />
                              </div>
                            </div>
                            <div className="archive-device-hint-text">
                              This is a hint text to help user.
                            </div>
                          </div>
                          <div className="archive-device-text188">–</div>
                          <div className="archive-device-input-field">
                            <div className="archive-device-input-with-label4">
                              <div className="archive-device-label5">Email</div>
                              <div className="archive-device-input4">
                                <div className="archive-device-content27">
                                  <div className="archive-device-text187">Max Value</div>
                                </div>
                                <img
                                  className="archive-device-help-icon1"
                                  alt=""
                                  src={help_icon}
                                />
                              </div>
                            </div>
                            <div className="archive-device-hint-text">
                              This is a hint text to help user.
                            </div>
                          </div>
                        </div>
                        <div className="archive-device-checkboxes">
                          <div className="archive-device-collapsed-names">
                            <input className="archive-device-input6" type="checkbox" />

                            <div className="archive-device-price-parent">
                              <div className="archive-device-text8">US$ 0 — US$ 1,000</div>
                              <div className="archive-device-hint-text">
                                Save my login details for next time.
                              </div>
                            </div>
                          </div>
                          <div className="archive-device-collapsed-names">
                            <input className="archive-device-input6" type="checkbox" />

                            <div className="archive-device-price-parent">
                              <div className="archive-device-text8">US$ 1,001 — US$ 5,000</div>
                              <div className="archive-device-hint-text">
                                Save my login details for next time.
                              </div>
                            </div>
                          </div>
                          <div className="archive-device-collapsed-names">
                            <input className="archive-device-input6" type="checkbox" />

                            <div className="archive-device-price-parent">
                              <div className="archive-device-text8">US$ 5,001 — US$ 15,000</div>
                              <div className="archive-device-hint-text">
                                Save my login details for next time.
                              </div>
                            </div>
                          </div>
                          <div className="archive-device-collapsed-names">
                            <input className="archive-device-input6" type="checkbox" />

                            <div className="archive-device-price-parent">
                              <div className="archive-device-text8">US$ 15,001 — US$ 9,999,999</div>
                              <div className="archive-device-hint-text">
                                Save my login details for next time.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <img
                  className="archive-device-divider-icon15"
                  alt=""
                  src={divider13}
                />

                <div className="archive-device-section-wrapper">
                  <div className="archive-device-section-parent">
                    <div className="archive-device-section">
                      <div className="archive-device-content-parent">
                        <div className="archive-device-content29">
                          <img className="archive-device-dot-icon" alt="" src={dot} />

                          <div className="archive-device-text194">Date Posted</div>
                        </div>
                        <img
                          onClick={() => toggleCollapse('datePosted')}
                          className="archive-device-search-lg-icon"
                          alt=""
                          src={chevrondown}
                          style={{ cursor: 'pointer' }}
                        />
                      </div>
                    </div>
                    <div className="archive-device-input-fields"

                      style={{
                        maxHeight: collapsedSections.datePosted ? '0px' : '500px',
                        overflow: 'hidden',
                        transition: 'max-height 0.3s ease-in-out',
                      }}
                    >
                      <div className="archive-device-input-field2">
                        <div className="archive-device-input-with-label4">
                          <div className="archive-device-label5">Email</div>
                          <div className="archive-device-input10">
                            <div className="archive-device-content27">
                              <div className="archive-device-text187">Jan 6, 2024</div>
                            </div>
                            <img
                              className="archive-device-help-icon1"
                              alt=""
                              src={help_icon2}
                            />
                          </div>
                        </div>
                        <div className="archive-device-hint-text">
                          This is a hint text to help user.
                        </div>
                      </div>
                      <div className="archive-device-text188">–</div>
                      <div className="archive-device-input-field2">
                        <div className="archive-device-input-with-label4" >
                          <div className="archive-device-label5">Email</div>
                          <div className="archive-device-input10">
                            <div className="archive-device-content27">
                              <div className="archive-device-text187">Jan 12, 2024</div>
                            </div>
                            <img
                              className="archive-device-help-icon1"
                              alt=""
                              src={help_icon2}
                            />
                          </div>
                        </div>
                        <div className="archive-device-hint-text">
                          This is a hint text to help user.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <img
                  className="archive-device-divider-icon15"
                  alt=""
                  src={divider13}
                />

                <div className="archive-device-section-wrapper">
                  <div className="archive-device-section1">
                    <div className="archive-device-content-parent">
                      <div className="archive-device-content29">
                        <img className="archive-device-dot-icon" alt="" src={dot} />

                        <div className="archive-device-text198">Seller Role</div>
                      </div>
                      <img
                        onClick={() => toggleCollapse('sellerRole')}
                        className="archive-device-search-lg-icon"
                        alt=""
                        src={chevrondown}
                        style={{ cursor: 'pointer' }}
                      />
                    </div>
                    <div className="archive-device-checkboxes1"
                      style={{
                        maxHeight: collapsedSections.sellerRole ? '0px' : '500px',
                        overflow: 'hidden',
                        transition: 'max-height 0.3s ease-in-out',
                      }}
                    >
                      <div className="archive-device-collapsed-names">
                        <input className="archive-device-input6" type="checkbox" />

                        <div className="archive-device-price-parent">
                          <div className="archive-device-text8">Dealer/Distributor</div>
                          <div className="archive-device-hint-text">
                            Save my login details for next time.
                          </div>
                        </div>
                      </div>
                      <div className="archive-device-collapsed-names">
                        <input className="archive-device-input6" type="checkbox" />

                        <div className="archive-device-price-parent">
                          <div className="archive-device-text8">Manufacturer</div>
                          <div className="archive-device-hint-text">
                            Save my login details for next time.
                          </div>
                        </div>
                      </div>
                      <div className="archive-device-collapsed-names">
                        <input className="archive-device-input6" type="checkbox" />

                        <div className="archive-device-price-parent">
                          <div className="archive-device-text8">Owner/Employee</div>
                          <div className="archive-device-hint-text">
                            Save my login details for next time.
                          </div>
                        </div>
                      </div>
                      <div className="archive-device-collapsed-names">
                        <input className="archive-device-input6" type="checkbox" />

                        <div className="archive-device-price-parent">
                          <div className="archive-device-text8">Third Party/Agent</div>
                          <div className="archive-device-hint-text">
                            Save my login details for next time.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <img
                  className="archive-device-divider-icon15"
                  alt=""
                  src={divider13}
                />

                <div className="archive-device-section-wrapper">
                  <div className="archive-device-section1">
                    <div className="archive-device-content-parent">
                      <div className="archive-device-content29">
                        <img className="archive-device-dot-icon" alt="" src={dot} />

                        <div className="archive-device-text203">Transaction Type</div>
                      </div>
                      <img
                        onClick={() => toggleCollapse('transactionType')}
                        className="archive-device-search-lg-icon1"
                        alt=""
                        src={chevrondown}
                        style={{ cursor: 'pointer' }}
                      />
                    </div>
                    <div className="archive-device-checkboxes1"

                      style={{
                        maxHeight: collapsedSections.transactionType ? '0px' : '500px',
                        overflow: 'hidden',
                        transition: 'max-height 0.3s ease-in-out',
                      }}


                    >
                      <div className="archive-device-collapsed-names">
                        <input className="archive-device-input6" type="checkbox" />

                        <div className="archive-device-price-parent">
                          <div className="archive-device-text8">Exchange</div>
                          <div className="archive-device-hint-text">
                            Save my login details for next time.
                          </div>
                        </div>
                      </div>
                      <div className="archive-device-collapsed-names">
                        <input className="archive-device-input6" type="checkbox" />

                        <div className="archive-device-price-parent">
                          <div className="archive-device-text8">Rent/Lease</div>
                          <div className="archive-device-hint-text">
                            Save my login details for next time.
                          </div>
                        </div>
                      </div>
                      <div className="archive-device-collapsed-names">
                        <input className="archive-device-input6" type="checkbox" />

                        <div className="archive-device-price-parent">
                          <div className="archive-device-text8">Sale</div>
                          <div className="archive-device-hint-text">
                            Save my login details for next time.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <img
                  className="archive-device-divider-icon15"
                  alt=""
                  src={divider13}
                />

                <div className="archive-device-section-wrapper">
                  <div className="archive-device-section1">
                    <div className="archive-device-content-parent">
                      <div className="archive-device-content29">
                        <img className="archive-device-dot-icon" alt="" src={dot} />

                        <div className="archive-device-text203">Status</div>
                      </div>
                      <img

                        onClick={() => toggleCollapse('status')}
                        className="archive-device-search-lg-icon"
                        alt=""
                        src={chevrondown}
                        style={{ cursor: 'pointer' }}
                      />
                    </div>
                    <div className="archive-device-checkboxes1"

                      style={{
                        maxHeight: collapsedSections.status ? '0px' : '500px',
                        overflow: 'hidden',
                        transition: 'max-height 0.3s ease-in-out',
                      }}

                    >
                      <div className="archive-device-collapsed-names">
                        <input className="archive-device-input6" type="checkbox" />

                        <div className="archive-device-price-parent">
                          <div className="archive-device-text8">New</div>
                          <div className="archive-device-hint-text">
                            Save my login details for next time.
                          </div>
                        </div>
                      </div>
                      <div className="archive-device-collapsed-names">
                        <input className="archive-device-input6" type="checkbox" />

                        <div className="archive-device-price-parent">
                          <div className="archive-device-text8">New / Surplus</div>
                          <div className="archive-device-hint-text">
                            Save my login details for next time.
                          </div>
                        </div>
                      </div>
                      <div className="archive-device-collapsed-names">
                        <input className="archive-device-input6" type="checkbox" />

                        <div className="archive-device-price-parent">
                          <div className="archive-device-text8">Pre-owned / Used</div>
                          <div className="archive-device-hint-text">
                            Save my login details for next time.
                          </div>
                        </div>
                      </div>

                      <div className="archive-device-collapsed-names">
                        <input className="archive-device-input6" type="checkbox" />

                        <div className="archive-device-price-parent">
                          <div className="archive-device-text8">
                            Pre-owned / Used / Currently De-Installed</div>
                          <div className="archive-device-hint-text">
                            Save my login details for next time.
                          </div>
                        </div>
                      </div>

                      <div className="archive-device-collapsed-names">
                        <input className="archive-device-input6" type="checkbox" />

                        <div className="archive-device-price-parent">
                          <div className="archive-device-text8">
                            Pre-owned / Used / Currently Installed</div>
                          <div className="archive-device-hint-text">
                            Save my login details for next time.
                          </div>
                        </div>
                      </div>

                      <div className="archive-device-collapsed-names">
                        <input className="archive-device-input6" type="checkbox" />

                        <div className="archive-device-price-parent">
                          <div className="archive-device-text8">Pre-owned / Used / Fully Refurbished</div>
                          <div className="archive-device-hint-text">
                            Save my login details for next time.
                          </div>
                        </div>
                      </div>

                      <div className="archive-device-collapsed-names">
                        <input className="archive-device-input6" type="checkbox" />

                        <div className="archive-device-price-parent">
                          <div className="archive-device-text8">
                            Pre-owned / Used / Partially Refurbished</div>
                          <div className="archive-device-hint-text">
                            Save my login details for next time.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <img
                  className="archive-device-divider-icon15"
                  alt=""
                  src={divider13}
                />

                <div className="archive-device-section-wrapper">
                  <div className="archive-device-section1">
                    <div className="archive-device-content-parent">
                      <div className="archive-device-content29">
                        <img className="archive-device-dot-icon" alt="" src={dot} />

                        <div className="archive-device-text203">Warranty</div>
                      </div>
                      <img
                        onClick={() => toggleCollapse('warranty')}
                        className="archive-device-search-lg-icon"
                        alt=""
                        src={chevrondown}
                        style={{ cursor: 'pointer' }}
                      />
                    </div>
                    <div className="archive-device-checkboxes1"

                      style={{
                        maxHeight: collapsedSections.warranty ? '0px' : '500px',
                        overflow: 'hidden',
                        transition: 'max-height 0.3s ease-in-out',
                      }}

                    >
                      <div className="archive-device-collapsed-names">
                        <input className="archive-device-input6" type="checkbox" />

                        <div className="archive-device-price-parent">
                          <div className="archive-device-text8">Included in pricing</div>
                          <div className="archive-device-hint-text">
                            Save my login details for next time.
                          </div>
                        </div>
                      </div>
                      <div className="archive-device-collapsed-names">
                        <input className="archive-device-input6" type="checkbox" />

                        <div className="archive-device-price-parent">
                          <div className="archive-device-text8">Seller does not offer warranty</div>
                          <div className="archive-device-hint-text">
                            Save my login details for next time.
                          </div>
                        </div>
                      </div>
                      <div className="archive-device-collapsed-names">
                        <input className="archive-device-input6" type="checkbox" />

                        <div className="archive-device-price-parent">
                          <div className="archive-device-text8">Seller may offer warranty at extra cost</div>
                          <div className="archive-device-hint-text">
                            Save my login details for next time.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <img
                  className="archive-device-divider-icon15"
                  alt=""
                  src={divider13}
                />

                <div className="archive-device-section-wrapper">
                  <div className="archive-device-section1">
                    <div className="archive-device-content-parent">
                      <div className="archive-device-content29">
                        <img className="archive-device-dot-icon" alt="" src={dot} />

                        <div className="archive-device-text203">Shipping</div>
                      </div>
                      <img
                        onClick={() => toggleCollapse('shipping')}
                        className="archive-device-search-lg-icon"
                        alt=""
                        src={chevrondown}
                        style={{ cursor: 'pointer' }}
                      />
                    </div>
                    <div className="archive-device-checkboxes1"

                      style={{
                        maxHeight: collapsedSections.shipping ? '0px' : '500px',
                        overflow: 'hidden',
                        transition: 'max-height 0.3s ease-in-out',
                      }}

                    >
                      <div className="archive-device-collapsed-names">
                        <input className="archive-device-input6" type="checkbox" />

                        <div className="archive-device-price-parent">
                          <div className="archive-device-text8">Buyer to organize shipping  </div>
                          <div className="archive-device-hint-text">
                            Save my login details for next time.
                          </div>
                        </div>
                      </div>
                      <div className="archive-device-collapsed-names">
                        <input className="archive-device-input6" type="checkbox" />

                        <div className="archive-device-price-parent">
                          <div className="archive-device-text8">Seller may organize shipping at extra cost</div>
                          <div className="archive-device-hint-text">
                            Save my login details for next time.
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
                <img
                  className="archive-device-divider-icon15"
                  alt=""
                  src={divider13}
                />


                <div className="archive-device-section-wrapper">
                  <div className="archive-device-section-parent">
                    <div className="archive-device-actions6">
                      <div className="archive-device-navigation">

                        <img
                          onClick={() => toggleCollapse('country')}
                          className="archive-device-search-lg"
                          src={searchlg}
                          alt=''
                          style={{ cursor: 'pointer' }}
                        />
                      </div>
                      <div className="archive-device-text210">Country</div>
                    </div>
                    <div className="archive-device-input-fields2">
                      <div className="archive-device-input-field2">
                        <div className="archive-device-input-with-label4"

                          style={{
                            maxHeight: collapsedSections.country ? '0px' : '500px',
                            overflow: 'hidden',
                            transition: 'max-height 0.3s ease-in-out',
                          }}
                        >
                          <div className="archive-device-label5">Email</div>
                          <div className="archive-device-input19">
                            <input

                              className="archive-device-content37"
                              placeholder="Search"
                              type="text"
                            />

                            <img
                              className="archive-device-help-icon1"
                              alt=""
                              src={help_icon}
                            />
                          </div>
                        </div>
                        <div className="archive-device-hint-text">
                          This is a hint text to help user.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="archive-device-second-badge-divider">
                  <img
                    className="archive-device-divider-icon22"
                    alt=""
                    src={divider13}
                  />

                  <img
                    className="archive-device-divider-icon23"
                    alt=""
                    src={divider21}
                  />
                </div>

                <div className="archive-device-section-wrapper">
                  <div className="archive-device-section-parent">
                    <div className="archive-device-actions6">
                      <div className="archive-device-navigation">
                        <img
                          onClick={() => toggleCollapse('deviceCategory')}
                          className="archive-device-search-lg"
                          src={searchlg}
                          alt=''
                          style={{ cursor: 'pointer' }}
                        />
                      </div>
                      <div className="archive-device-text210">Device Category</div>
                    </div>
                    <div className="archive-device-input-fields2">
                      <div className="archive-device-input-field2">
                        <div className="archive-device-input-with-label4"

                          style={{
                            maxHeight: collapsedSections.deviceCategory ? '0px' : '500px',
                            overflow: 'hidden',
                            transition: 'max-height 0.3s ease-in-out',
                          }}

                        >
                          <div className="archive-device-label5">Email</div>
                          <div className="archive-device-input19">
                            <input
                              className="archive-device-content37"
                              placeholder="Search"
                              type="text"
                            />

                            <img
                              className="archive-device-help-icon1"
                              alt=""
                              src={help_icon}
                            />
                          </div>
                        </div>
                        <div className="archive-device-hint-text">
                          This is a hint text to help user.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="archive-device-second-badge-divider">
                  <img
                    className="archive-device-divider-icon22"
                    alt=""
                    src={divider13}
                  />

                  <img
                    className="archive-device-divider-icon23"
                    alt=""
                    src={divider21}
                  />
                </div>

                <div className="archive-device-section-wrapper">
                  <div className="archive-device-section-parent">
                    <div className="archive-device-actions6">
                      <div className="archive-device-navigation">
                        <img
                          onClick={() => toggleCollapse('oemBrand')}
                          className="archive-device-search-lg"
                          src={searchlg}
                          alt=''
                          style={{ cursor: 'pointer' }}
                        />
                      </div>
                      <div className="archive-device-text210">OEM Brand</div>
                    </div>
                    <div className="archive-device-input-fields2">
                      <div className="archive-device-input-field2">
                        <div className="archive-device-input-with-label4"

                          style={{
                            maxHeight: collapsedSections.oemBrand ? '0px' : '500px',
                            overflow: 'hidden',
                            transition: 'max-height 0.3s ease-in-out',
                          }}

                        >
                          <div className="archive-device-label5">Email</div>
                          <div className="archive-device-input19">
                            <input
                              className="archive-device-content37"
                              placeholder="Search"
                              type="text"
                            />

                            <img
                              className="archive-device-help-icon1"
                              alt=""
                              src={help_icon}
                            />
                          </div>
                        </div>
                        <div className="archive-device-hint-text">
                          This is a hint text to help user.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="archive-device-second-badge-divider">
                  <img
                    className="archive-device-divider-icon22"
                    alt=""
                    src={divider13}
                  />

                  <img
                    className="archive-device-divider-icon23"
                    alt=""
                    src={divider21}
                  />
                </div>

                <div className="archive-device-section-wrapper">
                  <div className="archive-device-section-parent">
                    <div className="archive-device-actions6">
                      <div className="archive-device-navigation">
                        <img
                          onClick={() => toggleCollapse('yearOfManufacture')}
                          className="archive-device-search-lg"
                          src={searchlg}
                          alt=''
                          style={{ cursor: 'pointer' }}
                        />
                      </div>
                      <div className="archive-device-text210">Year of Manufacture</div>
                    </div>
                    <div className="archive-device-input-fields2">
                      <div className="archive-device-input-field2">
                        <div className="archive-device-input-with-label4"

                          style={{
                            maxHeight: collapsedSections.yearOfManufacture ? '0px' : '500px',
                            overflow: 'hidden',
                            transition: 'max-height 0.3s ease-in-out',
                          }}

                        >
                          <div className="archive-device-label5">Email</div>
                          <div className="archive-device-input19">
                            <input
                              className="archive-device-content37"
                              placeholder="Search"
                              type="text"
                            />

                            <img
                              className="archive-device-help-icon1"
                              alt=""
                              src={help_icon}
                            />
                          </div>
                        </div>
                        <div className="archive-device-hint-text">
                          This is a hint text to help user.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="archive-device-second-badge-divider">
                  <img
                    className="archive-device-divider-icon22"
                    alt=""
                    src={divider13}
                  />

                  <img
                    className="archive-device-divider-icon23"
                    alt=""
                    src={divider21}
                  />
                </div>

                <div className="archive-device-section-wrapper">
                  <div className="archive-device-section-parent">
                    <div className="archive-device-actions6">
                      <div className="archive-device-navigation">
                        <img
                          onClick={() => toggleCollapse('clinicalApplications')}
                          className="archive-device-search-lg"
                          src={searchlg}
                          alt=''
                          style={{ cursor: 'pointer' }}
                        />
                      </div>
                      <div className="archive-device-text210">Clinical Applications</div>
                    </div>
                    <div className="archive-device-input-fields2">
                      <div className="archive-device-input-field2">
                        <div className="archive-device-input-with-label4"

                          style={{
                            maxHeight: collapsedSections.clinicalApplications ? '0px' : '500px',
                            overflow: 'hidden',
                            transition: 'max-height 0.3s ease-in-out',
                          }}

                        >
                          <div className="archive-device-label5">Email</div>
                          <div className="archive-device-input19">
                            <input
                              className="archive-device-content37"
                              placeholder="Search"
                              type="text"
                            />

                            <img
                              className="archive-device-help-icon1"
                              alt=""
                              src={help_icon}
                            />
                          </div>
                        </div>
                        <div className="archive-device-hint-text">
                          This is a hint text to help user.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="archive-device-second-badge-divider">
                  <img
                    className="archive-device-divider-icon22"
                    alt=""
                    src={divider13}
                  />

                  <img
                    className="archive-device-divider-icon23"
                    alt=""
                    src={divider21}
                  />
                </div>

                <div className="archive-device-section-wrapper">
                  <div className="archive-device-section-parent">
                    <div className="archive-device-actions6">
                      <div className="archive-device-navigation">
                        <img
                          onClick={() => toggleCollapse('deviceUse')}
                          className="archive-device-search-lg"
                          src={searchlg}
                          alt=''
                          style={{ cursor: 'pointer' }}
                        />
                      </div>
                      <div className="archive-device-text210">Device Use</div>
                    </div>
                    <div className="archive-device-input-fields2">
                      <div className="archive-device-input-field2">
                        <div className="archive-device-input-with-label4"

                          style={{
                            maxHeight: collapsedSections.deviceUse ? '0px' : '500px',
                            overflow: 'hidden',
                            transition: 'max-height 0.3s ease-in-out',
                          }}
                        >
                          <div className="archive-device-label5">Email</div>
                          <div className="archive-device-input19">
                            <input
                              className="archive-device-content37"
                              placeholder="Search"
                              type="text"
                            />

                            <img
                              className="archive-device-help-icon1"
                              alt=""
                              src={help_icon}
                            />
                          </div>
                        </div>
                        <div className="archive-device-hint-text">
                          This is a hint text to help user.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="archive-device-second-badge-divider">
                  <img
                    className="archive-device-divider-icon22"
                    alt=""
                    src={divider13}
                  />

                  <img
                    className="archive-device-divider-icon23"
                    alt=""
                    src={divider21}
                  />
                </div>

                <div className="archive-device-section-wrapper">
                  <div className="archive-device-section-parent">
                    <div className="archive-device-actions6">
                      <div className="archive-device-navigation">
                        <img
                          onClick={() => toggleCollapse('physicalLocation')}
                          className="archive-device-search-lg"
                          src={searchlg}
                          alt=''
                          style={{ cursor: 'pointer' }}
                        />
                      </div>
                      <div className="archive-device-text210">Physical Location</div>
                    </div>
                    <div className="archive-device-input-fields2">
                      <div className="archive-device-input-field2">
                        <div className="archive-device-input-with-label4"

                          style={{
                            maxHeight: collapsedSections.physicalLocation ? '0px' : '500px',
                            overflow: 'hidden',
                            transition: 'max-height 0.3s ease-in-out',
                          }}
                        >
                          <div className="archive-device-label5">Email</div>
                          <div className="archive-device-input19">
                            <input
                              className="archive-device-content37"
                              placeholder="Search"
                              type="text"
                            />

                            <img
                              className="archive-device-help-icon1"
                              alt=""
                              src={help_icon}
                            />
                          </div>
                        </div>
                        <div className="archive-device-hint-text">
                          This is a hint text to help user.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="archive-device-second-badge-divider">


                  <img
                    className="archive-device-divider-icon23"
                    alt=""
                    src={divider21}
                  />
                </div>


              </div>
            </div>

            <div className="archive-device-frame-parent41">
              <div className="archive-device-divider-parent6">
                <img
                  className="archive-device-divider-icon35"
                  alt=""
                  src={divider13}
                />

                <div className="archive-device-footer1">
                  <div className="archive-device-content38">
                    <div className="archive-device-logo1">
                      <div className="archive-device-buttonsbutton37">
                        <img
                          className="archive-device-placeholder-icon"
                          alt=""
                          src={placeholder2}
                        />

                        <div className="archive-device-text217">Save filter</div>
                        <img
                          className="archive-device-placeholder-icon"
                          alt=""
                          src={placeholder2}
                        />
                      </div>
                    </div>
                    <div className="archive-device-actions9">
                      <button className="archive-device-buttonsbutton38">
                        <img
                          className="archive-device-placeholder-icon"
                          alt=""
                          src={placeholder}
                        />

                        <div className="archive-device-text-padding">
                          <div className="archive-device-text218">Cancel</div>
                        </div>
                        <img
                          className="archive-device-placeholder-icon"
                          alt=""
                          src={placeholder}
                        />
                      </button>
                      <button className="archive-device-buttonsbutton39">
                        <img
                          className="archive-device-placeholder-icon"
                          alt=""
                          src={placeholder3}
                        />

                        <div className="archive-device-text-padding">
                          <div className="archive-device-text219">Apply</div>
                        </div>
                        <img
                          className="archive-device-placeholder-icon"
                          alt=""
                          src={placeholder3}
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="archive-device-dot-item-innermost">
                <div className="archive-device-collapsed-content">
                  <img
                    className="archive-device-divider-icon22"
                    alt=""
                    src={divider13}
                  />

                  <img
                    className="archive-device-divider-icon37"
                    alt=""
                    src={divider21}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="archive-device1-filters">
          {/* small filter added here */}
          <div className='archive-device1-filter-selected-div'>
            <div className="archive-device1-main">
              <div className="archive-device1-header-section">
                <div className="archive-device1-container">
                  <div className="archive-device1-page-header">
                    <div className="archive-device1-breadcrumbs">
                      <div className="archive-device1-tabs">
                        <div className="archive-device1-breadcrumb-button-base">
                          <img
                            className="archive-device1-search-lg-icon"
                            alt=""
                            src={homeline}
                          />
                        </div>
                        <img
                          className="archive-device1-chevron-right-icon"
                          alt=""
                          src={chevronright}
                        />

                        <div className="archive-device1-breadcrumb-button-base1">
                          <a className="archive-device1-text7">Dashboard</a>
                        </div>
                        <img
                          className="archive-device1-chevron-right-icon"
                          alt=""
                          src={chevronright}
                        />

                        <div className="archive-device1-breadcrumb-button-base2">
                          <div className="archive-device1-text8">...</div>
                        </div>
                        <img
                          className="archive-device1-chevron-right-icon2"
                          alt=""
                          src={chevronright}
                        />

                        <div className="archive-device1-breadcrumb-button-base3">
                          <div className="archive-device1-text9">Another link</div>
                        </div>
                        <img
                          className="archive-device1-chevron-right-icon2"
                          alt=""
                          src={chevronright}
                        />

                        <div className="archive-device1-breadcrumb-button-base4">
                          <a className="archive-device1-text10">Category</a>
                        </div>
                      </div>
                    </div>
                    <img className="archive-device1-divider-icon" alt="" src={divider} />
                  </div>
                </div>
              </div>
              <div className="archive-device1-main-child"></div>
              <div className="archive-device1-main-item"></div>
            </div>
            <div className="archive-device1-secondary-content">
              <div className="archive-device1-page-header1">
                <div className="archive-device1-breadcrumbs1">
                  <div className="archive-device1-tabs1">
                    <div className="archive-device1-breadcrumb-button-base">
                      <img
                        className="archive-device1-search-lg-icon"
                        alt=""
                        src={homeline}
                      />
                    </div>
                    <img
                      className="archive-device1-chevron-right-icon"
                      alt=""
                      src={chevronright}
                    />

                    <div className="archive-device1-breadcrumb-button-base6">
                      <div className="archive-device1-text11">Settings</div>
                    </div>
                    <img
                      className="archive-device1-chevron-right-icon"
                      alt=""
                      src={chevronright}
                    />

                    <div className="archive-device1-breadcrumb-button-base7">
                      <div className="archive-device1-text8">...</div>
                    </div>
                    <img
                      className="archive-device1-chevron-right-icon"
                      alt=""
                      src={chevronright}
                    />

                    <div className="archive-device1-breadcrumb-button-base3">
                      <div className="archive-device1-text9">Another link</div>
                    </div>
                    <img
                      className="archive-device1-chevron-right-icon2"
                      alt=""
                      src={chevronright}
                    />

                    <div className="archive-device1-breadcrumb-button-base4">
                      <div className="archive-device1-menu-label">Team</div>
                    </div>
                  </div>
                </div>
                <div className="archive-device1-content4">
                  <div className="archive-device1-text-and-supporting-text">
                    <div className="archive-device1-page-title">314 Listings Found</div>
                    <div className="archive-device1-supporting-text">
                      Manage your team members and their account permissions here.
                    </div>
                  </div>
                  <div className="archive-device1-input-dropdown">
                    <div className="archive-device1-input-with-label1">
                      <div className="archive-device1-label1">Search</div>
                      <div className="archive-device1-input1">
                        <div className="archive-device1-content5">
                          <img
                            className="archive-device1-search-lg-icon"
                            alt=""
                            src={searchlg}
                          />

                          <div className="archive-device1-text15">Search</div>
                        </div>
                      </div>
                    </div>
                    <div className="archive-device1-hint-text">This is a hint text to help user.</div>
                  </div>
                </div>
                <img className="archive-device1-divider-icon1" alt="" src={divider1} />
              </div>
            </div>
            <div className="archive-device1-button-group">
              <button className="archive-device1-button-group-base">
                <a className="archive-device1-text16">View all</a>
              </button>
              <div className="archive-device1-button-group-base1">
                <div className="archive-device1-text17">Monitored</div>
              </div>
              <div className="archive-device1-button-group-base2">
                <div className="archive-device1-menu-label">Text</div>
              </div>
              <div className="archive-device1-button-group-base3">
                <div className="archive-device1-menu-label">Text</div>
              </div>
              <div className="archive-device1-button-group-base4">
                <div className="archive-device1-menu-label">Text</div>
              </div>
              <div className="archive-device1-button-group-base5">
                <div className="archive-device1-menu-label">Text</div>
              </div>
              <div className="archive-device1-button-group-base6">
                <div className="archive-device1-menu-label">Text</div>
              </div>
              <div className="archive-device1-button-group-base7">
                <div className="archive-device1-menu-label">Text</div>
              </div>
              <div className="archive-device1-button-group-base1">
                <div className="archive-device1-text24">Unmonitored</div>
              </div>

            </div>
            <div className="archive-device1-input-dropdown1">
              <div className="archive-device1-hint-text1">This is a hint text to help user.</div>
              <div className="archive-device1-buttonsbutton">
                <img className="archive-device1-sliders-02-icon" alt="" src={sliders02} />

                <div className="archive-device1-text-padding">
                  <div className="archive-device1-menu-label">Sort By : Relevance</div>
                  <img
                    className="archive-device1-sliders-02-icon"
                    alt=""
                    src={chevrondown}
                  />
                </div>
                <img
                  className="archive-device1-placeholder-icon"
                  alt=""
                  src={placeholder}
                />
              </div>
            </div>
            <img className="archive-device1-divider-icon2" alt="" src="/archive_page/divider2.svg" />

            <img className="archive-device1-divider-icon3" alt="" src="/archive_page/divider2.svg" />

            <div className="archive-device1-supporting-text1">
              You searched based on the following criteria.
            </div>

            <div className="archive-device1-buttonsbutton7">
              <img className="archive-device1-placeholder-icon" alt="" src={placeholder} />

              <a className="archive-device1-text47">All time</a>
              <img className="archive-device1-sliders-02-icon" alt="" src={xclose} />
            </div>
            <button className="archive-device1-buttonsbutton8">
              <img className="archive-device1-placeholder-icon" alt="" src={placeholder} />

              <div className="archive-device1-text48">Clinical Applications</div>
              <img className="archive-device1-sliders-02-icon" alt="" src={xclose} />
            </button>
            <div className="archive-device1-buttonsbutton9">
              <img className="archive-device1-placeholder-icon" alt="" src={placeholder} />

              <div className="archive-device1-text49">US, AU, +4</div>
              <img className="archive-device1-sliders-02-icon" alt="" src={xclose} />
            </div>
            <div className="archive-device1-buttonsbutton10">
              <img className="archive-device1-placeholder-icon" alt="" src={placeholder} />

              <div className="archive-device1-text50">All/Employee</div>
              <img className="archive-device1-sliders-02-icon" alt="" src={xclose} />
            </div>
            {/* <button className="archive-device1-buttonsbutton11">
          <img className="archive-device1-placeholder-icon" alt="" src={placeholder} />

          <div className="archive-device1-text48">Clinical Applications</div>
          <img className="archive-device1-sliders-02-icon" alt="" src={xclose} />
        </button> */}
            <div className="archive-device1-buttonsbutton12">
              <img className="archive-device1-placeholder-icon" alt="" src={placeholder} />

              <div className="archive-device1-menu-label">Year of Manufacture</div>
              <img className="archive-device1-sliders-02-icon" alt="" src={xclose} />
            </div>
            <button className="archive-device1-buttonsbutton13">
              <img className="archive-device1-placeholder-icon" alt="" src={placeholder} />

              <div className="archive-device1-text53">Warrenty</div>
              <img className="archive-device1-sliders-02-icon" alt="" src={xclose} />
            </button>
            <button className="archive-device1-buttonsbutton14">
              <img className="archive-device1-placeholder-icon" alt="" src={placeholder} />

              <div className="archive-device1-text54">Manufacturer</div>
              <img className="archive-device1-sliders-02-icon" alt="" src={xclose} />
            </button>
            <button className="archive-device1-buttonsbutton15">
              <img className="archive-device1-placeholder-icon" alt="" src={placeholder} />

              <div className="archive-device1-text55">All Applications</div>
              <img className="archive-device1-sliders-02-icon" alt="" src={xclose} />
            </button>
            <button className="archive-device1-buttonsbutton16">
              <img className="archive-device1-placeholder-icon" alt="" src={placeholder} />

              <div className="archive-device1-text56">Rent/Lease</div>
              <img className="archive-device1-sliders-02-icon" alt="" src={xclose} />
            </button>
            <button className="archive-device1-buttonsbutton17">
              <img className="archive-device1-sliders-02-icon" alt="" src={filterlines} />

              <div className="archive-device1-text57">More filters</div>
              <img className="archive-device1-placeholder-icon" alt="" src={placeholder} />
            </button>
            <button className="archive-device1-buttonsbutton18">
              <img
                className="archive-device1-sliders-02-icon"
                alt=""
                src={filterfunnel01}
              />

              <div className="archive-device1-text58">Clear Filter</div>
              <img className="archive-device1-placeholder-icon" alt="" src={placeholder} />
            </button>
            <button className="archive-device1-actions">
              <div className="archive-device1-button">
                <img
                  className="archive-device1-placeholder-icon"
                  alt=""
                  src={placeholder1}
                />

                <div className="archive-device1-text-padding1">
                  <div className="archive-device1-text59">Tertiary</div>
                </div>
                <img
                  className="archive-device1-placeholder-icon"
                  alt=""
                  src={placeholder1}
                />
              </div>
              <div className="archive-device1-button1">
                <img
                  className="archive-device1-placeholder-icon"
                  alt=""
                  src={placeholder2}
                />

                <div className="archive-device1-text-padding1">
                  <div className="archive-device1-text60">Secondary</div>
                </div>
                <img
                  className="archive-device1-placeholder-icon"
                  alt=""
                  src={placeholder2}
                />
              </div>
              <div className="archive-device1-button2">
                <img
                  className="archive-device1-sliders-02-icon"
                  alt=""
                  src={filterlines}
                />

                <div className="archive-device1-text-padding">
                  <div className="archive-device1-filter-label">Save Filter</div>
                </div>
                <img
                  className="archive-device1-placeholder-icon"
                  alt=""
                  src={placeholder3}
                />
              </div>
            </button>
          </div>
          <div className="archive-device1-listing-container archive-device-listing-container-custom">
            {/* listing layout added here */}

            <div className="archive-device1-rectangle-group">
              <div className="archive-device1-rectangle-container">
                <img
                  className="archive-device1-frame-inner"
                  alt=""
                  // src={device.featureImage}
                />

                <div className="archive-device1-dots-vertical-container">
                  <img
                    className="archive-device1-dots-vertical-icon1"
                    alt=""
                    src={dotsvertical}
                  />

                  <div className="archive-device1-text-parent">
                    {/* <Link to={`/device/${device.id}/${device.title}`} className="archive-device1-text37 ">
                  {device.title}
                </Link> */}
                    <div className="archive-device1-text37 ">
                      New / Surplus 2021 Philips Healthcare HD11 XE (P/N:23A10) for
                      abalatio...
                    </div>
                    <div className="archive-device1-frame-group">
                      <div className="archive-device1-frame-container">
                        <div className="archive-device1-frame-div">
                          <div className="archive-device1-frame-parent1">
                            <div className="archive-device1-badge-parent">
                              <div className="archive-device1-badge9">
                                <img
                                  className="archive-device1-dot-icon3"
                                  alt=""
                                  src={dot1}
                                />

                                <div className="archive-device1-price-value">Pending</div>
                              </div>
                              <div className="archive-device1-supporting-text4">ID# </div>
                            </div>
                            <div className="archive-device1-deal-type-parent">
                              <div className="archive-device1-completed">
                                <div className="archive-device1-second-value">4.9/5</div>
                              </div>
                              <div className="archive-device1-supporting-text-wrapper">
                                <div className="archive-device1-supporting-text3">99,999 reviews</div>
                              </div>
                            </div>
                            <div className="archive-device1-badge10">
                              <div className="archive-device1-third-badge-count archive-device1-text-overflow">
                                Device Category
                              </div>
                            </div>
                          </div>
                          <div className="archive-device1-deal-location-parent">
                            <div className="archive-device1-dot-item-location-parent">
                              <div className="archive-device1-navigation">
                                <img
                                  className="archive-device1-marker-pin-02-icon"
                                  alt=""
                                  src={markerpin02}
                                />
                              </div>
                              <div className="archive-device1-location">Location</div>
                            </div>
                            <div className="archive-device1-frame-wrapper1">
                              <div className="archive-device1-badge-group">
                                <div className="archive-device1-badge11">
                                  <div className="archive-device1-text33">11 Health</div>
                                </div>
                                <div className="archive-device1-badge12">
                                  <div className="archive-device1-sub-badge">Oem</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="archive-device1-card-work">
                            <div className="archive-device1-inner-work">
                              <div className="archive-device1-dot-item-type-parent">
                                <div className="archive-device1-navigation">
                                  <img
                                    className="archive-device1-marker-pin-02-icon"
                                    alt=""
                                    src={calendar}
                                  />
                                </div>
                                <div className="archive-device1-type-of-work archive-device1-text-overflow">September 2, 2024</div>
                              </div>
                            </div>
                            <div className="archive-device1-badge13">
                              <div className="archive-device1-third-badge-count">
                                Purpose use
                              </div>
                            </div>
                          </div>
                          <div className="archive-device1-badge14">
                            <div className="archive-device1-text36 archive-device1-text-overflow">Anatology</div>
                          </div>
                        </div>
                      </div>
                      <div className="archive-device1-second-price">
                        <div className="archive-device1-price">$34</div>
                        <div className="archive-device1-price-value1">
                          <div className="archive-device1-deal-title">USD - Per Unit</div>
                        </div>
                        <div className="archive-device1-inner-icons-wrapper">
                          <div className="archive-device1-inner-icons">
                            <img
                              className="archive-device1-magnifier-1-icon"
                              alt=""
                              src={radar_1_1_2x}
                            />

                            <img
                              className="archive-device1-magnifier-1-icon"
                              alt=""
                              src={deal_2_1_2x}
                            />

                            <img
                              className="archive-device1-magnifier-1-icon"
                              alt=""
                              src={magnifier_1_2x}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="archive-device1-divider-parent">
                <img className="archive-device1-divider-icon4" alt="" src={divider2} />
                <div className="archive-device1-user-listings">
                  <div className="archive-device1-frame-parent3">
                    <div className="archive-device1-frame-parent4">
                      <img
                        className="archive-device1-user-content-child"
                        alt=""
                        src={group_58_2x}
                      />

                      <div className="archive-device1-inner-info-wrapper">
                        <div className="archive-device1-inner-info">
                          <div className="archive-device1-name">Olivia Rhye</div>
                          <div className="archive-device1-role1">Business Name</div>
                        </div>
                      </div>
                    </div>
                    <div className="archive-device1-buttonsbutton-wrapper">
                      {/* <Link to={"/edit-device/" + device.id}> */}
                      <button className="archive-device1-buttonsbutton1">
                        <img className="archive-device1-eye-icon" alt="" src={eye} />

                        <div className="archive-device1-navigation">
                          <div className="archive-device1-view-label">Edit</div>
                        </div>
                      </button>
                      {/* </Link> */}

                    </div>
                    <div className="archive-device1-logo-wrapper">
                      <div className="archive-device1-buttonsbutton2">
                        <div className="archive-device1-icon-wrapper">
                          <img className="archive-device1-icon1" alt="" src={icon1} />
                        </div>
                        <div className="archive-device1-navigation">
                          <div className="archive-device1-lock-label">Delete</div>
                        </div>
                        <img className="archive-device1-eye-icon" alt="" src={lock04} />
                      </div>
                    </div>
                    <div className="archive-device1-logo-wrapper">
                      <div className="archive-device1-buttonsbutton3">
                        <div className="archive-device1-icon-wrapper">
                          <img className="archive-device1-icon1" alt="" src={icon1} />
                        </div>
                        <div className="archive-device1-navigation">
                          <div className="archive-device1-lock-label">Sales IQ</div>
                        </div>
                        <img
                          className="archive-device1-eye-icon"
                          alt=""
                          src={lockunlocked04}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="archive-device1-arrow-square-up-right-wrapper">
                    <img
                      className="archive-device1-frame-child1"
                      alt=""
                      src={arrowsquareupright}
                    />
                  </div>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
      <img className="archive-device1-divider-icon6" alt="" src={divider4} />

      <img className="archive-device1-divider-icon7 archive-device1-divider-icon7-cstm" alt="" src={divider4} />





      <FooterAddForms />




    </div>


  )
}

