

// import React, { useState, useEffect, useRef, useCallback } from 'react';
// import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';

// const libraries = ['marker'];

// const MapLocationViewComponent = ({ width, height, zoom, initialCoordinates }) => {
//     const mapStyles = {
//         height: height,
//         width: width,
//     };

//     const defaultCenter = {
//         lat: 0,
//         lng: 0,
//     };

//     const [selectedLocation, setSelectedLocation] = useState(null);
//     const mapRef = useRef(null);
//     const markerRef = useRef(null);

//     const { isLoaded, loadError } = useJsApiLoader({
//         googleMapsApiKey: "AIzaSyDW_qBPEy1VCPIiP9b6yvJXL0vg6iLlgTQ",
//         libraries: libraries,
//     });

//     const onMapLoad = useCallback((map) => {
//         mapRef.current = map;
//         // If we have initial coordinates, create the marker here
//         if (initialCoordinates && initialCoordinates.lat && initialCoordinates.lng) {
//             createOrUpdateMarker(initialCoordinates);
//         }
//     }, [initialCoordinates]);

//     const createOrUpdateMarker = useCallback((location) => {
//         const { google } = window;
//         if (google && google.maps && google.maps.marker && mapRef.current) {
//             if (markerRef.current) {
//                 markerRef.current.map = null;
//             }
//             markerRef.current = new google.maps.marker.AdvancedMarkerElement({
//                 position: location,
//                 map: mapRef.current,
//             });
//             mapRef.current.panTo(location);
//         }
//     }, []);

//     useEffect(() => {
//         if (initialCoordinates && initialCoordinates.lat && initialCoordinates.lng) {
//             setSelectedLocation(initialCoordinates);
//             // if (setSelectedMapCoordinates) {
//             //     setSelectedMapCoordinates(initialCoordinates);
//             // }
//             if (mapRef.current) {
//                 createOrUpdateMarker(initialCoordinates);
//             }
//         }
//     }, [initialCoordinates, createOrUpdateMarker]);

//     const handleMapClick = useCallback((e) => {
//         const lat = e.latLng.lat();
//         const lng = e.latLng.lng();
//         const newLocation = { lat, lng };
//         setSelectedLocation(newLocation);
//         createOrUpdateMarker(newLocation);
//         // if (setSelectedMapCoordinates) {
//         //     setSelectedMapCoordinates(newLocation);
//         // }
//         console.log('Latitude:', lat, 'Longitude:', lng);
//     }, [createOrUpdateMarker]);

//     if (loadError) {
//         return <div>Error loading maps</div>;
//     }

//     if (!isLoaded) {
//         return <div>Loading maps</div>;
//     }

//     return (
//         <GoogleMap
//             mapContainerStyle={mapStyles}
//             zoom={zoom || 12}
//             center={selectedLocation || defaultCenter}
//             onLoad={onMapLoad}
//             onClick={handleMapClick}
//             options={{
//                 mapId: "fea14720c452d711",
//                 streetViewControl: false,
//                 mapTypeControl: false,
//             }}
//         />
//     );
// };

// export default MapLocationViewComponent;
import React, { useEffect, useRef, useCallback, useMemo } from 'react';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';

const libraries = ['marker'];

const MapLocationViewComponent = ({ width, height, initialCoordinates }) => {
    const mapStyles = {
        height: height,
        width: width,
    };

    const defaultCenter = {
        lat: 0,
        lng: 0,
    };

    const mapRef = useRef(null);
    const markerRef = useRef(null);

    const { isLoaded, loadError } = useJsApiLoader({
        googleMapsApiKey: "AIzaSyDW_qBPEy1VCPIiP9b6yvJXL0vg6iLlgTQ",
        libraries: libraries,
    });

    const getCoordinatesFromInput = useCallback((input) => {
        if (input && typeof input === 'object') {
            if (input.type === 'Point' && Array.isArray(input.coordinates)) {
                const [longitude, latitude] = input.coordinates;
                return { lat: latitude, lng: longitude };
            } else if ('lat' in input && 'lng' in input) {
                return { lat: input.lat, lng: input.lng };
            }
        }
        return null;
    }, []);

    const coordinates = useMemo(() => getCoordinatesFromInput(initialCoordinates), [initialCoordinates, getCoordinatesFromInput]);

    const onMapLoad = useCallback((map) => {
        mapRef.current = map;
        if (coordinates) {
            createMarker(coordinates);
            adjustMapView(coordinates);
        }
    }, [coordinates]);

    const createMarker = useCallback((location) => {
        const { google } = window;
        if (google && google.maps && google.maps.marker && mapRef.current) {
            if (markerRef.current) {
                markerRef.current.map = null;
            }
            markerRef.current = new google.maps.marker.AdvancedMarkerElement({
                position: location,
                map: mapRef.current,
            });
        }
    }, []);

    const adjustMapView = useCallback((location) => {
        if (mapRef.current) {
            mapRef.current.setCenter(location);
            mapRef.current.setZoom(15); // Adjust this value for desired initial zoom level
        }
    }, []);

    useEffect(() => {
        if (coordinates && mapRef.current) {
            createMarker(coordinates);
            adjustMapView(coordinates);
        }
    }, [coordinates, createMarker, adjustMapView]);

    if (loadError) {
        return <div>Error loading maps</div>;
    }

    if (!isLoaded) {
        return <div>Loading maps</div>;
    }

    return (
        <GoogleMap
            mapContainerStyle={mapStyles}
            zoom={15} // This sets the initial zoom, but it will be adjusted by adjustMapView
            center={coordinates || defaultCenter}
            onLoad={onMapLoad}
            options={{
                mapId: "fea14720c452d711",
                streetViewControl: false,
                mapTypeControl: false,
                clickableIcons: false,
                draggableCursor: 'default',
                zoomControl: true,
                fullscreenControl: false
            }}
        />
    );
};

export default MapLocationViewComponent;