import AccountSetupEmail from "../screens/AccountSetup/Email/AccountCheckEmail";
import { AccountEmailVerified } from "../screens/AccountSetup/Email/AccountEmailVerified";
import { AccountSubscription } from "../screens/AccountSetup/Plan/AccountSubscription";
import { AccountUpdateProfile } from "../screens/AccountSetup/Profile/AccountUpdateProfile";


export const accountSetupRoutes = [
  {
    path: "/account-setup-check-email",
    element: <AccountSetupEmail />,
  },
  {
    path: "/account-setup-email-verified",
    element: <AccountEmailVerified />,
  },
  {
    path: "/account-setup-subscription-plan",
    element: <AccountSubscription />,
  },
  {
    path: "/account-setup-update-profile",
    element: <AccountUpdateProfile />,
  }
];
