import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./ChatPageComponent.css";

import {  getCustomizedDate } from "../../helper/ChatHelper";

import icon1 from "../../icons/Gerator/chat_page/icon-1.svg";
import divider1 from "../../icons/Gerator/chat_page/divider1.svg";
import rectangle57 from "../../icons/Gerator/chat_page/rectangle-57@2x.png";
import dot1 from "../../icons/Gerator/chat_page/-dot1.svg";
import markerpin02 from "../../icons/Gerator/chat_page/markerpin02.svg";
import calendar from "../../icons/Gerator/chat_page/calendar.svg";
import dotsvertical from "../../icons/Gerator/chat_page/dotsvertical.svg";
import arrowup from "../../icons/Gerator/chat_page/arrowup.svg";
import lock04 from "../../icons/Gerator/chat_page/lock04.svg";
import eye from "../../icons/Gerator/chat_page/eye.svg";
import lockunlocked04 from "../../icons/Gerator/chat_page/lockunlocked04.svg";
import chartmin from "../../icons/Gerator/chat_page/-chart-mini@2x.png"
import radar1 from  "../../icons/Gerator/chat_page/radar-1-1@2x.png"
import deal2 from  "../../icons/Gerator/chat_page/deal-2-1@2x.png"
import magnifier from  "../../icons/Gerator/chat_page/magnifier-1@2x.png"
import arrowsquareupright from "../../icons/Gerator/chat_page/arrowsquareupright.svg";
import messagechatsquare from "../../icons/Gerator/chat_page/messagechatsquare.svg";


import { useLazyFetchCountOfRadarDeviceQuery } from "../../api/Radar/RadarDeviceAPI";



// Temporary
import { Link } from "react-router-dom";
import dotsvertical from '../../icons/Gerator/sales_iq/dotsvertical.svg';
import calendar1 from '../../icons/Gerator/sales_iq/calendar1.svg';
import content_elements_2x from '../../icons/Gerator/sales_iq/content-elements@2x.png';
import dot1 from '../../icons/Gerator/sales_iq/-dot1.svg';

import _chart_mini2 from "../../icons/Gerator/sales_iq/-chart-mini2@2x.png"
import arrowup from "../../icons/Gerator/sales_iq/arrowup.svg";

import radar_1_1_2x from '../../icons/Gerator/sales_iq/radar-1-1@2x.png';
import deal_2_1_2x from '../../icons/Gerator/sales_iq/deal-2-1@2x.png';
import magnifier_1_2x from '../../icons/Gerator/sales_iq/magnifier-1@2x.png';
import content_elements_2x from '../../icons/Gerator/sales_iq/content-elements@2x.png';
import eye from '../../icons/Gerator/sales_iq/eye.svg';
import icon1 from '../../icons/Gerator/sales_iq/icon1.svg';
import lock04 from '../../icons/Gerator/sales_iq/lock04.svg';
import lockunlocked04 from '../../icons/Gerator/sales_iq/lockunlocked04.svg';
import arrowsquareupright from '../../icons/Gerator/sales_iq/arrowsquareupright.svg';
import messagechatsquare from '../../icons/Gerator/sales_iq/messagechatsquare.svg';
import markerpin02 from '../../icons/Gerator/sales_iq/markerpin02.svg';
import divider2 from '../../icons/Gerator/sales_iq/divider2.svg';
import arrowup1 from '../../icons/Gerator/sales_iq/arrowup1.svg';
import arrowdown1 from '../../icons/Gerator/sales_iq/arrowdown1.svg';
import placeholder2 from '../../icons/Gerator/sales_iq/placeholder2.svg';
import group_58_2x from '../../icons/Gerator/sales_iq/group-58@2x.png';
import sliders02 from '../../icons/Gerator/sales_iq/sliders02.svg';
import playstation from "../../icons/Gerator/sales_iq/playstation.jpeg";
import { PostStatusLIstingComponent } from '../../components/PostStatusLIstingComponent/PostStatusLIstingComponent';
import { TransactionTypeComponent } from '../../components/TransactionTypeComponent/TransactionTypeComponent';



const Analytics = ({ deviceData, onLoad}) => {

  useEffect(() => {
    if (deviceData ) {

      console.log('YES DEVICE SUCCESS in ANALYTICS', deviceData);
      //.log('YES CHATPAGEB', deviceData?.profileImage);
    }
  }, [deviceData]);

  useEffect(() => {
    if (onLoad) {

      console.log('YES RADAR DEVICE onLoad SUCCESS in ANALYTICS');
      onLoad();

      //.log('YES CHATPAGEB', deviceData?.profileImage);
    }
  }, [onLoad]);


  if (deviceData.data) {return (
      <div className="analytics-listing-container">
        <div className="analytics-sales-iq-content-items" >
          <div className="analytics-sales-iq-divider-container1">
            <div className="analytics-sales-iq-rectangle-parent5">
              {/*<img*/}
              {/*    className="analytics-sales-iq-frame-child7"*/}
              {/*    alt=""*/}
              {/*    src={ deviceData?.featureImage}*/}
              {/*/>*/}

              <div className="analytics-sales-iq-frame-parent1">
                <div className="analytics-sales-iq-action-fields">
                  <div className="analytics-sales-iq-input-elements">
                    { deviceData?.data?.title}

                    <div className="analytics-sales-iq-frame-parent21">
                      <div className="analytics-sales-iq-badge-item-parent">
                        <div className="analytics-sales-iq-badge-parent">

                          {/* <PostStatusLIstingComponent postStatus={ deviceData?.tax_post_status.name} postSlug={ deviceData?.tax_post_status.slug} postId={ deviceData?.id} /> */}
                          <PostStatusLIstingComponent postStatus={"Pending"} postSlug={"pending"} postId={1} />

                        </div>
                        <div className="analytics-sales-iq-frame-parent4">
                          <div className="analytics-sales-iq-end-label">
                            <div className="analytics-sales-iq-search-icon-input">4.9/5</div>
                          </div>
                          <div className="analytics-sales-iq-type-support">
                            <div className="analytics-sales-iq-supporting-text9">
                              99,999 reviews
                            </div>
                          </div>
                        </div>

                      </div>
                      <div className="analytics-sales-iq-frame-parent24">
                        <div className="analytics-sales-iq-location-pin-parent">
                          <div className="analytics-sales-iq-search-input">
                            <img
                                className="analytics-sales-iq-marker-pin-02-icon2"
                                alt=""
                                src={markerpin02}
                            />
                          </div>
                          <div className="analytics-sales-iq-location3"> {deviceData?.data?.wanted_device_address?.user_usermeta?.userTown  || deviceData?.data?.wanted_spare_address?.user_usermeta?.userTown  || deviceData?.data?.wanted_software_address?.user_usermeta?.userTown || deviceData?.data?.wanted_service_address?.user_usermeta?.userTown}, {deviceData?.data?.wanted_device_address?.user_usermeta?.tax_country?.name || deviceData?.data?.wanted_spare_address?.user_usermeta?.tax_country?.name  || deviceData?.data?.wanted_software_address?.user_usermeta?.tax_country?.name   || deviceData?.data?.wabted_service_address?.user_usermeta?.tax_country?.name } </div> {/*<div className="sales-iq-role">{deviceData?.data?.wanted_device_business.businessName || deviceData?.data?.wanted_sspare_business.businessName }</div>*/}
                          {/* <div className="analytics-sales-iq-location3">Oklahoma City , United States of America</div> */}
                        </div>

                      </div>
                      <div className="analytics-sales-iq-frame-parent26">
                        <div className="analytics-sales-iq-frame-wrapper19">
                          <div className="analytics-sales-iq-legend-series">
                            <div className="analytics-sales-iq-search-input">
                              <img
                                  className="analytics-sales-iq-marker-pin-02-icon2"
                                  alt=""
                                  src={calendar1}
                              />
                            </div>
                            <div className="analytics-sales-iq-type-of-work2">{getCustomizedDate( deviceData?.data?.createdAt)}</div>
                            {/* <div className="analytics-sales-iq-type-of-work2">November 13,2024</div> */}
                          </div>
                        </div>

                      </div>

                    </div>
                  </div>

                  <div className="analytics-sales-iq-small-card">

                    <div className="analytics-sales-iq-badge33">
                      <div className="analytics-sales-iq-third-badge-number">
                        { deviceData?.data?.tax_device_category.name}
                      </div>
                    </div>

                  

                    <div className="analytics-sales-iq-badge37">
                      <div className="analytics-sales-iq-third-badge-number">{ deviceData?.data?.tax_oem.name}</div>
                      {/* <div className="analytics-sales-iq-third-badge-number">
                                12TH MAN
                              </div> */}
                    </div>

                    <div className="analytics-sales-iq-badge38">
                      <div className="analytics-sales-iq-third-badge-number">
                        { deviceData?.data?.tax_clinical_application.name}
                        {/* Pathology */}
                      </div>
                    </div>


                  </div>

                </div>


                <div className="analytics-sales-iq-filter-elements">
                  {/* <div className="analytics-sales-iq-dropdown10" onClick={() => toggleThreeDotsDropdown()} ref={threeDotsRef}>
                            <div className="analytics-sales-iq-dropdown10" >

                              <img
                                className="analytics-sales-iq-dots-vertical-icon"
                                alt=""
                                src={dotsvertical}
                              />
                              {activeThreeDot && <ThreeDotsListingComponent url={`device/${ deviceData?.id}`} />}
                            </div>
                          </div> */}
                  <div className="analytics-sales-iq-filter-elements-inner">
                    <div className="analytics-sales-iq-price-parent">
                      {/* <TransactionTypeComponent transactionType={ deviceData?.tax_transaction_type.name} slug={ deviceData?.tax_transaction_type.slug} /> */}
                      <TransactionTypeComponent transactionType={"Sale"} slug={"sale"} />

                      {/* <div className="analytics-sales-iq-price2">${formatNumber( deviceData?.price)}</div> */}
                      <div className="analytics-sales-iq-price2">$258</div>

                      <div className="analytics-sales-iq-dropdown-elements">
                        <div className="analytics-sales-iq-dropdown-options-parent">
                          {/* <div className="analytics-sales-iq-text83">{currentListingData.tax_unit_measure_device_spare.name}</div> */}
                          <div className="analytics-sales-iq-text83">USD - Based on terms of exhange</div>

                          <div className="analytics-sales-iq-frame-wrapper3">
                            <div className="analytics-sales-iq-radar-1-1-container">
                              <img
                                  className="analytics-sales-iq-magnifier-1-icon"
                                  alt=""
                                  src={radar_1_1_2x}
                              />

                              <img
                                  className="analytics-sales-iq-magnifier-1-icon"
                                  alt=""
                                  src={deal_2_1_2x}
                              />

                              <img
                                  className="analytics-sales-iq-magnifier-1-icon"
                                  alt=""
                                  src={magnifier_1_2x}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


              </div>
            </div>

            {/*<img className="analytics-sales-iq-divider-icon8" alt="" src={divider2} />*/}
{/*//METRICS*/}

            {/*<div className="analytics-sales-iq-metric-item-parent">*/}

            {/*  <div className="analytics-sales-iq-metric-item3">*/}
            {/*    <div className="analytics-sales-iq-heading9">Active engagement</div>*/}
            {/*    <div className="analytics-sales-iq-number-and-chart">*/}
            {/*      <div className="analytics-sales-iq-number-and-badge3">*/}
            {/*        /!* <div className="analytics-sales-iq-heading">{currentListingData.totalActiveEngagement || 0}</div> *!/*/}
            {/*        <div className="analytics-sales-iq-heading">5</div>*/}

            {/*        <div className="analytics-sales-iq-change-and-text3">*/}
            {/*          <div className="analytics-sales-iq-change2">*/}
            {/*            <img*/}
            {/*                className="analytics-sales-iq-upload-04-icon"*/}
            {/*                alt=""*/}
            {/*                src={arrowup}*/}
            {/*            />*/}

            {/*            <div className="analytics-sales-iq-change7">100%</div>*/}
            {/*          </div>*/}
            {/*          <div className="analytics-sales-iq-text139">vs last month</div>*/}
            {/*        </div>*/}
            {/*      </div>*/}
            {/*      <img*/}
            {/*          className="analytics-sales-iq-chart-mini-icon3"*/}
            {/*          alt=""*/}
            {/*          src={_chart_mini2}*/}

            {/*      />*/}
            {/*    </div>*/}
            {/*    <div className="analytics-sales-iq-dropdown19">*/}
            {/*      <img*/}
            {/*          className="analytics-sales-iq-dots-vertical-icon"*/}
            {/*          alt=""*/}
            {/*          src={dotsvertical}*/}
            {/*      />*/}
            {/*    </div>*/}
            {/*  </div>*/}

            {/*  <div className="analytics-sales-iq-metric-item3">*/}
            {/*    <div className="analytics-sales-iq-heading9">Views</div>*/}
            {/*    <div className="analytics-sales-iq-number-and-chart">*/}
            {/*      <div className="analytics-sales-iq-number-and-badge3">*/}
            {/*        /!* <div className="analytics-sales-iq-heading">{currentListingData.totalCountView}</div> *!/*/}
            {/*        <div className="analytics-sales-iq-heading">{deviceData?.viewsCount}</div>*/}

            {/*        <div className="analytics-sales-iq-change-and-text3">*/}
            {/*          <div className="analytics-sales-iq-change2">*/}
            {/*            <img*/}
            {/*                className="analytics-sales-iq-upload-04-icon"*/}
            {/*                alt=""*/}
            {/*                src={arrowup}*/}
            {/*            />*/}

            {/*            <div className="analytics-sales-iq-change7">100%</div>*/}
            {/*          </div>*/}
            {/*          <div className="analytics-sales-iq-text139">vs last month</div>*/}
            {/*        </div>*/}
            {/*      </div>*/}
            {/*      <img*/}
            {/*          className="analytics-sales-iq-chart-mini-icon3"*/}
            {/*          alt=""*/}
            {/*          src={_chart_mini2}*/}

            {/*      />*/}
            {/*    </div>*/}
            {/*    <div className="analytics-sales-iq-dropdown19">*/}
            {/*      <img*/}
            {/*          className="analytics-sales-iq-dots-vertical-icon"*/}
            {/*          alt=""*/}
            {/*          src={dotsvertical}*/}
            {/*      />*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*  <div className="analytics-sales-iq-metric-item3">*/}
            {/*    <div className="analytics-sales-iq-heading9">Added to Radar</div>*/}
            {/*    <div className="analytics-sales-iq-number-and-chart">*/}
            {/*      <div className="analytics-sales-iq-number-and-badge3">*/}
            {/*        /!* <div className="analytics-sales-iq-heading">{currentListingData.totalCountRadar}</div> *!/*/}
            {/*        <div className="analytics-sales-iq-heading">{deviceData?.radarCount}</div>*/}

            {/*        <div className="analytics-sales-iq-change-and-text3">*/}
            {/*          <div className="analytics-sales-iq-change2">*/}
            {/*            <img*/}
            {/*                className="analytics-sales-iq-upload-04-icon"*/}
            {/*                alt=""*/}
            {/*                src={arrowup}*/}
            {/*            />*/}

            {/*            <div className="analytics-sales-iq-change7">100%</div>*/}
            {/*          </div>*/}
            {/*          <div className="analytics-sales-iq-text139">vs last month</div>*/}
            {/*        </div>*/}
            {/*      </div>*/}
            {/*      <img*/}
            {/*          className="analytics-sales-iq-chart-mini-icon3"*/}
            {/*          alt=""*/}
            {/*          src={_chart_mini2}*/}
            {/*      />*/}
            {/*    </div>*/}
            {/*    <div className="analytics-sales-iq-dropdown19">*/}
            {/*      <img*/}
            {/*          className="analytics-sales-iq-dots-vertical-icon"*/}
            {/*          alt=""*/}
            {/*          src={dotsvertical}*/}
            {/*      />*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*  <div className="analytics-sales-iq-metric-item3">*/}
            {/*    <div className="analytics-sales-iq-heading9">Searches</div>*/}
            {/*    <div className="analytics-sales-iq-number-and-chart">*/}
            {/*      <div className="analytics-sales-iq-number-and-badge3">*/}
            {/*        /!* <div className="analytics-sales-iq-heading">{currentListingData.totalSearch || 0}</div> *!/*/}
            {/*        <div className="analytics-sales-iq-heading">1</div>*/}

            {/*        <div className="analytics-sales-iq-change-and-text3">*/}
            {/*          <div className="analytics-sales-iq-change2">*/}
            {/*            <img*/}
            {/*                className="analytics-sales-iq-upload-04-icon"*/}
            {/*                alt=""*/}
            {/*                src={arrowup}*/}
            {/*            />*/}

            {/*            <div className="analytics-sales-iq-change7">100%</div>*/}
            {/*          </div>*/}
            {/*          <div className="analytics-sales-iq-text139">vs last month</div>*/}
            {/*        </div>*/}
            {/*      </div>*/}
            {/*      <img*/}
            {/*          className="analytics-sales-iq-chart-mini-icon3"*/}
            {/*          alt=""*/}
            {/*          src={_chart_mini2}*/}
            {/*      />*/}
            {/*    </div>*/}
            {/*    <div className="analytics-sales-iq-dropdown19">*/}
            {/*      <img*/}
            {/*          className="analytics-sales-iq-dots-vertical-icon"*/}
            {/*          alt=""*/}
            {/*          src={dotsvertical}*/}
            {/*      />*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}


            <img className="analytics-metric-card-chat-module-divider-icon8" alt="" src={divider2} />
         
         <div className="analytics-metric-card-chat-module-metric-item-parent">

           <div className="analytics-metric-card-chat-module-metric-item3">
             <div className="analytics-metric-card-chat-module-heading9">Active engagement</div>
             <div className="analytics-metric-card-chat-module-number-and-chart">
               <div className="analytics-metric-card-chat-module-number-and-badge3">
                 {/* <div className="analytics-metric-card-chat-module-heading">{currentListingData.totalActiveEngagement || 0}</div> */}
                 <div className="analytics-metric-card-chat-module-heading">6</div>

                 <div className="analytics-metric-card-chat-module-change-and-text3">
                   <div className="analytics-metric-card-chat-module-change2">
                     <img
                       className="analytics-metric-card-chat-module-upload-04-icon"
                       alt=""
                       src={arrowup}
                     />

                     <div className="analytics-metric-card-chat-module-change7">100%</div>
                   </div>
                   <div className="analytics-metric-card-chat-module-text139">vs last month</div>
                 </div>
               </div>
               <img
                 className="analytics-metric-card-chat-module-chart-mini-icon3"
                 alt=""
                 src={_chart_mini2}

               />
             </div>
             {/* <div className="analytics-metric-card-chat-module-dropdown19"  style={{cursor:"pointer"}} onClick={() => toggleThreeDotsDropdownNoAction(0)} ref={(el) => threeDotsRefNoAction.current[0] = el}> */}
             <div className="analytics-metric-card-chat-module-dropdown19"  >

               <img
                 className="analytics-metric-card-chat-module-dots-vertical-icon"
                 alt=""
                 src={dotsvertical}
               />
                                            {/* {activeThreeDotIdNoAction === 0  && <ThreeDotsNoActionComponent  />} */}
               
             </div>
           </div>

           <div className="analytics-metric-card-chat-module-metric-item3">
             <div className="analytics-metric-card-chat-module-heading9">Views</div>
             <div className="analytics-metric-card-chat-module-number-and-chart">
               <div className="analytics-metric-card-chat-module-number-and-badge3">
                 {/* <div className="analytics-metric-card-chat-module-heading">{currentListingData.totalCountView}</div> */}
                 <div className="analytics-metric-card-chat-module-heading">{deviceData?.data?.viewsCount}</div>
                 <div className="analytics-metric-card-chat-module-change-and-text3">
                   <div className="analytics-metric-card-chat-module-change2">
                     <img
                       className="analytics-metric-card-chat-module-upload-04-icon"
                       alt=""
                       src={arrowup}
                     />

                     <div className="analytics-metric-card-chat-module-change7">100%</div>
                   </div>
                   <div className="analytics-metric-card-chat-module-text139">vs last month</div>
                 </div>
               </div>
               <img
                 className="analytics-metric-card-chat-module-chart-mini-icon3"
                 alt=""
                 src={_chart_mini2}

               />
             </div>
             {/* <div className="analytics-metric-card-chat-module-dropdown19"  style={{cursor:"pointer"}} onClick={() => toggleThreeDotsDropdownNoAction(1)} ref={(el) => threeDotsRefNoAction.current[1] = el}> */}
             <div className="analytics-metric-card-chat-module-dropdown19"  >

               <img
                 className="analytics-metric-card-chat-module-dots-vertical-icon"
                 alt=""
                 src={dotsvertical}
               />
                                            {/* {activeThreeDotIdNoAction === 1  && <ThreeDotsNoActionComponent  />} */}
               
             </div>
           </div>
           <div className="analytics-metric-card-chat-module-metric-item3">
             <div className="analytics-metric-card-chat-module-heading9">Added to Radar</div>
             <div className="analytics-metric-card-chat-module-number-and-chart">
               <div className="analytics-metric-card-chat-module-number-and-badge3">
                 {/* <div className="analytics-metric-card-chat-module-heading">{currentListingData.totalCountRadar}</div> */}
                 <div className="analytics-metric-card-chat-module-heading">{deviceData.data.radarCount}</div>
                 <div className="analytics-metric-card-chat-module-change-and-text3">
                   <div className="analytics-metric-card-chat-module-change2">
                     <img
                       className="analytics-metric-card-chat-module-upload-04-icon"
                       alt=""
                       src={arrowup}
                     />

                     <div className="analytics-metric-card-chat-module-change7">100%</div>
                   </div>
                   <div className="analytics-metric-card-chat-module-text139">vs last month</div>
                 </div>
               </div>
               <img
                 className="analytics-metric-card-chat-module-chart-mini-icon3"
                 alt=""
                 src={_chart_mini2}
               />
             </div>
             {/* <div className="analytics-metric-card-chat-module-dropdown19"  style={{cursor:"pointer"}} onClick={() => toggleThreeDotsDropdownNoAction(2)} ref={(el) => threeDotsRefNoAction.current[2] = el}> */}
             <div className="analytics-metric-card-chat-module-dropdown19"  >

               <img
                 className="analytics-metric-card-chat-module-dots-vertical-icon"
                 alt=""
                 src={dotsvertical}
               />
                                            {/* {activeThreeDotIdNoAction === 2  && <ThreeDotsNoActionComponent  />} */}
               
             </div>
           </div>
           <div className="analytics-metric-card-chat-module-metric-item3">
             <div className="analytics-metric-card-chat-module-heading9">Searches</div>
             <div className="analytics-metric-card-chat-module-number-and-chart">
               <div className="analytics-metric-card-chat-module-number-and-badge3">
                 {/* <div className="analytics-metric-card-chat-module-heading">{currentListingData.totalSearch || 0}</div> */}
                 <div className="analytics-metric-card-chat-module-heading">0</div>

                 <div className="analytics-metric-card-chat-module-change-and-text3">
                   <div className="analytics-metric-card-chat-module-change2">
                     <img
                       className="analytics-metric-card-chat-module-upload-04-icon"
                       alt=""
                       src={arrowup}
                     />

                     <div className="analytics-metric-card-chat-module-change7">100%</div>
                   </div>
                   <div className="analytics-metric-card-chat-module-text139">vs last month</div>
                 </div>
               </div>
               <img
                 className="analytics-metric-card-chat-module-chart-mini-icon3"
                 alt=""
                 src={_chart_mini2}
               />
             </div>
             {/* <div className="analytics-metric-card-chat-module-dropdown19"  style={{cursor:"pointer"}} onClick={() => toggleThreeDotsDropdownNoAction(3)} ref={(el) => threeDotsRefNoAction.current[3] = el}> */}
             <div className="analytics-metric-card-chat-module-dropdown19" >

               <img
                 className="analytics-metric-card-chat-module-dots-vertical-icon"
                 alt=""
                 src={dotsvertical}
               />
                                            {/* {activeThreeDotIdNoAction === 3 && <ThreeDotsNoActionComponent  />} */}
               
             </div>
           </div>
         </div>

            <img className="analytics-sales-iq-divider-icon8" alt="" src={divider2} />


            <div className="analytics-sales-iq-frame-parent71-owner">
              <div className="analytics-sales-iq-buttonsbutton-parent1">
                <div className="sales-iq-legend-series">
                  <img
                      className="sales-iq-location-column-child"
                      alt=""
                      // src={currentListingData.profileImage}
                      src={deviceData?.data?.profileImage}
                  />

                  <div className="sales-iq-location-column-inner">
                    <div className="sales-iq-date-picker-dropdown">
                      <div className="sales-iq-name">{deviceData?.data?.wanted_device_user.displayName || deviceData?.data?.wanted_spare_user.displayName || deviceData?.data?.wanted_service_user.displayName || deviceData?.data?.wanted_software_user.displayName }</div>
                      {/*<div className="sales-iq-role">{deviceData?.data?.wanted_device_business.businessName || deviceData?.data?.wanted_sspare_business.businessName }</div>*/}

                      {/* <div className="sales-iq-name">{currentListingData.device_business.business_user.displayName}</div>
                              <Link className="sales-iq-role" to={`/business/${currentListingData.device_business.id}/${currentListingData.device_business.businessName}`}>
                                {currentListingData.device_business.businessName}
                              </Link> */}

                      {/* <div className="sales-iq-name">Test Account5</div>
                              <div className="sales-iq-role" >
                               Medico
                              </div> */}
                    </div>
                  </div>
                </div>
                <div className='analytics-main-buttons-group'>
                  <div className="analytics-sales-iq-buttonsbutton13">
                    <img
                        className="analytics-sales-iq-arrow-narrow-left-icon"
                        alt=""
                        src={eye}
                    />

                    <div className="analytics-sales-iq-search-input">
                      <div className="analytics-sales-iq-text67">View</div>
                    </div>
                  </div>
                  {/* <div className="analytics-sales-iq-buttonsbutton25">
                    <div className="analytics-sales-iq-icon-wrapper">
                      <img className="analytics-sales-iq-icon1" alt="" src={icon1} />
                    </div>
                    <div className="analytics-sales-iq-search-input">
                      <div className="analytics-sales-iq-text68">Sales IQ</div>
                    </div>
                    <img
                      className="analytics-sales-iq-arrow-narrow-left-icon"
                      alt=""
                      src={lock04}
                    />
                  </div> */}

                  <Link to={"/sales-iq"}>
                    <div className="analytics-sales-iq-buttonsbutton26">
                      <div className="analytics-sales-iq-icon-wrapper">
                        <img className="analytics-sales-iq-icon1" alt="" src={icon1} />
                      </div>
                      <div className="analytics-sales-iq-search-input">
                        <div className="analytics-sales-iq-text68">Sales IQ</div>
                      </div>
                      <img
                          className="analytics-sales-iq-arrow-narrow-left-icon"
                          alt=""
                          src={lockunlocked04}
                      />
                    </div>
                  </Link>
                </div>

              </div>
              <div className="analytics-sales-iq-arrow-square-up-right-wrapper1">
                <img
                    className="analytics-sales-iq-arrow-square-up-right-icon1"
                    alt=""
                    src={arrowsquareupright}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
  );
  }
}
export {Analytics};




