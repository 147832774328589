import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    deviceCategory:'',
    oem:'',
    modelName:'',
    statusCondition:'',
    ageOfDevice:'',
    availability:'',
    clinicalApplications:'',
    purposeUse:'',
    physicalLocation:'',
}

const editWantedDeviceSlice = createSlice({
  name: "editWantedDevice",
  initialState,
  reducers: {
    fillEditWantedDeviceForm(state, action) {
     return action.payload;
    },
    updateEditWantedDeviceField(state, action) {
      const {field, value} = action.payload
      if (value !== undefined) { 
        state[field] = value; 
      }
    },
    clearEditWantedDeviceForm() {
      return initialState;
    }
  },
});

export const {fillEditWantedDeviceForm, updateEditWantedDeviceField,clearEditWantedDeviceForm } = editWantedDeviceSlice.actions

export default editWantedDeviceSlice.reducer

