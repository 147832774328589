import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import "../../Sidebar/Sidebar.css";
import arrownarrowleft from '../../../icons/Gerator/dashboard/arrownarrowleft.svg';
import activity from '../../../icons/Gerator/dashboard/activity.svg';
import chevrondown from '../../../icons/Gerator/dashboard/chevrondown.svg';
import notificationbox from '../../../icons/Gerator/dashboard/notificationbox.svg';
import barlinechart from '../../../icons/Gerator/dashboard/barlinechart.svg';
import star01 from '../../../icons/Gerator/dashboard/star01.svg';
import clockfastforward from '../../../icons/Gerator/dashboard/clockfastforward.svg';
import usersquare from '../../../icons/Gerator/dashboard/usersquare.svg';
import settings03 from '../../../icons/Gerator/dashboard/settings03.svg';
import logout01 from '../../../icons/Gerator/dashboard/logout01.svg';
// import xclose from '../../icons/AGerator/dashboard/xclose.svg';
import { Link } from "react-router-dom";
import { useEffect } from "react";

import pluscircle_gray from '../../../icons/Gerator/dashboard/pluscircle_gray.svg';
import address from "../../../icons/Gerator/dashboard/address.svg";
import business from "../../../icons/Gerator/dashboard/business.svg";
import plan from "../../../icons/Gerator/dashboard/plan.svg";
import funnel from "../../../icons/Gerator/dashboard/funnel.svg";
import demand_center from "../../../icons/Gerator/dashboard/demand_center.svg";
import beacon from "../../../icons/Gerator/dashboard/beacon.svg";
import radar2 from "../../../icons/Gerator/dashboard/radar2.svg"
import { Radar } from "lucide-react";

export const SideBarSubRadar = ({ userData, isSubSideBarVisible, setSubSideBarVisible }) => {

  // const [userData, setUserData] = useState({ displayName: "Demo", email: "demo@gerator.com" })

  // useEffect(() => {
  //   if (!userData) {
  //     setUser({ displayName: "Demo", email: "demo@gerator.com" })
  //   }
  // },)

  const location = useLocation(); // Get the current path


  return (
    <div className="sidebar-sub-nav">
      <div className="sidebar-content5">
        <div className="sidebar-sub-nav-header">
          <div className="sidebar-sub-nav-title"><Link to={"/dashboard"}>Dashboard</Link></div>
          <img
            className="sidebar-arrow-narrow-left-icon" onClick={() => setSubSideBarVisible(!isSubSideBarVisible)}
            alt=""
            src={arrownarrowleft}
          />
        </div>
        <div className="sidebar-navigation4">
{/* 
        {subTab === "radar" && (

        <div className="sidebar-nav-item-base4">
            <Link to={"/beacon"} 
            onClick={() => handleSubTabClick("beacon")}
            style={{color:"#475467"}}>

                
            <div className="sidebar-content6">
              <img className="sidebar-dot-icon" alt="" src="/dashboard/-dot.svg" />

              <img
                className="sidebar-arrow-narrow-left-icon"
                alt=""
                src={beacon}
              />

              <div className="sidebar-text6">Beacon</div>
            </div>
            </Link>
          
          </div>
        )} */}


        {/* {subTab === "beacon" && (

          <div className="sidebar-nav-item-base4">
            <Link to={"/radar"} 
              onClick={() => handleSubTabClick("radar")}
            style={{color:"#475467"}}>
            <div className="sidebar-content6">
              <img className="sidebar-dot-icon" alt="" src="/dashboard/-dot.svg" />

              <img
                className="sidebar-arrow-narrow-left-icon"
                alt=""
                src={radar2}
              />

              <div className="sidebar-text6">Radar</div>
            </div>
            </Link>
          
          </div>
        )} */}
                    <div
                          className={`sidebar-nav-item-base4 ${
                            location.pathname === "/radar" ? "sidebar-active-tab" : ""
                          }`}
                        >
                    <Link to={"/radar"} style={{color:"#475467"}}>
                    <div className="sidebar-content6">
                      <img className="sidebar-dot-icon" alt="" src="/dashboard/-dot.svg" />
        
                      <img
                        className="sidebar-arrow-narrow-left-icon"
                        alt=""
                        src={radar2}
                      />
        
                      <div className="sidebar-text6">Radar</div>
                    </div>
                    </Link>
                    {/* <div className="sidebar-badge1">
                      <div className="sidebar-text1">10</div>
                    </div>
                    <img
                      className="sidebar-chevron-down-icon"
                      alt=""
                      src={chevrondown}
                    /> */}
                  </div>
        
        
                  <div
                      className={`sidebar-nav-item-base4 ${
                        location.pathname === "/beacon" ? "sidebar-active-tab" : ""
                      }`}
                    >
                    <Link to={"/beacon"} style={{color:"#475467"}}>
                    <div className="sidebar-content6">
                      <img className="sidebar-dot-icon" alt="" src="/dashboard/-dot.svg" />
        
                      <img
                        className="sidebar-arrow-narrow-left-icon"
                        alt=""
                        src={beacon}
                      />
        
                      <div className="sidebar-text6">Beacon</div>
                    </div>
                    </Link>
                    {/* <div className="sidebar-badge1">
                      <div className="sidebar-text1">10</div>
                    </div>
                    <img
                      className="sidebar-chevron-down-icon"
                      alt=""
                      src={chevrondown}
                    /> */}
                  </div>

          <div className="sidebar-nav-item-base4">
            <Link to={"/add-wanted-device"} style={{color:"#475467"}}>
            <div className="sidebar-content6">
              <img className="sidebar-dot-icon" alt="" src="/dashboard/-dot.svg" />

              <img
                className="sidebar-arrow-narrow-left-icon"
                alt=""
                src={pluscircle_gray}
              />

              <div className="sidebar-text6">Add Wanted Device</div>
            </div>
            </Link>
            {/* <div className="sidebar-badge1">
              <div className="sidebar-text1">10</div>
            </div>
            <img
              className="sidebar-chevron-down-icon"
              alt=""
              src={chevrondown}
            /> */}
          </div>
          <div className="sidebar-nav-item-base4">
          <Link to={"/add-wanted-spare"} style={{color:"#475467"}}>
            <div className="sidebar-content7">
              <img className="sidebar-dot-icon" alt="" src="/dashboard/-dot.svg" />

              <img
                className="sidebar-arrow-narrow-left-icon"
                alt=""
                src={pluscircle_gray}
              />

              <div className="sidebar-text8">Add Wanted Spare</div>
            </div>
            </Link>
            {/* <div className="sidebar-badge4">
              <div className="sidebar-badge-count">10</div>
            </div>
            <img
              className="sidebar-chevron-down-icon"
              alt=""
              src={chevrondown}
            /> */}
          </div>
          <div className="sidebar-nav-item-base4">
          <Link to={"/add-wanted-service"} style={{color:"#475467"}}>
            <div className="sidebar-content8">
              <img className="sidebar-dot-icon" alt="" src="/dashboard/-dot.svg" />

              <img
                className="sidebar-arrow-narrow-left-icon"
                alt=""
                src={pluscircle_gray}
              />

              <div className="sidebar-text8">Add Wanted Service</div>
            </div>
            </Link>
            {/* <div className="sidebar-badge">
              <div className="sidebar-text1">10</div>
            </div>
            <img
              className="sidebar-chevron-down-icon"
              alt=""
              src={chevrondown}
            /> */}
          </div>
          <div className="sidebar-nav-item-base4">
          <Link to={"/add-wanted-software"} style={{color:"#475467"}}>
            <div className="sidebar-content8">
              <img className="sidebar-dot-icon" alt="" src="/dashboard/-dot.svg" />

              <img
                className="sidebar-arrow-narrow-left-icon"
                alt=""
                src={pluscircle_gray}
              />

              <div className="sidebar-text8">Add Wanted Software</div>
            </div>
            </Link>
            {/* <div className="sidebar-badge">
              <div className="sidebar-text1">10</div>
            </div>
            <img
              className="sidebar-chevron-down-icon"
              alt=""
              src={chevrondown}
            /> */}
          </div>
          
          <div className="sidebar-nav-item-base4">
          <Link to={"/dashboard/settings/business"} style={{color:"#475467"}}>
            <div className="sidebar-content8">
              <img className="sidebar-dot-icon" alt="" src="/dashboard/-dot.svg" />

              <img
                className="sidebar-arrow-narrow-left-icon"
                alt=""
                src={business}
              />

              <div className="sidebar-text8">Business</div>
            </div>
            </Link>
            {/* <div className="sidebar-badge">
              <div className="sidebar-text1">10</div>
            </div>
            <img
              className="sidebar-chevron-down-icon"
              alt=""
              src={chevrondown}
            /> */}
          </div>
          <div className="sidebar-nav-item-base4">
          <Link to={"/dashboard/settings/address"} style={{color:"#475467"}}>
            <div className="sidebar-content12">
              <img className="sidebar-dot-icon" alt="" src="/dashboard/-dot.svg" />

              <img
                className="sidebar-arrow-narrow-left-icon"
                alt=""
                src={address}
              />

              <div className="sidebar-text17">Address</div>
            </div>
            </Link>
            {/* <div className="sidebar-badge">
              <div className="sidebar-text1">10</div>
            </div>
            <img
              className="sidebar-chevron-down-icon"
              alt=""
              src={chevrondown}
            /> */}
          </div>
              

          <div className="sidebar-nav-item-base4">
          <Link to={"/dashboard/settings/plan"} style={{color:"#475467"}}>
            <div className="sidebar-content12">
              <img className="sidebar-dot-icon" alt="" src="/dashboard/-dot.svg" />

              <img
                className="sidebar-arrow-narrow-left-icon"
                alt=""
                src={plan}
              />

              <div className="sidebar-text17">Upgrade Plan</div>
            </div>
            </Link>
            {/* <div className="sidebar-badge">
              <div className="sidebar-text1">10</div>
            </div>
            <img
              className="sidebar-chevron-down-icon"
              alt=""
              src={chevrondown}
            /> */}
          </div>
        </div>

      </div>
      <div className="sidebar-account">

        
        <Link to={"/dashboard/settings/profile"}>
        <div className="sidebar-price-parent">
          <div className="sidebar-title">{userData?.displayName ? userData.displayName : "Demo User"}</div>
          <div className="sidebar-supporting-text">{userData?.email ? userData.email : "demo@gerator.com"}</div>
        </div>
        </Link>
      
        <Link to={"/dashboard/settings/profile"}>
        <div className="sidebar-buttonsbutton">
          <img
            className="sidebar-search-lg-icon"
            alt=""
            src={logout01}
          />
        </div>
        </Link>
      </div>
    </div>
  )
}