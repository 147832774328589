import React from 'react';
import "./AddJobStep.css"
import divider1 from '../../icons/Gerator/add_form/divider1.svg';
import chevronleft from '../../icons/Gerator/add_form/chevronleft.svg';
import featured_icon from '../../icons/Gerator/add_form/featured-icon.svg';
import placeholder1 from '../../icons/Gerator/add_form/placeholder1.svg';
import placeholder2 from '../../icons/Gerator/add_form/placeholder2.svg';
import chevrondown_2 from '../../icons/Gerator/add_form/chevrondown-2.svg';
import placeholder3 from '../../icons/Gerator/add_form/placeholder3.svg';
import placeholder from '../../icons/Gerator/add_form/placeholder.svg';
import page from '../../icons/Gerator/add_form/page.svg';
import checkbox_base from '../../icons/Gerator/add_form/-checkbox-base.svg';
import eye from '../../icons/Gerator/add_form/eye.svg';
import login04 from '../../icons/Gerator/add_form/login04.svg';
import React, { useContext, useRef, useEffect, useState } from "react";
import AddJobFormContext from "../../screens/Job/AddJob/AddJobContext";
import { Field, useField, useFormikContext } from "formik";
// import { FileTypeDefaultImageTypeSolid } from "../../icons/FileTypeDefaultImageTypeSolid";
import { updateAppDataJob } from "./AppDataJobSlice";
import { useDispatch, useSelector } from "react-redux";
import { updateField } from "./AddJobSlice";

const MySelect = ({ validate, label, ...props }) => {
  const [field, meta] = useField(props);

  const inputStyle = meta.touched && meta.error ? { borderColor: 'red' } : {};   //New code for border colour

  let fieldValidationData = null;
  // console.log("MySelect inside validation result");
  // console.log(validate);

  if (validate?.length > 0) {
    validate.some(field => { // Use 'some' for early termination
      if (field.path === props.name) {
        fieldValidationData = field;
        return true; // This will stop the loop early
      }
      return false; // Continue if no match
    });
  }


  return (
    <>
      <div className="add-job-step-first-upload-inner">
        <div className="add-job-step-label3">
          <label className="add-job-step-transaction-type" htmlFor={props.name}>
            {label} <span className="add-job-step-span">*</span>
          </label>
        </div>
        <div className="add-job-step-input1">
          <div className="add-job-step-content8">
            <div className="add-job-step-text-input">
              <select
                className="add-job-step-first-nested-input account-update-profile-content-custom-23"
                style={inputStyle}   //New code for border colour
                {...field}
                {...props}
              />
            </div>
          </div>

        </div>
      </div>






      {(meta.touched && meta.error) ? (
        <div className="error-for-all-field">{meta.error}</div>
      ) : null}

      {fieldValidationData !== null ? (
        <div className="error-for-all-field">{fieldValidationData.msg}</div>
      ) : null}
    </>
  );
};

const DocumentFileUpload = ({ validate, ...props }) => {
  const [field, meta] = useField(props);
  let fieldValidationData = "";
  if (validate?.length > 0) {
    validate.some(field => { // Use 'some' for early termination
      if (field.path === "documentFileObject") {
        fieldValidationData = field;
        return true; // This will stop the loop early
      }
      return false; // Continue if no match
    });
  }

  return (

    <div className='document-field-component-width'>
      <div className="add-service-step3-label58 ">
        Documents
      </div>
      {/* <div className="add-service-step3-label58"></div> */}
      <div className="add-service-step3-file-upload-base5">
        <label htmlFor="document">

          <div className="add-service-step3-content69">
            <img

              className="add-service-step3-featured-icon5"
              loading="lazy"
              alt=""
              src={featured_icon}
            />

            <div className="add-service-step3-text-and-supporting-text13" htmlFor="document">
              <div className="add-service-step3-action5">
                <div className="add-service-step3-buttonsbutton16">
                  {/* <img 
                  className="add-service-step3-placeholder-icon60"
                  alt=""
                  src={placeholder1}
                /> */}
                  <div className="add-service-step3-text88 documentLabel">Click to upload</div>
                  <input type="file" accept=".pdf" {...field} {...props} className='hide-input-field' />
                </div>
              </div>
              <div className="action custom-action">

                <div className="add-service-step3-text89">or drag and drop</div>
              </div>
              <div className="add-service-step3-supporting-text28">
                <span>Please upload files having extension </span>
                <span className="add-service-step3-pdf1">PDF</span>
                <span> only (Maximum file size.10 MB)</span>
              </div>
            </div>
          </div>
        </label>
        {(meta.touched && meta.error) ? (
          <div className="error-for-all-field error-for-document">{meta.error}</div>
        ) : null}

        {fieldValidationData !== "" ? (
          <div className="error-for-all-field error-for-document">{meta.error}</div>
        ) : null}
      </div>


    </div>
  );
};

export const AddJobStep2 = () => {

  const dispatch = useDispatch();
  const formData = useSelector((state) => state.addJob);
  const appData = useSelector((state) => state.appDataAddJob);
  const { backendValidation, documentCheckboxError, setDocumentCheckboxError, selectedDocumentFile, setSelectedDocumentFile, allTaxonomy } = useContext(AddJobFormContext);
  // const [fileDoceLinkRemover, setFileDocLinkRemover] = useState(false);
  // ------------ Formik Methods to manage form flow ---------------------
  const { values, setFieldValue, setErrors, setFieldTouched, errors, touched, handleSubmit, isSubmitting } = useFormikContext();

  // ------------ These refs used to manage radio field state ---------------------
  const inputYoutubeRef = useRef(null);
  const inputVimeoRef = useRef(null);
  /* 
       This useEffect will provide updated values from formik and run some conditions Used on FeatureImage
   */
  useEffect(() => {
    console.log("Values useEffect Open");

    if (touched.document) {
      if (errors.document && values.document !== "") {
        console.log("Values Error UseEffect");
        handleSelectedDocumentFile("error");
        return; // Exit useEffect to avoid further actions if there are errors
      }

      // 2. Proceed with Updates if No Errors:
      if (touched.document && values.document != "") {
        console.log("Values Touch UseEffect");
        // setGalleryImageFile([...values.gallery]);
        handleSelectedDocumentFile("no-error");
      }
    }
    // 1. Check for Errors FIRST:

    console.log("Values useEffect Closed ");
    // }

  }, [values.document, errors.document]);

  //onClick Listener on input radio tag(video/youtube)
  useEffect(() => {
    if (inputYoutubeRef.current) {
      inputYoutubeRef.current.addEventListener('click', handleClickYoutube);
    }
    return () => { // Add a cleanup function
      if (inputYoutubeRef.current) {
        inputYoutubeRef.current.removeEventListener('click', handleClickYoutube);
      }
    }
  }, []);

  //onClick Listener on input radio tag(video/vimeo)
  useEffect(() => {
    if (inputVimeoRef.current) {
      inputVimeoRef.current.addEventListener('click', handleClickVimeo);
    }
    return () => { // Add a cleanup function
      if (inputVimeoRef.current) {
        inputVimeoRef.current.removeEventListener('click', handleClickVimeo);
      }
    }
  }, []);


  //Click method for Youtube radio
  const handleClickYoutube = (e) => {
    // Reset Vimeo when Youtube is clicked
    setFieldValue("videoType", "Youtube")
    dispatch(updateAppDataJob({ case: "VIDEO", field: "isVimeoChecked", value: false }))
    dispatch(updateAppDataJob({ case: "VIDEO", field: "isYoutubeChecked", value: true }))
    dispatch(updateAppDataJob({ case: "VIDEO", field: "myfieldinsideradio", value: true }))
  };

  //Click method for Vimeo radio
  const handleClickVimeo = (e) => {
    // Reset Youtube when Vimeo is clicked
    setFieldValue("videoType", "Vimeo")
    dispatch(updateAppDataJob({ case: "VIDEO", field: "isYoutubeChecked", value: false }))
    dispatch(updateAppDataJob({ case: "VIDEO", field: "isVimeoChecked", value: true }))
    dispatch(updateAppDataJob({ case: "VIDEO", field: "myfieldinsideradio", value: true }))
  };



  const prev = () => dispatch(updateAppDataJob({ case: "CURRENTSTEP", value: (appData.currentStep - 1) }));





  const handleCheckbox = async () => {

    //If errors.document has some errors then filter then set document to "", clear other field as well
    if (errors.document) {
      console.log(errors.document);
      const filteredErrors = Object.keys(errors).reduce((acc, fieldName) => {
        // Filter based on your condition (replace with your actual logic)
        if (fieldName !== 'document') {
          acc[fieldName] = errors[fieldName];
        }
        return acc;
      }, {});
      console.log(filteredErrors);
      await setFieldValue("document", '');
      setErrors(filteredErrors)
      setSelectedDocumentFile("")
      setDocumentCheckboxError('');
    } else {
      await setFieldValue("document", '');
      setSelectedDocumentFile("")
      setDocumentCheckboxError('');
    }


  }

  const handleSelectedDocumentFile = (type) => {
    console.log("HandleSelectedImageFile Called " + type);

    setFieldTouched("document", true); // Always touch the field for validation

    console.log(errors);

    // 3. Additional Error Check:
    if (errors.document) {
      console.log("Inside errors.gallery is true means error found ");
      setSelectedDocumentFile(values.document);
      setDocumentCheckboxError(errors.document);
      return; // Don't proceed with upload if there's an error
    }

    // 4. Proceed with Upload if No Errors:
    console.log("Inside !errors.gallery is true means no error found");
    console.log("File added into select multi image hook");
    setSelectedDocumentFile(values.document);
    setDocumentCheckboxError('');
    // setFileDocLinkRemover(true);
  }

  const handleChange = (event) => {
    const { name, value } = event.target;
    console.log("called");
    if (name === "document") {
      const pdf = event.target.files[0]
      console.log(pdf);
      setFieldValue(name, pdf);
      setFieldTouched(name, true)
      return;
    }
    setFieldValue(name, value);
    dispatch(updateField({ field: name, value }));
  };


  return (

    //  < form className="add-job-step-section-header-parent">

    <>

      <div className="add-job-step-section-header">

        <div className="add-job-step-content7">Highlights</div>



        <img
          className="add-job-step-divider-icon2"
          alt=""
          src={divider1}
        />
      </div>

      <div className="add-job-step-input-tags">

        <div className="add-job-step-section-header">

          <div className="add-job-step-content7">Job Location</div>



        </div>
        <div className="add-job-step-input-field13">
          <MySelect label="Location" name="location" id="location" onChange={handleChange} validate={backendValidation} value={formData.location}>
            <option value="0">Select From Dropdown List </option>
            {(allTaxonomy.relatedAddress.isLoading || allTaxonomy.relatedAddress.isError) && <option value="0">Loading...</option>}
            {
              (allTaxonomy.relatedAddress.isSuccess) && (allTaxonomy.relatedAddress.data.data.map(tax => (
                <option key={tax.id} value={tax.id}>{tax.addressTitle}{tax.fullAddress ? ` : ${tax.fullAddress}` : ""}</option>
              )))
            }
          </MySelect>
          <div className="add-job-step-hint-text21">
            <span className="add-job-step-cant-find-an"
            >Cant find an address?
            </span>
            <span className="add-job-step-add-it">Add it.</span>
          </div>
        </div>
      </div>


      <div className="add-job-step-single-input-parent">
        <div className="add-job-step-first-upload-container">

          <div className="add-job-step-tagged-inputs">
            <div className="add-job-step-input-field12">
              <div className="add-job-step-input-with-label19">
                <div className="add-job-step-label21">
                  <span className="add-job-step-transaction-type">Video </span>
                </div>
              </div>
              <div className="add-job-step-file-button-container">
                <div className="add-job-step-buttonsbutton4">
                  {/* <img
                    className="add-job-step-search-lg-icon"
                    alt=""
                    src={placeholder}
                  /> */}

                  <div className="add-job-step-text-padding6">
                    <div className="add-job-step-file-button-labels">YouTube
                      <Field type="radio" id="youtube" name="videoType" value="Youtube" innerRef={inputYoutubeRef} checked={appData.isYoutubeChecked} />
                    </div>
                  </div>
                </div>
                <div className="add-job-step-buttonsbutton4">
                  {/* <img
                    className="add-job-step-search-lg-icon"
                    alt=""
                    src={placeholder}
                  /> */}

                  <div className="add-job-step-text-padding6">
                    <div className="add-job-step-text48">Vimeo
                      <Field type="radio" id='vimeo' name="videoType" value="Vimeo" innerRef={inputVimeoRef} checked={appData.isVimeoChecked} />
                    </div>
                  </div>
                </div>
              </div>
              {appData.myfieldinsideradio && (
                <Field type="text" name="linkVideo" onChange={handleChange} validate={backendValidation} value={formData.linkVideo} className="text-input text-25 content-15 myinputfield" />
              )}
            </div>
          </div>

        </div>

      </div>


      <div className="add-service-step3-file-upload">
        <DocumentFileUpload key={selectedDocumentFile ? selectedDocumentFile.name : Date.now()} name="document" validate={backendValidation} id="document" onChange={handleChange} value=
          {undefined} />


        {
          (typeof selectedDocumentFile === 'object' && selectedDocumentFile !== "") &&

          (
            <div className="add-service-step3-file-upload-item-base3">
              <div className="add-service-step3-file-type-icon-parent">
                <div className="add-service-step3-file-type-icon3">
                  <img
                    className="add-service-step3-page-icon3"
                    loading="lazy"
                    alt=""
                    src={page}
                  />

                  <div className="add-service-step3-file-type-wrap3">
                    <b className="add-service-step3-file-type3">PDF</b>
                  </div>
                </div>
                <div className="add-service-step3-frame-parent7">
                  <div className="add-service-step3-text-parent1">
                    <div className="add-service-step3-text90">{selectedDocumentFile.name}</div>
                    <div className="add-service-step3-checkbox3">
                      {documentCheckboxError ?
                        <Field type="checkbox" onClick={handleCheckbox} name="imageDocCheckbox" checked className="checkbox-instance custom-checkbox"></Field> :
                        <Field type="checkbox" onClick={handleCheckbox} name="imageDocCheckbox" checked className="checkbox-instance checkbox-base size-30-sm state-8-default checked-true type-checkbox checkbox-2"></Field>
                      }
                    </div>
                  </div>
                  <div className="add-service-step3-supporting-text29">{(selectedDocumentFile.size / 1000).toFixed(1) + " KB"}</div>
                </div>
              </div>
              <div className="add-service-step3-progress-bar8">
                <div className="add-service-step3-progress-bar-wrapper">
                  <div className="add-service-step3-progress-bar9">
                    <div className="add-service-step3-background3"></div>
                    <div className="add-service-step3-progress3"></div>
                  </div>
                </div>
                <div className="add-service-step3-percentage3">100%</div>
              </div>
            </div>
          )
        }
      </div>

      <div className="add-job-step-section-footer1">
        <div className="add-job-step-section-footer2">
          <img
            className="add-job-step-divider-icon2"
            loading="lazy"
            alt=""
            src={divider1}
          />

          <div className="add-job-step-content31 ">

            <div className="add-job-step-actions3 add-job-step-actions3-custom">

              <button className="add-job-step-button11" onClick={prev}>
                <img
                  className="add-job-step-chevron-down-icon"
                  alt=""
                  src={placeholder2}
                />

                <img
                  className="add-job-step-home-line-icon"
                  alt=""
                  src={chevronleft}
                />

                <div className="add-job-step-text-padding6">
                  <div className="add-job-step-text53">Back</div>
                </div>
                <img
                  className="add-job-step-chevron-down-icon"
                  alt=""
                  src={placeholder2}
                />
              </button>
              <button className="button22">
                <img
                  className="placeholder-icon66"
                  alt=""
                  src={placeholder3}
                />

                <img
                  className="chevron-left-icon3"
                  alt=""
                  src={eye}
                />

                <div className="text-padding25">
                  <a className="text94">Preview</a>
                </div>
                <img
                  className="placeholder-icon66"
                  alt=""
                  src={placeholder3}
                />
              </button>
              <button className="add-job-step-button9" type="button" onClick={handleSubmit} disabled={isSubmitting}>
                <img
                  className="add-job-step-chevron-down-icon"
                  alt=""
                  src={placeholder3}
                />

                <img
                  className="add-job-step-home-line-icon"
                  alt=""
                  src={login04}
                />

                <div className="add-job-step-text-padding6">
                  <div className="add-job-step-text44">{isSubmitting ? 'Submitting...' : 'Save & Submit'}</div>
                </div>
                <img
                  className="add-job-step-chevron-down-icon"
                  alt=""
                  src={placeholder3}
                />
              </button>
            </div>
          </div>
        </div>
      </div>

    </>
    //  </form>


  )
}

