import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  addressTitle: '',
  addressBuilding: '',
  addressStreet: '',
  addressState: '',
  addressTownCity: '',
  addressDistrict: '',
  addressPostcodeZip: '',
  addressCountry: '',
  addressType: '1',
  addressLocation: {},
}

const addressRemote = {
  addressTitle: 'Remote Address',
  addressBuilding: 'remote',
  addressStreet: 'remote',
  addressState: 'remote',
  addressTownCity: 'remote',
  addressDistrict: 'remote',
  addressPostcodeZip: 'remote',
  addressCountry: 'abc',
  addressType: '2',
  addressLocation: { lat: 0, lng: 0 },
};

const AddAddressSlice = createSlice({
  name: "addAddress",
  initialState,
  reducers: {
    updateAddressField(state, action) {
      const { field, value } = action.payload
      if (value !== undefined) {
        state[field] = value;
      }
    },
    updateRemoteField() {
      return addressRemote;
    },
    clearAddressForm() {
      return initialState;
    }
  },
});

export const { updateAddressField, updateRemoteField, clearAddressForm } = AddAddressSlice.actions

export default AddAddressSlice.reducer

