import React, { useEffect, useRef, useState } from 'react';
import "./AdminDashboardWantedDeviceComponent.css"
import dotsvertical from '../../../../../icons/Gerator/dashboard/dotsvertical.svg';
import calendar1 from '../../../../../icons/Gerator/dashboard/calendar1.svg';
import content_elements_2x from '../../../../../icons/Gerator/dashboard/content-elements@2x.png';
import dot1 from '../../../../../icons/Gerator/dashboard/-dot1.svg';
import radar_1_1_2x from '../../../../../icons/Gerator/dashboard/radar-1-1@2x.png';
import deal_2_1_2x from '../../../../../icons/Gerator/dashboard/deal-2-1@2x.png';
import magnifier_1_2x from '../../../../../icons/Gerator/dashboard/magnifier-1@2x.png';
import content_elements_2x from '../../../../../icons/Gerator/dashboard/content-elements@2x.png';
import eye from '../../../../../icons/Gerator/dashboard/eye.svg';
import icon1 from '../../../../../icons/Gerator/dashboard/icon1.svg';
import lock04 from '../../../../../icons/Gerator/dashboard/lock04.svg';
import lockunlocked04 from '../../../../../icons/Gerator/dashboard/lockunlocked04.svg';
import arrowsquareupright from '../../../../../icons/Gerator/dashboard/arrowsquareupright.svg';
import messagechatsquare from '../../../../../icons/Gerator/dashboard/messagechatsquare.svg';
import markerpin02 from '../../../../../icons/Gerator/dashboard/markerpin02.svg';
import divider2 from '../../../../../icons/Gerator/dashboard/divider2.svg';
import arrowup1 from '../../../../../icons/Gerator/dashboard/arrowup1.svg';
import arrowdown1 from '../../../../../icons/Gerator/dashboard/arrowdown1.svg';
import placeholder2 from '../../../../../icons/Gerator/dashboard/placeholder2.svg';
import group_58_2x from '../../../../../icons/Gerator/dashboard/group-58@2x.png';
import sliders02 from '../../../../../icons/Gerator/dashboard/sliders02.svg';
import { ThreeDotsListingComponent } from '../../../../ThreeDotsListingComponent/ThreeDotsListingComponent';
import { Link } from 'react-router-dom';
import { NoListingFound } from '../../../../NoListing/NoListingFound';
import SkeletonListingLoaderComponent from '../../../../SkeletonListingLoaderComponent/SkeletonListingLoaderComponent';
import { getCustomizedDate } from '../../../../../helper/DeviceHelper';
import SearchInputListingComponent from '../../../../SearchInputListingComponent/SearchInputListingComponent';

import { useGetAdminDashboardWantedDevicesQuery } from '../../../../../api/admin/AdminWantedDeviceAPI';
import RadarFilterButtonGroup from '../../../../RadarFilterButtonGroup/RadarFilterButtonGroup';
import { SortByFilterComponent } from '../../../../SortByFilterComponent/SortByFilterComponent';
import { ThreeDotsAdminWantedListingComponent } from '../../../../ThreeDotsListingComponent/ThreeDotsAdminWantedListingComponent';
import { ThreeDotsNoActionComponent } from '../../../../ThreeDotsListingComponent/ThreeDotsNoActionComponent';


// export const AdminDashboardWantedDeviceComponent = () => {



//   //Offset to load data when click load more
//   const [offset, setOffset] = useState(0);
//   const [sortBy, setSortBy] = useState('date_desc');
//   const [searchTerm, setSearchTerm] = useState('');
//   const [totalCount, setTotalCount] = useState("");
//   const [totalCountPublished, settotalCountPublished] = useState("");
//   const [totalCountPending, settotalCountPending] = useState("");
//   const [totalCountArchived, settotalCountArchived] = useState("");
//   const [totalCountExpired, settotalCountExpired] = useState("");

//   const [activePostStatus, setActivePostStatusFilter] = useState(null);

//   //Query to get data
//   const { data, isSuccess, isError, refetch, isLoading, isFetching } = useGetUserDashboardDevicesQuery(
//     { offset, sortBy, postStatus: activePostStatus, search: searchTerm },
//     {
//       // Refetch when component mounts or tab becomes active
//       refetchOnMountOrArgChange: true
//     }
//   );

//   //flag var to show listing when loading complete when sekelteon layout is going to stop
//   const [loadingComplete, setLoadingComplete] = useState(false);

//   //Var is reponsible to show listings and other thing as well
//   let content;

//   //Sort by text show on button
//   const [selectedSortOption, setSelectedSortOption] = useState('');

//   //For SortBy Dropdown
//   const [isDropdownVisible, setDropdownVisible] = useState(false);
//   const dropdownRef = useRef(null);

//   //For Threedots in listing dropdown
//   const [activeThreeDotId, setActiveThreeDotId] = useState(null);
//   const threeDotsRef = useRef(null);
//   const [isClickBlocked, setIsClickBlocked] = useState(false);



//   const toggleDropdown = () => {
//     setDropdownVisible(!isDropdownVisible);
//   }

//   const toggleThreeDotsDropdown = (id) => {
//     if (isClickBlocked) return; // Prevent rapid toggle

//     if (activeThreeDotId === id) {
//       setActiveThreeDotId(null); // Close if the same one is clicked again
//       setIsClickBlocked(true);
//       setTimeout(() => setIsClickBlocked(false), 400); // Small delay to avoid reopening
//     } else {
//       setActiveThreeDotId(id); // Open the clicked one
//     }
//   };

//   const handleSearch = (term) => {
//     setSearchTerm(term);
//   };


//   //Set value for sortby selected option on button text
//   const handleOptionSelect = (option) => {
//     if (option.value !== sortBy) {
//       setSortBy(option.value);
//       setOffset(0); // Reset offset when sort option changes
//     }
//     setSelectedSortOption(option);
//     setDropdownVisible(false);
//   };

//   //DashbaordFilterButtonGroup component to get data
//   const handleFilterClick = (filterLabel) => {
//     if (activePostStatus === filterLabel) {
//       setSortBy('date_desc')
//       setSelectedSortOption('')
//       setActivePostStatusFilter(null);
//     } else {
//       setSortBy('date_desc')
//       setSelectedSortOption('')
//       setActivePostStatusFilter(filterLabel);
//     }
//     setOffset(0); // Reset offset when changing filters
//   };

//   //Setting offset for loadmore when user clicked on load more button(this trigger query to fetch new data)
//   // Reset offset when component mounts or remounts
//   // useEffect(() => {
//   //   setOffset(0);
//   // }, []);
//   // // Reset offset when sort option changes
//   // useEffect(() => {
//   //   setOffset(0);
//   // }, [sortBy]);

//   const handleLoadMore = () => {
//     if (data?.hasMore && !isFetching) {
//       setOffset(currentOffset => currentOffset + 5);
//     }
//   };

//   const showLoadMore = data?.hasMore &&
//     data.devices.length < data.totalCount &&
//     !isFetching;





//   //Onclick listener for sortby dropdown
//   useEffect(() => {
//     const handleClickOutside = (event) => {
//       if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
//         setDropdownVisible(false);
//       }
//     };

//     document.addEventListener("mousedown", handleClickOutside);
//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, []);


//   //Onclick listener for threedots dropdown
//   useEffect(() => {
//     const handleThreeDotClickOutside = (event) => {

//       const isLink = event.target.closest('a');

//       if (isLink) {
//         return; // Allow navigation to happen
//       }

//       if (threeDotsRef.current && !threeDotsRef.current.contains(event.target)) {
//         setActiveThreeDotId(null); // Close any open dropdown when clicking outside
//       }
//     };

//     document.addEventListener("mousedown", handleThreeDotClickOutside);
//     return () => {
//       document.removeEventListener("mousedown", handleThreeDotClickOutside);
//     };
//   }, []);



//   // Listen for tab visibility changes
//   useEffect(() => {
//     const handleVisibilityChange = () => {
//       if (document.visibilityState === 'visible') {
//         refetch();
//       }
//     };

//     document.addEventListener('visibilitychange', handleVisibilityChange);
//     return () => {
//       document.removeEventListener('visibilitychange', handleVisibilityChange);
//     };
//   }, [refetch]);


//   //Success true then pause to show sekelton layout
//   useEffect(() => {
//     if (isSuccess) {
//       // Set totalcount in listing counter layout
//       setTotalCount(data.totalCount);
//       settotalCountPublished(data.totalCountPublished);
//       settotalCountArchived(data.totalCountArchived);
//       settotalCountPending(data.totalCountPending);
//       settotalCountExpired(data.totalCountExpired);
//       // Set timeout to delay the loading completion
//       const timer = setTimeout(() => {
//         setLoadingComplete(true);
//       }, 1000); // 1 second

//       // Cleanup timer on component unmount
//       return () => clearTimeout(timer);
//     }
//   }, [isSuccess]);


//   if (isError) {
//     content = <NoListingFound path={"/add-device"} pathName={"Device"} />
//   }

//   if (isLoading) {
//     content = <SkeletonListingLoaderComponent />;
//   } else if (loadingComplete && isSuccess) {

//     if (data.devices.length > 0) {
//       // dataLength = data.devices.length;
//       content = data.devices.map((device, index) =>
//       (
//         <div className="admin-dashboard-wanted-service-component-dashboard-content-items" key={index}>
//           <div className="admin-dashboard-wanted-service-component-dashboard-divider-container1">
//             <div className="admin-dashboard-wanted-service-component-dashboard-rectangle-parent5">
//               <img
//                 className="admin-dashboard-wanted-service-component-dashboard-frame-child7"
//                 alt=""
//                 src={device.featureImage}
//               />

//               <div className="admin-dashboard-wanted-service-component-dashboard-frame-parent1">
//                 <div className="admin-dashboard-wanted-service-component-dashboard-action-fields">
//                   <div className="admin-dashboard-wanted-service-component-dashboard-input-elements">
//                     <Link to={`/device/${device.id}/${device.title}`} className="admin-dashboard-wanted-service-component-dashboard-text78">
//                       {device.title}
//                     </Link>
//                     <div className="admin-dashboard-wanted-service-component-dashboard-frame-parent21">
//                       <div className="admin-dashboard-wanted-service-component-dashboard-badge-item-parent">
//                         <div className="admin-dashboard-wanted-service-component-dashboard-badge-parent">

//                           <PostStatusLIstingComponent postStatus={device.tax_post_status.name} postSlug={device.tax_post_status.slug} postId={device.id} />

//                         </div>
//                         <div className="admin-dashboard-wanted-service-component-dashboard-frame-parent4">
//                           <div className="admin-dashboard-wanted-service-component-dashboard-end-label">
//                             <div className="admin-dashboard-wanted-service-component-dashboard-search-icon-input">4.9/5</div>
//                           </div>
//                           <div className="admin-dashboard-wanted-service-component-dashboard-type-support">
//                             <div className="admin-dashboard-wanted-service-component-dashboard-supporting-text9">
//                               99,999 reviews
//                             </div>
//                           </div>
//                         </div>

//                       </div>
//                       <div className="admin-dashboard-wanted-service-component-dashboard-frame-parent24">
//                         <div className="admin-dashboard-wanted-service-component-dashboard-location-pin-parent">
//                           <div className="admin-dashboard-wanted-service-component-dashboard-search-input">
//                             <img
//                               className="admin-dashboard-wanted-service-component-dashboard-marker-pin-02-icon2"
//                               alt=""
//                               src={markerpin02}
//                             />
//                           </div>
//                           <div className="admin-dashboard-wanted-service-component-dashboard-location3">{device.device_address.addressTownCity}, {device.device_address.tax_country.name}</div>
//                         </div>

//                       </div>
//                       <div className="admin-dashboard-wanted-service-component-dashboard-frame-parent26">
//                         <div className="admin-dashboard-wanted-service-component-dashboard-frame-wrapper19">
//                           <div className="admin-dashboard-wanted-service-component-dashboard-legend-series">
//                             <div className="admin-dashboard-wanted-service-component-dashboard-search-input">
//                               <img
//                                 className="admin-dashboard-wanted-service-component-dashboard-marker-pin-02-icon2"
//                                 alt=""
//                                 src={calendar1}
//                               />
//                             </div>
//                             <div className="admin-dashboard-wanted-service-component-dashboard-type-of-work2">{getCustomizedDate(device.createdAt)}</div>
//                           </div>
//                         </div>

//                       </div>

//                     </div>
//                   </div>

//                   <div className="admin-dashboard-wanted-service-component-dashboard-small-card">

//                     <div className="admin-dashboard-wanted-service-component-dashboard-badge33">
//                       <div className="admin-dashboard-wanted-service-component-dashboard-third-badge-number">
//                         {device.tax_device_category.name}
//                       </div>
//                     </div>

//                     <div className="admin-dashboard-wanted-service-component-dashboard-badge36">
//                       <div className="admin-dashboard-wanted-service-component-dashboard-third-badge-numbert">
//                         11 Health
//                       </div>
//                     </div>

//                     <div className="admin-dashboard-wanted-service-component-dashboard-badge37">
//                       <div className="admin-dashboard-wanted-service-component-dashboard-third-badge-number">{device.tax_oem.name}</div>
//                     </div>

//                     <div className="admin-dashboard-wanted-service-component-dashboard-badge38">
//                       <div className="admin-dashboard-wanted-service-component-dashboard-third-badge-number">
//                         {device.tax_clinical_application.name}
//                       </div>
//                     </div>

//                     <div className="admin-dashboard-wanted-service-component-dashboard-badge39">
//                       <div className="admin-dashboard-wanted-service-component-dashboard-third-badge-number">
//                         Accounting
//                       </div>
//                     </div>

//                   </div>

//                 </div>


//                 <div className="admin-dashboard-wanted-service-component-dashboard-filter-elements">
//                   <div className="admin-dashboard-wanted-service-component-dashboard-dropdown10" onClick={() => toggleThreeDotsDropdown(index)} ref={threeDotsRef}>
//                     <img
//                       className="admin-dashboard-wanted-service-component-dashboard-dots-vertical-icon"
//                       alt=""
//                       src={dotsvertical}
//                     />
//                     {activeThreeDotId === index && <ThreeDotsListingComponent url={`device/${device.id}`} />}
//                   </div>
//                   <div className="admin-dashboard-wanted-service-component-dashboard-filter-elements-inner">
//                     <div className="admin-dashboard-wanted-service-component-dashboard-price-parent">
//                       <TransactionTypeComponent transactionType={device.tax_transaction_type.name} slug={device.tax_transaction_type.slug} />
//                       <div className="admin-dashboard-wanted-service-component-dashboard-price2">${formatNumber(device.price)}</div>
//                       <div className="admin-dashboard-wanted-service-component-dashboard-dropdown-elements">
//                         <div className="admin-dashboard-wanted-service-component-dashboard-dropdown-options-parent">
//                           <div className="admin-dashboard-wanted-service-component-dashboard-text83">{device.tax_unit_measure_device_spare.name}</div>
//                           <div className="admin-dashboard-wanted-service-component-dashboard-frame-wrapper3">
//                             <div className="admin-dashboard-wanted-service-component-dashboard-radar-1-1-container">
//                               <img
//                                 className="admin-dashboard-wanted-service-component-dashboard-magnifier-1-icon"
//                                 alt=""
//                                 src={radar_1_1_2x}
//                               />

//                               <img
//                                 className="admin-dashboard-wanted-service-component-dashboard-magnifier-1-icon"
//                                 alt=""
//                                 src={deal_2_1_2x}
//                               />

//                               <img
//                                 className="admin-dashboard-wanted-service-component-dashboard-magnifier-1-icon"
//                                 alt=""
//                                 src={magnifier_1_2x}
//                               />
//                             </div>
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>


//               </div>
//             </div>
//             <img className="admin-dashboard-wanted-service-component-dashboard-divider-icon8" alt="" src={divider2} />

//             <div className="admin-dashboard-wanted-service-component-dashboard-frame-parent71">
//               <div className="admin-dashboard-wanted-service-component-dashboard-buttonsbutton-parent1">
//                 <div className="admin-dashboard-legend-series">
//                   <img
//                     className="admin-dashboard-location-column-child"
//                     alt=""
//                     src={device.profileImage}
//                   />

//                   <div className="admin-dashboard-location-column-inner">
//                     <div className="admin-dashboard-date-picker-dropdown">
//                       <div className="admin-dashboard-name">{device.device_business.business_user.displayName}</div>
//                       <div className="admin-dashboard-role">{device.device_business.businessName}</div>
//                     </div>
//                   </div>
//                 </div>
//                 <div className='dashboard-device-component-main-buttons-group'>
//                   <div className="admin-dashboard-wanted-service-component-dashboard-buttonsbutton13">
//                     <img
//                       className="admin-dashboard-wanted-service-component-dashboard-arrow-narrow-left-icon"
//                       alt=""
//                       src={eye}
//                     />

//                     <div className="admin-dashboard-wanted-service-component-dashboard-search-input">
//                       <div className="admin-dashboard-wanted-service-component-dashboard-text67">View</div>
//                     </div>
//                   </div>

//                   <Link to={"/dashboard/settings/plan"}>
//                   <div className="admin-dashboard-wanted-service-component-dashboard-buttonsbutton25">
//                     <div className="admin-dashboard-wanted-service-component-dashboard-icon-wrapper">
//                       <img className="admin-dashboard-wanted-service-component-dashboard-icon1" alt="" src={icon1} />
//                     </div>
//                     <div className="admin-dashboard-wanted-service-component-dashboard-search-input">
//                       <div className="admin-dashboard-wanted-service-component-dashboard-text68">Sales IQ</div>
//                     </div>
//                     <img
//                       className="admin-dashboard-wanted-service-component-dashboard-arrow-narrow-left-icon"
//                       alt=""
//                       src={lock04}
//                     />
//                   </div>
//                   </Link>

//                   <Link to={"/sales-iq"}>
//                   <div className="admin-dashboard-wanted-service-component-dashboard-buttonsbutton26">
//                     <div className="admin-dashboard-wanted-service-component-dashboard-icon-wrapper">
//                       <img className="admin-dashboard-wanted-service-component-dashboard-icon1" alt="" src={icon1} />
//                     </div>
//                     <div className="admin-dashboard-wanted-service-component-dashboard-search-input">
//                       <div className="admin-dashboard-wanted-service-component-dashboard-text68">Sales IQ</div>
//                     </div>
//                     <img
//                       className="admin-dashboard-wanted-service-component-dashboard-arrow-narrow-left-icon"
//                       alt=""
//                       src={lockunlocked04}
//                     />
//                   </div>
//                   </Link>


//                   <Link to={`chat/${device.id}`}>
//                     <div className="admin-dashboard-wanted-service-component-dashboard-buttonsbutton27">
//                       <img
//                         className="admin-dashboard-wanted-service-component-dashboard-upload-04-icon"
//                         alt=""
//                         src={messagechatsquare}
//                       />

//                       <div className="admin-dashboard-wanted-service-component-dashboard-text145">Messages</div>
//                       <div className="admin-dashboard-wanted-service-component-dashboard-ellipse-parent">
//                         <div className="admin-dashboard-wanted-service-component-dashboard-ellipse-div"></div>
//                         <div className="admin-dashboard-wanted-service-component-dashboard-div">2</div>
//                       </div>
//                     </div>
//                   </Link>
//                 </div>

//               </div>
//               <div className="admin-dashboard-wanted-service-component-dashboard-arrow-square-up-right-wrapper1">
//                 <img
//                   className="admin-dashboard-wanted-service-component-dashboard-arrow-square-up-right-icon1"
//                   alt=""
//                   src={arrowsquareupright}
//                 />
//               </div>
//             </div>
//           </div>
//         </div>
//       ))
//     }
//     else {
//       content = (

//         <NoTableDataDisplay />

//       )
//     }

//   } else if (!loadingComplete && isSuccess) {
//     content = <SkeletonListingLoaderComponent />; // Show loader until loadingComplete is true
//   }




//   return (
//     <>
//       <div className="admin-dashboard-frame-wrapper">
//         <div className="admin-dashboard-frame-group">
//           <div className="admin-dashboard-rectangle-group">
//             <div className="admin-dashboard-frame-item"></div>
//             <div className="admin-dashboard-metric-card-row">
//               <div className="admin-dashboard-metric-card">
//                 <div className="admin-dashboard-metric-card-title">
//                   <div className="admin-dashboard-heading4">Total</div>
//                 </div>
//                 <div className="admin-dashboard-dropdown3">
//                   <img
//                     className="admin-dashboard-dots-vertical-icon"
//                     alt=""
//                     src={dotsvertical}
//                   />
//                 </div>
//               </div>
//               <div className="admin-dashboard-number3">{totalCount || 0}</div>
//             </div>
//             <div className="admin-dashboard-badge11">
//               <div className="admin-dashboard-options-icon">
//                 <img
//                   className="admin-dashboard-arrow-up-icon2"
//                   alt=""
//                   src={arrowup1}
//                 />
//               </div>
//               <div className="admin-dashboard-text58">20 slots available</div>
//             </div>
//           </div>
//           <div className="admin-dashboard-rectangle-container">
//             <div className="admin-dashboard-frame-inner"></div>
//             <div className="admin-dashboard-frame-container">
//               <div className="admin-dashboard-heading-parent">
//                 <div className="admin-dashboard-heading5">Published</div>
//                 <div className="admin-dashboard-number4">{totalCountPublished || 0}</div>
//                 <div className="admin-dashboard-badge12">
//                   <div className="admin-dashboard-options-icon">
//                     <img
//                       className="admin-dashboard-arrow-up-icon2"
//                       alt=""
//                       src={arrowup1}
//                     />
//                   </div>
//                   <div className="admin-dashboard-change-amount">20%</div>
//                 </div>
//               </div>
//             </div>
//             <div className="admin-dashboard-dropdown3">
//               <img
//                 className="admin-dashboard-dots-vertical-icon"
//                 alt=""
//                 src={dotsvertical}
//               />
//             </div>
//           </div>

//           <div className="admin-dashboard-rectangle-container">
//             <div className="admin-dashboard-frame-inner"></div>
//             <div className="admin-dashboard-frame-container">
//               <div className="admin-dashboard-heading-container">
//                 <div className="admin-dashboard-heading5">Archived</div>
//                 <div className="admin-dashboard-number4">{totalCountArchived || 0}</div>
//                 <div className="admin-dashboard-badge12">
//                   <div className="admin-dashboard-options-icon">
//                     <img
//                       className="admin-dashboard-arrow-up-icon2"
//                       alt=""
//                       src={arrowup1}
//                     />
//                   </div>
//                   <div className="admin-dashboard-change-amount">20%</div>
//                 </div>
//               </div>
//             </div>
//             <div className="admin-dashboard-dropdown3">
//               <img
//                 className="admin-dashboard-dots-vertical-icon"
//                 alt=""
//                 src={dotsvertical}
//               />
//             </div>
//           </div>
//           <div className="admin-dashboard-rectangle-parent2">
//             <div className="admin-dashboard-frame-inner"></div>
//             <div className="admin-dashboard-frame-container">
//               <div className="admin-dashboard-heading-parent1">
//                 <div className="admin-dashboard-heading5">Expired</div>
//                 <div className="admin-dashboard-number4">{totalCountExpired || 0}</div>
//                 <div className="admin-dashboard-badge12">
//                   <div className="admin-dashboard-options-icon">
//                     <img
//                       className="admin-dashboard-arrow-up-icon2"
//                       alt=""
//                       src={arrowup1}
//                     />
//                   </div>
//                   <div className="admin-dashboard-change-amount">20%</div>
//                 </div>
//               </div>
//             </div>
//             <div className="admin-dashboard-dropdown7">
//               <img
//                 className="admin-dashboard-dots-vertical-icon"
//                 alt=""
//                 src={dotsvertical}
//               />
//             </div>
//           </div>
//         </div>
//       </div>
//       <div className="admin-dashboard-section-wrapper">
//         <div className="admin-dashboard-section1">
//           <div className="admin-dashboard-container1">
//             <div className="admin-dashboard-filters-bar">
//               <div className="admin-dashboard-content17">
//                 <div className="admin-dashboard-dropdowns">

//                   <AdminDashboardWantedFilterButtonGroup
//                     handleFilterClick={handleFilterClick}
//                     activePostStatus={activePostStatus}
//                   />

//                 </div>
//                 <div className="admin-dashboard-input-dropdown2">
//                   {/* <div className="admin-dashboard-input-with-label2">
//                     <div className="admin-dashboard-label">Search</div>
//                     <div className="admin-dashboard-input2">
//                       <div className="admin-dashboard-content3">
//                         <img
//                           className="admin-dashboard-search-lg-icon dashboard-search-lg-icon-custom"
//                           alt=""
//                           src={searchlg}
//                         />
//                         <input type="text" className="admin-dashboard-search-placeholder" placeholder='Search' />
//                       </div>
//                     </div>
//                   </div> */}

//                   <SearchInputListingComponent onSearch={handleSearch} />

//                   <div className="admin-dashboard-buttonsbutton1" onClick={toggleDropdown}>
//                     <img
//                       className="admin-dashboard-upload-04-icon"
//                       alt=""
//                       src={sliders02}
//                     />
//                     <div className="admin-dashboard-text-padding6" ref={dropdownRef}>
//                       <div className="admin-dashboard-text65">
//                         Sort By {selectedSortOption && `: ${selectedSortOption.label}`}
//                       </div>
//                       {isDropdownVisible && <SortByWantedFilterComponent onOptionSelect={handleOptionSelect} />}
//                     </div>
//                     <img
//                       className="admin-dashboard-placeholder-icon"
//                       alt=""
//                       src={placeholder2}
//                     />

//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>

//       {/* Listing Container */}
//       <div className="admin-dashboard-wanted-service-component-listing-container">

//         {/* Listing layout start    */}
//         {content}

//       </div>

//       {/* Load more button  */}
//       <div className="admin-dashboard-frame-wrapper44">
//         <div className="admin-dashboard-frame-parent72">
//           <div className="admin-dashboard-buttonsbutton-wrapper1">
//             {showLoadMore && (
//               <button className='dashboard-buttonsbutton-wrapper1-child'
//                 onClick={handleLoadMore}
//                 disabled={isFetching}
//               >
//                 <div className="admin-dashboard-buttonsbutton28">
//                   <img
//                     className="admin-dashboard-arrow-narrow-left-icon"
//                     alt=""
//                     src={arrowdown1}
//                   />
//                   <div className="admin-dashboard-search-input">
//                     <div className="admin-dashboard-text146">{isFetching ? 'Loading...' : 'Load More'}</div>
//                   </div>
//                 </div>
//               </button>
//             )}
//           </div>
//         </div>
//       </div>
//     </>
//   )
// }

export const AdminDashboardWantedDeviceComponent = () => {

const [activeThreeDotIdNoAction, setActiveThreeDotIdNoAction] = useState(null);
    const threeDotsRefNoAction = useRef([]);
    const [isClickBlockedNoAction, setIsClickBlockedNoAction] = useState(false);
  
    const toggleThreeDotsDropdownNoAction = (index) => {
      if (isClickBlockedNoAction) return; // Prevent rapid toggle
  
     

        if (activeThreeDotIdNoAction === index) {
          setActiveThreeDotIdNoAction(null); // Close if the same dropdown is clicked again
        } else {
          setActiveThreeDotIdNoAction(index); // Open the dropdown
        }

        setIsClickBlockedNoAction(true);
        setTimeout(() => setIsClickBlockedNoAction(false), 400);
    };
  
    
     useEffect(() => {
       const handleThreeDotClickOutsideNoAction = (event) => {
         if (activeThreeDotIdNoAction !== null) {
           const ref = threeDotsRefNoAction.current[activeThreeDotIdNoAction];
     
           if (ref && !ref.contains(event.target)) {
             setActiveThreeDotIdNoAction(null); // Close when clicking outside
           }
         }
       };
     
       document.addEventListener("mousedown", handleThreeDotClickOutsideNoAction);
       return () => {
         document.removeEventListener("mousedown", handleThreeDotClickOutsideNoAction);
       };
     }, [activeThreeDotIdNoAction]);

  //Offset to load data when click load more
  const [offset, setOffset] = useState(0);
  const [sortBy, setSortBy] = useState('date_desc');
  const [searchTerm, setSearchTerm] = useState('');
  const [totalCount, setTotalCount] = useState("");
  const [totalCountWantedSpare, settotalCountWantedSpare] = useState("");
  const [totalCountWantedService, settotalCountWantedService] = useState("");
  const [totalCountWantedSoftware, settotalCountWantedSoftware] = useState("");

  const [activePostStatus, setActivePostStatusFilter] = useState(null);
  //Query to get data
  const { data, isSuccess, isError, refetch, isLoading, isFetching } = useGetAdminDashboardWantedDevicesQuery(
    { offset, sortBy, postStatus: activePostStatus, search: searchTerm },
    {
      // Refetch when component mounts or tab becomes active
      refetchOnMountOrArgChange: true
    }
  );

  //flag var to show listing when loading complete when sekelteon layout is going to stop
  const [loadingComplete, setLoadingComplete] = useState(false);

  //Var is reponsible to show listings and other thing as well
  let content;

  //Sort by text show on button
  const [selectedSortOption, setSelectedSortOption] = useState('');

  //For SortBy Dropdown
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);

  //For Threedots in listing dropdown
  const [activeThreeDotId, setActiveThreeDotId] = useState(null);
  const threeDotsRef = useRef(null);
  const [isClickBlocked, setIsClickBlocked] = useState(false);



  const toggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible);
  }

  const toggleThreeDotsDropdown = (id) => {
    if (isClickBlocked) return; // Prevent rapid toggle

    if (activeThreeDotId === id) {
      setActiveThreeDotId(null); // Close if the same one is clicked again
      setIsClickBlocked(true);
      setTimeout(() => setIsClickBlocked(false), 400); // Small delay to avoid reopening
    } else {
      setActiveThreeDotId(id); // Open the clicked one
    }
  };

  const handleSearch = (term) => {
    setSearchTerm(term);
  };


  //Set value for sortby selected option on button text
  const handleOptionSelect = (option) => {
    if (option.value !== sortBy) {
      setSortBy(option.value);
      setOffset(0); // Reset offset when sort option changes
    }
    setSelectedSortOption(option);
    setDropdownVisible(false);
  };

  //DashbaordFilterButtonGroup component to get data
  const handleFilterClick = (filterLabel) => {
    if (activePostStatus === filterLabel) {
      setSortBy('date_desc')
      setSelectedSortOption('')
      setActivePostStatusFilter(null);
    } else {
      setSortBy('date_desc')
      setSelectedSortOption('')
      setActivePostStatusFilter(filterLabel);
    }
    setOffset(0); // Reset offset when changing filters
  };

  //Setting offset for loadmore when user clicked on load more button(this trigger query to fetch new data)
  // Reset offset when component mounts or remounts
  // useEffect(() => {
  //   setOffset(0);
  // }, []);
  // // Reset offset when sort option changes
  // useEffect(() => {
  //   setOffset(0);
  // }, [sortBy]);

  const handleLoadMore = () => {
    if (data?.hasMore && !isFetching) {
      setOffset(currentOffset => currentOffset + 5);
    }
  };

  const showLoadMore = data?.hasMore &&
    data.wanted_devices.length < data.totalCount &&
    !isFetching;





  //Onclick listener for sortby dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


  //Onclick listener for threedots dropdown
  useEffect(() => {
    const handleThreeDotClickOutside = (event) => {

      const isLink = event.target.closest('a');

      if (isLink) {
        return; // Allow navigation to happen
      }


      if (threeDotsRef.current && !threeDotsRef.current.contains(event.target)) {
        setActiveThreeDotId(null); // Close any open dropdown when clicking outside
      }
    };

    document.addEventListener("mousedown", handleThreeDotClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleThreeDotClickOutside);
    };
  }, []);

  // Listen for tab visibility changes
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        refetch();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [refetch]);


  //Success true then pause to show sekelton layout
  useEffect(() => {
    if (isSuccess) {
      // Set totalcount in listing counter layout
      setTotalCount(data.totalCount);
      settotalCountWantedSpare(data.totalCountWantedSpare);
      settotalCountWantedService(data.totalCountWantedService);
      settotalCountWantedSoftware(data.totalCountWantedSoftware);
      // settotalCountExpired(data.totalCountExpired);
      // Set timeout to delay the loading completion
      const timer = setTimeout(() => {
        setLoadingComplete(true);
      }, 1000); // 1 second

      // Cleanup timer on component unmount
      return () => clearTimeout(timer);
    }
  }, [isSuccess]);


  if (isError) {
    content = <NoListingFound path={"/add-wanted-device"} pathName={"Wanted Device"} />
  }

  if (isLoading) {
    content = <SkeletonListingLoaderComponent />;
  } else if (loadingComplete && isSuccess) {

    if (data.wanted_devices.length > 0) {
      // dataLength = data.wanted_devices.length;
      content = data.wanted_devices.map((wantedDevice, index) =>
      (

        <div className="beacon-device-component-beacon-content-items-admin" key={index}>
          <div className="beacon-device-component-beacon-divider-container1">
            <div className="beacon-device-component-beacon-divider-container-child"></div>
            <div className="beacon-device-component-beacon-rectangle-parent5">


              <div className="beacon-device-component-beacon-frame-parent1">
                <div className="beacon-device-component-beacon-action-fields">
                  <div className="beacon-device-component-beacon-input-elements">
                    <Link to={`/wanted-device/${wantedDevice.id}/${wantedDevice.title}`} className="beacon-device-component-beacon-text78">
                      {wantedDevice.title}
                    </Link>

                    <div className="beacon-device-component-beacon-frame-parent21">
                      <div className="beacon-device-component-beacon-badge-item-parent">

                        <div className="beacon-device-component-beacon-badge-parent">

                          {/* <PostStatusLIstingComponent postStatus={'Expired'} postSlug={"expired"} postId={'1'} /> */}

                        </div>

                        <div className="beacon-device-component-beacon-frame-wrapper19">
                          <div className="beacon-device-component-beacon-legend-series">
                            <div className="beacon-device-component-beacon-search-input">
                              <img
                                className="beacon-device-component-beacon-marker-pin-02-icon2"
                                alt=""
                                src={calendar1}
                              />
                            </div>
                            <div className="beacon-device-component-beacon-type-of-work2">{getCustomizedDate(wantedDevice.createdAt)}</div>

                          </div>
                        </div>

                        <div className="beacon-device-component-beacon-frame-parent4">
                          <div className="beacon-device-component-beacon-end-label">
                            <div className="beacon-device-component-beacon-search-icon-input">4.9/5</div>
                          </div>
                          <div className="beacon-device-component-beacon-type-support">
                            <div className="beacon-device-component-beacon-supporting-text9">
                              99,999 reviews
                            </div>
                          </div>
                        </div>

                      </div>
                      <div className="beacon-device-component-beacon-frame-parent24">
                        {/* <div className="beacon-device-component-beacon-location-pin-parent">
                        <div className="beacon-device-component-beacon-search-input">
                            <img
                            className="beacon-device-component-beacon-marker-pin-02-icon2"
                            alt=""
                            src={markerpin02}
                            />
                        </div>
                        <div className="beacon-device-component-beacon-location3">India, New Delhi</div>
                        </div> */}


                        {/* <div className="beacon-device-component-beacon-frame-wrapper18">
                          <div className="beacon-device-component-beacon-badge-elements-copy">
                           
                          </div>
                        </div> */}
                      </div>
                      {/* <div className="beacon-device-component-beacon-frame-parent26">

                       
                      </div> */}
                      {/* <div className="beacon-device-component-beacon-badge39">
                        <div className="beacon-device-component-beacon-location-badge-elements">
                        Accounting
                        </div>
                    </div> */}
                    </div>

                    <div className="funnel-device-component-funnel-small-card">

                      <div className="beacon-device-component-beacon-badge33">
                        <div className="beacon-device-component-beacon-third-badge-number">
                          {wantedDevice.tax_device_category.name}
                        </div>

                      </div>

                      <div className="beacon-device-component-beacon-badge36">
                        <div className="beacon-device-component-beacon-third-badge-number">
                          {wantedDevice.tax_oem.name}
                        </div>

                      </div>

                      <div className="beacon-device-component-beacon-badge37">
                        <div className="beacon-device-component-beacon-third-badge-number">{wantedDevice.tax_clinical_application.name}</div>

                      </div>

                      <div className="beacon-device-component-beacon-badge38">
                        <div className="beacon-device-component-beacon-third-badge-number">
                          {wantedDevice.tax_purpose_use.name}
                        </div>

                      </div>


                    </div>
                  </div>
                </div>
                <div className="beacon-device-component-beacon-filter-elements">
                  <div className="beacon-device-component-beacon-dropdown10" onClick={() => toggleThreeDotsDropdown(index)} ref={threeDotsRef}>
                    <img
                      className="beacon-device-component-beacon-dots-vertical-icon"
                      alt=""
                      src={dotsvertical}
                    />
                    {activeThreeDotId === index && <ThreeDotsAdminWantedListingComponent url={`wanted-device/${wantedDevice.id}`} />}
                  </div>

                </div>
              </div>
            </div>
            <img className="beacon-device-component-beacon-divider-icon8" alt="" src={divider2} />

            <div className="beacon-device-component-beacon-frame-parent71">
              <div className="beacon-device-component-beacon-buttonsbutton-parent1">
                <div className="beacon-legend-series">
                  <img
                    className="beacon-location-column-child"
                    alt=""
                    src={wantedDevice.profileImage}

                  />

                  <div className="beacon-location-column-inner">
                    <div className="beacon-date-picker-dropdown">
                      <div className="beacon-name">{wantedDevice.wanted_device_user.displayName}</div>
                      {/* <div className="beacon-role">Business Na...</div> */}

                    </div>
                  </div>
                </div>
                <div className='beacon-device-component-main-buttons-group'>
                  <div className="beacon-device-component-beacon-buttonsbutton13">
                    <img
                      className="beacon-device-component-beacon-arrow-narrow-left-icon"
                      alt=""
                      src={eye}
                    />

                    <div className="beacon-device-component-beacon-search-input">
                      <div className="beacon-device-component-beacon-text67">View</div>
                    </div>
                  </div>
                  <div className="beacon-device-component-beacon-buttonsbutton25">
                    <div className="beacon-device-component-beacon-icon-wrapper">
                      <img className="beacon-device-component-beacon-icon1" alt="" src={icon1} />
                    </div>
                    <div className="beacon-device-component-beacon-search-input">
                      <div className="beacon-device-component-beacon-text68">Sales IQ</div>
                    </div>
                    <img
                      className="beacon-device-component-beacon-arrow-narrow-left-icon"
                      alt=""
                      src={lock04}
                    />
                  </div>
                  <div className="beacon-device-component-beacon-buttonsbutton26">
                    <div className="beacon-device-component-beacon-icon-wrapper">
                      <img className="beacon-device-component-beacon-icon1" alt="" src={icon1} />
                    </div>
                    <div className="beacon-device-component-beacon-search-input">
                      <div className="beacon-device-component-beacon-text68">Sales IQ</div>
                    </div>
                    <img
                      className="beacon-device-component-beacon-arrow-narrow-left-icon"
                      alt=""
                      src={lockunlocked04}
                    />
                  </div>
                  <div className="beacon-device-component-beacon-buttonsbutton27">
                    <img
                      className="beacon-device-component-beacon-upload-04-icon"
                      alt=""
                      src={messagechatsquare}
                    />

                    <div className="beacon-device-component-beacon-text145">Messages</div>
                    <div className="beacon-device-component-beacon-ellipse-parent">
                      <div className="beacon-device-component-beacon-ellipse-div"></div>
                      <div className="beacon-device-component-beacon-div">2</div>
                    </div>
                  </div>
                </div>

              </div>
              <div className="beacon-device-component-beacon-arrow-square-up-right-wrapper1">
                <img
                  className="beacon-device-component-beacon-arrow-square-up-right-icon1"
                  alt=""
                  src={arrowsquareupright}
                />
              </div>
            </div>
          </div>
        </div>
      ))
    }
    else {
      content = <NoListingFound path={"/add-wanted-device"} pathName={"Wanted Device"} />
    }

  } else if (!loadingComplete && isSuccess) {
    content = <SkeletonListingLoaderComponent />; // Show loader until loadingComplete is true
  }




  return (
    <>
      <div className="beacon-frame-wrapper">
        <div className="beacon-frame-group">
          <div className="beacon-rectangle-group">
            <div className="beacon-frame-item"></div>
            <div className="beacon-metric-card-row">
              <div className="beacon-metric-card">
                <div className="beacon-metric-card-title">
                  <div className="beacon-heading4">Devices</div>
                </div>
                <div className="beacon-dropdown3" style={{cursor:"pointer"}}  onClick={() => toggleThreeDotsDropdownNoAction(0)} ref={(el) => threeDotsRefNoAction.current[0] = el}>
                  <img
                    className="beacon-dots-vertical-icon"
                    alt=""
                    src={dotsvertical}
                  />
                                  {activeThreeDotIdNoAction === 0  && <ThreeDotsNoActionComponent  />}
                  
                </div>
              </div>
              <div className="beacon-number3">{totalCount || 0}</div>
            </div>
            {/* <div className="beacon-badge11">
              <div className="beacon-options-icon">
                <img
                  className="beacon-arrow-up-icon2"
                  alt=""
                  src={arrowup1}
                />
              </div>
              <div className="beacon-text58">20 slots available</div>
            </div> */}
          </div>
          <div className="beacon-rectangle-container">
            <div className="beacon-frame-inner"></div>
            <div className="beacon-frame-container">
              <div className="beacon-heading-parent">
                <div className="beacon-heading5">Spares</div>
                <div className="beacon-number4">{totalCountWantedSpare || 0}</div>
                {/* <div className="beacon-badge12">
                  <div className="beacon-options-icon">
                    <img
                      className="beacon-arrow-up-icon2"
                      alt=""
                      src={arrowup1}
                    />
                  </div>
                  <div className="beacon-change-amount">20%</div>
                </div> */}
              </div>
            </div>
            <div className="beacon-dropdown3" style={{cursor:"pointer"}}  onClick={() => toggleThreeDotsDropdownNoAction(1)} ref={(el) => threeDotsRefNoAction.current[1] = el}>
              <img
                className="beacon-dots-vertical-icon"
                alt=""
                src={dotsvertical}
              />
                              {activeThreeDotIdNoAction === 1  && <ThreeDotsNoActionComponent  />}
              
            </div>
          </div>
          <div className="beacon-rectangle-container">
            <div className="beacon-frame-inner"></div>
            <div className="beacon-options-icon">
              <div className="beacon-heading-group">
                <div className="beacon-heading5">Services</div>
                <div className="beacon-number4">{totalCountWantedService || 0}</div>
                {/* <div className="beacon-badge12">
                  <div className="beacon-options-icon">
                    <img
                      className="beacon-arrow-up-icon2"
                      alt=""
                      src={arrowup1}
                    />
                  </div>
                  <div className="beacon-change-amount">20%</div>
                </div> */}
              </div>
            </div>
            <div className="beacon-dropdown3" style={{cursor:"pointer"}}  onClick={() => toggleThreeDotsDropdownNoAction(2)} ref={(el) => threeDotsRefNoAction.current[2] = el}>
              <img
                className="beacon-dots-vertical-icon"
                alt=""
                src={dotsvertical}
              />
                              {activeThreeDotIdNoAction === 2  && <ThreeDotsNoActionComponent  />}
              
            </div>
          </div>
          <div className="beacon-rectangle-container">
            <div className="beacon-frame-inner"></div>
            <div className="beacon-frame-container">
              <div className="beacon-heading-container">
                <div className="beacon-heading5">Software</div>
                <div className="beacon-number4">{totalCountWantedSoftware || 0}</div>
                {/* <div className="beacon-badge12">
                  <div className="beacon-options-icon">
                    <img
                      className="beacon-arrow-up-icon2"
                      alt=""
                      src={arrowup1}
                    />
                  </div>
                  <div className="beacon-change-amount">20%</div>
                </div> */}
              </div>
            </div>
            <div className="beacon-dropdown3" style={{cursor:"pointer"}}  onClick={() => toggleThreeDotsDropdownNoAction(3)} ref={(el) => threeDotsRefNoAction.current[3] = el}>
              <img
                className="beacon-dots-vertical-icon"
                alt=""
                src={dotsvertical}
              />
                              {activeThreeDotIdNoAction === 3  && <ThreeDotsNoActionComponent  />}
              
            </div>
          </div>

        </div>
      </div>

      <div className="beacon-section-wrapper">
        <div className="beacon-section1">
          <div className="beacon-container1">
            <div className="beacon-filters-bar">
              <div className="beacon-content17">
                <div className="beacon-dropdowns">

                  <RadarFilterButtonGroup
                    handleFilterClick={handleFilterClick}
                    activePostStatus={activePostStatus}
                  />

                </div>
                <div className="beacon-input-dropdown2">
                  {/* <div className="beacon-input-with-label2">
                    <div className="beacon-label">Search</div>
                    <div className="beacon-input2">
                      <div className="beacon-content3">
                        <img
                          className="beacon-search-lg-icon beacon-search-lg-icon-custom"
                          alt=""
                          src={searchlg}
                        />
                        <input type="text" className="beacon-search-placeholder" placeholder='Search' />
                      </div>
                    </div>
                  </div> */}

                  <SearchInputListingComponent onSearch={handleSearch} />

                  <div className="beacon-buttonsbutton1" onClick={toggleDropdown}>
                    <img
                      className="beacon-upload-04-icon"
                      alt=""
                      src={sliders02}
                    />
                    <div className="beacon-text-padding6" ref={dropdownRef}>
                      <div className="beacon-text65">
                        Sort By {selectedSortOption && `: ${selectedSortOption.label}`}
                      </div>
                      {isDropdownVisible && <SortByFilterComponent onOptionSelect={handleOptionSelect} />}
                    </div>
                    <img
                      className="beacon-placeholder-icon"
                      alt=""
                      src={placeholder2}
                    />

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      {/* Listing Container */}
      <div className="beacon-device-component-listing-container">

        {/* Listing layout start    */}
        {content}



      </div>

      {/* Load more button  */}
      <div className="beacon-frame-wrapper44">
        <div className="beacon-frame-parent72">
          <div className="beacon-buttonsbutton-wrapper1">
            {showLoadMore && (
              <button className='beacon-buttonsbutton-wrapper1-child'
                onClick={handleLoadMore}
                disabled={isFetching}
              >
                <div className="beacon-buttonsbutton28">
                  <img
                    className="beacon-arrow-narrow-left-icon"
                    alt=""
                    src={arrowdown1}
                  />
                  <div className="beacon-search-input">
                    <div className="beacon-text146">{isFetching ? 'Loading...' : 'Load More'}</div>
                  </div>
                </div>
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

