import React, { useState } from 'react';
import "./AddJob.css"
import { Navbar1 } from '../../../components/Navbar1/Navbar1'
import { FooterAddForms } from '../../../components/Footer/FooterAddForms/FooterAddForms';
import { HeaderForms } from '../../../components/Header/HeaderForms/HeaderForms';
import { AddJobStep1 } from '../../../components/AddJobFormComponent/AddJobStep1';
import { AddJobStep2 } from '../../../components/AddJobFormComponent/AddJobStep2';
// import { AddJobStep3 } from '../../../components/AddJobFormComponent/AddJobStep3';
import divider from '../../../icons/Gerator/add_form/navbar_divider/divider.svg';
import divider_light from '../../../icons/Gerator/divider.svg';
import { Sidebar } from '../../../components/Sidebar/Sidebar';
import { SideBarSubProgressStep1 } from '../../../components/SideBars-Sub/SideBarSubAddForm/SideBarSubProgressStep1';
import { SideBarSubProgressStep2 } from '../../../components/SideBars-Sub/SideBarSubAddForm/SideBarSubProgressStep2';
import AddJobParent from '../../../components/AddJobFormComponent/AddJobParent';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { auth, onAuthStateChanged } from '../../Login/FirebaseClient';
import { Loader } from '../../../components/Loader';
import { useLazyGetUserProfileQuery, useLazyGetUserQuery } from '../../../api/Login&RegisterAPI';
import { useEffect } from 'react';
import { useRef } from 'react';
import { decryptErrorData } from '../../../helper/AESHelper';
// const renderFormStep = (step, setStep) => {
//   switch (step) {
//     case 0:
//       return <AddJobStep1 setStep={setStep} />;
//     case 1:
//       return <AddJobStep2 setStep={setStep} />;
//     // case 2:
//     //   return <AddJobStep3 setStep={setStep}/>;
//     default:z
//       return null;
//   }
// };

const renderProgressStep = (step) => {
  switch (step) {
    case 0:
      return <SideBarSubProgressStep1 />;
    case 1:
      return <SideBarSubProgressStep2 />;
    // case 2:
    //   return <SideBarSubProgressStep3 />;
    default:
      return null;
  }
};


export const AddJob = () => {

  const appData = useSelector((state) => state.appDataAddJob);
  // Progress var when this is true means all taxonomy loaded
  const [isTaxonomySet, setIsTaxonomySet] = useState(false);
  const [triggerGetUser, { data, isSuccess, error, isLoading, isError }] = useLazyGetUserQuery();
  const [currentUser, setCurrentUser] = useState("");
  const navigate = useNavigate();

  const [showSuccessMessage, setShowSuccessMessage] = useState({
    message: "",
    color: "",
  });

  useEffect(() => {
    // const auth = getAuth();

    // Listen for authentication state change
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is authenticated
        triggerGetUser();
        setCurrentUser(user);
      } else {
        // User is not authenticated, redirect to login
        navigate('/sign-in', { state: { message: 'Please log in to continue.' } });
      }
    });

    return () => unsubscribe(); // Cleanup listener on component unmount
  }, [navigate, triggerGetUser]);


  if (error) {
    console.log(error);
    if (isError) {
      const decryptedData = decryptErrorData(error.data);
      console.log(decryptedData);
      // navigate("/sign-in", { state: decryptedData.message });
      navigate("/sign-in", { state: { message: decryptedData.message } });
    }
    else {
      navigate("/sign-in", { state: { message: "Internal server error" } });
    }
    return; // Optionally handle error differently
  }



  if(isSuccess){
    return (


      <div className="add-job-forms-add-job add-job-forms-add-job-custom-margin">
        <div className="add-job-forms-add-job-child"></div>
  
  
        <Navbar1 userData={currentUser} navigate={navigate} />
  
        <img className="divider-icon" alt="" src={divider} />
  
  
        <main className="add-job-content-parent">
  
          <Sidebar>{renderProgressStep(appData.currentStep)}</Sidebar>
          {/* <Sidebar><SideBarSubNormal userData={currentUser} /></Sidebar> */}
  
  
  
  
  
  
  
  
  
          <section className="add-job-bottom-actions">
  
            <div className="add-job-progress-container-parent">
              <div className="add-job-progress-container">
                {/* <div className="add-job-progress-steps">
                  <div className="add-job-form-parent">
                   
    
    
    
                  </div>
                </div> */}
                {/* {renderProgressStep(step)} */}
                {/* <ProgressStep1/> */}
                {/* <ProgressStep2/> */}
                {/* <ProgressStep3/> */}
                <div className="add-job-content5">
                  <div className="add-job-main">
  
  
                    <HeaderForms text="Add Job " text1="Add Job Listing" text2="Your first step to realizing asset value." />
  
  
  
  
                    <div className="add-job-main-child"></div>
                    <div className="add-job-main-item"></div>
                  </div>
                  <div className="add-job-body">
                    <AddJobParent setShowSuccessMessage={setShowSuccessMessage} setIsTaxonomySet={setIsTaxonomySet} />
  
  
  
  
                  </div>
                  {showSuccessMessage.message && (
                    <div
                      style={{
                        fontSize: "14px",
                        color: showSuccessMessage.color,
                        padding: "15px 10px",
                        border: "1px solid " + showSuccessMessage.color,
                        borderRadius: "10px",
                        margin: "0 auto",
                      }}
                    >
                      {showSuccessMessage.message}
                    </div>
                  )}
                </div>
              </div>
  
            </div>
          </section>
        </main>
        <img
          className="add-job-divider-icon10"
          loading="lazy"
          alt=""
          src={divider_light}
        />
  
        <FooterAddForms />
      </div>
    )
  } else {
    return <Loader />
  }

 
}





