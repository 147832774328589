import React, { useState } from 'react';
import "./EditAddress.css"
import MapComponent from '../../../components/MapComponent/MapComponent';


export const EditAddress = () => {
  const [selectedMapCoordinates, setSelectedMapCoordinates] = useState(null);

  return (



    <div className="address-edit-form-form-content-wrapper">
      <div className="address-edit-form-form-content">
        <div className="address-edit-form-toggle-header-parent">
          <div className="address-edit-form-toggle-header">
            <div className="address-edit-form-toggle-title">Address</div>
            <div className="address-display-supporting-text2">
              Update your photo and personal details here.
            </div>
          </div>
          <div className="address-edit-form-toggle-controls">
            <div className="address-edit-form-rectangle-group">
              <div className="address-edit-form-frame-item"></div>
              <div className="address-edit-form-toggle-base">
                <div className="address-edit-form-button6"></div>
              </div>
              <div className="address-edit-form-switch-label">Explorer mode</div>
              <div className="address-edit-form-supporting-text3">
                Save my login details for next time.
              </div>
            </div>
            <button className="address-edit-form-button7">
              <img
                className="address-edit-form-placeholder-icon"
                alt=""
                src="/profile_settings/placeholder2.svg"
              />

              <div className="address-edit-form-button-labels">Badge #1</div>
              <img
                className="address-edit-form-placeholder-icon"
                alt=""
                src="/profile_settings/placeholder2.svg"
              />
            </button>
            <button className="address-edit-form-button8">
              <img
                className="address-edit-form-placeholder-icon"
                alt=""
                src="/profile_settings/placeholder3.svg"
              />

              <div className="address-edit-form-text49">Business Owner</div>
              <img
                className="address-edit-form-placeholder-icon"
                alt=""
                src="/profile_settings/placeholder3.svg"
              />
            </button>
          </div>
        </div>
        <div className="address-edit-form-divider-container">
          <img
            className="address-edit-form-divider-icon3"
            alt=""
            src="/profile_settings/divider2.svg"
          />
        </div>


        <div className="address-edit-form-content18">
          <div className="address-edit-form-section-label1">
            <div className="address-edit-form-title">Address title <span className="address-edit-form-span">*</span>
            </div>
          </div>
          <div className="address-edit-form-input-field1">
            <div className="address-edit-form-textarea-input-field">
              <div className="address-edit-form-label2">Email</div>
              <div className="address-edit-form-input4">
                <div className="address-edit-form-content19">


                  <input
                    className="address-edit-form-extra-input-info"

                    type="text"
                  />
                </div>

                <img
                  className="address-edit-form-help-icon"
                  alt=""
                  src="/profile_settings/help-icon.svg"
                />
              </div>
            </div>

          </div>
        </div>

        <div className="address-edit-form-divider"></div>

        <div className="address-edit-form-tagged-inputs">
          <div className="address-edit-form-input-field12">
            <div className="address-edit-form-input-with-label19">
              <div className="address-edit-form-label21">
                <span className="address-edit-form-transaction-type">Choose Address type </span>
                <span className="address-edit-form-span">*</span>
              </div>
            </div>
            {/* <div className="address-edit-form-hint-text1">
            This is a hint text to help user.
          </div> */}
            <div className="address-edit-form-file-button-container">
              <div className="address-edit-form-buttonsbutton4">
                <img
                  className="address-edit-form-search-lg-icon"
                  alt=""
                  src="/add_form/placeholder.svg"
                />

                <div className="address-edit-form-text-padding6">
                  <div className="address-edit-form-file-button-labels">Geographic Location</div>
                </div>
              </div>
              <div className="address-edit-form-buttonsbutton4">
                <img
                  className="address-edit-form-search-lg-icon"
                  alt=""
                  src="/add_form/placeholder.svg"
                />

                <div className="address-edit-form-text-padding6">
                  <div className="address-edit-form-text48">Remote/Virtual Location</div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div className="address-edit-form-divider"></div>

        <div className="address-edit-form-content18">
          <div className="address-edit-form-section-label1">
            <div className="address-edit-form-title">Country <span className="address-edit-form-span">*</span> </div>
          </div>
          <div className="address-edit-form-input-dropdown1">
            <div className="address-edit-form-textarea-input-field">

              <div className="address-edit-form-input8">
                <div className="address-edit-form-content29">


                  <div className="address-edit-form-text59">Select from dropdown list</div>

                </div>
                <img
                  className="address-edit-form-search-lg-icon"
                  alt=""
                  src="/profile_settings/chevrondown.svg"
                />
              </div>
            </div>
            <div className="address-edit-form-hint-text2">
              This is a hint text to help user.
            </div>
          </div>
        </div>

        <div className="address-edit-form-divider"></div>
        <div className="address-edit-form-content18">
          <div className="address-edit-form-section-label1">
            <div className="address-edit-form-title">Building,Street Address <span className="address-edit-form-span">*</span> </div>
          </div>
          <div className="address-edit-form-input-field1">
            <div className="address-edit-form-textarea-input-field">

              <div className="address-edit-form-input4">
                <input
                  className="address-edit-form-content25"

                  type="text"
                />

                <img
                  className="address-edit-form-help-icon"
                  alt=""
                  src="/profile_settings/help-icon.svg"
                />
              </div>
            </div>
            <div className="address-edit-form-hint-text2">
              This is a hint text to help user.
            </div>
          </div>
        </div>
        <div className="address-edit-form-divider"></div>

        <div className="address-edit-form-content18">
          <div className="address-edit-form-section-label1">
            <div className="address-edit-form-title">State / Region </div>
          </div>
          <div className="address-edit-form-input-dropdown1">
            <div className="address-edit-form-textarea-input-field">

              <div className="address-edit-form-input10">
                <input
                  className="address-edit-form-content33"

                  type="text"
                />
              </div>
            </div>

          </div>
        </div>

        <div className="address-edit-form-divider"></div>
        <div className="address-edit-form-content18">
          <div className="address-edit-form-section-label1">
            <div className="address-edit-form-title">Town / City <span className="address-edit-form-span">*</span></div>
          </div>
          <div className="address-edit-form-input-dropdown1">
            <div className="address-edit-form-textarea-input-field">

              <div className="address-edit-form-input10">
                <input
                  className="address-edit-form-content33"

                  type="text"
                />
              </div>
            </div>

          </div>
        </div>


        <div className="address-edit-form-divider"></div>

        <div className="address-edit-form-content18">
          <div className="address-edit-form-section-label1">
            <div className="address-edit-form-title">District </div>
          </div>
          <div className="address-edit-form-input-dropdown1">
            <div className="address-edit-form-textarea-input-field">

              <div className="address-edit-form-input10">
                <input
                  className="address-edit-form-content33"

                  type="text"
                />
              </div>
            </div>

          </div>
        </div>

        <div className="address-edit-form-divider"></div>
        <div className="address-edit-form-content18">
          <div className="address-edit-form-section-label1">
            <div className="address-edit-form-title">Postcode / ZIP <span className="address-edit-form-span">*</span></div>
          </div>
          <div className="address-edit-form-input-dropdown1">
            <div className="address-edit-form-textarea-input-field">

              <div className="address-edit-form-input10">
                <input
                  className="address-edit-form-content33"

                  type="text"
                />
              </div>
            </div>

          </div>
        </div>

        <div className="address-edit-form-divider"></div>
        <div className="address-edit-form-content18">
          <div className="address-edit-form-section-label1">
            <div className="address-edit-form-title">Select the location on the map <span className="address-edit-form-span">*</span></div>
          </div>
          <div className="address-edit-form-input-dropdown1">
            <div className="address-edit-form-textarea-input-field">

              <div className="address-edit-form-input10">
                <MapComponent width={600} height={400} zoom={2} setSelectedMapCoordinates={setSelectedMapCoordinates} />
                {/* <iframe
                  title="Google Map"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3153.8354345093753!2d144.9631233153163!3d-37.81627997975148!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad642af0f11fd81%3A0x5045675218cee17!2sMelbourne%20VIC%2C%20Australia!5e0!3m2!1sen!2sin!4v1635596782052!5m2!1sen!2sin"
                  width="600"
                  height="450"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  loading="lazy"
                /> */}
              </div>
            </div>

          </div>
        </div>

        <div className="address-edit-form-upload-footer">
          <div className="address-edit-form-section-footer">
            <img
              className="address-edit-form-divider-icon4"
              alt=""
              src="/profile_settings/divider2.svg"
            />

            <div className="address-edit-form-content46">
              {/* <div className="address-edit-form-buttonsbutton5">
                      <img
                        className="address-edit-form-placeholder-icon"
                        alt=""
                        src="/profile_settings/placeholder.svg"
                      />

                      <div className="address-edit-form-text73">Learn more</div>
                      <img
                        className="address-edit-form-placeholder-icon"
                        alt=""
                        src="/profile_settings/placeholder.svg"
                      />
                    </div> */}
              <div className="address-edit-form-actions1">
                {/* <div className="address-edit-form-button9">
                        <img
                          className="address-edit-form-placeholder-icon"
                          alt=""
                          src="/profile_settings/placeholder.svg"
                        />

                        <div className="address-edit-form-text-padding">
                          <div className="address-edit-form-title">Tertiary</div>
                        </div>
                        <img
                          className="address-edit-form-placeholder-icon"
                          alt=""
                          src="/profile_settings/placeholder.svg"
                        />
                      </div> */}

                <button className="address-edit-form-button11" >
                  <img
                    className="address-edit-form-placeholder-icon"
                    alt=""
                    src="/profile_settings/placeholder3.svg"
                  />

                  <div className="address-edit-form-text-padding8">
                    <div className="address-edit-form-text76">Submit</div>
                  </div>
                  <img
                    className="address-edit-form-placeholder-icon"
                    alt=""
                    src="/profile_settings/placeholder3.svg"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>





      </div>
    </div>


  )
}

