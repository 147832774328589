// import React, { useEffect, useState, useRef } from "react";
// import "../ChatPageComponent.css"; // Correct relative path

// const MessageBody = ({ messages, currentUser, selectedChat }) => {
//   const [currentTime, setCurrentTime] = useState(Date.now());
//   const messagesEndRef = useRef(null); // Create a ref for the end of the message list
  

//   // Periodically update `currentTime` every minute
//   useEffect(() => {
//     const interval = setInterval(() => {
//       setCurrentTime(Date.now()); // Update currentTime every 1 minute
//     }, 60000); // 60 seconds
//     return () => clearInterval(interval); // Cleanup interval on unmount
//   }, []);

//   // Auto-scroll to the bottom when messages update
//   useEffect(() => {
//     if (messagesEndRef.current && currentUser) {
//       console.log("In Message Body we are checking for messages", messages);

//       console.log("In Message Body we are checking for currentUser", currentUser);
//       messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
//     }
//   }, [messages,currentUser]); // Triggered when `messages` changes

//   // Helper function to format the timestamp into HH:mm
//   // const formatTime = (timestamp) => {
//   //   const date = new Date(timestamp);
//   //   const hours = date.getHours();
//   //   const minutes = date.getMinutes();
//   //   return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;
//   // };

//   const formatTime = (timestamp) => {
//     const date = new Date(timestamp);
//     const day = date.getDate().toString().padStart(2, "0"); // Day of the month
//     const month = date.toLocaleString("en-US", { month: "short" }); // Short month name
//     const year = date.getFullYear(); // 4-digit year
//     const hours = date.getHours().toString().padStart(2, "0"); // 24-hour format hours
//     const minutes = date.getMinutes().toString().padStart(2, "0"); // Minutes
    
//     return `${day} ${month} ${year}, ${hours}:${minutes}`;
//   };
  
  
 
//   // Helper function to get display time (Just now or formatted time)
//   // const getDisplayTime = (timestamp) => {
//   //   const elapsedTime = currentTime - timestamp;

//   //   // Show "Just now" for the first 60 seconds
//   //   if (elapsedTime < 60000) {
//   //     return "Just now";
//   //   }

//   //   // Otherwise, show formatted time
//   //   return formatTime(timestamp);
//   // };

//   const getDisplayTime = (timestamp) => {
//     const elapsedTime = currentTime - timestamp;
  
//     // Show "Just now" for the first 60 seconds
//     if (elapsedTime < 60000) {
//       return "Just now";
//     }
  
//     // Otherwise, show full date and time
//     return formatTime(timestamp);
//   };
  

//   return (
//     <div className="chatB-container1">
//       <div className="chatB-content-divider1">
//         <div className="chatB-divider-wrapper">
//           <div className="chatB-divider" />
//         </div>
//         <div className="chatB-divider-label">Messages</div>
//         <div className="chatB-divider-wrapper">
//           <div className="chatB-divider" />
//         </div>
//       </div>
//       <div className="chatB-messages-parent">
//         <div className="chatB-messages">
//           {messages.map((message, index) => (
            
//             <div
//               key={index}
//               className={`${
//                 message.sender === currentUser.user_id
//                   ? "chatB-row"
//                   : "chatB-message12"
//               }`}
//             >
//               {/* Avatar and Online Indicator for Received Messages */}
//               {message.sender !== currentUser.user_id && (
//                 <img
//                 className="chatB-avatar2"
//                 src={selectedChat?.senderDetails?.profileImageLink || avatar2}
//                 alt="Avatar"
//               />
//               )}
//               {message.sender === currentUser.user_id ? (
//                 /* Wrap in message10 only for current user's messages */
//                 <div className="chatB-content13">
//                   <div className="chatB-name-and-time3">
//                     <div className="chatB-single-message-username">You</div>
//                   </div>
//                   <div className="chatB-input5">
//                     <div className="chatB-text31">{message.content}</div>
//                     <div className="chatB-sub-text3">
//                       {getDisplayTime(message.timestamp)}
//                     </div>
//                   </div>
//                 </div>
//               ) : (
//                 /* No wrapping for received messages */
//                 <div className="chatB-content8">
//                   <div className="chatB-name-and-time">
//                     <div className="chatB-usernames">{selectedChat.senderDetails.display_name}</div>
//                     {/* <div className="chatB-sub-text">
//                       {getDisplayTime(message.timestamp)}
//                     </div> */}
//                   </div>
//                   <div className="chatB-input2">
//                     <div className="chatB-receiever">{message.content}</div>
//                     <div className="chatB-sub-text">
//                       {getDisplayTime(message.timestamp)}
//                     </div>
//                   </div>
//                 </div>
//               )}
//               {/* Empty Space for Sent Messages */}
//               {message.sender === currentUser.user_id && (
//                 <div className="chatB-empty-space" />
//               )}
//             </div>
//           ))}
//           {/* Invisible div to act as a scroll anchor */}
//           <div ref={messagesEndRef} />
//         </div>
//       </div>
//     </div>
//   );
// };

// export { MessageBody };

import React, { useEffect, useState, useRef } from "react";
import "../ChatPageComponent.css"; // Correct relative path

const MessageBody = ({ messages, currentUser, selectedChat }) => {
  const messagesEndRef = useRef(null); // Create a ref for the end of the message list

  // Auto-scroll to the bottom when messages update
  useEffect(() => {
    if (messagesEndRef.current && currentUser) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages, currentUser]);

  const formatTime = (timestamp) => {
    const date = new Date(timestamp);
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    return `${hours}:${minutes}`;
  };

  const getDateLabel = (timestamp) => {
    const messageDate = new Date(timestamp);
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Normalize today to midnight

    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1); // Normalize yesterday to midnight

    if (messageDate.getTime() >= today.getTime()) return "Today";
    if (messageDate.getTime() >= yesterday.getTime()) return "Yesterday";

    return messageDate.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
  };

  // Ensure messages are sorted by timestamp (ascending order)
  const sortedMessages = [...messages].sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));

  let lastDate = null; // Tracks the last date divider

  // Process messages to include dividers
  const processedMessages = [];
  sortedMessages.forEach((message) => {
    const messageDate = new Date(message.timestamp).toDateString();
    if (messageDate !== lastDate) {
      processedMessages.push({ isDivider: true, text: getDateLabel(message.timestamp) });
      lastDate = messageDate;
    }
    processedMessages.push({ ...message, isDivider: false });
  });

  return (
    <div className="chatB-container1">
      <div className="chatB-messages-parent-owner">
        <div className="chatB-messages-owner">
          {processedMessages.map((item, index) =>
            item.isDivider ? (
              <div key={`divider-${index}`} className="chatB-divider-container">
                <div className="chatB-divider-line"></div>
                <div className="chatB-divider-label">{item.text}</div>
                <div className="chatB-divider-line"></div>
              </div>
            ) : (
              <div
                key={item.messageId || index}
                className={`${
                  item.sender === currentUser.user_id
                    ? "chatB-row"
                    : "chatB-message12"
                }`}
              >
                {item.sender !== currentUser.user_id && (
                  <img
                    className="chatB-avatar2"
                    src={selectedChat?.senderDetails?.profileImageLink || avatar2}
                    alt="Avatar"
                  />
                )}
                {item.sender === currentUser.user_id ? (
                  <div className="chatB-content13">
                    <div className="chatB-name-and-time3">
                      <div className="chatB-single-message-username">You</div>
                    </div>
                    <div className="chatB-input5">
                      <div className="chatB-text31">{item.content}</div>
                      <div className="chatB-sub-text3">
                        {formatTime(item.timestamp)}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="chatB-content8">
                    <div className="chatB-name-and-time">
                      <div className="chatB-usernames">
                        {selectedChat.senderDetails.display_name}
                      </div>
                    </div>
                    <div className="chatB-input2">
                      <div className="chatB-receiever">{item.content}</div>
                      <div className="chatB-sub-text">
                        {formatTime(item.timestamp)}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )
          )}
          <div ref={messagesEndRef} />
        </div>
      </div>
    </div>
  );
};

export { MessageBody };


// import React, { useEffect, useState, useRef } from "react";
// import "../ChatPageComponent.css"; // Correct relative path

// const MessageBody = ({ messages, currentUser, selectedChat }) => {
//   const messagesEndRef = useRef(null); // Create a ref for the end of the message list

//   // Auto-scroll to the bottom when messages update
//   useEffect(() => {
//     if (messagesEndRef.current && currentUser) {
//       messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
//     }
//   }, [messages, currentUser]); // Triggered when `messages` changes

//   const formatTime = (timestamp) => {
//     const date = new Date(timestamp);
//     const hours = date.getHours().toString().padStart(2, "0");
//     const minutes = date.getMinutes().toString().padStart(2, "0");
//     return `${hours}:${minutes}`;
//   };

//   const getDateLabel = (timestamp) => {
//     const messageDate = new Date(timestamp);
//     const today = new Date();
//     const yesterday = new Date(today);
//     yesterday.setDate(today.getDate() - 1);
    

    
//  console.log("MESSAGE DATE SIDEPANEL ", messageDate)
//  console.log("TODAY AND YESTERDAY: ", today, yesterday)
 
//     if (messageDate.toDateString() === today.toDateString()) return "Today";
//     else if (messageDate.toDateString() === yesterday.toDateString()) return "Yesterday";
//     else return messageDate;

//     // const daysDifference = Math.floor((today - messageDate) / (1000 * 60 * 60 * 24));
//     // if (daysDifference < 7) return messageDate.toLocaleDateString("en-US", { weekday: "long" });

//     // return messageDate.toLocaleDateString("en-US", { month: "short", day: "numeric", year: "numeric" });
//   };

//   let lastDate = null; // Tracks the date of the last processed message

//   return (
//     <div className="chatB-container1">
//       <div className="chatB-messages-parent">
//         <div className="chatB-messages">
//           {messages.map((message, index) => {
//             const messageDate = new Date(message.timestamp).toDateString();
//             const showDivider = messageDate !== lastDate; // Check if the date has changed
//             lastDate = messageDate; // Update the last date

//             return (
//               <React.Fragment key={index}>
//                 {/* Render the divider if the date has changed */}
//                 {showDivider && (
//                   <div className="chatB-divider-container">
//                     <div className="chatB-divider-line"></div>
//                     <div className="chatB-divider-label">{getDateLabel(message.timestamp)}</div>
//                     <div className="chatB-divider-line"></div>
//                   </div>
//                 )}
//                 <div
//                   className={`${
//                     message.sender === currentUser.user_id
//                       ? "chatB-row"
//                       : "chatB-message12"
//                   }`}
//                 >
//                   {message.sender !== currentUser.user_id && (
//                     <img
//                       className="chatB-avatar2"
//                       src={selectedChat?.senderDetails?.profileImageLink || avatar2}
//                       alt="Avatar"
//                     />
//                   )}
//                   {message.sender === currentUser.user_id ? (
//                     <div className="chatB-content13">
//                       <div className="chatB-name-and-time3">
//                         <div className="chatB-single-message-username">You</div>
//                       </div>
//                       <div className="chatB-input5">
//                         <div className="chatB-text31">{message.content}</div>
//                         <div className="chatB-sub-text3">
//                           {formatTime(message.timestamp)}
//                         </div>
//                       </div>
//                     </div>
//                   ) : (
//                     <div className="chatB-content8">
//                       <div className="chatB-name-and-time">
//                         <div className="chatB-usernames">
//                           {selectedChat.senderDetails.display_name}
//                         </div>
//                       </div>
//                       <div className="chatB-input2">
//                         <div className="chatB-receiever">{message.content}</div>
//                         <div className="chatB-sub-text">
//                           {formatTime(message.timestamp)}
//                         </div>
//                       </div>
//                     </div>
//                   )}
//                 </div>
//               </React.Fragment>
//             );
//           })}
//           <div ref={messagesEndRef} />
//         </div>
//       </div>
//     </div>
//   );
// };

// export { MessageBody };
