import React, { useEffect, useRef, useState } from 'react';
import "./EditAddress.css"
import { Navbar1 } from '../../../components/Navbar1/Navbar1';
import { Footer } from '../../../components/Footer/Footer';
import { Sidebar } from '../../../components/Sidebar/Sidebar';
import { firestore, doc, getDoc, auth, onAuthStateChanged } from '../../Login/FirebaseClient';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { SideBarSubNormal } from '../../../components/SideBars-Sub/SideBarSubNormal/SideBarSubNormal';
import divider from '../../../icons/Gerator/profile_settings/divider.svg';
import homeline_1 from '../../../icons/Gerator/profile_settings/homeline-1.svg';
import chevronright from '../../../icons/Gerator/profile_settings/chevronright.svg';
import divider1 from '../../../icons/Gerator/profile_settings/divider1.svg';
import divider2 from '../../../icons/Gerator/profile_settings/divider2.svg';
import help_icon from '../../../icons/Gerator/profile_settings/help-icon.svg';
import placeholder from '../../../icons/Gerator/profile_settings/placeholder.svg';
import chevrondown from '../../../icons/Gerator/profile_settings/chevrondown.svg';
import placeholder3 from '../../../icons/Gerator/profile_settings/placeholder3.svg';
import MapComponent from '../../../components/MapComponent/MapComponent';
import { EditAddressForm } from '../../../components/EditAddressFormComponent/EditAddressForm';
import { useGetCountryTaxQuery } from '../../../api/TaxonomyFormAPI';
import { Provider } from './EditAddressFormContext';
import { Loader } from '../../../components/Loader';
import { useLazyGetProfileImageLinkQuery } from '../../../api/Login&RegisterAPI';
// import divider2 from '../../../icons/Gerator/profile_settings/divider2.svg';
// import divider2 from '../../../icons/Gerator/profile_settings/divider2.svg';
// import divider2 from '../../../icons/Gerator/profile_settings/divider2.svg';



export const EditAddress = () => {

  const [backendValidation, setBackendValidation] = useState([])
  const formikRef = useRef(null);
  const countryTax = useGetCountryTaxQuery();
  const [isRequestFirst, setIsRequestFirst] = useState(true);
  const [editAddressSavedData, setEditAddressSavedData] = useState(null);

  const [showSuccessMessage, setShowSuccessMessage] = useState({
    message: "",
    color: "",
  });
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState("");
  const [allTaxonomy, setAllTaxonomy] = useState({ country: countryTax })
  const [profileImageLink, setProfileImageLink] = useState("");
  const [currentUsermeta, setCurrentUsermeta] = useState("");
  const [triggerNewProfileImageLink] = useLazyGetProfileImageLinkQuery();

  useEffect(() => {
    // const auth = getAuth();

    // Listen for authentication state change
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {

        if (user) {
          // Get user to check wizardStep
          const userDocRef = doc(firestore, "users", user.uid);
          const userDocSnapshot = await getDoc(userDocRef);

          if (userDocSnapshot.exists()) {
            const usermeta = userDocSnapshot.data(); // Default to 1 if not set

            if (usermeta.userStatus == 2) {
              navigate('/account-disabled');
              return;
            }

            if (usermeta.userType == 5) {
              navigate('/access-denied');
              return;
            }

            if (usermeta.wizardStep !== 0) {
              navigate('/account-setup-check-email');
              return;
            }
            // await triggerGetCurrentPlan().unwrap();
            setCurrentUser(user);
            setCurrentUsermeta(usermeta);
            // User is authenticated
          }
        }
      } else {
        // User is not authenticated, redirect to login
        navigate('/sign-in', { state: { message: 'Please log in to continue.' } });
      }
    });




    return () => unsubscribe(); // Cleanup listener on component unmount
  }, [navigate]);

  useEffect(() => {
    const refreshProfileImage = async () => {
      if (currentUsermeta && currentUsermeta.profileImage.expiresAt < Date.now() / 1000) {
        // Pre-signed URL has expired, trigger a new request to the backend
        const newImageData = await triggerNewProfileImageLink().unwrap();
        // Update the profile image link directly
        setProfileImageLink(newImageData.url);
      } else if (currentUsermeta && currentUsermeta.profileImage.url) {
        // Use the existing pre-signed URL to display the profile image
        setProfileImageLink(currentUsermeta.profileImage.url);
      }
    };

    refreshProfileImage();
  }, [currentUsermeta]);


  useEffect(() => {

    const allSuccess = [countryTax.isSuccess,].every(Boolean);


    if (allSuccess) {
      setAllTaxonomy({
        country: countryTax
      });
    }
  }, [
    countryTax.isSuccess, countryTax.data,
  ]);

  if (currentUser && profileImageLink) {
    return (
      <>
        <Helmet>
          <title>Settings - Edit Address</title>
        </Helmet>
        <div className="edit-address-form-dashboard-frame-layout-setti">
          <main className="edit-address-form-dashboard-frame-layout-setti1">
            <div className="edit-address-form-dashboard-frame-layout-setti-child"></div>

            <div className="edit-address-form-frame-child"></div>

            <Navbar1 userData={currentUser} navigate={navigate} profileImageLink={profileImageLink} />


            <section className="edit-address-form-divider-wrapper">
              <img
                className="edit-address-form-divider-icon"
                loading="lazy"
                alt=""
                src={divider}
              />
            </section>
            <section className="edit-address-form-sidebar-navigation-parent">
              <div className="edit-address-form-sidebar-navigation">

                <Sidebar><SideBarSubNormal userData={currentUser} /></Sidebar>


              </div>
              <div className="edit-address-form-main-parent">
                <div className="edit-address-form-main">
                  <div className="edit-address-form-header-section">
                    <div className="edit-address-form-container">
                      <div className="edit-address-form-page-header">
                        <div className="edit-address-form-breadcrumbs">
                          <div className="edit-address-form-tabs">
                            <div className="edit-address-form-breadcrumb-button-base">
                              <img
                                className="edit-address-form-search-lg-icon"
                                alt=""
                                src={homeline_1}
                              />
                            </div>
                            <img
                              className="edit-address-form-chevron-right-icon"
                              alt=""
                              src={chevronright}
                            />

                            <div className="edit-address-form-breadcrumb-button-base1">
                              <a className="edit-address-form-text22">Dashboard</a>
                            </div>
                            <img
                              className="edit-address-form-chevron-right-icon"
                              alt=""
                              src={chevronright}
                            />

                            {/* <div className="edit-address-form-breadcrumb-button-base2">
                            <div className="edit-address-form-text23">...</div>
                          </div>
                          <img
                            className="edit-address-form-chevron-right-icon"
                            alt=""
                            src={chevronright}
                          /> */}

                            <div className="edit-address-form-breadcrumb-button-base1">
                              <a className="edit-address-form-text22">Settings</a>
                            </div>
                            <img
                              className="edit-address-form-chevron-right-icon"
                              alt=""
                              src={chevronright}
                            />

                            <div className="edit-address-form-breadcrumb-button-base1">
                              <a className="edit-address-form-text22">Address</a>
                            </div>
                            <img
                              className="edit-address-form-chevron-right-icon"
                              alt=""
                              src={chevronright}
                            />

                            <div className="edit-address-form-breadcrumb-button-base4">
                              <a className="edit-address-form-text25">Edit Address</a>
                            </div>
                          </div>
                        </div>
                        <img
                          className="edit-address-form-divider-icon1"
                          alt=""
                          src={divider1}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="edit-address-form-main-child"></div>
                  <div className="edit-address-form-main-item"></div>
                </div>
                <div className="edit-address-form-main-wrapper">
                  <div className="edit-address-form-main1">
                    <div className="edit-address-form-header-content">
                      <div className="edit-address-form-header-section1">
                        <div className="edit-address-form-container1">
                          <div className="edit-address-form-page-header1">
                            <div className="edit-address-form-breadcrumbs1">
                              {/* <div className="edit-address-form-tabs1">
                              <div className="edit-address-form-breadcrumb-button-base">
                                <img
                                  className="edit-address-form-search-lg-icon"
                                  alt=""
                                  src={homeline_1}
                                />
                              </div>
                              <img
                                className="edit-address-form-chevron-right-icon"
                                alt=""
                                src={chevronright}
                              />

                              <div className="edit-address-form-breadcrumb-button-base6">
                                <div className="edit-address-form-text26">Settings</div>
                              </div>
                              <img
                                className="edit-address-form-chevron-right-icon"
                                alt=""
                                src={chevronright}
                              />

                              <div className="edit-address-form-breadcrumb-button-base7">
                                <div className="edit-address-form-text23">...</div>
                              </div>
                              <img
                                className="edit-address-form-chevron-right-icon"
                                alt=""
                                src={chevronright}
                              />

                              <div className="edit-address-form-breadcrumb-button-base3">
                                <div className="edit-address-form-text24">Another link</div>
                              </div>
                              <img
                                className="edit-address-form-chevron-right-icon"
                                alt=""
                                src={chevronright}
                              />

                              <div className="edit-address-form-breadcrumb-button-base4">
                                <div className="edit-address-form-text29">Team</div>
                              </div>
                            </div> */}
                            </div>
                            <div className="edit-address-form-content13">
                              <div className="edit-address-form-text-and-supporting-text">
                                <h1 className="edit-address-form-page-title">Edit Address</h1>
                                {/* <div className="edit-address-form-supporting-text1">
                                Manage your team members and their account
                                permissions here.
                              </div> */}
                              </div>
                              {/* <div className="edit-address-form-actions">
                              <div className="edit-address-form-button">
                                <img
                                  className="edit-address-form-placeholder-icon"
                                  alt=""
                                  src={placeholder}
                                />

                                <div className="edit-address-form-text-padding">
                                  <div className="edit-address-form-title">Tertiary</div>
                                </div>
                                <img
                                  className="edit-address-form-placeholder-icon"
                                  alt=""
                                  src={placeholder}
                                />
                              </div>
                              <div className="edit-address-form-button1">
                                <img
                                  className="edit-address-form-placeholder-icon"
                                  alt=""
                                  src="/profile_settings/placeholder1.svg"
                                />

                                <div className="edit-address-form-text-padding">
                                  <div className="edit-address-form-title">Secondary</div>
                                </div>
                                <img
                                  className="edit-address-form-placeholder-icon"
                                  alt=""
                                  src="/profile_settings/placeholder1.svg"
                                />
                              </div>
                              <div className="edit-address-form-button2">
                                <img
                                  className="edit-address-form-placeholder-icon"
                                  alt=""
                                  src="/profile_settings/placeholder2.svg"
                                />

                                <div className="edit-address-form-text-padding">
                                  <div className="edit-address-form-title">Secondary</div>
                                </div>
                                <img
                                  className="edit-address-form-placeholder-icon"
                                  alt=""
                                  src="/profile_settings/placeholder2.svg"
                                />
                              </div>
                              <div className="edit-address-form-button3">
                                <img
                                  className="edit-address-form-placeholder-icon"
                                  alt=""
                                  src={placeholder3}
                                />

                                <div className="edit-address-form-text-padding">
                                  <div className="edit-address-form-title">Primary</div>
                                </div>
                                <img
                                  className="edit-address-form-placeholder-icon"
                                  alt=""
                                  src={placeholder3}
                                />
                              </div>
                            </div> */}
                              {/* <div className="edit-address-form-input-dropdown">
                              <div className="edit-address-form-input-with-label1">
                                <div className="edit-address-form-label">Search</div>
                                <div className="edit-address-form-input1">
                                  <div className="edit-address-form-content3">
                                    <img
                                      className="edit-address-form-search-lg-icon"
                                      alt=""
                                      src="/profile_settings/searchlg.svg"
                                    />

                                    <input
                                      className="edit-address-form-text34"
                                      placeholder="Search"
                                      type="text"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="edit-address-form-hint-text">
                                This is a hint text to help user.
                              </div>
                            </div> */}
                            </div>
                            <img
                              className="edit-address-form-divider-icon2"
                              alt=""
                              src={divider2}
                            />
                          </div>
                          {/* <div className="edit-address-form-horizontal-tabs">
                          <div className="edit-address-form-tabs2">
                            <div className="edit-address-form-tab-button-base1">
                              <a className="edit-address-form-text35">Profile</a>
                            </div>
                            <div className="edit-address-form-tab-button-base1">
                              <a className="edit-address-form-text36">Business</a>
                            </div>
                            <div className="edit-address-form-tab-button-base">
                              <a className="edit-address-form-text25">Address</a>
                            </div>
                            <div className="edit-address-form-tab-button-base3">
                              <div className="edit-address-form-text38">Team</div>
                              <div className="edit-address-form-badge10">
                                <div className="edit-address-form-badge-count">4</div>
                              </div>
                            </div>
                            <div className="edit-address-form-tab-button-base1">
                              <div className="edit-address-form-text39">Plan</div>
                            </div>
                            <div className="edit-address-form-tab-button-base3">
                              <a className="edit-address-form-text40">Billing</a>
                              <div className="edit-address-form-badge10">
                                <div className="edit-address-form-badge-count">2</div>
                              </div>
                            </div>
                            <div className="edit-address-form-tab-button-base1">
                              <a className="edit-address-form-text40">Social</a>
                            </div>
                            <div className="edit-address-form-tab-button-base7">
                              <a className="edit-address-form-text43">Preferences</a>
                              <div className="edit-address-form-badge10">
                                <div className="edit-address-form-badge-count">2</div>
                              </div>
                            </div>
                            <div className="edit-address-form-tab-button-base1">
                              <div className="edit-address-form-text45">Integrations</div>
                            </div>
                            <div className="edit-address-form-tab-button-base9">
                              <div className="edit-address-form-text46">API</div>
                            </div>
                          </div>
                        </div> */}
                        </div>
                      </div>
                    </div>
                    {/* <div className="edit-address-form-button4">
                <img
                  className="edit-address-form-placeholder-icon"
                  alt=""
                  src={placeholder}
                />

                <div className="edit-address-form-text-padding4">
                  <div className="edit-address-form-text47">Tertiary</div>
                </div>
                <img
                  className="edit-address-form-placeholder-icon"
                  alt=""
                  src={placeholder}
                />
              </div>
              <div className="edit-address-form-button5">
                <img
                  className="edit-address-form-placeholder-icon"
                  alt=""
                  src="/profile_settings/placeholder1.svg"
                />

                <div className="edit-address-form-text-padding4">
                  <div className="edit-address-form-text47">Secondary</div>
                </div>
                <img
                  className="edit-address-form-placeholder-icon"
                  alt=""
                  src="/profile_settings/placeholder1.svg"
                />
              </div>
              <div className="edit-address-form-dropdown">
                <img
                  className="edit-address-form-dots-vertical-icon"
                  alt=""
                  src="/profile_settings/dotsvertical.svg"
                />
              </div> */}






                    <Provider value={{ editAddressSavedData, setEditAddressSavedData, isRequestFirst, setIsRequestFirst, allTaxonomy, backendValidation, setBackendValidation }}>
                      <EditAddressForm formikRef={formikRef} setShowSuccessMessage={setShowSuccessMessage} currentUsermeta={currentUsermeta} />
                    </Provider>
































                    {showSuccessMessage.message && (
                      <div
                        style={{
                          fontSize: "14px",
                          color: showSuccessMessage.color,
                          padding: "15px 10px",
                          border: "1px solid " + showSuccessMessage.color,
                          borderRadius: "10px",
                          margin: "0 auto",
                        }}
                      >
                        {showSuccessMessage.message}
                      </div>
                    )}
                  </div>

                </div>
              </div>
            </section>
            <img className="edit-address-form-divider-icon5" alt="" src={divider} />
            {/* <footer className="edit-address-form-footer1"> */}
            <Footer />

            {/* <div className="edit-address-form-container2">
          <div className="edit-address-form-content47">
            <div className="edit-address-form-footer-text">
              © Gerator Asia LLP. All rights reserved.
            </div>
            <div className="edit-address-form-logo1">
              <div className="edit-address-form-logo-wrapper1">
                <div className="edit-address-form-logo-container">
                  <div className="edit-address-form-footer-text">All prices are in USD</div>
                </div>
                <div className="edit-address-form-logomark">
                  <div className="edit-address-form-logomark1">
                    <img
                      className="edit-address-form-gerator-10-2-icon"
                      loading="lazy"
                      alt=""
                      src="/profile_settings/gerator10-2@2x.png"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
            {/* </footer> */}

          </main>
        </div>
      </>

    )
  } else {
    return <Loader />;
  }



}

