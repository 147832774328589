import React from 'react';
import "./TransactionTypeComponent.css";
import divider from '../../icons/Gerator/dashboard/divider.svg';

export const TransactionTypeComponent = ({ transactionType, slug }) => {
    return (
        <div className="dashboard-device-component-badge-wrapper">
            <div className={`dashboard-device-component-badge3 type-${slug || ""}`}>
                <div className="detailed-device-component-text29 type-sale-">{transactionType || 'not found'}</div>
            </div>
        </div>
    )
}

