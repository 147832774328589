import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  serviceCategory:'',
  serviceValueProposition:'',
  clinicalApplications:'',
  purposeUse:'',
  deviceCategory:'',
  physicalLocation:'',
}

const AddWantedServiceSlice = createSlice({
  name: "addWantedService",
  initialState,
  reducers: {
    updateWantedServiceField(state, action) {
      const {field, value} = action.payload
      if (value !== undefined) { 
        state[field] = value; 
      }
    },
    clearWantedServiceForm() {
      return initialState;
    }
  },
});

export const { updateWantedServiceField,clearWantedServiceForm } = AddWantedServiceSlice.actions

export default AddWantedServiceSlice.reducer

