import React, { useRef } from 'react';
import "./ArchiveSpare.css"
import { Navbar1 } from '../../../components/Navbar1/Navbar1';
import { FooterAddForms } from '../../../components/Footer/FooterAddForms/FooterAddForms';
import React, { useEffect, useState } from "react";
import { useLazyGetSparesQuery, useDeleteSpareMutation } from "../../../api/SpareAPI ";

import { getCustomizedDate } from "../../../helper/ServiceHelper";
import { Loader } from "../../../components/Loader";
import { Link, useNavigate } from "react-router-dom";
import divider from '../../../icons/Gerator/archive_page/divider.svg';
import divider1 from '../../../icons/Gerator/archive_page/divider1.svg';

import divider2 from '../../../icons/Gerator/archive_page/divider2.svg';
import divider4 from '../../../icons/Gerator/archive_page/divider-4.svg';
import divider12 from '../../../icons/Gerator/archive_page/divider-12.svg';
import divider13 from '../../../icons/Gerator/archive_page/divider-13.svg';
import divider21 from '../../../icons/Gerator/archive_page/divider-21.svg';

import eye from '../../../icons/Gerator/archive_page/eye.svg';

import rectangle_41_2x from '../../../icons/Gerator/archive_page/rectangle-41@2x.png';

import dotsvertical from '../../../icons/Gerator/archive_page/dotsvertical.svg';
import dot1 from '../../../icons/Gerator/archive_page/-dot1.svg';
import dot from '../../../icons/Gerator/archive_page/-dot.svg';

import help_icon2 from '../../../icons/Gerator/archive_page/help-icon2.svg';
import help_icon from '../../../icons/Gerator/archive_page/help-icon.svg';


import featured_icon1 from '../../../icons/Gerator/archive_page/featured-icon1.svg';

import target_04 from "../../../icons/Gerator/dashboard/target_04.png"

import chevronright from '../../../icons/Gerator/archive_page/chevronright.svg';
import chevrondown from '../../../icons/Gerator/archive_page/chevrondown.svg';

import placeholder from '../../../icons/Gerator/archive_page/placeholder.svg';
import placeholder1 from '../../../icons/Gerator/archive_page/placeholder1.svg';
import placeholder2 from '../../../icons/Gerator/archive_page/placeholder2.svg';
import placeholder3 from '../../../icons/Gerator/archive_page/placeholder3.svg';

import homeline from '../../../icons/Gerator/archive_page/homeline.svg';
import searchlg from '../../../icons/Gerator/archive_page/searchlg.svg';

import calendar from '../../../icons/Gerator/archive_page/calendar.svg';


import radar_1_1_2x from '../../../icons/Gerator/archive_page/radar-1-1@2x.png';
import deal_2_1_2x from '../../../icons/Gerator/archive_page/deal-2-1@2x.png';
import magnifier_1_2x from '../../../icons/Gerator/archive_page/magnifier-1@2x.png';

import group_58_2x from '../../../icons/Gerator/archive_page/group-58@2x.png';

import dotsvertical from '../../../icons/Gerator/archive_page/dotsvertical.svg';
import filterfunnel01 from '../../../icons/Gerator/archive_page/filterfunnel01.svg';
import searchlg from '../../../icons/Gerator/archive_page/searchlg.svg';
import filterlines from '../../../icons/Gerator/archive_page/filterlines.svg';
import dot1 from '../../../icons/Gerator/archive_page/-dot1.svg';
import xclose from '../../../icons/Gerator/archive_page/xclose.svg';
import xclose10 from '../../../icons/Gerator/archive_page/xclose-10.svg';

import lock04 from '../../../icons/Gerator/archive_page/lock04.svg';
import lockunlocked04 from '../../../icons/Gerator/archive_page/lockunlocked04.svg';
import arrowsquareupright from '../../../icons/Gerator/archive_page/arrowsquareupright.svg';
import markerpin02 from '../../../icons/Gerator/archive_page/markerpin02.svg';
import icon1 from '../../../icons/Gerator/archive_page/icon-1.svg';
import sliders02 from '../../../icons/Gerator/archive_page/sliders02.svg';
import { useLazyGetUserQuery } from "../../../api/Login&RegisterAPI";
import { auth, onAuthStateChanged } from '../../Login/FirebaseClient';
import { decryptErrorData } from "../../../helper/AESHelper";
import { Helmet } from 'react-helmet-async';
import { PostStatusLIstingComponent } from '../../../components/PostStatusLIstingComponent/PostStatusLIstingComponent';
import { ThreeDotsListingComponent } from '../../../components/ThreeDotsListingComponent/ThreeDotsListingComponent';
import { TransactionTypeComponent } from '../../../components/TransactionTypeComponent/TransactionTypeComponent';
import { useAddRadarSpareMutation, useDeleteRadarSpareMutation } from '../../../api/Radar/RadarSpareAPI';

export const ArchiveSpare = () => {

  let dataLength = 0;
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState("");


  // const [deleteSpare] = useDeleteSpareMutation();
  const [triggerGetUser, { data, isSuccess, error, isLoading, isError }] = useLazyGetUserQuery();
  const [triggerGetSpare, { data: spareData,
    isLoading: isSpareLoading,
    isSuccess: isSpareSuccess,
    isError: isSpareError,
    error: spareError }] = useLazyGetSparesQuery();

  const [addToRadar] = useAddRadarSpareMutation();

  const [deleteFromRadar] = useDeleteRadarSpareMutation();

  let content;
  //For Threedots in listing dropdown
  const [activeThreeDotId, setActiveThreeDotId] = useState(null);
  const threeDotsRef = useRef(null);
  const [isClickBlocked, setIsClickBlocked] = useState(false);
  // const [totalListings, setTotalListings] = useState("0");


  const toggleThreeDotsDropdown = (id) => {
    if (isClickBlocked) return; // Prevent rapid toggle

    if (activeThreeDotId === id) {
      setActiveThreeDotId(null); // Close if the same one is clicked again
      setIsClickBlocked(true);
      setTimeout(() => setIsClickBlocked(false), 400); // Small delay to avoid reopening
    } else {
      setActiveThreeDotId(id); // Open the clicked one
    }
  };

  const handleRemoveRadar = async (spareId) => {
    await deleteFromRadar(spareId);
    window.location.reload();
  };

  const handleAddRadar = async (spareId) => {
    await addToRadar(spareId);
    window.location.reload();
  };

  //Onclick listener for threedots dropdown
  useEffect(() => {
    const handleThreeDotClickOutside = (event) => {
      if (threeDotsRef.current && !threeDotsRef.current.contains(event.target)) {
        setActiveThreeDotId(null); // Close any open dropdown when clicking outside
      }
    };

    document.addEventListener("mousedown", handleThreeDotClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleThreeDotClickOutside);
    };
  }, []);


  useEffect(() => {
    // const auth = getAuth();

    // Listen for authentication state change
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is authenticated
        setCurrentUser(user);
        triggerGetSpare();
      } else {
        // User is not authenticated, redirect to login
        navigate('/sign-in', { state: { message: 'Please log in to continue.' } });
      }
    });

    return () => unsubscribe(); // Cleanup listener on component unmount
  }, [navigate, triggerGetSpare]);


  if (error) {
    console.log(error);
    if (isError) {
      const decryptedData = decryptErrorData(error.data);
      console.log(decryptedData);
      // navigate("/sign-in", { state: decryptedData.message });
      navigate("/sign-in", { state: { message: decryptedData.message } });
    }
    else {
      navigate("/sign-in", { state: { message: "Internal server error" } });
    }
    return; // Optionally handle error differently
  }

  if (isSpareSuccess) {

    if (spareData.data.length > 0) {
      dataLength = spareData.data.length;
      content = spareData.data.map((spare, index) =>
      (
        <div className="dashboard-device-component-dashboard-content-items" key={index}>
          <div className="dashboard-device-component-dashboard-divider-container1">
            <div className="dashboard-device-component-dashboard-rectangle-parent5">
              <img
                className="dashboard-device-component-dashboard-frame-child7"
                alt=""
                src={spare.featureImage}
              />

              <div className="dashboard-device-component-dashboard-frame-parent1">
                <div className="dashboard-device-component-dashboard-action-fields">
                  <div className="dashboard-device-component-dashboard-input-elements">
                    <Link to={`/spare/${spare.id}/${spare.title}`} className="dashboard-device-component-dashboard-text78">
                      {spare.title}
                    </Link>
                    <div className="dashboard-device-component-dashboard-frame-parent21">
                      <div className="dashboard-device-component-dashboard-badge-item-parent">
                        <div className="dashboard-device-component-dashboard-badge-parent">

                          <PostStatusLIstingComponent postStatus={spare.tax_post_status.name} postSlug={spare.tax_post_status.slug} postId={spare.id} />

                        </div>
                        <div className="dashboard-device-component-dashboard-frame-parent4">
                          <div className="dashboard-device-component-dashboard-end-label">
                            <div className="dashboard-device-component-dashboard-search-icon-input">4.9/5</div>
                          </div>
                          <div className="dashboard-device-component-dashboard-type-support">
                            <div className="dashboard-device-component-dashboard-supporting-text9">
                              99,999 reviews
                            </div>
                          </div>
                        </div>
                        <div className="dashboard-device-component-dashboard-badge33">
                          <div className="dashboard-device-component-dashboard-card-badge-number">
                            {spare.tax_device_category.name}
                          </div>
                        </div>
                      </div>
                      <div className="dashboard-device-component-dashboard-frame-parent24">
                        <div className="dashboard-device-component-dashboard-location-pin-parent">
                          <div className="dashboard-device-component-dashboard-search-input">
                            <img
                              className="dashboard-device-component-dashboard-marker-pin-02-icon2"
                              alt=""
                              src={markerpin02}
                            />
                          </div>
                          <div className="dashboard-device-component-dashboard-location3">{spare.spare_address.addressTownCity}, {spare.spare_address.tax_country.name}</div>
                        </div>
                        <div className="dashboard-device-component-dashboard-frame-wrapper18">
                          <div className="dashboard-device-component-dashboard-badge-elements-copy">
                            <div className="dashboard-device-component-dashboard-badge36">
                              <div className="dashboard-device-component-dashboard-badge-number-element">
                                11 Health
                              </div>
                            </div>
                            <div className="dashboard-device-component-dashboard-badge37">
                              <div className="dashboard-device-component-dashboard-second-badge-count">{spare.tax_oem.name}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="dashboard-device-component-dashboard-frame-parent26">
                        <div className="dashboard-device-component-dashboard-frame-wrapper19">
                          <div className="dashboard-device-component-dashboard-legend-series">
                            <div className="dashboard-device-component-dashboard-search-input">
                              <img
                                className="dashboard-device-component-dashboard-marker-pin-02-icon2"
                                alt=""
                                src={calendar}
                              />
                            </div>
                            <div className="dashboard-device-component-dashboard-type-of-work2">{getCustomizedDate(spare.createdAt)}</div>
                          </div>
                        </div>
                        <div className="dashboard-device-component-dashboard-badge38">
                          <div className="dashboard-device-component-dashboard-third-badge-number">
                            {spare.tax_clinical_application.name}
                          </div>
                        </div>
                      </div>
                      <div className="dashboard-device-component-dashboard-badge39">
                        <div className="dashboard-device-component-dashboard-location-badge-elements">
                          Accounting
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="dashboard-device-component-dashboard-filter-elements">
                  <div className="dashboard-device-component-dashboard-dropdown10" onClick={() => toggleThreeDotsDropdown(index)} ref={threeDotsRef}>
                    <img
                      className="dashboard-device-component-dashboard-dots-vertical-icon"
                      alt=""
                      src={dotsvertical}
                    />
                    {activeThreeDotId === index && <ThreeDotsListingComponent url={`#`} />}
                  </div>
                  <div className="dashboard-device-component-dashboard-filter-elements-inner">
                    <div className="dashboard-device-component-dashboard-price-parent">
                      <TransactionTypeComponent transactionType={spare.tax_transaction_type.name} slug={spare.tax_transaction_type.slug} />
                      <div className="dashboard-device-component-dashboard-price2">${spare.price}</div>
                      <div className="dashboard-device-component-dashboard-dropdown-elements">
                        <div className="dashboard-device-component-dashboard-dropdown-options-parent">
                          <div className="dashboard-device-component-dashboard-text83">{spare.tax_unit_measure_device_spare.name}</div>
                          <div className="dashboard-device-component-dashboard-frame-wrapper3">
                            <div className="dashboard-device-component-dashboard-radar-1-1-container">
                              {/* <img
                        className="dashboard-device-component-dashboard-magnifier-1-icon"
                        alt=""
                        src={radar_1_1_2x}
                      /> */}
                              {!spare.isAuthor && (
                                spare.addedToRadar ? (
                                  <img
                                    className="dashboard-device-component-dashboard-magnifier-1-icon"
                                    alt=""
                                    onClick={() => handleRemoveRadar(spare.id)}
                                    src={target_04}
                                    style={{ cursor: "pointer" }}
                                  />
                                ) : (
                                  <img
                                    className="dashboard-device-component-dashboard-magnifier-1-icon"
                                    alt=""
                                    onClick={() => handleAddRadar(spare.id)}
                                    src={radar_1_1_2x}
                                    style={{ cursor: "pointer" }}
                                  />
                                )
                              )}
                              <img
                                className="dashboard-device-component-dashboard-magnifier-1-icon"
                                alt=""
                                src={deal_2_1_2x}
                              />

                              <img
                                className="dashboard-device-component-dashboard-magnifier-1-icon"
                                alt=""
                                src={magnifier_1_2x}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <img className="dashboard-device-component-dashboard-divider-icon8" alt="" src={divider2} />

            <div className="dashboard-device-component-dashboard-frame-parent71">
              <div className="dashboard-device-component-dashboard-buttonsbutton-parent1">
                <div className="dashboard-legend-series">
                  <img
                    className="dashboard-location-column-child"
                    alt=""
                    src={group_58_2x}
                  />

                  <div className="dashboard-location-column-inner">
                    <div className="dashboard-date-picker-dropdown">
                      <div className="dashboard-name">{spare.spare_business.business_user.displayName}</div>
                      {/* <div className="dashboard-role">{spare.spare_business.businessName}</div> */}
                      <Link className="dashboard-role" to={`/business/${spare.spare_business.id}/${spare.spare_business.businessName}`}>
                        {spare.spare_business.businessName}
                      </Link>
                    </div>
                  </div>
                </div>
                {/* <div className='dashboard-device-component-main-buttons-group'>
          <div className="dashboard-device-component-dashboard-buttonsbutton13">
            <img
              className="dashboard-device-component-dashboard-arrow-narrow-left-icon"
              alt=""
              src={eye}
            />

            <div className="dashboard-device-component-dashboard-search-input">
              <div className="dashboard-device-component-dashboard-text67">View</div>
            </div>
          </div>
          <div className="dashboard-device-component-dashboard-buttonsbutton25">
            <div className="dashboard-device-component-dashboard-icon-wrapper">
              <img className="dashboard-device-component-dashboard-icon1" alt="" src={icon1} />
            </div>
            <div className="dashboard-device-component-dashboard-search-input">
              <div className="dashboard-device-component-dashboard-text68">Sales IQ</div>
            </div>
            <img
              className="dashboard-device-component-dashboard-arrow-narrow-left-icon"
              alt=""
              src={lock04}
            />
          </div>
          <div className="dashboard-device-component-dashboard-buttonsbutton26">
            <div className="dashboard-device-component-dashboard-icon-wrapper">
              <img className="dashboard-device-component-dashboard-icon1" alt="" src={icon1} />
            </div>
            <div className="dashboard-device-component-dashboard-search-input">
              <div className="dashboard-device-component-dashboard-text68">Sales IQ</div>
            </div>
            <img
              className="dashboard-device-component-dashboard-arrow-narrow-left-icon"
              alt=""
              src={lockunlocked04}
            />
          </div>
          <div className="dashboard-device-component-dashboard-buttonsbutton27">
            <img
              className="dashboard-device-component-dashboard-upload-04-icon"
              alt=""
              src={messagechatsquare}
            />

            <div className="dashboard-device-component-dashboard-text145">Messages</div>
            <div className="dashboard-device-component-dashboard-ellipse-parent">
              <div className="dashboard-device-component-dashboard-ellipse-div"></div>
              <div className="dashboard-device-component-dashboard-div">2</div>
            </div>
          </div>
        </div> */}

              </div>
              <div className="dashboard-device-component-dashboard-arrow-square-up-right-wrapper1">
                <img
                  className="dashboard-device-component-dashboard-arrow-square-up-right-icon1"
                  alt=""
                  src={arrowsquareupright}
                />
              </div>
            </div>
          </div>
        </div>
      ))
    }
    else {
      content = (

        <div className="content-44" >
          <img
            className="bifocal-optometry-2"
            alt="Bifocal optometry"
            src="/img/bifocal-optometry-measurement-spare-2.png"
          />
          <div className="text-and-supporting-16" style={{ alignSelf: "unset !important" }}>
            <div className="text-157">No listings found</div>
            <p className="supporting-text-36">
              Your search “Landing page design” did not match any projects. Please try again.
            </p>
          </div>
          <div className="actions-10">
            <div className="buttons-button-37">
              <div className="text-padding-18">
                <div className="text-158">Clear search</div>
              </div>
            </div>
            <div className="buttons-button-38">
              <img className="img-26" alt="Plus" src="/img/plus.svg" />
              <div className="text-padding-18">
                <Link to={"/add-wanted-spare"}>
                  <div className="text-159">Add Listing</div>
                </Link>
              </div>
            </div>
          </div>
        </div>

      )
    }

  } else {
    return <Loader />
  }

  return (
    <>
      <Helmet>
        <title>Archive - Spares</title>
      </Helmet>
      <div className="archive-spare-listings-search-results-list">

        <Navbar1 userData={currentUser} navigate={navigate} />
        <div className='archive-spare-mid-container'>
          <div className="archive-spare-slide-out">
            <img className="archive-spare-divider-icon14" alt="" src={divider12} />

            <div className="archive-spare-rectangle-parent4">
              <div className="archive-spare-frame-child8"></div>
              <div className="archive-spare-frame-child9"></div>
              <img className="archive-spare-dot-icon7" alt="" src={dot} />

              <img className="archive-spare-dot-icon8" alt="" src={dot} />

              <img className="archive-spare-dot-icon9" alt="" src={dot} />

              <img className="archive-spare-dot-icon10" alt="" src={dot} />

              <img className="archive-spare-dot-icon11" alt="" src={dot} />

              <img className="archive-spare-dot-icon12" alt="" src={dot} />

              <img className="archive-spare-dot-icon13" alt="" src={dot} />

              <div className="archive-spare-frame-parent28">
                <div className="archive-spare-slide-out-menu-header-parent">
                  <div className="archive-spare-slide-out-menu-header">
                    <div className="archive-spare-content24">
                      <img
                        className="archive-spare-featured-icon1"
                        alt=""
                        src={featured_icon1}
                      />

                      <div className="archive-spare-text-and-supporting-text6">
                        <a className="archive-spare-text184">Filters</a>
                        <div className="archive-spare-supporting-text57">
                          Apply filters to table data.
                        </div>
                      </div>
                    </div>
                    <div className="archive-spare-button-close-x">
                      <img
                        className="archive-spare-search-lg-icon"
                        alt=""
                        src={xclose10}
                      />
                    </div>
                  </div>
                  <div className="archive-spare-frame-wrapper13">
                    <div className="archive-spare-slide-out-title-parent">
                      <a className="archive-spare-slide-out-title">Price</a>
                      <div className="archive-spare-navigation">
                        <img
                          className="archive-spare-marker-pin-02-icon2"
                          alt=""
                          src={chevrondown}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="archive-spare-frame-parent29">
                  <div className="archive-spare-frame-parent30">
                    <div className="archive-spare-frame-wrapper14">
                      <div className="archive-spare-frame-parent31">
                        <div className="archive-spare-background-parent">
                          <div className="archive-spare-background"></div>
                          <div className="archive-spare-progress">
                            <div className="archive-spare-progress-line"></div>
                            <div className="archive-spare-control-handle">
                              <div className="archive-spare-tooltip1">
                                <div className="archive-spare-content25">
                                  <div className="archive-spare-text185">0</div>
                                </div>
                              </div>
                              <div className="archive-spare-handle"></div>
                            </div>
                            <div className="archive-spare-control-handle">
                              <div className="archive-spare-tooltip2">
                                <div className="archive-spare-content26">
                                  <div className="archive-spare-text186">9,999,999,99</div>
                                </div>
                              </div>
                              <div className="archive-spare-handle"></div>
                            </div>
                          </div>
                        </div>
                        <div className="archive-spare-input-fields-parent">
                          <div className="archive-spare-input-fields">
                            <div className="archive-spare-input-field">
                              <div className="archive-spare-input-with-label4">
                                <div className="archive-spare-label5">Email</div>
                                <div className="archive-spare-input4">
                                  <div className="archive-spare-content27">
                                    <div className="archive-spare-text187">Min Value</div>
                                  </div>
                                  <img
                                    className="archive-spare-help-icon1"
                                    alt=""
                                    src="/archive_page/help-icon1.svg"
                                  />
                                </div>
                              </div>
                              <div className="archive-spare-hint-text">
                                This is a hint text to help user.
                              </div>
                            </div>
                            <div className="archive-spare-text188">–</div>
                            <div className="archive-spare-input-field">
                              <div className="archive-spare-input-with-label4">
                                <div className="archive-spare-label5">Email</div>
                                <div className="archive-spare-input4">
                                  <div className="archive-spare-content27">
                                    <div className="archive-spare-text187">Max Value</div>
                                  </div>
                                  <img
                                    className="archive-spare-help-icon1"
                                    alt=""
                                    src="/archive_page/help-icon1.svg"
                                  />
                                </div>
                              </div>
                              <div className="archive-spare-hint-text">
                                This is a hint text to help user.
                              </div>
                            </div>
                          </div>
                          <div className="archive-spare-checkboxes">
                            <div className="archive-spare-collapsed-names">
                              <input className="archive-spare-input6" type="checkbox" />

                              <div className="archive-spare-price-parent">
                                <div className="archive-spare-text8">US$ 0 — US$ 1,000</div>
                                <div className="archive-spare-hint-text">
                                  Save my login details for next time.
                                </div>
                              </div>
                            </div>
                            <div className="archive-spare-collapsed-names">
                              <input className="archive-spare-input6" type="checkbox" />

                              <div className="archive-spare-price-parent">
                                <div className="archive-spare-text8">US$ 1,001 — US$ 5,000</div>
                                <div className="archive-spare-hint-text">
                                  Save my login details for next time.
                                </div>
                              </div>
                            </div>
                            <div className="archive-spare-collapsed-names">
                              <input className="archive-spare-input6" type="checkbox" />

                              <div className="archive-spare-price-parent">
                                <div className="archive-spare-text8">US$ 5,001 — US$ 15,000</div>
                                <div className="archive-spare-hint-text">
                                  Save my login details for next time.
                                </div>
                              </div>
                            </div>
                            <div className="archive-spare-collapsed-names">
                              <input className="archive-spare-input6" type="checkbox" />

                              <div className="archive-spare-price-parent">
                                <div className="archive-spare-text8">US$ 15,001 — US$ 9,999,999</div>
                                <div className="archive-spare-hint-text">
                                  Save my login details for next time.
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="archive-spare-frame-child10"></div>
                  </div>
                  <img
                    className="archive-spare-divider-icon15"
                    alt=""
                    src={divider13}
                  />

                  <div className="archive-spare-section-wrapper">
                    <div className="archive-spare-section-parent">
                      <div className="archive-spare-section">
                        <div className="archive-spare-content-parent">
                          <div className="archive-spare-content29">
                            <img className="archive-spare-dot-icon" alt="" src={dot} />

                            <div className="archive-spare-text194">Date Posted</div>
                          </div>
                          <img
                            className="archive-spare-search-lg-icon"
                            alt=""
                            src={chevrondown}
                          />
                        </div>
                      </div>
                      <div className="archive-spare-input-fields">
                        <div className="archive-spare-input-field2">
                          <div className="archive-spare-input-with-label4">
                            <div className="archive-spare-label5">Email</div>
                            <div className="archive-spare-input10">
                              <div className="archive-spare-content27">
                                <div className="archive-spare-text187">Jan 6, 2024</div>
                              </div>
                              <img
                                className="archive-spare-help-icon1"
                                alt=""
                                src={help_icon2}
                              />
                            </div>
                          </div>
                          <div className="archive-spare-hint-text">
                            This is a hint text to help user.
                          </div>
                        </div>
                        <div className="archive-spare-text188">–</div>
                        <div className="archive-spare-input-field2">
                          <div className="archive-spare-input-with-label4">
                            <div className="archive-spare-label5">Email</div>
                            <div className="archive-spare-input10">
                              <div className="archive-spare-content27">
                                <div className="archive-spare-text187">Jan 12, 2024</div>
                              </div>
                              <img
                                className="archive-spare-help-icon1"
                                alt=""
                                src={help_icon2}
                              />
                            </div>
                          </div>
                          <div className="archive-spare-hint-text">
                            This is a hint text to help user.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <img
                    className="archive-spare-divider-icon15"
                    alt=""
                    src={divider13}
                  />

                  <div className="archive-spare-section-wrapper">
                    <div className="archive-spare-section1">
                      <div className="archive-spare-content-parent">
                        <div className="archive-spare-content29">
                          <img className="archive-spare-dot-icon" alt="" src={dot} />

                          <div className="archive-spare-text198">Seller Role</div>
                        </div>
                        <img
                          className="archive-spare-search-lg-icon"
                          alt=""
                          src={chevrondown}
                        />
                      </div>
                      <div className="archive-spare-checkboxes1">
                        <div className="archive-spare-collapsed-names">
                          <input className="archive-spare-input6" type="checkbox" />

                          <div className="archive-spare-price-parent">
                            <div className="archive-spare-text8">Dealer/Distributor</div>
                            <div className="archive-spare-hint-text">
                              Save my login details for next time.
                            </div>
                          </div>
                        </div>
                        <div className="archive-spare-collapsed-names">
                          <input className="archive-spare-input6" type="checkbox" />

                          <div className="archive-spare-price-parent">
                            <div className="archive-spare-text8">Manufacturer</div>
                            <div className="archive-spare-hint-text">
                              Save my login details for next time.
                            </div>
                          </div>
                        </div>
                        <div className="archive-spare-collapsed-names">
                          <input className="archive-spare-input6" type="checkbox" />

                          <div className="archive-spare-price-parent">
                            <div className="archive-spare-text8">Owner/Employee</div>
                            <div className="archive-spare-hint-text">
                              Save my login details for next time.
                            </div>
                          </div>
                        </div>
                        <div className="archive-spare-collapsed-names">
                          <input className="archive-spare-input6" type="checkbox" />

                          <div className="archive-spare-price-parent">
                            <div className="archive-spare-text8">Third Party/Agent</div>
                            <div className="archive-spare-hint-text">
                              Save my login details for next time.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <img
                    className="archive-spare-divider-icon15"
                    alt=""
                    src={divider13}
                  />

                  <div className="archive-spare-section-wrapper">
                    <div className="archive-spare-section1">
                      <div className="archive-spare-content-parent">
                        <div className="archive-spare-content29">
                          <img className="archive-spare-dot-icon" alt="" src={dot} />

                          <div className="archive-spare-text203">Transaction Type</div>
                        </div>
                        <img
                          className="archive-spare-search-lg-icon"
                          alt=""
                          src={chevrondown}
                        />
                      </div>
                      <div className="archive-spare-checkboxes1">
                        <div className="archive-spare-collapsed-names">
                          <input className="archive-spare-input6" type="checkbox" />

                          <div className="archive-spare-price-parent">
                            <div className="archive-spare-text8">Exchange</div>
                            <div className="archive-spare-hint-text">
                              Save my login details for next time.
                            </div>
                          </div>
                        </div>
                        <div className="archive-spare-collapsed-names">
                          <input className="archive-spare-input6" type="checkbox" />

                          <div className="archive-spare-price-parent">
                            <div className="archive-spare-text8">Rent/Lease</div>
                            <div className="archive-spare-hint-text">
                              Save my login details for next time.
                            </div>
                          </div>
                        </div>
                        <div className="archive-spare-collapsed-names">
                          <input className="archive-spare-input6" type="checkbox" />

                          <div className="archive-spare-price-parent">
                            <div className="archive-spare-text8">Sale</div>
                            <div className="archive-spare-hint-text">
                              Save my login details for next time.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <img
                    className="archive-spare-divider-icon15"
                    alt=""
                    src={divider13}
                  />

                  <div className="archive-spare-section-wrapper">
                    <div className="archive-spare-section3">
                      <div className="archive-spare-content-parent">
                        <div className="archive-spare-content29">
                          <img className="archive-spare-dot-icon" alt="" src={dot} />

                          <div className="archive-spare-text207">Status</div>
                        </div>
                        <img
                          className="archive-spare-search-lg-icon"
                          alt=""
                          src={chevrondown}
                        />
                      </div>
                    </div>
                  </div>
                  <img
                    className="archive-spare-divider-icon15"
                    alt=""
                    src={divider13}
                  />

                  <div className="archive-spare-section-wrapper">
                    <div className="archive-spare-section3">
                      <div className="archive-spare-content-parent">
                        <div className="archive-spare-content29">
                          <img className="archive-spare-dot-icon" alt="" src={dot} />

                          <div className="archive-spare-text208">Warranty</div>
                        </div>
                        <img
                          className="archive-spare-search-lg-icon"
                          alt=""
                          src={chevrondown}
                        />
                      </div>
                    </div>
                  </div>
                  <img
                    className="archive-spare-divider-icon15"
                    alt=""
                    src={divider13}
                  />

                  <div className="archive-spare-section-wrapper">
                    <div className="archive-spare-section3">
                      <div className="archive-spare-content-parent">
                        <div className="archive-spare-content29">
                          <img className="archive-spare-dot-icon" alt="" src={dot} />

                          <div className="archive-spare-text209">Shipping</div>
                        </div>
                        <img
                          className="archive-spare-search-lg-icon"
                          alt=""
                          src={chevrondown}
                        />
                      </div>
                    </div>
                  </div>
                  <img
                    className="archive-spare-divider-icon15"
                    alt=""
                    src={divider13}
                  />

                  <div className="archive-spare-section-wrapper">
                    <div className="archive-spare-section-parent">
                      <div className="archive-spare-actions6">
                        <div className="archive-spare-navigation">
                          <input className="archive-spare-search-lg" type="radio" />
                        </div>
                        <div className="archive-spare-text210">Country</div>
                      </div>
                      <div className="archive-spare-input-fields2">
                        <div className="archive-spare-input-field2">
                          <div className="archive-spare-input-with-label4">
                            <div className="archive-spare-label5">Email</div>
                            <div className="archive-spare-input19">
                              <input
                                className="archive-spare-content37"
                                placeholder="Search"
                                type="text"
                              />

                              <img
                                className="archive-spare-help-icon1"
                                alt=""
                                src={help_icon}
                              />
                            </div>
                          </div>
                          <div className="archive-spare-hint-text">
                            This is a hint text to help user.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="archive-spare-second-badge-divider">
                    <img
                      className="archive-spare-divider-icon22"
                      alt=""
                      src={divider13}
                    />

                    <img
                      className="archive-spare-divider-icon23"
                      alt=""
                      src={divider21}
                    />
                  </div>
                  <div className="archive-spare-frame-wrapper17">
                    <div className="archive-spare-actions6">
                      <div className="archive-spare-navigation">
                        <input className="archive-spare-search-lg" type="radio" />
                      </div>
                      <div className="archive-spare-text211">Device Category</div>
                    </div>
                  </div>
                  <div className="archive-spare-second-badge-divider">
                    <img
                      className="archive-spare-divider-icon22"
                      alt=""
                      src={divider13}
                    />

                    <img
                      className="archive-spare-divider-icon23"
                      alt=""
                      src={divider21}
                    />
                  </div>
                  <div className="archive-spare-frame-wrapper17">
                    <div className="archive-spare-actions6">
                      <div className="archive-spare-navigation">
                        <input className="archive-spare-search-lg" type="radio" />
                      </div>
                      <div className="archive-spare-text212">OEM Brand</div>
                    </div>
                  </div>
                  <div className="archive-spare-frame-parent36">
                    <div className="archive-spare-second-badge-divider">
                      <img
                        className="archive-spare-divider-icon22"
                        alt=""
                        src={divider13}
                      />

                      <img
                        className="archive-spare-divider-icon23"
                        alt=""
                        src={divider21}
                      />
                    </div>
                    <div className="archive-spare-frame-wrapper19">
                      <div className="archive-spare-frame-parent37">
                        <div className="archive-spare-navigation">
                          <input className="archive-spare-search-lg" type="radio" />
                        </div>
                        <div className="archive-spare-text213">Year of Manufacture</div>
                      </div>
                    </div>
                    <div className="archive-spare-second-badge-divider">
                      <img
                        className="archive-spare-divider-icon22"
                        alt=""
                        src={divider13}
                      />

                      <img
                        className="archive-spare-divider-icon23"
                        alt=""
                        src={divider21}
                      />
                    </div>
                    <div className="archive-spare-frame-wrapper19">
                      <div className="archive-spare-actions6">
                        <div className="archive-spare-navigation">
                          <input className="archive-spare-search-lg" type="radio" />
                        </div>
                        <div className="archive-spare-text213">Clinical Applications</div>
                      </div>
                    </div>
                  </div>
                  <div className="archive-spare-card-header-parent">
                    <img
                      className="archive-spare-divider-icon15"
                      alt=""
                      src={divider13}
                    />

                    <img
                      className="archive-spare-divider-icon31"
                      alt=""
                      src={divider21}
                    />
                  </div>
                  <div className="archive-spare-frame-wrapper17">
                    <div className="archive-spare-actions6">
                      <div className="archive-spare-navigation">
                        <input className="archive-spare-search-lg" type="radio" />
                      </div>
                      <div className="archive-spare-text212">Device Use</div>
                    </div>
                  </div>
                  <div className="archive-spare-second-badge-divider">
                    <img
                      className="archive-spare-divider-icon22"
                      alt=""
                      src={divider13}
                    />

                    <img
                      className="archive-spare-divider-icon23"
                      alt=""
                      src={divider21}
                    />
                  </div>
                  <div className="archive-spare-frame-wrapper17">
                    <div className="archive-spare-frame-parent37">
                      <div className="archive-spare-navigation">
                        <input className="archive-spare-search-lg" type="radio" />
                      </div>
                      <div className="archive-spare-text216">Physical Location</div>
                    </div>
                  </div>
                  <img
                    className="archive-spare-divider-icon15"
                    alt=""
                    src={divider13}
                  />
                </div>
              </div>
              <img className="archive-spare-dot-icon20" alt="" src={dot} />

              <div className="archive-spare-frame-parent41">
                <div className="archive-spare-divider-parent6">
                  <img
                    className="archive-spare-divider-icon35"
                    alt=""
                    src={divider13}
                  />

                  <div className="archive-spare-footer1">
                    <div className="archive-spare-content38">
                      <div className="archive-spare-logo1">
                        <div className="archive-spare-buttonsbutton37">
                          <img
                            className="archive-spare-placeholder-icon"
                            alt=""
                            src={placeholder2}
                          />

                          <div className="archive-spare-text217">Save filter</div>
                          <img
                            className="archive-spare-placeholder-icon"
                            alt=""
                            src={placeholder2}
                          />
                        </div>
                      </div>
                      <div className="archive-spare-actions9">
                        <button className="archive-spare-buttonsbutton38">
                          <img
                            className="archive-spare-placeholder-icon"
                            alt=""
                            src={placeholder}
                          />

                          <div className="archive-spare-text-padding">
                            <div className="archive-spare-text218">Cancel</div>
                          </div>
                          <img
                            className="archive-spare-placeholder-icon"
                            alt=""
                            src={placeholder}
                          />
                        </button>
                        <button className="archive-spare-buttonsbutton39">
                          <img
                            className="archive-spare-placeholder-icon"
                            alt=""
                            src={placeholder3}
                          />

                          <div className="archive-spare-text-padding">
                            <div className="archive-spare-text219">Apply</div>
                          </div>
                          <img
                            className="archive-spare-placeholder-icon"
                            alt=""
                            src={placeholder3}
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="archive-spare-dot-item-innermost">
                  <div className="archive-spare-collapsed-content">
                    <img
                      className="archive-spare-divider-icon22"
                      alt=""
                      src={divider13}
                    />

                    <img
                      className="archive-spare-divider-icon37"
                      alt=""
                      src={divider21}
                    />
                  </div>
                </div>
              </div>
            </div>

          </div>

          <div className="archive-spare-filters">
            <div className='archive-spare-filter-selected-div'>
              <div className="archive-spare-main">
                <div className="archive-spare-header-section">
                  <div className="archive-spare-container">
                    <div className="archive-spare-page-header">
                      <div className="archive-spare-breadcrumbs">
                        <div className="archive-spare-tabs">
                          <div className="archive-spare-breadcrumb-button-base">
                            <img
                              className="archive-spare-search-lg-icon"
                              alt=""
                              src={homeline}
                            />
                          </div>
                          <img
                            className="archive-spare-chevron-right-icon"
                            alt=""
                            src={chevronright}
                          />

                          <div className="archive-spare-breadcrumb-button-base1">
                            <a className="archive-spare-text7">Dashboard</a>
                          </div>
                          <img
                            className="archive-spare-chevron-right-icon"
                            alt=""
                            src={chevronright}
                          />

                          <div className="archive-spare-breadcrumb-button-base2">
                            <div className="archive-spare-text8">...</div>
                          </div>
                          <img
                            className="archive-spare-chevron-right-icon2"
                            alt=""
                            src={chevronright}
                          />

                          <div className="archive-spare-breadcrumb-button-base3">
                            <div className="archive-spare-text9">Another link</div>
                          </div>
                          <img
                            className="archive-spare-chevron-right-icon2"
                            alt=""
                            src={chevronright}
                          />

                          <div className="archive-spare-breadcrumb-button-base4">
                            <a className="archive-spare-text10">Category</a>
                          </div>
                        </div>
                      </div>
                      <img className="archive-spare-divider-icon" alt="" src="/archive_page/divider.svg" />
                    </div>
                  </div>
                </div>
                <div className="archive-spare-main-child"></div>
                <div className="archive-spare-main-item"></div>
              </div>
              <div className="archive-spare-secondary-content">
                <div className="archive-spare-page-header1">
                  <div className="archive-spare-breadcrumbs1">
                    <div className="archive-spare-tabs1">
                      <div className="archive-spare-breadcrumb-button-base">
                        <img
                          className="archive-spare-search-lg-icon"
                          alt=""
                          src={homeline}
                        />
                      </div>
                      <img
                        className="archive-spare-chevron-right-icon"
                        alt=""
                        src={chevronright}
                      />

                      <div className="archive-spare-breadcrumb-button-base6">
                        <div className="archive-spare-text11">Settings</div>
                      </div>
                      <img
                        className="archive-spare-chevron-right-icon"
                        alt=""
                        src={chevronright}
                      />

                      <div className="archive-spare-breadcrumb-button-base7">
                        <div className="archive-spare-text8">...</div>
                      </div>
                      <img
                        className="archive-spare-chevron-right-icon"
                        alt=""
                        src={chevronright}
                      />

                      <div className="archive-spare-breadcrumb-button-base3">
                        <div className="archive-spare-text9">Another link</div>
                      </div>
                      <img
                        className="archive-spare-chevron-right-icon2"
                        alt=""
                        src={chevronright}
                      />

                      <div className="archive-spare-breadcrumb-button-base4">
                        <div className="archive-spare-menu-label">Team</div>
                      </div>
                    </div>
                  </div>
                  <div className="archive-spare-content4">
                    <div className="archive-spare-text-and-supporting-text">
                      <div className="archive-spare-page-title">{dataLength} Listings Found</div>
                      <div className="archive-spare-supporting-text">
                        Manage your team members and their account permissions here.
                      </div>
                    </div>
                    <div className="archive-spare-input-dropdown">
                      <div className="archive-spare-input-with-label1">
                        <div className="archive-spare-label1">Search</div>
                        <div className="archive-spare-input1">
                          <div className="archive-spare-content5">
                            <img
                              className="archive-spare-search-lg-icon"
                              alt=""
                              src={searchlg}
                            />

                            <div className="archive-spare-text15">Search</div>
                          </div>
                        </div>
                      </div>
                      <div className="archive-spare-hint-text">This is a hint text to help user.</div>
                    </div>
                  </div>
                  <img className="archive-spare-divider-icon1" alt="" src={divider1} />
                </div>
              </div>
              <div className="archive-spare-button-group">
                <button className="archive-spare-button-group-base">
                  <a className="archive-spare-text16">View all</a>
                </button>
                <div className="archive-spare-button-group-base1">
                  <div className="archive-spare-text17">Monitored</div>
                </div>
                <div className="archive-spare-button-group-base2">
                  <div className="archive-spare-menu-label">Text</div>
                </div>
                <div className="archive-spare-button-group-base3">
                  <div className="archive-spare-menu-label">Text</div>
                </div>
                <div className="archive-spare-button-group-base4">
                  <div className="archive-spare-menu-label">Text</div>
                </div>
                <div className="archive-spare-button-group-base5">
                  <div className="archive-spare-menu-label">Text</div>
                </div>
                <div className="archive-spare-button-group-base6">
                  <div className="archive-spare-menu-label">Text</div>
                </div>
                <div className="archive-spare-button-group-base7">
                  <div className="archive-spare-menu-label">Text</div>
                </div>
                <div className="archive-spare-button-group-base1">
                  <div className="archive-spare-text24">Unmonitored</div>
                </div>

              </div>
              <div className="archive-spare-input-dropdown1">
                <div className="archive-spare-hint-text1">This is a hint text to help user.</div>
                <div className="archive-spare-buttonsbutton">
                  <img className="archive-spare-sliders-02-icon" alt="" src={sliders02} />

                  <div className="archive-spare-text-padding">
                    <div className="archive-spare-menu-label">Sort By : Relevance</div>
                    <img
                      className="archive-spare-sliders-02-icon"
                      alt=""
                      src={chevrondown}
                    />
                  </div>
                  <img
                    className="archive-spare-placeholder-icon"
                    alt=""
                    src={placeholder}
                  />
                </div>
              </div>
              <img className="archive-spare-divider-icon2" alt="" src="/archive_page/divider2.svg" />

              <img className="archive-spare-divider-icon3" alt="" src="/archive_page/divider2.svg" />

              <div className="archive-spare-supporting-text1">
                You searched based on the following criteria.
              </div>

              <div className="archive-spare-buttonsbutton7">
                <img className="archive-spare-placeholder-icon" alt="" src={placeholder} />

                <a className="archive-spare-text47">All time</a>
                <img className="archive-spare-sliders-02-icon" alt="" src={xclose} />
              </div>
              <button className="archive-spare-buttonsbutton8">
                <img className="archive-spare-placeholder-icon" alt="" src={placeholder} />

                <div className="archive-spare-text48">Clinical Applications</div>
                <img className="archive-spare-sliders-02-icon" alt="" src={xclose} />
              </button>
              <div className="archive-spare-buttonsbutton9">
                <img className="archive-spare-placeholder-icon" alt="" src={placeholder} />

                <div className="archive-spare-text49">US, AU, +4</div>
                <img className="archive-spare-sliders-02-icon" alt="" src={xclose} />
              </div>
              <div className="archive-spare-buttonsbutton10">
                <img className="archive-spare-placeholder-icon" alt="" src={placeholder} />

                <div className="archive-spare-text50">All/Employee</div>
                <img className="archive-spare-sliders-02-icon" alt="" src={xclose} />
              </div>
              {/* <button className="archive-spare-buttonsbutton11">
          <img className="archive-spare-placeholder-icon" alt="" src={placeholder} />

          <div className="archive-spare-text48">Clinical Applications</div>
          <img className="archive-spare-sliders-02-icon" alt="" src={xclose} />
        </button> */}
              <div className="archive-spare-buttonsbutton12">
                <img className="archive-spare-placeholder-icon" alt="" src={placeholder} />

                <div className="archive-spare-menu-label">Year of Manufacture</div>
                <img className="archive-spare-sliders-02-icon" alt="" src={xclose} />
              </div>
              <button className="archive-spare-buttonsbutton13">
                <img className="archive-spare-placeholder-icon" alt="" src={placeholder} />

                <div className="archive-spare-text53">Warrenty</div>
                <img className="archive-spare-sliders-02-icon" alt="" src={xclose} />
              </button>
              <button className="archive-spare-buttonsbutton14">
                <img className="archive-spare-placeholder-icon" alt="" src={placeholder} />

                <div className="archive-spare-text54">Manufacturer</div>
                <img className="archive-spare-sliders-02-icon" alt="" src={xclose} />
              </button>
              <button className="archive-spare-buttonsbutton15">
                <img className="archive-spare-placeholder-icon" alt="" src={placeholder} />

                <div className="archive-spare-text55">All Applications</div>
                <img className="archive-spare-sliders-02-icon" alt="" src={xclose} />
              </button>
              <button className="archive-spare-buttonsbutton16">
                <img className="archive-spare-placeholder-icon" alt="" src={placeholder} />

                <div className="archive-spare-text56">Rent/Lease</div>
                <img className="archive-spare-sliders-02-icon" alt="" src={xclose} />
              </button>
              <button className="archive-spare-buttonsbutton17">
                <img className="archive-spare-sliders-02-icon" alt="" src={filterlines} />

                <div className="archive-spare-text57">More filters</div>
                <img className="archive-spare-placeholder-icon" alt="" src={placeholder} />
              </button>
              <button className="archive-spare-buttonsbutton18">
                <img
                  className="archive-spare-sliders-02-icon"
                  alt=""
                  src={filterfunnel01}
                />

                <div className="archive-spare-text58">Clear Filter</div>
                <img className="archive-spare-placeholder-icon" alt="" src={placeholder} />
              </button>
              <button className="archive-spare-actions">
                <div className="archive-spare-button">
                  <img
                    className="archive-spare-placeholder-icon"
                    alt=""
                    src={placeholder1}
                  />

                  <div className="archive-spare-text-padding1">
                    <div className="archive-spare-text59">Tertiary</div>
                  </div>
                  <img
                    className="archive-spare-placeholder-icon"
                    alt=""
                    src={placeholder1}
                  />
                </div>
                <div className="archive-spare-button1">
                  <img
                    className="archive-spare-placeholder-icon"
                    alt=""
                    src={placeholder2}
                  />

                  <div className="archive-spare-text-padding1">
                    <div className="archive-spare-text60">Secondary</div>
                  </div>
                  <img
                    className="archive-spare-placeholder-icon"
                    alt=""
                    src={placeholder2}
                  />
                </div>
                <div className="archive-spare-button2">
                  <img
                    className="archive-spare-sliders-02-icon"
                    alt=""
                    src={filterlines}
                  />

                  <div className="archive-spare-text-padding">
                    <div className="archive-spare-filter-label">Save Filter</div>
                  </div>
                  <img
                    className="archive-spare-placeholder-icon"
                    alt=""
                    src={placeholder3}
                  />
                </div>
              </button>
            </div>
            {/* <div className="archive-spare-listing-container archive-device-listing-container-custom"> */}
            <div className="dashboard-device-component-listing-container">
              {content}

            </div>
          </div>
        </div>
        <img className="archive-spare-divider-icon6" alt="" src={divider4} />

        <img className="archive-spare-divider-icon7 archive-spare-divider-icon7-cstm" alt="" src={divider4} />





        <FooterAddForms />




      </div>
    </>


  )
}

