import React, { useEffect, useRef, useState } from 'react';
import "./BeaconSpareComponent.css"
import dotsvertical from '../../../icons/Gerator/dashboard/dotsvertical.svg';
import calendar1 from '../../../icons/Gerator/dashboard/calendar1.svg';
import content_elements_2x from '../../../icons/Gerator/dashboard/content-elements@2x.png';
import dot1 from '../../../icons/Gerator/dashboard/-dot1.svg';
import radar from '../../../icons/Gerator/dashboard/radar-1-1@2x.png';
import deal_2_1_2x from '../../../icons/Gerator/dashboard/deal-2-1@2x.png';
import magnifier_1_2x from '../../../icons/Gerator/dashboard/magnifier-1@2x.png';
import content_elements_2x from '../../../icons/Gerator/dashboard/content-elements@2x.png';
import eye from '../../../icons/Gerator/dashboard/eye.svg';
import icon1 from '../../../icons/Gerator/dashboard/icon1.svg';
import lock04 from '../../../icons/Gerator/dashboard/lock04.svg';
import lockunlocked04 from '../../../icons/Gerator/dashboard/lockunlocked04.svg';
import arrowsquareupright from '../../../icons/Gerator/dashboard/arrowsquareupright.svg';
import messagechatsquare from '../../../icons/Gerator/dashboard/messagechatsquare.svg';
import markerpin02 from '../../../icons/Gerator/dashboard/markerpin02.svg';
import divider2 from '../../../icons/Gerator/dashboard/divider2.svg';
import arrowup1 from '../../../icons/Gerator/dashboard/arrowup1.svg';
import arrowdown1 from '../../../icons/Gerator/dashboard/arrowdown1.svg';
import placeholder2 from '../../../icons/Gerator/dashboard/placeholder2.svg';
import group_58_2x from '../../../icons/Gerator/dashboard/group-58@2x.png';
import sliders02 from '../../../icons/Gerator/dashboard/sliders02.svg';

import { SortByFilterComponent } from '../../SortByFilterComponent/SortByFilterComponent';
import RadarFilterButtonGroup from '../../RadarFilterButtonGroup/RadarFilterButtonGroup';
import { TransactionTypeComponent } from '../../TransactionTypeComponent/TransactionTypeComponent';
import { ThreeDotsListingComponent } from '../../ThreeDotsListingComponent/ThreeDotsListingComponent';
import { Link } from 'react-router-dom';
import { PostStatusLIstingComponent } from '../../PostStatusLIstingComponent/PostStatusLIstingComponent';
import { useGetUserDashboardDevicesQuery } from '../../../api/DeviceAPI';
import { NoListingFound } from '../../NoListing/NoListingFound';
import SkeletonListingLoaderComponent from '../../SkeletonListingLoaderComponent/SkeletonListingLoaderComponent';
import { getCustomizedDate } from '../../../helper/SpareHelper';
import { useGetPostTypeTaxQuery } from '../../../api/TaxonomyFormAPI';
import SearchInputListingComponent from '../../SearchInputListingComponent/SearchInputListingComponent';
import { useGetUserDashboardWantedSparesQuery } from '../../../api/WantedSpareAPI';

export const BeaconSpareComponent = () => {



  //Offset to load data when click load more
  const [offset, setOffset] = useState(0);
  const [sortBy, setSortBy] = useState('date_desc');
  const [searchTerm, setSearchTerm] = useState('');
  const [totalCount, setTotalCount] = useState("");
  const [totalCountWantedDevice, settotalCountWantedDevice] = useState("");
  const [totalCountWantedService, settotalCountWantedService] = useState("");
  const [totalCountWantedSoftware, settotalCountWantedSoftware] = useState("");
  // const [totalCountExpired, settotalCountExpired] = useState("");

  const [activePostStatus, setActivePostStatusFilter] = useState(null);
  //Query to get data
  const { data, isSuccess, isError, refetch, isLoading, isFetching } = useGetUserDashboardWantedSparesQuery(
    { offset, sortBy, postStatus: activePostStatus, search: searchTerm },
    {
      // Refetch when component mounts or tab becomes active
      refetchOnMountOrArgChange: true
    }
  );

  //flag var to show listing when loading complete when sekelteon layout is going to stop
  const [loadingComplete, setLoadingComplete] = useState(false);

  //Var is reponsible to show listings and other thing as well
  let content;

  //Sort by text show on button
  const [selectedSortOption, setSelectedSortOption] = useState('');

  //For SortBy Dropdown
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);

  //For Threedots in listing dropdown
  const [activeThreeDotId, setActiveThreeDotId] = useState(null);
  const threeDotsRef = useRef(null);
  const [isClickBlocked, setIsClickBlocked] = useState(false);



  const toggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible);
  }

  const toggleThreeDotsDropdown = (id) => {
    if (isClickBlocked) return; // Prevent rapid toggle

    if (activeThreeDotId === id) {
      setActiveThreeDotId(null); // Close if the same one is clicked again
      setIsClickBlocked(true);
      setTimeout(() => setIsClickBlocked(false), 400); // Small delay to avoid reopening
    } else {
      setActiveThreeDotId(id); // Open the clicked one
    }
  };

  const handleSearch = (term) => {
    setSearchTerm(term);
  };


  //Set value for sortby selected option on button text
  const handleOptionSelect = (option) => {
    if (option.value !== sortBy) {
      setSortBy(option.value);
      setOffset(0); // Reset offset when sort option changes
    }
    setSelectedSortOption(option);
    setDropdownVisible(false);
  };

  //DashbaordFilterButtonGroup component to get data
  const handleFilterClick = (filterLabel) => {
    if (activePostStatus === filterLabel) {
      setSortBy('date_desc')
      setSelectedSortOption('')
      setActivePostStatusFilter(null);
    } else {
      setSortBy('date_desc')
      setSelectedSortOption('')
      setActivePostStatusFilter(filterLabel);
    }
    setOffset(0); // Reset offset when changing filters
  };

  //Setting offset for loadmore when user clicked on load more button(this trigger query to fetch new data)
  // Reset offset when component mounts or remounts
  // useEffect(() => {
  //   setOffset(0);
  // }, []);
  // // Reset offset when sort option changes
  // useEffect(() => {
  //   setOffset(0);
  // }, [sortBy]);

  const handleLoadMore = () => {
    if (data?.hasMore && !isFetching) {
      setOffset(currentOffset => currentOffset + 5);
    }
  };

  const showLoadMore = data?.hasMore &&
    data.wanted_spares.length < data.totalCount &&
    !isFetching;





  //Onclick listener for sortby dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


  //Onclick listener for threedots dropdown
  useEffect(() => {
    const handleThreeDotClickOutside = (event) => {
      if (threeDotsRef.current && !threeDotsRef.current.contains(event.target)) {
        setActiveThreeDotId(null); // Close any open dropdown when clicking outside
      }
    };

    document.addEventListener("mousedown", handleThreeDotClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleThreeDotClickOutside);
    };
  }, []);

  // Listen for tab visibility changes
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        refetch();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [refetch]);


  //Success true then pause to show sekelton layout
  useEffect(() => {
    if (isSuccess) {
      // Set totalcount in listing counter layout
      setTotalCount(data.totalCount);
      settotalCountWantedDevice(data.totalCountWantedDevice);
      settotalCountWantedService(data.totalCountWantedService);
      settotalCountWantedSoftware(data.totalCountWantedSoftware);
      // Set timeout to delay the loading completion
      const timer = setTimeout(() => {
        setLoadingComplete(true);
      }, 1000); // 1 second

      // Cleanup timer on component unmount
      return () => clearTimeout(timer);
    }
  }, [isSuccess]);


  if (isError) {
    content = <NoListingFound path={"/add-spare"} />
  }

  if (isLoading) {
    content = <SkeletonListingLoaderComponent />;
  } else if (loadingComplete && isSuccess) {

    if (data.wanted_spares.length > 0) {
      // dataLength = data.wanted_spares.length;
      content = data.wanted_spares.map((wantedSpare, index) =>
      (
        <div className="beacon-spare-component-beacon-content-items" key={index}>
          <div className="beacon-spare-component-beacon-divider-container1">
            <div className="beacon-spare-component-beacon-divider-container-child"></div>
            <div className="beacon-spare-component-beacon-rectangle-parent5">


              <div className="beacon-spare-component-beacon-frame-parent1">
                <div className="beacon-spare-component-beacon-action-fields">
                  <div className="beacon-spare-component-beacon-input-elements">
                    <Link to={`/wanted-spare/${wantedSpare.id}/${wantedSpare.title}`} className="beacon-spare-component-beacon-text78">
                      {wantedSpare.title}
                    </Link>
                    <div className="beacon-spare-component-beacon-frame-parent21">
                      <div className="beacon-spare-component-beacon-badge-item-parent">
                        <div className="beacon-spare-component-beacon-badge-parent">

                          {/* <PostStatusLIstingComponent postStatus={'Expired'} postSlug={"expired"} postId={'1'} /> */}

                        </div>
                        <div className="beacon-spare-component-beacon-frame-parent4">
                          <div className="beacon-spare-component-beacon-end-label">
                            <div className="beacon-spare-component-beacon-search-icon-input">4.9/5</div>
                          </div>
                          <div className="beacon-spare-component-beacon-type-support">
                            <div className="beacon-spare-component-beacon-supporting-text9">
                              99,999 reviews
                            </div>
                          </div>
                        </div>
                        <div className="beacon-spare-component-beacon-badge33">
                          <div className="beacon-spare-component-beacon-card-badge-number">
                            {wantedSpare.tax_device_category.name}
                          </div>
                        </div>
                      </div>
                      <div className="beacon-spare-component-beacon-frame-parent24">
                        {/* <div className="beacon-spare-component-beacon-location-pin-parent">
                          <div className="beacon-spare-component-beacon-search-input">
                              <img
                              className="beacon-spare-component-beacon-marker-pin-02-icon2"
                              alt=""
                              src={markerpin02}
                              />
                          </div>
                          <div className="beacon-spare-component-beacon-location3">India, New Delhi</div>
                          </div> */}

                        <div className="beacon-spare-component-beacon-frame-wrapper19">
                          <div className="beacon-spare-component-beacon-legend-series">
                            <div className="beacon-spare-component-beacon-search-input">
                              <img
                                className="beacon-spare-component-beacon-marker-pin-02-icon2"
                                alt=""
                                src={calendar1}
                              />
                            </div>
                            <div className="beacon-spare-component-beacon-type-of-work2">{getCustomizedDate(wantedSpare.createdAt)}</div>
                          </div>
                        </div>

                        <div className="beacon-spare-component-beacon-frame-wrapper18">
                          <div className="beacon-spare-component-beacon-badge-elements-copy">
                            <div className="beacon-spare-component-beacon-badge36">
                              <div className="beacon-spare-component-beacon-badge-number-element">
                                {wantedSpare.tax_oem.name}
                              </div>
                            </div>
                            <div className="beacon-spare-component-beacon-badge37">
                              <div className="beacon-spare-component-beacon-second-badge-count">{wantedSpare.tax_clinical_application.name}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="beacon-spare-component-beacon-frame-parent26">

                        <div className="beacon-spare-component-beacon-badge38">
                          <div className="beacon-spare-component-beacon-third-badge-number">
                            {wantedSpare.tax_purpose_use.name}
                          </div>
                        </div>
                      </div>
                      {/* <div className="beacon-spare-component-beacon-badge39">
                          <div className="beacon-spare-component-beacon-location-badge-elements">
                          Accounting
                          </div>
                      </div> */}
                    </div>
                  </div>
                </div>
                <div className="beacon-spare-component-beacon-filter-elements">
                  <div className="beacon-spare-component-beacon-dropdown10" onClick={() => toggleThreeDotsDropdown(index)} ref={threeDotsRef}>
                    <img
                      className="beacon-spare-component-beacon-dots-vertical-icon"
                      alt=""
                      src={dotsvertical}
                    />
                    {activeThreeDotId === index && <ThreeDotsListingComponent url={`wanted-spare/${wantedSpare.id}`} />}
                  </div>

                </div>
              </div>
            </div>
            <img className="beacon-spare-component-beacon-divider-icon8" alt="" src={divider2} />

            <div className="beacon-spare-component-beacon-frame-parent71">
              <div className="beacon-spare-component-beacon-buttonsbutton-parent1">
                <div className="beacon-legend-series">
                  <img
                    className="beacon-location-column-child"
                    alt=""
                    src={group_58_2x}
                  />

                  <div className="beacon-location-column-inner">
                    <div className="beacon-date-picker-dropdown">
                      <div className="beacon-name">{wantedSpare.wanted_spare_user.displayName}</div>
                      <div className="beacon-role">Business Na...</div>
                    </div>
                  </div>
                </div>
                <div className='beacon-spare-component-main-buttons-group'>
                  <div className="beacon-spare-component-beacon-buttonsbutton13">
                    <img
                      className="beacon-spare-component-beacon-arrow-narrow-left-icon"
                      alt=""
                      src={eye}
                    />

                    <div className="beacon-spare-component-beacon-search-input">
                      <div className="beacon-spare-component-beacon-text67">View</div>
                    </div>
                  </div>
                  <div className="beacon-spare-component-beacon-buttonsbutton25">
                    <div className="beacon-spare-component-beacon-icon-wrapper">
                      <img className="beacon-spare-component-beacon-icon1" alt="" src={icon1} />
                    </div>
                    <div className="beacon-spare-component-beacon-search-input">
                      <div className="beacon-spare-component-beacon-text68">Sales IQ</div>
                    </div>
                    <img
                      className="beacon-spare-component-beacon-arrow-narrow-left-icon"
                      alt=""
                      src={lock04}
                    />
                  </div>
                  <div className="beacon-spare-component-beacon-buttonsbutton26">
                    <div className="beacon-spare-component-beacon-icon-wrapper">
                      <img className="beacon-spare-component-beacon-icon1" alt="" src={icon1} />
                    </div>
                    <div className="beacon-spare-component-beacon-search-input">
                      <div className="beacon-spare-component-beacon-text68">Sales IQ</div>
                    </div>
                    <img
                      className="beacon-spare-component-beacon-arrow-narrow-left-icon"
                      alt=""
                      src={lockunlocked04}
                    />
                  </div>
                  <div className="beacon-spare-component-beacon-buttonsbutton27">
                    <img
                      className="beacon-spare-component-beacon-upload-04-icon"
                      alt=""
                      src={messagechatsquare}
                    />

                    <div className="beacon-spare-component-beacon-text145">Messages</div>
                    <div className="beacon-spare-component-beacon-ellipse-parent">
                      <div className="beacon-spare-component-beacon-ellipse-div"></div>
                      <div className="beacon-spare-component-beacon-div">2</div>
                    </div>
                  </div>
                </div>

              </div>
              <div className="beacon-spare-component-beacon-arrow-square-up-right-wrapper1">
                <img
                  className="beacon-spare-component-beacon-arrow-square-up-right-icon1"
                  alt=""
                  src={arrowsquareupright}
                />
              </div>
            </div>
          </div>
        </div>
      ))
    }
    else {
      content = (

        <NoListingFound path="/add-spare" />

      )
    }

  } else if (!loadingComplete && isSuccess) {
    content = <SkeletonListingLoaderComponent />; // Show loader until loadingComplete is true
  }




  return (
    <>
      <div className="beacon-frame-wrapper">
        <div className="beacon-frame-group">
          <div className="beacon-rectangle-group">
            <div className="beacon-frame-item"></div>
            <div className="beacon-metric-card-row">
              <div className="beacon-metric-card">
                <div className="beacon-metric-card-title">
                  <div className="beacon-heading4">Devices</div>
                </div>
                <div className="beacon-dropdown3">
                  <img
                    className="beacon-dots-vertical-icon"
                    alt=""
                    src={dotsvertical}
                  />
                </div>
              </div>
              <div className="beacon-number3">{totalCountWantedDevice || 0}</div>
            </div>
            {/* <div className="beacon-badge11">
              <div className="beacon-options-icon">
                <img
                  className="beacon-arrow-up-icon2"
                  alt=""
                  src={arrowup1}
                />
              </div>
              <div className="beacon-text58">20 slots available</div>
            </div> */}
          </div>
          <div className="beacon-rectangle-container">
            <div className="beacon-frame-inner"></div>
            <div className="beacon-frame-container">
              <div className="beacon-heading-parent">
                <div className="beacon-heading5">Spares</div>
                <div className="beacon-number4">{totalCount || 0}</div>
                {/* <div className="beacon-badge12">
                  <div className="beacon-options-icon">
                    <img
                      className="beacon-arrow-up-icon2"
                      alt=""
                      src={arrowup1}
                    />
                  </div>
                  <div className="beacon-change-amount">20%</div>
                </div> */}
              </div>
            </div>
            <div className="beacon-dropdown3">
              <img
                className="beacon-dots-vertical-icon"
                alt=""
                src={dotsvertical}
              />
            </div>
          </div>
          <div className="beacon-rectangle-container">
            <div className="beacon-frame-inner"></div>
            <div className="beacon-options-icon">
              <div className="beacon-heading-group">
                <div className="beacon-heading5">Services</div>
                <div className="beacon-number4">{totalCountWantedService || 0}</div>
                {/* <div className="beacon-badge12">
                  <div className="beacon-options-icon">
                    <img
                      className="beacon-arrow-up-icon2"
                      alt=""
                      src={arrowup1}
                    />
                  </div>
                  <div className="beacon-change-amount">20%</div>
                </div> */}
              </div>
            </div>
            <div className="beacon-dropdown3">
              <img
                className="beacon-dots-vertical-icon"
                alt=""
                src={dotsvertical}
              />
            </div>
          </div>
          <div className="beacon-rectangle-container">
            <div className="beacon-frame-inner"></div>
            <div className="beacon-frame-container">
              <div className="beacon-heading-container">
                <div className="beacon-heading5">Softwares</div>
                <div className="beacon-number4">{totalCountWantedSoftware || 0}</div>
                {/* <div className="beacon-badge12">
                  <div className="beacon-options-icon">
                    <img
                      className="beacon-arrow-up-icon2"
                      alt=""
                      src={arrowup1}
                    />
                  </div>
                  <div className="beacon-change-amount">20%</div>
                </div> */}
              </div>
            </div>
            <div className="beacon-dropdown3">
              <img
                className="beacon-dots-vertical-icon"
                alt=""
                src={dotsvertical}
              />
            </div>
          </div>

        </div>
      </div>

      <div className="beacon-section-wrapper">
        <div className="beacon-section1">
          <div className="beacon-container1">
            <div className="beacon-filters-bar">
              <div className="beacon-content17">
                <div className="beacon-dropdowns">

                  <RadarFilterButtonGroup
                    handleFilterClick={handleFilterClick}
                    activePostStatus={activePostStatus}
                  />

                </div>
                <div className="beacon-input-dropdown2">
                  {/* <div className="beacon-input-with-label2">
                    <div className="beacon-label">Search</div>
                    <div className="beacon-input2">
                      <div className="beacon-content3">
                        <img
                          className="beacon-search-lg-icon beacon-search-lg-icon-custom"
                          alt=""
                          src={searchlg}
                        />
                        <input type="text" className="beacon-search-placeholder" placeholder='Search' />
                      </div>
                    </div>
                  </div> */}

                  <SearchInputListingComponent onSearch={handleSearch} />

                  <div className="beacon-buttonsbutton1" onClick={toggleDropdown}>
                    <img
                      className="beacon-upload-04-icon"
                      alt=""
                      src={sliders02}
                    />
                    <div className="beacon-text-padding6" ref={dropdownRef}>
                      <div className="beacon-text65">
                        Sort By {selectedSortOption && `: ${selectedSortOption.label}`}
                      </div>
                      {isDropdownVisible && <SortByFilterComponent onOptionSelect={handleOptionSelect} />}
                    </div>
                    <img
                      className="beacon-placeholder-icon"
                      alt=""
                      src={placeholder2}
                    />

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      {/* Listing Container */}
      <div className="beacon-spare-component-listing-container">

        {/* Listing layout start    */}
        {content}



      </div>

      {/* Load more button  */}
      <div className="beacon-frame-wrapper44">
        <div className="beacon-frame-parent72">
          <div className="beacon-buttonsbutton-wrapper1">
            {showLoadMore && (
              <button className='beacon-buttonsbutton-wrapper1-child'
                onClick={handleLoadMore}
                disabled={isFetching}
              >
                <div className="beacon-buttonsbutton28">
                  <img
                    className="beacon-arrow-narrow-left-icon"
                    alt=""
                    src={arrowdown1}
                  />
                  <div className="beacon-search-input">
                    <div className="beacon-text146">{isFetching ? 'Loading...' : 'Load More'}</div>
                  </div>
                </div>
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

