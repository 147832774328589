
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./ChatPage.css"; // Correct relative path

import { firestore, doc, getDoc, auth, onAuthStateChanged } from "../Login/FirebaseClient";
import { useNavigate } from "react-router-dom";

import { Helmet } from "react-helmet-async";
import { SideBarSubNormal } from '../../components/SideBars-Sub/SideBarSubChatPage/SideBarSubChatPae';
import { Footer } from "../../components/Footer/Footer";
import { Sidebar } from "../../components/Sidebar/Sidebar";
import { Navbar1 } from "../../components/Navbar1/Navbar1";
import { TitleHeader } from "../../components/ChatPageComponentOwner/TitleHeader"
import { ChatPanel } from "../../components/ChatPageComponentOwner/ChatPanelMain/ChatPanel"
import { Analytics } from "../../components/ChatPageComponentOwner/Analytics"
import { DeviceNotFound } from "./DeviceNotFound"
import { useAuthenticateChannelMutation, useSendMessageMutation, useLazyFindGetDeviceQuery } from '../../api/ChatAPI';
//import { useLazyFindGetDeviceQuery, useLazyGetDevicesQuery } from "../../api/DeviceAPI";

import { useFetchCountOfRadarDeviceQuery } from "../../api/Radar/RadarDeviceAPI";

import { useGetCountOfRadarDeviceQuery } from "../../api/View/ViewDeviceAPI";

import divider from "../../icons/Gerator/chat_page/divider.svg";
import divider3 from "../../icons/Gerator/dashboard/divider3.svg";


const ChatPage = () => {

  const [isSubSideBarVisible, setSubSideBarVisible] = useState(true);
  // stores the current authenticated user
  const [currentUser, setCurrentUser] = useState(null);

  // stores user metadata (fetched from Firestore)
  const [currentUserMeta, setCurrentUserMeta] = useState(null);

  //Stores DP link 
  const [profileImageLink, setProfileImageLink] = useState("");

  // Navigation hook from React Router
  const navigate = useNavigate();

  // RTK Query hooks
  const [authenticateChannel] = useAuthenticateChannelMutation();
  const [sendMessage] = useSendMessageMutation();

  const { id, entity } = useParams();
  // const [triggerGetUser, { data, isSuccess, error, isLoading, isError }] = useLazyGetUserQuery();
  const [triggerGetDevice, { data: deviceData,
    isLoading: isDeviceLoading,
    isSuccess: isDeviceSuccess,
    isError: isDeviceError,
    error: deviceError }] = useLazyFindGetDeviceQuery(id);

  // const [triggerFetchCount, { RadarCount, isLoading, isError }] = useLazyFetchCountOfRadarDeviceQuery();
  // const [addedToRadarCount, setAddedToRadarCount] = useState(null);


  // Fetch radar count
  //const { data: radarCount, isLoading, isError } = useFetchCountOfRadarDeviceQuery(deviceData?.data?.id);

  // Fetch radar count
  //const { data: viewCount, isLoading: isviewCountLoading , isError: isviewCountError} = useGetCountOfRadarDeviceQuery(deviceData?.data?.id);
  const [analyticsLoaded, setAnalyticsLoaded] = useState(false); // To track if Analytics has finished loading

  // Effect hook to handle user authentication and metadata fetching
  useEffect(() => {
    //checking if the auth state has changed
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) { //if user is true/present
        console.log(user);

        setCurrentUser(user); // Stores the user

        try {
          // Fetch user metadata from Firestore
          const userDocRef = doc(firestore, "users", user.uid);
          const userDocSnapshot = await getDoc(userDocRef);

          if (userDocSnapshot.exists()) {
            const userMeta = userDocSnapshot.data();

            if (userMeta.userStatus == 2) {
              navigate('/account-disabled');
              return;
            }

            if (userMeta.userType == 5) {
              navigate('/access-denied');
              return;
            }


            setCurrentUserMeta(userMeta);

            console.log(userMeta);

            // If the user has a profile image, set it
            if (userMeta.profileImage?.url) {
              setProfileImageLink(userMeta.profileImage.url);
            }
          }
        } catch (error) {
          console.error("Error fetching user metadata:", error);
        }
      } else {
        // Redirect unauthenticated users to the sign-in page
        navigate("/sign-in", { state: { message: "Please log in to continue." } });
      }
    });

    // Cleanup the listener on component unmount
    return () => unsubscribe();
  }, [navigate]);


  // TRIGGERING PROFILE FETCHING
  useEffect(() => {
    if (currentUser) {
      triggerGetDevice(id);
    }
  }, [currentUser, triggerGetDevice, id]);


  // // Trigger the get wanted device query only after user data is successfully fetched
  useEffect(() => {
    if (isDeviceSuccess && deviceData) {

      console.log('YES DEVICE SUCCESS in ChatPage', deviceData);

    }
  }, [isDeviceSuccess, deviceData]);

  // useEffect(() => {
  //   if (isDeviceSuccess && deviceData && radarCount) {

  //     console.log('YES DEVICE COUNT SUCCESS in ChatPage', radarCount);

  //     console.log('YES DEVICE VIEW COUNT SUCCESS in ChatPage', viewCount);


  //   }
  // }, [isDeviceSuccess, deviceData,radarCount]);

  const handleAnalyticsLoad = () => {
    setAnalyticsLoaded(true); // Mark Analytics as loaded
  };


  //       return (
  //         <div>
  //           <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap" />
  //           <div className="chatB-messaging-with-list-view-car">
  //             <div className="chatB-messaging-with-list-view-car-child" />
  //             <main className="chatB-frame-parent">
  //               {/* HEADER */}
  //               <Helmet>
  //         <title>Messaging</title>
  //       </Helmet>

  // {profileImageLink &&  <Navbar1 profileImageLink={profileImageLink}/>}
  //               <img className="chatB-divider-icon" loading="lazy" alt="" src={divider} />
  //               <section className="chatB-sidebar-navigation-parent">

  //         {/* NavBar */}


  //         {profileImageLink && <Sidebar profileImageLink={profileImageLink}>
  // <SideBarSubNormal
  // userData={currentUser}
  // />
  // </Sidebar>}
  //                 <div className="chatB-main-content-wrapper">
  //                   <div className="chatB-main-content">
  //                     {/* TITLE HEADER */}
  //                     <TitleHeader />
  //                     {/* ANALYTICS */}
  //                     {currentUser && <Analytics deviceData={deviceData} AddedToRadarCount={radarCount} viewCount={viewCount} />}

  //                     {/* <Analytics currentUser={currentUser} /> */}
  //                     {/* CHAT PANEL */}
  //                     {currentUser &&(<ChatPanel currentUser={currentUser} profileImageLink={profileImageLink} deviceData={deviceData}/>)}
  //                   </div>
  //                 </div>
  //               </section>
  //             </main>
  //             {/* FOOTER */}
  //             <Footer/>
  //           </div>
  //         </div>
  //       );
  // return (
  //   <div>
  //     <link
  //       rel="stylesheet"
  //       href="https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap"
  //     />
  //     <div className="chatB-messaging-with-list-view-car">
  //       <div className="chatB-messaging-with-list-view-car-child" />
  //       <main className="chatB-frame-parent">
  //         {/* HEADER */}
  //         <Helmet>
  //           <title>Messaging</title>
  //         </Helmet>

  //         {profileImageLink && <Navbar1 profileImageLink={profileImageLink} />}
  //         <img
  //           className="chatB-divider-icon"
  //           loading="lazy"
  //           alt=""
  //           src={divider}
  //         />
  //         <section className="chatB-sidebar-navigation-parent">
  //           {/* NavBar */}
  //           {profileImageLink && (
  //             <Sidebar profileImageLink={profileImageLink}>
  //               <SideBarSubNormal userData={currentUser} />
  //             </Sidebar>
  //           )}

  //           <div className="chatB-main-content-wrapper">
  //             <div className="chatB-main-content">
  //               {/* TITLE HEADER */}
  //               <TitleHeader />

  //               {/* CONDITIONAL RENDERING */}
  //               {deviceData ? (
  //                 <>
  //                   {/* ANALYTICS */}
  //                   {currentUser && (
  //                     <Analytics
  //                       deviceData={deviceData}
  //                       AddedToRadarCount={radarCount}
  //                       viewCount={viewCount}
  //                     />
  //                   )}

  //                   {/* CHAT PANEL */}
  //                   {currentUser && (
  //                     <ChatPanel
  //                       currentUser={currentUser}
  //                       profileImageLink={profileImageLink}
  //                       deviceData={deviceData}
  //                     />
  //                   )}
  //                 </>
  //               ) : (
  //                 // Show DeviceNotFound if deviceData is not valid
  //                 <DeviceNotFound />
  //               )}
  //             </div>
  //           </div>
  //         </section>
  //       </main>
  //       {/* FOOTER */}
  //       <Footer />
  //     </div>
  //   </div>
  // );

  return (
    <div>
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap"
      />
      <div className="chatB-messaging-with-list-view-car">
        <div className="chatB-messaging-with-list-view-car-child" />
        <main className="chatB-frame-parent">
          {/* HEADER */}
          <Helmet>
            <title>Messaging</title>
          </Helmet>

          {profileImageLink && <Navbar1  userData={currentUser} navigate={navigate} profileImageLink={profileImageLink}  />}
          <img className="chatB-divider-icon" loading="lazy" alt="" src={divider} />
          <section className="chatB-sidebar-navigation-parent">
            {/* Sidebar */}
            {profileImageLink && (
              <Sidebar profileImageLink={profileImageLink}>
                {/* <SideBarSubNormal userData={currentUser} /> */}
              </Sidebar>
            )}

            <div className="chatB-main-content-wrapper">
              <div className="chatB-main-content">
                {/* TITLE HEADER */}
                <TitleHeader />

                {/* CONDITIONAL RENDERING */}
                {deviceData ? (
                  <>
                    {/* ANALYTICS */}
                    {currentUser && deviceData && <Analytics deviceData={deviceData} onLoad={handleAnalyticsLoad} />}
                    {/* CHAT PANEL */}
                    {currentUser && deviceData && analyticsLoaded && (<ChatPanel currentUser={currentUser}
                      profileImageLink={profileImageLink} deviceData={deviceData} />)}
                  </>
                ) : (
                  // Render DeviceNotFound if deviceData is not available
                  <DeviceNotFound />
                )}
              </div>
            </div>
          </section>
        </main>
        {/* FOOTER */}
            <img className="dashboard-divider-icon6" alt="" src={divider} />
        
                <Footer />
        
      </div>
    </div>
  );

}
export { ChatPage };