

import React, { useRef, useState } from 'react';
import './SlideShowDetailPageComponent.css';
import slides from './slidesData';
import rectangle_5_2x from '../../icons/Gerator/detailed_listing/rectangle-5@2x.png';
import rectangle_9_2x from '../../icons/Gerator/detailed_listing/rectangle-9@2x.png';
import maximize01 from '../../icons/Gerator/detailed_listing/maximize01.svg';
import placeholder from '../../icons/Gerator/detailed_listing/placeholder.svg';
import arrowleft from '../../icons/Gerator/detailed_listing/arrowleft.svg';
import arrowright from '../../icons/Gerator/detailed_listing/arrowright.svg';

const SlideShowDetailPageComponent = ({slides}) => {
  console.log(slides);
  
  const slideshowRef = useRef(slides);
  const [modalOpen, setModalOpen] = useState(false);
  const [currentModalImageIndex, setCurrentModalImageIndex] = useState(null);



  const nextSlide = () => {
    if (slideshowRef.current) {
      const slideWidth = slideshowRef.current.querySelector('.mySlides').offsetWidth;
      slideshowRef.current.scrollBy({ left: slideWidth, behavior: 'smooth' });
    }
    if (modalOpen) {
      setCurrentModalImageIndex((prevIndex) => (prevIndex + 1) % slides.length);
    }
  };

  const prevSlide = () => {
    if (slideshowRef.current) {
      const slideWidth = slideshowRef.current.querySelector('.mySlides').offsetWidth;
      slideshowRef.current.scrollBy({ left: -slideWidth, behavior: 'smooth' });
    }
    if (modalOpen) {
      setCurrentModalImageIndex((prevIndex) => (prevIndex - 1 + slides.length) % slides.length);
    }
  };


  const openModal = (index) => {
    setCurrentModalImageIndex(index);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setCurrentModalImageIndex(null);
  };

  const showAllPhotos = () => {
    if (currentModalImageIndex === null) {
      setModalOpen(true);
      setCurrentModalImageIndex(0);
    } 
    // else{
    //   setModalOpen(true);
    // }
    // setCurrentModalImageIndex(null);
  };

  return (
    <>

      <div className="detailed-device-breadcrumb-container slideshow-container" ref={slideshowRef}>

        <div className="detailed-device-breadcrumb-controls">
          <img
            className="detailed-device-breadcrumb-controls-child"
            alt=""
            src={rectangle_5_2x}
          />

          <div className="detailed-device-testiomonial-carousel-arrow">
            <img
              className="detailed-device-arrow-left-icon"
              alt=""
              onClick={prevSlide}
              src={arrowleft}
            />
          </div>

        </div>


        {slides.map((slide, index) => (
          <div className="mySlides fade" key={index}>
            <div className="slide">
            <img
                className="detailed-device-breadcrumb-controls-icon"
                src={slide} style={{ width: '100%' }} alt={`Slide ${index + 1}`}  onClick={() => openModal(index)}
              />
              
              <div className="numbertext">{index + 1} / {slides.length}</div>
            </div>
          </div>
          ))}





        <div className="detailed-device-breadcrumb-controls1">
          <img
            className="detailed-device-breadcrumb-controls-item"
            alt=""
            src={rectangle_9_2x}
          />

          <div className="detailed-device-buttonsbutton">
            <img
              className="detailed-device-chevron-down-icon"
              alt=""
              src={placeholder}
            />

            <div className="detailed-device-navigation">
              <img
                className="detailed-device-maximize-01-icon"
                alt=""
                src={maximize01}
              />
            </div>
            <div className="detailed-device-text8" onClick={showAllPhotos}>See all Photos</div>
            <img
              className="detailed-device-chevron-down-icon"
              alt=""
              src={placeholder}
            />
          </div>
          <div className="detailed-device-testiomonial-carousel-arrow1">
            <img
              className="detailed-device-arrow-left-icon"
              alt=""
              src={arrowright}
              onClick={nextSlide}
            />
          </div>
        </div>

        {modalOpen && currentModalImageIndex !== null && currentModalImageIndex >= 0 && currentModalImageIndex < slides.length && (
          <div className="modal" onClick={closeModal}>
            <span className="close" onClick={closeModal}>&times;</span>
            <img
              className="modal-content"
              onClick={modalOpen}
              src={slides[currentModalImageIndex]}
              alt={`Modal Slide ${currentModalImageIndex + 1}`}
            />
            <img className="slideshow-prev" alt="Previous" src="/img/group-1073.png" onClick={(e) => { e.stopPropagation(); prevSlide(); }} />
            <img className="slideshow-next" alt="Next" src="/img/group-1074.png" onClick={(e) => { e.stopPropagation(); nextSlide(); }} />
          </div>
        )}

        </div>
      </>

      );
};


      export default SlideShowDetailPageComponent;



// import React, { useState } from 'react';
// import './SlideShowDetailPageComponent.css';

// const SlideShowDetailPageComponent = () => {
//   const [currentSlide, setCurrentSlide] = useState(null);

//   const slides = [
//     {
//       src: "/img/rectangle-5.png",
//       caption: "Caption Text"
//     },
//     {
//       src: "/img/rectangle-6.png",
//       caption: "Caption Two"
//     },
//     {
//       src: "/img/rectangle-7.png",
//       caption: "Caption Three"
//     },
//     {
//       src: "/img/rectangle-8.png",
//       caption: "Caption Three"
//     },
//     {
//       src: "/img/rectangle-9.png",
//       caption: "Caption Three",
//     },



//         {
//       src: "/img/rectangle-5.png",
//       caption: "Caption Text"
//     },
//     {
//       src: "/img/rectangle-6.png",
//       caption: "Caption Two"
//     },
//     {
//       src: "/img/rectangle-7.png",
//       caption: "Caption Three"
//     },
//     {
//       src: "/img/rectangle-8.png",
//       caption: "Caption Three"
//     },
//     {
//       src: "/img/rectangle-9.png",
//       caption: "Caption Three",
//     },
//   ];
//   const nextSlide = () => {
//     setCurrentSlide((prevSlide) => (prevSlide === null ? 0 : (prevSlide + 1) % slides.length));
//   };

//   const prevSlide = () => {
//     setCurrentSlide((prevSlide) => (prevSlide === null ? slides.length - 1 : (prevSlide - 1 + slides.length) % slides.length));
//   };


//   const setSlide = (index) => {
//     setCurrentSlide(index);
//   };

//   return (
//     <div className="slideshow-container">
//       {slides.map((slide, index) => (
//         <div
//           className={`mySlides fade ${currentSlide === null || index === currentSlide ? 'active' : ''}`}
//           key={index}
//           style={{ display: currentSlide === null || index === currentSlide ? 'block' : 'block' }}
//         >
//           <div className="numbertext">{index + 1} / {slides.length}</div>
//           <div className={`slide slide${index + 1}`}>
//           <img src={slide.src} style={{ width: '100%' }} alt={`Slide ${index + 1}`} />
//           <div className="text">{slide.caption}</div>
//         </div>
//         </div>
//       ))}

//       <img className="prev" alt="Group" src="/img/group-1073.png" onClick={prevSlide}/>
//       <img className="next" alt="Group" src="/img/group-1074.png" onClick={nextSlide} />

//       <div style={{ textAlign: 'center' }}>
//         {slides.map((_, index) => (
//           <span
//             className={`dot ${index === currentSlide ? 'active' : ''}`}
//             key={index}
//             onClick={() => setSlide(index)}
//           ></span>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default SlideShowDetailPageComponent;