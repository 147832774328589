import React, { useEffect, useState } from 'react';
import "./DetailedWantedSoftware.css"
import { Navbar1 } from '../../../components/Navbar1/Navbar1';
import FooterDetailPage from '../../../components/Footer/FooterDetailPage/FooterDetailPage';

import divider from '../../../icons/Gerator/detailed_listing/divider.svg';
import divider2 from '../../../icons/Gerator/detailed_listing/divider2.svg';

import chevronright from '../../../icons/Gerator/detailed_listing/chevronright.svg';


import placeholder1 from '../../../icons/Gerator/detailed_listing/placeholder1.svg';

import markerpin02 from '../../../icons/Gerator/detailed_listing/markerpin02.svg';

import calendar from '../../../icons/Gerator/detailed_listing/calendar.svg';

import facebook_1_1_2x from '../../../icons/Gerator/detailed_listing/facebook-1-1@2x.png';

import instagram_1_1_2x from '../../../icons/Gerator/detailed_listing/instagram-1-1@2x.png';
import whatsapp_1_2x from '../../../icons/Gerator/detailed_listing/whatsapp-1@2x.png';
import twitter_1_2x from '../../../icons/Gerator/detailed_listing/twitter-1@2x.png';
import linkedinlogo_1_2x from '../../../icons/Gerator/detailed_listing/linkedinlogo-1@2x.png';

import dot1 from '../../../icons/Gerator/detailed_listing/-dot1.svg';

import radar_1_1_2x from '../../../icons/Gerator/detailed_listing/radar-1-1@2x.png';
import deal_2_1_2x from '../../../icons/Gerator/detailed_listing/deal-2-1@2x.png';
import magnifier_1_2x from '../../../icons/Gerator/detailed_listing/magnifier-1@2x.png';
import share_1_2x from '../../../icons/Gerator/detailed_listing/share-1@2x.png';

import group_1010 from '../../../icons/Gerator/detailed_listing/group-1010.svg';
import group_78_2x from '../../../icons/Gerator/detailed_listing/group-78@2x.png';
import group_58_2x from '../../../icons/Gerator/detailed_listing/group-58@2x.png';
import group_781_2x from '../../../icons/Gerator/detailed_listing/group-781@2x.png';

import dotsvertical from '../../../icons/Gerator/detailed_listing/dotsvertical.svg';
import divider1 from '../../../icons/Gerator/detailed_listing/divider1.svg';
import bookmarkcheck from '../../../icons/Gerator/detailed_listing/bookmarkcheck.svg';
import usercheck02 from '../../../icons/Gerator/detailed_listing/usercheck02.svg';
import messagechatcircle from '../../../icons/Gerator/detailed_listing/messagechatcircle.svg';
import { Loader } from "../../../components/Loader";
// import { useDispatch } from "react-redux";
import { useLazyFindGetWantedSoftwareQuery, useLazyGetWantedSoftwaresQuery } from "../../../api/WantedSoftwareAPI";
import { useNavigate, useParams } from "react-router-dom";
import { getCustomizedDate } from "../../../helper/SoftwareHelper";
import { decryptErrorData } from '../../../helper/AESHelper';
import { useLazyGetUserQuery } from '../../../api/Login&RegisterAPI';
import { auth, onAuthStateChanged } from '../../Login/FirebaseClient';

export const DetailedWantedSoftware = () => {

  const { id } = useParams();

  const [triggerGetUser, { data, isSuccess, error, isLoading, isError }] = useLazyGetUserQuery();
  const [triggerGetWantedSoftware, { data: wantedSoftwareData,
    isLoading: isWantedSoftwareLoading,
    isSuccess: isWantedSoftwareSuccess,
    isError: isWantedSoftwareError,
    error: wantedSoftwareError }] = useLazyFindGetWantedSoftwareQuery(id);

  let content;
  const [currentUser, setCurrentUser] = useState("");
  // const [editWantedSoftwareSavedData, setEditWantedSoftwareSavedData] = useState(null);
  const navigate = useNavigate();

  // const [showSuccessMessage, setShowSuccessMessage] = useState({
  //   message: "",
  //   color: "",
  // });


  useEffect(() => {
    // const auth = getAuth();

    // Listen for authentication state change
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is authenticated
        setCurrentUser(user);
        triggerGetUser();
      } else {
        // User is not authenticated, redirect to login
        navigate('/sign-in', { state: { message: 'Please log in to continue.' } });
      }
    });

    return () => unsubscribe(); // Cleanup listener on component unmount
  }, [navigate, triggerGetUser]);

  // Trigger the get wanted software query only after user data is successfully fetched
  useEffect(() => {
    if (isSuccess) {
      // Once the user is authenticated and the getUser query is successful, trigger the getWantedSoftware query
      triggerGetWantedSoftware(id);
    }
  }, [isSuccess, triggerGetWantedSoftware]);


  if (error) {
    console.log(error);

    if (isError) {
      const decryptedData = decryptErrorData(error.data);
      console.log(decryptedData);
      // navigate("/sign-in", { state: decryptedData.message });
      navigate("/sign-in", { state: { message: decryptedData.message } });
    }
    else {
      navigate("/sign-in", { state: { message: "Internal server error" } });
    }
    return; // Optionally handle error differently
  }

  if (isWantedSoftwareSuccess) {
    return (
      <>
        <div className="detailed-wanted-software-listings-detailed-page-devic">
          <div className="detailed-wanted-software-rectangle-parent">
            <div className="detailed-wanted-software-frame-child"></div>
            <Navbar1 userData={currentUser} navigate={navigate} />
            <img className="detailed-wanted-software-divider-icon" alt="" src={divider} />

            <div className="detailed-wanted-software-breadcrumb-button-base">
              <div className="detailed-wanted-software-text6">...</div>
            </div>
            <img
              className="detailed-wanted-software-chevron-right-icon"
              alt=""
              src={chevronright}
            />

            <div className="detailed-wanted-software-breadcrumb-button-base1">
              <div className="detailed-wanted-software-text7">Another link</div>
            </div>
            <img
              className="detailed-wanted-software-chevron-right-icon1"
              alt=""
              src={chevronright}
            />

            <div className="detailed-wanted-software-frame-parent">
              <div className="detailed-wanted-software-breadcrumb-container-parent">
                <div className="detailed-wanted-software-breadcrumb">
                  <div className="detailed-wanted-software-breadcrumb-items">
                    <div className="detailed-wanted-software-breadcrumb-button-base2">
                      <div className="detailed-wanted-software-text9">Dashboard</div>
                    </div>
                    <div className="detailed-wanted-software-chevron-container">
                      <img
                        className="detailed-wanted-software-chevron-right-icon2"
                        alt=""
                        src={chevronright}
                      />
                    </div>
                    <div className="detailed-wanted-software-breadcrumb-button-base3">
                      <div className="detailed-wanted-software-text10">Wanted Software</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="detailed-wanted-software-listing-content-wrapper">
                <div className="detailed-wanted-software-listing-content">
                  <div className="detailed-wanted-software-description-block-parent">
                    <div className="detailed-wanted-software-description-block">
                      <div className="detailed-wanted-software-description-heading">
                        {/* <span className="detailed-wanted-software-pre-">Pre-</span>
                    <span className="detailed-wanted-software-o">o</span>
                    <span className="detailed-wanted-software-pre-">wned, </span>
                    <span className="detailed-wanted-software-o"
                    >USED, FULLY REFURBISHED 1984 gARAVENTA ACCESSIBILITY
                      WRISTVIEW STREPTOCOCCUS A HYALURONIDASE ANTIBODY
                      DETECTION, IDENTIFICATION REAGENTS FOR RENT / LEASE</span
                    > */}
                        {wantedSoftwareData.data.title}
                      </div>
                      <div className="detailed-wanted-software-listing-details">
                        <div className="detailed-wanted-software-detail-container">
                          <div className="detailed-wanted-software-details-content">
                            <div className="detailed-wanted-software-details-row">
                              <div className="detailed-wanted-software-detail-items">
                                <img
                                  className="detailed-wanted-software-marker-pin-02-icon"
                                  alt=""
                                  src={markerpin02}
                                />

                                <div className="detailed-wanted-software-supporting-text">India, New Delhi</div>
                              </div>
                              <div className="detailed-wanted-software-detail-items">
                                <img
                                  className="detailed-wanted-software-marker-pin-02-icon"
                                  alt=""
                                  src={calendar}
                                />

                                <div className="detailed-wanted-software-supporting-text">{getCustomizedDate(wantedSoftwareData.data.createdAt)}</div>
                              </div>
                            </div>
                            <div className="detailed-wanted-software-contact-details">
                              <div className="detailed-wanted-software-contact-name-wrapper">
                                <div className="detailed-wanted-software-contact-name">4.9/5</div>
                              </div>
                              <div className="detailed-wanted-software-contact-role">
                                <div className="detailed-wanted-software-supporting-text2">99,999 reviews</div>
                              </div>
                              <div className="detailed-wanted-software-social-links">
                                <div className="detailed-wanted-software-rectangle-container">
                                  <div className="detailed-wanted-software-frame-inner"></div>
                                  <div className="detailed-wanted-software-icon-backgrounds-parent">
                                    <div className="detailed-wanted-software-icon-backgrounds"></div>
                                    <img
                                      className="detailed-wanted-software-whatsapp-1-icon"
                                      alt=""
                                      src={facebook_1_1_2x}
                                    />
                                  </div>
                                  <div className="detailed-wanted-software-icon-backgrounds-parent">
                                    <div className="detailed-wanted-software-ellipse-div"></div>
                                    <img
                                      className="detailed-wanted-software-whatsapp-1-icon"
                                      alt=""
                                      src={instagram_1_1_2x}
                                    />
                                  </div>
                                  <div className="detailed-wanted-software-icon-backgrounds-parent">
                                    <div className="detailed-wanted-software-frame-child1"></div>
                                    <img
                                      className="detailed-wanted-software-whatsapp-1-icon"
                                      alt=""
                                      src={whatsapp_1_2x}
                                    />
                                  </div>
                                  <div className="detailed-wanted-software-icon-backgrounds-parent">
                                    <div className="detailed-wanted-software-icon-backgrounds"></div>
                                    <img
                                      className="detailed-wanted-software-whatsapp-1-icon"
                                      alt=""
                                      src={twitter_1_2x}
                                    />
                                  </div>
                                  <div className="detailed-wanted-software-icon-backgrounds-parent">
                                    <div className="detailed-wanted-software-ellipse-div"></div>
                                    <img
                                      className="detailed-wanted-software-whatsapp-1-icon"
                                      alt=""
                                      src={linkedinlogo_1_2x}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="detailed-wanted-software-button-row">
                            <div className="detailed-wanted-software-buttonsbutton1">
                              <img
                                className="detailed-wanted-software-chevron-down-icon"
                                alt=""
                                src={placeholder1}
                              />

                              <img
                                className="detailed-wanted-software-magnifier-1-icon"
                                alt=""
                                src={radar_1_1_2x}
                              />

                              <div className="detailed-wanted-software-text-padding">
                                <div className="detailed-wanted-software-text11">Add To Radar</div>
                              </div>
                              <img
                                className="detailed-wanted-software-chevron-down-icon"
                                alt=""
                                src={placeholder1}
                              />
                            </div>
                            <div className="detailed-wanted-software-buttonsbutton2">
                              <img
                                className="detailed-wanted-software-placeholder-icon4"
                                alt=""
                                src={placeholder1}
                              />

                              <img
                                className="detailed-wanted-software-magnifier-1-icon"
                                alt=""
                                src={deal_2_1_2x}
                              />

                              <img
                                className="detailed-wanted-software-placeholder-icon4"
                                alt=""
                                src={placeholder1}
                              />
                            </div>
                            <div className="detailed-wanted-software-buttonsbutton2">
                              <img
                                className="detailed-wanted-software-placeholder-icon4"
                                alt=""
                                src={placeholder1}
                              />

                              <img
                                className="detailed-wanted-software-magnifier-1-icon"
                                alt=""
                                src={magnifier_1_2x}
                              />

                              <img
                                className="detailed-wanted-software-placeholder-icon4"
                                alt=""
                                src={placeholder1}
                              />
                            </div>
                            <div className="detailed-wanted-software-buttonsbutton2">
                              <img
                                className="detailed-wanted-software-placeholder-icon4"
                                alt=""
                                src={placeholder1}
                              />

                              <img
                                className="detailed-wanted-software-magnifier-1-icon"
                                alt=""
                                src={share_1_2x}
                              />

                              <img
                                className="detailed-wanted-software-placeholder-icon4"
                                alt=""
                                src={placeholder1}
                              />
                            </div>
                            <img
                              className="detailed-wanted-software-button-row-child"
                              alt=""
                              src={group_1010}
                            />
                          </div>
                        </div>
                        <div className="detailed-wanted-software-dropdown-container">
                          <div className="detailed-wanted-software-dropdown">
                            <img
                              className="detailed-wanted-software-maximize-01-icon"
                              alt=""
                              src={dotsvertical}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <img className="detailed-wanted-software-divider-icon" alt="" src={divider1} />

                   

                    <div className="detailed-wanted-software-heading-parent">
                  <div className="detailed-wanted-software-heading">Highlights</div>
                  <div className="detailed-wanted-software-description-content">
                    <div className="detailed-wanted-software-description-columns-first">
                      <div className="detailed-wanted-software-first-columns-paragraphs">
                        <div className="detailed-wanted-software-paragraph">
                        {wantedSoftwareData.data.tax_software_category.name}
                        </div>
                        <div className="detailed-wanted-software-paragraph1">Software Category</div>
                      </div>
                      <div className="detailed-wanted-software-first-columns-paragraphs">
                        <div className="detailed-wanted-software-paragraph">{wantedSoftwareData.data.tax_software_type.name}</div>
                        <div className="detailed-wanted-software-paragraph1">Software Type</div>
                      </div>
                      <div className="detailed-wanted-software-first-columns-paragraphs">
                        <div className="detailed-wanted-software-paragraph">{wantedSoftwareData.data.tax_installation_location.name}</div>
                        <div className="detailed-wanted-software-paragraph1">Installation Location</div>
                      </div>
                    </div>
                    <div className="detailed-wanted-software-description-columns-second">
                      <div className="detailed-wanted-software-second-columns-paragraphs">
                        <div className="detailed-wanted-software-paragraph">
                        {wantedSoftwareData.data.tax_device_category.name}
                        </div>
                        <div className="detailed-wanted-software-paragraph1">Device Category</div>
                      </div>
                      <div className="detailed-wanted-software-second-columns-paragraphs">
                        <div className="detailed-wanted-software-paragraph">{wantedSoftwareData.data.tax_physical_location.name}</div>
                        <div className="detailed-wanted-software-paragraph1">Physical Location</div>
                      </div>
                      <div className="detailed-wanted-software-second-columns-paragraphs">
                        <div className="detailed-wanted-software-paragraph">
                        {wantedSoftwareData.data.tax_clinical_application.name}
                        </div>
                        <div className="detailed-wanted-software-paragraph1">Clinical Applications</div>
                      </div>

                      <div className="detailed-wanted-software-second-columns-paragraphs">
                        <div className="detailed-wanted-software-paragraph">
                        {wantedSoftwareData.data.tax_purpose_use.name}
                        </div>
                        <div className="detailed-wanted-software-paragraph1">Purpose/Use</div>
                      </div>


                    </div>
                  </div>
                </div>


                  </div>
                  <div className="detailed-wanted-software-service-card">
                    <div className="detailed-wanted-software-card-content">
                      <div className="detailed-wanted-software-card-content-child"></div>
                      <div className="detailed-wanted-software-card-header">
                        <div className="detailed-wanted-software-price-tag">
                          <div className="detailed-wanted-software-badge-wrapper">
                            <div className="detailed-wanted-software-badge3">
                              <img
                                className="detailed-wanted-software-dot-icon3"
                                alt=""
                                src={dot1}
                              />

                              <div className="detailed-wanted-software-text29">Sale</div>
                            </div>
                          </div>
                          <div className="detailed-wanted-software-price-parent">
                            <div className="detailed-wanted-software-price">$9,999,999.99</div>
                            <div className="detailed-wanted-software-original-price">
                              <div className="detailed-wanted-software-discount">USD - Per Unit</div>
                            </div>
                          </div>
                          <div className="detailed-wanted-software-service-title-container">
                            <div className="detailed-wanted-software-service-title">Posted By Manufacturer</div>
                          </div>
                        </div>
                      </div>
                      <div className="detailed-wanted-software-card-footer">
                        <img
                          className="detailed-wanted-software-card-footer-child"
                          alt=""
                          src={group_781_2x}
                        />

                        <div className="detailed-wanted-software-footer-divider">
                          <img
                            className="detailed-wanted-software-divider-icon4"
                            alt=""
                            src={divider2}
                          />
                        </div>
                      </div>
                      <div className="detailed-wanted-software-professional-info">
                        <div className="detailed-wanted-software-professional-details">
                          <div className="detailed-wanted-software-professional-actions">
                            <div className="detailed-wanted-software-benefits-container">
                              <div className="detailed-wanted-software-amlie-laurent">Olivia Rhye</div>
                              <div className="detailed-wanted-software-role">Adani Health</div>
                            </div>
                            <div className="detailed-wanted-software-action-buttons">
                              <div className="detailed-wanted-software-contact-button">
                                <div className="detailed-wanted-software-message-button">
                                  <div className="detailed-wanted-software-navigation">
                                    <img
                                      className="detailed-wanted-software-bookmark-check-icon"
                                      alt=""
                                      src={bookmarkcheck}
                                    />
                                  </div>
                                  <div className="detailed-wanted-software-supporting-text10">
                                    Verified by Gerätor
                                  </div>
                                </div>
                              </div>
                              <div className="detailed-wanted-software-supporting-text11">
                                Adani Health conforms to Gerätor's requirements for
                                verification
                              </div>
                            </div>
                            <div className="detailed-wanted-software-favorite-button">
                              <div className="detailed-wanted-software-button-container2">
                                <div className="detailed-wanted-software-buttonsbutton5">
                                  <img
                                    className="detailed-wanted-software-filter-lines-icon"
                                    alt=""
                                    src={messagechatcircle}
                                  />

                                  <div className="detailed-wanted-software-text30">Private Message</div>
                                </div>
                                <div className="detailed-wanted-software-buttonsbutton6">
                                  <img
                                    className="detailed-wanted-software-search-lg-icon"
                                    alt=""
                                    src={usercheck02}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="detailed-wanted-software-dropdown-menu">
                            <div className="detailed-wanted-software-dropdown1">
                              <img
                                className="detailed-wanted-software-maximize-01-icon"
                                alt=""
                                src={dotsvertical}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>

            <FooterDetailPage />
          </div>
        </div></>
    )
  } else {
    return <Loader />;
  }


  return (
    <div className="detailed-wanted-software-listings-detailed-page-devic">
      <div className="detailed-wanted-software-rectangle-parent">
        <div className="detailed-wanted-software-frame-child"></div>
        <Navbar1 />
        <img className="detailed-wanted-software-divider-icon" alt="" src={divider} />

        <div className="detailed-wanted-software-breadcrumb-button-base">
          <div className="detailed-wanted-software-text6">...</div>
        </div>
        <img
          className="detailed-wanted-software-chevron-right-icon"
          alt=""
          src={chevronright}
        />

        <div className="detailed-wanted-software-breadcrumb-button-base1">
          <div className="detailed-wanted-software-text7">Another link</div>
        </div>
        <img
          className="detailed-wanted-software-chevron-right-icon1"
          alt=""
          src={chevronright}
        />

        <div className="detailed-wanted-software-frame-parent">
          <div className="detailed-wanted-software-breadcrumb-container-parent">
            {/* <div className="detailed-wanted-software-breadcrumb-container">
            <div className="detailed-wanted-software-breadcrumb-controls">
              <img
                className="detailed-wanted-software-breadcrumb-controls-child"
                alt=""
                src="/detailed_listing/rectangle-5@2x.png"
              />

              <div className="detailed-wanted-software-testiomonial-carousel-arrow">
                <img
                  className="detailed-wanted-software-arrow-left-icon"
                  alt=""
                  src="/detailed_listing/arrowleft.svg"
                />
              </div>
            </div>
            <img
              className="detailed-wanted-software-breadcrumb-controls-icon"
              alt=""
              src="/detailed_listing/breadcrumb-controls@2x.png"
            />

            <img
              className="detailed-wanted-software-breadcrumb-controls-icon"
              alt=""
              src="/detailed_listing/breadcrumb-controls1@2x.png"
            />

            <img
              className="detailed-wanted-software-breadcrumb-controls-icon"
              alt=""
              src="/detailed_listing/breadcrumb-controls2@2x.png"
            />

            <div className="detailed-wanted-software-breadcrumb-controls1">
              <img
                className="detailed-wanted-software-breadcrumb-controls-item"
                alt=""
                src="/detailed_listing/rectangle-9@2x.png"
              />

              <div className="detailed-wanted-software-buttonsbutton">
                <img
                  className="detailed-wanted-software-chevron-down-icon"
                  alt=""
                  src="/detailed_listing/placeholder.svg"
                />

                <div className="detailed-wanted-software-navigation">
                  <img
                    className="detailed-wanted-software-maximize-01-icon"
                    alt=""
                    src="/detailed_listing/maximize01.svg"
                  />
                </div>
                <div className="detailed-wanted-software-text8">See all Photos</div>
                <img
                  className="detailed-wanted-software-chevron-down-icon"
                  alt=""
                  src="/detailed_listing/placeholder.svg"
                />
              </div>
              <div className="detailed-wanted-software-testiomonial-carousel-arrow1">
                <img
                  className="detailed-wanted-software-arrow-left-icon"
                  alt=""
                  src="/detailed_listing/arrowright.svg"
                />
              </div>
            </div>
          </div> */}
            <div className="detailed-wanted-software-breadcrumb">
              <div className="detailed-wanted-software-breadcrumb-items">
                <div className="detailed-wanted-software-breadcrumb-button-base2">
                  <div className="detailed-wanted-software-text9">Dashboard</div>
                </div>
                <div className="detailed-wanted-software-chevron-container">
                  <img
                    className="detailed-wanted-software-chevron-right-icon2"
                    alt=""
                    src={chevronright}
                  />
                </div>
                <div className="detailed-wanted-software-breadcrumb-button-base3">
                  <div className="detailed-wanted-software-text10">Wanted Software</div>
                </div>
              </div>
            </div>
          </div>
          <div className="detailed-wanted-software-listing-content-wrapper">
            <div className="detailed-wanted-software-listing-content">
              <div className="detailed-wanted-software-description-block-parent">
                <div className="detailed-wanted-software-description-block">
                  <div className="detailed-wanted-software-description-heading">
                    <span className="detailed-wanted-software-pre-">Pre-</span>
                    <span className="detailed-wanted-software-o">o</span>
                    <span className="detailed-wanted-software-pre-">wned, </span>
                    <span className="detailed-wanted-software-o"
                    >USED, FULLY REFURBISHED 1984 gARAVENTA ACCESSIBILITY
                      WRISTVIEW STREPTOCOCCUS A HYALURONIDASE ANTIBODY
                      DETECTION, IDENTIFICATION REAGENTS FOR RENT / LEASE</span
                    >
                  </div>
                  <div className="detailed-wanted-software-listing-details">
                    <div className="detailed-wanted-software-detail-container">
                      <div className="detailed-wanted-software-details-content">
                        <div className="detailed-wanted-software-details-row">
                          <div className="detailed-wanted-software-detail-items">
                            <img
                              className="detailed-wanted-software-marker-pin-02-icon"
                              alt=""
                              src={markerpin02}
                            />

                            <div className="detailed-wanted-software-supporting-text">India, New Delhi</div>
                          </div>
                          <div className="detailed-wanted-software-detail-items">
                            <img
                              className="detailed-wanted-software-marker-pin-02-icon"
                              alt=""
                              src={calendar}
                            />

                            <div className="detailed-wanted-software-supporting-text">April 9, 2023</div>
                          </div>
                        </div>
                        <div className="detailed-wanted-software-contact-details">
                          <div className="detailed-wanted-software-contact-name-wrapper">
                            <div className="detailed-wanted-software-contact-name">4.9/5</div>
                          </div>
                          <div className="detailed-wanted-software-contact-role">
                            <div className="detailed-wanted-software-supporting-text2">99,999 reviews</div>
                          </div>
                          <div className="detailed-wanted-software-social-links">
                            <div className="detailed-wanted-software-rectangle-container">
                              <div className="detailed-wanted-software-frame-inner"></div>
                              <div className="detailed-wanted-software-icon-backgrounds-parent">
                                <div className="detailed-wanted-software-icon-backgrounds"></div>
                                <img
                                  className="detailed-wanted-software-whatsapp-1-icon"
                                  alt=""
                                  src={facebook_1_1_2x}
                                />
                              </div>
                              <div className="detailed-wanted-software-icon-backgrounds-parent">
                                <div className="detailed-wanted-software-ellipse-div"></div>
                                <img
                                  className="detailed-wanted-software-whatsapp-1-icon"
                                  alt=""
                                  src={instagram_1_1_2x}
                                />
                              </div>
                              <div className="detailed-wanted-software-icon-backgrounds-parent">
                                <div className="detailed-wanted-software-frame-child1"></div>
                                <img
                                  className="detailed-wanted-software-whatsapp-1-icon"
                                  alt=""
                                  src={whatsapp_1_2x}
                                />
                              </div>
                              <div className="detailed-wanted-software-icon-backgrounds-parent">
                                <div className="detailed-wanted-software-icon-backgrounds"></div>
                                <img
                                  className="detailed-wanted-software-whatsapp-1-icon"
                                  alt=""
                                  src={twitter_1_2x}
                                />
                              </div>
                              <div className="detailed-wanted-software-icon-backgrounds-parent">
                                <div className="detailed-wanted-software-ellipse-div"></div>
                                <img
                                  className="detailed-wanted-software-whatsapp-1-icon"
                                  alt=""
                                  src={linkedinlogo_1_2x}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="detailed-wanted-software-button-row">
                        <div className="detailed-wanted-software-buttonsbutton1">
                          <img
                            className="detailed-wanted-software-chevron-down-icon"
                            alt=""
                            src={placeholder1}
                          />

                          <img
                            className="detailed-wanted-software-magnifier-1-icon"
                            alt=""
                            src={radar_1_1_2x}
                          />

                          <div className="detailed-wanted-software-text-padding">
                            <div className="detailed-wanted-software-text11">Add To Radar</div>
                          </div>
                          <img
                            className="detailed-wanted-software-chevron-down-icon"
                            alt=""
                            src={placeholder1}
                          />
                        </div>
                        <div className="detailed-wanted-software-buttonsbutton2">
                          <img
                            className="detailed-wanted-software-placeholder-icon4"
                            alt=""
                            src={placeholder1}
                          />

                          <img
                            className="detailed-wanted-software-magnifier-1-icon"
                            alt=""
                            src={deal_2_1_2x}
                          />

                          <img
                            className="detailed-wanted-software-placeholder-icon4"
                            alt=""
                            src={placeholder1}
                          />
                        </div>
                        <div className="detailed-wanted-software-buttonsbutton2">
                          <img
                            className="detailed-wanted-software-placeholder-icon4"
                            alt=""
                            src={placeholder1}
                          />

                          <img
                            className="detailed-wanted-software-magnifier-1-icon"
                            alt=""
                            src={magnifier_1_2x}
                          />

                          <img
                            className="detailed-wanted-software-placeholder-icon4"
                            alt=""
                            src={placeholder1}
                          />
                        </div>
                        <div className="detailed-wanted-software-buttonsbutton2">
                          <img
                            className="detailed-wanted-software-placeholder-icon4"
                            alt=""
                            src={placeholder1}
                          />

                          <img
                            className="detailed-wanted-software-magnifier-1-icon"
                            alt=""
                            src={share_1_2x}
                          />

                          <img
                            className="detailed-wanted-software-placeholder-icon4"
                            alt=""
                            src={placeholder1}
                          />
                        </div>
                        <img
                          className="detailed-wanted-software-button-row-child"
                          alt=""
                          src={group_1010}
                        />
                      </div>
                    </div>
                    <div className="detailed-wanted-software-dropdown-container">
                      <div className="detailed-wanted-software-dropdown">
                        <img
                          className="detailed-wanted-software-maximize-01-icon"
                          alt=""
                          src={dotsvertical}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <img className="detailed-wanted-software-divider-icon" alt="" src={divider1} />

                <div className="detailed-wanted-software-heading-parent">
                  <div className="detailed-wanted-software-heading">Highlights</div>
                  <div className="detailed-wanted-software-description-content">
                    <div className="detailed-wanted-software-description-columns-first">
                      <div className="detailed-wanted-software-first-columns-paragraphs">
                        <div className="detailed-wanted-software-paragraph">
                          Anti-Hyaluronidase (AHT) Streptococcus A Antibody
                          Detection, Identification Reagents
                        </div>
                        <div className="detailed-wanted-software-paragraph1">Software Category</div>
                      </div>
                      <div className="detailed-wanted-software-first-columns-paragraphs">
                        <div className="detailed-wanted-software-paragraph">March 1, 2021</div>
                        <div className="detailed-wanted-software-paragraph1">Software Type</div>
                      </div>
                      <div className="detailed-wanted-software-first-columns-paragraphs">
                        <div className="detailed-wanted-software-paragraph">Anatomy, Physiology</div>
                        <div className="detailed-wanted-software-paragraph1">Installation Location</div>
                      </div>
                    </div>
                    <div className="detailed-wanted-software-description-columns-second">
                      <div className="detailed-wanted-software-second-columns-paragraphs">
                        <div className="detailed-wanted-software-paragraph">
                          Fittydent-Altwirth & Schmitt
                        </div>
                        <div className="detailed-wanted-software-paragraph1">Software Category</div>
                      </div>
                      <div className="detailed-wanted-software-second-columns-paragraphs">
                        <div className="detailed-wanted-software-paragraph">AB9887727</div>
                        <div className="detailed-wanted-software-paragraph1">Physical Location</div>
                      </div>
                      <div className="detailed-wanted-software-second-columns-paragraphs">
                        <div className="detailed-wanted-software-paragraph">
                          Patient, Condition, Disease, Diagnosis
                        </div>
                        <div className="detailed-wanted-software-paragraph1">Clinical Applications</div>
                      </div>

                      <div className="detailed-wanted-software-second-columns-paragraphs">
                        <div className="detailed-wanted-software-paragraph">
                          Patient, Condition, Disease, Diagnosis
                        </div>
                        <div className="detailed-wanted-software-paragraph1">Purpose/Use</div>
                      </div>


                    </div>
                  </div>
                </div>




              </div>
              <div className="detailed-wanted-software-service-card">
                <div className="detailed-wanted-software-card-content">
                  <div className="detailed-wanted-software-card-content-child"></div>
                  <div className="detailed-wanted-software-card-header">
                    <div className="detailed-wanted-software-price-tag">
                      <div className="detailed-wanted-software-badge-wrapper">
                        <div className="detailed-wanted-software-badge3">
                          <img
                            className="detailed-wanted-software-dot-icon3"
                            alt=""
                            src={dot1}
                          />

                          <div className="detailed-wanted-software-text29">Sale</div>
                        </div>
                      </div>
                      <div className="detailed-wanted-software-price-parent">
                        <div className="detailed-wanted-software-price">$9,999,999.99</div>
                        <div className="detailed-wanted-software-original-price">
                          <div className="detailed-wanted-software-discount">USD - Per Unit</div>
                        </div>
                      </div>
                      <div className="detailed-wanted-software-service-title-container">
                        <div className="detailed-wanted-software-service-title">Posted By Manufacturer</div>
                      </div>
                    </div>
                  </div>
                  <div className="detailed-wanted-software-card-footer">
                    <img
                      className="detailed-wanted-software-card-footer-child"
                      alt=""
                      src={group_781_2x}
                    />

                    <div className="detailed-wanted-software-footer-divider">
                      <img
                        className="detailed-wanted-software-divider-icon4"
                        alt=""
                        src={divider2}
                      />
                    </div>
                  </div>
                  <div className="detailed-wanted-software-professional-info">
                    <div className="detailed-wanted-software-professional-details">
                      <div className="detailed-wanted-software-professional-actions">
                        <div className="detailed-wanted-software-benefits-container">
                          <div className="detailed-wanted-software-amlie-laurent">Olivia Rhye</div>
                          <div className="detailed-wanted-software-role">Adani Health</div>
                        </div>
                        <div className="detailed-wanted-software-action-buttons">
                          <div className="detailed-wanted-software-contact-button">
                            <div className="detailed-wanted-software-message-button">
                              <div className="detailed-wanted-software-navigation">
                                <img
                                  className="detailed-wanted-software-bookmark-check-icon"
                                  alt=""
                                  src={bookmarkcheck}
                                />
                              </div>
                              <div className="detailed-wanted-software-supporting-text10">
                                Verified by Gerätor
                              </div>
                            </div>
                          </div>
                          <div className="detailed-wanted-software-supporting-text11">
                            Adani Health conforms to Gerätor's requirements for
                            verification
                          </div>
                        </div>
                        <div className="detailed-wanted-software-favorite-button">
                          <div className="detailed-wanted-software-button-container2">
                            <div className="detailed-wanted-software-buttonsbutton5">
                              <img
                                className="detailed-wanted-software-filter-lines-icon"
                                alt=""
                                src={messagechatcircle}
                              />

                              <div className="detailed-wanted-software-text30">Private Message</div>
                            </div>
                            <div className="detailed-wanted-software-buttonsbutton6">
                              <img
                                className="detailed-wanted-software-search-lg-icon"
                                alt=""
                                src={usercheck02}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="detailed-wanted-software-dropdown-menu">
                        <div className="detailed-wanted-software-dropdown1">
                          <img
                            className="detailed-wanted-software-maximize-01-icon"
                            alt=""
                            src={dotsvertical}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="detailed-wanted-software-rectangle-parent4">
                <div className="detailed-wanted-software-frame-child6"></div>
                <div className="detailed-wanted-software-testimonial-header">
                  <div className="detailed-wanted-software-header-content1">
                    <div className="detailed-wanted-software-heading23">Jobs</div>
                    <div className="detailed-wanted-software-testimonial-description">
                      <div className="detailed-wanted-software-description">View All</div>
                    </div>
                  </div>
                </div>
                <div className="detailed-wanted-software-testimonial-list">
                  <img
                    className="detailed-wanted-software-divider-icon"
                    alt=""
                    src={divider2}
                  />

                  <div className="detailed-wanted-software-testimonial-divider">
                    <div className="detailed-wanted-software-testimonial-item">
                      <div className="detailed-wanted-software-rectangle-parent5">
                        <div className="detailed-wanted-software-frame-child7"></div>
                        <img
                          className="detailed-wanted-software-f4b711e503bc72fe396e5043e0c2-7-icon"
                          alt=""
                          src="/detailed_listing/8595f4b711e503bc72fe396e5043e0c2-7@2x.png"
                        />
                      </div>
                      <div className="detailed-wanted-software-testimonial-details">
                        <div className="detailed-wanted-software-client-name">
                          WHI Program assistant / Undergraduate researcher
                        </div>
                        <div className="detailed-wanted-software-heading24">Marketing Fusion SARL</div>
                      </div>
                    </div>
                  </div>
                  <img
                    className="detailed-wanted-software-divider-icon"
                    alt=""
                    src={divider2}
                  />

                  <div className="detailed-wanted-software-testimonial-divider">
                    <div className="detailed-wanted-software-testimonial-item">
                      <div className="detailed-wanted-software-rectangle-parent5">
                        <div className="detailed-wanted-software-frame-child7"></div>
                        <img
                          className="detailed-wanted-software-f4b711e503bc72fe396e5043e0c2-7-icon"
                          alt=""
                          src="/detailed_listing/8595f4b711e503bc72fe396e5043e0c2-7@2x.png"
                        />
                      </div>
                      <div className="detailed-wanted-software-testimonial-details">
                        <div className="detailed-wanted-software-client-name">
                          WHI Program assistant / Undergraduate researcher
                        </div>
                        <div className="detailed-wanted-software-heading24">Marketing Fusion SARL</div>
                      </div>
                    </div>
                  </div>
                  <img
                    className="detailed-wanted-software-divider-icon"
                    alt=""
                    src={divider2}
                  />

                  <div className="detailed-wanted-software-testimonial-divider">
                    <div className="detailed-wanted-software-testimonial-item">
                      <div className="detailed-wanted-software-rectangle-parent5">
                        <div className="detailed-wanted-software-frame-child7"></div>
                        <img
                          className="detailed-wanted-software-f4b711e503bc72fe396e5043e0c2-7-icon"
                          alt=""
                          src="/detailed_listing/8595f4b711e503bc72fe396e5043e0c2-7@2x.png"
                        />
                      </div>
                      <div className="detailed-wanted-software-testimonial-details">
                        <div className="detailed-wanted-software-client-name">
                          WHI Program assistant / Undergraduate researcher
                        </div>
                        <div className="detailed-wanted-software-heading24">Marketing Fusion SARL</div>
                      </div>
                    </div>
                  </div>
                  <img
                    className="detailed-wanted-software-divider-icon"
                    alt=""
                    src={divider2}
                  />

                  <div className="detailed-wanted-software-testimonial-divider">
                    <div className="detailed-wanted-software-testimonial-item">
                      <div className="detailed-wanted-software-rectangle-parent5">
                        <div className="detailed-wanted-software-frame-child7"></div>
                        <img
                          className="detailed-wanted-software-f4b711e503bc72fe396e5043e0c2-7-icon"
                          alt=""
                          src="/detailed_listing/8595f4b711e503bc72fe396e5043e0c2-7@2x.png"
                        />
                      </div>
                      <div className="detailed-wanted-software-testimonial-details">
                        <div className="detailed-wanted-software-client-name">
                          WHI Program assistant / Undergraduate researcher
                        </div>
                        <div className="detailed-wanted-software-heading24">Marketing Fusion SARL</div>
                      </div>
                    </div>
                  </div>
                  <img
                    className="detailed-wanted-software-divider-icon"
                    alt=""
                    src={divider2}
                  />

                  <div className="detailed-wanted-software-testimonial-divider">
                    <div className="detailed-wanted-software-testimonial-item">
                      <div className="detailed-wanted-software-rectangle-parent5">
                        <div className="detailed-wanted-software-frame-child7"></div>
                        <img
                          className="detailed-wanted-software-f4b711e503bc72fe396e5043e0c2-7-icon"
                          alt=""
                          src="/detailed_listing/8595f4b711e503bc72fe396e5043e0c2-7@2x.png"
                        />
                      </div>
                      <div className="detailed-wanted-software-testimonial-details">
                        <div className="detailed-wanted-software-client-name">
                          WHI Program assistant / Undergraduate researcher
                        </div>
                        <div className="detailed-wanted-software-heading24">Marketing Fusion SARL</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              </div>
            </div>
          </div>
        </div>

        {/*       
      <div className="detailed-wanted-software-listing-image-grid3">
        <img className="detailed-wanted-software-divider-icon11" alt="" src="/detailed_listing/divider3.svg" />
      </div>
      <div className="detailed-wanted-software-listing-image-grid4">
        <div className="detailed-wanted-software-heading-parent3">
          <div className="detailed-wanted-software-heading36">Related Softwares</div>
          <div className="detailed-wanted-software-text-wrapper">
            <div className="detailed-wanted-software-description">View All</div>
          </div>
        </div>
      </div>
      <div className="detailed-wanted-software-listing-image-grid5">
        <div className="detailed-wanted-software-frame-parent4">
          <div className="detailed-wanted-software-frame-parent5">
            <div className="detailed-wanted-software-rectangle-parent10">
              <div className="detailed-wanted-software-frame-child12"></div>
              <div className="detailed-wanted-software-listing-card-badge-overlay">
                <img
                  className="detailed-wanted-software-listing-card-badge-overlay-child"
                  alt=""
                  src="/detailed_listing/rectangle-95@2x.png"
                />

                <div className="detailed-wanted-software-image-overlay">
                  <div className="detailed-wanted-software-badge4">
                    <div className="detailed-wanted-software-listing-card-price">Assured</div>
                  </div>
                  <div className="detailed-wanted-software-short-listing-team">
                    <div className="detailed-wanted-software-badge5">
                      <div className="detailed-wanted-software-listing-card-radar">ID# AXJYN</div>
                    </div>
                    <div className="detailed-wanted-software-buttonsbutton11">
                      <img
                        className="detailed-wanted-software-magnifier-1-icon"
                        alt=""
                        src={radar_1_1_2x}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="detailed-wanted-software-listing-detail-item">
                <div className="detailed-wanted-software-message-content">
                  <div className="detailed-wanted-software-text-frame">
                    <div className="detailed-wanted-software-text42">
                      New / Surplus 2021 Philips Health-care HD11 XE
                      (P/N:23A...
                    </div>
                  </div>
                  <div className="detailed-wanted-software-listing-detail-item-value">
                    <div className="detailed-wanted-software-listing-detail-item-pin-wrapper">
                      <div className="detailed-wanted-software-heading-container">
                        <div className="detailed-wanted-software-listing-detail-item-location">
                          <div className="detailed-wanted-software-listing-detail-item-date-parent">
                            <div className="detailed-wanted-software-listing-detail-item-date">
                              <img
                                className="detailed-wanted-software-chevron-right-icon2"
                                alt=""
                                src="/detailed_listing/markerpin021.svg"
                              />
                            </div>
                            <div className="detailed-wanted-software-location">India, New Delhi</div>
                          </div>
                          <div className="detailed-wanted-software-listing-detail-item-date-parent">
                            <div className="detailed-wanted-software-listing-detail-item-date">
                              <img
                                className="detailed-wanted-software-chevron-right-icon2"
                                alt=""
                                src="/detailed_listing/calendar1.svg"
                              />
                            </div>
                            <div className="detailed-wanted-software-type-of-work">April 09, 2023</div>
                          </div>
                        </div>
                        <div className="detailed-wanted-software-listing-team">
                          <div className="detailed-wanted-software-listing-team-member-wrapper">
                            <div className="detailed-wanted-software-listing-team-member">4.9/5</div>
                          </div>
                          <div className="detailed-wanted-software-contact-role">
                            <div className="detailed-wanted-software-supporting-text12">
                              99,999 reviews
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="detailed-wanted-software-listing">
                      <div className="detailed-wanted-software-breadcrumb-items">
                        <div className="detailed-wanted-software-listing-short-info">$9,999,999.99</div>
                        <div className="detailed-wanted-software-supporting-text-wrapper">
                          <div className="detailed-wanted-software-supporting-text13">USD-Per Unit</div>
                        </div>
                      </div>
                    </div>
                    <img
                      className="detailed-wanted-software-divider-icon"
                      alt=""
                      src="/detailed_listing/divider4.svg"
                    />

                    <div className="detailed-wanted-software-listing-detail-item-value-inner">
                      <div className="detailed-wanted-software-footer-social-parent">
                        <div className="detailed-wanted-software-footer-social">
                          <img
                            className="detailed-wanted-software-footer-social-child"
                            alt=""
                            src="/detailed_listing/group-58@2x.png"
                          />

                          <div className="detailed-wanted-software-listing-short-detail-item-pin-parent">
                            <div className="detailed-wanted-software-listing-short-detail-item-pin">
                              <div className="detailed-wanted-software-name">Olivia Rhye</div>
                            </div>
                            <div className="detailed-wanted-software-role1">Business Name</div>
                          </div>
                        </div>
                        <div className="detailed-wanted-software-listing-short-detail-item-date">
                          <img
                            className="detailed-wanted-software-icon1"
                            alt=""
                            src="/detailed_listing/icon1.svg"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="detailed-wanted-software-rectangle-parent10">
              <div className="detailed-wanted-software-frame-child12"></div>
              <div className="detailed-wanted-software-listing-card-badge-overlay">
                <img
                  className="detailed-wanted-software-listing-card-badge-overlay-child"
                  alt=""
                  src="/detailed_listing/rectangle-95@2x.png"
                />

                <div className="detailed-wanted-software-image-overlay">
                  <div className="detailed-wanted-software-badge4">
                    <div className="detailed-wanted-software-listing-card-price">Assured</div>
                  </div>
                  <div className="detailed-wanted-software-short-listing-team">
                    <div className="detailed-wanted-software-badge5">
                      <div className="detailed-wanted-software-listing-card-radar">ID# AXJYN</div>
                    </div>
                    <div className="detailed-wanted-software-buttonsbutton11">
                      <img
                        className="detailed-wanted-software-magnifier-1-icon"
                        alt=""
                        src={radar_1_1_2x}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="detailed-wanted-software-listing-detail-item">
                <div className="detailed-wanted-software-message-content">
                  <div className="detailed-wanted-software-text-frame">
                    <div className="detailed-wanted-software-text42">
                      New / Surplus 2021 Philips Health-care HD11 XE
                      (P/N:23A...
                    </div>
                  </div>
                  <div className="detailed-wanted-software-listing-detail-item-value">
                    <div className="detailed-wanted-software-listing-detail-item-pin-wrapper">
                      <div className="detailed-wanted-software-heading-container">
                        <div className="detailed-wanted-software-listing-detail-item-location">
                          <div className="detailed-wanted-software-listing-detail-item-date-parent">
                            <div className="detailed-wanted-software-listing-detail-item-date">
                              <img
                                className="detailed-wanted-software-chevron-right-icon2"
                                alt=""
                                src="/detailed_listing/markerpin021.svg"
                              />
                            </div>
                            <div className="detailed-wanted-software-location">India, New Delhi</div>
                          </div>
                          <div className="detailed-wanted-software-listing-detail-item-date-parent">
                            <div className="detailed-wanted-software-listing-detail-item-date">
                              <img
                                className="detailed-wanted-software-chevron-right-icon2"
                                alt=""
                                src="/detailed_listing/calendar1.svg"
                              />
                            </div>
                            <div className="detailed-wanted-software-type-of-work">April 09, 2023</div>
                          </div>
                        </div>
                        <div className="detailed-wanted-software-listing-team">
                          <div className="detailed-wanted-software-listing-team-member-wrapper">
                            <div className="detailed-wanted-software-listing-team-member">4.9/5</div>
                          </div>
                          <div className="detailed-wanted-software-contact-role">
                            <div className="detailed-wanted-software-supporting-text12">
                              99,999 reviews
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="detailed-wanted-software-listing">
                      <div className="detailed-wanted-software-breadcrumb-items">
                        <div className="detailed-wanted-software-listing-short-info">$9,999,999.99</div>
                        <div className="detailed-wanted-software-supporting-text-wrapper">
                          <div className="detailed-wanted-software-supporting-text13">USD-Per Unit</div>
                        </div>
                      </div>
                    </div>
                    <img
                      className="detailed-wanted-software-divider-icon"
                      alt=""
                      src="/detailed_listing/divider4.svg"
                    />

                    <div className="detailed-wanted-software-listing-detail-item-value-inner">
                      <div className="detailed-wanted-software-footer-social-parent">
                        <div className="detailed-wanted-software-footer-social">
                          <img
                            className="detailed-wanted-software-footer-social-child"
                            alt=""
                            src="/detailed_listing/group-58@2x.png"
                          />

                          <div className="detailed-wanted-software-listing-short-detail-item-pin-parent">
                            <div className="detailed-wanted-software-listing-short-detail-item-pin">
                              <div className="detailed-wanted-software-name">Olivia Rhye</div>
                            </div>
                            <div className="detailed-wanted-software-role1">Business Name</div>
                          </div>
                        </div>
                        <div className="detailed-wanted-software-listing-short-detail-item-date">
                          <img
                            className="detailed-wanted-software-icon1"
                            alt=""
                            src="/detailed_listing/icon1.svg"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="detailed-wanted-software-rectangle-parent10">
              <div className="detailed-wanted-software-frame-child12"></div>
              <div className="detailed-wanted-software-listing-card-badge-overlay">
                <img
                  className="detailed-wanted-software-listing-card-badge-overlay-child"
                  alt=""
                  src="/detailed_listing/rectangle-95@2x.png"
                />

                <div className="detailed-wanted-software-image-overlay">
                  <div className="detailed-wanted-software-badge4">
                    <div className="detailed-wanted-software-listing-card-price">Assured</div>
                  </div>
                  <div className="detailed-wanted-software-short-listing-team">
                    <div className="detailed-wanted-software-badge5">
                      <div className="detailed-wanted-software-listing-card-radar">ID# AXJYN</div>
                    </div>
                    <div className="detailed-wanted-software-buttonsbutton11">
                      <img
                        className="detailed-wanted-software-magnifier-1-icon"
                        alt=""
                        src={radar_1_1_2x}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="detailed-wanted-software-listing-detail-item">
                <div className="detailed-wanted-software-message-content">
                  <div className="detailed-wanted-software-text-frame">
                    <div className="detailed-wanted-software-text42">
                      New / Surplus 2021 Philips Health-care HD11 XE
                      (P/N:23A...
                    </div>
                  </div>
                  <div className="detailed-wanted-software-listing-detail-item-value">
                    <div className="detailed-wanted-software-listing-detail-item-pin-wrapper">
                      <div className="detailed-wanted-software-heading-container">
                        <div className="detailed-wanted-software-listing-detail-item-location">
                          <div className="detailed-wanted-software-listing-detail-item-date-parent">
                            <div className="detailed-wanted-software-listing-detail-item-date">
                              <img
                                className="detailed-wanted-software-chevron-right-icon2"
                                alt=""
                                src="/detailed_listing/markerpin021.svg"
                              />
                            </div>
                            <div className="detailed-wanted-software-location">India, New Delhi</div>
                          </div>
                          <div className="detailed-wanted-software-listing-detail-item-date-parent">
                            <div className="detailed-wanted-software-listing-detail-item-date">
                              <img
                                className="detailed-wanted-software-chevron-right-icon2"
                                alt=""
                                src="/detailed_listing/calendar1.svg"
                              />
                            </div>
                            <div className="detailed-wanted-software-type-of-work">April 09, 2023</div>
                          </div>
                        </div>
                        <div className="detailed-wanted-software-listing-team">
                          <div className="detailed-wanted-software-listing-team-member-wrapper">
                            <div className="detailed-wanted-software-listing-team-member">4.9/5</div>
                          </div>
                          <div className="detailed-wanted-software-contact-role">
                            <div className="detailed-wanted-software-supporting-text12">
                              99,999 reviews
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="detailed-wanted-software-listing">
                      <div className="detailed-wanted-software-breadcrumb-items">
                        <div className="detailed-wanted-software-listing-short-info">$9,999,999.99</div>
                        <div className="detailed-wanted-software-supporting-text-wrapper">
                          <div className="detailed-wanted-software-supporting-text13">USD-Per Unit</div>
                        </div>
                      </div>
                    </div>
                    <img
                      className="detailed-wanted-software-divider-icon"
                      alt=""
                      src="/detailed_listing/divider4.svg"
                    />

                    <div className="detailed-wanted-software-listing-detail-item-value-inner">
                      <div className="detailed-wanted-software-footer-social-parent">
                        <div className="detailed-wanted-software-footer-social">
                          <img
                            className="detailed-wanted-software-footer-social-child"
                            alt=""
                            src="/detailed_listing/group-58@2x.png"
                          />

                          <div className="detailed-wanted-software-listing-short-detail-item-pin-parent">
                            <div className="detailed-wanted-software-listing-short-detail-item-pin">
                              <div className="detailed-wanted-software-name">Olivia Rhye</div>
                            </div>
                            <div className="detailed-wanted-software-role1">Business Name</div>
                          </div>
                        </div>
                        <div className="detailed-wanted-software-listing-short-detail-item-date">
                          <img
                            className="detailed-wanted-software-icon1"
                            alt=""
                            src="/detailed_listing/icon1.svg"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="detailed-wanted-software-rectangle-parent10">
              <div className="detailed-wanted-software-frame-child12"></div>
              <div className="detailed-wanted-software-listing-card-badge-overlay">
                <img
                  className="detailed-wanted-software-listing-card-badge-overlay-child"
                  alt=""
                  src="/detailed_listing/rectangle-95@2x.png"
                />

                <div className="detailed-wanted-software-image-overlay3">
                  <div className="detailed-wanted-software-badge4">
                    <div className="detailed-wanted-software-listing-card-price">Assured</div>
                  </div>
                  <div className="detailed-wanted-software-badge-group">
                    <div className="detailed-wanted-software-badge5">
                      <div className="detailed-wanted-software-listing-card-radar">ID# AXJYN</div>
                    </div>
                    <div className="detailed-wanted-software-buttonsbutton14">
                      <img
                        className="detailed-wanted-software-magnifier-1-icon"
                        alt=""
                        src={radar_1_1_2x}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="detailed-wanted-software-listing-detail-item">
                <div className="detailed-wanted-software-message-content">
                  <div className="detailed-wanted-software-text-frame">
                    <div className="detailed-wanted-software-text42">
                      New / Surplus 2021 Philips Health-care HD11 XE
                      (P/N:23A...
                    </div>
                  </div>
                  <div className="detailed-wanted-software-work-four-wrapper">
                    <div className="detailed-wanted-software-heading-container">
                      <div className="detailed-wanted-software-listing-detail-item-location">
                        <div className="detailed-wanted-software-listing-detail-item-date-parent">
                          <div className="detailed-wanted-software-listing-detail-item-date">
                            <img
                              className="detailed-wanted-software-chevron-right-icon2"
                              alt=""
                              src="/detailed_listing/markerpin021.svg"
                            />
                          </div>
                          <div className="detailed-wanted-software-location">India, New Delhi</div>
                        </div>
                        <div className="detailed-wanted-software-listing-detail-item-date-parent">
                          <div className="detailed-wanted-software-listing-detail-item-date">
                            <img
                              className="detailed-wanted-software-chevron-right-icon2"
                              alt=""
                              src="/detailed_listing/calendar1.svg"
                            />
                          </div>
                          <div className="detailed-wanted-software-type-of-work">April 09, 2023</div>
                        </div>
                      </div>
                      <div className="detailed-wanted-software-listing-team">
                        <div className="detailed-wanted-software-listing-team-member-wrapper">
                          <div className="detailed-wanted-software-listing-team-member">4.9/5</div>
                        </div>
                        <div className="detailed-wanted-software-contact-role">
                          <div className="detailed-wanted-software-supporting-text12">99,999 reviews</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="detailed-wanted-software-agent-details-icon">
                    <img className="detailed-wanted-software-icon4" alt="" src="/detailed_listing/icon1.svg" />

                    <div className="detailed-wanted-software-agent-extra-info">
                      <div className="detailed-wanted-software-listing-detail-item-pin-wrapper">
                        <div className="detailed-wanted-software-breadcrumb-items">
                          <div className="detailed-wanted-software-listing-short-info">$9,999,999.99</div>
                          <div className="detailed-wanted-software-supporting-text-wrapper">
                            <div className="detailed-wanted-software-supporting-text13">USD-Per Unit</div>
                          </div>
                        </div>
                      </div>
                      <img
                        className="detailed-wanted-software-divider-icon"
                        alt=""
                        src="/detailed_listing/divider4.svg"
                      />

                      <div className="detailed-wanted-software-another-agent-info">
                        <div className="detailed-wanted-software-detail-items">
                          <img
                            className="detailed-wanted-software-footer-social-child"
                            alt=""
                            src="/detailed_listing/group-58@2x.png"
                          />

                          <div className="detailed-wanted-software-listing-short-detail-item-pin-parent">
                            <div className="detailed-wanted-software-listing-short-detail-item-pin">
                              <div className="detailed-wanted-software-name">Olivia Rhye</div>
                            </div>
                            <div className="detailed-wanted-software-role1">Business Name</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="detailed-wanted-software-testiomonial-carousel-arrow2">
              <img
                className="detailed-wanted-software-arrow-left-icon"
                alt=""
                src="/detailed_listing/arrowleft.svg"
              />
            </div>
          </div>
          <div className="detailed-wanted-software-testiomonial-carousel-arrow3">
            <img
              className="detailed-wanted-software-arrow-left-icon"
              alt=""
              src="/detailed_listing/arrowright.svg"
            />
          </div>
        </div>
      </div>
      <div className="detailed-wanted-software-listing-image-grid4">
        <div className="detailed-wanted-software-heading-parent3">
          <div className="detailed-wanted-software-heading36">Related Spares</div>
          <div className="detailed-wanted-software-testimonial-description">
            <div className="detailed-wanted-software-description">View All</div>
          </div>
        </div>
      </div>
      <div className="detailed-wanted-software-listing-image-grid5">
        <div className="detailed-wanted-software-frame-parent4">
          <div className="detailed-wanted-software-frame-parent5">
            <div className="detailed-wanted-software-rectangle-parent10">
              <div className="detailed-wanted-software-frame-child12"></div>
              <div className="detailed-wanted-software-listing-card-badge-overlay">
                <img
                  className="detailed-wanted-software-listing-card-badge-overlay-child"
                  alt=""
                  src="/detailed_listing/rectangle-95@2x.png"
                />

                <div className="detailed-wanted-software-image-overlay">
                  <div className="detailed-wanted-software-badge4">
                    <div className="detailed-wanted-software-listing-card-price">Assured</div>
                  </div>
                  <div className="detailed-wanted-software-short-listing-team">
                    <div className="detailed-wanted-software-badge5">
                      <div className="detailed-wanted-software-listing-card-radar">ID# AXJYN</div>
                    </div>
                    <div className="detailed-wanted-software-buttonsbutton11">
                      <img
                        className="detailed-wanted-software-magnifier-1-icon"
                        alt=""
                        src={radar_1_1_2x}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="detailed-wanted-software-listing-detail-item">
                <div className="detailed-wanted-software-message-content">
                  <div className="detailed-wanted-software-text-frame">
                    <div className="detailed-wanted-software-text42">
                      New / Surplus 2021 Philips Health-care HD11 XE
                      (P/N:23A...
                    </div>
                  </div>
                  <div className="detailed-wanted-software-listing-detail-item-value">
                    <div className="detailed-wanted-software-listing-detail-item-pin-wrapper">
                      <div className="detailed-wanted-software-heading-container">
                        <div className="detailed-wanted-software-listing-detail-item-location">
                          <div className="detailed-wanted-software-listing-detail-item-date-parent">
                            <div className="detailed-wanted-software-listing-detail-item-date">
                              <img
                                className="detailed-wanted-software-chevron-right-icon2"
                                alt=""
                                src="/detailed_listing/markerpin021.svg"
                              />
                            </div>
                            <div className="detailed-wanted-software-location">India, New Delhi</div>
                          </div>
                          <div className="detailed-wanted-software-listing-detail-item-date-parent">
                            <div className="detailed-wanted-software-listing-detail-item-date">
                              <img
                                className="detailed-wanted-software-chevron-right-icon2"
                                alt=""
                                src="/detailed_listing/calendar1.svg"
                              />
                            </div>
                            <div className="detailed-wanted-software-type-of-work">April 09, 2023</div>
                          </div>
                        </div>
                        <div className="detailed-wanted-software-listing-team">
                          <div className="detailed-wanted-software-listing-team-member-wrapper">
                            <div className="detailed-wanted-software-listing-team-member">4.9/5</div>
                          </div>
                          <div className="detailed-wanted-software-contact-role">
                            <div className="detailed-wanted-software-supporting-text12">
                              99,999 reviews
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="detailed-wanted-software-listing">
                      <div className="detailed-wanted-software-breadcrumb-items">
                        <div className="detailed-wanted-software-listing-short-info">$9,999,999.99</div>
                        <div className="detailed-wanted-software-supporting-text-wrapper">
                          <div className="detailed-wanted-software-supporting-text13">USD-Per Unit</div>
                        </div>
                      </div>
                    </div>
                    <img
                      className="detailed-wanted-software-divider-icon"
                      alt=""
                      src="/detailed_listing/divider4.svg"
                    />

                    <div className="detailed-wanted-software-listing-detail-item-value-inner">
                      <div className="detailed-wanted-software-footer-social-parent">
                        <div className="detailed-wanted-software-footer-social">
                          <img
                            className="detailed-wanted-software-footer-social-child"
                            alt=""
                            src="/detailed_listing/group-58@2x.png"
                          />

                          <div className="detailed-wanted-software-listing-short-detail-item-pin-parent">
                            <div className="detailed-wanted-software-listing-short-detail-item-pin">
                              <div className="detailed-wanted-software-name">Olivia Rhye</div>
                            </div>
                            <div className="detailed-wanted-software-role1">Business Name</div>
                          </div>
                        </div>
                        <div className="detailed-wanted-software-listing-short-detail-item-date">
                          <img
                            className="detailed-wanted-software-icon1"
                            alt=""
                            src="/detailed_listing/icon1.svg"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="detailed-wanted-software-rectangle-parent10">
              <div className="detailed-wanted-software-frame-child12"></div>
              <div className="detailed-wanted-software-listing-card-badge-overlay">
                <img
                  className="detailed-wanted-software-listing-card-badge-overlay-child"
                  alt=""
                  src="/detailed_listing/rectangle-95@2x.png"
                />

                <div className="detailed-wanted-software-image-overlay">
                  <div className="detailed-wanted-software-badge4">
                    <div className="detailed-wanted-software-listing-card-price">Assured</div>
                  </div>
                  <div className="detailed-wanted-software-short-listing-team">
                    <div className="detailed-wanted-software-badge5">
                      <div className="detailed-wanted-software-listing-card-radar">ID# AXJYN</div>
                    </div>
                    <div className="detailed-wanted-software-buttonsbutton11">
                      <img
                        className="detailed-wanted-software-magnifier-1-icon"
                        alt=""
                        src={radar_1_1_2x}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="detailed-wanted-software-listing-detail-item">
                <div className="detailed-wanted-software-message-content">
                  <div className="detailed-wanted-software-text-frame">
                    <div className="detailed-wanted-software-text42">
                      New / Surplus 2021 Philips Health-care HD11 XE
                      (P/N:23A...
                    </div>
                  </div>
                  <div className="detailed-wanted-software-listing-detail-item-value">
                    <div className="detailed-wanted-software-listing-detail-item-pin-wrapper">
                      <div className="detailed-wanted-software-heading-container">
                        <div className="detailed-wanted-software-listing-detail-item-location">
                          <div className="detailed-wanted-software-listing-detail-item-date-parent">
                            <div className="detailed-wanted-software-listing-detail-item-date">
                              <img
                                className="detailed-wanted-software-chevron-right-icon2"
                                alt=""
                                src="/detailed_listing/markerpin021.svg"
                              />
                            </div>
                            <div className="detailed-wanted-software-location">India, New Delhi</div>
                          </div>
                          <div className="detailed-wanted-software-listing-detail-item-date-parent">
                            <div className="detailed-wanted-software-listing-detail-item-date">
                              <img
                                className="detailed-wanted-software-chevron-right-icon2"
                                alt=""
                                src="/detailed_listing/calendar1.svg"
                              />
                            </div>
                            <div className="detailed-wanted-software-type-of-work">April 09, 2023</div>
                          </div>
                        </div>
                        <div className="detailed-wanted-software-listing-team">
                          <div className="detailed-wanted-software-listing-team-member-wrapper">
                            <div className="detailed-wanted-software-listing-team-member">4.9/5</div>
                          </div>
                          <div className="detailed-wanted-software-contact-role">
                            <div className="detailed-wanted-software-supporting-text12">
                              99,999 reviews
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="detailed-wanted-software-listing">
                      <div className="detailed-wanted-software-breadcrumb-items">
                        <div className="detailed-wanted-software-listing-short-info">$9,999,999.99</div>
                        <div className="detailed-wanted-software-supporting-text-wrapper">
                          <div className="detailed-wanted-software-supporting-text13">USD-Per Unit</div>
                        </div>
                      </div>
                    </div>
                    <img
                      className="detailed-wanted-software-divider-icon"
                      alt=""
                      src="/detailed_listing/divider4.svg"
                    />

                    <div className="detailed-wanted-software-listing-detail-item-value-inner">
                      <div className="detailed-wanted-software-footer-social-parent">
                        <div className="detailed-wanted-software-footer-social">
                          <img
                            className="detailed-wanted-software-footer-social-child"
                            alt=""
                            src="/detailed_listing/group-58@2x.png"
                          />

                          <div className="detailed-wanted-software-listing-short-detail-item-pin-parent">
                            <div className="detailed-wanted-software-listing-short-detail-item-pin">
                              <div className="detailed-wanted-software-name">Olivia Rhye</div>
                            </div>
                            <div className="detailed-wanted-software-role1">Business Name</div>
                          </div>
                        </div>
                        <div className="detailed-wanted-software-listing-short-detail-item-date">
                          <img
                            className="detailed-wanted-software-icon1"
                            alt=""
                            src="/detailed_listing/icon1.svg"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="detailed-wanted-software-rectangle-parent10">
              <div className="detailed-wanted-software-frame-child12"></div>
              <div className="detailed-wanted-software-listing-card-badge-overlay">
                <img
                  className="detailed-wanted-software-listing-card-badge-overlay-child"
                  alt=""
                  src="/detailed_listing/rectangle-95@2x.png"
                />

                <div className="detailed-wanted-software-image-overlay">
                  <div className="detailed-wanted-software-badge4">
                    <div className="detailed-wanted-software-listing-card-price">Assured</div>
                  </div>
                  <div className="detailed-wanted-software-short-listing-team">
                    <div className="detailed-wanted-software-badge5">
                      <div className="detailed-wanted-software-listing-card-radar">ID# AXJYN</div>
                    </div>
                    <div className="detailed-wanted-software-buttonsbutton11">
                      <img
                        className="detailed-wanted-software-magnifier-1-icon"
                        alt=""
                        src={radar_1_1_2x}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="detailed-wanted-software-listing-detail-item">
                <div className="detailed-wanted-software-message-content">
                  <div className="detailed-wanted-software-text-frame">
                    <div className="detailed-wanted-software-text42">
                      New / Surplus 2021 Philips Health-care HD11 XE
                      (P/N:23A...
                    </div>
                  </div>
                  <div className="detailed-wanted-software-listing-detail-item-value">
                    <div className="detailed-wanted-software-listing-detail-item-pin-wrapper">
                      <div className="detailed-wanted-software-heading-container">
                        <div className="detailed-wanted-software-listing-detail-item-location">
                          <div className="detailed-wanted-software-listing-detail-item-date-parent">
                            <div className="detailed-wanted-software-listing-detail-item-date">
                              <img
                                className="detailed-wanted-software-chevron-right-icon2"
                                alt=""
                                src="/detailed_listing/markerpin021.svg"
                              />
                            </div>
                            <div className="detailed-wanted-software-location">India, New Delhi</div>
                          </div>
                          <div className="detailed-wanted-software-listing-detail-item-date-parent">
                            <div className="detailed-wanted-software-listing-detail-item-date">
                              <img
                                className="detailed-wanted-software-chevron-right-icon2"
                                alt=""
                                src="/detailed_listing/calendar1.svg"
                              />
                            </div>
                            <div className="detailed-wanted-software-type-of-work">April 09, 2023</div>
                          </div>
                        </div>
                        <div className="detailed-wanted-software-listing-team">
                          <div className="detailed-wanted-software-listing-team-member-wrapper">
                            <div className="detailed-wanted-software-listing-team-member">4.9/5</div>
                          </div>
                          <div className="detailed-wanted-software-contact-role">
                            <div className="detailed-wanted-software-supporting-text12">
                              99,999 reviews
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="detailed-wanted-software-listing">
                      <div className="detailed-wanted-software-breadcrumb-items">
                        <div className="detailed-wanted-software-listing-short-info">$9,999,999.99</div>
                        <div className="detailed-wanted-software-supporting-text-wrapper">
                          <div className="detailed-wanted-software-supporting-text13">USD-Per Unit</div>
                        </div>
                      </div>
                    </div>
                    <img
                      className="detailed-wanted-software-divider-icon"
                      alt=""
                      src="/detailed_listing/divider4.svg"
                    />

                    <div className="detailed-wanted-software-listing-detail-item-value-inner">
                      <div className="detailed-wanted-software-footer-social-parent">
                        <div className="detailed-wanted-software-footer-social">
                          <img
                            className="detailed-wanted-software-footer-social-child"
                            alt=""
                            src="/detailed_listing/group-58@2x.png"
                          />

                          <div className="detailed-wanted-software-listing-short-detail-item-pin-parent">
                            <div className="detailed-wanted-software-listing-short-detail-item-pin">
                              <div className="detailed-wanted-software-name">Olivia Rhye</div>
                            </div>
                            <div className="detailed-wanted-software-role1">Business Name</div>
                          </div>
                        </div>
                        <div className="detailed-wanted-software-listing-short-detail-item-date">
                          <img
                            className="detailed-wanted-software-icon1"
                            alt=""
                            src="/detailed_listing/icon1.svg"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="detailed-wanted-software-rectangle-parent10">
              <div className="detailed-wanted-software-frame-child12"></div>
              <div className="detailed-wanted-software-listing-card-badge-overlay">
                <img
                  className="detailed-wanted-software-listing-card-badge-overlay-child"
                  alt=""
                  src="/detailed_listing/rectangle-95@2x.png"
                />

                <div className="detailed-wanted-software-image-overlay3">
                  <div className="detailed-wanted-software-badge4">
                    <div className="detailed-wanted-software-listing-card-price">Assured</div>
                  </div>
                  <div className="detailed-wanted-software-badge-group">
                    <div className="detailed-wanted-software-badge5">
                      <div className="detailed-wanted-software-listing-card-radar">ID# AXJYN</div>
                    </div>
                    <div className="detailed-wanted-software-buttonsbutton14">
                      <img
                        className="detailed-wanted-software-magnifier-1-icon"
                        alt=""
                        src={radar_1_1_2x}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="detailed-wanted-software-listing-detail-item">
                <div className="detailed-wanted-software-message-content">
                  <div className="detailed-wanted-software-text-frame">
                    <div className="detailed-wanted-software-text42">
                      New / Surplus 2021 Philips Health-care HD11 XE
                      (P/N:23A...
                    </div>
                  </div>
                  <div className="detailed-wanted-software-work-four-wrapper">
                    <div className="detailed-wanted-software-heading-container">
                      <div className="detailed-wanted-software-listing-detail-item-location">
                        <div className="detailed-wanted-software-listing-detail-item-date-parent">
                          <div className="detailed-wanted-software-listing-detail-item-date">
                            <img
                              className="detailed-wanted-software-chevron-right-icon2"
                              alt=""
                              src="/detailed_listing/markerpin021.svg"
                            />
                          </div>
                          <div className="detailed-wanted-software-location">India, New Delhi</div>
                        </div>
                        <div className="detailed-wanted-software-listing-detail-item-date-parent">
                          <div className="detailed-wanted-software-listing-detail-item-date">
                            <img
                              className="detailed-wanted-software-chevron-right-icon2"
                              alt=""
                              src="/detailed_listing/calendar1.svg"
                            />
                          </div>
                          <div className="detailed-wanted-software-type-of-work">April 09, 2023</div>
                        </div>
                      </div>
                      <div className="detailed-wanted-software-listing-team">
                        <div className="detailed-wanted-software-listing-team-member-wrapper">
                          <div className="detailed-wanted-software-listing-team-member">4.9/5</div>
                        </div>
                        <div className="detailed-wanted-software-contact-role">
                          <div className="detailed-wanted-software-supporting-text12">99,999 reviews</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="detailed-wanted-software-agent-details-icon">
                    <img className="detailed-wanted-software-icon4" alt="" src="/detailed_listing/icon1.svg" />

                    <div className="detailed-wanted-software-agent-extra-info">
                      <div className="detailed-wanted-software-listing-detail-item-pin-wrapper">
                        <div className="detailed-wanted-software-breadcrumb-items">
                          <div className="detailed-wanted-software-listing-short-info">$9,999,999.99</div>
                          <div className="detailed-wanted-software-supporting-text-wrapper">
                            <div className="detailed-wanted-software-supporting-text13">USD-Per Unit</div>
                          </div>
                        </div>
                      </div>
                      <img
                        className="detailed-wanted-software-divider-icon"
                        alt=""
                        src="/detailed_listing/divider4.svg"
                      />

                      <div className="detailed-wanted-software-another-agent-info">
                        <div className="detailed-wanted-software-detail-items">
                          <img
                            className="detailed-wanted-software-footer-social-child"
                            alt=""
                            src="/detailed_listing/group-58@2x.png"
                          />

                          <div className="detailed-wanted-software-listing-short-detail-item-pin-parent">
                            <div className="detailed-wanted-software-listing-short-detail-item-pin">
                              <div className="detailed-wanted-software-name">Olivia Rhye</div>
                            </div>
                            <div className="detailed-wanted-software-role1">Business Name</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="detailed-wanted-software-testiomonial-carousel-arrow4">
              <img
                className="detailed-wanted-software-arrow-left-icon"
                alt=""
                src="/detailed_listing/arrowleft.svg"
              />
            </div>
          </div>
          <div className="detailed-wanted-software-testiomonial-carousel-arrow5">
            <img
              className="detailed-wanted-software-arrow-left-icon"
              alt=""
              src="/detailed_listing/arrowright.svg"
            />
          </div>
        </div>
      </div>
      <div className="detailed-wanted-software-listing-image-grid4">
        <div className="detailed-wanted-software-heading-parent3">
          <div className="detailed-wanted-software-heading38">Related Services</div>
          <div className="detailed-wanted-software-testimonial-description">
            <div className="detailed-wanted-software-description">View All</div>
          </div>
        </div>
      </div>
      <div className="detailed-wanted-software-listing-image-grid5">
        <div className="detailed-wanted-software-frame-parent4">
          <div className="detailed-wanted-software-frame-parent5">
            <div className="detailed-wanted-software-rectangle-parent10">
              <div className="detailed-wanted-software-frame-child12"></div>
              <div className="detailed-wanted-software-listing-card-badge-overlay">
                <img
                  className="detailed-wanted-software-listing-card-badge-overlay-child"
                  alt=""
                  src="/detailed_listing/rectangle-95@2x.png"
                />

                <div className="detailed-wanted-software-image-overlay">
                  <div className="detailed-wanted-software-badge4">
                    <div className="detailed-wanted-software-listing-card-price">Assured</div>
                  </div>
                  <div className="detailed-wanted-software-short-listing-team">
                    <div className="detailed-wanted-software-badge5">
                      <div className="detailed-wanted-software-listing-card-radar">ID# AXJYN</div>
                    </div>
                    <div className="detailed-wanted-software-buttonsbutton11">
                      <img
                        className="detailed-wanted-software-magnifier-1-icon"
                        alt=""
                        src={radar_1_1_2x}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="detailed-wanted-software-listing-detail-item">
                <div className="detailed-wanted-software-message-content">
                  <div className="detailed-wanted-software-text-frame">
                    <div className="detailed-wanted-software-text42">
                      New / Surplus 2021 Philips Health-care HD11 XE
                      (P/N:23A...
                    </div>
                  </div>
                  <div className="detailed-wanted-software-listing-detail-item-value">
                    <div className="detailed-wanted-software-listing-detail-item-pin-wrapper">
                      <div className="detailed-wanted-software-heading-container">
                        <div className="detailed-wanted-software-listing-detail-item-location">
                          <div className="detailed-wanted-software-listing-detail-item-date-parent">
                            <div className="detailed-wanted-software-listing-detail-item-date">
                              <img
                                className="detailed-wanted-software-chevron-right-icon2"
                                alt=""
                                src="/detailed_listing/markerpin021.svg"
                              />
                            </div>
                            <div className="detailed-wanted-software-location">India, New Delhi</div>
                          </div>
                          <div className="detailed-wanted-software-listing-detail-item-date-parent">
                            <div className="detailed-wanted-software-listing-detail-item-date">
                              <img
                                className="detailed-wanted-software-chevron-right-icon2"
                                alt=""
                                src="/detailed_listing/calendar1.svg"
                              />
                            </div>
                            <div className="detailed-wanted-software-type-of-work">April 09, 2023</div>
                          </div>
                        </div>
                        <div className="detailed-wanted-software-listing-team">
                          <div className="detailed-wanted-software-listing-team-member-wrapper">
                            <div className="detailed-wanted-software-listing-team-member">4.9/5</div>
                          </div>
                          <div className="detailed-wanted-software-contact-role">
                            <div className="detailed-wanted-software-supporting-text12">
                              99,999 reviews
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="detailed-wanted-software-listing">
                      <div className="detailed-wanted-software-breadcrumb-items">
                        <div className="detailed-wanted-software-listing-short-info">$9,999,999.99</div>
                        <div className="detailed-wanted-software-supporting-text-wrapper">
                          <div className="detailed-wanted-software-supporting-text13">USD-Per Unit</div>
                        </div>
                      </div>
                    </div>
                    <img
                      className="detailed-wanted-software-divider-icon"
                      alt=""
                      src="/detailed_listing/divider4.svg"
                    />

                    <div className="detailed-wanted-software-listing-detail-item-value-inner">
                      <div className="detailed-wanted-software-footer-social-parent">
                        <div className="detailed-wanted-software-footer-social">
                          <img
                            className="detailed-wanted-software-footer-social-child"
                            alt=""
                            src="/detailed_listing/group-58@2x.png"
                          />

                          <div className="detailed-wanted-software-listing-short-detail-item-pin-parent">
                            <div className="detailed-wanted-software-listing-short-detail-item-pin">
                              <div className="detailed-wanted-software-name">Olivia Rhye</div>
                            </div>
                            <div className="detailed-wanted-software-role1">Business Name</div>
                          </div>
                        </div>
                        <div className="detailed-wanted-software-listing-short-detail-item-date">
                          <img
                            className="detailed-wanted-software-icon1"
                            alt=""
                            src="/detailed_listing/icon1.svg"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="detailed-wanted-software-rectangle-parent10">
              <div className="detailed-wanted-software-frame-child12"></div>
              <div className="detailed-wanted-software-listing-card-badge-overlay">
                <img
                  className="detailed-wanted-software-listing-card-badge-overlay-child"
                  alt=""
                  src="/detailed_listing/rectangle-95@2x.png"
                />

                <div className="detailed-wanted-software-image-overlay">
                  <div className="detailed-wanted-software-badge4">
                    <div className="detailed-wanted-software-listing-card-price">Assured</div>
                  </div>
                  <div className="detailed-wanted-software-short-listing-team">
                    <div className="detailed-wanted-software-badge5">
                      <div className="detailed-wanted-software-listing-card-radar">ID# AXJYN</div>
                    </div>
                    <div className="detailed-wanted-software-buttonsbutton11">
                      <img
                        className="detailed-wanted-software-magnifier-1-icon"
                        alt=""
                        src={radar_1_1_2x}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="detailed-wanted-software-listing-detail-item">
                <div className="detailed-wanted-software-message-content">
                  <div className="detailed-wanted-software-text-frame">
                    <div className="detailed-wanted-software-text42">
                      New / Surplus 2021 Philips Health-care HD11 XE
                      (P/N:23A...
                    </div>
                  </div>
                  <div className="detailed-wanted-software-listing-detail-item-value">
                    <div className="detailed-wanted-software-listing-detail-item-pin-wrapper">
                      <div className="detailed-wanted-software-heading-container">
                        <div className="detailed-wanted-software-listing-detail-item-location">
                          <div className="detailed-wanted-software-listing-detail-item-date-parent">
                            <div className="detailed-wanted-software-listing-detail-item-date">
                              <img
                                className="detailed-wanted-software-chevron-right-icon2"
                                alt=""
                                src="/detailed_listing/markerpin021.svg"
                              />
                            </div>
                            <div className="detailed-wanted-software-location">India, New Delhi</div>
                          </div>
                          <div className="detailed-wanted-software-listing-detail-item-date-parent">
                            <div className="detailed-wanted-software-listing-detail-item-date">
                              <img
                                className="detailed-wanted-software-chevron-right-icon2"
                                alt=""
                                src="/detailed_listing/calendar1.svg"
                              />
                            </div>
                            <div className="detailed-wanted-software-type-of-work">April 09, 2023</div>
                          </div>
                        </div>
                        <div className="detailed-wanted-software-listing-team">
                          <div className="detailed-wanted-software-listing-team-member-wrapper">
                            <div className="detailed-wanted-software-listing-team-member">4.9/5</div>
                          </div>
                          <div className="detailed-wanted-software-contact-role">
                            <div className="detailed-wanted-software-supporting-text12">
                              99,999 reviews
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="detailed-wanted-software-listing">
                      <div className="detailed-wanted-software-breadcrumb-items">
                        <div className="detailed-wanted-software-listing-short-info">$9,999,999.99</div>
                        <div className="detailed-wanted-software-supporting-text-wrapper">
                          <div className="detailed-wanted-software-supporting-text13">USD-Per Unit</div>
                        </div>
                      </div>
                    </div>
                    <img
                      className="detailed-wanted-software-divider-icon"
                      alt=""
                      src="/detailed_listing/divider4.svg"
                    />

                    <div className="detailed-wanted-software-listing-detail-item-value-inner">
                      <div className="detailed-wanted-software-footer-social-parent">
                        <div className="detailed-wanted-software-footer-social">
                          <img
                            className="detailed-wanted-software-footer-social-child"
                            alt=""
                            src="/detailed_listing/group-58@2x.png"
                          />

                          <div className="detailed-wanted-software-listing-short-detail-item-pin-parent">
                            <div className="detailed-wanted-software-listing-short-detail-item-pin">
                              <div className="detailed-wanted-software-name">Olivia Rhye</div>
                            </div>
                            <div className="detailed-wanted-software-role1">Business Name</div>
                          </div>
                        </div>
                        <div className="detailed-wanted-software-listing-short-detail-item-date">
                          <img
                            className="detailed-wanted-software-icon1"
                            alt=""
                            src="/detailed_listing/icon1.svg"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="detailed-wanted-software-rectangle-parent10">
              <div className="detailed-wanted-software-frame-child12"></div>
              <div className="detailed-wanted-software-listing-card-badge-overlay">
                <img
                  className="detailed-wanted-software-listing-card-badge-overlay-child"
                  alt=""
                  src="/detailed_listing/rectangle-95@2x.png"
                />

                <div className="detailed-wanted-software-image-overlay">
                  <div className="detailed-wanted-software-badge4">
                    <div className="detailed-wanted-software-listing-card-price">Assured</div>
                  </div>
                  <div className="detailed-wanted-software-short-listing-team">
                    <div className="detailed-wanted-software-badge5">
                      <div className="detailed-wanted-software-listing-card-radar">ID# AXJYN</div>
                    </div>
                    <div className="detailed-wanted-software-buttonsbutton11">
                      <img
                        className="detailed-wanted-software-magnifier-1-icon"
                        alt=""
                        src={radar_1_1_2x}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="detailed-wanted-software-listing-detail-item">
                <div className="detailed-wanted-software-message-content">
                  <div className="detailed-wanted-software-text-frame">
                    <div className="detailed-wanted-software-text42">
                      New / Surplus 2021 Philips Health-care HD11 XE
                      (P/N:23A...
                    </div>
                  </div>
                  <div className="detailed-wanted-software-listing-detail-item-value">
                    <div className="detailed-wanted-software-listing-detail-item-pin-wrapper">
                      <div className="detailed-wanted-software-heading-container">
                        <div className="detailed-wanted-software-listing-detail-item-location">
                          <div className="detailed-wanted-software-listing-detail-item-date-parent">
                            <div className="detailed-wanted-software-listing-detail-item-date">
                              <img
                                className="detailed-wanted-software-chevron-right-icon2"
                                alt=""
                                src="/detailed_listing/markerpin021.svg"
                              />
                            </div>
                            <div className="detailed-wanted-software-location">India, New Delhi</div>
                          </div>
                          <div className="detailed-wanted-software-listing-detail-item-date-parent">
                            <div className="detailed-wanted-software-listing-detail-item-date">
                              <img
                                className="detailed-wanted-software-chevron-right-icon2"
                                alt=""
                                src="/detailed_listing/calendar1.svg"
                              />
                            </div>
                            <div className="detailed-wanted-software-type-of-work">April 09, 2023</div>
                          </div>
                        </div>
                        <div className="detailed-wanted-software-listing-team">
                          <div className="detailed-wanted-software-listing-team-member-wrapper">
                            <div className="detailed-wanted-software-listing-team-member">4.9/5</div>
                          </div>
                          <div className="detailed-wanted-software-contact-role">
                            <div className="detailed-wanted-software-supporting-text12">
                              99,999 reviews
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="detailed-wanted-software-listing">
                      <div className="detailed-wanted-software-breadcrumb-items">
                        <div className="detailed-wanted-software-listing-short-info">$9,999,999.99</div>
                        <div className="detailed-wanted-software-supporting-text-wrapper">
                          <div className="detailed-wanted-software-supporting-text13">USD-Per Unit</div>
                        </div>
                      </div>
                    </div>
                    <img
                      className="detailed-wanted-software-divider-icon"
                      alt=""
                      src="/detailed_listing/divider4.svg"
                    />

                    <div className="detailed-wanted-software-listing-detail-item-value-inner">
                      <div className="detailed-wanted-software-footer-social-parent">
                        <div className="detailed-wanted-software-footer-social">
                          <img
                            className="detailed-wanted-software-footer-social-child"
                            alt=""
                            src="/detailed_listing/group-58@2x.png"
                          />

                          <div className="detailed-wanted-software-listing-short-detail-item-pin-parent">
                            <div className="detailed-wanted-software-listing-short-detail-item-pin">
                              <div className="detailed-wanted-software-name">Olivia Rhye</div>
                            </div>
                            <div className="detailed-wanted-software-role1">Business Name</div>
                          </div>
                        </div>
                        <div className="detailed-wanted-software-listing-short-detail-item-date">
                          <img
                            className="detailed-wanted-software-icon1"
                            alt=""
                            src="/detailed_listing/icon1.svg"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="detailed-wanted-software-rectangle-parent10">
              <div className="detailed-wanted-software-frame-child12"></div>
              <div className="detailed-wanted-software-rectangle-parent30">
                <img
                  className="detailed-wanted-software-listing-card-badge-overlay-child"
                  alt=""
                  src="/detailed_listing/rectangle-95@2x.png"
                />

                <div className="detailed-wanted-software-image-overlay3">
                  <div className="detailed-wanted-software-badge4">
                    <div className="detailed-wanted-software-listing-card-price">Assured</div>
                  </div>
                  <div className="detailed-wanted-software-badge-group">
                    <div className="detailed-wanted-software-badge5">
                      <div className="detailed-wanted-software-listing-card-radar">ID# AXJYN</div>
                    </div>
                    <div className="detailed-wanted-software-buttonsbutton14">
                      <img
                        className="detailed-wanted-software-magnifier-1-icon"
                        alt=""
                        src={radar_1_1_2x}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="detailed-wanted-software-listing-detail-item">
                <div className="detailed-wanted-software-message-content">
                  <div className="detailed-wanted-software-text-frame">
                    <div className="detailed-wanted-software-text42">
                      New / Surplus 2021 Philips Health-care HD11 XE
                      (P/N:23A...
                    </div>
                  </div>
                  <div className="detailed-wanted-software-icon-group">
                    <img className="detailed-wanted-software-icon4" alt="" src="/detailed_listing/icon1.svg" />

                    <div className="detailed-wanted-software-message-content">
                      <div className="detailed-wanted-software-listing-detail-item-pin-wrapper">
                        <div className="detailed-wanted-software-listing-detail-item-location">
                          <div className="detailed-wanted-software-listing-detail-item-date-parent">
                            <div className="detailed-wanted-software-listing-detail-item-date">
                              <img
                                className="detailed-wanted-software-chevron-right-icon2"
                                alt=""
                                src="/detailed_listing/markerpin021.svg"
                              />
                            </div>
                            <div className="detailed-wanted-software-location">India, New Delhi</div>
                          </div>
                          <div className="detailed-wanted-software-listing-detail-item-date-parent">
                            <div className="detailed-wanted-software-listing-detail-item-date">
                              <img
                                className="detailed-wanted-software-chevron-right-icon2"
                                alt=""
                                src="/detailed_listing/calendar1.svg"
                              />
                            </div>
                            <div className="detailed-wanted-software-type-of-work">April 09, 2023</div>
                          </div>
                        </div>
                      </div>
                      <div className="detailed-wanted-software-supporting-work-five">
                        <div className="detailed-wanted-software-breadcrumb-items">
                          <div className="detailed-wanted-software-listing-team-member-wrapper">
                            <div className="detailed-wanted-software-listing-team-member">4.9/5</div>
                          </div>
                          <div className="detailed-wanted-software-contact-role">
                            <div className="detailed-wanted-software-supporting-text12">
                              99,999 reviews
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="detailed-wanted-software-supporting-six-wrapper">
                        <div className="detailed-wanted-software-breadcrumb-items">
                          <div className="detailed-wanted-software-listing-short-info">$9,999,999.99</div>
                          <div className="detailed-wanted-software-supporting-text-wrapper">
                            <div className="detailed-wanted-software-supporting-text13">USD-Per Unit</div>
                          </div>
                        </div>
                      </div>
                      <div className="detailed-wanted-software-divider-container">
                        <img
                          className="detailed-wanted-software-divider-icon3"
                          alt=""
                          src="/detailed_listing/divider4.svg"
                        />
                      </div>
                      <div className="detailed-wanted-software-team-info-four">
                        <div className="detailed-wanted-software-detail-items">
                          <img
                            className="detailed-wanted-software-footer-social-child"
                            alt=""
                            src="/detailed_listing/group-58@2x.png"
                          />

                          <div className="detailed-wanted-software-listing-short-detail-item-pin-parent">
                            <div className="detailed-wanted-software-listing-short-detail-item-pin">
                              <div className="detailed-wanted-software-name">Olivia Rhye</div>
                            </div>
                            <div className="detailed-wanted-software-role1">Business Name</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="detailed-wanted-software-testiomonial-carousel-arrow6">
              <img
                className="detailed-wanted-software-arrow-left-icon"
                alt=""
                src="/detailed_listing/arrowleft.svg"
              />
            </div>
          </div>
          <div className="detailed-wanted-software-testiomonial-carousel-arrow7">
            <img
              className="detailed-wanted-software-arrow-left-icon"
              alt=""
              src="/detailed_listing/arrowright.svg"
            />
          </div>
        </div>
      </div>
      <div className="detailed-wanted-software-breadcrumb-button-base4">
        <div className="detailed-wanted-software-text6">...</div>
      </div>
      <img
        className="detailed-wanted-software-chevron-right-icon3"
        alt=""
        src={chevronright}
      /> */}

        {/* <div className="detailed-wanted-software-breadcrumb-button-base5">
        <div className="detailed-wanted-software-text97">Another link</div>
      </div>
      <img
        className="detailed-wanted-software-chevron-right-icon4"
        alt=""
        src={chevronright}
      /> */}

        <img className="detailed-wanted-software-divider-icon24" alt="" src="/detailed_listing/divider5.svg" />

        {/* <div className="detailed-wanted-software-actions1">
        <div className="detailed-wanted-software-button2">
          <img
            className="detailed-wanted-software-placeholder-icon17"
            alt=""
            src="/detailed_listing/placeholder3.svg"
          />

          <div className="detailed-wanted-software-text-padding4">
            <div className="detailed-wanted-software-text98">Tertiary</div>
          </div>
          <img
            className="detailed-wanted-software-placeholder-icon17"
            alt=""
            src="/detailed_listing/placeholder3.svg"
          />
        </div>
        <div className="detailed-wanted-software-button3">
          <img
            className="detailed-wanted-software-placeholder-icon17"
            alt=""
            src="/detailed_listing/placeholder4.svg"
          />

          <div className="detailed-wanted-software-text-padding5">
            <div className="detailed-wanted-software-text98">Secondary</div>
          </div>
          <img
            className="detailed-wanted-software-placeholder-icon17"
            alt=""
            src="/detailed_listing/placeholder4.svg"
          />
        </div>
        <div className="detailed-wanted-software-button4">
          <img
            className="detailed-wanted-software-placeholder-icon17"
            alt=""
            src={placeholder1}
          />

          <div className="detailed-wanted-software-text-padding5">
            <div className="detailed-wanted-software-text98">Secondary</div>
          </div>
          <img
            className="detailed-wanted-software-placeholder-icon17"
            alt=""
            src={placeholder1}
          />
        </div>
        <div className="detailed-wanted-software-button5">
          <img
            className="detailed-wanted-software-placeholder-icon17"
            alt=""
            src="/detailed_listing/placeholder2.svg"
          />

          <div className="detailed-wanted-software-text-padding7">
            <div className="detailed-wanted-software-text98">Primary</div>
          </div>
          <img
            className="detailed-wanted-software-placeholder-icon17"
            alt=""
            src="/detailed_listing/placeholder2.svg"
          />
        </div>
      </div> */}
        {/* <div className="detailed-wanted-software-listing-image-grid4">
        <div className="detailed-wanted-software-heading-parent3">
          <div className="detailed-wanted-software-heading36">Related Software</div>
          <div className="detailed-wanted-software-testimonial-description">
            <div className="detailed-wanted-software-description">View All</div>
          </div>
        </div>
      </div> */}
        {/* <div className="detailed-wanted-software-listing-image-grid11">
        <div className="detailed-wanted-software-frame-parent4">
          <div className="detailed-wanted-software-frame-parent5">
            <div className="detailed-wanted-software-rectangle-parent10">
              <div className="detailed-wanted-software-frame-child12"></div>
              <div className="detailed-wanted-software-rectangle-parent30">
                <img
                  className="detailed-wanted-software-listing-card-badge-overlay-child"
                  alt=""
                  src="/detailed_listing/rectangle-95@2x.png"
                />

                <div className="detailed-wanted-software-image-overlay">
                  <div className="detailed-wanted-software-badge4">
                    <div className="detailed-wanted-software-listing-card-price">Assured</div>
                  </div>
                  <div className="detailed-wanted-software-short-listing-team">
                    <div className="detailed-wanted-software-badge5">
                      <div className="detailed-wanted-software-listing-card-radar">ID# AXJYN</div>
                    </div>
                    <div className="detailed-wanted-software-buttonsbutton11">
                      <img
                        className="detailed-wanted-software-magnifier-1-icon"
                        alt=""
                        src={radar_1_1_2x}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="detailed-wanted-software-listing-detail-item">
                <div className="detailed-wanted-software-message-content">
                  <div className="detailed-wanted-software-text-frame">
                    <div className="detailed-wanted-software-text42">
                      New / Surplus 2021 Philips Health-care HD11 XE
                      (P/N:23A...
                    </div>
                  </div>
                  <div className="detailed-wanted-software-listing-detail-item-value">
                    <div className="detailed-wanted-software-listing-detail-item-pin-wrapper">
                      <div className="detailed-wanted-software-heading-container">
                        <div className="detailed-wanted-software-listing-detail-item-location">
                          <div className="detailed-wanted-software-listing-detail-item-date-parent">
                            <div className="detailed-wanted-software-listing-detail-item-date">
                              <img
                                className="detailed-wanted-software-chevron-right-icon2"
                                alt=""
                                src="/detailed_listing/markerpin021.svg"
                              />
                            </div>
                            <div className="detailed-wanted-software-location">India, New Delhi</div>
                          </div>
                          <div className="detailed-wanted-software-listing-detail-item-date-parent">
                            <div className="detailed-wanted-software-listing-detail-item-date">
                              <img
                                className="detailed-wanted-software-chevron-right-icon2"
                                alt=""
                                src="/detailed_listing/calendar1.svg"
                              />
                            </div>
                            <div className="detailed-wanted-software-type-of-work">April 09, 2023</div>
                          </div>
                        </div>
                        <div className="detailed-wanted-software-listing-team">
                          <div className="detailed-wanted-software-listing-team-member-wrapper">
                            <div className="detailed-wanted-software-listing-team-member">4.9/5</div>
                          </div>
                          <div className="detailed-wanted-software-contact-role">
                            <div className="detailed-wanted-software-supporting-text12">
                              99,999 reviews
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="detailed-wanted-software-listing">
                      <div className="detailed-wanted-software-breadcrumb-items">
                        <div className="detailed-wanted-software-listing-short-info">$9,999,999.99</div>
                        <div className="detailed-wanted-software-supporting-text-wrapper">
                          <div className="detailed-wanted-software-supporting-text13">USD-Per Unit</div>
                        </div>
                      </div>
                    </div>
                    <img
                      className="detailed-wanted-software-divider-icon"
                      alt=""
                      src="/detailed_listing/divider4.svg"
                    />

                    <div className="detailed-wanted-software-listing-detail-item-value-inner">
                      <div className="detailed-wanted-software-footer-social-parent">
                        <div className="detailed-wanted-software-footer-social">
                          <img
                            className="detailed-wanted-software-footer-social-child"
                            alt=""
                            src="/detailed_listing/group-58@2x.png"
                          />

                          <div className="detailed-wanted-software-listing-short-detail-item-pin-parent">
                            <div className="detailed-wanted-software-listing-short-detail-item-pin">
                              <div className="detailed-wanted-software-name">Olivia Rhye</div>
                            </div>
                            <div className="detailed-wanted-software-role1">Business Name</div>
                          </div>
                        </div>
                        <div className="detailed-wanted-software-listing-short-detail-item-date">
                          <img
                            className="detailed-wanted-software-icon1"
                            alt=""
                            src="/detailed_listing/icon1.svg"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="detailed-wanted-software-rectangle-parent10">
              <div className="detailed-wanted-software-frame-child12"></div>
              <div className="detailed-wanted-software-rectangle-parent30">
                <img
                  className="detailed-wanted-software-listing-card-badge-overlay-child"
                  alt=""
                  src="/detailed_listing/rectangle-95@2x.png"
                />

                <div className="detailed-wanted-software-image-overlay">
                  <div className="detailed-wanted-software-badge4">
                    <div className="detailed-wanted-software-listing-card-price">Assured</div>
                  </div>
                  <div className="detailed-wanted-software-short-listing-team">
                    <div className="detailed-wanted-software-badge5">
                      <div className="detailed-wanted-software-listing-card-radar">ID# AXJYN</div>
                    </div>
                    <div className="detailed-wanted-software-buttonsbutton11">
                      <img
                        className="detailed-wanted-software-magnifier-1-icon"
                        alt=""
                        src={radar_1_1_2x}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="detailed-wanted-software-listing-detail-item">
                <div className="detailed-wanted-software-message-content">
                  <div className="detailed-wanted-software-text-frame">
                    <div className="detailed-wanted-software-text42">
                      New / Surplus 2021 Philips Health-care HD11 XE
                      (P/N:23A...
                    </div>
                  </div>
                  <div className="detailed-wanted-software-listing-detail-item-value">
                    <div className="detailed-wanted-software-listing-detail-item-pin-wrapper">
                      <div className="detailed-wanted-software-heading-container">
                        <div className="detailed-wanted-software-listing-detail-item-location">
                          <div className="detailed-wanted-software-listing-detail-item-date-parent">
                            <div className="detailed-wanted-software-listing-detail-item-date">
                              <img
                                className="detailed-wanted-software-chevron-right-icon2"
                                alt=""
                                src="/detailed_listing/markerpin021.svg"
                              />
                            </div>
                            <div className="detailed-wanted-software-location">India, New Delhi</div>
                          </div>
                          <div className="detailed-wanted-software-listing-detail-item-date-parent">
                            <div className="detailed-wanted-software-listing-detail-item-date">
                              <img
                                className="detailed-wanted-software-chevron-right-icon2"
                                alt=""
                                src="/detailed_listing/calendar1.svg"
                              />
                            </div>
                            <div className="detailed-wanted-software-type-of-work">April 09, 2023</div>
                          </div>
                        </div>
                        <div className="detailed-wanted-software-listing-team">
                          <div className="detailed-wanted-software-listing-team-member-wrapper">
                            <div className="detailed-wanted-software-listing-team-member">4.9/5</div>
                          </div>
                          <div className="detailed-wanted-software-contact-role">
                            <div className="detailed-wanted-software-supporting-text12">
                              99,999 reviews
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="detailed-wanted-software-listing">
                      <div className="detailed-wanted-software-breadcrumb-items">
                        <div className="detailed-wanted-software-listing-short-info">$9,999,999.99</div>
                        <div className="detailed-wanted-software-supporting-text-wrapper">
                          <div className="detailed-wanted-software-supporting-text13">USD-Per Unit</div>
                        </div>
                      </div>
                    </div>
                    <img
                      className="detailed-wanted-software-divider-icon"
                      alt=""
                      src="/detailed_listing/divider4.svg"
                    />

                    <div className="detailed-wanted-software-listing-detail-item-value-inner">
                      <div className="detailed-wanted-software-footer-social-parent">
                        <div className="detailed-wanted-software-footer-social">
                          <img
                            className="detailed-wanted-software-footer-social-child"
                            alt=""
                            src="/detailed_listing/group-58@2x.png"
                          />

                          <div className="detailed-wanted-software-listing-short-detail-item-pin-parent">
                            <div className="detailed-wanted-software-listing-short-detail-item-pin">
                              <div className="detailed-wanted-software-name">Olivia Rhye</div>
                            </div>
                            <div className="detailed-wanted-software-role1">Business Name</div>
                          </div>
                        </div>
                        <div className="detailed-wanted-software-listing-short-detail-item-date">
                          <img
                            className="detailed-wanted-software-icon1"
                            alt=""
                            src="/detailed_listing/icon1.svg"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="detailed-wanted-software-rectangle-parent10">
              <div className="detailed-wanted-software-frame-child12"></div>
              <div className="detailed-wanted-software-rectangle-parent30">
                <img
                  className="detailed-wanted-software-listing-card-badge-overlay-child"
                  alt=""
                  src="/detailed_listing/rectangle-95@2x.png"
                />

                <div className="detailed-wanted-software-image-overlay">
                  <div className="detailed-wanted-software-badge4">
                    <div className="detailed-wanted-software-listing-card-price">Assured</div>
                  </div>
                  <div className="detailed-wanted-software-short-listing-team">
                    <div className="detailed-wanted-software-badge5">
                      <div className="detailed-wanted-software-listing-card-radar">ID# AXJYN</div>
                    </div>
                    <div className="detailed-wanted-software-buttonsbutton11">
                      <img
                        className="detailed-wanted-software-magnifier-1-icon"
                        alt=""
                        src={radar_1_1_2x}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="detailed-wanted-software-listing-detail-item">
                <div className="detailed-wanted-software-message-content">
                  <div className="detailed-wanted-software-text-frame">
                    <div className="detailed-wanted-software-text42">
                      New / Surplus 2021 Philips Health-care HD11 XE
                      (P/N:23A...
                    </div>
                  </div>
                  <div className="detailed-wanted-software-listing-detail-item-value">
                    <div className="detailed-wanted-software-listing-detail-item-pin-wrapper">
                      <div className="detailed-wanted-software-heading-container">
                        <div className="detailed-wanted-software-listing-detail-item-location">
                          <div className="detailed-wanted-software-listing-detail-item-date-parent">
                            <div className="detailed-wanted-software-listing-detail-item-date">
                              <img
                                className="detailed-wanted-software-chevron-right-icon2"
                                alt=""
                                src="/detailed_listing/markerpin021.svg"
                              />
                            </div>
                            <div className="detailed-wanted-software-location">India, New Delhi</div>
                          </div>
                          <div className="detailed-wanted-software-listing-detail-item-date-parent">
                            <div className="detailed-wanted-software-listing-detail-item-date">
                              <img
                                className="detailed-wanted-software-chevron-right-icon2"
                                alt=""
                                src="/detailed_listing/calendar1.svg"
                              />
                            </div>
                            <div className="detailed-wanted-software-type-of-work">April 09, 2023</div>
                          </div>
                        </div>
                        <div className="detailed-wanted-software-listing-team">
                          <div className="detailed-wanted-software-listing-team-member-wrapper">
                            <div className="detailed-wanted-software-listing-team-member">4.9/5</div>
                          </div>
                          <div className="detailed-wanted-software-contact-role">
                            <div className="detailed-wanted-software-supporting-text12">
                              99,999 reviews
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="detailed-wanted-software-listing">
                      <div className="detailed-wanted-software-breadcrumb-items">
                        <div className="detailed-wanted-software-listing-short-info">$9,999,999.99</div>
                        <div className="detailed-wanted-software-supporting-text-wrapper">
                          <div className="detailed-wanted-software-supporting-text13">USD-Per Unit</div>
                        </div>
                      </div>
                    </div>
                    <img
                      className="detailed-wanted-software-divider-icon"
                      alt=""
                      src="/detailed_listing/divider4.svg"
                    />

                    <div className="detailed-wanted-software-listing-detail-item-value-inner">
                      <div className="detailed-wanted-software-footer-social-parent">
                        <div className="detailed-wanted-software-footer-social">
                          <img
                            className="detailed-wanted-software-footer-social-child"
                            alt=""
                            src="/detailed_listing/group-58@2x.png"
                          />

                          <div className="detailed-wanted-software-listing-short-detail-item-pin-parent">
                            <div className="detailed-wanted-software-listing-short-detail-item-pin">
                              <div className="detailed-wanted-software-name">Olivia Rhye</div>
                            </div>
                            <div className="detailed-wanted-software-role1">Business Name</div>
                          </div>
                        </div>
                        <div className="detailed-wanted-software-listing-short-detail-item-date">
                          <img
                            className="detailed-wanted-software-icon1"
                            alt=""
                            src="/detailed_listing/icon1.svg"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="detailed-wanted-software-rectangle-parent10">
              <div className="detailed-wanted-software-frame-child12"></div>
              <div className="detailed-wanted-software-rectangle-parent30">
                <img
                  className="detailed-wanted-software-listing-card-badge-overlay-child"
                  alt=""
                  src="/detailed_listing/rectangle-95@2x.png"
                />

                <div className="detailed-wanted-software-image-overlay3">
                  <div className="detailed-wanted-software-badge4">
                    <div className="detailed-wanted-software-listing-card-price">Assured</div>
                  </div>
                  <div className="detailed-wanted-software-badge-group">
                    <div className="detailed-wanted-software-badge5">
                      <div className="detailed-wanted-software-listing-card-radar">ID# AXJYN</div>
                    </div>
                    <div className="detailed-wanted-software-buttonsbutton14">
                      <img
                        className="detailed-wanted-software-magnifier-1-icon"
                        alt=""
                        src={radar_1_1_2x}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="detailed-wanted-software-listing-detail-item">
                <div className="detailed-wanted-software-message-content">
                  <div className="detailed-wanted-software-text-frame">
                    <div className="detailed-wanted-software-text42">
                      New / Surplus 2021 Philips Health-care HD11 XE
                      (P/N:23A...
                    </div>
                  </div>
                  <div className="detailed-wanted-software-icon-group">
                    <img className="detailed-wanted-software-icon4" alt="" src="/detailed_listing/icon1.svg" />

                    <div className="detailed-wanted-software-message-content">
                      <div className="detailed-wanted-software-listing-detail-item-pin-wrapper">
                        <div className="detailed-wanted-software-listing-detail-item-location">
                          <div className="detailed-wanted-software-listing-detail-item-date-parent">
                            <div className="detailed-wanted-software-listing-detail-item-date">
                              <img
                                className="detailed-wanted-software-chevron-right-icon2"
                                alt=""
                                src="/detailed_listing/markerpin021.svg"
                              />
                            </div>
                            <div className="detailed-wanted-software-location">India, New Delhi</div>
                          </div>
                          <div className="detailed-wanted-software-listing-detail-item-date-parent">
                            <div className="detailed-wanted-software-listing-detail-item-date">
                              <img
                                className="detailed-wanted-software-chevron-right-icon2"
                                alt=""
                                src="/detailed_listing/calendar1.svg"
                              />
                            </div>
                            <div className="detailed-wanted-software-type-of-work">April 09, 2023</div>
                          </div>
                        </div>
                      </div>
                      <div className="detailed-wanted-software-supporting-work-five">
                        <div className="detailed-wanted-software-breadcrumb-items">
                          <div className="detailed-wanted-software-listing-team-member-wrapper">
                            <div className="detailed-wanted-software-listing-team-member">4.9/5</div>
                          </div>
                          <div className="detailed-wanted-software-contact-role">
                            <div className="detailed-wanted-software-supporting-text12">
                              99,999 reviews
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="detailed-wanted-software-supporting-six-wrapper">
                        <div className="detailed-wanted-software-breadcrumb-items">
                          <div className="detailed-wanted-software-listing-short-info">$9,999,999.99</div>
                          <div className="detailed-wanted-software-supporting-text-wrapper">
                            <div className="detailed-wanted-software-supporting-text13">USD-Per Unit</div>
                          </div>
                        </div>
                      </div>
                      <div className="detailed-wanted-software-divider-container">
                        <img
                          className="detailed-wanted-software-divider-icon3"
                          alt=""
                          src="/detailed_listing/divider4.svg"
                        />
                      </div>
                      <div className="detailed-wanted-software-team-info-four">
                        <div className="detailed-wanted-software-detail-items">
                          <img
                            className="detailed-wanted-software-footer-social-child"
                            alt=""
                            src="/detailed_listing/group-58@2x.png"
                          />

                          <div className="detailed-wanted-software-listing-short-detail-item-pin-parent">
                            <div className="detailed-wanted-software-listing-short-detail-item-pin">
                              <div className="detailed-wanted-software-name">Olivia Rhye</div>
                            </div>
                            <div className="detailed-wanted-software-role1">Business Name</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="detailed-wanted-software-testiomonial-carousel-arrow8">
              <img
                className="detailed-wanted-software-arrow-left-icon"
                alt=""
                src="/detailed_listing/arrowleft.svg"
              />
            </div>
          </div>
          <div className="detailed-wanted-software-testiomonial-carousel-arrow9">
            <img
              className="detailed-wanted-software-arrow-left-icon"
              alt=""
              src="/detailed_listing/arrowright.svg"
            />
          </div>
        </div>
      </div> */}
      <FooterDetailPage/>
      </div>
    </div>
  )
}

