import React from 'react';
import "./AddServiceStep.css"
import divider1 from '../../icons/Gerator/add_form/divider1.svg';
import chevrondown_2 from '../../icons/Gerator/add_form/chevrondown-2.svg';
import chevronleft from '../../icons/Gerator/add_form/chevronleft.svg';
import placeholder3 from '../../icons/Gerator/add_form/placeholder3.svg';
import save02 from '../../icons/Gerator/add_form/save02.svg';
import featured_icon from '../../icons/Gerator/add_form/featured-icon.svg';
import placeholder1 from '../../icons/Gerator/add_form/placeholder1.svg';
import placeholder2 from '../../icons/Gerator/add_form/placeholder2.svg';
import placeholder3 from '../../icons/Gerator/add_form/placeholder3.svg';
import placeholder from '../../icons/Gerator/add_form/placeholder.svg';
import page from '../../icons/Gerator/add_form/page.svg';
import checkbox_base from '../../icons/Gerator/add_form/-checkbox-base.svg';
import page from '../../icons/Gerator/add_form/page.svg';
import { updateAppDataService } from "./AppDataServiceSlice";
import AddServiceImageCrop from "../ImageCropComponent/AddServiceImageCrop";
import { isValidURL } from "../../helper/SpareHelper";
import AddServiceBannerImageCrop from "../ImageCropComponent/AddServiceBannerImageCrop";
import { Field, Formik, useField, useFormikContext, getIn } from "formik";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddServiceFormContext from '../../screens/Service/AddService/AddServiceContext';
import { updateField } from "./AddServiceSlice";

const MySelect = ({ validate, label, ...props }) => {
  const [field, meta] = useField(props);

  const inputStyle = meta.touched && meta.error ? { borderColor: 'red' } : {};   //New code for border colour

  let fieldValidationData = null;
  // console.log("MySelect inside validation result");
  // console.log(validate);

  if (validate?.length > 0) {
    validate.some(field => { // Use 'some' for early termination
      if (field.path === props.name) {
        fieldValidationData = field;
        return true; // This will stop the loop early
      }
      return false; // Continue if no match
    });
  }


  return (
    <>
      <div className="add-job-step-first-upload-inner">
        <div className="add-job-step-label3">
          <label className="add-job-step-transaction-type" htmlFor={props.name}>
            {label} <span className="add-job-step-span">*</span>
          </label>
        </div>
        <div className="add-job-step-input1">
          <div className="add-job-step-content8">
            <div className="add-job-step-text-input">
              <select
                className="add-job-step-first-nested-input account-update-profile-content-custom-23"
                style={inputStyle}   //New code for border colour
                {...field}
                {...props}
              />
            </div>
          </div>

        </div>
      </div>






      {(meta.touched && meta.error) ? (
        <div className="error-for-all-field">{meta.error}</div>
      ) : null}

      {fieldValidationData !== null ? (
        <div className="error-for-all-field">{fieldValidationData.msg}</div>
      ) : null}
    </>
  );
};

const MyTextInput = ({ validate, label, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input>. We can use field meta to show an error
  // message if the field is invalid and it has been touched (i.e. visited)
  const [field, meta] = useField(props);
  let fieldValidationData = null;
  if (validate?.length > 0) {
    validate.some(field => { // Use 'some' for early termination
      if (field.path === props.name) {
        fieldValidationData = field;
        return true; // This will stop the loop early
      }
      return false; // Continue if no match
    });
  }

  if (fieldValidationData != null) {
    // console.log(fieldValidationData);
  }
  // {
  //   fieldValidationData !== null ? (
  //     <div className="error">{fieldValidationData.msg}</div>
  //   ) : null
  // }

  return (
    <>
      {/* <label className="label">Model Number</label> */}
      <div className="add-service-step-first-upload-inner">
        <div className="add-service-step-label5">
          <label className="add-service-step-transaction-type" htmlFor={props.id || props.name}>
            {label} <span className="add-service-step-span">*</span>
          </label>
        </div>
        <div className="add-service-step-input5">
          <input
            className="add-service-step-content12"
            // style={inputStyle}  //New code for border colour
            {...field}
            {...props}

          />
        </div>
      </div>


      {(meta.touched && meta.error) ? (
        <div className="error-for-all-field">{meta.error}</div>
      ) : null}

      {fieldValidationData !== null ? (
        <div className="error-for-all-field">{fieldValidationData.msg}</div>
      ) : null}

    </>
  );
};

const FileUpload = ({ validate, ...props }) => {
  const [field, meta] = useField(props);
  let fieldValidationData = "";
  if (validate?.length > 0) {
    validate.some(field => { // Use 'some' for early termination
      if (field.path === "featureImageObject") {
        fieldValidationData = field;
        return true; // This will stop the loop early
      }
      return false; // Continue if no match
    });
  }

  return (

    <div className='document-field-component-width'>
      <div className="add-service-step-input-with-label19">
        <a className="add-service-step-label22">
          <span className="add-service-step-your-role-in">Feature Image  </span>
          <span className="add-service-step-span">*</span>
        </a>
      </div>

      <div className="add-service-step3-file-upload-base5">
        <label htmlFor="featureImage">

          <div className="add-service-step3-content69">
            <img

              className="add-service-step3-featured-icon5"
              loading="lazy"
              alt=""
              src={featured_icon}
            />

            <div className="add-service-step3-text-and-supporting-text13">
              <div className="add-service-step3-action5">
                <div className="add-service-step3-buttonsbutton16">
                  {/* <img 
                  className="add-service-step3-placeholder-icon60"
                  alt=""
                  src={placeholder1}
                /> */}
                  <div className="add-service-step3-text88 documentLabel">Click to upload</div>
                  <input type="file" accept="image/*" {...field} {...props} className='hide-input-field' />
                </div>
              </div>
              <div className="action custom-action">

                <div className="add-service-step3-text89">or drag and drop</div>
              </div>
              <div className="add-service-step3-supporting-text28">
                <span>Please upload files having extension </span>
                <span className="add-service-step3-pdf1">PNG, JPEG & WEBP</span>
                <span> only (Maximum file size. 1 MB)</span>
              </div>
            </div>
          </div>
        </label>
        {(meta.touched && meta.error) ? (
          <div className="error-for-all-field error-for-document">{meta.error}</div>
        ) : null}

        {fieldValidationData !== "" ? (
          <div className="error-for-all-field error-for-document">{meta.error}</div>
        ) : null}
      </div>


    </div>
  );
};

const FileUploadBanner = ({ validate, ...props }) => {
  const [field, meta] = useField(props);
  let fieldValidationData = "";
  if (validate?.length > 0) {
    validate.some(field => { // Use 'some' for early termination
      if (field.path === "bannerImageObject") {
        fieldValidationData = field;
        return true; // This will stop the loop early
      }
      return false; // Continue if no match
    });
  }

  return (

    <div className='document-field-component-width'>
      <div className="add-service-step-input-with-label19">
        <a className="add-service-step-label22">
          <span className="add-service-step-your-role-in">Banner Image  </span>
          <span className="add-service-step-span">*</span>
        </a>
      </div>

      <div className="add-service-step3-file-upload-base5">
        <label htmlFor="bannerImage">

          <div className="add-service-step3-content69">
            <img

              className="add-service-step3-featured-icon5"
              loading="lazy"
              alt=""
              src={featured_icon}
            />

            <div className="add-service-step3-text-and-supporting-text13">
              <div className="add-service-step3-action5">
                <div className="add-service-step3-buttonsbutton16">
                  {/* <img 
                  className="add-service-step3-placeholder-icon60"
                  alt=""
                  src={placeholder1}
                /> */}
                  <div className="add-service-step3-text88 documentLabel">Click to upload</div>
                  <input type="file" accept="image/*" {...field} {...props} className='hide-input-field' />
                </div>
              </div>
              <div className="action custom-action">

                <div className="add-service-step3-text89">or drag and drop</div>
              </div>
              <div className="add-service-step3-supporting-text28">
                <span>Please upload files having extension </span>
                <span className="add-service-step3-pdf1">PNG, JPEG & WEBP</span>
                <span> only (Maximum file size. 1 MB)</span>
              </div>
            </div>
          </div>
        </label>
        {(meta.touched && meta.error) ? (
          <div className="error-for-all-field error-for-document">{meta.error}</div>
        ) : null}

        {fieldValidationData !== "" ? (
          <div className="error-for-all-field error-for-document">{meta.error}</div>
        ) : null}
      </div>


    </div>
  );
};

const MySelectForSupportAndReach = ({ validate, label, ...props }) => {
  const [field, meta] = useField(props);

  const inputStyle = meta.touched && meta.error ? { borderColor: 'red' } : {};   //New code for border colour

  let fieldValidationData = null;
  // console.log("MySelect inside validation result");
  // console.log(validate);

  if (validate?.length > 0) {
    validate.some(field => { // Use 'some' for early termination
      if (field.path === props.name) {
        fieldValidationData = field;
        return true; // This will stop the loop early
      }
      return false; // Continue if no match
    });
  }



  return (
    <>
      <div className="add-service-step-first-upload-inner">
        <div className="add-service-step-label3">
          <label className="add-service-step-transaction-type" htmlFor={props.name}>
            {label}
          </label>
        </div>
        <div className="add-service-step-input1">
          <div className="add-service-step-content8">
            <div className="add-service-step-text-input">
              <select
                className="add-service-step-first-nested-input account-update-profile-content-custom-23"
                style={inputStyle}   //New code for border colour
                {...field}
                {...props}
              />
            </div>
          </div>

        </div>
      </div>






      {(meta.touched && meta.error) ? (
        <div className="error-for-all-field">{meta.error}</div>
      ) : null}

      {fieldValidationData !== null ? (
        <div className="error-for-all-field">{fieldValidationData.msg}</div>
      ) : null}
    </>
  );
};

const MyTextArea = ({ validate, label, ...props }) => {
  const [field, meta] = useField(props);
  let fieldValidationData = null;
  if (validate?.length > 0) {
    validate.some(field => { // Use 'some' for early termination
      if (field.path === props.name) {
        fieldValidationData = field;
        return true; // This will stop the loop early
      }
      return false; // Continue if no match
    });
  }

  return (
    <>
      {/* <label className="label">Model Number</label> */}
      <div className="add-service-step3-input-with-label42">
        <div>
          <label className="add-service-step3-label47" htmlFor={props.id || props.name}>
            {label}
            <span className="add-service-step-span">*</span>
          </label>

        </div>
        <div className="add-service-step3-content62">
          <div className="add-service-step3-textarea-input-field7">
            <div className="add-service-step3-input-with-label43">
              {/* <div className="add-service-step3-label46">Description</div> */}
              <div className="add-service-step3-input31">
                <textarea
                  className="add-service-step3-text82"
                  // style={inputStyle}  //New code for border colour
                  {...field}
                  {...props}

                />
              </div>
            </div>

          </div>
        </div>
      </div>

      {(meta.touched && meta.error) ? (
        <div className="error-for-all-field">{meta.error}</div>
      ) : null}

      {fieldValidationData !== null ? (
        <div className="error-for-all-field">{fieldValidationData.msg}</div>
      ) : null}

    </>
  );
};



export const AddServiceStep2 = () => {

  const dispatch = useDispatch();
  const formData = useSelector((state) => state.addService);
  const appData = useSelector((state) => state.appDataAddService);

  // ------------ Feature Image Hooks ---------------------

  //This hook will store error message of featureImage 
  const [imageSingleCheckboxError, setImageSingleCheckboxError] = useState("");
  //This hook will store error message of bannerImage 
  const [bannerSingleCheckboxError, setbannerSingleCheckboxError] = useState("");
  //This hook will store image file if uploaded image have some validation error on FeatureImage 
  const [featureImageDemoFile, setFeatureImageDemoFile] = useState("");
  //This hook will store image file if uploaded image have some validation error on BannerImage 
  const [bannerImageDemoFile, setBannerImageDemoFile] = useState("");


  // //This hook will store all error message of all uploaded images. using when showing error message on image card.
  // const [imageMultiCheckboxError, setImageMultiCheckboxError] = useState([]);

  // ------------ Formik Methods to manage form flow ---------------------
  const { values, validateForm, setFieldValue, setFieldError, setErrors, setFieldTouched, validateField, errors, isValid, dirty, touched } = useFormikContext();

  // ------------ Form Context getting from Addserviceparent.jsx ---------------------
  const { backendValidation, allTaxonomy, selectedBannerImageFile, setSelectedBannerImageFile, selectedImageFile, setSelectedImageFile, cropedImageFile, setCropedImageFile, cropedBannerImageFile, setCropedBannerImageFile } = useContext(AddServiceFormContext);

  // ------------ These refs used to manage radio field state ---------------------
  const inputYoutubeRef = useRef(null);
  const inputVimeoRef = useRef(null);

  useEffect(() => {
    console.log(allTaxonomy.serviceAndSupportReachTax);
  }, [])


  /* 
      This useEffect will provide updated values from formik and run some conditions Used on FeatureImage
  */
  useEffect(() => {
    console.log("Values useEffect Open");

    if (touched.featureImage) {
      if (errors.featureImage && values.featureImage !== "") {
        console.log("Values Error UseEffect");
        handleSelectedImageFile("error");
        return; // Exit useEffect to avoid further actions if there are errors
      }

      // 2. Proceed with Updates if No Errors:
      if (touched.featureImage && values.featureImage != "") {
        console.log("Values Touch UseEffect");
        // setGalleryImageFile([...values.gallery]);
        handleSelectedImageFile("no-error");
      }
    }

    console.log("Values useEffect Closed ");
    // }

  }, [values.featureImage, errors.featureImage]);


  /* 
    This useEffect will provide updated values from formik and run some conditions Used on Gallery Image
*/
  useEffect(() => {
    console.log("Values useEffect Open");

    //BELOWE CODE IS FOR GALLERY IMAGES
    if (touched.bannerImage) {
      if (errors.bannerImage && values.bannerImage !== "") {
        console.log("Values Error UseEffect");
        handleSelectedBannerImageFile("error");
        return; // Exit useEffect to avoid further actions if there are errors
      }

      // 2. Proceed with Updates if No Errors:
      if (touched.bannerImage && values.bannerImage != "") {
        console.log("Values Touch UseEffect");
        handleSelectedBannerImageFile("no-error");
      }
    }

    console.log("Values useEffect Closed ");


  }, [values.bannerImage, errors.bannerImage]);

  //Added For loggin purposes
  // useEffect(() => {
  //   console.log("Errors in image checkbox");
  //   console.log(imageMultiCheckboxError);
  // }, [imageMultiCheckboxError])




  //onChange handle method for featureImage where populating image
  const handleSelectedImageFile = async (type) => {

    console.log("HandleSelectedImageFile Called " + type);

    setFieldTouched("featureImage", true); // Always touch the field for validation

    console.log(errors);

    // 3. Additional Error Check:
    if (errors.featureImage) {
      console.log("Inside errors.gallery is true means error found ");
      if (cropedImageFile !== '') setCropedImageFile('')
      setSelectedImageFile('');
      setFeatureImageDemoFile(values.featureImage);
      setImageSingleCheckboxError(errors.featureImage);
      return; // Don't proceed with upload if there's an error
    }

    // 4. Proceed with Upload if No Errors:
    console.log("Inside !errors.gallery is true means no error found");
    setFeatureImageDemoFile('');
    setImageSingleCheckboxError('');
    if (cropedImageFile === '') setSelectedImageFile(values.featureImage);
    console.log("File added into select multi image hook");

  }



  //onClick handle method For featureImage remove
  const handleImageCheckbox = async (e) => {

    //If croped Image hook has some data then clean up 
    if (cropedImageFile !== '') setCropedImageFile('');
    //If errors.featureImage has some errors then filter then set featureImage to "", clear other field as well
    if (errors.featureImage) {
      console.log(errors.featureImage);
      const filteredErrors = Object.keys(errors).reduce((acc, fieldName) => {
        // Filter based on your condition (replace with your actual logic)
        if (fieldName !== 'featureImage') {
          acc[fieldName] = errors[fieldName];
        }
        return acc;
      }, {});
      console.log(filteredErrors);
      await setFieldValue("featureImage", '');
      setErrors(filteredErrors)
      setFeatureImageDemoFile('');
      setImageSingleCheckboxError('');
    } else {

      await setFieldValue("featureImage", '');
      setFeatureImageDemoFile('');
      setImageSingleCheckboxError('');
    }
  }

  //onChange handle method for featureImage where populating image
  const handleSelectedBannerImageFile = async (type) => {

    console.log("HandleSelectedImageFile Called " + type);

    setFieldTouched("bannerImage", true); // Always touch the field for validation

    console.log(errors);

    // 3. Additional Error Check:
    if (errors.bannerImage) {
      console.log("Inside errors.gallery is true means error found ");
      if (cropedBannerImageFile !== '') setCropedBannerImageFile('')
      setSelectedBannerImageFile('');
      setBannerImageDemoFile(values.bannerImage);
      setbannerSingleCheckboxError(errors.bannerImage);
      return; // Don't proceed with upload if there's an error
    }

    // 4. Proceed with Upload if No Errors:
    console.log("Inside !errors.gallery is true means no error found");
    setBannerImageDemoFile('');
    setbannerSingleCheckboxError('');
    if (cropedBannerImageFile === '') setSelectedBannerImageFile(values.bannerImage);
    // console.log("File added into select multi image hook");

  }



  //onClick handle method For featureImage remove
  const handleBannerImageCheckbox = async (e) => {

    //If croped Image hook has some data then clean up 
    if (cropedBannerImageFile !== '') setCropedBannerImageFile('');
    //If errors.featureImage has some errors then filter then set featureImage to "", clear other field as well
    if (errors.bannerImage) {
      console.log(errors.bannerImage);
      const filteredErrors = Object.keys(errors).reduce((acc, fieldName) => {
        // Filter based on your condition (replace with your actual logic)
        if (fieldName !== 'bannerImage') {
          acc[fieldName] = errors[fieldName];
        }
        return acc;
      }, {});
      console.log(filteredErrors);
      await setFieldValue("bannerImage", '');
      setErrors(filteredErrors)
      setBannerImageDemoFile('');
      setbannerSingleCheckboxError('');
    } else {

      await setFieldValue("bannerImage", '');
      setBannerImageDemoFile('');
      setbannerSingleCheckboxError('');
    }
  }

  //This handle method used to capture input entered text and save them into redux state
  const handleChange = async (event) => {
    const { name, value } = event.target;
    console.log("handle change called");
    console.log(name);
    if (name === 'featureImage') {
      const file = event.target.files[0];
      console.log('\x1b[36m%s\x1b[0m', "inside handle change called");
      setFieldValue(name, file);
      setFieldTouched("featureImage", true);
      return;
    }

    if (name === 'bannerImage') {
      const file = event.target.files[0];
      console.log('\x1b[36m%s\x1b[0m', "inside handle change called");
      setFieldValue(name, file);
      setFieldTouched("bannerImage", true);
      return;
    }

    console.log("handle change closed");

    setFieldValue(name, value);
    dispatch(updateField({ field: name, value }));

  };

  //onClick Listener on input radio tag(video/youtube)
  useEffect(() => {
    if (inputYoutubeRef.current) {
      inputYoutubeRef.current.addEventListener('click', handleClickYoutube);
    }
    return () => { // Add a cleanup function
      if (inputYoutubeRef.current) {
        inputYoutubeRef.current.removeEventListener('click', handleClickYoutube);
      }
    }
  }, []);

  //onClick Listener on input radio tag(video/vimeo)
  useEffect(() => {
    if (inputVimeoRef.current) {
      inputVimeoRef.current.addEventListener('click', handleClickVimeo);
    }
    return () => { // Add a cleanup function
      if (inputVimeoRef.current) {
        inputVimeoRef.current.removeEventListener('click', handleClickVimeo);
      }
    }
  }, []);


  //Click method for Youtube radio
  const handleClickYoutube = (e) => {
    // Reset Vimeo when Youtube is clicked
    setFieldValue("videoType", "Youtube")
    dispatch(updateAppDataService({ case: "VIDEO", field: "isVimeoChecked", value: false }))
    dispatch(updateAppDataService({ case: "VIDEO", field: "isYoutubeChecked", value: true }))
    dispatch(updateAppDataService({ case: "VIDEO", field: "myfieldinsideradio", value: true }))
  };

  //Click method for Vimeo radio
  const handleClickVimeo = (e) => {
    // Reset Youtube when Vimeo is clicked
    setFieldValue("videoType", "Vimeo")
    dispatch(updateAppDataService({ case: "VIDEO", field: "isYoutubeChecked", value: false }))
    dispatch(updateAppDataService({ case: "VIDEO", field: "isVimeoChecked", value: true }))
    dispatch(updateAppDataService({ case: "VIDEO", field: "myfieldinsideradio", value: true }))
  };

  //This method used to decrement current step of form and update state in appData reducer
  const prev = () => {
    // dispatch(updateField({transactionType:"Nothing"}));
    dispatch(updateAppDataService({ case: "CURRENTSTEP", value: (appData.currentStep - 1) }))
  }

  //This method used to increment current step of form and update state in appData reducer
  const next = async () => {

    // dispatch(updateAppDataService({ case: "CURRENTSTEP", value: appData.currentStep + 1 }));
    try {
      // const { errors } = useFormikContext();
      const validatedError = await validateForm();
      // console.log(formikUpdated);
      // console.log(validatedError);
      if (Object.keys(validatedError).length > 0) {

        Object.keys(validatedError).forEach(key => {
          console.log(key);
          setFieldTouched(key, true);
        });
      } else {
        dispatch(updateAppDataService({ case: "CURRENTSTEP", value: appData.currentStep + 1 }));
      }
      // dispatch(updateAppDataDevice({ case: "CURRENTSTEP", value: (appData.currentStep + 1) }));
    }
    catch (err) {
      console.log(Object.keys(err).length);
      if (Object.keys(err).length > 0) {

        Object.keys(err).forEach(key => {
          console.log(key);
          setFieldTouched(key, true);
        });
      }
    }



  };

  return (

    //  < form className="add-service-step-section-header-parent">

    <>

      <div className="add-service-step-section-header">

        <div className="add-service-step-content7">Highlights</div>



        <img
          className="add-service-step-divider-icon2"
          alt=""
          src={divider1}
        />
      </div>
      <div className="add-service-step-tagged-inputs">
        <div className="add-service-step-input-field12">
          <MyTextInput
            label="Service Features - Highlights "
            name="serviceFeatureHighlights"                                                          //New Code
            type="text"
            placeholder="190 characters"
            id="serviceFeatureHighlights"
            validate={backendValidation}
            onChange={handleChange}
            value={formData.serviceFeatureHighlights}
          />

        </div>
      </div>
      <div className="add-service-step-tagged-inputs">
        <div className="add-service-step-input-field12">
          <MyTextInput
            label="Service Benefits - Highlights"
            name="serviceBenefitsHighlights"                                                          //New Code
            type="text"
            placeholder="190 characters"
            id="serviceBenefitsHighlights"
            validate={backendValidation}
            onChange={handleChange}
            value={formData.serviceBenefitsHighlights}
          />
        </div>
      </div>
      <section className="add-service-step3-input-field35">
        <MyTextArea
          label="List Parent Devices Compatible With This Service"
          name="parentDevices"                                                          //New Code
          type="textarea"
          placeholder="1000 characters. Eg: Siemens MAGNETOM Essenza 1.5 T MRI System. Separate entries with comma."
          id="parentDevices"
          onChange={handleChange}
          validate={backendValidation}
          value={formData.parentDevices}
        />
      </section>

      <div className="add-service-step-single-input-parent">
        <div className="add-service-step-first-upload-container">
          <div className="add-service-step-first-upload-inner">
            <FileUpload key={selectedImageFile ? selectedImageFile.name : Date.now()} name="featureImage" id="featureImage" validate={backendValidation} onChange={handleChange} value={undefined} />
            {(selectedImageFile instanceof File) && (
              <AddServiceImageCrop />
            )}



            {
              (typeof cropedImageFile === 'object' && cropedImageFile !== "") &&

              (
                <div className="add-service-step-file-upload-item-base">
                  <div className="add-service-step-first-upload-item-content">
                    <div className="add-service-step-file-type-icon">
                      <img
                        className="add-service-step-page-icon"
                        loading="lazy"
                        alt=""
                        src={page}
                      />

                      <div className="add-service-step-file-type-wrap">
                        <b className="add-service-step-file-type">IMG</b>
                      </div>
                    </div>
                    <div className="add-service-step-first-upload-item-info">
                      <div className="add-service-step-first-upload-item-inner">
                        <div className="add-service-step-first-upload-item">
                          {cropedImageFile.name}
                        </div>
                        <div className="add-service-step3-checkbox3">
                          <Field type="checkbox" onClick={handleImageCheckbox} name="imageCheckbox" checked className="checkbox-instance checkbox-base size-30-sm state-8-default checked-true type-checkbox checkbox-2"></Field>
                        </div>
                      </div>
                      <div className="add-service-step3-supporting-text14">{(cropedImageFile.size / 1000).toFixed(1) + " KB"}</div>
                    </div>
                  </div>
                  <div className="add-service-step3-progress-bar8">
                    <div className="add-service-step3-progress-bar-wrapper">
                      <div className="add-service-step3-progress-bar9">
                        <div className="add-service-step3-background3"></div>
                        <div className="add-service-step3-progress3"></div>
                      </div>
                    </div>
                    {(imageSingleCheckboxError !== "") ?
                      <div style={{ color: "red" }}>
                        {imageSingleCheckboxError}
                      </div> :
                      <div className="add-service-step3-percentage3">100%</div>
                    }
                  </div>

                </div>
              )
            }
            {
              (featureImageDemoFile instanceof File) &&

              (
                <div className="add-service-step-file-upload-item-base">
                  <div className="add-service-step-first-upload-item-content">
                    <div className="add-service-step-file-type-icon">
                      <img
                        className="add-service-step-page-icon"
                        loading="lazy"
                        alt=""
                        src={page}
                      />

                      <div className="add-service-step-file-type-wrap">
                        <b className="add-service-step-file-type">IMG</b>
                      </div>
                    </div>
                    <div className="add-service-step-first-upload-item-info">
                      <div className="add-service-step-first-upload-item-inner">
                        <div className="add-service-step-first-upload-item">
                          {featureImageDemoFile.name}
                        </div>
                        <div className="add-service-step3-checkbox3">
                          <Field type="checkbox" onClick={handleImageCheckbox} name="imageCheckbox" checked className="checkbox-instance checkbox-base size-30-sm state-8-default checked-true type-checkbox checkbox-2"></Field>
                        </div>
                      </div>
                      <div className="add-service-step3-supporting-text14">{(featureImageDemoFile.size / 1000).toFixed(1) + " KB"}</div>
                    </div>
                  </div>
                  <div className="add-service-step3-progress-bar8">
                    <div className="add-service-step3-progress-bar-wrapper">
                      <div className="add-service-step3-progress-bar9">
                        <div className="add-service-step3-background3"></div>
                        <div className="add-service-step3-progress3"></div>
                      </div>
                    </div>
                    {(imageSingleCheckboxError !== "") ?
                      <div style={{ color: "red" }}>
                        {imageSingleCheckboxError}
                      </div> :
                      <div className="add-service-step3-percentage3">100%</div>
                    }
                  </div>

                </div>
              )
            }
          </div>
          <div className="add-service-step-tagged-inputs">
            <div className="add-service-step-input-field12">
              <div className="add-service-step-input-with-label19">
                <div className="add-service-step-label21">
                  <span className="add-service-step-transaction-type">Video </span>

                </div>
              </div>
              {/* <div className="add-service-step-hint-text1">
            This is a hint text to help user.
          </div> */}
              <div className="add-service-step-file-button-container">
                <div className="add-service-step-buttonsbutton4">
                  {/* <img
                    className="add-service-step-search-lg-icon"
                    alt=""
                    src={placeholder}
                  /> */}

                  <div className="add-service-step-text-padding6">
                    <div className="add-service-step-file-button-labels">YouTube
                      <Field type="radio" id="youtube" name="video" value="Youtube" innerRef={inputYoutubeRef} checked={appData.isYoutubeChecked} />

                    </div>
                  </div>
                </div>
                <div className="add-service-step-buttonsbutton4">
                  {/* <img
                    className="add-service-step-search-lg-icon"
                    alt=""
                    src={placeholder}
                  /> */}

                  <div className="add-service-step-text-padding6">
                    <div className="add-service-step-text48">Vimeo
                      <Field type="radio" id='vimeo' name="video" value="Vimeo" innerRef={inputVimeoRef} checked={appData.isVimeoChecked} />

                    </div>
                  </div>
                </div>
                {appData.myfieldinsideradio && (
                  <Field type="text" name="linkVideo" className="text-input text-25 content-15 myinputfield" onChange={handleChange}
                    value={formData.linkVideo} />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="add-service-step-first-upload-container">
          <div className="add-service-step-first-upload-inner">
            <FileUploadBanner key={selectedBannerImageFile ? selectedBannerImageFile.name : Date.now()} name="bannerImage" id="bannerImage" validate={backendValidation} onChange={handleChange} value={undefined} />
            {(selectedBannerImageFile instanceof File) && (
              <AddServiceBannerImageCrop />
            )}

            {
              (typeof cropedBannerImageFile === 'object' && cropedBannerImageFile !== "") &&

              (
                <div className="add-service-step-file-upload-item-base">
                  <div className="add-service-step-first-upload-item-content">
                    <div className="add-service-step-file-type-icon">
                      <img
                        className="add-service-step-page-icon"
                        loading="lazy"
                        alt=""
                        src={page}
                      />

                      <div className="add-service-step-file-type-wrap">
                        <b className="add-service-step-file-type">IMG</b>
                      </div>
                    </div>
                    <div className="add-service-step-first-upload-item-info">
                      <div className="add-service-step-first-upload-item-inner">
                        <div className="add-service-step-first-upload-item">
                          {cropedBannerImageFile.name}
                        </div>
                        <div className="add-service-step3-checkbox3">
                          <Field type="checkbox" onClick={handleBannerImageCheckbox} name="imageCheckbox" checked className="checkbox-instance checkbox-base size-30-sm state-8-default checked-true type-checkbox checkbox-2"></Field>
                        </div>
                      </div>
                      <div className="add-service-step3-supporting-text14">{(cropedBannerImageFile.size / 1000).toFixed(1) + " KB"}</div>
                    </div>
                  </div>
                  <div className="add-service-step3-progress-bar8">
                    <div className="add-service-step3-progress-bar-wrapper">
                      <div className="add-service-step3-progress-bar9">
                        <div className="add-service-step3-background3"></div>
                        <div className="add-service-step3-progress3"></div>
                      </div>
                    </div>
                    {(bannerSingleCheckboxError !== "") ?
                      <div style={{ color: "red" }}>
                        {bannerSingleCheckboxError}
                      </div> :
                      <div className="add-service-step3-percentage3">100%</div>
                    }
                  </div>

                </div>
              )
            }
            {
              (bannerImageDemoFile instanceof File) &&

              (
                <div className="add-service-step-file-upload-item-base">
                  <div className="add-service-step-first-upload-item-content">
                    <div className="add-service-step-file-type-icon">
                      <img
                        className="add-service-step-page-icon"
                        loading="lazy"
                        alt=""
                        src={page}
                      />

                      <div className="add-service-step-file-type-wrap">
                        <b className="add-service-step-file-type">IMG</b>
                      </div>
                    </div>
                    <div className="add-service-step-first-upload-item-info">
                      <div className="add-service-step-first-upload-item-inner">
                        <div className="add-service-step-first-upload-item">
                          {bannerImageDemoFile.name}
                        </div>
                        <div className="add-service-step3-checkbox3">
                          <Field type="checkbox" onClick={handleBannerImageCheckbox} name="imageCheckbox" checked className="checkbox-instance checkbox-base size-30-sm state-8-default checked-true type-checkbox checkbox-2"></Field>
                        </div>
                      </div>
                      <div className="add-service-step3-supporting-text14">{(bannerImageDemoFile.size / 1000).toFixed(1) + " KB"}</div>
                    </div>
                  </div>
                  <div className="add-service-step3-progress-bar8">
                    <div className="add-service-step3-progress-bar-wrapper">
                      <div className="add-service-step3-progress-bar9">
                        <div className="add-service-step3-background3"></div>
                        <div className="add-service-step3-progress3"></div>
                      </div>
                    </div>
                    {(bannerSingleCheckboxError !== "") ?
                      <div style={{ color: "red" }}>
                        {bannerSingleCheckboxError}
                      </div> :
                      <div className="add-service-step3-percentage3">100%</div>
                    }
                  </div>

                </div>
              )
            }
          </div>
        </div>
      </div>

      <div className="add-service-step-input-tags">
        <div className="add-service-step-divider"></div>
        <div className="add-service-step-section-header">

          <div className="add-service-step-content7">Key Service Delivery Center Geolocation</div>


          <img
            className="add-service-step-divider-icon2"
            alt=""
            src={divider1}
          />
        </div>
        <div className="add-service-step-input-field13">
          <MySelect label="Location" name="location" id="location" onChange={handleChange} validate={backendValidation} value={formData.location}>
            <option value="0">Select From Dropdown List </option>
            {(allTaxonomy.relatedAddress.isLoading || allTaxonomy.relatedAddress.isError) && <option value="0">Loading...</option>}
            {
              (allTaxonomy.relatedAddress.isSuccess) && (allTaxonomy.relatedAddress.data.data.map(tax => (
                <option key={tax.id} value={tax.id}>{tax.addressTitle}{tax.fullAddress ? ` : ${tax.fullAddress}` : ""}</option>
              )))
            }
          </MySelect>
          <div className="add-service-step-hint-text21">
            <span className="add-service-step-cant-find-an"
            >Cant find an address?
            </span>
            <span className="add-service-step-add-it">Add it.</span>
          </div>
        </div>
      </div>

      <div className="add-service-step-input-tags">

        <div className="add-service-step-section-header">

          <div className="add-service-step-content7">Service & Support Reach     <span className="add-service-step-span">*</span> </div>




        </div>
        <div className="add-service-step-input-field13">
          <MySelectForSupportAndReach name="serviceAndSupportReach" id="serviceAndSupportReach" onChange={handleChange} validate={backendValidation} value={formData.serviceAndSupportReach}>
            <option value="0">Select From Dropdown list </option>
            {(allTaxonomy.serviceAndSupportReach?.isLoading || allTaxonomy.serviceAndSupportReach?.isError) && <option value="0">Loading...</option>}
            {
              (allTaxonomy.serviceAndSupportReach?.isSuccess) && (allTaxonomy.serviceAndSupportReach?.data.map(tax => (
                <option key={tax.id} value={tax.id}>{tax.name}</option>
              )))
            }

            {/* <option value="India">India</option>
                    <option value="USA">USA</option>
                    <option value="UK">UK</option> */}
          </MySelectForSupportAndReach>

        </div>
      </div>

      <div className="add-service-step-section-footer1">
        <div className="add-service-step-section-footer2">
          <img
            className="add-service-step-divider-icon2"
            loading="lazy"
            alt=""
            src={divider1}
          />

          <div className="add-service-step-content31 add-job-step-content31-custom">

            <div className="add-service-step-actions3 add-job-step-actions3-custom">

              <button className="add-service-step-button11" type='button' onClick={prev}>
                <img
                  className="add-service-step-chevron-down-icon"
                  alt=""
                  src={placeholder2}
                />

                <img
                  className="add-service-step-home-line-icon"
                  alt=""
                  src={chevronleft}
                />

                <div className="add-service-step-text-padding6">
                  <div className="add-service-step-text53">Back</div>
                </div>
                <img
                  className="add-service-step-chevron-down-icon"
                  alt=""
                  src={placeholder2}
                />
              </button>
              <button className="add-service-step-button9" type='button' onClick={next}>
                <img
                  className="add-service-step-chevron-down-icon"
                  alt=""
                  src={placeholder3}
                />

                <img
                  className="add-service-step-home-line-icon"
                  alt=""
                  src={save02}
                />

                <div className="add-service-step-text-padding6">
                  <div className="add-service-step-text44">Save & Proceed</div>
                </div>
                <img
                  className="add-service-step-chevron-down-icon"
                  alt=""
                  src={placeholder3}
                />
              </button>
            </div>
          </div>
        </div>
      </div>

    </>
    //  </form>


  )
}

