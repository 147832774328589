import EmailUrlActionHandler from "../handler/FirebaseEmailActionHandler/EmailUrlActionHandler";
import { CheckingResetPasswordEmail } from "../screens/ResetPassword/CheckEmail";
import { FinishedResetPassword } from "../screens/ResetPassword/FinishedPassword";
import { NewPassword } from "../screens/ResetPassword/NewPassword";
import { SendingEmail } from "../screens/ResetPassword/SendingEmail";



export const resetPasswordRoutes = [
  //email action handler
  {
    path: "/action",
    element: <EmailUrlActionHandler />,
  },
  {
    path: "/reset-password-send-email",
    element: <SendingEmail />,
  },
  {
    path: "/reset-password-check-email",
    element: <CheckingResetPasswordEmail />,
  },
  {
    path: "/reset-password-new-password",
    element: <NewPassword />,
  },
  {
    path: "/reset-password-finished",
    element: <FinishedResetPassword />,
  }
];
