import { element } from "prop-types";
import { Dashboard } from "../screens/Dashboard/Dashboard";
import { SignIn } from "../screens/Sign_In/SignIn";
import { SignUp } from "../screens/Sign_Up/SignUp";
import { UserProfile } from "../screens/UserProfile/UserProfile";
import { Radar } from "../screens/Radar/Radar";
import { Beacon } from "../screens/Beacon/Beacon";
import { Contact } from "../screens/Contact/Contact";
import { SalesIQ } from "../screens/SalesIQ/SalesIQ";
import { Funnel } from "../screens/Funnel/Funnel";
import { DemandCenter } from "../screens/DemandCenter/DemandCenter";
import { ChatPage } from "../screens/Chat/ChatPage";
import { ChatPageB } from "../screens/Chat/ChatPageB";
import { AdminSignIn } from "../screens/Admin/AdminSignIn/AdminSignIn";
import { UserAccessDenied } from "../screens/AccessControl/UserAccessDenied/UserAccessDenied";
import { UserAccountDisabled } from "../screens/AccessControl/UserAccountDisabled/UserAccountDisabled";



export const authRoutes = [
  {
    path: "/account-disabled",
    element: <UserAccountDisabled />,
  },
  {
    path: "/access-denied",
    element: <UserAccessDenied />,
  },
  {
    path: "/admin/sign-in",
    element: <AdminSignIn />,
  },
  {
    path: "/",
    element: <SignIn />,
  },
  {
    path: "/sign-up",
    element: <SignUp />,
  },
  {
    path: "/sign-in",
    element: <SignIn />,
  },
  {
    path: "/dashboard",
    element: <Dashboard />,
  },
  {
    path: "/radar",
    element: <Radar />
  },
  {
    path: "/beacon",
    element: <Beacon />
  },

  {
    path: "/contact",
    element: <Contact />
  },
  {
    path: "/edit-profile",
    element: <UserProfile />,
  },
  {
    path: "/chatPage",
    element: <ChatPage />,
  },
  {
    path: "/chatPageB",
    element: <ChatPageB />,
  },
  // {
  //   path: "/sales-iq",
  //   element: <SalesIQ />
  // },
  {
    path: "/funnel",
    element: <Funnel />,
  },
  {
    path: "/demand-center",
    element: <DemandCenter />,
  },


];
