import React, { useEffect, useState } from "react";
import "./ArchiveWantedSoftware.css"
import { useLazyGetWantedSoftwaresQuery, useDeleteWantedSoftwareMutation } from "../../../api/WantedSoftwareAPI";

import { getCustomizedDate } from "../../../helper/ServiceHelper";
import { Loader } from "../../../components/Loader";
import { Link, useNavigate } from "react-router-dom";
import { Navbar1 } from '../../../components/Navbar1/Navbar1';
import { FooterAddForms } from '../../../components/Footer/FooterAddForms/FooterAddForms';
import divider from '../../../icons/Gerator/archive_page/divider.svg';
import divider1 from '../../../icons/Gerator/archive_page/divider1.svg';

import divider2 from '../../../icons/Gerator/archive_page/divider2.svg';
import divider4 from '../../../icons/Gerator/archive_page/divider-4.svg';

import eye from '../../../icons/Gerator/archive_page/eye.svg';

import rectangle_41_2x from '../../../icons/Gerator/archive_page/rectangle-41@2x.png';

import dotsvertical from '../../../icons/Gerator/archive_page/dotsvertical.svg';
import dot1 from '../../../icons/Gerator/archive_page/-dot1.svg';


import chevronright from '../../../icons/Gerator/archive_page/chevronright.svg';
import chevrondown from '../../../icons/Gerator/archive_page/chevrondown.svg';

import placeholder from '../../../icons/Gerator/archive_page/placeholder.svg';
import placeholder1 from '../../../icons/Gerator/archive_page/placeholder1.svg';
import placeholder2 from '../../../icons/Gerator/archive_page/placeholder2.svg';
import placeholder3 from '../../../icons/Gerator/archive_page/placeholder3.svg';

import homeline from '../../../icons/Gerator/archive_page/homeline.svg';
import searchlg from '../../../icons/Gerator/archive_page/searchlg.svg';

import calendar from '../../../icons/Gerator/archive_page/calendar.svg';


import radar_1_1_2x from '../../../icons/Gerator/archive_page/radar-1-1@2x.png';
import deal_2_1_2x from '../../../icons/Gerator/archive_page/deal-2-1@2x.png';
import magnifier_1_2x from '../../../icons/Gerator/archive_page/magnifier-1@2x.png';

import group_58_2x from '../../../icons/Gerator/archive_page/group-58@2x.png';

import dotsvertical from '../../../icons/Gerator/archive_page/dotsvertical.svg';
import filterfunnel01 from '../../../icons/Gerator/archive_page/filterfunnel01.svg';
import searchlg from '../../../icons/Gerator/archive_page/searchlg.svg';
import filterlines from '../../../icons/Gerator/archive_page/filterlines.svg';
import dot1 from '../../../icons/Gerator/archive_page/-dot1.svg';
import xclose from '../../../icons/Gerator/archive_page/xclose.svg';
import lock04 from '../../../icons/Gerator/archive_page/lock04.svg';
import lockunlocked04 from '../../../icons/Gerator/archive_page/lockunlocked04.svg';
import arrowsquareupright from '../../../icons/Gerator/archive_page/arrowsquareupright.svg';
import markerpin02 from '../../../icons/Gerator/archive_page/markerpin02.svg';
import icon1 from '../../../icons/Gerator/archive_page/icon-1.svg';
import sliders02 from '../../../icons/Gerator/archive_page/sliders02.svg';
import { useLazyGetUserQuery } from "../../../api/Login&RegisterAPI";
import { auth, onAuthStateChanged } from '../../Login/FirebaseClient';
import { decryptErrorData } from "../../../helper/AESHelper";

export const ArchiveWantedSoftware  = () => {

  let dataLength = 0;
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState("");


  const [deleteWantedSoftware] = useDeleteWantedSoftwareMutation();
  const [triggerGetUser, { data, isSuccess, error, isLoading, isError }] = useLazyGetUserQuery();
  const [triggerGetWantedSoftware, { data: wantedSoftwareData,
    isLoading: isWantedSoftwareLoading,
    isSuccess: isWantedSoftwareSuccess,
    isError: isWantedSoftwareError,
    error: wantedSoftwareError }] = useLazyGetWantedSoftwaresQuery();

  let content;
  // const [totalListings, setTotalListings] = useState("0");

  //Delete
  const handleDelete = async (id) => {
    try {

      const response = await deleteWantedSoftware(id).unwrap();

      if (response.status) {
        // setShowSuccessMessage(response.message)
        // await new Promise(resolve => setTimeout(resolve, 1500));
        // Reseting form
        window.location.reload();
      }
    } catch (error) {
      console.error(`Failed to delete wanted software `, error);
    }
  };

  useEffect(() => {
    // const auth = getAuth();

    // Listen for authentication state change
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is authenticated
        setCurrentUser(user);
        triggerGetUser();
      } else {
        // User is not authenticated, redirect to login
        navigate('/sign-in', { state: { message: 'Please log in to continue.' } });
      }
    });

    return () => unsubscribe(); // Cleanup listener on component unmount
  }, [navigate, triggerGetUser]);

  // Trigger the get wanted software query only after user data is successfully fetched
  useEffect(() => {
    if (isSuccess) {
      // Once the user is authenticated and the getUser query is successful, trigger the getWantedSoftware query
      triggerGetWantedSoftware();
    }
  }, [isSuccess, triggerGetWantedSoftware]);


  if (error) {
    console.log(error);
    if (isError) {
      const decryptedData = decryptErrorData(error.data);
      console.log(decryptedData);
      // navigate("/sign-in", { state: decryptedData.message });
      navigate("/sign-in", { state: { message: decryptedData.message } });
    }
    else {
      navigate("/sign-in", { state: { message: "Internal server error" } });
    }
    return; // Optionally handle error differently
  }


  if (isWantedSoftwareSuccess) {
    
    if (wantedSoftwareData.data.length > 0) {
      dataLength = wantedSoftwareData.data.length;
      content = wantedSoftwareData.data.map(wantedSoftware =>
      (

        <div className="archive-wanted-device-rectangle-group listing-item" key={wantedSoftware.id}>

          <div className="archive-wanted-software-rectangle-container">
            {/* <img
              className="archive-wanted-software-frame-inner"
              alt=""
              src={rectangle_41_2x}
            /> */}

            <div className="archive-wanted-software-dots-vertical-container">
              {/* <img
                className="archive-wanted-software-dots-vertical-icon1"
                alt=""
                src={dotsvertical}
              /> */}

              <div className="archive-wanted-software-text-parent">

                <Link to={`/wanted-software/${wantedSoftware.id}/${wantedSoftware.title}`} className=" archive-wanted-software-text37"><p className="text-overflow-archive-software">{wantedSoftware.title}</p></Link>

                <div className="archive-wanted-software-frame-group">
                  <div className="archive-wanted-software-frame-container">
                    <div className="archive-wanted-software-frame-div">
                      <div className="archive-wanted-software-frame-parent1">
                        <div className="archive-wanted-software-badge-parent">
                          <div className="archive-wanted-software-badge9">
                            <img
                              className="archive-wanted-software-dot-icon3"
                              alt=""
                              src={dot1}
                            />

                            <div className="archive-wanted-software-price-value">Pending</div>
                          </div>
                          <div className="archive-wanted-software-supporting-text4">ID# {wantedSoftware.id}</div>
                        </div>
                        <div className="archive-wanted-software-deal-type-parent">
                          <div className="archive-wanted-software-completed">
                            <div className="archive-wanted-software-second-value">4.9/5</div>
                          </div>
                          <div className="archive-wanted-software-supporting-text-wrapper">
                            <div className="archive-wanted-software-supporting-text3">99,999 reviews</div>
                          </div>
                        </div>
                        <div className="archive-wanted-software-badge10">
                          <div className="archive-wanted-software-third-badge-count text-overflow-archive-software">
                            {wantedSoftware.tax_software_category.name}
                          </div>
                        </div>
                      </div>
                      <div className="archive-wanted-software-deal-location-parent">
                        <div className="archive-wanted-software-dot-item-location-parent">
                          {/* <div className="archive-wanted-software-navigation">
                            <img
                              className="archive-wanted-software-marker-pin-02-icon"
                              alt=""
                              src={markerpin02}
                            />
                          </div> */}
                          {/* <div className="archive-wanted-software-location">India, New Delhi</div> */}
                        </div>
                        <div className="archive-wanted-software-frame-wrapper1">
                          <div className="archive-wanted-software-badge-group">
                            <div className="archive-wanted-software-badge10">
                              <div className="archive-wanted-software-sub-badge text-overflow-archive-software">{wantedSoftware.tax_clinical_application.name}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="archive-wanted-software-card-work">
                        <div className="archive-wanted-software-inner-work">
                          <div className="archive-wanted-software-dot-item-type-parent">
                            <div className="archive-wanted-software-navigation">
                              <img
                                className="archive-wanted-software-marker-pin-02-icon"
                                alt=""
                                src={calendar}
                              />
                            </div>
                            <div className="archive-wanted-software-type-of-work">{getCustomizedDate(wantedSoftware.createdAt)}</div>
                          </div>
                        </div>
                        <div className="archive-wanted-software-badge13 text-overflow-archive-software">
                          <div className="archive-wanted-software-third-badge-count">
                            {wantedSoftware.tax_purpose_use.name}
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                  {/* <div className="archive-wanted-software-second-price">
                    <div className="archive-wanted-software-price">$9,999,999.99</div>
                    <div className="archive-wanted-software-price-value1">
                      <div className="archive-wanted-software-deal-title">USD - Per Unit</div>
                    </div>
                    <div className="archive-wanted-software-inner-icons-wrapper">
                      <div className="archive-wanted-software-inner-icons">
                        <img
                          className="archive-wanted-software-magnifier-1-icon"
                          alt=""
                          src={radar_1_1_2x}
                        />

                        <img
                          className="archive-wanted-software-magnifier-1-icon"
                          alt=""
                          src={deal_2_1_2x}
                        />

                        <img
                          className="archive-wanted-software-magnifier-1-icon"
                          alt=""
                          src={magnifier_1_2x}
                        />
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="archive-wanted-software-divider-parent">
            <img className="archive-wanted-software-divider-icon4" alt="" src={divider2} />

            <div className="archive-wanted-software-user-listings">
              <div className="archive-wanted-software-frame-parent3">
                <div className="archive-wanted-software-frame-parent4">
                  <img
                    className="archive-wanted-software-user-content-child"
                    alt=""
                    src={group_58_2x}
                  />

                  <div className="archive-wanted-software-inner-info-wrapper">
                    <div className="archive-wanted-software-inner-info">
                      <div className="archive-wanted-software-name">Olivia Rhye</div>
                      <div className="archive-wanted-software-role1">Business Name</div>
                    </div>
                  </div>
                </div>
                <div className="archive-wanted-software-buttonsbutton-wrapper">
                  <Link to={"/edit-wanted-software/" + wantedSoftware.id}>
                    <button className="archive-wanted-software-buttonsbutton1">

                      <img className="archive-wanted-software-eye-icon" alt="" src={eye} />

                      <div className="archive-wanted-software-navigation">
                        <div className="archive-wanted-software-view-label">Edit</div>
                      </div>
                    </button>
                  </Link>
                </div>
                <div className="archive-wanted-software-logo-wrapper">
                  {/* <Link to={"/delete-wanted-software/" + wantedSoftware.id}> */}
                  <div className="archive-wanted-software-buttonsbutton2 archive-wanted-software-view-label" onClick={() => handleDelete(wantedSoftware.id)}>
                    <div className="archive-wanted-software-icon-wrapper">
                      <img className="archive-wanted-software-icon1" alt="" src={icon1} />
                    </div>
                    <div className="archive-wanted-software-navigation">
                      <div className="archive-wanted-software-lock-label">Delete</div>
                    </div>
                    <img className="archive-wanted-software-eye-icon" alt="" src={lock04} />
                  </div>
                  {/* </Link> */}
                </div>
                <div className="archive-wanted-software-logo-wrapper">
                  <div className="archive-wanted-software-buttonsbutton3">
                    <div className="archive-wanted-software-icon-wrapper">
                      <img className="archive-wanted-software-icon1" alt="" src={icon1} />
                    </div>
                    <div className="archive-wanted-software-navigation">
                      <div className="archive-wanted-software-lock-label">Sales IQ</div>
                    </div>
                    <img
                      className="archive-wanted-software-eye-icon"
                      alt=""
                      src={lockunlocked04}
                    />
                  </div>
                </div>
              </div>
              <div className="archive-wanted-software-arrow-square-up-right-wrapper">
                <img
                  className="archive-wanted-software-frame-child1"
                  alt=""
                  src={arrowsquareupright}
                />
              </div>
            </div>
          </div>
        </div>


      ))
    }
    else {
      content = (

        <div className="content-44" >
          <img
            className="bifocal-optometry-2"
            alt="Bifocal optometry"
            src="/img/bifocal-optometry-measurement-software-2.png"
          />
          <div className="text-and-supporting-16" style={{ alignSelf: "unset !important" }}>
            <div className="text-157">No listings found</div>
            <p className="supporting-text-36">
              Your search “Landing page design” did not match any projects. Please try again.
            </p>
          </div>
          <div className="actions-10">
            <div className="buttons-button-37">
              <div className="text-padding-18">
                <div className="text-158">Clear search</div>
              </div>
            </div>
            <div className="buttons-button-38">
              <img className="img-26" alt="Plus" src="/img/plus.svg" />
              <div className="text-padding-18">
                <Link to={"/add-wanted-software"}>
                  <div className="text-159">Add Listing</div>
                </Link>
              </div>
            </div>
          </div>
        </div>

      )
    }
    // content = <Loader/>
  } else {
    return <Loader />
  }



  return (
    <div className="archive-wanted-software-listings-search-results-list">
    <div className="archive-wanted-software-listings-search-results-list-child"></div>
    

    <Navbar1 userData={currentUser} navigate={navigate}/>


   <div className="archive-wanted-software-filters">
      <div className="archive-wanted-software-main">
        <div className="archive-wanted-software-header-section">
          <div className="archive-wanted-software-container">
            <div className="archive-wanted-software-page-header">
              <div className="archive-wanted-software-breadcrumbs">
                <div className="archive-wanted-software-tabs">
                  <div className="archive-wanted-software-breadcrumb-button-base">
                    <img
                      className="archive-wanted-software-search-lg-icon"
                      alt=""
                      src={homeline}
                    />
                  </div>
                  <img
                    className="archive-wanted-software-chevron-right-icon"
                    alt=""
                    src={chevronright}
                  />

                  <div className="archive-wanted-software-breadcrumb-button-base1">
                    <a className="archive-wanted-software-text7">Dashboard</a>
                  </div>
                  <img
                    className="archive-wanted-software-chevron-right-icon"
                    alt=""
                    src={chevronright}
                  />

                  <div className="archive-wanted-software-breadcrumb-button-base2">
                    <div className="archive-wanted-software-text8">...</div>
                  </div>
                  <img
                    className="archive-wanted-software-chevron-right-icon2"
                    alt=""
                    src={chevronright}
                  />

                  <div className="archive-wanted-software-breadcrumb-button-base3">
                    <div className="archive-wanted-software-text9">Another link</div>
                  </div>
                  <img
                    className="archive-wanted-software-chevron-right-icon2"
                    alt=""
                    src={chevronright}
                  />

                  <div className="archive-wanted-software-breadcrumb-button-base4">
                    <a className="archive-wanted-software-text10">Category</a>
                  </div>
                </div>
              </div>
              <img className="archive-wanted-software-divider-icon" alt="" src={divider} />
            </div>
          </div>
        </div>
        <div className="archive-wanted-software-main-child"></div>
        <div className="archive-wanted-software-main-item"></div>
      </div>
      <div className="archive-wanted-software-secondary-content">
        <div className="archive-wanted-software-page-header1">
          <div className="archive-wanted-software-breadcrumbs1">
            <div className="archive-wanted-software-tabs1">
              <div className="archive-wanted-software-breadcrumb-button-base">
                <img
                  className="archive-wanted-software-search-lg-icon"
                  alt=""
                  src={homeline}
                />
              </div>
              <img
                className="archive-wanted-software-chevron-right-icon"
                alt=""
                src={chevronright}
              />

              <div className="archive-wanted-software-breadcrumb-button-base6">
                <div className="archive-wanted-software-text11">Settings</div>
              </div>
              <img
                className="archive-wanted-software-chevron-right-icon"
                alt=""
                src={chevronright}
              />

              <div className="archive-wanted-software-breadcrumb-button-base7">
                <div className="archive-wanted-software-text8">...</div>
              </div>
              <img
                className="archive-wanted-software-chevron-right-icon"
                alt=""
                src={chevronright}
              />

              <div className="archive-wanted-software-breadcrumb-button-base3">
                <div className="archive-wanted-software-text9">Another link</div>
              </div>
              <img
                className="archive-wanted-software-chevron-right-icon2"
                alt=""
                src={chevronright}
              />

              <div className="archive-wanted-software-breadcrumb-button-base4">
                <div className="archive-wanted-software-menu-label">Team</div>
              </div>
            </div>
          </div>
          <div className="archive-wanted-software-content4">
            <div className="archive-wanted-software-text-and-supporting-text">
              <div className="archive-wanted-software-page-title">{dataLength} Listings Found</div>
              <div className="archive-wanted-software-supporting-text">
                Manage your team members and their account permissions here.
              </div>
            </div>
            <div className="archive-wanted-software-input-dropdown">
              <div className="archive-wanted-software-input-with-label1">
                <div className="archive-wanted-software-label1">Search</div>
                <div className="archive-wanted-software-input1">
                  <div className="archive-wanted-software-content5">
                    <img
                      className="archive-wanted-software-search-lg-icon"
                      alt=""
                      src={searchlg}
                    />

                    <div className="archive-wanted-software-text15">Search</div>
                  </div>
                </div>
              </div>
              <div className="archive-wanted-software-hint-text">This is a hint text to help user.</div>
            </div>
          </div>
          <img className="archive-wanted-software-divider-icon1" alt="" src={divider1} />
        </div>
      </div>
      <div className="archive-wanted-software-button-group">
        <button className="archive-wanted-software-button-group-base">
          <a className="archive-wanted-software-text16">View all</a>
        </button>
        <div className="archive-wanted-software-button-group-base1">
          <div className="archive-wanted-software-text17">Monitored</div>
        </div>
        <div className="archive-wanted-software-button-group-base2">
          <div className="archive-wanted-software-menu-label">Text</div>
        </div>
        <div className="archive-wanted-software-button-group-base3">
          <div className="archive-wanted-software-menu-label">Text</div>
        </div>
        <div className="archive-wanted-software-button-group-base4">
          <div className="archive-wanted-software-menu-label">Text</div>
        </div>
        <div className="archive-wanted-software-button-group-base5">
          <div className="archive-wanted-software-menu-label">Text</div>
        </div>
        <div className="archive-wanted-software-button-group-base6">
          <div className="archive-wanted-software-menu-label">Text</div>
        </div>
        <div className="archive-wanted-software-button-group-base7">
          <div className="archive-wanted-software-menu-label">Text</div>
        </div>
        <div className="archive-wanted-software-button-group-base1">
          <div className="archive-wanted-software-text24">Unmonitored</div>
        </div>
        {/* <div className="archive-wanted-software-breadcrumbs2">
          <div className="archive-wanted-software-tabs2">
            <div className="archive-wanted-software-breadcrumb-button-base">
              <img
                className="archive-wanted-software-search-lg-icon"
                alt=""
                src={homeline}
              />
            </div>
            <div className="archive-wanted-software-logo-wrapper">
              <input className="archive-wanted-software-chevron-right" type="checkbox" />
            </div>
            <div className="archive-wanted-software-breadcrumb-button-base11">
              <div className="archive-wanted-software-text11">Settings</div>
            </div>
            <div className="archive-wanted-software-logo-wrapper">
              <input className="archive-wanted-software-chevron-right" type="checkbox" />
            </div>
            <div className="archive-wanted-software-breadcrumb-button-base12">
              <div className="archive-wanted-software-text8">...</div>
            </div>
            <div className="archive-wanted-software-logo-wrapper">
              <img
                className="archive-wanted-software-arrow-down-icon"
                alt=""
                src={chevronright}
              />
            </div>
            <div className="archive-wanted-software-breadcrumb-button-base3">
              <div className="archive-wanted-software-text9">Another link</div>
            </div>
            <img
              className="archive-wanted-software-chevron-right-icon2"
              alt=""
              src={chevronright}
            />

            <div className="archive-wanted-software-breadcrumb-button-base14">
              <div className="archive-wanted-software-text28">Team</div>
            </div>
          </div>
        </div> */}
      </div>
      <div className="archive-wanted-software-input-dropdown1">
        <div className="archive-wanted-software-hint-text1">This is a hint text to help user.</div>
        <div className="archive-wanted-software-buttonsbutton">
          <img className="archive-wanted-software-sliders-02-icon" alt="" src={sliders02} />

          <div className="archive-wanted-software-text-padding">
            <div className="archive-wanted-software-menu-label">Sort By : Relevance</div>
            <img
              className="archive-wanted-software-sliders-02-icon"
              alt=""
              src={chevrondown}
            />
          </div>
          <img
            className="archive-wanted-software-placeholder-icon"
            alt=""
            src={placeholder}
          />
        </div>
      </div>
      <img className="archive-wanted-software-divider-icon2" alt="" src={divider2} />

      <img className="archive-wanted-software-divider-icon3" alt="" src={divider2} />

      <div className="archive-wanted-software-supporting-text1">
        You searched based on the following criteria.
      </div>
    
      <div className="listings-container">
          {content}
        </div>
      <div className="archive-wanted-software-buttonsbutton7">
        <img className="archive-wanted-software-placeholder-icon" alt="" src={placeholder} />

        <a className="archive-wanted-software-text47">All time</a>
        <img className="archive-wanted-software-sliders-02-icon" alt="" src={xclose} />
      </div>
      <button className="archive-wanted-software-buttonsbutton8">
        <img className="archive-wanted-software-placeholder-icon" alt="" src={placeholder} />

        <div className="archive-wanted-software-text48">Clinical Applications</div>
        <img className="archive-wanted-software-sliders-02-icon" alt="" src={xclose} />
      </button>
      <div className="archive-wanted-software-buttonsbutton9">
        <img className="archive-wanted-software-placeholder-icon" alt="" src={placeholder} />

        <div className="archive-wanted-software-text49">US, AU, +4</div>
        <img className="archive-wanted-software-sliders-02-icon" alt="" src={xclose} />
      </div>
      <div className="archive-wanted-software-buttonsbutton10">
        <img className="archive-wanted-software-placeholder-icon" alt="" src={placeholder} />

        <div className="archive-wanted-software-text50">All/Employee</div>
        <img className="archive-wanted-software-sliders-02-icon" alt="" src={xclose} />
      </div>
      <button className="archive-wanted-software-buttonsbutton11">
        <img className="archive-wanted-software-placeholder-icon" alt="" src={placeholder} />

        <div className="archive-wanted-software-text48">Clinical Applications</div>
        <img className="archive-wanted-software-sliders-02-icon" alt="" src={xclose} />
      </button>
      <div className="archive-wanted-software-buttonsbutton12">
        <img className="archive-wanted-software-placeholder-icon" alt="" src={placeholder} />

        <div className="archive-wanted-software-menu-label">Year of Manufacture</div>
        <img className="archive-wanted-software-sliders-02-icon" alt="" src={xclose} />
      </div>
      <button className="archive-wanted-software-buttonsbutton13">
        <img className="archive-wanted-software-placeholder-icon" alt="" src={placeholder} />

        <div className="archive-wanted-software-text53">Warrenty</div>
        <img className="archive-wanted-software-sliders-02-icon" alt="" src={xclose} />
      </button>
      <button className="archive-wanted-software-buttonsbutton14">
        <img className="archive-wanted-software-placeholder-icon" alt="" src={placeholder} />

        <div className="archive-wanted-software-text54">Manufacturer</div>
        <img className="archive-wanted-software-sliders-02-icon" alt="" src={xclose} />
      </button>
      <button className="archive-wanted-software-buttonsbutton15">
        <img className="archive-wanted-software-placeholder-icon" alt="" src={placeholder} />

        <div className="archive-wanted-software-text55">All Applications</div>
        <img className="archive-wanted-software-sliders-02-icon" alt="" src={xclose} />
      </button>
      <button className="archive-wanted-software-buttonsbutton16">
        <img className="archive-wanted-software-placeholder-icon" alt="" src={placeholder} />

        <div className="archive-wanted-software-text56">Rent/Lease</div>
        <img className="archive-wanted-software-sliders-02-icon" alt="" src={xclose} />
      </button>
      <button className="archive-wanted-software-buttonsbutton17">
        <img className="archive-wanted-software-sliders-02-icon" alt="" src={filterlines} />

        <div className="archive-wanted-software-text57">More filters</div>
        <img className="archive-wanted-software-placeholder-icon" alt="" src={placeholder} />
      </button>
      <button className="archive-wanted-software-buttonsbutton18">
        <img
          className="archive-wanted-software-sliders-02-icon"
          alt=""
          src={filterfunnel01}
        />

        <div className="archive-wanted-software-text58">Clear Filter</div>
        <img className="archive-wanted-software-placeholder-icon" alt="" src={placeholder} />
      </button>
      <button className="archive-wanted-software-actions">
        <div className="archive-wanted-software-button">
          <img
            className="archive-wanted-software-placeholder-icon"
            alt=""
            src={placeholder1}
          />

          <div className="archive-wanted-software-text-padding1">
            <div className="archive-wanted-software-text59">Tertiary</div>
          </div>
          <img
            className="archive-wanted-software-placeholder-icon"
            alt=""
            src={placeholder1}
          />
        </div>
        <div className="archive-wanted-software-button1">
          <img
            className="archive-wanted-software-placeholder-icon"
            alt=""
            src={placeholder2}
          />

          <div className="archive-wanted-software-text-padding1">
            <div className="archive-wanted-software-text60">Secondary</div>
          </div>
          <img
            className="archive-wanted-software-placeholder-icon"
            alt=""
            src={placeholder2}
          />
        </div>
        <div className="archive-wanted-software-button2">
          <img
            className="archive-wanted-software-sliders-02-icon"
            alt=""
            src={filterlines}
          />

          <div className="archive-wanted-software-text-padding">
            <div className="archive-wanted-software-filter-label">Save Filter</div>
          </div>
          <img
            className="archive-wanted-software-placeholder-icon"
            alt=""
            src={placeholder3}
          />
        </div>
      </button>
    </div>
  
    <img className="archive-wanted-software-divider-icon6" alt="" src={divider4} />

    <img className="archive-wanted-software-divider-icon7" alt="" src={divider4} />

   
    {/* <div className="archive-wanted-software-slide-out">
      <img className="archive-wanted-software-divider-icon14" alt="" src="/archive_page/divider-12.svg" />

      <div className="archive-wanted-software-rectangle-parent4">
        <div className="archive-wanted-software-frame-child8"></div>
        <div className="archive-wanted-software-frame-child9"></div>
        <img className="archive-wanted-software-dot-icon7" alt="" src="/archive_page/-dot.svg" />

        <img className="archive-wanted-software-dot-icon8" alt="" src="/archive_page/-dot.svg" />

        <img className="archive-wanted-software-dot-icon9" alt="" src="/archive_page/-dot.svg" />

        <img className="archive-wanted-software-dot-icon10" alt="" src="/archive_page/-dot.svg" />

        <img className="archive-wanted-software-dot-icon11" alt="" src="/archive_page/-dot.svg" />

        <img className="archive-wanted-software-dot-icon12" alt="" src="/archive_page/-dot.svg" />

        <img className="archive-wanted-software-dot-icon13" alt="" src="/archive_page/-dot.svg" />

        <div className="archive-wanted-software-frame-parent28">
          <div className="archive-wanted-software-slide-out-menu-header-parent">
            <div className="archive-wanted-software-slide-out-menu-header">
              <div className="archive-wanted-software-content24">
                <img
                  className="archive-wanted-software-featured-icon1"
                  alt=""
                  src="/archive_page/featured-icon1.svg"
                />

                <div className="archive-wanted-software-text-and-supporting-text6">
                  <a className="archive-wanted-software-text184">Filters</a>
                  <div className="archive-wanted-software-supporting-text57">
                    Apply filters to table data.
                  </div>
                </div>
              </div>
              <div className="archive-wanted-software-button-close-x">
                <img
                  className="archive-wanted-software-search-lg-icon"
                  alt=""
                  src="/archive_page/xclose-10.svg"
                />
              </div>
            </div>
            <div className="archive-wanted-software-frame-wrapper13">
              <div className="archive-wanted-software-slide-out-title-parent">
                <a className="archive-wanted-software-slide-out-title">Price</a>
                <div className="archive-wanted-software-navigation">
                  <img
                    className="archive-wanted-software-marker-pin-02-icon2"
                    alt=""
                    src={chevrondown}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="archive-wanted-software-frame-parent29">
            <div className="archive-wanted-software-frame-parent30">
              <div className="archive-wanted-software-frame-wrapper14">
                <div className="archive-wanted-software-frame-parent31">
                  <div className="archive-wanted-software-background-parent">
                    <div className="archive-wanted-software-background"></div>
                    <div className="archive-wanted-software-progress">
                      <div className="archive-wanted-software-progress-line"></div>
                      <div className="archive-wanted-software-control-handle">
                        <div className="archive-wanted-software-tooltip1">
                          <div className="archive-wanted-software-content25">
                            <div className="archive-wanted-software-text185">0</div>
                          </div>
                        </div>
                        <div className="archive-wanted-software-handle"></div>
                      </div>
                      <div className="archive-wanted-software-control-handle">
                        <div className="archive-wanted-software-tooltip2">
                          <div className="archive-wanted-software-content26">
                            <div className="archive-wanted-software-text186">9,999,999,99</div>
                          </div>
                        </div>
                        <div className="archive-wanted-software-handle"></div>
                      </div>
                    </div>
                  </div>
                  <div className="archive-wanted-software-input-fields-parent">
                    <div className="archive-wanted-software-input-fields">
                      <div className="archive-wanted-software-input-field">
                        <div className="archive-wanted-software-input-with-label4">
                          <div className="archive-wanted-software-label5">Email</div>
                          <div className="archive-wanted-software-input4">
                            <div className="archive-wanted-software-content27">
                              <div className="archive-wanted-software-text187">Min Value</div>
                            </div>
                            <img
                              className="archive-wanted-software-help-icon1"
                              alt=""
                              src="/archive_page/help-icon1.svg"
                            />
                          </div>
                        </div>
                        <div className="archive-wanted-software-hint-text">
                          This is a hint text to help user.
                        </div>
                      </div>
                      <div className="archive-wanted-software-text188">–</div>
                      <div className="archive-wanted-software-input-field">
                        <div className="archive-wanted-software-input-with-label4">
                          <div className="archive-wanted-software-label5">Email</div>
                          <div className="archive-wanted-software-input4">
                            <div className="archive-wanted-software-content27">
                              <div className="archive-wanted-software-text187">Max Value</div>
                            </div>
                            <img
                              className="archive-wanted-software-help-icon1"
                              alt=""
                              src="/archive_page/help-icon1.svg"
                            />
                          </div>
                        </div>
                        <div className="archive-wanted-software-hint-text">
                          This is a hint text to help user.
                        </div>
                      </div>
                    </div>
                    <div className="archive-wanted-software-checkboxes">
                      <div className="archive-wanted-software-collapsed-names">
                        <input className="archive-wanted-software-input6" type="checkbox" />

                        <div className="archive-wanted-software-price-parent">
                          <div className="archive-wanted-software-text8">US$ 0 — US$ 1,000</div>
                          <div className="archive-wanted-software-hint-text">
                            Save my login details for next time.
                          </div>
                        </div>
                      </div>
                      <div className="archive-wanted-software-collapsed-names">
                        <input className="archive-wanted-software-input6" type="checkbox" />

                        <div className="archive-wanted-software-price-parent">
                          <div className="archive-wanted-software-text8">US$ 1,001 — US$ 5,000</div>
                          <div className="archive-wanted-software-hint-text">
                            Save my login details for next time.
                          </div>
                        </div>
                      </div>
                      <div className="archive-wanted-software-collapsed-names">
                        <input className="archive-wanted-software-input6" type="checkbox" />

                        <div className="archive-wanted-software-price-parent">
                          <div className="archive-wanted-software-text8">US$ 5,001 — US$ 15,000</div>
                          <div className="archive-wanted-software-hint-text">
                            Save my login details for next time.
                          </div>
                        </div>
                      </div>
                      <div className="archive-wanted-software-collapsed-names">
                        <input className="archive-wanted-software-input6" type="checkbox" />

                        <div className="archive-wanted-software-price-parent">
                          <div className="archive-wanted-software-text8">US$ 15,001 — US$ 9,999,999</div>
                          <div className="archive-wanted-software-hint-text">
                            Save my login details for next time.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="archive-wanted-software-frame-child10"></div>
            </div>
            <img
              className="archive-wanted-software-divider-icon15"
              alt=""
              src="/archive_page/divider-13.svg"
            />

            <div className="archive-wanted-software-section-wrapper">
              <div className="archive-wanted-software-section-parent">
                <div className="archive-wanted-software-section">
                  <div className="archive-wanted-software-content-parent">
                    <div className="archive-wanted-software-content29">
                      <img className="archive-wanted-software-dot-icon" alt="" src="/archive_page/-dot.svg" />

                      <div className="archive-wanted-software-text194">Date Posted</div>
                    </div>
                    <img
                      className="archive-wanted-software-search-lg-icon"
                      alt=""
                      src={chevrondown}
                    />
                  </div>
                </div>
                <div className="archive-wanted-software-input-fields">
                  <div className="archive-wanted-software-input-field2">
                    <div className="archive-wanted-software-input-with-label4">
                      <div className="archive-wanted-software-label5">Email</div>
                      <div className="archive-wanted-software-input10">
                        <div className="archive-wanted-software-content27">
                          <div className="archive-wanted-software-text187">Jan 6, 2024</div>
                        </div>
                        <img
                          className="archive-wanted-software-help-icon1"
                          alt=""
                          src="/archive_page/help-icon2.svg"
                        />
                      </div>
                    </div>
                    <div className="archive-wanted-software-hint-text">
                      This is a hint text to help user.
                    </div>
                  </div>
                  <div className="archive-wanted-software-text188">–</div>
                  <div className="archive-wanted-software-input-field2">
                    <div className="archive-wanted-software-input-with-label4">
                      <div className="archive-wanted-software-label5">Email</div>
                      <div className="archive-wanted-software-input10">
                        <div className="archive-wanted-software-content27">
                          <div className="archive-wanted-software-text187">Jan 12, 2024</div>
                        </div>
                        <img
                          className="archive-wanted-software-help-icon1"
                          alt=""
                          src="/archive_page/help-icon2.svg"
                        />
                      </div>
                    </div>
                    <div className="archive-wanted-software-hint-text">
                      This is a hint text to help user.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <img
              className="archive-wanted-software-divider-icon15"
              alt=""
              src="/archive_page/divider-13.svg"
            />

            <div className="archive-wanted-software-section-wrapper">
              <div className="archive-wanted-software-section1">
                <div className="archive-wanted-software-content-parent">
                  <div className="archive-wanted-software-content29">
                    <img className="archive-wanted-software-dot-icon" alt="" src="/archive_page/-dot.svg" />

                    <div className="archive-wanted-software-text198">Seller Role</div>
                  </div>
                  <img
                    className="archive-wanted-software-search-lg-icon"
                    alt=""
                    src={chevrondown}
                  />
                </div>
                <div className="archive-wanted-software-checkboxes1">
                  <div className="archive-wanted-software-collapsed-names">
                    <input className="archive-wanted-software-input6" type="checkbox" />

                    <div className="archive-wanted-software-price-parent">
                      <div className="archive-wanted-software-text8">Dealer/Distributor</div>
                      <div className="archive-wanted-software-hint-text">
                        Save my login details for next time.
                      </div>
                    </div>
                  </div>
                  <div className="archive-wanted-software-collapsed-names">
                    <input className="archive-wanted-software-input6" type="checkbox" />

                    <div className="archive-wanted-software-price-parent">
                      <div className="archive-wanted-software-text8">Manufacturer</div>
                      <div className="archive-wanted-software-hint-text">
                        Save my login details for next time.
                      </div>
                    </div>
                  </div>
                  <div className="archive-wanted-software-collapsed-names">
                    <input className="archive-wanted-software-input6" type="checkbox" />

                    <div className="archive-wanted-software-price-parent">
                      <div className="archive-wanted-software-text8">Owner/Employee</div>
                      <div className="archive-wanted-software-hint-text">
                        Save my login details for next time.
                      </div>
                    </div>
                  </div>
                  <div className="archive-wanted-software-collapsed-names">
                    <input className="archive-wanted-software-input6" type="checkbox" />

                    <div className="archive-wanted-software-price-parent">
                      <div className="archive-wanted-software-text8">Third Party/Agent</div>
                      <div className="archive-wanted-software-hint-text">
                        Save my login details for next time.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <img
              className="archive-wanted-software-divider-icon15"
              alt=""
              src="/archive_page/divider-13.svg"
            />

            <div className="archive-wanted-software-section-wrapper">
              <div className="archive-wanted-software-section1">
                <div className="archive-wanted-software-content-parent">
                  <div className="archive-wanted-software-content29">
                    <img className="archive-wanted-software-dot-icon" alt="" src="/archive_page/-dot.svg" />

                    <div className="archive-wanted-software-text203">Transaction Type</div>
                  </div>
                  <img
                    className="archive-wanted-software-search-lg-icon"
                    alt=""
                    src={chevrondown}
                  />
                </div>
                <div className="archive-wanted-software-checkboxes1">
                  <div className="archive-wanted-software-collapsed-names">
                    <input className="archive-wanted-software-input6" type="checkbox" />

                    <div className="archive-wanted-software-price-parent">
                      <div className="archive-wanted-software-text8">Exchange</div>
                      <div className="archive-wanted-software-hint-text">
                        Save my login details for next time.
                      </div>
                    </div>
                  </div>
                  <div className="archive-wanted-software-collapsed-names">
                    <input className="archive-wanted-software-input6" type="checkbox" />

                    <div className="archive-wanted-software-price-parent">
                      <div className="archive-wanted-software-text8">Rent/Lease</div>
                      <div className="archive-wanted-software-hint-text">
                        Save my login details for next time.
                      </div>
                    </div>
                  </div>
                  <div className="archive-wanted-software-collapsed-names">
                    <input className="archive-wanted-software-input6" type="checkbox" />

                    <div className="archive-wanted-software-price-parent">
                      <div className="archive-wanted-software-text8">Sale</div>
                      <div className="archive-wanted-software-hint-text">
                        Save my login details for next time.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <img
              className="archive-wanted-software-divider-icon15"
              alt=""
              src="/archive_page/divider-13.svg"
            />

            <div className="archive-wanted-software-section-wrapper">
              <div className="archive-wanted-software-section3">
                <div className="archive-wanted-software-content-parent">
                  <div className="archive-wanted-software-content29">
                    <img className="archive-wanted-software-dot-icon" alt="" src="/archive_page/-dot.svg" />

                    <div className="archive-wanted-software-text207">Status</div>
                  </div>
                  <img
                    className="archive-wanted-software-search-lg-icon"
                    alt=""
                    src={chevrondown}
                  />
                </div>
              </div>
            </div>
            <img
              className="archive-wanted-software-divider-icon15"
              alt=""
              src="/archive_page/divider-13.svg"
            />

            <div className="archive-wanted-software-section-wrapper">
              <div className="archive-wanted-software-section3">
                <div className="archive-wanted-software-content-parent">
                  <div className="archive-wanted-software-content29">
                    <img className="archive-wanted-software-dot-icon" alt="" src="/archive_page/-dot.svg" />

                    <div className="archive-wanted-software-text208">Warranty</div>
                  </div>
                  <img
                    className="archive-wanted-software-search-lg-icon"
                    alt=""
                    src={chevrondown}
                  />
                </div>
              </div>
            </div>
            <img
              className="archive-wanted-software-divider-icon15"
              alt=""
              src="/archive_page/divider-13.svg"
            />

            <div className="archive-wanted-software-section-wrapper">
              <div className="archive-wanted-software-section3">
                <div className="archive-wanted-software-content-parent">
                  <div className="archive-wanted-software-content29">
                    <img className="archive-wanted-software-dot-icon" alt="" src="/archive_page/-dot.svg" />

                    <div className="archive-wanted-software-text209">Shipping</div>
                  </div>
                  <img
                    className="archive-wanted-software-search-lg-icon"
                    alt=""
                    src={chevrondown}
                  />
                </div>
              </div>
            </div>
            <img
              className="archive-wanted-software-divider-icon15"
              alt=""
              src="/archive_page/divider-13.svg"
            />

            <div className="archive-wanted-software-section-wrapper">
              <div className="archive-wanted-software-section-parent">
                <div className="archive-wanted-software-actions6">
                  <div className="archive-wanted-software-navigation">
                    <input className="archive-wanted-software-search-lg" type="radio" />
                  </div>
                  <div className="archive-wanted-software-text210">Country</div>
                </div>
                <div className="archive-wanted-software-input-fields2">
                  <div className="archive-wanted-software-input-field2">
                    <div className="archive-wanted-software-input-with-label4">
                      <div className="archive-wanted-software-label5">Email</div>
                      <div className="archive-wanted-software-input19">
                        <input
                          className="archive-wanted-software-content37"
                          placeholder="Search"
                          type="text"
                        />

                        <img
                          className="archive-wanted-software-help-icon1"
                          alt=""
                          src="/archive_page/help-icon.svg"
                        />
                      </div>
                    </div>
                    <div className="archive-wanted-software-hint-text">
                      This is a hint text to help user.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="archive-wanted-software-second-badge-divider">
              <img
                className="archive-wanted-software-divider-icon22"
                alt=""
                src="/archive_page/divider-13.svg"
              />

              <img
                className="archive-wanted-software-divider-icon23"
                alt=""
                src="/archive_page/divider-21.svg"
              />
            </div>
            <div className="archive-wanted-software-frame-wrapper17">
              <div className="archive-wanted-software-actions6">
                <div className="archive-wanted-software-navigation">
                  <input className="archive-wanted-software-search-lg" type="radio" />
                </div>
                <div className="archive-wanted-software-text211">Software Category</div>
              </div>
            </div>
            <div className="archive-wanted-software-second-badge-divider">
              <img
                className="archive-wanted-software-divider-icon22"
                alt=""
                src="/archive_page/divider-13.svg"
              />

              <img
                className="archive-wanted-software-divider-icon23"
                alt=""
                src="/archive_page/divider-21.svg"
              />
            </div>
            <div className="archive-wanted-software-frame-wrapper17">
              <div className="archive-wanted-software-actions6">
                <div className="archive-wanted-software-navigation">
                  <input className="archive-wanted-software-search-lg" type="radio" />
                </div>
                <div className="archive-wanted-software-text212">OEM Brand</div>
              </div>
            </div>
            <div className="archive-wanted-software-frame-parent36">
              <div className="archive-wanted-software-second-badge-divider">
                <img
                  className="archive-wanted-software-divider-icon22"
                  alt=""
                  src="/archive_page/divider-13.svg"
                />

                <img
                  className="archive-wanted-software-divider-icon23"
                  alt=""
                  src="/archive_page/divider-21.svg"
                />
              </div>
              <div className="archive-wanted-software-frame-wrapper19">
                <div className="archive-wanted-software-frame-parent37">
                  <div className="archive-wanted-software-navigation">
                    <input className="archive-wanted-software-search-lg" type="radio" />
                  </div>
                  <div className="archive-wanted-software-text213">Year of Manufacture</div>
                </div>
              </div>
              <div className="archive-wanted-software-second-badge-divider">
                <img
                  className="archive-wanted-software-divider-icon22"
                  alt=""
                  src="/archive_page/divider-13.svg"
                />

                <img
                  className="archive-wanted-software-divider-icon23"
                  alt=""
                  src="/archive_page/divider-21.svg"
                />
              </div>
              <div className="archive-wanted-software-frame-wrapper19">
                <div className="archive-wanted-software-actions6">
                  <div className="archive-wanted-software-navigation">
                    <input className="archive-wanted-software-search-lg" type="radio" />
                  </div>
                  <div className="archive-wanted-software-text213">Clinical Applications</div>
                </div>
              </div>
            </div>
            <div className="archive-wanted-software-card-header-parent">
              <img
                className="archive-wanted-software-divider-icon15"
                alt=""
                src="/archive_page/divider-13.svg"
              />

              <img
                className="archive-wanted-software-divider-icon31"
                alt=""
                src="/archive_page/divider-21.svg"
              />
            </div>
            <div className="archive-wanted-software-frame-wrapper17">
              <div className="archive-wanted-software-actions6">
                <div className="archive-wanted-software-navigation">
                  <input className="archive-wanted-software-search-lg" type="radio" />
                </div>
                <div className="archive-wanted-software-text212">Software Use</div>
              </div>
            </div>
            <div className="archive-wanted-software-second-badge-divider">
              <img
                className="archive-wanted-software-divider-icon22"
                alt=""
                src="/archive_page/divider-13.svg"
              />

              <img
                className="archive-wanted-software-divider-icon23"
                alt=""
                src="/archive_page/divider-21.svg"
              />
            </div>
            <div className="archive-wanted-software-frame-wrapper17">
              <div className="archive-wanted-software-frame-parent37">
                <div className="archive-wanted-software-navigation">
                  <input className="archive-wanted-software-search-lg" type="radio" />
                </div>
                <div className="archive-wanted-software-text216">Physical Location</div>
              </div>
            </div>
            <img
              className="archive-wanted-software-divider-icon15"
              alt=""
              src="/archive_page/divider-13.svg"
            />
          </div>
        </div>
        <img className="archive-wanted-software-dot-icon20" alt="" src="/archive_page/-dot.svg" />

        <div className="archive-wanted-software-frame-parent41">
          <div className="archive-wanted-software-divider-parent6">
            <img
              className="archive-wanted-software-divider-icon35"
              alt=""
              src="/archive_page/divider-13.svg"
            />

            <div className="archive-wanted-software-footer1">
              <div className="archive-wanted-software-content38">
                <div className="archive-wanted-software-logo1">
                  <div className="archive-wanted-software-buttonsbutton37">
                    <img
                      className="archive-wanted-software-placeholder-icon"
                      alt=""
                      src={placeholder2}
                    />

                    <div className="archive-wanted-software-text217">Save filter</div>
                    <img
                      className="archive-wanted-software-placeholder-icon"
                      alt=""
                      src={placeholder2}
                    />
                  </div>
                </div>
                <div className="archive-wanted-software-actions9">
                  <button className="archive-wanted-software-buttonsbutton38">
                    <img
                      className="archive-wanted-software-placeholder-icon"
                      alt=""
                      src={placeholder}
                    />

                    <div className="archive-wanted-software-text-padding">
                      <div className="archive-wanted-software-text218">Cancel</div>
                    </div>
                    <img
                      className="archive-wanted-software-placeholder-icon"
                      alt=""
                      src={placeholder}
                    />
                  </button>
                  <button className="archive-wanted-software-buttonsbutton39">
                    <img
                      className="archive-wanted-software-placeholder-icon"
                      alt=""
                      src={placeholder3}
                    />

                    <div className="archive-wanted-software-text-padding">
                      <div className="archive-wanted-software-text219">Apply</div>
                    </div>
                    <img
                      className="archive-wanted-software-placeholder-icon"
                      alt=""
                      src={placeholder3}
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="archive-wanted-software-dot-item-innermost">
            <div className="archive-wanted-software-collapsed-content">
              <img
                className="archive-wanted-software-divider-icon22"
                alt=""
                src="/archive_page/divider-13.svg"
              />

              <img
                className="archive-wanted-software-divider-icon37"
                alt=""
                src="/archive_page/divider-21.svg"
              />
            </div>
          </div>
        </div>
      </div>
    
    </div> */}
    <FooterAddForms/>

  

    
  </div>

  
  )
}

