import React, { useEffect, useState } from 'react';
import "./AccountCheckEmail.css";
import { Link, useNavigate } from 'react-router-dom';
import { BackToLoginButton } from '../../../components/BackToLoginButton/BackToLoginButton';
import { AccountSetupButton } from '../../../components/AccountSetupButton/AccountSetupButton';
import gerator_logo from '../../../icons/Gerator/gerator04-1@2x.png';
import feature_icon from '../../../icons/Gerator/account_setup/featured-icon.svg';
import feature_icon1 from '../../../icons/Gerator/account_setup/featured-icon-1.svg';
import feature_icon2 from '../../../icons/Gerator/account_setup/featured-icon-2.svg';
import feature_icon3 from '../../../icons/Gerator/account_setup/featured-icon-3.svg';
import feature_icon4 from '../../../icons/Gerator/account_setup/featured-icon-4.svg';
import mail_icon from '../../../icons/Gerator/account_setup/mail01-1.svg';
import { auth, onAuthStateChanged, sendEmailVerification, firestore, doc, getDoc } from "../../Login/FirebaseClient";
import { Helmet } from 'react-helmet-async';
import { useGetUserQuery, useLazyGetUserQuery } from '../../../api/Login&RegisterAPI';
import { Loader } from '../../../components/Loader';
import support_icon from "../../../icons/Gerator/support_24dp_5F6368_FILL0_wght400_GRAD0_opsz24.svg";


const AccountSetupEmail = () => {
  const [showSuccessMessage, setShowSuccessMessage] = useState({
    message: "",
    color: "",
  });
  const [emailSent, setEmailSent] = useState(false);
  const [timeUntilResend, setTimeUntilResend] = useState(0);
  const [userDetails, setUserDetails] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [currentUsermeta, setCurrentUsermeta] = useState(null);
  const [wizardStep, setWizardStep] = useState(null);
  const [isInitializing, setIsInitializing] = useState(true);
  const [shouldRender, setShouldRender] = useState(false);
  const navigate = useNavigate();
  const [triggerGetUser, { data, isLoading }] = useLazyGetUserQuery();

  // Handle auth state changes
  // useEffect(() => {
  //   let isMounted = true;

  //   const unsubscribe = onAuthStateChanged(auth, async (user) => {
  //     if (!isMounted) return;

  //     if (user) {
  //       console.log(user);
  //       // Fetch wizardStep from Firestore
  //       const userDocRef = doc(firestore, "users", user.uid);
  //       const userDocSnapshot = await getDoc(userDocRef);

  //       if (userDocSnapshot.exists()) {
  //         const wizardStep = userDocSnapshot.data().wizardStep || 1; // Default to 1 if not set
  //         console.log("Wizard Step:", wizardStep);
  //         // You can use the wizardStep here as needed, without setting it in state
  //       } else {
  //         navigate("/sign-in", { state: { message: "User document does not exist in Firestore" } });
  //         console.log("User document does not exist in Firestore");
  //       }
  //       setCurrentUser(user);
  //       const idTokenResult = await user.getIdTokenResult();
  //       if (idTokenResult.claims.emailVerified) {
  //         // If email is already verified, don't even attempt to render the page
  //         navigate('/dashboard');
  //         return;
  //       }
  //     } else {
  //       setIsInitializing(false);
  //       navigate("/sign-in", { state: { message: "User not authorized" } });
  //     }
  //   });

  //   return () => {
  //     isMounted = false;
  //     unsubscribe();
  //   };
  // }, [navigate]);

  useEffect(() => {
    let isMounted = true;

    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (!isMounted) return;

      if (user) {
        try {
          // Fetch wizardStep from Firestore
          const userDocRef = doc(firestore, "users", user.uid);
          const userDocSnapshot = await getDoc(userDocRef);

          if (userDocSnapshot.exists()) {
            const usermeta = userDocSnapshot.data(); // Default to 1 if not set
            setCurrentUsermeta(usermeta);
            setWizardStep(usermeta.wizardStep);
            console.log("Wizard Step:", usermeta.wizardStep);
          } else {
            console.log("User document does not exist in Firestore");
            navigate("/sign-in", { state: { message: "There is an issue when signing up" } });
          }

          setCurrentUser(user);
          const idTokenResult = await user.getIdTokenResult();
          if (idTokenResult.claims.emailVerified) {
            // If email is already verified, don't even attempt to render the page
            navigate('/dashboard');
            return;
          }
        } catch (error) {
          console.error("Error fetching wizardStep from Firestore:", error);
          navigate("/sign-in", { state: { message: "Permission error or document does not exist" } });
        }
      } else {
        setIsInitializing(false);
        navigate("/sign-in", { state: { message: "User not authorized" } });
      }
    });

    return () => {
      isMounted = false;
      unsubscribe();
    };
  }, [navigate]);



  // Trigger user data fetch and handle initial routing
  useEffect(() => {
    let isMounted = true;

    const initializeUser = async () => {
      if (!currentUser?.uid) return;

      try {
        // const result = await triggerGetUser().unwrap();

        if (!isMounted) return;

        if (currentUsermeta.wizardStep > 1) {
          const destination = currentUsermeta.wizardStep === 2
            ? '/account-setup-update-profile'
            : currentUsermeta.wizardStep === 3
              ? '/account-setup-subscription-plan'
              : '/dashboard';

          navigate(destination);
          return;
        }

        // Only set user details and allow rendering if email is not verified
        setUserDetails(currentUser);
        setIsInitializing(false);
        setShouldRender(true);
      } catch (error) {
        console.error('Error fetching user data:', error);
        if (isMounted) {
          setIsInitializing(false);
        }
      }
    };

    initializeUser();

    return () => {
      isMounted = false;
    };
  }, [wizardStep, navigate]);

  // Send initial verification email
  useEffect(() => {
    if (!emailSent && userDetails && shouldRender) {
      handleVerificationEmail(userDetails);
    }
  }, [userDetails, emailSent, shouldRender]);

  // Check email verification status periodically
  useEffect(() => {
    if (!userDetails || !shouldRender) return;

    const checkVerificationStatus = async () => {
      try {
        if (auth.currentUser) {
          await auth.currentUser.reload();
          if (auth.currentUser.emailVerified) {
            navigate('/account-setup-update-profile');
          }
        }
      } catch (error) {
        console.error('Error checking verification status:', error);
      }
    };

    const intervalId = setInterval(checkVerificationStatus, 5000);
    return () => clearInterval(intervalId);
  }, [userDetails, navigate, shouldRender]);

  const handleVerificationEmail = async (user) => {
    try {
      if (timeUntilResend > 0) {
        setShowSuccessMessage({
          message: `Please wait ${timeUntilResend} seconds before requesting another email`,
          color: "#FFA500"
        });
        return;
      }

      if (auth.currentUser) {
        await sendEmailVerification(auth.currentUser);
        setEmailSent(true);
        setTimeUntilResend(60);

        setShowSuccessMessage({
          message: "Verification email sent successfully!",
          color: "#4CAF50"
        });

        const timer = setInterval(() => {
          setTimeUntilResend((prev) => {
            if (prev <= 1) {
              clearInterval(timer);
              return 0;
            }
            return prev - 1;
          });
        }, 1000);
      } else {
        throw new Error('No authenticated user found');
      }
    } catch (error) {
      let errorMessage = "An error occurred while sending verification email.";

      if (error.code === 'auth/too-many-requests') {
        errorMessage = "Too many attempts. Please try again in a few minutes.";
      }

      setShowSuccessMessage({
        message: errorMessage,
        color: "#FF0000"
      });

      console.error('Error sending verification email:', error);
    }
  };

  const handleResendClick = () => {
    if (userDetails && !timeUntilResend) {
      handleVerificationEmail(userDetails);
    }
  };

  // Show loader until we're absolutely sure we should render the verification page
  if (isInitializing || isLoading || !shouldRender) {
    return <Loader />;
  }

  return (

    <>
      <Helmet>
        <title>Step 1 - Verify Email</title>
      </Helmet>
      <div className="account-email-check-set-up-wizardcheck-email-des">
        <div className="account-email-check-section">
          <div className="account-email-check-section-inner">
            <div className="account-email-check-logo-parent">
              <div className="account-email-check-logo">
                <img
                  className="account-email-check-gerator-04-1-icon"
                  alt=""
                  src={gerator_logo}
                />
              </div>
              <div className="account-email-check-progress-steps-progress-icon">
                <div className="account-email-check-step-base">
                  <div className="account-email-check-featured-icon-parent">
                    <img
                      className="account-email-check-featured-icon"
                      loading="lazy"
                      alt=""
                      src={feature_icon}
                    />

                    <div className="account-email-check-text-parent">
                      <div className="account-email-check-text">Quick tap to confirm you're you!</div>
                      <div className="account-email-check-supporting-text">Verify your email address</div>
                    </div>
                  </div>
                  <div className="account-email-check-button-steps">
                    <div className="account-email-check-connector"></div>
                  </div>
                </div>
                <div className="account-email-check-step-base1">
                  <div className="account-email-check-featured-icon-parent">
                    <img
                      className="account-email-check-featured-icon"
                      loading="lazy"
                      alt=""
                      src={feature_icon1}
                    />

                    <div className="account-email-check-text-parent">
                      <div className="account-email-check-text">Update Profile</div>
                      <div className="account-email-check-supporting-text">
                        Tell us a bit about yourself
                      </div>
                    </div>
                  </div>
                  <div className="account-email-check-button-steps">
                    <div className="account-email-check-connector"></div>
                  </div>
                </div>
                <div className="account-email-check-step-base2">
                  <div className="account-email-check-connector-wrap">
                    <img
                      className="account-email-check-featured-icon2"
                      loading="lazy"
                      alt=""
                      src={feature_icon2}
                    />


                  </div>
                  <div className="account-email-check-text-and-supporting-text">
                    <div className="account-email-check-text">Choose a Subscription plan</div>
                    <div className="account-email-check-supporting-text2">
                      Choose how you want to roll
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div className="account-email-check-email-title-parent">

          </div>
        </div>
        <section className="account-email-check-section1">
          <div className="account-email-check-container-parent">
            <div className="account-email-check-container">
              <div className="account-email-check-content">
                <div className="account-email-check-container1">
                  <div className="account-email-check-content1">
                    <div className="account-email-check-header">
                      <img
                        className="account-email-check-featured-icon4"
                        loading="lazy"
                        alt=""
                        src={feature_icon4}
                      />

                      <div className="account-email-check-text-and-supporting-text2">
                        <h2 className="account-email-check-title">Check your email</h2>
                        <div className="account-email-check-supporting-text4">
                          <span>We sent a verification link to </span>
                          <span className="account-email-check-oliviauntitleduicom"
                          >{userDetails?.email}</span
                          >
                        </div>
                      </div>
                    </div>


                    <AccountSetupButton
                      text={timeUntilResend > 0 ?
                        `Resend available in ${timeUntilResend}s` :
                        "Resend verification email"
                      }
                      onClick={handleResendClick}
                      disabled={timeUntilResend > 0}
                    />

                    <Link to={"/sign-in"} style={{ textDecoration: 'none' }}>
                      <BackToLoginButton />
                    </Link>
                    {showSuccessMessage.message?.length > 0 && (
                      <div
                        style={{
                          fontSize: "14px",
                          color: showSuccessMessage.color,
                          padding: "15px 10px",
                          border: "1px solid " + showSuccessMessage.color,
                          borderRadius: "10px",
                          margin: "0 auto",
                        }}
                      >
                        {showSuccessMessage.message}
                      </div>
                    )}

                  </div>
                  <div className="account-email-check-mobile-container" >
                    <div className="account-email-check-mobile-PaginationDotGroup" >
                      <div className="account-email-check-mobile-PaginationDotIndicator1" />
                      <div className="account-email-check-mobile-PaginationDotIndicator" />
                      <div className="account-email-check-mobile-PaginationDotIndicator" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <footer className="account-email-check-footer">
              <div className="account-email-check-icon-and-text">
                <img
                  className="account-email-check-mail-01-icon1"
                  loading="lazy"
                  alt=""
                  src={support_icon}
                />

                <div className="account-email-check-helpcentergeratorcom"><a href=" https://helpcenter.gerator.com/" className='account-email-check-hyperlink'>Need Help?</a></div>
              </div>
              <div className="account-email-check-text3">© Gerator Asia LLP. All rights reserved.</div>
            </footer>
          </div>
        </section>
      </div>
    </>
  )


}

export default AccountSetupEmail;

