import { AddWantedService } from "../../screens/WantedService/AddWantedService/AddWantedService";
import { ArchiveWantedService } from "../../screens/WantedService/ArchiveWantedService/ArchiveWantedService";
import { DetailedWantedService } from "../../screens/WantedService/DetailedWantedService/DetailWantedService";
import { EditWantedService } from "../../screens/WantedService/EditWantedService/EditWantedService";

export const wantedServiceRoutes = [
    {
        path: "/wanted-services",
        element: <ArchiveWantedService />,
      },
      {
        path: "/add-wanted-service",
        element: <AddWantedService />,
      },
      {
        path: "/edit-wanted-service/:id/*",
        element: <EditWantedService />,
      },
      {
        path: "/wanted-service/:id/*",
        element: <DetailedWantedService />,
      },
]