import React, { useEffect, useRef, useState } from 'react';
import "./Radar.css"
import { Sidebar } from '../../components/Sidebar/Sidebar';
import { Navbar1 } from '../../components/Navbar1/Navbar1';
import { Footer } from '../../components/Footer/Footer';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { Loader } from '../../components/Loader';
import { decryptData, decryptErrorData } from '../../helper/AESHelper';
import { auth, app, getAuth, onAuthStateChanged, sendPasswordResetEmail, fetchSignInMethodsForEmail } from "../../screens/Login/FirebaseClient";

// import chart_data2x from '../../icons/Gerator/dashboard/-chart-data@2x.png';
// import arrowup from '../../icons/Gerator/dashboard/arrowup.svg';
// import arrowdown from '../../icons/Gerator/dashboard/arrowdown.svg';
// import chart_mini2x from '../../icons/Gerator/dashboard/-chart-mini@2x.png';
// import dotsvertical from '../../icons/Gerator/dashboard/dotsvertical.svg';
// import chart_mini1_2x from '../../icons/Gerator/dashboard/-chart-mini1@2x.png';
// import placeholder from '../../icons/Gerator/dashboard/placeholder.svg';
// import placeholder1 from '../../icons/Gerator/dashboard/placeholder1.svg';
// import placeholder2 from '../../icons/Gerator/dashboard/placeholder2.svg';
// import placeholder3 from '../../icons/Gerator/dashboard/placeholder3.svg';
// import upload04 from '../../icons/Gerator/dashboard/upload04.svg';
// import pluscircle from '../../icons/Gerator/dashboard/pluscircle.svg';
// import chevrondown1 from '../../icons/Gerator/dashboard/chevrondown1.svg';
// import calendar from '../../icons/Gerator/dashboard/calendar.svg';
// import filterlines from '../../icons/Gerator/dashboard/filterlines.svg';
// import arrowleft from '../../icons/Gerator/dashboard/arrowleft.svg';
// import arrowright from '../../icons/Gerator/dashboard/arrowright.svg';
// import markerpin02 from '../../icons/Gerator/dashboard/markerpin02.svg';
// import divider2 from '../../icons/Gerator/dashboard/divider2.svg';
// import calendar1 from '../../icons/Gerator/dashboard/calendar1.svg';
// import arrowsquareupright1 from '../../icons/Gerator/dashboard/arrowsquareupright1.svg';
// import arrowsdown from '../../icons/Gerator/dashboard/arrowsdown.svg';
// import chart_mini2_2x from '../../icons/Gerator/dashboard/-chart-mini2@2x.png';
// import content_elements_2x from '../../icons/Gerator/dashboard/content-elements@2x.png';
// import dot1 from '../../icons/Gerator/dashboard/-dot1.svg';
// import radar_1_1_2x from '../../icons/Gerator/dashboard/radar-1-1@2x.png';
// import deal_2_1_2x from '../../icons/Gerator/dashboard/deal-2-1@2x.png';
// import magnifier_1_2x from '../../icons/Gerator/dashboard/magnifier-1@2x.png';
// import group_58_2x from '../../icons/Gerator/dashboard/group-58@2x.png';
// import eye from '../../icons/Gerator/dashboard/eye.svg';
// import icon1 from '../../icons/Gerator/dashboard/icon1.svg';
// import lock04 from '../../icons/Gerator/dashboard/lock04.svg';
// import lockunlocked04 from '../../icons/Gerator/dashboard/lockunlocked04.svg';
// import arrowsquareupright from '../../icons/Gerator/dashboard/arrowsquareupright.svg';
// import tooltip from '../../icons/Gerator/dashboard/tooltip.svg';
// import group_212 from '../../icons/Gerator/dashboard/group-212.svg';
// import trash01 from '../../icons/Gerator/dashboard/trash01.svg';
// import icon2 from '../../icons/Gerator/dashboard/icon2.svg';
// import messagechatsquare from '../../icons/Gerator/dashboard/messagechatsquare.svg';
// import bifocal_optometry_measurement_device_2_2x from '../../icons/Gerator/dashboard/bifocal-optometry-measurement-device-2@2x.png';
// import uploadcloud02 from '../../icons/Gerator/dashboard/uploadcloud02.svg';
// import plus from '../../icons/Gerator/dashboard/plus.svg';
// import divider3 from '../../icons/Gerator/dashboard/divider3.svg';
// import help_icon from '../../icons/Gerator/dashboard/help-icon.svg';
// import f4b711e503bc72fe396e5043e0c2_11_2x from '../../icons/Gerator/dashboard/8595f4b711e503bc72fe396e5043e0c2-11@2x.png';
// import featured_icon from '../../icons/Gerator/dashboard/featured-icon.svg';
// import arrowdown1 from '../../icons/Gerator/dashboard/arrowdown1.svg';


import divider from '../../icons/Gerator/dashboard/divider.svg';
import divider1 from '../../icons/Gerator/dashboard/divider1.svg';
import homeline1 from '../../icons/Gerator/dashboard/homeline1.svg';
import chevronright from '../../icons/Gerator/dashboard/chevronright.svg';
import searchlg from '../../icons/Gerator/dashboard/searchlg.svg';
import _1_2x from '../../icons/Gerator/dashboard/8595f4b711e503bc72fe396e5043e0c2-1@2x.png';
import xclose from '../../icons/Gerator/dashboard/xclose.svg';
import { SideBarSubNormal } from '../../components/SideBars-Sub/SideBarSubNormal/SideBarSubNormal';
import { RadarDeviceComponent } from '../../components/Radar/RadarDeviceComponent/RadarDeviceComponent';
import { DashboardSpareComponent } from '../../components/Dashboard/DashboardSpareComponent/DashboardSpareComponent';
import { DashboardServiceComponent } from '../../components/Dashboard/DashboardServiceComponent/DashboardServiceComponent';
import { DashboardSoftwareComponent } from '../../components/Dashboard/DashboardSoftwareComponent/DashboardSoftwareComponent';
import { DashboardJobComponent } from '../../components/Dashboard/DashboardJobComponent/DashboardJobComponent';
import { Helmet } from 'react-helmet-async';
import { RadarSpareComponent } from '../../components/Radar/RadarSpareComponent/RadarSpareComponent';
import { RadarServiceComponent } from '../../components/Radar/RadarServiceComponent/RadarServiceComponent';
import { RadarSoftwareComponent } from '../../components/Radar/RadarSoftwareComponent/RadarSoftwareComponent';
import { RadarJobComponent } from '../../components/Radar/RadarJobComponent/RadarJobComponent';
import { RadarBusinessComponent } from '../../components/Radar/RadarBusinessComponent/RadarBusinessComponent';
// const auth = getAuth(app);


export const Radar = () => {
  const [currentUser, setCurrentUser] = useState("");
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState('device');

  const renderContent = () => {
    switch (activeTab) {
      case 'device':
        return <RadarDeviceComponent />;
      case 'spare':
        return <RadarSpareComponent />;
      case 'service':
        return <RadarServiceComponent />;
      case 'software':
        return <RadarSoftwareComponent />;
      case 'job':
        return <RadarJobComponent />;
      case 'business':
        return <RadarBusinessComponent />;
      default:
        return null;
    }
  };

  // const [triggerGetUser, { data, error, isLoading, isError }] = useLazyGetUserQuery();

  useEffect(() => {
    // const auth = getAuth();

    // Listen for authentication state change
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is authenticated
        setCurrentUser(user);
      } else {
        // User is not authenticated, redirect to login
        navigate('/sign-in', { state: { message: 'Please log in to continue.' } });
      }
    });

    return () => unsubscribe(); // Cleanup listener on component unmount
  }, [navigate]);




  if (currentUser) {
    return (
      <div className="radar-radar-frame-layout">
        <Helmet>
          <title>Radar</title>
        </Helmet>
        <div className="radar-radar-frame-layout-child"></div>

        <Navbar1 userData={currentUser} navigate={navigate} />

        <div className="radar-all">
          <img className="radar-divider-icon" alt="" src={divider} />

          <div className="radar-all-content">

            <Sidebar>
              <SideBarSubNormal userData={currentUser} />
            </Sidebar>

            <div className="radar-frame-parent">
              <div className="radar-main-wrapper">
                <div className="radar-main radar-main-custom">
                  <div className="radar-header-section">
                    <div className="radar-container">
                      <div className="radar-page-header">
                        <div className="radar-breadcrumbs">
                          <div className="radar-tabs">
                            <div className="radar-breadcrumb-button-base">
                              <img
                                className="radar-search-lg-icon"
                                alt=""
                                src={homeline1}
                              />
                            </div>
                            <img
                              className="radar-chevron-right-icon"
                              alt=""
                              src={chevronright}
                            />

                            <div className="radar-breadcrumb-button-base1">
                              <div className="radar-location">Radar</div>
                            </div>
                            {/* <img
                              className="radar-chevron-right-icon"
                              alt=""
                              src={chevronright}
                            /> */}

                            {/* <div className="radar-breadcrumb-button-base2">
                              <div className="radar-text20">...</div>
                            </div>
                            <img
                              className="radar-chevron-right-icon2"
                              alt=""
                              src={chevronright}
                            />

                            <div className="radar-breadcrumb-button-base3">
                              <div className="radar-text21">Another link</div>
                            </div>
                            <img
                              className="radar-chevron-right-icon2"
                              alt=""
                              src={chevronright}
                            />

                            <div className="radar-breadcrumb-button-base4">
                              <div className="radar-text22">Overview</div>
                            </div> */}
                          </div>
                        </div>
                        <div className="radar-content13">
                          <div className="radar-text-and-supporting-text">
                            <div className="radar-heading"> {currentUser.displayName},here are the listings you seem to have liked.</div>
                            {/* <div className="radar-supporting-text1">
                              You are on Krypton Plan, expiring in 23 days.
                            </div> */}
                          </div>
                        </div>
                        <img
                          className="radar-divider-icon1"
                          alt=""
                          src={divider1}
                        />
                      </div>
                      {/* <div className="radar-tabs-and-filters">
                        <div className="radar-button-group">
                          <div className="radar-button-group-base">
                            <div className="radar-text31">12 months</div>
                          </div>
                          <div className="radar-button-group-base1">
                            <div className="radar-text32">30 days</div>
                          </div>
                          <div className="radar-button-group-base2">
                            <div className="radar-text29">7 days</div>
                          </div>
                          <div className="radar-button-group-base3">
                            <div className="radar-text34">Text</div>
                          </div>
                          <div className="radar-button-group-base4">
                            <div className="radar-text34">Text</div>
                          </div>
                          <div className="radar-button-group-base5">
                            <div className="radar-text34">Text</div>
                          </div>
                          <div className="radar-button-group-base6">
                            <div className="radar-text34">Text</div>
                          </div>
                          <div className="radar-button-group-base7">
                            <div className="radar-text34">Text</div>
                          </div>
                          <div className="radar-button-group-base8">
                            <div className="radar-text39">24 hours</div>
                          </div>
                        </div>
                        <div className="radar-actions2">
                          <div className="radar-date-picker-dropdown">
                            <div className="radar-buttonsbutton1">
                              <img
                                className="radar-upload-04-icon"
                                alt=""
                                src={calendar}
                              />

                              <div className="radar-text-padding6">
                                <div className="radar-filter-date">Select dates</div>
                              </div>
                              <img
                                className="radar-placeholder-icon"
                                alt=""
                                src={placeholder2}
                              />
                            </div>
                          </div>
                          <div className="radar-buttonsbutton2">
                            <img
                              className="radar-upload-04-icon"
                              alt=""
                              src={filterlines}
                            />

                            <div className="radar-text-padding6">
                              <div className="radar-filter-options">Filters</div>
                            </div>
                            <img
                              className="radar-placeholder-icon"
                              alt=""
                              src={placeholder2}
                            />
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                  {/* <div className="radar-line-and-bar-chart-wrapper">
                    <div className="radar-line-and-bar-chart">
                      <div className="radar-line-and-bar-chart">
                        <div className="radar-content14">
                          <div className="radar-legend">
                            <div className="radar-legend-series">
                              <div className="radar-legend-color">
                                <div className="radar-color"></div>
                              </div>
                              <div className="radar-series-1">2022</div>
                            </div>
                            <div className="radar-legend-series">
                              <div className="radar-legend-color">
                                <div className="radar-color1"></div>
                              </div>
                              <div className="radar-series-2">2021</div>
                            </div>
                            <div className="radar-legend-series">
                              <div className="radar-legend-color">
                                <div className="radar-color2"></div>
                              </div>
                              <div className="radar-series-1">2020</div>
                            </div>
                            <div className="radar-legend-series3">
                              <div className="radar-color3"></div>
                              <div className="radar-series-4">Series 4</div>
                            </div>
                            <div className="radar-legend-series4">
                              <div className="radar-color4"></div>
                              <div className="radar-series-5">Series 5</div>
                            </div>
                            <div className="radar-legend-series3">
                              <div className="radar-color5"></div>
                              <div className="radar-series-4">Series 6</div>
                            </div>
                          </div>
                          <div className="radar-y-axis">
                            <div className="radar-y-axis-line">
                              <div className="radar-divider"></div>
                            </div>
                            <div className="radar-y-axis-line1">
                              <div className="radar-divider"></div>
                            </div>
                            <div className="radar-y-axis-line1">
                              <div className="radar-divider"></div>
                            </div>
                            <div className="radar-y-axis-line1">
                              <div className="radar-divider"></div>
                            </div>
                            <div className="radar-y-axis-line1">
                              <div className="radar-divider"></div>
                            </div>
                            <div className="radar-y-axis-line1">
                              <div className="radar-divider"></div>
                            </div>
                            <div className="radar-y-axis-line6">
                              <div className="radar-divider6"></div>
                            </div>
                            <div className="radar-y-axis-line6">
                              <div className="radar-divider6"></div>
                            </div>
                            <div className="radar-y-axis-line6">
                              <div className="radar-divider6"></div>
                            </div>
                            <div className="radar-y-axis-line6">
                              <div className="radar-divider6"></div>
                            </div>
                            <div className="radar-y-axis-line6">
                              <div className="radar-divider6"></div>
                            </div>
                            <div className="radar-y-axis-line6">
                              <div className="radar-divider6"></div>
                            </div>
                          </div>
                          <div className="radar-x-axis">
                            <div className="radar-jan">Jan</div>
                            <div className="radar-jan">Feb</div>
                            <div className="radar-mar">Mar</div>
                            <div className="radar-jan">Apr</div>
                            <div className="radar-may">May</div>
                            <div className="radar-jun">Jun</div>
                            <div className="radar-jul">Jul</div>
                            <div className="radar-mar">Aug</div>
                            <div className="radar-mar">Sep</div>
                            <div className="radar-jan">Oct</div>
                            <div className="radar-mar">Nov</div>
                            <div className="radar-mar">Dec</div>
                          </div>
                          <img
                            className="radar-chart-data-icon"
                            alt=""
                            src={chart_data2x}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="radar-section">
                    <div className="radar-container1">
                      <div className="radar-metric-group">
                        <div className="radar-metric-item">
                          <div className="radar-heading1">Today’s revenue</div>
                          <div className="radar-number-and-chart">
                            <div className="radar-number-and-badge">
                              <div className="radar-number">$1,280</div>
                              <div className="radar-change-and-text">
                                <div className="radar-change">
                                  <img
                                    className="radar-upload-04-icon"
                                    alt=""
                                    src={arrowup}
                                  />

                                  <div className="radar-change1">15%</div>
                                </div>
                                <div className="radar-change-duration">last mth</div>
                              </div>
                            </div>
                            <img
                              className="radar-chart-mini-icon"
                              alt=""
                              src={chart_mini2x}
                            />
                          </div>
                          <div className="radar-dropdown">
                            <img
                              className="radar-dots-vertical-icon"
                              alt=""
                              src={dotsvertical}
                            />
                          </div>
                        </div>
                        <div className="radar-metric-item">
                          <div className="radar-heading1">Today’s orders</div>
                          <div className="radar-number-and-chart">
                            <div className="radar-number-and-badge">
                              <div className="radar-heading">14</div>
                              <div className="radar-change-and-text1">
                                <div className="radar-change2">
                                  <img
                                    className="radar-upload-04-icon"
                                    alt=""
                                    src={arrowdown}
                                  />

                                  <div className="radar-change1">10%</div>
                                </div>
                                <div className="radar-change-duration">last mth</div>
                              </div>
                            </div>
                            <img
                              className="radar-chart-mini-icon"
                              alt=""
                              src={chart_mini1_2x}
                            />
                          </div>
                          <div className="radar-dropdown">
                            <img
                              className="radar-dots-vertical-icon"
                              alt=""
                              src={dotsvertical}
                            />
                          </div>
                        </div>
                        <div className="radar-metric-item">
                          <div className="radar-heading1">Avg. order value</div>
                          <div className="radar-number-and-chart">
                            <div className="radar-number-and-badge">
                              <div className="radar-number">$91.42</div>
                              <div className="radar-change-and-text">
                                <div className="radar-change">
                                  <img
                                    className="radar-upload-04-icon"
                                    alt=""
                                    src={arrowup}
                                  />

                                  <div className="radar-change-amount">20%</div>
                                </div>
                                <div className="radar-change-duration">last mth</div>
                              </div>
                            </div>
                            <img
                              className="radar-chart-mini-icon"
                              alt=""
                              src={chart_mini2x}
                            />
                          </div>
                          <div className="radar-dropdown">
                            <img
                              className="radar-dots-vertical-icon"
                              alt=""
                              src={dotsvertical}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}

                </div>
              </div>
              {/* <div className="radar-separator"></div>
              <div className="radar-separator1"></div> */}
              <div className="radar-main-wrapper">
                <div className="radar-page-header-wrapper">
                  <div className="radar-page-header1">
                    <div className="radar-breadcrumbs1">
                      <div className="radar-tabs1">
                        <div className="radar-breadcrumb-button-base">
                          <img
                            className="radar-search-lg-icon"
                            alt=""
                            src={homeline1}
                          />
                        </div>
                        <img
                          className="radar-chevron-right-icon"
                          alt=""
                          src={chevronright}
                        />

                        <div className="radar-breadcrumb-button-base6">
                          <div className="radar-text42">Settings</div>
                        </div>
                        <img
                          className="radar-chevron-right-icon"
                          alt=""
                          src={chevronright}
                        />

                        <div className="radar-breadcrumb-button-base7">
                          <div className="radar-text20">...</div>
                        </div>
                        <img
                          className="radar-chevron-right-icon"
                          alt=""
                          src={chevronright}
                        />

                        <div className="radar-breadcrumb-button-base3">
                          <div className="radar-text21">Another link</div>
                        </div>
                        <img
                          className="radar-chevron-right-icon2"
                          alt=""
                          src={chevronright}
                        />

                        <div className="radar-breadcrumb-button-base4">
                          <div className="radar-text34">Team</div>
                        </div>
                      </div>
                    </div>
                    <div className="radar-content15">
                      {/* <div className="radar-text-and-supporting-text1">
                        <div className="radar-text46">Your Listings</div>
                        <div className="radar-supporting-text2">
                          Manage your team members and their account permissions
                          here.
                        </div>
                      </div> */}
                      <div className="radar-input-dropdown1">
                        <div className="radar-input-with-label1">
                          <div className="radar-label">Search</div>
                          <div className="radar-input1">
                            <div className="radar-content16">
                              <img
                                className="radar-search-lg-icon"
                                alt=""
                                src={searchlg}
                              />

                              <div className="radar-text47">Search</div>
                            </div>
                          </div>
                        </div>
                        <div className="radar-hint-text1">
                          This is a hint text to help user.
                        </div>
                      </div>
                    </div>
                    <img className="radar-divider-icon1" alt="" src={divider1} />
                  </div>
                </div>
                {/* <div className="radar-supporting-text-wrapper">
                  <div className="radar-supporting-text3">
                    Line 1 about subscription plan. Plan expiry date.
                  </div>
                </div> */}
                <div className="radar-horizontal-tabs-wrapper">
                  <div className="radar-horizontal-tabs">
                    <div className="radar-tabs2">
                      <div className={activeTab === 'device' ? "radar-tab-button-base" : "radar-tab-button-base1"}>
                        <div className="radar-tab-name" style={{ cursor: "pointer" }} onClick={() => setActiveTab('device')}>Devices</div>
                      </div>
                      <div className={activeTab === 'spare' ? "radar-tab-button-base" : "radar-tab-button-base1"}>
                        <div className="radar-text48" style={{ cursor: "pointer" }} onClick={() => setActiveTab('spare')}>Spares</div>
                      </div>
                      <div className={activeTab === 'service' ? "radar-tab-button-base" : "radar-tab-button-base1"}>
                        <div className="radar-text49" style={{ cursor: "pointer" }} onClick={() => setActiveTab('service')}>Service</div>
                      </div>
                      <div className={activeTab === 'software' ? "radar-tab-button-base" : "radar-tab-button-base1"}>
                        <div className="radar-text50" style={{ cursor: "pointer" }} onClick={() => setActiveTab('software')}>Software</div>
                      </div>
                      <div className={activeTab === 'job' ? "radar-tab-button-base" : "radar-tab-button-base1"}>
                        <div className="radar-text51" style={{ cursor: "pointer" }} onClick={() => setActiveTab('job')}>Jobs</div>
                      </div>
                      <div className={activeTab === 'business' ? "radar-tab-button-base" : "radar-tab-button-base1"}>
                        <div className="radar-text51" style={{ cursor: "pointer" }} onClick={() => setActiveTab('business')}>Businesses</div>
                      </div>
                      <div className="radar-tab-button-base5">
                        <div className="radar-text52">Billing</div>
                      </div>
                      <div className="radar-tab-button-base5">
                        <div className="radar-text52">Email</div>
                      </div>
                      <div className="radar-tab-button-base7">
                        <div className="radar-text52">Notifications</div>
                        <div className="radar-badge10">
                          <div className="radar-text55">2</div>
                        </div>
                      </div>
                      <div className="radar-tab-button-base5">
                        <div className="radar-text52">Integrations</div>
                      </div>
                      <div className="radar-tab-button-base9">
                        <div className="radar-text52">API</div>
                      </div>
                    </div>
                  </div>
                </div>



                {renderContent()}



              </div>





            </div>
          </div>
        </div>
        <img className="radar-divider-icon6" alt="" src={divider} />

        <Footer />

      </div>
    )
  }
  else {
    return <Loader />
  }


}

