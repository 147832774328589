

import React from 'react';
import "./EditSpareStep3.css";
import divider1 from '../../icons/Gerator/add_form/divider1.svg';
import chevronleft from '../../icons/Gerator/add_form/chevronleft.svg';
import placeholder3 from '../../icons/Gerator/add_form/placeholder3.svg';
import featured_icon from '../../icons/Gerator/add_form/featured-icon.svg';
import placeholder1 from '../../icons/Gerator/add_form/placeholder1.svg';
import placeholder2 from '../../icons/Gerator/add_form/placeholder2.svg';
import placeholder3 from '../../icons/Gerator/add_form/placeholder3.svg';
import placeholder from '../../icons/Gerator/add_form/placeholder.svg';
import page from '../../icons/Gerator/add_form/page.svg';
import checkbox_base from '../../icons/Gerator/add_form/-checkbox-base.svg';
import eye from '../../icons/Gerator/add_form/eye.svg';
import login04 from '../../icons/Gerator/add_form/login04.svg';
import page from '../../icons/Gerator/add_form/page.svg';
import page from '../../icons/Gerator/add_form/page.svg';
import { updateEditSpareField } from "./EditSpareSlice";
import { updateAppDataEditSpare } from "./AppDataEditSpareSlice";
import EditSpareFormContext from "../../screens/Spare/EditSpare/EditSpareContext";
import { isValidURL } from "../../helper/SpareHelper";
import { Link } from "react-router-dom";
import { Field, Formik, useField, useFormikContext } from "formik";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const DocumentFileUpload = ({ validate, ...props }) => {
  const [field, meta] = useField(props);
  let fieldValidationData = "";
  if (validate?.length > 0) {
    validate.some(field => { // Use 'some' for early termination
      if (field.path === "documentFileObject") {
        fieldValidationData = field;
        return true; // This will stop the loop early
      }
      return false; // Continue if no match
    });
  }

  return (

    <div className='document-field-component-width'>
      <div className="add-service-step3-label58 ">
        Documents
      </div>
      {/* <div className="add-service-step3-label58"></div> */}
      <div className="add-service-step3-file-upload-base5">
        <label htmlFor="document">

          <div className="add-service-step3-content69">
            <img

              className="add-service-step3-featured-icon5"
              loading="lazy"
              alt=""
              src={featured_icon}
            />

            <div className="add-service-step3-text-and-supporting-text13" htmlFor="document">
              <div className="add-service-step3-action5">
                <div className="add-service-step3-buttonsbutton16">
                  {/* <img 
                  className="add-service-step3-placeholder-icon60"
                  alt=""
                  src={placeholder1}
                /> */}
                  <div className="add-service-step3-text88 documentLabel">Click to upload</div>
                  <input type="file" accept=".pdf" {...field} {...props} className='hide-input-field' />
                </div>
              </div>
              <div className="action custom-action">

                <div className="add-service-step3-text89">or drag and drop</div>
              </div>
              <div className="add-service-step3-supporting-text28">
                <span>Please upload files having extension </span>
                <span className="add-service-step3-pdf1">PDF</span>
                <span> only (Maximum file size.10 MB)</span>
              </div>
            </div>
          </div>
        </label>
        {(meta.touched && meta.error) ? (
          <div className="error-for-all-field error-for-document">{meta.error}</div>
        ) : null}

        {fieldValidationData !== "" ? (
          <div className="error-for-all-field error-for-document">{fieldValidationData.msg}</div>
        ) : null}
      </div>


    </div>
  );
};

const MyTextAreaNotMandatory = ({ validate, label, ...props }) => {
  const [field, meta] = useField(props);
  let fieldValidationData = null;
  if (validate?.length > 0) {
    validate.some(field => { // Use 'some' for early termination
      if (field.path === props.name) {
        fieldValidationData = field;
        return true; // This will stop the loop early
      }
      return false; // Continue if no match
    });
  }

  return (
    <>
      {/* <label className="label">Model Number</label> */}
      <div className="add-service-step3-input-with-label42">
        <div>
          <label className="add-service-step3-label47" htmlFor={props.id || props.name}>
            {label}
            {/* <span className="add-service-step-span">*</span> */}
          </label>

        </div>
        <div className="add-service-step3-content62">
          <div className="add-service-step3-textarea-input-field7">
            <div className="add-service-step3-input-with-label43">
              {/* <div className="add-service-step3-label46">Description</div> */}
              <div className="add-service-step3-input31">
                <textarea
                  className="add-service-step3-text82"
                  // style={inputStyle}  //New code for border colour
                  {...field}
                  {...props}

                />
              </div>
            </div>

          </div>
        </div>
      </div>

      {(meta.touched && meta.error) ? (
        <div className="error-for-all-field">{meta.error}</div>
      ) : null}

      {fieldValidationData !== null ? (
        <div className="error-for-all-field">{fieldValidationData.msg}</div>
      ) : null}

    </>
  );
};




export const EditSpareStep3 = () => {
  
  const dispatch = useDispatch();
  const formData = useSelector((state) => state.editSpare);
  const appData = useSelector((state) => state.appDataEditSpare);
  const {setDocumentFileToDelete, backendValidation, documentCheckboxError, setDocumentCheckboxError, selectedDocumentFile, setSelectedDocumentFile } = useContext(EditSpareFormContext);

  const { values, setFieldValue, setErrors, setFieldTouched, errors, touched, handleSubmit, isSubmitting } = useFormikContext();

  /* 
      This useEffect will provide updated values from formik and run some conditions Used on FeatureImage
  */
  useEffect(() => {
    console.log("Values useEffect Open");

    if (touched.document) {
      if (errors.document && values.document !== "") {
        console.log("Values Error UseEffect");
        handleSelectedDocumentFile("error");
        return; // Exit useEffect to avoid further actions if there are errors
      }

      // 2. Proceed with Updates if No Errors:
      if (touched.document && values.document != "") {
        console.log("Values Touch UseEffect");
        // setGalleryImageFile([...values.gallery]);
        handleSelectedDocumentFile("no-error");
      }
    }
    // 1. Check for Errors FIRST:

    console.log("Values useEffect Closed ");
    // }

  }, [values.document, errors.document]);




  const prev = () => dispatch(updateAppDataEditSpare({ case: "CURRENTSTEP", value: (appData.currentStep - 1) }));

  // const next = () => {
  //   dispatch(updateAppDataEditSpare({ case: "CURRENTSTEP", value: 0 }));
  // };


  const handleCheckbox = async () => {

    //If errors.document has some errors then filter then set document to "", clear other field as well
    if (errors.document) {
      console.log(errors.document);
      const filteredErrors = Object.keys(errors).reduce((acc, fieldName) => {
        // Filter based on your condition (replace with your actual logic)
        if (fieldName !== 'document') {
          acc[fieldName] = errors[fieldName];
        }
        return acc;
      }, {});
      console.log(filteredErrors);
      await setFieldValue("document", '');
      setErrors(filteredErrors)
      setSelectedDocumentFile("")
      setDocumentCheckboxError('');
    } else {
      setDocumentFileToDelete(values.documentFile);
      await setFieldValue("document", '');
      setSelectedDocumentFile("")
      setDocumentCheckboxError('');
    }


  }

  const handleSelectedDocumentFile = (type) => {
    console.log("HandleSelectedImageFile Called " + type);

    setFieldTouched("document", true); // Always touch the field for validation

    console.log(errors);

    // 3. Additional Error Check:
    if (errors.document) {
      console.log("Inside errors.gallery is true means error found ");
      setSelectedDocumentFile(values.document);
      setDocumentCheckboxError(errors.document);
      return; // Don't proceed with upload if there's an error
    }

    // 4. Proceed with Upload if No Errors:
    console.log("Inside !errors.gallery is true means no error found");
    console.log("File added into select multi image hook");
    setSelectedDocumentFile(values.document);
    setDocumentCheckboxError('');
    // setFileDocLinkRemover(true);
  }
  const handleChange = (event) => {
    const { name, value } = event.target;
    console.log("called");
    if (name === "document") {
      const pdf = event.target.files[0]
      console.log(pdf);
      //updating document in formik object with file object
      setFieldValue(name, pdf);
      // updating documentLink, to indicate this file needs to be remove
      setFieldValue("documentFile", '');
      setDocumentFileToDelete("");
      //Touching field so formik will be aware featureImage has been changed
      setFieldTouched(name, true)
      return;
    }
    setFieldValue(name, value);
    dispatch(updateEditSpareField({ field: name, value }));
  };

  return (
     <>
      <div className="add-spare-step-section-header-parent">
        <div className="add-spare-step3-add-spare-step-3">
          <div className="add-spare-step3-hint-text46">This is a hint text to help user.</div>
          <main className="add-spare-step3-section-header-group">
            <div className="add-spare-step3-section-header7">
              <div className="add-spare-step3-content61">
                Description
              </div>


              <img className="add-spare-step3-divider-icon14" alt="" src={divider1} />
            </div>

            <section className="add-spare-step3-input-field35">
              <MyTextAreaNotMandatory
                label="List Parent Devices Compatible With This Part / Spare"
                name="parentDeviceCompatible"                                                          //New Code
                id="parentDeviceCompatible"                                                          //New Code
                type="text"
                validate={backendValidation}
                onChange={handleChange}
                value={formData.parentDeviceCompatible}
                placeholder="1000 characters"
              />

            </section>

            <section className="add-spare-step3-frame-section">
              <MyTextAreaNotMandatory
                label="Hardware"
                name="hardware"                                                          //New Code
                id="hardware"                                                          //New Code
                onChange={handleChange}
                value={formData.hardware}
                type="text"
                placeholder="1000 characters"
              />

            </section>
            <section className="add-spare-step3-input-field35">
              <MyTextAreaNotMandatory
                label="Software / Upgrades / OS / Applications / Worklist"
                name="softwareUpgraadesOsApplicationsWorklist"                                                          //New Code
                id="softwareUpgraadesOsApplicationsWorklist"                                                          //New Code
                onChange={handleChange}
                value={formData.softwareUpgraadesOsApplicationsWorklist}
                type="text"
                placeholder="1000 characters"
              />
            </section>
            <section className="add-spare-step3-input-field35">
              <MyTextAreaNotMandatory
                label="Service History"
                name="serviceHistory"                                                          //New Code
                id="serviceHistory"                                                          //New Code
                type="text"
                onChange={handleChange}
                value={formData.serviceHistory}
                placeholder="1000 characters"
              />
            </section>
            <section className="add-spare-step3-input-field35">
              <MyTextAreaNotMandatory
                label="Warranty"
                name="warrantyInput"                                                          //New Code
                id="warrantyInput"                                                          //New Code
                type="text"
                onChange={handleChange}
                value={formData.warrantyInput}
                placeholder="1000 characters"
              />
            </section>

            <section className="add-spare-step3-input-field35">
              <MyTextAreaNotMandatory
                label="Packing List"
                name="packingList"                                                          //New Code
                id="packingList"                                                          //New Code
                type="text"
                onChange={handleChange}
                value={formData.packingList}
                placeholder="1000 characters"
              />

            </section>
            <section className="add-spare-step3-input-field35">
              <MyTextAreaNotMandatory
                label="Additional Information"
                name="additionalInformation"                                                          //New Code
                id="additionalInformation"                                                          //New Code
                type="text"
                onChange={handleChange}
                value={formData.additionalInformation}
                placeholder="2000 characters"
              />

            </section>
            <div className="file-upload file-upload-custom-width ">
              <DocumentFileUpload key={selectedDocumentFile ? selectedDocumentFile.name : Date.now()} name="document" validate={backendValidation} id="document" onChange={handleChange} value=
                {undefined} />


              {
                (typeof selectedDocumentFile === 'object' && selectedDocumentFile !== "") &&

                (
                  <div className="add-service-step3-file-upload-item-base3">
                    <div className="add-service-step3-file-type-icon-parent">
                      <div className="add-service-step3-file-type-icon3">
                        <img
                          className="add-service-step3-page-icon3"
                          loading="lazy"
                          alt=""
                          src={page}
                        />

                        <div className="add-service-step3-file-type-wrap3">
                          <b className="add-service-step3-file-type3">PDF</b>
                        </div>
                      </div>
                      <div className="add-service-step3-frame-parent7">
                        <div className="add-service-step3-text-parent1">
                          <div className="add-service-step3-text90">{selectedDocumentFile.name}</div>
                          <div className="add-service-step3-checkbox3">
                            {documentCheckboxError ?
                              <Field type="checkbox" onClick={handleCheckbox} name="imageDocCheckbox" checked className="checkbox-instance custom-checkbox"></Field> :
                              <Field type="checkbox" onClick={handleCheckbox} name="imageDocCheckbox" checked className="checkbox-instance checkbox-base size-30-sm state-8-default checked-true type-checkbox checkbox-2"></Field>
                            }
                          </div>
                        </div>
                        <div className="add-service-step3-supporting-text29">{(selectedDocumentFile.size / 1000).toFixed(1) + " KB"}</div>
                      </div>
                    </div>
                    <div className="add-service-step3-progress-bar8">
                      <div className="add-service-step3-progress-bar-wrapper">
                        <div className="add-service-step3-progress-bar9">
                          <div className="add-service-step3-background3"></div>
                          <div className="add-service-step3-progress3"></div>
                        </div>
                      </div>
                      <div className="add-service-step3-percentage3">100%</div>
                    </div>
                  </div>
                )
              }

              {
                (isValidURL(values.document)) &&

                (
                  <Link to={values.document} target="_blank" rel="noopener noreferrer" style={{maxWidth:"inherit"}}>
                    <div className="add-service-step3-file-upload-item-base3">
                      <div className="add-service-step3-file-type-icon-parent">
                        <div className="add-service-step3-file-type-icon3">
                          <img
                            className="add-service-step3-page-icon3"
                            loading="lazy"
                            alt=""
                            src={page}
                          />

                          <div className="add-service-step3-file-type-wrap3">
                            <b className="add-service-step3-file-type3">PDF</b>
                          </div>
                        </div>
                        <div className="add-service-step3-frame-parent7">
                          <div className="add-service-step3-text-parent1">
                            <div className="add-service-step3-text90">{values.document_file.file_name}</div>
                            <div className="add-service-step3-checkbox3">
                              {documentCheckboxError ?
                                <Field type="checkbox" onClick={handleCheckbox} name="imageDocCheckbox" checked className="checkbox-instance custom-checkbox"></Field> :
                                <Field type="checkbox" onClick={handleCheckbox} name="imageDocCheckbox" checked className="checkbox-instance checkbox-base size-30-sm state-8-default checked-true type-checkbox checkbox-2"></Field>
                              }
                            </div>
                          </div>
                          <div className="add-service-step3-supporting-text29">{(values.document_file.file_size / 1000).toFixed(1) + " KB"}</div>
                        </div>
                      </div>
                      <div className="add-service-step3-progress-bar8">
                        <div className="add-service-step3-progress-bar-wrapper">
                          <div className="add-service-step3-progress-bar9">
                            <div className="add-service-step3-background3"></div>
                            <div className="add-service-step3-progress3"></div>
                          </div>
                        </div>
                        <div className="add-service-step3-percentage3">100%</div>
                      </div>
                    </div>
                  </Link>
                )
              }
            </div>
           
            <div className="add-spare-step3-section-footer7">
              <div className="add-spare-step3-section-footer8">
                <img
                  className="add-spare-step3-divider-icon14"
                  loading="lazy"
                  alt=""
                  src={divider1}
                />

                <div className="add-spare-step3-content70 add-job-step-content31-custom">
                
                  <div className="add-spare-step3-actions6 add-service-step3-actions6-custom">
                   


                    <button className="add-spare-step3-button21" type="button" onClick={prev}>
                      <img
                        className="add-spare-step3-placeholder-icon66"
                        alt=""
                        src={placeholder2}
                      />

                      <img
                        className="add-spare-step3-chevron-left-icon3"
                        alt=""
                        src={chevronleft}
                      />

                      <div className="add-spare-step3-text-padding25">
                        <div className="add-spare-step3-text93">Back</div>
                      </div>
                      <img
                        className="add-spare-step3-placeholder-icon66"
                        alt=""
                        src={placeholder2}
                      />
                    </button>
                    <button className="add-spare-step3-button22">
                      <img
                        className="add-spare-step3-placeholder-icon66"
                        alt=""
                        src={placeholder3}
                      />

                      <img
                        className="add-spare-step3-chevron-left-icon3"
                        alt=""
                        src={eye}
                      />

                      <div className="add-spare-step3-text-padding25">
                        <a className="add-spare-step3-text94">Preview</a>
                      </div>
                      <img
                        className="add-spare-step3-placeholder-icon66"
                        alt=""
                        src={placeholder3}
                      />
                    </button>
                    <button className="add-spare-step3-button23" type="submit" onClick={handleSubmit} disabled={isSubmitting}>
                      <img
                        className="add-spare-step3-placeholder-icon66"
                        alt=""
                        src={placeholder3}
                      />

                      <img
                        className="add-spare-step3-chevron-left-icon3"
                        alt=""
                        src={login04}
                      />

                      <div className="add-spare-step3-text-padding25">
                        <div className="add-spare-step3-text95">{isSubmitting ? 'Submitting...' : 'Save & Submit'}</div>
                      </div>
                      <img
                        className="add-spare-step3-placeholder-icon66"
                        alt=""
                        src={placeholder3}
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
   
  )
}


