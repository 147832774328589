import React, { useEffect, useState } from 'react';
import "./AccountEmailVerified.css";
import { Link, useNavigate } from 'react-router-dom';
import { BackToLoginButton } from '../../../components/BackToLoginButton/BackToLoginButton';
import { AccountSetupButton } from '../../../components/AccountSetupButton/AccountSetupButton';
import gerator_logo from '../../../icons/Gerator/gerator04-1@2x.png';
import feature_icon from '../../../icons/Gerator/account_setup/featured-icon.svg';
import feature_icon1 from '../../../icons/Gerator/account_setup/featured-icon-1.svg';
import feature_icon2 from '../../../icons/Gerator/account_setup/featured-icon-2.svg';
import feature_icon3 from '../../../icons/Gerator/account_setup/featured-icon-3.svg';
import feature_icon4 from '../../../icons/Gerator/account_setup/featured-icon-4.svg';
import placeholder2 from '../../../icons/Gerator/account_setup/placeholder2.svg';
import mail_icon from '../../../icons/Gerator/account_setup/mail01-1.svg';
import { firestore, doc, getDoc, auth, onAuthStateChanged, applyActionCode, checkActionCode, sendEmailVerification } from "../../Login/FirebaseClient";
import { useLazyGetUserQuery, useLazySetEmailVerifyQuery } from '../../../api/Login&RegisterAPI';
import { decryptData } from '../../../helper/AESHelper';
import { Helmet } from 'react-helmet-async';
import { Loader } from '../../../components/Loader';
import support_icon from "../../../icons/Gerator/support_24dp_5F6368_FILL0_wght400_GRAD0_opsz24.svg";


// const sendVerificationEmail = async (user,setShowSuccessMessage) => {
//   try {
//     // const auth = getAuth();
//     // const user = auth.currentUser;

//     if (user) {
//       await sendEmailVerification(user);
//       console.log('Verification email sent.');
//       setShowSuccessMessage({ message: 'Verification email sent.', color: "green" });
//     } else {
//       navigate("/sign-in", { state: { message: "User not authorized." } });
//       // setShowSuccessMessage({ message: 'User not authorized.', color: "red" });
//       // console.error('User not authorized.');
//     }
//   } catch (error) {
//     // navigate("/sign-in", { state: { message: "User not authorized." } });
//     setShowSuccessMessage({ message: 'Error sending verification email.', color: "green" });
//     console.error('Error sending verification email:', error);
//   }
// };



export const AccountEmailVerified = () => {
  const navigate = useNavigate();
  const [emailError, setEmailError] = useState(false);
  // const [isInternalServerError, setIsIntervalServerError] = useState(false);
  // const [validateToken, setValidateToken] = useState('');
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [currentUsermeta, setCurrentUsermeta] = useState(null);
  const [showSuccessMessage, setShowSuccessMessage] = useState({
    message: "",
    color: "",
  });
  // const [triggerGetUser, { data, isLoading }] = useLazyGetUserQuery();

  const [triggerSetEmailVerify, { data: dataEmailVerify, isLoading: isGettingEmailVerify, isError: isGetEmailVerifyError, error: getEmailVerifyError }] = useLazySetEmailVerifyQuery();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        // const result = await triggerGetUser().unwrap();
        // Get user to check wizardStep
        const userDocRef = doc(firestore, "users", user.uid);
        const userDocSnapshot = await getDoc(userDocRef);

        if (userDocSnapshot.exists()) {
          const usermeta = userDocSnapshot.data(); // Default to 1 if not set
          setCurrentUsermeta(usermeta);
          console.log("Wizard Step:", usermeta.wizardStep);
        } else {
          console.log("User document does not exist in Firestore");
          navigate("/sign-in", { state: { message: "There is an issue when verifying" } });
        }

        // sendVerificationEmail(user);
      } else {
        navigate("/sign-in", { state: { message: "User not authorized" } });
      }
    });

    return () => unsubscribe(); // Clean up subscription on unmount
  }, [navigate]);


  useEffect(() => {

    if (currentUsermeta != null) {
      switch (currentUsermeta.wizardStep) {
        case 0:
          navigate('/dashboard');
          break;
        // case 1:
        //   navigate('/account-setup-check-email');
        //   break;
        case 2:
          navigate('/account-setup-update-profile');
        case 3:
          navigate('/account-setup-subscription-plan');
          break;
      }


      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get('validateToken');
      verifyEmailCode(auth, code);
      // setUserDetails(user);
    }

  }, [currentUsermeta]);


  const verifyEmailCode = async (auth, oobCode) => {
    try {
      // Check if the action code is valid
      const info = await checkActionCode(auth, oobCode);

      // If valid, apply the action code to verify the email
      await applyActionCode(auth, oobCode);

      // Trigger any additional actions after successful verification
      const state = await triggerSetEmailVerify();
      if (state.isSuccess) {
        setIsEmailVerified(true);
      }
      console.log(state);
    } catch (error) {
      if (error.code) {
        console.log(error.code);

        switch (error.code) {
          case 'auth/invalid-action-code':
            setEmailError(true);
            // setShowSuccessMessage({ message: 'Invalid verification code.', color: "red" });
            break;
          case 'auth/internal-error':
            // setIsIntervalServerError(true);
            setShowSuccessMessage({ message: 'Internal server error.', color: "red" });
            break;
          default:
            setShowSuccessMessage({ message: 'An unknown error occurred. Please try again later.', color: "red" });
        }
      } else {
        const decryptedData = decryptData(error.data);
        console.log(decryptedData);
        setShowSuccessMessage({ message: decryptedData.message, color: "red" });
      }
    };



    //   if (user) {
    //     await sendEmailVerification(user);
    //     console.log('Verification email sent.');
    //   } else {
    //     navigate("/sign-in", { state: { message: "User not authorized." } });
    //     console.error('User not authorized.');
    //   }
    // } catch (error) {
    //   navigate("/sign-in", { state: { message: "User not authorized." } });
    //   console.error('Error sending verification email:', error);
    // }
  };
  if (currentUsermeta) {
    return (
      <>
        <Helmet>
          <title>Step 1 - Verify Email</title>
        </Helmet>
        <div className="verified-email-set-up-wizardemail-verified">
          <div className="verified-email-section4">
            <div className="verified-email-section-child">
              <div className="verified-email-logo-group">
                <div className="verified-email-logo2">
                  <img
                    className="verified-email-gerator-04-1-icon2"
                    alt=""
                    src={gerator_logo}
                  />
                </div>
                <div className="verified-email-progress-steps-progress-icon2">
                  <div className="verified-email-step-base8">
                    <div className="verified-email-step-content">
                      <img
                        className="verified-email-featured-icon10"
                        loading="lazy"
                        alt=""
                        src={feature_icon}
                      />

                      <div className="verified-email-step-title-parent">
                        <div className="verified-email-step-title">Quick tap to confirm you're you!</div>
                        <div className="verified-email-supporting-text10">
                          Verify your email address
                        </div>
                      </div>
                    </div>
                    <div className="verified-email-progress-connector">
                      <div className="verified-email-connector8"></div>
                    </div>
                  </div>
                  <div className="verified-email-step-base9">
                    <div className="verified-email-step-content">
                      <img
                        className="verified-email-featured-icon10"
                        loading="lazy"
                        alt=""
                        src={feature_icon1}
                      />

                      <div className="verified-email-step-title-parent">
                        <div className="verified-email-step-title">Update Profile</div>
                        <div className="verified-email-supporting-text10">
                          Tell us a bit about yourself
                        </div>
                      </div>
                    </div>
                    <div className="verified-email-progress-connector">
                      <div className="verified-email-connector8"></div>
                    </div>
                  </div>
                  <div className="verified-email-step-base10">
                    <div className="verified-email-connector-wrap4">
                      <img
                        className="verified-email-featured-icon12"
                        loading="lazy"
                        alt=""
                        src={feature_icon2}
                      />


                    </div>
                    <div className="verified-email-text-and-supporting-text6">
                      <div className="verified-email-step-title">Choose a Subscription plan</div>
                      <div className="verified-email-supporting-text12">
                        Choose how you want to roll
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <div className="verified-email-footer1">
              {/* <div className="verified-email-text15">© Untitled UI 2077</div>
          <div className="verified-email-footer-help">
            <div className="verified-email-help-icon-wrapper">
              <img className="verified-email-mail-01-icon4" loading="lazy" alt="" />
            </div>
            <div className="verified-email-helpuntitleduicom2">helpcenter@gerator.com</div>
          </div> */}
            </div>
          </div>
          <section className="verified-email-section5">
            <div className="verified-email-container-group">
              <div className="verified-email-container5">
                <div className="verified-email-container6">
                  <div className="verified-email-content5">
                    <div className="verified-email-container7">
                      <div className="verified-email-content6">
                        <div className="verified-email-header2">
                          <img
                            className="verified-email-featured-icon14"
                            loading="lazy"
                            alt=""
                            src={feature_icon4}
                          />
                          {emailError ?
                            <div className="verified-email-text-and-supporting-text8">
                              <h2 className="verified-email-page-title">Oh! verification link is expired.</h2>
                              <div className="verified-email-supporting-text14">
                                Please click on "Click to resend" button.
                              </div>
                            </div>
                            :
                            <div className="verified-email-text-and-supporting-text8">
                              <h2 className="verified-email-page-title">{isEmailVerified ? "Email verified" : "Email verifying..."}</h2>
                              <div className="verified-email-supporting-text14">
                                {isEmailVerified ? "Your email has been successfully verified. Click below to continue." : "Please wait while we are verifying your email."}
                              </div>
                            </div>
                          }
                        </div>
                        {/* <button className="verified-email-buttonsbutton5">
                      <img
                        className="verified-email-placeholder-icon8"
                        alt=""
                        src="/account_setup/placeholder3.svg"
                      />
  
                      <div className="verified-email-text-padding2">
                      <Link to={"/sign-in"} style={{ textDecoration: 'none' }} >
                        <a className="verified-email-text16">Continue</a>
                        </Link>
                      </div>
  
                      <img
                        className="verified-email-placeholder-icon8"
                        alt=""
                        src="/account_setup/placeholder3.svg"
                      />
                    </button> */}
                        {(!emailError && isEmailVerified) &&
                          // isEmailVerified ? 
                          <AccountSetupButton text="Continue" next="/account-setup-update-profile" />
                          // : <AccountSetupButton text="Please Wait..." next="" />
                        }

                        {(showSuccessMessage.message) && (
                          <div
                            style={{
                              color: showSuccessMessage.color,
                              padding: "15px 10px",
                              border: "1px solid " + showSuccessMessage.color,
                              borderRadius: "10px",
                              margin: "0 auto",
                            }}
                          >
                            {showSuccessMessage.message}
                          </div>
                        )
                        }
                        {/* {} */}
                        {!isEmailVerified && <div className="verified-email-row1">
                          <div className="verified-email-copyright">Didn’t receive the email?</div>
                          <div className="verified-email-buttonsbutton6">
                            <img
                              className="verified-email-placeholder-icon10"
                              alt=""
                              src={placeholder2}
                            />
                            <Link to={"/account-setup-check-email"}>
                              <div className="verified-email-text18">Click to resend</div>
                            </Link>
                            <img
                              className="verified-email-placeholder-icon10"
                              alt=""
                              src={placeholder2}
                            />
                          </div>
                        </div>}


                        <Link to={"/sign-in"} style={{ textDecoration: 'none' }}>
                          <BackToLoginButton />
                        </Link>
                      </div>
                    </div>
                    <div className="verified-email-mobile-container" >
                      <div className="verified-email-mobile-PaginationDotGroup" >
                        <div className="verified-email-mobile-PaginationDotIndicator1" />
                        <div className="verified-email-mobile-PaginationDotIndicator" />
                        <div className="verified-email-mobile-PaginationDotIndicator" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <footer className="verified-email-footer-info">
                <div className="verified-email-icon-and-text2">
                  <img
                    className="verified-email-mail-01-icon5"
                    loading="lazy"
                    alt=""
                    src={support_icon}
                  />

                  <div className="account-email-check-helpcentergeratorcom"><a href=" https://helpcenter.gerator.com/" className='verified-email-hyperlink'>Need Help?</a></div>
                </div>
                <div className="verified-email-copyright">
                  © Gerator Asia LLP. All rights reserved.
                </div>
              </footer>
            </div>
          </section>
        </div>
      </>
    )
  }
  else {
    return <Loader />
  }
}
