import React from 'react';
import "./NoAddress.css"
import { Navbar } from '../../components/Navbar/Navbar';
import { Footer } from '../../components/Footer/Footer';
import { Sidebar } from '../../components/Sidebar/Sidebar';
// import { NothingFoundMessage } from '../../components/NothingFoundMessage/NothingFoundMessage';

export const NoAddress = () => {
  return (
    <div className="no-address-dashboard-frame-layout-setti">
      <main className="no-address-dashboard-frame-layout-setti1">
        <div className="no-address-dashboard-frame-layout-setti-child"></div>
        <section className="no-address-dashboard-frame-layout-setti-inner">
          <header className="no-address-rectangle-parent">
            <div className="no-address-frame-child"></div>

            <Navbar />
            {/* <div className="no-address-frame-parent">
            <div className="no-address-logo-wrapper">
              <div className="no-address-logo">
                <img
                  className="no-address-gerator-04-1-icon"
                  alt=""
                  src="/profile_settings/gerator04-1@2x.png"
                />
              </div>
            </div>
            <div className="no-address-frame-group">
              <div className="no-address-navigation-wrapper">
                <div className="no-address-navigation">
                  <div className="no-address-nav-item-base">
                    <div className="no-address-content">
                      <img className="no-address-dot-icon" alt="" src="/profile_settings/-dot.svg" />

                      <img
                        className="no-address-bar-chart-01-icon"
                        alt=""
                        src="/profile_settings/barchart01.svg"
                      />

                      <a className="no-address-text">Buy</a>
                    </div>
                    <div className="no-address-badge">
                      <div className="no-address-text1">10</div>
                    </div>
                    <img
                      className="no-address-chevron-down-icon"
                      alt=""
                      src="/profile_settings/chevrondown.svg"
                    />

                    <img
                      className="no-address-search-lg-icon"
                      alt=""
                      src="/profile_settings/chevrondown.svg"
                    />
                  </div>
                  <button className="no-address-nav-item-base1">
                    <div className="no-address-content">
                      <img className="no-address-dot-icon" alt="" src="/profile_settings/-dot.svg" />

                      <img
                        className="no-address-bar-chart-01-icon"
                        alt=""
                        src="/profile_settings/barchart01.svg"
                      />

                      <a className="no-address-text2">Sell</a>
                    </div>
                    <div className="no-address-badge1">
                      <div className="no-address-text3">10</div>
                    </div>
                    <img
                      className="no-address-chevron-down-icon"
                      alt=""
                      src="/profile_settings/chevrondown.svg"
                    />
                  </button>
                  <div className="no-address-nav-item-base">
                    <div className="no-address-content">
                      <img className="no-address-dot-icon" alt="" src="/profile_settings/-dot.svg" />

                      <img
                        className="no-address-bar-chart-01-icon"
                        alt=""
                        src="/profile_settings/barchart01.svg"
                      />

                      <a className="no-address-text4">Jobs</a>
                    </div>
                    <div className="no-address-badge">
                      <div className="no-address-text1">10</div>
                    </div>
                    <img
                      className="no-address-chevron-down-icon"
                      alt=""
                      src="/profile_settings/chevrondown.svg"
                    />
                  </div>
                </div>
              </div>
              <div className="no-address-input-with-label">
                <div className="no-address-label">Search</div>
                <div className="no-address-input">
                  <div className="no-address-content3">
                    <img
                      className="no-address-search-lg-icon"
                      alt=""
                      src="/profile_settings/searchlg.svg"
                    />

                    <a className="no-address-text6">Search</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="no-address-navigation-wrapper">
            <div className="no-address-avatar-button-parent">
              <div className="no-address-navigation-wrapper">
                <div className="no-address-nav-item-button">
                  <img className="no-address-icon" alt="" src="/profile_settings/icon.svg" />
                </div>
              </div>
              <div className="no-address-avatar-button1">
                <div className="no-address-nav-item-button">
                  <img
                    className="no-address-nav-item-button-child"
                    alt=""
                    src="/profile_settings/group-20.svg"
                  />
                </div>
              </div>
              <div className="no-address-avatar">
                <div className="no-address-contrast-border"></div>
              </div>
            </div>
          </div> */}
          </header>
        </section>
        <section className="no-address-divider-wrapper">
          <img
            className="no-address-divider-icon"
            loading="lazy"
            alt=""
            src="/profile_settings/divider.svg"
          />
        </section>
        <section className="no-address-sidebar-navigation-parent">
          <div className="no-address-sidebar-navigation">

            <Sidebar />

            {/* <div className="no-address-content4">
            <div className="no-address-navigation1">
              <div className="no-address-navigation2">
                <div className="no-address-nav-item-button2">
                  <img
                    className="no-address-home-line-icon"
                    loading="lazy"
                    alt=""
                    src="/profile_settings/homeline.svg"
                  />
                </div>
                <div className="no-address-nav-item-button3">
                  <img
                    className="no-address-home-line-icon"
                    loading="lazy"
                    alt=""
                    src="/profile_settings/barchartsquare02.svg"
                  />
                </div>
                <div className="no-address-nav-item-button2">
                  <img
                    className="no-address-home-line-icon"
                    loading="lazy"
                    alt=""
                    src="/profile_settings/layersthree01.svg"
                  />
                </div>
                <div className="no-address-nav-item-button2">
                  <img
                    className="no-address-home-line-icon"
                    loading="lazy"
                    alt=""
                    src="/profile_settings/checkdone01.svg"
                  />
                </div>
                <div className="no-address-nav-item-button2">
                  <img
                    className="no-address-home-line-icon"
                    loading="lazy"
                    alt=""
                    src="/profile_settings/piechart03.svg"
                  />
                </div>
                <div className="no-address-nav-item-button2">
                  <img
                    className="no-address-home-line-icon"
                    loading="lazy"
                    alt=""
                    src="/profile_settings/users01.svg"
                  />
                </div>
              </div>
            </div>
            <div className="no-address-footer">
              <div className="no-address-navigation3">
                <div className="no-address-nav-item-button2">
                  <img
                    className="no-address-home-line-icon"
                    loading="lazy"
                    alt=""
                    src="/profile_settings/lifebuoy01.svg"
                  />
                </div>
                <div className="no-address-nav-item-button2">
                  <img
                    className="no-address-home-line-icon"
                    loading="lazy"
                    alt=""
                    src="/profile_settings/settings01.svg"
                  />
                </div>
              </div>
              <div className="no-address-avatar1">
                <div className="no-address-contrast-border1"></div>
              </div>
            </div>
          </div>
          <div className="no-address-sub-nav">
            <div className="no-address-content5">
              <div className="no-address-sub-nav-header">
                <a className="no-address-sub-nav-title">Dashboard</a>
                <img
                  className="no-address-arrow-narrow-left-icon"
                  alt=""
                  src="/profile_settings/arrownarrowleft.svg"
                />
              </div>
              <div className="no-address-navigation4">
                <div className="no-address-nav-item-base3">
                  <div className="no-address-content6">
                    <img className="no-address-dot-icon" alt="" src="/profile_settings/-dot.svg" />

                    <img
                      className="no-address-arrow-narrow-left-icon"
                      alt=""
                      src="/profile_settings/activity.svg"
                    />

                    <div className="no-address-text7">Item 1</div>
                  </div>
                  <div className="no-address-badge3">
                    <div className="no-address-text1">10</div>
                  </div>
                  <img
                    className="no-address-chevron-down-icon"
                    alt=""
                    src="/profile_settings/chevrondown.svg"
                  />
                </div>
                <div className="no-address-nav-item-base4">
                  <div className="no-address-content7">
                    <img className="no-address-dot-icon" alt="" src="/profile_settings/-dot.svg" />

                    <img
                      className="no-address-arrow-narrow-left-icon"
                      loading="lazy"
                      alt=""
                      src="/profile_settings/notificationbox.svg"
                    />

                    <div className="no-address-text9">Item 2</div>
                  </div>
                  <div className="no-address-badge4">
                    <div className="no-address-text10">10</div>
                  </div>
                  <img
                    className="no-address-chevron-down-icon"
                    alt=""
                    src="/profile_settings/chevrondown.svg"
                  />
                </div>
                <div className="no-address-nav-item-base4">
                  <div className="no-address-content8">
                    <img className="no-address-dot-icon" alt="" src="/profile_settings/-dot.svg" />

                    <img
                      className="no-address-arrow-narrow-left-icon"
                      alt=""
                      src="/profile_settings/barlinechart.svg"
                    />

                    <div className="no-address-text9">Item 3</div>
                  </div>
                  <div className="no-address-badge">
                    <div className="no-address-text1">10</div>
                  </div>
                  <img
                    className="no-address-chevron-down-icon"
                    alt=""
                    src="/profile_settings/chevrondown.svg"
                  />
                </div>
                <div className="no-address-nav-item-base4">
                  <div className="no-address-content8">
                    <img className="no-address-dot-icon" alt="" src="/profile_settings/-dot.svg" />

                    <img
                      className="no-address-arrow-narrow-left-icon"
                      alt=""
                      src="/profile_settings/star01.svg"
                    />

                    <div className="no-address-text9">Item 4</div>
                  </div>
                  <div className="no-address-badge">
                    <div className="no-address-text1">10</div>
                  </div>
                  <img
                    className="no-address-chevron-down-icon"
                    alt=""
                    src="/profile_settings/chevrondown.svg"
                  />
                </div>
                <div className="no-address-nav-item-base4">
                  <div className="no-address-content8">
                    <img className="no-address-dot-icon" alt="" src="/profile_settings/-dot.svg" />

                    <img
                      className="no-address-arrow-narrow-left-icon"
                      alt=""
                      src="/profile_settings/clockfastforward.svg"
                    />

                    <div className="no-address-text9">Item 5</div>
                  </div>
                  <div className="no-address-badge">
                    <div className="no-address-text1">10</div>
                  </div>
                  <img
                    className="no-address-chevron-down-icon"
                    alt=""
                    src="/profile_settings/chevrondown.svg"
                  />
                </div>
                <div className="no-address-nav-item-base4">
                  <div className="no-address-content8">
                    <img className="no-address-dot-icon" alt="" src="/profile_settings/-dot.svg" />

                    <img
                      className="no-address-arrow-narrow-left-icon"
                      alt=""
                      src="/profile_settings/usersquare.svg"
                    />

                    <div className="no-address-text9">Item 6</div>
                  </div>
                  <div className="no-address-badge">
                    <div className="no-address-text1">10</div>
                  </div>
                  <img
                    className="no-address-chevron-down-icon"
                    alt=""
                    src="/profile_settings/chevrondown.svg"
                  />
                </div>
                <div className="no-address-nav-item-base4">
                  <div className="no-address-content12">
                    <img className="no-address-dot-icon" alt="" src="/profile_settings/-dot.svg" />

                    <img
                      className="no-address-arrow-narrow-left-icon"
                      alt=""
                      src="/profile_settings/settings03.svg"
                    />

                    <div className="no-address-text19">Item 7</div>
                  </div>
                  <div className="no-address-badge">
                    <div className="no-address-text1">10</div>
                  </div>
                  <img
                    className="no-address-chevron-down-icon"
                    alt=""
                    src="/profile_settings/chevrondown.svg"
                  />
                </div>
              </div>
            </div>
            <div className="no-address-account">
              <div className="no-address-text21">
                <div className="no-address-title">Olivia Rhye</div>
                <div className="no-address-supporting-text">olivia@untitledui.com</div>
              </div>
              <div className="no-address-buttonsbutton">
                <img
                  className="no-address-search-lg-icon"
                  loading="lazy"
                  alt=""
                  src="/profile_settings/logout01.svg"
                />
              </div>
            </div>
          </div> */}
          </div>
          <div className="no-address-main-parent">
            <div className="no-address-main">
              <div className="no-address-header-section">
                <div className="no-address-container">
                  <div className="no-address-page-header">
                    <div className="no-address-breadcrumbs">
                      <div className="no-address-tabs">
                        <div className="no-address-breadcrumb-button-base">
                          <img
                            className="no-address-search-lg-icon"
                            alt=""
                            src="/profile_settings/homeline-1.svg"
                          />
                        </div>
                        <img
                          className="no-address-chevron-right-icon"
                          alt=""
                          src="/profile_settings/chevronright.svg"
                        />

                        <div className="no-address-breadcrumb-button-base1">
                          <a className="no-address-text22">Dashboard</a>
                        </div>
                        <img
                          className="no-address-chevron-right-icon"
                          alt=""
                          src="/profile_settings/chevronright.svg"
                        />

                        <div className="no-address-breadcrumb-button-base2">
                          <div className="no-address-text23">...</div>
                        </div>
                        <img
                          className="no-address-chevron-right-icon2"
                          alt=""
                          src="/profile_settings/chevronright.svg"
                        />

                        <div className="no-address-breadcrumb-button-base3">
                          <div className="no-address-text24">Another link</div>
                        </div>
                        <img
                          className="no-address-chevron-right-icon2"
                          alt=""
                          src="/profile_settings/chevronright.svg"
                        />

                        <div className="no-address-breadcrumb-button-base4">
                          <a className="no-address-text25">Settings</a>
                        </div>
                      </div>
                    </div>
                    <img
                      className="no-address-divider-icon1"
                      alt=""
                      src="/profile_settings/divider1.svg"
                    />
                  </div>
                </div>
              </div>
              <div className="no-address-main-child"></div>
              <div className="no-address-main-item"></div>
            </div>
            <div className="no-address-main-wrapper">
              <div className="no-address-main1">
                <div className="no-address-header-content">
                  <div className="no-address-header-section1">
                    <div className="no-address-container1">
                      <div className="no-address-page-header1">
                        <div className="no-address-breadcrumbs1">
                          <div className="no-address-tabs1">
                            <div className="no-address-breadcrumb-button-base">
                              <img
                                className="no-address-search-lg-icon"
                                alt=""
                                src="/profile_settings/homeline-1.svg"
                              />
                            </div>
                            <img
                              className="no-address-chevron-right-icon"
                              alt=""
                              src="/profile_settings/chevronright.svg"
                            />

                            <div className="no-address-breadcrumb-button-base6">
                              <div className="no-address-text26">Settings</div>
                            </div>
                            <img
                              className="no-address-chevron-right-icon"
                              alt=""
                              src="/profile_settings/chevronright.svg"
                            />

                            <div className="no-address-breadcrumb-button-base7">
                              <div className="no-address-text23">...</div>
                            </div>
                            <img
                              className="no-address-chevron-right-icon"
                              alt=""
                              src="/profile_settings/chevronright.svg"
                            />

                            <div className="no-address-breadcrumb-button-base3">
                              <div className="no-address-text24">Another link</div>
                            </div>
                            <img
                              className="no-address-chevron-right-icon2"
                              alt=""
                              src="/profile_settings/chevronright.svg"
                            />

                            <div className="no-address-breadcrumb-button-base4">
                              <div className="no-address-text29">Team</div>
                            </div>
                          </div>
                        </div>
                        <div className="no-address-content13">
                          <div className="no-address-text-and-supporting-text">
                            <h1 className="no-address-page-title">Settings</h1>
                            <div className="no-address-supporting-text1">
                              Manage your team members and their account
                              permissions here.
                            </div>
                          </div>
                          <div className="no-address-actions">
                            <div className="no-address-button">
                              <img
                                className="no-address-placeholder-icon"
                                alt=""
                                src="/profile_settings/placeholder.svg"
                              />

                              <div className="no-address-text-padding">
                                <div className="no-address-title">Tertiary</div>
                              </div>
                              <img
                                className="no-address-placeholder-icon"
                                alt=""
                                src="/profile_settings/placeholder.svg"
                              />
                            </div>
                            <div className="no-address-button1">
                              <img
                                className="no-address-placeholder-icon"
                                alt=""
                                src="/profile_settings/placeholder1.svg"
                              />

                              <div className="no-address-text-padding">
                                <div className="no-address-title">Secondary</div>
                              </div>
                              <img
                                className="no-address-placeholder-icon"
                                alt=""
                                src="/profile_settings/placeholder1.svg"
                              />
                            </div>
                            <div className="no-address-button2">
                              <img
                                className="no-address-placeholder-icon"
                                alt=""
                                src="/profile_settings/placeholder2.svg"
                              />

                              <div className="no-address-text-padding">
                                <div className="no-address-title">Secondary</div>
                              </div>
                              <img
                                className="no-address-placeholder-icon"
                                alt=""
                                src="/profile_settings/placeholder2.svg"
                              />
                            </div>
                            <div className="no-address-button3">
                              <img
                                className="no-address-placeholder-icon"
                                alt=""
                                src="/profile_settings/placeholder3.svg"
                              />

                              <div className="no-address-text-padding">
                                <div className="no-address-title">Primary</div>
                              </div>
                              <img
                                className="no-address-placeholder-icon"
                                alt=""
                                src="/profile_settings/placeholder3.svg"
                              />
                            </div>
                          </div>
                          <div className="no-address-input-dropdown">
                            <div className="no-address-input-with-label1">
                              <div className="no-address-label">Search</div>
                              <div className="no-address-input1">
                                <div className="no-address-content3">
                                  <img
                                    className="no-address-search-lg-icon"
                                    alt=""
                                    src="/profile_settings/searchlg.svg"
                                  />

                                  <input
                                    className="no-address-text34"
                                    placeholder="Search"
                                    type="text"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="no-address-hint-text">
                              This is a hint text to help user.
                            </div>
                          </div>
                        </div>
                        <img
                          className="no-address-divider-icon2"
                          alt=""
                          src="/profile_settings/divider2.svg"
                        />
                      </div>
                      <div className="no-address-horizontal-tabs">
                        <div className="no-address-tabs2">
                          <div className="no-address-tab-button-base1">
                            <a className="no-address-text35">Profile</a>
                          </div>
                          <div className="no-address-tab-button-base1">
                            <a className="no-address-text36">Business</a>
                          </div>
                          <div className="no-address-tab-button-base">
                            <a className="no-address-text25">Address</a>
                          </div>
                          <div className="no-address-tab-button-base3">
                            <div className="no-address-text38">Team</div>
                            <div className="no-address-badge10">
                              <div className="no-address-badge-count">4</div>
                            </div>
                          </div>
                          <div className="no-address-tab-button-base1">
                            <div className="no-address-text39">Plan</div>
                          </div>
                          <div className="no-address-tab-button-base3">
                            <a className="no-address-text40">Billing</a>
                            <div className="no-address-badge10">
                              <div className="no-address-badge-count">2</div>
                            </div>
                          </div>
                          <div className="no-address-tab-button-base1">
                            <a className="no-address-text40">Social</a>
                          </div>
                          <div className="no-address-tab-button-base7">
                            <a className="no-address-text43">Preferences</a>
                            <div className="no-address-badge10">
                              <div className="no-address-badge-count">2</div>
                            </div>
                          </div>
                          <div className="no-address-tab-button-base1">
                            <div className="no-address-text45">Integrations</div>
                          </div>
                          <div className="no-address-tab-button-base9">
                            <div className="no-address-text46">API</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="no-address-button4">
                <img
                  className="no-address-placeholder-icon"
                  alt=""
                  src="/profile_settings/placeholder.svg"
                />

                <div className="no-address-text-padding4">
                  <div className="no-address-text47">Tertiary</div>
                </div>
                <img
                  className="no-address-placeholder-icon"
                  alt=""
                  src="/profile_settings/placeholder.svg"
                />
              </div> */}
                {/* <div className="no-address-button5">
                <img
                  className="no-address-placeholder-icon"
                  alt=""
                  src="/profile_settings/placeholder1.svg"
                />

                <div className="no-address-text-padding4">
                  <div className="no-address-text47">Secondary</div>
                </div>
                <img
                  className="no-address-placeholder-icon"
                  alt=""
                  src="/profile_settings/placeholder1.svg"
                />
              </div> */}
                <div className="no-address-dropdown">
                  <img
                    className="no-address-dots-vertical-icon"
                    alt=""
                    src="/profile_settings/dotsvertical.svg"
                  />
                </div>
                <br /><br />
                {/* <NothingFoundMessage text="Your company doesn't have an address yet ?!" text1="Let's start the process ?" text2="You need to create an address for your business,or be related one." /> */}
                <div className="nothing-found-message-search-results">

                  <section className="nothing-found-message-empty-state2">
                    <div className="nothing-found-message-empty-state3">
                      <div className="nothing-found-message-content15">
                        <img
                          className="nothing-found-message-featured-icon"
                          loading="lazy"
                          alt=""
                          src="/no_address/featured-icon.svg"
                        />

                        <div className="nothing-found-message-text-and-supporting-text4">
                          <div className="nothing-found-message-supporting-text16">
                            Your company doesn't have an address yet ?!
                          </div>
                          <br />
                          <div className="nothing-found-message-empty-title">Let's start the process ?</div>
                          <br />
                          <div className="nothing-found-message-supporting-text16">
                            You need to create an address for your business,or be related one.
                          </div>
                        </div>
                      </div>
                      <div className="nothing-found-message-actions6">

                        <button className="nothing-found-message-buttonsbutton5">
                          <img className="nothing-found-message-search-lg-icon1" alt="" src="/no_address/plus.svg" />

                          <div className="nothing-found-message-text-padding15">
                            <div className="nothing-found-message-text74">Create Address</div>
                          </div>
                          <img
                            className="nothing-found-message-placeholder-icon27"
                            alt=""
                            src="/no_address/placeholder1.svg"
                          />
                        </button>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </section>
        <footer className="no-address-footer1">

          <Footer />

          {/* <div className="no-address-container2">
          <div className="no-address-content47">
            <div className="no-address-footer-text">
              © Gerator Asia LLP. All rights reserved.
            </div>
            <div className="no-address-logo1">
              <div className="no-address-logo-wrapper1">
                <div className="no-address-logo-container">
                  <div className="no-address-footer-text">All prices are in USD</div>
                </div>
                <div className="no-address-logomark">
                  <div className="no-address-logomark1">
                    <img
                      className="no-address-gerator-10-2-icon"
                      loading="lazy"
                      alt=""
                      src="/profile_settings/gerator10-2@2x.png"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        </footer>
        <img className="no-address-divider-icon5" alt="" src="/profile_settings/divider.svg" />
      </main>
    </div>
  )
}





