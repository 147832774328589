import React, { useEffect, useRef, useState } from 'react';
import "./FunnelServiceDetailPage.css";
import { Sidebar } from '../../../components/Sidebar/Sidebar';
import { Navbar1 } from '../../../components/Navbar1/Navbar1';
import { Footer } from '../../../components/Footer/Footer';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Loader } from '../../../components/Loader';
import { firestore, doc, getDoc, auth, onAuthStateChanged, sendPasswordResetEmail, fetchSignInMethodsForEmail } from "../../../screens/Login/FirebaseClient";
import divider3 from '../../../icons/Gerator/sales_iq/divider3.svg';
import divider1 from '../../../icons/Gerator/sales_iq/divider1.svg';
import plus from '../../../icons/Gerator/sales_iq/plus.svg';
import homeline1 from '../../../icons/Gerator/sales_iq/homeline1.svg';
import chevronright from '../../../icons/Gerator/sales_iq/chevronright.svg';
import chart_data2x from '../../../icons/Gerator/sales_iq/-chart-data@2x.png';
import arrowup from '../../../icons/Gerator/sales_iq/arrowup.svg';
import arrowdown from '../../../icons/Gerator/sales_iq/arrowdown.svg';
import chart_mini2x from '../../../icons/Gerator/sales_iq/-chart-mini@2x.png';
import dotsvertical from '../../../icons/Gerator/sales_iq/dotsvertical.svg';
import chart_mini1_2x from '../../../icons/Gerator/sales_iq/-chart-mini1@2x.png';
import _1_2x from '../../../icons/Gerator/sales_iq/8595f4b711e503bc72fe396e5043e0c2-1@2x.png';
import divider2 from "../../../icons/Gerator/sales_iq/divider2.svg";
import arrowdown1 from '../../../icons/Gerator/dashboard/arrowdown1.svg';

import { formatNumber, getCustomizedDate } from '../../../helper/DeviceHelper';
import { Helmet } from 'react-helmet-async';
import ButtonDropdown from '../../../components/AddButtonDropdown/AddButtonDropdown';
import PlanExpiryStatus from '../../../components/PlanExpiryStatusComponent/PlanExpiryStatus';
import { useLazyGetCurrentPlanOfUserQuery } from '../../../api/PlanAPI';
import { useLazyGetProfileImageLinkQuery } from '../../../api/Login&RegisterAPI';
import { SideBarSubSalesIQ } from '../../../components/SideBars-Sub/SideBarSubSalesIQ/SideBarSubSalesIQ';
import { SalesIQDetailDeviceComponent } from '../../../components/SalesIQDetailPageComponents/SalesIQDeviceDetailPageComponent/SalesIQDeviceDetailPageComponent';




// Temporary
import dotsvertical from '../../../icons/Gerator/sales_iq/dotsvertical.svg';
import calendar1 from '../../../icons/Gerator/sales_iq/calendar1.svg';
import content_elements_2x from '../../../icons/Gerator/sales_iq/content-elements@2x.png';
import dot1 from '../../../icons/Gerator/sales_iq/-dot1.svg';

import _chart_mini2 from "../../../icons/Gerator/sales_iq/-chart-mini2@2x.png"
import arrowup from "../../../icons/Gerator/sales_iq/arrowup.svg";

import radar_1_1_2x from '../../../icons/Gerator/sales_iq/radar-1-1@2x.png';
import deal_2_1_2x from '../../../icons/Gerator/sales_iq/deal-2-1@2x.png';
import magnifier_1_2x from '../../../icons/Gerator/sales_iq/magnifier-1@2x.png';
import content_elements_2x from '../../../icons/Gerator/sales_iq/content-elements@2x.png';
import eye from '../../../icons/Gerator/sales_iq/eye.svg';
import icon1 from '../../../icons/Gerator/sales_iq/icon1.svg';
import lock04 from '../../../icons/Gerator/sales_iq/lock04.svg';
import lockunlocked04 from '../../../icons/Gerator/sales_iq/lockunlocked04.svg';
import arrowsquareupright from '../../../icons/Gerator/sales_iq/arrowsquareupright.svg';
import messagechatsquare from '../../../icons/Gerator/sales_iq/messagechatsquare.svg';
import markerpin02 from '../../../icons/Gerator/sales_iq/markerpin02.svg';
import divider2 from '../../../icons/Gerator/sales_iq/divider2.svg';
import arrowup1 from '../../../icons/Gerator/sales_iq/arrowup1.svg';
import arrowdown1 from '../../../icons/Gerator/sales_iq/arrowdown1.svg';
import placeholder2 from '../../../icons/Gerator/sales_iq/placeholder2.svg';
import group_58_2x from '../../../icons/Gerator/sales_iq/group-58@2x.png';
import sliders02 from '../../../icons/Gerator/sales_iq/sliders02.svg';
import playstation from "../../../icons/Gerator/sales_iq/playstation.jpeg";
import { PostStatusLIstingComponent } from '../../../components/PostStatusLIstingComponent/PostStatusLIstingComponent';
import { TransactionTypeComponent } from '../../../components/TransactionTypeComponent/TransactionTypeComponent';
import { useLazyFindGetDeviceWithoutTaxSalesIQQuery } from '../../../api/DeviceAPI';
import { ThreeDotsListingComponent } from '../../../components/ThreeDotsListingComponent/ThreeDotsListingComponent';
import SalesIqActiveEngagementTable from '../../../components/SalesIQDetailPageTableComponents/SalesIqActiveEngagementTable';
import { useLazyGetUsersForSalesIQDashboardViewDeviceQuery } from '../../../api/View/ViewDeviceAPI';
import SalesIqViewTable from '../../../components/SalesIQDetailPageTableComponents/SalesIqViewTable';
import { useLazyGetUsersForSalesIQDashboardRadarDeviceQuery } from '../../../api/Radar/RadarDeviceAPI';
import SalesIqRadarTable from '../../../components/SalesIQDetailPageTableComponents/SalesIqRadarTable';
import SalesIqSalesTable from '../../../components/SalesIQDetailPageTableComponents/SalesIqSalesTable';
import FunnelTable from '../../../components/FunnelTable/FunnelTable';


// const auth = getAuth(app);


export const FunnelServiceDetailPage = () => {


  const { id } = useParams();
  const [profileImageLink, setProfileImageLink] = useState("");
  const [currentUserMeta, setCurrentUserMeta] = useState("");
  const [currentUser, setCurrentUser] = useState("");
  const navigate = useNavigate();
  const [offSetView, setOffSetView] = useState(0);
  const [offSetRadar, setOffSetRadar] = useState(0);
  const [limit, setLimit] = useState(5);
  const [currentPlanExpiry, setCurrentPlanExpiry] = useState("");
  const [currentPlanName, setCurrentPlanName] = useState("");

  //For Threedots in listing dropdown
  const [activeThreeDot, setActiveThreeDot] = useState(false);
  const threeDotsRef = useRef(null);
  const [isClickBlocked, setIsClickBlocked] = useState(false);

  const [triggerGetCurrentPlan, { data: currentPlanData, isError: isCurrentPlanError, error: currentPlanError, isLoading: isCurrentPlanLoading, isSuccess: isCurrentPlanSuccess }] = useLazyGetCurrentPlanOfUserQuery();
  const [triggerNewProfileImageLink] = useLazyGetProfileImageLinkQuery();

  const [triggerGetListingData, { data: currentListingData, isError: isListingsDataError, error: currentListingError, isLoading: isListingsDataLoading, isSuccess: isListingsDataSuccess }] = useLazyFindGetDeviceWithoutTaxSalesIQQuery();

  const [triggerGetViewData, { data: currentViewData, isError: isViewsDataError, error: currentViewError, isLoading: isViewsDataLoading, isSuccess: isViewsDataSuccess, isFetching: isViewDataFetching }] = useLazyGetUsersForSalesIQDashboardViewDeviceQuery();

  const [triggerGetRadarData, { data: currentRadarData, isError: isRadarsDataError, error: currentRadarError, isLoading: isRadarsDataLoading, isSuccess: isRadarsDataSuccess, isFetching: isRadarDataFetching }] = useLazyGetUsersForSalesIQDashboardRadarDeviceQuery();

  const toggleThreeDotsDropdown = () => {
    if (isClickBlocked) return; // Prevent rapid toggle

    if (activeThreeDot) {
      setActiveThreeDot(false); // Close if the same one is clicked again
      setIsClickBlocked(true);
      setTimeout(() => setIsClickBlocked(false), 400); // Small delay to avoid reopening
    } else {
      setActiveThreeDot(true); // Open the clicked one
    }
  };

  // const [triggerGetUser, { data, error, isLoading, isError }] = useLazyGetUserQuery();
  useEffect(() => {
    // const auth = getAuth();

    // Listen for authentication state change
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {

        if (user) {
          // Get user to check wizardStep
          const userDocRef = doc(firestore, "users", user.uid);
          const userDocSnapshot = await getDoc(userDocRef);

          if (userDocSnapshot.exists()) {
            const usermeta = userDocSnapshot.data(); // Default to 1 if not set

            if (usermeta.userStatus == 2) {
              navigate('/account-disabled');
              return;
            }

            if (usermeta.userType == 5) {
              navigate('/access-denied');
              return;
            }

            if (usermeta.wizardStep !== 0) {
              navigate('/account-setup-check-email');
              return;
            }
            await triggerGetCurrentPlan().unwrap();
            setCurrentUser(user);
            setCurrentUserMeta(usermeta);
            // User is authenticated
          }
        }
      } else {
        // User is not authenticated, redirect to login
        navigate('/sign-in', { state: { message: 'Please log in to continue.' } });
      }
    });




    return () => unsubscribe(); // Cleanup listener on component unmount
  }, [navigate]);

  useEffect(() => {
    const refreshProfileImage = async () => {
      if (currentUserMeta && currentUserMeta.profileImage.expiresAt < Date.now() / 1000) {
        // Pre-signed URL has expired, trigger a new request to the backend
        const newImageData = await triggerNewProfileImageLink().unwrap();
        // Update the profile image link directly
        setProfileImageLink(newImageData.url);
      } else if (currentUserMeta && currentUserMeta.profileImage.url) {
        // Use the existing pre-signed URL to display the profile image
        setProfileImageLink(currentUserMeta.profileImage.url);
      }
    };

    refreshProfileImage();
  }, [currentUserMeta]);

  useEffect(() => {
    if (currentUser) {
      triggerGetListingData(id || 0);
      triggerGetViewData({ offSetView, limit, id },
        {
          // Refetch when component mounts or tab becomes active
          refetchOnMountOrArgChange: true
        });
      triggerGetRadarData({ offSetRadar, limit, id },
        {
          // Refetch when component mounts or tab becomes active
          refetchOnMountOrArgChange: true
        });
    }
  }, [currentUser]);


  useEffect(() => {
    if (currentUser) {
      triggerGetViewData({ offSetView, limit, id },
        {
          // Refetch when component mounts or tab becomes active
          refetchOnMountOrArgChange: true
        });
    }
  }, [offSetView]);

  useEffect(() => {
    if (currentUser) {
      triggerGetRadarData({ offSetRadar, limit, id },
        {
          // Refetch when component mounts or tab becomes active
          refetchOnMountOrArgChange: true
        });
    }
  }, [offSetRadar]);

  useEffect(() => {
    if (currentViewData) {
      console.log(currentViewData);
    }
  }, [currentViewData])

  useEffect(() => {
    if (isCurrentPlanSuccess && currentPlanData?.data?.order_plan?.id) {
      setCurrentPlanName(currentPlanData.data.order_plan.planName);
      setCurrentPlanExpiry(currentPlanData.data.orderExpiry);
    } else {
      console.log("No current plan data or ID not available.");
    }
  }, [isCurrentPlanSuccess, currentPlanData]);

  //Onclick listener for threedots dropdown
  useEffect(() => {

    const handleThreeDotClickOutside = (event) => {

      const isLink = event.target.closest('a');

      if (isLink) {
        return; // Allow navigation to happen
      }

      if (threeDotsRef.current && !threeDotsRef.current.contains(event.target)) {
        setActiveThreeDot(null); // Close any open dropdown when clicking outside
      }
    };

    document.addEventListener("mousedown", handleThreeDotClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleThreeDotClickOutside);
    };
  }, []);


  const handleViewDataLoadMore = () => {
    if (currentViewData?.hasMore && !isViewDataFetching) {
      setOffSetView(currentOffset => currentOffset + 5);
    }
  };

  const showViewDataLoadMore = currentViewData?.hasMore &&
    currentViewData.view_device.length < currentViewData.totalCount &&
    !isViewDataFetching;


  const handleRadarDataLoadMore = () => {
    if (currentRadarData?.hasMore && !isRadarDataFetching) {
      setOffSetRadar(currentOffset => currentOffset + 5);
    }
  };

  const showRadarDataLoadMore = currentRadarData?.hasMore &&
    currentRadarData.radar_device.length < currentRadarData.totalCount &&
    !isRadarDataFetching;


  //   if (currentUser && isCurrentPlanSuccess && profileImageLink && currentListingData) {
  //     return (
  //       <div className="funnel-detail-service-funnel-detail-service-frame-layout">
  //         <Helmet>
  //           <title></title>
  //         </Helmet>
  //         <div className="funnel-detail-service-funnel-detail-service-frame-layout-child"></div>

  //         <Navbar1 userData={currentUser} navigate={navigate} profileImageLink={profileImageLink} />

  //         <div className="funnel-detail-service-all">
  //           <img className="funnel-detail-service-divider-icon" alt="" src={divider3} />

  //           <div className="funnel-detail-service-all-content">

  //             <Sidebar>
  //               <SideBarSubSalesIQ userData={currentUser} />
  //             </Sidebar>

  //             <div className="funnel-detail-service-frame-parent">
  //               <div className="funnel-detail-service-main-wrapper">
  //                 <div className="funnel-detail-service-main funnel-detail-service-main-custom">
  //                   <div className="funnel-detail-service-header-section">
  //                     <div className="funnel-detail-service-container">
  //                       <div className="funnel-detail-service-page-header">
  //                         <div className="funnel-detail-service-breadcrumbs">
  //                           <div className="funnel-detail-service-tabs">
  //                             <div className="funnel-detail-service-breadcrumb-button-base">
  //                               <img
  //                                 className="funnel-detail-service-search-lg-icon"
  //                                 alt=""
  //                                 src={homeline1}
  //                               />
  //                             </div>
  //                             <img
  //                               className="funnel-detail-service-chevron-right-icon"
  //                               alt=""
  //                               src={chevronright}
  //                             />

  //                             <div className="funnel-detail-service-breadcrumb-button-base1">
  //                               <div className="funnel-detail-service-location">Funnel</div>
  //                             </div>
  //                             <img
  //                               className="funnel-detail-service-chevron-right-icon"
  //                               alt=""
  //                               src={chevronright}
  //                             />

  //                             <div className="funnel-detail-service-breadcrumb-button-base2">
  //                               <div className="funnel-detail-service-text20">...</div>
  //                             </div>
  //                             <img
  //                               className="funnel-detail-service-chevron-right-icon2"
  //                               alt=""
  //                               src={chevronright}
  //                             />

  //                             <div className="funnel-detail-service-breadcrumb-button-base3">
  //                               <div className="funnel-detail-service-text21">Another link</div>
  //                             </div>
  //                             <img
  //                               className="funnel-detail-service-chevron-right-icon2"
  //                               alt=""
  //                               src={chevronright}
  //                             />

  //                             <div className="funnel-detail-service-breadcrumb-button-base4">
  //                               <div className="funnel-detail-service-text22">Overview</div>
  //                             </div>
  //                           </div>
  //                         </div>



  //                       </div>

  //                     </div>
  //                   </div>


  //                 </div>
  //               </div>
  //               {/* <div className="funnel-detail-service-separator"></div>
  //               <div className="funnel-detail-service-separator1"></div> */}

  //               {/* <SalesIQDetailDeviceComponent/> */}

  //               {/* <div className="funnel-detail-service-component-funnel-content-items" key={index}> */}
  //               <div className="funnel-detail-service-component-listing-container">
  //                 <div className="funnel-detail-service-component-funnel-content-items" >
  //                   <div className="funnel-detail-service-component-funnel-divider-container1">
  //                     <div className="funnel-detail-service-component-funnel-rectangle-parent5">
  //                       <img
  //                         className="funnel-detail-service-component-funnel-frame-child7"
  //                         alt=""
  //                         src={currentListingData.featureImage}
  //                       // src={playstation}

  //                       />

  //                       <div className="funnel-detail-service-component-funnel-frame-parent1">
  //                         <div className="funnel-detail-service-component-funnel-action-fields">
  //                           <div className="funnel-detail-service-component-funnel-input-elements">
  //                             <Link to={`/device/${currentListingData.id}/${currentListingData.title}`} className="funnel-detail-service-component-funnel-text78">
  //                               {currentListingData.title}
  //                             </Link>
  //                             {/* <div className="funnel-detail-service-component-funnel-text78">
  //                               PRE-OWNED / USED / PARTIALLY REFURBISHED 1995 12TH MAN SBJ123
  //                             </div> */}
  //                             <div className="funnel-detail-service-component-funnel-frame-parent21">
  //                               <div className="funnel-detail-service-component-funnel-badge-item-parent">
  //                                 <div className="funnel-detail-service-component-funnel-badge-parent">

  //                                   <PostStatusLIstingComponent postStatus={currentListingData.tax_post_status.name} postSlug={currentListingData.tax_post_status.slug} postId={currentListingData.id} />
  //                                   {/* <PostStatusLIstingComponent postStatus={"Pending"} postSlug={"pending"} postId={1} /> */}

  //                                 </div>
  //                                 <div className="funnel-detail-service-component-funnel-frame-parent4">
  //                                   <div className="funnel-detail-service-component-funnel-end-label">
  //                                     <div className="funnel-detail-service-component-funnel-search-icon-input">4.9/5</div>
  //                                   </div>
  //                                   <div className="funnel-detail-service-component-funnel-type-support">
  //                                     <div className="funnel-detail-service-component-funnel-supporting-text9">
  //                                       99,999 reviews
  //                                     </div>
  //                                   </div>
  //                                 </div>

  //                               </div>
  //                               <div className="funnel-detail-service-component-funnel-frame-parent24">
  //                                 <div className="funnel-detail-service-component-funnel-location-pin-parent">
  //                                   <div className="funnel-detail-service-component-funnel-search-input">
  //                                     <img
  //                                       className="funnel-detail-service-component-funnel-marker-pin-02-icon2"
  //                                       alt=""
  //                                       src={markerpin02}
  //                                     />
  //                                   </div>
  //                                   <div className="funnel-detail-service-component-funnel-location3">{currentListingData.device_address.addressTownCity}, {currentListingData.device_address.tax_country.name}</div>
  //                                   {/* <div className="funnel-detail-service-component-funnel-location3">Oklahoma City , United States of America</div> */}
  //                                 </div>

  //                               </div>
  //                               <div className="funnel-detail-service-component-funnel-frame-parent26">
  //                                 <div className="funnel-detail-service-component-funnel-frame-wrapper19">
  //                                   <div className="funnel-detail-service-component-funnel-legend-series">
  //                                     <div className="funnel-detail-service-component-funnel-search-input">
  //                                       <img
  //                                         className="funnel-detail-service-component-funnel-marker-pin-02-icon2"
  //                                         alt=""
  //                                         src={calendar1}
  //                                       />
  //                                     </div>
  //                                     <div className="funnel-detail-service-component-funnel-type-of-work2">{getCustomizedDate(currentListingData.createdAt)}</div>
  //                                     {/* <div className="funnel-detail-service-component-funnel-type-of-work2">November 13,2024</div> */}
  //                                   </div>
  //                                 </div>

  //                               </div>

  //                             </div>
  //                           </div>

  //                           <div className="funnel-detail-service-component-funnel-small-card">

  //                             <div className="funnel-detail-service-component-funnel-badge33">
  //                               <div className="funnel-detail-service-component-funnel-third-badge-number">
  //                                 {currentListingData.tax_device_category.name}
  //                                 {/* 1/25-DIHYDROXYDEVICE */}
  //                               </div>
  //                             </div>

  //                             {/* <div className="funnel-detail-service-component-funnel-badge36">
  //                               <div className="funnel-detail-service-component-funnel-third-badge-number">
  //                                 11 Health
  //                               </div>
  //                             </div> */}

  //                             <div className="funnel-detail-service-component-funnel-badge37">
  //                               <div className="funnel-detail-service-component-funnel-third-badge-number">{currentListingData.tax_oem.name}</div>
  //                               {/* <div className="funnel-detail-service-component-funnel-third-badge-number">
  //                                 12TH MAN
  //                               </div> */}
  //                             </div>

  //                             <div className="funnel-detail-service-component-funnel-badge38">
  //                               <div className="funnel-detail-service-component-funnel-third-badge-number">
  //                                 {currentListingData.tax_clinical_application.name}
  //                                 {/* Pathology */}
  //                               </div>
  //                             </div>

  //                             {/* <div className="dashboard-service-component-dashboard-badge39">
  //                               <div className="dashboard-service-component-dashboard-third-badge-number">
  //                                 Accounting
  //                               </div>
  //                             </div> */}

  //                           </div>

  //                         </div>


  //                         <div className="funnel-detail-service-component-funnel-filter-elements">
  //                           <div className="funnel-detail-service-component-funnel-dropdown10" onClick={() => toggleThreeDotsDropdown()} ref={threeDotsRef}>
  //                             <div className="funnel-detail-service-component-funnel-dropdown10" >

  //                               <img
  //                                 className="funnel-detail-service-component-funnel-dots-vertical-icon"
  //                                 alt=""
  //                                 src={dotsvertical}
  //                               />
  //                               {activeThreeDot && <ThreeDotsListingComponent url={`device/${currentListingData.id}`} />}
  //                             </div>
  //                           </div>
  //                           <div className="funnel-detail-service-component-funnel-filter-elements-inner">
  //                             <div className="funnel-detail-service-component-funnel-price-parent">
  //                               <TransactionTypeComponent transactionType={currentListingData.tax_transaction_type.name} slug={currentListingData.tax_transaction_type.slug} />
  //                               {/* <TransactionTypeComponent transactionType={"Sale"} slug={"sale"} /> */}

  //                               <div className="funnel-detail-service-component-funnel-price2">${formatNumber(currentListingData.price)}</div>
  //                               {/* <div className="funnel-detail-service-component-funnel-price2">$258</div> */}

  //                               <div className="funnel-detail-service-component-funnel-dropdown-elements">
  //                                 <div className="funnel-detail-service-component-funnel-dropdown-options-parent">
  //                                   <div className="funnel-detail-service-component-funnel-text83">{currentListingData.tax_unit_measure_device_spare.name}</div>
  //                                   {/* <div className="funnel-detail-service-component-funnel-text83">USD - Based on terms of exhange</div> */}

  //                                   <div className="funnel-detail-service-component-funnel-frame-wrapper3">
  //                                     <div className="funnel-detail-service-component-funnel-radar-1-1-container">
  //                                       <img
  //                                         className="funnel-detail-service-component-funnel-magnifier-1-icon"
  //                                         alt=""
  //                                         src={radar_1_1_2x}
  //                                       />

  //                                       <img
  //                                         className="funnel-detail-service-component-funnel-magnifier-1-icon"
  //                                         alt=""
  //                                         src={deal_2_1_2x}
  //                                       />

  //                                       <img
  //                                         className="funnel-detail-service-component-funnel-magnifier-1-icon"
  //                                         alt=""
  //                                         src={magnifier_1_2x}
  //                                       />
  //                                     </div>
  //                                   </div>
  //                                 </div>
  //                               </div>
  //                             </div>
  //                           </div>
  //                         </div>


  //                       </div>
  //                     </div>

  //                     <img className="funnel-detail-service-component-funnel-divider-icon8" alt="" src={divider2} />

  //                     <div className="funnel-detail-service-component-funnel-metric-item-parent">

  //                       <div className="funnel-detail-service-component-funnel-metric-item3">
  //                         <div className="funnel-detail-service-component-funnel-heading9">Active engagement</div>
  //                         <div className="funnel-detail-service-component-funnel-number-and-chart">
  //                           <div className="funnel-detail-service-component-funnel-number-and-badge3">
  //                             <div className="funnel-detail-service-component-funnel-heading">{currentListingData.totalActiveEngagement || 0}</div>
  //                             <div className="funnel-detail-service-component-funnel-change-and-text3">
  //                               <div className="funnel-detail-service-component-funnel-change2">
  //                                 <img
  //                                   className="funnel-detail-service-component-funnel-upload-04-icon"
  //                                   alt=""
  //                                   src={arrowup}
  //                                 />

  //                                 <div className="funnel-detail-service-component-funnel-change7">100%</div>
  //                               </div>
  //                               <div className="funnel-detail-service-component-funnel-text139">vs last month</div>
  //                             </div>
  //                           </div>
  //                           <img
  //                             className="funnel-detail-service-component-funnel-chart-mini-icon3"
  //                             alt=""
  //                             src={_chart_mini2}

  //                           />
  //                         </div>
  //                         <div className="funnel-detail-service-component-funnel-dropdown19">
  //                           <img
  //                             className="funnel-detail-service-component-funnel-dots-vertical-icon"
  //                             alt=""
  //                             src={dotsvertical}
  //                           />
  //                         </div>
  //                       </div>

  //                       <div className="funnel-detail-service-component-funnel-metric-item3">
  //                         <div className="funnel-detail-service-component-funnel-heading9">Views</div>
  //                         <div className="funnel-detail-service-component-funnel-number-and-chart">
  //                           <div className="funnel-detail-service-component-funnel-number-and-badge3">
  //                             <div className="funnel-detail-service-component-funnel-heading">{currentListingData.totalCountView}</div>
  //                             <div className="funnel-detail-service-component-funnel-change-and-text3">
  //                               <div className="funnel-detail-service-component-funnel-change2">
  //                                 <img
  //                                   className="funnel-detail-service-component-funnel-upload-04-icon"
  //                                   alt=""
  //                                   src={arrowup}
  //                                 />

  //                                 <div className="funnel-detail-service-component-funnel-change7">100%</div>
  //                               </div>
  //                               <div className="funnel-detail-service-component-funnel-text139">vs last month</div>
  //                             </div>
  //                           </div>
  //                           <img
  //                             className="funnel-detail-service-component-funnel-chart-mini-icon3"
  //                             alt=""
  //                             src={_chart_mini2}

  //                           />
  //                         </div>
  //                         <div className="funnel-detail-service-component-funnel-dropdown19">
  //                           <img
  //                             className="funnel-detail-service-component-funnel-dots-vertical-icon"
  //                             alt=""
  //                             src={dotsvertical}
  //                           />
  //                         </div>
  //                       </div>
  //                       <div className="funnel-detail-service-component-funnel-metric-item3">
  //                         <div className="funnel-detail-service-component-funnel-heading9">Added to Radar</div>
  //                         <div className="funnel-detail-service-component-funnel-number-and-chart">
  //                           <div className="funnel-detail-service-component-funnel-number-and-badge3">
  //                             <div className="funnel-detail-service-component-funnel-heading">{currentListingData.totalCountRadar}</div>
  //                             <div className="funnel-detail-service-component-funnel-change-and-text3">
  //                               <div className="funnel-detail-service-component-funnel-change2">
  //                                 <img
  //                                   className="funnel-detail-service-component-funnel-upload-04-icon"
  //                                   alt=""
  //                                   src={arrowup}
  //                                 />

  //                                 <div className="funnel-detail-service-component-funnel-change7">100%</div>
  //                               </div>
  //                               <div className="funnel-detail-service-component-funnel-text139">vs last month</div>
  //                             </div>
  //                           </div>
  //                           <img
  //                             className="funnel-detail-service-component-funnel-chart-mini-icon3"
  //                             alt=""
  //                             src={_chart_mini2}
  //                           />
  //                         </div>
  //                         <div className="funnel-detail-service-component-funnel-dropdown19">
  //                           <img
  //                             className="funnel-detail-service-component-funnel-dots-vertical-icon"
  //                             alt=""
  //                             src={dotsvertical}
  //                           />
  //                         </div>
  //                       </div>
  //                       <div className="funnel-detail-service-component-funnel-metric-item3">
  //                         <div className="funnel-detail-service-component-funnel-heading9">Searches</div>
  //                         <div className="funnel-detail-service-component-funnel-number-and-chart">
  //                           <div className="funnel-detail-service-component-funnel-number-and-badge3">
  //                             <div className="funnel-detail-service-component-funnel-heading">{currentListingData.totalSearch || 0}</div>
  //                             <div className="funnel-detail-service-component-funnel-change-and-text3">
  //                               <div className="funnel-detail-service-component-funnel-change2">
  //                                 <img
  //                                   className="funnel-detail-service-component-funnel-upload-04-icon"
  //                                   alt=""
  //                                   src={arrowup}
  //                                 />

  //                                 <div className="funnel-detail-service-component-funnel-change7">100%</div>
  //                               </div>
  //                               <div className="funnel-detail-service-component-funnel-text139">vs last month</div>
  //                             </div>
  //                           </div>
  //                           <img
  //                             className="funnel-detail-service-component-funnel-chart-mini-icon3"
  //                             alt=""
  //                             src={_chart_mini2}
  //                           />
  //                         </div>
  //                         <div className="funnel-detail-service-component-funnel-dropdown19">
  //                           <img
  //                             className="funnel-detail-service-component-funnel-dots-vertical-icon"
  //                             alt=""
  //                             src={dotsvertical}
  //                           />
  //                         </div>
  //                       </div>
  //                     </div>

  //                     <img className="funnel-detail-service-component-funnel-divider-icon8" alt="" src={divider2} />

  //                     <div className="funnel-detail-service-component-funnel-frame-parent71">
  //                       <div className="funnel-detail-service-component-funnel-buttonsbutton-parent1">
  //                         <div className="funnel-legend-series">
  //                           <img
  //                             className="funnel-location-column-child"
  //                             alt=""
  //                             src={currentListingData.profileImage}
  //                           />

  //                           <div className="funnel-location-column-inner">
  //                             <div className="funnel-date-picker-dropdown">
  //                               {/* <div className="funnel-name">{currentListingData.device_business.business_user.displayName}</div>
  //                       <div className="funnel-role">{currentListingData.device_business.businessName}</div> */}

  //                               <div className="funnel-name">{currentListingData.device_business.business_user.displayName}</div>
  //                               <Link className="funnel-role" to={`/business/${currentListingData.device_business.id}/${currentListingData.device_business.businessName}`}>
  //                                 {currentListingData.device_business.businessName}
  //                               </Link>
  //                             </div>
  //                           </div>
  //                         </div>
  //                         <div className='funnel-detail-service-component-main-buttons-group'>
  //                           <div className="funnel-detail-service-component-funnel-buttonsbutton13">
  //                             <img
  //                               className="funnel-detail-service-component-funnel-arrow-narrow-left-icon"
  //                               alt=""
  //                               src={eye}
  //                             />

  //                             <div className="funnel-detail-service-component-funnel-search-input">
  //                               <div className="funnel-detail-service-component-funnel-text67">View</div>
  //                             </div>
  //                           </div>
  //                           {/* <div className="funnel-detail-service-component-funnel-buttonsbutton25">
  //                     <div className="funnel-detail-service-component-funnel-icon-wrapper">
  //                       <img className="funnel-detail-service-component-funnel-icon1" alt="" src={icon1} />
  //                     </div>
  //                     <div className="funnel-detail-service-component-funnel-search-input">
  //                       <div className="funnel-detail-service-component-funnel-text68">Sales IQ</div>
  //                     </div>
  //                     <img
  //                       className="funnel-detail-service-component-funnel-arrow-narrow-left-icon"
  //                       alt=""
  //                       src={lock04}
  //                     />
  //                   </div> */}

  //                           <Link to={"/funnel"}>
  //                             <div className="funnel-detail-service-component-funnel-buttonsbutton26">
  //                               <div className="funnel-detail-service-component-funnel-icon-wrapper">
  //                                 <img className="funnel-detail-service-component-funnel-icon1" alt="" src={icon1} />
  //                               </div>
  //                               <div className="funnel-detail-service-component-funnel-search-input">
  //                                 <div className="funnel-detail-service-component-funnel-text68">Funnel</div>
  //                               </div>
  //                               <img
  //                                 className="funnel-detail-service-component-funnel-arrow-narrow-left-icon"
  //                                 alt=""
  //                                 src={lockunlocked04}
  //                               />
  //                             </div>
  //                           </Link>


  //                           <div className="funnel-detail-service-component-funnel-buttonsbutton27">
  //                             <img
  //                               className="funnel-detail-service-component-funnel-upload-04-icon"
  //                               alt=""
  //                               src={messagechatsquare}
  //                             />

  //                             <div className="funnel-detail-service-component-funnel-text145">Messages</div>
  //                             <div className="funnel-detail-service-component-funnel-ellipse-parent">
  //                               <div className="funnel-detail-service-component-funnel-ellipse-div"></div>
  //                               <div className="funnel-detail-service-component-funnel-div">2</div>
  //                             </div>
  //                           </div>
  //                         </div>

  //                       </div>
  //                       <div className="funnel-detail-service-component-funnel-arrow-square-up-right-wrapper1">
  //                         <img
  //                           className="funnel-detail-service-component-funnel-arrow-square-up-right-icon1"
  //                           alt=""
  //                           src={arrowsquareupright}
  //                         />
  //                       </div>
  //                     </div>
  //                   </div>
  //                 </div>
  //               </div>

  //               <br />

  //               {/* Active Engagement Table */}


  //               <SalesIqActiveEngagementTable data={[]} isLoading={isViewsDataLoading} isSuccess={isViewsDataSuccess} isError={isViewsDataError} isFetching={isViewDataFetching} showLoadMore={showViewDataLoadMore} handleLoadMore={handleViewDataLoadMore} />
  //               <br />

  //               {/* Viewed Table */}

  //               <SalesIqViewTable data={currentViewData?.view_device} isLoading={isViewsDataLoading} isSuccess={isViewsDataSuccess} isError={isViewsDataError} isFetching={isViewDataFetching} showLoadMore={showViewDataLoadMore} handleLoadMore={handleViewDataLoadMore} />

  //               <br /><br />

  //               {/* Added to Radar Table */}

  //               <SalesIqRadarTable data={currentRadarData?.radar_device} isLoading={isRadarsDataLoading} isSuccess={isRadarsDataSuccess} isError={isRadarsDataError} isFetching={isRadarDataFetching} showLoadMore={showRadarDataLoadMore} handleLoadMore={handleRadarDataLoadMore} />

  //               <br /><br />

  //               {/* Searched Table */}

  //               <SalesIqSalesTable data={[]} isLoading={isViewsDataLoading} isSuccess={isViewsDataSuccess} isError={isViewsDataError} isFetching={isViewDataFetching} showLoadMore={showViewDataLoadMore} handleLoadMore={handleViewDataLoadMore} />
  //               <br />




  //             </div>

  //           </div>
  //         </div>

  //         <br /><br />

  //         <img className="funnel-detail-service-divider-icon6" alt="" src={divider3} />

  //         <Footer />


  //       </div>
  //     )
  //   }

  if (currentUser && isCurrentPlanSuccess && profileImageLink) {
    return (
      <div className="funnel-detail-service-funnel-detail-service-frame-layout">
        <Helmet>
          <title>Funnel</title>
        </Helmet>
        <div className="funnel-detail-service-funnel-detail-service-frame-layout-child"></div>

        <Navbar1 userData={currentUser} navigate={navigate} profileImageLink={profileImageLink} />

        <div className="funnel-detail-service-all">
          <img className="funnel-detail-service-divider-icon" alt="" src={divider3} />

          <div className="funnel-detail-service-all-content">

            <Sidebar>
              {/* <SideBarSubSalesIQ userData={currentUser} /> */}
            </Sidebar>

            <div className="funnel-detail-service-frame-parent">
              <div className="funnel-detail-service-main-wrapper">
                <div className="funnel-detail-service-main funnel-detail-service-main-custom">
                  <div className="funnel-detail-service-header-section">
                    <div className="funnel-detail-service-container">
                      <div className="funnel-detail-service-page-header">
                        <div className="funnel-detail-service-breadcrumbs">
                          <div className="funnel-detail-service-tabs">
                            <div className="funnel-detail-service-breadcrumb-button-base">
                              <img
                                className="funnel-detail-service-search-lg-icon"
                                alt=""
                                src={homeline1}
                              />
                            </div>
                            <img
                              className="funnel-detail-service-chevron-right-icon"
                              alt=""
                              src={chevronright}
                            />

                            <div className="funnel-detail-service-breadcrumb-button-base1">
                              <div className="funnel-detail-service-location">Funnel</div>
                            </div>
                            <img
                              className="funnel-detail-service-chevron-right-icon"
                              alt=""
                              src={chevronright}
                            />

                            <div className="funnel-detail-service-breadcrumb-button-base2">
                              <div className="funnel-detail-service-text20">...</div>
                            </div>
                            <img
                              className="funnel-detail-service-chevron-right-icon2"
                              alt=""
                              src={chevronright}
                            />

                            <div className="funnel-detail-service-breadcrumb-button-base3">
                              <div className="funnel-detail-service-text21">Another link</div>
                            </div>
                            <img
                              className="funnel-detail-service-chevron-right-icon2"
                              alt=""
                              src={chevronright}
                            />

                            <div className="funnel-detail-service-breadcrumb-button-base4">
                              <div className="funnel-detail-service-text22">Overview</div>
                            </div>
                          </div>
                        </div>



                      </div>

                    </div>
                  </div>


                </div>
              </div>
              {/* <div className="funnel-detail-service-separator"></div>
              <div className="funnel-detail-service-separator1"></div> */}

              {/* <SalesIQDetailDeviceComponent/> */}

              {/* <div className="funnel-detail-service-component-funnel-content-items" key={index}> */}
              <div className="funnel-detail-service-component-listing-container">

                {/* <div className="funnel-detail-service-component-funnel-content-items" key={index}> */}
                <div className="funnel-detail-service-component-funnel-content-items" >
                  <div className="funnel-detail-service-component-funnel-divider-container1">
                    <div className="funnel-detail-service-component-funnel-rectangle-parent5">
                      <img
                        className="funnel-detail-service-component-funnel-frame-child7"
                        alt=""
                        //   src={currentListingData.featureImage}
                        src={playstation}
                      />

                      <div className="funnel-detail-service-component-funnel-frame-parent1">
                        <div className="funnel-detail-service-component-funnel-action-fields">
                          <div className="funnel-detail-service-component-funnel-input-elements">
                            {/* <Link to={`/service/${currentListingData.id}/${currentListingData.title}`} className="funnel-detail-service-component-funnel-text78">
                                            {currentListingData.title}
                                          </Link> */}
                            <div className="funnel-detail-service-component-funnel-text78">

                              HEALTHCARE TECHNOLOGY CONSULTING - LOREM IPSUM
                            </div>
                            <div className="funnel-detail-service-component-funnel-frame-parent21">
                              <div className="funnel-detail-service-component-funnel-badge-item-parent">
                                <div className="funnel-detail-service-component-funnel-badge-parent">

                                  {/* <PostStatusLIstingComponent postStatus={currentListingData.tax_post_status.name} postSlug={currentListingData.tax_post_status.slug} postId={currentListingData.id} /> */}
                                  <PostStatusLIstingComponent postStatus={"Pending"} postSlug={"pending"} postId={1} />

                                </div>
                                <div className="funnel-detail-service-component-funnel-frame-parent4">
                                  <div className="funnel-detail-service-component-funnel-end-label">
                                    <div className="funnel-detail-service-component-funnel-search-icon-input">4.9/5</div>
                                  </div>
                                  <div className="funnel-detail-service-component-funnel-type-support">
                                    <div className="funnel-detail-service-component-funnel-supporting-text9">
                                      99,999 reviews
                                    </div>
                                  </div>
                                </div>

                              </div>
                              <div className="funnel-detail-service-component-funnel-frame-parent24">
                                <div className="funnel-detail-service-component-funnel-location-pin-parent">
                                  <div className="funnel-detail-service-component-funnel-search-input">
                                    <img
                                      className="funnel-detail-service-component-funnel-marker-pin-02-icon2"
                                      alt=""
                                      src={markerpin02}
                                    />
                                  </div>
                                  <div className="funnel-detail-service-component-funnel-location3">
                                    Okalahoma City, United States of America
                                    {/* {
                                                    (currentListingData.service_address.tax_address_type.slug === 'remote') && currentListingData.service_address.tax_address_type.name
                                                  }
              
                                                  {
                                                    (currentListingData.service_address.tax_address_type.slug === 'address' && `${currentListingData.service_address.addressTownCity}, ${currentListingData.service_address.tax_country.name}`)
                                                  } */}
                                    {/* {currentListingData.service_address.addressTownCity}, {currentListingData.service_address.tax_country.name} */}
                                  </div>
                                </div>
                                {/* <div className="funnel-detail-service-component-funnel-frame-wrapper18">
                                        <div className="funnel-detail-service-component-funnel-badge-elements-copy">
                                         
                                        </div>
                                      </div> */}
                              </div>
                              <div className="funnel-detail-service-component-funnel-frame-parent26">
                                <div className="funnel-detail-service-component-funnel-frame-wrapper19">
                                  <div className="funnel-detail-service-component-funnel-legend-series">
                                    <div className="funnel-detail-service-component-funnel-search-input">
                                      <img
                                        className="funnel-detail-service-component-funnel-marker-pin-02-icon2"
                                        alt=""
                                        src={calendar1}
                                      />
                                    </div>
                                    {/* <div className="funnel-detail-service-component-funnel-type-of-work2">{getCustomizedDate(currentListingData.createdAt)}</div> */}
                                    <div className="funnel-detail-service-component-funnel-type-of-work2">November 13,2024</div>
                                  </div>
                                </div>

                              </div>

                            </div>
                          </div>

                          <div className="funnel-detail-service-component-funnel-small-card">

                            <div className="funnel-detail-service-component-funnel-badge33">
                              <div className="funnel-detail-service-component-funnel-third-badge-number">
                                {/* {currentListingData.tax_service_category.name} */}
                                Healthcare Technology Consulting
                              </div>
                            </div>



                            <div className="funnel-detail-service-component-funnel-badge38">
                              <div className="funnel-detail-service-component-funnel-third-badge-number">
                                {/* {currentListingData.tax_clinical_application.name} */}
                                Anesthesiology
                              </div>
                            </div>




                            <div className="funnel-detail-service-component-funnel-badge37">
                              <div className="funnel-detail-service-component-funnel-third-badge-number">
                                {/* {currentListingData.tax_service_and_support_reach.name} */}
                                North America
                              </div>
                            </div>


                          </div>




                        </div>





                      </div>


                      <div className="funnel-detail-service-component-funnel-filter-elements">
                        {/* {(currentListingData.tax_post_status.slug !== 'pending') ? */}
                        <div className="funnel-detail-service-component-funnel-dropdown10" onClick={() => toggleThreeDotsDropdown()} ref={threeDotsRef}>
                          <div className="funnel-detail-service-component-funnel-dropdown10" >

                            <img
                              className="funnel-detail-service-component-funnel-dots-vertical-icon"
                              alt=""
                              src={dotsvertical}
                            />


                            {/* {activeThreeDot && <ThreeDotsListingComponent url={`service/${currentListingData.id}`} />} */}
                          </div>
                        </div>
                        {/* :
                                      <div className="funnel-detail-service-component-funnel-dropdown10" >
                                        <img
                                      className="funnel-detail-service-component-funnel-dots-vertical-icon"
                                      alt=""
                                      src={dotsvertical}
                                    />
              
              
                                        {activeThreeDotId === index && <ThreeDotsListingComponent url={`service/${currentListingData.id}`} />}
                                      </div> */}

                        {/* } */}
                        <div className="funnel-detail-service-component-funnel-filter-elements-inner">
                          <div className="funnel-detail-service-component-funnel-price-parent">
                            {/* <TransactionTypeComponent transactionType={currentListingData.tax_transaction_type.name} slug={currentListingData.tax_transaction_type.slug} /> */}
                            <div className="funnel-detail-service-component-funnel-text83">Starting From</div>
                            {/* <div className="funnel-detail-service-component-funnel-price2">${formatNumber(currentListingData.rangeMin)}</div> */}
                            <div className="funnel-detail-service-component-funnel-price2">$1</div>

                            <div className="funnel-detail-service-component-funnel-dropdown-elements">
                              <div className="funnel-detail-service-component-funnel-dropdown-options-parent">
                                {/* <div className="funnel-detail-service-component-funnel-text83">{currentListingData.tax_unit_measure_service.name}</div> */}
                                <div className="funnel-detail-service-component-funnel-text83">USD - Per Kilometere</div>

                                <div className="funnel-detail-service-component-funnel-frame-wrapper3">
                                  <div className="funnel-detail-service-component-funnel-radar-1-1-container">
                                    <img
                                      className="funnel-detail-service-component-funnel-magnifier-1-icon"
                                      alt=""
                                      src={radar_1_1_2x}
                                    />

                                    <img
                                      className="funnel-detail-service-component-funnel-magnifier-1-icon"
                                      alt=""
                                      src={deal_2_1_2x}
                                    />

                                    <img
                                      className="funnel-detail-service-component-funnel-magnifier-1-icon"
                                      alt=""
                                      src={magnifier_1_2x}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                    <img className="funnel-detail-service-component-funnel-divider-icon8" alt="" src={divider2} />

                    <div className="funnel-detail-service-component-funnel-metric-item-parent">

                      <div className="funnel-detail-service-component-funnel-metric-item3">
                        <div className="funnel-detail-service-component-funnel-heading9">Active engagement</div>
                        <div className="funnel-detail-service-component-funnel-number-and-chart">
                          <div className="funnel-detail-service-component-funnel-number-and-badge3">
                            {/* <div className="funnel-detail-service-component-funnel-heading">{currentListingData.totalActiveEngagement || 0}</div> */}
                            <div className="funnel-detail-service-component-funnel-heading">5</div>

                            <div className="funnel-detail-service-component-funnel-change-and-text3">
                              <div className="funnel-detail-service-component-funnel-change2">
                                <img
                                  className="funnel-detail-service-component-funnel-upload-04-icon"
                                  alt=""
                                  src={arrowup}
                                />

                                <div className="funnel-detail-service-component-funnel-change7">100%</div>
                              </div>
                              <div className="funnel-detail-service-component-funnel-text139">vs last month</div>
                            </div>
                          </div>
                          <img
                            className="funnel-detail-service-component-funnel-chart-mini-icon3"
                            alt=""
                            src={_chart_mini2}

                          />
                        </div>
                        <div className="funnel-detail-service-component-funnel-dropdown19">
                          <img
                            className="funnel-detail-service-component-funnel-dots-vertical-icon"
                            alt=""
                            src={dotsvertical}
                          />
                        </div>
                      </div>

                      <div className="funnel-detail-service-component-funnel-metric-item3">
                        <div className="funnel-detail-service-component-funnel-heading9">Views</div>
                        <div className="funnel-detail-service-component-funnel-number-and-chart">
                          <div className="funnel-detail-service-component-funnel-number-and-badge3">
                            {/* <div className="funnel-detail-service-component-funnel-heading">{currentListingData.totalCountView}</div> */}
                            <div className="funnel-detail-service-component-funnel-heading">3</div>

                            <div className="funnel-detail-service-component-funnel-change-and-text3">
                              <div className="funnel-detail-service-component-funnel-change2">
                                <img
                                  className="funnel-detail-service-component-funnel-upload-04-icon"
                                  alt=""
                                  src={arrowup}
                                />

                                <div className="funnel-detail-service-component-funnel-change7">100%</div>
                              </div>
                              <div className="funnel-detail-service-component-funnel-text139">vs last month</div>
                            </div>
                          </div>
                          <img
                            className="funnel-detail-service-component-funnel-chart-mini-icon3"
                            alt=""
                            src={_chart_mini2}

                          />
                        </div>
                        <div className="funnel-detail-service-component-funnel-dropdown19">
                          <img
                            className="funnel-detail-service-component-funnel-dots-vertical-icon"
                            alt=""
                            src={dotsvertical}
                          />
                        </div>
                      </div>
                      <div className="funnel-detail-service-component-funnel-metric-item3">
                        <div className="funnel-detail-service-component-funnel-heading9">Added to Radar</div>
                        <div className="funnel-detail-service-component-funnel-number-and-chart">
                          <div className="funnel-detail-service-component-funnel-number-and-badge3">
                            {/* <div className="funnel-detail-service-component-funnel-heading">{currentListingData.totalCountRadar}</div> */}
                            <div className="funnel-detail-service-component-funnel-heading">2</div>

                            <div className="funnel-detail-service-component-funnel-change-and-text3">
                              <div className="funnel-detail-service-component-funnel-change2">
                                <img
                                  className="funnel-detail-service-component-funnel-upload-04-icon"
                                  alt=""
                                  src={arrowup}
                                />

                                <div className="funnel-detail-service-component-funnel-change7">100%</div>
                              </div>
                              <div className="funnel-detail-service-component-funnel-text139">vs last month</div>
                            </div>
                          </div>
                          <img
                            className="funnel-detail-service-component-funnel-chart-mini-icon3"
                            alt=""
                            src={_chart_mini2}
                          />
                        </div>
                        <div className="funnel-detail-service-component-funnel-dropdown19">
                          <img
                            className="funnel-detail-service-component-funnel-dots-vertical-icon"
                            alt=""
                            src={dotsvertical}
                          />
                        </div>
                      </div>
                      <div className="funnel-detail-service-component-funnel-metric-item3">
                        <div className="funnel-detail-service-component-funnel-heading9">Searches</div>
                        <div className="funnel-detail-service-component-funnel-number-and-chart">
                          <div className="funnel-detail-service-component-funnel-number-and-badge3">
                            {/* <div className="funnel-detail-service-component-funnel-heading">{currentListingData.totalSearch || 0}</div> */}
                            <div className="funnel-detail-service-component-funnel-heading">1</div>

                            <div className="funnel-detail-service-component-funnel-change-and-text3">
                              <div className="funnel-detail-service-component-funnel-change2">
                                <img
                                  className="funnel-detail-service-component-funnel-upload-04-icon"
                                  alt=""
                                  src={arrowup}
                                />

                                <div className="funnel-detail-service-component-funnel-change7">100%</div>
                              </div>
                              <div className="funnel-detail-service-component-funnel-text139">vs last month</div>
                            </div>
                          </div>
                          <img
                            className="funnel-detail-service-component-funnel-chart-mini-icon3"
                            alt=""
                            src={_chart_mini2}
                          />
                        </div>
                        <div className="funnel-detail-service-component-funnel-dropdown19">
                          <img
                            className="funnel-detail-service-component-funnel-dots-vertical-icon"
                            alt=""
                            src={dotsvertical}
                          />
                        </div>
                      </div>
                    </div>

                    <img className="funnel-detail-service-component-funnel-divider-icon8" alt="" src={divider2} />

                    <div className="funnel-detail-service-component-funnel-frame-parent71">
                      <div className="funnel-detail-service-component-funnel-buttonsbutton-parent1">
                        <div className="funnel-legend-series">
                          <img
                            className="funnel-location-column-child"
                            alt=""
                            //   src={currentListingData.profileImage}
                            src={playstation}
                          />

                          <div className="funnel-location-column-inner">
                            <div className="funnel-date-picker-dropdown">
                              {/* <div className="funnel-name">{currentListingData.service_business.business_user.displayName}</div>
                                    <div className="funnel-role">{currentListingData.service_business.businessName}</div> */}
                              {/* <div className="funnel-name">{currentListingData.service_business.business_user.displayName}</div>
                                            <Link className="funnel-role" to={`/business/${currentListingData.service_business.id}/${currentListingData.service_business.businessName}`}>
                                              {currentListingData.service_business.businessName}
                                            </Link> */}
                              <div className="funnel-name">Prithvi Raj</div>
                              <div className="funnel-role">Gerator</div>
                            </div>
                          </div>
                        </div>
                        <div className='funnel-detail-service-component-main-buttons-group'>
                          <div className="funnel-detail-service-component-funnel-buttonsbutton13">
                            <img
                              className="funnel-detail-service-component-funnel-arrow-narrow-left-icon"
                              alt=""
                              src={eye}
                            />

                            <div className="funnel-detail-service-component-funnel-search-input">
                              <div className="funnel-detail-service-component-funnel-text67">View</div>
                            </div>
                          </div>
                          {/* <div className="funnel-detail-service-component-funnel-buttonsbutton25">
                                          <div className="funnel-detail-service-component-funnel-icon-wrapper">
                                            <img className="funnel-detail-service-component-funnel-icon1" alt="" src={icon1} />
                                          </div>
                                          <div className="funnel-detail-service-component-funnel-search-input">
                                            <div className="funnel-detail-service-component-funnel-text68">Sales IQ</div>
                                          </div>
                                          <img
                                            className="funnel-detail-service-component-funnel-arrow-narrow-left-icon"
                                            alt=""
                                            src={lock04}
                                          />
                                        </div> */}
                          <Link to={"/funnel"}>
                            <div className="funnel-detail-service-component-funnel-buttonsbutton26">
                              <div className="funnel-detail-service-component-funnel-icon-wrapper">
                                <img className="funnel-detail-service-component-funnel-icon1" alt="" src={icon1} />
                              </div>
                              <div className="funnel-detail-service-component-funnel-search-input">
                                <div className="funnel-detail-service-component-funnel-text68">Sales IQ</div>
                              </div>
                              <img
                                className="funnel-detail-service-component-funnel-arrow-narrow-left-icon"
                                alt=""
                                src={lockunlocked04}
                              />
                            </div>
                          </Link>
                          <div className="funnel-detail-service-component-funnel-buttonsbutton27">
                            <img
                              className="funnel-detail-service-component-funnel-upload-04-icon"
                              alt=""
                              src={messagechatsquare}
                            />

                            <div className="funnel-detail-service-component-funnel-text145">Messages</div>
                            <div className="funnel-detail-service-component-funnel-ellipse-parent">
                              <div className="funnel-detail-service-component-funnel-ellipse-div"></div>
                              <div className="funnel-detail-service-component-funnel-div">2</div>
                            </div>
                          </div>
                        </div>

                      </div>
                      <div className="funnel-detail-service-component-funnel-arrow-square-up-right-wrapper1">
                        <img
                          className="funnel-detail-service-component-funnel-arrow-square-up-right-icon1"
                          alt=""
                          src={arrowsquareupright}
                        />
                      </div>
                    </div>
                  </div>
                </div>

              </div>

              <br />

              {/* Funnel Table*/}

              <FunnelTable />

              {/* <SalesIqActiveEngagementTable data={[]} isLoading={isViewsDataLoading} isSuccess={isViewsDataSuccess} isError={isViewsDataError} isFetching={isViewDataFetching} showLoadMore={showViewDataLoadMore} handleLoadMore={handleViewDataLoadMore} />
              <br /> */}









            </div>

          </div>
        </div>

        <br /><br />

        <img className="funnel-detail-service-divider-icon6" alt="" src={divider3} />

        <Footer />


      </div>
    )
  }
  else {
    return <Loader />
  }


}

