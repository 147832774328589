import { AddWantedSoftware } from "../../screens/WantedSoftware/AddWantedSoftware/AddWantedSoftware";
import { ArchiveWantedSoftware } from "../../screens/WantedSoftware/ArchiveWantedSoftware/ArchiveWantedSoftware";
import { DetailedWantedSoftware } from "../../screens/WantedSoftware/DetailedWantedSoftware/DetailedWantedSoftware";
import { EditWantedSoftware } from "../../screens/WantedSoftware/EditWantedSoftware/EditWantedSoftware";

export const wantedSoftwareRoutes = [
    {
        path: "/wanted-softwares",
        element: <ArchiveWantedSoftware />,
    },
    {
        path: "/add-wanted-software",
        element: <AddWantedSoftware />,
    },
    {
        path: "/edit-wanted-software/:id/*",
        element: <EditWantedSoftware />,
    },
    {
        path: "/wanted-software/:id/*",
        element: <DetailedWantedSoftware />,
    }
]