const slides = [
  { src: require('../../icons/Gerator/detailed_listing/breadcrumb-controls@2x.png') },
  { src: require('../../icons/Gerator/detailed_listing/breadcrumb-controls1@2x.png') },
  { src: require('../../icons/Gerator/detailed_listing/breadcrumb-controls2@2x.png') },
  { src: require('../../icons/Gerator/detailed_listing/breadcrumb-controls1@2x.png') },
  { src: require('../../icons/Gerator/detailed_listing/breadcrumb-controls2@2x.png') },
  { src: require('../../icons/Gerator/detailed_listing/breadcrumb-controls@2x.png') },
  { src: require('../../icons/Gerator/detailed_listing/breadcrumb-controls1@2x.png') },
  { src: require('../../icons/Gerator/detailed_listing/breadcrumb-controls2@2x.png') },
  { src: require('../../icons/Gerator/detailed_listing/breadcrumb-controls1@2x.png') },
  { src: require('../../icons/Gerator/detailed_listing/breadcrumb-controls2@2x.png') }
];

export default slides;