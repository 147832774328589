import { initializeApp } from 'firebase/app';
import { getAuth, signOut, signInWithEmailAndPassword, sendPasswordResetEmail, GoogleAuthProvider, FacebookAuthProvider, signInWithPopup, confirmPasswordReset, verifyPasswordResetCode, fetchSignInMethodsForEmail, onAuthStateChanged, sendEmailVerification, applyActionCode, checkActionCode } from 'firebase/auth';
import { doc, getDoc, getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDVfcLS1UlGYpyimuddfXFBelCtu9ixJiw",
  authDomain: "gerator-c4e78.firebaseapp.com",
  projectId: "gerator-c4e78",
  storageBucket: "gerator-c4e78.appspot.com",
  messagingSenderId: "515808424584",
  appId: "1:515808424584:web:b6d2f021ae8435c2d4a53d",
  measurementId: "G-L2Y0SNHQKF"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// // Initialize Auth
const auth = getAuth(app);

// Initialize Firestore
const firestore = getFirestore(app);

// // Example usage of auth
// onAuthStateChanged(auth, (user) => {
//   if (user) {
//     console.log('User is logged in:', user);
//   } else {
//     console.log('No user is logged in');
//   }
// });

export { app, auth, getAuth, signOut, signInWithEmailAndPassword, sendPasswordResetEmail, GoogleAuthProvider, FacebookAuthProvider, signInWithPopup, confirmPasswordReset, verifyPasswordResetCode, fetchSignInMethodsForEmail, onAuthStateChanged, sendEmailVerification, applyActionCode, checkActionCode, firestore, doc, getDoc, };