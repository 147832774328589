import React from 'react';
import "./ArchiveService.css"
import { Navbar1 } from '../../../components/Navbar1/Navbar1';
import { FooterAddForms } from '../../../components/Footer/FooterAddForms/FooterAddForms';
import React, { useEffect, useState } from "react";

import { getCustomizedDate } from "../../../helper/ServiceHelper";
import { Loader } from "../../../components/Loader";
import { Link, useNavigate } from "react-router-dom";
import divider from '../../../icons/Gerator/archive_page/divider.svg';
import divider1 from '../../../icons/Gerator/archive_page/divider1.svg';

import divider2 from '../../../icons/Gerator/archive_page/divider2.svg';
import divider4 from '../../../icons/Gerator/archive_page/divider-4.svg';
import divider12 from '../../../icons/Gerator/archive_page/divider-12.svg';
import divider13 from '../../../icons/Gerator/archive_page/divider-13.svg';
import divider21 from '../../../icons/Gerator/archive_page/divider-21.svg';

import eye from '../../../icons/Gerator/archive_page/eye.svg';

import rectangle_41_2x from '../../../icons/Gerator/archive_page/rectangle-41@2x.png';

import dotsvertical from '../../../icons/Gerator/archive_page/dotsvertical.svg';
import dot1 from '../../../icons/Gerator/archive_page/-dot1.svg';
import dot from '../../../icons/Gerator/archive_page/-dot.svg';

import help_icon2 from '../../../icons/Gerator/archive_page/help-icon2.svg';
import help_icon from '../../../icons/Gerator/archive_page/help-icon.svg';


import featured_icon1 from '../../../icons/Gerator/archive_page/featured-icon1.svg';


import chevronright from '../../../icons/Gerator/archive_page/chevronright.svg';
import chevrondown from '../../../icons/Gerator/archive_page/chevrondown.svg';

import placeholder from '../../../icons/Gerator/archive_page/placeholder.svg';
import placeholder1 from '../../../icons/Gerator/archive_page/placeholder1.svg';
import placeholder2 from '../../../icons/Gerator/archive_page/placeholder2.svg';
import placeholder3 from '../../../icons/Gerator/archive_page/placeholder3.svg';

import homeline from '../../../icons/Gerator/archive_page/homeline.svg';
import searchlg from '../../../icons/Gerator/archive_page/searchlg.svg';

import calendar from '../../../icons/Gerator/archive_page/calendar.svg';

import target_04 from "../../../icons/Gerator/dashboard/target_04.png"
import radar_1_1_2x from '../../../icons/Gerator/archive_page/radar-1-1@2x.png';
import deal_2_1_2x from '../../../icons/Gerator/archive_page/deal-2-1@2x.png';
import magnifier_1_2x from '../../../icons/Gerator/archive_page/magnifier-1@2x.png';

import group_58_2x from '../../../icons/Gerator/archive_page/group-58@2x.png';

import dotsvertical from '../../../icons/Gerator/archive_page/dotsvertical.svg';
import filterfunnel01 from '../../../icons/Gerator/archive_page/filterfunnel01.svg';
import searchlg from '../../../icons/Gerator/archive_page/searchlg.svg';
import filterlines from '../../../icons/Gerator/archive_page/filterlines.svg';
import dot1 from '../../../icons/Gerator/archive_page/-dot1.svg';
import xclose from '../../../icons/Gerator/archive_page/xclose.svg';
import xclose10 from '../../../icons/Gerator/archive_page/xclose-10.svg';

import lock04 from '../../../icons/Gerator/archive_page/lock04.svg';
import lockunlocked04 from '../../../icons/Gerator/archive_page/lockunlocked04.svg';
import arrowsquareupright from '../../../icons/Gerator/archive_page/arrowsquareupright.svg';
import markerpin02 from '../../../icons/Gerator/archive_page/markerpin02.svg';
import icon1 from '../../../icons/Gerator/archive_page/icon-1.svg';
import sliders02 from '../../../icons/Gerator/archive_page/sliders02.svg';
import { useLazyGetUserQuery } from "../../../api/Login&RegisterAPI";
import { auth, onAuthStateChanged } from '../../Login/FirebaseClient';
import { decryptErrorData } from "../../../helper/AESHelper";
import { useDeleteServiceMutation, useLazyGetServicesQuery } from '../../../api/ServiceAPI';
import { useAddRadarServiceMutation, useDeleteRadarServiceMutation } from '../../../api/Radar/RadarServiceAPI';
import { Helmet } from 'react-helmet-async';

export const ArchiveService = () => {

  let dataLength = 0;
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState("");


  const [deleteService] = useDeleteServiceMutation();
  // const [triggerGetUser, { data, isSuccess, error, isLoading, isError }] = useLazyGetUserQuery();
  const [triggerGetService, { data: serviceData,
    isLoading: isServiceLoading,
    isSuccess: isServiceSuccess,
    isError: isServiceError,
    error: serviceError }] = useLazyGetServicesQuery();

  const [addToRadar] = useAddRadarServiceMutation();

  const [deleteFromRadar] = useDeleteRadarServiceMutation();

  let content;
  // const [totalListings, setTotalListings] = useState("0");

  const handleRemoveRadar = async (serviceId) => {
    await deleteFromRadar(serviceId);
    window.location.reload();
  };

  const handleAddRadar = async (serviceId) => {
    await addToRadar(serviceId);
    window.location.reload();
  };

  useEffect(() => {
    // const auth = getAuth();

    // Listen for authentication state change
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is authenticated
        setCurrentUser(user);
        // triggerGetUser();
        triggerGetService();
      } else {
        // User is not authenticated, redirect to login
        navigate('/sign-in', { state: { message: 'Please log in to continue.' } });
      }
    });

    return () => unsubscribe(); // Cleanup listener on component unmount
  }, [navigate, triggerGetService]);



  if (isServiceSuccess) {

    if (serviceData.data.length > 0) {
      dataLength = serviceData.data.length;
      content = serviceData.data.map((service, index) =>
      (
        <div className="archive-service-rectangle-group" key={index}>
          <div className="archive-service-rectangle-container">
            <img
              className="archive-service-frame-inner"
              alt=""
              src={service.featureImage}
            />

            <div className="archive-service-dots-vertical-container">
              <img
                className="archive-service-dots-vertical-icon1"
                alt=""
                src={dotsvertical}
              />

              <div className="archive-service-text-parent">
                <Link to={`/service/${service.id}/${service.title}`} className="archive-service-text37 ">
                  {service.title}
                </Link>
                {/* <div className="archive-service-text37 ">
                    New / Surplus 2021 Philips Healthcare HD11 XE (P/N:23A10) for
                    abalatio...
                  </div> */}
                <div className="archive-service-frame-group">
                  <div className="archive-service-frame-container">
                    <div className="archive-service-frame-div">
                      <div className="archive-service-frame-parent1">
                        <div className="archive-service-badge-parent">
                          <div className="archive-service-badge9">
                            <img
                              className="archive-service-dot-icon3"
                              alt=""
                              src={dot1}
                            />

                            <div className="archive-service-price-value">Pending</div>
                          </div>
                          <div className="archive-service-supporting-text4">ID# {service.id}</div>
                        </div>
                        <div className="archive-service-deal-type-parent">
                          <div className="archive-service-completed">
                            <div className="archive-service-second-value">4.9/5</div>
                          </div>
                          <div className="archive-service-supporting-text-wrapper">
                            <div className="archive-service-supporting-text3">99,999 reviews</div>
                          </div>
                        </div>
                        <div className="archive-service-badge10">
                          <div className="archive-service-third-badge-count archive-service-text-overflow">
                            {service.tax_device_category.name}
                          </div>
                        </div>
                      </div>
                      <div className="archive-service-deal-location-parent">
                        <div className="archive-service-dot-item-location-parent">
                          <div className="archive-service-navigation">
                            <img
                              className="archive-service-marker-pin-02-icon"
                              alt=""
                              src={markerpin02}
                            />
                          </div>
                          {service.service_address.tax_address_type
                            .slug === "address" ?
                            <div className="archive-service-location">{service.service_address.addressTownCity},  {service.service_address.tax_country.name}</div>
                            :
                            <div className="archive-service-location">{service.service_address.tax_address_type
                              .name}</div>
                          }
                          {/* <div className="archive-service-location">{service.location}</div> */}
                        </div>
                        <div className="archive-service-frame-wrapper1">
                          <div className="archive-service-badge-group">
                            <div className="archive-service-badge11">
                              <div className="archive-service-text33">11 Health</div>
                            </div>
                            <div className="archive-service-badge12">
                              <div className="archive-service-sub-badge archive-service-text-overflow">{service.tax_service_category.name}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="archive-service-card-work">
                        <div className="archive-service-inner-work">
                          <div className="archive-service-dot-item-type-parent">
                            <div className="archive-service-navigation">
                              <img
                                className="archive-service-marker-pin-02-icon"
                                alt=""
                                src={calendar}
                              />
                            </div>
                            <div className="archive-service-type-of-work archive-service-text-overflow">{getCustomizedDate(service.createdAt)}</div>
                          </div>
                        </div>
                        <div className="archive-service-badge13">
                          <div className="archive-service-third-badge-count">
                            {service.tax_purpose_use.name}
                          </div>
                        </div>
                      </div>
                      <div className="archive-service-badge14">
                        <div className="archive-service-text36 archive-service-text-overflow">{service.tax_clinical_application.name}</div>
                      </div>
                    </div>
                  </div>
                  <div className="archive-service-second-price">
                    <div className="archive-service-price">${service.rangeMin} - {service.rangeMax}</div>
                    <div className="archive-service-price-value1">
                      <div className="archive-service-deal-title">{service.tax_unit_measure_service.name}</div>
                    </div>
                    <div className="archive-service-inner-icons-wrapper">
                      <div className="archive-service-inner-icons">
                        {/* <img
                          className="archive-service-magnifier-1-icon"
                          alt=""
                          src={radar_1_1_2x}
                        /> */}
                        {!service.isAuthor && (
                          service.addedToRadar ? (
                            <img
                              className="archive-service-magnifier-1-icon"
                              alt=""
                              onClick={() => handleRemoveRadar(service.id)}
                              src={target_04}
                              style={{ cursor: "pointer" }}
                            />
                          ) : (
                            <img
                              className="archive-service-magnifier-1-icon"
                              alt=""
                              onClick={() => handleAddRadar(service.id)}
                              src={radar_1_1_2x}
                              style={{ cursor: "pointer" }}
                            />
                          )
                        )}
                        <img
                          className="archive-service-magnifier-1-icon"
                          alt=""
                          src={deal_2_1_2x}
                        />

                        <img
                          className="archive-service-magnifier-1-icon"
                          alt=""
                          src={magnifier_1_2x}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="archive-service-divider-parent">
            <img className="archive-service-divider-icon4" alt="" src={divider2} />

            <div className="archive-service-user-listings">
              <div className="archive-service-frame-parent3">
                <div className="archive-service-frame-parent4">
                  <img
                    className="archive-service-user-content-child"
                    alt=""
                    src={group_58_2x}
                  />

                  <div className="archive-service-inner-info-wrapper">
                    <div className="archive-service-inner-info">
                      <div className="archive-service-name">{service.service_business.business_user.displayName}</div>
                      {/* <div className="archive-service-role1">{service.service_business.businessName}</div> */}
                      <Link className="archive-service-role1" to={`/business/${service.service_business.id}/${service.service_business.businessName}`}>
                        {service.service_business.businessName}
                      </Link>
                    </div>
                  </div>
                </div>
                {/* <div className="archive-service-buttonsbutton-wrapper">
                  <Link to={"/edit-device/" + service.id}>
                    <button className="archive-service-buttonsbutton1">
                      <img className="archive-service-eye-icon" alt="" src={eye} />

                      <div className="archive-service-navigation">
                        <div className="archive-service-view-label">Edit</div>
                      </div>
                    </button>
                  </Link>

                </div>
                <div className="archive-service-logo-wrapper">
                  <div className="archive-service-buttonsbutton2" onClick={() => handleDelete(service.id)} style={{ cursor: 'pointer' }}>
                    <div className="archive-service-icon-wrapper">
                      <img className="archive-service-icon1" alt="" src={icon1} />
                    </div>
                    <div className="archive-service-navigation">
                      <div className="archive-service-lock-label">Delete</div>
                    </div>
                    <img className="archive-service-eye-icon" alt="" src={lock04} />
                  </div>
                </div>
                <div className="archive-service-logo-wrapper">
                  <div className="archive-service-buttonsbutton3">
                    <div className="archive-service-icon-wrapper">
                      <img className="archive-service-icon1" alt="" src={icon1} />
                    </div>
                    <div className="archive-service-navigation">
                      <div className="archive-service-lock-label">Sales IQ</div>
                    </div>
                    <img
                      className="archive-service-eye-icon"
                      alt=""
                      src={lockunlocked04}
                    />
                  </div>
                </div> */}
              </div>
              <div className="archive-service-arrow-square-up-right-wrapper">
                <img
                  className="archive-service-frame-child1"
                  alt=""
                  src={arrowsquareupright}
                />
              </div>
            </div>
          </div>
        </div>
      ))
    }
    else {
      content = (

        <div className="content-44" >
          <img
            className="bifocal-optometry-2"
            alt="Bifocal optometry"
            src="/img/bifocal-optometry-measurement-service-2.png"
          />
          <div className="text-and-supporting-16" style={{ alignSelf: "unset !important" }}>
            <div className="text-157">No listings found</div>
            <p className="supporting-text-36">
              Your search “Landing page design” did not match any projects. Please try again.
            </p>
          </div>
          <div className="actions-10">
            <div className="buttons-button-37">
              <div className="text-padding-18">
                <div className="text-158">Clear search</div>
              </div>
            </div>
            <div className="buttons-button-38">
              <img className="img-26" alt="Plus" src="/img/plus.svg" />
              <div className="text-padding-18">
                <Link to={"/add-wanted-device"}>
                  <div className="text-159">Add Listing</div>
                </Link>
              </div>
            </div>
          </div>
        </div>

      )
    }

  } else {
    return <Loader />
  }

  return (
    <>
      <Helmet>
        <title>Archive - Services</title>
      </Helmet>
      <div className="archive-service-listings-search-results-list">

        <Navbar1 userData={currentUser} navigate={navigate} />
        <div className='archive-service-mid-container'>
          <div className="archive-service-slide-out">
            <img className="archive-service-divider-icon14" alt="" src={divider12} />

            <div className="archive-service-rectangle-parent4">
              <div className="archive-service-frame-child8"></div>
              <div className="archive-service-frame-child9"></div>
              <img className="archive-service-dot-icon7" alt="" src={dot} />

              <img className="archive-service-dot-icon8" alt="" src={dot} />

              <img className="archive-service-dot-icon9" alt="" src={dot} />

              <img className="archive-service-dot-icon10" alt="" src={dot} />

              <img className="archive-service-dot-icon11" alt="" src={dot} />

              <img className="archive-service-dot-icon12" alt="" src={dot} />

              <img className="archive-service-dot-icon13" alt="" src={dot} />

              <div className="archive-service-frame-parent28">
                <div className="archive-service-slide-out-menu-header-parent">
                  <div className="archive-service-slide-out-menu-header">
                    <div className="archive-service-content24">
                      <img
                        className="archive-service-featured-icon1"
                        alt=""
                        src={featured_icon1}
                      />

                      <div className="archive-service-text-and-supporting-text6">
                        <a className="archive-service-text184">Filters</a>
                        <div className="archive-service-supporting-text57">
                          Apply filters to table data.
                        </div>
                      </div>
                    </div>
                    <div className="archive-service-button-close-x">
                      <img
                        className="archive-service-search-lg-icon"
                        alt=""
                        src={xclose10}
                      />
                    </div>
                  </div>
                  <div className="archive-service-frame-wrapper13">
                    <div className="archive-service-slide-out-title-parent">
                      <a className="archive-service-slide-out-title">Price</a>
                      <div className="archive-service-navigation">
                        <img
                          className="archive-service-marker-pin-02-icon2"
                          alt=""
                          src={chevrondown}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="archive-service-frame-parent29">
                  <div className="archive-service-frame-parent30">
                    <div className="archive-service-frame-wrapper14">
                      <div className="archive-service-frame-parent31">
                        <div className="archive-service-background-parent">
                          <div className="archive-service-background"></div>
                          <div className="archive-service-progress">
                            <div className="archive-service-progress-line"></div>
                            <div className="archive-service-control-handle">
                              <div className="archive-service-tooltip1">
                                <div className="archive-service-content25">
                                  <div className="archive-service-text185">0</div>
                                </div>
                              </div>
                              <div className="archive-service-handle"></div>
                            </div>
                            <div className="archive-service-control-handle">
                              <div className="archive-service-tooltip2">
                                <div className="archive-service-content26">
                                  <div className="archive-service-text186">9,999,999,99</div>
                                </div>
                              </div>
                              <div className="archive-service-handle"></div>
                            </div>
                          </div>
                        </div>
                        <div className="archive-service-input-fields-parent">
                          <div className="archive-service-input-fields">
                            <div className="archive-service-input-field">
                              <div className="archive-service-input-with-label4">
                                <div className="archive-service-label5">Email</div>
                                <div className="archive-service-input4">
                                  <div className="archive-service-content27">
                                    <div className="archive-service-text187">Min Value</div>
                                  </div>
                                  <img
                                    className="archive-service-help-icon1"
                                    alt=""
                                    src="/archive_page/help-icon1.svg"
                                  />
                                </div>
                              </div>
                              <div className="archive-service-hint-text">
                                This is a hint text to help user.
                              </div>
                            </div>
                            <div className="archive-service-text188">–</div>
                            <div className="archive-service-input-field">
                              <div className="archive-service-input-with-label4">
                                <div className="archive-service-label5">Email</div>
                                <div className="archive-service-input4">
                                  <div className="archive-service-content27">
                                    <div className="archive-service-text187">Max Value</div>
                                  </div>
                                  <img
                                    className="archive-service-help-icon1"
                                    alt=""
                                    src="/archive_page/help-icon1.svg"
                                  />
                                </div>
                              </div>
                              <div className="archive-service-hint-text">
                                This is a hint text to help user.
                              </div>
                            </div>
                          </div>
                          <div className="archive-service-checkboxes">
                            <div className="archive-service-collapsed-names">
                              <input className="archive-service-input6" type="checkbox" />

                              <div className="archive-service-price-parent">
                                <div className="archive-service-text8">US$ 0 — US$ 1,000</div>
                                <div className="archive-service-hint-text">
                                  Save my login details for next time.
                                </div>
                              </div>
                            </div>
                            <div className="archive-service-collapsed-names">
                              <input className="archive-service-input6" type="checkbox" />

                              <div className="archive-service-price-parent">
                                <div className="archive-service-text8">US$ 1,001 — US$ 5,000</div>
                                <div className="archive-service-hint-text">
                                  Save my login details for next time.
                                </div>
                              </div>
                            </div>
                            <div className="archive-service-collapsed-names">
                              <input className="archive-service-input6" type="checkbox" />

                              <div className="archive-service-price-parent">
                                <div className="archive-service-text8">US$ 5,001 — US$ 15,000</div>
                                <div className="archive-service-hint-text">
                                  Save my login details for next time.
                                </div>
                              </div>
                            </div>
                            <div className="archive-service-collapsed-names">
                              <input className="archive-service-input6" type="checkbox" />

                              <div className="archive-service-price-parent">
                                <div className="archive-service-text8">US$ 15,001 — US$ 9,999,999</div>
                                <div className="archive-service-hint-text">
                                  Save my login details for next time.
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="archive-service-frame-child10"></div>
                  </div>
                  <img
                    className="archive-service-divider-icon15"
                    alt=""
                    src={divider13}
                  />

                  <div className="archive-service-section-wrapper">
                    <div className="archive-service-section-parent">
                      <div className="archive-service-section">
                        <div className="archive-service-content-parent">
                          <div className="archive-service-content29">
                            <img className="archive-service-dot-icon" alt="" src={dot} />

                            <div className="archive-service-text194">Date Posted</div>
                          </div>
                          <img
                            className="archive-service-search-lg-icon"
                            alt=""
                            src={chevrondown}
                          />
                        </div>
                      </div>
                      <div className="archive-service-input-fields">
                        <div className="archive-service-input-field2">
                          <div className="archive-service-input-with-label4">
                            <div className="archive-service-label5">Email</div>
                            <div className="archive-service-input10">
                              <div className="archive-service-content27">
                                <div className="archive-service-text187">Jan 6, 2024</div>
                              </div>
                              <img
                                className="archive-service-help-icon1"
                                alt=""
                                src={help_icon2}
                              />
                            </div>
                          </div>
                          <div className="archive-service-hint-text">
                            This is a hint text to help user.
                          </div>
                        </div>
                        <div className="archive-service-text188">–</div>
                        <div className="archive-service-input-field2">
                          <div className="archive-service-input-with-label4">
                            <div className="archive-service-label5">Email</div>
                            <div className="archive-service-input10">
                              <div className="archive-service-content27">
                                <div className="archive-service-text187">Jan 12, 2024</div>
                              </div>
                              <img
                                className="archive-service-help-icon1"
                                alt=""
                                src={help_icon2}
                              />
                            </div>
                          </div>
                          <div className="archive-service-hint-text">
                            This is a hint text to help user.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <img
                    className="archive-service-divider-icon15"
                    alt=""
                    src={divider13}
                  />

                  <div className="archive-service-section-wrapper">
                    <div className="archive-service-section1">
                      <div className="archive-service-content-parent">
                        <div className="archive-service-content29">
                          <img className="archive-service-dot-icon" alt="" src={dot} />

                          <div className="archive-service-text198">Seller Role</div>
                        </div>
                        <img
                          className="archive-service-search-lg-icon"
                          alt=""
                          src={chevrondown}
                        />
                      </div>
                      <div className="archive-service-checkboxes1">
                        <div className="archive-service-collapsed-names">
                          <input className="archive-service-input6" type="checkbox" />

                          <div className="archive-service-price-parent">
                            <div className="archive-service-text8">Dealer/Distributor</div>
                            <div className="archive-service-hint-text">
                              Save my login details for next time.
                            </div>
                          </div>
                        </div>
                        <div className="archive-service-collapsed-names">
                          <input className="archive-service-input6" type="checkbox" />

                          <div className="archive-service-price-parent">
                            <div className="archive-service-text8">Manufacturer</div>
                            <div className="archive-service-hint-text">
                              Save my login details for next time.
                            </div>
                          </div>
                        </div>
                        <div className="archive-service-collapsed-names">
                          <input className="archive-service-input6" type="checkbox" />

                          <div className="archive-service-price-parent">
                            <div className="archive-service-text8">Owner/Employee</div>
                            <div className="archive-service-hint-text">
                              Save my login details for next time.
                            </div>
                          </div>
                        </div>
                        <div className="archive-service-collapsed-names">
                          <input className="archive-service-input6" type="checkbox" />

                          <div className="archive-service-price-parent">
                            <div className="archive-service-text8">Third Party/Agent</div>
                            <div className="archive-service-hint-text">
                              Save my login details for next time.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <img
                    className="archive-service-divider-icon15"
                    alt=""
                    src={divider13}
                  />

                  <div className="archive-service-section-wrapper">
                    <div className="archive-service-section1">
                      <div className="archive-service-content-parent">
                        <div className="archive-service-content29">
                          <img className="archive-service-dot-icon" alt="" src={dot} />

                          <div className="archive-service-text203">Transaction Type</div>
                        </div>
                        <img
                          className="archive-service-search-lg-icon"
                          alt=""
                          src={chevrondown}
                        />
                      </div>
                      <div className="archive-service-checkboxes1">
                        <div className="archive-service-collapsed-names">
                          <input className="archive-service-input6" type="checkbox" />

                          <div className="archive-service-price-parent">
                            <div className="archive-service-text8">Exchange</div>
                            <div className="archive-service-hint-text">
                              Save my login details for next time.
                            </div>
                          </div>
                        </div>
                        <div className="archive-service-collapsed-names">
                          <input className="archive-service-input6" type="checkbox" />

                          <div className="archive-service-price-parent">
                            <div className="archive-service-text8">Rent/Lease</div>
                            <div className="archive-service-hint-text">
                              Save my login details for next time.
                            </div>
                          </div>
                        </div>
                        <div className="archive-service-collapsed-names">
                          <input className="archive-service-input6" type="checkbox" />

                          <div className="archive-service-price-parent">
                            <div className="archive-service-text8">Sale</div>
                            <div className="archive-service-hint-text">
                              Save my login details for next time.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <img
                    className="archive-service-divider-icon15"
                    alt=""
                    src={divider13}
                  />

                  <div className="archive-service-section-wrapper">
                    <div className="archive-service-section3">
                      <div className="archive-service-content-parent">
                        <div className="archive-service-content29">
                          <img className="archive-service-dot-icon" alt="" src={dot} />

                          <div className="archive-service-text207">Status</div>
                        </div>
                        <img
                          className="archive-service-search-lg-icon"
                          alt=""
                          src={chevrondown}
                        />
                      </div>
                    </div>
                  </div>
                  <img
                    className="archive-service-divider-icon15"
                    alt=""
                    src={divider13}
                  />

                  <div className="archive-service-section-wrapper">
                    <div className="archive-service-section3">
                      <div className="archive-service-content-parent">
                        <div className="archive-service-content29">
                          <img className="archive-service-dot-icon" alt="" src={dot} />

                          <div className="archive-service-text208">Warranty</div>
                        </div>
                        <img
                          className="archive-service-search-lg-icon"
                          alt=""
                          src={chevrondown}
                        />
                      </div>
                    </div>
                  </div>
                  <img
                    className="archive-service-divider-icon15"
                    alt=""
                    src={divider13}
                  />

                  <div className="archive-service-section-wrapper">
                    <div className="archive-service-section3">
                      <div className="archive-service-content-parent">
                        <div className="archive-service-content29">
                          <img className="archive-service-dot-icon" alt="" src={dot} />

                          <div className="archive-service-text209">Shipping</div>
                        </div>
                        <img
                          className="archive-service-search-lg-icon"
                          alt=""
                          src={chevrondown}
                        />
                      </div>
                    </div>
                  </div>
                  <img
                    className="archive-service-divider-icon15"
                    alt=""
                    src={divider13}
                  />

                  <div className="archive-service-section-wrapper">
                    <div className="archive-service-section-parent">
                      <div className="archive-service-actions6">
                        <div className="archive-service-navigation">
                          <input className="archive-service-search-lg" type="radio" />
                        </div>
                        <div className="archive-service-text210">Country</div>
                      </div>
                      <div className="archive-service-input-fields2">
                        <div className="archive-service-input-field2">
                          <div className="archive-service-input-with-label4">
                            <div className="archive-service-label5">Email</div>
                            <div className="archive-service-input19">
                              <input
                                className="archive-service-content37"
                                placeholder="Search"
                                type="text"
                              />

                              <img
                                className="archive-service-help-icon1"
                                alt=""
                                src={help_icon}
                              />
                            </div>
                          </div>
                          <div className="archive-service-hint-text">
                            This is a hint text to help user.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="archive-service-second-badge-divider">
                    <img
                      className="archive-service-divider-icon22"
                      alt=""
                      src={divider13}
                    />

                    <img
                      className="archive-service-divider-icon23"
                      alt=""
                      src={divider21}
                    />
                  </div>
                  <div className="archive-service-frame-wrapper17">
                    <div className="archive-service-actions6">
                      <div className="archive-service-navigation">
                        <input className="archive-service-search-lg" type="radio" />
                      </div>
                      <div className="archive-service-text211">Service Category</div>
                    </div>
                  </div>
                  <div className="archive-service-second-badge-divider">
                    <img
                      className="archive-service-divider-icon22"
                      alt=""
                      src={divider13}
                    />

                    <img
                      className="archive-service-divider-icon23"
                      alt=""
                      src={divider21}
                    />
                  </div>
                  <div className="archive-service-frame-wrapper17">
                    <div className="archive-service-actions6">
                      <div className="archive-service-navigation">
                        <input className="archive-service-search-lg" type="radio" />
                      </div>
                      <div className="archive-service-text212">OEM Brand</div>
                    </div>
                  </div>
                  <div className="archive-service-frame-parent36">
                    <div className="archive-service-second-badge-divider">
                      <img
                        className="archive-service-divider-icon22"
                        alt=""
                        src={divider13}
                      />

                      <img
                        className="archive-service-divider-icon23"
                        alt=""
                        src={divider21}
                      />
                    </div>
                    <div className="archive-service-frame-wrapper19">
                      <div className="archive-service-frame-parent37">
                        <div className="archive-service-navigation">
                          <input className="archive-service-search-lg" type="radio" />
                        </div>
                        <div className="archive-service-text213">Year of Manufacture</div>
                      </div>
                    </div>
                    <div className="archive-service-second-badge-divider">
                      <img
                        className="archive-service-divider-icon22"
                        alt=""
                        src={divider13}
                      />

                      <img
                        className="archive-service-divider-icon23"
                        alt=""
                        src={divider21}
                      />
                    </div>
                    <div className="archive-service-frame-wrapper19">
                      <div className="archive-service-actions6">
                        <div className="archive-service-navigation">
                          <input className="archive-service-search-lg" type="radio" />
                        </div>
                        <div className="archive-service-text213">Clinical Applications</div>
                      </div>
                    </div>
                  </div>
                  <div className="archive-service-card-header-parent">
                    <img
                      className="archive-service-divider-icon15"
                      alt=""
                      src={divider13}
                    />

                    <img
                      className="archive-service-divider-icon31"
                      alt=""
                      src={divider21}
                    />
                  </div>
                  <div className="archive-service-frame-wrapper17">
                    <div className="archive-service-actions6">
                      <div className="archive-service-navigation">
                        <input className="archive-service-search-lg" type="radio" />
                      </div>
                      <div className="archive-service-text212">Device Use</div>
                    </div>
                  </div>
                  <div className="archive-service-second-badge-divider">
                    <img
                      className="archive-service-divider-icon22"
                      alt=""
                      src={divider13}
                    />

                    <img
                      className="archive-service-divider-icon23"
                      alt=""
                      src={divider21}
                    />
                  </div>
                  <div className="archive-service-frame-wrapper17">
                    <div className="archive-service-frame-parent37">
                      <div className="archive-service-navigation">
                        <input className="archive-service-search-lg" type="radio" />
                      </div>
                      <div className="archive-service-text216">Physical Location</div>
                    </div>
                  </div>
                  <img
                    className="archive-service-divider-icon15"
                    alt=""
                    src={divider13}
                  />
                </div>
              </div>
              <img className="archive-service-dot-icon20" alt="" src={dot} />

              <div className="archive-service-frame-parent41">
                <div className="archive-service-divider-parent6">
                  <img
                    className="archive-service-divider-icon35"
                    alt=""
                    src={divider13}
                  />

                  <div className="archive-service-footer1">
                    <div className="archive-service-content38">
                      <div className="archive-service-logo1">
                        <div className="archive-service-buttonsbutton37">
                          <img
                            className="archive-service-placeholder-icon"
                            alt=""
                            src={placeholder2}
                          />

                          <div className="archive-service-text217">Save filter</div>
                          <img
                            className="archive-service-placeholder-icon"
                            alt=""
                            src={placeholder2}
                          />
                        </div>
                      </div>
                      <div className="archive-service-actions9">
                        <button className="archive-service-buttonsbutton38">
                          <img
                            className="archive-service-placeholder-icon"
                            alt=""
                            src={placeholder}
                          />

                          <div className="archive-service-text-padding">
                            <div className="archive-service-text218">Cancel</div>
                          </div>
                          <img
                            className="archive-service-placeholder-icon"
                            alt=""
                            src={placeholder}
                          />
                        </button>
                        <button className="archive-service-buttonsbutton39">
                          <img
                            className="archive-service-placeholder-icon"
                            alt=""
                            src={placeholder3}
                          />

                          <div className="archive-service-text-padding">
                            <div className="archive-service-text219">Apply</div>
                          </div>
                          <img
                            className="archive-service-placeholder-icon"
                            alt=""
                            src={placeholder3}
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="archive-service-dot-item-innermost">
                  <div className="archive-service-collapsed-content">
                    <img
                      className="archive-service-divider-icon22"
                      alt=""
                      src={divider13}
                    />

                    <img
                      className="archive-service-divider-icon37"
                      alt=""
                      src={divider21}
                    />
                  </div>
                </div>
              </div>
            </div>

          </div>

          <div className="archive-service-filters">
            <div className='archive-service-filter-selected-div'>
              <div className="archive-service-main">
                <div className="archive-service-header-section">
                  <div className="archive-service-container">
                    <div className="archive-service-page-header">
                      <div className="archive-service-breadcrumbs">
                        <div className="archive-service-tabs">
                          <div className="archive-service-breadcrumb-button-base">
                            <img
                              className="archive-service-search-lg-icon"
                              alt=""
                              src={homeline}
                            />
                          </div>
                          <img
                            className="archive-service-chevron-right-icon"
                            alt=""
                            src={chevronright}
                          />

                          <div className="archive-service-breadcrumb-button-base1">
                            <a className="archive-service-text7">Dashboard</a>
                          </div>
                          <img
                            className="archive-service-chevron-right-icon"
                            alt=""
                            src={chevronright}
                          />

                          <div className="archive-service-breadcrumb-button-base2">
                            <div className="archive-service-text8">...</div>
                          </div>
                          <img
                            className="archive-service-chevron-right-icon2"
                            alt=""
                            src={chevronright}
                          />

                          <div className="archive-service-breadcrumb-button-base3">
                            <div className="archive-service-text9">Another link</div>
                          </div>
                          <img
                            className="archive-service-chevron-right-icon2"
                            alt=""
                            src={chevronright}
                          />

                          <div className="archive-service-breadcrumb-button-base4">
                            <a className="archive-service-text10">Category</a>
                          </div>
                        </div>
                      </div>
                      <img className="archive-service-divider-icon" alt="" src="/archive_page/divider.svg" />
                    </div>
                  </div>
                </div>
                <div className="archive-service-main-child"></div>
                <div className="archive-service-main-item"></div>
              </div>
              <div className="archive-service-secondary-content">
                <div className="archive-service-page-header1">
                  <div className="archive-service-breadcrumbs1">
                    <div className="archive-service-tabs1">
                      <div className="archive-service-breadcrumb-button-base">
                        <img
                          className="archive-service-search-lg-icon"
                          alt=""
                          src={homeline}
                        />
                      </div>
                      <img
                        className="archive-service-chevron-right-icon"
                        alt=""
                        src={chevronright}
                      />

                      <div className="archive-service-breadcrumb-button-base6">
                        <div className="archive-service-text11">Settings</div>
                      </div>
                      <img
                        className="archive-service-chevron-right-icon"
                        alt=""
                        src={chevronright}
                      />

                      <div className="archive-device-breadcrumb-button-base7">
                        <div className="archive-device-text8">...</div>
                      </div>
                      <img
                        className="archive-device-chevron-right-icon"
                        alt=""
                        src={chevronright}
                      />

                      <div className="archive-device-breadcrumb-button-base3">
                        <div className="archive-device-text9">Another link</div>
                      </div>
                      <img
                        className="archive-device-chevron-right-icon2"
                        alt=""
                        src={chevronright}
                      />

                      <div className="archive-device-breadcrumb-button-base4">
                        <div className="archive-device-menu-label">Team</div>
                      </div>
                    </div>
                  </div>
                  <div className="archive-device-content4">
                    <div className="archive-device-text-and-supporting-text">
                      <div className="archive-device-page-title">{dataLength} Listings Found</div>
                      <div className="archive-device-supporting-text">
                        Manage your team members and their account permissions here.
                      </div>
                    </div>
                    <div className="archive-device-input-dropdown">
                      <div className="archive-device-input-with-label1">
                        <div className="archive-device-label1">Search</div>
                        <div className="archive-device-input1">
                          <div className="archive-device-content5">
                            <img
                              className="archive-device-search-lg-icon"
                              alt=""
                              src={searchlg}
                            />

                            <div className="archive-device-text15">Search</div>
                          </div>
                        </div>
                      </div>
                      <div className="archive-device-hint-text">This is a hint text to help user.</div>
                    </div>
                  </div>
                  <img className="archive-device-divider-icon1" alt="" src={divider1} />
                </div>
              </div>
              <div className="archive-device-button-group">
                <button className="archive-device-button-group-base">
                  <a className="archive-device-text16">View all</a>
                </button>
                <div className="archive-device-button-group-base1">
                  <div className="archive-device-text17">Monitored</div>
                </div>
                <div className="archive-device-button-group-base2">
                  <div className="archive-device-menu-label">Text</div>
                </div>
                <div className="archive-device-button-group-base3">
                  <div className="archive-device-menu-label">Text</div>
                </div>
                <div className="archive-device-button-group-base4">
                  <div className="archive-device-menu-label">Text</div>
                </div>
                <div className="archive-device-button-group-base5">
                  <div className="archive-device-menu-label">Text</div>
                </div>
                <div className="archive-device-button-group-base6">
                  <div className="archive-device-menu-label">Text</div>
                </div>
                <div className="archive-device-button-group-base7">
                  <div className="archive-device-menu-label">Text</div>
                </div>
                <div className="archive-device-button-group-base1">
                  <div className="archive-device-text24">Unmonitored</div>
                </div>

              </div>
              <div className="archive-device-input-dropdown1">
                <div className="archive-device-hint-text1">This is a hint text to help user.</div>
                <div className="archive-device-buttonsbutton">
                  <img className="archive-device-sliders-02-icon" alt="" src={sliders02} />

                  <div className="archive-device-text-padding">
                    <div className="archive-device-menu-label">Sort By : Relevance</div>
                    <img
                      className="archive-device-sliders-02-icon"
                      alt=""
                      src={chevrondown}
                    />
                  </div>
                  <img
                    className="archive-device-placeholder-icon"
                    alt=""
                    src={placeholder}
                  />
                </div>
              </div>
              <img className="archive-device-divider-icon2" alt="" src="/archive_page/divider2.svg" />

              <img className="archive-device-divider-icon3" alt="" src="/archive_page/divider2.svg" />

              <div className="archive-device-supporting-text1">
                You searched based on the following criteria.
              </div>

              <div className="archive-device-buttonsbutton7">
                <img className="archive-device-placeholder-icon" alt="" src={placeholder} />

                <a className="archive-device-text47">All time</a>
                <img className="archive-device-sliders-02-icon" alt="" src={xclose} />
              </div>
              <button className="archive-device-buttonsbutton8">
                <img className="archive-device-placeholder-icon" alt="" src={placeholder} />

                <div className="archive-device-text48">Clinical Applications</div>
                <img className="archive-device-sliders-02-icon" alt="" src={xclose} />
              </button>
              <div className="archive-device-buttonsbutton9">
                <img className="archive-device-placeholder-icon" alt="" src={placeholder} />

                <div className="archive-device-text49">US, AU, +4</div>
                <img className="archive-device-sliders-02-icon" alt="" src={xclose} />
              </div>
              <div className="archive-device-buttonsbutton10">
                <img className="archive-device-placeholder-icon" alt="" src={placeholder} />

                <div className="archive-device-text50">All/Employee</div>
                <img className="archive-device-sliders-02-icon" alt="" src={xclose} />
              </div>
              {/* <button className="archive-device-buttonsbutton11">
          <img className="archive-device-placeholder-icon" alt="" src={placeholder} />

          <div className="archive-device-text48">Clinical Applications</div>
          <img className="archive-device-sliders-02-icon" alt="" src={xclose} />
        </button> */}
              <div className="archive-device-buttonsbutton12">
                <img className="archive-device-placeholder-icon" alt="" src={placeholder} />

                <div className="archive-device-menu-label">Year of Manufacture</div>
                <img className="archive-device-sliders-02-icon" alt="" src={xclose} />
              </div>
              <button className="archive-device-buttonsbutton13">
                <img className="archive-device-placeholder-icon" alt="" src={placeholder} />

                <div className="archive-device-text53">Warrenty</div>
                <img className="archive-device-sliders-02-icon" alt="" src={xclose} />
              </button>
              <button className="archive-device-buttonsbutton14">
                <img className="archive-device-placeholder-icon" alt="" src={placeholder} />

                <div className="archive-device-text54">Manufacturer</div>
                <img className="archive-device-sliders-02-icon" alt="" src={xclose} />
              </button>
              <button className="archive-device-buttonsbutton15">
                <img className="archive-device-placeholder-icon" alt="" src={placeholder} />

                <div className="archive-device-text55">All Applications</div>
                <img className="archive-device-sliders-02-icon" alt="" src={xclose} />
              </button>
              <button className="archive-device-buttonsbutton16">
                <img className="archive-device-placeholder-icon" alt="" src={placeholder} />

                <div className="archive-device-text56">Rent/Lease</div>
                <img className="archive-device-sliders-02-icon" alt="" src={xclose} />
              </button>
              <button className="archive-device-buttonsbutton17">
                <img className="archive-device-sliders-02-icon" alt="" src={filterlines} />

                <div className="archive-device-text57">More filters</div>
                <img className="archive-device-placeholder-icon" alt="" src={placeholder} />
              </button>
              <button className="archive-device-buttonsbutton18">
                <img
                  className="archive-device-sliders-02-icon"
                  alt=""
                  src={filterfunnel01}
                />

                <div className="archive-device-text58">Clear Filter</div>
                <img className="archive-device-placeholder-icon" alt="" src={placeholder} />
              </button>
              <button className="archive-device-actions">
                <div className="archive-device-button">
                  <img
                    className="archive-device-placeholder-icon"
                    alt=""
                    src={placeholder1}
                  />

                  <div className="archive-device-text-padding1">
                    <div className="archive-device-text59">Tertiary</div>
                  </div>
                  <img
                    className="archive-device-placeholder-icon"
                    alt=""
                    src={placeholder1}
                  />
                </div>
                <div className="archive-device-button1">
                  <img
                    className="archive-device-placeholder-icon"
                    alt=""
                    src={placeholder2}
                  />

                  <div className="archive-device-text-padding1">
                    <div className="archive-device-text60">Secondary</div>
                  </div>
                  <img
                    className="archive-device-placeholder-icon"
                    alt=""
                    src={placeholder2}
                  />
                </div>
                <div className="archive-device-button2">
                  <img
                    className="archive-device-sliders-02-icon"
                    alt=""
                    src={filterlines}
                  />

                  <div className="archive-device-text-padding">
                    <div className="archive-device-filter-label">Save Filter</div>
                  </div>
                  <img
                    className="archive-device-placeholder-icon"
                    alt=""
                    src={placeholder3}
                  />
                </div>
              </button>
            </div>
            {/* <div className="archive-service-listing-container archive-device-listing-container-custom"> */}
            <div className="dashboard-device-component-listing-container">

              {content}

            </div>
          </div>
        </div>
        <img className="archive-service-divider-icon6" alt="" src={divider4} />

        <img className="archive-service-divider-icon7 archive-service-divider-icon7-cstm" alt="" src={divider4} />





        <FooterAddForms />




      </div>

    </>


  )
}

