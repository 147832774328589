import { useEffect, useState } from "react";
import { useGetAvailabilityTaxQuery, useGetClinicalApplicationsTaxQuery, useGetPhysicalLocationTaxQuery, useGetPurposeUseTaxQuery, useGetShippingTaxQuery, useGetStatusConditionTaxQuery, useGetTransactionTypeTaxQuery, useGetUnitOfMeasureDeviceSpareTaxQuery, useGetWarrantyTaxQuery, useGetYearOfManufactureTaxQuery, useGetYourRoleTaxQuery } from "../api/TaxonomyFormAPI";
import { encryptData } from "./AESHelper";

// export function imageToFormData(galleryImage) {
//   const formData = new FormData();
//   console.log(galleryImage);

//   if (galleryImage instanceof File) {
//     console.log('galleryimage is added ');

//     formData.append("galleryImageObject", galleryImage);
//   }
//   console.log(formData);

//   return formData;

// }


// export function documentToFormData(documentImage) {
//   const formData = new FormData();
//   console.log(documentImage);

//   if (documentImage instanceof File) {
//     console.log('document is added ');

//     formData.append("documentImageObject", documentImage);
//   }
//   console.log(formData);

//   return formData;

// }



export function objectToFormData(obj, featureImage, imageMultiUploadStatus, documentMultiUploadStatus, currentUsermeta) {
  const formData = new FormData();

  // Destructure to remove file fields from obj
  const { document: _, gallery: __, featureImage: ___, imageMultiCheckbox: ____, imageDocCheckbox: _____, ...values } = obj;

  values.price = values.price.replace(/,/g, '');

  values.availableQuantity = Number(values.availableQuantity.replace(/,/g, ''));

  values.businessId = currentUsermeta.userBusinessId;

  values.userId = currentUsermeta.userId;

  values.gallery = imageMultiUploadStatus.map(item => item.postId);

  values.document = documentMultiUploadStatus.map(item => item.postId);

  console.log(values);
  // Encrypt the object without the document
  const encryptedValue = encryptData(values); // Custom encryptData function

  // Append the encrypted text data
  formData.append("encryptedData", encryptedValue);


  // Append featureImage if it's a valid File
  if (featureImage instanceof File) {
    formData.append("featureImageObject", featureImage);
  }


  //  Appending galleryImage to formData

  //Appending documentFile to formData also checking if user has uploaded document or not
  // if (document !== "" || document.length !== 0) {
  //   formData.append("documentFileObject", document);
  // }
  return formData;
}


export function objectToFormDataForEditDevice(obj, documentFileRM, galleryImageRM, featureImage, imageMultiUploadStatus, documentMultiUploadStatus) {
  const formData = new FormData();

  // Destructure to remove file fields from obj
  const { document: _, gallery, featureImage: __, galleryImages, devices_files, documentFile, document_file, feature_image, tax_device_category, tax_oem, imageCheckbox, imageDocCheckbox, imageMultiCheckbox, ...values } = obj;

  values.price = values.price.replace(/,/g, '');
  values.availableQuantity = Number(values.availableQuantity.replace(/,/g, ''));

  values.gallery = imageMultiUploadStatus
    .map(item => Number(item.postId)) // Convert each postId to a number
    .filter(postId => !isNaN(postId)); // Filter out non-numeric values

  values.document = documentMultiUploadStatus
    .map(item => Number(item.postId)) // Convert each postId to a number
    .filter(postId => !isNaN(postId)); // Filter out non-numeric values

  if (galleryImageRM.length) {
    values.galleryImageRM = [...galleryImageRM]; // Add galleryImageRM to values
  }

  if (documentFileRM !== "" && documentFileRM !== undefined) {
    values.documentFileRM = documentFileRM; // Add documentFileRM to values
  }
  console.log(values);

  // Append featureImage if it's a valid File
  if (featureImage instanceof File) {
    formData.append("featureImageObject", featureImage);
  }

  // //  Appending galleryImage to formData
  // for (let i = 0; i < galleryImage.length; i++) {
  //   const file = galleryImage[i].file;
  //   if (file instanceof File) {
  //     formData.append("galleryImageObject", file);
  //   }
  // }

  // if (document && document.type === 'application/pdf') {
  //   formData.append("documentFileObject", document);
  // }

  const encryptedValue = encryptData(values); // Custom encryptData function

  console.log(values);
  // Append the encrypted text data
  formData.append("encryptedData", encryptedValue);

  return formData;
}


export function getCustomizedDate(sqlDate) {
  // const date = ; 
  const formattedDate = new Date(sqlDate).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });

  return formattedDate;
}


export function getLastSeen(timestamp) {
  const now = new Date();
  const date = new Date(timestamp);
  const diffSeconds = Math.floor((now - date) / 1000);

  if (diffSeconds < 60) return 'Just now';
  if (diffSeconds < 3600) return `${Math.floor(diffSeconds / 60)} minutes ago`;
  if (diffSeconds < 86400) return `${Math.floor(diffSeconds / 3600)} hours ago`;
  if (diffSeconds < 604800) return `${Math.floor(diffSeconds / 86400)} days ago`;
  if (diffSeconds < 31536000) return `${Math.floor(diffSeconds / 604800)} weeks ago`;
  return `${Math.floor(diffSeconds / 31536000)} years ago`;
}

// export const formatNumber = (value) => {
//   // Return empty string if value is empty
//   if (!value && value !== 0) return '';

//   // Remove any existing commas and non-numeric characters except decimal
//   const cleanValue = value.toString().replace(/[^\d.-]/g, '');

//   // Handle invalid numbers
//   if (isNaN(cleanValue)) return '';

//   // Parse the number and format it
//   const number = parseFloat(cleanValue);

//   // Check if the original input ends with a decimal point
//   const endsWithDecimal = value.toString().endsWith('.');

//   // Format the number with commas
//   const formatted = number.toLocaleString('en-US', {
//     minimumFractionDigits: 0,
//     maximumFractionDigits: 2
//   });

//   // Add back the decimal point if it was in the original input
//   return endsWithDecimal ? `${formatted}.` : formatted;
// };

export const formatNumber = (value) => {
  // Return empty string if value is empty
  if (!value && value !== 0) return '';

  // Check if the input contains any non-numeric characters (except decimal and comma)
  if (/[^\d.,]/.test(value)) {
    return value; // Return the value as-is if it contains letters
  }

  // Remove any existing commas
  const cleanValue = value.toString().replace(/,/g, '');

  // If it's not a valid number format, return the value as-is
  if (!/^\d*\.?\d*$/.test(cleanValue)) {
    return value;
  }

  // Handle case when only a decimal point is entered
  if (cleanValue === '.') {
    return '.';
  }

  // Parse the number
  const number = parseFloat(cleanValue);

  // Handle invalid numbers
  if (isNaN(number)) {
    return value;
  }

  // Check if the original input ends with a decimal point
  const endsWithDecimal = value.toString().endsWith('.');

  // Format the number with commas
  const formatted = number.toLocaleString('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
  });

  // Add back the decimal point if it was in the original input
  return endsWithDecimal ? `${formatted}.` : formatted;
};

export const formatPrice = (value) => {
  if (!value) return '';
  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(value);
};


export function isValidURL(urlString) {
  try {
    new URL(urlString);
    return true;
  } catch (_) {
    return false;
  }
}

export function isAllURLsValid(urlArray) {
  for (const url of urlArray) {
    if (!isValidURL(url)) {
      return false; // If even one URL is invalid, return false
    }
  }
  return true; // All URLs were valid
}