import React from 'react';

const PlanExpiryStatus = ({ expiryDate, planName }) => {
    const calculateStatus = () => {
        const today = new Date();
        const expiry = new Date(expiryDate);

        if (expiryDate === null && planName === "Oxygen") {
            return `You are on ${planName} Plan, lifetime validity.`;
        }

        // If plan is expired
        if (expiry < today) {
            return `You are on ${planName} Plan, plan is expired.`;
        }

        // Calculate difference in days
        const diffTime = expiry.getTime() - today.getTime();
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

        // If within a month (30 days)
        if (diffDays <= 30) {
            return `You are on ${planName} Plan, expiring in ${diffDays} ${diffDays === 1 ? 'day' : 'days'}`;
        }

        // If within 2 months to a year
        const diffMonths = Math.floor(diffDays / 30);
        if (diffMonths <= 12) {
            return `You are on ${planName} Plan, expiring in ${diffMonths} ${diffMonths === 1 ? 'month' : 'months'}`;
        }

        // Default case: show months
        return `You are on ${planName} Plan, expiring in ${diffMonths} months`;
    };

    return (
        <div className="plan-and-billing-display-supporting-text2">
            {calculateStatus()}
        </div >


    );
};

export default PlanExpiryStatus;