import React, { useEffect, useRef, useState } from 'react';
import "./DemandCenterDeviceComponent.css"
import dotsvertical from '../../../icons/Gerator/dashboard/dotsvertical.svg';
import calendar1 from '../../../icons/Gerator/dashboard/calendar1.svg';
import content_elements_2x from '../../../icons/Gerator/dashboard/content-elements@2x.png';
import dot1 from '../../../icons/Gerator/dashboard/-dot1.svg';
import radar from '../../../icons/Gerator/dashboard/radar-1-1@2x.png';
import deal_2_1_2x from '../../../icons/Gerator/dashboard/deal-2-1@2x.png';
import magnifier_1_2x from '../../../icons/Gerator/dashboard/magnifier-1@2x.png';
import content_elements_2x from '../../../icons/Gerator/dashboard/content-elements@2x.png';
import eye from '../../../icons/Gerator/dashboard/eye.svg';
import icon1 from '../../../icons/Gerator/dashboard/icon1.svg';
import lock04 from '../../../icons/Gerator/dashboard/lock04.svg';
import lockunlocked04 from '../../../icons/Gerator/dashboard/lockunlocked04.svg';
import arrowsquareupright from '../../../icons/Gerator/dashboard/arrowsquareupright.svg';
import messagechatsquare from '../../../icons/Gerator/dashboard/messagechatsquare.svg';
import markerpin02 from '../../../icons/Gerator/dashboard/markerpin02.svg';
import divider2 from '../../../icons/Gerator/dashboard/divider2.svg';
import arrowup1 from '../../../icons/Gerator/dashboard/arrowup1.svg';
import arrowdown1 from '../../../icons/Gerator/dashboard/arrowdown1.svg';
import placeholder2 from '../../../icons/Gerator/dashboard/placeholder2.svg';
import group_58_2x from '../../../icons/Gerator/dashboard/group-58@2x.png';
import sliders02 from '../../../icons/Gerator/dashboard/sliders02.svg';


import { SortByFilterComponent } from '../../SortByFilterComponent/SortByFilterComponent';
import RadarFilterButtonGroup from '../../RadarFilterButtonGroup/RadarFilterButtonGroup';
import { TransactionTypeComponent } from '../../TransactionTypeComponent/TransactionTypeComponent';
import { ThreeDotsListingComponent } from '../../ThreeDotsListingComponent/ThreeDotsListingComponent';
import { Link } from 'react-router-dom';
import { PostStatusLIstingComponent } from '../../PostStatusLIstingComponent/PostStatusLIstingComponent';
import { useGetUserDashboardDevicesQuery } from '../../../api/DeviceAPI';
import { NoListingFound } from '../../NoListing/NoListingFound';
import SkeletonListingLoaderComponent from '../../SkeletonListingLoaderComponent/SkeletonListingLoaderComponent';
import { getCustomizedDate } from '../../../helper/DeviceHelper';
import { useGetPostTypeTaxQuery } from '../../../api/TaxonomyFormAPI';
import SearchInputListingComponent from '../../SearchInputListingComponent/SearchInputListingComponent';
import { useFindGetWantedDeviceQuery, useGetUserDashboardWantedDevicesQuery, useGetWantedDevicesQuery } from '../../../api/WantedDeviceAPI';


// Temporary 

import playstation from '../../../icons/Gerator/sales_iq/playstation.jpeg';
import radar_1_1_2x from '../../../icons/Gerator/sales_iq/radar-1-1@2x.png';
import deal_2_1_2x from '../../../icons/Gerator/sales_iq/deal-2-1@2x.png';
import magnifier_1_2x from '../../../icons/Gerator/sales_iq/magnifier-1@2x.png';
import arrowup from "../../../icons/Gerator/sales_iq/arrowup.svg";
import _chart_mini2 from "../../../icons/Gerator/sales_iq/-chart-mini2@2x.png"
import { ErrorShowComponent } from '../../ErrorShowComponent/ErrorShowComponent';
import { ThreeDotsDemandCenterComponent } from '../../ThreeDotsListingComponent/ThreeDotsDemandCenterComponent';
import { ThreeDotsNoActionComponent } from '../../ThreeDotsListingComponent/ThreeDotsNoActionComponent';


export const DemandCenterDeviceComponent = () => {


    const [activeThreeDotIdNoAction, setActiveThreeDotIdNoAction] = useState(null);
    const threeDotsRefNoAction = useRef([]);
    const [isClickBlockedNoAction, setIsClickBlockedNoAction] = useState(false);

    const toggleThreeDotsDropdownNoAction = (index) => {
      if (isClickBlockedNoAction) return; // Prevent rapid toggle



        if (activeThreeDotIdNoAction === index) {
          setActiveThreeDotIdNoAction(null); // Close if the same dropdown is clicked again
        } else {
          setActiveThreeDotIdNoAction(index); // Open the dropdown
        }

        setIsClickBlockedNoAction(true);
        setTimeout(() => setIsClickBlockedNoAction(false), 400);
    };


    useEffect(() => {
      const handleThreeDotClickOutsideNoAction = (event) => {

        const isLink = event.target.closest('a');

        if (isLink) {
          return; // Allow navigation to happen
        }

        if (threeDotsRefNoAction.current[activeThreeDotIdNoAction]&& !threeDotsRefNoAction.current[activeThreeDotIdNoAction].contains(event.target)) {
          setActiveThreeDotIdNoAction(null); // Close any open dropdown when clicking outside
        }
      };

      document.addEventListener("mousedown", handleThreeDotClickOutsideNoAction);
      return () => {
        document.removeEventListener("mousedown", handleThreeDotClickOutsideNoAction);
      };
    }, []);

  //Offset to load data when click load more
  const [offset, setOffset] = useState(0);
  const [sortBy, setSortBy] = useState('date_desc');
  const [searchTerm, setSearchTerm] = useState('');
  const [totalCount, setTotalCount] = useState("");
  const [totalCountWantedSpare, settotalCountWantedSpare] = useState("");
  const [totalCountWantedService, settotalCountWantedService] = useState("");
  const [totalCountWantedSoftware, settotalCountWantedSoftware] = useState("");

  const [activePostStatus, setActivePostStatusFilter] = useState(null);
  //Query to get data
  const { data, isSuccess, isError, refetch, isLoading, isFetching } = useGetWantedDevicesQuery(
    { offset, sortBy, postStatus: activePostStatus, search: searchTerm },
    {
      // Refetch when component mounts or tab becomes active
      refetchOnMountOrArgChange: true
    }
  );

  //flag var to show listing when loading complete when sekelteon layout is going to stop
  const [loadingComplete, setLoadingComplete] = useState(false);

  //Var is reponsible to show listings and other thing as well
  let content;

  //Sort by text show on button
  const [selectedSortOption, setSelectedSortOption] = useState('');

  //For SortBy Dropdown
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);

  //For Threedots in listing dropdown
  const [activeThreeDotId, setActiveThreeDotId] = useState(null);
  const threeDotsRef = useRef(null);
  const [isClickBlocked, setIsClickBlocked] = useState(false);



  const toggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible);
  }

  const toggleThreeDotsDropdown = (id) => {
    if (isClickBlocked) return; // Prevent rapid toggle

    if (activeThreeDotId === id) {
      setActiveThreeDotId(null); // Close if the same one is clicked again
      setIsClickBlocked(true);
      setTimeout(() => setIsClickBlocked(false), 400); // Small delay to avoid reopening
    } else {
      setActiveThreeDotId(id); // Open the clicked one
    }
  };

  const handleSearch = (term) => {
    setSearchTerm(term);
  };


  //Set value for sortby selected option on button text
  const handleOptionSelect = (option) => {
    if (option.value !== sortBy) {
      setSortBy(option.value);
      setOffset(0); // Reset offset when sort option changes
    }
    setSelectedSortOption(option);
    setDropdownVisible(false);
  };

  //DashbaordFilterButtonGroup component to get data
  const handleFilterClick = (filterLabel) => {
    if (activePostStatus === filterLabel) {
      setSortBy('date_desc')
      setSelectedSortOption('')
      setActivePostStatusFilter(null);
    } else {
      setSortBy('date_desc')
      setSelectedSortOption('')
      setActivePostStatusFilter(filterLabel);
    }
    setOffset(0); // Reset offset when changing filters
  };

  //Setting offset for loadmore when user clicked on load more button(this trigger query to fetch new data)
  // Reset offset when component mounts or remounts
  // useEffect(() => {
  //   setOffset(0);
  // }, []);
  // // Reset offset when sort option changes
  // useEffect(() => {
  //   setOffset(0);
  // }, [sortBy]);

  const handleLoadMore = () => {
    if (data?.hasMore && !isFetching) {
      setOffset(currentOffset => currentOffset + 5);
    }
  };

  const showLoadMore = data?.hasMore &&
    data.wanted_devices.length < data.totalCount &&
    !isFetching;





  //Onclick listener for sortby dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


  //Onclick listener for threedots dropdown
  useEffect(() => {
    const handleThreeDotClickOutside = (event) => {
      if (threeDotsRef.current && !threeDotsRef.current.contains(event.target)) {
        setActiveThreeDotId(null); // Close any open dropdown when clicking outside
      }
    };

    document.addEventListener("mousedown", handleThreeDotClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleThreeDotClickOutside);
    };
  }, []);

  // Listen for tab visibility changes
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        refetch();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [refetch]);


  //Success true then pause to show sekelton layout
  useEffect(() => {
    if (isSuccess) {
      // Set totalcount in listing counter layout
      setTotalCount(data.totalCount);
      settotalCountWantedSpare(data.totalCountWantedSpare);
      settotalCountWantedService(data.totalCountWantedService);
      settotalCountWantedSoftware(data.totalCountWantedSoftware);
      // settotalCountExpired(data.totalCountExpired);
      // Set timeout to delay the loading completion
      const timer = setTimeout(() => {
        setLoadingComplete(true);
      }, 1000); // 1 second

      // Cleanup timer on component unmount
      return () => clearTimeout(timer);
    }
  }, [isSuccess]);


  if (isError) {
    content = <ErrorShowComponent message1={'Internal server error'} message2={'Oh! we got an issue while fetching listings...'} />
  }

  if (isLoading) {
    content = <SkeletonListingLoaderComponent />;
  } else if (loadingComplete && isSuccess) {

    if (data.wanted_devices.length > 0) {
      // dataLength = data.wanted_devices.length;
      content = data.wanted_devices.map((wantedDevice, index) =>
      (

        <div className="demand-center-device-component-demand-center-content-items" key={index}>
          <div className="demand-center-device-component-demand-center-content-items" >
            <div className="demand-center-device-component-demand-center-divider-container1">
              <div className="demand-center-device-component-demand-center-divider-container-child"></div>
              <div className="demand-center-device-component-demand-center-rectangle-parent5">


                <div className="demand-center-device-component-demand-center-frame-parent1">
                  <div className="demand-center-device-component-demand-center-action-fields">
                    <div className="demand-center-device-component-demand-center-input-elements">
                      <Link to={`/wanted-device/${wantedDevice.id}/${wantedDevice.title}`} className="demand-center-device-component-demand-center-text78">
                        {wantedDevice.title}
                      </Link>
                      {/* <div className="demand-center-device-component-demand-center-text78">
                        WANTED - NEW / SURPLUS 1 VIVIT DSFS FOR 1/25-DIHYDROXY VITAMIN D3 IVD / CALIBRATOR
                      </div> */}
                      <div className="demand-center-device-component-demand-center-frame-parent21">
                        <div className="demand-center-device-component-demand-center-badge-item-parent">




                          <div className="demand-center-device-component-demand-center-frame-wrapper19">
                            <div className="demand-center-device-component-demand-center-legend-series">
                              <div className="demand-center-device-component-demand-center-search-input">
                                <img
                                  className="demand-center-device-component-demand-center-marker-pin-02-icon2"
                                  alt=""
                                  src={calendar1}
                                />
                              </div>
                              <div className="demand-center-device-component-demand-center-type-of-work2">{getCustomizedDate(wantedDevice.createdAt)}</div>
                              {/* <div className="demand-center-device-component-demand-center-type-of-work2">November 28, 2024</div> */}

                            </div>
                          </div>

                          <div className="demand-center-device-component-demand-center-frame-parent4">
                            <div className="demand-center-device-component-demand-center-end-label">
                              <div className="demand-center-device-component-demand-center-search-icon-input">4.9/5</div>
                            </div>
                            <div className="demand-center-device-component-demand-center-type-support">
                              <div className="demand-center-device-component-demand-center-supporting-text9">
                                99,999 reviews
                              </div>
                            </div>
                          </div>






                        </div>



                      </div>
                      <div className="demand-center-device-component-demand-center-small-card">

                        <div className="demand-center-device-component-demand-center-badge33">
                          <div className="demand-center-device-component-demand-center-third-badge-number">
                            {wantedDevice.tax_device_category.name} 1/25-DIHYDROXY VITAMIN D3 IVD / CALIBRATOR
                          </div>
                          {/* <div className="demand-center-device-component-demand-center-third-badge-number">
                            1/25-DIHYDROXY VITAMIN D3 IVD / CALIBRATOR
                          </div> */}
                        </div>

                        <div className="demand-center-device-component-demand-center-badge36">
                          <div className="demand-center-device-component-demand-center-third-badge-number">
                            {wantedDevice.tax_oem.name}
                          </div>
                          {/* <div className="demand-center-device-component-demand-center-third-badge-number">
                            1 Vivit
                          </div> */}
                        </div>

                        <div className="demand-center-device-component-demand-center-badge37">
                          <div className="demand-center-device-component-demand-center-third-badge-number">{wantedDevice.tax_clinical_application.name}</div>
                          {/* <div className="demand-center-device-component-demand-center-third-badge-number">Physiotherapy</div> */}

                        </div>

                        <div className="demand-center-device-component-demand-center-badge38">
                          <div className="demand-center-device-component-demand-center-third-badge-number">
                            {wantedDevice.tax_purpose_use.name}
                          </div>
                          {/* <div className="demand-center-device-component-demand-center-third-badge-number">
                            Sample Preparation
                          </div> */}
                        </div>

                        {/* <div className="demand-center-device-component-demand-center-frame-parent24">


                            <div className="demand-center-device-component-demand-center-frame-wrapper18">
                            <div className="demand-center-device-component-demand-center-badge-elements-copy">
                               
                               
                            </div>
                            </div>
                            </div>
                            <div className="demand-center-device-component-demand-center-frame-parent26">

                               
                            </div> */}

                      </div>
                    </div>

                  </div>
                  <div className="demand-center-device-component-demand-center-filter-elements">
                    <div className="demand-center-device-component-demand-center-dropdown10" onClick={() => toggleThreeDotsDropdown(index)} ref={threeDotsRef}>
                      {/* <div className="demand-center-device-component-demand-center-dropdown10" > */}

                      <img
                        className="demand-center-device-component-demand-center-dots-vertical-icon"
                        alt=""
                        src={dotsvertical}
                      />
                                                              {activeThreeDotId === index && <ThreeDotsDemandCenterComponent />}

                      {/* {activeThreeDotId === index && <ThreeDotsListingComponent url={`wanted-device/${wantedDevice.id}`} />} */}
                    </div>

                  </div>
                </div>
              </div>
              <img className="demand-center-device-component-demand-center-divider-icon8" alt="" src={divider2} />

              <div className="demand-center-device-component-demand-center-frame-parent71">
                <div className="demand-center-device-component-demand-center-buttonsbutton-parent1">
                  <div className="demand-center-legend-series">
                    <img
                      className="demand-center-location-column-child"
                      alt=""
                      src={wantedDevice.profileImage}
                    // src={group_58_2x}
                    />

                    <div className="demand-center-location-column-inner">
                      <div className="demand-center-date-picker-dropdown">
                        <div className="demand-center-name">{wantedDevice.wanted_device_user.displayName}</div>
                        {/* <div className="demand-center-name">Prithvi Raj</div> */}
                        <div className="demand-center-role">{wantedDevice.wanted_device_user.user_usermeta.user_work_title}</div>
                      </div>
                    </div>
                  </div>
                  <div className='demand-center-device-component-main-buttons-group'>
                    <div className="demand-center-device-component-demand-center-buttonsbutton13">
                      <img
                        className="demand-center-device-component-demand-center-arrow-narrow-left-icon"
                        alt=""
                        src={eye}
                      />

                      <div className="demand-center-device-component-demand-center-search-input">
                        <div className="demand-center-device-component-demand-center-text67">View</div>
                      </div>
                    </div>
                    <div className="demand-center-device-component-demand-center-buttonsbutton25">
                      <div className="demand-center-device-component-demand-center-icon-wrapper">
                        <img className="demand-center-device-component-demand-center-icon1" alt="" src={icon1} />
                      </div>
                      <div className="demand-center-device-component-demand-center-search-input">
                        <div className="demand-center-device-component-demand-center-text68">Sales IQ</div>
                      </div>
                      <img
                        className="demand-center-device-component-demand-center-arrow-narrow-left-icon"
                        alt=""
                        src={lock04}
                      />
                    </div>
                    <div className="demand-center-device-component-demand-center-buttonsbutton26">
                      <div className="demand-center-device-component-demand-center-icon-wrapper">
                        <img className="demand-center-device-component-demand-center-icon1" alt="" src={icon1} />
                      </div>
                      <div className="demand-center-device-component-demand-center-search-input">
                        <div className="demand-center-device-component-demand-center-text68">Sales IQ</div>
                      </div>
                      <img
                        className="demand-center-device-component-demand-center-arrow-narrow-left-icon"
                        alt=""
                        src={lockunlocked04}
                      />
                    </div>
                    <Link to={`chat/wanted/Device/${wantedDevice.id}`}>
                    <div className="demand-center-device-component-demand-center-buttonsbutton27">
                      <img
                        className="demand-center-device-component-demand-center-upload-04-icon"
                        alt=""
                        src={messagechatsquare}
                      />

                      <div className="demand-center-device-component-demand-center-text145">Messages</div>
                      <div className="demand-center-device-component-demand-center-ellipse-parent">
                        <div className="demand-center-device-component-demand-center-ellipse-div"></div>
                        <div className="demand-center-device-component-demand-center-div">2</div>
                      </div>
                    </div>
                    </Link>
                  </div>

                </div>
                <div className="demand-center-device-component-demand-center-arrow-square-up-right-wrapper1">
                  <img
                    className="demand-center-device-component-demand-center-arrow-square-up-right-icon1"
                    alt=""
                    src={arrowsquareupright}


                  />
                </div>
              </div>
            </div>
          </div>
        </div>

      ))
    }
    else {
      content = <NoListingFound path={"/add-wanted-device"} pathName={"Wanted Device"} />
    }

  } else if (!loadingComplete && isSuccess) {
    content = <SkeletonListingLoaderComponent />; // Show loader until loadingComplete is true
  }




  return (
    <>
      <div className="demand-center-frame-wrapper">
        <div className="demand-center-frame-group">
          <div className="demand-center-rectangle-group">
            <div className="demand-center-frame-item"></div>
            <div className="demand-center-metric-card-row">
              <div className="demand-center-metric-card">
                <div className="demand-center-metric-card-title">
                  <div className="demand-center-heading4">Devices</div>
                </div>
                <div className="demand-center-dropdown3" style={{cursor:"pointer"}}   onClick={() => toggleThreeDotsDropdownNoAction(0)} ref={(el) => threeDotsRefNoAction.current[0] = el}>
                  <img
                    className="demand-center-dots-vertical-icon"
                    alt=""
                    src={dotsvertical}
                  />
                                                        {activeThreeDotIdNoAction === 0  && <ThreeDotsNoActionComponent  />}

                </div>
              </div>
              <div className="demand-center-number3">{totalCount || 0}</div>
            </div>
            {/* <div className="demand-center-badge11">
              <div className="demand-center-options-icon">
                <img
                  className="demand-center-arrow-up-icon2"
                  alt=""
                  src={arrowup1}
                />
              </div>
              <div className="demand-center-text58">20 slots available</div>
            </div> */}
          </div>
          <div className="demand-center-rectangle-container">
            <div className="demand-center-frame-inner"></div>
            <div className="demand-center-frame-container">
              <div className="demand-center-heading-parent">
                <div className="demand-center-heading5">Spares</div>
                <div className="demand-center-number4">{totalCountWantedSpare || 0}</div>
                {/* <div className="demand-center-badge12">
                  <div className="demand-center-options-icon">
                    <img
                      className="demand-center-arrow-up-icon2"
                      alt=""
                      src={arrowup1}
                    />
                  </div>
                  <div className="demand-center-change-amount">20%</div>
                </div> */}
              </div>
            </div>
            <div className="demand-center-dropdown3" style={{cursor:"pointer"}}   onClick={() => toggleThreeDotsDropdownNoAction(1)} ref={(el) => threeDotsRefNoAction.current[1] = el}>
              <img
                className="demand-center-dots-vertical-icon"
                alt=""
                src={dotsvertical}
              />
                                                        {activeThreeDotIdNoAction === 1  && <ThreeDotsNoActionComponent  />}

            </div>
          </div>
          <div className="demand-center-rectangle-container">
            <div className="demand-center-frame-inner"></div>
            <div className="demand-center-options-icon">
              <div className="demand-center-heading-group">
                <div className="demand-center-heading5">Services</div>
                <div className="demand-center-number4">{totalCountWantedService || 0}</div>
                {/* <div className="demand-center-badge12">
                  <div className="demand-center-options-icon">
                    <img
                      className="demand-center-arrow-up-icon2"
                      alt=""
                      src={arrowup1}
                    />
                  </div>
                  <div className="demand-center-change-amount">20%</div>
                </div> */}
              </div>
            </div>
            <div className="demand-center-dropdown3" style={{cursor:"pointer"}}  onClick={() => toggleThreeDotsDropdownNoAction(2)} ref={(el) => threeDotsRefNoAction.current[2] = el}>
              <img
                className="demand-center-dots-vertical-icon"
                alt=""
                src={dotsvertical}
              />
                                                        {activeThreeDotIdNoAction === 2  && <ThreeDotsNoActionComponent  />}

            </div>
          </div>
          <div className="demand-center-rectangle-container">
            <div className="demand-center-frame-inner"></div>
            <div className="demand-center-frame-container">
              <div className="demand-center-heading-container">
                <div className="demand-center-heading5">Software</div>
                <div className="demand-center-number4">{totalCountWantedSoftware || 0}</div>
                {/* <div className="demand-center-badge12">
                  <div className="demand-center-options-icon">
                    <img
                      className="demand-center-arrow-up-icon2"
                      alt=""
                      src={arrowup1}
                    />
                  </div>
                  <div className="demand-center-change-amount">20%</div>
                </div> */}
              </div>
            </div>
            <div className="demand-center-dropdown3" style={{cursor:"pointer"}}   onClick={() => toggleThreeDotsDropdownNoAction(3)} ref={(el) => threeDotsRefNoAction.current[3] = el}>
              <img
                className="demand-center-dots-vertical-icon"
                alt=""
                src={dotsvertical}
              />
                                                        {activeThreeDotIdNoAction === 3  && <ThreeDotsNoActionComponent  />}

            </div>
          </div>

        </div>
      </div>

      <div className="demand-center-section-wrapper">
        <div className="demand-center-section1">
          <div className="demand-center-container1">
            <div className="demand-center-filters-bar">
              <div className="demand-center-content17">
                <div className="demand-center-dropdowns">

                  <RadarFilterButtonGroup
                    handleFilterClick={handleFilterClick}
                    activePostStatus={activePostStatus}
                  />

                </div>
                <div className="demand-center-input-dropdown2">
                  {/* <div className="demand-center-input-with-label2">
                    <div className="demand-center-label">Search</div>
                    <div className="demand-center-input2">
                      <div className="demand-center-content3">
                        <img
                          className="demand-center-search-lg-icon demand-center-search-lg-icon-custom"
                          alt=""
                          src={searchlg}
                        />
                        <input type="text" className="demand-center-search-placeholder" placeholder='Search' />
                      </div>
                    </div>
                  </div> */}

                  <SearchInputListingComponent onSearch={handleSearch} />

                  <div className="demand-center-buttonsbutton1" onClick={toggleDropdown}>
                    <img
                      className="demand-center-upload-04-icon"
                      alt=""
                      src={sliders02}
                    />
                    <div className="demand-center-text-padding6" ref={dropdownRef}>
                      <div className="demand-center-text65">
                        Sort By {selectedSortOption && `: ${selectedSortOption.label}`}
                      </div>
                      {isDropdownVisible && <SortByFilterComponent onOptionSelect={handleOptionSelect} />}
                    </div>
                    <img
                      className="demand-center-placeholder-icon"
                      alt=""
                      src={placeholder2}
                    />

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      {/* Listing Container */}
      <div className="demand-center-device-component-listing-container">

        {/* Listing layout start    */}
        {content}



      </div>




      {/* Load more button  */}
      <div className="demand-center-frame-wrapper44">
        <div className="demand-center-frame-parent72">
          <div className="demand-center-buttonsbutton-wrapper1">
            {showLoadMore && (
              <button className='demand-center-buttonsbutton-wrapper1-child'
                onClick={handleLoadMore}
                disabled={isFetching}
              >
                <div className="demand-center-buttonsbutton28">
                  <img
                    className="demand-center-arrow-narrow-left-icon"
                    alt=""
                    src={arrowdown1}
                  />
                  <div className="demand-center-search-input">
                    <div className="demand-center-text146">{isFetching ? 'Loading...' : 'Load More'}</div>
                  </div>
                </div>
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  )
}


