import React from 'react'
import "./SkeletonListingLoaderComponent.css"
const SkeletonListingLoaderComponent = () => {
    return (
        <div className="skeleton-component-listing-container">

            <div className="skeleton-listing">
                <div className="skeleton-header">
                    <div className="skeleton-image"></div>
                    <div className="skeleton-info">
                        <div className="skeleton-title"></div>
                        <div className="skeleton-meta"></div>
                        <div className="skeleton-meta"></div>
                    </div>
                    <div className="skeleton-price"></div>
                </div>
                <div className="skeleton-tags">
                    <div className="skeleton-tag"></div>
                    <div className="skeleton-tag"></div>
                    <div className="skeleton-tag"></div>
                </div>
                <div className="skeleton-footer">
                    <div className="skeleton-avatar"></div>
                    <div className="skeleton-actions">
                        <div className="skeleton-button"></div>
                        <div className="skeleton-button"></div>
                        <div className="skeleton-button"></div>
                    </div>
                </div>
            </div>

            <div className="skeleton-listing">
                <div className="skeleton-header">
                    <div className="skeleton-image"></div>
                    <div className="skeleton-info">
                        <div className="skeleton-title"></div>
                        <div className="skeleton-meta"></div>
                        <div className="skeleton-meta"></div>
                    </div>
                    <div className="skeleton-price"></div>
                </div>
                <div className="skeleton-tags">
                    <div className="skeleton-tag"></div>
                    <div className="skeleton-tag"></div>
                    <div className="skeleton-tag"></div>
                </div>
                <div className="skeleton-footer">
                    <div className="skeleton-avatar"></div>
                    <div className="skeleton-actions">
                        <div className="skeleton-button"></div>
                        <div className="skeleton-button"></div>
                        <div className="skeleton-button"></div>
                    </div>
                </div>
            </div>

            <div className="skeleton-listing">
                <div className="skeleton-header">
                    <div className="skeleton-image"></div>
                    <div className="skeleton-info">
                        <div className="skeleton-title"></div>
                        <div className="skeleton-meta"></div>
                        <div className="skeleton-meta"></div>
                    </div>
                    <div className="skeleton-price"></div>
                </div>
                <div className="skeleton-tags">
                    <div className="skeleton-tag"></div>
                    <div className="skeleton-tag"></div>
                    <div className="skeleton-tag"></div>
                </div>
                <div className="skeleton-footer">
                    <div className="skeleton-avatar"></div>
                    <div className="skeleton-actions">
                        <div className="skeleton-button"></div>
                        <div className="skeleton-button"></div>
                        <div className="skeleton-button"></div>
                    </div>
                </div>
            </div>

            <div className="skeleton-listing">
                <div className="skeleton-header">
                    <div className="skeleton-image"></div>
                    <div className="skeleton-info">
                        <div className="skeleton-title"></div>
                        <div className="skeleton-meta"></div>
                        <div className="skeleton-meta"></div>
                    </div>
                    <div className="skeleton-price"></div>
                </div>
                <div className="skeleton-tags">
                    <div className="skeleton-tag"></div>
                    <div className="skeleton-tag"></div>
                    <div className="skeleton-tag"></div>
                </div>
                <div className="skeleton-footer">
                    <div className="skeleton-avatar"></div>
                    <div className="skeleton-actions">
                        <div className="skeleton-button"></div>
                        <div className="skeleton-button"></div>
                        <div className="skeleton-button"></div>
                    </div>
                </div>
            </div>

            <div className="skeleton-listing">
                <div className="skeleton-header">
                    <div className="skeleton-image"></div>
                    <div className="skeleton-info">
                        <div className="skeleton-title"></div>
                        <div className="skeleton-meta"></div>
                        <div className="skeleton-meta"></div>
                    </div>
                    <div className="skeleton-price"></div>
                </div>
                <div className="skeleton-tags">
                    <div className="skeleton-tag"></div>
                    <div className="skeleton-tag"></div>
                    <div className="skeleton-tag"></div>
                </div>
                <div className="skeleton-footer">
                    <div className="skeleton-avatar"></div>
                    <div className="skeleton-actions">
                        <div className="skeleton-button"></div>
                        <div className="skeleton-button"></div>
                        <div className="skeleton-button"></div>
                    </div>
                </div>
            </div>

            <div className="skeleton-listing">
                <div className="skeleton-header">
                    <div className="skeleton-image"></div>
                    <div className="skeleton-info">
                        <div className="skeleton-title"></div>
                        <div className="skeleton-meta"></div>
                        <div className="skeleton-meta"></div>
                    </div>
                    <div className="skeleton-price"></div>
                </div>
                <div className="skeleton-tags">
                    <div className="skeleton-tag"></div>
                    <div className="skeleton-tag"></div>
                    <div className="skeleton-tag"></div>
                </div>
                <div className="skeleton-footer">
                    <div className="skeleton-avatar"></div>
                    <div className="skeleton-actions">
                        <div className="skeleton-button"></div>
                        <div className="skeleton-button"></div>
                        <div className="skeleton-button"></div>
                    </div>
                </div>
            </div>

            <div className="skeleton-listing">
                <div className="skeleton-header">
                    <div className="skeleton-image"></div>
                    <div className="skeleton-info">
                        <div className="skeleton-title"></div>
                        <div className="skeleton-meta"></div>
                        <div className="skeleton-meta"></div>
                    </div>
                    <div className="skeleton-price"></div>
                </div>
                <div className="skeleton-tags">
                    <div className="skeleton-tag"></div>
                    <div className="skeleton-tag"></div>
                    <div className="skeleton-tag"></div>
                </div>
                <div className="skeleton-footer">
                    <div className="skeleton-avatar"></div>
                    <div className="skeleton-actions">
                        <div className="skeleton-button"></div>
                        <div className="skeleton-button"></div>
                        <div className="skeleton-button"></div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default SkeletonListingLoaderComponent