import React, { useEffect, useState } from 'react';
import "./SendingEmail.css";
import { Link, useNavigate } from 'react-router-dom';
import { Formik, Form, Field, useField } from 'formik';
import { BackToLoginButton } from '../../components/BackToLoginButton/BackToLoginButton';
import gerator_logo from '../../icons/Gerator/reset_password/gerator04-1@2x.png';
import placeholder from '../../icons/Gerator/reset_password/placeholder.svg';
import placeholder1 from '../../icons/Gerator/reset_password/placeholder1.svg';
import feature_icon from '../../icons/Gerator/reset_password/featured-icon.svg';
import help_icon from '../../icons/Gerator/reset_password/help-icon.svg';
import mail_icon from '../../icons/Gerator/reset_password/mail01.svg';
import { useSendMailMutation } from '../../api/Login&RegisterAPI';
import reset_nav from '../../icons/Gerator/reset_password/_Nav menu button.svg';
import * as Yup from "yup";
import { auth, sendPasswordResetEmail, fetchSignInMethodsForEmail } from "../Login/FirebaseClient";
import { decryptData } from '../../helper/AESHelper';
import { useRef } from 'react';
import { ResetPasswordDropdown } from '../../components/Dropdown/Dropdown';


export const MyTextInput = ({ validate, label, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input>. We can use field meta to show an error
  // message if the field is invalid and it has been touched (i.e. visited)
  const [field, meta] = useField(props);
  let fieldValidationData = null;
  if (validate?.length > 0) {
    validate.some((field) => {
      // Use 'some' for early termination
      if (field.path === props.name) {
        fieldValidationData = field;
        return true; // This will stop the loop early
      }
      return false; // Continue if no match
    });
  }

  if (fieldValidationData != null) {
    // console.log(fieldValidationData);
  }
  // {
  //   fieldValidationData !== null ? (
  //     <div className="reset-password-enter-mail-error">{fieldValidationData.msg}</div>
  //   ) : null
  // }

  return (
    <>
      {/* <label className="reset-password-enter-mail-label">Model Number</label> */}

      {/* <label className="reset-password-enter-mail-label" htmlFor={props.id || props.name}>
      {label} <span className="reset-password-enter-mail-text-wrapper-3">*</span>
    </label> */}
      <input
        className="reset-password-enter-mail-text-input text-25 content-15 myinputfield"
        // style={inputStyle}  //New code for border colour
        {...field}
        {...props}
      />
      {meta.touched && meta.error ? (
        <div className="reset-password-enter-mail-error">{meta.error}</div>
      ) : null}

      {fieldValidationData !== null ? (
        <div className="reset-password-enter-mail-error">{fieldValidationData.msg}</div>
      ) : null}
    </>
  );
};


export const SendingEmail = () => {


  const navigate = useNavigate();
  const [showSuccessMessage, setShowSuccessMessage] = useState({
    message: "",
    color: "",
  });
  // Mutation for adding a user
  const [sendMail, { isLoading, isError, error }] = useSendMailMutation();

  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible);
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // const sendPasswordResetMail = async (email) => {

  // };

  useEffect(() => {

    if (isError) {
      console.log(error);
      // const 
      if (error.status === 404) {
        const decryptedData = decryptData(error);
        if (!decryptedData.status) {
          setShowSuccessMessage({ message: decryptedData.message, color: "red" });
        }
        console.log(decryptedData);
        // navigate("/sign-in", { state: decryptedData.message });
      }
    }
  }, [error]);

  const finalSubmit = async (formData, setSubmitting) => {


    try {
      const response = await sendMail(formData).unwrap();
      console.log(response);

      // const actionCodeSettings = {
      //   // URL you want users to be redirected to after password reset
      //   url: 'http://localhost:1234/reset-password-new-password',
      //   // This must be true if you want to redirect back to your app
      //   handleCodeInApp: false,
      // };

      // Email exists, proceed with sending the password reset email
      await sendPasswordResetEmail(auth, formData.email);
      setShowSuccessMessage({ message: "Password reset email sent.", color: "green" });
      await new Promise(resolve => setTimeout(resolve, 1500));
      navigate('/reset-password-check-email', { state: formData.email });
      // console.log('Password reset email sent.');


    } catch (error) {
      if (error.code === 'auth/invalid-email') {
        setShowSuccessMessage({ message: "Invalid email address", color: "red" });
      } else if (error.code === 'auth/user-disabled') {
        setShowSuccessMessage({ message: "User account is disabled", color: "red" });
      } else {
        setShowSuccessMessage({ message: error.message, color: "red" });
      }
      setSubmitting(false);
    }

  };


  return (
    <div className="reset-password-enter-mail-reset-password-enter-email">
      <header className="reset-password-enter-mail-reset-password-enter-email-inner">
        <div className="reset-password-enter-mail-frame-parent">
          <div className="reset-password-enter-mail-logo-wrapper">
            <div className="reset-password-enter-mail-logo">
              <img
                className="reset-password-enter-mail-gerator-04-1-icon"
                alt=""
                src={gerator_logo}
              />
            </div>
          </div>
          <div className="reset-password-enter-mail-navigation-actions">
            <button className="reset-password-enter-mail-buttonsbutton">
              <img
                className="reset-password-enter-mail-placeholder-icon"
                alt=""
                src={placeholder}
              />

              <div className="reset-password-enter-mail-text-padding">
                <a href='/sign-in' className="reset-password-enter-mail-text">Log in</a>
              </div>
              <img
                className="reset-password-enter-mail-placeholder-icon"
                alt=""
                src={placeholder}
              />
            </button>
            <button className="reset-password-enter-mail-buttonsbutton1">
              <img
                className="reset-password-enter-mail-placeholder-icon"
                alt=""
                src={placeholder1}
              />

              <div className="reset-password-enter-mail-text-padding">
                <a href='/sign-up' className="reset-password-enter-mail-text1">Sign up</a>
              </div>
              <img
                className="reset-password-enter-mail-placeholder-icon"
                alt=""
                src={placeholder1}
              />
            </button>
          </div>

          <div ref={dropdownRef} className="reset-password-enter-mail-hamburger-menu" onClick={toggleDropdown}>
            {isDropdownVisible && <ResetPasswordDropdown />}
            <img

              className="reset-password-enter-mail-hamburger-icon"
              src={reset_nav}
              alt="" />

          </div>
        </div>
      </header>
      <main className="reset-password-enter-mail-sending-email-log-in">
        <section className="reset-password-enter-mail-container0">
          <div className="reset-password-enter-mail-container1">
            <div className="reset-password-enter-mail-content">
              <div className="reset-password-enter-mail-header">
                <img
                  className="reset-password-enter-mail-featured-icon"
                  loading="lazy"
                  alt=""
                  src={feature_icon}
                />

                <div className="reset-password-enter-mail-text-and-supporting-text">
                  <h2 className="reset-password-enter-mail-header-title">Forgot password?</h2>
                  <div className="reset-password-enter-mail-supporting-text">
                    No worries, we’ll send you reset instructions.
                  </div>
                </div>
              </div>
              <Formik
                initialValues={{
                  email: "",
                }}
                validationSchema={Yup.object({
                  email: Yup.string()
                    .email("Invalid email address")
                    .required("Email is required"),
                })}
                onSubmit={(values, { setSubmitting }) => {
                  // multiPartData = objectToWantedDeviceFormData(values)
                  console.log(values);
                  finalSubmit(values, setSubmitting);
                  // resetForm();
                }}
              >
                {({ handleSubmit, isSubmitting }) => (
                  <Form onSubmit={handleSubmit}>
                    <div className="reset-password-enter-mail-content1">
                      <div className="reset-password-enter-mail-form">
                        <div className="reset-password-enter-mail-input-field">
                          <div className="reset-password-enter-mail-input-field">
                            <div className="reset-password-enter-mail-label">Email</div>
                            <div className="reset-password-enter-mail-input">
                              {/* <Field
                              className="reset-password-enter-mail-content2"
                              name="email"
                              placeholder="Enter your email"
                              type="text"
                            /> */}
                              <MyTextInput className="reset-password-enter-mail-content2"
                                name="email"
                                placeholder="Enter your email"
                                type="email" />
                              <img
                                className="reset-password-enter-mail-help-icon"
                                alt=""
                                src={help_icon}
                              />
                            </div>
                          </div>
                          <div className="reset-password-enter-mail-hint-text">
                            This is a hint text to help the user.
                          </div>
                        </div>
                      </div>
                      <button className="reset-password-enter-mail-buttonsbutton2" type="submit" disabled={isSubmitting}>
                        <img
                          className="reset-password-enter-mail-placeholder-icon"
                          alt=""
                          src={placeholder1}
                        />
                        {/* <Link
                        to={"/reset-password-check-email"}
                        style={{ textDecoration: 'none' }}
                      > */}
                        <div className="reset-password-enter-mail-text-padding">
                          <div className="reset-password-enter-mail-text2">{isSubmitting ? "Sending..." : "Send"}</div>
                        </div>
                        {/* </Link> */}
                        <img
                          className="reset-password-enter-mail-placeholder-icon"
                          alt=""
                          src={placeholder1}
                        />
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
              {showSuccessMessage.message?.length > 0 && (
                <div
                  style={{
                    fontSize: "14px",
                    color: showSuccessMessage.color,
                    padding: "15px 10px",
                    border: "1px solid " + showSuccessMessage.color,
                    borderRadius: "10px",
                    margin: "0 auto",
                  }}
                >
                  {showSuccessMessage.message}
                </div>
              )}
              <Link to={"/sign-in"} style={{ textDecoration: 'none' }}>
                <BackToLoginButton />
              </Link>
            </div>
          </div>
        </section>
        <div className="reset-password-enter-mail-text4">© Gerator Asia LLP. All rights reserved.</div>
        <img
          className="reset-password-enter-mail-mail-01-icon"
          loading="lazy"
          alt=""
          src={mail_icon}
        />

        <div className="reset-password-enter-mail-helpcentergeratorcom">helpcenter@gerator.com</div>
      </main>
    </div>
  )
}

