import React, { useState } from 'react';
import "./ThreeDotsListingComponent.css";  



export const ThreeDotsSalesIqDetailPageComponent = ({ onLaunchCampaignClick, onSocialShareClick  }) => {

    const handleLaunchCampaign = () => {
        console.log("Launch Campaign Clicked");
        if (onLaunchCampaignClick) {
          onLaunchCampaignClick();
        }
      };
    
      const handleSocialShare = () => {
        console.log("Social Channels Clicked");
        if (onSocialShareClick) {
          onSocialShareClick();
        }
      };
  return (
    <>
     
    <div className="three-dots-filter-dropdown-three-dots-filter-all-options-fixed">
      <section className="three-dots-filter-dropdown-menu-items">
        {/* Broadcast Option */}
        <div className="three-dots-filter-dropdown-dropdown-list-item">
          <div className="three-dots-filter-dropdown-content48">
            <div className="three-dots-filter-dropdown-icon-and-text">
              <div 
                className="three-dots-filter-dropdown-text247" 
                style={{ cursor: 'pointer' }} 
                onClick={handleLaunchCampaign}
              >
                Launch Campaign
              </div>
            </div>
          </div>
        </div>

        {/* Social Share Option */}
        <div className="three-dots-filter-dropdown-dropdown-list-item">
          <div className="three-dots-filter-dropdown-content48">
            <div className="three-dots-filter-dropdown-icon-and-text">
              <div
                className="three-dots-filter-dropdown-text247"
                style={{ cursor: 'pointer' }}
                onClick={handleSocialShare}
              >
                Social Channels
              </div>
            </div>
          </div>
        </div>

        <div className="three-dots-filter-dropdown-dropdown-list-item">
          <div className="three-dots-filter-dropdown-content48">
            <div className="three-dots-filter-dropdown-icon-and-text">
              <div
                className="three-dots-filter-dropdown-text247"
                style={{ cursor: 'pointer' }}
              
              >
                Edit
              </div>
            </div>
          </div>
        </div>

        <div className="three-dots-filter-dropdown-dropdown-list-item">
          <div className="three-dots-filter-dropdown-content48">
            <div className="three-dots-filter-dropdown-icon-and-text">
              <div
                className="three-dots-filter-dropdown-text247"
                style={{ cursor: 'pointer' }}
              
              >
                Archive
              </div>
            </div>
          </div>
        </div>

        <div className="three-dots-filter-dropdown-dropdown-list-item">
          <div className="three-dots-filter-dropdown-content48">
            <div className="three-dots-filter-dropdown-icon-and-text">
              <div
                className="three-dots-filter-dropdown-text247"
                style={{ cursor: 'pointer' }}
             
              >
                Republish
              </div>
            </div>
          </div>
        </div>
      </section>

    
 

    </div>
    </>
  );
};
