import React, { useEffect, useRef, useState } from 'react';
import "./SalesIQServiceComponent.css"
import dotsvertical from '../../../icons/Gerator/sales_iq/dotsvertical.svg';
import calendar1 from '../../../icons/Gerator/sales_iq/calendar1.svg';
import content_elements_2x from '../../../icons/Gerator/sales_iq/content-elements@2x.png';
import dot1 from '../../../icons/Gerator/sales_iq/-dot1.svg';
import radar_1_1_2x from '../../../icons/Gerator/sales_iq/radar-1-1@2x.png';
import deal_2_1_2x from '../../../icons/Gerator/sales_iq/deal-2-1@2x.png';
import magnifier_1_2x from '../../../icons/Gerator/sales_iq/magnifier-1@2x.png';
import content_elements_2x from '../../../icons/Gerator/sales_iq/content-elements@2x.png';
import eye from '../../../icons/Gerator/sales_iq/eye.svg';
import group_58_2x from '../../../icons/Gerator/sales_iq/group-58@2x.png';

import icon1 from '../../../icons/Gerator/sales_iq/icon1.svg';
import lock04 from '../../../icons/Gerator/sales_iq/lock04.svg';
import lockunlocked04 from '../../../icons/Gerator/sales_iq/lockunlocked04.svg';
import arrowsquareupright from '../../../icons/Gerator/sales_iq/arrowsquareupright.svg';
import messagechatsquare from '../../../icons/Gerator/sales_iq/messagechatsquare.svg';
import markerpin02 from '../../../icons/Gerator/sales_iq/markerpin02.svg';
import divider2 from '../../../icons/Gerator/sales_iq/divider2.svg';
import arrowup1 from '../../../icons/Gerator/sales_iq/arrowup1.svg';
import arrowdown1 from '../../../icons/Gerator/sales_iq/arrowdown1.svg';
import placeholder2 from '../../../icons/Gerator/sales_iq/placeholder2.svg';
import xclose from '../../../icons/Gerator/sales_iq/xclose.svg';
import filterlines from '../../../icons/Gerator/sales_iq/filterlines.svg';
import sliders02 from '../../../icons/Gerator/sales_iq/sliders02.svg';
import searchlg from '../../../icons/Gerator/sales_iq/searchlg.svg';
import { SortByFilterComponent } from '../../SortByFilterComponent/SortByFilterComponent';
import DashboardFilterButtonGroup from '../../DashboardFilterButtonGroup/DashboardFilterButtonGroup';
import { ThreeDotsListingComponent } from '../../ThreeDotsListingComponent/ThreeDotsListingComponent';
import { Link } from 'react-router-dom';
import { PostStatusLIstingComponent } from '../../PostStatusLIstingComponent/PostStatusLIstingComponent';
import { NoListingFound } from '../../NoListing/NoListingFound';
import SkeletonListingLoaderComponent from '../../SkeletonListingLoaderComponent/SkeletonListingLoaderComponent';
import { getCustomizedDate } from '../../../helper/ServiceHelper';
import SearchInputListingComponent from '../../SearchInputListingComponent/SearchInputListingComponent';
import { useGetSalesIQDashboardServicesQuery, useGetUserDashboardServicesQuery } from '../../../api/ServiceAPI';
import { formatNumber } from '../../../helper/DeviceHelper';
import arrowup from "../../../icons/Gerator/sales_iq/arrowup.svg";
import _chart_mini2 from "../../../icons/Gerator/sales_iq/-chart-mini2@2x.png"
import { ThreeDotsNoActionComponent } from '../../ThreeDotsListingComponent/ThreeDotsNoActionComponent';



export const SalesIQServiceComponent = () => {


const [activeThreeDotIdNoAction, setActiveThreeDotIdNoAction] = useState(null);
    const threeDotsRefNoAction = useRef([]);
    const [isClickBlockedNoAction, setIsClickBlockedNoAction] = useState(false);
  
    const toggleThreeDotsDropdownNoAction = (index) => {
      if (isClickBlockedNoAction) return; // Prevent rapid toggle
  
     

        if (activeThreeDotIdNoAction === index) {
          setActiveThreeDotIdNoAction(null); // Close if the same dropdown is clicked again
        } else {
          setActiveThreeDotIdNoAction(index); // Open the dropdown
        }

        setIsClickBlockedNoAction(true);
        setTimeout(() => setIsClickBlockedNoAction(false), 400);
    };
  
    
     useEffect(() => {
       const handleThreeDotClickOutsideNoAction = (event) => {
         if (activeThreeDotIdNoAction !== null) {
           const ref = threeDotsRefNoAction.current[activeThreeDotIdNoAction];
     
           if (ref && !ref.contains(event.target)) {
             setActiveThreeDotIdNoAction(null); // Close when clicking outside
           }
         }
       };
     
       document.addEventListener("mousedown", handleThreeDotClickOutsideNoAction);
       return () => {
         document.removeEventListener("mousedown", handleThreeDotClickOutsideNoAction);
       };
     }, [activeThreeDotIdNoAction]);



  //Offset to load data when click load more
  const [offset, setOffset] = useState(0);
  const [sortBy, setSortBy] = useState('date_desc');
  const [searchTerm, setSearchTerm] = useState('');
  const [totalCount, setTotalCount] = useState("");
  const [totalCountPublished, settotalCountPublished] = useState("");
  const [totalCountPending, settotalCountPending] = useState("");
  const [totalCountArchived, settotalCountArchived] = useState("");
  const [totalCountExpired, settotalCountExpired] = useState("");

  const [activePostStatus, setActivePostStatusFilter] = useState(null);
  //Query to get data
  const { data, isSuccess, isError, refetch, isLoading, isFetching } = useGetSalesIQDashboardServicesQuery(
    { offset, sortBy, postStatus: activePostStatus, search: searchTerm },
    {
      // Refetch when component mounts or tab becomes active
      refetchOnMountOrArgChange: true
    }
  );

  //flag var to show listing when loading complete when sekelteon layout is going to stop
  const [loadingComplete, setLoadingComplete] = useState(false);

  //Var is reponsible to show listings and other thing as well
  let content;

  //Sort by text show on button
  const [selectedSortOption, setSelectedSortOption] = useState('');

  //For SortBy Dropdown
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);

  //For Threedots in listing dropdown
  const [activeThreeDotId, setActiveThreeDotId] = useState(null);
  const threeDotsRef = useRef(null);
  const [isClickBlocked, setIsClickBlocked] = useState(false);



  const toggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible);
  }

  const toggleThreeDotsDropdown = (id) => {
    if (isClickBlocked) return; // Prevent rapid toggle

    if (activeThreeDotId === id) {
      setActiveThreeDotId(null); // Close if the same one is clicked again
      setIsClickBlocked(true);
      setTimeout(() => setIsClickBlocked(false), 400); // Small delay to avoid reopening
    } else {
      setActiveThreeDotId(id); // Open the clicked one
    }
  };

  const handleSearch = (term) => {
    setSearchTerm(term);
  };


  //Set value for sortby selected option on button text
  const handleOptionSelect = (option) => {
    if (option.value !== sortBy) {
      setSortBy(option.value);
      setOffset(0); // Reset offset when sort option changes
    }
    setSelectedSortOption(option);
    setDropdownVisible(false);
  };

  //DashbaordFilterButtonGroup component to get data
  const handleFilterClick = (filterLabel) => {
    if (activePostStatus === filterLabel) {
      setSortBy('date_desc')
      setSelectedSortOption('')
      setActivePostStatusFilter(null);
    } else {
      setSortBy('date_desc')
      setSelectedSortOption('')
      setActivePostStatusFilter(filterLabel);
    }
    setOffset(0); // Reset offset when changing filters
  };

  //Setting offset for loadmore when user clicked on load more button(this trigger query to fetch new data)
  // Reset offset when component mounts or remounts
  // useEffect(() => {
  //   setOffset(0);
  // }, []);
  // // Reset offset when sort option changes
  // useEffect(() => {
  //   setOffset(0);
  // }, [sortBy]);

  const handleLoadMore = () => {
    if (data?.hasMore && !isFetching) {
      setOffset(currentOffset => currentOffset + 5);
    }
  };

  const showLoadMore = data?.hasMore &&
    data.services.length < data.totalCount &&
    !isFetching;





  //Onclick listener for sortby dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


  //Onclick listener for threedots dropdown
  useEffect(() => {

    const handleThreeDotClickOutside = (event) => {

      const isLink = event.target.closest('a');

      if (isLink) {
        return; // Allow navigation to happen
      }

      if (threeDotsRef.current && !threeDotsRef.current.contains(event.target)) {
        setActiveThreeDotId(null); // Close any open dropdown when clicking outside
      }
    };

    document.addEventListener("mousedown", handleThreeDotClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleThreeDotClickOutside);
    };
  }, []);

  // Listen for tab visibility changes
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        refetch();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [refetch]);


  //Success true then pause to show sekelton layout
  useEffect(() => {
    if (isSuccess) {
      // Set totalcount in listing counter layout
      setTotalCount(data.totalCount);
      settotalCountPublished(data.totalCountPublished);
      settotalCountArchived(data.totalCountArchived);
      settotalCountPending(data.totalCountPending);
      settotalCountExpired(data.totalCountExpired);
      // Set timeout to delay the loading completion
      const timer = setTimeout(() => {
        setLoadingComplete(true);
      }, 1000); // 1 second

      // Cleanup timer on component unmount
      return () => clearTimeout(timer);
    }
  }, [isSuccess]);


  if (isError) {
    content = <NoListingFound path={"/add-service"} pathName={"Service"} />
  }

  if (isLoading) {
    content = <SkeletonListingLoaderComponent />;
  } else if (loadingComplete && isSuccess) {

    if (data.services.length > 0) {
      // dataLength = data.services.length;
      content = data.services.map((service, index) =>
      (
        <div className="sales-iq-service-component-sales-iq-content-items" key={index}>
          <div className="sales-iq-service-component-sales-iq-divider-container1">
            <div className="sales-iq-service-component-sales-iq-rectangle-parent5">
              <img
                className="sales-iq-service-component-sales-iq-frame-child7"
                alt=""
                src={service.featureImage}
              />

              <div className="sales-iq-service-component-sales-iq-frame-parent1">
                <div className="sales-iq-service-component-sales-iq-action-fields">
                  <div className="sales-iq-service-component-sales-iq-input-elements">
                    <Link to={`/service/${service.id}/${service.title}`} className="sales-iq-service-component-sales-iq-text78">
                      {service.title}
                    </Link>
                    <div className="sales-iq-service-component-sales-iq-frame-parent21">
                      <div className="sales-iq-service-component-sales-iq-badge-item-parent">
                        <div className="sales-iq-service-component-sales-iq-badge-parent">

                          <PostStatusLIstingComponent postStatus={service.tax_post_status.name} postSlug={service.tax_post_status.slug} postId={service.id} />

                        </div>
                        <div className="sales-iq-service-component-sales-iq-frame-parent4">
                          <div className="sales-iq-service-component-sales-iq-end-label">
                            <div className="sales-iq-service-component-sales-iq-search-icon-input">4.9/5</div>
                          </div>
                          <div className="sales-iq-service-component-sales-iq-type-support">
                            <div className="sales-iq-service-component-sales-iq-supporting-text9">
                              99,999 reviews
                            </div>
                          </div>
                        </div>

                      </div>
                      <div className="sales-iq-service-component-sales-iq-frame-parent24">
                        <div className="sales-iq-service-component-sales-iq-location-pin-parent">
                          <div className="sales-iq-service-component-sales-iq-search-input">
                            <img
                              className="sales-iq-service-component-sales-iq-marker-pin-02-icon2"
                              alt=""
                              src={markerpin02}
                            />
                          </div>
                          <div className="sales-iq-service-component-sales-iq-location3">
                            {
                              (service.service_address.tax_address_type.slug === 'remote') && service.service_address.tax_address_type.name
                            }

                            {
                              (service.service_address.tax_address_type.slug === 'address' && `${service.service_address.addressTownCity}, ${service.service_address.tax_country.name}`)
                            }
                            {/* {service.service_address.addressTownCity}, {service.service_address.tax_country.name} */}
                          </div>
                        </div>
                        {/* <div className="sales-iq-service-component-sales-iq-frame-wrapper18">
                          <div className="sales-iq-service-component-sales-iq-badge-elements-copy">
                           
                          </div>
                        </div> */}
                      </div>
                      <div className="sales-iq-service-component-sales-iq-frame-parent26">
                        <div className="sales-iq-service-component-sales-iq-frame-wrapper19">
                          <div className="sales-iq-service-component-sales-iq-legend-series">
                            <div className="sales-iq-service-component-sales-iq-search-input">
                              <img
                                className="sales-iq-service-component-sales-iq-marker-pin-02-icon2"
                                alt=""
                                src={calendar1}
                              />
                            </div>
                            <div className="sales-iq-service-component-sales-iq-type-of-work2">{getCustomizedDate(service.createdAt)}</div>
                          </div>
                        </div>

                      </div>

                    </div>
                  </div>

                  <div className="sales-iq-service-component-sales-iq-small-card">

                    <div className="sales-iq-service-component-sales-iq-badge33">
                      <div className="sales-iq-service-component-sales-iq-third-badge-number">
                        {service.tax_service_category.name}
                      </div>
                    </div>

                    <div className="sales-iq-service-component-sales-iq-badge39">
                      <div className="sales-iq-service-component-sales-iq-third-badge-number">
                        Accounting
                      </div>
                    </div>

                    <div className="sales-iq-service-component-sales-iq-badge38">
                      <div className="sales-iq-service-component-sales-iq-third-badge-number">
                        {service.tax_clinical_application.name}
                      </div>
                    </div>

                    <div className="sales-iq-service-component-sales-iq-badge36">
                      <div className="sales-iq-service-component-sales-iq-third-badge-number">
                        11 Health
                      </div>
                    </div>


                    <div className="sales-iq-service-component-sales-iq-badge37">
                      <div className="sales-iq-service-component-sales-iq-third-badge-number">{service.tax_service_and_support_reach.name}</div>
                    </div>


                  </div>




                </div>





              </div>


              <div className="sales-iq-service-component-sales-iq-filter-elements">
                {/* {(service.tax_post_status.slug !== 'pending') ? */}
                <div className="sales-iq-service-component-sales-iq-dropdown10" onClick={() => toggleThreeDotsDropdown(index)} ref={threeDotsRef}>
                  <img
                    className="sales-iq-service-component-sales-iq-dots-vertical-icon"
                    alt=""
                    src={dotsvertical}
                  />


                  {activeThreeDotId === index && <ThreeDotsListingComponent url={`service/${service.id}`} />}
                </div>
                {/* : */}
                <div className="sales-iq-service-component-sales-iq-dropdown10" >
                  {/* <img
                        className="sales-iq-service-component-sales-iq-dots-vertical-icon"
                        alt=""
                        src={dotsvertical}
                      /> */}


                  {/* {activeThreeDotId === index && <ThreeDotsListingComponent url={`service/${service.id}`} />} */}
                </div>

                {/* } */}
                <div className="sales-iq-service-component-sales-iq-filter-elements-inner">
                  <div className="sales-iq-service-component-sales-iq-price-parent">
                    {/* <TransactionTypeComponent transactionType={service.tax_transaction_type.name} slug={service.tax_transaction_type.slug} /> */}
                    <div className="sales-iq-service-component-sales-iq-text83">Starting From</div>
                    <div className="sales-iq-service-component-sales-iq-price2">${formatNumber(service.rangeMin)}</div>
                    <div className="sales-iq-service-component-sales-iq-dropdown-elements">
                      <div className="sales-iq-service-component-sales-iq-dropdown-options-parent">
                        <div className="sales-iq-service-component-sales-iq-text83">{service.tax_unit_measure_service.name}</div>
                        <div className="sales-iq-service-component-sales-iq-frame-wrapper3">
                          <div className="sales-iq-service-component-sales-iq-radar-1-1-container">
                            <img
                              className="sales-iq-service-component-sales-iq-magnifier-1-icon"
                              alt=""
                              src={radar_1_1_2x}
                            />

                            <img
                              className="sales-iq-service-component-sales-iq-magnifier-1-icon"
                              alt=""
                              src={deal_2_1_2x}
                            />

                            <img
                              className="sales-iq-service-component-sales-iq-magnifier-1-icon"
                              alt=""
                              src={magnifier_1_2x}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>

            <img className="sales-iq-service-component-sales-iq-divider-icon8" alt="" src={divider2} />

            <div className="sales-iq-service-component-sales-iq-metric-item-parent">

              <div className="sales-iq-service-component-sales-iq-metric-item3">
                <div className="sales-iq-service-component-sales-iq-heading9">Active engagement</div>
                <div className="sales-iq-service-component-sales-iq-number-and-chart">
                  <div className="sales-iq-service-component-sales-iq-number-and-badge3">
                    <div className="sales-iq-service-component-sales-iq-heading">{service.totalActiveEngagement || 0}</div>
                    <div className="sales-iq-service-component-sales-iq-change-and-text3">
                      <div className="sales-iq-service-component-sales-iq-change2">
                        <img
                          className="sales-iq-service-component-sales-iq-upload-04-icon"
                          alt=""
                          src={arrowup}
                        />

                        <div className="sales-iq-service-component-sales-iq-change7">100%</div>
                      </div>
                      <div className="sales-iq-service-component-sales-iq-text139">vs last month</div>
                    </div>
                  </div>
                  <img
                    className="sales-iq-service-component-sales-iq-chart-mini-icon3"
                    alt=""
                    src={_chart_mini2}

                  />
                </div>
                <div className="sales-iq-service-component-sales-iq-dropdown19" style={{cursor:"pointer"}}  onClick={() => toggleThreeDotsDropdownNoAction(4)} ref={(el) => threeDotsRefNoAction.current[4] = el}>
                  <img
                    className="sales-iq-service-component-sales-iq-dots-vertical-icon"
                    alt=""
                    src={dotsvertical}
                  />
                                              {activeThreeDotIdNoAction === 4  && <ThreeDotsNoActionComponent  />}
                  
                </div>
              </div>

              <div className="sales-iq-service-component-sales-iq-metric-item3">
                <div className="sales-iq-service-component-sales-iq-heading9">Views</div>
                <div className="sales-iq-service-component-sales-iq-number-and-chart">
                  <div className="sales-iq-service-component-sales-iq-number-and-badge3">
                    <div className="sales-iq-service-component-sales-iq-heading">{service.totalCountView}</div>
                    <div className="sales-iq-service-component-sales-iq-change-and-text3">
                      <div className="sales-iq-service-component-sales-iq-change2">
                        <img
                          className="sales-iq-service-component-sales-iq-upload-04-icon"
                          alt=""
                          src={arrowup}
                        />

                        <div className="sales-iq-service-component-sales-iq-change7">100%</div>
                      </div>
                      <div className="sales-iq-service-component-sales-iq-text139">vs last month</div>
                    </div>
                  </div>
                  <img
                    className="sales-iq-service-component-sales-iq-chart-mini-icon3"
                    alt=""
                    src={_chart_mini2}

                  />
                </div>
                <div className="sales-iq-service-component-sales-iq-dropdown19" style={{cursor:"pointer"}}  onClick={() => toggleThreeDotsDropdownNoAction(5)} ref={(el) => threeDotsRefNoAction.current[5] = el}>
                  <img
                    className="sales-iq-service-component-sales-iq-dots-vertical-icon"
                    alt=""
                    src={dotsvertical}
                  />
                                              {activeThreeDotIdNoAction === 5  && <ThreeDotsNoActionComponent  />}
                  
                </div>
              </div>
              <div className="sales-iq-service-component-sales-iq-metric-item3">
                <div className="sales-iq-service-component-sales-iq-heading9">Added to Radar</div>
                <div className="sales-iq-service-component-sales-iq-number-and-chart">
                  <div className="sales-iq-service-component-sales-iq-number-and-badge3">
                    <div className="sales-iq-service-component-sales-iq-heading">{service.totalCountRadar}</div>
                    <div className="sales-iq-service-component-sales-iq-change-and-text3">
                      <div className="sales-iq-service-component-sales-iq-change2">
                        <img
                          className="sales-iq-service-component-sales-iq-upload-04-icon"
                          alt=""
                          src={arrowup}
                        />

                        <div className="sales-iq-service-component-sales-iq-change7">100%</div>
                      </div>
                      <div className="sales-iq-service-component-sales-iq-text139">vs last month</div>
                    </div>
                  </div>
                  <img
                    className="sales-iq-service-component-sales-iq-chart-mini-icon3"
                    alt=""
                    src={_chart_mini2}
                  />
                </div>
                <div className="sales-iq-service-component-sales-iq-dropdown19" style={{cursor:"pointer"}}  onClick={() => toggleThreeDotsDropdownNoAction(6)} ref={(el) => threeDotsRefNoAction.current[6] = el}>
                  <img
                    className="sales-iq-service-component-sales-iq-dots-vertical-icon"
                    alt=""
                    src={dotsvertical}
                  />
                                              {activeThreeDotIdNoAction === 6  && <ThreeDotsNoActionComponent  />}
                  
                </div>
              </div>
              <div className="sales-iq-service-component-sales-iq-metric-item3">
                <div className="sales-iq-service-component-sales-iq-heading9">Searches</div>
                <div className="sales-iq-service-component-sales-iq-number-and-chart">
                  <div className="sales-iq-service-component-sales-iq-number-and-badge3">
                    <div className="sales-iq-service-component-sales-iq-heading">{service.totalSearch || 0}</div>
                    <div className="sales-iq-service-component-sales-iq-change-and-text3">
                      <div className="sales-iq-service-component-sales-iq-change2">
                        <img
                          className="sales-iq-service-component-sales-iq-upload-04-icon"
                          alt=""
                          src={arrowup}
                        />

                        <div className="sales-iq-service-component-sales-iq-change7">100%</div>
                      </div>
                      <div className="sales-iq-service-component-sales-iq-text139">vs last month</div>
                    </div>
                  </div>
                  <img
                    className="sales-iq-service-component-sales-iq-chart-mini-icon3"
                    alt=""
                    src={_chart_mini2}
                  />
                </div>
                <div className="sales-iq-service-component-sales-iq-dropdown19" style={{cursor:"pointer"}}  onClick={() => toggleThreeDotsDropdownNoAction(7)} ref={(el) => threeDotsRefNoAction.current[7] = el}>
                  <img
                    className="sales-iq-service-component-sales-iq-dots-vertical-icon"
                    alt=""
                    src={dotsvertical}
                  />
                                              {activeThreeDotIdNoAction === 7  && <ThreeDotsNoActionComponent  />}
                  
                </div>
              </div>
            </div>

            <img className="sales-iq-service-component-sales-iq-divider-icon8" alt="" src={divider2} />

            <div className="sales-iq-service-component-sales-iq-frame-parent71">
              <div className="sales-iq-service-component-sales-iq-buttonsbutton-parent1">
                <div className="sales-iq-legend-series">
                  <img
                    className="sales-iq-location-column-child"
                    alt=""
                    src={service.profileImage}
                  />

                  <div className="sales-iq-location-column-inner">
                    <div className="sales-iq-date-picker-dropdown">
                      <div className="sales-iq-name">{service.service_user.displayName}</div>
                      <Link className="sales-iq-role" to={`/business/${service.service_business.id}/${service.service_business.businessName}`}>
                        {service.service_business.businessName}
                      </Link>
                    </div>
                  </div>
                </div>
                <div className='sales-iq-service-component-main-buttons-group'>
                  <div className="sales-iq-service-component-sales-iq-buttonsbutton13">
                    <img
                      className="sales-iq-service-component-sales-iq-arrow-narrow-left-icon"
                      alt=""
                      src={eye}
                    />

                    <div className="sales-iq-service-component-sales-iq-search-input">
                      <div className="sales-iq-service-component-sales-iq-text67">View</div>
                    </div>
                  </div>
                  {/* <div className="sales-iq-service-component-sales-iq-buttonsbutton25">
                    <div className="sales-iq-service-component-sales-iq-icon-wrapper">
                      <img className="sales-iq-service-component-sales-iq-icon1" alt="" src={icon1} />
                    </div>
                    <div className="sales-iq-service-component-sales-iq-search-input">
                      <div className="sales-iq-service-component-sales-iq-text68">Sales IQ</div>
                    </div>
                    <img
                      className="sales-iq-service-component-sales-iq-arrow-narrow-left-icon"
                      alt=""
                      src={lock04}
                    />
                  </div> */}

                  <Link to={`/sales-iq/service/${service.id}/${service.title}`}>
                    <div className="sales-iq-service-component-sales-iq-buttonsbutton26">
                      <div className="sales-iq-service-component-sales-iq-icon-wrapper">
                        <img className="sales-iq-service-component-sales-iq-icon1" alt="" src={icon1} />
                      </div>
                      <div className="sales-iq-service-component-sales-iq-search-input">
                        <div className="sales-iq-service-component-sales-iq-text68">Sales IQ</div>
                      </div>
                      <img
                        className="sales-iq-service-component-sales-iq-arrow-narrow-left-icon"
                        alt=""
                        src={lockunlocked04}
                      />
                    </div>

                  </Link>

                  <div className="sales-iq-service-component-sales-iq-buttonsbutton27">
                    <img
                      className="sales-iq-service-component-sales-iq-upload-04-icon"
                      alt=""
                      src={messagechatsquare}
                    />

                    <div className="sales-iq-service-component-sales-iq-text145">Messages</div>
                    <div className="sales-iq-service-component-sales-iq-ellipse-parent">
                      <div className="sales-iq-service-component-sales-iq-ellipse-div"></div>
                      <div className="sales-iq-service-component-sales-iq-div">2</div>
                    </div>
                  </div>
                </div>

              </div>
              <a 
                href={`/service/${service.id}/${service.title}`}
                target="_blank"
                rel="noopener noreferrer"
                className="sales-iq-service-component-sales-iq-arrow-square-up-right-wrapper1"
              >
                <img
                  className="sales-iq-service-component-sales-iq-arrow-square-up-right-icon1"
                  alt=""
                  src={arrowsquareupright}
                />
              </a>
            </div>
          </div>
        </div>
      ))
    }
    else {
      content = (

        <NoListingFound path={"/add-service"} pathName={"Service"} />

      )
    }

  } else if (!loadingComplete && isSuccess) {
    content = <SkeletonListingLoaderComponent />; // Show loader until loadingComplete is true
  }




  return (
    <>
      <div className="sales-iq-frame-wrapper">
        <div className="sales-iq-frame-group">
          <div className="sales-iq-rectangle-group">
            <div className="sales-iq-frame-item"></div>
            <div className="sales-iq-metric-card-row">
              <div className="sales-iq-metric-card">
                <div className="sales-iq-metric-card-title">
                  <div className="sales-iq-heading4">Total</div>
                </div>
                <div className="sales-iq-dropdown3"  style={{cursor:"pointer"}}  onClick={() => toggleThreeDotsDropdownNoAction(0)} ref={(el) => threeDotsRefNoAction.current[0] = el}>
                  <img
                    className="sales-iq-dots-vertical-icon"
                    alt=""
                    src={dotsvertical}
                  />
                                      {activeThreeDotIdNoAction === 0  && <ThreeDotsNoActionComponent  />}
                  
                </div>
              </div>
              <div className="sales-iq-number3">{totalCount || 0}</div>
            </div>
            <div className="sales-iq-badge11">
              <div className="sales-iq-options-icon">
                <img
                  className="sales-iq-arrow-up-icon2"
                  alt=""
                  src={arrowup1}
                />
              </div>
              <div className="sales-iq-text58">20 slots available</div>
            </div>
          </div>
          <div className="sales-iq-rectangle-container">
            <div className="sales-iq-frame-inner"></div>
            <div className="sales-iq-frame-container">
              <div className="sales-iq-heading-parent">
                <div className="sales-iq-heading5">Published</div>
                <div className="sales-iq-number4">{totalCountPublished || 0}</div>
                <div className="sales-iq-badge12">
                  <div className="sales-iq-options-icon">
                    <img
                      className="sales-iq-arrow-up-icon2"
                      alt=""
                      src={arrowup1}
                    />
                  </div>
                  <div className="sales-iq-change-amount">20%</div>
                </div>
              </div>
            </div>
            <div className="sales-iq-dropdown3"  style={{cursor:"pointer"}}  onClick={() => toggleThreeDotsDropdownNoAction(1)} ref={(el) => threeDotsRefNoAction.current[1] = el}>
              <img
                className="sales-iq-dots-vertical-icon"
                alt=""
                src={dotsvertical}
              />
                                  {activeThreeDotIdNoAction === 1  && <ThreeDotsNoActionComponent  />}
              
            </div>
          </div>
          <div className="sales-iq-rectangle-container">
            <div className="sales-iq-frame-inner"></div>
            <div className="sales-iq-options-icon">
              <div className="sales-iq-heading-group">
                <div className="sales-iq-heading5">Pending</div>
                <div className="sales-iq-number4">{totalCountPending || 0}</div>
                <div className="sales-iq-badge12">
                  <div className="sales-iq-options-icon">
                    <img
                      className="sales-iq-arrow-up-icon2"
                      alt=""
                      src={arrowup1}
                    />
                  </div>
                  <div className="sales-iq-change-amount">20%</div>
                </div>
              </div>
            </div>
            <div className="sales-iq-dropdown3"  style={{cursor:"pointer"}}  onClick={() => toggleThreeDotsDropdownNoAction(2)} ref={(el) => threeDotsRefNoAction.current[2] = el}>
              <img
                className="sales-iq-dots-vertical-icon"
                alt=""
                src={dotsvertical}
              />
                                  {activeThreeDotIdNoAction === 2  && <ThreeDotsNoActionComponent  />}
              
            </div>
          </div>
          <div className="sales-iq-rectangle-container">
            <div className="sales-iq-frame-inner"></div>
            <div className="sales-iq-frame-container">
              <div className="sales-iq-heading-container">
                <div className="sales-iq-heading5">Archived</div>
                <div className="sales-iq-number4">{totalCountArchived || 0}</div>
                <div className="sales-iq-badge12">
                  <div className="sales-iq-options-icon">
                    <img
                      className="sales-iq-arrow-up-icon2"
                      alt=""
                      src={arrowup1}
                    />
                  </div>
                  <div className="sales-iq-change-amount">20%</div>
                </div>
              </div>
            </div>
            <div className="sales-iq-dropdown3"  style={{cursor:"pointer"}}  onClick={() => toggleThreeDotsDropdownNoAction(3)} ref={(el) => threeDotsRefNoAction.current[3] = el}>
              <img
                className="sales-iq-dots-vertical-icon"
                alt=""
                src={dotsvertical}
              />
                                  {activeThreeDotIdNoAction === 3  && <ThreeDotsNoActionComponent  />}
              
            </div>
          </div>
          <div className="sales-iq-rectangle-parent2">
            <div className="sales-iq-frame-inner"></div>
            <div className="sales-iq-frame-container">
              <div className="sales-iq-heading-parent1">
                <div className="sales-iq-heading5">Expired</div>
                <div className="sales-iq-number4">{totalCountExpired || 0}</div>
                <div className="sales-iq-badge12">
                  <div className="sales-iq-options-icon">
                    <img
                      className="sales-iq-arrow-up-icon2"
                      alt=""
                      src={arrowup1}
                    />
                  </div>
                  <div className="sales-iq-change-amount">20%</div>
                </div>
              </div>
            </div>
            <div className="sales-iq-dropdown7">
              <img
                className="sales-iq-dots-vertical-icon"
                alt=""
                src={dotsvertical}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="sales-iq-section-wrapper">
        <div className="sales-iq-section1">
          <div className="sales-iq-container1">
            <div className="sales-iq-filters-bar">
              <div className="sales-iq-content17">
                <div className="sales-iq-dropdowns">

                  <DashboardFilterButtonGroup
                    handleFilterClick={handleFilterClick}
                    activePostStatus={activePostStatus}
                  />

                </div>
                <div className="sales-iq-input-dropdown2">
                  {/* <div className="sales-iq-input-with-label2">
                    <div className="sales-iq-label">Search</div>
                    <div className="sales-iq-input2">
                      <div className="sales-iq-content3">
                        <img
                          className="sales-iq-search-lg-icon sales-iq-search-lg-icon-custom"
                          alt=""
                          src={searchlg}
                        />
                        <input type="text" className="sales-iq-search-placeholder" placeholder='Search' />
                      </div>
                    </div>
                  </div> */}

                  <SearchInputListingComponent onSearch={handleSearch} />

                  <div className="sales-iq-buttonsbutton1" onClick={toggleDropdown}>
                    <img
                      className="sales-iq-upload-04-icon"
                      alt=""
                      src={sliders02}
                    />
                    <div className="sales-iq-text-padding6" ref={dropdownRef}>
                      <div className="sales-iq-text65">
                        Sort By {selectedSortOption && `: ${selectedSortOption.label}`}
                      </div>
                      {isDropdownVisible && <SortByFilterComponent onOptionSelect={handleOptionSelect} />}
                    </div>
                    <img
                      className="sales-iq-placeholder-icon"
                      alt=""
                      src={placeholder2}
                    />

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Listing Container */}
      <div className="sales-iq-service-component-listing-container">

        {/* Listing layout start    */}
        {content}

      </div>

      {/* Load more button  */}
      <div className="sales-iq-frame-wrapper44">
        <div className="sales-iq-frame-parent72">
          <div className="sales-iq-buttonsbutton-wrapper1">
            {showLoadMore && (
              <button className='sales-iq-buttonsbutton-wrapper1-child'
                onClick={handleLoadMore}
                disabled={isFetching}
              >
                <div className="sales-iq-buttonsbutton28">
                  <img
                    className="sales-iq-arrow-narrow-left-icon"
                    alt=""
                    src={arrowdown1}
                  />
                  <div className="sales-iq-search-input">
                    <div className="sales-iq-text146">{isFetching ? 'Loading...' : 'Load More'}</div>
                  </div>
                </div>
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  )


}


