import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  serviceCategory:'',
  serviceValueProposition:'',
  clinicalApplications:'',
  purposeUse:'',
  deviceCategory:'',
  physicalLocation:'',
}

const editWantedServiceSlice = createSlice({
  name: "editWantedService",
  initialState,
  reducers: {
    fillEditWantedServiceForm(state, action) {
     return action.payload;
    },
    updateEditWantedServiceField(state, action) {
      const {field, value} = action.payload
      if (value !== undefined) { 
        state[field] = value; 
      }
    },
    clearEditWantedServiceForm() {
      return initialState;
    }
  },
});

export const {fillEditWantedServiceForm, updateEditWantedServiceField,clearEditWantedServiceForm } = editWantedServiceSlice.actions

export default editWantedServiceSlice.reducer

