import React, { useEffect } from 'react'
import { Loader } from '../../components/Loader'
import { useNavigate } from 'react-router-dom';


const EmailUrlActionHandler = () => {
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(window.location.search);
    const mode = queryParams.get('mode'); // 'resetPassword' or 'verifyEmail'
    const oobCode = queryParams.get('oobCode'); // one-time code
    // const apiKey = queryParams.get('apiKey'); // API key

    useEffect(() => {
        if (mode === 'resetPassword') {
          navigate(`/reset-password-new-password?resetToken=${oobCode}`);
        } else if (mode === 'verifyEmail') {
          navigate(`/account-setup-email-verified?validateToken=${oobCode}`);
        }
        // Handle other modes similarly if needed
      }, [mode, oobCode, navigate]);


    return (
        <Loader />
    )
}

export default EmailUrlActionHandler