import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
// import { AddDevice } from "./screens/Device/AddDevice";
// import { EditDevice } from "./screens/Device/EditDevice";
// import { ArchiveDevice } from "./screens/Device/ArchiveDevice";
// import { SinglePostDevice } from "./screens/Device/SinglePostDevice/SinglePostDevice";

// import { AddSpare } from "./screens/Spare/AddSpare";
// import { EditSpare } from "./screens/Spare/EditSpare";
// import { ArchiveSpare } from "./screens/Spare/ArchiveSpare";
// import { SinglePostSpare } from "./screens/Spare/SinglePostSpare";

// import { AddService } from "./screens/Service/AddService/AddService";
// import { EditService } from "./screens/Service/EditService";
// import { ArchiveService } from "./screens/Service/ArchiveService";
// import { SinglePostService } from "./screens/Service/SinglePostService";

// import { AddJob } from "./screens/Job/AddJob";
// import { EditJob } from "./screens/Job/EditJob";
// import { ArchiveJob } from "./screens/Job/ArchiveJob/ArchiveJob";
// import { SinglePostJob } from "./screens/Job/SinglePostJob/SinglePostJob";

// import { AddSoftware } from "./screens/Software/AddSoftware";
// import { EditSoftware } from "./screens/Software/EditSoftware";
// import { ArchiveSoftware } from "./screens/Software/ArchiveSoftware";
// import { SinglePostSoftware } from "./screens/Software/SinglePostSoftware";

// import { Desktop } from "./screens/Desktop";
// // import { Navbar } from "./components/Navbar/Navbar";
// import { ElementListingsScreen } from "./components/ElementListingsScreen";

// import AddDeviceParent from "./components/AddDeviceFormComponent/AddDeviceParent";
// import { TagCloseX } from "./components/TagCloseX";
// import { Checkbox } from "./components/Checkbox";
// import { XClose5 } from "./icons/XClose5";
// import { XClose31 } from "./icons/XClose31";
// import { CheckboxBase } from "./components/CheckboxBase";

// import { AddWantedDevice } from "./screens/WantedDevice/AddWantedDevice/AddWantedDevice";
// import { EditWantedDevice } from "./screens/WantedDevice/EditWantedDevice";
// import { ArchiveWantedDevice } from "./screens/WantedDevice/ArchiveWantedDevice/ArchiveWantedDevice";
// import { SinglePostWantedDevice } from "./screens/WantedDevice/SinglePostWantedDevice";


// import { AddWantedSpare } from "./screens/WantedSpare/AddWantedSpare/AddWantedSpare";
// import { EditWantedSpare } from "./screens/WantedSpare/EditWantedSpare";
// import { ArchiveWantedSpare } from "./screens/WantedSpare/ArchiveWantedSpare";
// import { SinglePostWantedSpare } from "./screens/WantedSpare/SinglePostWantedSpare";

// import { AddWantedService } from "./screens/WantedService/AddWantedService/AddWantedService";
// import { EditWantedService } from "./screens/WantedService/EditWantedService/EditWantedService";
// import { ArchiveWantedService } from "./screens/WantedService/ArchiveWantedService";
// import { SinglePostWantedService } from "./screens/WantedService/SinglePostWantedService/SinglePostWantedService";

// import { AddWantedSoftware } from "./screens/WantedSoftware/AddWantedSoftware/AddWantedSoftware";
// import { EditWantedSoftware } from "./screens/WantedSoftware/EditWantedSoftware";
// import { ArchiveWantedSoftware } from "./screens/WantedSoftware/ArchiveWantedSoftware";
// import { SinglePostWantedSoftware } from "./screens/WantedSoftware/SinglePostWantedSoftware";
// // import { Login } from "./screens/Login/Login";
import { SignUp } from "./screens/Sign_Up/SignUp";
import { SignIn } from "./screens/Sign_In/SignIn";
import { Dashboard } from "./screens/Dashboard/Dashboard";
import AccountSetupEmail from "./screens/AccountSetup/Email/AccountCheckEmail";
import { AccountEmailVerified } from "./screens/AccountSetup/Email/AccountEmailVerified";
import { AccountUpdateProfile } from "./screens/AccountSetup/Profile/AccountUpdateProfile";
import { AccountSubscription } from "./screens/AccountSetup/Plan/AccountSubscription";
import { SendingEmail } from "./screens/ResetPassword/SendingEmail";
import { CheckingResetPasswordEmail } from "./screens/ResetPassword/CheckEmail";
import { NewPassword } from "./screens/ResetPassword/NewPassword";
import { FinishedResetPassword } from "./screens/ResetPassword/FinishedPassword";
import EmailUrlActionHandler from "./handler/FirebaseEmailActionHandler/EmailUrlActionHandler";
import { UserProfile } from "./screens/UserProfile/UserProfile";
import { Router } from "./routes/router";



const router = createBrowserRouter([

  //email action handler
  {
    path: "/action",
    element: <EmailUrlActionHandler />,
  },

  //Device
  {
    path: "/",
    element: <SignUp />,
  },

  //SignUp
  {
    path: "/sign-up",
    element: <SignUp />,
  },

  //SignIn
  {
    path: "/sign-in",
    element: <SignIn />,

  },

  //Dashboard
  {
    path: "/dashboard",
    element: <Dashboard />,
  },

  //UserProfile
  {
    path: "/user-profile",
    element: <UserProfile />,
  },

  {
    path: "/account-setup-check-email",
    element: <AccountSetupEmail />,
  },


  //Account Setup (Email Verified)
  {
    path: "/account-setup-email-verified",
    element: <AccountEmailVerified />,
  },
  //Account Setup (Subscription Plan)
  {
    path: "/account-setup-subscription-plan",
    element: <AccountSubscription />,
  },

  //Account Setup (Update Profile)
  {
    path: "/account-setup-update-profile",
    element: <AccountUpdateProfile />,
  },













  //ResetPassword (Send Email)
  {
    path: "/reset-password-send-email",
    element: <SendingEmail />,

  },

  //ResetPassword (Check Email)
  {
    path: "/reset-password-check-email",
    element: <CheckingResetPasswordEmail />,

  },

  //ResetPassword (New Password)
  {
    path: "/reset-password-new-password",
    element: <NewPassword />,
  },

  //ResetPassword (Finish)
  {
    path: "/reset-password-finished",
    element: <FinishedResetPassword />,

  },









  // {
  //   path: "add-device",
  //   element: <AddDevice />,
  //   // element: <AddDeviceParent/>,
  // },

  // {
  //   path: "/edit-device/:id/*",
  //   element: <EditDevice />,
  // },

  // {
  //   path: "/devices",
  //   element: <ArchiveDevice />,
  // },
  // {
  //   path: "/devices/:id/*",
  //   element: <SinglePostDevice />,
  // },
  // {
  //   path: "/add",
  //   element: <AddDevice />,
  //   // element: <AddDeviceParent/>,
  // },


  //Spares

  // {
  //   path: "/add-spare",
  //   element: <AddSpare />,
  //   // element: <AddSpareParent/>,
  // },
  // {
  //   path: "/edit-spare/:id/*",
  //   element: <EditSpare />,
  // },

  // {
  //   path: "/spares",
  //   element: <ArchiveSpare />,
  // },
  // {
  //   path: "/spare/:id/*",
  //   element: <SinglePostSpare />,
  // },


  //Service

  // {
  //   path: "/add-service",
  //   element: <AddService />,
  // },
  // {
  //   path: "/edit-service/:id/*",
  //   element: <EditService />,
  // },

  // {
  //   path: "/services",
  //   element: <ArchiveService />,
  // },
  // {
  //   path: "/service/:id/*",
  //   element: <SinglePostService />,
  // },

  // //Software
  // {
  //   path: "/add-software",
  //   element: <AddSoftware />,
  // },
  // {
  //   path: "/edit-software/:id/*",
  //   element: <EditSoftware />,
  // },

  // {
  //   path: "/softwares",
  //   element: <ArchiveSoftware />,
  // },
  // {
  //   path: "/software/:id/*",
  //   element: <SinglePostSoftware />,
  // },

  // //Jobs
  // {
  //   path: "/add-job",
  //   element: <AddJob />,
  // },
  // {
  //   path: "/edit-job/:id/*",
  //   element: <EditJob />,
  // },

  // {
  //   path: "/jobs",
  //   element: <ArchiveJob />,
  // },
  // {
  //   path: "/job/:id/*",
  //   element: <SinglePostJob />,
  // },

  // //Wanted Device

  // {
  //   path: "/wanted-devices",
  //   element: <ArchiveWantedDevice />,
  // },
  // {
  //   path: "/add-wanted-device",
  //   element: <AddWantedDevice />,
  // },
  // {
  //   path: "/edit-wanted-device/:id/*",
  //   element: <EditWantedDevice />,
  // },
  // {
  //   path: "wanted-device/:id/*",
  //   element: <SinglePostWantedDevice />,
  // },




  // //Wanted Spares
  // {
  //   path: "/wanted-spares",
  //   element: <ArchiveWantedSpare />,
  // },
  // {
  //   path: "/add-wanted-spare",
  //   element: <AddWantedSpare />,
  // },
  // {
  //   path: "/edit-wanted-spare/:id/*",
  //   element: <EditWantedSpare />,
  // },
  // {
  //   path: "wanted-spare/:id/*",
  //   element: <SinglePostWantedSpare />,
  // },



  // //Wanted Services
  // {
  //   path: "/wanted-services",
  //   element: <ArchiveWantedService />,
  // },
  // {
  //   path: "/add-wanted-service",
  //   element: <AddWantedService />,
  // },
  // {
  //   path: "/edit-wanted-service/:id/*",
  //   element: <EditWantedService />,
  // },
  // {
  //   path: "wanted-service/:id/*",
  //   element: <SinglePostWantedService />,
  // },


  // //Wanted Sofwares
  // {
  //   path: "/wanted-softwares",
  //   element: <ArchiveWantedSoftware />,
  // },
  // {
  //   path: "/add-wanted-software",
  //   element: <AddWantedSoftware />,
  // },
  // {
  //   path: "/edit-wanted-software/:id/*",
  //   element: <EditWantedSoftware />,
  // },
  // {
  //   path: "wanted-software/:id/*",
  //   element: <SinglePostWantedSoftware />,
  // },
]);
export const App = () => {
  console.log(process.env.REACT_APP_API_URL);

  return <RouterProvider router={Router} />;
};
