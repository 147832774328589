import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import Pusher from "pusher-js";
import { MessageBox } from "./MessageBox";

import { SidePanel } from "./SidePanel";

import { NoMessages } from "./NoMessages";
import { useLazyGetUserProfileQuery ,useLazyGetSenderProfileQuery,useLazyGetContactedProfilesQuery} from "../../../api/UsermetaAPI";
import { updateChatCache,deduplicateMessages } from "../../../helper/ChatHelper";
import {
  useSaveMetadataMutation,
  useSaveConversationMutation,
  useSendMessageMutation,
  useLazyCheckChatMetadataQuery,
  useSaveEachMessageMutation,
  useLazyFetchLatestDetailedSenderProfilesQuery,
  useLazyFetchInitialMessagesForDisplayQuery,
  useLazyFetchInitialMessagesForDisplayQuery,
  useLazyFetchMessagesQuery,
} from "../../../api/ChatAPI";
import { useLazyGetPostTypesQuery } from "../../../api/TaxonomyFormAPI";
import { ChatAPI } from "../../../api/ChatAPI"; // Import your ChatAPI instance


const ChatPanel = ({ currentUser, deviceData }) => {


  const { listing ,id, entity } = useParams();
  const pusherInstance = useRef(null); // Persist Pusher instance
  const [triggerGetUserProfile, { data: decryptedData, error, isLoading }] = useLazyGetUserProfileQuery();
  const [sendMessage] = useSendMessageMutation();  //send message to save the data
  const [saveMetadata] = useSaveMetadataMutation(); //Save metadata id and details
  const [saveConversation] = useSaveConversationMutation(); //save conversation details
  const [messages, setMessages] = useState([]); //messages state 
  const [postTypeMap, setPostTypeMap] = useState(new Map()); // to hold post type map 
  const [triggerCheckChatMetadata] = useLazyCheckChatMetadataQuery();
  const [saveEachMessage] = useSaveEachMessageMutation();
  const [metadataId, setMetadataId] = useState(null);
  const [page, setPage] = useState(1); // Pagination state
 const [selectedChat, setSelectedChat] = useState({messageId: null,content: "",timestamp: null,senderId: null,recipientId: null,channelName: "",});
  const [triggerFetchLatestDetailedSenderProfiles, { data: LatestSenderProfiles , isLoading:isLatestSenderProfilesLoading, isError : isLatestSenderProfilesError}] = useLazyFetchLatestDetailedSenderProfilesQuery();
  const [triggerFetchInitialMessagesForDisplay, { data: initialMessagesForDisplay , isLoading:isInitialMessagesForDisplayLoading, isError : isInitialMessagesForDisplayError}] = useLazyFetchInitialMessagesForDisplayQuery();
  const [triggerFetchPostTypes, { data: postTypeQueryData }] = useLazyGetPostTypesQuery();
  const [triggerFetchMessages] = useLazyFetchMessagesQuery();
  const [sidePanelProfiles, setSidePanelProfiles] = useState([]);
  //const isInitialMessagesFetched = useRef(false);
  const [showCardHeader, setShowCardHeader] = useState(true);


  useEffect(() => {
    if (currentUser) {
      triggerGetUserProfile(); //
      triggerFetchPostTypes();
    }
  }, [currentUser, triggerGetUserProfile, triggerFetchPostTypes]);

    useEffect(() => { //FETCHING ALL THE DATA OF "SENDERS"
      if ( currentUser && decryptedData ) {
    const recipientId = decryptedData.user.id;
    console.log("HERE REC: ",recipientId)
    //FETCHING NEW ENDPOINT TO GET ALL
    triggerFetchLatestDetailedSenderProfiles({ recipient_id: recipientId, listing_type: listing})
      .unwrap()
      .then((response) => {
        if (response?.users?.length > 0) {
          // Show the card header if there are senders
          setShowCardHeader(true);
        } else {
          // Hide the card header if no senders are found
          setShowCardHeader(false);
        }
      })
      .catch((error) => {
        console.error("Error fetching sender profiles:", error);
        setShowCardHeader(false); // Optionally hide the header if there's an error
      });
      }
    }, [currentUser, decryptedData,triggerFetchLatestDetailedSenderProfiles]);

    useEffect(() => {
      if (LatestSenderProfiles?.users && currentUser && decryptedData) {
        
        setSidePanelProfiles(LatestSenderProfiles.users); // Initialize the side panel data NEW
      }
    }, [LatestSenderProfiles,decryptedData,currentUser]);
    

    useEffect(() => { //printing and checking
      if ( currentUser && decryptedData && LatestSenderProfiles ) {
    console.log("HIHIHIHIHIHI IN SIDE LatestSenderProfiles:", LatestSenderProfiles);
      }
    }, [currentUser, decryptedData,LatestSenderProfiles]);
    

    //SETTING SELECTED CHAT
    useEffect(() => {
      if (currentUser && LatestSenderProfiles?.users) {

        console.log("Latest message and sender on the side panel [0]: " ,LatestSenderProfiles.users[0])
        const latestChat = LatestSenderProfiles.users[0]; // Assuming sidePanelData is sorted by timestamp (latest first)
        setSelectedChat(latestChat);
        console.log("LATEST CHAT: ", latestChat);

      }
    }, [currentUser, LatestSenderProfiles]);

    // ENF OF SIDEPANEL

  useEffect(() => {
    if (postTypeQueryData) {
      const map = new Map(postTypeQueryData.map((item) => [item.name, item.id]));
      setPostTypeMap(map);
    }
  }, [postTypeQueryData]);

  useEffect(() => {
    // Initialize Pusher once
    if (!pusherInstance.current) {
      pusherInstance.current = new Pusher("032ab85ab72373f5f617", {
        cluster: "us3",
        authEndpoint: `${process.env.REACT_APP_API_URL}/pusher/auth`,
      });
      console.log("Pusher instance initialized:", pusherInstance.current);
    }
  
    // Subscribe to the global channel
    const globalChannel = pusherInstance.current.subscribe("global-channel");
  
    globalChannel.bind("new-message", (newMessage) => {
      console.log("New message received in global channel:", newMessage);
  
      const { channelName, content, timestamp, profileImageLink, displayName, sender } = newMessage;
  
      if (!channelName) {
        console.error("Missing channelName in newMessage:", newMessage);
        return;
      }
  
      // Ignore messages sent by the current user
      if (sender === decryptedData.user.id) {
        console.log("Message sent by me, ignoring.");
        return;
      }
  
      // Update SidePanel profiles
      setSidePanelProfiles((prevProfiles) => {
        const updatedProfiles = [...prevProfiles];
        const existingProfileIndex = updatedProfiles.findIndex(
          (profile) => profile.channelName === channelName
        );
  
        if (existingProfileIndex >= 0) {
          updatedProfiles[existingProfileIndex] = {
            ...updatedProfiles[existingProfileIndex],
            content,
            timestamp,
            senderDetails: {
              display_name: displayName || updatedProfiles[existingProfileIndex].senderDetails?.display_name || "Unknown User",
              profileImageLink: profileImageLink || updatedProfiles[existingProfileIndex].senderDetails?.profileImageLink || avatar2,
            },
          };
        } else {
          updatedProfiles.push({
            channelName,
            content,
            timestamp,
            senderDetails: {
              display_name: displayName || "Unknown User",
              profileImageLink: profileImageLink || avatar2,
            },
            hasUnreadMessage: true,
          });
        }
  console.log("CHAT PANLE OWNER UPDATED PROFILE",updatedProfiles)
  // Sort profiles by timestamp
const sortedProfiles = updatedProfiles.sort((a, b) => {
  // Convert timestamp strings to Date objects and compare them
  return new Date(b.timestamp) - new Date(a.timestamp);
});

  console.log("CHAT PANLE OWNER SORTED UPDATED PROFILE",sortedProfiles)
        return sortedProfiles;
      });
  
      // Update the currently open chat's MessageBox
      if (channelName === selectedChat?.channelName) {
        setMessages((prevMessages) => [...prevMessages, newMessage]);
      }
    });
  
    // Cleanup subscription on unmount
    return () => {
      globalChannel.unbind_all();
      globalChannel.unsubscribe();
    };
  }, [selectedChat, decryptedData]);
    
  const handleLoadMore = async () => { //NEW DONE
    try {
      const response = await triggerFetchMessages({ channel_name: selectedChat.channelName, page }).unwrap();
      if (response?.messages) {
        setMessages((prevMessages) => [...response.messages, ...prevMessages]);
        setPage((prevPage) => prevPage + 1); // Increment page for the next fetch
      }
    } catch (error) {
      console.error("Error fetching historical messages:", error);
    }
  };
  
  // Helper function to save metadata and conversation
  const handleSaveMetadataAndConversation = async (
    channel_name,
    userId,
    recipientId,
    subject,
    postId
  ) => {
    try {
      const metadataResponse = await saveMetadata({
        channel_name,
        user_id: userId,
        recipient_id: recipientId,
        post_id: postId,
        listing_type:listing,
      }).unwrap();

      const conversationResponse = await saveConversation({
        metadata_id: metadataResponse.metadata_id,
        subject,
      }).unwrap();

      return { metadataResponse, conversationResponse };
    } catch (error) {
      console.error("Error saving metadata or conversation:", error);
      throw error;
    }
  };

  // Helper function to check metadata and optionally create it
  const subscribeWithMetadataCheck = async (
    channel_name,
    userId,
    recipientId,
    postId,
    subject
  ) => {
    try {
      console.log("yi:");
      const response = await triggerCheckChatMetadata({ channel_name }).unwrap();
      console.log("RESPONSE:",response);

      if (response?.status) {
        console.log("yes");
        return response.metadata_id;
      } else {
        console.log("NO");
        const result = await handleSaveMetadataAndConversation(
          channel_name,
          userId,
          recipientId,
          subject,
          postId
        );

        if (result?.metadataResponse) {
          console.log("UMMMMMM");
          return result.metadataResponse.metadata_id;
        } else {
          throw new Error("Failed to create metadata");
        }
      }
    } catch (error) {
      console.error("Error checking or saving metadata:", error);
      throw error;
    }
  };

  // Step 2: Initialize chat
  useEffect(() => {
    const initializeChat = async () => {
      if (
        !currentUser ||
        !decryptedData?.user ||
        !deviceData?.data?.device_user?.id ||
        !postTypeMap ||
        !LatestSenderProfiles ||
        !selectedChat||
        !selectedChat?.senderId
      ) {
        return;
      }
console.log("CUR: ",currentUser);
      const userId = decryptedData.user.id;
      const recipientId = selectedChat.senderId; //sender ID of the selected chat will be the reciver of the current user
      const postId = postTypeMap.get(entity);

      console.log("user and rec id:", userId, recipientId);
      const channel_name = `private-chat-${listing}-${postId}-${Math.min(
        userId,
        recipientId
      )}-${Math.max(userId, recipientId)}`;

      try {
        const metadata_id = await subscribeWithMetadataCheck(
          channel_name,
          userId,
          recipientId,
          postId,
          entity
        );
        setMetadataId(metadata_id);

      console.log("1st metaid:", metadataId);
      } catch (error) {
        console.error("Error initializing chat:", error);
      }
    };

    initializeChat();
  }, [currentUser, decryptedData, deviceData, postTypeMap,LatestSenderProfiles, selectedChat]);

  //FETCH THE INITIAL MESSAGES FOR DISPLAY BTU NOT WORKING
  useEffect(() => {  //NEW DONE
    const initializeChat = async () => {
      if (!currentUser || !decryptedData?.user || !selectedChat?.channelName ){//|| isInitialMessagesFetched.current) {
        return;
      }
        // Reset pagination
  setPage(1);

  // Clear messages when switching chats
  setMessages([]);
      try {
        const response = await triggerFetchInitialMessagesForDisplay(selectedChat.channelName).unwrap();
      //  isInitialMessagesFetched.current = true;
        console.log("PRINTING DISPLAY MESSAGES IN CHAT PANEL: ", response);
        if (response?.messages) {
          setMessages(response.messages); // Populate messages with historical data
        }
      } catch (error) {
        console.error("Error fetching initial messages:", error);
      }
    };
  
    initializeChat();
  }, [currentUser, decryptedData, selectedChat?.channelName]);

const handleSendMessage = (messageText) => {
  if (!messageText.trim()) return;

  const userId = decryptedData?.user?.id;
  const profileImageLink = decryptedData?.user?.profileImageLink;
  const displayName = currentUser?.displayName;
  const recipientId = selectedChat?.senderId;
  const postId = postTypeMap?.get(entity);

  const channel_name = `private-chat-${listing}-${postId}-${Math.min(
    userId,
    recipientId
  )}-${Math.max(userId, recipientId)}`;

  console.log("Printing channel name and user ID:", channel_name, userId);

  const time = Date.now();

  // Construct the new message object
  const newMessage = {
    sender: userId,
    timestamp: time,
    content: messageText,
    real_time: true, // To differentiate between real-time and historical messages
  };

  console.log("New message in panel:", newMessage);

  // Update the chat messages state for the MessageBox
  setMessages((prevMessages) => [...prevMessages, newMessage]);

//  Update the sidePanelProfiles state

  
  setSidePanelProfiles((prevProfiles) => {
    // Remove the existing profile for the current chat
    const updatedProfiles = prevProfiles.filter(
      (profile) => profile.channelName !== channel_name
    );

    // Add the chat back at the top
    return [
      {
        ...selectedChat,
        content: messageText,
        timestamp: time,
      },
      ...updatedProfiles,
    ];
  });

  const formattedTime = new Date(time).toISOString();
  // Send the message to the backend via API
  sendMessage({
    channel: channel_name,
    message: messageText,
    sender: userId,
    timestamp: formattedTime,
    profileImageLink:profileImageLink,
    displayName:displayName,
  })
    .unwrap()
    .then(() => console.log("Message sent"))
    .catch((err) => console.error("Error sending message:", err));

  // Save the message in the database
  saveEachMessage({
    message_metadata_id: metadataId,
    message: messageText,
    message_type: "text",
    message_status: true,
    sender_id: userId, // Sender ID
  }).unwrap();
};


  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error loading data</div>;

  return (
    <form className="chatB-chat-panel" onSubmit={(e) => e.preventDefault()}>
      <div className="chatB-chat-content">
          {/* CHAT SIDE PANEL */}
        { currentUser && <SidePanel
  LatestSenderProfiles={sidePanelProfiles}
  onSelectChat={(message) => setSelectedChat(message)} 
/>}
        {messages.length > 0 && currentUser && decryptedData?.user?.user_usermeta && selectedChat ? (
          <MessageBox
            messages={messages}
           setMessages={setMessages}
            currentUser={decryptedData.user.user_usermeta}
            onSendMessage={handleSendMessage}
            deviceData={deviceData}
            onLoadMore={handleLoadMore} // Pass load more functionality
            selectedChat={selectedChat}
            showCardHeader = {showCardHeader}
          />
        ) : (
          <NoMessages />
        )}
      </div>
    </form>
  );
};

export { ChatPanel };