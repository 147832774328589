import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    deviceCategory:'',
    oem:'',
    modelName:'',
    statusCondition:'',
    ageOfDevice:'',
    availability:'',
    clinicalApplications:'',
    purposeUse:'',
    physicalLocation:'',
}

const AddWantedDeviceSlice = createSlice({
  name: "addWantedDevice",
  initialState,
  reducers: {
    updateWantedDeviceField(state, action) {
      const {field, value} = action.payload
      if (value !== undefined) { 
        state[field] = value; 
      }
    },
    clearWantedDeviceForm() {
      return initialState;
    }
  },
});

export const { updateWantedDeviceField,clearWantedDeviceForm } = AddWantedDeviceSlice.actions

export default AddWantedDeviceSlice.reducer

