// import { useEffect, useState } from "react";
// import { useGetAvailabilityTaxQuery, useGetClinicalApplicationsTaxQuery, useGetPhysicalLocationTaxQuery, useGetPurposeUseTaxQuery, useGetShippingTaxQuery, useGetStatusConditionTaxQuery, useGetTransactionTypeTaxQuery, useGetUnitOfMeasureDeviceSpareTaxQuery, useGetWarrantyTaxQuery, useGetYearOfManufactureTaxQuery, useGetYourRoleTaxQuery } from "../api/TaxonomyFormAPI";

import { encryptData } from "./AESHelper";

export function objectToFormDataAddJob(obj, document) {
  // Create a new FormData object
  const formData = new FormData();
  // Clone the object without the document field
  const { document: _, ...values } = obj;

  // Encrypt the object without the document
  const encryptedValue = encryptData(values); // Custom encryptData function

  // Append the encrypted text data
  formData.append("encryptedData", encryptedValue);

  // Check if the document is a valid File and append it to the FormData
  if (document && document.type === 'application/pdf') {
    formData.append("documentFileObject", document);
  }

  return formData;
}



export function objectToFormDataEditJob(obj, documentFileRM, document) {
  // Create a new FormData object
  const formData = new FormData();

  // Clone the object without the document field
  const { document: _, documentFile, document_file, imageDocCheckbox, ...values } = obj;

  // Append field if document was removed from form
  if (documentFileRM !== "" && documentFileRM !== undefined) {
    values.documentFileRM = documentFileRM; // Add documentFileRM to values
  }

  console.log(values);

  // Encrypt the object without the document
  const encryptedValue = encryptData(values); // Custom encryptData function

  // Append the encrypted text data to formData
  formData.append("encryptedData", encryptedValue);

  // Appending documentFile to formData, checking if user has uploaded document or not
  if (document && document.type === 'application/pdf') {
    formData.append("documentFileObject", document);
  }

  return formData; // Return the correct FormData object
}




export function getCustomizedDate(sqlDate) {
  // const date = ; 
  const formattedDate = new Date(sqlDate).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });

  return formattedDate;
}

export function getAllTaxonomyData() {


  return allTaxonomy;
}


export function isValidURL(urlString) {
  try {
    new URL(urlString);
    return true;
  } catch (_) {
    return false;
  }
}

export function isAllURLsValid(urlArray) {
  for (const url of urlArray) {
    if (!isValidURL(url)) {
      return false; // If even one URL is invalid, return false
    }
  }
  return true; // All URLs were valid
}