import React, { useState } from 'react';
import './ContactFilterButtonGroup.css'; // Import your CSS file

const ContactFilterButtonGroup = ({ handleFilterClick, activePostStatus }) => {
    const getTextClass = (filter) => {
        switch (filter) {
            case 'Viewed': return '31';
            case 'Added to Radar': return '32';
            case 'Enquired': return '29';
            
            default: return '';
        }
    };

    return (
        <div className="contact-button-group custom-cursor">
            {['Viewed', 'Added to Radar', 'Enquired'].map(filter => (
                <div
                    key={filter}
                    className={`contact-button-group-base1 ${activePostStatus === filter ? 'active' : ''}`}
                    onClick={() => handleFilterClick(filter)}
                >
                    <div className={`contact-text${getTextClass(filter)}`}>
                        {filter}
                    </div>
                </div>
            ))}
        </div>
    );
}

export default ContactFilterButtonGroup;
// <div className="contact-button-group custom-cursor">
//     <div
//         className={`${activeButton === '12 months' ? 'contact-button-group-base1 active' : 'contact-button-group-base1'
//             }`}
//         onClick={() => handleButtonClick('12 months')}
//     >
//         <div className="contact-text31">12 months</div>
//     </div>

//     <div
//         className={`${activeButton === '30 days' ? 'contact-button-group-base1 active' : 'contact-button-group-base1'
//             }`}
//         onClick={() => handleButtonClick('30 days')}
//     >
//         <div className="contact-text32">30 days</div>
//     </div>

//     <div
//         className={`${activeButton === '7 days' ? 'contact-button-group-base1 active' : 'contact-button-group-base1'
//             }`}
//         onClick={() => handleButtonClick('7 days')}
//     >
//         <div className="contact-text29">7 days</div>
//     </div>

//     <div
//         className={`${activeButton === '24 hours' ? 'contact-button-group-base1 active' : 'contact-button-group-base1'
//             }`}
//         onClick={() => handleButtonClick('24 hours')}
//     >
//         <div className="contact-text39">24 hours</div>
//     </div>
// </div>