import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  deviceCategory:'',
  oem:'',
  partName:'',
  ageOfDevice:'',
  availability:'',
  partNumber:'',
  clinicalApplications:'',
  purposeUse:'',
  statusCondition:'',
  physicalLocation:'',
}

const editWantedSpareSlice = createSlice({
  name: "editWantedSpare",
  initialState,
  reducers: {
    fillEditWantedSpareForm(state, action) {
     return action.payload;
    },
    updateEditWantedSpareField(state, action) {
      const {field, value} = action.payload
      if (value !== undefined) { 
        state[field] = value; 
      }
    },
    clearEditWantedSpareForm() {
      return initialState;
    }
  },
});

export const {fillEditWantedSpareForm, updateEditWantedSpareField,clearEditWantedSpareForm } = editWantedSpareSlice.actions

export default editWantedSpareSlice.reducer

