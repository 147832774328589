import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    jobTitle:'',
    overview:'',
    deliverables:'',
    engagementType:'',
    experienceLevel:'',
    occupationCategory:'',
    serviceCategory:'',
    budget:'',
    unitOfMeasureService:'',
    deviceCategory:'',
    clinicalApplications:'',
    purposeUse:'',
    physicalLocation:'',
    location:'',
    videoType:'',
    linkVideo:'',
    document:'',
    // documentFileObject:{}
}

const addJobSlice = createSlice({
  name: "addJob",
  initialState,
  reducers: {
    updateField(state, action) {
      const {field, value} = action.payload
      if (value !== undefined) { 
        state[field] = value; 
      }
    },
    clearForm() {
      return initialState;
    }
  },
});

export const { updateField,clearForm } = addJobSlice.actions

export default addJobSlice.reducer


