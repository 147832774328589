import React from 'react';
import "./NoAddressNew.css";
import bifocallens from '../../icons/Gerator/no_listing/bifocallens.png';
import placeholder from '../../icons/Gerator/no_listing/placeholder.svg';
import placeholder1 from '../../icons/Gerator/no_listing/placeholder1.svg';
import plus from '../../icons/Gerator/no_listing/plus.svg';
import { Link } from "react-router-dom";
import NoAddress from "../../icons/Gerator/no_address/NoAddress.png";

export const NoAddressFoundNew = ({ path, pathName }) => {
  return (
    <div className="no-address-found-new-no-address-found-new">
      <section className="no-address-found-new-empty-state">
        <div className="no-address-found-new-empty-state1">
          <div className="no-address-found-new-content">
            <img
              className="no-address-found-new-bifocal-optometry-measurement-icon"
              loading="lazy"
              alt=""
              src={NoAddress}
            />

            <div className="no-address-found-new-text-and-supporting-text">
              <div className="no-address-found-new-title">No address yet!</div>
              <div className="no-address-found-new-supporting-text">
              Addresses come handy when you add listings. Geolocation, transparency, and information accuracy are key features here.
              </div>
            </div>
          </div>
          <div className="no-address-found-new-actions">

            
            <button className="no-address-found-new-buttonsbutton1"  >
              <Link to={path} className='no-address-found-new-button-link'>
                <img className="no-address-found-new-plus-icon" alt="" src={plus} />

                <div className="no-address-found-new-text-padding">
                  <div className="no-address-found-new-text1">Add {pathName}</div>
                </div>
                <img
                  className="no-address-found-new-placeholder-icon"
                  alt=""
                  src={placeholder1}
                />
              </Link>
            </button>
          </div>
        </div>
      </section>
    </div>
  )
}
