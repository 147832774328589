import React from 'react';
import "./PostStatusLIstingComponent.css";
import divider from '../../icons/Gerator/dashboard/divider.svg';

export const PostStatusLIstingComponent = ({ postStatus, postSlug, postId }) => {

    switch (postSlug) {
        case 'published':
            return <>
                <div className={`post-status-component-badge-parent-${postSlug}`}>
                    <div className={`post-status-component-badge-${postSlug}`}>{postStatus}</div>
                    <div className={`post-status-component-id-text-${postSlug}`}>ID# {postId}</div>
                </div>
            </>
        case 'pending':
            return <div className={`post-status-component-badge-parent-${postSlug}`}>
                <div className={`post-status-component-badge-${postSlug}`}>{postStatus}</div>
                <div className={`post-status-component-id-text-${postSlug}`}>ID# {postId}</div>
            </div>
        case 'archived':
            return <div className={`post-status-component-badge-parent-${postSlug}`}>
                <div className={`post-status-component-badge-${postSlug}`}>{postStatus}</div>
                <div className={`post-status-component-id-text-${postSlug}`}>ID# {postId}</div>
            </div>
        case 'expired':
            return <div className={`post-status-component-badge-parent-${postSlug}`}>
                <div className={`post-status-component-badge-${postSlug}`}>{postStatus}</div>
                <div className={`post-status-component-id-text-${postSlug}`}>ID# {postId}</div>
            </div>
        default:
            return <div className={`post-status-component-badge-parent-expired`}>
                <div className={`post-status-component-badge-expired`}>Not Found</div>
                <div className={`post-status-component-id-text-expired`}>ID# 0</div>
            </div>
    }

}

