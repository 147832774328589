import React from 'react';
// import "./AddBusinessForm.css"
import React, { useContext, useRef, useState, useEffect } from "react";
import { Formik, Form, useField, useFormikContext, Field } from "formik";
import { useNavigate } from "react-router-dom";
import { decryptData, decryptErrorData } from '../../helper/AESHelper';
import * as Yup from "yup";
import { useEffect } from 'react';
import { useAddBusinessMutation } from '../../api/BusinessAPI';
import AddBusinessFormContext from '../../screens/Business/AddBusiness/AddBusinessContext';
import { useAddBusinessMutation } from '../../api/BusinessAPI';
import { objectToBusinessFormData } from '../../helper/BusinessHelper';
import divider2 from '../../icons/Gerator/profile_settings/divider2.svg';

import wysiwyg_editor_icon from '../../icons/Gerator/profile_settings/-wysiwyg-editor-icon.svg';
import wysiwyg_editor_icon_1 from '../../icons/Gerator/profile_settings/-wysiwyg-editor-icon-1.svg';
import wysiwyg_editor_icon1 from '../../icons/Gerator/profile_settings/-wysiwyg-editor-icon1.svg';

import wysiwyg_editor_icon2 from '../../icons/Gerator/profile_settings/-wysiwyg-editor-icon2.svg';
import wysiwyg_editor_icon_2 from '../../icons/Gerator/profile_settings/-wysiwyg-editor-icon-2.svg';

import wysiwyg_editor_icon_3 from '../../icons/Gerator/profile_settings/-wysiwyg-editor-icon-3.svg';
import wysiwyg_editor_icon_4 from '../../icons/Gerator/profile_settings/-wysiwyg-editor-icon-4.svg';
import page from '../../icons/Gerator/add_form/page.svg';
import featured_icon from '../../icons/Gerator/profile_settings/featured-icon.svg';
import divider from '../../icons/Gerator/profile_settings/divider.svg';
import divider from '../../icons/Gerator/profile_settings/divider.svg';
import placeholder1 from '../../icons/Gerator/profile_settings/placeholder1.svg';
import placeholder3 from '../../icons/Gerator/profile_settings/placeholder3.svg';
import chevrondown from '../../icons/Gerator/profile_settings/chevrondown.svg';

import VerificationBusinessContext from '../../screens/Business/VerificationBusiness/VerificationBusinessContext';

const DocumentFileUpload = ({ validate, label, ...props }) => {
  const [field, meta] = useField(props);
  let fieldValidationData = "";
  if (validate?.length > 0) {
    validate.some(field => { // Use 'some' for early termination
      if (field.path === "documentFileObject") {
        fieldValidationData = field;
        return true; // This will stop the loop early
      }
      return false; // Continue if no match
    });
  }

  return (

    <div className='document-field-component-width'>
      <div className="add-service-step3-label58 add-service-step3-label58-custom-padding">
        {label} <span className="business-verification-span">*</span>
      </div>
      {/* <div className="add-service-step3-label58"></div> */}
      <div className="add-service-step3-file-upload-base5">
        <label htmlFor={props.id}>

          <div className="add-service-step3-content69">
            <img

              className="add-service-step3-featured-icon5"
              loading="lazy"
              alt=""
              src={featured_icon}
            />

            <div className="add-service-step3-text-and-supporting-text13" htmlFor="document">
              <div className="add-service-step3-action5">
                <div className="add-service-step3-buttonsbutton16">
                  {/* <img 
                  className="add-service-step3-placeholder-icon60"
                  alt=""
                  src={placeholder1}
                /> */}
                  <div className="add-service-step3-text88 documentLabel">Click to upload</div>
                  <input type="file" accept=".pdf" {...field} {...props} className='hide-input-field' />
                </div>
              </div>
              <div className="action custom-action">

                <div className="add-service-step3-text89">or drag and drop</div>
              </div>
              <div className="add-service-step3-supporting-text28">
                <span>Please upload files having extension </span>
                <span className="add-service-step3-pdf1">PDF</span>
                <span> only (Maximum file size.10 MB)</span>
              </div>
            </div>
          </div>
        </label>
        {(meta.touched && meta.error) ? (
          <div className="error-for-all-field error-for-document">{meta.error}</div>
        ) : null}

        {fieldValidationData !== "" ? (
          <div className="error-for-all-field error-for-document">{meta.error}</div>
        ) : null}
      </div>


    </div>
  );
};


export const AddBusinessVerificationForm = () => {


  const { selectedDocumentFile1, setSelectedDocumentFile1, documentCheckboxError1, setDocumentCheckboxError1, selectedDocumentFile2, setSelectedDocumentFile2, documentCheckboxError2, setDocumentCheckboxError2, selectedDocumentFile3, setSelectedDocumentFile3, documentCheckboxError3, setDocumentCheckboxError3, backendValidation, setBackendValidation } = useContext(VerificationBusinessContext)

  const { values, validateForm, setFieldValue, setFieldError, setErrors, setFieldTouched, validateField, errors, isValid, dirty, touched, handleSubmit, isSubmitting } = useFormikContext();



  /* 
    This useEffect will provide updated values from formik and run some conditions Used on FeatureImage
*/
  useEffect(() => {
    console.log("Values useEffect Open");

    if (touched.businessEntity) {
      if (errors.businessEntity && values.businessEntity !== "") {
        console.log("Values Error UseEffect");
        handleSelectedDocumentFile1("error");
        return; // Exit useEffect to avoid further actions if there are errors
      }

      // 2. Proceed with Updates if No Errors:
      if (touched.businessEntity && values.businessEntity != "") {
        console.log("Values Touch UseEffect");
        // setGalleryImageFile([...values.gallery]);
        handleSelectedDocumentFile1("no-error");
      }
    }

    if (touched.businessProofOfIdentity) {
      if (errors.businessProofOfIdentity && values.businessProofOfIdentity !== "") {
        console.log("Values Error UseEffect");
        handleSelectedDocumentFile2("error");
        return; // Exit useEffect to avoid further actions if there are errors
      }

      // 2. Proceed with Updates if No Errors:
      if (touched.businessProofOfIdentity && values.businessProofOfIdentity != "") {
        console.log("Values Touch UseEffect");
        // setGalleryImageFile([...values.gallery]);
        handleSelectedDocumentFile2("no-error");
      }
    }

    if (touched.businessProofOfAddress) {
      if (errors.businessProofOfAddress && values.businessProofOfAddress !== "") {
        console.log("Values Error UseEffect");
        handleSelectedDocumentFile3("error");
        return; // Exit useEffect to avoid further actions if there are errors
      }

      // 2. Proceed with Updates if No Errors:
      if (touched.businessProofOfAddress && values.businessProofOfAddress != "") {
        console.log("Values Touch UseEffect");
        // setGalleryImageFile([...values.gallery]);
        handleSelectedDocumentFile3("no-error");
      }
    }
    // 1. Check for Errors FIRST:

    console.log("Values useEffect Closed ");
    // }

  }, [values.businessEntity, errors.businessEntity, values.businessProofOfIdentity, errors.businessProofOfIdentity, values.businessProofOfAddress, errors.businessProofOfAddress]);



  const handleCheckbox1 = async () => {

    //If errors.document has some errors then filter then set document to "", clear other field as well
    if (errors.businessEntity) {
      console.log(errors.businessEntity);
      const filteredErrors = Object.keys(errors).reduce((acc, fieldName) => {
        // Filter based on your condition (replace with your actual logic)
        if (fieldName !== 'businessEntity') {
          acc[fieldName] = errors[fieldName];
        }
        return acc;
      }, {});
      console.log(filteredErrors);
      await setFieldValue("businessEntity", '');
      setErrors(filteredErrors)
      setSelectedDocumentFile1("")
      setDocumentCheckboxError1('');
    } else {
      await setFieldValue("businessEntity", '');
      setSelectedDocumentFile1("")
      setDocumentCheckboxError1('');
    }


  }
  const handleCheckbox2 = async () => {

    //If errors.document has some errors then filter then set document to "", clear other field as well
    if (errors.businessProofOfIdentity) {
      console.log(errors.businessProofOfIdentity);
      const filteredErrors = Object.keys(errors).reduce((acc, fieldName) => {
        // Filter based on your condition (replace with your actual logic)
        if (fieldName !== 'businessProofOfIdentity') {
          acc[fieldName] = errors[fieldName];
        }
        return acc;
      }, {});
      console.log(filteredErrors);
      await setFieldValue("businessProofOfIdentity", '');
      setErrors(filteredErrors)
      setSelectedDocumentFile2("")
      setDocumentCheckboxError2('');
    } else {
      await setFieldValue("businessProofOfIdentity", '');
      setSelectedDocumentFile2("")
      setDocumentCheckboxError2('');
    }


  }

  const handleCheckbox3 = async () => {

    //If errors.document has some errors then filter then set document to "", clear other field as well
    if (errors.businessProofOfAddress) {
      console.log(errors.businessProofOfAddress);
      const filteredErrors = Object.keys(errors).reduce((acc, fieldName) => {
        // Filter based on your condition (replace with your actual logic)
        if (fieldName !== 'businessProofOfAddress') {
          acc[fieldName] = errors[fieldName];
        }
        return acc;
      }, {});
      console.log(filteredErrors);
      await setFieldValue("businessProofOfAddress", '');
      setErrors(filteredErrors)
      setSelectedDocumentFile3("")
      setDocumentCheckboxError3('');
    } else {
      await setFieldValue("businessProofOfAddress", '');
      setSelectedDocumentFile3("")
      setDocumentCheckboxError3('');
    }


  }

  const handleSelectedDocumentFile1 = (type) => {
    console.log("HandleSelectedImageFile Called " + type);

    setFieldTouched("businessEntity", true); // Always touch the field for validation

    console.log(errors);

    // 3. Additional Error Check:
    if (errors.businessEntity) {
      console.log("Inside errors.gallery is true means error found ");
      setSelectedDocumentFile1(values.businessEntity);
      setDocumentCheckboxError1(errors.businessEntity);
      return; // Don't proceed with upload if there's an error
    }

    // 4. Proceed with Upload if No Errors:
    console.log("Inside !errors.gallery is true means no error found");
    console.log("File added into select multi image hook");
    setSelectedDocumentFile1(values.businessEntity);
    setDocumentCheckboxError1('');
    // setFileDocLinkRemover(true);
  }

  const handleSelectedDocumentFile2 = (type) => {
    console.log("HandleSelectedImageFile Called " + type);

    setFieldTouched("businessProofOfIdentity", true); // Always touch the field for validation

    console.log(errors);

    // 3. Additional Error Check:
    if (errors.businessProofOfIdentity) {
      console.log("Inside errors.gallery is true means error found ");
      setSelectedDocumentFile2(values.businessProofOfIdentity);
      setDocumentCheckboxError2(errors.businessProofOfIdentity);
      return; // Don't proceed with upload if there's an error
    }

    // 4. Proceed with Upload if No Errors:
    console.log("Inside !errors.gallery is true means no error found");
    console.log("File added into select multi image hook");
    setSelectedDocumentFile2(values.businessProofOfIdentity);
    setDocumentCheckboxError2('');
    // setFileDocLinkRemover(true);
  }

  const handleSelectedDocumentFile3 = (type) => {
    console.log("HandleSelectedImageFile Called " + type);

    setFieldTouched("businessProofOfAddress", true); // Always touch the field for validation

    console.log(errors);

    // 3. Additional Error Check:
    if (errors.businessProofOfAddress) {
      console.log("Inside errors.gallery is true means error found ");
      setSelectedDocumentFile3(values.businessProofOfAddress);
      setDocumentCheckboxError3(errors.businessProofOfAddress);
      return; // Don't proceed with upload if there's an error
    }

    // 4. Proceed with Upload if No Errors:
    console.log("Inside !errors.gallery is true means no error found");
    console.log("File added into select multi image hook");
    setSelectedDocumentFile3(values.businessProofOfAddress);
    setDocumentCheckboxError3('');
    // setFileDocLinkRemover(true);
  }



  const handleChange = (event) => {
    const { name, value } = event.target;
    console.log("called");
    if (name === "businessEntity") {
      const pdf = event.target.files[0]
      console.log(pdf);
      setFieldValue(name, pdf);
      setFieldTouched(name, true)
      return;
    }

    if (name === "businessProofOfAddress") {
      const pdf = event.target.files[0]
      console.log(pdf);
      setFieldValue(name, pdf);
      setFieldTouched(name, true)
      return;
    }

    if (name === "businessProofOfIdentity") {
      const pdf = event.target.files[0]
      console.log(pdf);
      setFieldValue(name, pdf);
      setFieldTouched(name, true)
      return;
    }
    // setFieldValue(name, value);
    // dispatch(updateField({ field: name, value }));
  };

  return (
    // <form className="add-wanted-device-step-section-header-parent">

    <>
      <div className="business-verification-content-wrapper business-verification-content-wrapper-custom">
        <DocumentFileUpload key={selectedDocumentFile1 ? selectedDocumentFile1.name : Date.now()} name="businessEntity" label="Business Entity" validate={backendValidation} id="businessEntity" onChange={handleChange} value=
          {undefined} />
        {
          (typeof selectedDocumentFile1 === 'object' && selectedDocumentFile1 !== "") &&

          (
            <div className="add-service-step3-file-upload-item-base3">
              <div className="add-service-step3-file-type-icon-parent">
                <div className="add-service-step3-file-type-icon3">
                  <img
                    className="add-service-step3-page-icon3"
                    loading="lazy"
                    alt=""
                    src={page}
                  />

                  <div className="add-service-step3-file-type-wrap3">
                    <b className="add-service-step3-file-type3">PDF</b>
                  </div>
                </div>
                <div className="add-service-step3-frame-parent7">
                  <div className="add-service-step3-text-parent1">
                    <div className="add-service-step3-text90">{selectedDocumentFile1.name}</div>
                    <div className="add-service-step3-checkbox3">
                      {documentCheckboxError1 ?
                        <Field type="checkbox" onClick={handleCheckbox1} name="imageDocCheckbox1" checked className="checkbox-instance custom-checkbox"></Field> :
                        <Field type="checkbox" onClick={handleCheckbox1} name="imageDocCheckbox1" checked className="checkbox-instance checkbox-base size-30-sm state-8-default checked-true type-checkbox checkbox-2"></Field>
                      }
                    </div>
                  </div>
                  <div className="add-service-step3-supporting-text29">{(selectedDocumentFile1.size / 1000).toFixed(1) + " KB"}</div>
                </div>
              </div>
              <div className="add-service-step3-progress-bar8">
                <div className="add-service-step3-progress-bar-wrapper">
                  <div className="add-service-step3-progress-bar9">
                    <div className="add-service-step3-background3"></div>
                    <div className="add-service-step3-progress3"></div>
                  </div>
                </div>
                <div className="add-service-step3-percentage3">100%</div>
              </div>
            </div>
          )
        }
      </div>

      <div className="business-verification-divider"></div>

      <div className="business-verification-content-wrapper business-verification-content-wrapper-custom">
        <DocumentFileUpload key={selectedDocumentFile2 ? selectedDocumentFile2.name : Date.now()} name="businessProofOfIdentity" label="Business Proof of Identity" validate={backendValidation} id="businessProofOfIdentity" onChange={handleChange} value=
          {undefined} />
        {
          (typeof selectedDocumentFile2 === 'object' && selectedDocumentFile2 !== "") &&

          (
            <div className="add-service-step3-file-upload-item-base3">
              <div className="add-service-step3-file-type-icon-parent">
                <div className="add-service-step3-file-type-icon3">
                  <img
                    className="add-service-step3-page-icon3"
                    loading="lazy"
                    alt=""
                    src={page}
                  />

                  <div className="add-service-step3-file-type-wrap3">
                    <b className="add-service-step3-file-type3">PDF</b>
                  </div>
                </div>
                <div className="add-service-step3-frame-parent7">
                  <div className="add-service-step3-text-parent1">
                    <div className="add-service-step3-text90">{selectedDocumentFile2.name}</div>
                    <div className="add-service-step3-checkbox3">
                      {documentCheckboxError2 ?
                        <Field type="checkbox" onClick={handleCheckbox2} name="imageDocCheckbox2" checked className="checkbox-instance custom-checkbox"></Field> :
                        <Field type="checkbox" onClick={handleCheckbox2} name="imageDocCheckbox2" checked className="checkbox-instance checkbox-base size-30-sm state-8-default checked-true type-checkbox checkbox-2"></Field>
                      }
                    </div>
                  </div>
                  <div className="add-service-step3-supporting-text29">{(selectedDocumentFile2.size / 1000).toFixed(1) + " KB"}</div>
                </div>
              </div>
              <div className="add-service-step3-progress-bar8">
                <div className="add-service-step3-progress-bar-wrapper">
                  <div className="add-service-step3-progress-bar9">
                    <div className="add-service-step3-background3"></div>
                    <div className="add-service-step3-progress3"></div>
                  </div>
                </div>
                <div className="add-service-step3-percentage3">100%</div>
              </div>
            </div>
          )
        }
      </div>

      <div className="business-verification-divider"></div>

      <div className="business-verification-content-wrapper business-verification-content-wrapper-custom">
        <DocumentFileUpload key={selectedDocumentFile3 ? selectedDocumentFile3.name : Date.now()} name="businessProofOfAddress" label="Business Proof of Address" validate={backendValidation} id="businessProofOfAddress" onChange={handleChange} value=
          {undefined} />
        {
          (typeof selectedDocumentFile3 === 'object' && selectedDocumentFile3 !== "") &&

          (
            <div className="add-service-step3-file-upload-item-base3">
              <div className="add-service-step3-file-type-icon-parent">
                <div className="add-service-step3-file-type-icon3">
                  <img
                    className="add-service-step3-page-icon3"
                    loading="lazy"
                    alt=""
                    src={page}
                  />

                  <div className="add-service-step3-file-type-wrap3">
                    <b className="add-service-step3-file-type3">PDF</b>
                  </div>
                </div>
                <div className="add-service-step3-frame-parent7">
                  <div className="add-service-step3-text-parent1">
                    <div className="add-service-step3-text90">{selectedDocumentFile3.name}</div>
                    <div className="add-service-step3-checkbox3">
                      {documentCheckboxError3 ?
                        <Field type="checkbox" onClick={handleCheckbox3} name="imageDocCheckbox3" checked className="checkbox-instance custom-checkbox"></Field> :
                        <Field type="checkbox" onClick={handleCheckbox3} name="imageDocCheckbox3" checked className="checkbox-instance checkbox-base size-30-sm state-8-default checked-true type-checkbox checkbox-2"></Field>
                      }
                    </div>
                  </div>
                  <div className="add-service-step3-supporting-text29">{(selectedDocumentFile3.size / 1000).toFixed(1) + " KB"}</div>
                </div>
              </div>
              <div className="add-service-step3-progress-bar8">
                <div className="add-service-step3-progress-bar-wrapper">
                  <div className="add-service-step3-progress-bar9">
                    <div className="add-service-step3-background3"></div>
                    <div className="add-service-step3-progress3"></div>
                  </div>
                </div>
                <div className="add-service-step3-percentage3">100%</div>
              </div>

            </div>

          )
        }
      </div>
      <div className="business-verification-upload-footer">
        <div className="business-verification-section-footer">
          <img
            className="business-verification-divider-icon4"
            alt=""
            src="/profile_settings/divider2.svg"
          />

          <div className="business-verification-content46">

            <div className="business-verification-actions1">


              <button className="business-verification-button11" type="submit" onClick={handleSubmit} disabled={isSubmitting}>
                <img
                  className="business-verification-placeholder-icon"
                  alt=""
                  src="/profile_settings/placeholder3.svg"
                />

                <div className="business-verification-text-padding8">
                  <div className="business-verification-text76">{isSubmitting ? 'Uploading...' : 'Upload'}</div>
                </div>
                <img
                  className="business-verification-placeholder-icon"
                  alt=""
                  src="/profile_settings/placeholder3.svg"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>

    // </form>
  )
}
