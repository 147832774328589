import React, { useEffect, useState } from 'react';
import "./CheckEmail.css";
import { Link, useLocation } from 'react-router-dom';
import { BackToLoginButton } from '../../components/BackToLoginButton/BackToLoginButton';
import gerator_logo from '../../icons/Gerator/reset_password/gerator04-1@2x.png';
import placeholder from '../../icons/Gerator/reset_password/placeholder.svg';
import placeholder1 from '../../icons/Gerator/reset_password/placeholder1.svg';
import placeholder2 from '../../icons/Gerator/reset_password/placeholder2.svg';
import placeholder3 from '../../icons/Gerator/reset_password/placeholder3.svg';
import reset_nav from '../../icons/Gerator/reset_password/_Nav menu button.svg';
import { ResetPasswordDropdown } from '../../components/Dropdown/Dropdown';

import feature_icon from '../../icons/Gerator/reset_password/featured-icon.svg';
import arrow_left from '../../icons/Gerator/reset_password/arrowleft1.svg';
import mail_icon from '../../icons/Gerator/reset_password/mail01.svg';
import { useRef } from 'react';


export const CheckingResetPasswordEmail = () => {

  const [email, setEmail] = useState("......")
  const location = useLocation();
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible);
  }
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (location.state != null)
      setEmail(location.state);
  }, [])

  return (
    <div className="reset-password-check-mail-reset-password-check-email">
      <header className="reset-password-check-mail-main">
        <div className="reset-password-check-mail-page-content">
          <div className="reset-password-check-mail-app-wrapper">
            <div className="reset-password-check-mail-logo1">
              <img
                className="reset-password-check-mail-gerator-04-1-icon1"
                alt=""
                src={gerator_logo}
              />
            </div>
          </div>
          <div className="reset-password-check-mail-navigation-actions1">
            <button className="reset-password-check-mail-buttonsbutton4">
              <img
                className="reset-password-check-mail-placeholder-icon7"
                alt=""
                src={placeholder}
              />

              <div className="reset-password-check-mail-text-padding3">
                <a href='/sign-in' className="reset-password-check-mail-action-label">Log in</a>
              </div>
              <img
                className="reset-password-check-mail-placeholder-icon7"
                alt=""
                src={placeholder}
              />
            </button>
            <button className="reset-password-check-mail-buttonsbutton5">
              <img
                className="reset-password-check-mail-placeholder-icon7"
                alt=""
                src={placeholder1}
              />

              <div className="reset-password-check-mail-text-padding3">
                <a href='/sign-up' className="reset-password-check-mail-text5">Sign up</a>
              </div>
              <img
                className="reset-password-check-mail-placeholder-icon7"
                alt=""
                src={placeholder1}
              />
            </button>
          </div>
          <div ref={dropdownRef} className="reset-password-check-mail-reset-password-check-email-hamburger-menu" onClick={toggleDropdown}>
            {isDropdownVisible && <ResetPasswordDropdown />}
            <img
              className="reset-password-check-mail-hamburger-icon"
              src={reset_nav}
              alt="" />
          </div>
        </div>
      </header>


      <section className="reset-password-check-mail-log-in1">
        <div className="reset-password-check-mail-container2">
          <div className="reset-password-check-mail-container3">
            <div className="reset-password-check-mail-content3">
              <div className="reset-password-check-mail-header1">
                <img
                  className="reset-password-check-mail-featured-icon1"
                  loading="lazy"
                  alt=""
                  src={feature_icon}
                />

                <div className="reset-password-check-mail-text-and-supporting-text1">
                  <h1 className="reset-password-check-mail-title">Check your email</h1>
                  <div className="reset-password-check-mail-supporting-text1">
                    <span>We sent a password reset link to </span>
                    <span className="reset-password-check-mail-oliviauntitleduicom"
                    >{email}</span
                    >
                  </div>
                </div>
              </div>


              <button className="reset-password-check-mail-buttonsbutton6">
                <img
                  className="reset-password-check-mail-placeholder-icon7"
                  alt=""
                  src={arrow_left}
                />

                {/* <Link to={"/reset-password-new-password"} style={{ textDecoration: 'none' }}> */}
                <div className="reset-password-check-mail-text-padding3">
                  {/* <div className="reset-password-check-mail-text6">Open email app</div> */}
                  <a className="reset-password-check-mail-text6" href="https://mail.google.com/" target="_blank" rel="noopener noreferrer">
                    Open Email app
                  </a>
                </div>
                {/* </Link> */}
                <img
                  className="reset-password-check-mail-placeholder-icon7"
                  alt=""
                  src={placeholder2}
                />
              </button>

              <div className="reset-password-check-mail-row">
                <div className="reset-password-check-mail-text7">Didn’t receive the email?</div>

                <img
                  className="reset-password-check-mail-placeholder-icon12"
                  alt=""
                  src={placeholder3}
                />
                {/* onClick={sendMail} */}
                <Link to={"/reset-password-send-email"}>
                  <div className="reset-password-check-mail-option-label" >Click to resend</div>
                </Link>
                <img
                  className="reset-password-check-mail-placeholder-icon12"
                  alt=""
                  src={placeholder3}
                />

              </div>

              <Link to={"/sign-in"} style={{ textDecoration: 'none' }}>
                <BackToLoginButton />
              </Link>
            </div>
          </div>
        </div>
      </section>

      <footer className='reset-password-check-mail-footer'>
        <div className="reset-password-check-mail-help-link">© Gerator Asia LLP. All rights reserved.</div>
        <img
          className="reset-password-check-mail-mail-01-icon1"
          loading="lazy"
          alt=""
          src={mail_icon}
        />
        <div className="reset-password-check-mail-helpcentergeratorcom1">helpcenter@gerator.com</div>
      </footer>
    </div>
  )
}

