import { element } from "prop-types";
import { Dashboard } from "../screens/Dashboard/Dashboard";
import { SignIn } from "../screens/Sign_In/SignIn";
import { SignUp } from "../screens/Sign_Up/SignUp";
import { UserProfile } from "../screens/UserProfile/UserProfile";
import { Radar } from "../screens/Radar/Radar";
import { Beacon } from "../screens/Beacon/Beacon";
import { AddAddress } from "../screens/Address/AddAddress/AddAddress";
import { EditAddress } from "../screens/Address/EditAddress/EditAddress";



export const AddressRoutes = [
  {
    path: "/dashboard/settings/address/add-address",
    element: <AddAddress />,
  },
  {
    path: "/dashboard/settings/address/edit-address/:id",
    element: <EditAddress />,
  },
];
