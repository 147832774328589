import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  serviceCategory: '',
  serviceValueProposition: '',
  rangeMin: '',
  rangeMax: '',
  unitOfMeasureService: '',
  clinicalApplications: '',
  purposeUse: '',
  deviceCategory: '',
  physicalLocation: '',

  serviceFeatureHighlights: '',
  serviceBenefitsHighlights: '',
  parentDevices: '',
  featureImage: '',
  featureImageLink: '',
  bannerImage: '',
  bannerImageLink: '',
  videoType: '',
  linkVideo: '',
  location: '',
  serviceAndSupportReach: '',

  serviceFeatures: '',
  serviceBenefits: '',
  scopeOfWork: '',
  serviceLevelAgreement: '',
  additionalInformation:'',
  document: '',
  documentLink: '',
}


const editServiceSlice = createSlice({
  name: "editService",
  initialState,
  reducers: {
    fillEditServiceForm(state, action) {
     return action.payload;
    },
    updateEditServiceField(state, action) {
      const {field, value} = action.payload
      if (value !== undefined) { 
        state[field] = value; 
      }
    },
    clearEditServiceForm() {
      return initialState;
    }
  },
});

export const { updateEditServiceField,clearEditServiceForm,fillEditServiceForm } = editServiceSlice.actions

export default editServiceSlice.reducer

