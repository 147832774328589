import React, { useState } from 'react';
import './DashboardFilterButtonGroup.css'; // Import your CSS file

const DashboardFilterButtonGroup = ({ handleFilterClick, activePostStatus }) => {
    const getTextClass = (filter) => {
        switch (filter) {
            case 'Published': return '31';
            case 'Pending': return '32';
            case 'Archived': return '29';
            case 'Expired': return '39';
            default: return '';
        }
    };

    return (
        <div className="dashboard-button-group custom-cursor">
            {['Published', 'Pending', 'Archived', 'Expired'].map(filter => (
                <div
                    key={filter}
                    className={`dashboard-button-group-base1 ${activePostStatus === filter ? 'active' : ''}`}
                    onClick={() => handleFilterClick(filter)}
                >
                    <div className={`dashboard-text${getTextClass(filter)}`}>
                        {filter}
                    </div>
                </div>
            ))}
        </div>
    );
}

export default DashboardFilterButtonGroup;
// <div className="dashboard-button-group custom-cursor">
//     <div
//         className={`${activeButton === '12 months' ? 'dashboard-button-group-base1 active' : 'dashboard-button-group-base1'
//             }`}
//         onClick={() => handleButtonClick('12 months')}
//     >
//         <div className="dashboard-text31">12 months</div>
//     </div>

//     <div
//         className={`${activeButton === '30 days' ? 'dashboard-button-group-base1 active' : 'dashboard-button-group-base1'
//             }`}
//         onClick={() => handleButtonClick('30 days')}
//     >
//         <div className="dashboard-text32">30 days</div>
//     </div>

//     <div
//         className={`${activeButton === '7 days' ? 'dashboard-button-group-base1 active' : 'dashboard-button-group-base1'
//             }`}
//         onClick={() => handleButtonClick('7 days')}
//     >
//         <div className="dashboard-text29">7 days</div>
//     </div>

//     <div
//         className={`${activeButton === '24 hours' ? 'dashboard-button-group-base1 active' : 'dashboard-button-group-base1'
//             }`}
//         onClick={() => handleButtonClick('24 hours')}
//     >
//         <div className="dashboard-text39">24 hours</div>
//     </div>
// </div>