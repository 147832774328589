
import { ChatPage } from "../screens/Chat/ChatPage";
import { ChatPageB } from "../screens/Chat/ChatPageB";

import { ChatPageSpareOwner } from "../screens/Chat/ChatPageSpareOwner";
import { ChatPageSpareApproacher } from "../screens/Chat/ChatPageSpareApproacher";

import { ChatPageSoftwareOwner } from "../screens/Chat/ChatPageSoftwareOwner";
import { ChatPageSoftwareApproacher } from "../screens/Chat/ChatPageSoftwareApproacher";


import { ChatPageServiceOwner } from "../screens/Chat/ChatPageServiceOwner";
import { ChatPageServiceApproacher } from "../screens/Chat/ChatPageServiceApproacher";



import { ChatPageJobOwner } from "../screens/Chat/ChatPageJobOwner";
import { ChatPageJobApproacher } from "../screens/Chat/ChatPageJobApproacher";

import { ChatPageWantedDeviceOwner } from "../screens/Chat/Wanted/ChatPageWantedDeviceOwner";
import { ChatPageWantedDeviceApproacher } from "../screens/Chat/Wanted/ChatPageWantedDeviceApproacher";


import { ChatPageWantedSpareOwner } from "../screens/Chat/Wanted/ChatPageWantedSpareOwner";
import { ChatPageWantedSpareApproacher } from "../screens/Chat/Wanted/ChatPageWantedSpareApproacher";


import { ChatPageWantedSoftwareOwner } from "../screens/Chat/Wanted/ChatPageWantedSoftwareOwner";
import { ChatPageWantedSoftwareApproacher } from "../screens/Chat/Wanted/ChatPageWantedSoftwareApproacher";


import { ChatPageWantedServiceOwner } from "../screens/Chat/Wanted/ChatPageWantedServiceOwner";
import { ChatPageWantedServiceApproacher } from "../screens/Chat/Wanted/ChatPageWantedServiceApproacher";

export const chatRoutes = [

  //DEVICE
  {
  path: "/dashboard/chat/:listing/:entity/:id",
  element: <ChatPage />,
},
{
path: "/chat/:listing/:entity/:id",
element: <ChatPageB />,
},

//SPARE
{
path: "/dashboard/chat/:listing/:entity/:id",
element: <ChatPageSpareOwner />,
},
{
  path: "/chat/:listing/:entity/:id",
element: <ChatPageSpareApproacher />,
},

//SOFTWARE
{
  path: "/dashboard/chat/:listing/:entity/:id",
  element: <ChatPageSoftwareOwner />,
  },
  {
    path: "/chat/:listing/:entity/:id",
  element: <ChatPageSoftwareApproacher />,
  },


//SERVICE

{
  path: "/dashboard/chat/:listing/:entity/:id",
  element: <ChatPageServiceOwner />,
  },
  {
    path: "/chat/:listing/:entity/:id",
  element: <ChatPageServiceApproacher />,
  },
  


//JOB

  {
    path: "/dashboard/chat/:listing/:entity/:id",
    element: <ChatPageJobOwner />,
  },
  {
    path: "/chat/:listing/:entity/:id",
    element: <ChatPageJobApproacher />,
  },

//WANTED DEVICE

  {
    path: "/beacon/chat/:listing/:entity/:id",
    element: <ChatPageWantedDeviceOwner />,
  },
  {
    path: "/demand-center/chat/:listing/:entity/:id",
    element: <ChatPageWantedDeviceApproacher />,
  },

    //WANTED SPARE

  {
    path: "/beacon/chat/:listing/:entity/:id",
    element: <ChatPageWantedSpareOwner />,
  },
  {
    path: "/demand-center/chat/:listing/:entity/:id",
    element: <ChatPageWantedSpareApproacher />,
  },

  //WANTED SOFTWARE

  {
    path: "/beacon/chat/:listing/:entity/:id",
    element: <ChatPageWantedSoftwareOwner/>,
  },
  {
    path: "/demand-center/chat/:listing/:entity/:id",
    element: <ChatPageWantedSoftwareApproacher />,
  },

  //WANTED SERVICE   "/beacon/chat/wanted/:entity/:id"

  {
    path: "/beacon/chat/:listing/:entity/:id",
    element: <ChatPageWantedServiceOwner/>,
  },
  {
    path: "/demand-center/chat/:listing/:entity/:id",
    element: <ChatPageWantedServiceApproacher />,
  },

];