import React, { useState } from 'react';
import "./PlanAndBillingSuccess.css";
// import { AddressEditForm } from '../EditAddress_copy/EditAddress';
// import divider1 from '../../icons/Gerator/profile_settings/divider1.svg';
import divider2 from '../../icons/Gerator/profile_settings/divider2.svg';
import placeholder2 from '../../icons/Gerator/profile_settings/placeholder2.svg';
import placeholder3 from '../../icons/Gerator/profile_settings/placeholder3.svg';

import trend_down_02 from "../../icons/Gerator/profile_settings/trend_down_02.svg";
import trend_up_02 from "../../icons/Gerator/profile_settings/trend_up_02.svg"
import thumbs_up from "../../icons/Gerator/profile_settings/thumbs_up.svg";
import { Helmet } from 'react-helmet-async';
import { Link, useLocation } from 'react-router-dom';
import { useNavigate, useParams } from 'react-router-dom';
import { auth, onAuthStateChanged } from '../Login/FirebaseClient';
import SkeletonListingLoaderComponent from '../../components/SkeletonListingLoaderComponent/SkeletonListingLoaderComponent';
import { NoListingFound } from '../../components/NoListing/NoListingFound';
import { useRef } from 'react';
import { useDeleteAddressMutation, useGetUserDashboardAddressesQuery, useLazyGetAddressesQuery } from '../../api/AddressAPI';
import dotsvertical from "../../icons/Gerator/contacts/dotsvertical.svg";
import arrowdown from "../../icons/Gerator/contacts/arrowdown.svg";
import markerpin02 from "../../icons/Gerator/contacts/markerpin02.svg";
import help_icon from "../../icons/Gerator/contacts/help_icon.svg";
import arrowdown1 from '../../icons/Gerator/dashboard/arrowdown1.svg';
import badge_paid from "../../icons/Gerator/dashboard/Badge_Paid.svg"


import { useEffect } from 'react';
import { NoAddress } from '../../components/NoAddress/NoAddress';
import { NothingFoundMessage } from '../../components/NothingFoundMessage/NothingFoundMessage';
import { PlanAndBillingSuccessComponent } from '../../components/PlanAndBillingSuccessComponent/PlanAndBillingSuccessComponent';
import { useNavigate } from 'react-router-dom';

export const PlanAndBillingSuccess = () => {


  const navigate = useNavigate();
  const location = useLocation();
  const { planName } = location.state || {};
  const [currentUser, setCurrentUser] = useState("");

  useEffect(() => {
    // const auth = getAuth();

    // Listen for authentication state change
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is authenticated
        setCurrentUser(user);
      } else {
        // User is not authenticated, redirect to login
        navigate('/sign-in', { state: { message: 'Please log in to continue.' } });
      }
    });

    return () => unsubscribe(); // Cleanup listener on component unmount
  }, [navigate]);



  return (
    <>
      <Helmet>
        <title>Settings - Plan Confirmation</title>
      </Helmet>
      <div className="plan-and-billing-success-form-content-wrapper">
        <div className="plan-and-billing-success-form-content">
          {/* <div className="plan-and-billing-success-toggle-header-parent">
            <div className="plan-and-billing-success-toggle-header">
              <div className="plan-and-billing-success-toggle-title">Subscription Plans</div>
              <div className="plan-and-billing-success-supporting-text2">
              You are on Krypton Plan, expiring in 23 days
              </div>
            </div>
            
          </div>
          <div className="plan-and-billing-success-divider-container">
            <img
              className="plan-and-billing-success-divider-icon3"
              alt=""
              src={divider2}
            />
          </div> */}

          {/* Listing Container */}
          <div className="plan-and-billing-success-component-listing-container">

            {/* Listing layout start    */}
            {/* {content} */}
            <PlanAndBillingSuccessComponent planName={planName} />



          </div>


        </div>
      </div >

      <br /><br />


    </>

  )

}