import React, { useEffect, useState, useRef } from "react";
import { Provider } from "../../screens/Job/EditJob/EditJobContext";
import { useNavigate, useParams } from "react-router-dom";
import { clearEditJobForm, fillEditJobForm, updateEditJobField, updateEditJobField } from "./EditJobSlice";
import { updateAppDataEditJob, clearAppDataEditJob } from "./AppDataEditJobSlice";
import { useGetEngagementTypeTaxQuery, useGetExperienceLevelTaxQuery, useGetOccupationCategoryTaxQuery, useGetServiceCategoryTaxQuery, useGetUnitOfMeasureServiceTaxQuery, useGetDeviceCategoryTaxQuery, useGetClinicalApplicationsTaxQuery, useGetPurposeUseTaxQuery, useGetPhysicalLocationTaxQuery } from "../../api/TaxonomyFormAPI";
// import { getAllTaxonomyData } from "../../helper/EditJobHelper";
import { EditJobStep1 } from "./EditJobStep1";
import { EditJobStep2 } from "./EditJobStep2";
import { useDispatch, useSelector } from "react-redux";
import { Form, Formik } from "formik";
import * as Yup from "yup";

import { useEditJobMutation } from "../../api/JobAPI";
import { isValidURL, objectToFormDataEditJob } from "../../helper/JobHelper";
import { decryptErrorData } from "../../helper/AESHelper";
import { useFindGetRelatedAddressRemoteQuery } from "../../api/AddressAPI";


const renderStep = (step) => {
  switch (step) {
    case 0:
      return <EditJobStep1 />;
    case 1:
      return <EditJobStep2 />;
    // case 2:
    //   return <EditJobStep3 />;
    default:
      return null;
  }
};

const EditJobParent = ({ setShowSuccessMessage, setIsTaxonomySet }) => {
  const { id } = useParams();

  const formikRef = useRef(null);
  //Device Category helper select field
  const [newDeviceCategoryLength, setNewDeviceCategoryLength] = useState(50);
  const [deviceCategorySearchField, setDeviceCategorySearchField] = useState("");

  //For Validation message navidating to steps. Used only when backend validation will be trigger
  const step1FormKey = ["jobTitle", "overview", "deliverables", "budget", "engagementType", "experienceLevel", "occupationCategory", "serviceCategory", "unitofMeasureService", "clinicalApplications", "purposeUse", "deviceCategory", "physicalLocation"];
  const step2FormKey = ["linkVideo", "location", "documentFileObject", "documentLink"]

  const [editJob, { isLoading, isError, error }] = useEditJobMutation();
  const navigate = useNavigate();
  const engagementTypeTax = useGetEngagementTypeTaxQuery();
  const experienceLevelTax = useGetExperienceLevelTaxQuery();
  const occupationCategoryTax = useGetOccupationCategoryTaxQuery();
  const serviceCategoryTax = useGetServiceCategoryTaxQuery();
  const unitOfMeasureServiceTax = useGetUnitOfMeasureServiceTaxQuery();
  const clinicalApplicationsTax = useGetClinicalApplicationsTaxQuery()
  const purposeUseTax = useGetPurposeUseTaxQuery()
  const deviceCategoryTax = useGetDeviceCategoryTaxQuery({ id: newDeviceCategoryLength, query: deviceCategorySearchField });
  const physicalLocationTax = useGetPhysicalLocationTaxQuery()
  const relatedAddressTax = useFindGetRelatedAddressRemoteQuery();



  const [allTaxonomy, setAllTaxonomy] = useState({ engagementType: engagementTypeTax, deviceCategory: deviceCategoryTax, physicalLocation: physicalLocationTax, purposeUse: purposeUseTax, clinicalApplications: clinicalApplicationsTax, unitOfMeasureService: unitOfMeasureServiceTax, occupationCategory: occupationCategoryTax, experienceLevel: experienceLevelTax, serviceCategory: serviceCategoryTax, relatedAddress: relatedAddressTax })

  useEffect(() => {

    const allSuccess = [engagementTypeTax.isSuccess, deviceCategoryTax.isSuccess, physicalLocationTax.isSuccess, purposeUseTax.isSuccess, clinicalApplicationsTax.isSuccess, unitOfMeasureServiceTax.isSuccess, occupationCategoryTax.isSuccess, experienceLevelTax.isSuccess, serviceCategoryTax.isSuccess, relatedAddressTax.isSuccess].every(Boolean);


    if (allSuccess) {
      setAllTaxonomy({
        engagementType: engagementTypeTax,
        deviceCategory: deviceCategoryTax, physicalLocation: physicalLocationTax, purposeUse: purposeUseTax, clinicalApplications: clinicalApplicationsTax, unitOfMeasureService: unitOfMeasureServiceTax, occupationCategory: occupationCategoryTax, experienceLevel: experienceLevelTax, serviceCategory: serviceCategoryTax, relatedAddress: relatedAddressTax
      });
    }

    setIsTaxonomySet(true)
  }, [
    engagementTypeTax.isSuccess, engagementTypeTax.data,
    experienceLevelTax.isSuccess, experienceLevelTax.data,
    occupationCategoryTax.isSuccess, occupationCategoryTax.data,
    deviceCategoryTax.isSuccess, deviceCategoryTax.data,
    unitOfMeasureServiceTax.isSuccess, unitOfMeasureServiceTax.data,
    clinicalApplicationsTax.isSuccess, clinicalApplicationsTax.data,
    purposeUseTax.isSuccess, purposeUseTax.data,
    physicalLocationTax.isSuccess, physicalLocationTax.data,
    serviceCategoryTax.isSuccess, serviceCategoryTax.data,
    relatedAddressTax.isSuccess, relatedAddressTax.data,
  ]);



  // useEffect(() => {
  //   if(allTaxonomy.serviceCategory.status === "fulfilled")
  //     {
  //       // console.log(allTaxonomy);
  //       setTaxonomyLoaded(true)
  //     }
  // }, [allTaxonomy])

  const dispatch = useDispatch();
  const formData = useSelector((state) => state.editJob);
  const appData = useSelector((state) => state.appDataEditJob);
  // const [featureImageMeta, setFeatureImageMeta] = useState("");
  const [documentFileToDelete, setDocumentFileToDelete] = useState('');
  const [selectedDocumentFile, setSelectedDocumentFile] = useState("");
  const [documentCheckboxError, setDocumentCheckboxError] = useState("")
  const [backendValidation, setBackendValidation] = useState([])
  const [isRequestFirst, setIsRequestFirst] = useState(true);
  const [isImageSelected, setIsImageSelected] = useState(false)
  const [editJobFormInitialData, setEditJobFormInitialData] = useState(null);



  // //This snippet used to reset form and applied on reset form button
  // const resetForm = () => {
  //   setSelectedDocumentFile("")
  //   setBackendValidation([])
  //   setShowSuccessMessage("")
  //   setDeviceCategorySearchField(editJobFormInitialData.tax_device_category.name)
  //   dispatch(clearAppDataEditJob());
  //   dispatch(fillEditJobForm(editJobFormInitialData))
  //   // formikRef.current.resetForm();
  //   // purgeStorage()
  // }

  const step1Schema = Yup.object({
    //  engagementType: Yup.string().required("This is a required field").test(
    //     "OPTION", "Please select a valid option",
    //     (value) => value !== '0'

    //   ),

    //   jobTitle: Yup.string().required("This is a required field").max(50, 'Must be 50 characters or less'),
    //   overview: Yup.string().required("This is a required field").max(50, 'Must be 50 characters or less'),
    //   deliverables: Yup.string().required("This is a required field").max(1000, 'Must be 1000 characters or less'),

    //   experienceLevel: Yup.string().required("This is a required field").test(
    //     "OPTION", "Please select a valid option",
    //     (value) => value !== '0'

    //   ),

    //   occupationCategory: Yup.string().required("This is a required field").test(
    //     "OPTION", "Please select a valid option",
    //     (value) => value !== '0'

    //   ),

    //   budget: Yup.number("Please enter a valid number").required("This is a required field")
    //   .test('non-negative', 'Field data cannot be negative', value => value >= 0)
    //     .test('decimal-places', 'Only two decimal places are allowed', value => {

    //       const [, decimalPart] = String(value).split('.');
    //       return decimalPart === undefined || decimalPart.length <= 2;
    //     })
    //     .min(0.01,'Field data must be greater than or equal to 0.01')
    //     .max(9999999.99, 'Field data must be less than or equal to 9,999,999.99'),


    //   unitOfMeasureService: Yup.string().required("This is a required field").test(
    //     "OPTION", "Please select a valid option",
    //     (value) => value !== '0'

    //   ),
    //   serviceCategory: Yup.string().required("This is a required field").test(
    //     "OPTION", "Please select a valid option",
    //     (value) => value !== '0'

    //   ),
    //   clinicalApplications: Yup.string().required("This is a required field").test(
    //     "OPTION", "Please select a valid option",
    //     (value) => value !== '0'

    //   ),

    //   purposeUse: Yup.string().required("This is a required field").test(
    //     "OPTION", "Please select a valid option",
    //     (value) => value !== '0'

    //   ),

    //   deviceCategory: Yup.string().required("This is a required field").test(
    //     "OPTION", "Please select a valid option",
    //     (value) => value !== '0'

    //   ),


    //   physicalLocation: Yup.string().required("This is a required field").test(
    //     "OPTION", "Please select a valid option",
    //     (value) => value !== '0'

    //   ),

  })

  const step2Schema = Yup.object().shape({

    // location: Yup.string().required("This is a required field").test(
    //   "OPTION", "Please select a valid option",
    //   (value) => value !== '0'
    // ),
    // document: Yup.mixed()
    // .test(
    //   'fileType',
    //   'Unsupported File Format (only pdf)',
    //   value => {
    //     if (isValidURL(value)) return true;
    //     if (!value) return true
    //     return ['application/pdf'].includes(value.type)
    //   }
    // )
    // .test(
    //   'fileSize',
    //   'File too large (max size 1MB)',
    //   value => {
    //     if (isValidURL(value)) return true;
    //     return !value || (value && value.size <= 1024 * 1024)
    //   }
    // )
  }
  )




  const matchErrorsAgainstBackendValidationList = async (backendValidationError) => {
    if (backendValidationError?.length > 0) {



      for (const field of backendValidationError) {
        if (step1FormKey.includes(field.path)) {
          dispatch(updateAppDataEditJob({ case: "CURRENTSTEP", value: 0 }));
          console.log("step1 was true");
          break;
        } else if (step2FormKey.includes(field.path)) {
          console.log("step2 was true");
          dispatch(updateAppDataEditJob({ case: "CURRENTSTEP", value: 1 }));
          break;
        }
      }
    }
  }

  const finalSubmit = async (multiPartData, setSubmitting) => {
    try {

      const response = await editJob({ body: multiPartData }).unwrap();
      //If form was submitted successfully then isUpdated return with true, then reseting form
      console.log(response);
      if (response.status) {
        console.log(response);

        // setShowSuccessMessage(response.message)
        setShowSuccessMessage({ message: "Job edited successfully", color: "green" });
        await new Promise(resolve => setTimeout(resolve, 1500));
        // Reseting form
        formikRef.current.resetForm();
        window.location.reload();
        // navigate('/devices');
      }
      // if (!response.status) {
      //   setShowSuccessMessage({ message: response.message, color: "red" })
      // }
    }
    catch (error) {
      if (error.status === 422) {
        console.log(error);
        const decryptedData = decryptErrorData(error);
        console.log(decryptedData);
        setBackendValidation(decryptedData.errors);
        matchErrorsAgainstBackendValidationList(decryptedData.errors)
        // setShowSuccessMessage({ message: decryptedData.message, color: "red" });
      }
      else {
        console.log(error);
        // const decryptedData = decryptErrorData(error.data);
        // console.log(decryptedData);
        setShowSuccessMessage({ message: "Internal server error", color: "red" });
      }
      setSubmitting(false);
    }
  };

  useEffect(() => {
    console.log(backendValidation);
  }, [backendValidation])


  return (
    <Formik
      innerRef={formikRef}
      initialValues={formData}
      // validateOnChange={false}
      // validateOnBlur={false}
      validationSchema={appData.currentStep === 0 ? step1Schema : appData.currentStep === 1 ? step2Schema : Yup.object().shape({})}
      onSubmit={(values, { setSubmitting }) => {
        // Getting FormData object to send multipart request
        if (appData.isYoutubeChecked) { dispatch(updateEditJobField({ field: "videoType", value: "Youtube" })) }
        if (appData.isVimeoChecked) dispatch(updateEditJobField({ field: "videoType", value: "Vimeo" }));
        multiPartData = objectToFormDataEditJob(values, documentFileToDelete, selectedDocumentFile)
        // Final submission of form
        setBackendValidation([]);
        finalSubmit(multiPartData, setSubmitting);
        //   for (const [key, value] of multiPartData.entries()) {
        //     console.log(key, value);
        // }
        // console.log(values);
        // setSubmitting(false);
      }}
    >
      {(formik) => (
        <Form className="add-job-step-section-header-parent">



          <Provider value={{ deviceCategorySearchField, setDeviceCategorySearchField, newDeviceCategoryLength, setNewDeviceCategoryLength, editJobFormInitialData, setEditJobFormInitialData, documentFileToDelete, setDocumentFileToDelete, isRequestFirst, setIsRequestFirst, backendValidation, setBackendValidation, documentCheckboxError, setDocumentCheckboxError, isImageSelected, setIsImageSelected, allTaxonomy, selectedDocumentFile, setSelectedDocumentFile, }}>

            {renderStep(appData.currentStep)}
          </Provider>
        </Form>
      )}
    </Formik>

  );
};

export default EditJobParent;