import React from "react";
import "./Navbar.css";
import gerator_logo from '../../icons/Gerator/gerator04-1@2x.png';
import bar_chart from '../../icons/Gerator/dashboard/barchart01.svg';
import dot_svg from '../../icons/Gerator/dashboard/-dot.svg';
import chevrondown from '../../icons/Gerator/dashboard/chevrondown.svg';
import dot_svg from '../../icons/Gerator/dashboard/-dot.svg';
import searchlg from '../../icons/Gerator/dashboard/searchlg.svg';
import dashboard_icon from '../../icons/Gerator/dashboard/icon.svg';
import group20 from '../../icons/Gerator/dashboard/group-20.svg';
import { getAuth, signOut } from "firebase/auth";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import Cookies from 'js-cookie';
import { useGetUserQuery } from "../../api/Login&RegisterAPI";
import { logout } from "../../helper/UserHelper";
import { UserMenuComponent } from "../UserMenu/UserMenu";
import { useRef } from "react";

export const Navbar = () => {
  const [userDetail, setUserDetail] = useState("");
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible);
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="navbar-rectangle-parent">
      <div className="navbar-frame-child"></div>
      <div className="navbar-top-header">
        <div className="navbar-branding">
          <div className="navbar-logo">
            <img
              className="navbar-gerator-04-1-icon"
              alt=""
              src={gerator_logo}
            />
          </div>
        </div>
        <div className="navbar-top-header-right">
          <div className="navbar-search-input">
            <div className="navbar-navigation">
              <div className="navbar-nav-item-base">
                <div className="navbar-content">
                  <img className="navbar-dot-icon" alt="" src={dot_svg} />

                  <img
                    className="navbar-bar-chart-01-icon"
                    alt=""
                    src={bar_chart}
                  />

                  <div className="navbar-text">Buy</div>
                </div>
                <div className="navbar-badge">
                  <div className="navbar-text1">10</div>
                </div>
                <img
                  className="navbar-chevron-down-icon"
                  alt=""
                  src={chevrondown}
                />

                <img
                  className="navbar-search-lg-icon"
                  alt=""
                  src={chevrondown}
                />
              </div>
              <div className="navbar-nav-item-base1">
                <div className="navbar-content">
                  <img className="navbar-dot-icon" alt="" src="/dashboard/-dot.svg" />

                  <img
                    className="navbar-bar-chart-01-icon"
                    alt=""
                    src={bar_chart}
                  />

                  <div className="navbar-text2">Sell</div>
                </div>
                <div className="navbar-badge1">
                  <div className="navbar-text1">10</div>
                </div>
                <img
                  className="navbar-chevron-down-icon"
                  alt=""
                  src={chevrondown}
                />
              </div>
              <div className="navbar-nav-item-base">
                <div className="navbar-content">
                  <img className="navbar-dot-icon" alt="" src="/dashboard/-dot.svg" />

                  <img
                    className="navbar-bar-chart-01-icon"
                    alt=""
                    src={bar_chart}
                  />

                  <div className="navbar-text4">Jobs</div>
                </div>
                <div className="navbar-badge">
                  <div className="navbar-text1">10</div>
                </div>
                <img
                  className="navbar-chevron-down-icon"
                  alt=""
                  src={chevrondown}
                />
              </div>
            </div>
          </div>
          <div className="navbar-input-with-label">
            <div className="navbar-label">Search</div>
            <div className="navbar-input">
              <div className="navbar-content3">
                <img
                  className="navbar-search-lg-icon"
                  alt=""
                  src={searchlg}
                />

                <div className="navbar-search-placeholder">Search</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="navbar-search-input">
        <div className="navbar-user-actions">
          <div className="navbar-search-input">
            <div className="navbar-nav-item-button">
              <img className="navbar-icon" alt="" src={dashboard_icon} />
            </div>
          </div>
          <div className="navbar-location-column-inner">
            <div className="navbar-nav-item-button">
              <img
                className="navbar-nav-item-button-child"
                alt=""
                src={group20}
              />
            </div>
          </div>
          <div ref={dropdownRef} className="navbar-avatar" onClick={toggleDropdown}>
            {isDropdownVisible && <UserMenuComponent user={{displayName:'Demo',email:"demo@gerator.com"}}/>}
            <div className="navbar-contrast-border"></div>
          </div>
          {/* <div className="navbar-avatar  dropbtn">
            <div className="navbar-contrast-border"></div>
            <div className="dropdown-content">
              <a href="#">{userData?.displayName || 'Guest'}</a>
              <Link to={"/user-profile"}><span>Profile</span></Link>
              <a href="#" onClick={logout}>Logout</a>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  )
}