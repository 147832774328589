import React, { useEffect, useState } from 'react';
import "./SignIn.css";
import reset_nav from '../../icons/Gerator/reset_password/_Nav menu button.svg';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import LoginForm from '../../components/LoginFormComponent/LoginForm';
import { ResetPasswordDropdown } from '../../components/Dropdown/Dropdown';
import { useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { firestore, doc, getDoc, auth, onAuthStateChanged } from '../../screens/Login/FirebaseClient';
import { Loader } from '../../components/Loader';
// import { useLazyGetUserQuery } from '../../api/Login&RegisterAPI';
import support_icon from "../../icons/Gerator/support_24dp_5F6368_FILL0_wght400_GRAD0_opsz24.svg"


export const SignIn = () => {
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState("")
  const location = useLocation();
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);
  const [isNotLogin, setIsNotLogin] = useState(false);


  // let errorMessage = '';
  useEffect(() => {
    // const auth = getAuth();

    // Listen for authentication state change
    const unsubscribe = onAuthStateChanged(auth, async (user) => {


      if (user) {
        // Get user to check wizardStep
        const userDocRef = doc(firestore, "users", user.uid);
        const userDocSnapshot = await getDoc(userDocRef);

        if (userDocSnapshot.exists()) {
          const usermeta = userDocSnapshot.data(); // Default to 1 if not set

          switch (usermeta.wizardStep) {
            case 1:
              navigate('/account-setup-check-email');
              break;
            case 2:
              navigate('/account-setup-update-profile');
              break;
            case 3:
              navigate('/account-setup-subscription-plan');
              break;
            default:
              navigate('/dashboard');
              break;
          }

          // setCurrentUsermeta(usermeta);
          // setCurrentUser(user);
          console.log("Wizard Step:", usermeta.wizardStep);
        }
      }
      else {
        setIsNotLogin(true);
      }
    });

    return () => unsubscribe(); // Cleanup listener on component unmount
  }, [navigate]);

  useEffect(() => {
    if (location.state != null)
      // errorMessage = ;
      setErrorMessage(location.state.message);
    location.state = {};
  }, [])

  const toggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible);
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  // console.log(message);


  if (isNotLogin) {
    return (
      <>
        <Helmet>
          <title>Sign-in</title>
        </Helmet>
        <div className="sign-in-sign-in-desktop">
          <header className="sign-in-desktop">
            <div className="sign-in-brand-navigation-parent">
              <div className="sign-in-brand-navigation">
                <div className="sign-in-logo1">
                  <img
                    className="sign-in-gerator-04-1-icon1"
                    alt=""
                    src="/gerator04-1@2x.png"
                  />
                </div>
              </div>
              <div className="sign-in-navigation-actions">
                <button className="sign-in-buttonsbutton4">
                  <img
                    className="sign-in-placeholder-icon4"
                    alt=""
                    src="/placeholder2.svg"
                  />

                  {/* <div className="sign-in-text-padding1">
                <a className="sign-in-action-label">Log in</a>
              </div> */}
                  <img
                    className="sign-in-placeholder-icon4"
                    alt=""
                    src="/placeholder2.svg"
                  />
                </button>
                <button className="sign-in-buttonsbutton5">
                  <img
                    className="sign-in-placeholder-icon4"
                    alt=""
                    src="/placeholder3.svg"
                  />

                  <div className="sign-in-text-padding1">
                    <Link to={"/sign-up"}>
                      <span className="sign-in-text8">Sign up</span>
                    </Link>

                  </div>
                  <img
                    className="sign-in-placeholder-icon4"
                    alt=""
                    src="/placeholder3.svg"
                  />
                </button>
              </div>
              <div className="sign-in-hamburger-menu" ref={dropdownRef} onClick={toggleDropdown}>
                {isDropdownVisible && <ResetPasswordDropdown />}
                <img
                  className="hamburger-icon"
                  src={reset_nav}
                  alt="" />
              </div>
            </div>
          </header>
          <footer className="sign-in-log-in">
            <LoginForm message={errorMessage} setErrorMessage={setErrorMessage} />
            <div className="sign-in-footer1">
              <div className="sign-in-copyright">© Gerator Asia LLP. All rights reserved.</div>
              <div className="sign-in-contact">
                <div className="sign-in-mail-icon">
                  <img
                    className="sign-in-mail-01-icon1"
                    loading="lazy"
                    alt=""
                    src={support_icon}
                  />
                </div>
                <div className="sign-in-helpcentergeratorcom1"><a href=" https://helpcenter.gerator.com/" className='sign-in-hyperlink'>Need Help?</a></div>
              </div>
            </div>
          </footer>
        </div>
      </>
    )

  } else {
    return <Loader />
  }




}
