import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  jobTitle:'',
    overview:'',
    deliverables:'',
    engagementType:'',
    experienceLevel:'',
    occupationCategory:'',
    serviceCategory:'',
    budget:'',
    unitOfMeasureService:'',
    deviceCategory:'',
    clinicalApplications:'',
    purposeUse:'',
    physicalLocation:'',
    location:'',
    videoType:'',
    linkVideo:'',
    document:'',
    documentFileObject:{}
}


const editJobSlice = createSlice({
  name: "editJob",
  initialState,
  reducers: {
    fillEditJobForm(state, action) {
     return action.payload;
    },
    updateEditJobField(state, action) {
      const {field, value} = action.payload
      if (value !== undefined) { 
        state[field] = value; 
      }
    },
    clearEditJobForm() {
      return initialState;
    }
  },
});

export const { updateEditJobField,clearEditJobForm,fillEditJobForm } = editJobSlice.actions

export default editJobSlice.reducer

