import React, { useState } from 'react';
import "./PlanAndBilling.css";
// import { AddressEditForm } from '../EditAddress_copy/EditAddress';
// import divider1 from '../../icons/Gerator/profile_settings/divider1.svg';
import divider2 from '../../icons/Gerator/profile_settings/divider2.svg';
import divider3 from "../../icons/Gerator/dashboard/divider3.svg"
import placeholder2 from '../../icons/Gerator/profile_settings/placeholder2.svg';
import placeholder3 from '../../icons/Gerator/profile_settings/placeholder3.svg';

import trend_down_02 from "../../icons/Gerator/profile_settings/trend_down_02.svg";
import trend_up_02 from "../../icons/Gerator/profile_settings/trend_up_02.svg"
import thumbs_up from "../../icons/Gerator/profile_settings/thumbs_up.svg";
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { useNavigate, useParams } from 'react-router-dom';
import { auth, onAuthStateChanged } from '../Login/FirebaseClient';
import SkeletonListingLoaderComponent from '../../components/SkeletonListingLoaderComponent/SkeletonListingLoaderComponent';
import { NoListingFound } from '../../components/NoListing/NoListingFound';
import { useRef } from 'react';
import dotsvertical from "../../icons/Gerator/contacts/dotsvertical.svg";
import arrowdown from "../../icons/Gerator/contacts/arrowdown.svg";
import markerpin02 from "../../icons/Gerator/contacts/markerpin02.svg";
import help_icon from "../../icons/Gerator/contacts/help_icon.svg";
import arrowdown1 from '../../icons/Gerator/dashboard/arrowdown1.svg';
import badge_paid from "../../icons/Gerator/dashboard/Badge_Paid.svg"
import badge_cancelled from "../../icons/Gerator/dashboard/Badge_Cancelled.svg"


import { useEffect } from 'react';
import { NoAddress } from '../../components/NoAddress/NoAddress';
import { NothingFoundMessage } from '../../components/NothingFoundMessage/NothingFoundMessage';
import { useLazyGetCurrentPlanOfUserQuery, useLazyGetPlansMonthlyInrQuery, useLazyGetPlansMonthlyUsdQuery, useLazyGetPlansQuery, useLazyGetPlansYearlyInrQuery, useLazyGetPlansYearlyUsdQuery } from '../../api/PlanAPI';
import { useAddFreePlanOrderMutation, useAddOrderMutation, useGetUserDashboardOrdersQuery, useLazyGetCurrentUserOrdersQuery, useVerifyOrderMutation } from '../../api/OrderAPI';
import { useLazyGetUserCountryQuery } from '../../api/UsermetaAPI';
import { getCustomizedDate } from "../../helper/DeviceHelper";
import PlanExpiryStatus from '../../components/PlanExpiryStatusComponent/PlanExpiryStatus';


// If you just need the calculation function
export const calculateExpiryDuration = (expiryDate) => {
  const now = new Date();
  const expiry = new Date(expiryDate);
  const diffTime = expiry - now;

  if (diffTime <= 0) return "expired";

  const totalDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  const years = Math.floor(totalDays / 365);
  const months = Math.floor(totalDays / 30);

  if (years >= 1) {
    return `${years} ${years === 1 ? 'year' : 'years'}`;
  } else if (months >= 1) {
    return `${months} ${months === 1 ? 'month' : 'months'}`;
  }
  return `${totalDays} ${totalDays === 1 ? 'day' : 'days'}`;
};


export const PlanAndBilling = () => {

  let content, billingContent;
  const navigate = useNavigate();
  const [offset, setOffset] = useState(0);
  const [activeplanId, setActivePlanId] = useState(null);
  const [currentPlanExpiry, setCurrentPlanExpiry] = useState("");
  const [currentPlanName, setCurrentPlanName] = useState("");
  const [currentUser, setCurrentUser] = useState("");
  //flag var to show listing when loading complete when sekelteon layout is going to stop
  const [loadingOrderComplete, setOrderLoadingComplete] = useState(false);
  const [loadingComplete, setLoadingComplete] = useState(false);
  const [createOrder] = useAddOrderMutation();
  const [createFreePlanOrder] = useAddFreePlanOrderMutation();
  const [verifyPayment] = useVerifyOrderMutation();

  const { data, refetch, isFetching } = useGetUserDashboardOrdersQuery(
    { offset },
    {
      // Refetch when component mounts or tab becomes active
      refetchOnMountOrArgChange: true
    }
  );

  const [triggerGetCurrentPlan, { data: currentPlanData, isError: isCurrentPlanError, error: currentPlanError, isLoading: isCurrentPlanLoading, isSuccess: isCurrentPlanSuccess }] = useLazyGetCurrentPlanOfUserQuery();


  const [triggerGetUserOrders, { data: ordersData, isError: isOrdersError, error: ordersError, isLoading: isOrdersLoading, isSuccess: isOrdersSuccess }] = useLazyGetCurrentUserOrdersQuery();

  const [triggerGetUserCountry, { data: countryData, isLoading, isError, error, isSuccess }] = useLazyGetUserCountryQuery();
  const [triggerGetPlanMonthlyInr, { data: monthInrPlanData, isError: isMonthInrError, error: monthInrError, isLoading: isMonthInrLoading, isSuccess: isMonthInrSuccess }] = useLazyGetPlansMonthlyInrQuery();
  const [triggerGetPlanYearlyInr, { data: yearInrPlanData, isError: isYearInrError, error: yearInrError, isLoading: isYearInrLoading, isSuccess: isYearInrSuccess }] = useLazyGetPlansYearlyInrQuery();
  const [triggerGetPlanYearlyUsd, { data: yearUsdPlanData, isError: isYearUsdError, error: yearUsdError, isLoading: isYearUsdLoading, isSuccess: isYearUsdSuccess }] = useLazyGetPlansYearlyUsdQuery();
  const [triggerGetPlanMonthlyUsd, { data: monthUsdPlanData, isError: isMonthUsdError, error: monthUsdError, isLoading: isMonthUsdLoading, isSuccess: isMonthUsdSuccess }] = useLazyGetPlansMonthlyUsdQuery();


  // Retrieve the initial state from localStorage, or default to "Monthly" (true)
  const initialToggleState = localStorage.getItem('toggleState') === 'false' ? false : true;

  // State to manage the toggle
  const [isMonthly, setIsMonthly] = useState(initialToggleState);

  const handleLoadMore = () => {
    if (data?.hasMore && !isFetching) {
      setOffset(currentOffset => currentOffset + 8);
    }
  };

  const showLoadMore = data?.hasMore &&
    data.orders.length < data.totalCount &&
    !isFetching;



  // Toggle the state when button is clicked
  const handleToggle = () => {
    const newToggleState = !isMonthly;
    setIsMonthly(newToggleState); // Toggle between monthly and yearly
    localStorage.setItem('toggleState', newToggleState); // Save state to localStorage
  };

  useEffect(() => {
    // const auth = getAuth();
    // Listen for authentication state change
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is authenticated
        setCurrentUser(user);
        triggerGetCurrentPlan();
        triggerGetUserCountry();
        triggerGetUserOrders();
      } else {
        // User is not authenticated, redirect to login
        navigate('/sign-in', { state: { message: 'Please log in to continue.' } });
      }
    });

    return () => unsubscribe(); // Cleanup listener on component unmount
  }, [navigate]);


  useEffect(() => {
    if (countryData?.usermeta?.tax_country.id === 98) {
      triggerGetPlanMonthlyInr();
      triggerGetPlanYearlyInr();
    } else {
      triggerGetPlanMonthlyUsd();
      triggerGetPlanYearlyUsd();
    }
  }, [countryData]);


  //Setting active plan id for dynamic data.
  useEffect(() => {
    if (isCurrentPlanSuccess && currentPlanData?.data?.order_plan?.id) {
      setActivePlanId(currentPlanData.data.order_plan.id);
      setCurrentPlanName(currentPlanData.data.order_plan.planName);
      setCurrentPlanExpiry(currentPlanData.data.orderExpiry);
    } else {
      console.log("No current plan data or ID not available.");
    }
  }, [isCurrentPlanSuccess, currentPlanData]);




  useEffect(() => {
    // Dynamically load the Razorpay checkout script when the component mounts
    const script = document.createElement('script');
    script.src = 'https://checkout.razorpay.com/v1/checkout.js';
    script.async = true;
    document.body.appendChild(script);

    // Cleanup script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);


  useEffect(() => {
    // On component mount, check localStorage and set the state accordingly
    const savedToggleState = localStorage.getItem('toggleState');
    if (savedToggleState !== null) {
      setIsMonthly(savedToggleState === 'true');
    }
  }, []);


  const handlePayment = async (planId) => {


    try {


      if (planId === 1) {
        // Free plan flow
        try {
          const orderData = await createFreePlanOrder(planId).unwrap();

          if (orderData.status) {
            navigate('/dashboard/settings/success', {
              state: {
                planName: orderData.planName,
              }
            });
          } else {
            throw new Error('Invalid response from free plan order creation');
          }
        } catch (error) {
          console.error('Free plan activation failed:', error);
          navigate('/payment-failed', {
            state: {
              error: 'Got error while upgrading plan',
              details: error.message
            }
          });
        }
        return;
      }

      const orderData = await createOrder(planId).unwrap();
      const isIndia = countryData?.usermeta?.tax_country.id === 98;

      const options = {
        key: orderData.keyId,
        amount: orderData.amount,
        currency: isIndia ? 'INR' : 'USD',
        order_id: orderData.orderId,
        name: 'Gerator',
        description: 'Plan Purchase',
        handler: async function (response) {
          try {
            const verificationData = {
              orderId: response.razorpay_order_id,
              paymentId: response.razorpay_payment_id,
              signature: response.razorpay_signature,
            };

            const result = await verifyPayment(verificationData).unwrap();
            if (result.status) {
              navigate('/dashboard/settings/success', {
                state: {
                  planName: result.planName,
                }
              });
            }
          } catch (error) {
            console.error('Payment verification failed:', error);
            navigate('/payment-failed');
          }
        },
        prefill: {
          name: currentUser.displayName,
          email: currentUser.email,
          contact: orderData.contact,
        },
        theme: {
          color: '#3399cc',
        },
        modal: {
          confirm_close: true,
          escape: false
        },
        notes: {
          currency: isIndia ? 'INR' : 'USD'
        }
      };

      const razorpayInstance = new window.Razorpay(options);
      razorpayInstance.open();
    } catch (error) {
      console.error('Payment initiation failed:', error);
    }



  };


  //Success true then pause to show sekelton layout for orders
  useEffect(() => {
    if (isOrdersSuccess) {
      console.log(ordersData);

      // Set timeout to delay the loading completion
      const timer = setTimeout(() => {
        setOrderLoadingComplete(true);
      }, 1000); // 1 second

      // Cleanup timer on component unmount
      return () => clearTimeout(timer);
    }
  }, [isOrdersSuccess]);


  //Success true then pause to show sekelton layout for plans
  useEffect(() => {
    const isIndia = countryData?.usermeta?.tax_country.id === 98;
    const successCondition = isIndia ?
      (isMonthInrSuccess && isYearInrSuccess) :
      (isMonthUsdSuccess && isYearUsdSuccess);

    if (successCondition) {
      const timer = setTimeout(() => {
        setLoadingComplete(true);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [isMonthInrSuccess, isYearInrSuccess, isMonthUsdSuccess, isYearUsdSuccess, countryData]);


  //Orders dynamic condition
  if (isOrdersError) {
    billingContent = <NoListingFound path={"/add-device"} />
  }

  if (isOrdersLoading) {
    billingContent = <SkeletonListingLoaderComponent />;
  } else if (loadingComplete && isOrdersSuccess) {
    const isIndia = countryData?.usermeta?.tax_country.id === 98;
    const currencySymbol = isIndia ? '₹' : '$';
    if (data.orders.length > 0) {
      // monthUsdPlanDataLength = monthUsdPlanData.data.length;
      billingContent = data.orders.map((order, index) =>
      (
        <div className="plan-and-billing-table-header-cell-parent" key={index}>

          <div className="plan-and-billing-table-cell10">

            <div className="plan-and-billing-cell-content">
              <input className="plan-and-billing-checkbox-wrapper" type="checkbox" />
            </div>

            <div className="plan-and-billing-dropdown1">
              <div className="plan-and-billing-cell-title">#{order.id}</div>
            </div>
          </div>

          <div className="plan-and-billing-table-cell20">
            <div className="plan-and-billing-location-content">


              <div className="plan-and-billing-text36">{getCustomizedDate(order.updatedAt)}</div>
            </div>
          </div>

          <div className="plan-and-billing-table-cell50">
            <div className="plan-and-billing-location-content">
              {order.orderStatus === "paid" && <img src={badge_paid} alt="" />}
              {order.orderStatus === "created" && <img src={badge_cancelled} alt="" />}
              {/* <img src={badge_cancelled} alt="" /> */}
            </div>
          </div>

          <div className="plan-and-billing-table-cell30">
            <div className="plan-and-billing-location-content">


              <div className="plan-and-billing-text36">{order.order_plan.planName}</div>
            </div>
          </div>

          <div className="plan-and-billing-table-cell40">
            <div className="plan-and-billing-text47">{currencySymbol}{order.orderTotal}</div>
          </div>

          <div className="plan-and-billing-table-cell40">
            <div className="plan-and-billing-dropdown">
              <img
                className="plan-and-billing-dots-vertical-icon"
                alt=""
                src={dotsvertical}
              />
            </div>
          </div>

        </div>
        // <></>
      ))
    }
    else {
      billingContent = (

        <NothingFoundMessage text="Your company doesn't have an address yet ?!" text1="Let's start the process ?" text2="You need to create an address for your business,or be related one." />
        // <NoAddress />
      )
    }

  } else if (!loadingOrderComplete && isOrdersSuccess) {
    content = <SkeletonListingLoaderComponent />; // Show loader until loadingComplete is true
  }



  // Plan dynamic condition with INR support
  if (isMonthInrError || isYearInrError || isMonthUsdError || isYearUsdError) {
    content = <NothingFoundMessage text="No plans available" text1="Please contact support" text2="No subscription plans are currently available." />;

  }


  if (isMonthInrLoading || isYearInrLoading || isMonthUsdLoading || isYearUsdLoading) {
    content = <SkeletonListingLoaderComponent />;
  } else if (loadingComplete) {
    const isIndia = countryData?.usermeta?.tax_country.id === 98;

    if (isMonthly) {
      const planData = isIndia ? monthInrPlanData : monthUsdPlanData;
      const currencySymbol = isIndia ? '₹' : '$';

      if (planData?.data?.length > 0) {
        content = planData.data.map((plan, index) => (
          <div className={(activeplanId === plan.id) ? "plan-and-billing-display-CheckboxGroupItem1" : "plan-and-billing-display-CheckboxGroupItem"} key={index}>
            <div className="plan-and-billing-display-Content plan-and-billing-display-Content-width">
              <div className="plan-and-billing-display-TextAndSupportingText">
                <div className="plan-and-billing-display-TextAndSubtext">
                  <div className="plan-and-billing-display-Text-1">{plan.planName}</div>
                  <div className="plan-and-billing-display-Subtext">
                    {plan.tax_plan_expiration_period.name} Plan ({currencySymbol}{plan.planPrice})
                  </div>
                </div>
                <div className="plan-and-billing-display-SupportingText plan-and-billing-display-text-overlflow">
                  Includes up to {plan.planAllowedListing} listings, {plan.planAllowedAddress} addresses and {plan.planListingValidity} days listing validity.
                </div>
                <div className="plan-and-billing-display-Actions">
                  <div className="plan-and-billing-display-ButtonsButton">
                    <div className="plan-and-billing-display-Text-2" style={{ cursor: "pointer" }}>See Details</div>
                  </div>
                </div>
              </div>
            </div>

            {/* Plan Status Button (Current/Upgrade/Downgrade) */}
            {(plan.id === activeplanId) && (
              <div className="plan-and-billing-display-buttonsbutton-wrapper plan-and-billing-display-buttonsbutton-wrapper-width">
                <div className="plan-and-billing-display-buttonsbutton19">
                  <img
                    className="plan-and-billing-display-arrow-narrow-left-icon"
                    alt=""
                    src={thumbs_up}
                  />
                  <div className="plan-and-billing-display-search-input">
                    <div className="plan-and-billing-display-text107a">Current Plan</div>
                  </div>
                </div>
              </div>
            )}

            {/* Upgrade/Downgrade buttons */}
            {((activeplanId !== plan.id)) && (
              <div className="plan-and-billing-display-buttonsbutton-wrapper plan-and-billing-display-buttonsbutton-wrapper-width">
                <div className="plan-and-billing-display-buttonsbutton19">
                  <img
                    className="plan-and-billing-display-arrow-narrow-left-icon"
                    alt=""
                    src={plan.id < activeplanId ? trend_down_02 : trend_up_02}
                  />
                  <div className="plan-and-billing-display-search-input">
                    <div className="plan-and-billing-display-text107"
                      onClick={() => handlePayment(plan.id)}>
                      {plan.id < activeplanId ? 'Downgrade Plan' : 'Upgrade Plan'}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        ));
      } else {
        content = <NothingFoundMessage text="No plans available" text1="Please contact support" text2="No subscription plans are currently available." />;
      }
    } else {
      // Yearly plans
      const planData = isIndia ? yearInrPlanData : yearUsdPlanData;
      const currencySymbol = isIndia ? '₹' : '$';

      if (planData?.data?.length > 0) {
        content = planData.data.map((plan, index) => (
          <div className={(activeplanId === plan.id) ? "plan-and-billing-display-CheckboxGroupItem1" : "plan-and-billing-display-CheckboxGroupItem"} key={index}>
            <div className="plan-and-billing-display-Content plan-and-billing-display-Content-width">
              <div className="plan-and-billing-display-TextAndSupportingText">
                <div className="plan-and-billing-display-TextAndSubtext">
                  <div className="plan-and-billing-display-Text-1">{plan.planName}</div>
                  <div className="plan-and-billing-display-Subtext">
                    {plan.tax_plan_expiration_period.name} Plan ({currencySymbol}{plan.planPrice})
                  </div>
                </div>
                <div className="plan-and-billing-display-SupportingText plan-and-billing-display-text-overlflow">
                  Includes up to {plan.planAllowedListing} listings, {plan.planAllowedAddress} addresses and {plan.planListingValidity} days listing validity.
                </div>
                <div className="plan-and-billing-display-Actions">
                  <div className="plan-and-billing-display-ButtonsButton">
                    <div className="plan-and-billing-display-Text-2" style={{ cursor: "pointer" }}>See Details</div>
                  </div>
                </div>
              </div>
            </div>

            {/* Plan Status Button (Current/Upgrade/Downgrade) */}
            {(plan.id === activeplanId) && (
              <div className="plan-and-billing-display-buttonsbutton-wrapper plan-and-billing-display-buttonsbutton-wrapper-width">
                <div className="plan-and-billing-display-buttonsbutton19">
                  <img
                    className="plan-and-billing-display-arrow-narrow-left-icon"
                    alt=""
                    src={thumbs_up}
                  />
                  <div className="plan-and-billing-display-search-input">
                    <div className="plan-and-billing-display-text107a">Current Plan</div>
                  </div>
                </div>
              </div>
            )}

            {/* Upgrade/Downgrade buttons */}
            {((activeplanId !== plan.id)) && (
              <div className="plan-and-billing-display-buttonsbutton-wrapper plan-and-billing-display-buttonsbutton-wrapper-width">
                <div className="plan-and-billing-display-buttonsbutton19">
                  <img
                    className="plan-and-billing-display-arrow-narrow-left-icon"
                    alt=""
                    src={plan.id < activeplanId ? trend_down_02 : trend_up_02}
                  />
                  <div className="plan-and-billing-display-search-input">
                    <div className="plan-and-billing-display-text107"
                      onClick={() => handlePayment(plan.id)}>
                      {plan.id < activeplanId ? 'Downgrade Plan' : 'Upgrade Plan'}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        ));
      } else {
        content = <NothingFoundMessage text="No plans available" text1="Please contact support" text2="No subscription plans are currently available." />;
      }
    }
  }




  return (
    <>
      <Helmet>
        <title>Settings - Plan & Billing</title>
      </Helmet>
      <div className="plan-and-billing-display-form-content-wrapper">
        <div className="plan-and-billing-display-form-content">
          <div className="plan-and-billing-display-toggle-header-parent">
            <div className="plan-and-billing-display-toggle-header">
              <div className="plan-and-billing-display-toggle-title">Subscription Plans</div>

              <PlanExpiryStatus expiryDate={currentPlanExpiry} planName={currentPlanName} />
            </div>
            <div className="plan-and-billing-display-toggle-controls">
              <div className="plan-and-billing-display-rectangle-group">
                <div className="plan-and-billing-display-frame-item"></div>
                <div className={`plan-and-billing-display-switch-label1 ${isMonthly ? 'active' : ''}`}>Monthly</div>
                <div className="plan-and-billing-display-toggle-base" onClick={handleToggle}>
                  <div className={`plan-and-billing-display-button6 ${isMonthly ? 'left' : 'right'}`}></div>
                </div>
                <div className={`plan-and-billing-display-switch-label ${!isMonthly ? 'active' : ''}`}>
                  Yearly</div>
                <div className="plan-and-billing-display-supporting-text3">
                  Save my login details for next time.
                </div>
              </div>
              <button className="plan-and-billing-display-button7">
                <img
                  className="plan-and-billing-display-placeholder-icon"
                  alt=""
                  src={placeholder2}
                />

                <div className="plan-and-billing-display-button-labels">Badge #1</div>
                <img
                  className="plan-and-billing-display-placeholder-icon"
                  alt=""
                  src={placeholder2}
                />
              </button>

              <button className='plan-and-billing-display-button8'>
                <img
                  className="plan-and-billing-display-placeholder-icon"
                  alt=""
                  src={placeholder3}
                />

                <div className="plan-and-billing-display-text49">Business Owner</div>
                <img
                  className="plan-and-billing-display-placeholder-icon"
                  alt=""
                  src={placeholder3}
                />
              </button>

            </div>
          </div>
          <div className="plan-and-billing-display-divider-container">
            <img
              className="plan-and-billing-display-divider-icon3"
              alt=""
              src={divider3}
            />
          </div>

          {/* Listing Container */}
          <div className="plan-and-billing-display-component-listing-container">

            {/* Listing layout start    */}
            {content}


          </div>


        </div>
      </div >

      <br /><br />

      {/* Invoice Table */}

      <div className="plan-and-billing-display-form-content-wrapper">
        <div className="plan-and-billing-display-form-content">
          <div className="plan-and-billing-display-toggle-header-parent">
            <div className="plan-and-billing-display-toggle-header">
              <div className="plan-and-billing-display-toggle-title">Billing</div>
              <PlanExpiryStatus expiryDate={currentPlanExpiry} planName={currentPlanName} />
            </div>

          </div>
          <div className="plan-and-billing-display-divider-container">
            <img
              className="plan-and-billing-display-divider-icon3"
              alt=""
              src={divider3}
            />
          </div>

          <form className="plan-and-billing-table">

            <div className="plan-and-billing-table-content">

              <div className="plan-and-billing-table-row">

                {/* Invoice */}



                <div className="plan-and-billing-table-header-cell1">
                  <input className="plan-and-billing-checkbox-wrapper" type="checkbox" />
                  <div className="plan-and-billing-table-header">
                    <a className="plan-and-billing-column-title">Invoice</a>
                    <img
                      className="plan-and-billing-arrow-down-icon1"
                      alt=""
                      src={arrowdown}
                    />
                  </div>
                </div>

                <div className="plan-and-billing-table-header-cell2">
                  <div className="plan-and-billing-table-header">
                    <a className="plan-and-billing-column-title">Date</a>

                  </div>
                </div>


                {/* Status  */}

                <div className="plan-and-billing-table-header-cell2">
                  <div className="plan-and-billing-table-header">
                    <a className="plan-and-billing-column-title">Status</a>


                  </div>
                </div>

                {/* Plan  */}

                <div className="plan-and-billing-table-header-cell3">
                  <div className="plan-and-billing-table-header">
                    <a className="plan-and-billing-column-title">Plan</a>


                  </div>
                </div>

                {/* Price  */}

                <div className="plan-and-billing-table-header-cell4">
                  <div className="plan-and-billing-table-header">
                    <a className="plan-and-billing-column-title">Price</a>


                  </div>
                </div>

                {/* Action  */}

                <div className="plan-and-billing-table-header-cell4">
                  <div className="plan-and-billing-table-header2">
                    <div className="plan-and-billing-action-column-title">Action</div>
                    <img
                      className="plan-and-billing-arrow-down-icon2"
                      alt=""
                      src={arrowdown}
                    />
                  </div>
                </div>

              </div>

              {billingContent}

            </div>

            {/* Load More Button */}

            <div className="contact-frame-wrapper44">
              <div className="contact-frame-parent72">
                <div className="contact-buttonsbutton-wrapper1">
                  {showLoadMore && (
                    <button className='contact-buttonsbutton-wrapper1-child'
                      onClick={handleLoadMore}
                      disabled={isFetching}
                    >
                      <div className="contact-buttonsbutton28">
                        <img
                          className="contact-arrow-narrow-left-icon"
                          alt=""
                          src={arrowdown1}
                        />
                        <div className="contact-search-input">
                          <div className="contact-text146">{isFetching ? 'Loading...' : 'Load More'}</div>
                        </div>
                      </div>
                    </button>
                  )}

                </div>
              </div>
            </div>



          </form>


        </div>
      </div >
    </>

  )

}


// <div className="plan-and-billing-display-CheckboxGroupItem" >
//               <div className="plan-and-billing-display-Content plan-and-billing-display-Content-width" >
//                 {/* <div className="plan-and-billing-display-Input" > */}
//                 {/* <input type="checkbox" className="plan-and-billing-display-CheckboxBase" style={{ accentColor: '#7F56D9' }} /> */}
//                 {/* </div> */}
//                 <div className="plan-and-billing-display-TextAndSupportingText" >
//                   <div className="plan-and-billing-display-TextAndSubtext" >
//                     <div className="plan-and-billing-display-Text-1" >Oxygen</div>
//                     <div className="plan-and-billing-display-Subtext" >Free Plan</div>
//                   </div>
//                   <div className="plan-and-billing-display-SupportingText plan-and-billing-display-text-overlflow" >Includes up to 10 listings, 5 addresses and 15 days listing validity. </div>
//                   {/* <br /> */}
//                   <div className="plan-and-billing-display-Actions" >
//                     <div className="plan-and-billing-display-ButtonsButton" >
//                       <div className="plan-and-billing-display-Text-2" style={{ cursor: "pointer" }}>See Details</div>
//                     </div>
//                     {/* {
//                   (address.tax_address_type.slug === 'address' && <a href={`/dashboard/settings/address/edit-address/${address.id}`} className="plan-and-billing-display-ButtonsButton" style={{ cursor: "pointer" }} >
//                     <div className="plan-and-billing-display-Text-3" >Edit</div>
//                   </a>)
//                 } */}
//                     {/* <div className="plan-and-billing-display-ButtonsButton" style={{ cursor: "pointer" }} >
//                   <div className="plan-and-billing-display-Text-3" >Edit</div>
//                 </div> */}
//                   </div>
//                 </div>
//               </div>

//               <div className="plan-and-billing-display-buttonsbutton-wrapper plan-and-billing-display-buttonsbutton-wrapper-width" >
//                 <div className="plan-and-billing-display-buttonsbutton19">
//                   <img
//                     className="plan-and-billing-display-arrow-narrow-left-icon"
//                     alt=""
//                     src={trend_down_02}
//                   />
//                   <div className="plan-and-billing-display-search-input">
//                     <div className="plan-and-billing-display-text107" onClick={() => {
//                       handlePayment(1)
//                     }}>Downgrade Plan </div>

//                   </div>
//                 </div>
//               </div>
//             </div>

//             <div className="plan-and-billing-display-CheckboxGroupItem1" >
//               <div className="plan-and-billing-display-Content plan-and-billing-display-Content-width" >
//                 {/* <div className="plan-and-billing-display-Input" > */}
//                 {/* <input type="checkbox" className="plan-and-billing-display-CheckboxBase" style={{ accentColor: '#7F56D9' }} /> */}
//                 {/* </div> */}
//                 <div className="plan-and-billing-display-TextAndSupportingText" >
//                   <div className="plan-and-billing-display-TextAndSubtext" >
//                     <div className="plan-and-billing-display-Text-1" >Krypton</div>
//                     <div className="plan-and-billing-display-Subtext" >   {isMonthly ? `$${monthlyPrices.plan1}/month` : `$${yearlyPrices.plan1}/year`}</div>
//                   </div>
//                   <div className="plan-and-billing-display-SupportingText plan-and-billing-display-text-overlflow" >Includes up to 25 listings, 15 addresses and access to all features.  </div>
//                   {/* <br /> */}
//                   <div className="plan-and-billing-display-Actions" >
//                     <div className="plan-and-billing-display-ButtonsButton" >
//                       <div className="plan-and-billing-display-Text-2" style={{ cursor: "pointer" }}>See Details</div>
//                     </div>
//                     {/* {
//                 (address.tax_address_type.slug === 'address' && <a href={`/dashboard/settings/address/edit-address/${address.id}`} className="plan-and-billing-display-ButtonsButton" style={{ cursor: "pointer" }} >
//                 <div className="plan-and-billing-display-Text-3" >Edit</div>
//                 </a>)
//             } */}
//                     {/* <div className="plan-and-billing-display-ButtonsButton" style={{ cursor: "pointer" }} >
//                 <div className="plan-and-billing-display-Text-3" >Edit</div>
//             </div> */}
//                   </div>
//                 </div>
//               </div>

//               <div className="plan-and-billing-display-buttonsbutton-wrapper plan-and-billing-display-buttonsbutton-wrapper-width" >
//                 <div className="plan-and-billing-display-buttonsbutton19">
//                   <img
//                     className="plan-and-billing-display-arrow-narrow-left-icon"
//                     alt=""
//                     src={thumbs_up}
//                   />
//                   <div className="plan-and-billing-display-search-input">
//                     <div className="plan-and-billing-display-text107a" onClick={() => {
//                       handlePayment(2)
//                     }}>Current Plan </div>
//                   </div>
//                 </div>
//               </div>
//             </div>

//             <div className="plan-and-billing-display-CheckboxGroupItem" >
//               <div className="plan-and-billing-display-Content plan-and-billing-display-Content-width" >
//                 {/* <div className="plan-and-billing-display-Input" > */}
//                 {/* <input type="checkbox" className="plan-and-billing-display-CheckboxBase" style={{ accentColor: '#7F56D9' }} /> */}
//                 {/* </div> */}
//                 <div className="plan-and-billing-display-TextAndSupportingText" >
//                   <div className="plan-and-billing-display-TextAndSubtext" >
//                     <div className="plan-and-billing-display-Text-1" >Xenon</div>
//                     <div className="plan-and-billing-display-Subtext" >   {isMonthly ? `$${monthlyPrices.plan2}/month` : `$${yearlyPrices.plan2}/year`}</div>
//                   </div>
//                   <div className="plan-and-billing-display-SupportingText plan-and-billing-display-text-overlflow" >Includes up to 100 listings, 30 addresses and access to all features.   </div>
//                   {/* <br /> */}
//                   <div className="plan-and-billing-display-Actions" >
//                     <div className="plan-and-billing-display-ButtonsButton" >
//                       <div className="plan-and-billing-display-Text-2" style={{ cursor: "pointer" }}>See Details</div>
//                     </div>
//                     {/* {
//                 (address.tax_address_type.slug === 'address' && <a href={`/dashboard/settings/address/edit-address/${address.id}`} className="plan-and-billing-display-ButtonsButton" style={{ cursor: "pointer" }} >
//                 <div className="plan-and-billing-display-Text-3" >Edit</div>
//                 </a>)
//             } */}
//                     {/* <div className="plan-and-billing-display-ButtonsButton" style={{ cursor: "pointer" }} >
//                 <div className="plan-and-billing-display-Text-3" >Edit</div>
//             </div> */}
//                   </div>
//                 </div>
//               </div>

//               <div className="plan-and-billing-display-buttonsbutton-wrapper plan-and-billing-display-buttonsbutton-wrapper-width" >
//                 <div className="plan-and-billing-display-buttonsbutton19">
//                   <img
//                     className="plan-and-billing-display-arrow-narrow-left-icon"
//                     alt=""
//                     src={trend_up_02}
//                   />
//                   <div className="plan-and-billing-display-search-input">
//                     <div className="plan-and-billing-display-text107" onClick={() => {
//                       handlePayment(3)
//                     }}>Upgrade Plan </div>
//                   </div>
//                 </div>
//               </div>
//             </div>

//             <div className="plan-and-billing-display-CheckboxGroupItem">
//               <div className="plan-and-billing-display-Content plan-and-billing-display-Content-width" >
//                 {/* <div className="plan-and-billing-display-Input" > */}
//                 {/* <input type="checkbox" className="plan-and-billing-display-CheckboxBase" style={{ accentColor: '#7F56D9' }} /> */}
//                 {/* </div> */}
//                 <div className="plan-and-billing-display-TextAndSupportingText" >
//                   <div className="plan-and-billing-display-TextAndSubtext" >
//                     <div className="plan-and-billing-display-Text-1" >Helium</div>
//                     <div className="plan-and-billing-display-Subtext" >   {isMonthly ? `$${monthlyPrices.plan1}/month` : `$${yearlyPrices.plan1}/year`}</div>
//                   </div>
//                   <div className="plan-and-billing-display-SupportingText plan-and-billing-display-text-overlflow" >Includes up to 250 listings,50 addresses and access to all features.   </div>
//                   {/* <br /> */}
//                   <div className="plan-and-billing-display-Actions" >
//                     <div className="plan-and-billing-display-ButtonsButton" >
//                       <div className="plan-and-billing-display-Text-2" style={{ cursor: "pointer" }} >See Details</div>
//                     </div>
//                     {/* {
//                 (address.tax_address_type.slug === 'address' && <a href={`/dashboard/settings/address/edit-address/${address.id}`} className="plan-and-billing-display-ButtonsButton" style={{ cursor: "pointer" }} >
//                 <div className="plan-and-billing-display-Text-3" >Edit</div>
//                 </a>)
//             } */}
//                     {/* <div className="plan-and-billing-display-ButtonsButton" style={{ cursor: "pointer" }} >
//                 <div className="plan-and-billing-display-Text-3" >Edit</div>
//             </div> */}
//                   </div>
//                 </div>
//               </div>

//               <div className="plan-and-billing-display-buttonsbutton-wrapper address-display-buttonsbutton-wrapper-width" >
//                 <div className="plan-and-billing-display-buttonsbutton19">
//                   <img
//                     className="plan-and-billing-display-arrow-narrow-left-icon"
//                     alt=""
//                     src={trend_up_02}
//                   />
//                   <div className="plan-and-billing-display-search-input">
//                     <div className="plan-and-billing-display-text107" onClick={() => {
//                       handlePayment(4)
//                     }}>Upgrade Plan </div>
//                   </div>
//                 </div>
//               </div>
//             </div>