// import React, { useEffect } from "react";

// import "../ChatPageComponent.css"; // Correct relative path

// import arrowright from "../../../icons/Gerator/chat_page/arrowright.svg";
// import dot2 from "../../../icons/Gerator/chat_page/-dot2.svg";
// import facesmile from "../../../icons/Gerator/chat_page/facesmile.svg";
// import arrowleft from "../../../icons/Gerator/chat_page/arrowleft.svg";
// import avatar2 from "../../../icons/Gerator/chat_page/avatar2.svg";
// import dotsvertical from "../../../icons/Gerator/chat_page/dotsvertical.svg";
// import divider2 from "../../../icons/Gerator/chat_page/divider2.svg";
// import divider3 from "../../../icons/Gerator/chat_page/divider-3.svg";
// import dotshorizontal from "../../../icons/Gerator/chat_page/dotshorizontal.svg";
// import uploadcloud02 from "../../../icons/Gerator/chat_page/uploadcloud02.svg";
// import videorecorder from "../../../icons/Gerator/chat_page/videorecorder.svg";
// import phonecall01 from "../../../icons/Gerator/chat_page/phonecall01.svg";
// import filterlines from "../../../icons/Gerator/chat_page/filterlines.svg";
// import placeholder1 from "../../../icons/Gerator/chat_page/placeholder1.svg";
// import placeholder2 from "../../../icons/Gerator/chat_page/placeholder2.svg";
// import placeholder3 from "../../../icons/Gerator/chat_page/placeholder3.svg";
// import placeholder from "../../../icons/Gerator/chat_page/placeholder.svg";
// import pageIcon from "../../../icons/Gerator/chat_page/page.svg";

// import searchlg from "../../../icons/Gerator/chat_page/searchlg.svg";

// const CardHeader = ({deviceData}) => {
//   useEffect(() => {
//     if (deviceData) {
   
//       console.log('YES DEVICE SUCCESS in CARDHEADER', deviceData);

//       //.log('YES CHATPAGEB', deviceData.data.profileImage);
//     }
//   }, [deviceData]);


//   return (


//         <div className="chatB-card-header1">
//           <div className="chatB-content7">
//             <div className="chatB-avatar-and-text">
//               {/* <img className="chatB-avatar-icon" loading="lazy" alt="" src={deviceData?.profileImage} /> */}
//               {/* {deviceData?.data?.profileImage ?

// <img
//   className="chatB-avatar-icon"
//   loading="lazy" 
//   alt=""
//   src={deviceData?.data?.profileImage}
// />
// :
// <img
//   className="chatB-avatar-icon"
//   alt=""
//   src={avatar2}
// />

// } */}

// {deviceData?.data?.profileImage ?

// <img
//   className="chatB-avatar-icon"
//   alt=""
//   src={deviceData?.data?.profileImage}
// />
// :
// <img
//   className="chatB-avatar-icon"
//   alt=""
//   src={avatar2}
// />

// }
//               <div className="chatB-text-and-supporting-text8">
//                 <div className="chatB-text-and-badge1">
//                   <div className="chatB-caller-name">   {deviceData?.data?.device_business?.business_user?.displayName ||
//                   "Unknown User"}</div>
//                   <div className="chatB-badge11">
//                     <img className="chatB-dot-icon5" alt="" src={dot2}/>
//                     <div className="chatB-text27">Online</div>
//                   </div>
//                 </div>
//                 <div className="chatB-supporting-text17">
//                 {deviceData?.data?.tax_your_role?.name } @{deviceData?.data?.device_business?.businessName}
//                 </div>
//               </div>
//             </div>
//             <div className="chatB-actions1">
//               <div className="chatB-button">
//                 <img className="chatB-placeholder-icon" alt="" src={placeholder} />
//                 <div className="chatB-text-padding">
//                   <div className="chatB-text18">Tertiary</div>
//                 </div>
//                 <img className="chatB-placeholder-icon" alt="" src={placeholder} />
//               </div>
//               <button className="chatB-button5">
//                 <img className="chatB-arrow-up-icon" alt="" src={phonecall01} />
//                 <div className="chatB-text-padding4">
//                   <div className="chatB-text29">Call</div>
//                 </div>
//                 <img className="chatB-placeholder-icon" alt="" src={placeholder1}/>
//               </button>
//               <button className="chatB-button6">
//                 <img className="chatB-placeholder-icon" alt="" src={placeholder3} />
//                 <img className="chatB-arrow-up-icon" alt="" src={videorecorder} />
//                 <div className="chatB-text-padding4">
//                   <div className="chatB-call-button-label">Video</div>
//                 </div>
//                 <img className="chatB-placeholder-icon" alt="" src={placeholder3} />
//               </button>
//               <button className="chatB-button7">
//                 <img className="chatB-placeholder-icon" alt="" src={placeholder2}/>
//                 <img className="chatB-arrow-up-icon" alt="" src={uploadcloud02} />
//                 <div className="chatB-text-padding4">
//                   <div className="chatB-text30">Update Stage</div>
//                 </div>
//                 <img className="chatB-placeholder-icon" alt="" src={placeholder2}/>
//               </button>
//             </div>
//             <div className="chatB-text-and-supporting-text1">
//               <img className="chatB-dots-vertical-icon" alt="" src={dotsvertical} />
//             </div>
//           </div>
//           <img className="chatB-divider-icon5" alt="" src={divider3} />
//         </div>
        
//   );
// }

// export {CardHeader};

import React, { useEffect,useState } from "react";

import "../ChatPageComponent.css";

import avatar2 from "../../../icons/Gerator/chat_page/avatar2.svg";
import dot2 from "../../../icons/Gerator/chat_page/-dot2.svg";
import phonecall01 from "../../../icons/Gerator/chat_page/phonecall01.svg";
import videorecorder from "../../../icons/Gerator/chat_page/videorecorder.svg";
import uploadcloud02 from "../../../icons/Gerator/chat_page/uploadcloud02.svg";
import placeholder from "../../../icons/Gerator/chat_page/placeholder.svg";
import placeholder1 from "../../../icons/Gerator/chat_page/placeholder1.svg";
import placeholder2 from "../../../icons/Gerator/chat_page/placeholder2.svg";
import placeholder3 from "../../../icons/Gerator/chat_page/placeholder3.svg";
import dotsvertical from "../../../icons/Gerator/chat_page/dotsvertical.svg";
import divider3 from "../../../icons/Gerator/chat_page/divider-3.svg";
import { StageSlideOutForm } from "../../StageSlideOutForm/StageSlideOutForm";

const CardHeader = ({ deviceData, selectedChat }) => {

   const [isFormOpen, setIsFormOpen] = useState(false);
       
      
      
        const toggleForm = () => {
          setIsFormOpen(!isFormOpen);
        };

  useEffect(() => {
    if (  selectedChat) {
      console.log("selectedChat in CARD HEADER:", selectedChat);
    }
  }, [ selectedChat]);

  return (
    <div className="chatB-card-header1">
      <div className="chatB-content7">
        <div className="chatB-avatar-and-text">
          {selectedChat?.profileImage ? (
            <img
              className="chatB-avatar-icon"
              alt="Avatar"
              src={selectedChat.profileImage}
            />
          ) : (
            <img className="chatB-avatar-icon" alt="Default Avatar" src={selectedChat?.senderDetails?.profileImageLink} />
          )}

          <div className="chatB-text-and-supporting-text8">
            <div className="chatB-text-and-badge1">
              <div className="chatB-caller-name">
                {selectedChat?.senderDetails?.display_name || "Unknown User"}
              </div>
              <div className="chatB-badge11">
                <img className="chatB-dot-icon5" alt="" src={dot2} />
                <div className="chatB-text27">Online</div>
              </div>
            </div>
            <div className="chatB-supporting-text17">
              {selectedChat?.senderDetails?.user_usermeta?.user_work_title || "User Role"} @
              {selectedChat?.senderDetails?.user_usermeta?.user_business_name || "Business Name"}
            </div>
          </div>
        </div>

        <div className="chatB-actions1">
          <button className="chatB-button5">
            <img className="chatB-arrow-up-icon" alt="Call" src={phonecall01} />
            <div className="chatB-text-padding4">
              <div className="chatB-text29">Call</div>
            </div>
          </button>
          <button className="chatB-button6">
            <img className="chatB-arrow-up-icon" alt="Video" src={videorecorder} />
            <div className="chatB-text-padding4">
              <div className="chatB-call-button-label">Video</div>
            </div>
          </button>
          <button className="chatB-button7"  onClick={toggleForm}>
            <img className="chatB-arrow-up-icon" alt="Update" src={uploadcloud02} />
            <div className="chatB-text-padding4">
              <div className="chatB-text30">Update Stage</div>
            </div>
          </button>
             {isFormOpen && <StageSlideOutForm onClose={toggleForm}/>}
        </div>

        <div className="chatB-text-and-supporting-text1">
          <img className="chatB-dots-vertical-icon" alt="Options" src={dotsvertical} />
        </div>
      </div>
      <img className="chatB-divider-icon5" alt="" src={divider3} />
    </div>
  );
};

export { CardHeader };


