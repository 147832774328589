import React from 'react';
import "./SlideOutMenu.css"

export const SlideOutMenu = ({ isVisible, onClose }) => {
  return (
    <div className={`slide-out-component-slide-out-menu ${isVisible ? 'open' : ''}`}>
      <main className="slide-out-component-panel">
        <section className="slide-out-component-slide-out-menu-header-parent">
          <header className="slide-out-component-slide-out-menu-header">
            <div className="slide-out-component-content71">
              <img
                className="slide-out-component-featured-icon2"
                loading="lazy"
                alt=""
                src="./public/featured-icon11.svg"
              />

              <div className="slide-out-component-text-and-supporting-text26">
                <a className="slide-out-component-title9"></a>
                <div className="slide-out-component-supporting-text91">
                  This will appear in your funnel
                </div>
              </div>
            </div>
            <div className="slide-out-component-button-close-x6" onClick={onClose}>
              <img
                className="slide-out-component-mail-01-icon"
                loading="lazy"
                alt=""
                src="./public/xclose1.svg"
              />
            </div>
          </header>
          <div className="slide-out-component-section-container">
            <div className="slide-out-component-section2">
              <div className="slide-out-component-input-field">
                <div className="slide-out-component-input-field">
                  <div className="slide-out-component-label9">Full Name</div>
                  <div className="slide-out-component-input6">
                    <input
                      className="slide-out-component-content72"
                      placeholder="Olivia Rhye"
                      type="text"
                    />

                    <img
                      className="slide-out-component-help-icon2"
                      alt=""
                      src="./public/help-icon.svg"
                    />
                  </div>
                </div>
                <div className="slide-out-component-hint-text6">This is a hint text to help user.</div>
              </div>
              <div className="slide-out-component-form">
                <div className="slide-out-component-input-field1">
                  <div className="slide-out-component-input-field1">
                    <div className="slide-out-component-label10">Email address</div>
                    <div className="slide-out-component-input7">
                      <div className="slide-out-component-content73">
                        <img
                          className="slide-out-component-mail-01-icon"
                          alt=""
                          src="./public/mail01.svg"
                        />

                        <input
                          className="slide-out-component-text274"
                          placeholder="olivia.rhye@refurbish-med.com"
                          type="text"
                        />
                      </div>
                      <img
                        className="slide-out-component-help-icon3"
                        alt=""
                        src="./public/help-icon.svg"
                      />
                    </div>
                  </div>
                  <div className="slide-out-component-hint-text6">
                    This is a hint text to help user.
                  </div>
                </div>
              </div>
              <div className="slide-out-component-input-field">
                <div className="slide-out-component-input-field">
                  <div className="slide-out-component-label11">Company Name</div>
                  <div className="slide-out-component-input6">
                    <input
                      className="slide-out-component-content72"
                      placeholder="Refurbish Med Systems Inc."
                      type="text"
                    />

                    <img
                      className="slide-out-component-help-icon2"
                      alt=""
                      src="./public/help-icon.svg"
                    />
                  </div>
                </div>
                <div className="slide-out-component-hint-text6">This is a hint text to help user.</div>
              </div>
              <div className="slide-out-component-input-field">
                <div className="slide-out-component-input-field">
                  <div className="slide-out-component-label12">Work Title</div>
                  <div className="slide-out-component-input6">
                    <input
                      className="slide-out-component-content72"
                      placeholder="Sales Manager"
                      type="text"
                    />

                    <img
                      className="slide-out-component-help-icon2"
                      alt=""
                      src="./public/help-icon.svg"
                    />
                  </div>
                </div>
                <div className="slide-out-component-hint-text6">This is a hint text to help user.</div>
              </div>
            </div>
          </div>
          <div className="slide-out-component-divider-wrapper">
            <div className="slide-out-component-divider12"></div>
          </div>
        </section>
        <footer className="slide-out-component-footer2">
          <div className="slide-out-component-content76">
            <div className="slide-out-component-actions19">
              <button className="slide-out-component-buttonsbutton49">
                <img
                  className="slide-out-component-placeholder-icon91"
                  alt=""
                  src="./public/placeholder2.svg"
                />

                <div className="slide-out-component-text-padding51">
                  <div className="slide-out-component-text275">Cancel</div>
                </div>
                <img
                  className="slide-out-component-placeholder-icon91"
                  alt=""
                  src="./public/placeholder2.svg"
                />
              </button>
              <button className="slide-out-component-buttonsbutton50">
                <img
                  className="slide-out-component-placeholder-icon91"
                  alt=""
                  src="./public/placeholder3.svg"
                />

                <div className="slide-out-component-text-padding51">
                  <div className="slide-out-component-text276">Confirm</div>
                </div>
                <img
                  className="slide-out-component-placeholder-icon91"
                  alt=""
                  src="./public/placeholder3.svg"
                />
              </button>
            </div>
          </div>
        </footer>
      </main>
    </div>
  )
}

