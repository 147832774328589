import React from 'react';
import "./FinishedPassword.css";
import { Link } from 'react-router-dom';
import { BackToLoginButton } from '../../components/BackToLoginButton/BackToLoginButton';
import gerator_logo from '../../icons/Gerator/reset_password/gerator04-1@2x.png';
import placeholder from '../../icons/Gerator/reset_password/placeholder.svg';
import placeholder1 from '../../icons/Gerator/reset_password/placeholder1.svg';
import feature_icon3 from '../../icons/Gerator/reset_password/featured-icon3.svg';
import reset_nav from '../../icons/Gerator/reset_password/_Nav menu button.svg';
import { ResetPasswordDropdown } from '../../components/Dropdown/Dropdown';
import placeholder2 from '../../icons/Gerator/reset_password/featured-icon2.svg';
import arrow_left from '../../icons/Gerator/reset_password/arrowleft1.svg';
import mail_icon from '../../icons/Gerator/reset_password/mail01.svg';
import { useState } from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';

export const FinishedResetPassword = () => {
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible);
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <div className="reset-password-finished-password-reset-password-finished-des">
      <header className="reset-password-finished-password-reset-password-finished-des-inner">
        <div className="reset-password-finished-password-frame-container">
          <div className="reset-password-finished-password-logo-frame">
            <div className="reset-password-finished-password-logo3">
              <img
                className="reset-password-finished-password-gerator-04-1-icon3"
                alt=""
                src={gerator_logo}
              />
            </div>
          </div>
          <div className="reset-password-finished-password-navigation-actions3">
            <button className="reset-password-finished-password-buttonsbutton13">
              <img
                className="reset-password-finished-password-placeholder-icon22"
                alt=""
                src={placeholder}
              />

              <div className="reset-password-finished-password-text-padding9">
                <a href='/sign-in' className="reset-password-finished-password-text12">Log in</a>
              </div>
              <img
                className="reset-password-finished-password-placeholder-icon22"
                alt=""
                src={placeholder}
              />
            </button>
            <button className="reset-password-finished-password-buttonsbutton14">
              <img
                className="reset-password-finished-password-placeholder-icon22"
                alt=""
                src={placeholder1}
              />

              <div className="reset-password-finished-password-text-padding9">
                <a href='/sign-up' className="reset-password-finished-password-text13">Sign up</a>
              </div>
              <img
                className="reset-password-finished-password-placeholder-icon22"
                alt=""
                src={placeholder1}
              />
            </button>
          </div>
          <div className="reset-password-finished-password-reset-password-finished-hamburger-menu" ref={dropdownRef} onClick={toggleDropdown}>
            {isDropdownVisible && <ResetPasswordDropdown />}
            <img

              className="reset-password-finished-password-hamburger-icon"
              src={reset_nav}
              alt="" />

          </div>
        </div>
      </header>
      <main className="reset-password-finished-password-log-in3">
        <section className="reset-password-finished-password-container6">
          <div className="reset-password-finished-password-container7">
            <div className="reset-password-finished-password-content8">
              <div className="reset-password-finished-password-header3">
                <img
                  className="reset-password-finished-password-featured-icon3"
                  loading="lazy"
                  alt=""
                  src={feature_icon3}
                />

                <div className="reset-password-finished-password-text-and-supporting-text3">
                  <div className="reset-password-finished-password-title2">Password reset</div>
                  <div className="reset-password-finished-password-supporting-text3">
                    Your password has been successfully reset. Click below to
                    log in magically.
                  </div>
                </div>
              </div>
              <button className="reset-password-finished-password-buttonsbutton15">
                <img
                  className="reset-password-finished-password-placeholder-icon22"
                  alt=""
                  src={arrow_left}
                />

                <Link to={"/sign-in"} style={{ textDecoration: 'none' }}>
                  <div className="reset-password-finished-password-text-padding9">
                    <div className="reset-password-finished-password-text14">Continue</div>
                  </div>
                </Link>
                <img
                  className="reset-password-finished-password-placeholder-icon22"
                  alt=""
                  src={placeholder2}
                />
              </button>

              <Link to={"/sign-in"} style={{ textDecoration: 'none' }}>
                <BackToLoginButton />
              </Link>

            </div>
          </div>
        </section>



      </main>

      <footer className='reset-password-finished-password-footer'>

        <div className="reset-password-finished-password-text16">© Gerator Asia LLP. All rights reserved.</div>
        <img
          className="reset-password-finished-password-mail-01-icon3"
          loading="lazy"
          alt=""
          src={mail_icon}
        />

        <div className="reset-password-finished-password-helpcentergeratorcom3">helpcenter@gerator.com</div>

      </footer>
    </div>
  )
}

