import React from 'react';
import "./SortByFilterComponent.css";
import divider from '../../icons/Gerator/dashboard/divider.svg';

export const SortByFilterComponent = ({ onOptionSelect }) => {
  const handleOptionClick = (option) => {
    onOptionSelect(option); // Call the function from the parent with the selected option
  };
  return (
    <div className="sort-by-filter-dropdown-sort-by-filter-all-options-fixed">
      {/* <div className="sort-by-filter-dropdown-dropdown-list-header">
        <div className="sort-by-filter-dropdown-avatar-label-group">


        </div>
      </div> */}
      <section className="sort-by-filter-dropdown-menu-items">
        <div className="sort-by-filter-dropdown-dropdown-list-item" onClick={() => handleOptionClick({ label: 'Date (newest to oldest)', value: 'date_desc' })}>
          <div className="sort-by-filter-dropdown-content48">
            <div className="sort-by-filter-dropdown-icon-and-text">
              <div className="sort-by-filter-dropdown-text247">Date (newest to oldest)</div>
            </div>
          </div>
        </div>
        <div className="sort-by-filter-dropdown-dropdown-list-item" onClick={() => handleOptionClick({ label: 'Date (oldest to newest)', value: 'date_asc' },)}>
          <div className="sort-by-filter-dropdown-content48">
            <div className="sort-by-filter-dropdown-icon-and-text">
              <div className="sort-by-filter-dropdown-text247">Date (oldest to newest)</div>
            </div>
          </div>
        </div>
        <div className="sort-by-filter-dropdown-dropdown-list-item" onClick={() => handleOptionClick({ label: 'Price (low to high)', value: 'price_asc' },)}>
          <div className="sort-by-filter-dropdown-content48">
            <div className="sort-by-filter-dropdown-icon-and-text">
              <div className="sort-by-filter-dropdown-text247">Price (low to high)</div>
            </div>
          </div>
        </div>
        <div className="sort-by-filter-dropdown-dropdown-list-item" onClick={() => handleOptionClick({ label: 'Price (high to low)', value: 'price_desc' })}>
          <div className="sort-by-filter-dropdown-content48">
            <div className="sort-by-filter-dropdown-icon-and-text">
              <div className="sort-by-filter-dropdown-text247">Price (high to low)</div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

