import React from 'react';
import "./HeaderForms.css"
import homeline_1 from '../../../icons/Gerator/add_form/homeline-1.svg';
import chevronright from '../../../icons/Gerator/add_form/chevronright.svg';
import placeholder2 from '../../../icons/Gerator/add_form/placeholder2.svg';
import pluscircle from '../../../icons/Gerator/add_form/pluscircle.svg';
import chevrondown_1 from '../../../icons/Gerator/add_form/chevrondown-1.svg';
import placeholder3 from '../../../icons/Gerator/add_form/placeholder3.svg';
import divider1 from '../../../icons/Gerator/add_form/divider1.svg';
import upload04 from '../../../icons/Gerator/add_form/upload04.svg';





export const HeaderForms = ({text,text1,text2}) => {
  return (
    <div className="add-form-header-header-section">
      <div className="add-form-header-container">
        <div className="add-form-header-page-header">
          <div className="add-form-header-breadcrumbs">
            <div className="add-form-header-tabs">
              <div className="add-form-header-breadcrumb-button-base">
                <img
                  className="add-form-header-search-lg-icon"
                  alt=""
                  src={homeline_1}
                />
              </div>
              <img
                className="add-form-header-chevron-right-icon"
                alt=""
                src={chevronright}
              />

              <div className="add-form-header-breadcrumb-button-base1">
                <a className="add-form-header-text17">Dashboard</a>
              </div>
              <img
                className="add-form-header-chevron-right-icon"
                alt=""
                src={chevronright}
              />

              <div className="add-form-header-breadcrumb-button-base2">
                <div className="add-form-header-text18">...</div>
              </div>
              <img
                className="add-form-header-chevron-right-icon2"
                alt=""
                src={chevronright}
              />

              <div className="add-form-header-breadcrumb-button-base3">
                <div className="add-form-header-text19">Another link</div>
              </div>
              <img
                className="add-form-header-chevron-right-icon2"
                alt=""
                src={chevronright}
              />

              <div className="add-form-header-breadcrumb-button-base4">
                <div className="add-form-header-text20">{text}</div>
              </div>
            </div>
          </div>
          <div className="add-form-header-content6">
            <div className="add-form-header-text-and-supporting-text4">
              <h2 className="add-form-header-title">{text1}</h2>
              <div className="add-form-header-supporting-text12">
                {text2}
              </div>
            </div>
            {/* <div className="add-form-header-actions">
                          <div className="add-form-header-button">
                            <img
                              className="add-form-header-placeholder-icon"
                              alt=""
                              src="/add_form/placeholder.svg"
                            />

                            <div className="add-form-header-text-padding">
                              <div className="add-form-header-step-three-title">Tertiary</div>
                            </div>
                            <img
                              className="add-form-header-placeholder-icon"
                              alt=""
                              src="/add_form/placeholder.svg"
                            />
                          </div>
                          <div className="add-form-header-button1">
                            <img
                              className="add-form-header-placeholder-icon"
                              alt=""
                              src="/add_form/placeholder1.svg"
                            />

                            <div className="add-form-header-text-padding">
                              <div className="add-form-header-step-three-title">Secondary</div>
                            </div>
                            <img
                              className="add-form-header-placeholder-icon"
                              alt=""
                              src="/add_form/placeholder1.svg"
                            />
                          </div>
                          <div className="add-form-header-button2">
                            <img
                              className="add-form-header-placeholder-icon"
                              alt=""
                              src={placeholder2}
                            />

                            <div className="add-form-header-text-padding">
                              <div className="add-form-header-step-three-title">Secondary</div>
                            </div>
                            <img
                              className="add-form-header-placeholder-icon"
                              alt=""
                              src={placeholder2}
                            />
                          </div>
                          <div className="add-form-header-button3">
                            <img
                              className="add-form-header-placeholder-icon"
                              alt=""
                              src="/add_form/placeholder3.svg"
                            />

                            <div className="add-form-header-text-padding">
                              <div className="add-form-header-step-three-title">Primary</div>
                            </div>
                            <img
                              className="add-form-header-placeholder-icon"
                              alt=""
                              src="/add_form/placeholder3.svg"
                            />
                          </div>
                        </div> */}
            <div className="add-form-header-input-dropdown">
              {/* <div className="add-form-header-hint-text">
                            This is a hint text to help user.
                          </div> */}
              <div className="add-form-header-content">
                {/* <div className="add-form-header-button4">
                              <img
                                className="add-form-header-placeholder-icon"
                                alt=""
                                src="/add_form/placeholder.svg"
                              />

                              <div className="add-form-header-text-padding">
                                <div className="add-form-header-step-three-title">Tertiary</div>
                              </div>
                              <img
                                className="add-form-header-placeholder-icon"
                                alt=""
                                src="/add_form/placeholder.svg"
                              />
                            </div> */}
                {/* <div className="add-form-header-button5">
                              <img
                                className="add-form-header-placeholder-icon"
                                alt=""
                                src="/add_form/placeholder1.svg"
                              />

                              <div className="add-form-header-text-padding">
                                <div className="add-form-header-step-three-title">Secondary</div>
                              </div>
                              <img
                                className="add-form-header-placeholder-icon"
                                alt=""
                                src="/add_form/placeholder1.svg"
                              />
                            </div> */}
                <button className="add-form-header-button6">
                  <img
                    className="add-form-header-upload-04-icon"
                    alt=""
                    src={upload04}
                  />

                  <div className="add-form-header-text-padding6">
                    <div className="add-form-header-text27">Import</div>
                  </div>
                  <img
                    className="add-form-header-placeholder-icon"
                    alt=""
                    src={placeholder2}
                  />
                </button>
                <button className="add-form-header-button7">
                  <img
                    className="add-form-header-upload-04-icon"
                    alt=""
                    src={pluscircle}
                  />

                  <div className="add-form-header-text-padding6">
                    <div className="add-form-header-text28">Add listing</div>
                  </div>
                  <img
                    className="add-form-header-upload-04-icon"
                    alt=""
                    src={chevrondown_1}
                  />

                  <img
                    className="add-form-header-placeholder-icon"
                    alt=""
                    src={placeholder3}
                  />
                </button>
              </div>
            </div>
          </div>
          <img
            className="add-form-header-divider-icon1"
            alt=""
            src={divider1}
          />
        </div>
      </div>
    </div>
  )
}

