import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  softwareCategory: '',
  softwareValueProposition: '',
  softwareType: '',
  installationLocation: '',
  rangeMin: '',
  rangeMax: '',
  unitOfMeasureSoftware: '',
  clinicalApplications: '',
  purposeUse: '',
  deviceCategory: '',
  physicalLocation: '',

  softwareFeaturesHighlights: '',
  softwareBenefitsHighlights: '',
  listParentSoftware: '',
  featureImage: '',
  featureImageObject: {},
  gallery: "",
  galleryImageObject: [],
  video: '',
  linkVideo: '',
  location: '',
  serviceAndSupportReach: '',
  
  softwareFeatures: '',
  softwareBenefits: '',
  systemRequirements: '',
  additionalInformation: '',
  document: '',
  documentFileObject: {}
}





const addSofwareSlice = createSlice({
  name: "addSofware",
  initialState,
  reducers: {
    updateField(state, action) {
      const { field, value } = action.payload
      if (value !== undefined) {
        state[field] = value;
      }
    },
    clearForm() {
      return initialState;
    }
  },
});

export const { updateField, clearForm } = addSofwareSlice.actions

export default addSofwareSlice.reducer

