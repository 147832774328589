import React from 'react';
import "./ThreeDotsListingComponent.css";
import divider from '../../icons/Gerator/dashboard/divider.svg';
import { Link } from 'react-router-dom';

export const ThreeDotsListingComponent = ({ url }) => {
  // const handleOptionClick = (option) => {
  //   onOptionSelect(option); // Call the function from the parent with the selected option
  // };
  return (
    <div className="three-dots-filter-dropdown-three-dots-filter-all-options-fixed">
      <section className="three-dots-filter-dropdown-menu-items">
        <div className="three-dots-filter-dropdown-dropdown-list-item" >
          <div className="three-dots-filter-dropdown-content48">
            <div className="three-dots-filter-dropdown-icon-and-text">
              <Link className="three-dots-filter-dropdown-text247" to={`/edit-${url}`}>Edit</Link>
            </div>
          </div>
        </div>
        <div className="three-dots-filter-dropdown-dropdown-list-item" >
          <div className="three-dots-filter-dropdown-content48">
            <div className="three-dots-filter-dropdown-icon-and-text">
              <Link className="three-dots-filter-dropdown-text247" to={`/archive-${url}`}>Archive</Link>
            </div>
          </div>
        </div>
        <div className="three-dots-filter-dropdown-dropdown-list-item" >
          <div className="three-dots-filter-dropdown-content48">
            <div className="three-dots-filter-dropdown-icon-and-text">
              <Link className="three-dots-filter-dropdown-text247" to={`/delete-${url}`}>Delete</Link>
            </div>
          </div>
        </div>
        {/* <div className="three-dots-filter-dropdown-dropdown-list-item" >
          <div className="three-dots-filter-dropdown-content48">
            <div className="three-dots-filter-dropdown-icon-and-text">
              <div className="three-dots-filter-dropdown-text247">Price (high to low)</div>
            </div>
          </div>
        </div> */}
      </section>
    </div>
  )
}

