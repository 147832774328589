import React from 'react';
import "./PlanAndBillingSuccessComponent.css";
import featured_icon from "../../icons/Gerator/success_component/featured-icon.svg";
import arrowleft from "../../icons/Gerator/success_component/arrowleft.svg";
export const PlanAndBillingSuccessComponent = ({ planName }) => {
  return (
    <div className="Plan-billing-success-component-email-popup">
      {/* <div className="Plan-billing-success-component-button-close-x1">
      <img
        className="Plan-billing-success-component-x-close-icon3"
        loading="lazy"
        alt=""
        src="./public/xclose.svg"
      />
    </div> */}
      <section className="Plan-billing-success-component-frame-section">
        <div className="Plan-billing-success-component-frame-child5"></div>
        <div className="Plan-billing-success-component-container7">
          <div className="Plan-billing-success-component-container8">
            <div className="Plan-billing-success-component-content21">
              <div className="Plan-billing-success-component-container9">
                <div className="Plan-billing-success-component-content22">
                  <div className="Plan-billing-success-component-header1">
                    <img
                      className="Plan-billing-success-component-featured-icon1"
                      loading="lazy"
                      alt=""
                      src={featured_icon}
                    />

                    <div className="Plan-billing-success-component-text-and-supporting-text3">
                      <h1 className="Plan-billing-success-component-title1">Confirmation</h1>
                      <div className="Plan-billing-success-component-supporting-text5">
                        Thank you for subscribing to Gerator.
                        <br /><br />
                        Your <span className="Plan-billing-success-component-span">{planName || "No Plan"} </span>membership is now active.
                      </div>
                    </div>
                  </div>
                  <button className="Plan-billing-success-component-buttonsbutton10">
                    {/* <img
                    className="Plan-billing-success-component-placeholder-icon25"
                    alt=""
                    src="./public/placeholder4.svg"
                  /> */}

                    <div className="Plan-billing-success-component-text-padding15">
                      <a href='/dashboard/settings/plan' className="Plan-billing-success-component-text76">View Your Membership Account →</a>
                    </div>
                    {/* <img
                    className="Plan-billing-success-component-placeholder-icon25"
                    alt=""
                    src="./public/placeholder4.svg"
                  /> */}
                  </button>
                  <div className="Plan-billing-success-component-row1">
                    <div className="Plan-billing-success-component-secondary-label">Invoice is on its way to your inbox</div>
                    <div className="Plan-billing-success-component-buttonsbutton11">
                      {/* <img
                      className="Plan-billing-success-component-placeholder-icon27"
                      alt=""
                      src="./public/placeholder1.svg"
                    />

                    <div className="Plan-billing-success-component-text77">Click to resend</div>
                    <img
                      className="Plan-billing-success-component-placeholder-icon27"
                      alt=""
                      src="./public/placeholder1.svg"
                    /> */}
                    </div>
                  </div>
                  <div className="Plan-billing-success-component-buttonsbutton12">
                    {/* <img
                    className="Plan-billing-success-component-arrow-left-icon1"
                    loading="lazy"
                    alt=""
                    src={arrowleft}
                  /> */}

                    <div className="Plan-billing-success-component-back-label">Need help?</div>
                    {/* <img
                    className="Plan-billing-success-component-placeholder-icon27"
                    alt=""
                    src="./public/placeholder.svg"
                  /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
