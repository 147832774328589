import React, { useEffect, useRef, useState } from 'react';
import "./Beacon.css"
import { Sidebar } from '../../components/Sidebar/Sidebar';
import { Navbar1 } from '../../components/Navbar1/Navbar1';
import { Footer } from '../../components/Footer/Footer';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { Loader } from '../../components/Loader';
import { decryptData, decryptErrorData } from '../../helper/AESHelper';
import { auth, app, getAuth, onAuthStateChanged, sendPasswordResetEmail, fetchSignInMethodsForEmail } from "../../screens/Login/FirebaseClient";
import divider from '../../icons/Gerator/dashboard/divider.svg';
import divider1 from '../../icons/Gerator/dashboard/divider1.svg';
import homeline1 from '../../icons/Gerator/dashboard/homeline1.svg';
import chevronright from '../../icons/Gerator/dashboard/chevronright.svg';
import chart_data2x from '../../icons/Gerator/dashboard/-chart-data@2x.png';
import arrowup from '../../icons/Gerator/dashboard/arrowup.svg';
import arrowdown from '../../icons/Gerator/dashboard/arrowdown.svg';
import chart_mini2x from '../../icons/Gerator/dashboard/-chart-mini@2x.png';
import dotsvertical from '../../icons/Gerator/dashboard/dotsvertical.svg';
import chart_mini1_2x from '../../icons/Gerator/dashboard/-chart-mini1@2x.png';
import searchlg from '../../icons/Gerator/dashboard/searchlg.svg';
import _1_2x from '../../icons/Gerator/dashboard/8595f4b711e503bc72fe396e5043e0c2-1@2x.png';
import placeholder from '../../icons/Gerator/dashboard/placeholder.svg';
import placeholder1 from '../../icons/Gerator/dashboard/placeholder1.svg';
import placeholder2 from '../../icons/Gerator/dashboard/placeholder2.svg';
import placeholder3 from '../../icons/Gerator/dashboard/placeholder3.svg';
import upload04 from '../../icons/Gerator/dashboard/upload04.svg';
import pluscircle from '../../icons/Gerator/dashboard/pluscircle.svg';
import chevrondown1 from '../../icons/Gerator/dashboard/chevrondown1.svg';
import calendar from '../../icons/Gerator/dashboard/calendar.svg';
import filterlines from '../../icons/Gerator/dashboard/filterlines.svg';
import arrowleft from '../../icons/Gerator/dashboard/arrowleft.svg';
import arrowright from '../../icons/Gerator/dashboard/arrowright.svg';
import markerpin02 from '../../icons/Gerator/dashboard/markerpin02.svg';
import divider2 from '../../icons/Gerator/dashboard/divider2.svg';
import calendar1 from '../../icons/Gerator/dashboard/calendar1.svg';
import arrowsquareupright1 from '../../icons/Gerator/dashboard/arrowsquareupright1.svg';
import arrowsdown from '../../icons/Gerator/dashboard/arrowsdown.svg';
import chart_mini2_2x from '../../icons/Gerator/dashboard/-chart-mini2@2x.png';
import content_elements_2x from '../../icons/Gerator/dashboard/content-elements@2x.png';
import dot1 from '../../icons/Gerator/dashboard/-dot1.svg';
import radar_1_1_2x from '../../icons/Gerator/dashboard/radar-1-1@2x.png';
import deal_2_1_2x from '../../icons/Gerator/dashboard/deal-2-1@2x.png';
import magnifier_1_2x from '../../icons/Gerator/dashboard/magnifier-1@2x.png';
import group_58_2x from '../../icons/Gerator/dashboard/group-58@2x.png';
import eye from '../../icons/Gerator/dashboard/eye.svg';
import icon1 from '../../icons/Gerator/dashboard/icon1.svg';
import lock04 from '../../icons/Gerator/dashboard/lock04.svg';
import lockunlocked04 from '../../icons/Gerator/dashboard/lockunlocked04.svg';
import arrowsquareupright from '../../icons/Gerator/dashboard/arrowsquareupright.svg';
import tooltip from '../../icons/Gerator/dashboard/tooltip.svg';
import group_212 from '../../icons/Gerator/dashboard/group-212.svg';
import trash01 from '../../icons/Gerator/dashboard/trash01.svg';
import icon2 from '../../icons/Gerator/dashboard/icon2.svg';
import messagechatsquare from '../../icons/Gerator/dashboard/messagechatsquare.svg';
import bifocal_optometry_measurement_device_2_2x from '../../icons/Gerator/dashboard/bifocal-optometry-measurement-device-2@2x.png';
import uploadcloud02 from '../../icons/Gerator/dashboard/uploadcloud02.svg';
import plus from '../../icons/Gerator/dashboard/plus.svg';
import divider3 from '../../icons/Gerator/dashboard/divider3.svg';
import help_icon from '../../icons/Gerator/dashboard/help-icon.svg';
import f4b711e503bc72fe396e5043e0c2_11_2x from '../../icons/Gerator/dashboard/8595f4b711e503bc72fe396e5043e0c2-11@2x.png';

import featured_icon from '../../icons/Gerator/dashboard/featured-icon.svg';
import arrowdown1 from '../../icons/Gerator/dashboard/arrowdown1.svg';


import xclose from '../../icons/Gerator/dashboard/xclose.svg';
import { SideBarSubNormal } from '../../components/SideBars-Sub/SideBarSubNormal/SideBarSubNormal';
import { BeaconDeviceComponent } from '../../components/Beacon/BeaconDeviceComponent/BeaconDeviceComponent';
import { DashboardSpareComponent } from '../../components/Dashboard/DashboardSpareComponent/DashboardSpareComponent';
import { DashboardServiceComponent } from '../../components/Dashboard/DashboardServiceComponent/DashboardServiceComponent';
import { DashboardSoftwareComponent } from '../../components/Dashboard/DashboardSoftwareComponent/DashboardSoftwareComponent';
import { DashboardJobComponent } from '../../components/Dashboard/DashboardJobComponent/DashboardJobComponent';
import { Helmet } from 'react-helmet-async';
import { BeaconSpareComponent } from '../../components/Beacon/BeaconSpareComponent/BeaconSpareComponent';
import { BeaconServiceComponent } from '../../components/Beacon/BeaconServiceComponent/BeaconServiceComponent';
import { BeaconSoftwareComponent } from '../../components/Beacon/BeaconSoftwareComponent/BeaconSoftwareComponent';

// const auth = getAuth(app);


export const Beacon = () => {
  const [currentUser, setCurrentUser] = useState("");
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState('device');

  const renderContent = () => {
    switch (activeTab) {
      case 'device':
        return <BeaconDeviceComponent />;
      case 'spare':
        return <BeaconSpareComponent/>;
      case 'service':
        return <BeaconServiceComponent />;
      case 'software':
        return <BeaconSoftwareComponent/>;
     
      default:
        return null;
    }
  };

  // const [triggerGetUser, { data, error, isLoading, isError }] = useLazyGetUserQuery();

  useEffect(() => {
    // const auth = getAuth();

    // Listen for authentication state change
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is authenticated
        setCurrentUser(user);
      } else {
        // User is not authenticated, redirect to login
        navigate('/sign-in', { state: { message: 'Please log in to continue.' } });
      }
    });

    return () => unsubscribe(); // Cleanup listener on component unmount
  }, [navigate]);




  if (currentUser) {
    return (
      <div className="beacon-beacon-frame-layout">
        <Helmet>
          <title>Beacon</title>
        </Helmet>
        <div className="beacon-beacon-frame-layout-child"></div>

        <Navbar1 userData={currentUser} navigate={navigate} />

        <div className="beacon-all">
          <img className="beacon-divider-icon" alt="" src={divider} />

          <div className="beacon-all-content">

            <Sidebar>
              <SideBarSubNormal userData={currentUser} />
            </Sidebar>

            <div className="beacon-frame-parent">
              <div className="beacon-main-wrapper">
                <div className="beacon-main beacon-main-custom">
                  <div className="beacon-header-section">
                    <div className="beacon-container">
                      <div className="beacon-page-header">
                        <div className="beacon-breadcrumbs">
                          <div className="beacon-tabs">
                            <div className="beacon-breadcrumb-button-base">
                              <img
                                className="beacon-search-lg-icon"
                                alt=""
                                src={homeline1}
                              />
                            </div>
                            <img
                              className="beacon-chevron-right-icon"
                              alt=""
                              src={chevronright}
                            />

                            <div className="beacon-breadcrumb-button-base1">
                              <div className="beacon-location">Radar</div>
                            </div>
                            {/* <img
                              className="beacon-chevron-right-icon"
                              alt=""
                              src={chevronright}
                            /> */}

                            {/* <div className="beacon-breadcrumb-button-base2">
                              <div className="beacon-text20">...</div>
                            </div>
                            <img
                              className="beacon-chevron-right-icon2"
                              alt=""
                              src={chevronright}
                            />

                            <div className="beacon-breadcrumb-button-base3">
                              <div className="beacon-text21">Another link</div>
                            </div>
                            <img
                              className="beacon-chevron-right-icon2"
                              alt=""
                              src={chevronright}
                            />

                            <div className="beacon-breadcrumb-button-base4">
                              <div className="beacon-text22">Overview</div>
                            </div> */}
                          </div>
                        </div>
                        <div className="beacon-content13">
                          <div className="beacon-text-and-supporting-text">
                            <div className="beacon-heading"> {currentUser.displayName},let's look out for things you want to buy, or get done.</div>
                            {/* <div className="beacon-supporting-text1">
                              You are on Krypton Plan, expiring in 23 days.
                            </div> */}
                          </div>
                        </div>
                        <img
                          className="beacon-divider-icon1"
                          alt=""
                          src={divider1}
                        />
                      </div>
                      {/* <div className="beacon-tabs-and-filters">
                        <div className="beacon-button-group">
                          <div className="beacon-button-group-base">
                            <div className="beacon-text31">12 months</div>
                          </div>
                          <div className="beacon-button-group-base1">
                            <div className="beacon-text32">30 days</div>
                          </div>
                          <div className="beacon-button-group-base2">
                            <div className="beacon-text29">7 days</div>
                          </div>
                          <div className="beacon-button-group-base3">
                            <div className="beacon-text34">Text</div>
                          </div>
                          <div className="beacon-button-group-base4">
                            <div className="beacon-text34">Text</div>
                          </div>
                          <div className="beacon-button-group-base5">
                            <div className="beacon-text34">Text</div>
                          </div>
                          <div className="beacon-button-group-base6">
                            <div className="beacon-text34">Text</div>
                          </div>
                          <div className="beacon-button-group-base7">
                            <div className="beacon-text34">Text</div>
                          </div>
                          <div className="beacon-button-group-base8">
                            <div className="beacon-text39">24 hours</div>
                          </div>
                        </div>
                        <div className="beacon-actions2">
                          <div className="beacon-date-picker-dropdown">
                            <div className="beacon-buttonsbutton1">
                              <img
                                className="beacon-upload-04-icon"
                                alt=""
                                src={calendar}
                              />

                              <div className="beacon-text-padding6">
                                <div className="beacon-filter-date">Select dates</div>
                              </div>
                              <img
                                className="beacon-placeholder-icon"
                                alt=""
                                src={placeholder2}
                              />
                            </div>
                          </div>
                          <div className="beacon-buttonsbutton2">
                            <img
                              className="beacon-upload-04-icon"
                              alt=""
                              src={filterlines}
                            />

                            <div className="beacon-text-padding6">
                              <div className="beacon-filter-options">Filters</div>
                            </div>
                            <img
                              className="beacon-placeholder-icon"
                              alt=""
                              src={placeholder2}
                            />
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                  {/* <div className="beacon-line-and-bar-chart-wrapper">
                    <div className="beacon-line-and-bar-chart">
                      <div className="beacon-line-and-bar-chart">
                        <div className="beacon-content14">
                          <div className="beacon-legend">
                            <div className="beacon-legend-series">
                              <div className="beacon-legend-color">
                                <div className="beacon-color"></div>
                              </div>
                              <div className="beacon-series-1">2022</div>
                            </div>
                            <div className="beacon-legend-series">
                              <div className="beacon-legend-color">
                                <div className="beacon-color1"></div>
                              </div>
                              <div className="beacon-series-2">2021</div>
                            </div>
                            <div className="beacon-legend-series">
                              <div className="beacon-legend-color">
                                <div className="beacon-color2"></div>
                              </div>
                              <div className="beacon-series-1">2020</div>
                            </div>
                            <div className="beacon-legend-series3">
                              <div className="beacon-color3"></div>
                              <div className="beacon-series-4">Series 4</div>
                            </div>
                            <div className="beacon-legend-series4">
                              <div className="beacon-color4"></div>
                              <div className="beacon-series-5">Series 5</div>
                            </div>
                            <div className="beacon-legend-series3">
                              <div className="beacon-color5"></div>
                              <div className="beacon-series-4">Series 6</div>
                            </div>
                          </div>
                          <div className="beacon-y-axis">
                            <div className="beacon-y-axis-line">
                              <div className="beacon-divider"></div>
                            </div>
                            <div className="beacon-y-axis-line1">
                              <div className="beacon-divider"></div>
                            </div>
                            <div className="beacon-y-axis-line1">
                              <div className="beacon-divider"></div>
                            </div>
                            <div className="beacon-y-axis-line1">
                              <div className="beacon-divider"></div>
                            </div>
                            <div className="beacon-y-axis-line1">
                              <div className="beacon-divider"></div>
                            </div>
                            <div className="beacon-y-axis-line1">
                              <div className="beacon-divider"></div>
                            </div>
                            <div className="beacon-y-axis-line6">
                              <div className="beacon-divider6"></div>
                            </div>
                            <div className="beacon-y-axis-line6">
                              <div className="beacon-divider6"></div>
                            </div>
                            <div className="beacon-y-axis-line6">
                              <div className="beacon-divider6"></div>
                            </div>
                            <div className="beacon-y-axis-line6">
                              <div className="beacon-divider6"></div>
                            </div>
                            <div className="beacon-y-axis-line6">
                              <div className="beacon-divider6"></div>
                            </div>
                            <div className="beacon-y-axis-line6">
                              <div className="beacon-divider6"></div>
                            </div>
                          </div>
                          <div className="beacon-x-axis">
                            <div className="beacon-jan">Jan</div>
                            <div className="beacon-jan">Feb</div>
                            <div className="beacon-mar">Mar</div>
                            <div className="beacon-jan">Apr</div>
                            <div className="beacon-may">May</div>
                            <div className="beacon-jun">Jun</div>
                            <div className="beacon-jul">Jul</div>
                            <div className="beacon-mar">Aug</div>
                            <div className="beacon-mar">Sep</div>
                            <div className="beacon-jan">Oct</div>
                            <div className="beacon-mar">Nov</div>
                            <div className="beacon-mar">Dec</div>
                          </div>
                          <img
                            className="beacon-chart-data-icon"
                            alt=""
                            src={chart_data2x}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="beacon-section">
                    <div className="beacon-container1">
                      <div className="beacon-metric-group">
                        <div className="beacon-metric-item">
                          <div className="beacon-heading1">Today’s revenue</div>
                          <div className="beacon-number-and-chart">
                            <div className="beacon-number-and-badge">
                              <div className="beacon-number">$1,280</div>
                              <div className="beacon-change-and-text">
                                <div className="beacon-change">
                                  <img
                                    className="beacon-upload-04-icon"
                                    alt=""
                                    src={arrowup}
                                  />

                                  <div className="beacon-change1">15%</div>
                                </div>
                                <div className="beacon-change-duration">last mth</div>
                              </div>
                            </div>
                            <img
                              className="beacon-chart-mini-icon"
                              alt=""
                              src={chart_mini2x}
                            />
                          </div>
                          <div className="beacon-dropdown">
                            <img
                              className="beacon-dots-vertical-icon"
                              alt=""
                              src={dotsvertical}
                            />
                          </div>
                        </div>
                        <div className="beacon-metric-item">
                          <div className="beacon-heading1">Today’s orders</div>
                          <div className="beacon-number-and-chart">
                            <div className="beacon-number-and-badge">
                              <div className="beacon-heading">14</div>
                              <div className="beacon-change-and-text1">
                                <div className="beacon-change2">
                                  <img
                                    className="beacon-upload-04-icon"
                                    alt=""
                                    src={arrowdown}
                                  />

                                  <div className="beacon-change1">10%</div>
                                </div>
                                <div className="beacon-change-duration">last mth</div>
                              </div>
                            </div>
                            <img
                              className="beacon-chart-mini-icon"
                              alt=""
                              src={chart_mini1_2x}
                            />
                          </div>
                          <div className="beacon-dropdown">
                            <img
                              className="beacon-dots-vertical-icon"
                              alt=""
                              src={dotsvertical}
                            />
                          </div>
                        </div>
                        <div className="beacon-metric-item">
                          <div className="beacon-heading1">Avg. order value</div>
                          <div className="beacon-number-and-chart">
                            <div className="beacon-number-and-badge">
                              <div className="beacon-number">$91.42</div>
                              <div className="beacon-change-and-text">
                                <div className="beacon-change">
                                  <img
                                    className="beacon-upload-04-icon"
                                    alt=""
                                    src={arrowup}
                                  />

                                  <div className="beacon-change-amount">20%</div>
                                </div>
                                <div className="beacon-change-duration">last mth</div>
                              </div>
                            </div>
                            <img
                              className="beacon-chart-mini-icon"
                              alt=""
                              src={chart_mini2x}
                            />
                          </div>
                          <div className="beacon-dropdown">
                            <img
                              className="beacon-dots-vertical-icon"
                              alt=""
                              src={dotsvertical}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}

                </div>
              </div>
              {/* <div className="beacon-separator"></div>
              <div className="beacon-separator1"></div> */}
              <div className="beacon-main-wrapper">
                <div className="beacon-page-header-wrapper">
                  <div className="beacon-page-header1">
                    <div className="beacon-breadcrumbs1">
                      <div className="beacon-tabs1">
                        <div className="beacon-breadcrumb-button-base">
                          <img
                            className="beacon-search-lg-icon"
                            alt=""
                            src={homeline1}
                          />
                        </div>
                        <img
                          className="beacon-chevron-right-icon"
                          alt=""
                          src={chevronright}
                        />

                        <div className="beacon-breadcrumb-button-base6">
                          <div className="beacon-text42">Settings</div>
                        </div>
                        <img
                          className="beacon-chevron-right-icon"
                          alt=""
                          src={chevronright}
                        />

                        <div className="beacon-breadcrumb-button-base7">
                          <div className="beacon-text20">...</div>
                        </div>
                        <img
                          className="beacon-chevron-right-icon"
                          alt=""
                          src={chevronright}
                        />

                        <div className="beacon-breadcrumb-button-base3">
                          <div className="beacon-text21">Another link</div>
                        </div>
                        <img
                          className="beacon-chevron-right-icon2"
                          alt=""
                          src={chevronright}
                        />

                        <div className="beacon-breadcrumb-button-base4">
                          <div className="beacon-text34">Team</div>
                        </div>
                      </div>
                    </div>
                    <div className="beacon-content15">
                      {/* <div className="beacon-text-and-supporting-text1">
                        <div className="beacon-text46">Your Listings</div>
                        <div className="beacon-supporting-text2">
                          Manage your team members and their account permissions
                          here.
                        </div>
                      </div> */}
                      <div className="beacon-input-dropdown1">
                        <div className="beacon-input-with-label1">
                          <div className="beacon-label">Search</div>
                          <div className="beacon-input1">
                            <div className="beacon-content16">
                              <img
                                className="beacon-search-lg-icon"
                                alt=""
                                src={searchlg}
                              />

                              <div className="beacon-text47">Search</div>
                            </div>
                          </div>
                        </div>
                        <div className="beacon-hint-text1">
                          This is a hint text to help user.
                        </div>
                      </div>
                    </div>
                    <img className="beacon-divider-icon1" alt="" src={divider1} />
                  </div>
                </div>
                {/* <div className="beacon-supporting-text-wrapper">
                  <div className="beacon-supporting-text3">
                    Line 1 about subscription plan. Plan expiry date.
                  </div>
                </div> */}
                <div className="beacon-horizontal-tabs-wrapper">
                  <div className="beacon-horizontal-tabs">
                    <div className="beacon-tabs2">
                      <div className={activeTab === 'device' ? "beacon-tab-button-base" : "beacon-tab-button-base1"}>
                        <div className="beacon-tab-name" style={{ cursor: "pointer" }} onClick={() => setActiveTab('device')}>Devices</div>
                      </div>
                      <div className={activeTab === 'spare' ? "beacon-tab-button-base" : "beacon-tab-button-base1"}>
                        <div className="beacon-text48" style={{ cursor: "pointer" }} onClick={() => setActiveTab('spare')}>Spares</div>
                      </div>
                      <div className={activeTab === 'service' ? "beacon-tab-button-base" : "beacon-tab-button-base1"}>
                        <div className="beacon-text49" style={{ cursor: "pointer" }} onClick={() => setActiveTab('service')}>Service</div>
                      </div>
                      <div className={activeTab === 'software' ? "beacon-tab-button-base" : "beacon-tab-button-base1"}>
                        <div className="beacon-text50" style={{ cursor: "pointer" }} onClick={() => setActiveTab('software')}>Software</div>
                      </div>
                      
                      <div className="beacon-tab-button-base5">
                        <div className="beacon-text52">Billing</div>
                      </div>
                      <div className="beacon-tab-button-base5">
                        <div className="beacon-text52">Email</div>
                      </div>
                      <div className="beacon-tab-button-base7">
                        <div className="beacon-text52">Notifications</div>
                        <div className="beacon-badge10">
                          <div className="beacon-text55">2</div>
                        </div>
                      </div>
                      <div className="beacon-tab-button-base5">
                        <div className="beacon-text52">Integrations</div>
                      </div>
                      <div className="beacon-tab-button-base9">
                        <div className="beacon-text52">API</div>
                      </div>
                    </div>
                  </div>
                </div>



                {renderContent()}



              </div>





            </div>
          </div>
        </div>
        <img className="beacon-divider-icon6" alt="" src={divider} />

        <Footer />


      </div>
    )
  }
  else {
    return <Loader />
  }


}

