import React, { useEffect, useState } from 'react';
import divider3 from '../../icons/Gerator/sales_iq/divider3.svg';
import dotsvertical from '../../icons/Gerator/sales_iq/dotsvertical.svg';
import arrowdown1 from '../../icons/Gerator/dashboard/arrowdown1.svg';
import "./FunnelTable.css"

import SkeletonTableListingLoaderComponent from '../SkeletonTableListingLoaderComponent/SkeletonTableListingLoaderComponent';
import { ErrorShowComponent } from '../ErrorShowComponent/ErrorShowComponent';
import { NoTableDataDisplay } from '../NoTableDataDisplay/NoTableDataDisplay';
import { getLastSeen } from '../../helper/DeviceHelper';
import stage5 from "../../icons/Gerator/sales_iq/stage5.svg";
import stage2 from "../../icons/Gerator/sales_iq/stage2.svg";

import uploadcloud02 from "../../icons/Gerator/contacts/uploadcloud02.svg";
import plus from "../../icons/Gerator/contacts/plus.svg";
import uploadcloud02 from "../../icons/Gerator/contacts/uploadcloud02.svg";
import dotsvertical from "../../icons/Gerator/contacts/dotsvertical.svg";
import divider from "../../icons/Gerator/contacts/divider.svg";
import searchlg from "../../icons/Gerator/contacts/searchlg.svg";
import filterlines from "../../icons/Gerator/contacts/filterlines.svg";
import arrowdown from "../../icons/Gerator/contacts/arrowdown.svg";


import FunnelFilterButtonGroup from "../FunnelFilterButtonGroup/FunnelFilterButtonGroup";


const FunnelTable = ({ data, isLoading, isSuccess, isError, isFetching, showLoadMore, handleLoadMore, }) => {
  console.log('table data : ' + data);
  const [loadingComplete, setLoadingComplete] = useState(false);

  let content;



  //Success true then pause to show sekelton layout
  useEffect(() => {
    if (isSuccess) {

      const timer = setTimeout(() => {
        setLoadingComplete(true);
      }, 1000); // 1 second

      // Cleanup timer on component unmount
      return () => clearTimeout(timer);
    }
  }, [isSuccess]);

  if (isError) {
    content = <ErrorShowComponent message1={'Internal server error'} />
  }

  if (isLoading) {
    content = <SkeletonTableListingLoaderComponent />;
  } else if (loadingComplete && isSuccess) {

    if (data?.length > 0) {
      // dataLength = data.length;
      content = data.map((data, index) =>
      (
        <div className="funnel-detail-device-table-header-cell-parent" key={index}>

          <div className="funnel-detail-device-table-cell10">

           
                               

{/* <div className="funnel-detail-device-table-avatar" style={{
                                    backgroundImage: data.profileImage
                                      && `url(${data.profileImage})`
                                  }}>
                                    
                                </div> */}

                            <div className="funnel-detail-device-table-avatar">
                                    
                                </div>

            <div className="funnel-detail-device-dropdown1">
              {/* <div className="funnel-detail-device-cell-title">{data.user_usermeta.firstName} {data.user_usermeta.lastName}</div> */}
              {/* <div className="funnel-detail-device-table-supporting-text4">{data.user_usermeta.userWorkTitle}, {data.user_usermeta.tax_country.name}</div> */}

              <div className="funnel-detail-device-cell-title">Prithvi</div>
              <div className="funnel-detail-device-table-supporting-text4">Gerator, India</div>


            </div>
          </div>

          <div className="funnel-detail-device-table-cell20">
            <div className="funnel-detail-device-location-content">


              <div className="funnel-detail-device-text36">
                <img className="funnel-detail-device-stage-badge" src={stage5} alt="" />
                </div>
            </div>
          </div>

          <div className="funnel-detail-device-table-cell30">
            <div className="funnel-detail-device-location-content">
              <div className="funnel-detail-device-text36">91-100%</div>
            </div>
          </div>

          <div className="funnel-detail-device-table-cell40">
            <div className="funnel-detail-device-location-content">
              <div className="funnel-detail-device-text36">
               25-01-2025
              </div>
            </div>

          </div>
          

          <div className="funnel-detail-device-table-cell30">
            <div className="funnel-detail-device-location-content">


              <div className="funnel-detail-device-text36">$2500</div>
            </div>
          </div>

          <div className="funnel-detail-device-table-cell40">
            <div className="funnel-detail-device-location-content">
              <div className="funnel-detail-device-text38">
                  Need to talk to him after 2 weeks
              </div>
            </div>

          </div>

          <div className="funnel-detail-device-table-cell40">
            <div className="funnel-detail-device-location-content">
              <div className="funnel-detail-device-text36">
                <img
                  className="funnel-detail-device-dots-vertical-icon"
                  alt=""
                  src={dotsvertical}
                />
              </div>
            </div>

          </div>

        </div>
      ))
    }
    else {
      content = (

        <NoTableDataDisplay/>

      )
    }

  } else if (!loadingComplete && isSuccess) {
    content = <SkeletonTableListingLoaderComponent />; // Show loader until loadingComplete is true
  }


  return (

<div className="funnel-component-prsopective-buyers">
      <header className="funnel-component-main">
        <div className="funnel-component-card-header">
          <div className="funnel-component-content1">
            <div className="funnel-component-text-and-supporting-text1">
              <div className="funnel-component-text-and-badge">
                <a className="funnel-component-text2">Funnel</a>
                <div className="funnel-component-badge">
                  <div className="funnel-component-text3">240 have shown interest</div>
                </div>
              </div>
              <div className="funnel-component-supporting-text1">
                These users have signaled an interest in your listing
              </div>
            </div>
              {/* <div className="funnel-component-actions1" style={{ position: "relative" }}>
                <div className="funnel-component-button">
                  <img
                    className="funnel-component-placeholder-icon3"
                    alt=""
                    src="../../../placeholder2.svg"
                  />

                  <div className="funnel-component-text-padding2">
                    <div className="funnel-component-text4">Tertiary</div>
                  </div>
                  <img
                    className="funnel-component-placeholder-icon3"
                    alt=""
                    src="./public/placeholder2.svg"
                  />
                </div>
                <div className="funnel-component-button1">
                  <img
                    className="funnel-component-placeholder-icon3"
                    alt=""
                    src="./public/placeholder3.svg"
                  />

                  <div className="funnel-component-text-padding3">
                    <div className="funnel-component-text4">Secondary</div>
                  </div>
                  <img
                    className="funnel-component-placeholder-icon3"
                    alt=""
                    src="./public/placeholder3.svg"
                  />
                </div>
                <button className="funnel-component-button2">
                  <img
                    className="funnel-component-upload-cloud-02-icon"
                    alt=""
                    src={uploadcloud02}
                  />

                  <div className="funnel-component-text-padding4">
                    <a className="funnel-component-text6">Import</a>
                  </div>
                  <img
                    className="funnel-component-placeholder-icon3"
                    alt=""
                    src="./public/placeholder.svg"
                  />
                </button>
                <button className="funnel-component-button3">
                  <img
                    className="funnel-component-upload-cloud-02-icon"
                    alt=""
                    src={plus}
                  />

                  <div className="funnel-component-text-padding4" >
                    <a className="funnel-component-text7">Add Contact</a>
                  </div>
                  <img
                    className="funnel-component-placeholder-icon3"
                    alt=""
                    src="./public/placeholder1.svg"
                  />
                </button>

                

              </div>
              <div className="funnel-component-dropdown">
                <img
                  className="funnel-component-dots-vertical-icon"
                  loading="lazy"
                  alt=""
                  src={dotsvertical}
                />
              </div> */}
          </div>
          <img
            className="funnel-component-divider-icon"
            loading="lazy"
            alt=""
            src={divider}
          />
        </div>
        <div className="funnel-component-filters-bar">
          <div className="funnel-component-content2">

            {/* <ContactFilterButtonGroup
              handleFilterClick={handleFilterClick}
              activePostStatus={activePostStatus}
            /> */}

            <FunnelFilterButtonGroup/>

            <div className="funnel-component-actions2">
              <div className="funnel-component-input-dropdown">
                <div className="funnel-component-input-with-label">
                  <div className="funnel-component-label">Search</div>
                  <div className="funnel-component-input">
                    <div className="funnel-component-content3">
                      <img
                        className="funnel-component-search-lg-icon"
                        alt=""
                        src={searchlg}
                      />

                      <input
                        className="funnel-component-text17"
                        placeholder="Magnetom"
                        type="text"
                      />

                      <div className="funnel-component-supporting-text2">@olivia</div>
                    </div>
                  </div>
                </div>
                <div className="funnel-component-hint-text">This is a hint text to help user.</div>
              </div>
              <button className="funnel-component-button4">
                <img
                  className="funnel-component-upload-cloud-02-icon"
                  alt=""
                  src={filterlines}
                />

                <div className="funnel-component-text-padding4">
                  <a className="funnel-component-text18">Filters</a>
                </div>
                <img
                  className="funnel-component-placeholder-icon3"
                  alt=""
                  src="./public/placeholder.svg"
                />
              </button>
            </div>
          </div>
        </div>
      </header>

      
      
    

     {/* Main Table  */}
    
    
    <div className="funnel-detail-device-display-form-content-wrapper">
      <div className="funnel-detail-device-display-form-content">
        <div className="funnel-detail-device-display-toggle-header-parent">
          <div className="funnel-detail-device-display-toggle-header">
            {/* <div className="funnel-detail-device-display-toggle-title">Funnel</div> */}
            {/* <PlanExpiryStatus expiryDate={currentPlanExpiry} planName={currentPlanName} /> */}
          </div>

        </div>
        {/* <div className="funnel-detail-device-display-divider-container">
          <img
            className="funnel-detail-device-display-divider-icon3"
            alt=""
            src={divider3}
          />
        </div> */}

        <form className="funnel-detail-device-table">

          <div className="funnel-detail-device-table-content">

            <div className="funnel-detail-device-table-row">

              {/* Invoice */}



              <div className="funnel-detail-device-table-header-cell1">
                {/* <input className="funnel-detail-device-checkbox-wrapper" type="checkbox" /> */}
                <div className="funnel-detail-device-table-header">
                  <a className="funnel-detail-device-column-title">Prospect</a>
                  <img
                                className="funnel-detail-device-arrow-down-icon1"
                                alt=""
                                src={arrowdown}
                                />
                </div>
              </div>


              {/* Business Name */}

              <div className="funnel-detail-device-table-header-cell2">
                <div className="funnel-detail-device-table-header">
                  <a className="funnel-detail-device-column-title">Stage</a>
                  <img
                                className="funnel-detail-device-arrow-down-icon1"
                                alt=""
                                src={arrowdown}
                                />
                </div>
              </div>


              {/* Location  */}

              <div className="funnel-detail-device-table-header-cell3">
                <div className="funnel-detail-device-table-header">
                  <a className="funnel-detail-device-column-title">Probability </a>
                  <img
                                className="funnel-detail-device-arrow-down-icon1"
                                alt=""
                                src={arrowdown}
                                />

                </div>
              </div>

              {/* Last seen  */}

              <div className="funnel-detail-device-table-header-cell4">
                <div className="funnel-detail-device-table-header">
                  <a className="funnel-detail-device-column-title">Expected Closure</a>
                  <img
                                className="funnel-detail-device-arrow-down-icon1"
                                alt=""
                                src={arrowdown}
                                />

                </div>
              </div>

              {/* Transaction value  */}

              <div className="funnel-detail-device-table-header-cell3">
                <div className="funnel-detail-device-table-header">
                  <a className="funnel-detail-device-column-title">Value</a>
                  <img
                                className="funnel-detail-device-arrow-down-icon1"
                                alt=""
                                src={arrowdown}
                                />

                </div>
              </div>

              {/* Comment  */}

              <div className="funnel-detail-device-table-header-cell5">
                <div className="funnel-detail-device-table-header">
                  <a className="funnel-detail-device-column-title">Comment</a>

                  <img
                                className="funnel-detail-device-arrow-down-icon1"
                                alt=""
                                src={arrowdown}
                                />
                </div>
              </div>

              {/* Price  */}

              <div className="funnel-detail-device-table-header-cell6">
                <div className="funnel-detail-device-table-header">
                  <a className="funnel-detail-device-column-title">Action</a>
                  <img
                                className="funnel-detail-device-arrow-down-icon1"
                                alt=""
                                src={arrowdown}
                                />

                </div>
              </div>

              {/* Action  */}

              {/* <div className="funnel-detail-device-table-header-cell4">
                            <div className="funnel-detail-device-table-header2">
                                <div className="funnel-detail-device-action-column-title">Action</div>
                                <img
                                className="funnel-detail-device-arrow-down-icon2"
                                alt=""
                                src={arrowdown}
                                />
                            </div>
                            </div> */}

            </div>



          </div>

          <div className='funnel-detail-device-table-data-custom-main-container' style={{ width: "100%" }}>
            {/* {content} */}

            <div className="funnel-detail-device-table-header-cell-parent" >

                  <div className="funnel-detail-device-table-cell10">

                  
                                      

                  {/* <div className="funnel-detail-device-table-avatar" style={{
                                            backgroundImage: data.profileImage
                                              && `url(${data.profileImage})`
                                          }}>
                                            
                                        </div> */}

                                    <div className="funnel-detail-device-table-avatar">
                                            
                                        </div>

                    <div className="funnel-detail-device-dropdown1">
                      {/* <div className="funnel-detail-device-cell-title">{data.user_usermeta.firstName} {data.user_usermeta.lastName}</div> */}
                      {/* <div className="funnel-detail-device-table-supporting-text4">{data.user_usermeta.userWorkTitle}, {data.user_usermeta.tax_country.name}</div> */}

                      <div className="funnel-detail-device-cell-title">Prithvi</div>
                      <div className="funnel-detail-device-table-supporting-text4">Gerator, India</div>


                    </div>
                  </div>

                  <div className="funnel-detail-device-table-cell20">
                    <div className="funnel-detail-device-location-content">


                    <img className="funnel-detail-device-stage-badge" src={stage2} alt="" />

                      <div className="funnel-detail-device-text37">
                        Discussions/evaluations in progress
                        </div>
                   
                    </div>
                  </div>

                    <div className="funnel-detail-device-table-cell30">
                      <div className="funnel-detail-device-location-content">
                        <div className="funnel-detail-device-text36">91-100%</div>
                      </div>
                    </div>

                    <div className="funnel-detail-device-table-cell40">
                      <div className="funnel-detail-device-location-content">
                        <div className="funnel-detail-device-text36">
                        January 25,2025
                        </div>
                      </div>

                    </div>


                    <div className="funnel-detail-device-table-cell30">
                      <div className="funnel-detail-device-location-content">


                        <div className="funnel-detail-device-text36">$2500</div>
                      </div>
                    </div>

                    <div className="funnel-detail-device-table-cell50">
                      <div className="funnel-detail-device-location-content">
                        <div className="funnel-detail-device-text38">
                            Need to talk to him after 2 weeks
                        </div>
                      </div>

                    </div>

                    <div className="funnel-detail-device-table-cell60">
                      <div className="funnel-detail-device-location-content">
                        <div className="funnel-detail-device-text36">
                          <img
                            className="funnel-detail-device-dots-vertical-icon"
                            alt=""
                            src={dotsvertical}
                          />
                        </div>
                      </div>

                    </div>

            </div>
          </div>

          {/* Load more button  */}
          <div className="sales-iq-frame-wrapper44">
            <div className="sales-iq-frame-parent72">
              <div className="sales-iq-buttonsbutton-wrapper1">
                {showLoadMore && (
                  <button className='sales-iq-buttonsbutton-wrapper1-child'
                    onClick={handleLoadMore}
                    disabled={isFetching}
                  >
                    <div className="sales-iq-buttonsbutton28">
                      <img
                        className="sales-iq-arrow-narrow-left-icon"
                        alt=""
                        src={arrowdown1}
                      />
                      <div className="sales-iq-search-input">
                        <div className="sales-iq-text146">{isFetching ? 'Loading...' : 'Load More'}</div>
                      </div>
                    </div>
                  </button>
                )}
              </div>
            </div>
          </div>

          {/* Load More  */}
          {showLoadMore || undefined && (
            <div className="sales-iq-detail-device-frame-wrapper44">
              <div className="sales-iq-detail-device-frame-parent72">
                <div className="sales-iq-detail-device-buttonsbutton-wrapper1">

                  <button className='sales-iq-detail-device-buttonsbutton-wrapper1-child'
                    onClick={handleLoadMore}
                    disabled={isFetching}
                  >
                    <div className="sales-iq-detail-device-buttonsbutton28">
                      <img
                        className="sales-iq-detail-device-arrow-narrow-left-icon"
                        alt=""
                        src={arrowdown1}
                      />
                      <div className="sales-iq-detail-device-search-input">
                        <div className="sales-iq-detail-device-text146">{isFetching ? 'Loading...' : 'Load More'}</div>
                      </div>
                    </div>
                  </button>

                </div>
              </div>
            </div>
          )
          }

        </form>


      </div>


    </div >

    </div>
  )
}

export default FunnelTable