import React, { useEffect, useState } from 'react';
import "./DetailedSoftware.css"
import { Navbar1 } from '../../../components/Navbar1/Navbar1';
import FooterDetailPage from '../../../components/Footer/FooterDetailPage/FooterDetailPage';
import SlideShowDetailPageComponent from '../../../components/SlideShowDetailPageComponent/SlideShowDetailPageComponent';

import divider from '../../../icons/Gerator/detailed_listing/divider.svg';
import divider2 from '../../../icons/Gerator/detailed_listing/divider2.svg';
import divider3 from '../../../icons/Gerator/detailed_listing/divider3.svg';
import divider4 from '../../../icons/Gerator/detailed_listing/divider4.svg';
import divider5 from '../../../icons/Gerator/detailed_listing/divider5.svg';

import chevronright from '../../../icons/Gerator/detailed_listing/chevronright.svg';

import users01 from '../../../icons/Gerator/detailed_listing/users01.svg';

import rectangle_9_2x from '../../../icons/Gerator/detailed_listing/rectangle-9@2x.png';
import rectangle_5_2x from '../../../icons/Gerator/detailed_listing/rectangle-5@2x.png';
import rectangle_43_2x from '../../../icons/Gerator/detailed_listing/rectangle-43@2x.png';
import rectangle_95_2x from '../../../icons/Gerator/detailed_listing/rectangle-95@2x.png';
import rectangle_431_2x from '../../../icons/Gerator/detailed_listing/rectangle-431@2x.png';
import rectangle_196_2x from '../../../icons/Gerator/detailed_listing/rectangle-196@2x.png';

import arrowleft from '../../../icons/Gerator/detailed_listing/arrowleft.svg';
import arrowright from '../../../icons/Gerator/detailed_listing/arrowright.svg';

import breadcrumb_controls_2x from '../../../icons/Gerator/detailed_listing/breadcrumb-controls@2x.png';
import breadcrumb_controls1_2x from '../../../icons/Gerator/detailed_listing/breadcrumb-controls1@2x.png';
import breadcrumb_controls2_2x from '../../../icons/Gerator/detailed_listing/breadcrumb-controls2@2x.png';

import placeholder from '../../../icons/Gerator/detailed_listing/placeholder.svg';
import placeholder1 from '../../../icons/Gerator/detailed_listing/placeholder1.svg';
import placeholder2 from '../../../icons/Gerator/detailed_listing/placeholder2.svg';
import target_04 from "../../../icons/Gerator/dashboard/target_04.png"

import maximize01 from '../../../icons/Gerator/detailed_listing/maximize01.svg';
import markerpin02 from '../../../icons/Gerator/detailed_listing/markerpin02.svg';

import calendar from '../../../icons/Gerator/detailed_listing/calendar.svg';
import calendar1 from '../../../icons/Gerator/detailed_listing/calendar1.svg';

import facebook_1_1_2x from '../../../icons/Gerator/detailed_listing/facebook-1-1@2x.png';

import instagram_1_1_2x from '../../../icons/Gerator/detailed_listing/instagram-1-1@2x.png';
import whatsapp_1_2x from '../../../icons/Gerator/detailed_listing/whatsapp-1@2x.png';
import twitter_1_2x from '../../../icons/Gerator/detailed_listing/twitter-1@2x.png';
import linkedinlogo_1_2x from '../../../icons/Gerator/detailed_listing/linkedinlogo-1@2x.png';


import radar_1_1_2x from '../../../icons/Gerator/detailed_listing/radar-1-1@2x.png';
import deal_2_1_2x from '../../../icons/Gerator/detailed_listing/deal-2-1@2x.png';
import magnifier_1_2x from '../../../icons/Gerator/detailed_listing/magnifier-1@2x.png';
import share_1_2x from '../../../icons/Gerator/detailed_listing/share-1@2x.png';

import group_1010 from '../../../icons/Gerator/detailed_listing/group-1010.svg';
import group_78_2x from '../../../icons/Gerator/detailed_listing/group-78@2x.png';
import group_58_2x from '../../../icons/Gerator/detailed_listing/group-58@2x.png';
import group_781_2x from '../../../icons/Gerator/detailed_listing/group-781@2x.png';

import dotsvertical from '../../../icons/Gerator/detailed_listing/dotsvertical.svg';
import divider1 from '../../../icons/Gerator/detailed_listing/divider1.svg';
import group_351 from '../../../icons/Gerator/detailed_listing/group-351.svg';
import page from '../../../icons/Gerator/detailed_listing/page.svg';
import progress_fill from '../../../icons/Gerator/detailed_listing/progress-fill.svg';
import searchlg from '../../../icons/Gerator/detailed_listing/searchlg.svg';
import filterlines from '../../../icons/Gerator/detailed_listing/filterlines.svg';
import star_background from '../../../icons/Gerator/detailed_listing/star-background.svg';
import star_icon from '../../../icons/Gerator/detailed_listing/star-icon.svg';
import dot1 from '../../../icons/Gerator/detailed_listing/-dot1.svg';
import bookmarkcheck from '../../../icons/Gerator/detailed_listing/bookmarkcheck.svg';
import usercheck02 from '../../../icons/Gerator/detailed_listing/usercheck02.svg';
import f8595f4b711e503bc72fe396e5043e0c2_7_2x from '../../../icons/Gerator/detailed_listing/8595f4b711e503bc72fe396e5043e0c2-7@2x.png';
import messagedotscircle from '../../../icons/Gerator/detailed_listing/messagedotscircle.svg';
import messagechatcircle from '../../../icons/Gerator/detailed_listing/messagechatcircle.svg';


import markerpin021 from '../../../icons/Gerator/detailed_listing/markerpin021.svg';
import icon1 from '../../../icons/Gerator/detailed_listing/icon1.svg';
import login04 from '../../../icons/Gerator/detailed_listing/login04.svg';
import { Loader } from "../../../components/Loader";
// import { useDispatch } from "react-redux";
import { useLazyFindGetSoftwareQuery, useLazyGetSoftwaresQuery } from "../../../api/SoftwareAPI";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getCustomizedDate } from "../../../helper/SoftwareHelper";
import { decryptErrorData } from '../../../helper/AESHelper';
import { useLazyGetUserQuery } from '../../../api/Login&RegisterAPI';
import { auth, onAuthStateChanged } from '../../Login/FirebaseClient';
import { useAddContactMutation, useDeleteContactMutation } from '../../../api/ContactAPI';
import { useAddRadarSoftwareMutation, useDeleteRadarSoftwareMutation } from '../../../api/Radar/RadarSoftwareAPI';
import MapLocationViewComponent from '../../../components/MapLocationViewComponent/MapLocationViewComponent';
import VideoSection from '../../../components/VideoSection/VideoSection';
import { Helmet } from 'react-helmet-async';


export const DetailedSoftware = () => {
  const { id } = useParams();

  // const [triggerGetUser, { data, isSuccess, error, isLoading, isError }] = useLazyGetUserQuery();
  const [triggerGetSoftware, { data: softwareData,
    isLoading: isSoftwareLoading,
    isSuccess: isSoftwareSuccess,
    isError: isSoftwareError,
    error: deviceError }] = useLazyFindGetSoftwareQuery(id);

  let content;
  const [currentUser, setCurrentUser] = useState("");
  // const [editSoftwareSavedData, setEditSoftwareSavedData] = useState(null);
  const navigate = useNavigate();

  // const [showSuccessMessage, setShowSuccessMessage] = useState({
  //   message: "",
  //   color: "",
  // });

  const [addToContact, {
    isLoading: isContactLoading,
    isSuccess: isContactSuccess,
    isError: isContactError,
    error: contactError }] = useAddContactMutation();

  const [deleteFromContact] = useDeleteContactMutation();

  // Radar query delete
  const [deleteFromRadar] = useDeleteRadarSoftwareMutation();

  // Radar query delete
  const [addToRadar] = useAddRadarSoftwareMutation();


  useEffect(() => {
    // const auth = getAuth();

    // Listen for authentication state change
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is authenticated
        setCurrentUser(user);
        triggerGetSoftware(id);
      } else {
        // User is not authenticated, redirect to login
        navigate('/sign-in', { state: { message: 'Please log in to continue.' } });
      }
    });

    return () => unsubscribe(); // Cleanup listener on component unmount
  }, [navigate]);

  const handleRemoveRadar = async (softwareId) => {
    await deleteFromRadar(softwareId);
    window.location.reload();
  };

  const handleAddRadar = async (softwareId) => {
    await addToRadar(softwareId);
    window.location.reload();
  };

  const handleRemoveContact = async (softwareId) => {
    const result = await deleteFromContact(softwareId);
    if (result.data) {
      // If successful, reload the page
      window.location.reload();
    } else if (result.error) {
      console.log(result.error);
    }
  };

  const handleAddContact = async (softwareId) => {
    const result = await addToContact(softwareId);
    if (result.data) {
      // If successful, reload the page
      window.location.reload();
    } else if (result.error) {
      console.log(result.error);
    }
  };

  // To show error when adding contact
  useEffect(() => {
    // const { status } = contactError;
    // console.log(contactError);

    if (contactError?.status === 405)
      console.log(contactError.data.message);
    else
      console.log("Internal server error");
  }, [isContactError])





  if (isSoftwareSuccess) {
    return (
      <>
        <Helmet>
          <title>Software</title>
        </Helmet>
        <div className="detailed-software-listings-detailed-page-devic">
          <div className="detailed-software-rectangle-parent">
            <div className="detailed-software-frame-child"></div>
            <Navbar1 userData={currentUser} navigate={navigate} />
            <img className="detailed-software-divider-icon" alt="" src={divider} />

            <div className="detailed-software-breadcrumb-button-base">
              <div className="detailed-software-text6">...</div>
            </div>
            <img
              className="detailed-software-chevron-right-icon"
              alt=""
              src={chevronright}
            />

            <div className="detailed-software-breadcrumb-button-base1">
              <div className="detailed-software-text7">Another link</div>
            </div>
            <img
              className="detailed-software-chevron-right-icon1"
              alt=""
              src={chevronright}
            />

            <div className="detailed-software-frame-parent">
              <div className="detailed-software-breadcrumb-container-parent">
                <SlideShowDetailPageComponent slides={softwareData.data.galleryImages} />
                <div className="detailed-software-breadcrumb">
                  <div className="detailed-software-breadcrumb-items">
                    <div className="detailed-software-breadcrumb-button-base2">
                      <div className="detailed-software-text9">Dashboard</div>
                    </div>
                    <div className="detailed-software-chevron-container">
                      <img
                        className="detailed-software-chevron-right-icon2"
                        alt=""
                        src={chevronright}
                      />
                    </div>
                    <div className="detailed-software-breadcrumb-button-base3">
                      <div className="detailed-software-text10">Software</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="detailed-software-listing-content-wrapper">
                <div className="detailed-software-listing-content">
                  <div className="detailed-software-description-block-parent">
                    <div className="detailed-software-description-block">
                      <div className="detailed-software-description-heading">
                        {softwareData.data.title}
                      </div>
                      <div className="detailed-software-listing-details">
                        <div className="detailed-software-detail-container">
                          <div className="detailed-software-details-content">
                            <div className="detailed-software-details-row">
                              <div className="detailed-software-detail-items">
                                <img
                                  className="detailed-software-marker-pin-02-icon"
                                  alt=""
                                  src={markerpin02}
                                />
                                {softwareData.data.software_address.tax_address_type
                                  .slug === "address" ?
                                  <div className="detailed-software-supporting-text">{softwareData.data.software_address.addressTownCity},  {softwareData.data.software_address.tax_country.name}</div>
                                  :
                                  <div className="detailed-software-supporting-text">{softwareData.data.software_address.tax_address_type
                                    .name}</div>
                                }


                              </div>
                              <div className="detailed-software-detail-items">
                                <img
                                  className="detailed-software-marker-pin-02-icon"
                                  alt=""
                                  src={calendar}
                                />

                                <div className="detailed-software-supporting-text">{getCustomizedDate(softwareData.data.createdAt)}</div>
                              </div>
                            </div>
                            <div className="detailed-software-contact-details">
                              <div className="detailed-software-contact-name-wrapper">
                                <div className="detailed-software-contact-name">4.9/5</div>
                              </div>
                              <div className="detailed-software-contact-role">
                                <div className="detailed-software-supporting-text2">99,999 reviews</div>
                              </div>
                              <div className="detailed-software-social-links">
                                <div className="detailed-software-rectangle-container">
                                  <div className="detailed-software-frame-inner"></div>
                                  <div className="detailed-software-icon-backgrounds-parent">
                                    <div className="detailed-software-icon-backgrounds"></div>
                                    <img
                                      className="detailed-software-whatsapp-1-icon"
                                      alt=""
                                      src={facebook_1_1_2x}
                                    />
                                  </div>
                                  <div className="detailed-software-icon-backgrounds-parent">
                                    <div className="detailed-software-ellipse-div"></div>
                                    <img
                                      className="detailed-software-whatsapp-1-icon"
                                      alt=""
                                      src={instagram_1_1_2x}
                                    />
                                  </div>
                                  <div className="detailed-software-icon-backgrounds-parent">
                                    <div className="detailed-software-frame-child1"></div>
                                    <img
                                      className="detailed-software-whatsapp-1-icon"
                                      alt=""
                                      src={whatsapp_1_2x}
                                    />
                                  </div>
                                  <div className="detailed-software-icon-backgrounds-parent">
                                    <div className="detailed-software-icon-backgrounds"></div>
                                    <img
                                      className="detailed-software-whatsapp-1-icon"
                                      alt=""
                                      src={twitter_1_2x}
                                    />
                                  </div>
                                  <div className="detailed-software-icon-backgrounds-parent">
                                    <div className="detailed-software-ellipse-div"></div>
                                    <img
                                      className="detailed-software-whatsapp-1-icon"
                                      alt=""
                                      src={linkedinlogo_1_2x}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="detailed-software-button-row">
                            {!softwareData.data.isAuthor && (
                              softwareData.data.addedToRadar ? (

                                <div className="detailed-software-buttonsbutton1" onClick={() => handleRemoveRadar(softwareData.data.id)} style={{ cursor: "pointer" }}>
                                  <img
                                    className="detailed-software-magnifier-1-icon"
                                    alt=""
                                    src={target_04}
                                  />
                                  <div className="detailed-software-text-padding">
                                    <div className="detailed-software-text11">Added To Radar</div>
                                  </div>

                                </div>
                              ) : (
                                <div className="detailed-software-buttonsbutton1" onClick={() => handleAddRadar(softwareData.data.id)} style={{ cursor: "pointer" }}>
                                  <img
                                    className="detailed-software-magnifier-1-icon"
                                    alt=""
                                    src={radar_1_1_2x}
                                  />

                                  <div className="detailed-software-text-padding">
                                    <div className="detailed-software-text11">Add To Radar</div>
                                  </div>
                                </div>
                              )
                            )}
                            <div className="detailed-software-buttonsbutton2">
                              <img
                                className="detailed-software-placeholder-icon4"
                                alt=""
                                src={placeholder1}
                              />

                              <img
                                className="detailed-software-magnifier-1-icon"
                                alt=""
                                src={deal_2_1_2x}
                              />

                              <img
                                className="detailed-software-placeholder-icon4"
                                alt=""
                                src={placeholder1}
                              />
                            </div>
                            <div className="detailed-software-buttonsbutton2">
                              <img
                                className="detailed-software-placeholder-icon4"
                                alt=""
                                src={placeholder1}
                              />

                              <img
                                className="detailed-software-magnifier-1-icon"
                                alt=""
                                src={magnifier_1_2x}
                              />

                              <img
                                className="detailed-software-placeholder-icon4"
                                alt=""
                                src={placeholder1}
                              />
                            </div>
                            <div className="detailed-software-buttonsbutton2">
                              <img
                                className="detailed-software-placeholder-icon4"
                                alt=""
                                src={placeholder1}
                              />

                              <img
                                className="detailed-software-magnifier-1-icon"
                                alt=""
                                src={share_1_2x}
                              />

                              <img
                                className="detailed-software-placeholder-icon4"
                                alt=""
                                src={placeholder1}
                              />
                            </div>
                            <img
                              className="detailed-software-button-row-child"
                              alt=""
                              src={group_1010}
                            />
                          </div>
                        </div>
                        <div className="detailed-software-dropdown-container">
                          <div className="detailed-software-dropdown">
                            <img
                              className="detailed-software-maximize-01-icon"
                              alt=""
                              src={dotsvertical}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <img className="detailed-software-divider-icon" alt="" src={divider1} />

                    <div className="detailed-software-heading-parent">
                      <div className="detailed-software-heading">Highlights</div>
                      <div className="detailed-software-description-content">
                        <div className="detailed-software-description-columns-first">
                          <div className="detailed-software-first-columns-paragraphs">
                            <div className="detailed-software-paragraph">
                              {softwareData.data.tax_software_category.name}
                            </div>
                            <div className="detailed-software-paragraph1">Software Category</div>
                          </div>
                          <div className="detailed-software-first-columns-paragraphs">
                            <div className="detailed-software-paragraph">{softwareData.data.tax_software_type.name}</div>
                            <div className="detailed-software-paragraph1">Software Type</div>

                          </div>
                          <div className="detailed-software-first-columns-paragraphs">
                            <div className="detailed-software-paragraph">{softwareData.data.tax_installation_location.name}</div>
                            <div className="detailed-software-paragraph1">Installation Location</div>
                          </div>
                        </div>
                        <div className="detailed-software-description-columns-second">
                          <div className="detailed-software-second-columns-paragraphs">
                            <div className="detailed-software-paragraph">
                              Fittydent-Altwirth & Schmitt
                            </div>
                            <div className="detailed-software-paragraph1">Software Brand / Company</div>
                          </div>
                          <div className="detailed-software-second-columns-paragraphs">
                            <div className="detailed-software-paragraph">{softwareData.data.tax_service_and_support_reach.name}</div>
                            <div className="detailed-software-paragraph1">Service & Support Reach</div>

                          </div>
                          {softwareData.data?.tax_software_category?.name?.trim() !== "" && (
                            <div className="detailed-software-second-columns-paragraphs">
                              <div className="detailed-software-paragraph">
                                {softwareData.data.tax_software_category.name}
                              </div>
                              <div className="detailed-software-paragraph1">Device Category</div>
                            </div>
                          )}
                          {softwareData.data?.tax_physical_location?.name?.trim() !== "" && (
                            <div className="detailed-software-second-columns-paragraphs">
                              <div className="detailed-software-paragraph">{softwareData.data.tax_physical_location.name}</div>
                              <div className="detailed-software-paragraph1">Physical Location</div>
                            </div>
                          )}
                          {softwareData.data?.tax_clinical_application?.name?.trim() !== "" && (
                            <div className="detailed-software-second-columns-paragraphs">
                              <div className="detailed-software-paragraph">
                                {softwareData.data.tax_clinical_application.name}
                              </div>
                              <div className="detailed-software-paragraph1">Clinical Applications</div>
                            </div>
                          )}
                          {softwareData.data?.tax_purpose_use?.name?.trim() !== "" && (
                            <div className="detailed-software-second-columns-paragraphs">
                              <div className="detailed-software-paragraph">{softwareData.data.tax_purpose_use.name}</div>
                              <div className="detailed-software-paragraph1">Device Use</div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="detailed-software-content-blocks">
                      <div className="detailed-software-heading1">Software Features Highlights</div>
                      <div className="detailed-software-paragraph26">
                        {softwareData.data.softwareFeaturesHighlights}
                      </div>
                    </div>
                    <div className="detailed-software-content-blocks">
                      <div className="detailed-software-heading1">
                        Software Benefits Highlights
                      </div>
                      <div className="detailed-software-paragraph26">
                        {softwareData.data.softwareBenefitsHighlights}
                      </div>
                    </div>
                    <div className="detailed-software-content-blocks">
                      <div className="detailed-software-heading1">Parent Devices for which this software is available</div>
                      <div className="detailed-software-paragraph26">
                        {softwareData.data.listParentSoftware}
                      </div>
                    </div>
                    <div className="detailed-software-content-blocks">
                      <div className="detailed-software-heading1">Location</div>
                      {softwareData.data.software_address.tax_address_type
                        .slug === "address" ?
                        <div className="detailed-software-paragraph26">
                          {softwareData.data.software_address?.fullAddress}
                        </div>
                        :
                        <div className="detailed-software-paragraph26">
                          {softwareData.data.software_address.tax_address_type
                            .name}
                        </div>
                      }

                      {softwareData.data.software_address.tax_address_type
                        .slug === "address" && <MapLocationViewComponent
                          key={softwareData.data.id}
                          width={"100%"}
                          height={"400px"}
                          initialCoordinates={softwareData.data.software_address.addressLocation}
                        />}
                    </div>
                    {(softwareData.data.videoType && softwareData.data.linkVideo) &&
                      // (softwareData.data.videoType === 'Youtube') ?
                      <>
                        <VideoSection videoUrl={softwareData.data.linkVideo} />
                        <br />
                      </>
                      // :
                      // <>
                      //   <VideoSection videoUrl={serviceData.data.linkVideo} />
                      //   <br />
                      // </>
                    }

                    <img className="detailed-software-divider-icon" alt="" src={divider1} />



                    <div className="detailed-software-section">
                      <div className="detailed-software-benefits-container">
                        <div className="detailed-software-benefits-content">
                          <div className="detailed-software-heading6">Description</div>
                          {softwareData.data?.softwareFeatures?.trim() !== "" && (
                            <div className="detailed-software-benefits-subtitle">
                              <div className="detailed-software-heading7">Software Features</div>
                              <div className="detailed-software-paragraph30">
                                {softwareData.data.softwareFeatures}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    {softwareData.data?.softwareBenefits?.trim() !== "" && (
                      <div className="detailed-software-content-blocks">
                        <div className="detailed-software-heading1">
                          Software Benefits
                        </div>
                        <div className="detailed-software-paragraph26">
                          {softwareData.data.softwareBenefits}
                        </div>
                      </div>
                    )}
                    {softwareData.data?.systemRequirements?.trim() !== "" && (
                      <div className="detailed-software-content-blocks">
                        <div className="detailed-software-heading1">System Requirements</div>
                        <div className="detailed-software-paragraph26">
                          {softwareData.data.systemRequirements}
                        </div>
                      </div>
                    )}
                    {softwareData.data?.additionalInformation?.trim() !== "" && (
                      <div className="detailed-software-content-blocks">
                        <div className="detailed-software-heading1">Additional Information</div>
                        <div className="detailed-software-paragraph26">
                          {softwareData.data.additionalInformation}
                        </div>
                      </div>
                    )}

                    {softwareData.data?.documentFile?.trim() !== "" && (
                      <div className="detailed-software-heading-parent">
                        <div className="detailed-software-heading1">Documents</div>
                        <div className="detailed-software-download-items-parent detailed-software-download-items-parent-custom">
                          <a href={softwareData.data.documentFile} target="_blank" rel="noopener noreferrer">
                            <div className="detailed-software-download-items">
                              <div className="detailed-software-file-upload-item-base">
                                <img
                                  className="detailed-software-progress-fill-icon"
                                  alt=""
                                  src={progress_fill}
                                />

                                <div className="detailed-software-file-type-icon">
                                  <img
                                    className="detailed-software-page-icon"
                                    alt=""
                                    src={page}
                                  />

                                  <b className="detailed-software-file-type">PDF</b>
                                </div>
                                <div className="detailed-software-download-info">
                                  <div className="detailed-software-text12">{softwareData.data.document_file.file_name}</div>
                                  <div className="detailed-software-supporting-text3">{(softwareData.data.document_file.file_size / 1000).toFixed(1) + " KB"}</div>
                                </div>
                              </div>

                            </div>
                          </a>
                          {/* <Link to={softwareData.data.documentFile}>
                       
                      </Link> */}
                          <div className="detailed-software-download-items">

                          </div>
                          <div className="detailed-software-download-items">

                          </div>
                        </div>
                      </div>
                    )}
                    <div className="detailed-software-heading15">Reviews (2)</div>
                    <div className="detailed-software-filters-bar">
                      <div className="detailed-software-content4">
                        <div className="detailed-software-button-group">
                          <div className="detailed-software-button-group-base">
                            <div className="detailed-software-text18">Newest</div>
                          </div>
                          <div className="detailed-software-button-group-base1">
                            <div className="detailed-software-text19">Highest</div>
                          </div>
                          <div className="detailed-software-button-group-base2">
                            <div className="detailed-software-text20">Text</div>
                          </div>
                          <div className="detailed-software-button-group-base3">
                            <div className="detailed-software-text20">Text</div>
                          </div>
                          <div className="detailed-software-button-group-base4">
                            <div className="detailed-software-text20">Text</div>
                          </div>
                          <div className="detailed-software-button-group-base5">
                            <div className="detailed-software-text20">Text</div>
                          </div>
                          <div className="detailed-software-button-group-base6">
                            <div className="detailed-software-text20">Text</div>
                          </div>
                          <div className="detailed-software-button-group-base7">
                            <div className="detailed-software-text20">Text</div>
                          </div>
                          <div className="detailed-software-button-group-base8">
                            <div className="detailed-software-text26">Lowest</div>
                          </div>
                        </div>
                        <div className="detailed-software-actions">
                          <div className="detailed-software-input-dropdown">
                            <div className="detailed-software-input-with-label1">
                              <div className="detailed-software-label">Search</div>
                              <div className="detailed-software-input1">
                                <div className="detailed-software-content5">
                                  <img
                                    className="detailed-software-search-lg-icon"
                                    alt=""
                                    src={searchlg}
                                  />

                                  <div className="detailed-software-text27">Thomson Medical</div>
                                  <div className="detailed-software-supporting-text9">@olivia</div>
                                </div>
                              </div>
                            </div>
                            <div className="detailed-software-hint-text">
                              This is a hint text to help user.
                            </div>
                          </div>
                          <div className="detailed-software-button">
                            <img
                              className="detailed-software-filter-lines-icon"
                              alt=""
                              src={filterlines}
                            />

                            <div className="detailed-software-text-padding">
                              <div className="detailed-software-text28">Filters</div>
                            </div>
                            <img
                              className="detailed-software-placeholder-icon4"
                              alt=""
                              src={placeholder1}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="detailed-software-review-container">
                      <div className="detailed-software-review-content">
                        <div className="detailed-software-first-review">
                          <b className="detailed-software-empty-review">5.0</b>
                          <div className="detailed-software-reviewer">
                            <div className="detailed-software-heading16">/5</div>
                          </div>
                        </div>
                        <div className="detailed-software-review-stars-background">
                          <img
                            className="detailed-software-star-background-icon"
                            alt=""
                            src={star_background}
                          />

                          <img
                            className="detailed-software-star-background-icon"
                            alt=""
                            src={star_background}
                          />

                          <img
                            className="detailed-software-star-background-icon"
                            alt=""
                            src={star_background}
                          />

                          <img
                            className="detailed-software-star-background-icon"
                            alt=""
                            src={star_background}
                          />

                          <img
                            className="detailed-software-star-background-icon"
                            alt=""
                            src={star_background}
                          />
                        </div>
                      </div>
                      <div className="detailed-software-review-stars">
                        <div className="detailed-software-rectangle-parent1">
                          <div className="detailed-software-rectangle-div"></div>
                          <div className="detailed-software-stars">
                            <img
                              className="detailed-software-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />

                            <img
                              className="detailed-software-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />

                            <img
                              className="detailed-software-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />

                            <img
                              className="detailed-software-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />

                            <img
                              className="detailed-software-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />
                          </div>
                          <div className="detailed-software-first-review-fifth-star">
                            <div className="detailed-software-heading17">Accuracy (5.0)</div>
                          </div>
                        </div>
                        <div className="detailed-software-rectangle-parent1">
                          <div className="detailed-software-rectangle-div"></div>
                          <div className="detailed-software-stars">
                            <img
                              className="detailed-software-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />

                            <img
                              className="detailed-software-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />

                            <img
                              className="detailed-software-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />

                            <img
                              className="detailed-software-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />

                            <img
                              className="detailed-software-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />
                          </div>
                          <div className="detailed-software-heading-wrapper">
                            <div className="detailed-software-heading18">Relevance (5.0)</div>
                          </div>
                        </div>
                        <div className="detailed-software-rectangle-parent3">
                          <div className="detailed-software-rectangle-div"></div>
                          <div className="detailed-software-stars-wrapper">
                            <div className="detailed-software-stars2">
                              <img
                                className="detailed-software-filter-lines-icon"
                                alt=""
                                src={star_icon}
                              />

                              <img
                                className="detailed-software-filter-lines-icon"
                                alt=""
                                src={star_icon}
                              />

                              <img
                                className="detailed-software-filter-lines-icon"
                                alt=""
                                src={star_icon}
                              />

                              <img
                                className="detailed-software-filter-lines-icon"
                                alt=""
                                src={star_icon}
                              />

                              <img
                                className="detailed-software-filter-lines-icon"
                                alt=""
                                src={star_icon}
                              />
                            </div>
                          </div>
                          <div className="detailed-software-heading19">Value for Money (5.0)</div>
                        </div>
                      </div>
                    </div>
                    <div className="detailed-software-divider-wrapper">
                      <img
                        className="detailed-software-divider-icon3"
                        alt=""
                        src={divider1}
                      />
                    </div>
                    <div className="detailed-software-client-review">
                      <img
                        className="detailed-software-client-review-child"
                        alt=""
                        src={group_78_2x}
                      />

                      <div className="detailed-software-client-review-header">
                        <div className="detailed-software-heading20">Chloe Tammy</div>
                        <div className="detailed-software-heading21">
                          <span>Marketing Communications at</span>
                          <span className="detailed-software-alvo-medical"> Alvo Medical</span>
                        </div>
                      </div>
                      <div className="detailed-software-client-review-content">
                        <div className="detailed-software-client-rating">
                          <div className="detailed-software-stars3">
                            <img
                              className="detailed-software-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />

                            <img
                              className="detailed-software-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />

                            <img
                              className="detailed-software-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />

                            <img
                              className="detailed-software-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />

                            <img
                              className="detailed-software-filter-lines-icon"
                              alt=""
                              src={star_icon}
                            />
                          </div>
                          <div className="detailed-software-rating-value">
                            <div className="detailed-software-heading22">1 month ago</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="detailed-software-service-card">
                    <div className="detailed-software-card-content">
                      <div className="detailed-software-card-content-child"></div>
                      <div className="detailed-software-card-header">
                        <div className="detailed-software-price-tag">
                          {/* <div className="detailed-software-badge-wrapper">
                          <div className="detailed-software-badge3">
                            <img
                              className="detailed-software-dot-icon3"
                              alt=""
                              src={dot1}
                            />

                            <div className="detailed-software-text29">Sale</div>
                          </div>
                        </div> */}
                          <div className="detailed-software-price-parent">
                            <div className="detailed-software-price">${softwareData.data.rangeMin}</div>
                            <div className="detailed-software-original-price">
                              <div className="detailed-software-discount">{softwareData.data.tax_unit_measure_software.name}</div>
                            </div>
                          </div>
                          {/* <div className="detailed-software-service-title-container">
                          <div className="detailed-software-service-title">Posted By Manufacturer</div>
                        </div> */}
                        </div>
                      </div>
                      <div className="detailed-software-card-footer">
                        <img
                          className="detailed-software-card-footer-child"
                          alt=""
                          src={group_781_2x}
                        />

                        <div className="detailed-software-footer-divider">
                          <img
                            className="detailed-software-divider-icon4"
                            alt=""
                            src={divider2}
                          />
                        </div>
                      </div>
                      <div className="detailed-software-professional-info">
                        <div className="detailed-software-professional-details">
                          <div className="detailed-software-professional-actions">
                            <div className="detailed-software-benefits-container">
                              <div className="detailed-software-amlie-laurent">{softwareData.data.software_business.business_user.displayName}</div>
                              <Link className="detailed-software-role" to={`/business/${softwareData.data.software_business.id}/${softwareData.data.software_business.businessName}`}>
                                {softwareData.data.software_business.businessName}
                              </Link>
                              {/* <div className="detailed-software-role">{softwareData.data.software_business.businessName}</div> */}
                            </div>
                            <div className="detailed-software-action-buttons">
                              <div className="detailed-software-contact-button">
                                <div className="detailed-software-message-button">
                                  <div className="detailed-software-navigation">
                                    <img
                                      className="detailed-software-bookmark-check-icon"
                                      alt=""
                                      src={bookmarkcheck}
                                    />
                                  </div>
                                  <div className="detailed-software-supporting-text10">
                                    Verified by Gerätor
                                  </div>
                                </div>
                              </div>
                              <div className="detailed-software-supporting-text11">
                                Adani Health conforms to Gerätor's requirements for
                                verification
                              </div>
                            </div>
                            <div className="detailed-software-favorite-button">
                              <div className="detailed-software-button-container2">
                                <div className="detailed-software-buttonsbutton5">
                                  <img
                                    className="detailed-software-filter-lines-icon"
                                    alt=""
                                    src={messagechatcircle}
                                  />

                                  <div className="detailed-software-text30">Private Message</div>
                                </div>
                                {/* Add To Contact */}
                                {!softwareData.data.isAuthor && (
                                  (softwareData.data.addedToContact) ?
                                    <div className="detailed-software-buttonsbutton6" onClick={() => handleRemoveContact(softwareData.data.software_business.business_user.id)} style={{ cursor: 'pointer' }}>
                                      <img
                                        className="detailed-software-search-lg-icon"
                                        alt=""
                                        src={users01}
                                      />
                                    </div>
                                    :
                                    <div className="detailed-software-buttonsbutton6" onClick={() => handleAddContact(softwareData.data.software_business.business_user.id)} style={{ cursor: 'pointer' }}>
                                      <img
                                        className="detailed-software-search-lg-icon"
                                        alt=""
                                        src={usercheck02}
                                      />
                                    </div>
                                )}
                                {/* <div className="detailed-software-buttonsbutton6">
                                <img
                                  className="detailed-software-search-lg-icon"
                                  alt=""
                                  src={usercheck02}
                                />
                              </div> */}
                              </div>
                            </div>
                          </div>
                          <div className="detailed-software-dropdown-menu">
                            <div className="detailed-software-dropdown1">
                              <img
                                className="detailed-software-maximize-01-icon"
                                alt=""
                                src={dotsvertical}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="detailed-software-rectangle-parent4">
                      <div className="detailed-software-frame-child6"></div>
                      <div className="detailed-software-testimonial-header">
                        <div className="detailed-software-header-content1">
                          <div className="detailed-software-heading23">Jobs</div>
                          <div className="detailed-software-testimonial-description">
                            <div className="detailed-software-description">View All</div>
                          </div>
                        </div>
                      </div>
                      <div className="detailed-software-testimonial-list">
                        <img
                          className="detailed-software-divider-icon"
                          alt=""
                          src={divider2}
                        />

                        <div className="detailed-software-testimonial-divider">
                          <div className="detailed-software-testimonial-item">
                            <div className="detailed-software-rectangle-parent5">
                              <div className="detailed-software-frame-child7"></div>
                              <img
                                className="detailed-software-f4b711e503bc72fe396e5043e0c2-7-icon"
                                alt=""
                                src={f8595f4b711e503bc72fe396e5043e0c2_7_2x}
                              />
                            </div>
                            <div className="detailed-software-testimonial-details">
                              <div className="detailed-software-client-name">
                                WHI Program assistant / Undergraduate researcher
                              </div>
                              <div className="detailed-software-heading24">Marketing Fusion SARL</div>
                            </div>
                          </div>
                        </div>
                        <img
                          className="detailed-software-divider-icon"
                          alt=""
                          src={divider2}
                        />

                        <div className="detailed-software-testimonial-divider">
                          <div className="detailed-software-testimonial-item">
                            <div className="detailed-software-rectangle-parent5">
                              <div className="detailed-software-frame-child7"></div>
                              <img
                                className="detailed-software-f4b711e503bc72fe396e5043e0c2-7-icon"
                                alt=""
                                src={f8595f4b711e503bc72fe396e5043e0c2_7_2x}
                              />
                            </div>
                            <div className="detailed-software-testimonial-details">
                              <div className="detailed-software-client-name">
                                WHI Program assistant / Undergraduate researcher
                              </div>
                              <div className="detailed-software-heading24">Marketing Fusion SARL</div>
                            </div>
                          </div>
                        </div>
                        <img
                          className="detailed-software-divider-icon"
                          alt=""
                          src={divider2}
                        />

                        <div className="detailed-software-testimonial-divider">
                          <div className="detailed-software-testimonial-item">
                            <div className="detailed-software-rectangle-parent5">
                              <div className="detailed-software-frame-child7"></div>
                              <img
                                className="detailed-software-f4b711e503bc72fe396e5043e0c2-7-icon"
                                alt=""
                                src={f8595f4b711e503bc72fe396e5043e0c2_7_2x}
                              />
                            </div>
                            <div className="detailed-software-testimonial-details">
                              <div className="detailed-software-client-name">
                                WHI Program assistant / Undergraduate researcher
                              </div>
                              <div className="detailed-software-heading24">Marketing Fusion SARL</div>
                            </div>
                          </div>
                        </div>
                        <img
                          className="detailed-software-divider-icon"
                          alt=""
                          src={divider2}
                        />

                        <div className="detailed-software-testimonial-divider">
                          <div className="detailed-software-testimonial-item">
                            <div className="detailed-software-rectangle-parent5">
                              <div className="detailed-software-frame-child7"></div>
                              <img
                                className="detailed-software-f4b711e503bc72fe396e5043e0c2-7-icon"
                                alt=""
                                src={f8595f4b711e503bc72fe396e5043e0c2_7_2x}
                              />
                            </div>
                            <div className="detailed-software-testimonial-details">
                              <div className="detailed-software-client-name">
                                WHI Program assistant / Undergraduate researcher
                              </div>
                              <div className="detailed-software-heading24">Marketing Fusion SARL</div>
                            </div>
                          </div>
                        </div>
                        <img
                          className="detailed-software-divider-icon"
                          alt=""
                          src={divider2}
                        />

                        <div className="detailed-software-testimonial-divider">
                          <div className="detailed-software-testimonial-item">
                            <div className="detailed-software-rectangle-parent5">
                              <div className="detailed-software-frame-child7"></div>
                              <img
                                className="detailed-software-f4b711e503bc72fe396e5043e0c2-7-icon"
                                alt=""
                                src={f8595f4b711e503bc72fe396e5043e0c2_7_2x}
                              />
                            </div>
                            <div className="detailed-software-testimonial-details">
                              <div className="detailed-software-client-name">
                                WHI Program assistant / Undergraduate researcher
                              </div>
                              <div className="detailed-software-heading24">Marketing Fusion SARL</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="detailed-software-listing-image-grid">
              <div className="detailed-software-paragraph37">
                One of the best offers I found in the market
              </div>
            </div>
            <div className="detailed-software-listing-image-grid1">
              <div className="detailed-software-paragraph35">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy text
                ever since the 1500s, when an unknown printer took a galley of type
                and scrambled it to make a type specimen book.
              </div>
            </div>
            <div className="detailed-software-client-feedback">
              <div className="detailed-software-feedback-content">
                <img
                  className="detailed-software-feedback-content-child"
                  alt=""
                  src={group_78_2x}
                />

                <div className="detailed-software-feedback-header">
                  <div className="detailed-software-header-info">
                    <div className="detailed-software-heading20">Chloe Tammy</div>
                    <div className="detailed-software-heading21">
                      <span>Marketing Communications at</span>
                      <span className="detailed-software-alvo-medical"> Alvo Medical</span>
                    </div>
                  </div>
                </div>
                <div className="detailed-software-feedback-rating">
                  <div className="detailed-software-buttonsbutton7">
                    <img
                      className="detailed-software-filter-lines-icon"
                      alt=""
                      src={messagedotscircle}
                    />

                    <div className="detailed-software-text35">Reply</div>
                  </div>
                  <div className="detailed-software-feedback-score">
                    <div className="detailed-software-stars-parent">
                      <div className="detailed-software-stars">
                        <img
                          className="detailed-software-filter-lines-icon"
                          alt=""
                          src={star_icon}
                        />

                        <img
                          className="detailed-software-filter-lines-icon"
                          alt=""
                          src={star_icon}
                        />

                        <img
                          className="detailed-software-filter-lines-icon"
                          alt=""
                          src={star_icon}
                        />

                        <img
                          className="detailed-software-filter-lines-icon"
                          alt=""
                          src={star_icon}
                        />

                        <img
                          className="detailed-software-filter-lines-icon"
                          alt=""
                          src={star_icon}
                        />
                      </div>
                      <div className="detailed-software-rating-value">
                        <div className="detailed-software-heading22">1 month ago</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="detailed-software-client-message">
              <div className="detailed-software-message-content">
                <div className="detailed-software-paragraph39">
                  One of the best offers I found in the market
                </div>
                <div className="detailed-software-paragraph26">
                  Lorem Ipsum is simply dummy text of the printing and typesetting
                  industry. Lorem Ipsum has been the industry's standard dummy text
                  ever since the 1500s, when an unknown printer took a galley of
                  type and scrambled it to make a type specimen book.
                </div>
              </div>
            </div>
            <div className="detailed-software-view-replies">
              <div className="detailed-software-buttonsbutton8">
                <img
                  className="detailed-software-filter-lines-icon"
                  alt=""
                  src={messagedotscircle}
                />

                <div className="detailed-software-text35">Reply</div>
              </div>
            </div>
            <div className="detailed-software-pagination-wrapper">
              <div className="detailed-software-pagination">
                <div className="detailed-software-button-wrap">
                  <div className="detailed-software-buttonsbutton9">
                    <img
                      className="detailed-software-placeholder-icon4"
                      alt=""
                      src={placeholder1}
                    />

                    <div className="detailed-software-text-padding">
                      <div className="detailed-software-page-number">Previous</div>
                    </div>
                    <img
                      className="detailed-software-placeholder-icon4"
                      alt=""
                      src={placeholder1}
                    />
                  </div>
                </div>
                <div className="detailed-software-details">1 of 10</div>
                <div className="detailed-software-button-wrap1">
                  <div className="detailed-software-buttonsbutton10">
                    <img
                      className="detailed-software-placeholder-icon4"
                      alt=""
                      src={placeholder1}
                    />

                    <div className="detailed-software-text-padding">
                      <div className="detailed-software-text37">Next</div>
                    </div>
                    <img
                      className="detailed-software-placeholder-icon4"
                      alt=""
                      src={placeholder1}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="detailed-software-listing-image-grid1">
              <img className="detailed-software-divider-icon3" alt="" src={divider1} />
            </div>
            <div className="detailed-software-review-form-wrapper">
              <div className="detailed-software-review-form">
                <div className="detailed-software-review-form-child"></div>
                <div className="detailed-software-breadcrumb-container-parent">
                  <div className="detailed-software-heading32">Write Review</div>
                  <div className="detailed-software-frame-parent3">
                    <div className="detailed-software-heading-container">
                      <div className="detailed-software-heading33">Accuracy</div>
                      <div className="detailed-software-listing-detail-item-date-parent">
                        <img
                          className="detailed-software-filter-lines-icon"
                          alt=""
                          src={star_icon}
                        />

                        <img
                          className="detailed-software-filter-lines-icon"
                          alt=""
                          src={star_icon}
                        />

                        <img
                          className="detailed-software-filter-lines-icon"
                          alt=""
                          src={star_icon}
                        />

                        <img
                          className="detailed-software-filter-lines-icon"
                          alt=""
                          src={star_icon}
                        />

                        <img
                          className="detailed-software-filter-lines-icon"
                          alt=""
                          src={star_icon}
                        />
                      </div>
                    </div>
                    <div className="detailed-software-heading-container">
                      <div className="detailed-software-heading33">Relevance</div>
                      <div className="detailed-software-listing-detail-item-date-parent">
                        <img
                          className="detailed-software-filter-lines-icon"
                          alt=""
                          src={star_icon}
                        />

                        <img
                          className="detailed-software-filter-lines-icon"
                          alt=""
                          src={star_icon}
                        />

                        <img
                          className="detailed-software-filter-lines-icon"
                          alt=""
                          src={star_icon}
                        />

                        <img
                          className="detailed-software-filter-lines-icon"
                          alt=""
                          src={star_icon}
                        />

                        <img
                          className="detailed-software-filter-lines-icon"
                          alt=""
                          src={star_icon}
                        />
                      </div>
                    </div>
                    <div className="detailed-software-heading-container">
                      <div className="detailed-software-heading35">Value for Money</div>
                      <div className="detailed-software-listing-detail-item-date-parent">
                        <img
                          className="detailed-software-filter-lines-icon"
                          alt=""
                          src={star_icon}
                        />

                        <img
                          className="detailed-software-filter-lines-icon"
                          alt=""
                          src={star_icon}
                        />

                        <img
                          className="detailed-software-filter-lines-icon"
                          alt=""
                          src={star_icon}
                        />

                        <img
                          className="detailed-software-filter-lines-icon"
                          alt=""
                          src={star_icon}
                        />

                        <img
                          className="detailed-software-filter-lines-icon"
                          alt=""
                          src={star_icon}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="detailed-software-input-field">
                    <div className="detailed-software-input-with-label1">
                      <div className="detailed-software-label2">Title</div>
                      <div className="detailed-software-content6">
                        <div className="detailed-software-textarea-input-field">
                          <div className="detailed-software-input-with-label3">
                            <div className="detailed-software-label3">Description</div>
                            <div className="detailed-software-input2">
                              <div className="detailed-software-text38">70 characters</div>
                            </div>
                          </div>
                          <div className="detailed-software-hint-text1">10 characters left</div>
                        </div>
                      </div>
                    </div>
                    <div className="detailed-software-hint-text">This is a hint text to help user.</div>
                  </div>
                  <div className="detailed-software-input-field1">
                    <div className="detailed-software-input-with-label1">
                      <div className="detailed-software-label4">Review</div>
                      <div className="detailed-software-content6">
                        <div className="detailed-software-textarea-input-field1">
                          <div className="detailed-software-input-with-label3">
                            <div className="detailed-software-label3">Description</div>
                            <div className="detailed-software-input3">
                              <div className="detailed-software-text39">1000 characters</div>
                            </div>
                          </div>
                          <div className="detailed-software-hint-text3">40 characters left</div>
                        </div>
                      </div>
                    </div>
                    <div className="detailed-software-hint-text">This is a hint text to help user.</div>
                  </div>
                </div>
                <div className="detailed-software-submit-button-wrapper">
                  <div className="detailed-software-button1">
                    <img
                      className="detailed-software-chevron-down-icon"
                      alt=""
                      src={placeholder2}
                    />

                    <img className="detailed-software-log-in-04-icon" alt="" src={login04} />

                    <div className="detailed-software-navigation">
                      <div className="detailed-software-text40">Submit</div>
                    </div>
                    <img
                      className="detailed-software-chevron-down-icon"
                      alt=""
                      src={placeholder2}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="detailed-software-listing-image-grid3">
              <img className="detailed-software-divider-icon11" alt="" src={divider3} />
            </div>
            <div className="detailed-software-listing-image-grid4">
              <div className="detailed-software-heading-parent3">
                <div className="detailed-software-heading36">Related Devices</div>
                <div className="detailed-software-text-wrapper">
                  <div className="detailed-software-description">View All</div>
                </div>
              </div>
            </div>
            <div className="detailed-software-listing-image-grid5">
              <div className="detailed-software-frame-parent4">
                <div className="detailed-software-frame-parent5">
                  <div className="detailed-software-rectangle-parent10">
                    <div className="detailed-software-frame-child12"></div>
                    <div className="detailed-software-listing-card-badge-overlay">
                      <img
                        className="detailed-software-listing-card-badge-overlay-child"
                        alt=""
                        src={rectangle_95_2x}
                      />

                      <div className="detailed-software-image-overlay">
                        <div className="detailed-software-badge4">
                          <div className="detailed-software-listing-card-price">Assured</div>
                        </div>
                        <div className="detailed-software-short-listing-team">
                          <div className="detailed-software-badge5">
                            <div className="detailed-software-listing-card-radar">ID# AXJYN</div>
                          </div>
                          <div className="detailed-software-buttonsbutton11">
                            <img
                              className="detailed-software-magnifier-1-icon"
                              alt=""
                              src={radar_1_1_2x}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="detailed-software-listing-detail-item">
                      <div className="detailed-software-message-content">
                        <div className="detailed-software-text-frame">
                          <div className="detailed-software-text42">
                            New / Surplus 2021 Philips Health-care HD11 XE
                            (P/N:23A...
                          </div>
                        </div>
                        <div className="detailed-software-listing-detail-item-value">
                          <div className="detailed-software-listing-detail-item-pin-wrapper">
                            <div className="detailed-software-heading-container">
                              <div className="detailed-software-listing-detail-item-location">
                                <div className="detailed-software-listing-detail-item-date-parent">
                                  <div className="detailed-software-listing-detail-item-date">
                                    <img
                                      className="detailed-software-chevron-right-icon2"
                                      alt=""
                                      src={markerpin021}
                                    />
                                  </div>
                                  <div className="detailed-software-location">India, New Delhi</div>
                                </div>
                                <div className="detailed-software-listing-detail-item-date-parent">
                                  <div className="detailed-software-listing-detail-item-date">
                                    <img
                                      className="detailed-software-chevron-right-icon2"
                                      alt=""
                                      src={calendar1}
                                    />
                                  </div>
                                  <div className="detailed-software-type-of-work">April 09, 2023</div>
                                </div>
                              </div>
                              <div className="detailed-software-listing-team">
                                <div className="detailed-software-listing-team-member-wrapper">
                                  <div className="detailed-software-listing-team-member">4.9/5</div>
                                </div>
                                <div className="detailed-software-contact-role">
                                  <div className="detailed-software-supporting-text12">
                                    99,999 reviews
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="detailed-software-listing">
                            <div className="detailed-software-breadcrumb-items">
                              <div className="detailed-software-listing-short-info">$9,999,999.99</div>
                              <div className="detailed-software-supporting-text-wrapper">
                                <div className="detailed-software-supporting-text13">USD-Per Unit</div>
                              </div>
                            </div>
                          </div>
                          <img
                            className="detailed-software-divider-icon"
                            alt=""
                            src={divider4}
                          />

                          <div className="detailed-software-listing-detail-item-value-inner">
                            <div className="detailed-software-footer-social-parent">
                              <div className="detailed-software-footer-social">
                                <img
                                  className="detailed-software-footer-social-child"
                                  alt=""
                                  src={group_58_2x}
                                />

                                <div className="detailed-software-listing-short-detail-item-pin-parent">
                                  <div className="detailed-software-listing-short-detail-item-pin">
                                    <div className="detailed-software-name">Olivia Rhye</div>
                                  </div>
                                  <div className="detailed-software-role1">Business Name</div>
                                </div>
                              </div>
                              <div className="detailed-software-listing-short-detail-item-date">
                                <img
                                  className="detailed-software-icon1"
                                  alt=""
                                  src={icon1}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="detailed-software-rectangle-parent10">
                    <div className="detailed-software-frame-child12"></div>
                    <div className="detailed-software-listing-card-badge-overlay">
                      <img
                        className="detailed-software-listing-card-badge-overlay-child"
                        alt=""
                        src={rectangle_95_2x}
                      />

                      <div className="detailed-software-image-overlay">
                        <div className="detailed-software-badge4">
                          <div className="detailed-software-listing-card-price">Assured</div>
                        </div>
                        <div className="detailed-software-short-listing-team">
                          <div className="detailed-software-badge5">
                            <div className="detailed-software-listing-card-radar">ID# AXJYN</div>
                          </div>
                          <div className="detailed-software-buttonsbutton11">
                            <img
                              className="detailed-software-magnifier-1-icon"
                              alt=""
                              src={radar_1_1_2x}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="detailed-software-listing-detail-item">
                      <div className="detailed-software-message-content">
                        <div className="detailed-software-text-frame">
                          <div className="detailed-software-text42">
                            New / Surplus 2021 Philips Health-care HD11 XE
                            (P/N:23A...
                          </div>
                        </div>
                        <div className="detailed-software-listing-detail-item-value">
                          <div className="detailed-software-listing-detail-item-pin-wrapper">
                            <div className="detailed-software-heading-container">
                              <div className="detailed-software-listing-detail-item-location">
                                <div className="detailed-software-listing-detail-item-date-parent">
                                  <div className="detailed-software-listing-detail-item-date">
                                    <img
                                      className="detailed-software-chevron-right-icon2"
                                      alt=""
                                      src={markerpin021}
                                    />
                                  </div>
                                  <div className="detailed-software-location">India, New Delhi</div>
                                </div>
                                <div className="detailed-software-listing-detail-item-date-parent">
                                  <div className="detailed-software-listing-detail-item-date">
                                    <img
                                      className="detailed-software-chevron-right-icon2"
                                      alt=""
                                      src={calendar1}
                                    />
                                  </div>
                                  <div className="detailed-software-type-of-work">April 09, 2023</div>
                                </div>
                              </div>
                              <div className="detailed-software-listing-team">
                                <div className="detailed-software-listing-team-member-wrapper">
                                  <div className="detailed-software-listing-team-member">4.9/5</div>
                                </div>
                                <div className="detailed-software-contact-role">
                                  <div className="detailed-software-supporting-text12">
                                    99,999 reviews
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="detailed-software-listing">
                            <div className="detailed-software-breadcrumb-items">
                              <div className="detailed-software-listing-short-info">$9,999,999.99</div>
                              <div className="detailed-software-supporting-text-wrapper">
                                <div className="detailed-software-supporting-text13">USD-Per Unit</div>
                              </div>
                            </div>
                          </div>
                          <img
                            className="detailed-software-divider-icon"
                            alt=""
                            src={divider4}
                          />

                          <div className="detailed-software-listing-detail-item-value-inner">
                            <div className="detailed-software-footer-social-parent">
                              <div className="detailed-software-footer-social">
                                <img
                                  className="detailed-software-footer-social-child"
                                  alt=""
                                  src={group_58_2x}
                                />

                                <div className="detailed-software-listing-short-detail-item-pin-parent">
                                  <div className="detailed-software-listing-short-detail-item-pin">
                                    <div className="detailed-software-name">Olivia Rhye</div>
                                  </div>
                                  <div className="detailed-software-role1">Business Name</div>
                                </div>
                              </div>
                              <div className="detailed-software-listing-short-detail-item-date">
                                <img
                                  className="detailed-software-icon1"
                                  alt=""
                                  src={icon1}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="detailed-software-rectangle-parent10">
                    <div className="detailed-software-frame-child12"></div>
                    <div className="detailed-software-listing-card-badge-overlay">
                      <img
                        className="detailed-software-listing-card-badge-overlay-child"
                        alt=""
                        src={rectangle_95_2x}
                      />

                      <div className="detailed-software-image-overlay">
                        <div className="detailed-software-badge4">
                          <div className="detailed-software-listing-card-price">Assured</div>
                        </div>
                        <div className="detailed-software-short-listing-team">
                          <div className="detailed-software-badge5">
                            <div className="detailed-software-listing-card-radar">ID# AXJYN</div>
                          </div>
                          <div className="detailed-software-buttonsbutton11">
                            <img
                              className="detailed-software-magnifier-1-icon"
                              alt=""
                              src={radar_1_1_2x}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="detailed-software-listing-detail-item">
                      <div className="detailed-software-message-content">
                        <div className="detailed-software-text-frame">
                          <div className="detailed-software-text42">
                            New / Surplus 2021 Philips Health-care HD11 XE
                            (P/N:23A...
                          </div>
                        </div>
                        <div className="detailed-software-listing-detail-item-value">
                          <div className="detailed-software-listing-detail-item-pin-wrapper">
                            <div className="detailed-software-heading-container">
                              <div className="detailed-software-listing-detail-item-location">
                                <div className="detailed-software-listing-detail-item-date-parent">
                                  <div className="detailed-software-listing-detail-item-date">
                                    <img
                                      className="detailed-software-chevron-right-icon2"
                                      alt=""
                                      src={markerpin021}
                                    />
                                  </div>
                                  <div className="detailed-software-location">India, New Delhi</div>
                                </div>
                                <div className="detailed-software-listing-detail-item-date-parent">
                                  <div className="detailed-software-listing-detail-item-date">
                                    <img
                                      className="detailed-software-chevron-right-icon2"
                                      alt=""
                                      src={calendar1}
                                    />
                                  </div>
                                  <div className="detailed-software-type-of-work">April 09, 2023</div>
                                </div>
                              </div>
                              <div className="detailed-software-listing-team">
                                <div className="detailed-software-listing-team-member-wrapper">
                                  <div className="detailed-software-listing-team-member">4.9/5</div>
                                </div>
                                <div className="detailed-software-contact-role">
                                  <div className="detailed-software-supporting-text12">
                                    99,999 reviews
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="detailed-software-listing">
                            <div className="detailed-software-breadcrumb-items">
                              <div className="detailed-software-listing-short-info">$9,999,999.99</div>
                              <div className="detailed-software-supporting-text-wrapper">
                                <div className="detailed-software-supporting-text13">USD-Per Unit</div>
                              </div>
                            </div>
                          </div>
                          <img
                            className="detailed-software-divider-icon"
                            alt=""
                            src={divider4}
                          />

                          <div className="detailed-software-listing-detail-item-value-inner">
                            <div className="detailed-software-footer-social-parent">
                              <div className="detailed-software-footer-social">
                                <img
                                  className="detailed-software-footer-social-child"
                                  alt=""
                                  src={group_58_2x}
                                />

                                <div className="detailed-software-listing-short-detail-item-pin-parent">
                                  <div className="detailed-software-listing-short-detail-item-pin">
                                    <div className="detailed-software-name">Olivia Rhye</div>
                                  </div>
                                  <div className="detailed-software-role1">Business Name</div>
                                </div>
                              </div>
                              <div className="detailed-software-listing-short-detail-item-date">
                                <img
                                  className="detailed-software-icon1"
                                  alt=""
                                  src={icon1}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="detailed-software-rectangle-parent10">
                    <div className="detailed-software-frame-child12"></div>
                    <div className="detailed-software-listing-card-badge-overlay">
                      <img
                        className="detailed-software-listing-card-badge-overlay-child"
                        alt=""
                        src={rectangle_95_2x}
                      />

                      <div className="detailed-software-image-overlay3">
                        <div className="detailed-software-badge4">
                          <div className="detailed-software-listing-card-price">Assured</div>
                        </div>
                        <div className="detailed-software-badge-group">
                          <div className="detailed-software-badge5">
                            <div className="detailed-software-listing-card-radar">ID# AXJYN</div>
                          </div>
                          <div className="detailed-software-buttonsbutton14">
                            <img
                              className="detailed-software-magnifier-1-icon"
                              alt=""
                              src={radar_1_1_2x}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="detailed-software-listing-detail-item">
                      <div className="detailed-software-message-content">
                        <div className="detailed-software-text-frame">
                          <div className="detailed-software-text42">
                            New / Surplus 2021 Philips Health-care HD11 XE
                            (P/N:23A...
                          </div>
                        </div>
                        <div className="detailed-software-work-four-wrapper">
                          <div className="detailed-software-heading-container">
                            <div className="detailed-software-listing-detail-item-location">
                              <div className="detailed-software-listing-detail-item-date-parent">
                                <div className="detailed-software-listing-detail-item-date">
                                  <img
                                    className="detailed-software-chevron-right-icon2"
                                    alt=""
                                    src={markerpin021}
                                  />
                                </div>
                                <div className="detailed-software-location">India, New Delhi</div>
                              </div>
                              <div className="detailed-software-listing-detail-item-date-parent">
                                <div className="detailed-software-listing-detail-item-date">
                                  <img
                                    className="detailed-software-chevron-right-icon2"
                                    alt=""
                                    src={calendar1}
                                  />
                                </div>
                                <div className="detailed-software-type-of-work">April 09, 2023</div>
                              </div>
                            </div>
                            <div className="detailed-software-listing-team">
                              <div className="detailed-software-listing-team-member-wrapper">
                                <div className="detailed-software-listing-team-member">4.9/5</div>
                              </div>
                              <div className="detailed-software-contact-role">
                                <div className="detailed-software-supporting-text12">99,999 reviews</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="detailed-software-agent-details-icon">
                          <img className="detailed-software-icon4" alt="" src={icon1} />

                          <div className="detailed-software-agent-extra-info">
                            <div className="detailed-software-listing-detail-item-pin-wrapper">
                              <div className="detailed-software-breadcrumb-items">
                                <div className="detailed-software-listing-short-info">$9,999,999.99</div>
                                <div className="detailed-software-supporting-text-wrapper">
                                  <div className="detailed-software-supporting-text13">USD-Per Unit</div>
                                </div>
                              </div>
                            </div>
                            <img
                              className="detailed-software-divider-icon"
                              alt=""
                              src={divider4}
                            />

                            <div className="detailed-software-another-agent-info">
                              <div className="detailed-software-detail-items">
                                <img
                                  className="detailed-software-footer-social-child"
                                  alt=""
                                  src={group_58_2x}
                                />

                                <div className="detailed-software-listing-short-detail-item-pin-parent">
                                  <div className="detailed-software-listing-short-detail-item-pin">
                                    <div className="detailed-software-name">Olivia Rhye</div>
                                  </div>
                                  <div className="detailed-software-role1">Business Name</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="detailed-software-testiomonial-carousel-arrow2">
                    <img
                      className="detailed-software-arrow-left-icon"
                      alt=""
                      src={arrowleft}
                    />
                  </div>
                </div>
                <div className="detailed-software-testiomonial-carousel-arrow3">
                  <img
                    className="detailed-software-arrow-left-icon"
                    alt=""
                    src={arrowright}
                  />
                </div>
              </div>
            </div>
            <div className="detailed-software-listing-image-grid4">
              <div className="detailed-software-heading-parent3">
                <div className="detailed-software-heading36">Related Spares</div>
                <div className="detailed-software-testimonial-description">
                  <div className="detailed-software-description">View All</div>
                </div>
              </div>
            </div>
            <div className="detailed-software-listing-image-grid5">
              <div className="detailed-software-frame-parent4">
                <div className="detailed-software-frame-parent5">
                  <div className="detailed-software-rectangle-parent10">
                    <div className="detailed-software-frame-child12"></div>
                    <div className="detailed-software-listing-card-badge-overlay">
                      <img
                        className="detailed-software-listing-card-badge-overlay-child"
                        alt=""
                        src={rectangle_95_2x}
                      />

                      <div className="detailed-software-image-overlay">
                        <div className="detailed-software-badge4">
                          <div className="detailed-software-listing-card-price">Assured</div>
                        </div>
                        <div className="detailed-software-short-listing-team">
                          <div className="detailed-software-badge5">
                            <div className="detailed-software-listing-card-radar">ID# AXJYN</div>
                          </div>
                          <div className="detailed-software-buttonsbutton11">
                            <img
                              className="detailed-software-magnifier-1-icon"
                              alt=""
                              src={radar_1_1_2x}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="detailed-software-listing-detail-item">
                      <div className="detailed-software-message-content">
                        <div className="detailed-software-text-frame">
                          <div className="detailed-software-text42">
                            New / Surplus 2021 Philips Health-care HD11 XE
                            (P/N:23A...
                          </div>
                        </div>
                        <div className="detailed-software-listing-detail-item-value">
                          <div className="detailed-software-listing-detail-item-pin-wrapper">
                            <div className="detailed-software-heading-container">
                              <div className="detailed-software-listing-detail-item-location">
                                <div className="detailed-software-listing-detail-item-date-parent">
                                  <div className="detailed-software-listing-detail-item-date">
                                    <img
                                      className="detailed-software-chevron-right-icon2"
                                      alt=""
                                      src={markerpin021}
                                    />
                                  </div>
                                  <div className="detailed-software-location">India, New Delhi</div>
                                </div>
                                <div className="detailed-software-listing-detail-item-date-parent">
                                  <div className="detailed-software-listing-detail-item-date">
                                    <img
                                      className="detailed-software-chevron-right-icon2"
                                      alt=""
                                      src={calendar1}
                                    />
                                  </div>
                                  <div className="detailed-software-type-of-work">April 09, 2023</div>
                                </div>
                              </div>
                              <div className="detailed-software-listing-team">
                                <div className="detailed-software-listing-team-member-wrapper">
                                  <div className="detailed-software-listing-team-member">4.9/5</div>
                                </div>
                                <div className="detailed-software-contact-role">
                                  <div className="detailed-software-supporting-text12">
                                    99,999 reviews
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="detailed-software-listing">
                            <div className="detailed-software-breadcrumb-items">
                              <div className="detailed-software-listing-short-info">$9,999,999.99</div>
                              <div className="detailed-software-supporting-text-wrapper">
                                <div className="detailed-software-supporting-text13">USD-Per Unit</div>
                              </div>
                            </div>
                          </div>
                          <img
                            className="detailed-software-divider-icon"
                            alt=""
                            src={divider4}
                          />

                          <div className="detailed-software-listing-detail-item-value-inner">
                            <div className="detailed-software-footer-social-parent">
                              <div className="detailed-software-footer-social">
                                <img
                                  className="detailed-software-footer-social-child"
                                  alt=""
                                  src={group_58_2x}
                                />

                                <div className="detailed-software-listing-short-detail-item-pin-parent">
                                  <div className="detailed-software-listing-short-detail-item-pin">
                                    <div className="detailed-software-name">Olivia Rhye</div>
                                  </div>
                                  <div className="detailed-software-role1">Business Name</div>
                                </div>
                              </div>
                              <div className="detailed-software-listing-short-detail-item-date">
                                <img
                                  className="detailed-software-icon1"
                                  alt=""
                                  src={icon1}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="detailed-software-rectangle-parent10">
                    <div className="detailed-software-frame-child12"></div>
                    <div className="detailed-software-listing-card-badge-overlay">
                      <img
                        className="detailed-software-listing-card-badge-overlay-child"
                        alt=""
                        src={rectangle_95_2x}
                      />

                      <div className="detailed-software-image-overlay">
                        <div className="detailed-software-badge4">
                          <div className="detailed-software-listing-card-price">Assured</div>
                        </div>
                        <div className="detailed-software-short-listing-team">
                          <div className="detailed-software-badge5">
                            <div className="detailed-software-listing-card-radar">ID# AXJYN</div>
                          </div>
                          <div className="detailed-software-buttonsbutton11">
                            <img
                              className="detailed-software-magnifier-1-icon"
                              alt=""
                              src={radar_1_1_2x}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="detailed-software-listing-detail-item">
                      <div className="detailed-software-message-content">
                        <div className="detailed-software-text-frame">
                          <div className="detailed-software-text42">
                            New / Surplus 2021 Philips Health-care HD11 XE
                            (P/N:23A...
                          </div>
                        </div>
                        <div className="detailed-software-listing-detail-item-value">
                          <div className="detailed-software-listing-detail-item-pin-wrapper">
                            <div className="detailed-software-heading-container">
                              <div className="detailed-software-listing-detail-item-location">
                                <div className="detailed-software-listing-detail-item-date-parent">
                                  <div className="detailed-software-listing-detail-item-date">
                                    <img
                                      className="detailed-software-chevron-right-icon2"
                                      alt=""
                                      src={markerpin021}
                                    />
                                  </div>
                                  <div className="detailed-software-location">India, New Delhi</div>
                                </div>
                                <div className="detailed-software-listing-detail-item-date-parent">
                                  <div className="detailed-software-listing-detail-item-date">
                                    <img
                                      className="detailed-software-chevron-right-icon2"
                                      alt=""
                                      src={calendar1}
                                    />
                                  </div>
                                  <div className="detailed-software-type-of-work">April 09, 2023</div>
                                </div>
                              </div>
                              <div className="detailed-software-listing-team">
                                <div className="detailed-software-listing-team-member-wrapper">
                                  <div className="detailed-software-listing-team-member">4.9/5</div>
                                </div>
                                <div className="detailed-software-contact-role">
                                  <div className="detailed-software-supporting-text12">
                                    99,999 reviews
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="detailed-software-listing">
                            <div className="detailed-software-breadcrumb-items">
                              <div className="detailed-software-listing-short-info">$9,999,999.99</div>
                              <div className="detailed-software-supporting-text-wrapper">
                                <div className="detailed-software-supporting-text13">USD-Per Unit</div>
                              </div>
                            </div>
                          </div>
                          <img
                            className="detailed-software-divider-icon"
                            alt=""
                            src={divider4}
                          />

                          <div className="detailed-software-listing-detail-item-value-inner">
                            <div className="detailed-software-footer-social-parent">
                              <div className="detailed-software-footer-social">
                                <img
                                  className="detailed-software-footer-social-child"
                                  alt=""
                                  src={group_58_2x}
                                />

                                <div className="detailed-software-listing-short-detail-item-pin-parent">
                                  <div className="detailed-software-listing-short-detail-item-pin">
                                    <div className="detailed-software-name">Olivia Rhye</div>
                                  </div>
                                  <div className="detailed-software-role1">Business Name</div>
                                </div>
                              </div>
                              <div className="detailed-software-listing-short-detail-item-date">
                                <img
                                  className="detailed-software-icon1"
                                  alt=""
                                  src={icon1}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="detailed-software-rectangle-parent10">
                    <div className="detailed-software-frame-child12"></div>
                    <div className="detailed-software-listing-card-badge-overlay">
                      <img
                        className="detailed-software-listing-card-badge-overlay-child"
                        alt=""
                        src={rectangle_95_2x}
                      />

                      <div className="detailed-software-image-overlay">
                        <div className="detailed-software-badge4">
                          <div className="detailed-software-listing-card-price">Assured</div>
                        </div>
                        <div className="detailed-software-short-listing-team">
                          <div className="detailed-software-badge5">
                            <div className="detailed-software-listing-card-radar">ID# AXJYN</div>
                          </div>
                          <div className="detailed-software-buttonsbutton11">
                            <img
                              className="detailed-software-magnifier-1-icon"
                              alt=""
                              src={radar_1_1_2x}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="detailed-software-listing-detail-item">
                      <div className="detailed-software-message-content">
                        <div className="detailed-software-text-frame">
                          <div className="detailed-software-text42">
                            New / Surplus 2021 Philips Health-care HD11 XE
                            (P/N:23A...
                          </div>
                        </div>
                        <div className="detailed-software-listing-detail-item-value">
                          <div className="detailed-software-listing-detail-item-pin-wrapper">
                            <div className="detailed-software-heading-container">
                              <div className="detailed-software-listing-detail-item-location">
                                <div className="detailed-software-listing-detail-item-date-parent">
                                  <div className="detailed-software-listing-detail-item-date">
                                    <img
                                      className="detailed-software-chevron-right-icon2"
                                      alt=""
                                      src={markerpin021}
                                    />
                                  </div>
                                  <div className="detailed-software-location">India, New Delhi</div>
                                </div>
                                <div className="detailed-software-listing-detail-item-date-parent">
                                  <div className="detailed-software-listing-detail-item-date">
                                    <img
                                      className="detailed-software-chevron-right-icon2"
                                      alt=""
                                      src={calendar1}
                                    />
                                  </div>
                                  <div className="detailed-software-type-of-work">April 09, 2023</div>
                                </div>
                              </div>
                              <div className="detailed-software-listing-team">
                                <div className="detailed-software-listing-team-member-wrapper">
                                  <div className="detailed-software-listing-team-member">4.9/5</div>
                                </div>
                                <div className="detailed-software-contact-role">
                                  <div className="detailed-software-supporting-text12">
                                    99,999 reviews
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="detailed-software-listing">
                            <div className="detailed-software-breadcrumb-items">
                              <div className="detailed-software-listing-short-info">$9,999,999.99</div>
                              <div className="detailed-software-supporting-text-wrapper">
                                <div className="detailed-software-supporting-text13">USD-Per Unit</div>
                              </div>
                            </div>
                          </div>
                          <img
                            className="detailed-software-divider-icon"
                            alt=""
                            src={divider4}
                          />

                          <div className="detailed-software-listing-detail-item-value-inner">
                            <div className="detailed-software-footer-social-parent">
                              <div className="detailed-software-footer-social">
                                <img
                                  className="detailed-software-footer-social-child"
                                  alt=""
                                  src={group_58_2x}
                                />

                                <div className="detailed-software-listing-short-detail-item-pin-parent">
                                  <div className="detailed-software-listing-short-detail-item-pin">
                                    <div className="detailed-software-name">Olivia Rhye</div>
                                  </div>
                                  <div className="detailed-software-role1">Business Name</div>
                                </div>
                              </div>
                              <div className="detailed-software-listing-short-detail-item-date">
                                <img
                                  className="detailed-software-icon1"
                                  alt=""
                                  src={icon1}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="detailed-software-rectangle-parent10">
                    <div className="detailed-software-frame-child12"></div>
                    <div className="detailed-software-listing-card-badge-overlay">
                      <img
                        className="detailed-software-listing-card-badge-overlay-child"
                        alt=""
                        src={rectangle_95_2x}
                      />

                      <div className="detailed-software-image-overlay3">
                        <div className="detailed-software-badge4">
                          <div className="detailed-software-listing-card-price">Assured</div>
                        </div>
                        <div className="detailed-software-badge-group">
                          <div className="detailed-software-badge5">
                            <div className="detailed-software-listing-card-radar">ID# AXJYN</div>
                          </div>
                          <div className="detailed-software-buttonsbutton14">
                            <img
                              className="detailed-software-magnifier-1-icon"
                              alt=""
                              src={radar_1_1_2x}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="detailed-software-listing-detail-item">
                      <div className="detailed-software-message-content">
                        <div className="detailed-software-text-frame">
                          <div className="detailed-software-text42">
                            New / Surplus 2021 Philips Health-care HD11 XE
                            (P/N:23A...
                          </div>
                        </div>
                        <div className="detailed-software-work-four-wrapper">
                          <div className="detailed-software-heading-container">
                            <div className="detailed-software-listing-detail-item-location">
                              <div className="detailed-software-listing-detail-item-date-parent">
                                <div className="detailed-software-listing-detail-item-date">
                                  <img
                                    className="detailed-software-chevron-right-icon2"
                                    alt=""
                                    src={markerpin021}
                                  />
                                </div>
                                <div className="detailed-software-location">India, New Delhi</div>
                              </div>
                              <div className="detailed-software-listing-detail-item-date-parent">
                                <div className="detailed-software-listing-detail-item-date">
                                  <img
                                    className="detailed-software-chevron-right-icon2"
                                    alt=""
                                    src={calendar1}
                                  />
                                </div>
                                <div className="detailed-software-type-of-work">April 09, 2023</div>
                              </div>
                            </div>
                            <div className="detailed-software-listing-team">
                              <div className="detailed-software-listing-team-member-wrapper">
                                <div className="detailed-software-listing-team-member">4.9/5</div>
                              </div>
                              <div className="detailed-software-contact-role">
                                <div className="detailed-software-supporting-text12">99,999 reviews</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="detailed-software-agent-details-icon">
                          <img className="detailed-software-icon4" alt="" src={icon1} />

                          <div className="detailed-software-agent-extra-info">
                            <div className="detailed-software-listing-detail-item-pin-wrapper">
                              <div className="detailed-software-breadcrumb-items">
                                <div className="detailed-software-listing-short-info">$9,999,999.99</div>
                                <div className="detailed-software-supporting-text-wrapper">
                                  <div className="detailed-software-supporting-text13">USD-Per Unit</div>
                                </div>
                              </div>
                            </div>
                            <img
                              className="detailed-software-divider-icon"
                              alt=""
                              src={divider4}
                            />

                            <div className="detailed-software-another-agent-info">
                              <div className="detailed-software-detail-items">
                                <img
                                  className="detailed-software-footer-social-child"
                                  alt=""
                                  src={group_58_2x}
                                />

                                <div className="detailed-software-listing-short-detail-item-pin-parent">
                                  <div className="detailed-software-listing-short-detail-item-pin">
                                    <div className="detailed-software-name">Olivia Rhye</div>
                                  </div>
                                  <div className="detailed-software-role1">Business Name</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="detailed-software-testiomonial-carousel-arrow4">
                    <img
                      className="detailed-software-arrow-left-icon"
                      alt=""
                      src={arrowleft}
                    />
                  </div>
                </div>
                <div className="detailed-software-testiomonial-carousel-arrow5">
                  <img
                    className="detailed-software-arrow-left-icon"
                    alt=""
                    src={arrowright}
                  />
                </div>
              </div>
            </div>
            <div className="detailed-software-listing-image-grid4">
              <div className="detailed-software-heading-parent3">
                <div className="detailed-software-heading38">Related Services</div>
                <div className="detailed-software-testimonial-description">
                  <div className="detailed-software-description">View All</div>
                </div>
              </div>
            </div>
            <div className="detailed-software-listing-image-grid5">
              <div className="detailed-software-frame-parent4">
                <div className="detailed-software-frame-parent5">
                  <div className="detailed-software-rectangle-parent10">
                    <div className="detailed-software-frame-child12"></div>
                    <div className="detailed-software-listing-card-badge-overlay">
                      <img
                        className="detailed-software-listing-card-badge-overlay-child"
                        alt=""
                        src={rectangle_95_2x}
                      />

                      <div className="detailed-software-image-overlay">
                        <div className="detailed-software-badge4">
                          <div className="detailed-software-listing-card-price">Assured</div>
                        </div>
                        <div className="detailed-software-short-listing-team">
                          <div className="detailed-software-badge5">
                            <div className="detailed-software-listing-card-radar">ID# AXJYN</div>
                          </div>
                          <div className="detailed-software-buttonsbutton11">
                            <img
                              className="detailed-software-magnifier-1-icon"
                              alt=""
                              src={radar_1_1_2x}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="detailed-software-listing-detail-item">
                      <div className="detailed-software-message-content">
                        <div className="detailed-software-text-frame">
                          <div className="detailed-software-text42">
                            New / Surplus 2021 Philips Health-care HD11 XE
                            (P/N:23A...
                          </div>
                        </div>
                        <div className="detailed-software-listing-detail-item-value">
                          <div className="detailed-software-listing-detail-item-pin-wrapper">
                            <div className="detailed-software-heading-container">
                              <div className="detailed-software-listing-detail-item-location">
                                <div className="detailed-software-listing-detail-item-date-parent">
                                  <div className="detailed-software-listing-detail-item-date">
                                    <img
                                      className="detailed-software-chevron-right-icon2"
                                      alt=""
                                      src={markerpin021}
                                    />
                                  </div>
                                  <div className="detailed-software-location">India, New Delhi</div>
                                </div>
                                <div className="detailed-software-listing-detail-item-date-parent">
                                  <div className="detailed-software-listing-detail-item-date">
                                    <img
                                      className="detailed-software-chevron-right-icon2"
                                      alt=""
                                      src={calendar1}
                                    />
                                  </div>
                                  <div className="detailed-software-type-of-work">April 09, 2023</div>
                                </div>
                              </div>
                              <div className="detailed-software-listing-team">
                                <div className="detailed-software-listing-team-member-wrapper">
                                  <div className="detailed-software-listing-team-member">4.9/5</div>
                                </div>
                                <div className="detailed-software-contact-role">
                                  <div className="detailed-software-supporting-text12">
                                    99,999 reviews
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="detailed-software-listing">
                            <div className="detailed-software-breadcrumb-items">
                              <div className="detailed-software-listing-short-info">$9,999,999.99</div>
                              <div className="detailed-software-supporting-text-wrapper">
                                <div className="detailed-software-supporting-text13">USD-Per Unit</div>
                              </div>
                            </div>
                          </div>
                          <img
                            className="detailed-software-divider-icon"
                            alt=""
                            src={divider4}
                          />

                          <div className="detailed-software-listing-detail-item-value-inner">
                            <div className="detailed-software-footer-social-parent">
                              <div className="detailed-software-footer-social">
                                <img
                                  className="detailed-software-footer-social-child"
                                  alt=""
                                  src={group_58_2x}
                                />

                                <div className="detailed-software-listing-short-detail-item-pin-parent">
                                  <div className="detailed-software-listing-short-detail-item-pin">
                                    <div className="detailed-software-name">Olivia Rhye</div>
                                  </div>
                                  <div className="detailed-software-role1">Business Name</div>
                                </div>
                              </div>
                              <div className="detailed-software-listing-short-detail-item-date">
                                <img
                                  className="detailed-software-icon1"
                                  alt=""
                                  src={icon1}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="detailed-software-rectangle-parent10">
                    <div className="detailed-software-frame-child12"></div>
                    <div className="detailed-software-listing-card-badge-overlay">
                      <img
                        className="detailed-software-listing-card-badge-overlay-child"
                        alt=""
                        src={rectangle_95_2x}
                      />

                      <div className="detailed-software-image-overlay">
                        <div className="detailed-software-badge4">
                          <div className="detailed-software-listing-card-price">Assured</div>
                        </div>
                        <div className="detailed-software-short-listing-team">
                          <div className="detailed-software-badge5">
                            <div className="detailed-software-listing-card-radar">ID# AXJYN</div>
                          </div>
                          <div className="detailed-software-buttonsbutton11">
                            <img
                              className="detailed-software-magnifier-1-icon"
                              alt=""
                              src={radar_1_1_2x}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="detailed-software-listing-detail-item">
                      <div className="detailed-software-message-content">
                        <div className="detailed-software-text-frame">
                          <div className="detailed-software-text42">
                            New / Surplus 2021 Philips Health-care HD11 XE
                            (P/N:23A...
                          </div>
                        </div>
                        <div className="detailed-software-listing-detail-item-value">
                          <div className="detailed-software-listing-detail-item-pin-wrapper">
                            <div className="detailed-software-heading-container">
                              <div className="detailed-software-listing-detail-item-location">
                                <div className="detailed-software-listing-detail-item-date-parent">
                                  <div className="detailed-software-listing-detail-item-date">
                                    <img
                                      className="detailed-software-chevron-right-icon2"
                                      alt=""
                                      src={markerpin021}
                                    />
                                  </div>
                                  <div className="detailed-software-location">India, New Delhi</div>
                                </div>
                                <div className="detailed-software-listing-detail-item-date-parent">
                                  <div className="detailed-software-listing-detail-item-date">
                                    <img
                                      className="detailed-software-chevron-right-icon2"
                                      alt=""
                                      src={calendar1}
                                    />
                                  </div>
                                  <div className="detailed-software-type-of-work">April 09, 2023</div>
                                </div>
                              </div>
                              <div className="detailed-software-listing-team">
                                <div className="detailed-software-listing-team-member-wrapper">
                                  <div className="detailed-software-listing-team-member">4.9/5</div>
                                </div>
                                <div className="detailed-software-contact-role">
                                  <div className="detailed-software-supporting-text12">
                                    99,999 reviews
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="detailed-software-listing">
                            <div className="detailed-software-breadcrumb-items">
                              <div className="detailed-software-listing-short-info">$9,999,999.99</div>
                              <div className="detailed-software-supporting-text-wrapper">
                                <div className="detailed-software-supporting-text13">USD-Per Unit</div>
                              </div>
                            </div>
                          </div>
                          <img
                            className="detailed-software-divider-icon"
                            alt=""
                            src={divider4}
                          />

                          <div className="detailed-software-listing-detail-item-value-inner">
                            <div className="detailed-software-footer-social-parent">
                              <div className="detailed-software-footer-social">
                                <img
                                  className="detailed-software-footer-social-child"
                                  alt=""
                                  src={group_58_2x}
                                />

                                <div className="detailed-software-listing-short-detail-item-pin-parent">
                                  <div className="detailed-software-listing-short-detail-item-pin">
                                    <div className="detailed-software-name">Olivia Rhye</div>
                                  </div>
                                  <div className="detailed-software-role1">Business Name</div>
                                </div>
                              </div>
                              <div className="detailed-software-listing-short-detail-item-date">
                                <img
                                  className="detailed-software-icon1"
                                  alt=""
                                  src={icon1}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="detailed-software-rectangle-parent10">
                    <div className="detailed-software-frame-child12"></div>
                    <div className="detailed-software-listing-card-badge-overlay">
                      <img
                        className="detailed-software-listing-card-badge-overlay-child"
                        alt=""
                        src={rectangle_95_2x}
                      />

                      <div className="detailed-software-image-overlay">
                        <div className="detailed-software-badge4">
                          <div className="detailed-software-listing-card-price">Assured</div>
                        </div>
                        <div className="detailed-software-short-listing-team">
                          <div className="detailed-software-badge5">
                            <div className="detailed-software-listing-card-radar">ID# AXJYN</div>
                          </div>
                          <div className="detailed-software-buttonsbutton11">
                            <img
                              className="detailed-software-magnifier-1-icon"
                              alt=""
                              src={radar_1_1_2x}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="detailed-software-listing-detail-item">
                      <div className="detailed-software-message-content">
                        <div className="detailed-software-text-frame">
                          <div className="detailed-software-text42">
                            New / Surplus 2021 Philips Health-care HD11 XE
                            (P/N:23A...
                          </div>
                        </div>
                        <div className="detailed-software-listing-detail-item-value">
                          <div className="detailed-software-listing-detail-item-pin-wrapper">
                            <div className="detailed-software-heading-container">
                              <div className="detailed-software-listing-detail-item-location">
                                <div className="detailed-software-listing-detail-item-date-parent">
                                  <div className="detailed-software-listing-detail-item-date">
                                    <img
                                      className="detailed-software-chevron-right-icon2"
                                      alt=""
                                      src={markerpin021}
                                    />
                                  </div>
                                  <div className="detailed-software-location">India, New Delhi</div>
                                </div>
                                <div className="detailed-software-listing-detail-item-date-parent">
                                  <div className="detailed-software-listing-detail-item-date">
                                    <img
                                      className="detailed-software-chevron-right-icon2"
                                      alt=""
                                      src={calendar1}
                                    />
                                  </div>
                                  <div className="detailed-software-type-of-work">April 09, 2023</div>
                                </div>
                              </div>
                              <div className="detailed-software-listing-team">
                                <div className="detailed-software-listing-team-member-wrapper">
                                  <div className="detailed-software-listing-team-member">4.9/5</div>
                                </div>
                                <div className="detailed-software-contact-role">
                                  <div className="detailed-software-supporting-text12">
                                    99,999 reviews
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="detailed-software-listing">
                            <div className="detailed-software-breadcrumb-items">
                              <div className="detailed-software-listing-short-info">$9,999,999.99</div>
                              <div className="detailed-software-supporting-text-wrapper">
                                <div className="detailed-software-supporting-text13">USD-Per Unit</div>
                              </div>
                            </div>
                          </div>
                          <img
                            className="detailed-software-divider-icon"
                            alt=""
                            src={divider4}
                          />

                          <div className="detailed-software-listing-detail-item-value-inner">
                            <div className="detailed-software-footer-social-parent">
                              <div className="detailed-software-footer-social">
                                <img
                                  className="detailed-software-footer-social-child"
                                  alt=""
                                  src={group_58_2x}
                                />

                                <div className="detailed-software-listing-short-detail-item-pin-parent">
                                  <div className="detailed-software-listing-short-detail-item-pin">
                                    <div className="detailed-software-name">Olivia Rhye</div>
                                  </div>
                                  <div className="detailed-software-role1">Business Name</div>
                                </div>
                              </div>
                              <div className="detailed-software-listing-short-detail-item-date">
                                <img
                                  className="detailed-software-icon1"
                                  alt=""
                                  src={icon1}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="detailed-software-rectangle-parent10">
                    <div className="detailed-software-frame-child12"></div>
                    <div className="detailed-software-rectangle-parent30">
                      <img
                        className="detailed-software-listing-card-badge-overlay-child"
                        alt=""
                        src={rectangle_95_2x}
                      />

                      <div className="detailed-software-image-overlay3">
                        <div className="detailed-software-badge4">
                          <div className="detailed-software-listing-card-price">Assured</div>
                        </div>
                        <div className="detailed-software-badge-group">
                          <div className="detailed-software-badge5">
                            <div className="detailed-software-listing-card-radar">ID# AXJYN</div>
                          </div>
                          <div className="detailed-software-buttonsbutton14">
                            <img
                              className="detailed-software-magnifier-1-icon"
                              alt=""
                              src={radar_1_1_2x}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="detailed-software-listing-detail-item">
                      <div className="detailed-software-message-content">
                        <div className="detailed-software-text-frame">
                          <div className="detailed-software-text42">
                            New / Surplus 2021 Philips Health-care HD11 XE
                            (P/N:23A...
                          </div>
                        </div>
                        <div className="detailed-software-icon-group">
                          <img className="detailed-software-icon4" alt="" src={icon1} />

                          <div className="detailed-software-message-content">
                            <div className="detailed-software-listing-detail-item-pin-wrapper">
                              <div className="detailed-software-listing-detail-item-location">
                                <div className="detailed-software-listing-detail-item-date-parent">
                                  <div className="detailed-software-listing-detail-item-date">
                                    <img
                                      className="detailed-software-chevron-right-icon2"
                                      alt=""
                                      src={markerpin021}
                                    />
                                  </div>
                                  <div className="detailed-software-location">India, New Delhi</div>
                                </div>
                                <div className="detailed-software-listing-detail-item-date-parent">
                                  <div className="detailed-software-listing-detail-item-date">
                                    <img
                                      className="detailed-software-chevron-right-icon2"
                                      alt=""
                                      src={calendar1}
                                    />
                                  </div>
                                  <div className="detailed-software-type-of-work">April 09, 2023</div>
                                </div>
                              </div>
                            </div>
                            <div className="detailed-software-supporting-work-five">
                              <div className="detailed-software-breadcrumb-items">
                                <div className="detailed-software-listing-team-member-wrapper">
                                  <div className="detailed-software-listing-team-member">4.9/5</div>
                                </div>
                                <div className="detailed-software-contact-role">
                                  <div className="detailed-software-supporting-text12">
                                    99,999 reviews
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="detailed-software-supporting-six-wrapper">
                              <div className="detailed-software-breadcrumb-items">
                                <div className="detailed-software-listing-short-info">$9,999,999.99</div>
                                <div className="detailed-software-supporting-text-wrapper">
                                  <div className="detailed-software-supporting-text13">USD-Per Unit</div>
                                </div>
                              </div>
                            </div>
                            <div className="detailed-software-divider-container">
                              <img
                                className="detailed-software-divider-icon3"
                                alt=""
                                src={divider4}
                              />
                            </div>
                            <div className="detailed-software-team-info-four">
                              <div className="detailed-software-detail-items">
                                <img
                                  className="detailed-software-footer-social-child"
                                  alt=""
                                  src={group_58_2x}
                                />

                                <div className="detailed-software-listing-short-detail-item-pin-parent">
                                  <div className="detailed-software-listing-short-detail-item-pin">
                                    <div className="detailed-software-name">Olivia Rhye</div>
                                  </div>
                                  <div className="detailed-software-role1">Business Name</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="detailed-software-testiomonial-carousel-arrow6">
                    <img
                      className="detailed-software-arrow-left-icon"
                      alt=""
                      src={arrowleft}
                    />
                  </div>
                </div>
                <div className="detailed-software-testiomonial-carousel-arrow7">
                  <img
                    className="detailed-software-arrow-left-icon"
                    alt=""
                    src={arrowright}
                  />
                </div>
              </div>
            </div>

            <img className="detailed-software-divider-icon24" alt="" src={divider5} />

            {/* <div className="detailed-software-actions1">
          <div className="detailed-software-button2">
            <img
              className="detailed-software-placeholder-icon17"
              alt=""
              src="/detailed_listing/placeholder3.svg"
            />
  
            <div className="detailed-software-text-padding4">
              <div className="detailed-software-text98">Tertiary</div>
            </div>
            <img
              className="detailed-software-placeholder-icon17"
              alt=""
              src="/detailed_listing/placeholder3.svg"
            />
          </div>
          <div className="detailed-software-button3">
            <img
              className="detailed-software-placeholder-icon17"
              alt=""
              src="/detailed_listing/placeholder4.svg"
            />
  
            <div className="detailed-software-text-padding5">
              <div className="detailed-software-text98">Secondary</div>
            </div>
            <img
              className="detailed-software-placeholder-icon17"
              alt=""
              src="/detailed_listing/placeholder4.svg"
            />
          </div>
          <div className="detailed-software-button4">
            <img
              className="detailed-software-placeholder-icon17"
              alt=""
              src={placeholder1}
            />
  
            <div className="detailed-software-text-padding5">
              <div className="detailed-software-text98">Secondary</div>
            </div>
            <img
              className="detailed-software-placeholder-icon17"
              alt=""
              src={placeholder1}
            />
          </div>
          <div className="detailed-software-button5">
            <img
              className="detailed-software-placeholder-icon17"
              alt=""
              src={placeholder2}
            />
  
            <div className="detailed-software-text-padding7">
              <div className="detailed-software-text98">Primary</div>
            </div>
            <img
              className="detailed-software-placeholder-icon17"
              alt=""
              src={placeholder2}
            />
          </div>
        </div> */}
            <div className="detailed-software-listing-image-grid4">
              <div className="detailed-software-heading-parent3">
                <div className="detailed-software-heading36">Related Software</div>
                <div className="detailed-software-testimonial-description">
                  <div className="detailed-software-description">View All</div>
                </div>
              </div>
            </div>
            <div className="detailed-software-listing-image-grid11">
              <div className="detailed-software-frame-parent4">
                <div className="detailed-software-frame-parent5">
                  <div className="detailed-software-rectangle-parent10">
                    <div className="detailed-software-frame-child12"></div>
                    <div className="detailed-software-rectangle-parent30">
                      <img
                        className="detailed-software-listing-card-badge-overlay-child"
                        alt=""
                        src={rectangle_95_2x}
                      />

                      <div className="detailed-software-image-overlay">
                        <div className="detailed-software-badge4">
                          <div className="detailed-software-listing-card-price">Assured</div>
                        </div>
                        <div className="detailed-software-short-listing-team">
                          <div className="detailed-software-badge5">
                            <div className="detailed-software-listing-card-radar">ID# AXJYN</div>
                          </div>
                          <div className="detailed-software-buttonsbutton11">
                            <img
                              className="detailed-software-magnifier-1-icon"
                              alt=""
                              src={radar_1_1_2x}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="detailed-software-listing-detail-item">
                      <div className="detailed-software-message-content">
                        <div className="detailed-software-text-frame">
                          <div className="detailed-software-text42">
                            New / Surplus 2021 Philips Health-care HD11 XE
                            (P/N:23A...
                          </div>
                        </div>
                        <div className="detailed-software-listing-detail-item-value">
                          <div className="detailed-software-listing-detail-item-pin-wrapper">
                            <div className="detailed-software-heading-container">
                              <div className="detailed-software-listing-detail-item-location">
                                <div className="detailed-software-listing-detail-item-date-parent">
                                  <div className="detailed-software-listing-detail-item-date">
                                    <img
                                      className="detailed-software-chevron-right-icon2"
                                      alt=""
                                      src={markerpin021}
                                    />
                                  </div>
                                  <div className="detailed-software-location">India, New Delhi</div>
                                </div>
                                <div className="detailed-software-listing-detail-item-date-parent">
                                  <div className="detailed-software-listing-detail-item-date">
                                    <img
                                      className="detailed-software-chevron-right-icon2"
                                      alt=""
                                      src={calendar1}
                                    />
                                  </div>
                                  <div className="detailed-software-type-of-work">April 09, 2023</div>
                                </div>
                              </div>
                              <div className="detailed-software-listing-team">
                                <div className="detailed-software-listing-team-member-wrapper">
                                  <div className="detailed-software-listing-team-member">4.9/5</div>
                                </div>
                                <div className="detailed-software-contact-role">
                                  <div className="detailed-software-supporting-text12">
                                    99,999 reviews
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="detailed-software-listing">
                            <div className="detailed-software-breadcrumb-items">
                              <div className="detailed-software-listing-short-info">$9,999,999.99</div>
                              <div className="detailed-software-supporting-text-wrapper">
                                <div className="detailed-software-supporting-text13">USD-Per Unit</div>
                              </div>
                            </div>
                          </div>
                          <img
                            className="detailed-software-divider-icon"
                            alt=""
                            src={divider4}
                          />

                          <div className="detailed-software-listing-detail-item-value-inner">
                            <div className="detailed-software-footer-social-parent">
                              <div className="detailed-software-footer-social">
                                <img
                                  className="detailed-software-footer-social-child"
                                  alt=""
                                  src={group_58_2x}
                                />

                                <div className="detailed-software-listing-short-detail-item-pin-parent">
                                  <div className="detailed-software-listing-short-detail-item-pin">
                                    <div className="detailed-software-name">Olivia Rhye</div>
                                  </div>
                                  <div className="detailed-software-role1">Business Name</div>
                                </div>
                              </div>
                              <div className="detailed-software-listing-short-detail-item-date">
                                <img
                                  className="detailed-software-icon1"
                                  alt=""
                                  src={icon1}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="detailed-software-rectangle-parent10">
                    <div className="detailed-software-frame-child12"></div>
                    <div className="detailed-software-rectangle-parent30">
                      <img
                        className="detailed-software-listing-card-badge-overlay-child"
                        alt=""
                        src={rectangle_95_2x}
                      />

                      <div className="detailed-software-image-overlay">
                        <div className="detailed-software-badge4">
                          <div className="detailed-software-listing-card-price">Assured</div>
                        </div>
                        <div className="detailed-software-short-listing-team">
                          <div className="detailed-software-badge5">
                            <div className="detailed-software-listing-card-radar">ID# AXJYN</div>
                          </div>
                          <div className="detailed-software-buttonsbutton11">
                            <img
                              className="detailed-software-magnifier-1-icon"
                              alt=""
                              src={radar_1_1_2x}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="detailed-software-listing-detail-item">
                      <div className="detailed-software-message-content">
                        <div className="detailed-software-text-frame">
                          <div className="detailed-software-text42">
                            New / Surplus 2021 Philips Health-care HD11 XE
                            (P/N:23A...
                          </div>
                        </div>
                        <div className="detailed-software-listing-detail-item-value">
                          <div className="detailed-software-listing-detail-item-pin-wrapper">
                            <div className="detailed-software-heading-container">
                              <div className="detailed-software-listing-detail-item-location">
                                <div className="detailed-software-listing-detail-item-date-parent">
                                  <div className="detailed-software-listing-detail-item-date">
                                    <img
                                      className="detailed-software-chevron-right-icon2"
                                      alt=""
                                      src={markerpin021}
                                    />
                                  </div>
                                  <div className="detailed-software-location">India, New Delhi</div>
                                </div>
                                <div className="detailed-software-listing-detail-item-date-parent">
                                  <div className="detailed-software-listing-detail-item-date">
                                    <img
                                      className="detailed-software-chevron-right-icon2"
                                      alt=""
                                      src={calendar1}
                                    />
                                  </div>
                                  <div className="detailed-software-type-of-work">April 09, 2023</div>
                                </div>
                              </div>
                              <div className="detailed-software-listing-team">
                                <div className="detailed-software-listing-team-member-wrapper">
                                  <div className="detailed-software-listing-team-member">4.9/5</div>
                                </div>
                                <div className="detailed-software-contact-role">
                                  <div className="detailed-software-supporting-text12">
                                    99,999 reviews
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="detailed-software-listing">
                            <div className="detailed-software-breadcrumb-items">
                              <div className="detailed-software-listing-short-info">$9,999,999.99</div>
                              <div className="detailed-software-supporting-text-wrapper">
                                <div className="detailed-software-supporting-text13">USD-Per Unit</div>
                              </div>
                            </div>
                          </div>
                          <img
                            className="detailed-software-divider-icon"
                            alt=""
                            src={divider4}
                          />

                          <div className="detailed-software-listing-detail-item-value-inner">
                            <div className="detailed-software-footer-social-parent">
                              <div className="detailed-software-footer-social">
                                <img
                                  className="detailed-software-footer-social-child"
                                  alt=""
                                  src={group_58_2x}
                                />

                                <div className="detailed-software-listing-short-detail-item-pin-parent">
                                  <div className="detailed-software-listing-short-detail-item-pin">
                                    <div className="detailed-software-name">Olivia Rhye</div>
                                  </div>
                                  <div className="detailed-software-role1">Business Name</div>
                                </div>
                              </div>
                              <div className="detailed-software-listing-short-detail-item-date">
                                <img
                                  className="detailed-software-icon1"
                                  alt=""
                                  src={icon1}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="detailed-software-rectangle-parent10">
                    <div className="detailed-software-frame-child12"></div>
                    <div className="detailed-software-rectangle-parent30">
                      <img
                        className="detailed-software-listing-card-badge-overlay-child"
                        alt=""
                        src={rectangle_95_2x}
                      />

                      <div className="detailed-software-image-overlay">
                        <div className="detailed-software-badge4">
                          <div className="detailed-software-listing-card-price">Assured</div>
                        </div>
                        <div className="detailed-software-short-listing-team">
                          <div className="detailed-software-badge5">
                            <div className="detailed-software-listing-card-radar">ID# AXJYN</div>
                          </div>
                          <div className="detailed-software-buttonsbutton11">
                            <img
                              className="detailed-software-magnifier-1-icon"
                              alt=""
                              src={radar_1_1_2x}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="detailed-software-listing-detail-item">
                      <div className="detailed-software-message-content">
                        <div className="detailed-software-text-frame">
                          <div className="detailed-software-text42">
                            New / Surplus 2021 Philips Health-care HD11 XE
                            (P/N:23A...
                          </div>
                        </div>
                        <div className="detailed-software-listing-detail-item-value">
                          <div className="detailed-software-listing-detail-item-pin-wrapper">
                            <div className="detailed-software-heading-container">
                              <div className="detailed-software-listing-detail-item-location">
                                <div className="detailed-software-listing-detail-item-date-parent">
                                  <div className="detailed-software-listing-detail-item-date">
                                    <img
                                      className="detailed-software-chevron-right-icon2"
                                      alt=""
                                      src={markerpin021}
                                    />
                                  </div>
                                  <div className="detailed-software-location">India, New Delhi</div>
                                </div>
                                <div className="detailed-software-listing-detail-item-date-parent">
                                  <div className="detailed-software-listing-detail-item-date">
                                    <img
                                      className="detailed-software-chevron-right-icon2"
                                      alt=""
                                      src={calendar1}
                                    />
                                  </div>
                                  <div className="detailed-software-type-of-work">April 09, 2023</div>
                                </div>
                              </div>
                              <div className="detailed-software-listing-team">
                                <div className="detailed-software-listing-team-member-wrapper">
                                  <div className="detailed-software-listing-team-member">4.9/5</div>
                                </div>
                                <div className="detailed-software-contact-role">
                                  <div className="detailed-software-supporting-text12">
                                    99,999 reviews
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="detailed-software-listing">
                            <div className="detailed-software-breadcrumb-items">
                              <div className="detailed-software-listing-short-info">$9,999,999.99</div>
                              <div className="detailed-software-supporting-text-wrapper">
                                <div className="detailed-software-supporting-text13">USD-Per Unit</div>
                              </div>
                            </div>
                          </div>
                          <img
                            className="detailed-software-divider-icon"
                            alt=""
                            src={divider4}
                          />

                          <div className="detailed-software-listing-detail-item-value-inner">
                            <div className="detailed-software-footer-social-parent">
                              <div className="detailed-software-footer-social">
                                <img
                                  className="detailed-software-footer-social-child"
                                  alt=""
                                  src={group_58_2x}
                                />

                                <div className="detailed-software-listing-short-detail-item-pin-parent">
                                  <div className="detailed-software-listing-short-detail-item-pin">
                                    <div className="detailed-software-name">Olivia Rhye</div>
                                  </div>
                                  <div className="detailed-software-role1">Business Name</div>
                                </div>
                              </div>
                              <div className="detailed-software-listing-short-detail-item-date">
                                <img
                                  className="detailed-software-icon1"
                                  alt=""
                                  src={icon1}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="detailed-software-rectangle-parent10">
                    <div className="detailed-software-frame-child12"></div>
                    <div className="detailed-software-rectangle-parent30">
                      <img
                        className="detailed-software-listing-card-badge-overlay-child"
                        alt=""
                        src={rectangle_95_2x}
                      />

                      <div className="detailed-software-image-overlay3">
                        <div className="detailed-software-badge4">
                          <div className="detailed-software-listing-card-price">Assured</div>
                        </div>
                        <div className="detailed-software-badge-group">
                          <div className="detailed-software-badge5">
                            <div className="detailed-software-listing-card-radar">ID# AXJYN</div>
                          </div>
                          <div className="detailed-software-buttonsbutton14">
                            <img
                              className="detailed-software-magnifier-1-icon"
                              alt=""
                              src={radar_1_1_2x}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="detailed-software-listing-detail-item">
                      <div className="detailed-software-message-content">
                        <div className="detailed-software-text-frame">
                          <div className="detailed-software-text42">
                            New / Surplus 2021 Philips Health-care HD11 XE
                            (P/N:23A...
                          </div>
                        </div>
                        <div className="detailed-software-icon-group">
                          <img className="detailed-software-icon4" alt="" src={icon1} />

                          <div className="detailed-software-message-content">
                            <div className="detailed-software-listing-detail-item-pin-wrapper">
                              <div className="detailed-software-listing-detail-item-location">
                                <div className="detailed-software-listing-detail-item-date-parent">
                                  <div className="detailed-software-listing-detail-item-date">
                                    <img
                                      className="detailed-software-chevron-right-icon2"
                                      alt=""
                                      src={markerpin021}
                                    />
                                  </div>
                                  <div className="detailed-software-location">India, New Delhi</div>
                                </div>
                                <div className="detailed-software-listing-detail-item-date-parent">
                                  <div className="detailed-software-listing-detail-item-date">
                                    <img
                                      className="detailed-software-chevron-right-icon2"
                                      alt=""
                                      src={calendar1}
                                    />
                                  </div>
                                  <div className="detailed-software-type-of-work">April 09, 2023</div>
                                </div>
                              </div>
                            </div>
                            <div className="detailed-software-supporting-work-five">
                              <div className="detailed-software-breadcrumb-items">
                                <div className="detailed-software-listing-team-member-wrapper">
                                  <div className="detailed-software-listing-team-member">4.9/5</div>
                                </div>
                                <div className="detailed-software-contact-role">
                                  <div className="detailed-software-supporting-text12">
                                    99,999 reviews
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="detailed-software-supporting-six-wrapper">
                              <div className="detailed-software-breadcrumb-items">
                                <div className="detailed-software-listing-short-info">$9,999,999.99</div>
                                <div className="detailed-software-supporting-text-wrapper">
                                  <div className="detailed-software-supporting-text13">USD-Per Unit</div>
                                </div>
                              </div>
                            </div>
                            <div className="detailed-software-divider-container">
                              <img
                                className="detailed-software-divider-icon3"
                                alt=""
                                src={divider4}
                              />
                            </div>
                            <div className="detailed-software-team-info-four">
                              <div className="detailed-software-detail-items">
                                <img
                                  className="detailed-software-footer-social-child"
                                  alt=""
                                  src={group_58_2x}
                                />

                                <div className="detailed-software-listing-short-detail-item-pin-parent">
                                  <div className="detailed-software-listing-short-detail-item-pin">
                                    <div className="detailed-software-name">Olivia Rhye</div>
                                  </div>
                                  <div className="detailed-software-role1">Business Name</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="detailed-software-testiomonial-carousel-arrow8">
                    <img
                      className="detailed-software-arrow-left-icon"
                      alt=""
                      src={arrowleft}
                    />
                  </div>
                </div>
                <div className="detailed-software-testiomonial-carousel-arrow9">
                  <img
                    className="detailed-software-arrow-left-icon"
                    alt=""
                    src={arrowright}
                  />
                </div>
              </div>
            </div>
            <FooterDetailPage />
          </div>
        </div>
      </>
    )
  }
  else {
    return <Loader />;
  }

}

