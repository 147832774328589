// import React from 'react';
// import { Link } from 'react-router-dom';
// import './BusinessDropdown.css';
// import RequestAccess from "../../icons/Gerator/business_page/RequestAccess.svg";
// import verifyBusiness from "../../icons/Gerator/business_page/verifyBusiness.svg";
// import editBusiness from "../../icons/Gerator/business_page/editBusiness.svg";

// const BusinessDropdown = () => {
//   return (
//     <div className="business-display-menu">
//       <div className="business-display-content">

//         <Link to={"/devices"}>
//         <BusinessDropdownItem
//           title="Request Co-Ownership/Upgrade to Request Co-Ownership"
//           description="As a Co-Owner you can manage the business page, apply for verification, and add more team members. Available for Xenon and Helium subscription plans."
//           iconClass={RequestAccess}
//         />
//         </Link>

//         <Link to={"/spares"}>
//         <BusinessDropdownItem
//           title="Edit/Upgrade to Edit"
//           description="As a Business Owner or Co-Owner, updating the business page with current information is critical to your growth objectives."
//           iconClass={editBusiness}
//         />
//         </Link>

//         <Link to={"/services"}>
//         <BusinessDropdownItem
//           title="Verify Business/Upgrade to Verify Business "
//           description="A verified business page offers you more authenticity, and more engagement for your listings."
//           iconClass={verifyBusiness}
//         />
//         </Link>

        

//         {/* <HeaderNavDropdownItem
//           title="Help and support"
//           description="Learn, fix a problem, and get answers to your questions."
//           iconClass="lifeBuoy01"
//         /> */}
//       </div>
//     </div>
//   );
// };

// const BusinessDropdownItem = ({ title, description, iconClass }) => {
//   return (
//     <div className="business-display-navMenuItem">
//       <div className="business-display-iconWrapper">
//       <img src={iconClass} alt={title} className="business-display-icon" />
//       </div>
//       <div className="business-display-itemContent">
//         <div className="business-display-textAndSupportingText">
//           <div className="business-display-textAndBadge">
//             <div className="business-display-text">{title}</div>
//           </div>
//           <div className="business-display-supportingText">{description}</div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default BusinessDropdown;


import React from 'react';
import { Link } from 'react-router-dom';
import './BusinessDropdown.css';
import RequestAccess from "../../icons/Gerator/business_page/RequestAccess.svg";
import verifyBusiness from "../../icons/Gerator/business_page/verifyBusiness.svg";
import editBusiness from "../../icons/Gerator/business_page/editBusiness.svg";

const BusinessDropdown = () => {
  return (
    <div className="business-display-menu">
      <div className="business-display-content">
        
        {/* Request Co-Ownership/Upgrade */}
        <Link to="/devices">
          <div className="business-display-navMenuItem">
            <div className="business-display-iconWrapper">
              <img src={RequestAccess} alt="Request Co-Ownership" className="business-display-icon" />
            </div>
            <div className="business-display-itemContent">
              <div className="business-display-textAndSupportingText">
                <div className="business-display-textAndBadge">
                  <div className="business-display-text">Request Co-Ownership/Upgrade to Request Co-Ownership</div>
                </div>
                <div className="business-display-supportingText">
                  As a Co-Owner you can manage the business page, apply for verification, and add more team members. Available for Xenon and Helium subscription plans.
                </div>
              </div>
            </div>
          </div>
        </Link>

        {/* Edit/Upgrade */}
        <Link to="/spares">
          <div className="business-display-navMenuItem2">
            <div className="business-display-iconWrapper">
              <img src={editBusiness} alt="Edit Business" className="business-display-icon" />
            </div>
            <div className="business-display-itemContent">
              <div className="business-display-textAndSupportingText">
                <div className="business-display-textAndBadge">
                  <div className="business-display-text">Edit/Upgrade to Edit</div>
                </div>
                <div className="business-display-supportingText">
                  As a Business Owner or Co-Owner, updating the business page with current information is critical to your growth objectives.
                </div>
              </div>
            </div>
          </div>
        </Link>

        {/* Verify Business/Upgrade */}
        <Link to="/services">
          <div className="business-display-navMenuItem3">
            <div className="business-display-iconWrapper">
              <img src={verifyBusiness} alt="Verify Business" className="business-display-icon" />
            </div>
            <div className="business-display-itemContent">
              <div className="business-display-textAndSupportingText">
                <div className="business-display-textAndBadge">
                  <div className="business-display-text">Verify Business/Upgrade to Verify Business</div>
                </div>
                <div className="business-display-supportingText">
                  A verified business page offers you more authenticity, and more engagement for your listings.
                </div>
              </div>
            </div>
          </div>
        </Link>

      </div>
    </div>
  );
};

export default BusinessDropdown;
