import React, { useEffect, useState } from 'react';
import "./NewPassword.css";
import * as Yup from "yup";
import { Link, useNavigate } from 'react-router-dom';
import { Formik, Form, Field, useField } from 'formik';
import { BackToLoginButton } from '../../components/BackToLoginButton/BackToLoginButton';
import gerator_logo from '../../icons/Gerator/reset_password/gerator04-1@2x.png';
import placeholder from '../../icons/Gerator/reset_password/placeholder.svg';
import placeholder1 from '../../icons/Gerator/reset_password/placeholder1.svg';
import reset_nav from '../../icons/Gerator/reset_password/_Nav menu button.svg';

import feature_icon2 from '../../icons/Gerator/reset_password/featured-icon2.svg';
import help_icon from '../../icons/Gerator/reset_password/help-icon.svg';
import mail_icon from '../../icons/Gerator/reset_password/mail01.svg';
import { auth, confirmPasswordReset, verifyPasswordResetCode } from "../Login/FirebaseClient";
import { useRef } from 'react';
import { ResetPasswordDropdown } from '../../components/Dropdown/Dropdown';


export const MyTextInput = ({ validate, label, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input>. We can use field meta to show an error
  // message if the field is invalid and it has been touched (i.e. visited)
  const [field, meta] = useField(props);
  let fieldValidationData = null;
  if (validate?.length > 0) {
    validate.some((field) => {
      // Use 'some' for early termination
      if (field.path === props.name) {
        fieldValidationData = field;
        return true; // This will stop the loop early
      }
      return false; // Continue if no match
    });
  }

  if (fieldValidationData != null) {
    // console.log(fieldValidationData);
  }
  // {
  //   fieldValidationData !== null ? (
  //     <div className="reset-password-set-password-error">{fieldValidationData.msg}</div>
  //   ) : null
  // }

  return (
    <>
      {/* <label className="reset-password-set-password-label">Model Number</label> */}

      {/* <label className="reset-password-set-password-label" htmlFor={props.id || props.name}>
      {label} <span className="reset-password-set-password-text-wrapper-3">*</span>
    </label> */}
      <input
        className="reset-password-set-password-text-input text-25 content-15 myinputfield"
        // style={inputStyle}  //New code for border colour
        {...field}
        {...props}
      />
      {meta.touched && meta.error ? (
        <div className="reset-password-set-password-error">{meta.error}</div>
      ) : null}

      {fieldValidationData !== null ? (
        <div className="reset-password-set-password-error">{fieldValidationData.msg}</div>
      ) : null}
    </>
  );
};




export const NewPassword = () => {
  const navigate = useNavigate();
  const [showSuccessMessage, setShowSuccessMessage] = useState({
    message: "",
    color: "",
  });
  const [resetToken, setResetToken] = useState('');
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible);
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('resetToken');
    console.log('resetToken : ' + code);
    setResetToken(code);
  }, []);

  const finalSubmit = async (formData, setSubmitting) => {

    try {

      if (formData.password !== formData.confirmPassword)
        throw new Error('Passwords must match')
      // Verify if the resetToken is valid and not expired
      const email = await verifyPasswordResetCode(auth, resetToken);
      console.log(email);

      // If code is valid, proceed with resetting the password
      await confirmPasswordReset(auth, resetToken, formData.password);
      console.log('Password has been reset successfully.');
      setShowSuccessMessage({ message: "Password has been reset successfully", color: "green" });
      await new Promise(resolve => setTimeout(resolve, 1500));
      navigate('/reset-password-finished');

    } catch (error) {
      if (error.code === 'auth/expired-action-code') {
        setShowSuccessMessage({ message: "Link has expired. Please request a new password reset email", color: "red" });
      } else if (error.code === 'auth/invalid-action-code') {
        setShowSuccessMessage({ message: "Invalid or already used link. Please request a new password reset email", color: "red" });
      } else {
        setShowSuccessMessage({ message: error.message || "Error resetting password", color: "red" });
      }
      setSubmitting(false);

    }

    // const response = await sendMail(formData).unwrap();
    // console.log(response);

    // console.log("Device added successfully!", response);
    //If form was submitted successfully then status return with true, then reseting form
    // if (response.status) {
    //   // setShowSuccessMessage(response.message)
    //   setShowSuccessMessage({ message: response.message, color: "green" });
    //   await new Promise(resolve => setTimeout(resolve, 1500));
    //   // Reseting form
    //   // resetForm();
    //   navigate('/reset-password-check-email',{state: formData.email});
    // }
    // if (!response.status) {
    //   setShowSuccessMessage({ message: response.message, color: "red" })
    // }


  };



  return (
    <div className="reset-password-set-password-reset-password-set-password">
      <div className="reset-password-set-password-reset-password-set-password-inner">
        <header className="reset-password-set-password-frame-group">
          <div className="reset-password-set-password-logo-container">
            <div className="reset-password-set-password-logo2">
              <img
                className="reset-password-set-password-gerator-04-1-icon2"
                alt=""
                src={gerator_logo}
              />
            </div>
          </div>
          <div className="reset-password-set-password-navigation-actions2">
            <button className="reset-password-set-password-buttonsbutton9">
              <img
                className="reset-password-set-password-placeholder-icon15"
                alt=""
                src={placeholder}
              />

              <div className="reset-password-set-password-text-padding6">
                <a href='/sign-in' className="reset-password-set-password-text8">Log in</a>
              </div>
              <img
                className="reset-password-set-password-placeholder-icon15"
                alt=""
                src={placeholder}
              />
            </button>
            <button className="reset-password-set-password-buttonsbutton10">
              <img
                className="reset-password-set-password-placeholder-icon15"
                alt=""
                src={placeholder1}
              />

              <div className="reset-password-set-password-text-padding6">
                <a href='/sign-up' className="reset-password-set-password-text9">Sign up</a>
              </div>
              <img
                className="reset-password-set-password-placeholder-icon15"
                alt=""
                src={placeholder1}
              />
            </button>
          </div>
          <div className="reset-password-set-password-reset-password-new-password-hamburger-menu" ref={dropdownRef} onClick={toggleDropdown}>
            {isDropdownVisible && <ResetPasswordDropdown />}
            <img

              className="reset-password-set-password-hamburger-icon"
              src={reset_nav}
              alt="" />

          </div>
        </header>
      </div>
      <main className="reset-password-set-password-log-in2">
        <section className="reset-password-set-password-container4">
          <div className="reset-password-set-password-container5">
            <div className="reset-password-set-password-content4">
              <div className="reset-password-set-password-header2">
                <img
                  className="reset-password-set-password-featured-icon2"
                  loading="lazy"
                  alt=""
                  src={feature_icon2}
                />

                <div className="reset-password-set-password-text-and-supporting-text2">
                  <h1 className="reset-password-set-password-title1">Set new password</h1>
                  <div className="reset-password-set-password-supporting-text2">
                    Your new password must be different to previously used
                    passwords.
                  </div>
                </div>
              </div>
              <Formik
                initialValues={{
                  password: "",
                  confirmPassword: ""
                }}
                validationSchema={Yup.object().shape({
                  password: Yup.string()
                    .required('Password is required')
                    .min(8, 'Password must be at least 8 characters'),
                  confirmPassword: Yup.string()
                    .required('Confirm Password is required')
                  // .oneOf([Yup.ref('password'), null], 'Passwords must match'),
                })}
                onSubmit={(values, { setSubmitting }) => {
                  // multiPartData = objectToWantedDeviceFormData(values)
                  console.log(values);
                  finalSubmit(values, setSubmitting);
                  // resetForm();
                }}
              >
                {({ handleSubmit, isSubmitting }) => (
                  <Form className="reset-password-set-password-content5" onSubmit={handleSubmit}>
                    <div className="reset-password-set-password-form1">
                      <div className="reset-password-set-password-input-field1">
                        <div className="reset-password-set-password-input-field1">
                          <div className="reset-password-set-password-label1">Password</div>
                          <div className="reset-password-set-password-input1">
                            <MyTextInput className="reset-password-set-password-content6"
                              name="password"
                              placeholder="Enter password"
                              type="password" />
                            {/* <Field
                              className="reset-password-set-password-content6"
                              name="password"
                              placeholder="••••••••"
                              type="password"
                            /> */}
                            <img className="reset-password-set-password-help-icon1" alt="" src={help_icon} />
                          </div>
                        </div>
                        <div className="reset-password-set-password-hint-text1">Must be at least 8 characters.</div>
                      </div>

                      <div className="reset-password-set-password-input-field1">
                        <div className="reset-password-set-password-input-field1">
                          <div className="reset-password-set-password-label2">Confirm password</div>
                          <div className="reset-password-set-password-input1">
                            <MyTextInput className="reset-password-set-password-content6"
                              name="confirmPassword"
                              placeholder="Re-enter Password"
                              type="password" />
                            {/* <Field
                              className="reset-password-set-password-content6"
                              name="confirmPassword"
                              placeholder=""
                              type="password"
                            /> */}
                            <img className="reset-password-set-password-help-icon1" alt="" src={help_icon} />
                          </div>
                        </div>
                        <div className="reset-password-set-password-hint-text1">
                          This is a hint text to help the user.
                        </div>
                      </div>

                      <div className="reset-password-set-password-checks">
                        <div className="reset-password-set-password-check-and-text">
                          <Field
                            className="reset-password-set-password-check-icon"
                            type="radio"
                            name="radioGroup"
                            value="option1"
                          />
                          <div className="reset-password-set-password-hint-text3">
                            Must be at least 8 characters
                          </div>
                        </div>
                        <div className="reset-password-set-password-check-and-text">
                          <Field
                            className="reset-password-set-password-check-icon"
                            type="radio"
                            name="radioGroup"
                            value="option2"
                          />
                          <div className="reset-password-set-password-hint-text3">
                            Must contain one special character
                          </div>
                        </div>
                      </div>
                    </div>

                    <button className="reset-password-set-password-buttonsbutton11" type="submit" disabled={isSubmitting}>
                      <img className="reset-password-set-password-placeholder-icon15" alt="" src={placeholder1} />
                      <div className="reset-password-set-password-text-padding6">
                        <div className="reset-password-set-password-text10">{isSubmitting ? "Resetting Password..." : "Reset Password"}</div>
                      </div>
                      <img className="reset-password-set-password-placeholder-icon15" alt="" src={placeholder1} />
                    </button>
                  </Form>
                )}
              </Formik>

              {showSuccessMessage.message.length > 0 && (
                <div
                  style={{
                    fontSize: "14px",
                    color: showSuccessMessage.color,
                    padding: "15px 10px",
                    border: "1px solid " + showSuccessMessage.color,
                    borderRadius: "10px",
                    margin: "0 auto",
                  }}
                >
                  {showSuccessMessage.message}
                </div>
              )}
              <Link to={"/sign-in"} style={{ textDecoration: 'none' }}>
                <BackToLoginButton />
              </Link>
            </div>
          </div>
        </section>



      </main>

      <footer className='reset-password-set-password-footer'>
        <div className="reset-password-set-password-help-link1">© Gerator Asia LLP. All rights reserved.</div>
        <img
          className="reset-password-set-password-mail-01-icon2"
          loading="lazy"
          alt=""
          src={mail_icon}
        />

        <div className="reset-password-set-password-helpcentergeratorcom2">helpcenter@gerator.com</div>
      </footer>
    </div>
  )
}
